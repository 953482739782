import { createRouter, createWebHistory } from "vue-router";

import AlertView from "@/views/AlertView";
import LearningView from "@/views/LearningView";
import LearningGuideView from "@/views/LearningGuideView";
import ResourcesView from "@/views/ResourcesView";
import StatsView from "@/views/StatsView";
import AboutView from "@/views/AboutView";
import ContactView from "@/views/ContactView";
import ExportView from "@/views/ExportView";
import SocialListeningReport from "@/components/SocialListeningReport";

const routes = [
  {
    path: "/",
    name: "AlertView",
    component: AlertView,
  },
  {
    path: "/learning",
    name: "LearningView",
    component: LearningView,
    children: [{ path: "guide", component: LearningGuideView }],
  },
  // {
  //   path: "/learning/guide",
  //   name: "LearningGuideView",
  //   component: LearningGuideView,
  // },
  {
    path: "/resources",
    name: "ResourcesView",
    component: ResourcesView,
    children: [{ path: "reports", component: SocialListeningReport }],
  },

  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
  {
    path: "/contact",
    name: "ContactView",
    component: ContactView,
  },
  {
    path: "/stats",
    name: "StatsView",
    component: StatsView,
  },
  {
    path: "/export",
    name: "ExportView",
    component: ExportView,
  },
];

const router = createRouter({
  routes,
  linkActiveClass: "selected",
  history: createWebHistory(),
});

export default router;
