<template>
  <div class="Loader">
    <div class="Loader__loading" :class="margin"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    margin: {
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>

<style lang="scss">
.Loader {
  @apply flex flex-col justify-center items-center w-full;
  &__loading {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #94dfde;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
