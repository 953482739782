<template>
  <div class="social-listening-report" v-if="ready">
    <button
      type="button"
      class="md:hidden border border-white py-1 px-2 mb-6 filter-button"
      :class="{ selected: showFilters }"
      @click="showFilters = !showFilters"
    >
      Filters
    </button>
    <div class="filters" :class="showFilters ? 'show' : ''">
      <div class="group">
        <div class="title">Regions</div>
        <a
          class="region-filter uppercase"
          @click="filterSelected('region', 'none')"
          :class="{ selected: this.selectedRegion === 'none' }"
          >all</a
        >
        <a
          class="region-filter"
          :class="{ selected: this.selectedRegion === region }"
          v-for="region in regionOptions"
          :key="region"
          @click="filterSelected('region', region)"
          >{{ region }}</a
        >
      </div>
      <div class="group">
        <div class="title">Date</div>

        <a
          class="month-filter uppercase"
          :class="{ selected: this.selectedMonth === 'none' }"
          href="#"
          @click.prevent="selectMonth()"
          >ALL</a
        >
        <a
          class="month-filter"
          :class="{
            disabled: !month.display,
            selected: this.selectedMonth === month.name,
          }"
          :href="'#' + month.name.replace(/ /g, '_')"
          v-for="month in months"
          :key="month.name"
          @click.prevent="selectMonth(month.name)"
        >
          {{ month.name }}
        </a>
      </div>
    </div>
    <div class="list" ref="scrollBox" v-if="ready">
      <h2 class="month_name sticky top-0">
        <span v-if="selectedMonth !== 'none'">{{ selectedMonth }}</span
        ><span v-else>All Months</span>
      </h2>
      <div
        v-for="report in socialListeningReports"
        :key="report.id"
        class="list-item"
        :class="{ display: report.display }"
      >
        <div class="list-item__label">
          <span>
            {{ asText(report.data.title) }}
          </span>
        </div>
        <a
          class="list-item__title"
          :href="report.data.asset_link.url"
          target="_blank"
        >
          <span>{{ report.data.region }}</span>
          <span class="divider">|</span>
          <div class="whitespace-nowrap mr-2">
            <span> {{ formatDate(report.data.start_date) }}</span>
            <span v-if="report.data.end_date" class="mx-1">-</span>
            <span class="type">{{ formatDate(report.data.end_date) }}</span>
          </div>
          <img
            src="@/images/external-link-teal.svg"
            class="link-out"
            alt="link icon"
          />
        </a>
      </div>
      <div class="mt-4" v-if="hasFilters">
        <button type="button" class="btn" @click="clearFilters">
          Reset Filters
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import * as prismicH from "@prismicio/helpers";
import { groupBy } from "lodash";

export default {
  name: "SocialListeningReportComponent",
  inject: ["prismic", "dayjs"],
  data() {
    return {
      apiPageNumber: 1,
      ready: false,
      socialListeningReports: [],
      regions: {},
      selectedMonth: "none",
      selectedRegion: "none",
      showFilters: false,
    };
  },
  watch: {},
  methods: {
    clearFilters() {
      this.selectedRegion = "none";
      this.selectedMonth = "none";
      this.filterSelected("month");
    },
    selectMonth(month) {
      if (!month) {
        this.selectedMonth = "none";
      } else {
        this.selectedMonth = month;
      }
      this.filterSelected("month");
    },
    fetchData() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);

      prismicClient
        .query(
          [this.prismic.Predicates.any("document.type", ["unicef_report"])],
          {
            orderings: "[my.unicef_report.end_date desc]",
            pageSize: 100,
            page: this.apiPageNumber,
            fetchLinks: "region.name",
          }
        )
        .then((response) => {
          response.results.forEach((report) => {
            report.start_day = this.dayjs(report.data.start_date);
            report.end_day = this.dayjs(report.data.end_date);
            if (!this.regions[report.data.region.id]) {
              this.regions[report.data.region.id] = this.asText(
                report.data.region.data.name
              );
            }
            report.data.region = this.asText(report.data.region.data.name);
            report.display = true;
          });

          this.socialListeningReports = this.socialListeningReports.concat(
            response.results
          );

          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadResources();
          } else {
            this.ready = true;
          }
        });
    },
    filterSelected(type, region) {
      this.showFilters = false;
      if (type === "region") {
        this.selectedRegion = region;

        if (this.selectedRegion === "none" && this.selectedMonth === "none") {
          this.socialListeningReports.forEach((report) => {
            report.display = true;
          });
        } else if (
          this.selectedRegion === "none" &&
          this.selectedMonth !== "none"
        ) {
          this.socialListeningReports.forEach((report) => {
            if (
              report.start_day.format("MMMM YYYY") === this.selectedMonth ||
              report.end_day.format("MMMM YYYY") === this.selectedMonth
            ) {
              report.display = true;
            } else {
              report.display = false;
            }
          });
        } else if (
          this.selectedRegion !== "none" &&
          this.selectedMonth === "none"
        ) {
          this.socialListeningReports.forEach((report) => {
            if (report.data.region === this.selectedRegion) {
              report.display = true;
            } else {
              report.display = false;
            }
          });
        } else if (
          this.selectedRegion !== "none" &&
          this.selectedMonth !== "none"
        ) {
          this.socialListeningReports.forEach((report) => {
            if (
              report.data.region === this.selectedRegion &&
              (report.start_day.format("MMMM YYYY") === this.selectedMonth ||
                report.end_day.format("MMMM YYYY") === this.selectedMonth)
            ) {
              report.display = true;
            } else {
              report.display = false;
            }
          });
        }
      }

      if (type === "month") {
        if (this.selectedMonth === "none" && this.selectedRegion === "none") {
          this.socialListeningReports.forEach((report) => {
            report.display = true;
          });
        } else if (
          this.selectedMonth === "none" &&
          this.selectedRegion !== "none"
        ) {
          this.socialListeningReports.forEach((report) => {
            if (report.data.region === this.selectedRegion) {
              report.display = true;
            } else {
              report.display = false;
            }
          });
        } else if (
          this.selectedMonth !== "none" &&
          this.selectedRegion === "none"
        ) {
          this.socialListeningReports.forEach((report) => {
            if (
              report.start_day.format("MMMM YYYY") === this.selectedMonth ||
              report.end_day.format("MMMM YYYY") === this.selectedMonth
            ) {
              report.display = true;
            } else {
              report.display = false;
            }
          });
        } else if (
          this.selectedMonth !== "none" &&
          this.selectedRegion !== "none"
        ) {
          this.socialListeningReports.forEach((report) => {
            if (
              report.data.region === this.selectedRegion &&
              (report.start_day.format("MMMM YYYY") === this.selectedMonth ||
                report.end_day.format("MMMM YYYY") === this.selectedMonth)
            ) {
              report.display = true;
            } else {
              report.display = false;
            }
          });
        }
      }

      if (this.selectedMonth === "none") {
        let totalMonths = this.months.length;
        let totalHiddenMonths = 0;

        this.months.forEach((month) => {
          let display = true;

          let totalResources = month.resources.length;
          let totalHidden = 0;

          if (month.resources.length) {
            month.resources.forEach((resource) => {
              if (!resource.display) {
                totalHidden++;
              }
            });
            if (totalHidden === totalResources) {
              display = false;
              totalHiddenMonths++;
            }
          } else {
            display = false;
          }

          month.display = display;
        });

        if (totalHiddenMonths === totalMonths) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      }
    },
    asText(ref) {
      return prismicH.asText(ref);
    },
    formatDate(date) {
      return this.dayjs(date).format("MMM DD, YYYY");
    },
  },
  computed: {
    hasFilters() {
      return this.selectedMonth !== "none" || this.selectedRegion !== "none";
    },
    months() {
      let monthsObj = groupBy(this.socialListeningReports, (resource) => {
        let d = resource.end_day;
        return d.format("MMMM YYYY");
      });

      let startMonthsObj = groupBy(this.socialListeningReports, (resource) => {
        let d = resource.start_day;
        return d.format("MMMM YYYY");
      });

      let uniqueMonths = [
        ...new Set(
          [].concat(Object.keys(monthsObj)).concat(Object.keys(startMonthsObj))
        ),
      ];

      let resMonths = {};
      uniqueMonths.forEach((month) => {
        resMonths[month] = [
          ...new Set([].concat(monthsObj[month]).concat(startMonthsObj[month])),
        ];
      });

      let months = [];
      for (const [key, value] of Object.entries(resMonths)) {
        let month = {
          name: key,
          resources: value.filter((n) => n),
          display: true,
        };
        months.push(month);
      }

      return months;
    },
    regionOptions() {
      let regionOptions = Object.keys(this.regions).map((key) => {
        return this.regions[key];
      });

      return regionOptions;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss">
.social-listening-report {
  @apply grid grid-cols-1;
  @screen md {
    @apply grid-cols-2;
    grid-template-columns: 216px 1fr;
    grid-column-gap: 72px;
  }

  .month_name {
    @apply mb-4;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $teal;
    padding: 8px 0;
    font-size: 14px;
    border-bottom: 1px solid white;
    background-color: $bg-primary;
    @screen md {
      @apply mb-8;
    }
  }

  .list {
    top: 0;
    height: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 265px);
    box-sizing: border-box;
    padding-right: 1rem;
    //padding-bottom: 80px;

    scroll-behavior: smooth;
    .list-item {
      border-color: $midgrey;
      max-width: 800px;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity $uiSpeed, max-height $uiSpeed;
      display: none;

      &.display {
        @apply border-b pb-4 mb-4 block;
        max-height: 800px;
        opacity: 1;
      }
      &:first-child {
        &.display {
          @apply pt-0;
        }
      }
    }

    .divider {
      @apply px-1;
      @screen md {
        @apply px-2;
      }
    }

    .list-item__title {
      @apply no-underline flex flex-wrap md:flex-nowrap items-center justify-start;
      border: none;
      font-weight: bold;
      font-size: 15px;
      line-height: 1.3;
      @screen md {
        font-size: 19.2px;
      }
      //margin: 8px 0;
    }

    .link-out {
      width: 16px;
    }

    .list-item__label {
      @apply max-w-sm mb-2;
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 11.11px;
      line-height: 1.2;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #f3f3f3;
    }
  }

  .filters {
    position: absolute;
    height: 0;
    top: calc(#{$topnav_height} + 6rem);
    overflow: hidden;
    left: 0;
    width: 100%;

    &.show {
      @apply p-4 border-b border-white;
      transition: height 0.2s ease;
      height: 100%;
      z-index: 10;
      background: $bg-primary;
    }

    @screen md {
      @apply p-0;
      height: auto;
      position: sticky;
      top: calc(#{$topnav_height} + 2rem);
    }

    .group {
      margin-bottom: 24px;
      max-height: 500px;
      overflow-y: auto;

      .month-filter,
      .region-filter {
        @apply cursor-pointer;
        display: block;
        border: none;

        @apply py-1;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 16px;
        &.selected {
          color: $jade;
        }

        &.disabled {
          pointer-events: none;
          color: $grey;
        }
      }
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 11.11px;
      line-height: 14px;
      /* identical to box height */

      letter-spacing: 0.08em;
      text-transform: uppercase;

      margin: 4px 0;
    }

    select {
      position: relative;
      width: 100%;
      background: $bg-primary;
      color: $white;
      border: 1px solid $midgrey;
      border-radius: 4px;
      outline: none;
      padding: 0.5em 0.25em;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      // letter-spacing: 0.15em;
      padding: 4px 8px;
      font-family: "Mark Pro";

      background-position: 0 0;

      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      background-image: url("~@/images/Single-Angle.svg");
      background-position: calc(100% - 8px) center;
      background-size: 8px auto;
      background-repeat: no-repeat;
    }
  }

  .filter-button {
    &.selected {
      background: $jade;
      color: black;
      border-color: $jade;
    }
  }
}
</style>
