<template>
  <div class="splash_screen" :class="{ active: splashView }" @click="hide">
    <div class="gradient_background"></div>
    <div class="world_map"></div>
    <div class="copy_block">
      <h1 class="copy_block__header">Vaccination Demand Observatory</h1>
      <p class="call_to_action">Click to continue</p>
      <p class="description max-w-xl">
        Together with UNICEF, the Vaccination Demand Observatory unites experts
        from multiple sectors to support global communities with increased
        vaccine demand and reduce the impact of misinformation.
      </p>
    </div>
    <SponsorsComponent
      class="mt-16 flex flex-col md:flex-row gap-y-4 md:gap-y-0 md:gap-x-4 sponsor-item"
    />
  </div>
</template>

<script>
import SponsorsComponent from "./SponsorsComponent.vue";

export default {
  name: "SplashScreen",
  props: ["splashView"],
  components: { SponsorsComponent },
  // data() {
  //   return {
  //     active: true,
  //   };
  // },
  emits: ["hidden"],
  methods: {
    hide() {
      // this.active = false;
      this.$emit("hidden");
    },
  },
};
</script>

<style scoped lang="scss">
.splash_screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  opacity: 0;
  pointer-events: none;
  transition: opacity $uiSpeed;
  cursor: pointer;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .gradient_background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      270deg,
      rgba(45, 196, 167, 0.2) 0%,
      rgba(43, 192, 165, 0) 100%
    );
    background-size: contain;
    opacity: 0.5;
  }

  .world_map {
    position: absolute;
    top: $timeline_height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$timeline_height});

    background-image: url("~@/images/World_Map.png");
    background-position: center;
    background-size: auto 100%;
  }

  .sponsor-item {
    position: absolute;
    bottom: 3rem;
    left: 5vw;
    padding: 0 30px;
  }

  .copy_block {
    position: absolute;
    top: 50%;
    // left: calc(100vw - #{$timeline_right} - #{$sidebar_width});
    left: 5vw;
    height: auto;
    width: auto;
    @apply max-w-3xl;
    transform: translateY(-50%);
    padding: 0 30px;

    &__header {
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-weight: bold;
      font-size: 42px;
      line-height: 42px;

      margin: 0 0 16px 0;
      color: #26c09e;
      background-color: #26c09e;

      background-image: linear-gradient(-180deg, #229c93, $jade);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }

    .call_to_action {
      @apply text-body mb-3;
      @screen md {
        @apply text-subtitle uppercase text-jade mb-11;
      }
      letter-spacing: 0.15em;
      line-height: 1.5em;
      transition: text-shadow $uiSpeed;
    }

    .description {
      @apply text-subtitle text-white font-medium;
      line-height: 1.5em;
      opacity: 0.85;
      font-weight: 500;
    }

    &:hover {
      .call_to_action {
        text-shadow: 0 0 10px $jade;
      }
    }
  }

  @include breakpoint(tablet) {
    .copy_block {
      top: 33%;
      //width: 60vw;
      //max-width: initial;
      //top: 30%;
    }
  }

  @include breakpoint(handheld) {
    //top: $topnav_height;
    //height: calc(100vh - #{$topnav_height});
    background: $bg-primary;

    .world_map {
      opacity: 0.5;
    }

    .sponsor-item {
      left: 0;
    }

    .copy_block {
      //top: 33%;
      left: 0;
      width: 100vw;
      max-width: initial;
      box-sizing: border-box;

      &__header {
        font-size: 28px;
        line-height: 1em;
        margin-bottom: 12px;
      }

      // .call_to_action {
      //   font-size: 14px;
      //   margin-bottom: 12px;
      // }

      // .description {
      //   font-size: 14px;
      // }
    }
  }
}
</style>
