<template>
  <div
    class="alert"
    :class="[{ active: alertView }, { hide_extras: !extras }]"
    ref="alertContainer"
  >
    <div v-if="alert">
      <div v-if="isEdit" class="mb-4">
        <a
          target="_blank"
          :href="
            'https://projectvctr.prismic.io/documents~b=working&c=published&l=en-us/' +
            alert.id
          "
          class="btn btn--green"
          >Edit</a
        >
      </div>
      <div class="two_columns">
        <h2 v-if="alert.type == 'fact_check'">
          {{ alert.data.statement[0].text }}
        </h2>
        <h2 v-if="alert.data.title && alert.data.title.length > 0">
          {{ alert.data.title[0].text }}
        </h2>
        <h2
          v-if="
            alert.data.title &&
            alert.data.title.length == 0 &&
            alert.data.content
          "
        >
          {{ alert.data.content[0].text }}
        </h2>
      </div>

      <div class="two_columns">
        <div class="article_meta">
          <div class="meta">
            <div class="label">Risk Level</div>
            <span
              :class="[
                alert.data.rating.toLowerCase().replace(/ /g, '_'),
                'dot',
              ]"
            ></span>
            {{ riskLevel }}
          </div>

          <div class="meta">
            <div class="label">Date</div>
            {{ alert.day.format("MMM DD YYYY") }}
          </div>

          <div v-if="alert.data.fact_source" class="meta">
            <div class="label">Fact Source</div>
            {{ alert.data.fact_source }}
          </div>

          <div v-if="locations.length > 0" class="meta">
            <div class="label">Locations</div>
            <div
              v-for="(location, index) in locations"
              :key="index + 'country'"
            >
              {{ location }}
            </div>
          </div>

          <div v-if="vaccines.length" class="meta">
            <div class="label">Vaccines</div>
            <div v-for="(vaccine, index) in vaccines" :key="index + '_vaccine'">
              {{ vaccine }}
            </div>
          </div>
          <div v-if="topics.length > 0" class="meta">
            <div class="label">Topics</div>
            <div v-for="(topic, index) in topics" :key="index + '_topic'">
              {{ topic }}
            </div>
          </div>
        </div>

        <div class="take_action">
          <div class="meta">
            <div class="label">Take Action</div>
            Resources
          </div>
        </div>
      </div>

      <div class="two_columns">
        <div class="content">
          <div v-if="alert.data.background" class="background">
            <div class="label">background</div>
            <div class="copy" v-html="background"></div>
          </div>

          <div v-if="alert.data.response" class="response">
            <div class="label">response</div>
            <div class="copy" v-html="response"></div>
          </div>

          <div v-if="sources" class="sources">
            <div class="label">sources</div>
            <div class="copy" v-html="sources"></div>
          </div>

          <div v-if="alert.data.content" class="content">
            <div class="copy" v-html="content"></div>
          </div>

          <div v-if="alert.data.description" class="content">
            <div class="label">description</div>
            <div class="copy" v-html="description"></div>
          </div>
        </div>
        <div class="related">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
// var PrismicDOM = require('prismic-dom');
import { RichText, Link } from "prismic-dom";

export default {
  name: "Alert",
  props: ["extras", "alert", "alertView"],
  data() {
    return {
      isEdit: false,
    };
  },
  methods: {
    link(val) {
      //console.log(val, Link.url(val));
      return Link.url(val);
    },
    convertToExternalLinks() {
      let linkList = document.querySelectorAll(".content a");
      //console.log(linkList);
      for (let link of linkList) {
        //console.log(link);
        link.setAttribute("target", "_blank");
      }
    },
  },
  computed: {
    riskLevel() {
      return this.alert.data.rating;
    },
    vaccines() {
      return this.alert.vaccines;
    },
    topics: function () {
      return this.alert.topics;
    },

    locations: function () {
      let locations = [];
      if (this.alert.data.locations) {
        this.alert.data.locations.forEach((location) => {
          if (location.country.slug && location.country.slug.length > 2) {
            locations.push(location.country.slug.replace(/-/g, " "));
          }
        });
      }

      if (this.alert.data.states) {
        this.alert.data.states.forEach((state) => {
          if (state.advocacy_state.slug) {
            locations.push(
              state.advocacy_state.slug.replace(/-/g, " ") + ", USA"
            );
          }
        });
      }

      if (this.alert.data.location) {
        if (this.alert.data.location.slug == "global") {
          locations.push("global");
        } else {
          locations.push(
            this.alert.data.location.slug.replace(/-/g, " ") + ", USA"
          );
        }
      }

      return locations;
    },
    background: function () {
      return RichText.asHtml(this.alert.data.background);
    },
    response: function () {
      return RichText.asHtml(this.alert.data.response);
    },
    content: function () {
      return RichText.asHtml(this.alert.data.content);
    },
    description: function () {
      return RichText.asHtml(this.alert.data.description);
    },
    sources() {
      if (
        this.alert.data.sources[0] &&
        this.alert.data.sources[0].text !== ""
      ) {
        return RichText.asHtml(this.alert.data.sources);
      }
      return null;
    },
  },
  watch: {
    alertView() {
      this.$refs.alertContainer.scrollTop = 0;
    },
    alert() {
      //console.log(val);
      if (this.alertView === true) {
        setTimeout(() => {
          this.convertToExternalLinks();
        }, 500);
      }
    },
  },
  mounted() {
    this.isEdit = !!document.querySelector("#prismic-toolbar-v2");
  },
  created() {},
};
</script>

<style scoped lang="scss">
.alert {
  position: absolute;
  display: block;
  left: 30vw;
  top: $toparea_height;
  width: 70vw;
  max-width: 1200px;
  height: calc(100vh - #{$toparea_height});
  padding: $timeline_height 90px;
  box-sizing: border-box;
  overflow-y: scroll;
  // border: 1px solid magenta;

  opacity: 0;
  pointer-events: none;
  transition: opacity $uiSpeed;

  &.active {
    opacity: 1;
    pointer-events: all;
    transition: opacity $viewSpeed $viewSpeed;
  }
  .btn {
    border: 1px solid;
    padding: 0.25rem 1rem;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: bold;
    &--red {
      //border-color: red;
      background-color: red;
      transition: background-color $uiSpeed;
      &:hover {
        background-color: #c10101;
        color: #fff;
      }
    }
    &--green {
      //background-color: $jade;
      color: $ignore;
      transition: background-color $uiSpeed;
      &:hover {
        background-color: #222;
        //color: #fff;
      }
    }
  }

  .two_columns {
    display: grid;
    grid-template-columns: auto 25%;
    column-gap: 48px;
    margin-bottom: 2em;
  }

  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    text-transform: capitalize;
  }

  .label {
    color: $grey;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    line-height: 24px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-bottom: 1em;
  }

  .article_meta {
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .meta {
    display: inline-block;
    margin-right: 54px;
    margin-bottom: 1em;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;

    .label {
      margin: 0;
    }

    .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: white;
      margin-right: 0.5em;
      transform: translateY(-1px);

      &.low {
        background: $ignore;
      }

      &.medium {
        background: $passive;
      }

      &.high {
        background: $active;
      }
    }
  }

  .copy {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2em;

    .copy * {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.hide_extras {
    .two_columns {
      grid-template-columns: 100%;
    }

    .take_action,
    .related {
      display: none;
    }
  }

  @include breakpoint(handheld) {
    left: 0;
    width: 100vw;
    padding: 52px 24px;

    h2 {
      font-size: 28px;
      line-height: 1.25em;
      margin: 0.5em 0;
    }

    .two_columns {
      margin-bottom: 0;
    }

    .article_meta {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 8px;
      margin-bottom: 60px;
      white-space: normal;
    }

    .label {
      margin-bottom: 0;
    }

    .meta {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      width: 100%;
      margin-bottom: 0.5em;

      .label {
        margin-bottom: 0;
      }
    }

    .copy {
      font-size: 14px;
      line-height: 18px;

      p {
        margin: 0 0 1em 0;
      }
    }
  }
}
</style>
