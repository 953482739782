import jsPDF from "jspdf";
import getBase64 from "./getBase64.js";

// Used to calculate text height so that we can create consistent margins between text
export function calculateTextHeight(fontSize, width, maxWidth, lineHeight) {
  return Math.ceil(width / maxWidth) * lineHeight * fontSize;
}

// Makes a cleaner array of objects to work with for source links
export function restructureData(sourceArr, newSourcesArr) {
  let text = sourceArr[0].text;
  let textArr = [];
  if (sourceArr[0].spans.length > 1) {
    textArr = text.split(", ");
  } else {
    textArr = [text];
  }
  textArr.forEach((source) => newSourcesArr.push({ text: source }));
  for (let i = 0; i < newSourcesArr.length; i++) {
    newSourcesArr[i].link = sourceArr[0].spans[i].data.url;
  }
}

// Main function for downloading PDF
export default async function generatePDF(
  title,
  risk,
  date,
  locations,
  vaccines,
  topics,
  background,
  response,
  sources,
  slug
) {
  let page = 1;
  // console.log('Title: ' + title.length);
  // console.log('Locations: ' + locations.length);
  // console.log('Vaccines: ' + vaccines.length);
  // console.log('Topics ' + topics.length);

  // Function to output color based on risk level
  function riskLevelColor() {
    if (risk === "High") {
      return "#FF6262";
    } else if (risk === "Medium") {
      return "#FFDD63";
    } else {
      return "#9EFD8E";
    }
  }

  function calculateTitleHeight(text, maxWidth, fontSize) {
    const textArr = doc.splitTextToSize(text, maxWidth);
    return {
      titleHeight: textArr.length * fontSize * 1.5,
      linkYCoordinate:
        textArr.length > 1 ? (textArr.length - 1) * fontSize * 1.5 - 10 : -10,
    };
  }

  // Function for creating paragraphs for Background and Response data in alert
  function generateParagraphs(textArr) {
    // Removes unnecessary Prismic text data
    textArr = textArr.filter((element) => element.text.length);

    for (let text of textArr) {
      const paragraph = doc.splitTextToSize(text.text, widthMax);
      const textHeight = paragraph.length * 1.28 * 11;
      // console.log(`Paragraph ${textArr.indexOf(text) + 1}: ${text.text.length}`)
      checkNewPage(textHeight);
      doc.setFont("Inter-Regular");
      doc.setTextColor("#000000");
      doc.setFontSize(11);

      doc.text(paragraph, 96, y, {
        lineHeightFactor: 1.28,
        charSpace: 0.03,
      });
      if (textArr.indexOf(text) !== textArr.length - 1) {
        y += textHeight + 10;
      } else {
        y += textHeight;
      }
    }
  }

  // Function to add sources data
  function addSources(sourceArr) {
    if (!sourceArr.length || !sourceArr[0].text.length) {
      doc.setFont("Inter-Regular");
      doc.setFontSize(11);
      doc.setTextColor("#000000");
      return doc.text("N/A", 96, y);
    }
    let sources = [];

    // Makes a cleaner array of objects for sources
    restructureData(sourceArr, sources);
    // let charCount = 0;
    // sources.forEach(source => charCount += source.text.length);
    // console.log('Sources: ' + charCount);

    // Adding source links
    let x = 96;
    for (let source of sources) {
      const textWidth = doc.getTextWidth(source.text);
      const index = sources.indexOf(source);
      const text =
        index + 1 !== sources.length ? `${source.text},` : source.text;
      checkNewPage(
        calculateTextHeight(11, doc.getTextWidth(text), widthMax, 1.28)
      );
      doc.setFont("Inter-Regular");
      doc.setFontSize(11);
      doc.setTextColor("#000000");
      doc.textWithLink(text, x, y, { url: source.link });
      doc.line(x, y + 2, textWidth + x, y + 2, "F");
      x += textWidth + 8;
    }
  }

  // Automatically adds new PDF page if text starts to overflow towards footer (JSPDF doesn't offer built in pagination)
  function checkNewPage(textHeight) {
    if (y + textHeight >= 678) {
      page++;
      doc.addPage();
      doc.setPage(page);
      y = 38;

      // Footer container on new page
      doc.setFillColor("#00403F");
      doc.rect(0, 682, 612, 110, "F");

      // Footer disclaimer text on new page
      const disclaimer =
        "The Vaccination Demand Observatory (hereafter VDO) monitors trending vaccine misinformation around the world at TheVDO.org. Misinformation alerts are categorized by country of origin, type of vaccine, and risk level as well as by specific vaccine-related topics. The VDO has made every attempt to ensure the accuracy and reliability of the information provided. However, the information is provided “as is” without warranty of any kind. The information on the VDO website may be of a time-critical nature, and VDO cannot guarantee that the information is not outdated, inaccurate, or incomplete. As such, the VDO disclaims any and all liability whatsoever relating to any information, apparatus, and product, and is not responsible or liable for any claim, loss, or damage resulting from its use by any user.";
      doc.setFont("Inter-Regular");
      doc.setFontSize(8);
      doc.setTextColor("#FFFFFF");
      doc.text(disclaimer, 80, 705, {
        lineHeightFactor: 1.375,
        maxWidth: 460,
        align: "justify",
      });

      // Risk level on new page
      doc.setFillColor(riskLevelColor());
      doc.rect(80, 10, 4, 653, "F");
    }
  }

  // Creates PDF Document
  const doc = new jsPDF({
    unit: "pt",
    format: "letter",
    filters: ["ASCIIHexEncode"],
  });

  // Grabbing Base64 for fonts and uploading them to PDF
  const markProBold = await getBase64("./data/Mark-Pro-Bold.txt");
  doc.addFileToVFS("Mark-Pro-Bold-normal.ttf", markProBold);
  doc.addFont("Mark-Pro-Bold-normal.ttf", "Mark-Pro-Bold", "normal");

  const interRegular = await getBase64("./data/Inter-Regular.txt");
  doc.addFileToVFS("Inter-Regular-normal.ttf", interRegular);
  doc.addFont("Inter-Regular-normal.ttf", "Inter-Regular", "normal");

  const markProMedium = await getBase64("./data/Mark-Pro-Medium.txt");
  doc.addFileToVFS("MarkPro-Medium-normal.ttf", markProMedium);
  doc.addFont("MarkPro-Medium-normal.ttf", "MarkPro-Medium", "normal");

  // Grabbing Base64 for images;
  const vdoLogo = await getBase64("./data/VDO-Logo.txt");
  // const unicefLogo = await getBase64("./data/unicef-logo.txt");

  // Setting max text width
  let widthMax = 440;

  // Header
  doc.setFillColor("#BFCFCF");
  doc.rect(0, 0, 612, 80, "F");

  // Header Logos
  doc.addImage(vdoLogo, "PNG", 80, 14, 127, 48);
  // doc.addImage(unicefLogo, "PNG", 432, 14, 80, 53);

  // Footer container
  doc.setFillColor("#00403F");
  doc.rect(0, 682, 612, 110, "F");

  // Footer disclaimer text
  const disclaimer =
    "The Vaccination Demand Observatory (hereafter VDO) monitors trending vaccine misinformation around the world at TheVDO.org. Misinformation alerts are categorized by country of origin, type of vaccine, and risk level as well as by specific vaccine-related topics. The VDO has made every attempt to ensure the accuracy and reliability of the information provided. However, the information is provided “as is” without warranty of any kind. The information on the VDO website may be of a time-critical nature, and VDO cannot guarantee that the information is not outdated, inaccurate, or incomplete. As such, the VDO disclaims any and all liability whatsoever relating to any information, apparatus, and product, and is not responsible or liable for any claim, loss, or damage resulting from its use by any user.";
  doc.setFont("Inter-Regular");
  doc.setFontSize(8);
  doc.setTextColor("#FFFFFF");
  doc.text(disclaimer, 80, 705, {
    lineHeightFactor: 1.375,
    maxWidth: 460,
    align: "justify",
  });

  // Alert Title
  let y = 110;
  doc.setFont("MarkPro-Medium");
  doc.setTextColor("#000000");
  doc.setFontSize(16);
  const titleArr = doc.splitTextToSize(title, widthMax);
  doc.text(titleArr, 96, y, { lineHeightFactor: 1.5 });

  // Coordinate calculations
  let titleDimensions = calculateTitleHeight(title, widthMax, 16);

  let titleHeight = titleDimensions.titleHeight;
  let linkYCoordinate = titleDimensions.linkYCoordinate + y;

  const linkXCoordinate =
    doc.getTextWidth(titleArr[titleArr.length - 1]) + 96 + 5;

  // Grabbing link icon to send users to web version of alert
  const linkIcon = await getBase64("./data/link-icon.txt");
  doc.addImage(linkIcon, linkXCoordinate, linkYCoordinate, 12, 12);

  const alertLink = `https://dashboard.thevdo.org/?alert=${slug}`;
  doc.link(linkXCoordinate, linkYCoordinate, 12, 12, { url: alertLink });

  // Risk Level Bar
  y += titleHeight + 20;
  doc.setFillColor(riskLevelColor());
  doc.rect(80, 94, 4, 569, "F");

  // First row of labels
  doc.setFont("Mark-Pro-Bold");
  doc.setFontSize(11);
  doc.setTextColor("#00403F");
  doc.text("RISK LEVEL", 96, y, { lineHeightFactor: 2, charSpace: 0.75 });
  doc.text("DATE", 209, y, { lineHeightFactor: 2, charSpace: 0.75 });
  doc.text("LOCATIONS", 320, y, { lineHeightFactor: 2, charSpace: 0.75 });
  doc.text("VACCINES", 460, y, { lineHeightFactor: 2, charSpace: 0.75 });

  // Data for first row of labels
  y += 13;
  doc.setFillColor(riskLevelColor());
  doc.circle(100, y, 5, "F");

  y += 4;
  doc.setFont("MarkPro-Medium");
  doc.setFontSize(12);
  doc.setTextColor("#000000");
  doc.text(risk, 110, y);
  doc.text(date, 209, y);

  // Inputting locations and vaccine data. Also offsetting in case the sections overflow
  doc.text(locations ? locations : "N/A", 320, y, { maxWidth: 120 });
  const locationsHeight =
    Math.ceil(doc.getTextWidth(locations) / 120) * 1.15 * 12;

  doc.text(vaccines ? vaccines : "N/A", 460, y, { maxWidth: 140 });
  const vaccinesHeight =
    Math.ceil(doc.getTextWidth(vaccines) / 140) * 1.15 * 12;

  const offset =
    locationsHeight > vaccinesHeight ? locationsHeight : vaccinesHeight;

  // Topics label and data
  y += 25 + (offset > 41.4 ? offset : 0);
  doc.setFont("Mark-Pro-Bold");
  doc.setFontSize(11);
  doc.setTextColor("#00403F");
  doc.text("TOPICS", 96, y, { charSpace: 0.75 });

  y += 17;
  doc.setFont("MarkPro-Medium");
  doc.setFontSize(12);
  doc.setTextColor("#000000");
  doc.text(topics, 96, y, { maxWidth: widthMax });

  // Background label and data
  y += 24;
  doc.setFont("Mark-Pro-Bold");
  doc.setFontSize(11);
  doc.setTextColor("#00403F");
  doc.text("BACKGROUND", 96, y, { charSpace: 0.75 });

  y += 16;
  doc.setFont("Inter-Regular");
  doc.setFontSize(11);
  doc.setTextColor("#000000");
  generateParagraphs(background, y);

  // Response label and data
  y += 24;
  checkNewPage(
    calculateTextHeight(11, doc.getTextWidth("RESPONSE"), widthMax, 1.28)
  );
  doc.setFont("Mark-Pro-Bold");
  doc.setFontSize(11);
  doc.setTextColor("#00403F");
  doc.text("RESPONSE", 96, y, { charSpace: 0.75 });

  y += 16;
  doc.setFont("Inter-Regular");
  doc.setFontSize(11);
  doc.setTextColor("#000000");
  generateParagraphs(response, y);

  // Sources label and data
  y += 24;
  checkNewPage(
    calculateTextHeight(11, doc.getTextWidth("SOURCES"), widthMax, 1.28)
  );
  doc.setFont("Mark-Pro-Bold");
  doc.setFontSize(11);
  doc.setTextColor("#00403F");
  doc.text("SOURCES", 96, y, { charSpace: 0.75 });

  y += 16;
  addSources(sources);

  // Add site link at bottom
  y += 15;
  checkNewPage(
    calculateTextHeight(11, doc.getTextWidth("SOURCES"), widthMax, 1.28)
  );
  doc.setFont("Inter-Regular");
  doc.setFontSize(8);
  doc.setTextColor("#000000");

  const siteLinkText = "For more info, please visit";
  const siteLinkTextWidth = doc.getTextWidth(siteLinkText);
  doc.text(siteLinkText, 96, y);

  const linkWidth = doc.getTextWidth("dashboard.thevdo.org.");
  doc.textWithLink("dashboard.thevdo.org.", 96 + siteLinkTextWidth + 2, y, {
    url: "https://dashboard.thevdo.org/",
  });

  const linkPosition = 96 + siteLinkTextWidth + 2;
  doc.line(linkPosition, y + 2, linkPosition + linkWidth - 3, y + 2);

  // Downloads PDF doc
  doc.save(`${title}.pdf`);
}
