<template>
  <div class="learning_view">
    <div class="learning_view_container" v-if="$route.path === '/learning'">
      <h1>
        <span>Learning Hub</span>
      </h1>

      <div class="learnings">
        <div class="group">
          <div class="items">
            <div class="learning">
              <div class="image">
                <router-link class="" to="/learning/guide">
                  <img
                    src="@/images/learning_hub/Vaccin-Misinformation-101.jpg"
                    alt="Vaccine Misinformation 101 thumbnail"
                  />
                </router-link>
              </div>
              <div class="text">
                <router-link class="" to="/learning/guide">
                  <div class="title">Vaccine Misinformation 101</div>
                  <p class="description">
                    The Vaccine Misinformation Playbook and its accompanying
                    video course are designed to give you practical knowledge on
                    how to understand and tackle the root causes of vaccine
                    misinformation.
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="group">
          <h2>Webinars</h2>
          <div class="items">
            <div
              class="learning"
              v-for="learning in types['Webinars']"
              :key="learning.slugs[0]"
            >
              <div class="image">
                <a :href="learning.data.url.url" target="_blank">
                  <img
                    :src="learning.data.thumbnail.url"
                    :alt="learning.data.thumbnail.alt"
                  />
                </a>
              </div>
              <div class="text">
                <a :href="learning.data.url.url" target="_blank">
                  <div class="title">{{ learning.data.title[0].text }}</div>
                  <p class="description">
                    {{ learning.data.description[0].text }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="group">
          <h2>Guides &amp; Reports</h2>
          <div class="items">
            <div
              class="learning"
              v-for="learning in types['Guides & Reports']"
              :key="learning.slugs[0]"
            >
              <div class="image">
                <a :href="learning.data.url.url" target="_blank">
                  <img
                    :src="learning.data.thumbnail.url"
                    :alt="learning.data.thumbnail.alt"
                  />
                </a>
              </div>
              <div class="text">
                <a :href="learning.data.url.url" target="_blank">
                  <div class="title">{{ learning.data.title[0].text }}</div>
                  <p class="description">
                    {{ learning.data.description[0].text }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <learning-guide-view />
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
import LearningGuideView from "@/views/LearningGuideView";

export default {
  name: "LearningView",
  inject: ["prismic"],
  components: {
    LearningGuideView,
  },
  data() {
    return {
      apiPageNumber: 1,
      learnings: [],
    };
  },
  methods: {
    loadLearnings() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);

      prismicClient
        .query(this.prismic.Predicates.any("document.type", ["learning"]), {
          pageSize: 100,
          page: this.apiPageNumber,
        })
        .then((response) => {
          this.learnings = this.learnings.concat(response.results);
          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadLearnings();
          }
        });
    },
  },
  computed: {
    types: function () {
      let types = groupBy(this.learnings, (learning) => {
        return learning.data.learning_type;
      });

      //console.log("learning types:", types);

      return types;
    },

    // languages: function () {
    //   let languages = groupBy(this.learnings, (learning) => {
    //     return learning.data.language_code;
    //   });
    //   return languages;
    // },
  },
  created() {
    this.loadLearnings();
  },
};
</script>

<style lang="scss" scoped>
.learning_view_container {
  position: relative;
  top: $topnav_height;
  left: 0;
  width: 100%;
  padding: 1rem 120px;
  overflow-y: scroll;

  height: calc(100vh - #{$topnav_height}); //overflow-y: scroll;
  @screen md {
    padding: 2rem 120px;
  }

  // height: calc(100vh - 122px);
  // overflow-y: scroll;

  h1 {
    position: relative;
    margin-bottom: 2rem;
    background-color: $bg-primary;

    span {
      background-color: $bg-primary;
      position: relative;
      padding-right: 0.7em;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      border-top: 1px solid $teal;
    }
  }

  .learnings {
    //overflow-y: scroll;
    //max-height: calc(100vh - 240px);

    .group {
      margin-bottom: 96px;
      border-left: 9px solid $teal;
      box-sizing: border-box;
      padding: 0 30px;

      h2,
      h3 {
        font-weight: bold;
        line-height: 1.16em;
        text-transform: capitalize;
        margin-bottom: 2rem;
      }

      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 22px;
      }

      .items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 80px;
        grid-row-gap: 4rem;

        .learning {
          display: grid;
          grid-template-columns: 160px 1fr;
          grid-column-gap: 40px;

          .image {
            a {
              border: none;
            }
            img {
              width: 100%;
              height: auto;
            }
          }

          .text {
            .title {
              font-weight: bold;
              font-size: 19px;
              line-height: 1.25em;
              text-transform: capitalize;
            }

            .description {
              font-weight: normal;
              font-size: 16px;
              line-height: 1.37em;
            }
          }
        }
      }
    }
  }

  @include breakpoint(tablet) {
    top: $topnav_height;
    //padding-top: 24px;

    h1 {
      margin-bottom: 60px;
    }

    .learnings {
      .group {
        .items {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  @include breakpoint(handheld) {
    top: $topnav_height;
    padding-left: 16px;
    padding-right: 16px;

    height: calc(100vh - #{$topnav_height});

    h1 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    .learnings {
      max-height: initial;

      .group {
        border-left-width: 3px;
        padding: 0 16px;
        margin-bottom: 42px;

        h2 {
          font-size: 22px;
        }

        h3 {
          font-size: 20px;
        }

        .items {
          grid-template-columns: 1fr;

          .learning {
            grid-template-columns: 1fr;

            .text .title {
              font-size: 18px;
              line-height: 22px;
              margin-top: 1em;
            }

            .text .description {
              font-size: 14px;
              line-height: 18px;
              margin: 0.25em 0;
            }
          }
        }
      }
    }
  }
}
</style>
