<template>
  <div
    v-if="this.activeGuide"
    class="learning_guide_view"
    :class="{ rtl: this.activeGuide.data.text_direction == 'right' }"
  >
    <div class="guide_breadcrumb">
      <router-link class="link" to="/learning">Learning Hub</router-link>
      <Icon icon="ic:baseline-arrow-forward-ios" />
      <router-link class="link" to="/learning/guide"
        >Vaccine Misinformation Guide</router-link
      >
    </div>
    <div class="guide_switcher" v-if="enableLanguages">
      <div
        v-for="guide in guides"
        :key="guide.id"
        class="switch"
        :class="{ active: guide.active }"
        @click="switchGuide(guide)"
      >
        <span>{{ guide.language }}</span>
      </div>
    </div>

    <div class="head">
      <div class="title">
        <h1>
          <span v-if="this.activeGuide">
            {{ this.activeGuide.data.title[0].text }}
          </span>
        </h1>
      </div>
      <div class="logos flex">
        <img src="@/images/VDO_logo.svg" alt="First Draft Logo" />
        <img src="@/images/learning_hub/unicef_logo.svg" alt="UNICEF Logo" />
      </div>
    </div>

    <div class="guides">
      <div
        class="guide"
        v-for="guide in guides"
        :key="guide.id"
        :class="{ active: guide.active }"
      >
        <div class="guide-text" :class="{ active: guide.active }">
          <div class="intro-content">
            <!-- <a href="#introduction">Introduction</a> -->
            <a
              class="intro-link"
              :href="'#item_' + guide.data.language_code + '_' + index"
              v-for="(item, index) in guide.data.body"
              :key="'v' + index"
            >
              <div v-if="item.slice_type == 'section'" class="section">
                {{ item.primary.section_title[0].text }}
              </div>
              <div v-if="item.slice_type == 'video'" class="video">
                {{ item.primary.video_title[0].text }}
              </div>
            </a>
          </div>

          <div class="content">
            <div class="introduction">
              <p
                v-for="(parapgraph, index) in guide.data.introduction"
                :key="'p' + index"
              >
                {{ parapgraph.text }}
              </p>
              <div class="download-container mb-5">
                <div v-if="guide.data.pdf_download.url">
                  <a
                    :href="guide.data.pdf_download.url"
                    class="download"
                    target="_blank"
                  >
                    <span class="download-icon">
                      <img src="@/images/download.svg" alt="download icon" />
                      <span>{{ guide.data.pdf_download_text }}</span>
                    </span>
                  </a>
                </div>
                <div v-else>
                  <span class="text-subtitle text-teal"
                    >* Playbook Coming Soon</span
                  >
                </div>
              </div>
              <div class="download-container">
                <div v-if="guide.data.course_slides_download.url">
                  <a
                    :href="guide.data.course_slides_download.url"
                    class="download"
                    target="_blank"
                  >
                    <span class="download-icon">
                      <img
                        src="@/images/presentation.svg"
                        alt="download icon"
                      />
                      <span>{{ guide.data.course_slides_text }}</span>
                    </span>
                  </a>
                </div>
                <div v-else>
                  <span class="text-subtitle text-teal"
                    >* Course Slides Coming Soon</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="items">
          <div
            class="item"
            v-for="(item, index) in guide.data.body"
            :key="'v' + index"
            :id="'item_' + guide.data.language_code + '_' + index"
          >
            <div class="video-section" v-if="item.slice_type == 'section'">
              <hr class="py-10" />
              <h3>{{ item.primary.section_title[0].text }}</h3>
            </div>
            <div v-if="item.slice_type == 'video'" class="video">
              <div class="title">
                {{ item.primary.video_title[0].text }}
              </div>
              <div class="player" v-if="item.primary.vimeo_id">
                <!-- <img :src="video.primary.video_poster_image.url" :alt="video.primary.video_poster_image.alt"> -->
                <vueVimeoPlayer
                  class="vimeo"
                  ref="player"
                  :video-id="item.primary.vimeo_id"
                ></vueVimeoPlayer>
              </div>
              <div v-else>
                <img src="@/images/coming-soon.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import { vueVimeoPlayer } from "vue-vimeo-player";
import { Icon } from "@iconify/vue";

export default {
  name: "LearningGuideView",
  inject: ["prismic"],
  components: { vueVimeoPlayer, Icon },
  data() {
    return {
      apiPageNumber: 1,
      guides: [],
      language: { EN: "English", FR: "French", ES: "Spanish", AR: "Arabic" },
      activeGuide: null,
      enableLanguages: true,
    };
  },
  methods: {
    loadGuides() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);

      prismicClient
        .query(this.prismic.Predicates.any("document.type", ["learning_101"]), {
          pageSize: 100,
          page: this.apiPageNumber,
        })
        .then((response) => {
          this.guides = this.guides.concat(response.results);
          //console.log("learning guides: ", this.guides);
          this.guides.forEach(
            (guide) =>
              (guide.language = this.language[guide.data.language_code])
          );
          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadGuides();
          } else {
            // this.guides.reverse();

            let guideOrder = ["EN", "FR", "ES", "AR"];

            this.guides = sortBy(this.guides, function (guide) {
              return guideOrder.indexOf(guide.data.language_code);
            });

            this.switchGuide(this.guides[0]);
          }
        });
    },
    switchGuide(guide) {
      this.guides.forEach((guide) => (guide.active = false));

      this.activeGuide = guide;
      this.activeGuide.active = true;
    },
  },
  created() {
    this.loadGuides();
  },
};
</script>

<style lang="scss" scoped>
.learning_guide_view {
  scroll-behavior: smooth;
  position: absolute;
  top: 100px;

  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  max-width: 1200px;

  box-sizing: border-box;
  // margin: 0 120px;
  padding: 0 120px;

  .guide_breadcrumb {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 6px;
    .link {
      border: none;
    }
    .router-link-exact-active {
      color: $teal;
    }
  }

  .guide_switcher {
    margin-top: 50px;

    .switch {
      @apply px-1.5;
      @screen md {
        @apply text-body px-3;
      }
      display: inline-block;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      border-right: 1px solid white;
      cursor: pointer;
      font-size: 11px;

      &.active span {
        color: $teal;
      }
    }
    .switch:last-of-type {
      border-right: none;
    }
    .switch:first-of-type {
      padding-left: 0px;
    }
  }

  .head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 80px;
    margin-top: 24px;

    h1 {
      position: relative;
      //font-size: 40px;
      //line-height: 40px;
      margin: 0;
    }

    .logos {
      text-align: right;
      img {
        height: 40px;
        margin-left: 24px;
      }
    }
  }

  .guides {
    position: relative;
  }

  .guide {
    position: absolute;
    top: 0;
    left: 0;

    display: none;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-column-gap: 60px;

    // opacity: 0;
    // pointer-events: none;

    &.active {
      // opacity: 1;
      // pointer-events: all;
      display: block;
    }
    .guide-text {
      display: none;
      // display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 60px;

      &.active {
        // opacity: 1;
        // pointer-events: all;
        display: grid;
      }

      .intro-content {
        // height: calc(100vh - 322px);
        // overflow-y: scroll;

        // position: sticky;
        // top: 0;

        .intro-link {
          border-bottom: 1px solid transparent;
          display: block;

          .section {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            display: block;
            margin-top: 24px;
            margin-bottom: 8px;
          }

          &:first-child {
            .section {
              margin-top: 0;
            }
          }

          .video {
            @apply ml-1;
            font-size: 11px;
            line-height: 1.4;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-bottom: 0.5em;
          }
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 1em 0;
    }

    .content {
      // height: calc(100vh - 322px);
      // overflow-y: scroll;
      // scroll-behavior: smooth;

      .introduction {
        margin-bottom: 80px;
      }

      .download-container {
        width: fit-content;

        .download {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          text-transform: capitalize;
          color: $teal;
          border-bottom: 1px solid transparent;

          .download-icon {
            display: flex;
            align-items: center;
            &:hover {
              img {
                filter: brightness(1.15);
              }
              span {
                color: #77d3d2;
              }
            }
            img {
              display: inline-block;
              height: 20px;
              margin-right: 0.5em;
              transition: filter 0.2s ease-in-out;
            }
            span {
              transition: color 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }
  .items {
    margin-top: 50px;
    .video-section {
      font-weight: bold;
      font-size: 23px;
      line-height: 20px;
      text-transform: capitalize;
      margin-bottom: 25px;
    }
    .vimeo {
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
    }
    .video {
      @apply mb-10;

      .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        margin-bottom: 16px;
      }

      .vimeo {
        height: 0;
        padding-bottom: 62.25%;
        position: relative;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  &.rtl {
    .head h1,
    .contents,
    .introduction,
    .video .title {
      direction: rtl;
    }

    .head h1 {
      padding-right: 30px;
    }

    .guide .content .download img {
      margin-right: 0;
      margin-left: 1em;
    }
  }

  @include breakpoint(tablet) {
    // .guide .contents, .guide .content {
    //   height: calc(100vh - 192px);
    // }

    .guide_switcher {
      margin-top: 20px;
    }

    .head {
      margin-bottom: 40px;
    }
  }

  @include breakpoint(handheld) {
    position: relative;

    top: $topnav_height;
    margin: 0;
    padding: 16px;

    height: calc(100vh - #{$topnav_height});
    overflow-y: scroll;

    .guide_switcher {
      margin-top: 16px;
    }

    .guide_breadcrumb {
      .link {
        font-size: 12px;
      }
    }

    .head {
      display: block;
      margin-bottom: 60px;

      .logos {
        margin-top: 16px;
        text-align: left;

        img {
          margin-left: 0;
          margin-right: 24px;
          height: 32px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .guide,
    .guide-text {
      &.active {
        display: block !important;
      }

      .contents {
        a .section {
          margin-top: 16px;
        }
      }

      .content {
        padding-top: 60px;
        height: auto;
      }
    }

    &.rtl {
      .head {
        h1 {
          padding: 0;
        }

        .logos {
          text-align: right;

          img {
            margin-right: 0;
            margin-left: 24px;
          }
        }
      }
    }
  }
}
</style>
