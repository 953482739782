<template>
  <div
    class="cards_container"
    :class="[{ hidden: splashView }, { alert_view: alertView }]"
  >
    <div class="cards" :class="{ active }" ref="scrollBox">
      <div class="fader"></div>
      <div
        class="week"
        :class="{ display: week.display }"
        v-for="(week, i) in weeks.slice().reverse()"
        :key="'week' + i"
        :data-key="i"
        :ref="setWeekRef"
      >
        <!-- <div  class="weeks"> -->
        <div
          class="dates flex flex-wrap items-center"
          :class="
            isDownloading &&
            savedPdf === `${week.dayStart}-${week.dayEnd} Alerts`
              ? 'justify-start'
              : 'justify-between'
          "
        >
          <span class="mr-5">{{ week.dayStart + " - " + week.dayEnd }}</span>
          <div
            v-if="
              isDownloading &&
              savedPdf === `${week.dayStart}-${week.dayEnd} Alerts`
            "
          >
            <Loader />
          </div>
          <div v-else>
            <button
              class="btn text-caption"
              @click="
                downloadAllPDFs(
                  week.misinformation_alert,
                  `${week.dayStart}-${week.dayEnd} Alerts`
                )
              "
            >
              <span>DOWNLOAD ALL ALERTS</span>
              <Icon color="#94DFDE" icon="bx:download" width="20" height="20" />
            </button>
          </div>
        </div>

        <div
          v-for="alert in week.misinformation_alert['High']"
          class="card alert active"
          :class="[{ selected: alert.selected }, { display: alert.display }]"
          :key="alert.id"
          @click="selectAlert(alert)"
        >
          <div class="meta">
            <div class="rating">High Risk</div>
            <span class="divider">|</span> {{ alert.timeAgo }}
          </div>
          <div class="title">
            {{ alert.data.title[0].text }}
          </div>

          <div class="tags">
            <div
              class="pill pill--outline pill--teal"
              v-for="(location, i) in alert.locations"
              :key="i"
            >
              <span>{{ location.replace(/-/g, " ") }}</span>
            </div>
            <div class="pill" v-for="(topic, i) in alert.topics" :key="i">
              <span>{{ topic }}</span>
            </div>
          </div>
          <div class="vaccine" v-if="alert.vaccines.length">
            <div class="" v-for="(vaccine, i) in alert.vaccines" :key="i">
              <span class="vaccine-label"
                ><span>{{ vaccine }}</span></span
              >
            </div>
          </div>
          <div class="flex items-end">
            <div class="ml-auto" v-if="isDownloading && savedPdf === alert.id">
              <Loader />
            </div>
            <div class="ml-auto" v-else>
              <button class="btn" @click.stop="downloadPDF(alert)">
                <span>DOWNLOAD</span>
                <Icon
                  color="#94DFDE"
                  icon="bx:download"
                  width="20"
                  height="20"
                />
              </button>
            </div>
          </div>
        </div>

        <div
          v-for="alert in week.misinformation_alert['Medium']"
          class="card alert passive"
          :class="[{ selected: alert.selected }, { display: alert.display }]"
          :key="alert.id"
          @click="selectAlert(alert)"
        >
          <div class="meta">
            <div class="rating">Medium Risk</div>
            <span class="divider">|</span> {{ alert.timeAgo }}
          </div>
          <div class="title">
            {{ alert.data.title[0].text }}
          </div>
          <div class="tags">
            <div
              class="pill pill--outline pill--teal"
              v-for="(location, i) in alert.locations"
              :key="i"
            >
              <span>{{ location.replace(/-/g, " ") }}</span>
            </div>
            <div class="pill" v-for="(topic, i) in alert.topics" :key="i">
              <span>{{ topic }}</span>
            </div>
          </div>
          <div class="vaccine" v-if="alert.vaccines.length">
            <div class="" v-for="(vaccine, i) in alert.vaccines" :key="i">
              <span class="vaccine-label"
                ><span>{{ vaccine }}</span></span
              >
            </div>
          </div>
          <div class="flex items-end p-0">
            <div class="ml-auto" v-if="isDownloading && savedPdf === alert.id">
              <Loader />
            </div>
            <div class="ml-auto" v-else>
              <button class="btn" @click.stop="downloadPDF(alert)">
                <span>DOWNLOAD</span>
                <Icon
                  color="#94DFDE"
                  icon="bx:download"
                  width="20"
                  height="20"
                />
              </button>
            </div>
          </div>
        </div>

        <div
          v-for="alert in week.misinformation_alert['Low']"
          class="card alert ignore"
          :class="[{ selected: alert.selected }, { display: alert.display }]"
          :key="alert.id"
          @click="selectAlert(alert)"
        >
          <div class="meta">
            <div class="rating">Low Risk</div>
            <span class="divider">|</span> {{ alert.timeAgo }}
          </div>
          <div class="title">
            {{ alert.data.title[0].text }}
          </div>
          <div class="tags">
            <div
              class="pill pill--outline pill--teal"
              v-for="(location, i) in alert.locations"
              :key="i"
            >
              <span>{{ location.replace(/-/g, " ") }}</span>
            </div>
            <div class="pill" v-for="(topic, i) in alert.topics" :key="i">
              <span>{{ topic }}</span>
            </div>
          </div>
          <div class="vaccine" v-if="alert.vaccines.length">
            <div class="" v-for="(vaccine, i) in alert.vaccines" :key="i">
              <span class="vaccine-label"
                ><span>{{ vaccine }}</span></span
              >
            </div>
          </div>
          <div class="flex items-end p-0">
            <div class="ml-auto" v-if="isDownloading && savedPdf === alert.id">
              <Loader />
            </div>
            <div class="ml-auto" v-else>
              <button class="btn" @click.stop="downloadPDF(alert)">
                <span>DOWNLOAD</span>
                <Icon
                  color="#94DFDE"
                  icon="bx:download"
                  width="20"
                  height="20"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header">
      <div class="back_button flex items-center" @click="deselectAlert">
        <img
          class="inline-block"
          src="@/images/arrow-left.svg"
          alt="Back Arrow"
        />
        <span>Back</span>
      </div>
      <!-- <div class="dates">{{ currentWeek.dayStart }} - {{ currentWeek.dayEnd }}</div> -->
    </div>
  </div>
</template>

<script>
import generatePDF from "../utilities/generatePDF";
import multiplePDFs from "../utilities/multiplePDFs";
import { RichText } from "prismic-dom";

import { Icon } from "@iconify/vue";
import Loader from "./Loader.vue";

export default {
  name: "Cards",
  props: ["weekIndex", "weeks", "totalWeeks", "splashView", "alertView"],
  components: { Icon, Loader },
  data() {
    return {
      currentWeek: {},
      fadeTimeout: null,
      fadeDelay: 800,
      active: true,
      weekEls: [],
      observer: {},
      autoScrolling: false,
      userScrolling: false,
      isDownloading: false,
      savedPdf: "",
      week: "",
    };
  },
  watch: {
    weekIndex() {
      if (!this.userScrolling) {
        this.autoScrolling = true;
        this.currentWeek = this.weeks[this.weekIndex];
        this.weekEls[this.totalWeeks - 1 - this.weekIndex].scrollIntoView(true);

        window.setTimeout(() => {
          this.autoScrolling = false;
        }, 2000);
      }
    },
  },
  emits: ["alertSelected", "alertDeselected", "indexChange"],
  methods: {
    selectAlert(alert) {
      this.$emit("alertSelected", alert);
    },
    deselectAlert() {
      this.$emit("alertDeselected");
    },
    setWeekRef(weekEl) {
      if (weekEl) {
        this.weekEls.push(weekEl);
        this.observer.observe(weekEl);
      }
    },
    intersectionCallback(entries) {
      if (!this.autoScrolling) {
        this.userScrolling = true;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("display")) {
              let index = this.totalWeeks - 1 - entry.target.dataset.key;
              this.$emit("indexChange", index);
            }
          }
        });

        window.setTimeout(() => {
          this.userScrolling = false;
        }, 2000);
      }
    },

    // Download a single alert PDF
    async downloadPDF(alert) {
      this.savedPdf = alert.id;
      this.isDownloading = true;
      const args = [
        RichText.asText(alert.data.title),
        alert.data.rating,
        alert.day.format("MMM DD YYYY"),
        alert.locations.join(", "),
        alert.vaccines.join(", "),
        alert.topics.join("   "),
        alert.data.background,
        alert.data.response,
        alert.data.sources,
        alert.slugs[0],
      ];
      await generatePDF(...args);
      this.isDownloading = false;
    },

    // Button function for downloading all alert pdfs in a week
    async downloadAllPDFs(weeklyAlerts, dates) {
      this.savedPdf = dates;
      this.isDownloading = true;
      const alerts = this.getCurrentWeekAlerts(weeklyAlerts).filter(
        (alert) => alert.display
      );
      await multiplePDFs(alerts, dates);
      this.isDownloading = false;
    },

    getCurrentWeekAlerts(alertsObject) {
      const nestedAlerts = Object.values(alertsObject);
      const allAlerts = [];
      nestedAlerts.forEach((alert) => allAlerts.push(...alert));
      return allAlerts;
    },
  },
  created() {
    this.currentWeek = this.weeks[this.weekIndex];
    this.active = true;

    let options = {
      root: this.$refs.scrollBox,
      rootMargin: "0px",
      threshold: 0,
    };

    this.observer = new IntersectionObserver(
      this.intersectionCallback,
      options
    );
  },
};
</script>

<style scoped lang="scss">
.cards_container {
  position: fixed;
  top: calc(#{$toparea_height} + #{$timeline_height});
  right: 0;
  width: $cards_width;
  height: calc(100vh - #{$timeline_height} - #{$toparea_height});
  padding: 0 0 0 30px;
  box-sizing: border-box;
  pointer-events: none;

  @screen md {
    position: absolute;
  }

  transition: opacity $viewSpeed, right $viewSpeed;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.alert_view {
    right: calc(100vw - #{$cards_width} - 40px);

    .header {
      opacity: 1;
      pointer-events: all;
    }

    .dimmer {
      opacity: 0;
      pointer-events: none;
    }
  }

  .header {
    display: block;
    position: absolute;
    top: -$timeline_height;
    left: 0;
    width: 100%;
    min-width: 280px;
    height: $timeline_height;

    opacity: 0;
    pointer-events: none;
    transition: opacity $viewSpeed;

    .back_button {
      //display: inline-block;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      cursor: pointer;

      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform $uiSpeed;

      &:hover {
        transform: translate(-4px, -50%);
      }

      img {
        height: 12px;
        margin: 0 0.5em 0 0;
      }
    }

    .dates {
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: 0.15em;

      position: absolute;
      top: 50%;
      right: 1em;

      transform: translateY(-50%);
    }
  }

  .cards {
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    // min-width: 255px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 0 3px;
    // padding-bottom: 60px;

    opacity: 0;
    transition: opacity $uiSpeed;
    pointer-events: none;
    background: $bg-primary-semi;

    &.active {
      opacity: 1;
      pointer-events: all;

      .card {
        pointer-events: all;
      }
    }

    .fader {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 60px;
      z-index: 10;
      background: linear-gradient(
        0deg,
        $bg-primary 0%,
        $bg-primary-transparent 100%
      );
    }

    .week {
      // overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: max-height $uiSpeed, opacity $uiSpeed, margin-bottom $uiSpeed;
      pointer-events: none;
      margin-bottom: 0;

      &.display {
        max-height: 1000vh;
        opacity: 1;
        pointer-events: all;
        margin-bottom: 60px;
      }
    }

    .dates {
      padding: 8px 0;
      font-size: 14px;
      text-transform: uppercase;
      border-bottom: 1px solid white;
      margin-bottom: 2em;
      position: sticky;
      top: -1px;
      z-index: 1;
      background: $bg-primary;
    }

    .btn {
      @apply flex items-center p-0 gap-x-2 font-bold;
      border: none;
      outline: none;
      color: #94dfde;
      height: 24px;
      &:hover {
        background-color: transparent;
      }
      svg {
        @apply self-start;
      }
    }

    .card {
      background: $bg-card;
      border-radius: 4px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 0;

      pointer-events: none;
      cursor: pointer;

      transition: background $uiSpeed;

      opacity: 0;
      max-height: 0;
      padding: 0;

      overflow: hidden;

      border-style: solid;
      border-width: 0;
      border-left-width: 8px;
      border-color: rgba(0, 0, 0, 0);

      transition: opacity $uiSpeed, max-height $uiSpeed $uiSpeed,
        padding $uiSpeed $uiSpeed, margin-bottom $uiSpeed $uiSpeed;

      &.display {
        opacity: 1;
        max-height: 500px;
        transition: opacity $uiSpeed $uiSpeed, max-height $uiSpeed,
          padding $uiSpeed, margin-bottom $uiSpeed, background $uiSpeed;

        padding: 16px;
        margin-bottom: 8px;
        border-width: 1px;
        border-left: 8px solid white;
      }

      &:hover,
      &.selected {
        background: $bg-card-hover;

        .pill {
          background: $bg-card;
        }
      }

      &.selected {
        position: sticky;
        top: 40px;
        bottom: 0;
        z-index: 1000;
        box-shadow: 0 0 5px 5px $bg-primary;
      }

      .tags {
        margin-top: 1rem;
      }
      .vaccine {
        margin-top: 0.5rem;
        display: inline;
      }

      .pill {
        background: $bg-pill;
        display: inline-block;
        font-size: 9px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        padding: 4px 12px;
        border-radius: 24px;
        margin: 0 0.5em 0.5em 0;
        transition: opacity $uiSpeed;
        @apply font-bold;

        &--jade {
          background: $jade;
        }

        &--teal {
          background: $teal-faded;
        }

        //&--outline {
        //  border: 0.5px solid white;
        //}
      }

      .vaccine-label {
        @apply ml-1;
        font-size: 10px;
        font-weight: normal;

        span {
          text-decoration: underline;
        }
      }
      .meta {
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-weight: bold;
        .divider {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        .rating {
          display: inline-block;

          &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: white;
            margin-right: 1em;
            transform: translateY(1px);
          }
        }
      }

      .title {
        font-family: "Inter";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        margin: 0.5rem 12px 1rem 0;
      }

      &.ignore {
        border-left-color: $ignore;

        &.selected {
          border-color: $ignore;
        }

        .meta .rating:before {
          background: $ignore;
        }
      }

      &.passive {
        border-left-color: $passive;

        &.selected {
          border-color: $passive;
        }

        .meta .rating:before {
          background: $passive;
        }
      }

      &.active {
        border-left-color: $active;

        &.selected {
          border-color: $active;
        }

        .meta .rating:before {
          background: $active;
        }
      }
    }
  }

  @include breakpoint(tablet) {
    .week {
      box-sizing: border-box;
      padding: 0 16px;
    }
  }

  @include breakpoint(handheld) {
    right: -10px;
    width: 100vw;

    .header .dates {
      display: none;
    }

    .alert .article_meta {
      white-space: normal;
      column-gap: 16px;
    }

    .week {
      padding: 6px;
    }

    &.alert_view {
      right: -20px;
      width: 100vw;
      pointer-events: none;

      .week,
      .week.display {
        pointer-events: none;
      }

      .header {
        width: calc(100% - 20px);
        background: linear-gradient(
          180deg,
          $bg-primary 50%,
          $bg-primary-transparent 100%
        );
      }

      .cards {
        opacity: 0;
        pointer-events: none;

        &.active .card {
          pointer-events: none;
        }
      }
    }
  }
}
</style>
