<script>
import { forOwn } from "lodash";

import panzoom from "panzoom";
import { Icon } from "@iconify/vue";

import ZoomInIcon from "./ZoomInIcon.vue";
import ZoomOutIcon from "./ZoomOutIcon.vue";

export default {
  name: "Choropleth",
  components: {
    Icon,
    ZoomInIcon,
    ZoomOutIcon,
  },
  props: [
    "weekIndex",
    "weeks",
    "splashView",
    "listView",
    "alertView",
    "locationsLookup",
    "lastLocation",
    "location",
    "mapView",
  ],
  data() {
    return {
      enableFeatureZoom: true,
      showReset: true,
      locationID: null,
      mapScalar: 0.005,
      mapScale: 1.0,
      mapScaleMin: 1,
      mapScaleMax: 2,
      panningMap: false,
      panTransform: null,
      panInstance: null,
      wasPanningMap: false,
      yOffset: 205,
      originRect: {},
      originVector: {
        x: 0,
        y: 0,
      },
      panVector: {
        x: 0,
        y: 0,
      },
      prevPointerPos: {
        x: 0,
        y: 0,
      },
      prevTouchDistance: 0,
    };
  },
  watch: {
    weekIndex() {
      this.updateMapLocations();
    },
    location() {
      let previousLocationElement = document.getElementById(this.locationID);
      if (previousLocationElement) {
        previousLocationElement.classList.remove("selected");
      }

      this.locationID = this.locationsLookup[this.location];

      let locationElement = document.getElementById(this.locationID);
      if (locationElement) {
        locationElement.classList.add("selected");
      }
    },
  },
  methods: {
    /* eslint-disable no-debugger */
    updateMapLocations() {
      if (this.location == "none") {
        if (this.lastLocation !== "none") {
          let tempLocationID = this.locationsLookup[this.lastLocation];
          let locationElement = document.getElementById(tempLocationID);
          if (locationElement) {
            locationElement.classList.remove("selected");
          }
        }

        let locations = this.weeks[this.weekIndex].locations;

        // if (!locations.length) {
        //   return;
        // }

        let children = document
          .getElementById("mapSVG")
          .getElementsByClassName("active");
        while (children.length) {
          children[0].classList.remove("active");
        }

        locations.forEach((location) => {
          let locationElement = document.getElementById(location);

          if (locationElement) {
            locationElement.classList.add("active");
            let locationChildren = document.getElementById(location).childNodes;

            locationChildren.forEach((child) => {
              child.classList.add("active");
            });
          }
        });
      } else {
        if (this.location !== "none") {
          let tempLocationID = this.locationsLookup[this.location];

          let locationElement = document.getElementById(tempLocationID);
          if (locationElement) {
            locationElement.classList.add("selected");
          }
        }

        let children = document
          .getElementById("mapSVG")
          .getElementsByClassName("active");
        while (children.length) {
          children[0].classList.remove("active");
        }
      }
    },
    addListeners() {
      const vm = this;
      // zoom the map
      // this.$refs.choropleth.addEventListener("wheel", this.zoomMap);
      // this.$refs.choropleth.addEventListener("touchmove", this.zoomMap);

      // pan the map
      // this.$refs.choropleth.addEventListener("pointerdown", this.panMapStart);

      // this.$refs.choropleth.addEventListener('pointermove', this.panMap);
      // this.$refs.choropleth.addEventListener("touchmove", this.panMap);
      // this.$refs.choropleth.addEventListener("mousemove", this.panMap);
      //
      // this.$refs.choropleth.addEventListener("pointerup", this.panMapEnd);
      // this.$refs.choropleth.addEventListener("pointerout", this.panMapEnd);

      if (this.enableFeatureZoom) {
        this.panInstance.on("transform", function () {
          vm.enableReset();
        });

        this.panInstance.on("panstart", function () {
          vm.$refs.mapSVG.style.cursor = "grab";
        });

        this.panInstance.on("panend", function () {
          vm.$refs.mapSVG.style.cursor = "move";
        });
      }

      // handler for click to select location with country shape, tooltip handlers
      let countryElements = document
        .getElementById("mapSVG")
        .querySelectorAll(".countryGroup");
      countryElements.forEach((countryElement) => {
        let hasData = false;
        forOwn(this.locationsLookup, (location) => {
          if (location == countryElement.id) {
            hasData = true;
          }
        });

        // only countries with associated alerts should be clickable
        if (hasData) {
          countryElement.classList.add("has_data");
          countryElement.addEventListener("click", this.selectCountry);
          countryElement.addEventListener("mousemove", this.positionTooltip);
          countryElement.addEventListener("mouseout", this.hideTooltip);
        }
      });

      // click outside of selectable country to reset location
      // let mapSVG = document.getElementById('mapSVG');
      // this.$refs.choropleth.addEventListener("click", this.selectNone);
    },
    selectCountry(e) {
      if (!this.wasPanningMap) {
        e.stopPropagation();

        // get location name and send result to parent
        let location = Object.keys(this.locationsLookup).find(
          (key) => this.locationsLookup[key] === e.currentTarget.id
        );
        if (location === undefined) {
          location = "none";
        }
        this.$emit("locationSelected", location);
      }
    },
    selectNone() {
      if (!this.wasPanningMap) {
        this.$emit("locationSelected", "none");
      }
    },
    positionTooltip(e) {
      e.stopPropagation();
      forOwn(this.locationsLookup, (location, key) => {
        if (location == e.currentTarget.id) {
          this.$refs.tooltip.innerHTML = key.replace(/-/g, " ");
        }
      });

      this.$refs.tooltip.classList.add("active");
      this.$refs.tooltip.style.left = e.x + "px";
      this.$refs.tooltip.style.top = e.y - this.yOffset - 20 + "px";
    },
    hideTooltip() {
      this.$refs.tooltip.classList.remove("active");
    },
    resetPanzoom() {
      // @todo factor to this to ensure both occur (review api methods), appears to be a bug or race condition
      this.panInstance.moveTo(this.panTransform.x, this.panTransform.y);
      this.panInstance.zoomAbs(
        this.panTransform.x,
        this.panTransform.y,
        this.panTransform.scale
      );
    },
    enableReset() {
      this.showReset =
        this.panInstance.getTransform().x !== this.panTransform.x ||
        this.panInstance.getTransform().y !== this.panTransform.y ||
        this.panInstance.getTransform().scale !== this.panTransform.scale;
    },
    zoom(val) {
      let mapWidth = document
        .querySelector(".choropleth")
        .getBoundingClientRect().width;
      let mapHeight = document
        .querySelector(".choropleth")
        .getBoundingClientRect().height;
      if (val === "in") {
        this.panInstance.zoomTo(mapWidth / 2, mapHeight / 2, 1.5);
      } else {
        this.panInstance.zoomTo(mapWidth / 2, mapHeight / 2, 0.5);
      }
    },
  },
  mounted() {
    this.updateMapLocations();
    if (this.enableFeatureZoom) {
      let map = document.querySelector("#scene");
      this.panInstance = panzoom(map, {
        maxZoom: 2,
        minZoom: 0.1,
        initialZoom: 0.3,
        bounds: true,
        zoomSpeed: 0.5,
        // boundsPadding: 0.2,
      });

      this.panInstance.setMinZoom(this.panInstance.getTransform().scale);
      let transform = this.panInstance.getTransform();
      this.panTransform = {
        x: transform.x,
        y: transform.y,
        scale: transform.scale,
      };

      this.addListeners();
    }

    let choroplethRect = this.$refs.choropleth.getBoundingClientRect();
    this.originVector.x = choroplethRect.width * 0.5;
    this.originVector.y = choroplethRect.height * 0.45;

    this.yOffset = choroplethRect.top;

    this.panVector.x = this.originVector.x;
    this.panVector.y = this.originVector.y;

    this.originRect = this.$refs.mapSVG.getBoundingClientRect();
  },
  unmounted() {
    this.resetPanzoom();
    this.panInstance.dispose();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.choropleth {
  position: fixed;
  left: 0;
  top: 210px;
  width: 70vw;
  height: calc(100vh - 210px);
  padding: 0;

  opacity: 1;
  pointer-events: all;

  transition: opacity $uiSpeed;

  overflow: hidden;
  //cursor: move;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .tooltip {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: 100px;
    min-height: 20px;
    font-size: 14px;
    line-height: 1em;
    box-sizing: border-box;
    padding: 2px 4px;
    background: black;
    border: 1px solid $darkgrey;
    border-radius: 3px;
    text-transform: capitalize;
    opacity: 0;
    pointer-events: none;
    transition: opacity $uiQuickSpeed;

    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */

    &.active {
      opacity: 1;
    }

    z-index: 1000;
  }

  #map-disclaimer {
    z-index: 1;
    position: absolute;
    bottom: 1rem;
    @apply ml-10;
    left: 0;
    color: $darkishgrey;
    //font-family: "Inter", "sans-serif";
    max-width: 320px;
    &:hover {
      color: $grey;
      cursor: default;
    }
  }

  #reset-button {
    z-index: 10;
    position: fixed;
    left: 6rem;
    bottom: 8rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 1px solid white;
    border-radius: 100px;
    &:hover {
      background-color: $teal-faded;
    }
  }

  #mapSVG {
    max-width: calc(70vw - 10px);
    width: 100%;
    height: 100%;
    position: absolute;
    will-change: transform;
    cursor: move;
    // left: 50%;
    // top: 45%;
    // transform: translate(-50%, -50%);

    // transition: transform $uiQuickSpeed;
    &:focus {
      outline: none;
      // outline-color: rgba(255, 255, 255, 0.2);
      // outline-width: 2px;
    }

    .countryGroup {
      pointer-events: none;

      &.has_data {
        pointer-events: all;
        fill: $teal-faded;
        * {
          fill: $teal-faded;
        }

        &:hover {
          cursor: pointer;
          fill: $teal-transparent;
          * {
            fill: $teal-transparent;
          }
        }

        &.selected {
          fill: $teal;
          * {
            fill: $teal;
          }
        }

        &.active {
          fill: $teal;
          * {
            fill: $teal;
          }
        }
      }
    }

    .country,
    .countryIsland {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: $bg-card;
      stroke: $darkgrey;
      stroke-width: 1;
      transition: fill $uiQuickSpeed;
      z-index: 100000;
    }

    // .countryIsland {
    //   stroke-width:.5;
    // }

    .disputed {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: magenta;
      fill-opacity: 0;
    }

    .st4 {
      fill: magenta;
      stroke: magenta;
      stroke-width: 10;
      opacity: 1;
    }

    .circle {
      fill: $bg-card;
      fill-opacity: 0;
    }

    * {
      transition: fill $uiSpeed ease-out;
    }

    // #br {
    //   .country, .countryIsland {
    //     stroke: magenta;
    //     stroke-width: 10;
    //   }
    // }
  }

  @include breakpoint(handheld) {
    // display: none;
    position: fixed;
    top: 146px;
    height: calc(60vh - 146px);
    width: 100vw;

    &.hidden {
      opacity: 1;
      pointer-events: all;
    }

    &.hiddenMobile {
      opacity: 0;
      pointer-events: none;
    }

    #mapSVG {
      max-width: none;
      width: 100vw;
      touch-action: none;
      // max-height: 100%;
    }
  }
}

#zoom {
  @apply cursor-pointer;
  position: absolute;
  bottom: 7rem;
  left: 2rem;
  z-index: 10000;
  border-radius: 8px;
  .zoom-btn {
    @apply flex justify-center items-center;
    width: 35px;
    height: 35px;
    background: #fff;
    color: black;
    font-size: 1rem;
    border-radius: 8px;
    &:hover {
      background-color: $jade;
    }
    &__top {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      @apply border-b;
    }
    &__bottom {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
</style>

<template lang="html">
  <div id="zoom">
    <div class="zoom-btn zoom-btn__top" @click.prevent="zoom('in')">
      <ZoomInIcon />
    </div>
    <div class="zoom-btn zoom-btn__bottom" @click.prevent="zoom('out')">
      <ZoomOutIcon />
    </div>
  </div>
  <div
    class="choropleth"
    :class="{
      hidden: splashView || listView || alertView,
      hiddenMobile: splashView || alertView,
    }"
    ref="choropleth"
  >
    <div class="tooltip" ref="tooltip">Tooltip</div>

    <button
      type="button"
      class="flex items-center justify-center"
      id="reset-button"
      @click.stop="resetPanzoom()"
      v-if="showReset"
    >
      <Icon icon="carbon:center-circle" class="mr-2" />
      Re-center
    </button>

    <div id="map-disclaimer" class="text-caption">
      The boundaries and names shown and the designations used on this map do
      not imply official endorsement or acceptance by the United Nations.
    </div>

    <svg
      id="mapSVG"
      ref="mapSVG"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 2754 1200"
      style="enable-background: new 0 0 2754 1200"
      xml:space="preserve"
    >
      <g id="scene">
        <path
          id="sd"
          class="countryGroup country"
          d="M1489.2,614.5c0.3-0.3,0.4-0.9,0.6-1.2c0.4-0.4,1.1-0.8,1.5-1.2c0.7-0.7,0-2.1,0.7-2.8
    	c0.5-0.5,2,0.2,2.6-0.3c0.4-0.4,2.7-1.1,3.1-0.7c0.5,0.5,0.4,2.2,0.7,2.4c0.2,0.2,1,0.1,1.2,0.3c0.3,0.3,0.3,1,0.6,1.4
    	c0.1,0.3,0.4,0.6,0.6,0.7c0.5,0.5,0.2,1.9,0.7,2.3c0.3,0.3,1.2,0.7,1.6,0.8c0.9,0.3,4.4-0.9,4.9-1.4c0.1-0.1,0.7,0.1,0.8,0.1
    	c1.4,0.3,3.5,0.3,4.5,0.1c0.5,0.7,0.8,1.8,1.4,2.4c0.6,0.6,4.6,0.2,5.4-0.2c0.3-0.1,0.1-0.8,0.2-1c0.4-0.7,1.4-1.6,2.2-2
    	c0.9-0.5,3.1-0.1,3.6-0.6c0.4-0.4-0.3-2.3,0.2-2.8c0.7-0.7,1.9-1.2,2.6-1.7c2,0.5,2.8,1.9,4,3.1c0.7,0.7,1.6,1,2.2,1.6
    	c0.2,0.2,0.4-0.2,0.4-0.3c0.1-0.1,1.5,0,1.8,0c0.4,0,1.4,0.1,1.6-0.2c1.3-1.3,2.4-3.3,3.8-4.7c0.7-0.7,0.7-2.3,1.4-2.9
    	c1.2-1.2,2.8-2.2,3.3-3.7c-1.5-1.2,0.4-4.6-0.6-5.6c-0.1-0.1-2.1-1.8-2-1.9c0.7-0.7,4.3,0.4,4.8-0.6c0.3-0.6-0.1-1.4,0-1.9
    	c0.4-0.2,4,0,4,0c0,0.8-0.7,3.4-1.1,4.2c-0.4,0.8,0.8,5.4,1.1,6.4c0.1,0.5-0.3,2.1-0.1,2.3c0.3,0.4,1.2,0.5,1.7,0.8
    	c1.8,1.4,2.8,4,4.1,5.3c0.4,0.4,0,2.8-0.8,2.2c0.2,4.3-0.3,1.4,2.3,2.3c0.1-0.6,0.2-1.3,0.4-1.9l0,0c0.5-1.5,1.2-2.5,1.1-4.1
    	c-0.1-1.8-0.2-2.9,0.9-4.4c0.9-1.1,2.3-0.2,2.3,1c0.6-0.7,0.9-1.2,1.4-2.2c1.2-2.5-0.6-2.4,0-3.1c1.4-1.6,0.3-4.4,1.8-5.4
    	c1.4-0.9,2-4.1,2.9-5.6c0.9-1.5,2.1-0.8,3.6-1c0.9-0.1,0.8-3,0.8-3.7c0-1.9,1.2-3.3,1.5-5.1c0.6-2.6,0.1-5.5,0-7.3
    	c-0.1-2-0.7-4.3,0-5.7c1-1.9,1.3-4.4,2.1-6.7c0.3-0.9,0.8-1.9,1-2.8c0.2-1-0.3-1.8-0.1-2.8c0.2-1,0-3.1,1-3.7
    	c0.7-0.4,2.6,0.4,2.8-0.6c0.5-1.8,0.2-2,2.2-2.7c1.1-0.4,3.6-1.3,4.2-2.3c0.5-0.7,1.6-2.7,1.9-3c-0.2-1.3-2.4-1.2-2.5-2.4
    	c-0.9,0.8-3.6-3.5-5-3.8c-2.4-0.4-3.2-5.1-3.2-7.2c-0.2-2.9-1-5.5-0.7-8.4c0.2-1.3-0.3-2.7-0.8-3.9c-0.7-1.6,0.7-2.3,1.1-0.7
    	c-0.1-2.1-2.5-3.3-3-5.3c-0.7-2.3,0.3-3.2-1.8-4.7c-1.6-1.1-2.6-3.2-4.3-4.1c-1.9-1-3.4-1.7-3.9-4c-0.7,0.7-2.5,3.4-3.5,2.8
    	c-1.4-0.7-1.9,1.1-2.2,2c-0.6,1.7-1.1,2.6-2.9,2.9c-1.8,0.3-2.2,0.7-2.6,2.5c-0.8,3.9-4.7,0.9-6.5-0.1c-1.6-0.9-4.4-0.2-6.2-0.1
    	c-2.3,0.1-4.6,0.2-6.9-0.2c0.8-0.7,0.4-3.1-0.8-1.4c-1.1,1.8-2.5,1.4-4.4,1.4c-4.7,0-9.5,0-14.2,0c-9.7,0-19.4-0.1-29.1-0.1
    	c0.2,5.8,0.4,11.6,0.5,17.4c-2.5,0-5,0-7.5,0l0.7,37.2c-1.2-0.4-2.5-0.9-3.8-0.2c-1,0.5-2-0.1-3,0.5c-2.4,1.2,0.1,3-1.4,4.9
    	c-1.2,1.5-1.4,3.2-3.2,4.2c-1.1,0.6,0,3.3,0.7,4c-2.5,2-4.2,2.7-2.3,6.1c0.7,1.3-0.2,1.8-1.2,2.6c-1.1,0.8-1.3,1.8-1.8,3
    	c1,2.6,2.8,0.2,4.5,1.1c1,0.6-0.5,1.5,0,2.3c0.6,0.9,0.8,2.2,0.5,3.2c0.3-0.1,0.5-0.3,0.8-0.4c0.8,1.3-0.7,2.7,0,3.9
    	c0.6,1.3,2.3,1.4,2.8,2.4c0.8,1.4-0.9,2.6-0.3,3.9c0.6,1.4,1.7,2.4,2.8,3.4c1.9,1.9,3.5,4.6,3.5,7.3c0,0.5,0.1,1,0.1,1.4
    	c0,0,4.5,0,4.5,0c0.5,0.2,0.7-0.2,1.1-0.6c0.7-0.1,0.8,0.9,1.2-0.4C1488.6,615.1,1488.9,615,1489.2,614.5L1489.2,614.5z"
        />
        <path
          id="ss"
          class="countryGroup country"
          d="M1485.4,624.7c0,0.4-0.1,0.9-0.1,1.2c0,1.6,2.1,1.5,3.2,1.7c0.8,0.1,1.6,0.3,2.2,0.9
    	c0.6,0.6,0.8,1.2,1.7,1.7c0.8,0.5,1.2,0.9,1.2,1.6c0,0.7-1,1.4-0.5,2c1.6,1.7,3.5,2.6,5.6,3.7c1,0.6,0.7,1.2,1.4,2.1
    	c0.5,0.6,1.6,0.9,1.9,1.6c0.3,0.8-0.8,1.5-0.2,2.3c0.3,0.5,1.2,0.8,1.2,1.5c0,1.6,1.6,1.8,2.9,2.7c1.1,0.8,2.8,1.1,2.9,2.7
    	c0.1,1-0.2,2.2,0.5,3.1c0.5,0.6,0.8,1.8,1.5,2.2c1,0.6,1.3,1,1.5,2.1c0.2,1.2,1.8,1.4,2.4,2.1c0.3,0.4,1.1,0.7,1.5,0.9
    	c1.5,1,1.9,0.3,3.3-0.6c2.2-1.4,2.5,0.1,4.4,0.3c1.9,0.2,1.3-3.7,3.6-1.7c0.8,0.6,0.9,1.5,1.4,2.2c0.5,0.7,0.9,1.5,1.5,2.1
    	c1.2,1.4,4.9,1.9,3.7,4.3c1-0.2,2.2-0.2,2.3,1.1c0.4-1.1,1.8-2.8,3.2-2.4c0.9,0.2,1.2,1.3,2.2,0.9c0.6-0.3,1.6-1.4,2.1-0.6
    	c0.7,1,1.3,1.5,2.6,2c-0.9-2,3.6-1.8,5.5-2.8c1.1-0.6,2.3,0.8,3.8,0.8c0.5,0,2.3-1.3,2.5-1.6c1.4-1.7,3.3-5.2,5.5-5.6
    	c3.4-0.5,7.6-0.2,11.1-0.4c-0.6-0.8-1.3-1.4-1.3-2.5c0-0.9,0.6-1.9,0.2-2.8c-0.3-0.8,0-0.5-0.9-1.2c-0.9-0.8-1.9-0.9-2.7,0.1
    	c-0.6-0.5-0.3-1.3-0.9-1.7c-0.6-0.3-0.7-1.1-1-1.6c-0.7-1.4-0.8-3-1.5-4.4c-0.6-1.2-0.5-3.1-1.7-4c-1.1-0.8-1.7-1.7-2.5-2.1
    	c-0.7-0.3-1.7-2.6-2.6-3.7c-0.8-1-1.5-1.9-3-2.6c-1.3-0.7-2.8-0.4-4-1.1c-1.9-1.1,0.3-1.9,0.5-3c0.2-1.4,0-2.4,1.7-2.5
    	c1.1,0,2.4,0.4,3.5,0.3c3.5-0.4,2.1-4.4,1.9-6.7c-0.1-1.2-0.1-2.4,0-3.6c-2.6-0.9-2.2,2-2.3-2.3c0.7,0.6,1.2-1.7,0.8-2.2
    	c-1.3-1.3-2.3-3.9-4.1-5.3c-0.4-0.3-1.3-0.5-1.7-0.8c-0.2-0.2,0.2-1.8,0.1-2.3c-0.2-0.9-1.4-5.6-1.1-6.4c0.4-0.8,1.1-3.5,1.1-4.2
    	c0,0-3.6-0.2-4,0c-0.1,0.5,0.3,1.3,0,1.9c-0.5,0.9-4.1-0.1-4.8,0.6c-0.1,0.1,1.9,1.8,2,1.9c1,1-0.9,4.4,0.6,5.6
    	c-0.5,1.5-2.2,2.6-3.4,3.7c-0.6,0.6-0.7,2.2-1.4,2.9c-1.4,1.4-2.5,3.4-3.8,4.7c-0.3,0.3-1.2,0.2-1.6,0.2c-0.3,0-1.6-0.1-1.8,0
    	c0,0-0.3,0.5-0.4,0.3c-0.6-0.6-1.5-0.9-2.2-1.6c-1.2-1.2-2-2.7-4-3.1c-0.7,0.4-1.9,1-2.6,1.7c-0.5,0.5,0.2,2.5-0.2,2.8
    	c-0.5,0.5-2.7,0.1-3.6,0.6c-0.8,0.4-1.9,1.3-2.3,2c-0.1,0.3,0,0.9-0.2,1c-0.8,0.4-4.8,0.8-5.3,0.2c-0.5-0.5-0.8-1.6-1.4-2.4
    	c-1.1,0.2-3.2,0.2-4.6-0.1c-0.1,0-0.7-0.2-0.8-0.1c-0.5,0.5-4,1.7-4.9,1.4c-0.3-0.1-1.3-0.6-1.6-0.8c-0.4-0.4-0.2-1.8-0.7-2.3
    	c-0.1-0.1-0.5-0.5-0.6-0.7c-0.2-0.4-0.2-1-0.6-1.4c-0.2-0.2-0.9,0-1.2-0.3c-0.2-0.2-0.2-1.9-0.7-2.4c-0.4-0.4-2.7,0.3-3.1,0.7
    	c-0.6,0.6-2.1-0.1-2.6,0.3c-0.7,0.7,0,2.1-0.7,2.8c-0.4,0.4-1.1,0.8-1.5,1.2c-0.3,0.3-0.3,0.9-0.6,1.2c-0.3,0.3-0.4,0.5-1.2,0.9
    	c-0.4,1.4-0.7,0.1-1.2,0.4c-0.2,0.3-0.6,0.6-1.1,0.6h-4.5c-0.1,1.2-0.4,1.9-0.8,2.2c-0.6,0.8-1.2,1.1-1,2.3c0.3-0.1,0.5-0.2,0.8-0.3
    	c0.2,0.8-0.2,1.5-0.5,2.2c1.3,0.2,4.3-0.1,5.3,0.9C1485.4,623.9,1485.4,624.2,1485.4,624.7L1485.4,624.7z"
        />
        <g id="ge" class="countryGroup">
          <path
            id="ge-"
            class="country"
            d="M1584.3,323.7c0-3.9,8-0.3,9.5,0.4c1.8,0.8,3.2,1.4,5.3,1c1-0.2,1.8-0.4,3,0.3
    		c0.4,0.3,1.9-0.2,2.7,0.3c1.8,1,3.3,1.7,4.1,2.2c0.3,0.2,1.2,0.4,2.2,1.5c0.7,0.7-0.3,0.3,0.6,0.6c2.2,0.9,5.4-2.8,6.8,0.1
    		c0.5-1.9,2.4-0.5,2.8-0.3c0.4,0.3,0.7,0.8,1.2,0.9c0.8,0.3,2.4-0.3,2.8,0.9c0.3,0.9-0.7,1.5,0.2,2.3c1.3,1.2,3,1.2,4.5,1.8
    		c2.4,1-1.2,1.8,0,3.2c0.7,0.9,4.1,2.2,3.8,3.3c-0.5,2.5-3.3,0-4.1,0.2c-2,0.7-3.2-1.6-5-1.9c-0.8-0.1-1.5,0-2,0.5
    		c-0.5,0.4-2,0.9-1.7,1.3c-1.2,0.1-2.5-0.2-3.7-0.1c-1.3,0.1-2.4,0.7-3.7,0.7c-1.3,0-1.8-0.2-2.9-0.3c-0.2,0-1.9,0.1-1.6-0.6
    		c0.2-0.4,0-0.8-0.4-0.7c-0.1,0-1.8-1.8-2.7-1.6c0.6-1.4-1.6-0.5-1.8,0.4c-0.2,0.9-3.1-0.1-3.9-0.3c-0.5,0.1-0.5,1-1.4,0.5
    		c-0.4-0.2-1.3-0.8-1.7-0.5c-0.1-0.1,0.1-0.3,0.3-0.8c0.6-1.6,1.3-2.5,0.2-4.1c-1.1-1.5-1.2-3.4-2.2-5c-0.8-1.3-2.4-1.1-3.3-2.3
    		c-0.8-1.1-2.7-1.3-3.9-1.5C1586.4,325.7,1586,323.9,1584.3,323.7"
          />
          <g id="xa" class="countryGroup">
            <path
              id="xa-"
              class="disputed"
              d="M1584.3,323.7c1.6,0.2,2.1,2.1,3.8,2.3c1.2,0.2,3.1,0.3,3.9,1.5c0.9,1.2,2.5,1.1,3.3,2.3
    			c0.3,0.4,0.5,0.8,0.7,1.2c0,0,0,0.1,0.2-0.1c0.3-0.3,0.2-0.2,0.4-0.6s0.2-0.4,0.4-0.6c0.3-0.2,0.7-0.3,0.8-0.6
    			c0.1-0.3,0.1-1.5,0-1.9c-0.1-0.4,0.4-1,0.8-1.2c0.5-0.3,0.4-0.9,0.3-0.9c-2.1,0.4-3.5-0.2-5.3-1
    			C1592.3,323.4,1584.4,319.8,1584.3,323.7"
            />
            <circle id="xa_" class="circle" cx="1591.4" cy="328.2" r="4" />
          </g>
          <g id="xo" class="countryGroup">
            <path
              id="xo-"
              class="disputed"
              d="M1611.2,329.3c0.7,0.7-0.3,0.3,0.6,0.6c1.2,0.5,2.7-0.4,4-0.8c0,0,0.2,0.3,0.3,0.6
    			c0,0.3,0,0.6,0.4,0.7c0.4,0.2,1.1,0.6,1.4,0.7c0.3,0.2,0.4,1.4,0.4,1.6c0,0.2,0,0.9-0.6,1c-0.6,0.2-0.9,0.5-1.1,0.5
    			c-0.2,0-0.4,0.2-0.9-0.4c-0.5-0.6-1.8-1.1-1.9-1.2c-0.1,0-0.8,0.5-1.2,0.6c-0.3,0.1-1.6-0.3-1.6-0.6c0-0.2,0-0.7-0.1-0.9
    			s-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.8,0-1.1C1610.8,329.5,1611.2,329.3,1611.2,329.3L1611.2,329.3z"
            />
            <circle id="xo_" class="circle" cx="1613.6" cy="334.8" r="4" />
          </g>
        </g>
        <path
          id="pe"
          class="countryGroup country"
          d="M679.7,737.4c1-2.1,1.5-3.5,3.1-5.2c0.7-0.8,1.2-1.7,2.1-2.4c0.6-0.5,1.9-1.1,2-2c1,0.3,2,4.3,1.2,4.9
    	c-0.6,0.4-1.3,0-1.8,0.7c-0.5,0.7-0.4,1.7,0.6,1.9c-0.8,1.1-0.6,2.6,0.7,1.1c1.2-1.3,1.8-0.4,2.9,0.6c1,0.8,2-0.4,2.8,0.8
    	c0.8,1.2,0.8,3.3,2.6,3.7c1.5,0.3,1.6,0.2,2-1.5c0.7-1.6,1-1.9,1.9-3c0-2.2,0.5-3.2,1.3-4.9c0.4-0.9,0.7-1.3,0.7-2.3
    	c0-0.6,1-2.5,1.3-0.8c0.2-0.4,0.3-0.8,0.1-1.2c-0.1-0.6,0.8-1.2,1-1.5c0.8-1,1.7-1.8,2.9-2.3c2.5-1.2,5.5-1.6,7.8-3.2
    	c4.8-3.4,8.2-8.4,9.2-14.1c0.4,0.4,0.9,0.6,1.4,0.5c-0.7-1.7,0-3.1-1-4.5c-0.4-0.6-2.3-2.3-2.2-2.9c0.8,0.1,1.8,0.4,2.5,0
    	c0.8-0.5,0.5-0.6,1.4-0.3c1.5,0.6,2.6,1.1,3.6,2.4c1.3,1.6,2.1,2.2,2.7,4.3c0.3,0.9-0.1,1.6,1.1,1.7c1.2,0.1,0.8,0.6,1.6,1.3
    	c1.5,1.4,2.7,0.6,3.2,3c0.4,1.8,0.3,2.3,2.3,2.8c2.1,0.6,0.1,3.1,1.2,4.3c0.6,0.7,1.5,0.9,2.3,0.9c0.5-0.1,1.1-0.1,1.6-0.1
    	c0.7,0,1.5,0.4,2.2,0.4c1.4,0,3.2-1.1,3.8-2.4c0.7-1.3,2.5,1.2,3.3,1.3c1.2,0.1,3.1-1.8,4-1.2c0.7,0.5,1.2,1.4,1.9,1.8
    	c0.6,0.4,2.1,0.3,2.5,0.7c1.6,1.7,1.6,2.3,0.4,4.2c-1,1.6-2.9,4.2-3.2,6c-0.3,2.2,2.2,0.5,3.1,1.3c0.8,0.7,1.3,1.6,1.9,2.4
    	c0.3,0.4,0.6,0.6,0.4,0.9c-0.1,0.7-0.7,0.9-1.1,0.2c-0.2,0.2-0.4,0.5-0.6,0.7c-1.1-1.5-3.6-2.5-5-0.7c-1.6,2-5.1,1.5-7.4,2.1
    	c-1.2,0.3-1.8,1.7-3,2c-1.3,0.4-0.9,0.9-1.7,1.7c-2,1.8-3.4,1.3-3.4,4.4c0,1.1,0.1,2-0.6,2.9c-0.6,0.9-1.3,1.8-1.1,2.9
    	c0.2,1.2,1.3,2.9-0.1,3.8c-1.1,0.8-2.2,1.2-3.1,2.2c-0.7,0.8-1,1.7-0.8,2.7c0.1,0.5,0.3,1,0.5,1.4c-0.6,0.2-1.1,0.5-1.6,1
    	c-1.6,1.7,3.2,3.3,1.2,4.5c1,0.7,1.7,1.9,1.9,3c0.1,0.7,0.6,0.9,1.1,1.3c0.6,0.5,0.6,1.3,1,1.9c0.7,1.1,2.1,1.6,2.6,2.8
    	c0.6,1.5-0.5,2.4-1.4,3.5c1.8,0.9,4.4,0.1,6.2,1.3c1.1,0.8,2.2,2.7,1.8,4.1c2.4,0.3,5.4,0.8,7.6-0.6c1.3-0.9,3.1-4.2,4.9-4.3
    	c-0.2,0.9-0.9,0.9-0.4,1.8c0.6,1-0.4,1.9-0.3,3c0.2,2.8,0.3,5.7,0.6,8.5c0.9-0.8,1.6,0.8,2.5,0.8c1.3-0.1,2.3-1.2,3.6-1.2
    	c1.3,0,2.3-0.1,2.6,1.2c0.6,2,2,3.6,3,5.4c1,1.8,2,3.6,3,5.4c0.9,1.6,1.7,2.5,0.2,3.6c-1.3,1-1.4,2.7-1,4.1c0.4,1.2,0.1,2.8,0.2,5
    	c0,0.9,1.2,2.1,1.2,3.1c0,0.1-0.8,2.4-0.9,2.4c-0.9,0.8-1.4,2.2-1.8,3.3c-0.7,2,1.2,2.2,1.3,3.8c0,1.1-0.7,1.6-0.9,2.7
    	c-1.2-0.8-3.8-3.5-4.3-1.5c-0.3,1.4,0.3,0.9,0.5,2.7c0.1,0.2-1.2-1.2-0.7-0.7c0,0.3-1.7,1.8,1.3,2.8c0.1-1.4,1.8,0.9,2.6,0.6
    	c0.5-0.1,0.2,0.5,0.1,1.6c0.9,0.3,2.5,0.7,2.7,0.1c0.1,0,0.8,0.1,1.1,0.3c-0.6,3.2-0.2,2.5,0.3,2.9c-0.3,0.7,0.1,0.5-0.3,1.1
    	c-0.8,1-1.5,2.6-2.5,3.5c-0.4,0.4-1,0.6-1.3,1.1c-0.6,0.9,0.6,0.5,0.8,1.3c0.2,1,0.6,1,0.3,1.4c-5.7,7.2-1.9,1-2.2,1.5
    	c-0.5,1.1,0.9,2.6,0.1,3.9c-0.7,1.1-2,1.5-3.2,1.5c-1.4,0-2.4-1-3.3-1.9c-1.1-1-2.3-1.5-3.5-2.3c-2.1-1.5-1.9-4.2-4.4-5.2
    	c-3-1.2-5-4.1-8.1-4.9c-1.5-0.4-3-1.4-4.3-2.2c-1.5-1-3.2-1.3-4.7-2.1c-1.5-0.8-2.8-2.1-4.4-3c-1.7-1-3.4-1.9-5.2-2.9
    	c-1.5-0.8-2.4-2.7-3.5-3.8c-1.5-1.6-3.7-2.4-4.8-4.4c-0.6-1.1-1.4-2.4-2.4-3.1c-0.9-0.6,0.3-2.8-1.3-2.5c0-0.3,0.1-0.5,0.2-0.8
    	c0.2,0.1,0.5,0.3,0.7,0.4c0.6-3.2-0.7-4.9-2.3-7.5c-1.5-2.4-2.4-4.9-4.5-6.8c-0.6-0.6-1.2-0.9-1.1-1.7c0.2-0.9-0.3-1.9-0.8-2.7
    	c-0.4-0.7-0.9-1.6-1.6-2.1c-0.6-0.5-1.7-0.8-2-1.5c0-0.4,0-0.8,0.1-1.2c-0.1-0.9-0.7-1.9-1.1-2.6c-0.8-1.5-1.8-2.8-2.6-4.2
    	c-0.8-1.3-1.2-2.9-1.8-4.4c-1.3-3.4-3.4-5.9-4.3-9.2c-1.3-4.4-5-7.1-6.8-11.5c-2-5-6-8.6-10.9-10.6c-0.4-0.2-2-0.6-2.1-1.2
    	c-0.1-0.8-0.3-2.2,0.9-2.1c2.1,0.3,1.3-1.7,0.6-2.8c-1-1.5-1.9-2.1-2-3.9c0.2-0.2,0.4-0.5,0.6-0.7c0-0.3-0.9-1.1-1.1-1.3
    	C679.5,739.3,680.1,738.7,679.7,737.4"
        />
        <path
          id="bf"
          class="countryGroup country"
          d="M1258.9,608.2c0.5-1.4,1-3,0.7-4.5c-0.3-1.6,0.6-0.7,1.2-1.9c0.4-0.8,1.2-3,0.3-3.7
    	c-0.6-0.5,0.6-1.5-1.2-2c0.8,0,1.4-0.6,2-1c1-0.6,2.5-0.1,3.6-0.8c1.3-0.8,2.9-3.9,1.7-5.3c-0.6-0.6,0.2-1.1,1-1
    	c1.1,0.1,1-0.9,0.8-1.7c-0.1-0.6-1.2-1.4-0.2-1.7c0.7-0.3,1.8-2.4,2.2-3c-0.1,1.3,2.9,2.8,4,2.9c-0.2-1.2,0.5-1,1.5-1
    	c-0.1-1.1,0.4-2.6-0.2-3.6c0.8,0.2,1.7,0.2,2.4,0.6c1.1-1.6,1-5.2,3.8-5.5c1.4-0.1,3.1,2.1,3.4-0.4c0.1-1.3,1.6-1.2,2.5-1.6
    	c1.2-0.6,2.1-1.5,3.3-2.1c1.5-0.7,4.2-4.3,6.2-2.5c0.4,0.4,1.3-0.5,2-0.3c0.9,0.3,1.7,0.6,2.5,1c1.2,0.6-1.1,3.8,0.9,4.8
    	c1.2,0.6,0.2,2.6,1.1,3.5c0.5,0.5,1.5,1.5,1.3,2.2c0.9,0.4,3.4,2,5,3.3c-0.7,0.3-1.4,0.3-2-0.1c-1,2.5,2.3,5.4,4.3,6.3
    	c1.6,0.8,2.5-1.1,3.6-0.8c0.9,0.3,1.1,1.7,1.5,2.5c-2.5,0.9-0.4,2.3,0.5,4.1c1,1.9-1.4,4.5-3.2,4.7c-0.4,0-2.2,0.5-2.5,0.3
    	c-0.9-0.7-1.1-0.3-1.8,0.3c-1.5,1.3-2.3,3.1-4.2,3.3c-2.9,0.4-3.4-0.5-5.1-0.8c-0.8-0.2-1.6-0.4-2.5-0.4c-0.8,0-2.7,1.9-3.3,1.7
    	c-1.3-0.6,1.6-0.6-8.6-0.6c-3.4,0-5.4,0.2-8.1,0c0.3,1.6-1.2,2.4-0.8,3.3c0.6,1.3,0.9,2.3,1.1,3.6c0.2,1.1,0.3,2.6,0.2,3.9
    	c-0.1,1.1,0.8,2.5,0,3.1c-1.3-0.9-2.5-3.2-3.3-4.5c-0.7,1.1-3.6-0.2-4.8,0.2c-1.2,0.5-3.8,0.8-3.8,2.6c-0.8-0.1-3.5-1.3-4-1.9
    	c-0.4-0.5-0.9-0.8-1-1.3c-0.2-0.9-0.9-2.5-1.6-2.8C1260.4,609,1259.3,609.8,1258.9,608.2"
        />
        <path
          id="ly"
          class="countryGroup country"
          d="M1372.4,438.7c-1-0.8,1.1-1.8,1.6-2.1c1.6-0.7,2.5-1.2,3.4-2.7c0.8-1.3,1.6-1.9,1.3-3.5
    	c-0.3-1.4-0.9-2.8-0.8-4.3c0.2-2,2.6-1.5,3-2.9c0.4-1.6,1.7-2.4,3-3c1-0.5,4.2-1.4,4.3-2.7c0-0.7-0.7-1.1-0.6-1.9
    	c0.1-1.3,0.2-2.7,0.3-4c1.3,0.9,3,1.4,4.4,2.2c1.5,0.9,3.1,1.2,4.8,1c1.7-0.2,3-1.3,4.7-0.6c1.6,0.6,3.2,0.6,4.8,1.3
    	c1.4,0.6,2.5,1.6,3.8,2.2c1.2,0.5,2.8,0,3.9,0.7c1.3,0.9,1.5,2.4,1.7,3.8c0.3,1.8,1,3.2,2.4,4.4c2.5,2.2,5.7,1.7,8.7,2.2
    	c3,0.6,7.4,1.9,9.8,3.9c1.9,1.6,3.8,4.3,6.6,4c3.2-0.3,6.7-3.1,7.4-6.4c0.3-1.5-0.7-2.7-1.1-4c-0.5-1.5-0.9-3.2-0.4-4.7
    	c0.9-2.8,5.6-6.3,8.5-6.4c1.5,0,2.4-1.2,3.8-1.3c1.4-0.1,3-0.1,4.4,0.2c1.6,0.4,3.1,1.2,4.6,1.8c1.8,0.7,1.4,1,1.4,2.6
    	c0.1,1.3,2.2,1.6,3.1,1.7c1.8,0.1,3,1,4.7,1.5c1.7,0.5,3.4-0.1,5.2,0.2c1,0.2,2.4,2.1,2.1,3.1c-0.4,1.1-1.7,1.3-1.8,2.5
    	c-0.2,1.7,0.8,2.9,1.1,4.4c0.3,1.7-0.8,3.3-1.4,4.8c-0.6,1.6,1,3.5,1.3,5.2c0.5,2.8,1,5.5,1.1,8.4l1.8,75.3c-2.5,0-5,0-7.5,0
    	c0.1,1.4,0,2.9,0.1,4.3l-59.7-33.8l-13.5,6.5c-1.1,0.3-2.6-1.6-3.2-2.2c-2.8-2.8-5.8-3.4-9.6-4.2c-1.3-0.3-3.1-0.5-4.2-1.2
    	c-0.9-0.6-1.4-2.2-1.8-3.2c-1.1-2.8-2.5-3.5-5.3-4.6c-0.9-0.3-1.8-1.1-2.8-1.1c-1,0-2,1.1-2.9,0.1c-0.7-0.8-2-2.9-2-4
    	c0-2.3-0.3-3.2-1.5-5.1c-1-1.5-4.2-4.2-2.8-6.2c1.6-2.3,4.1-1.4,3-5.3c-0.5-1.9-0.9-3.4-0.3-5.3c0.6-1.7,0.9-3,0.3-4.8
    	c-0.6-1.8-0.1-3.6,0-5.5c0.2-2.2-0.1-4.4-0.9-6.4C1374.4,441.9,1373.5,440.2,1372.4,438.7"
        />
        <path
          id="by"
          class="countryGroup country"
          d="M1463.1,248.6c0.5-1.2,1.1-2.4,2.6-2.7c1.4-0.3,1.9-1.4,1.7-2.7c-0.4-3.1-3.1-5.3-3.6-8.4
    	c1.6,0.4,3.7,0.1,5.4,0.3c0.5,0.1,1.3-0.3,1.8-0.5c1-0.3,0.8-0.7,1.5-1.3c0.4-0.4,1,0,1.5-0.2c0.4-0.2,0.6-0.6,0.9-0.8
    	c0.8-0.4,2.3-0.5,1.8,0.8c0.4,0.1,1.1,0.1,1.4-0.3c0.6-0.9-1-1.2-1.5-1.4c1.6-1.8,0.4-3.7,2.7-4.9c1.1-0.6,1.2-1.7,2.6-1.6
    	c1.5,0.1,0.8-0.7,1.9-1.2c-1.6-0.4-3.2-1.1-1.6-3c1.1-1.2,3.4-1.6,4.9-1.1c1.8,0.5,1.8-1.9,3.5-2.5c0.9-0.3,2.2,0.6,2.6,0.2
    	c1.6-0.1,6.2,0.1,6.5,1.2c0.4,0.3-0.2,1.1,0.2,1.5c0.6,0.6,2.6-0.2,3.2-0.5c2.1-1,3-0.4,4.9,0.9c0.5,0.4,1.9,0.5,1.9,1.6
    	c0,1.3-0.1,1.6,0.6,2.8c0.4,0.8,0.6,1.1-0.2,1.6c-1.1,0.8,0.7,1.9,1.3,2.5c1.4,1.3,1.8,3.4,4,4.2c0.6,0.2,1.7,0.4,1.9,1.1
    	c0.4,0.9-0.7,1.8,0.2,1.8c0.4-0.1,0.9,0,1.2-0.2c0.5-0.1,1.1,0.2,1.6,0.3c1.7,0.2,0.6,0.6,1.3,1.4c0.3,0.4,1.4,0.7,1.9,1.3
    	c1,1.3-0.6,1.4-1,1.7c-0.8,0.5-1.1,0.9-2.3,1.2c-1.5,0.4-2.4-1-3.7-0.7c-3.3,0.7-1.2,2-0.1,2.9c0.7,0.6,0.6,1.7,0.8,2.5
    	c0.3,1.3,1.3,2.4,2,3.6c-1.6,0-0.9-0.4-3.8,0.5c-1.2-0.7-3.2,0.5-3.8,2.6c-0.3,0.9,0.3,1.6,0.4,3c0.2,1.6-1.8,0.2-2.2-0.3
    	c-1.2-1.4-2.4,0.2-3.8-0.6c-3.3,1.8-2.4-0.5-3.9-1c-0.9-0.5-2.4,0.4-2.3,1.5c-0.6-0.2-0.6-1.1-1.1-1.3c-0.5-0.2-1.2,0.4-1.7,0.1
    	c-0.6-0.5-1.3-0.4-2-0.3c-1,0.1-1.5-0.6-1.6,0.6c-0.6-0.2-1.1-0.7-1.7-0.8c-0.7-0.1-1.5,0.6-2.1-0.2c-1.2-1.7-3.1-1-4.8-1.4
    	c-1.8-0.4-3.8-1.2-5.6-0.9c-2.4,0.1-4.3,0.2-6.3,0.2c-2.2,0-2.1,0.9-3.6,2.1c-0.8,0.6-1.5,0.1-2.3,0c-1.1-0.2-0.9,0.5-1.2,1.1
    	c-0.8,1.1,0.1-3.4,0.1-3.7C1466.3,248.9,1463.5,250.3,1463.1,248.6"
        />
        <g id="pk" class="countryGroup">
          <path
            id="pk-"
            class="country"
            d="M1750.3,440.2c3.2,0.7,6.1,2.3,9.2,3.2c3.7,1.1,6.7,0,10.4-0.1c1.3,0,2.4,0.5,3.7,0.7
    		c1.3,0.2,2.2-0.7,3.4-1.2c1.5-0.5,2.9,0.1,4.4,0c1.3-0.1,2.5-0.7,3.7-1c1.4-0.3,4.2-0.6,5.1-1.9c0.5-0.8-0.8-1.2-0.6-2
    		c0.4-1.9-0.5-3.4-0.3-5.3c0.2-1.9,0.6-1.4,1.7-2.5c0.7-0.7,0.8-1.8,1.8-2.3c0.9-0.5,1.2,0.5,2.2,0.6c1.6,0.2,2.9-0.3,4.3-1
    		c-0.6-0.6-1.6-0.6-1.8-1.5c1.3,0,2.7-0.9,3.4-1.9c0.8-1.3,2.9,0.9,3-0.7c1,0.5,1.4,0.2,2,1.3c0.5,0.9,1.3,0.6,2,0
    		c1.9-1.4,1.4-4,0.7-5.9c-0.5-1.2,0.5-1.4,0.7-2.4c0.2-0.8,0.2-2-0.1-2.8c-0.3-0.9,0.6-1.1,1.3-1.2c1.1-0.2,1.7,0.1,2.7-0.7
    		c1.2-1,1.2-1,0.7-2.2c-0.4-1-1-2.1-2-2.7c-1.9-1.2-2.2-2.9,0.5-2.2c1.8,0.5,6.1,1.1,6.6-1.5c0.1-0.7,0.1-1.3-0.4-1.8
    		c-0.2-0.2-1.4-0.9-1.2-1.4c0.2-0.2,0.4-0.3,0.7-0.2c-0.3-0.7,0.2-1.3,0.7-1.8c0.4-0.5,1.7-1.5,1.7-2.1c0-0.5,0.2-1,0.5-1.4
    		c0.3-0.4-0.7-0.7-0.8-1c-0.1-0.3,0-0.5,0.2-0.6c0.4-0.4-0.3-1.3-0.4-1.7c-0.2-0.5-1.1-1.8-1.4-2.2c-0.5-0.7-1.4-1.1-2-1.6
    		c-0.9-0.7,1.7-3.9,2.6-3.6c1.1,0.4,1.2-0.9,1.9-1.6c0.7-0.7,1.7-1.2,2.6-1.5c2.1-0.7,4.3-0.7,6.5-0.7c1.1,0,2.6-0.6,3.6-0.1
    		c1,0.5,1.1,0.8,2,0.1c1.2-0.9,4.4-2.5,5.2-0.3c0.9-1.6,3.2-0.3,3.9,0.8c0.7,1,2.4,0.5,3.5,1.4c0.7,0.7,1.2,1.5,1.5,2.5
    		c0.2,0.7,0.1,1.9,0.4,2.5c0.3,0.7,1.8,1.6,2.4,2.1c0.7,0.7,1.7-0.4,2.4-0.6c0.1,3.1,7.3,4.1,9.8,3.6c-0.7,0.7-4.9,2.7-4.6,3.7
    		c0.2,0.5-1.2,1.7-0.7,1.2c0.3-0.2-0.8,0.6-1.1,1.1c-0.3,0.5-4.7,1.1-5.5,1.6c-2.2,1.5-4.1,0.3-6.7-0.2c-2.1-0.4-4.6-1.8-6.7-0.6
    		c-1.4,0.8-2.3,2.8-0.6,3.5c1.6,0.7-0.6,1.6,0.7,2c0.4,0.1,3.4,0,2.2,1.1c-1,1-2.4,1.7-0.4,2.9c1.8,1.1-0.5,2.6,0.4,3.7
    		c0.6,0.7,1.5,1,2.2,1.5c0.3,0.2,0.5,0.5,0.5,0.8c0,0.8,0.3,0.8,1.4,1c2.1,0.3,1,2.2,3,2.6c1.1,0.2,3.1,0.4,3.9,1.4
    		c1,1.1-2.1,2.2-2.7,2.5c-1.6,0.7-3.4,2.2-1.8,3.7c1.2,1.2-0.4,4.2,1.6,4.3c-0.2,0.8-1.6,1.5-2.4,2.4c-1,1.2-3.2,3.5-2.1,4.4
    		c1.9,1.6-1.7,2.5-2.5,2.9c-0.9,0.4-0.9,1.9-1,2.7c-0.2,1.4-0.6,2.8-1.2,4.1c-0.9,1.9-2.5,2.1-3.9,3.6c-0.5,0.6-0.8,1.6-0.9,2.4
    		c-0.1,1.4-1.6,2.2-2.1,3.4c-1,2.6-2.5,1.7-5,2.4c-0.8,0.2-1.8,1.1-2.7,1c-1.1-0.1-1.6-1.4-2.1-2.1c-1.2-1.7-3.1,0.9-3.6,2
    		c-0.9,1.9-2.3,3.6-3,5.5c-1,2.3,0.4,3.7,2.6,4.2c2.1,0.5,3.1,0.6,2.9,3.2c-0.1,1.5-0.4,4.7,2.1,4.8c0.7,0,1.6-0.5,2.2,0.1
    		c0.6,0.6,0.1,2.2,0.7,3c1.2,1.7,2.4,2.9,3,5c0.2,0.8,0.7,0.6-0.1,1.1c-0.6,0.4,0.4,1.9,0.9,2c-1.4,1-2.8,2.3-3.9,0.4
    		c-0.5-0.9-1.3-0.2-2,0c-0.3,0.1-0.7,0.2-1,0.3c-0.5,0.3-0.2,1-0.8,1.2c-1.9,0.8-4.1-1-6.2-0.9c-1.1,0.1-2.5,0.3-3.5-0.2
    		c0.1,0.8,0.8,3-0.7,3c-1.2,0-3.1,0-3,1.7c-0.1-1-0.2-1-0.4,0.1c-0.5-0.4-0.8-1-1.2-1.5c0.3,0.5,0.3,0.9,0.2,1.4
    		c-0.5-0.3-1-0.7-1.4-1.2c0.3,1.3-0.7,0.6-1.5,1c0-0.4,0-0.8,0-1.2c-0.9,0.6-2-0.6-1-1.2c-1.2,0.2-2.8-1.5-1.6-2.4
    		c-1.4-0.8-0.6-3.1-1.8-3.6c-0.6-0.3-3.8-0.5-3.8-1.5c0.2-2.1-0.3-2.7-1.3-4.3c-0.8-1.2-3.1-2.5-3.4-0.3c1.2-0.1,2.1-1.2,2.7,0.5
    		c-1.3-0.9-2.8-0.3-4.2,0.1c-1.4,0.4-2.8,0-4.1,0.4c-0.7,0.2-5.8,0.1-4.5,1.6c-0.6,0.2-1-0.1-1-0.7c-2,1-3.5-1.9-4.6-1.4
    		c0.2,0.2,0.3,0.5,0.5,0.7c-1,0.2-5.1-1.1-4,1.3c-1-0.5-8.8-1.1-8.1,0.9c-0.5,0-0.9-0.8-1.3-1c-0.7-0.4-0.7,0.6-1.2,0.8
    		c-1.8,1.1-3.1-0.1-3.1-1.7c0.1-2.2-0.4-9.9,3.7-9c-1.2-3.2,6.2-1.5,6.5-3.2c0.2-0.8,0.4-3.7,0.1-4c-0.8-0.6-1.8-0.2-2.7-0.2
    		c-1.3-0.1-1.1-2.2-1.1-3.1c-0.1-1.7-0.6-3.8-1.1-5.4c-0.2-0.7-1.5-0.8-2.2-1.2c-1.1-0.7-2.2-1.1-3.3-1.6c-2.5-1.2-3.6-2.9-4.8-5.2
    		C1754.4,444.2,1752.2,442.2,1750.3,440.2"
          />
          <path
            id="xj"
            class="countryGroup disputed"
            d="M1837.1,410.7c-0.6-1.4-1-2.2-0.7-3.1c-0.3-1.9,0-1.6-0.8-3.2c-0.3-0.8-0.3-2.2-1-2.8
    		c-0.7-1.1-0.4-1.7-0.4-2.6c0.7-0.2,1.4-0.2,2-0.1c0.5-0.7,0-1.4,0.7-1.8c0.4-1,1.6-0.4,2.3-0.9c0.7-0.2-1.2-0.8-1-1.4
    		c0.5-0.3-0.6-1,0.5-1.2c0.3,0.2,0.9,0.2,1.3,0.5c0.4,0.3,0.7-0.3,1-0.1c0.7,0.1,1.3-0.9,2-0.4c0.5,0.5,0.9,1,0.9,1.7
    		c0.1,0.8,1.4,0.2,1.7,1c0.8,0.2,1.7,1,2.4,0.4c0.3-0.6,0.6,0.6,1.3-0.2c1.6-0.1,1.1,2.1,0.8,2.8c-0.5,0.1-0.9-0.3-1.8-0.4
    		c-0.9-0.2-1-0.1-2.1-0.4c-1-0.2-1.3-0.4-2.2-0.6c-0.9-0.1-0.9-0.3-2-0.5c-0.9,0.1-0.8,0.2-1.7,0.2c-1.5,0.5-1.3,0.5-2,1.2
    		c-0.4,1-0.8,1-0.7,2.2c1,0.6,1.6,0.3,1.8,1.3c0.2,0.6-0.6,0.7-0.4,1.5c1.4,0.3,1.6,0.3,3.1,0.6c-0.4,0.6-0.7,0.8-1.6,1.5
    		c-0.5,1.2,0.8,1.5,1.5,2.7c0.2,0.7-0.1,1.4-0.6,2.4c1.9,2,3.5,2.1,2.9,3.5c-1.6,0.4-2,0.1-3.4-0.3c-0.6-0.4-0.6-0.8-0.7-1
    		c-0.6-0.7-1.2-0.8-1.6-0.9C1837.2,411.5,1837.7,411.8,1837.1,410.7L1837.1,410.7z"
          />
        </g>
        <g id="id" class="countryGroup">
          <path
            id="Indonesia_New_Guinea"
            class="country"
            d="M2303.7,707.2c0.2-0.6,0.1-1.1-0.3-1.5c2.2-0.6,4.1-0.7,5.9-2.3
    		c1.6-1.5,3.3-2.1,5.5-1.6c2.2,0.5,3.3,2.2,5.2,3c1.9,0.8,3.9-0.3,5.7,1.1c-1.4,1-0.1,2.4,0.5,3.4c0.8,1.4-0.1,2.5-0.6,3.8
    		c-1.3,3.3,0.6,7.2,2.1,10c0.5-0.7-0.2-2.4,0.6-2.9c1.2-0.8,1,2.7,1,3c0,0.7,0.2,0.8,0.7,1.2c0.6,0.6,0.3,1.5,0.9,2.1
    		c1.1,1.2,3.4,1.9,4.6,0.6c0.9-1,1.9-1.8,2.8-2.7c1-1,1.2-2.3,2.5-3.1c1.2-0.8,0.9-3.5,2.4-3.6c1.8,0,2.7,0.2,4.3-0.7
    		c1.2-0.6,1.3-1,0.7-2.2c-0.4-0.9,2.2-2.1,2.8-2.4c1.3-0.7,2.6-1.2,4-0.5c1.9,1,4.1,1.6,5.7,2.9c1.4,1,3,1.6,4.5,2.4
    		c1.1,0.5,3.1,2.2,4.4,1.7c1.6-0.6,2.2,0.6,3.8,0.6c1.7,0,2.5,1.7,4.2,1.5l-1.6,31c-0.2,1.7-1.4,2.9-1.4,4.6c0,0.7,1,1.1,1,1.6
    		c0,1.1-0.2,2.3-0.3,3.4c-0.4,5.3-0.8,10.5-1.2,15.8c-2.2-1.1-2.6-3.6-4.2-5.1c-0.8-0.8-3.9-2.7-2.4-4.1c-0.9,0.7-2.6,0.4-3.8,0.9
    		c-1,0.4-2.7,0.5-2.2-1.1c-0.5,0.6-1.7,1.7-2.5,1.7c-1.2,0,0.2-2.1,0.3-2.4c0.4-1,1.8-3.2,0.8-3.7c-0.9-0.5-1.2-1.7-1.9-2.4
    		c1.2-1,2.7,0.8,3.7-0.8c-1.6,1.6-3.8-0.9-4.9-1.9c1.5-1.7,3.2,0.3,4.8,0.4c-1.3-0.5-2.5-1.4-3.7-2.2c1.4-0.2,0.2-0.7-0.3-1.2
    		c-0.7-0.7-1.3-1.5-1.6-2.5c-0.3-1.2-1.5-3.5,0.1-4.1c-1.6,0.6-1.7-1.1-0.2-1.4c-0.5,0-1.9,0.9-2.3,0.2c-0.5-0.8,0.5-1.8,0.1-2.6
    		c-0.5,1.8-1.8-1.1-2.4-1.4c-1-0.4-1.1-0.7-1.9-1.1c-0.6-0.3-2.1-0.7-2.4-1.3c-0.5-0.8-3.6-0.9-4.6-1.5c-1.5-0.9-3.3-1.9-5-2.5
    		c-1.6-0.5-4,0-5.4-0.7c-1.3-0.6-3-1.5-3.5-2.9c-0.5-1.3,1.6-1.1,2.3-1.2c-0.9-0.3-2-0.7-2.7,0.2c-0.8,1-1.3-0.5-2-0.8
    		c0,1.5-2.3,0.4-1.2-0.6c-1.6,0-2.2,0-2.6-1.9c-0.1,0.6-0.4,1-0.8,0.4c-0.6-0.8-0.1-1.3-0.2-2.2c-0.1-1.5,1.4-2.4,1.5-4
    		c-0.4,0.8-1.7,1.1-1.7,2.1c0,0.9,0.1,2.3-1.2,2.2c1.1,0.5,0.2,1.1-0.1,1.7c-0.2,0.2-0.5,0.5-0.7,0.7c0,0.5-0.1,1-0.1,1.4
    		c-0.4,1-2.4,2.1-3.5,1.8c-0.9-0.3-1.5-2.5-1.6-3.4c0-0.6,0.7-0.6,1.1-0.8c1-0.5-0.6-1.8-0.3-2.6c-1.2,0.7-2.8-3.3-4.5-3.3
    		c-2.7-0.1-1.2-2,0.7-2.2c2-0.2,3.5,1.8,4.9-0.5c1-1.7,4.2-2.3,3.4,0.5c0.2-0.5,0.4-1,0.5-1.5c0.3,1.4,1.9-0.3,1.8,1.2
    		c0.2-1.2,0.6-0.5,0.9,0.1c0.3-0.6,0.3-1.4-0.2-2c0.5,0.1,1,0.1,1.4-0.1c-0.4-0.5-0.4-1,0.1-1.4c-1.5,1.1-0.4-0.8-0.1-1.2
    		c-1.6,0.1-2.5,1.2-4.3,0.9c-1.1-0.2-2.6,0.9-3.5,0.5c-1.2-0.4-1.7,0.4-2.6-0.5c-0.3-0.3-1.2,0.3-1.5,0.3c-1,0.1-1.8-0.9-2.6-1.4
    		c0.2-0.2,0.4-0.5,0.6-0.7c-0.8,0.1-1.6-0.8-1.3-1.6c0.1-0.3,0.5-0.7,0.4-1c-0.1-0.8-1.5,0.5-0.6-1c-0.7,0.1-0.9,0.2-0.9-0.6
    		c-1,1.5-3.1-1.1-4-1.1c0.9,1.1-1.2,1-1.7,0.6C2300.6,710.1,2303.4,708.3,2303.7,707.2"
          />
          <path
            id="Indonesia_West_Timor"
            class="country"
            d="M2241,785.1c0.4,0.3,0.3,0.6,0.9,0.4c0.2,1.1-1.8,1.4-1.9,2.8
    		c0.9-0.3,1.7,0,2.6-0.2c1.1-0.2,2-1.4,3.1-1.5c1.9,0,2.5-0.6,3.7-2c0.8-0.9,2.1-2,2.5-3.1c0.5-1.7-0.5-0.3,1-0.8c0,0,0.2,0,0.5-0.8
    		c-0.3,0-0.4-1-0.4-1.7c1.6-2.2-0.7-2.5-0.7-2.6c-3,1.2-0.7,1.8-4.2,2.5c-0.9,0.2-0.2,2.7-0.8,3c-0.6,0.4-0.8-0.9-1.2-0.4
    		s-1.9-1.4-2.2-0.8C2243.7,780.7,2241.3,784.3,2241,785.1"
          />
          <path
            id="Indonesia_Kalimantan"
            class="country"
            d="M2132.7,691c0.9-0.6,0.3-1.3,0.5-2.1c0.3-1.1,1.9-1.2,2-2.4c-0.3,0.5-0.7,1-1.2,1.4
    		c-0.9-1.3,0.2-2.6,1-3.5c0.5-0.6,0.7-1.2,1-2c0.4-1,1.6-1.2,2.3-1.9c-0.1,0.9-1.3,2,0,2.5c0.2,0.1,0.7,2.2,1.1,2.6
    		c1.1,1.2,3.1,2.1,3.8,3.7c0.3,0.6,1.5,1.1,2.1,1.4c1.1,0.6,2.6-0.6,3.6-1c1.9-0.8,3.7,0.3,5.7,0.1c0.8-0.1,1.5-0.7,2.2-1.1
    		c1.1-0.6,0.8-2,1.6-2.8c0.8-0.9,5.1-1.8,5.8-0.4c0.6,1.3,4.5,2.6,5.8,1.8c1.1-0.7,1.3-1.7,2.8-1.2c0.7,0.2,1.3-0.3,1.9-0.6
    		c0.5-0.2,1.1,0.5,1.6,0.4c-0.3-1.4,2.7-4.4,1.9-5.3c-1.4-1.5,1.7-3.5,2.9-3.9c-0.7-0.8-2-4,0.2-4.2c2.7-0.3,0.7-2,2.2-3.4
    		c0.6-0.6-0.2-2.9-0.1-3.8c0.1-0.9,0.3-2,0.7-2.9c0.4-0.7,1.1-1,1.2-1.8c1.4,0.5,1.8,0.8,3.3,0.2c0.5-0.2,1.8,1.7,1.9-0.3
    		c1,0.9,2.8,0.7,4,0.6c1.6-0.1,2.5,1.6,4,1.4c-0.5,0.3-1,0.5-1.5,0.6c0.3,0.2,3,2.5,1.1,1.6c0.4,0.3,1.3,0.4,1.5,0.9
    		c0.2,0.4,0.2,0.9,0.7,1.1c-0.9,1.1-2.3,0.4-2.7-0.7c0.2,0.5,0.2,1,0.2,1.5c-0.8-0.5-2.1-0.4-2.9-0.1c1,0.4,1.6,1.5,2.7,1.7
    		c-0.3,0.6-0.4,1.8-1.4,1.4c0.7,0.9,1.8,1.3,2.8,1.7c-0.9,1,0.8,1.1-0.6,1.3c0.5,0,0.9,0.1,1.4,0.4c-1.4,0.4,0.4,1.2,0.9,1.9
    		c0.6,0.8,1.2,1.6,1.7,2.4c0.6,1.1-0.7,3.1-1.9,3.1c0.8,0.3,0.4,1.1,1,1.6c0.9,0.8,1.8,1.5,2.8,2.2c0.8,0.6,1.2,1.5,2.1,1.9
    		c1.2,0.4,2,1.6,2.8,2.6c0.3,0.4,1.2,0.1,0.6,1c-0.7,1.2-2,0.6-3,1.1c-1.7,0.9-3.7-1.5-5.1-2.1c0.6,0.9,1.1,2.1-0.4,2.1
    		c-1.3,0.1-1.7,2.1-2.1,3.1c-0.9,2.7-1.3,5.7-1.2,8.4c0.4-0.4,0.9-0.7,1.4-0.7c-0.2,0.6-1.1,1.3-0.4,1.6c-0.7,0.4-0.7,1,0,1.4
    		c-0.9,0.3-2,1.4-2.2,0.1c-0.3,1.9-3.8,5.8-4.2,2c-0.7,1.3,0.5,2.3-1,3.4c-0.7,0.5-0.4,1.2-0.8,1.8c-0.6,0.7-1.6,0.7-2.1,1.4
    		c2.3-0.9,1.5,2.3,0.4,3.1c0.6,0.5,1.7-0.2,2.2,0.4c0.5,0.6-0.5,2.7-0.8,3.2c-0.4-0.5-0.9-0.6-1.4-0.4c1.4,1.2-0.4,5.2-1.4,3.2
    		c-0.3,0.9,0.5,1.6,0.9,2.2c-2,0.4-1.4,3.4-3,4c-1.8,0.7-3.7,2-5.5,2.8c-0.5,0.2-4.4,2.8-4.2,1.4c0.1-0.9,0-1.9,0-2.8
    		c0.1-1.3-0.8-2.4-0.5-3.7c-1.5,1.4-2.5,0.9-1.4-1.1c-0.7,1.1-2.1,1.8-1.8-0.1c-0.2,1.1-2.9,3.5-3.8,1.9c-0.3-0.6,0.5-1,0-1.6
    		c-1-1.1-2,0.9-2.3-0.6c0.3,1.7-1.8-1.3-2-2c0,1.5-1.6,3.2-2.9,3.8c-1.3,0.7-2.5-1.2-3.8-0.3c-0.5,0.3-2.3,2.9-2.6,1.3
    		c-0.4-2.1,0.3-3.9-0.8-5.9c-0.9,2.2-1.2,1.2-2.8,1.1c-0.7,0-1.3,0.6-1.8,0.9c-1,0.6-1.7,0.1-2.7-0.1c0.3-0.4,0.7-0.7,1.1-1
    		c-0.8,0.1-1.1,1.2-2,1.1c0.4-2.2-1.8-1-2.7-0.3c-1.1-1.3-0.2-3.5-0.7-5c-0.3-1-0.1-2.1-0.3-3.2c-0.2-0.9-1.5-1.6-1.4-2.4
    		c0-0.2,0.8-1.1,0.9-1.4c0.3-1,0.5-1.8-0.1-2.7c-0.4-0.6-0.5-1.3-0.8-1.8c-0.4-0.7-1.3-1-1.3-1.9c-0.2,0.2-0.4,0.5-0.6,0.7
    		c-0.8-1.5-1.6-0.1-2.6-0.7c-0.6-0.4-0.2-1,0-1.4c0.3-0.4,1.2,0.1,1.6,0.1c-0.6-0.3-0.9-1.1-1.5-1.2c-0.5-0.1-1.1,0-1.4-0.5
    		c-0.4-0.7-0.7-3.3,0.7-2.5c-0.4-0.9-0.9-2.1,0.2-2.2c-1-1.3-2.2-2.4-2.6-4C2132.7,693.8,2132,691.6,2132.7,691"
          />
          <path
            id="path2528"
            class="countryIsland"
            d="M2324.9,732.5c-0.5,0.3-0.4,0.6,0.4,0.7C2325.2,733,2325,732.8,2324.9,732.5"
          />
          <path
            id="path2530"
            class="countryIsland"
            d="M2326.4,713.7c-0.6,0.2-0.7,1-0.5,1.5C2325.9,714.7,2326.1,714.2,2326.4,713.7"
          />
          <path
            id="path2532"
            class="countryIsland"
            d="M2327.6,716c-0.5-0.1-0.6,0.1-0.1,0.7C2327.5,716.5,2327.5,716.3,2327.6,716"
          />
          <path
            id="path2540"
            class="countryIsland"
            d="M2331.5,706.7c-0.9,0.2-0.8,2,0.2,1.7C2332.2,708.3,2331.7,707,2331.5,706.7"
          />
          <path
            id="path2542"
            class="countryIsland"
            d="M2333.2,711.3c0,0.1-0.1,0.3-0.1,0.4c0.5,0,1-0.1,1.5-0.3
    		C2334.1,711.5,2333.6,711.4,2333.2,711.3"
          />
          <path
            id="path2544"
            class="countryIsland"
            d="M2335.1,704.1c0,1.1,1.9,2.6,2.7,1.3C2338.4,704.6,2335.6,704.2,2335.1,704.1"
          />
          <path
            id="path2546"
            class="countryIsland"
            d="M2336,712.4c-0.1,0.2-0.1,0.5-0.1,0.7c1.9,0.1,3.4,1.1,5.2,1.5c0.8,0.2,1.6,0.5,2.4,0.3
    		c0.9-0.2,1.9-0.8,2.8-0.6c-0.8-1.1-2.7-1-3.9-1.2C2340.3,712.9,2338.1,712.6,2336,712.4"
          />
          <path
            id="path2548"
            class="countryIsland"
            d="M2349.3,770.7c-0.1,0.2-0.1,0.5-0.2,0.7c1.5-0.5,3.1-0.3,4.6-0.3c1.5,0,2.4-0.8,3.6-1.7
    		c0.5-0.4,3.7-4.3,3-5.1c-1.8-2.3-3-2.5-5.8-1.3C2351.6,764.3,2350.5,768.1,2349.3,770.7"
          />
          <path
            id="path2550"
            class="countryIsland"
            d="M2358.2,771.3c0.2-0.1,0.5-0.1,0.7-0.2c-0.4-0.7-0.3-2.1-1.6-1.7
    		C2355.7,770.1,2357,771.3,2358.2,771.3"
          />
          <path
            id="path2552"
            class="countryIsland"
            d="M2357.8,756.8c0,0.6,0.4,0.9,0.9,1.1C2358.6,757.3,2358.4,756.9,2357.8,756.8"
          />
          <path
            id="path2206"
            class="countryIsland"
            d="M2036.4,678c0.3,0.2,1.4,0.3,1.3-0.4c-0.1-0.4-1.3-1.1-1.6-1.4c-0.4-0.4-0.8-0.7-1.4-0.6
    		c0.3-0.8-0.8-1.4-1.4-1.7c-0.3-0.1-0.5-0.7-0.9-0.4c-0.7,0.6-0.7,1.2,0,1.8C2033.6,676.4,2035.7,676.4,2036.4,678"
          />
          <path
            id="Indonesia_Sulawesi"
            class="countryIsland"
            d="M2211.7,743.3c-0.3,1.2,0.3,2.3,0.1,3.5c1.1-0.5,1.8,1.6,3,0.7c0.9-0.6,0.9-0.8,2-0.6
    		c1,0.1,1.7-1.1,2.7-0.3c-0.1-1.7-1.4-3.1-0.8-4.9c0.8-2.1,1.1-3.5,0.9-5.8c-0.2-1.9,0.2-3.5,0.4-5.4c0.1-0.9,0-1.7,0.1-2.6
    		c0.1-1.4-0.5-1.4-0.9-2.7c-0.7-2.4,3.4-4.3,5.2-3.8c1.6,0.5,1.1,4.2,0.3,5.1c-2.4,2.7,1.7,5.7,4,7c1,0.5-1.5,4.2-0.5,5.7
    		c0.5,0.8,2.3,1.2,3.2,1.4c1.1,0.2,0.8-1.6,1-2.3c0.3-1.4,2.3-1.2,3.3-1.6c0.6-0.2,3,1,1.6-0.8c1.1,1.9,2.6-1.9,0.6-2.5
    		c-0.3,1.8-0.8-0.7-1.7-0.7c0.2-0.3,0.4-0.5,0.6-0.8c-0.8,0-1.8-1.1-2.2-1.6c-0.7-0.9,0.2-1.7-0.4-2.7c0.4,0.1,0.7,0.2,1.1,0.2
    		c-0.8-0.5-0.6-1.7,0.4-1.7c-0.2-0.4-0.4-0.7-0.8-1c-0.6-0.6-0.2-1.3-0.6-2c-0.3-0.6-1.1-0.6-1.4-1.1c-0.4-0.6-0.5-1.6-0.9-2.2
    		c-0.5-1-0.9-2-2-2.5c-0.9-0.4-1.3-2.3-2.5-1.7c0.1-0.6,0.1-1.2,0.1-1.9c1.2,1,2.2,2,3.4,0.3c1.1-1.5,2.2-1.5,3.7-2.3
    		c2.1-1.2,3.6-6,6.3-5.9c0.8,0,0.9,2.3,1.8,1.3c0.5-0.6,1.6-2,1-2.8c-0.9-1.3-4.4-0.9-5.5,0c0.6,0.1,1.1,0.3,1.5,0.7
    		c-1.4,0-5.5-0.9-6,0.9c-0.3,1.2-4,1.2-3.8-0.5c-1.2,0.8-2.5,2-3.1,3.3c-0.7,1.4-1,2.3-2.6,1.9c-1.1-0.2-2.1-0.5-2.4-1.7
    		c-0.2-0.6-0.2-1.2-0.6-1.8c-0.6-1-1.1-0.2-1.8-0.7c-2.2-1.6-2.3-6.7-1.4-8.9c0.6-1.3,1.5-2.7,2.8-3.4c1.1-0.6,3.6,0.4,4.7,0.8
    		c0.8,0.3,2.3-0.4,3-0.7c1.4-0.7,1.8,0.4,3.2,0.6c1.6,0.2,3.6-0.6,5.3-0.6c2,0,3.3,0.2,4.9,1.3c2,1.4,8.1,0.5,9.7-1.4
    		c1.1-1.3,1.5-2.7,2.8-3.9c1.4-1.3,1.4-3.5,2.9-4.6c-0.5-0.3-0.9-0.9-0.6-1.6c-0.6,0.1-1.4-0.3-1.8,0.2c-0.7,0.6-0.7,1.8-1.6,2.1
    		c-1.5,0.5-0.6,0.9-1.3,1.9c-0.4,0.5-1.4,0.3-1.7,1.1c-0.7,1.9-3.6,2.2-5.1,1.8c-1.3-0.3-2.9-0.6-4.3-0.5c-0.8,0-1.5,0.9-2.1,0.6
    		c-2-0.8-3.4-1.2-5.5-1.4c-1.1-0.1-1.7-0.4-2.9-0.3c-1.3,0.1-1.7-1.7-2.4-1.7c-0.9,0-4.1-1.6-4.3,0.2c-0.2,2.5-2.5,4.8-4.3,2.2
    		c-0.2,0.8-0.4,1.4-1.1,2c-0.8,0.7-0.7,1.9-1.5,2.6c-1.6,1.3,0.5,3.5-1.1,4.6c-0.1-0.6-0.5-1-1.1-0.8c0.4,1.5,1.6,1.2,1.1,2.9
    		c-0.4,1.6,1,3.1,0.6,4.7c-0.3-0.6-0.5-1.3-1-1.8c-0.9,2.4-2.8,3.7-2.9,6.5c-0.1,1.2,0.1,2.6,0,3.8c-0.1,1.4-1.4,2.3-1.5,3.8
    		c-0.1,2-3.4,2.9-2.9,4.6c0.3,0.9,1,0.4,0.2,1.7c-0.7,1.2,0.2,3.7,0.7,4.8c0.6-0.7,2.3-1,3.2-1c2-0.1,0.4,1.8,1.1,3
    		c1.1,1.9,0.8,3.6,0.5,5.8C2213.5,739.1,2213,741.9,2211.7,743.3"
          />
          <path
            id="path2366"
            class="countryIsland"
            d="M2231.6,742.5c-2.3,0.8-0.2,3.4-0.1,3.3C2232.1,745.2,2232,743.2,2231.6,742.5"
          />
          <path
            id="path2368"
            class="countryIsland"
            d="M2232.2,702.6c-0.4-0.7-1.4-0.8-1.4,0.2C2231.3,703,2231.8,703,2232.2,702.6"
          />
          <path
            id="path2370"
            class="countryIsland"
            d="M2233.3,701.8c-0.5-0.7-1-0.6-1.5,0C2232.3,702.2,2232.9,702.2,2233.3,701.8"
          />
          <path
            id="path2372"
            class="countryIsland"
            d="M2235.8,745.6c0.3-0.4,1-1.2,0.8-1.7c-0.5-1,0.5-1.4,0.8-2.3c0.1-0.5,0.8-3.7-0.5-3
    		c-0.6,0.3-1.5,0.8-2,1.2c-1,0.9,0.5,2-0.2,2.9c-0.3,0.4-1,1.6-0.8,2c0.4,0.8,1.7,0.5,1.6-0.4
    		C2235.5,744.9,2235.6,745.2,2235.8,745.6"
          />
          <path
            id="path2378"
            class="countryIsland"
            d="M2240.2,736.7c-1.9,0-2.5,4-2.6,5.4c-0.1,1.5-0.7,2.1-1.2,3.5c-0.7,1.7,0.4,1.2,1.3,2.2
    		c0.4-0.6,0.5-1.4,1-2c0.5-0.6,1.4-0.3,1.8-0.8c0.6-0.8-0.7-2.2-1.5-1.4c0.2-0.9,0.2-1.8,0.4-2.7c0.2-0.6,1.2-2.5,1.5-0.8
    		C2241.7,738.7,2240.2,738,2240.2,736.7"
          />
          <path
            id="path2386"
            class="countryIsland"
            d="M2242.3,712.8c0-0.5,0-1,0.1-1.5c2.8,1.4,2.1-4-0.1-0.7c-0.7-1.1,0.7-1.4-0.7-1.8
    		c-0.5-0.2-1.1-0.2-1.6,0.1c-1.1,0.7-0.8,2.4-0.5,3.5c0.8-0.4,1.3-1.2,1.6-2C2242,710.9,2241.2,712.7,2242.3,712.8"
          />
          <path
            id="path2388"
            class="countryIsland"
            d="M2240.2,733c-0.8,0.3-0.7,1.8,0.1,2.2C2241.5,735.6,2242.2,733.3,2240.2,733"
          />
          <path
            id="path2394"
            class="countryIsland"
            d="M2243,709c-0.2,0.3-0.4,0.2-0.1,0.7C2242.9,709.4,2242.9,709.2,2243,709"
          />
          <path
            id="path2396"
            class="countryIsland"
            d="M2243.3,713.2c-0.3,0.1-0.5,0.4-0.5,0.7C2243,713.7,2243.2,713.5,2243.3,713.2"
          />
          <path
            id="path2398"
            class="countryIsland"
            d="M2243.4,744c0.1,0.5,0,0.7,0.6,0.7C2243.8,744.5,2243.6,744.3,2243.4,744"
          />
          <path
            id="path2400"
            class="countryIsland"
            d="M2244.6,745.9c0,0.4,0.3,0.6,0.7,0.7C2245.1,746.3,2244.9,746.1,2244.6,745.9"
          />
          <path
            id="path2404"
            class="countryIsland"
            d="M2246.6,749.6c-0.5,0.5-0.4,0.8,0.5,0.7C2247,750.1,2246.8,749.8,2246.6,749.6"
          />
          <path
            id="path2408"
            class="countryIsland"
            d="M2251.3,712.9c-1.3,1.8-0.3,3.4,1.9,2.7c0.4-0.2,0.7-0.4,1.1-0.6c0.4,0.1,0.8,0.1,1.3,0.1
    		c0.8-0.4,2.2-0.1,2.6-1.1c-0.6,0.1-1-0.2-1.1-0.8C2256.2,714.6,2252.7,712.4,2251.3,712.9"
          />
          <path
            id="path2414"
            class="countryIsland"
            d="M2258.9,674.8c-0.7-0.2-0.8,0.1-0.2,0.7C2258.8,675.2,2258.8,675,2258.9,674.8"
          />
          <path
            id="path2416"
            class="countryIsland"
            d="M2260.4,669.6c0.4-0.9-0.8-3.8-1.9-2.5C2259.6,667.4,2259.2,669.1,2260.4,669.6"
          />
          <path
            id="path2418"
            class="countryIsland"
            d="M2258.9,714c-1,1.1,1.6,1.1,2.1,1.1c1.4,0,3.5,0.2,4.6-0.9C2263.4,714,2261,714.8,2258.9,714"
          />
          <path
            id="path2422"
            class="countryIsland"
            d="M2263,715.7c-1.6,0.3-0.1,3.9,0.6,4.4C2264,719.5,2263.3,716.2,2263,715.7"
          />
          <path
            id="path2424"
            class="countryIsland"
            d="M2268,731.7c1.1-0.5,3.4-0.9,4.1-1.9c0.9-1.3,0.4-2.2-0.9-2.5c1.1-1.2-1.2-1.9-2-2.1
    		c-1.5-0.4-2.8,0.1-4.3,0.3c-1.8,0.2-2.2,1.1-1.4,2.8C2264.3,730.1,2266.3,730.8,2268,731.7"
          />
          <path
            id="path2426"
            class="countryIsland"
            d="M2268.2,665.8c0.2-0.6,0-1.2-0.5-1.5C2267.8,664.8,2268,665.3,2268.2,665.8"
          />
          <path
            id="path2428"
            class="countryIsland"
            d="M2268.5,664.1c0.8-0.8,1-2.1,0.8-3.2c-0.1-0.6-0.6-2.2-1.1-0.9c-0.4,0.9,0.7,1.6,0.4,2.3
    		C2268.2,663,2267.6,663.5,2268.5,664.1"
          />
          <path
            id="path2432"
            class="countryIsland"
            d="M2273,700.9c-0.9-0.4-1.6,1.6-1,2.2C2273,704,2273.1,701.4,2273,700.9"
          />
          <path
            id="path2434"
            class="countryIsland"
            d="M2273,703.9c-0.9,0.5-0.4,1.8,0.6,1.5C2273.5,704.8,2273.3,704.3,2273,703.9"
          />
          <path
            id="path2438"
            class="countryIsland"
            d="M2273.7,691.2c-0.2,0.3-0.1,0.5,0.2,0.8C2273.8,691.7,2273.8,691.4,2273.7,691.2"
          />
          <path
            id="path2440"
            class="countryIsland"
            d="M2275.2,701.3c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2-0.2-0.5-0.5-0.7-0.7c-0.7,2,0.3,1.3,0.9,2.7
    		c0.1,0.3-0.8,1.6,0.2,1.4c0.5-0.1,1.1-0.4,1.3,0.3c0.2,0.8,3.1-0.5,0.7-1.2c-1-0.3-1.2-0.8-0.7-1.7
    		C2276.6,702.4,2275.5,701.6,2275.2,701.3"
          />
          <path
            id="path2442"
            class="countryIsland"
            d="M2274.2,695.4c-0.2,0.3-0.3,0.3,0,0.7C2274.2,695.8,2274.2,695.6,2274.2,695.4"
          />
          <path
            id="path2444"
            class="countryIsland"
            d="M2274.6,711c-1.1,0.9-0.5,2.4,0.8,2.6c0.6,0.1,1.1-0.4,1.6-0.4c0.6,0,1.2,0.2,1.8,0.2
    		c2.1,0.1,0-1.9-0.6-2.2C2276.9,710.6,2275.9,709.9,2274.6,711"
          />
          <path
            id="path2446"
            class="countryIsland"
            d="M2274.5,692.2c-0.2,0-0.3,0-0.5-0.1c0.1,0.3,0.3,0.6,0.5,0.8
    		C2274.5,692.7,2274.5,692.4,2274.5,692.2"
          />
          <path
            id="path2452"
            class="countryIsland"
            d="M2276.4,698.5c0.1,0.9-0.3,1.8,0.3,2.6c0.6,0.7,1.3,1.1,1.6,2.1c0.4,1,2.3,3.3,3.2,2.7
    		c-2.6-2.7-2.9-5.9-3.4-9.3c-0.2-1.5-0.2-2.7,1.7-1.8c1.6,0.8,3.9,1.3,5.6,1.4c-1.1-0.5-1.1-1.9-2-2.6c-0.9-0.7-3.4-0.6-2.3-2.4
    		c0.7-1.2,2.7-1.3,3-2.8c0.2-1-0.5-2.8,0-3.5c-1.9-0.5-3.9,1.4-4.6,2.9c1.7,0.1,0,1-0.4,1.2c-1,0.4-0.6,1.5-1.1,2.3
    		c-0.5,0.8-1.6,0-1.9-0.5c-0.6-0.9,0.6-1.5,1.2-2c1.3-0.9,2.2-3.5,1.5-5.1c-0.3-0.7-1.4-0.9-1.1-1.8c0.2-0.8,1.4-1.4,1.1-2.3
    		c-1.8,0-2.6,2.2-3,3.6c-0.4,1.5-1.6,4-1.3,5.5c0.1,0.7,0.4,1.4,0.8,2c0.2,0.3,0.4,0.5,0.7,0.6c-0.5,0.6-0.6,1.3-0.6,2.1
    		c0.1,1,0.4,1.5,1,2.3C2277,696.5,2276.6,697.7,2276.4,698.5"
          />
          <path
            id="path2454"
            class="countryIsland"
            d="M2275.4,708.8c-0.3,0.2-0.5,0.4-0.8,0.6c0.5,0.1,1,0,1.5-0.3
    		C2275.9,708.9,2275.7,708.8,2275.4,708.8"
          />
          <path
            id="path2456"
            class="countryIsland"
            d="M2275,726.8c-0.4,0.6-0.2,0.8,0.6,0.7C2275.4,727.3,2275.2,727.1,2275,726.8"
          />
          <path
            id="path2458"
            class="countryIsland"
            d="M2276.1,725.9c-0.2,0.3-0.2,0.5-0.2,0.9c0.3,0,0.5,0,0.8-0.1
    		C2276.5,726.3,2276.3,726.1,2276.1,725.9"
          />
          <path
            id="path2462"
            class="countryIsland"
            d="M2278,723.8c-0.2,0.4-0.5,0.7-0.7,1.1C2277.8,724.8,2278.1,724.4,2278,723.8"
          />
          <path
            id="path2464"
            class="countryIsland"
            d="M2293.4,728.6c1.6,0.9,3.2,1.6,4.7,2.7c1.7,1.3,1.9-0.7,1.7-2c-0.2-0.8-1.3-1.1-1.4-1.9
    		c-0.2-0.8-0.2-1.7-1-2.2c-0.7-0.4-1-1-2-0.8c-1,0.2-1.9,0-2.7-0.6c-0.8-0.6-1.8-1.1-2.8-1c-1,0-1.5,0.8-2.4,1
    		c-0.8,0.2-1.1-0.2-1.5-0.7c-0.1-0.2-2.6,0.1-2.9,0.1c-3.4-0.2-6.9,2.1-5.5,5.9c0-0.8,1.1-3.8,2.2-3.6c-0.4,1,1.5,2.6,2.3,2.8
    		c0.4,0.1,4.4-3.3,3.4-0.7c0.6-0.1,4.4,1.4,4.6,0.1C2290.4,726.6,2293.3,727.5,2293.4,728.6"
          />
          <path
            id="path2466"
            class="countryIsland"
            d="M2278.4,729.6c-0.6,0.3-1,0.8-0.9,1.5c0.9-0.1,1.4-0.8,2.2-1c-0.8,1.3,1,0.1,0.9-1.2
    		C2279.8,729,2279.2,729.8,2278.4,729.6"
          />
          <path
            id="path2468"
            class="countryIsland"
            d="M2279.6,678.7c0.2-0.3,0.4-0.2,0.1-0.7C2279.7,678.2,2279.6,678.5,2279.6,678.7"
          />
          <path
            id="path2470"
            class="countryIsland"
            d="M2280.7,681.1c2.7-0.6,3.7-2.9,2.1-5.3c-0.3,0.8-1.2,1-1.7,1.6
    		C2280.1,678.3,2280.1,680,2280.7,681.1"
          />
          <path
            id="path2474"
            class="countryIsland"
            d="M2281.6,729c-0.2,0.3-0.2,0.6-0.1,0.9c0.3-0.1,0.6-0.3,0.8-0.6
    		C2282,729.2,2281.8,729.1,2281.6,729"
          />
          <path
            id="path2478"
            class="countryIsland"
            d="M2282.6,728.8c-0.3,0.7,0.3,1.4,1.1,1C2283.6,729.2,2283.2,728.8,2282.6,728.8"
          />
          <path
            id="path2482"
            class="countryIsland"
            d="M2289.1,698.5c0.4,0.6,0.9,1.2,1.6,1.4C2290.2,699.5,2289.7,698.9,2289.1,698.5"
          />
          <path
            id="path2484"
            class="countryIsland"
            d="M2293.9,716c0.5,0.2,1,0.3,1.5,0.4c0.6,0,0.9-0.5,1.4-0.4c-0.6-0.8,0.6-1.2,0-2.4
    		c-0.7-1.3-3.5,0.8-4.7,1C2292.3,715.4,2293,716,2293.9,716"
          />
          <path
            id="path2486"
            class="countryIsland"
            d="M2293.1,708.5c-0.3,0.1-0.6,0.3-0.8,0.6C2293,709.4,2293.2,709.2,2293.1,708.5"
          />
          <path
            id="path2488"
            class="countryIsland"
            d="M2294.9,698.6c0.1,0.2,0.2,0.5,0.4,0.7C2295.4,698.7,2295.1,698.9,2294.9,698.6"
          />
          <path
            id="path2490"
            class="countryIsland"
            d="M2299.6,702.3c3,0.9,0-2-0.6-2.7c0.9-0.1,1.4,0.8,1.9,1.3c0.9,1,1.1,0.1,1.9,0.6
    		c1.6,0.8,1.7-1.5,0.6-2.1c-1.1-0.6-6.7-1.4-6.8,0.7c1.4-1,0.6,0.6-0.2,0.3c0.7,0.9,2.2,0.3,1.9,1.9c0.1-0.5,0.5-1,1-1.1
    		C2299.4,701.6,2299.5,702,2299.6,702.3"
          />
          <path
            id="path2494"
            class="countryIsland"
            d="M2297.5,705.7c-0.3,1.4,3.4,0.9,3-0.5C2299.7,706.1,2298.4,705.4,2297.5,705.7"
          />
          <path
            id="path2496"
            class="countryIsland"
            d="M2298.9,702.2c-0.4,0.2-0.8,0.5-1,0.9C2298.5,703.4,2299.9,702.6,2298.9,702.2"
          />
          <path
            id="path2500"
            class="countryIsland"
            d="M2300.9,706.3c-2.7,0.5-1.7,2.4-0.2,3.8C2301.6,711,2303.3,706.8,2300.9,706.3"
          />
          <path
            id="path2506"
            class="countryIsland"
            d="M2302.7,707.2c-0.2,0.3-0.4,0.2-0.3,0.7C2302.6,707.6,2302.6,707.4,2302.7,707.2"
          />
          <path
            id="path2508"
            class="countryIsland"
            d="M2303.2,733.8c0.1,0.4,0.1,0.6,0.6,0.7C2303.6,734.3,2303.4,734,2303.2,733.8"
          />
          <path
            id="path2510"
            class="countryIsland"
            d="M2304.1,732.9c-0.6-0.1-0.6,0.1,0.2,0.7C2304.2,733.4,2304.2,733.1,2304.1,732.9"
          />
          <path
            id="path2522"
            class="countryIsland"
            d="M2318.6,734.1c-0.3,1,1.7,1.2,2.2,1.7C2320.2,735,2319.2,734.9,2318.6,734.1"
          />
          <path
            id="path2346"
            class="countryIsland"
            d="M2208.1,768.9c-0.5,0.2-0.7,0.6-0.5,1.1C2208.2,770,2208.4,769.5,2208.1,768.9"
          />
          <path
            id="path2278"
            class="countryIsland"
            d="M2103.4,755.1c-0.3,0.1-0.6,0.3-0.8,0.6C2103.3,756.1,2103.6,755.9,2103.4,755.1"
          />
          <path
            id="Indonesia_Java"
            class="countryIsland"
            d="M2114.8,751c-0.6-0.5-3.9-1.1-4.5-0.5c-0.1-2-2,0.6-2.2,1.3c-0.3,0.7-0.3,4-1.7,2.7
    		c-0.2,1.1-0.9,1.9-1.3,3c-0.4-1-1.3-0.7-2.1-0.3c2.3,1.1,5.1-0.2,7.1,1.2c0.6,0.5,1.6,0.5,2.3,0.8c1.1,0.5-0.4,1.4-0.6,2.2
    		c-0.5,2.1,5.7,1.8,6.8,2c2.2,0.4,4,2,6.2,2.6c0.7,0.2,1.6,0.4,2.3,0.1c0.7-0.4,0.6-1,1.6-1c2.6-0.1,5.3,0.5,7.8,1.4
    		c3.1,1,6,2.7,9.1,3.4c1.3,0.3,3.2,0,4.3,0.6c1.2,0.6,2.2,0,3.5,0.2c2.7,0.3,5.7,1.9,8.1,0.2c2-1.4,4.3,1.1,6.1,2
    		c0.6,0.3,4.1,0.5,4.1,0.9c-0.1,0.7,1,1.2,1.6,0.9c0.4-0.2-0.7-1.7-0.9-1.9c-1.2-1.9,0.2-3.7,0.4-5.6c0.1-1.2-1.1-1.4-1.9-1.7
    		c-1.5-0.5-1.9,0.2-3.3,0c-1.3-0.1-3.1,0.8-4.2,0.3c-0.7-0.4-2.8-1.1-3.1-1.8c-0.3-0.7,1-3.9-0.7-2.5c0.3-1.1-0.6-2.1-0.5-3.2
    		c-1,0.5-2.3,0.2-3.3,0.3c-0.4,0-0.7-0.2-1-0.5c-0.7-0.6-1.5-0.2-2.2-0.6c-0.4-0.2-1.1-1.9-1.8-1c-0.7,0.8-2.1,0.7-2.6-0.3
    		c-1.3-2.2-2.8-2.5-3.8,0.3c-0.4,0.9-1,3-2.4,2.6c-0.8-0.3-1-0.7-1.9-0.6c-0.7,0-1.5,0.2-2.2,0.1c-1.4-0.3-2-0.2-3.4-0.2
    		c-0.8,0-2.1-1-2.8-0.6c-2.9,1.5-3.5-2.4-4.5-4c-1-1.4-0.8-0.1-1.7-0.1c-1.1,0-2-1.5-3.1-1c-1.1,0.5-1.8-0.9-2.4-1.4
    		c-1.1-0.9-1.9-0.5-3-1.2C2116.8,750.8,2115.8,752.2,2114.8,751"
          />
          <path
            id="path2296"
            class="countryIsland"
            d="M2130.1,765.1c0.1,0.8,1,0.7,1.5,0.7C2131.1,765.4,2130.6,765.2,2130.1,765.1"
          />
          <path
            id="path2308"
            class="countryIsland"
            d="M2161.8,758.2c-0.7-0.1-2.6,1.3-1.7,2.1c0.8,0.7,2.8,1.3,2.9,0.1c1.2,1.4,2.7,0.6,4.1,0.1
    		c0.6-0.2,1.2-0.4,1.8-0.2c-0.1-1,0.9-0.9,1.6-1.1c-0.2-1.4-2.2-1.1-3.2-1C2165.5,758.4,2163.6,757.7,2161.8,758.2"
          />
          <path
            id="path2310"
            class="countryIsland"
            d="M2160.5,748.1c-0.6,0.1-0.7,0.5-0.5,1C2160.6,749.1,2160.8,748.7,2160.5,748.1"
          />
          <path
            id="path2312"
            class="countryIsland"
            d="M2172.3,759.7c0.1,0.5,0,0.8,0.6,0.8C2172.7,760.2,2172.5,759.9,2172.3,759.7"
          />
          <path
            id="path2314"
            class="countryIsland"
            d="M2172.9,768.6c-0.9,1.8,1.4,2.6,2.6,3.2c0.8,0.4,1.4,0.7,1.9,1.4c0.9,1.2-0.2,0.9-0.3,1.8
    		c0,0.6,2.3-2.1,2.4-2.1c1-0.3,3.4-1.3,2.2-2.6c-0.9-0.9-2.6-2.3-4-1.5C2176.3,769.6,2174.4,769.6,2172.9,768.6"
          />
          <path
            id="path2316"
            class="countryIsland"
            d="M2173.7,760.3c0.2,0.2,0.4,0.2,0.7,0C2174.2,760.3,2173.9,760.3,2173.7,760.3"
          />
          <path
            id="path2318"
            class="countryIsland"
            d="M2179.8,757.7c-0.6,0.3-0.7,0.9-0.2,1.4c0.6-0.7,1.5-0.8,2.2-0.4
    		C2181.4,757.8,2180.5,757.8,2179.8,757.7"
          />
          <path
            id="path2320"
            class="countryIsland"
            d="M2181,774.8c0.2-0.4,0.2-0.8,0-1.2c-0.3,0.2-0.5,0.4-0.7,0.7
    		C2180.5,774.4,2180.7,774.6,2181,774.8"
          />
          <path
            id="path2322"
            class="countryIsland"
            d="M2187,769.7c-1,0.5-2.3,1.5-2.2,2.8c0.1,1.1-0.3,2.8-1.6,1.7c-1.1,1.7,4.7,1.2,4.9,1.5
    		c-0.5-1.1,1-3.1,1.6-3.9C2190.4,770.4,2188,769.3,2187,769.7"
          />
          <path
            id="path2328"
            class="countryIsland"
            d="M2198.6,775.8c1.6-0.8,3.1-1.1,4-2.7c0.3,0.6-0.4,1.7,0.1,2c0.6,0.3,1,0.1,1.6-0.1
    		c0.7-0.3,1.3,0.1,2,0.2c-0.4-0.5-0.8-0.7-1.4-0.8c1.4-0.8,3.4,0.7,3.7-1.5c-1.2,1.5-1.1-1.3-1.4-1.9c-0.7-1.5-2.2,0-2.2,1.1
    		c-0.2-1.1-1.1-2.9-2.3-1.6c-0.9,1-1.7-1-2.2-1.5c-0.7-0.8-3.6-0.2-2.7,1.1c1,1.6,2.3,2,3.9,2.8c-0.5,0.7-1.8,1-2.6,1.2
    		c-1.3,0.3-1.4-0.9-2-1.7c-1.3-1.9-5.2-1.2-6.6,0.3c-0.6,0.7,0,1.9-0.6,2.6c-0.8,1,0.6,1.8,1.4,2c0.5,0.1,0.9,0.2,1.3-0.2
    		c0.8-0.6,0.8-0.2,1.8-0.2C2195.9,776.7,2197.1,775.3,2198.6,775.8"
          />
          <path
            id="path2330"
            class="countryIsland"
            d="M2196,771.2c0.5-0.6,0.8-1.4,1.2-2C2195.8,768.5,2195.9,770.2,2196,771.2"
          />
          <path
            id="path2342"
            class="countryIsland"
            d="M2206,781.1c0.7,1.8,2.3,1.7,4,1.9c1.9,0.2,3.4,2,4.6,3.4c2.2,2.6,7.1,0.2,4.5-2.6
    		c-0.8-0.9-1.4-1.6-2.6-1.6c0,0-1.9-2.3-2.1-2.5c-0.2-0.2-3.4-0.1-3.5,0C2209.4,780.5,2207.3,779,2206,781.1"
          />
          <path
            id="path2350"
            class="countryIsland"
            d="M2210.6,771.5c-0.4,0.7-0.9,1.5-0.2,2.2C2210.4,772.7,2212.4,772.2,2210.6,771.5"
          />
          <path
            id="path2352"
            class="countryIsland"
            d="M2211.9,773c0.2,0.6,0.1,1.1-0.2,1.6c1,0.2,0.8-0.9,1-1.4C2212.4,773.1,2212.1,773,2211.9,773"
          />
          <path
            id="path2354"
            class="countryIsland"
            d="M2214.5,774.8c0.8-0.4,2.2,0,3.1,0c0.8-0.1,2.1,0.1,2.9,0.3c0.9,0.2,1.4,1.2,2.4,0.7
    		c0.7-0.3,2.4,0.3,2.1-1.1c2.2,0.9,3.2,0.3,5.4-0.5c1.4-0.5,6.5,0.2,5.7-2.7c1,0.2,0.9-1,1.8-1.1c-0.4-1.2-1.7-3-2-0.7
    		c0.4-0.3,0.9-0.3,1.4-0.1c-1,1.2-2.8,1.2-3.6,2.6c-0.9,1.7-2.4,0.6-3.4-0.3c-0.9-0.9-2,0.6-3,0.2c-0.5-0.2-0.5,0.2-0.8,0.5
    		c-0.5,0.5-1.5-0.4-1.9-0.6c-1.2-0.9-3.2-1.5-4.7-1.8c-2.3-0.5-3.1,0.3-5,1.3c-1,0.5-1.6,0.5-1.7,1.8
    		C2213.2,774,2213.5,776.1,2214.5,774.8"
          />
          <path
            id="path2356"
            class="countryIsland"
            d="M2219.9,748.6c-1.2,0.4-0.6,5-0.4,5.9C2219.8,752.6,2220.3,750.6,2219.9,748.6"
          />
          <path
            id="path2358"
            class="countryIsland"
            d="M2220.6,759.3c-0.2,0.4-0.2,0.7,0,1.1c0.3-0.1,0.5-0.3,0.7-0.6
    		C2221.1,759.6,2220.8,759.5,2220.6,759.3"
          />
          <path
            id="path2360"
            class="countryIsland"
            d="M2221.8,761.5c0.2,0.6,1.1,0.3,1.4,0.1C2222.8,761.6,2222.3,761.6,2221.8,761.5"
          />
          <path
            id="path2362"
            class="countryIsland"
            d="M2225,790.3c0.2,0.1,0.5,0.1,0.7-0.2C2225.5,790.2,2225.2,790.3,2225,790.3"
          />
          <path
            id="path2364"
            class="countryIsland"
            d="M2226.5,789.8c0,0.1-0.1,0.2-0.1,0.4c0.6,0.2,1.4,0.1,1.9-0.3c0.2-0.2,0.3-0.4,0.2-0.7
    		C2227.9,788.2,2227.1,789.5,2226.5,789.8"
          />
          <path
            id="path2374"
            class="countryIsland"
            d="M2234.9,791.7c-0.3,0.5-0.3,0.9-0.1,1.4c1.2-0.7,3.1-1.9,4.4-2.2c-0.2-0.5-0.5-0.9-0.6-1.4
    		C2237.2,790.1,2236.4,791.2,2234.9,791.7"
          />
          <path
            id="path2380"
            class="countryIsland"
            d="M2236.9,772.3c0.1,0.2,0.1,0.4,0.2,0.6c0.3-0.5,0.7-1,1.2-1.3
    		C2237.8,771.6,2237.3,771.8,2236.9,772.3"
          />
          <path
            id="path2382"
            class="countryIsland"
            d="M2238.4,770.2c-0.3,0.3-0.5,0.6-0.5,1.1c0.6-0.1,2,0.2,2.2-0.6
    		C2240.4,769.6,2238.9,770,2238.4,770.2"
          />
          <path
            id="path2384"
            class="countryIsland"
            d="M2239.7,786.4c-0.5,0.3-0.8,0.9-0.9,1.5C2239.7,788.1,2239.9,787.1,2239.7,786.4"
          />
          <path
            id="path2390"
            class="countryIsland"
            d="M2240.8,770.2c0.2,0.2,0.4,0.5,0.5,0.7c-0.6,0.2-1.5,0.6-1.6,1.3c-0.3,1.1,1.8,0.3,2.2,0.6
    		c-0.1-1.4,1.9-2.5,3-2.7c-1.4-1.5-2.1,0.5-3.1,0.9C2242.3,770,2241.6,770.1,2240.8,770.2"
          />
          <path
            id="path2402"
            class="countryIsland"
            d="M2245.2,770.7c-0.3,0.3-0.4,0.7-0.4,1.1c0.6-0.1,1.2,0.2,1.4,0.7c0.6-0.6,1.8-2.1,1-3
    		C2246.8,770.4,2246,770.5,2245.2,770.7"
          />
          <path
            id="path2406"
            class="countryIsland"
            d="M2249.1,768.9c-0.4,0.5-1,1.7,0.4,0.9c-0.8,0.3-1.4,1-1.4,1.9c1.8-0.2,3.8,0,5.5-0.8
    		c1.1-0.5,0.7-1.6-0.4-1.8c-0.6-0.1-1.1,0.2-1.8,0.1C2250.6,769.2,2249.9,768.8,2249.1,768.9"
          />
          <path
            id="path2410"
            class="countryIsland"
            d="M2258,769.1c-0.6-0.1-1,0.9-1.1,1.3C2257.5,770.1,2257.9,769.7,2258,769.1"
          />
          <path
            id="path2420"
            class="countryIsland"
            d="M2259.1,767.9c1.5-1.3,3-1,4.8-0.7c0.9,0.2,1.9-1.7,3.1-1.6c-1.6-1.9-2.4-1.2-4.6-0.4
    		c-0.6,0.2-1.1-0.3-1.6-0.1C2259.7,765.6,2259.2,767,2259.1,767.9"
          />
          <path
            id="path2430"
            class="countryIsland"
            d="M2271.9,763.5c-0.3,0.4-0.5,0.8-0.4,1.3C2272.1,764.6,2272.4,764.1,2271.9,763.5"
          />
          <path
            id="path2436"
            class="countryIsland"
            d="M2273.4,769.2c-0.2,0.8,0.1,0.9,0.7,0.2C2273.8,769.3,2273.6,769.3,2273.4,769.2"
          />
          <path
            id="path2450"
            class="countryIsland"
            d="M2274.4,768.7c-0.3,1.3,2.1,1.7,2.3,0.3C2275.9,769.1,2275.1,768.9,2274.4,768.7"
          />
          <path
            id="path2460"
            class="countryIsland"
            d="M2276.7,769.6c0,0.2,0.1,0.4,0.1,0.7c0.3-0.2,0.5-0.4,0.7-0.7
    		C2277.3,769.6,2277,769.6,2276.7,769.6"
          />
          <path
            id="path2472"
            class="countryIsland"
            d="M2281.3,759.7c-0.3,0.2-0.5,0.5-0.5,0.9C2281.6,760.7,2281.7,760.4,2281.3,759.7"
          />
          <path
            id="path2476"
            class="countryIsland"
            d="M2282.4,769.5c0.2,0.5,0.6,0.7,1.1,0.7C2283.5,769.5,2283,769.2,2282.4,769.5"
          />
          <path
            id="path2480"
            class="countryIsland"
            d="M2288.6,766.1c-0.6,0.6-0.3,1.9,0.6,1.9C2291,767.9,2290.1,765.9,2288.6,766.1"
          />
          <path
            id="path2492"
            class="countryIsland"
            d="M2296.9,770.8c1.2-0.4,2.2-1.1,3.2-1.9C2298.9,769.4,2297.7,769.3,2296.9,770.8"
          />
          <path
            id="path2498"
            class="countryIsland"
            d="M2298.2,763.5c-0.1,0.6,0.1,0.6,0.7,0.1C2298.7,763.5,2298.4,763.5,2298.2,763.5"
          />
          <path
            id="path2502"
            class="countryIsland"
            d="M2299.5,762.8c-0.1,0.2-0.2,0.4-0.4,0.6c0.6,0.2,1-0.1,1.1-0.7
    		C2300,762.7,2299.7,762.7,2299.5,762.8"
          />
          <path
            id="path2504"
            class="countryIsland"
            d="M2304.3,760.2c-1.2,0.1-4.5,4.1-3.4,5.1c-2.3-1.1-0.7,4.8,0.5,2.1c0.5-1.2,1.8-1.8,2.5-2.9
    		c0.5-0.9-0.1-3.1,1.1-3.5C2304.8,760.7,2304.6,760.4,2304.3,760.2"
          />
          <path
            id="path2512"
            class="countryIsland"
            d="M2305.1,760.2c0.4,0.6,1.2,0.5,1.7,1C2306.8,760.2,2305.9,760.1,2305.1,760.2"
          />
          <path
            id="path2516"
            class="countryIsland"
            d="M2313.4,747.7c-0.2-0.2-0.4-0.3-0.7-0.2c0.3,0.5-0.2,2.1,0.5,2.3
    		C2314.3,750.2,2313.6,748.1,2313.4,747.7"
          />
          <path
            id="path2518"
            class="countryIsland"
            d="M2314,746.8c-0.6-0.1-0.7,0.1-0.1,0.7C2313.9,747.3,2313.9,747.1,2314,746.8"
          />
          <path
            id="path2520"
            class="countryIsland"
            d="M2314,750.5c1.3-1,2.6-4.4,3-5.9c-1.1,0.4-1,1.5-1.3,2.3C2315.1,748.2,2314.4,749.2,2314,750.5"
          />
          <path
            id="path2524"
            class="countryIsland"
            d="M2323.7,752c-0.2,0.9-0.4,2.1,0.4,2.8c-1.4-0.1-1.9,4.3,0.5,3.1
    		C2327.6,756.4,2325.6,753.4,2323.7,752"
          />
          <path
            id="path2534"
            class="countryIsland"
            d="M2327.9,755.6c-0.5,0.3-0.6,1-0.4,1.5C2327.9,756.7,2328.1,756.2,2327.9,755.6"
          />
          <path
            id="path2536"
            class="countryIsland"
            d="M2327.9,754.4c-0.7,0.4-0.6,0.6,0.3,0.7C2328.1,754.9,2328,754.6,2327.9,754.4"
          />
          <path
            id="path2538"
            class="countryIsland"
            d="M2328.9,754.5C2329.8,751.9,2328.3,753.7,2328.9,754.5"
          />
          <path
            id="path2230"
            class="countryIsland"
            d="M2081.8,746c0.2-1-1.2-2.1-1.9-1.2C2080.6,745.2,2080.9,746.4,2081.8,746"
          />
          <path
            id="path2202"
            class="countryIsland"
            d="M2027.6,648.4c1.1,0.3,0.5-1-0.5-0.7C2027.2,647.9,2027.4,648.2,2027.6,648.4"
          />
          <path
            id="Indonesia_Sumatra"
            class="countryIsland"
            d="M2044.6,653.1c-0.6,0.4-1.5,0.9-2.2,0.7c-0.8-0.2-1-0.9-1.8-0.9
    		c-1.4,0-2.7,0.7-4.1,0.6c-1.7-0.2-2.8-0.7-4-2c-0.8-1-3.2-2.3-4.4-1.4c-4.7,3.3,6.7,12.5,8.5,14.9c0.7,0.9,1.6,1,2.7,1.1
    		c1.7,0.3,2.3,2.3,3.1,3.5c0.6,1,1.8,3.4,2.9,3.9c1.8,0.8,1.1,2.5,1.6,4.2c0.5,1.5,2.1,1.1,3.2,2c0.8,0.5,1.5,1.1,2.2,1.6
    		c0.9,0.7,1.8,1.3,2.6,2.1c1.1,1.1,0,1.3,0.4,2.5c0.4,1.5,1.1,3,1.5,4.5c0.4,1.6,1.6,3.3,1.5,5c-0.1,1.8,1.3,0.7,1.9,1.5
    		c1.1,1.3,2.8,1,3.1,3.2c0.4,2.8,4.1,4.6,4.7,7.6c0.1,0.4-0.6,0.5-0.3,0.9c0.4,0.6,0.9,1.3,1.2,2c0.8,1.5,2.4,3.6,2.5,5.3
    		c0.1,1.7,0,2.8,1.1,4.2c0.8,1.1,2,1.9,2.6,3.1c0.8,1.4,1.2,2.9,2.5,4c1.2,1.1,3,1.9,3.7,3.4c0.8,1.6,0.6,2.5,2.1,3.6
    		c1.4,1.2,2.7,2.6,4.1,3.8c1.2,1.1,2.4,2.1,3.8,2.9c1.4,0.8,1.6,1.3,2.4,2.6c0.4,0.7,0.9,1.3,1.4,1.9c0.4,0.4,0.9,0.8,1.3,1.2
    		c0.3,0.3-0.1,0.6,0.3,0.8c0.3,0.2,2.7,3.3,2.7,1.7c0-1-0.9-1.7-1-2.7c-0.1-1.3,2,0.4,2.2,0.6c1.8,1.6,3.5,1.6,3.3-1.4
    		c1.1,1,1.9,2.9,3.5,3.2c0.9,0.2,0.9-2.7,0.9-3.2c0.1-1.9,0.4-3.7,0.5-5.6c0.1-1,0.7-2.4,0.3-3.4c-0.3-0.7-0.6-1.2-0.3-2
    		c0.3-1,1.1-2.3,0.9-3.3c-0.1-0.8-0.9-0.8-0.8-1.8c0.2-0.9,0.7-1.7,1.2-2.5c0.5-0.8,0.9-1.2,0.7-2.2c-0.2-1-1-0.6-1.5-1.2
    		c-0.8-0.8-2-3.4-1.5-4.5c-0.9-0.1-5.1-1.2-5.4-0.4c-0.1-0.3-0.2-0.5-0.4-0.8c-0.9,1.1-1,2.7-2.4,3.5c0.4-0.6,2.2-2.6,0.4-2.6
    		c1-0.4,2.1-1.2,2-2.4c-0.1-0.9-1.1-0.6-1.6-0.9c-0.6-0.3-0.9-1.8-1-2.4c-0.4-1.7-0.8-3.3-1-5c-0.1-1.1-3.2-1.2-4.2-1
    		c-0.7,0.2-2.1-1.3-2.6-1.7c-0.9-0.7-0.5-2.3-0.9-3.3c0.4,0.5,1,0.6,1.5,0.2c-0.2-0.8-1-0.9-1.7-1c0.6,0,1-0.5,1.2-1
    		c-0.5,0-1,0-1.4,0.1c0.9-0.2,1.4-1,2-1.6c0.5-0.4,1.7-0.3,2-0.8c0.5-0.8-1.3-2.5-1.8-3.1c-1.3-1.4-2.2-1.3-3.7-0.3
    		c-1.1,0.8-4,2.9-5.3,1.2c2.6,1.5,7.5-3.1,2.8-4.1c-0.7-0.2-1.5,0.2-2.2-0.1c-0.8-0.3-1.2-1.3-1.8-1.8c-1.4-1.1-0.6-3.3-2.1-4.5
    		c-0.6-0.4-1.1-1.1-1.7-1.4c-0.6-0.4-1.5,0-2.2-0.4c-1.1-0.6-1.1-2.4-1.8-3.3c-0.4-0.5-2.9-3-3.5-1.5c-0.4,1.1,0.9,2.5,1.1,3.6
    		c-0.5-0.5-0.6-1.1-1-1.7c-0.6-0.9-1.8-1-2.6-1.7c-1.3-1.2-1.4-3.1-2.6-4.3c-0.3,0.5-0.4,1-0.2,1.5c-0.2-0.4-1.4-2.8-1-0.9
    		c-0.8-0.9,0.4-2.1-0.8-2.9c-1.1-0.7-2-2-3.1-2.8c-1.2-0.9-2.3-2.4-3.7-3.1c-0.5-0.2-1.1-0.3-1.6-0.6c-0.9-0.4-0.8-1.1-1.3-1.7
    		c-0.8-0.8-2.1-0.9-2.9-1.6c-1-0.9,0.3-2.5-0.7-3.4c-1.2-1-1.5-1-2-2.6C2048,655.8,2045.7,654,2044.6,653.1"
          />
          <path
            id="path2208"
            class="countryIsland"
            d="M2047.1,693.2c1.5,1.6,2-2.9,1.3-3.8c-0.8-1.1-2-3.1-3.3-3.6c-0.9-0.4-1.4,0.9-2.4,0.3
    		c0.2,1.3,1.3,2.3,1.9,3.5c0.3,0.7,1.2,1,1.6,1.6C2046.7,691.9,2046.8,692.6,2047.1,693.2"
          />
          <path
            id="path2210"
            class="countryIsland"
            d="M2044.3,680.8c0-0.7-0.5-1.9-1.4-1.2C2043.4,679.9,2043.9,680.3,2044.3,680.8"
          />
          <path
            id="path2212"
            class="countryIsland"
            d="M2053.3,702.6c0.2-0.6-0.1-1.3-0.6-1.7c-0.4,0.5-0.7,1.4-0.9,2
    		C2051.7,703.9,2053.6,703.9,2053.3,702.6"
          />
          <path
            id="path2214"
            class="countryIsland"
            d="M2052,698.5c0.4,1.1,0.9,2,1.5,3C2053.8,700.8,2053.2,698.4,2052,698.5"
          />
          <path
            id="path2216"
            class="countryIsland"
            d="M2053.8,684.5c0.1-0.6-0.1-0.6-0.7,0C2053.4,684.5,2053.6,684.5,2053.8,684.5"
          />
          <path
            id="path2218"
            class="countryIsland"
            d="M2055.7,697.7c-0.2-1-1.7-0.8-2.2-0.3C2054.1,697.9,2054.9,697.9,2055.7,697.7"
          />
          <path
            id="path2220"
            class="countryIsland"
            d="M2057.9,714.2c3-0.4-1.7-6.2-1.6-7.6c-0.5,0.3-1.3,0.1-1.7,0.6c-0.5,0.7-0.8,2-0.3,2.8
    		C2055.4,711.4,2056,713.7,2057.9,714.2"
          />
          <path
            id="path2222"
            class="countryIsland"
            d="M2063.5,719.1c-0.2-0.8-1.2-3.5-2.2-2.8C2060.1,717.2,2062.9,718.8,2063.5,719.1"
          />
          <path
            id="path2224"
            class="countryIsland"
            d="M2064.5,722.6c0.2,0.9,1.2,0.5,1.5-0.1c0.4-0.8-1-2-1.6-2.4
    		C2064.1,720.9,2064.6,721.7,2064.5,722.6"
          />
          <path
            id="path2226"
            class="countryIsland"
            d="M2067.9,727.4c0-0.6-0.5-1-0.4-1.5c0.1-0.7,0.5-0.5,0.3-1.2c-0.1-0.5-0.8-2.1-1.6-1.8
    		C2065.1,723.3,2067.3,726.9,2067.9,727.4"
          />
          <path
            id="path2228"
            class="countryIsland"
            d="M2078.2,681.8c-0.3-1.4-2.3-1.9-2.8-0.3c-0.3,0.9,0.3,2.1,1.2,2.3
    		C2077.8,683.9,2077.8,682.4,2078.2,681.8"
          />
          <path
            id="path2232"
            class="countryIsland"
            d="M2083.6,685.5c-1.1-0.5-2.3-0.4-3.3-1c-0.2,1.1,0.7,1.5,1.6,1.7c0.9,0.2,1.3,1,2,1.5
    		C2083.8,686.9,2083.9,686.2,2083.6,685.5"
          />
          <path
            id="path2234"
            class="countryIsland"
            d="M2083.7,688.2c-0.4-0.7-1.1-1.6-2-1.7c-0.2,1.3-0.1,3.7,1.6,4
    		C2083.5,689.7,2083.4,688.9,2083.7,688.2"
          />
          <path
            id="path2236"
            class="countryIsland"
            d="M2088.1,691.8c-0.5-0.9-1.5-1.6-2.5-1.9c-0.6-0.2-1.1-1-1.4-1c-0.4-0.1-0.8,2.3-0.3,2.7
    		c1.2,0.8,2.9,0,4.1,1C2088,692.2,2088.1,692,2088.1,691.8"
          />
          <path
            id="path2238"
            class="countryIsland"
            d="M2089,691.2c-0.3-1.2-4.2-3.6-3.7-1.4C2086.8,689.1,2087.4,691.7,2089,691.2"
          />
          <path
            id="path2240"
            class="countryIsland"
            d="M2090,693.5c-0.1-0.5-0.4-0.8-0.9-0.9c-0.4,0.5-0.3,1,0.2,1.4
    		C2089.5,693.9,2089.8,693.7,2090,693.5"
          />
          <path
            id="path2242"
            class="countryIsland"
            d="M2091,689.7c-0.2-0.3-0.5-0.5-0.8-0.6C2090.1,689.6,2090.4,689.9,2091,689.7"
          />
          <path
            id="path2244"
            class="countryIsland"
            d="M2091.6,692.2c-0.2-0.6-0.6-0.9-1.2-0.7c0,0.6,0.4,1.1,0.9,1.4
    		C2091.3,692.7,2091.4,692.4,2091.6,692.2"
          />
          <path
            id="path2248"
            class="countryIsland"
            d="M2093.2,700.9c-0.5-0.4-2.5-0.2-1.7,0.8C2092,702.3,2093.1,701.5,2093.2,700.9"
          />
          <path
            id="path2252"
            class="countryIsland"
            d="M2094.9,690.5c0.2-0.8-0.1-0.9-0.8-0.4C2094.3,690.2,2094.6,690.4,2094.9,690.5"
          />
          <path
            id="path2256"
            class="countryIsland"
            d="M2096.4,688.7c-0.5-1.2-1.5,0.2-1.5,0.9C2094.9,690.5,2096.6,689.5,2096.4,688.7"
          />
          <path
            id="path2258"
            class="countryIsland"
            d="M2096.7,691.7c0.4-0.7,0.2-1-0.6-0.7C2096.3,691.2,2096.5,691.5,2096.7,691.7"
          />
          <path
            id="path2262"
            class="countryIsland"
            d="M2100.4,689.7c-1-2.5-3.9-0.2-2.7,0.3c0.8,0.3,1.2-0.5,1.4,0.9
    		C2099.4,692.2,2100.4,690.2,2100.4,689.7"
          />
          <path
            id="path2264"
            class="countryIsland"
            d="M2097.5,692.5c0-0.3-0.1-0.5-0.2-0.8C2097.4,692,2097.4,692.2,2097.5,692.5"
          />
          <path
            id="path2266"
            class="countryIsland"
            d="M2099.1,701.5c-0.4,0.3-1,0.4-1.4,0.5c-0.8,0.3,0.5,1.8,0.6,2.3c0-0.7,0.4-0.9,1-0.5
    		C2099.4,703,2100.1,702.2,2099.1,701.5"
          />
          <path
            id="path2268"
            class="countryIsland"
            d="M2098.6,697.2c-0.4,0.5-0.2,0.7,0.5,0.8C2099,697.7,2098.8,697.4,2098.6,697.2"
          />
          <path
            id="path2270"
            class="countryIsland"
            d="M2099.3,698.5c0.1,0.5-0.8,2.4,0.3,2.3c0.6-0.1,1-0.4,1.6-0.1c0.6,0.3,1.1,0.8,1.9,0.4
    		C2102.7,700.5,2100,698.5,2099.3,698.5"
          />
          <path
            id="path2272"
            class="countryIsland"
            d="M2100.1,691.9c-0.2,0-0.5,0-0.7,0.2C2099.6,692,2099.8,692,2100.1,691.9"
          />
          <path
            id="path2274"
            class="countryIsland"
            d="M2099.6,696.7c-0.3,0.7,0.7,1.3,1.2,1.5C2100.4,697.7,2100,697.2,2099.6,696.7"
          />
          <path
            id="path2282"
            class="countryIsland"
            d="M2105.6,713.4c0.8,1.2-1.3,1.7-1.7,2.5c0.9,0.7,1.6,1.3,2.7,0.9c0.8-0.3,2.1,0.4,2.4,1.2
    		c0.2,0.7,0.8,1.6,0.9,2.2c0.3,1.5-0.6,2.1,1.1,3c0.7,0.4,4,2.9,4.8,2.2c0.8-0.7-0.4-1-0.6-1.6c-0.4-0.9,0.8-2.6,1.7-2.8
    		c-1.1-0.7-2.7-0.4-3.8-1.2c-1-0.8-1.3-2.8-1.2-4c0.1-0.7-0.4-3-0.9-3.5c-1.3-1.6-3,0.2-1.8,1.7
    		C2108.5,714.1,2105.7,710.2,2105.6,713.4"
          />
          <path
            id="path2286"
            class="countryIsland"
            d="M2116.6,723.7c-0.2,0.1-0.4,0.1-0.6,0.2c0.1,0.6,0.6,0.9,1.1,0.5
    		C2117,724.1,2116.8,723.9,2116.6,723.7"
          />
          <path
            id="path2290"
            class="countryIsland"
            d="M2121.7,723c-0.3,0.1-0.6,0.3-0.9,0.6C2121.3,723.8,2121.7,723.6,2121.7,723"
          />
          <path
            id="path2292"
            class="countryIsland"
            d="M2123.4,720.7c-0.9,1-1.2,2.6-1.1,3.9c0.1,1.4,2.1,2.1,1.8,0.6c0.6,0,1.5,1.7,2.1,1.1
    		c0.8-0.8,1.6-2.4,1.5-3.6C2127.5,721.1,2124.8,720.4,2123.4,720.7"
          />
          <path
            id="path2294"
            class="countryIsland"
            d="M2125.8,665.3c0.6,0.1,1.2,0.5,1.6,0.9c-0.6-0.4-1-0.1-1,0.6c1.8,0.3,2.3-1.5,1.6-2.9
    		C2126.7,661.5,2125.2,663.4,2125.8,665.3"
          />
          <path
            id="path2298"
            class="countryIsland"
            d="M2130.9,713.4c0.3,0,0.6,0,0.9-0.1C2131.5,713.3,2131.2,713.3,2130.9,713.4"
          />
          <path
            id="path2302"
            class="countryIsland"
            d="M2133.2,712.1c-0.4-0.3-0.8-0.3-1.2,0c0.2,0.3,0.4,0.5,0.6,0.7
    		C2132.8,712.6,2133,712.4,2133.2,712.1"
          />
          <path
            id="path2306"
            class="countryIsland"
            d="M2139.3,707.5c-1.1-1.6-3.4,0.9-2,2.3C2138,709.2,2139.7,708.8,2139.3,707.5"
          />
          <path
            id="path2324"
            class="countryIsland"
            d="M2188.7,727c-2.6-1.1-1.8,5.5-1.2,6.4c0.7-1.1,1.4-0.8,1.3-2.3
    		C2188.7,729.8,2188.2,728.1,2188.7,727"
          />
          <path
            id="path2326"
            class="countryIsland"
            d="M2189.8,728c-0.8,0.1-0.8,1-0.2,1.4C2189.7,729,2189.8,728.5,2189.8,728"
          />
          <path
            id="path2332"
            class="countryIsland"
            d="M2197.5,669.7c-0.2,0.7,0.1,0.7,0.7,0.2C2198,669.9,2197.8,669.8,2197.5,669.7"
          />
          <path
            id="path2334"
            class="countryIsland"
            d="M2198.4,668.9c-0.3,0.7,0.4,1.3,0.9,1.5C2199.4,669.7,2199.4,668.6,2198.4,668.9"
          />
          <path
            id="path2336"
            class="countryIsland"
            d="M2198.6,663.1c0,0.6,0.4,1,1,0.7C2199.5,663.2,2199.2,663,2198.6,663.1"
          />
          <path
            id="path2338"
            class="countryIsland"
            d="M2198.9,701.8c-0.2,0.2-0.3,0.5-0.3,0.8C2198.7,702.3,2198.8,702,2198.9,701.8"
          />
          <path
            id="path2340"
            class="countryIsland"
            d="M2200.1,667.6c0,0.5,0.3,0.9,0.8,1C2200.9,668.1,2200.6,667.8,2200.1,667.6"
          />
          <path
            id="path2344"
            class="countryIsland"
            d="M2206.4,678.5c0,0.5,0.3,0.9,0.7,1.2C2206.9,679.3,2206.7,678.9,2206.4,678.5"
          />
          <path
            id="path2556"
            class="countryIsland"
            d="M2195.9,668.1c0-0.2,0.1-0.3,0.1-0.5c0.8,0.1,1.5,0.4,2.2,0.8
    		C2197.4,668.5,2196.6,668.3,2195.9,668.1"
          />
          <path
            id="path2558"
            class="countryIsland"
            d="M2199.2,662.5c0.9-0.1,2-0.3,1.8,1C2200.2,663.7,2199.8,663,2199.2,662.5"
          />
          <path
            id="path2564"
            class="countryIsland"
            d="M2324.5,750.7c0,0.6,0.3,1.1,0.7,1.5c-0.2-0.3,0.1-2.4,0.3-2.9c0.4-1.2-0.9-0.6-0.5-1.6
    		c1,0.5,1.6-1.1,2-1.7c0.7-1,1.6,1.1,1.6,1.7c0,1.4-0.1,2.9-0.2,4.4c-0.1,2.2-1.8,1.2-2.9,0.4C2327,754.2,2321.7,750.6,2324.5,750.7
    		"
          />
          <path
            id="path2566"
            class="countryIsland"
            d="M2326.2,753c0.3,0.2,1.2,1.4,0.6,1.7C2325.8,755.1,2325.1,753.2,2326.2,753"
          />
        </g>
        <g id="ye" class="countryGroup">
          <path
            id="path2190"
            class="countryIsland"
            d="M1625.3,578.2c0.1-0.3,0.1-0.7,0.1-1C1624.3,577.4,1624.8,577.8,1625.3,578.2"
          />
          <path
            id="path2192"
            class="countryIsland"
            d="M1698.4,593.5c0-0.8-1-0.5-1.4-0.4C1697.4,593.2,1697.9,593.4,1698.4,593.5"
          />
          <path
            id="Yemen_Socotra"
            class="countryIsland"
            d="M1709,592.1c1.7-0.4,4.6-0.2,5.5-2.1c-1-0.6-2.3-1.5-3.6-1.3c-0.6,0.1-1.1,0.5-1.7,0.5
    		c-0.7-0.1-1.2-0.6-1.9-0.5c-0.8,0.1-1.4,1.6-0.8,2.2C1707.1,591.4,1708.2,591.9,1709,592.1"
          />
          <path
            id="Yemen_mainland"
            class="country"
            d="M1702.8,554.2c-2.4-0.1-1.1-5.1-3.5-5.3c1.1-1.7-2.9-7-3.6-8.9l-2.8-6.5
    		c-7.1,1.4-14.3,2.9-21.3,3.9c-5.8,1.8-8.8,4.7-10.1,7.9c-1.2,1.8-1.9,6.4-4.8,6.3c-2-0.1-1.8-3.1-3.7-2.9c-2.4,0.3-4.4,0.2-6.8-0.1
    		c-1.4-0.2-2.8-1.2-4.2-1.2c-1.5,0-3,0.1-4.5,0.3c-1.8,0.2-3.4,1-5.3,0.9c-1.1-0.1-1.4-1.2-2.5-1.4c-1-0.1-1.8,0.6-2.2,1.4
    		c-0.5,0.9-0.6,2.1-0.4,3.1c0.1,0.7,1.1,1.3,0.5,2c-0.5,0.7-0.9,1.3-1.4,2c-0.3,0.4-0.6,0.7-1,0.9c-1,0.5-0.5,0.8-0.3,1.9
    		c0.3,1.7-0.3,2.4-0.6,4c-0.3,1.4,0.9,2.6,0.8,4c-0.3-0.2-1.7-0.3-1.5,0.3c0-0.1,1.4,0.7,1.4,0.7c0.8,0.9,1.1,1.7,1.4,2.9
    		c0.5,2.2,0.6,4.6,1.3,6.8c0.3,0.9,1,1.5,1.3,2.4c0.4,1,0,2.1,0,3.2c0,2.1,2.5,3.8,1.8,6c0.9,0.1,1.5-0.4,2.5-0.1
    		c1,0.4,1.7,0.7,2.8,0.5c2.2-0.4,3.3-2,5.6-0.8c-0.1-0.6,0.6-1.3,0.8-0.4c0.4-0.4,0.6-0.9,0.9-1.4c0.5-0.8,1.3-0.7,2-1.2
    		c1.5-1.1,1.7-2.2,3.7-2.7c1.9-0.4,3.9-0.5,5.8-0.3c1.1,0.1,3.1-1.2,4.2-1.5c1.7-0.5,2.6-2,4.1-2.8c0.5-0.3,1.1-0.8,1.6-1
    		c0.6,0,1.1,0.1,1.6,0.4c0.6,0,1.1,0,1.7-0.2c3.6-0.6,4.4-4.3,7.7-5.9c1.5-0.7,2.9-0.9,4.5-1.2c2-0.3,3.7-1.7,5.6-2.2
    		c1.4-0.3,2.7-0.6,4.1-1.1c1.3-0.5,1.8-1.5,2.9-2.1c0.9-0.5,2.9-0.4,3.2-1.7c0.3-1.5-0.7-2.8-0.2-4.3
    		C1696.9,555.8,1700.5,555.9,1702.8,554.2"
          />
        </g>
        <g id="mg" class="countryGroup">
          <path
            id="Madagascar_island"
            class="countryIsland"
            d="M1625.3,889.4c0.6,2.4-0.6,5.3,1.1,7.4c0.8,1,0.8,2.2,1.2,3.4c0.4,1.1,1.5,1.3,0.4,2.5
    		c-1.1,1.2-0.9,5.2-0.2,6.6c0.6,1.3,1.3,2.3,1.6,3.7c0.4,1.9,1.7,2.4,3.1,3.6c-1.8-1-0.2,0.3,0.2,0.5c0.6,0.2,1.3,0.1,2,0.3
    		c1.6,0.4,2.4,2.1,4,2.5c2.7,0.6,5.5-2.9,8-3.6c0.9-0.3,1.9,0.3,2.7,0c0.7-0.2,1.2-0.7,1.9-0.8c1-0.2,1.6-1.4,2-2.2
    		c0.5-1.1,0.9-2.3,1.2-3.5c0.4-1.6,1.4-3,1.9-4.5c0.9-2.9,1.9-5.7,2.7-8.6c1.1-4.2,2.8-8,4.3-12.1c1.2-3.3,1.9-6.7,3.3-9.9
    		c0.4-0.9,1.2-2.4,0.8-3.5c0.5,0,1.1-2,1.2-2.4c0.8-2.2,1.3-4.6,2.2-6.8c1.3-3.2,2.6-6,3.2-9.4c0.5-2.6-1.2-6.4,1.9-8.1
    		c0.6-0.3,1.3-2.3,1.4-3.1c0.1-0.7-0.1-1.4,0-2.1c0.2-1-1-0.9-1-1.8c0-0.7-0.7-6.2,1-5.4c1.5,0.6,1,3.8,2.4,4.3
    		c1.6,0.6,2.7-3.8,2.9-4.8c0.3-1.4-0.7-2.1-1.1-3.3c-0.7-2.3-0.6-4.5-0.7-6.8c-0.2-4.3-1.2-8.8-2.9-12.7c-0.2,0.2-0.5,0.3-0.7,0.5
    		c0.2-0.6-0.1-0.7-0.4-1.1c-0.6-0.6,0.3-0.9-0.2-1.6c-0.5-0.6-0.8-2.9-2.1-2.1c-0.1-0.3-0.1-0.5-0.1-0.8c2.2,0.2-1-3.1-0.6-0.3
    		c0.1,1.1-2.1,3.1-3.1,3.1c1.9,1,0.7,3.8,0.3,5.1c-0.6,1.9-0.5,3.1-2.9,3.2c0.3,1.4-0.2,0.3-0.8,1.1c-0.5,0.6-0.5,1.4-0.7,2.1
    		c-0.6,0-2.7-4.4-3-1.3c-0.1,1.1,0.3,1.8,0.7,2.8c0.3,0.9-0.3,3.3-0.4,1.6c-0.2,0.9-2.8,1-1.8,2.9c0.6,1,2.7,0.6,1.8,2.3
    		c0.3-1.3-1.1-1.4-1.9-0.8c-1.1,0.9-1,2.8-2.6,3.3c-0.2-1.1,0.9-2,0.7-3.1c-1.1,0.2-0.8,1-1.3,1.8c-0.4,0.8-1.7,1.2-1.9,2.1
    		c-0.1,0.6,0.5,1.3,0.8,1.8c0.5,1-1.3,1.2-1.9,1.7c0.1-0.7,1.4-2.7-0.4-2.7c-1,0-1.8,1.3-2.6,1.8c-0.5,0.4-1.9,1.1-1.8,1.9
    		c0.1,0.4-0.3,0.9-0.1,1.3c0.2,0.5,0.9,0.4,1.1,1.1c-0.8,0-1.6,0.1-1.8-1c-0.2-1.4-1.6-1.4-1.6,0.1c-0.3-0.6-1.9-0.7-2.5-0.4
    		c-0.7,0.3-0.4,1.5-1,2c0-1.5-1.7-0.3-1.8,0.6c-0.2-0.5-0.5-0.9-0.9-1.3c-0.9,0.6-1.5,1.6-2.5,2c-0.9,0.3-2.6-0.4-3.4,0.3
    		c-0.4,0.3-0.1,3.2-0.4,2.9c0.3,1.2-0.6,2.5-1.1,3.4c-0.9,1.6-3.9,4.5-2.8,6.4c0.8,1.3,0.1,3.7,0.2,5.2c0.2,1.7,1.4,3.2,1.4,4.9
    		c0,1.6-0.1,2.6,0.8,4c0.9,1.5,0.4,2.2-0.1,3.6c-0.3,1.1,1,1.4,0.4,2.5c-0.7,1.2-1.5,2.4-2.3,3.5c-0.8,1.3-1.7,2.5-2.3,3.9
    		c-0.4,1.1-0.1,2.9-1.4,3.3c-1.6,0.6-2,0.6-2.2,2.5c-0.1,1.6-1.5,3-1.9,4.6"
          />
          <path
            id="Madagascar_Nosy_Be"
            class="countryIsland"
            d="M1667.4,814.5c0.4-0.6-0.1-1.3-0.5-1.7C1666.2,813.4,1666.4,814.5,1667.4,814.5"
          />
          <path
            id="Madagascar_Nosy_Boraha"
            class="countryIsland"
            d="M1678.7,843.4c0-0.1,0-0.3,0-0.4c-0.4,0.8-1.5,1.7-1.1,2.7
    		C1678.1,845,1678.3,844.1,1678.7,843.4"
          />
        </g>
        <g id="bo" class="countryGroup">
          <path
            id="path2375"
            class="country"
            d="M772.1,793.3c1.9,0.1,3.8,0.3,5.7,0.4c0.9,0.1,0.4,0.2,0.6,0.7c0.2,0.9,1.6,0,2-0.2
    		c0.4-0.2,1.3-0.4,1.6-0.8c0.4-0.7,0.6-1.7,1.2-2.2c1.4-1.2,2.6,0.7,3.4-1.2c0.7-1.7,2.8-1.7,4.1-2.7c1.2-0.9,1.8-2.6,3.4-3.1
    		c1.8-0.5,3.8-1.4,5.8-1.1c2.1,0.3,1.7,0.5,3.1-0.8c3.1,2.1-0.2,6.1,0.7,8.8c0.2,0.7,0.8,1.1,1,1.7c0.3,0.7-0.4,1.4-0.5,2
    		c-0.5,1.8,1.3,2.8,1.7,4.2c0.3,1,0.2,0.9,1,1.6c0.5,0.4,0.4,1.6,0.9,1.8c0.7,0.4,1,0,1.7,0.6c0.7,0.5,1.3,0.9,2.1,1.5
    		c0.1,0.1,0.5,1,0.7,1.2c0.5,0.6,1,0.7,1.8,0.9c1,0.2,1.7,0.6,2.7,0.1c0.8-0.4,1.3-0.2,2.1,0.3c0.6,0.4,1.1,0.9,1.7,1.3
    		c0.8,0.5,1-0.6,1.6-0.4c1.2,0.4,1.3,2.7,2.9,2.9c1.4,0.2,2.4,1.3,3.8,1.4c1.9,0.1,2.2,3,3.8,3.4c1.3,0.3,2.6,0.1,3.9-0.1
    		c1.9-0.3,3.8,1.2,5.3,2.1c2.5,1.4,0.9,2.8,1.8,4.9c1,2.3,1.4,4.3,1.9,6.7c-0.6,0-1.2,0-1.9,0.1c0.1,1.8,2.3,2.8,2.6,4.7
    		c0.2,1.4,0.1,3.1,0.7,4.4c0.8,1.7,3.9,1.1,5.4,1.3c2.8,0.3,5.8,0.7,8.6,0c0,1.3-0.7,2-0.8,3.2c-0.1,0.9,0.3,2.1,0.5,3.1
    		c0.4,1.4,2.1,3.7,3.6,4c0.8,0.1,1.2-0.4,1.6,0.6c0.3,0.8,0.6,1.5,0.9,2.2c0.8,2,1.8,3.1,1.4,5.2c-0.2,1.1-1.1,2.9-0.8,4.1
    		c0.5,1.6-0.6,2.9-1.2,4.4c-0.8,2-0.9,3,0.8,4.3c1.2,1-0.7,1.7-1.6,2.3c0.6-2-1.9-4.2-3.4-5.1c-1.6-1-3.4-1.8-5.3-2.2
    		c-5.1-1.1-10.5,1.8-15.4,2.3c-2.4,0.2-3.6,0.5-4,3.1c-0.3,2.1-1.9,3.3-2.2,5.4c-0.3,2,0.5,3.9,0.1,5.9c-0.6,2.9-0.7,6-1.4,8.8
    		c-2.2-1.4-1.3-2.3-3.3-2.3c-1.5,0.1-3.4-0.3-4.9,0.2c-1.7,0.6-1.6-0.8-2.5,1.6c-0.8,2-1.1,3.7-1.3,5.8c-2.2-3-1.8-6-6-6.7
    		c-1.7-0.3-4.2,0.5-5.7-0.2c-1.3-0.7-2.1-2.3-3.6-2.6c-0.2,2.2-0.1,2.9-2.3,3.6c-1.1,0.4-2.3,2.5-2.9,3.5c-0.9,1.5-1.2,2.2-3.3,2.5
    		c-0.8,0.1-2-0.3-2.3-0.7c-1.3-3.5-1.7-5.6-3.2-8.4c-1.3-2.3-1.1-4.2-2.1-5.8c-0.6-0.9-1.7-1.7-2.2-2.6c-0.7-1.2,0.4-2.3-0.1-2.3
    		c-0.9,0.1-1.5-0.8-1.9-1.7c-0.5-0.9,0.6-1.5-0.6-2.6c2.1-1.3,2-1.1,0.5-3.1c-0.2-0.3,0.7-1.5,0.8-1.8c0.4-1.1-0.1-1.7-1.1-2.5
    		c-1.9-1.7-2.8-3-3.5-5.5c-0.3-1.1-2-3.9-0.9-5c-3.6-0.7-3.6-6.2-5.1-6.5c0.1-0.7,0.6-1,1.1-1.4c0.8-0.6,1-1.5,1.6-2.2
    		c0.5-0.7,0.8-1.3,1.4-1.8c0.1-0.1-0.1-1.2,0.1-1.1c0.6,0.3,1.9,0.6,0.6-1.1c0.4,0.3,2.1-1.6,2.1-1.1c-0.1-1.9-3,1.2-1.9-3.1
    		c-1.8-0.3-0.3-0.8-4.2-3.3c0.2-1,1.5-2.1,0.9-3.2c-0.8-1.7-2.1-2-1-4.1c0.7-1.3,1.4-2.4,1.8-3.7c0.5-1.6,0.9-1.5-0.1-3.2
    		c-0.9-1.5-0.2-2.8-0.4-4.4c-0.1-1.3-0.9-4.1,0.1-5.2c1-1.1,1.9-1.9,1.1-3.4C776.9,801.8,774.3,797.5,772.1,793.3"
          />
          <path
            id="path5225"
            class="country"
            d="M780.2,838.9c0,0-0.5,0-1.1-0.2c-0.3-0.1,0.2-0.9,0.5-1C780,838,780.6,838.7,780.2,838.9"
          />
        </g>
        <g id="rs" class="countryGroup">
          <path
            id="rs-"
            class="country"
            d="M1442.9,299.8c-1.2-0.5-2.2,1.3-2.6,1.4c-0.7,0.3-1.9,0.1-3.4,0.6c0.4,1,0.7,1.6,0.9,2.6
    		c0.2,0.7,0.3,0.9,1,0.9c-2,1.4,1.7,2.2,2.3,2.8c-0.1-0.1-2,0.4-2.2,0.5c0.6,1.2,0,1.8-0.2,2.2c0.9-0.4,1.7-0.6,1.9-0.1
    		c0.5,1.1,0.6,1-1.1,3.5c-0.6,0.8,1.2,2.1,2.7,3.1c1.6,1.5-1.2,0.8-1.6,1.1c-0.3,0.1,2.3,2.9,1.8,3.1c-1.4,0.7-2.9,0.8-3.9,0.8
    		c0.5-0.1,1.8,1.5,3.6,3c0.6,0.5,0.7,1,2.1,1.4c1.1,0.6,3,0,3.3,1.3c0.1,0.1-1.5,0.9-1.4,0.9c0.1,0.1,1,1.4,0.7,1.6
    		c1.9,0.6-0.4,1.8,3,2.4c1,0.2,1,4.7,1.1,3.6c1.5-1.2,1.3-3.5,4.7-2.2c0-1.1,1.2-0.9,1.8-1.3c0.7,0.7,1.4-0.7,2.5-0.4
    		c0.7,0.2,5.1-0.3,4-1.9c-0.7-1-0.8-2.1-0.3-2.7c0.5-0.6,1.2,0.1,1.8-0.7c0.6-0.7,1.1-1.5,1.4-2.2c-1-0.3-2.3-1.6-3-2.3
    		c-2.4-2.3-1.2-4.1,0.2-6.4c-0.3-0.3-2-2-1.4-2.4c0.6-0.4,1.3-0.1,1.8-0.4c-0.6-0.7-2-1.6-2.8-0.9c-0.3,0.2-1,1.5-1.3,1.5
    		c-0.4,0-1.1-1-1.5-1.2c-1-0.4-2.1-0.7-3-1.2c-0.7-0.4-0.3-0.2-1-0.5c0.9-0.5,1.3-1.3,0-1.4c1.4-1.3,0.3-1.6-0.9-2
    		c-1.2-0.4-2.8-1.5-3.8-2.3c1.3-2.6-1.5-2.6-1.9-4.4C1448,299.6,1444.6,299.6,1442.9,299.8L1442.9,299.8z"
          />
          <g id="xk" class="countryGroup">
            <path
              id="xk-"
              class="disputed"
              d="M1446.4,328.9c0.4-0.3,1.2-0.8,1.6-0.6c0.1-0.7,0.2,0.1,0.4-0.4c0.4-0.7,1-0.6,1.5-0.8
    			c1-0.4,1-0.9,0.5-2c0.5-0.1,1.1-0.4,1.5-0.7c0.3,2,5.1,1.3,4.8,3.2c-0.2,1,1.7,1.3,1.9,2.1c0.3,1.2-1.9,2.4-1,3.4
    			c-0.8,0.4-2,0.4-1.7,1.4c-1.2-0.1-1-0.8-2.8-0.1c-1.4,0.6-1.3,2.2-2,2.4c-0.5-0.1,0.2-2.4-0.8-3.2c-0.2-0.9-1.1-0.6-2-1.2
    			c-0.6-0.9-0.2-1.4-1.2-1.7C1446.8,330.3,1445.9,329.3,1446.4,328.9"
            />
            <circle id="xk_" class="circle" cx="1453.1" cy="328.6" r="4" />
          </g>
        </g>
        <path
          id="ci"
          class="countryGroup country"
          d="M1235.1,642.2c0.4-0.9,1.5-1.1,1.9-2.1c0.3-0.7,0.5-1.7,0.5-2.4c0.1-1.3-0.4-3.6-1.3-4.5
    	c0.6-0.6,1.4-0.3,2,0.1c0.1-0.8,1.3-1.6,0.9-2.4c-0.4-0.7,0.2-2.1,1.2-1.6c-0.7-1-1.9-1.7-2.2-2.9c-0.6-2.4,3.8-0.5,4.6-0.2
    	c-0.5-1.6-0.3-3.3-2.4-3.7c0.4-1.1,0.4-2.2,1.7-2.4c-1.1-1.3-1.3-1-0.9-3c-2.4,1.6-2.3-3.8-2-5c0.3-1.2,1.1-1.5,2.2-1.8
    	c0.6-0.2,0.6-1.2,1-1.6c0.6-0.5,1.4-0.2,2.1-0.5c-0.2,1.4,2.7,2.8,3.8,2.3c-0.8-1.3,0.5-1.3,1.5-1.3c1.2,0,0.8-2,0.8-2.9
    	c0.5,0.3,1,1.2,1.7,1c0.6-0.2,0.8-1.4,1.4-1.7c0.6,1-0.2,4.4,1.2,4.7c1.3,0.3,0.3-2.5,4.2-2.4c0.4,0-0.8,1.1,2.3,1.3
    	c1.5,0.1,1.8,3,2,3.3c1,1.2,3,2.5,4.9,2.9c0-1.7,2.6-2.1,3.8-2.6c1.1-0.4,4,0.9,4.8-0.2c1.2,1.4,1.7,3,3.3,4.5
    	c0.2-0.2,0.3-0.5,0.5-0.7c0.5,1.4-0.6,2.8-0.4,3.5c0.3,1.2,1.1,1.8,1.2,3.2c0.1,1.2,0.8,3.1,0.4,4.2c-0.5,1.2-1.1,1.9-1.7,3
    	c-0.8,1.5-2.1,2.3-2.1,4c0.1,2.1-1,3.7-1.4,5.3c-0.5,1.7-0.2,4.4,0.8,5.7c1,1.3,1.5,3.1,1.1,4.6c2.6-0.7,1.1,3.2,1.5,4.4
    	c-0.5,0-1,0.1-1.5,0.2c0.2-0.3,0.3-0.5,0.5-0.8c-1.2,1.8-2.2-0.5-2.4-1.4c-0.7,0.7-0.9,1.7-2,1.8c-0.7,0-1.6-0.3-2.2-0.8
    	c-0.4,0.7-1.3,0-1.7-0.3c0.6-0.1,1.3,0.5,1.8-0.2c-0.2-0.2-0.4-0.4-0.7-0.6c0.3,1.4-0.3,0.1-1.3,0.4c0.2,0.4-2.4,0.2-3,0.3
    	c-0.3,0-3.5,0.6-3.3,0.7c1.6,0.9,4.8-1.9,6-0.3c-2,0.1-3.9,0.4-5.9,0.8c-0.4,0.1-1.3,0.3-1.6-0.3c-0.4-0.7-2.3-0.4-3,0.3
    	c1.1-0.5,1.8-0.3,2.9,0.1c-2.4,0.3-5.1,0.1-7.4,1.1c-2,0.9-3.8,2.5-6,2.7c-1.1,0.1-3.6,1.4-4.4,2.2c-1.9,1.8-2.2-1.5-1.9-2.9
    	c0.3-1.6,0.9-3.4,1.4-4.8c0.6-1.8,0.1-3.8-1.3-4.9c-0.9-0.7-2.1-1-2-2.6c0.2-1.7-5.1-0.2-4.1-2.2
    	C1236.1,642.9,1235.5,642.7,1235.1,642.2"
        />
        <path
          id="dz"
          class="countryGroup country"
          d="M1238.9,462.4l0.5-10.9c-0.5-2.9,4-3.9,5.6-5.4c0.8-0.7,2.1-2.2,3.3-2.1c1.1,0.1,1.3-1.5,2.7-1.8
    	c2.3-0.4,3.5,1.8,4.8-1.3c0.4-0.9,1.9-0.5,2.7-0.7c1.5-0.2,3-0.4,4.4-0.9c2.8-1,2.9-4,5.3-5.3c1-0.5,2.3-0.6,3.3-1
    	c1.3-0.5,2.1-1.9,3.4-2.2c0.5-0.1,3.1-0.3,2-1.7c-0.7-0.9-1.8-1.2-0.4-2.5c-2-0.6-0.2-2.7,1.3-2.5c2,0.2,3.8-0.5,4.5-2.4
    	c1.3-3.5,10.4-0.4,13-1.1c-3-1.3,2.2-3,0.6-4.2c-0.8-0.6-1.6-1.1-2.2-1.9c-0.7-0.9-0.3-2.1-1-3.2c-0.7-1.1-0.3-2-0.1-3.1
    	c0.2-1-0.6-1-0.5-2.1c0.1-0.9,0.1-2.1,0.1-3c0-1.3-0.8-2.3,0.4-3.2c-1.3-0.5-0.8-1.2-1-2.2c-0.2-1.3-2.5-1.7-3-3
    	c2.4,0,3.4-0.6,5.4-1.6c0.9-0.5,1.4-0.5,2-1.4c0.5-0.8,0.8-1.5,1.7-2c0.5-0.3,1.5-1,2-0.6c0.7,0.5,1.5-0.4,2-0.8
    	c1.2-1.1,2.8,1.6,4-0.8c1.6-3.3,7.2-4.7,10.4-5.1c2.3-0.3,4.7-0.9,7-0.7c1.7,0.1,2.9-2.1,4.4-1.2c0.7,0.4,0.5,0.2,0.8-0.2
    	c0-0.1,2.7,0,3.1-0.2c2.2-0.9,4.5-0.6,6.9-0.6c2,0,2.4,0.4,3.9,1.5c1.9,1.4,2.6-0.7,4.2-0.9c0.5-0.1,3.8-0.2,3.7-1.2
    	c-0.3-1.8,2.1-0.7,2.7-0.3c1.2,0.8,3,1.2,4.2,0.2c0.7-0.5,0.2-1.2,1.5-0.9c0.9,0.2,1.6,0.9,2.6,0.8c-0.8,1.4,1.2,0.9,1.9,0.7
    	c1.3-0.3,2.7,0,4-0.4c0.1,1.8-2.2,2.7-3.2,3.8c2.7,0.6,0.1,4.4,0.7,6.1c0.9,3,0.6,5.3-0.1,8.3c-0.5,2.5-3.2,4.4-5.1,6
    	c-1.1,0.9-0.5,3.4,0.1,4.4c1,1.7,0.5,3.5,2.6,4.3c2.8,1.1,2,4.7,4.3,6.4c1.1,0.8,2.3,1.4,3.2,2.4c1.2,1.3,1.4,3.5,1.7,5.2
    	c0.6,3.5,2.2,7.4,2.2,11c0,1-2,0.6-0.7,2.3c1.3,1.7,2.3,3.7,2.7,5.7c1.4,7.1-0.7,7.1,0.4,10.7c0.5,1.7,0.5,3-0.1,4.7
    	c-0.8,1.9-0.1,3.7,0.3,5.6c0.6,2.9-0.7,2.9-2.7,4.5c-2.3,1.8,1.6,5.2,2.6,6.8c1.9,2.9,0.8,6.4,3.3,9.1c1,1,2.1-0.2,3.1-0.1
    	c1.3,0.1,2.7,1.1,3.9,1.5c2.8,1,3.7,3.4,4.8,6c0.5,1.3,1,1.4-0.4,2.2c-14.2,9.1-28.5,18.1-40.6,29.9c-0.7,1-2.9,3.5-4.7,4.5
    	c-1.3,0.7-3.3,0.8-4.7,1.1c-2,0.4-4,0.8-6,1.2c-1,0.2-9.7,3.2-9.3,0.1c0.1-1.1,0.9-1.5,0.8-2.7c0-0.5,0.1-2.9-0.4-3.1
    	c-1.5-0.7-3.3-1.1-4.9-1.4c-0.8-0.2-1.9-2.2-2.5-2c-3.5,0.9-4-2.6-4.7-3.1c-0.8-0.6-2-0.7-2.7-1.2c-0.8-0.6-0.1-2-0.6-2.8
    	c-0.6-1-1.6-1.8-2.5-2.4c-1.6-1-3.1-2.1-4.6-3.2c-3.7-2.8-7.3-5.8-10.9-8.7c-14.1-11.1-28.2-22.1-42.9-32.3
    	C1245.9,467.2,1242.4,464.8,1238.9,462.4"
        />
        <path
          id="ch"
          class="countryGroup country"
          d="M1349.5,300.1c0.2-0.4,0.5-0.9,0.7-1.3c0.3-0.6-0.2-1.2,0-1.8c0.3-0.9,1.9-1.6,2-1.9
    	c0.2-1.2,0.8-1.9,2.4-2.9c0.9-0.5,1.5-1.6,2.3-2.4c-1.6,0.2-0.6-2.3,0.7-1.1c1.4,1.3,2.1-0.8,3.4-1c-0.7,0.8,2.9,0.2,3.6,0.2
    	c1.3,0.1,2.3,0.1,2.6-0.7c-1,0.4-1.2-0.6-0.3-0.9c0.4-0.1,0.7,0,1.3,0.2c0.9,0.4,2.2,0.4,2.3,0.5c1.1,0.4,2.5,0.6,3.4,1.4
    	c1.2,1-0.6,2.7-0.8,3.9c2.3-0.3,2.5,1.4,4.4,1.7c0.8,0.2,1.1-1.4,2-1.1c1,0.3,0.2,1.5,0.1,2c-0.1,0.4,0.8,1.7-0.1,1.7
    	c-0.7,0-1.2-1.2-1.8-0.6c-0.2,0.2-1,1.4-0.5,1.6c1,0.4,0.4,0.8,0.2,1.7c-0.9-0.4-1-1.4-2.2-1.2c-1.2,0.2-1.3,0.5-2.2-0.4
    	c-0.1-0.1-1.1-1.8-1.3-0.4c-0.1,1.3-0.1,1.6-1.1,2.6c-1.2,1.3-0.1,4.2-2.1,1.4c-0.4-0.6-0.9-0.9-1.5-1.4c-0.9-0.7-0.6-2-1.4-2.6
    	c-0.5,0.6-1.1,1.3-1.9,1.5c0.9,1-1.1,2.9-2.2,2.8c-2.1-0.2-3.8,1.1-5.4-0.6c-0.6-0.6-1.3-1.2-1.2-2.2c0.3-1.3-0.8-1.6-1.9-1.5
    	C1351.4,297.7,1351.7,300.1,1349.5,300.1"
        />
        <path
          id="cm"
          class="countryGroup country"
          d="M1366.2,656.9c0.9-0.8,1.5-2,1.6-3.2c0-1.1,1.5-3.3,1.1-4c-0.4-0.8-0.6-1.1,0.6-2.4
    	c1.2-1.3,2.3-2.6,3.6-3.8c0.7-0.7,1.7-1,2-1.9c0.3-0.8,1.6-3,2.5-3.2c1-0.3,1.6,1,2.8,0.8c1-0.2,1-1.6,1.2-2.3
    	c0.2,0.8,2.2,2.9,3,3.1c0.7,0.2,0.9,1.2,0.7,2c-0.2,0.6,0.8,1.4,1.9,1.2c0.3-0.6,2.2-2.3,1.7-3.3c-0.3-0.7,1.2-1.7,1.7-2.1
    	c0.9-0.7,0-1.8,0.5-2.7c0.9-1.8,2.2-3.5,2.8-5.8c0.3-1-0.1-2,0.2-2.9c0.2-0.7,1.3-1,1.3-2.1c-0.3-0.1,2.5,0.3,3.1-2.5
    	c0.1-0.6,1.2-3.8,0.2-4c1.2-0.6,3-1.4,3.2-2.8c0.1-0.9,0.1-1.7,0.1-2.6c0-1,1-0.9,1.4-1.5c0.5-0.8,0.5-1.9,0.8-2.9
    	c0.3-1,0.6-2,0.8-2.7c0.3-1.2,1-3.5,2.2-4c0.7-0.3,4.7-0.2,5.2-2.5c0.2-0.6-0.5-1.1-0.4-1.9c0.2-1.3,0.2-2,0.5-3
    	c0.2-1.1-1.8-2.1-2.5-1.9c-0.6,0.2-1.3-1-1.5-2.5c-0.2-2.3-0.1-3.2-0.4-3.9c0.9,0,2.2-0.1,2.9,0.7c1,1.1,0,2.2,2.2,2.9
    	c1.2,0.3,1.3,3,1.3,3.9c0.1,0.9-0.1,0.1,0.8,1.1c1.9,1.8-0.2,7.2,0.1,9.6c0.4,3.3,1.9,7.1,4.9,8.7c-1.2,1-2.7,0.5-3.7,0.1
    	c-1,0.1-1.2,0.3-2.3,0.3c-2.2,0.1-4.5-1.4-5.6,0.5c-1.4,2.2-1.2,2.6,0.6,4.7c1.8,2.1,3.8,3.9,5.8,5.8c0.6,0.6,1.4,1.5,1.8,2.2
    	c0.8,1.5,1.4,4.5,2.3,5.9c0.9,1.5-0.9,2.5-1.3,3.1c-0.7,0.9-1.7,3.1-2.3,4.6c-0.2,0.5-0.7,1.3-0.9,1.8c-0.4,0.9-1,1.7-1.4,2.6
    	c-0.6,1.3-3.7,2.5-1.9,3.3c1.3,0.5,1.2,1.9,1,3.6c-0.1,0.8-0.1,1.5-0.6,2.2c0.1-0.1,1,1.2,1,1.3c0.2,1.5-0.1,3.7,0.8,4.9
    	c0.6,0.7,1.4,1,1.7,2.1c0.2,0.8,0.6,2,1.3,2.5c-2.5,0.1,3.1,6.6,3.6,7c1.2,1,2.9,1.5,3.1,2.4c0.2,0.7,0.3,1,0.3,1.6
    	c0.1,1.6,0.4,3.3,0.8,4.8c-1.9,0.9,0.2,4-1,4.9c-1.1-1.1-2.3-2-3.7-2.5c-0.6-0.2-2.5-1-3-0.6c-0.7,0.4-2,0.4-2.4-0.6
    	c0,0-2.2-0.5-2.4-0.5c-0.7-0.3-2.6-0.1-3.5-0.1c-1.6-0.1-3.2-0.1-4.9-0.3c-0.3,0-1.1,0.2-1.4,0.1c-0.6-0.3,0.3-0.9-0.9-0.8
    	c-1.5,0.1-2.8,0.4-4.3,0.1c-1.8-0.4-3.2-0.1-5-0.1c-1.2,0-3.2-0.7-4.4-0.1c-0.3,0.2-0.2,1.1-0.5,1.1c-1.1,0-2.2,0-3.3,0
    	c-1.5,0-3.1,0.1-4.6,0.1c-3.3,0.1-4-1.7-3.3-4.8c0.4-1.7,1-3.6,0.1-5.3c-0.4-0.8-2-2.1-0.4-2.7c-0.9,0.5-2.7-0.3-1.6-1.4
    	c0.6-0.6,1.8-1.5,0.2-1.6c0.3-0.3,0.5-0.8,0.5-1.2c-0.5,1.2-1.1,0.3-1.9,0.3c0.3,0.4,0.5,0.7,0,0.4c0,0.4,0,0.8-0.1,1.2
    	c-0.7,0-1-0.5-0.8-1.2c-0.6,1-2.2-0.3-2.4-1c-0.3-1.2-0.4-3.3-1.3-4.2c-1,1.2-0.8-0.2-1.4-0.2c0.1,0.5,0.2,1,0.2,1.4
    	C1364.9,659.9,1366,658,1366.2,656.9"
        />
        <path
          id="mk"
          class="countryGroup country"
          d="M1450,339.2c0.9-0.1,0.3-0.9,0.3-1.4c0-0.7,1.3-1.1,1.5-2c0.2-0.8,0.8-1.2,1.5-1.5
    	c1.5-0.7,1.2,0.2,2.6,0.3c-0.3-1.1,0.5-0.7,1-1.2c0.3-0.2,0.6-0.1,0.9,0c0.7,0.2,0.8-0.5,1.4-0.6c0.7-0.1,2.2,0.2,2.8-0.3
    	c0.7-0.6,1.5,1.1,2,1.5c1.7,1.6,3.2,2,3.2,4.8c0,0.6,0.4,1.5,0.1,2.2c-0.2,0.4-0.9,0-0.8,0.6c0.1,0.9-1.6,1.5-2.6,1.2
    	c-1.1-0.3-2.3,0-3.2,0.7c-1.9,1.4-3.4,1.4-5.8,1.8c-1.3,0.2-0.7-0.7-1.8-0.4c-1.3,0.3-0.7-0.8-1.4-1.3
    	C1450.3,342.4,1450.6,340.8,1450,339.2"
        />
        <path
          id="bw"
          class="countryGroup country"
          d="M1451.3,912.8l0.8-24h6.8l1-31.8c2.4-0.6,8.6-0.9,12.1-2.2c1.1-0.4,3.8-0.9,4.8-0.3
    	c1.5,0.8,1.8,2.6,2.5,4c1.9-1.5,3.4-4.6,5.7-4.7c0.8,0,0.8,0.5,1.4,0.6c0.8,0.1,1.3-1,1.9-1.4c1.9-1.2,4-1,3.6-0.3
    	c-0.1,0.2,0,1.3,0.1,1.5c0.5,1.3,1.2,2.6,2.1,3.7c0.8,1.1,1.5,1.7,2.1,3.6c0.4,1.3,0.6,1.6,1,2.9c0.8,2.7,2.8,5,5.2,6.2
    	c2.6,1.3,4.9,1.1,4.1,5.2c2.4-0.6,4.2-0.3,3.2,2.9c-0.3,1.1-0.2,1.9,0.4,2.8c0.7,1,1.1,3.4,2.2,3.8c2.2,0.7,4,0.6,6.3,1.3
    	c1.7,0.6,0.2,1.4,0.8,2.3c0.4,0.7,1.9,0.5,2.2,1.4c-2.7,0.3-2.3,0.9-3.3,2.3c-1.1,1.6-4.1,0.8-5.5,2.2c-1.2,1.3-2.2,3.4-3.8,4.7
    	c-0.2,0.1-0.8,0.7-1.4,1.2c-1.1,0.8-2.3,1-3.1,2c-1.1,1.2-1.3,1.6-1.5,3.5c-0.2,2.3-1.6,2.8-3.2,4.8c-0.9,1.1-2.5,1.5-4.1,1.7
    	c-1.2,0.2-0.5,0.6-0.7,1.8c-0.3,1.5-1,2.8-1.5,4.2c-0.7,2.1-1.8,2.8-4,2.6c-1.9-0.2-1.7,1.2-4.2,0.7c-1-0.2-3.4-1.3-4.2-1.4
    	c-1.2-0.1-2.1-0.9-2.8-1.7c-1.1-1.3-4-2.3-5.5-0.4c-1.4,1.8-0.8,3.7-2,5.6c-0.6,0.9-1.5,1.5-2.3,2.1c-1,0.7-1.3,1.9-2.2,2.7
    	c-0.7,0.6-1.2,0.3-2,0.6c-0.2,0.2-0.3,0.5-0.2,0.8c-0.3,0.8-1.5,1-2.2,1c-1.1-0.1-1.7-0.3-2.8-0.4c-1-0.1-1.8-0.5-3.1,0.6
    	c-1.8-3.5,2-5,0.7-9.7c-0.5-1.8-1.2-3.8-2.1-5.4C1453.8,914.8,1452.3,914.1,1451.3,912.8"
        />
        <path
          id="ke"
          class="countryGroup country"
          d="M1560.8,707.6c1.5-1.1-2.3-5.7,3-4.6c0-0.3,0.3-0.9,0-1.4l3.1-0.4c-2.1-3.1-3-0.7-3.9,0.2
    	c-0.8,0.7-2.9-2.5-2.8-4.5c0.8-1.4,0.3-2.1,1.8-3.5c1.1-1,2-4.3,3.8-5.1c0.8-0.4,0.7-0.5,0.7-1.3c-0.1-1.2,1.3-1.7,1.6-2.8
    	c0.3-1.2,0.4-2.4,0.6-3.7c0.1-1.1-1-1.9-1.2-3.1c-0.5-2.8-2.2-3.4-2.5-4.6c-0.2-1-0.7-1.8-1.1-2.7c-0.2-0.4-0.3-0.8-0.4-1.3
    	c0-0.6,0.7-1,0.5-1.6c-0.2-0.6-0.8-0.7-1.3-0.9c-1.1-0.4-0.3-1.3-1.5-1.2c0.5-1.1-0.9-2.2-0.8-3c0.1-0.8,1.1-1.4,1.6-2
    	c1.9-2,4.2-1.4,6.6-1.4c1.4,0,2.8-0.1,4.2-0.1c0.7,0,1.4,0,2.1,0.1c0.6,0.1,0.8,1.1,1.5,1.4c2.2,0.8,4.6-0.2,6.8,0.8
    	c2.3,1.1,4.8,3.1,6.6,4.8c0.9,0.9,1.7,1.5,3,1.7c0.9,0.1,2.8-0.4,3.5,0c1.9,1.2,4.8,0.1,6.5,1.7c0.2-1.1,1.8-2.8,2.5-3.8
    	c1.2-1.7,3.9-2.4,5.8-3.3c2-0.9,2.3,1.2,3.8,2.1c1,0.6,2.1,0.2,3.2,0c0.8-0.2,1.9,0.4,2.7,0c-1.2,2.1-2.4,4.4-3.8,6.3
    	c-1.6,2-3,3.2-3,5.9c0,6.4,0.1,12.9,0.1,19.3c0,4.8-1,9.4,2.3,13.4c1.1,1.3,2.8,3.1,1.6,4.8c-0.7,1-2.8,2.9-4.1,1.7
    	c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2-0.2-0.4-0.5-0.6-0.8c0.3,0.8,1.2,2.2,0.4,3c-0.1-2-0.9,0.3-1.1,0.7c-0.4,0.9-1.2,1.1-2.1,1.2
    	c-2,0.3-2.8,2-2.1,3.7c0.3,0.8-1.1,2.9-1.7,3.4c-1,0.8-1.8,6.1-2.9,5.6c0.5,2.1-1.6,3.5-1.9,5.4c-0.8-0.6-0.7,0.5-1.4,0.3
    	c-0.9-0.2-2-1.7-2.7-2.3c-3-2.3-6.8-4.8-8.6-7.3c-0.5-0.7-1.4-0.8-0.4-1.9c1.4-1.6-1.1-3.5-2.4-4.3L1560.8,707.6"
        />
        <path
          id="jo"
          class="countryGroup country"
          d="M1560.4,444.5c-0.1-2.4,0.8-4.7,0.9-7.1c0-2.8,1.3-4.8,1.6-7.4c0.1-1.1-0.2-2.2-0.1-3.3
    	c0.1-1,0.4-2.1,0.5-3.1c0.1-2.1-0.2-4.7-0.3-6.9c-0.1-1.8,2.2-1.3,3.3-0.5c1.7,1.2,3.3,3,5.6,2.5c2.3-0.5,4.5-2.7,6.4-3.9
    	c2.5-1.6,4.9-3.5,7.5-4.9c0.8,2.6,1.8,5,2.7,7.6c-0.9,0.1-0.3,1.3,0.3,1.5c0.9,0.2,2.1,0.1,0.6,1.6c-4.6,2.8-9.2,4.3-14.8,5.7
    	l8.2,8.7c-0.7,0.4-2.7,0.3-3.2,1c-0.6,0.8,0.1,2.3-0.6,3.1c-1.1,1.3-3.8,0.2-5.3,1.5c-1.6,1.5-2.1,4.4-4.4,5.3
    	C1567.2,446.6,1562.5,445.1,1560.4,444.5"
        />
        <g id="mx" class="countryGroup">
          <path
            id="Mexico_mainland"
            class="country"
            d="M452,417.1c6-0.6,12-1.4,18.1-1.6c-0.5,0.6-1.1,1.1-1.4,1.8l23.6,10l20.2,0.1l0.1-3.8
    		l13,0c1.8,0,2,0.8,2.4,2.2c0.3,1.2,1.8,1.4,2.4,2.6c0.5,1,1.4,2.8,2.4,3.4c0.9,0.6,2.2,1.1,2.4,2.3c0.3,1.7,0.3,3.2,0.1,4.9
    		c-0.5,3.8,2.8,5.5,5.5,7.2c0.9,0.6,2.4,1.9,3.5,1.2c0.8-0.5,1.8-1.4,2.3-2.3c0.6-1.1,1.7-3,2.8-3.8c0.6-0.5,2-0.7,2.8-1
    		c2.1,0,4.2,0.4,6.2,0.7c0.6,0.1,1.3,1.7,1.9,2.2c0.9,0.9,2.1,2.9,2.4,4.1c0.3,1.7,0.7,3.3,0.8,5c0.1,1.8,1.2,2.8,1.8,4.5
    		c0.3,0.7,0.5,1.5,0.9,2.2c0.3,0.5,0.8,0.5,1.1,1c0.7,1.1-0.3,3-0.3,4.2c0,1.5,1.4,2.6,1.2,4.2c0.6,2.5,2.5,2,1.7,2.1
    		c1,0.1,1.7,0.9,2.8,1.6c0.7,0.4,1,1,1.7,1c1.8,0.1,3.2-0.2,4.4,1.3c1,1.3,1.8-0.7,3.1-0.7c-3.6,5.8-5.7,12.2-8.2,18.6
    		c-0.9,4.6,0.6-5.2,4.1-11.3c0.7-1.1,0.9-2.6,0.1-2.5c0.1,0-0.5,1.9-1.5,0.6c0,0.6-0.8,1.4-0.8,1.5c0.6,3.2-0.1,2.7-0.1,3.6
    		c0,0.1-1,2.6-1.1,2.7c0,0.1-0.7-0.5-0.8-0.5c0.1,1.4,0.5,1.3,0.4,1.9c0,0.6-0.7,1.7-0.8,3.8c0,0.5,0,0.2-0.5,0.7
    		c1.1,1.6-0.4,4-0.5,5.7c-0.2,2.1-1.6,3.8-1.7,6c-0.1,2.2,0.1,4.2,1.5,6.1c1.4,2,0.8,1.9,0,3.9c-0.1-0.8,0.9-1.9,0.4-2.6
    		c-0.9-1.3-1.1-2.7-1.9-4.1c0.3,1.3-0.2,2.6,0.3,3.8c0.4,1.1,1,1.2,1.1,2.6c0.2,2.8,1.3,5.7,2.7,8.1c1.6,2.7,2.2,4.9,2.9,7.9
    		c0.3,1.3,1.2,2.2,1.6,3.4c0.4,1,0.5,2,1.6,2.5c-0.6,0-1-0.8-1.4-0.4c1.3,0.3,0,2-0.4,2.5c1.2-0.1,0.1-1.4,1.2-1.6
    		c0.4,0,0.6-0.2,0.7-0.5c0.7,0.4,1.5,0.4,2.2,0.5c2.4,0.1,4.5,1.4,5.6,3.4c1,1.8,2,1.6,4,1.1c1.3-0.3,3.4-0.7,4.4-1.7
    		c-0.8,0.2-1.5,0.4-2.2,0.8c1-0.6,8.8-2.4,9.1-1.3c-0.5-2,5.5-3.1,6.3-1.5c-1.6-0.3,0,1.1-0.2,1.8c0.5-0.5,1-0.2,1.6-0.3
    		c0.4,0.1,0.8,0.1,1.2,0.1c0.1-0.3,0.2-0.5,0.4-0.8c0.9-0.4,2.9-2.3,0.7-2.4c0.5-0.4,1-0.8,1.5-1.2c-0.7,0.4-1.4,0.9-2.2,1.2
    		c0.9-1.5,2.9-2,4.3-2.9c2-1.1,1.9-2.8,2.8-4.7c0.5-1,1.3-1.4,1.9-2.2c0.6-0.8,0.5-1.9,0.6-2.9c0.3-2.3,1.5-3.9,2-6
    		c-0.2,0.3-0.4,0.6-0.6,0.8c1.2-3.2,5.7-3.8,8.6-4.2c2.1-0.2,3.8-0.8,5.8-1.5c2.6-1,4.8,0.1,7.4,0.4c-0.4-0.2-0.9-0.3-1.4-0.3
    		c1.2-0.3,2.3,0.4,3.4,0.7c-1.8-0.4,1.3-1.5,2.6-1c1.9,0.9,1.3,3.3,0.7,4.8c-0.8,2-2.5,3.1-3.9,4.6c-0.8,0.8-1.7,1.8-2,2.8
    		c-0.2,0.6,0.2,3-0.4,1.7c0.3,1.2-1.7,2.2-1.9,3.4c3.6-0.4-1,1.8-0.2,3c0.2-0.5,0.7-0.9,1.3-0.9c-1,1.4-1.3,3.1-2,4.7
    		c-0.6,1.2-2,3.7-2.1,5.1c-1.7-1.9-0.4-3.3-0.8-5.3c-1.1,0.8-1,0.8-1.6,1.9c-0.5,0.9-1.2,0.8-1.9,1.2c-1.6,0.9-2.7,3.4-3.6,5
    		c-0.5-0.5-1.5-1.3-2,0c-0.3,1-0.9,0.5-2.3,0.5c-3.1,0-6.2,0-9.3,0c-0.7,0-2.7-0.3-2.8,0.8c-0.1,0.9-0.1,1.6-0.2,2.5
    		c-0.2,2.3-1.9,1.6-3.8,1.6c1.3,1.3,2.3,3,3.8,3.8c1.5,0.8,1.3,2.5,2.8,3.2c0.3,0.1-0.4,3.4-0.5,3.2c-2.4,0.9-6.3-0.2-9.8-0.1
    		c-4.6,8.6-4,7.3-3.7,8.4c0.4,0.6-1.2,4.3-1.7,4.9c-0.7-0.9-1.5-1.7-2.2-2.5c-0.4-0.5-2.3-4-1-2.1c-0.3-1-1.6-1.5-2.2-2.2
    		c-1-1-1.7-2.3-2.8-3.3c-2.2-2-3.4-3.9-6.4-4.9c-0.2,1.1,2.6,1.6,3.2,2.4c-1.7-0.9-3.5-1.8-5.5-1.7c0.5-0.2,1-0.6,1.2-1.2
    		c-0.6-0.1-1.1,0.1-1.4,0.6c0.1-1.8-1.5-1.2-1.7,0.1c0.5,0,1,0,1.5,0.3c-2.7,0.2-4,1.8-6.4,2.5c-2.2,0.7-4,1.7-6.3,2.1
    		c-1.7,0.3-2.4,0.1-4.1-0.8c-1.8-1-3-1.1-5-1.5c-1.1-0.2-1.7-0.7-2.5-1.4c-1-0.9-2-1.1-3.2-1.5c-1-0.4-1.2-1.2-1.9-1.8
    		c-0.9-0.8-2.5-0.9-3.6-1.2c-1.1-0.3-2.3-0.4-3.4-0.9c-1.1-0.5-1.9-1.4-3-1.9c-2.6-1.1-5.1-1.8-7.4-3.5c-1.9-1.4-2.9-3.5-4.5-5
    		c-0.6-0.6-1.5-0.4-2.3-0.2c-1.2,0.2-3.1-0.6-4.2-1c-1.8-0.6-4.3-1.5-5.4-3.1c-0.6-0.8-1-1.7-1.5-2.5c-0.8-1.4-2-1.5-3.3-2.4
    		c-0.3-1.3-3.6-2-4.8-3.1c-0.6-0.6-0.4-1.4-1-2c-0.7-0.7-1.3-2.2-1.7-3.1c-0.5-1-1.3-3.9,0-4.7c0.9-0.6,1.9-0.3,2.7-1.1
    		c0.7-0.8-0.9-1.5-1.5-1.3c0.3-1.1,4.2-4.7,3.1-6c-1-1.2-1.4-2-1.9-3.5c-0.6-1.6,0.1-2.9,0-4.5c-0.4-3.5-3.7-6.4-5.3-9.4
    		c-1.8-3.5-3.7-6.1-6.9-8.6c0.8,0.4,1.5,1.4,1.9,0.1c-1.4,0.2-3.7-1.1-3.1-2.6c0.4-1.1,0.5-4.2-1.5-3.4c0-1.3-0.2-0.3-1.1-0.8
    		c-0.6-0.3-1.1-0.9-1.8-1.1c0.9,0.2,1,0.1,0.2-0.2c-0.4-0.4-0.3-1.1-0.7-1.5c-0.4,0.4-0.9,0.7-1.5,0.9c-0.2-0.9,0.9-1,1-1.8
    		c-1.1,1-1.6,0.4-3,0.1c0.3-0.2,0.5-0.3,0.8-0.5c-0.6-0.5-1-0.4-0.9,0.4c0-1,0.1-2.1,0.6-3c0.2-0.3,1.5-2.7,1.3-1.4
    		c0.3-0.6,0.9-1.6,1.4-0.7c0.1-0.8,0-1.4-0.9-0.8c0.4-0.9,0.2-2.1-0.7-2.7c-0.7-0.4-1.5,0.4-2.3-0.4c-0.9-0.8,0.2-3.2-0.9-3.5
    		c-0.7-0.2-3.1-0.5-3.2-1.5c-0.1-1.1-0.2-4.9,1.3-4.9c-0.7-0.1-2.3-0.3-2.1-0.9c-0.3,0.3-0.5,0.6-0.6,1c0-1.1-1.5-1.1-2.1-1.8
    		c-0.5-0.6-0.6-1.6-0.9-2.3c-0.3-0.7-1.1-0.8-1.5-1.3c-0.6-0.9-0.4-2.6-1.1-3.3c-1.2-1.3-0.3-2.6-0.7-4.1c-1.3,1-0.9-1.8-1-2.4
    		c-0.5-2-0.5-3.2-0.4-5.3c0.1-1.7-1.1-3.2-0.7-4.9c0.2-0.8,1.5-2.7,1.3-3.3c-0.4-1-2-1-2.9-1.3c-1.4-0.5,0.4-2.3-1.7-2.5
    		c-0.7,0-1,0.8-1.9,0.7c-0.8-0.1-1.4-0.7-1.9-1.2c-1-1.2-2.4-1.5-3.4-2.8c0.1,0.9,1.1,1.8,0.7,2.7c-0.5,1.2-1.3,2.4-1.9,3.6
    		c-0.6,1.2,0.3,2.3,0,3.5c-0.3,1.3-0.4,2.7-0.9,3.9c-1.2,3,0.1,4.2,2,6.5c1.7,2,2.2,3.5,2.1,6.1c1.2-0.9,1.8,1.3,2,2.1
    		c0.1,0.4,0,1.3,0.4,1.6c0.3,0.3,1.4,0.5,1.1,1.1c-0.6,1.3-1,4.9,0.2,6c1.6,1.5,1.4,4.1,3.3,5.2c-1.1,0.8-0.2,3.4-0.1,4.5
    		c0.7-0.6,0.3-2.2,0.1-3c3.7,0.6,0.9,12.4,3.3,11.8c-0.2,1.2-0.3,2.6,0.1,3.7c0.4,1.2,1.4,1.9,0.4,3.2c-1.2,1.7-0.4,4.9,2.1,4.6
    		c-1.6,0.2-0.2,0.2,0-0.1c0.3-0.4,0.3-0.9,0.6-1.3c0.4,0.9,1.9,2.2,2.7,2.9c0.4,0.3,0.3,2.5,0.6,3.1c0.6,1.4,2.6,2.3,0.8,4
    		c-0.7,0.7-3.3,2.7-4.2,2.3c-1.3-0.6-0.6-3.4-1.1-4.4c-0.4-0.8-1.1-1.4-1.7-1.9c-1-0.9-1.6-2.2-2.5-3.2c-1.3-1.5-3.4-2.1-4.5-3.8
    		c-0.9-1.4-3.1-2.3-2.6-4.3c-0.1,0.5-0.3,1-0.5,1.4c0.2-4.2,3.6-6.8,1.6-11.1c-0.8-1.7-2.6-1.9-3.6-3.4c-0.5-0.7-1.2-1.8-1.3-2.7
    		c0-0.6,0.7-0.8,0.5-1.5c-0.4,0.6-0.5,1.6-1.2,1.9c-0.7,0.3-1.3-0.3-2.1,0.2c-0.6,0.4-1-0.5-1.2-0.9c-0.4-1-1.2-1.1-1.9-1.7
    		c-0.1-0.3-0.3-0.6-0.5-0.8c-0.4-0.1-0.8-0.2-1.3-0.3c-0.8-0.6-0.4-0.9-0.5-1.6c-0.3-1.8-3.1-2.1-3.2-4.1c1.7,0.2,3.9,1.1,5.4-0.2
    		c-0.1,1.9,3.3,1.4,2.3,3.3c0.9-0.7,1.3-3,0.4-3.8c0,0.4-0.4,2.2-1.2,1.8c-0.5-0.3,0.2-1.7,0.2-2.1c-0.3,0.2-0.5,0.3-0.8,0.5
    		c0.8-1.8,3.5-3.6,2.4-5.9c-1.1-2.4-2.4-5.5-4.5-7.1c-1.8-1.3-3.2-2.4-3.6-4.7c-0.2-0.9,0.4-1.5,0.6-2.3c0.2-1.1-0.8-1.5-0.3-2.1
    		c-0.2,0.2-0.5,0.4-0.7,0.6c-0.5-1.9,1.5-2.8-0.1-4.6c-0.7-0.8,0.2-1.5,0.2-2.3c0-1.2-0.9-2-1.1-3.1c-0.1-0.8,0.3-1.5,0.9-2
    		c0.5-0.4-0.2-1.2-0.4-1.6c-0.2-0.3-0.4-0.6-0.6-0.9c0.1-0.5,0.2-1,0.3-1.4c-0.2-1-0.3-0.7,0.2-1.8"
          />
          <path
            id="path2614"
            class="countryIsland"
            d="M497.7,482.3c0.1,0.5,0.3,1,0.6,1.5c0-0.9-0.5-1.7-1.2-2.3C497.3,481.7,497.5,482,497.7,482.3"
          />
          <path
            id="path2594"
            class="countryIsland"
            d="M433.2,446c-0.9,0.5-0.9,1.5-0.4,2.2C433.4,447.5,432.8,446.7,433.2,446"
          />
          <path
            id="path2596"
            class="countryIsland"
            d="M453.6,453c-0.5,0.6-2,2.5-0.7,3C453.3,455.2,454,454.1,453.6,453"
          />
          <path
            id="path2600"
            class="countryIsland"
            d="M467.1,483.7c-0.1,0.6,0.2,0.8,0.7,0.7c-0.3,0.6-0.1,1.2,0.4,1.6c-1.4-1.8,0.8-4.2,0.7-6
    		C468.8,481.3,467.9,482.6,467.1,483.7"
          />
          <path
            id="path2602"
            class="countryIsland"
            d="M470.5,447.5c0.2-0.9-0.4-4.5-1.5-4.6C467.3,442.8,470.3,447.2,470.5,447.5"
          />
          <path
            id="path2604"
            class="countryIsland"
            d="M468.5,486.2c0.6,0.6,1.1,1.3,1.9,1.4C470.2,486.8,469.4,486.1,468.5,486.2"
          />
          <path
            id="path2606"
            class="countryIsland"
            d="M476.1,449.8c1.2-1.2,0.9-2.8,1.6-4.2c-2,0.3-1.9,2.1-3.1,3.4
    		C475.1,449.2,475.6,449.5,476.1,449.8"
          />
          <path
            id="path2608"
            class="countryIsland"
            d="M478.7,473c-1,0.1-1.4,1-1.4,1.9C477.6,474.2,478.7,473.9,478.7,473"
          />
          <path
            id="path2610"
            class="countryIsland"
            d="M479.3,481.3c-0.1,0.8,0,1.5,0.4,2.2C480.6,482.7,480,481.7,479.3,481.3"
          />
          <path
            id="path2612"
            class="countryIsland"
            d="M484.4,489.8c0.1-0.6-0.1-1.2-0.6-1.5C483.8,488.9,484,489.4,484.4,489.8"
          />
          <path
            id="path2616"
            class="countryIsland"
            d="M498.3,481.8c-0.3,0.6-0.2,1.1,0.4,1.4C498.8,482.7,498.7,482.2,498.3,481.8"
          />
          <path
            id="path2618"
            class="countryIsland"
            d="M503.6,510.9c-0.1,0.6,0.3,1,0.9,1C504.5,511.3,504.2,510.9,503.6,510.9"
          />
          <path
            id="path2634"
            class="countryIsland"
            d="M610.4,537.3c1,0.2,1.7-0.8,2.6-1.1C612,536.1,611.1,536.9,610.4,537.3"
          />
          <path
            id="path2772"
            class="countryIsland"
            d="M649,523.3c1-0.8,1.9-1.7,2.4-2.9C650.4,521.2,648.3,521.3,649,523.3"
          />
        </g>
        <g id="ae" class="countryGroup">
          <path
            id="United_Arab_Emirates_Abu_al_Abyad"
            class="countryIsland"
            d="M1703.1,489.8c0.5,0,0.9-0.2,1.2-0.6c-0.7-0.5-1.5-0.4-1.9,0.4
    		C1702.7,489.7,1702.9,489.7,1703.1,489.8"
          />
          <path
            id="United_Arab_Emirates_mainland"
            class="country"
            d="M1686.5,488.5c0.5-0.3,0.7-0.1,0.6,0.6c0.2-0.2,0.5-0.4,0.7-0.6
    		c0.5,0.7,0,1.7,0.6,2.3c0.5,0.6,1.7,0.5,2.4,0.4c0.9,0,1.7-0.2,2.3-0.8c0.3-0.3,0.7-1.1,1.1-1.2c0.5-0.1,1.6,0.6,2.2,0.6
    		c0.9,0,1.9,0.1,2.8,0.1c2.5-0.1,4.3,1.1,6.8-0.4c-0.2-0.3-0.5-0.7-0.7-1c0.8-0.3,0.7,0.6,1.3,0.6c0.9-0.1,1.7-1.2,2-1.9
    		c1-2,1.1-3.1,2.9-4.5c1.7-1.4,2.4-4,4-5.3c1.8-1.5,2.8-1.9,3.4-4.3c1.3,0.1,0.7,1.9,0.7,2.7c0,1.5,1.6,0.7,1.8,2.4
    		c0.2,1.7,1.3,3.8,0,5.1c-2.8,2.7-1.9,0.8-2.3-0.4c-0.4-1.2-1.3,1-1.2,1.7c0.1,0.5-0.2,0.5-0.1,1.1c0.1,0.6,0.5,1.5,0.5,2.3
    		c0,2,2,1.1,1.2,2.5c-0.7,1.1-2.8,0.1-3.6,1.1c1.9,1-0.9,6-1.3,9c0.1,2-0.9,2.4-2.6,2c-2.1-0.5-4.3-0.7-6.5-1
    		c-2.2-0.3-4.5-0.7-6.8-0.9c-1.7-0.2-3.7,0.1-4.8-1.4c-1.1-1.5-2.2-3-3.4-4.5c-0.8-1-1.7-2-2.5-3
    		C1687.2,490.7,1687.1,489.6,1686.5,488.5"
          />
        </g>
        <g id="bz" class="countryGroup">
          <path
            id="Belize_Blackbird_Cay"
            class="countryIsland"
            d="M638.7,548.1c0.6-0.2,0.9-0.6,0.7-1.2C639.2,547.3,638.9,547.7,638.7,548.1"
          />
          <path
            id="Belize_mainland"
            class="country"
            d="M627.1,561.1c0.6-3.7,1.6-7.3,1.9-11c0.1-1.5,0.5-9.2,3.2-6.2c0.8-1.4,2.7-5.7,4.8-5.3
    		c-0.4,0.4-0.7,0.9-0.6,1.5c3.2-1.6,0.4,4.6,0.1,5.7c-0.4,1.3-0.6,2.9-0.8,4.2c-0.2,1,0.2,1.7-0.1,2.7c-0.3,1-0.8,1.9-1.2,2.9
    		c0.6-2-0.5,0.5-0.7,0.8c-0.6,0.8-1.5,1.1-2.2,1.7c-0.7,0.5-1.2,1.2-1.6,2c-0.2,0.3-0.3,0.7-0.3,1
    		C628.7,560.9,627.9,561,627.1,561.1"
          />
        </g>
        <g id="br" class="countryGroup">
          <path
            id="Brazil_mainland"
            class="country"
            d="M736.8,763.7c0.5-1,0.3-1.5,1.4-1.7c1.1-0.2-0.2-1.6,0.1-3.1c0.5-2.4,2.4-2.6,4-3.7
    		c1.1-0.7,0.3-2.4,0-3.6c-0.3-1.1,0.3-2.1,0.9-2.9c1.2-1.7-0.1-4.2,1.2-5.8c0.6-0.8,2-0.8,2.8-1.6c0.7-0.7,0.7-1.4,1.6-1.7
    		c1.2-0.4,1.9-1.6,3-2.1c1.1-0.4,2.7-0.4,3.9-0.7c1.4-0.3,2.5-0.2,3.6-1.3c0.2-0.2,0.4-0.9,2-1.2c0.6-0.1,0.7,0.1,1.5,0.3
    		c0.7,0.1,1.4,0.9,1.8,1.5c0.2-0.2,0.4-0.5,0.6-0.7c1,1.7,1.6-1.7,1.7-2.3c0.4-2.8,0.8-5.5,1.1-8.3c0.4-3.8,0.8-7.6,1.6-11.3
    		c0.3-1.6,1-3,0.6-4.6c-0.4-1.6-1.5-2.4-1.5-4.1c0-1.7-0.9-2-2.2-3.2c-1.4-1.3-1.5-2.8-1.5-4.5c0-1-0.2-2.2,0.4-3.2
    		c1.4-1.5,4-2.2,5.1-1.1c1.2,0.6,1.6,0.3,1.5-1.1c-0.1-2.1-3.4-3.3-5.2-2.2c0-1.5-0.8-4.2-0.1-5.5c0.7-1.3,3.2-0.2,4.1-0.2
    		c2.2,0,4.3,0,6.4,0c0.4,0,1.6,0.1,1.7-0.5c0.1-0.3-0.1-1.2,0.3-1.5c1.5-1,1.2,2.1,2.4,1.5c1.1-0.5,1.7-1.9,2.6-2.6
    		c1.7-1.3,2.5,0.7,2.9,1.6c0.8,1.9,0.8,2.8,0.6,4.7c0,0.4-0.3,1.4,0.5,1.3c1.1-0.2,0.9-0.8,1.8,0c1.1,1.1,1.8,2.5,3.2,3.1
    		c1.9,0.9,3.2-0.5,4.6-1.2c2.9-1.4,1.4,2,1.8,2.8c0.7-0.2,1.9-2.1,2.3-2.7c0.7-1.1,1.1-1.7,2.3-2.2c1.1-0.4,1.7-0.6,2.5-1.4
    		c0.6-0.6,2.1-1.6,2-0.1c0.9-1.4,1.8-1.9,2.2-3.6c0.3-1.3,0.8-1.3,2-1.7c0.5-0.2,2.4-1.2,2.6-1.5c1.4-2.1,0.3-2.5-1.3-2.3
    		c-0.8,0.1-2.1,0.1-2.8-0.5c-0.4-0.3,0.1-1.7-0.1-2.1c-0.7-2-1.5-2.8-1.5-6.1c0-1.4-1-2.4-2.1-3.5c-0.6-0.6-2.6-2.9-1.7-3.9
    		c0.7,0.3,1,1.2,1.7,1.3c1,0.1,3-0.6,3.3,0.8c0.2,1,0.6,1.3,1.7,1.1c1.8-0.2,2.9,0.7,3.2,0.1c1.4-2.6,2.6,3.2,4.6,2.8
    		c1.3-0.2,0.1-2.7,0.8-3.7c0.4-0.6,1.5-0.3,2.1-1c0.9-1,1.7,0.4,2.5,0.1c1.4-0.5,4.5-1.3,5.1-2.5c0-0.1,1.7-1,1.9-1
    		c0.8,0.1,1.8,0.5,2.3-0.5c0.9-1.6,4.1-2.6,1.8-5.6c1.1-0.2,2,0,3.1-0.3c0.9-0.3,1.6,0,2.2,0.9c1.3,1.9-0.4,3.3-0.7,5
    		c-0.2,0.9,1.9,0.4,3,1.5c0.2,0.2-0.3,1-0.1,1.5c0.3,0.8,0.7,1.4,1.2,2.2c0.5,0.8-1.8,3-2,3.6c-0.2,0.7-0.2,1.4-0.4,2.2
    		c-0.3,1.3-1.1,2.4-1.2,3.7c-0.2,2,0,3.9,1.4,5.2c0.9,0.9-0.1,3.9,1.1,3.8c-0.1,0.2-0.2,0.5-0.3,0.7c1.5,0.2,2.6,1.8,3.2,3
    		c0.5,0.9,1.5,0.1,2.1,0.9c0.5,0.8,1.4,0.6,1.9,0.7c0.5,0.1,1.1-1.3,1.7-1.8c2.1-1.7,3.5-1.6,5.2-2.3c1-0.4,1.4,0.2,2.3-0.8
    		c1.1-1.3,2.2-2,4.1-1.4c1.5,0.5,2.3-0.5,3.9,0c1,0.3,3.9,1.8,4.3,0c0.4-1.6-1.7-1.8-0.9-3.3c1.4-2.8,1.7-0.4,3.3-0.8
    		c1-0.3,1.8-0.5,2.7-0.9c2-0.7,1.9-0.3,3.4,0.9c0.3,0.2,0.1-0.6,0.4,0c0.4,0.7,1.1,0.9,1.8,1.4c0.8,0.6,1.6,0.7,2.5,1.2
    		c0.4,0.4,0.9-1.1,2.9-1.8c0.5-0.3,1.1,0.4,1.6,0.4c0.6,0.1,3.1-0.6,2.6,0.7c0.7-0.3,1.8,0.5,2.4,0c0.8-0.7,1.6-1.1,2.2-2
    		c0.9-1.3,2.5-5.7,3.4-7c2.8-4.2,5.4-7.7,4.1-10.4c0.1,0.6,1.6,3.7,2,4.1c-0.3-0.9-1.1-2.1-0.3-3c2.2,1.4,2.5,3.5,2.2,5.9
    		c0.1-0.7,0.2-1.5,0.8-1.9c-0.1,3.1,0.2,6.4,1.3,9.4c0.5,1.2,2.3,4.9,1,6c1-0.3,1.2,0.8,1.6,1.5c0.6,1.3,2.2,0.8,3.3,1.3
    		c2.5,1.1,2.1,4.1-0.2,5c0.6-0.2,1.2-0.1,1.7,0.4c-1,1-2.7,1.8-3,3.2c-0.2,0.7-1,1.1-1.4,1.6c-0.6,0.9-0.9,1.9-1.6,2.7
    		c-1,1.1-2.2,1.9-3.3,2.9c-1.3,1.2-2.1,2.9-3.2,4.3c-1.1,1.3-1.4,2.3-1.3,4c0.1,0.9-1.6,1.1-1.7,2.4c-0.1,1.7-1.3,0.8-2.2,0.8
    		c-0.8,0-3.4,1.2-3.7,2c0.7-0.2,4.6-0.9,3.5,0.9c0.7-0.8,1.9-0.7,2.8-1.3c1-0.6,1.8-1.3,3-1.8c1.5-0.6,3.6-2.4,4.4-3.8
    		c1.9,1.1,0.1,3.9,1.3,5.5c0.5,0.6,0,2.2,0.8,2.5c0.7,0.2,1.9-0.2,1.4,1c1.6,0.3,4.5-2.6,4.8,0.7c-0.1-1,0.9-1.2,1.6-1.4
    		c0.4,0,0.7-0.2,0.8-0.5c0.3-0.8,1.1-0.5,1.8-0.6c-0.9,2.7-1.6,4.4-1.5,7.2c1.2-1.6,0.5-5,2.5-6c1.4-0.6,1.6-0.8,2.4-2.1
    		c1.3-2.2,1.7-0.6,3,0.3c0-0.5-0.1-0.9-0.3-1.3c0.8,0,1.5-0.2,2.2-0.4c-0.8-0.4-1.6-0.1-2.4,0.1c-0.2-1.1,0.5-1.1,1.1-1.7
    		c0.9-0.9-0.1-2.8,1-3.8c1.3-1.3,0.9-0.2,1.8-0.5c0.6-0.2,1.9-1.8,2-0.2c1.2-1.2,1.3,0.5,2.4,0.1c-0.5-0.4-0.6-1-0.3-1.6
    		c0.7,0.2,2.3,0.6,2,1.7c0.2-0.1,0.5-0.2,0.7-0.4c0.1,0.3,0.2,0.5,0.3,0.8c0.6-0.5,0.7-0.5,0.7,0.3c0.6-0.2,1-0.7,1-1.3
    		c-0.4,0.9,0.9,1.1,0.6,2c0.1-0.6,0.5-1,1.1-0.7c0,0.6-0.4,1.1-1,1.2c1-0.6,2,0.9,3-0.1c-0.1,1.4,0.9,0.6,1.2-0.2
    		c-0.4,1.6,1,1,0.9,2c0.4-0.2,0.7-0.4,0.9-0.8c-0.1,0.9,1,0.6,1.2,1.4c0.2-0.2,0.5-0.5,0.7-0.7c0,0.5,0.2,1,0.6,1.4
    		c0.2-0.2,0.5-0.4,0.7-0.6c0.1,0.7,0.4,1.1,1.1,0.4c-0.5,0.4-0.7,1-0.3,1.5c0.1-0.6,0.6-1,1.1-1.1c-0.8,0.7-0.5,2.3,0.2,3
    		c0.3-0.6-0.1-1.6,0.8-2c1.5-0.6,0.8,0.5,1.5,0.7c0-0.6,0.3-1.1,0.9-1.3c-0.2,0.5-0.4,1-0.4,1.5c1.7-1-0.2,2,1.6,0.9
    		c-0.2,0.2-0.5,0.5-0.7,0.7c0.6,0.2,1.1-0.1,1.4-0.6c-0.5,1.1,1.2,0.5,0.5,1.9c-0.7,1.3-0.4,2.3-2,2.8c0.6-0.4,1.1,0,1.2,0.6
    		c0-0.9,1-2.3,1.9-1.1c0.9,1.3-0.7,1.7-1.2,2.6c-0.4,0.8-1.2,3.3-0.9,4.2c0.3,1-1.2,1.8-0.5,2.5c0.1-1.4,1.7-1.8,2.3-2.9
    		c0.6-1.1,0.2-2.9,0.8-3.8c0.3-0.5,1.8-1.5,2.2-0.7c0.8,1.3-1.7,2-1.8,3.1c0.7-0.5,1-0.3,0.7,0.5c0.8-0.4,2-3.7,2.5-2
    		c0.1-1.2,2.3-1.8,3.2-1.3c-0.1-2.3,4.6,0.2,5.5,0.7c0.6,0.3,3.9,1.7,3.9,2.3c0.7-0.5,2.6,0.7,2.2-0.8c0.9,0.2,1.6,0.9,2.4,1.3
    		c1,0.4,2.1,0.4,2.9,1.2c0-1.4,0.8-1.2,1.9-1.3c2.2-0.1,4.1-0.9,6.3-0.5c4,0.9,7.7,4.3,10.9,6.8c1,0.8,2.2,1,3.1,2
    		c0.8,1,1.5,2.1,2.2,3.1c0.9,1.1,2.1,1.7,2.9,2.8c0.9,1.2,2.5,1.3,3.4,2.6c1,1.2,2.3,0.7,3.4,1.8c1.3,1.2,3.5,0.7,5,0.5
    		c3.6-0.4,6.2,0.9,7.2,4.4c0.4,1.4,1,2.7,1,4.2c0,0.4-0.4,1.2,0,1.3c0.4,0.2,0.6,0.7,0.8,1.1c0.7,1.5,1.8,5,0.9,6.6
    		c0.2-0.4,0.4-0.7,0.6-1.1c-0.2,1.6,1.1,3.6-0.6,4.8c0.3,0.1,0.5,0.1,0.8,0.2c-0.7,1.4,0.4,3-0.4,4.2c-0.8,1.3-0.2,3-0.9,4.3
    		c-0.8,1.6-1,3.3-1.8,4.9c-0.8,1.7-2.5,2.9-3.2,4.7c-0.2-0.2-0.5-0.4-0.7-0.6c0.1,0.7-0.2,1.6-0.9,0.4c0.1,0.5,0.4,0.7,0.9,0.7
    		c-1.1,2.1-2.6,4.8-4.3,6.3c-0.7,0.6-1.7,0.9-2.4,1.5c-0.8,0.7-1.3,1.5-2.3,2c0.2-0.6-0.2-1-0.8-1.2c-0.1,0.7,0.8,0.9,0.7,1.5
    		c-0.2,0.8-0.8,1.9-1.7,0.9c0.4,0.5,1.3,1,0.8,1.8c-0.3,0.5-1.7,1.9-1.1,0.3c-0.5,0.7-0.3,1.6-1,2.2c0.3-0.1,0.6-0.2,0.9-0.3
    		c-1.6,2.3-4.1,13.7-8.2,13c0.8-1.7-1-2-1.5-3.4c0,0.8-0.9,3-1.6,1.3c0.2,0.7,1.3,1,1.3,1.7c-0.1,1.3-1.7,2.3-1.3,3.7
    		c-1.2-0.8-0.9,1.2-0.9,2c0.1-0.4,0.2-0.7,0.4-1.1c0.4,1,0.4,1.9,0.4,2.9c-0.3-0.2-0.5-0.5-0.8-0.7c0.6,0.5,1.2,2.2,0.9,3
    		c0.2-0.5,0.4-1,0.6-1.5c0.1,3-0.9,5.8-0.4,8.8c0.4,2.6,1.5,5.4,1.7,8c0.2,4.7-2.7,9-1.4,13.8c0.7,2.7-1,3-2.1,5
    		c-1.3,2.3-1.5,5.2-1.2,7.8c0.2,1.9,1.3,5.6-0.9,6.5c-2.1,0.9-0.4,5-2.9,5.3c1.7,1.5-1.2,3.5-2.2,4.4c-0.9,0.8-0.6,1.4-0.9,2.5
    		c-0.4,1.6-1.2,3.1-0.9,4.8c0.1,0.9,0.8,2.3,0.1,3.1c-1.1,1.3-2.8,1.3-4.2,2.1c-0.9,0.6-1.9,1.6-2.3,2.6c-0.3,0.7,0,1.3,0.2,1.9
    		c0.2,0.6-0.6,1-0.3,1.7c-1-1.1-3.8-0.5-5-0.3c-0.7,0.2-1.9,0.5-2.6,0.2c-1-0.6-0.3-1.6-0.4-2.5c-2.3,0.3-0.4,1.2-0.7,2.4
    		c-0.3,1.2-4.9,0.7-6,1.3c0.7-0.8,1.9-0.4,2.8-0.7c-0.9-0.8-2-1.3-3.2-0.6c-1,0.6-1.5,0.1-2.6,0.3c-0.8,0.1-2.1,0.2-2.4,1.1
    		c-0.3,0.9,1.1,1.2,1.6,1.6c-1.1,0.9-2.7-0.1-3.4,1c-0.6,0.9-1.7,1-2.6,1.7c-0.8,0.5,0.2,1.4-0.6,1.9c-1.1,0.7-2.9-1.3-4.5,0
    		c-0.2,0.1-1.2,2.1-1.6,1.6c-0.3-0.4-0.1-1-0.9-0.9c0.4,1.3-1.3,1.5-2.2,2.1c-1.2,0.8-1.7,1.9-2.6,2.9c-1,1.1-2.5,1.4-3.6,2.3
    		c-0.9,0.8-1.4,2.1-2.5,2.8c0.2,0.1,0.5,0.3,0.7,0.4c-0.8,0.8-1.6,1.8-2.1,2.9c-0.1-0.5,0.1-1,0.4-1.4c-0.8,0.3-1.5,0.1-2.2-0.2
    		c0.3,1.5-0.5,2.3-1.9,1.2c0,0.9,2,1.7,2.8,1.7c-0.5,0.5-0.7,1.2-0.9,1.9c-0.1,0.5-1.2,0.4-1.6,0.7c0.5,0,1,0,1.4,0.1
    		c-0.2,0.7,0.1,1.4-0.1,2c-0.2,0.8-1.1,0.9-1.3,0c0.3,0.6,0.2,1.4,0.8,1.7c0.9,0.4,0.4,2.2,0.6,2.9c0.3,1.1,1.7,2.7,1.1,4.1
    		c1.1-0.2,0.9,1,0.1,1.2c1,0.7,0.2,3.8,1,5.1c0.7,1.2-0.6,4.2-0.6,5.4c-0.4-0.3-0.7-0.6-0.9-1c0.3,0.6,0.2,1.3,1,1.6
    		c-0.8,1.1-2.1,1.8-3.1,2.7c-1.1,1-1.9,2.2-2.5,3.5c-1.7,3.1-2.1,6.4-3,9.7c-0.8,3.1-2.4,6-4.2,8.7c-1.7,2.6-4.3,4.4-6.3,6.8
    		c0.4-0.9-0.2-2.1-0.4-3c1.1,0.3,1.5,0.4,2.4-0.3c0.8-0.6,1.3-1.9,2.1-2.3c1-0.6,1.2-1.1,1.2-2.3c0-0.8,0.1-2.1,1.1-1
    		c-0.4-1.1,0.2-1.6,0.9-2.4c0.6-0.7,0.7-1.4,0.5-2.2c-0.1-0.6-0.7-2.6,0.6-1.4c0-0.3-0.4-2.2-0.9-2.1c-0.3,0.1-2,1.4-2.1,1.8
    		c-0.5-0.5-0.7-1.2-1.5-1.4c-0.8-0.3-0.7-1.5-1.4-1.9c-0.1,2,0.9,1.9,1.7,3.3c-1.7,0.4-0.5,2.3-0.6,3.4c-0.1-0.5-0.3-1-0.6-1.4
    		c-0.2,0.7,0.4,1.5,0.1,2.1c-0.2,0.2-0.7,0.2-0.6,0.6c0.3,1.6-1.1,2.5-2.2,3.2c-0.9,0.5-0.5,1.7-0.8,2.5c-0.2,0.7-2,2.5-0.3,2.7
    		c-0.9,0.6-0.3,1.8,0.7,1.1c0.1,0.9-0.5,1.8-0.9,2.5c-0.6,1.3-0.6,2.8-0.8,4.2c-0.4,2.7-2,4.3-3.4,6.5c-0.6,0.9-1.3,1.4-1.8,1.2
    		c-0.5-0.2-1.1-1.3-1.4-1.9c-0.4-1-0.3-1.9-0.3-2.8c-0.1-1.2,1-2,1.5-3c0.8-1.5-0.9-2.2-1.9-2.6c-1.2-0.5-1.7-1.6-2.4-2.6
    		c-1.7-2.4-4-2.3-5.5-4c-1.3-1.3-2.8-2.1-4.4-3c-0.6-0.3-0.8-0.8-1.4-1.1c-1.6,1-3.6-2.5-5.3-3.9c0.9,1.2-2.6,3.3-2.6,0.7
    		c0.1-2.1-2.5-3.4-4-4.5c-1.1-0.8-3.3-3.7-4.9-2.5c-0.5,0.4-0.6,1.1-1.4,1.4c-0.8,0.2-1.9,0.1-2.7-0.8c1.5-0.6,1.1-3,2.9-3.8
    		c1.6-0.6,2.2-2.8,2.8-4.5c0.7-1.9,2-3.4,2.9-5.2c0.5-0.9,1.6-3.2,2.8-2c0.3-0.6,0-1.1-0.6-1.3c1-1.2,2-2.7,3.3-3.4
    		c1.4-0.8,1.6-1.4,2.6-2.4c0.9-1,2.2-1.2,3.3-1.8c0.9-0.4,2.5-2.3,3.6-1.8c0.2-1.8,0.2-3.2,0.1-5c-0.1-2.2-0.3-3.3-1-5.3
    		c-0.5-1.4-0.8-3-2.4-3.6c-0.9-0.3-2.6-0.5-2.8,0.8c-0.3-0.3-1-0.1-1.5-0.4c-0.4-0.2-0.2-1.2-0.2-1.8c-0.1-1.7,0.4-2.9,0.7-4.7
    		c0.1-1.2-0.1-2.9,0.4-4c0.6-1.1-1-2.1,0.3-3c-0.9-0.6-1.5-1.7-2.7-1.8c-1.2-0.1-2.1,0.9-3.1,1.2c-3,1.1-3.7-1.9-4.2-4.2
    		c-0.3-1.3-1.3-2.7-1.5-4.1c-0.9-3.2-1.8-4.3-2.9-5.9c-1.2-0.3-2.4-0.6-3.4-1.4c-1.1-1-1.1-0.2-2.1,1.3c-2,3.2-1.7,0-2.6-0.2
    		c-1.4-0.2-3-0.1-4.2-0.8c-0.7-0.4-3.3-0.6-3.5-1.5c-0.2-0.8,0.4-1.6,0.6-2.4c0.2-0.8-0.6-1.9-0.3-2.7c0.5-1.4,0-2.6,0.2-3.9
    		c0.2-1-1.6-1.4-0.6-2.5c-1.2-0.5-1.6-2.5-2.2-3.8c-1.3-1.8,3.1-1.9,1.2-3.4c-2.3-1.7-1.4-3-0.3-5.5c0.3-0.7,1.2-2,0.9-2.7
    		c-0.6-1.1-0.1-1.9,0.2-3.1c1.2-3.2,0.4-3.7-0.7-6c-1-1.5-0.9-3.8-1.8-3.5c-1.6,0.6-3.7-1.4-4.3-3.1c-0.4-1.1-0.8-2.5-0.9-3.7
    		c0-1.2,0.9-2.2,0.8-3.5c-2.4,0.6-5,0.4-7.4,0.1c-1.6-0.2-5.1,0.3-6.2-0.9c-1-1.1-0.8-3.5-1-4.9c-0.3-1.9-2.6-3-2.6-4.7
    		c0.6-0.1,1.2-0.1,1.9-0.1c-0.4-2.1-1.1-4.9-1.8-6.5c-0.4-0.9-0.4-1.1-0.4-1.9c0.1-1.1,0.1-1.9-0.8-2.6c-2.2-1.7-4.5-2.9-5.7-2.7
    		c-3.9,0.4-2.9,0.1-4.1,0.1c-1,0-2.3-2.2-2.7-2.8c-0.5-0.7-0.9-0.4-1.6-0.4c-0.6,0-1.9-1-2.5-1.3c-0.4-0.2-0.9-0.1-1.4-0.4
    		c-1.2-0.6-1.2-1.4-1.7-2.1c-0.6-0.9-0.8-0.7-1.6-0.3c-1,0.5-1.6-0.7-2.2-1.2c-1.8-1.2-2.3,0.2-4.1-0.2c-2.1-0.5-2.3-0.7-3.4-2.2
    		c-0.5-0.8-2.5-2-3.3-2.1c-0.9-0.1-0.6-1.3-1.1-1.9c-0.4-0.4-1.2-0.3-1.2-1c0-1.4-1.6-2.7-1.7-4.2c0-0.9,0.6-1.4,0.7-2.3
    		c0.1-0.7-0.9-0.9-1.1-1.7c-0.1-0.7-0.2-1.5-0.2-2c0-0.9,0.4-1.6,0.6-2.4c0.3-1.5,0.4-3.9-1-4.8c-0.3,0.3-0.6,1.1-1.2,1.1
    		c-1.4-0.1-2.5-0.2-3.8,0.1c-1.5,0.3-4.1,0.6-5.1,1.7c-1.7,1.9-1.9,3.1-4.2,3.2c-0.5,0-2,1-2.1,1.8c-0.2,1.1-1.4,0.6-2,0.6
    		c-1-0.1-1.8,0.8-2,1.6c-0.2,1.1-1.2,1.7-2.4,2c-0.4,0.1-2.1,1-1.8-0.4c0.1-0.4-1.1-0.4-1.9-0.5c-2.2-0.1-5.4-0.5-7-0.4
    		c-1,0.1-2.2,1.3-3.3,1c-0.7-0.2-1.4-1.3-2.1-0.7c-0.3-2.6-0.5-5.6-0.6-8.1c0-1.3,0.2-1.5,0.5-2.6c0-0.4-0.1-0.7-0.3-0.9
    		c-0.4-0.7,0.3-1,0.5-1.7c-1.7,0.2-3.4,3.2-4.8,4.2c-2,1.6-5.4,0.9-7.7,0.6c0.4-1.2-0.7-3.2-1.6-4c-1.7-1.4-4.5-0.5-6.3-1.4
    		c2.1-2.8,1.4-3.5-0.8-5.9c-0.5-0.5-0.6-0.8-0.8-1.5c-0.2-0.7-0.9-1-1.3-1.5c-0.9-1.2-0.7-2.7-2.2-3.7
    		C740,766.3,737.2,764.6,736.8,763.7"
          />
          <path
            id="path2886"
            class="countryIsland"
            d="M1005.9,816C1007,813.1,1004.1,815.4,1005.9,816"
          />
          <path
            id="path2814"
            class="countryIsland"
            d="M899.9,711.8c0.7-0.1,1.4-0.6,1.9-1.1C901,710.6,900.1,710.9,899.9,711.8"
          />
          <path
            id="path2816"
            class="countryIsland"
            d="M901.6,712.8c0.6-0.7,1.5-0.5,2.2-1.1C903.1,710.6,901.2,711.6,901.6,712.8"
          />
          <path
            id="path2846"
            class="countryIsland"
            d="M914.7,680.2c0.2-0.2,0.3-0.5,0.5-0.7C914.4,679.3,914.3,679.5,914.7,680.2"
          />
          <path
            id="path2848"
            class="countryIsland"
            d="M915.4,682.3c1.1-0.5,0.5-1.5,0-2.2C914.3,680.6,914.9,681.6,915.4,682.3"
          />
          <path
            id="path2818"
            class="countryIsland"
            d="M903.8,711.3c2-0.5,6.7-5.2,5.6-7.4c-1.2-2.3-3.4,0.8-3.7,2c-0.2,0.9,0.2,1.9-0.7,2.4
    		C904.1,709,903.3,710.1,903.8,711.3"
          />
          <path
            id="path2820"
            class="countryIsland"
            d="M907.7,702.8c0.7-0.5,2.8-1.9,1.8-3C908.5,700.4,908.1,701.8,907.7,702.8"
          />
          <path
            id="path2822"
            class="countryIsland"
            d="M907.8,709.1c1.1-0.4,1.9-1.9,1.2-2.9C909,707.3,907.8,708,907.8,709.1"
          />
          <path
            id="path2824"
            class="countryIsland"
            d="M910.9,706.1c-0.8,0.3-1.4,1-1.6,1.9c1-0.2,1.2-1.3,2-1.9C911.2,706.1,911,706.1,910.9,706.1"
          />
          <path
            id="path2826"
            class="countryIsland"
            d="M909.8,703.3c0.3-0.7,0.5-1.5,1.3-1.8C910,700.4,909.5,702.5,909.8,703.3"
          />
          <path
            id="path2828"
            class="countryIsland"
            d="M909.7,706.9c0.7-0.7,1.1-1.7,1.9-2.3c0.4-0.3,0.7-1.3-0.2-0.9
    		C910.4,704,909.6,705.9,909.7,706.9"
          />
          <path
            id="path2830"
            class="countryIsland"
            d="M910.2,703.3c0.6-0.2,1-0.6,0.8-1.3C910.5,702.3,910.1,702.8,910.2,703.3"
          />
          <path
            id="path2832"
            class="countryIsland"
            d="M911.8,701c0.5-0.6,1.2-0.9,1.8-1.5c0.7-0.7-0.5-1-1-0.8C911.5,699.2,909.6,700.6,911.8,701"
          />
          <path
            id="path2834"
            class="countryIsland"
            d="M910.8,703.6c0.6-0.3,1.9-0.8,1.4-1.7C911.3,701.9,911.2,702.9,910.8,703.6"
          />
          <path
            id="path2836"
            class="countryIsland"
            d="M911.2,705.9c0.4-0.2,0.6-0.5,0.8-0.9C911.4,704.9,911,705.3,911.2,705.9"
          />
          <path
            id="path2838"
            class="countryIsland"
            d="M911.4,698.5c0,0.1,0,0.3,0.1,0.4c0.6-0.1,1-0.3,1.4-0.7C912.4,697.8,911.8,698,911.4,698.5"
          />
          <path
            id="Brazil_Marajo"
            class="countryIsland"
            d="M912.4,711.3c0.5,0.9,0.9,1.9,1.6,2.6c0.5,0.6,2.8,0.7,1.6,0.1c1,0.1,2.3-0.5,3.3-0.2
    		c0.7,0.2,1.7,0.7,1.4-0.5c0.2,0.3,0.3,0.5,0.5,0.8c0.7-0.5,1.4-1.2,1.4-2.1c0.4,1.5,3,0.4,3.8,0c1.5-0.8,1.5-2.3,2.3-3.6
    		c0.8-1.2,1.1-2.5,1.4-3.9c0.3-1.4,1.8-3.7-0.5-3.8c-1.9-0.1-4.2-1.3-6-0.5c-0.8,0.3,0.1,0.7-1.2,0.6c-1.1,0-2-0.2-3-0.5
    		c-1.9-0.5-4.5-1.4-5.7,0.8c-0.7,1.2-0.8,3.1,0.8,3.5c-0.6-0.1-1.2-0.3-1.7-0.4c-0.2,0.7,0.2,1,0.2,1.6c0,0.7-0.6,1.3-0.2,2
    		c0.4,0.8,1.6-0.3,2.3-0.1c-1,1.7-0.8,0.5-2.5,0.7C912.6,709.2,912.5,710.3,912.4,711.3"
          />
          <path
            id="path2842"
            class="countryIsland"
            d="M914,698.5c1,0.7,0.9-0.3,0.6-1C913.9,696.1,913.1,697.7,914,698.5"
          />
          <path
            id="path2844"
            class="countryIsland"
            d="M914.9,697.1c0.7-1.1,1.3-2.3,0.3-3.5C914.8,694.5,913.9,696.8,914.9,697.1"
          />
          <path
            id="path2850"
            class="countryIsland"
            d="M917.7,698.5c0.7,1.4,2.1-0.8,2.7-1.4c1.5-1.6-1.2-1.3-2-1c-0.8,0.3-2.7,0.3-3,1.4
    		C915.1,698.3,917.3,698.5,917.7,698.5"
          />
          <path
            id="path2852"
            class="countryIsland"
            d="M916.4,695.6c0.4-0.3,2.3-1.8,1.6-2.4C916.8,692.1,915.5,694.7,916.4,695.6"
          />
          <path
            id="path2854"
            class="countryIsland"
            d="M916.5,696.6c0.5-0.2,0.8-0.5,1.2-0.9C917,695.6,916.6,696,916.5,696.6"
          />
          <path
            id="path2856"
            class="countryIsland"
            d="M916.5,692.1c0.7,0,1.6-0.2,1.5-1.1C917.2,690.7,916.2,691.2,916.5,692.1"
          />
          <path
            id="path2858"
            class="countryIsland"
            d="M918.2,690.5c0.5-0.3,0.5-0.6,0.2-1.1C918,689.7,917.9,690.1,918.2,690.5"
          />
          <path
            id="path2860"
            class="countryIsland"
            d="M920.1,698.5c-1,1.6,1.5,1.3,2.3,1C923.9,699.1,921.3,697.3,920.1,698.5"
          />
          <path
            id="path2862"
            class="countryIsland"
            d="M926.3,714.2c0.2-0.6,0.4-1.3,0.4-1.9C925.8,712.5,925.4,713.5,926.3,714.2"
          />
          <path
            id="path2864"
            class="countryIsland"
            d="M930.1,708.6C932.1,710.5,930.6,706.3,930.1,708.6"
          />
          <path
            id="path2866"
            class="countryIsland"
            d="M935.9,704.5c0.8,0.3,0-0.8,0-0.8C935.9,704,935.9,704.2,935.9,704.5"
          />
          <path
            id="path2872"
            class="countryIsland"
            d="M945,707.4c0.4-0.1,0.6-0.4,0.6-0.8C944.8,706.4,944.6,706.7,945,707.4"
          />
          <path
            id="path2874"
            class="countryIsland"
            d="M956.6,710.7c0.6-0.2,0.9-0.7,0.6-1.2c-0.3,0.8-0.5,1-0.6,0.5
    		C956.6,710.2,956.6,710.5,956.6,710.7"
          />
          <path
            id="path2876"
            class="countryIsland"
            d="M958.3,713c0.3-0.1,0.5-0.2,0.8-0.3C958.5,712.3,958.2,712.4,958.3,713"
          />
          <path
            id="path2878"
            class="countryIsland"
            d="M959.9,725.1c0.5-0.9,0.8-1.9,0.8-3C959.6,722.6,960,724.1,959.9,725.1"
          />
          <path
            id="path2882"
            class="countryIsland"
            d="M966.6,718.9c0.2-0.2,0.4-0.5,0.7-0.7C966.5,718.1,966.3,718.3,966.6,718.9"
          />
          <path
            id="path2868"
            class="countryIsland"
            d="M942.1,926.5c0.2,0.2,0.4,0.4,0.7,0.7c0.3-0.7,0.5-1.5-0.1-2.1
    		C942.3,925.5,942.1,926,942.1,926.5"
          />
          <path
            id="path2870"
            class="countryIsland"
            d="M944.5,939.3c0.7-0.9,1.2-2.7,0.4-3.7C943.9,936.2,944.4,938.3,944.5,939.3"
          />
          <path
            id="path2880"
            class="countryIsland"
            d="M965.3,906c0.1-0.7-0.4-1.1-0.1-1.8c-0.9-0.2-1.2,0.8-1.4,1.4
    		C964.3,905.5,964.8,905.7,965.3,906"
          />
          <path
            id="path2884"
            class="countryIsland"
            d="M971.5,899.5c0.6-0.1,1.1-0.5,1.4-0.9C972.3,898,971.2,898.5,971.5,899.5"
          />
          <path
            id="path2888"
            class="countryIsland"
            d="M1007,812.2c0.5-0.5,1.9-1.4,0.8-2.2C1007.9,810.9,1006.9,811.4,1007,812.2"
          />
        </g>
        <g id="sl" class="countryGroup">
          <path
            id="Sierra_Leone_Sherbro"
            class="countryIsland"
            d="M1202.5,632.7c-0.1,0.2-0.1,0.3-0.2,0.5c1,0.3,1.9,0.7,2.9,0.9
    		c0.2-0.4,0.3-0.9-0.1-1.2C1204.4,632.4,1203.3,632.7,1202.5,632.7"
          />
          <path
            id="Sierra_Leone_mainland"
            class="country"
            d="M1199.6,620.4c0.6-0.6,1.4-0.1,2.1-0.5c0.7-0.4,0.5-1.6,1.3-1.8
    		c1.3-0.4,2.2-3,2.7-4.1c0.7-1.9,2.8-0.5,4.2-1.5c1.2-0.8,4.7-0.9,5.9,0c1.2,0.9,1.8,2.4,2.5,3.6c0.4,0.6,0.8,1.3,1.1,2
    		c0.2,0.5-0.4,1.2,0,1.5c0.4,0.5,0.9,0.4,1,1.1c0,0.6-0.2,1.7,0.3,2.1c0.7,0.7-1.5,2.9-1.4,4.1c0.2-0.4,1.8-1.2,2.2-1.4
    		c1.5-0.9,1.2,0.3,0.9,1.4c-0.4,1.3-2.3,0.9-2.4,2.8c-0.2,1.8-0.2,1.7-1.2,2.7c-0.8,0.8-1.5,1.6-2.7,2.5c-1.5,1.1-2.2,3.9-2.8,3.7
    		c-0.7-0.2-6.5-3.3-7.4-4.1c1.6,0.5,0.3-0.3-0.1-0.6c0.7-0.5,1.6-0.5,2.2-1.1c-1.3,0.2-3.3,0.4-2.2-1.6c-0.9,2-2-0.2-2.7-0.1
    		c-0.5,0.1-1-3.5-1-3.9c-1.1,0.3-2.8-0.7-2-2.1c0.5,0.4,1,0.7,1.5,1c-1.3-0.6,0.6-1.7,1.3-1.7c-0.3-0.5-0.2-0.4-0.1-0.9
    		c-0.7,1.3-1.3,0.6-2.1,0.1c-0.1,0.3-0.2,0.5-0.3,0.8c-0.9-1.6-0.2-2.2,1.2-2.5C1201,621.2,1199.9,621.3,1199.6,620.4"
          />
        </g>
        <path
          id="ml"
          class="countryGroup country"
          d="M1208.6,570.8c1.3-0.3,1.9,0.4,3.1-1c0.9-1.2,0.5-3.7,1-5.2c1-3.4,5.7,2.2,6.2,3.3
    	c0.9-2.5,2.4-3.8,5.2-2.7c1.9,0.8,5.8,0.6,6.2-1.9c1.1,2.6,6.3,1.4,8.3,1.3c5-0.4,10.1-0.1,15.2-0.1c1.8,0,3.9-0.2,5.7,0
    	c1.3,0.1,0.9-2,1-3.1c0.1-1.2,1.3-3.6,0.1-4.5c-2.2-1.4-1.6-2.7-1.6-4.5l-5.2-70.1h13.2c13.7,10.3,26.5,20.5,39.5,30.7
    	c1.6,1.1,4.5,2.3,4.3,4.6c-0.1,0.7-0.2,1.4,1.1,1.9c0.6,0.3,1.3,0.4,1.9,0.8c0.9,0.6,0.8,1.7,1.5,2.4c0.7,0.7,1.4,0.8,2.3,0.9
    	c0.6,0.1,0.2-0.6,1.3,0c0.8,0.4,1.2,1.5,2.1,1.8c1.5,0.5,3.4,0.6,4.8,1.4c1,0.6,0.7,3.4,0.3,4.3c-0.6,1.3-1.4,2.6,0.7,2.9
    	c2.2,0.3,4.8-0.9,7-1.2c-0.3,6.1,0.6,12.7-0.2,18.8c-0.1,0.7,0.1,1.2-0.4,4.5c-0.2,1.1,0.2-0.3-1.3,2.9c-0.5,1-0.4,2.2-0.9,3.2
    	c-0.4,1-1.4,1.3-2.2,1.9c-0.9,0.7-0.3,1.7-1.6,1.5c-0.3,0-2.9-0.8-3.1-0.3c-0.3,1.2-8.5,0.8-9.8,0.9c-3,0.2-3-0.1-5.1,2.2
    	c-0.7,0.7-2,1-2.9,0.8c-0.6-0.1-1.8-0.1-2.4-0.1c-1,0-0.9-0.8-0.9,0.5c-0.7-0.3-1.4-0.6-2.1-0.9c-1.7-0.7-2.1,0.5-2.8,0
    	c-0.6-0.5-1.7-1.1-3.1,0.2c-1.5,1.3-3.6,2.4-4.4,3c-1.4,0.9-2.4,1.7-3.9,2.1c-1.2,0.3-0.2,2.8-2,2c-1.1-0.5-2.1-1.1-3.2-0.3
    	c-1,0.7-1.5,1.9-1.9,3c-0.2,0.6-0.5,1.6-0.8,2.2c-0.2-0.2-1.8-0.6-2.5-0.7c0.6,1,0,2.5,0.2,3.6c-1,0-1.8-0.2-1.5,1
    	c-1-0.1-4-1.6-4-2.9c-1.9,4.5-3.3,2.4-2.3,4c0.5,0.9,0.6,1.6,0.3,2.3c-0.1,0.3-2.4-0.1-1.8,0.9c1,1.8,0.6,3.7-1.4,5.3
    	c-0.9,0.8-2,0.5-3.1,0.7c-1,0.2-1.6,1.3-2.7,1.3c2,0.5,0.6,1.5,1.2,1.9c1,0.6,0.2,3.2-0.4,4.2c-0.2,0.3-1.3,0.4-1.2,0.7
    	c0.3,1.5,0.5,3.4-0.5,4.7c-0.4,0.5-1.6,0.5-2.2,0.7c-1.2,0.3-0.6,2-2.2,1.8c-1.7-0.2-0.6-3.6-1.2-4.7c-0.7,0.3-0.8,1.5-1.4,1.7
    	c-0.7,0.2-1.2-0.7-1.7-1c0,0.8,0.4,2.9-0.8,2.9c-0.9,0-2.2,0.1-1.5,1.3c-1.1,0.5-4-0.9-3.8-2.3c-0.6,0.3-1.5,0-2,0.5
    	c-0.7,0.8-0.6,1.8-2,2c1-1.9-2.3-2.2-2.4-3.8c0-1,0.3-2.2,0-3.2c-0.3-1.4-2.3,0.2-2.9,0.2c0.2-0.9,1-3.4,2.4-2.9
    	c0.5-1.4-2.3-1.9-2.5-3.2c-0.9,0.5-0.8-0.5-0.7-1.1c0.2-1.3-0.3-1.8-0.9-2.9c-0.4-0.9,0.1-2.1-0.9-2.6c-0.4-0.2-1.8-1-2.2-0.6
    	c-0.4,0.4,1,1.5,0,2c-1.1,0.5-2,1.4-3.1,2c-0.8,0.4-1.7-0.7-2.3-1c-1.4-0.6-2.4-0.5-3.4,0.7c-2.4,2.9-1.1-0.6-3.2-1.3
    	c-1.4-0.5-2.8,3.9-4.2,0.5c-0.4-1.1,1.4-2.1,0.5-3.3c-0.5-0.7-0.1-2.4,0.2-3.1c0.3-0.9-2.3-5.8-2.9-3.7c-0.6-0.2-1.6-2.1-1.9-2.7
    	c-0.2-0.5,0.6-1.6,0.5-2.6c0-1.6,0.1-2.2-1-3.5c-0.4-0.4-0.8-0.9-0.5-1.6C1209.3,571.8,1209,571.8,1208.6,570.8"
        />
        <path
          id="cd"
          class="countryGroup country"
          d="M1393.8,748.5c0.7-0.5,1.5-0.1,1.8-1c0.2-0.8,0.8-3.7,0.3-4.3c-1.4-1.6,4-4,5-4.9
    	c0.3,1.1,1.9,1.3,2,2.5c0.5-0.6,1.2-0.9,1.8-1.4c0.8-0.7,0.5-1.8,1.2-2.6c1.2,1.6,3-1.5,4.4-0.7c1.3,0.7,0.1,1-0.1,1.8
    	c-0.2,1,0.4,2,0.4,2.9c0.6-0.8,1.9,0.2,2.7-0.4c1.2-0.8,1.9-2.4,2.8-3.5c0.5-0.6,2.4-1.5,2.5-2c0.3-1.4,1.4-1.7,2.6-2.2
    	c2.7-1.1,3.1-6.1,3.1-8.5c0-1.8,0.1-3.5-0.1-5.3c-0.2-1.7,2-2.2,2.7-3.6c1.7-3.2,1.6-5.3,5.1-7.7c1.5-1,2.4-2.2,3.4-3.6
    	c1-1.4,0.3-3.4,0.7-5c0.3-1.4,1-7.4,1.1-8.9c0.1-1.8,1.1-3.3,1.4-5c0.3-1.7-0.2-3.4,0.2-5.1c0.7-3.1,2.6-5.7,3.6-8.6
    	c0.6-1.7,0.3-3.5,0.3-5.3c0-0.8,0.3-1.7,0.2-2.4c-0.1-0.7-0.5-1.3-0.7-2c-0.3-1.1,1.6-1,2-2c0.6-1.4,1.2-2.5,2.2-3.6
    	c1-1.1,2.2-2,3.7-1.9c1.3,0,1.8,1.1,2.6,1.3c1.9,0.4,3.7,2.5,4.3,4.2c0.6,1.4,2.6,0.4,3.8,0.7c0.6,0.2,0.9,1,1.7,1
    	c0.6,0,0.8-0.1,1.4,0.2c1,0.5,1.2,0,2.2-0.1c0.7,0,1.4,0.3,2,0.5c1.1,0.4,2.5,1.2,3.7,0.8c2.1-0.7,1.5-5.7,4.2-5.9
    	c1.6-0.1,2,3.1,4.1,1.2c0.9-0.8,6.9-1.6,6.6-3.6c1.3,0,1.7,1.7,2.9,1.5c1.4-0.1,3.9-0.4,4.5-1.9c0.2-0.6-0.3-1.2,0.4-1.5
    	c0.9-0.5,1.7-0.2,2.4,0.5c1.2,1.1,2.2-0.2,3.5,0.4c0.6,0.3,1.2,0.8,1.8,1.1c0.7,0.4,1.2,0.1,2,0.2c1.8,0.3,2.5-1.8,4.5-0.7
    	c0.7,0.4,3.3,2,3.3,3c0,0.5,0.6,1.6,1.1,1.8c1.9,0.8,3.7,3.6,5.7,1.7c1.2-1.1,2-1.3,3.5-0.6c1.6,0.8,2.2,0.5,3.1-1.4
    	c1.2-2.4,4.3,3.6,4.5,4c0.7,1.8,5.4,2,4,4.7c0.9-0.2,2-0.3,2.3,0.9c0.1,0.3,0.3,0.6,0.5,0.9c0.1,0.6-0.7,1.7-0.6,2.4
    	c0.3,2,0,4-0.3,5.9c-0.2,1,0.6,0.9,1.7,0.7c0.5-0.1,2.5,1.3,2.1,2.4c-0.9,2.6-4.1,5.2-6,7.2c-0.6,0.6-1.9,2.4-2.6,3
    	c-0.8,0.6-0.8,0.1-1.3,0.9c-0.5,1,0.1,2.3-0.5,3.4c-0.7,1.2-1.4,2.9-1.6,4.3c-0.1,1.2-0.1,2.5-0.2,3.7c-0.1,1.5-0.3,2.7-0.4,4.1
    	c-0.1,1,0.2,2.4-0.3,3.3c-0.5,1.1-1.4,1.4-2.2,2.3c-1.2,1.3-1.1,1.7-1.1,3.3c0,1-1.1,2.2-1.7,2.9c-1.2,1.6,0.5,3.3,1.4,4.8
    	c0.7,1.2,1.2,2,0.7,3.5c-1.9,1.7,0.4,6.9-1.4,8.7c1.3,0.8,1.1-2.6,1.5-2.1c0.4,2.7-1.2,4.6-1.2,7.2c0,3.4,2.8,5,1.6,7.3
    	c-0.7,1.4-1.4,1.7-0.8,2.5c0.9,1.3,1.3,3.5,3.1,7c1.2,2.3,4.6,1.9,4.8,7.5c0.1,2.2,1.8,1,2.5,4.4c-4.7,1.1-8.2,1.2-12.9,2.2
    	c1.9,3.2-3.1,4.6-4,7c2.7,0.8,1.8,5,1.6,7.1c-0.1,1.5,0.4,2.9,0.3,4.4c0,0.8-0.2,1.5-0.5,2.2c-0.4,1-0.8,1.7-1,2.7
    	c-0.5,2-1.2,3.2,0.1,5.1c0.9,1.3,2.5,3,3.7,4.2c0.5,0.5,3.3,2.2,3.5,1.2c0.4-1.8,0.9-1.5,2.7-2c0,2.9-0.1,5.8-0.2,8.7
    	c0,0.4,0.3,2.4-0.4,2.4c-0.9,0.1-1.1-0.9-0.5-1.4c-1.3-1.6-4.2,3.4-5.6,0.4c-0.5-1-0.9-2.3-1.6-3.2c-0.4-0.5-1.4-1.2-1.5-1.8
    	c-0.2-1.2-0.5-2.6-1.9-2.6c-1.2,0-1.8-1.3-3-1.5c-1-0.1-1.6,0.6-2.3-0.5c-0.5-0.9-0.6-2.1-1.3-2.8c-1.1-1-1.9-4.5-2.9-1.4
    	c-1,3.2-2.8,1.5-5.3,1.6c-1.7,0.1-2.1-0.5-3.5-1.1c-0.1,0-1.6-0.4-1.6-0.4c-2.3,0.4-3-3.1-2.2-4.6c-1.4,0.1-2.8,0.4-4.1,0.9
    	c-1.6,0.6-1.9,1.4-3.7,0.7c0.7-1.2,0.7-2.9,0.1-2.8c-0.8,0-1.1-0.6-1.7-1.1c-1.2-1.2-1.1,0.2-1.4,0.3c-1.1,0.4-1.6,0.3-2.6,0.1
    	c-1.2-0.3-2,0.5-3,0.9c-1.4,0.5-2.2-0.4-3.1-0.3c-0.3,0-1.6-0.1-1.6-0.1c-0.9,0.5-1.3,1.8-2.5,1.7c0.3-0.9-0.3-1.6-0.4-2.4
    	c-0.2-1.1,0.6-1.4,0.8-2.2c0.6-2-0.2-5.6-1.5-7.2c-1.5-2-2.2-3.3-2-5.9c0.2-2.7,1.1-5.5,0.4-8.2c-0.3-1-1-1.9-1-3
    	c0-1.2,0.7-2.4,0.8-3.6c0.2-2-1.1-1.8-2.8-1.8c-1.5,0-3.1,0-4.6,0c-3.2,0.1-2.7-0.6-1.8-3.2c-0.7,0-1.4-0.2-2.1,0.1
    	c-0.5,0.2-0.1,0.4-0.4,0.6c-0.8,0.7-4.7,0.1-5.8,0.1c0.4,0.9-0.3,2.1-0.2,3.1c0.1,0.8,0.2,2.1-1.1,1.8c0.4,1.2-0.3,2.5-0.1,3.7
    	c-0.5,0-4,0.1-4.5-0.2c-1.7-1-4.6,0.8-5.1,0.9c-1.3-0.2-2.5-0.2-4-0.1c-1.8-0.4-2.6-4.2-3.5-5.5c-0.3-0.5-0.6-0.8-0.9-1.3
    	c-0.6-0.9-0.3-1.5-0.5-2.4c-0.2-1.6-1.9-2.7-1.7-4.5c0.2-1.6,0-2.9-0.7-3.8c-1-1.3-1-1.7-2.6-1.7c-4.4,0.2-8.7,0.4-13.1,0.1
    	c-3.5-0.2-7.7,0.4-11.2-0.3C1397.7,748.5,1395.4,752.7,1393.8,748.5"
        />
        <g id="it" class="countryGroup">
          <path
            id="Italy_mainland"
            class="country"
            d="M1353.7,308.6c1-1.1,3.7-0.2,3.6-2.4c0-0.9-0.7-1.3-1.3-1.9c-0.3-0.3-0.7-0.7-0.9-1
    		c0.8-0.5,1.7-1.5,1.2-2.1c0.9,0.2,1.9,1.1,2.9,0.8c1.2-0.4,1.8-0.2,2.9-0.1c0.8,0.1,1.7-0.9,2.1-1.5c0.3-0.5,0.3-0.9,0-1.3
    		c0.8-0.2,1.4-0.9,1.9-1.5c0.9,0.6,0.5,1.3,1,2.1c0.5,0.7,1.2,0.9,1.8,1.6c0.2,0.2,0.3,1,1.2,1.5c0.3,0.2,0.2-1.9,0.8-2.5
    		c0.8-0.7,1-1.2,1.1-2.3c0.2-2,0.8-0.6,1.2-0.1c0.7,0.8,1,0.9,1.9,0.6c1.5-0.5,1.5,0.6,2.5,1.1c0.1-0.7,0.6-1.3-0.2-1.7
    		c-0.8-0.3,0.2-1.7,0.7-1.8c0.5-0.1,1,0.6,1.4,0.7c1,0.3,0.1-1.3,0.2-1.6c0.2-0.6,0.3-0.8,0.4-1.1c0.6,0.1,2.2,0.5,2.6,0.7
    		c1,0.5,1.6-0.8,2.2-1.3c0.7-0.7,1.9-0.5,2.8-0.4c1.5,0.1,2.9-1,4.3-0.7c-1.6,0.9,1.6,3.4,2.5,3.7c2.7,0.8,4.5,0,7.2,0.8
    		c-0.1,1-1.2,1.7-1.7,2.3c0.6,0.3,1.2,0.7,1.9,0.9c-0.6,0.7-1.5,1.7-0.2,2c-0.7,1,0.5,1.3,0.9,1.4c0.9,0.2,1.2,1.8-0.2,1.5
    		c1-2.1-1.7-0.9-2.2-0.7c-0.2-1.4-2-0.1-2.5,0.2c-0.6,0.3-1.1,0.7-1.7,1c-0.4,0.2-3.7,1.3-1.6,0.2c-0.6,0.1-1.3,0-1.7,0.4
    		c-0.3,0.3-0.2,1.4-0.8,1.3c0,0.3,1.2,2,1.3,2.1c1.8,0.5,0.7,3.2-0.4,2c-2.1,3.7,2.6,7.2,5.1,8.8c1.2,0.8,3.2,1.3,4.1,2.6
    		c1.3,1.8,1.9,4.2,2.7,6.2c0.9,2.6,4.4,5.3,6.9,6.5c1.8,0.8,2.8,1.1,4.8,0.8c0.8-0.1,4.1-0.6,3.7,1.1c-0.2,0.8-2.6,1.6-1.7,2.6
    		c0.9,1.1,3.4,1.7,4.7,2.2c2,0.7,3.9,1.4,5.6,2.8c1.7,1.4,3.9,1.8,5.6,3.3c1.1,1,2.7,2.1,2.4,3.8c-0.1,0.4-0.1,2.1-0.7,2.2
    		c-0.8,0.1-1.8-0.4-2.2-1.1c-0.9-1.5-0.9-3.2-3.1-3c-1.8,0.2-2.2-1-2.4-1.7c-1.9,0.3-2.4,0.3-3.6,2.1c-0.8,1.1-1.3,2.5-1.6,3.8
    		c-0.3,1.5,1.6,1.9,2.6,2.4c1.7,0.8,2,2,2.1,3.6c0.3,2.9-4.7,0.8-4.2,4.2c0.2,1.7-0.2,1.8-1.3,3c-0.9,1-1.6,2.2-2.7,3
    		c-1,0.7-1.9,0.3-2.4-0.8c-0.8-1.7,1.6-2.4,1.8-3.9c0-0.5-0.2-1.1,0.1-1.6c0.6-0.8,1.8-0.2,2.1-1.2c0.3-0.9-0.8-1.8-1-2.6
    		c-0.3-1.2-0.4-2.3-1.1-3.4c-0.8-1.3-0.9-3.3-1.9-4.4c-0.6-0.7-1.1-0.5-1.7,0c-0.7,0.6-1.4-0.6-1.9-1c-0.5-0.4-1.4-0.3-1.5-1.1
    		c-0.1-0.7,0.3-0.9,0-1.8c-0.8-1.8-2.9-1.9-4.4-0.9c0.8-1.2,0.5-2.3-1.1-2.1c-1.2,0.1-1.9-1.6-2.3-2.5c-0.8-1.5-1.7-0.6-2.9-1
    		c-0.7-0.2-1.3-0.5-2-0.3c-1.5,0.4-0.5,0.2-1.6-0.7c-1-0.8-2.3-1.1-3.3-2c-1.1-1-1.8-2.5-3-3.4c-1.1-0.8-1.5-0.6-2.2-1.7
    		c-0.7-1.2-2.7-2.7-4.1-1.8c0.8-1.4-2.6-5-3.9-4.9c0.2,0-1.9-5.8-1.9-6.1c-0.7-3.4-3-3-5.4-4.6c-2.5-1.7-5.7-2.6-7.8,0.1
    		c-0.8,1-1.4,2.1-2.5,2.7c-0.4,0.2-4,1.4-3.8,0.4c0.2-0.7,2.9-3.7-0.3-2.5c-1.9,0.7-5.5-2.4-3.2-4.3c0.9-0.7,0.7-1.3-0.3-1.8
    		c-1.7-0.8-2.2-2.1-1.9-2.2"
          />
          <path
            id="Italy_Pantelleria"
            class="countryIsland"
            d="M1391.2,380.7c-0.2-0.2-0.3-0.5-0.5-0.7C1391.5,379.9,1391.7,380.2,1391.2,380.7"
          />
          <path
            id="Italy_Sardinia"
            class="countryIsland"
            d="M1365.6,349c-0.2-0.6-0.7-0.9-1.1-1.4c-0.6-0.9-0.1-1.8-0.3-2.8c1.9,2.1,4.3-0.4,5.8-1.7
    		c0.5-0.5,1.3-1.3,2.1-0.8c0.5,0.3,0.9,1.2,1.6,0.8c-0.3,0.6,0,0.9,0.6,0.9c-0.2,0.2-0.5,0.4-0.7,0.6c1.5-0.1,2.1,3.4,1.8,4.4
    		c-0.3,0.9-1.6,1.4-0.8,2.4c0.8,1,0.1,3.7,0,4.9c0,0.9,0,2.9-0.9,3.5c-0.9,0.5-2.3-0.5-3.3-0.5c-0.5,0.8-0.3,2.9-1.8,2.6
    		c-1.4-0.2-1.4,0.1-2.1-1.3c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2-0.2-0.4-0.4-0.6-0.7c1.2-0.2,0.5-1,0.4-2.1c0-1.3,0.1-2.6,0.2-3.9
    		c0.2,0.2,0.5,0.4,0.7,0.6c0-0.5,0.2-0.9,0-1.4c-0.2-0.5-0.9-0.3-1-0.9c-0.1-0.5,0.6-1.2,0.5-1.8
    		C1366.2,350.4,1365.4,349.7,1365.6,349"
          />
          <path
            id="Italy_Elba"
            class="countryIsland"
            d="M1379.9,329.4c-0.7-0.4-1.6,0-2.2-0.6c0.7-0.3,1.6-0.3,2.2-0.8
    		C1380,328.5,1380,328.9,1379.9,329.4"
          />
          <path
            id="Italy_Sicily"
            class="countryIsland"
            d="M1397.2,373.5c-0.9,0-2.2-0.3-2.7-1.1c-1.2-1.7,1.2-2.9,1.8-4c1.1,1.6,1.8,0.7,3,0
    		c1.9-1.1,3.2,1.9,5.4,1.4c1.2-0.3,2.5,0,3.8-0.3c1.2-0.2,2.2-1.4,3.4-1.4c1,0,1.2,0.7,2.1,0.1c0.6-0.4,2.3-1.2,3-0.8
    		c-0.9,1.1-1.8,2.5-2.4,3.9c-0.4,0.9-1.6,2.8-1.3,3.9c0.2,0.9,1.8,2.2,1.3,3.2c-0.4,0.9-1.5,1.5-1,2.6c-1.1,0.3-2.5,0.1-3.6-0.2
    		c-1.6-0.5-1.5-2.3-3-2.9c-1.6-0.7-3.3-0.7-4.8-1.6C1400.6,375.4,1399.2,373.6,1397.2,373.5"
          />
        </g>
        <g id="so" class="countryGroup">
          <path
            id="so-"
            class="country"
            d="M1613.7,678.5c0-1.4-0.4-3.4,0.2-4.7c0.6-1.3,1.8-2.2,2.6-3.4c1.6-2.3,3-6.3,5.4-7.8
    		c2.2-1.3,4.8-0.5,6.7-2.5c2.2-2.3,4.3-3.9,7.6-4.4c2.5-0.4,6.8,1.4,8.5-0.9l21.7-25.6c-2.6,0-5.3,0.4-7.9-0.2
    		c-2.9-0.8-5.7-2.2-8.5-3.2c-2.9-1.1-5.8-2.2-8.7-3.3c-2.7-1-5.1-1.7-7.1-3.8c-0.7-0.7-0.9-1.1-1.9-1.3c-1-0.3-1.3-1.8-1.8-2.6
    		c-0.8-1.4-1.3-2.5-2.4-3.6c-1.3-1.4-2.8-3.3-2.2-5.4c0.7-2.3,2.7-4.4,3.6-6.7c2.4,1.3,3.7,4.5,5.4,6.6c2.1,2.6,5.2,3.3,8.3,2.3
    		c1.2-0.4,2.8-1.5,3.8-2.2c1.8-1.4,2.3-1.1,4.6-0.8c2.3,0.4,3.4,0.9,5.6-0.5c1.1-0.7,3.6-3.2,5-2.7c1.6,0.6,2.6,0.6,4.3,0
    		c1-0.3,2-0.9,3-1.1c1.2-0.3,2,0.3,3.2,0.4c1.9,0.2,3.3-0.9,4.9-1.6c1.7-0.7,3.6-0.4,5.3-1.3c1.4-0.7,1.6-2.6,2.8-3.2
    		c1.2-0.6,5,0.4,4.5,2.1c-0.6,2.2-1,3.5-0.6,5.8c0.3,1.6,0.7,3.9-0.7,5.2c0.9,0.4,1.7,0.1,1.5-0.9c0.5,0.3,1,0.5,1.5,0.6
    		c-0.3,1.6-3.8-0.6-3.8,2.1c0,2.2,0.2,4.5-0.4,6.7c-0.5,1.9-1.7,3.3-2.7,4.8c-1.2,1.8-1.1,4.5-2.6,6.3c-0.6,0.7-1.4,0.9-1.8,1.8
    		c-0.4,0.9,0,1.9-0.4,2.9c-0.8,2.2-2.3,3.9-3.4,5.9c-1.4,2.6-1.6,5.6-2.7,8.3c-1.1,2.8-2.9,5.2-4.5,7.7c-1.6,2.4-2.6,5.2-4.4,7.5
    		c-2,2.5-4,5-6.1,7.5c-4.1,5-8.3,9.1-13.8,12.6c-2.3,1.5-4.6,3-6.5,5c-2.2,2.2-4.6,4.4-6.6,6.8c-1.9,2.4-4,4.6-5.9,7
    		c-0.9,1.2-1.7,2.4-2.6,3.6c-0.6,0.7-2.2,3.3-2.5,1.8c0,2.5-1.6,4.9-3.1,6.7c0.1-1.9-1.8-3.5-3.1-5.5c-1.5-2.2-1.3-3.7-1.3-6.2
    		C1613.5,693.7,1613.7,686.2,1613.7,678.5"
          />
          <path
            id="xs"
            class="countryGroup disputed"
            d="M1666.3,629.3c-2.6,0-5.3,0.4-7.9-0.2c-2.9-0.8-5.7-2.2-8.5-3.2c-2.9-1.1-5.8-2.2-8.7-3.3
    		c-2.7-1-5.1-1.7-7.1-3.8c-0.7-0.7-0.9-1.1-1.9-1.3c-1-0.3-1.3-1.8-1.8-2.6c-0.8-1.4-1.3-2.5-2.4-3.6c-1.3-1.4-2.8-3.3-2.2-5.4
    		c0.7-2.3,2.7-4.4,3.6-6.7c2.4,1.3,3.7,4.5,5.4,6.6c2.1,2.6,5.2,3.3,8.3,2.3c1.2-0.4,2.8-1.5,3.8-2.2c1.8-1.4,2.3-1.1,4.6-0.8
    		c2.3,0.4,3.4,0.9,5.6-0.5c1.1-0.7,3.6-3.2,5-2.7c1.6,0.6,2.6,0.6,4.3,0c1-0.3,2-0.9,3-1.1c0.3-0.1,0.7-0.1,1-0.1l0.1,23.6
    		L1666.3,629.3L1666.3,629.3z"
          />
        </g>
        <path
          id="af"
          class="countryGroup country"
          d="M1742.2,403.8c-0.3-0.9,0.4-1.6,1.1-2.1c1.1-0.7,0.5-1.2-0.1-2.1c1.9-0.2,1.8-2.7,2-4.1
    	c0.1-0.7-0.8-1.6-0.3-2.3c0.4-0.6,0.4-1.6,0.5-2.3c0.3-1.5,2.3,0.5,3,0.9c1,0.5,1.8-0.2,2.8,0.1c1.1,0.3,2,1.4,2.3,2.5
    	c0.7-1.5,2.6-0.7,3.7-1.7c0.6-0.5,1.2-0.8,1.6-1.6c0.3-0.7-0.4-1,0.6-1.5c-0.5-0.4-0.9-0.9-1.2-1.4c3.1,0.4,6.4-1.7,8.8-3.4
    	c2.4-1.7-0.6-7,2.3-8.2c1.1-0.4,2.9,0.2,3.8-0.4c0.9-0.6-0.2-2.3,1.2-2.5c1-0.2,2.1,0.9,2.9,1.3c1.2,0.7,2.3,0.3,3.6,0.1
    	c1.5-0.2,2.9-0.2,4,0.9c1.1,1.2,2.4-0.1,3.8,0.3c0.6,0.1,1.6,1.5,2,1.9c1.1,1,1.2-0.2,2.3,0c-0.2-1.2,1.9-1.3,2.5-2
    	c0.6-0.7,0.5,0.1,1.2-0.1c0.3-0.1,0.3-0.8,0.7-0.7c1.1,0.2,1.5,2.2,2.8,1.9c1.6-0.4-0.2-2.4,0.5-3.3c1.4-2,4.4,0.4,5.3-0.6
    	c1.1-1.2-0.6-1.6-0.7-2.4c-0.2-1,1.1-2.1,1.5-3c1-2.6,4.4-2.9,5.9-0.3c0.3,0.6-0.2,2.5,0.5,2.8c0.7,0.2,1.7-0.7,2.1,0.3
    	c0.4,1,0.2,3.2,0.2,4.3c0,1.3,0.3,2.6,0.7,3.7c1.4,3.6,3.6,1.6,5.7-0.1c0.8-0.7,2.6,0.1,3-1c0.6-1.5,1.5-2.4,3-3.1
    	c1.1-0.5,5.3-0.4,3.5,1.6c1.2,0.5,3.3-0.9,4.4-1.4c0.9-0.5,4,0.4,4.5,1.5c-0.8,0.4-1.7-0.3-2.5,0.1c-1,0.5-0.3,1.4,0.7,1.7
    	c-0.9,0.3-2.1,0.7-2.8,1.4c-1.2,1.1-2-0.5-3.2-0.3c-2.5,0.5-5,0.3-7.3,0.6c-1.1,0.2-2,0.8-3.1,1.1c-1.2,0.3-1.4,1.9-2.2,2.3
    	c-0.8,0.4-1.1-0.5-2,0.8c-0.4,0.6-1.3,1.5-1.5,2.2c-0.2,0.8,0.9,1.3,1.4,1.7c1.1,1.3,2.2,2.5,2.8,4.4c-1,1.2,0.5,1.2,0.5,1.9
    	c-0.6,0.5-0.5,1-0.8,1.7c-0.5,1.4-1.7,1.7-2.1,2.4c-0.2,0.4-0.3,0.7-0.1,1.1c-0.2,0-0.9,0-0.7,0.5c0.2,0.6,1,1,1.4,1.4
    	c1.5,1.6-0.8,3.2-2,3.4c-1.1,0.2-2.4,0-3.5-0.2c-0.5-0.1-2.1-0.7-2.6-0.2c-1.1,1.1,2.2,2.1,2.6,3.5c0.2,0.7,1.3,1.9,1,2.6
    	c-0.3,0.7-1.9,1.6-2.8,1.6c-3,0-2.2,0.9-2.1,2.1c0.1,1,0,2.1-0.7,2.9c-0.7,0.8,0,2,0.3,2.9c0.4,1.7,0.2,3.9-1.6,4.8
    	c-1,0.5-1.5,0-2-0.8c-0.2-0.3-1.1-0.6-1.6-0.7c-0.3,0,0.2,0.1-0.2,0.7c-4-1.5-1.6,1.4-6.2,1.9c0.2,0.9,1.2,0.9,1.8,1.5
    	c-1.1,0.6-2.3,1-3.6,1.1c-1.2,0.1-1.7-0.7-2.4-0.8c-1.2,0-1.7,2.1-2.6,2.7c-1,0.7-1.2,0.7-1.3,2.2c-0.1,1.4,0.5,2.5,0.6,3.9
    	c-0.1,0.5-0.1,0.9-0.2,1.5c0.1,0.5,0.5,0.8,0.7,1.3c0.7,1.5-5.3,2.6-6,2.7c-1.3,0.3-2.2,0.9-3.6,0.8c-1.3-0.1-2.9-0.6-4.2,0.1
    	c-1.2,0.6-2,1.3-3.4,0.9c-1.3-0.4-2.8-0.8-4.2-0.6c-3,0.4-6.4,1-9.4,0.2c-3.5-1-5.8-2.5-9.3-3.3c1.5-1.8,2.6-3.8,3.9-5.7
    	c1.1-1.6,2.2-3.4,1.6-5.5c-1.2-3.8-7.1-0.8-8-5c-0.6-2.6-0.7-4.9-1.9-7.4c-1-2.1-2.4-4.2-0.9-6.4c1.2-1.8,0.9-1.6-0.9-2.1
    	C1742.3,407.6,1742,404.8,1742.2,403.8"
        />
        <g id="bd" class="countryGroup">
          <path
            id="path3240"
            class="countryIsland"
            d="M1977.6,496.4c-0.2,0-0.4,0-0.6-0.1c0.4,0.3,0.8,0.5,1.3,0.6
    		C1978,496.8,1977.8,496.6,1977.6,496.4"
          />
          <path
            id="path3242"
            class="countryIsland"
            d="M1977.9,501.2c0,0.4,0.2,0.8,0.6,1.1c-0.1-0.6,0.9-1,0.9-0.9
    		C1979.3,500.3,1978.3,500.7,1977.9,501.2"
          />
          <path
            id="path3244"
            class="countryIsland"
            d="M1978.7,499.9c0.2,0.1,0.4,0.3,0.6,0.4C1979.1,500.1,1978.9,500,1978.7,499.9"
          />
          <path
            id="path3246"
            class="countryIsland"
            d="M1978.7,502.6c0.3,0.8,1,1.2,1.2,2c0.1,0.5,0.2,1.1,0.1,1.6c-0.1,0.4-0.7,2.6,0.3,1.1
    		c-0.2,0.3-0.2,0.5-0.1,0.8c1.1,0.5,1.3-3.2,1.1-3.7c-0.2-0.9-1.8-1.7-1.8-2.6C1979.6,501.1,1978.6,501.8,1978.7,502.6"
          />
          <path
            id="path3248"
            class="countryIsland"
            d="M1979,507.8c0.2,0.3,0.5-0.4,0.2,0.5c0.3-0.4,0.4-0.8,0.2-1.3
    		C1979.3,507.3,1979.2,507.5,1979,507.8"
          />
          <path
            id="path3250"
            class="countryIsland"
            d="M1979.7,504.6c0,0.3,0,0.6,0.2,0.9C1979.9,505.2,1979.8,504.9,1979.7,504.6"
          />
          <path
            id="path3252"
            class="countryIsland"
            d="M1982.9,507.5c1.7-0.9,0.6-2.6-0.1-3.7C1983.1,505,1983.1,506.3,1982.9,507.5"
          />
          <path
            id="path3254"
            class="countryIsland"
            d="M1986.3,505.2c0.7-0.7-0.4-1.8-0.9-2.2C1985.1,503.9,1985.6,504.7,1986.3,505.2"
          />
          <path
            id="path3256"
            class="countryIsland"
            d="M1989.5,509c-0.2,0.5-0.2,1-0.1,1.5C1989.5,510,1989.5,509.5,1989.5,509"
          />
          <path
            id="path3258"
            class="countryIsland"
            d="M1990,512.8c1.2-0.4,0.3-1.9-0.5-2.2C1989.7,511.3,1989.8,512,1990,512.8"
          />
          <path
            id="Bangladesh_mainland"
            class="country"
            d="M1957.4,469.4c0-0.3,0.1-0.6,0.2-0.9c0.8,0.6,2.2,1,2.6,2c0.4,0.9,1.9,0.9,2.7,1.2
    		c-0.2-0.6-0.6-1.1-1-1.5c1.2-0.2,1.4,0.9,1.9,1.7c0.6,1.3,2.3,1.5,3.4,2.2c0.2-0.6,0.4-1.2,0.1-1.8c2.2-0.2,2.7,4.2,2.8,5.5
    		c0,0.9-0.2,1.7,0.8,2.1c1.6,0.5,3.4,1,5.1,1c3.5,0,7-0.3,10.5,0c1.6,0.2,6.1,2.9,2.2,2.6c1.2,2.2-1.5,4.2-1.7,6
    		c-0.7-0.6-1.4,0.5-1.9-0.2c0.1,1.2-1.2,1-1.9,0.9c0.3,1-0.9,2.6-0.9,3.7c-0.1,1.5,1.4,3.7,2,5.1c0.1-0.5,0.2-1,0.1-1.4
    		c0.7,0.3,1.9,4,3.3,1.9c0.5-0.8-0.5-2.2,0-3.2c0.4-1,0.7-1.2,0.4-2.4c1,0.9,2-1.3,2.6,0.2c0.8,1.8,1.2,3.9,1.7,5.8
    		c0.7,2.4,1.6,4.8,2.2,7.3c0.6,2.4,0.7,5,1.7,7.3c-0.8,0.3-1.9-1.2-2.9-1.1c-1.5,0.2,0.5,4.6,0.6,5.5c-0.7-1.1-1.9-2.4-2.3-3.6
    		c-0.4-1.3-0.4-2-0.6-3.3c-0.3-2.3-3.5-4.8-1.7-7.2c-0.5,0.4-0.7,1.1-0.7,1.7c-0.7-2-3.2-3.6-3-5.8c-0.1,0.9-1.1,1.3-1.6,1.9
    		c-0.2,0.2-0.3,0.5-0.2,0.8c-0.6,0.2-1.2-0.1-1.5-0.6c0.2,0.3,0.2,0.5,0,0.8c-1,0.6-2.6-3.2-2.9-3.6c-0.3-0.5-0.4-1-0.3-1.5
    		c0-0.9-0.4-1.2-0.6-1.9c-0.2-0.6,0.8-0.8,0.4-1.4c-0.1,0.6,0.7,0.3-0.7,0.4c0-0.3,0.1-0.5,0.1-0.8c-0.4,0.3-0.7,0.3-1.2,0.1
    		c0.3,0.2,1.6,1.8,0.4,1.7c-0.6-0.1-1.6-0.4-2-0.5c0.9,0.6,2.3,1,2.9,2c0.4,0.7-0.3,1.2-0.9,1.4c0.7,0.9,0.3,1.7-0.3,2.5
    		c1.3,0,0.9,1.4,0.9,2.3c0.2-0.3,0.4-0.5,0.6-0.7c1.1,1,1.3,3.6-0.5,4c0.1-0.5,0-1-0.2-1.4c0.2,1.2-0.2,2.2-0.4,3.3
    		c-0.3,1.4-1.9,0.8-1.2-0.3c-0.2,0.2-0.4,0.4-0.7,0.6c-0.4-1.3,1.1-1.7,1.2-2.9c-0.4,0.7-1,1.2-1.6,1.7c0.1-0.5,0.2-0.9,0.4-1.4
    		c-0.3,0.5-0.6,0.9-0.9,1.4c-0.5-1.5-0.7-2.7-0.1-4.2c-1.6,0.6-0.2,2.1-0.8,3.2c-0.4,0.8,0.6,0.8,0.2,1.8c-0.3,0.8-1.1,0.4-1.5-0.1
    		c-0.1,0.5-0.1,0.9-0.1,1.4c-1.1,0-0.6-4.2-0.6-4.9c-0.4,1,0.1,1.9-0.1,2.9c-0.4-0.8-0.8-1.6-0.9-2.5c0,1.4,1.4,3.6,0,5
    		c-0.4-0.7,0-1.6-0.6-2.2c0.3,0.7-0.2,2.3-0.7,2.8c0.1-1.4-1.4-2.4-1.6-3.9c-0.2-1.7-1.1-2.9-1.5-4.6c-0.2-0.8-0.3-1.5-0.6-2.3
    		c-0.3-1,0-1.5-0.1-2.5c0-0.6-0.8-0.5-1.2-0.6c-0.8-0.2-0.3-1.1-0.2-1.6c0.1-1.2-2.1-1.3-2-2.8c0-0.8,0.9-1.1,1-1.8
    		c0.1-0.7-0.2-1.6-0.5-2.3c-0.5-1.1-2.7-1.2-3.8-1.9c-0.6-0.4-1.7-1-1.7-1.9c-0.1-0.9,0.7-1.5,0.8-2.4c1.2,2.1,1.7-1.9,2-1.9
    		c0.8-0.2,2.5-0.3,3.2,0.1c0.9-1.5-1-1-1.2-2.4c-0.1-1-1.4-0.2-2.1-0.6c-0.9-0.6-1.3-1.8-2.4-2.3c-2.8-1.1-1.3-4.3,1-5.2
    		C1958.8,469.7,1958.1,469.4,1957.4,469.4"
          />
        </g>
        <g id="do" class="countryGroup">
          <path
            id="Dominican_Republic_Hispaniola"
            class="country"
            d="M761.1,542.5c0.3-0.7,0.7-1.9,0.3-2.6c-0.4-0.6-0.9-1.8-1.4-2.3
    		c1,0,3.2-1.5,2.1-2.6c-1.1-1,2.1-1.7,0.8-3.1c-0.4-0.5,0.1-1.5,0.3-2c0.2-0.9-0.6-1.8-0.1-2.7c1-2,3-0.1,4.2-0.6c1-0.4,2-0.6,3-0.1
    		c1,0.5,2,0.6,2.9,1.1c0.9,0.4,1.3,0.9,2.3,0.9c1,0,1.2-0.2,1.3,1c0.2,2.1,1.1,2.4,3,1.9c0.7-0.2,1.6-0.4,2.2,0.1
    		c-0.3,1-1.1,1-2,0.8c-0.8-0.1-2.6,0.5-1.1,0.9c0.7,0.2,3.3,1.2,3.9,0.9c1.7-0.8,2.8,1.4,3.8,2.4c2.1,1.9-1.2,2.9-1.6,3.7
    		c-0.7,1.4-1,0-1.6-0.6c-0.6-0.6-1.2-0.2-1.9-0.4c-0.5-0.2-1-0.3-1.5-0.3c-0.6,0-1.2,0.5-1.8,0.2c-2.6-1.6-3.2,1.4-5.2,1.7
    		c-0.5,0.1-2,0.4-2.4,0.1c-0.6-0.5,0.1-1.5-0.8-1.9c-0.4,0.8-1.3,0.7-1.9,1.3c-0.5,0.5-1.1-0.3-1.4,0.4c-0.7,2.2-1.7,3.7-3.2,5.5
    		c-0.3-0.7-1.1-1-1.3-1.7C761.8,543.8,761.8,543,761.1,542.5"
          />
          <path
            id="Dominican_Republic_Isla_Saona"
            class="countryIsland"
            d="M785.2,541.9c-0.5-0.1-1-0.2-1.4-0.5C784.2,541.3,785,541.3,785.2,541.9"
          />
        </g>
        <g id="gw" class="countryGroup">
          <path
            id="path3430"
            class="countryIsland"
            d="M1177,599.3c0-0.2,0-0.4-0.1-0.6c-0.3,0.2-0.5,0.3-0.7,0.6
    		C1176.5,599.3,1176.7,599.3,1177,599.3"
          />
          <path
            id="path3432"
            class="countryIsland"
            d="M1177.5,596.2c0.7-0.3,0.6-0.6-0.3-0.7C1177.3,595.7,1177.4,595.9,1177.5,596.2"
          />
          <path
            id="path3434"
            class="countryIsland"
            d="M1178.1,603.2c0.3-0.2,0.6-0.4,0.9-0.5C1178.4,601.5,1176.9,602.3,1178.1,603.2"
          />
          <path
            id="path3436"
            class="countryIsland"
            d="M1179.3,595.7c-0.4,0-0.7,0.1-1.1,0.1C1178.3,596.9,1179.6,596.8,1179.3,595.7"
          />
          <path
            id="path3438"
            class="countryIsland"
            d="M1179.3,598.9c-0.1,0.2-0.2,0.5-0.3,0.7c0.6,0.3,1,0,1.1-0.6
    		C1179.8,599,1179.5,598.9,1179.3,598.9"
          />
          <path
            id="path3440"
            class="countryIsland"
            d="M1181.1,602c0.4-0.3,0.7-0.7,0.8-1.2C1181.4,601,1181.1,601.4,1181.1,602"
          />
          <path
            id="path3442"
            class="countryIsland"
            d="M1184.7,602.1c0.2-0.7,0-0.9-0.7-0.6C1184.2,601.8,1184.4,601.9,1184.7,602.1"
          />
          <path
            id="Guinea-Bissau_mainland"
            class="country"
            d="M1174.2,592c0.7-0.5,1.6-0.1,2.4-0.5c0.8-0.4,1.6-0.8,2.5-0.8
    		c1.9,0,3.7,0.5,5.3-0.9c1.4-1.2,3.2-0.9,4.9-0.9c2.6,0,5.2,0,7.8,0c-0.2,1,0.9,2.2,0,3.1c-0.5,0.5-1.8,0.4-2,1.1
    		c-0.3,1,3.4,1.9,1.6,3.9c-0.9,1.1-2.3,0.5-3.5,0.7c-1.1,0.2-2.5,1.1-3.6,1.4c-2,0.6-1,4.1-3.1,4.8c-0.5-1.9-0.8-2.1-2.4-3
    		c0.2-0.3,0.3-0.5,0.5-0.8c-1.4,1.1-0.1-3.4,2-1.7c-0.5-1.4-1.8-0.5-2.5,0.1c-0.7-1.3-0.8-2.1,0.7-2.7c1.3-0.5,1.8,0.9,3,0.9
    		c-0.7-0.3-1.1-1.1-0.4-1.7c-0.5-0.2-0.9-0.1-1.4,0.2c-0.7,0.4-1.3-0.1-2,0c-0.7,0.1-1.2,0.7-1.7,1.1c-0.7,0.5-1.6,0.5-2.4,0.6
    		c0-1.3,1.1-1.7,2-2.4c-1.2-0.1-2.2,1.2-3.5,0.9c-2.4-0.5-0.9-2.7,0.3-3.4c-0.5,0-0.9,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.8,0.8
    		C1175.5,593.2,1175,592.1,1174.2,592"
          />
        </g>
        <path
          id="gh"
          class="countryGroup country"
          d="M1276.5,644.5c-0.5-2.5-0.6-4.4,0.4-6.7c0.9-2,0.4-2.7,1.1-4.7c0.6-1.5,2.5-3.5,3.1-4.8
    	c0.8-1.7,0.2-4.3-0.4-6.1c-0.2-0.7-0.5-1.2-0.8-1.8c-0.3-0.7,0.2-1.5,0.2-2.2c0.1-1.7-0.7-2.9-0.3-4.5c0.4-1.7-0.3-3.6-0.7-5.3
    	c-0.2-0.8-1-1.7-0.5-2.5c0.4-0.7,0.9-1.6,0.7-2.4c4.2,0.4,8.1-0.3,12.5,0c0.9,0.1,3.6-0.2,3.8,0.3c0.4,1,2-0.6,2.8-1.2
    	c0.8-0.6,3.2-0.1,2.7,1c-0.8,1.6-1,3.3,1.4,5c1.9,1.5,0.8,0.9,1.2,2.4c0.2,0.7,0.2,0.7,0.2,1.5c-0.1,0.8-0.1,2.2-0.3,3.3
    	c-1.1-0.2-0.6,0.6-1.1,1.2c0.7,0.1,1.1-0.6,1.7-0.5c0.9,0.2,0.9,1.5,0.7,2.2c-0.4,1.2,0.4,4.4-1.2,4.1c0.4,1.1,1.6,1.7,2.2,2.6
    	c0.8,1.1,0.1,1.4-0.3,2.4c-0.5,1.2,0.5,2.9-0.2,4c-0.8,1.4-0.9,2.1,0.2,3.3c0.5,0.6-0.1,2-0.3,2.6c-0.6,1.6-0.8,2.2,0.1,3.8
    	c0.6,1,1.8,1.8,2.6,2.5c0.2,0.2,1.8,1.1,1.8,1.2c0.2,0.8-1.1,1.2-1.3,1.9c-0.3,0.9-0.9,1.7-2.1,1.6c-0.6-0.1-0.8-1.5-1.2-1.9
    	c-0.7-0.9-2-0.6-3-1c0.8,0.6,2.1,0.2,2.9,0.9c1.7,1.2-0.9,2-1.7,2.1c-3.1,0.6-5.1,2.3-7.7,3.9c-1.5,0.9-3.2,0.9-4.8,1.5
    	c-1,0.4-2.1,0.6-2.9,1.3c-0.7,0.6-1.2,1.3-2.1,1.7c-0.8,0.4-1.5,0.3-2.1-0.4c-0.8-0.9-1.7-1-2.8-1.3c-1.2-0.3-2.1-0.1-2.8-1.2
    	c0.9,0,0.5-0.2,1.4-0.1c-0.4-1.2,1-5.1-1.6-4.4C1279.1,648.4,1277.4,645.7,1276.5,644.5"
        />
        <path
          id="at"
          class="countryGroup country"
          d="M1373.8,290.6c0.7-1.2,0.6-0.9,0.2-2c-0.1-0.4,5.4-0.2,4.1,2c1.6,0,1.6-1.5,1.9-2.7
    	c0.7,0.6,2.2,0.6,3.1,0.5c-0.7,0.3,1.2,0.9,1.4,0.9c0.5,0.2,1.2-0.4,1.9-0.7c1.4-0.8,3.6-1.4,5.2-0.9c0-0.3,0-0.6-0.1-0.8
    	c0.8,0.1,2,0.7,2.8,0.4c1.6-0.8,1.4,1,2.4,1.4c0.8,0.3,1.1-1.6,0.4-1.7c-0.9-0.1-0.3-1.2-0.5-1.6c-0.1-0.6-0.7-0.8-0.9-1.4
    	c-0.7-1.4,3-1.9,3.7-2.4c0.6-0.4,0.1-2.3,1.3-1.8c1.6,0.6,0.7-1,1-2.5c1.1,0.3,2.5,2.1,3.7,2.3c2.3-0.6,2.5,0,2.8,0
    	c0-0.7,0.4-1.4,1.1-1.6c0.9-0.3,0.8-0.9,0.9-2.1c1.5-0.1,3.3,0.6,4.7,1c0.5-0.2,2.8,1.5,4.4,1.1c1.3-0.3,3.9-0.5,4.1,1.1
    	c0,0.2,0,0.9,0.2,1.6c0.2,0.7,0,1.8,0.4,2.4c0.5,0.7,1.3,0.9,1.2,1.4c-0.1,0.5-0.9,1.3-1.3,2.2c-0.7,1.8-1.5-0.5-3.1,0.2
    	c-0.7,0.3,2.6,1,0.6,2c-1.5,0.8-1.1,2.8-0.7,4c-1.1-0.2-3.9,1.4-3,2.9c-1.6-1-2.3,0.1-3.8,0.3c-1.1,0.1-1.8-0.4-2.8-0.2
    	c-0.8,0.2-2.4,1.7-3,2.1c-1.1,0.6-4-0.8-5.2-0.9c-2.9-0.4-5.5-0.1-8.5-1c-0.9-0.3-4.3-2.7-2.6-3.8c-1-0.2-1.8,0.5-2.7,0.6
    	c-1.4,0.2-2.5,0-3.9,0.4c-0.8,0.2-1.5,1.7-2.3,1.5c-0.9-0.3-1.6-0.4-2.5-0.7c-0.7-0.2-0.3-1-0.8-1.1c-0.6-0.1-0.9,0.4-1.2,0.8
    	c-0.6,0.7-2.3-0.3-2.8-0.8c-0.5-0.6-1.3-0.6-1.6-0.7C1374.3,291.7,1374.2,291.1,1373.8,290.6"
        />
        <g id="se" class="countryGroup">
          <path
            id="Sweden_mainland"
            class="country"
            d="M1384.6,193.2c1.1-0.2,1.4,1.8,2.2,1.6c1.2-0.3,1.5-3.1,1.1-4c-0.7-1.6,1-1.9,0.7-3.3
    		c-0.3-1.9,3.1-0.7,3.6-2.7c0.3-1.5,0.8-2.5-0.2-3.8c-0.5-0.7-1.9-2.6-0.4-3.2c1.5-0.6,2,0,2.5-2c0.4-1.4-1.2-1.6-2.2-2
    		c-1.7-0.6-2.2-1.9-1.7-3.5c0.5-1.9,0.2-2.9-0.4-4.6c-0.2-0.7-0.7-1.8-0.3-2.4c0.3-0.6,0.7-0.4,0.5-1c-0.2-0.7-0.8-1.1-0.9-1.9
    		c-0.1-0.9,0.7-1.4,0.7-2.1c-0.1-1.3,2.1-2.4,2.7-2.9c1.8-1.5,3.6-1.3,5.5-1c1.3,0.2,3.5,0.2,3.2-1.5c-0.3-1.8-2.2-1.9-3-2.5
    		c0.7-0.9,1.5-1.6,2.2-2.4c0.7-0.7,1.6-2.5,2.4-3c1-0.6,0.3-5.6,0.1-6.4c1.6,0.1,3.7-0.1,5-0.9c0.7-0.4,0-1,0-1.5
    		c-0.1-0.5,0.9-0.7,1.2-1c1.3-1.4,2.6-2.3,4-3.4c1.2-1-1.1-2.3-1.6-2.8c2.3-1.1,3-3.3,5.2-4.3c1.4-0.7,2.3-0.5,3.8-0.2
    		c0.7,0.2,2.4-0.4,2.3-1.3c-0.1-0.7-0.7-1.6-0.4-2.2c0.3-0.8,3,0.2,3.6,0.1c1.5-0.3,5.9,1.7,8.2,0.2c-0.5-0.2-1-0.4-1.5-0.5
    		c1.2-0.8,2.9-2.4,0.8-3.3c3.3-1.5,6.9,1.8,9.7,3c1.9,0.8,4.3,0.9,6.2,1.7c0.6,0.3,3.9,2.3,4.3,3c0.3,0.6-0.6,0.6-0.7,1
    		c-0.1,0.1,0.1,0.3,0.1,2c0,0.4,0.8,0.5,1.2,0.6c1.4,0.4,0.4,1-0.1,1.7c-0.6,0.7,1.2,1.8,1.6,2.1c0.9,0.8,0.7,2.2-0.1,3.1
    		c-1.6,1.8,2.4,3.6,2.9,5.3c-1.6,0.2-4.4,1.1-5.8-0.2c0.2,0.2,0.4,0.5,0.6,0.7c-1.2,0-2.1-0.2-3.1-0.9c-0.2,2-2-0.9-2.4,0.5
    		c-0.2,0.7,0.6,1.3,0.7,2c-0.7,0-1.4-0.3-2.1-0.6c0.2,0.2,0.5,0.3,0.8,0.4c-0.5,0.4-1.1,0.4-1.7,0.2c2.3,0.7-1.8,1-2.2,1.1
    		c0.5,0.8,1.7,1.1,0.7,2.4c-0.3,0.5-2.3,1.8-2.9,1.5c0.4,0.5,0.9,0.7,1.5,0.9c-2.1,0.4,1.7,1.7,2.1,2.4c-1.4,0.7-3.4,1.5-3.9,3.1
    		c-0.3,0.9-2.9,2-2.7,2.8c-0.2-0.3-0.3-0.5-0.5-0.8c-0.4,0.9-1.7,1.2-2.5,1.4c-0.9,0.2-1.2,2-2.1,0.4c0.2,1.2,0,0.6-0.9,0.6
    		c-0.5,0-0.9,1.1-1.3,1.4c-1.5,1.1-3.5,0.9-5.1,2.1c0.5,0.1,1,0.1,1.5,0.1c0,0.8-0.6,1.3-1.4,1c-0.4-0.2-0.6,0.9-1.4,0.6
    		c1.8-0.2-0.4-0.4-0.7-0.3c0.2-0.8-0.9-1.2-0.9-1.9c-0.3,1.2,1.1,1.8,1.2,2.9c0.3,1.6-2,2.7-3,1.4c-0.6,1.3,0.2,2.2,1.5,2.3
    		c-0.9,0.1-1.3,1.5-1.5,2.2c-0.3,1.1,1.2,1.6,0.8,2.6c-0.6-0.6-1.4-1-2.2-0.8c2.7,0.6-1.6,0.4,0.8,0.9c-2-0.2-0.6,0.2-0.1,0.4
    		c-2.1-0.4,1.2,0.9-0.8,1.1c1,0.4,0.4,1.7,0.5,2.5c0.1,1.1,0.8,1.8,1.3,2.7c-0.3,0.1-0.5,0.2-0.8,0.4c0.4,0.2,2.1,0.3,2.2,0.6
    		c0.6,1.3,1.5,0.4,2.5,0.2c-0.6,2,2.9,1.7,3.7,2.8c-1.8,0.4,0.6,1,1.2,1.2c0.4,0.1,2.6,3,0.9,1.6c0.5,0.4,0.9,0.9,1.2,1.5
    		c-1.1,0-1.9,0.6-2.8,1.3c-0.8,0.6-2.9,0.9-1.5,1.4c-0.6,0.1-1,0-1.4-0.4c-0.1,0.9,2.6,1.4,2.3,0.1c0.5,0.3,1.5,2,0.1,1.2
    		c1.9,0.7-0.8,0-1.2-0.1c0.1,0.6,0.6,1,1.2,1.1c-0.6,0.3-1.3,0.4-1.9,0.6c-0.9,0.3-1,1.2-1.7,1.6c-0.5,0.3-0.8-1.6-1.6-1.6
    		c0.5,0.4,0.7,0.9,0.6,1.4c-0.2-0.2-0.5-0.3-0.7-0.5c0.2,0.7,0.2,1.2-0.7,0.7c0.1,0.2,0.2,0.5,0.4,0.7c-0.9,0.5-1.9,0.5-2.9,0.4
    		c0.2,0.2,0.5,0.4,0.7,0.6c-1.6,0.9-3.6-0.7-5.1,0.4c1.2-0.3,2.9-0.2,3.6,1.1c-1,0.5-1.9,0.1-2.9,0c1.5,0.5,4.2,3,1.5,4
    		c0.6-0.1,0.9,0.2,0.7,0.8c-0.5-0.3-1-0.4-1.6-0.2c0.3,0.4,2.1,1.4,0.9,1.9c-1.1,0.4,0.9,1.1,0.3,1.9c-1.4,1.9-0.1,3.9-1.7,5.8
    		c-1,1.3-1.5,5-3.8,4.3c-1.4-0.4-2.8,0.2-4.2,0.1c-0.8-0.1-2.8-0.2-1.7,1.1c-1.4,0.5-2.5-0.3-3.3,1.5c-0.5,1,0.6,1.8,0.7,2.7
    		c0.2,1.8-2.3,0.6-3,0.7c-2.3,0.2-4.2,0.3-6.5,0.2c0.7,0,1.3-2.3,0.6-3.1c-0.8-0.8-2.7-3-2.6-4.1c2.5,0.9,1,0,0.9-1.4
    		c3.3-0.6,0.1-1.7-0.8-2.8c-0.8-1.1-1.9-2-2.4-3.3c-0.3-0.8-0.7-1.9-1.8-1.5c0-0.6,0.1-1.1,0-1.7c-0.2-0.9-1.8-1.1-0.3-1.6
    		c-1.4,0.5,0-3.8,0.1-4.3c-0.9-0.2-1.4,0.8-2.3,0.7c0.4-0.5,0.8-1,1.2-1.5c-1.3-0.1-0.3,0.8-1.5,1.2c-0.1-0.7-0.6-0.8-1.2-0.4
    		C1385.5,197.8,1383.4,194,1384.6,193.2"
          />
          <path
            id="path3388"
            class="countryIsland"
            d="M1387.5,199.9c-0.5,0.2-1,0.5-1.4,0.7c0.4,0.6,2,0.8,2.4,0
    		C1388.6,200.1,1387.9,199.2,1387.5,199.9"
          />
          <path
            id="path3390"
            class="countryIsland"
            d="M1387.1,201.5c-0.2,0.2-0.3,0.5-0.4,0.7c0.6,0.1,1.1-0.2,1.4-0.8
    		C1387.7,201.5,1387.4,201.5,1387.1,201.5"
          />
          <path
            id="Sweden_Oland"
            class="countryIsland"
            d="M1417.3,214.5c0.1,0.4,0.2,0.9,0.1,1.4c1.2-0.2,1.7-2.5,2-3.5c0.5-1.8,1.4-3.5,2.1-5.2
    		c-1.2,0-1.5,2.2-2,2.9C1418.7,211.5,1416.9,212.6,1417.3,214.5"
          />
          <path
            id="path3394"
            class="countryIsland"
            d="M1427.4,193.7c-0.2,0.1-0.5,0.2-0.7,0.4C1427,194,1427.2,193.9,1427.4,193.7"
          />
          <path
            id="Sweden_Gotland"
            class="countryIsland"
            d="M1429.4,209.9c-0.3-0.8,2.5-3.1,3.1-3.5c-2.7-0.3,3.3-4.5-1.2-3.6
    		c-1.2,0.3-2.4,1.3-3.2,2.3c-0.7,0.8-0.4,4,0.9,4.2c-0.4,0.5-0.8,1-1,1.5C1428.6,210.9,1429.2,210.5,1429.4,209.9"
          />
          <path
            id="path3398"
            class="countryIsland"
            d="M1428.1,182.9c0.3,0.3,0.7,0.5,1.2,0.4c-0.3-0.5-0.8-1-1.4-1.2
    		C1428,182.3,1428,182.6,1428.1,182.9"
          />
          <path
            id="path3400"
            class="countryIsland"
            d="M1429,193.2c-0.2,0.2-0.3,0.5-0.5,0.7C1429,193.7,1429,193.6,1429,193.2"
          />
          <path
            id="path3402"
            class="countryIsland"
            d="M1429.7,190.2c0.2-0.1,0.5-0.3,0.7-0.4c-0.6-0.2-1,0-1,0.6
    		C1429.5,190.4,1429.6,190.3,1429.7,190.2"
          />
          <path
            id="path3404"
            class="countryIsland"
            d="M1433.3,202.7c0.5,0.2,1,0.1,1.4-0.3C1434.2,201.8,1433.6,202,1433.3,202.7"
          />
          <path
            id="path3408"
            class="countryIsland"
            d="M1448.3,143.1c-0.2,0-0.5-0.1-0.7-0.1C1448.1,143.4,1448,143.2,1448.3,143.1"
          />
          <path
            id="path3454"
            class="countryIsland"
            d="M1421.1,166.5c0.2,0.4,0.4,0.2,0.2,0.8C1421.1,167,1421.1,166.8,1421.1,166.5"
          />
        </g>
        <g id="tr" class="countryGroup">
          <path
            id="Turkey_Eastern_Thrace"
            class="country"
            d="M1489.2,346.1c0.8-0.4,1.8-1.4,2-2.2c0.3-1.3-0.6-2.2,1.1-3
    		c0.9-0.4,0.6-0.4,0.4-1.4c-0.1-0.6-0.4-1.2-1-1.4c-2.3-0.8,0.5-1.1,0.4-2c0-0.3,2.4-1,2.9-1.1c1.3-0.4,2.1-0.9,3.1,0.2
    		c0.9,1,1.4,0.4,2.5,0.3c0.5,0,1.2,0,1.6,0.4c0.7,0.6-0.4,0.8,0.2,1.5c1.9,2.5,4.2,3.6,7.2,4.5c1.2,0.4,0.2,2.1-0.5,2.3
    		c-1.4,0.5-3.4-0.8-4.9-0.9c-0.7,0-1.1,0.5-1.7,0.7c-0.7,0.4-1.5-0.3-2.3,0c-1,0.3-1.4,1.4-2.1,2.1c-1.1,1.2-2.6,1.6-3.9,2.5
    		c-1.1,0.8-2.1,3-3.3,3.5c-0.4-1.4,0.2-2.9,1.6-3.4c0.2-0.1,2.9-0.9,2-1.6C1493.1,345.8,1489.9,348.9,1489.2,346.1"
          />
          <path
            id="Turkey_Anatolia"
            class="country"
            d="M1490.2,356.9c0.1-0.7,0.2-4.4,0.7-4.6c1.4-0.5,1.8-2.5,3.2-3.2c0.8-0.4,1.7,0.3,2.4-0.2
    		c0.9-0.6,1.2-0.1,2,0.3c1.1,0.7,4.7,0.4,2.4-1c0.7-0.3,1.5-0.3,2.2-0.1c-0.4,0.1-0.5,0.4-0.5,0.7c0.4,0.6,2.6,0.2,3.1,0.2
    		c1.7-0.2,4.1,0.8,5.5-0.4c-0.7-0.7-1.5-0.1-2.2-0.6c0.9-2.3,5.1-1.4,6.9-1.9c-1.6-0.8-3.9-0.1-5.3-1.5c-1.5-1.6,0-2.9,1.6-2.7
    		c1.8,0.2,3.6,1,5.4,0.6c2.4-0.5,4.4,0.8,6.9,0.6c1.1-0.1,1.3-0.7,1.7-1.6c0.5-1,2.2-1.4,3.1-1.9c2.6-1.5,4.6-2.8,7.5-3.6
    		c3-0.8,5.3-0.4,8.4-0.4c1.2,0,2.6,0.5,3.8,0.1c0.6-0.2,0.8-0.8,1.3-1c0.6-0.2,1,0.2,1.6,0.2c-0.9,1.1,0.7,2.6,1.7,2.9
    		c0.8,0.2,1.5,0.4,2.3,0.3c0.9-0.1,1.6-1.1,1.6,0.1c0.2-1.9,1.4,0.4,1.6,0.9c0.2,0.7,2.1,3.1,2.9,2.3c1-1.1,2.1-0.8,3.1,0
    		c1.3,1,2.9,1.2,4.3,2c0.8,0.4,1-1.3,2.1,0c0.9,1,2.7,1,3.9,1.1c1.7,0.2,3.5-1,5.2-1.2c1.5-0.1,2.3-0.3,3.7,0.2
    		c2,0.7,3.4,1.2,5.5,0.2c2.4-1.1,4.7-2.5,6.8-4.1c0.8-0.7,1.6,0.5,2.5,0.5c0.5,0,0.5-0.7,1.1-0.6c0.4,0.1,0.8,0.2,1.2,0.2
    		c0.6,0.1,2.2,0.8,2.5,0c0.4-0.9,2.5-1.7,1.8-0.3c0.6-0.1,1.2,0.3,1.7,0.7c0.3,0.3,0.7,0.6,1,0.8c0.6,0.1,0.7,0.3,0.4,0.8
    		c0.3,0.8,1.4,0,1.8,0.7c0.3,0.4,0.5,1.7,0.9,2c1.4,1.1,2.5,2.2,1.7,3.3c-0.8,1.1,1.3,2.8,0.2,3.5c1.8,0.9,2.7,0.7,4.5,0.8
    		c1.6,0.1,3.8,2.1,4.4,3.5c-0.9-0.7-2.2-1.7-2.5,0c-0.1,0.5,0,1.1-0.1,1.6c-0.2,0.7-2,0.3-2.6,0.5c0.4,0.5,1.3,1.8,1.4,2.4
    		c0.3,1,0.7,1.6,1.1,2.6c0.6,1.4,0,3.5,1.9,4.1c-0.6,0.6-1,1.7-1.2,2.5c-0.4,1.6,1.3,1.4,2.1,2.1c0.8,0.8,0.7,1.8,0.9,2.8
    		c0.1,0.5,1,0.7,1.4,1c0.4,0.3,0,1.2,0.3,1.6c-1.7-1.2-2.4,1.8-3.3,1.4c-1.2-0.4-0.2-2.1-1.1-2.7c-0.5-0.3-1.2-0.3-1.6,0.1
    		c-0.8,0.7-1.8,0.5-2.7,0.2c-1.4-0.5-2.5-0.9-4.1-0.9c-1.1,0-1.8-0.3-2.3,0.7c-0.7,1.1-2,2.2-2.5,0.5c-0.4-1.5-2.3,0.3-3.1,0.6
    		c-2.1,0.7-3.9,0.7-6,0.4c-3.8-0.5-7.2,3.4-11.1,3.6c-1.6,0.1-4.4,0.5-5.7-0.5c-1.4-1.1-2.3-1.7-4.1-0.8c-1.9,0.9-6,3.1-7.6,1.1
    		c-0.8-1-2.5-1.4-2.8,0.2c-0.2,1.6,0.3,2.6,1,4c-0.7,0-1.5,0.2-2.2,0.1c-0.1,0.6,0.3,1.3,0.1,1.9c-0.3,0.7-1.2,0.9-1.4,1.7
    		c-0.5-0.4-1.1-0.8-1.7-0.9c0.6-0.8-0.2-1.9-0.7-2.5c-0.9-1.1,0.1-1.8,1-2.6c1-0.8,1.6-1.4,0.7-2.7c-0.4-0.5-1.2-1.1-1.7-0.4
    		c-0.7,1-1.5,0.8-2.4,1.5c1.5-0.2,0,1.4-1.2,1.2c-1.4-0.3-2.7-1.1-3.9-1.7c-3-1.5-4.6,2.9-6,4.7c-0.2-0.2-0.4-0.5-0.7-0.7
    		c-0.5,0.6-0.9,1.6-1.8,1.2c-0.8-0.3-2.9,0.2-3.7,0.6c-1.3,0.7-2.8,0.6-4.1,0c-2-0.9-2.7-3-4.5-3.9c-2.1-1-4.1-2.1-6.4-2.5
    		c-1.2-0.2-3.6-0.7-3.6,1.2c0,1.1,0.2,3.1-0.6,3.9c-0.4,0.5-1-0.4-1.4-0.5c-1-0.1-2.1,1.1-3.2,1.3c-1.2,0.2-3-0.6-4-1.2
    		c-0.8-0.5-0.8-2-1.8-2.1c0.8-1.1-1.1-2.3-1.3-0.9c-0.8-0.7-2.7-0.9-3-2.1c-0.2,0.8-2.2,2.2-2.9,2.7c0.2-0.5,0.1-0.8-0.5-0.9
    		c0.6-0.1,0.9-0.6,0.9-1.2c-0.7,0.6-4.2,1.3-5.1,1.1c0.8-0.9,2.2-1,3.2-0.9c1.1,0,1,0.3,1.4-0.5c0.3-0.6,1-0.9,1.4-1.3
    		c-1.3-0.6-5.9-0.7-7,0.4c-1.7-1.9,1.5-0.9,1.7-1.4c0.2-0.4,0.2-1-0.1-1.4c-0.6-0.9-1.2,0.4-0.9-1.1c-1.6,2-1.5-2.2-2.9-2.1
    		c1-0.6,2.2-1.1,1.3-2.5c-0.6-1-2.4-0.5-2.9-1.2c-0.4-0.8-0.9-1.9-1.6-0.5c-0.3,0.6-2.4-1-2.5-1.5c2.2,0.5,0.3-1.8,0.4-2.6
    		c0.1-0.7,1.2-0.2,1.4,0.2c0.4,0.7,0.3,2,1,2.6c-0.2-1.6,2.3,0.4,3-1.2c-1.4-0.2-2-0.9-2.9-1.9c0.8-0.4,1.1-1.3,2-1.5
    		c-0.4-1.2-1.6,0-1.5-1.3c0.1-1.3-0.6-1.5-1.5-2.3c0.6-0.7,1.4-1.4,1.9-2.2C1494.2,355.4,1492.2,357.6,1490.2,356.9"
          />
          <path
            id="Turkey_Gokceada"
            class="country"
            d="M1487.4,351.6c-0.6-0.9,1.3-2,1.6-1C1489.3,351.2,1487.8,351.6,1487.4,351.6"
          />
        </g>
        <path
          id="ug"
          class="countryGroup country"
          d="M1526.5,708.6c0.5-1.4,0.3-3.2,0.5-4.6c0.3-1.8,0.4-3.6,0.7-5.4c0.3-2.2,1.6-3.5,1.8-4.6
    	c0.1-0.7-0.1-1.4,0-2.1c0.2-1.1,1.3-0.8,1.8-1.6c1.6-2.5,4-4.2,6.1-6.4c0.8-0.8,1.9-2.3,2.3-3.4c0.3-0.9-1.5-1.9-2.1-2.3
    	c-0.5-0.3-1.4,0.6-1.8-0.3c-0.3-0.7-0.1-1.7,0.1-2.4c0.4-2.2-0.4-3.9,0.7-5.9c0.3-0.6-0.5-0.7-0.3-1.4c0.2-0.7,0.8-1.2,1.3-1.6
    	c0.5-0.4,1.1-0.8,1.8-0.7c0.6,0.1,1.6,1.3,2.2,1c0.7-0.3,1.7-1.6,2.5-0.6c0.8,1.1,1,1.4,2.4,1.9c-0.8-1.3,1.4-1.8,2.4-2
    	c1.3-0.2,2.7-1.2,3.9-1c1.7,0.2,2.2,1.7,4.1,0.4c1.3-0.9,2.2-2.3,3.3-3.5c0.6,0.8,1.2,1.9,0.9,3c1.2-0.1,0.4,0.7,1.5,1.1
    	c1.2,0.3,1.6,0.6,1.1,2.1c-0.6,1.9,1.3,4,2.3,6.3c0.2,0.5,1.4,2,1.3,2.4c0,0.8,0.9,2.1,1.1,2.9c0.3,1.4,0,3.4-0.6,4.8
    	c-0.5,1.2-1.6,1.4-1.3,2.8c0.1,0.7-1.1,1.2-1.6,1.5c-1.1,0.5-1.1,2-1.8,3c-0.5,0.8-1.3,1.2-1.6,2c-0.4,1.1-0.9,1.9-1.4,3
    	c-0.5,0-2.3,0.6-2-1c-1.2,0.4-0.8,0.8-1.3,0.8c-1.4,0.2-1.4-0.6-0.4-0.6c-0.3-0.6-1.2-1.3-0.9-1.7c-0.8,0.3-0.3,0.1-1.4,0.5l0.3,0.6
    	c-0.9,0.3-0.2,1.2-1.4,1.1c0.2,1.9-2.6,0.9-3,1c-0.1,0,0.2-1.7-0.1-1.4c-1.4,3.2-1.8,1.5-2.1,1.6c0,0.7-0.5,0.7-1.6,0.8
    	c-0.5,0.8-1.8,0.8-1.7,1c1,2.6-1.1,3-1.5,4.9c-0.3,1.6,0.3,1.7,0.5,2.7c-7.2,0,1.6,0.3-5.6-0.2c-2.5-0.2-5.4,0.3-6.8,2.1
    	c-0.5,0.6-1.2,2.4-2.1,1.8c-0.2-0.2-0.4-1-0.8-1c-0.7,0-1.8,0.8-2.2,1.1C1526.4,710.4,1527.3,709.3,1526.5,708.6"
        />
        <g id="mz" class="countryGroup">
          <path
            id="Mozambique_mainland"
            class="country"
            d="M1529.9,828.4c5.5-2.1,11.1-4.1,16.7-6.1c1.2-0.4,2.4-1,3.7-1.5
    		c0.4-0.2,1.5-0.8,1.9-0.7l4.2,4.7c0-0.9,2-0.6,3-0.9c0.8-0.2,2-1,2.7-0.1c1.2,1.6,1.2,5.5,0.6,7.2c-0.8,2.2-2.7,4.3-1.5,6.6
    		c0.3,0.5,0.1,1.2,0.4,1.7c0.4,0.6,1,0.9,1.4,1.5c0.7,1.1,1.8,2.2,2.9,3.1c1,0.8,0.9,1,0.3,2.1c-0.7,1.2,1.6,1.6,1.8,0
    		c0.2-1.5-0.3-2.9-1.2-4.2c1.4-1,0.7-3.1,2.7-3.6c2.8-0.6,3.1-1.8,3.1-4.6c0-1-0.2-2.1-0.1-3.1c0.1-1.7,1-3.5,0.3-5.2
    		c-0.7-1.6-2.2-3.2-3.2-4.7c-0.9-1.4-1.8-3-2.9-4.2c-0.8-0.9-0.5-0.1-1.3-0.9c-0.2-0.7,0.2-2.1-0.3-3.5c-0.6-1.8,0.5-2.9,0.2-3.7
    		c-1.4-4.7-0.6-3.5-0.6-4.1c0.5-1.2,2.3-1,1.9-5.4c1.5,0.1,2.9,0.5,4.6,0c1-0.3,1.9-1.5,3-0.8c0.8,0.5,1.1,1.4,1.9,1.8
    		c0.6,0.3,2.6,0.4,3.3,0.3c1.1-0.3,1.9-2.2,3.2-0.8c2.3,2.5,5.2-0.5,6.3-2.3c0.6-1,1.7-1.1,2.7-0.4c1.6,1,2,1,3.5-0.1
    		c1.3-0.9,2.3-1.5,3.9-1.2c0.9,0.2,2.8-1.6,3.7-2c1.7-0.8,3.2-1.9,4.7-3.1c1.8-1.4,3,1,1.4,1.7c0.2,0.1,0.5,0.2,0.7,0.4
    		c-0.7,0.9-0.3,2-0.7,3c-0.2,0.6-1.6,1.3-0.8,1.8c0.7,0.5,0.5,3.1,0.6,4.1c0.1,0.7,0.1,1.5,0.1,2.3c-0.1,1.1-0.5,0.9-0.1,1.9
    		c0.1,0.4,0,0.7-0.1,1.1c-0.2,0.7,0.3,0.9,0.5,1.5c0.3,0.9-0.1,2.1-0.7,2.8c2,0.3-0.6,4.7,0.2,4.9c1.4,0.4,0,5,0.5,6.1
    		c1.5-0.9-0.2,2,0.2,2.7c0.1-1.3,1.4-1.1,1.2,0.2c-0.2,1.4,0.1,2.3-1.3,3.1c0.2,0.2,0.5,0.4,0.8,0.6c-1.7-0.4-0.2,0.2-0.7,0.8
    		c-0.3,0.4-0.8,0.5-1,1c1.6-0.6,0.2,2,0,2.5c-0.8,1.7-2.5,2.6-3.3,4.3c-0.8,1.7-2.1,2.6-3,4.2c-0.7,1.2-5.4,2.8-5.2,4.2
    		c0.1,0.6-3.5,1.2-4.2,1.7c-0.6,0.4-1.3,0.7-2,0.9c-1.1,0.3-0.9-0.1-1.8,0.7c-1.2,1.1-2.8,1.6-4.2,2.3c-1.4,0.7-3.6,4.1-5.2,2.8
    		c2.9,3.2-4.2,4.5-3.5,7.7c-0.6,0.1-1-0.3-1.2-0.9c0.1,1.1-0.9,2.6-0.8,1c-1.4,1.2-3,2-4.2,3.5c-1,1.3-1.9,2.5-3.1,3.7
    		c-0.5,0.5-2.4,2.5-3.1,1.6c-0.4-0.5-1-2.4-2-1.8c1.7-0.1,1.1,2.1,1.5,2.6c0.3,0.5,0.3,1.3,0.1,1.8c-0.1,0.3-0.3,0.4-0.6,0.5
    		c0.2,0.4,0.3,0.9,0.2,1.3c-0.2,2.3,0.6,1.6,1.7,3.1c0.7,0.8,0.8,1.8,0.8,2.9c0,0.4-0.3,3.5,0.2,1.6c-0.4,1.7,0.7,3.5,0.9,5.2
    		c0.1,1,0.5,2.1,0.3,3.2c-0.2,0.9-0.5,1.9,0.4,2.5c0.2-1.1,0.1-2.1,0.9-3c0,0.3,0,2.8,0.4,0.7c-0.2,1.2-0.5,2.3-0.6,3.5
    		c-0.1,1.1,0.7,2,0.5,3c-0.4,2.8-2.4,5.6-1.9,8.6c-0.3-1.6,0.9,0,1-1.4c1.1,1.8-1,4.6-2.2,5.8c-1.8,2.1-4.7,3.1-7.3,4
    		c-2.9,1-5.9,2.1-8.5,3.8c-1.2,0.8-2.4,1.4-3.1,2.8c-0.6,1.1-0.8,2.4-2.3,2.6c1,0,2.6,3.5,3.4,1.4c-0.2,1.8,0.8,6.4-1.1,6.3
    		l-4.5-0.2c0.1-1.5-0.8-3.1-0.6-4.3c0.2-1-0.3-1.9-0.9-2.7c-0.5-0.6-0.4-2.2,0.1-3.4c0.3-0.7,0.2-3.1,0.3-3.8
    		c0.1-1.7,0.1-3.4,0.2-5.2c0.1-1.6-0.2-2.6-0.5-4.1c-0.4-2.2-1.9-4-2.3-6.2c-0.2-1-0.1-2.1-0.2-3.2c-0.3-1.5-0.6-2.9-1-4.4
    		c-0.3-0.9-0.7-0.7-0.2-1.4c0.5-0.4,2-2.4,2.6-3c0.9-1,5.3-7,6.4-6.2c-1.1-1.5,0-2.3,0.4-3.8c0.2-0.9-0.7-2.2,0.3-2.8
    		c1.4-0.8,1.8-2,2.5-3.4c0.3-0.6,0.5-1.1,0.8-1.8c0.4-1,0.1-1.8,0.1-2.9c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5-1-0.9-2-0.7-2.6
    		c0.2-0.7,1.2-1.3,0.8-2.2c-0.3-0.8-1.5-0.5-1.1-1.8c0.2-1,1.9-1.1,1.8-2.3c-0.2-2,1-1.3,0.9-2.6c-0.1-1.1-0.7-2.2-0.5-3.3
    		c0.1-0.8,0.7-1.6,0.5-2.4c-0.4-1.6-0.2-2.6-0.2-4.3c0-0.5-0.3-0.9-0.4-1.5c-0.2-0.8,0.5-2.1,0.7-2.8c-1.1,0.3-1.6,0.3-2.2-0.8
    		c-0.5-1-3.6-1.1-4.3-1.5c-2.4-1.4-4.8-2.9-7.4-3.6c-0.8-0.2-3.5-0.2-4.4-0.4c-1.5-0.4-0.9-2.8-1-3.9
    		C1531,831.4,1529.8,830,1529.9,828.4"
          />
          <path
            id="path3382"
            class="countryIsland"
            d="M1567.4,887.1c0.2-0.7,0.4-1.5,0.4-2.2C1567.5,885.6,1567.1,886.4,1567.4,887.1"
          />
          <path
            id="path3384"
            class="countryIsland"
            d="M1575,862c0.6-0.6,0.5-0.8-0.4-0.7C1574.7,861.5,1574.8,861.8,1575,862"
          />
          <path
            id="path3466"
            class="countryIsland"
            d="M1602.1,839.9c0.2-0.2,0.5-0.3,0.7-0.5c0,0.4-0.1,0.8-0.2,1.2
    		C1602.5,840.4,1602.3,840.1,1602.1,839.9"
          />
        </g>
        <g id="jp" class="countryGroup">
          <path
            id="path3482"
            class="countryIsland"
            d="M2224.2,487.4c-0.2,0.4-0.4,0.7-0.4,1.2c0.5,0,1,0,1.4-0.2
    		C2225.1,487.8,2224.7,487.5,2224.2,487.4"
          />
          <path
            id="path3484"
            class="countryIsland"
            d="M2226.3,487.1c0,0.5,0.3,0.8,0.9,0.8c0.7-0.1,0.4-1.1,0.4-1.5
    		C2227.4,487,2226.9,487.3,2226.3,487.1"
          />
          <path
            id="path3486"
            class="countryIsland"
            d="M2234.6,483.4c-0.6,0.9,0.6,1.5,1.3,1C2235.2,484.3,2234.8,483.9,2234.6,483.4"
          />
          <path
            id="path3496"
            class="countryIsland"
            d="M2242.2,470.3c-0.4,0.4-0.2,0.6,0.5,0.7C2242.6,470.8,2242.4,470.5,2242.2,470.3"
          />
          <path
            id="path3504"
            class="countryIsland"
            d="M2249.8,473c1-0.3,0.6-2.5,1.2-3.3c0.7-0.9,2.6-1.8,1.9-3.2c-0.9,0.2-1.6,2.4-2.7,1.2
    		c0.1,1.1,0.5,1.3-0.2,2.2C2249.4,470.6,2249.1,472.1,2249.8,473"
          />
          <path
            id="path3514"
            class="countryIsland"
            d="M2253.3,461.4c0.1,0.2,0.2,0.3,0.3,0.5c0.2-0.2,0.4-0.5,0.5-0.7
    		C2253.9,461.2,2253.6,461.3,2253.3,461.4"
          />
          <path
            id="path3516"
            class="countryIsland"
            d="M2254.9,457.2c-0.6,0.6,0.2,1.7,1,1.5C2255.7,458.1,2255.3,457.6,2254.9,457.2"
          />
          <path
            id="path3518"
            class="countryIsland"
            d="M2256.2,454.7c0.1,0.3,0.4,0.5,0.7,0.6C2256.7,455,2256.5,454.9,2256.2,454.7"
          />
          <path
            id="path3520"
            class="countryIsland"
            d="M2259.1,451.9c-0.7,0.6-4.4,2.2-2.1,3.2c1.4,0.6,0.5-0.5,0.9-1.2
    		C2258.4,453,2260.1,453,2259.1,451.9"
          />
          <path
            id="path3488"
            class="countryIsland"
            d="M2238.9,398.4c-1.6,0.3-0.5,2.7,0.6,3C2238.9,400.6,2239.1,399.4,2238.9,398.4"
          />
          <path
            id="path3490"
            class="countryIsland"
            d="M2239.4,402.4c-0.2-0.4-0.6-0.5-1-0.4C2238.6,403.7,2240,403.6,2239.4,402.4"
          />
          <path
            id="path3492"
            class="countryIsland"
            d="M2239.5,415.6c0.1,0.3,0.2,0.6,0.4,0.9c0.5-0.3,1-0.5,1.5-0.6c-0.5-0.9-1.4-0.6-2.1-1
    		C2239.4,415.2,2239.4,415.4,2239.5,415.6"
          />
          <path
            id="path3494"
            class="countryIsland"
            d="M2241.8,412.5c-0.1-0.1-0.2-0.2-0.3-0.4c0,0.7-0.4,1.7,0.6,1.8
    		C2241.9,413.5,2241.7,413,2241.8,412.5"
          />
          <path
            id="path3498"
            class="countryIsland"
            d="M2244,410.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.5-0.3,1-0.2,1.5
    		C2243.8,411.4,2244.1,411,2244,410.4"
          />
          <path
            id="path3500"
            class="countryIsland"
            d="M2244,405.9c-0.2,0-0.4,0-0.6,0.1c0.2,0.6,0.6,0.9,1.2,0.6
    		C2244.3,406.4,2244.1,406.2,2244,405.9"
          />
          <path
            id="Japan_Kyushu"
            class="countryIsland"
            d="M2245.9,410.2c-0.3-0.3-1.2-0.3-1.5,0c-0.4,0.4,0.7,1.4,0.9,1.6c0.6,0.7,1.6,0.7,2.4,1
    		c0.6,0.3,0.7,1.3,1.3,1.7c-0.4-0.2-1.1,0-1.5-0.3c-0.5-0.3-0.6-1-0.9-1.4c-1.4,1.1,1.6,2.2,1.7,3.3c0.2-0.4,2-1.9,2.3-1
    		c0.2,0.5-0.1,1.4,0.9,1.2c1-0.2,0.1-2.9-1.5-1.9c0.5-1-0.3-1.9-1-2.4c1.1-1.6,3.4,1.6,4.1,2.6c1,1.4,1.9,3.3,0.9,5.1
    		c-0.6,1.1-1.4,0.8-0.9,2.2c0.2,0.7,0.6,1.3,1,1.9c0.3,0.5,2.4,3.7,0.4,3c0.8,0.8,1.2,1.4,2.4,1.5c0.6,0.1,0.8,0.7,1.4,0.6
    		c0.9-0.2,0.2-0.9-0.2-1.1c-0.9-0.5-1.6-2.2-0.9-3.2c0.8-1,2.3,0.9,0.6,0.4c0.3,0.9,1,1.4,1.4,2.2c0.5,1,0.3,1.9,0.2,3
    		c1.1-0.4,1.6-1.5,2.6-2.2c-2-1.3-0.6-1.8,1-1c1.1-2.2-0.8-5.5-0.7-7.9c0-0.4-0.4-2.3-0.1-2.6c0.7-1.1,1.9-2.3,0.2-3.1
    		c0.2-0.2,0.3-0.5,0.5-0.7c-1.1,0-1.1-0.7-1.8-1.3c-0.8-0.7-2.1-0.2-2.8-1c1.1-0.5,1.4-2.4,0.2-3c-1.8-0.8-3.3,2-5-0.5
    		c-1-1.4-0.3-2.1-2.2-1.8c-1.3,0.2-3.1,1.2-2.2,2.9c-1.8-0.1-1,0-2.1,1.3C2246.2,407.5,2245.7,409.3,2245.9,410.2"
          />
          <path
            id="path3506"
            class="countryIsland"
            d="M2250.2,417.9c0.1,0.8,0.6,1.4,0.8,2.1C2253.9,418.4,2249.4,416.4,2250.2,417.9"
          />
          <path
            id="path3508"
            class="countryIsland"
            d="M2250.2,424.6c0.6,0.2,0.7-0.1,0.4-0.7C2250.4,424.1,2250.3,424.3,2250.2,424.6"
          />
          <path
            id="Japan_Honshu"
            class="countryIsland"
            d="M2252,405c0.1-1.4,2,0.1,3.2-0.3c2.3-0.8,3.9-0.3,6,1c-0.8-0.6-1.3-4.4,0.1-4.5
    		c0.8-0.1,1.2,1,1.9,1.2c0.5,0.1,2.9-1,3.2-1.4c0,0.4,0.2,0.6,0.5,0.7c0.2-0.3,0.2-0.5,0.1-0.9c0.6,0.4,1.3,0.3,1.2-0.6
    		c0.8,0.5,1.5-0.5,2.4-0.1c1.3,0.5,2.1-1.3,0.7-0.8c1.4-0.4,2.5-1.2,3.9-1.4c1.5-0.3,2.7,1.2,4.1,0.9c1-0.2,2.2-1.2,2.9,0.2
    		c0.6,1.1-0.6,2.4-1.5,2.9c0.8,0.6,1.4,1.4,1.3,2.4c-0.1,1.2,1.3,1.7,2.1,2.1c0.9,0.5,1.2,1.5,2.2,1.9c0.8,0.3,1.4,0.7,2.2,0.9
    		c0.9,0.2,1.4-3.8,1.6-4.4c0.2-0.6,0.1-1.9,0.5-2.3c0.9-0.8,2.3-0.1,3.3-0.7c-0.9-1.2-1.6-1.9-2.9-2.5c-1.7-0.8-1.1-1.5-1.1-3
    		c0-0.7,0.6-1.9,1.2-0.7c0.3,0.7,0.1,2.5,1.4,2.2c-0.4-0.4-0.6-0.9-0.4-1.4c0.4,1.9,2.8,0.2,3.2,1.7c-0.6,0.1-1,0.6-0.9,1.2
    		c2.4-1.4,5.3-0.5,7.9-0.1c-1.7-1.6,1.1-6.3,3.2-3.7c-0.3,0.1-0.5,0.2-0.8,0.3c0.6,0.9,0.4,2.7,1.6,3.1c1.2,0.5,1.5-1.5,1.4-2.3
    		c-0.1-1-1.1-1.6-1.2-2.6c-0.2-2.1,3.3-0.5,3.9,0.2c0.6-0.9-2.4-2.6-0.4-3c-1.1-1.3,3-1.8,1.8,0.6c-0.3,0.6-0.4,4.4,0.7,4.7
    		c0.4,0.1,2.9-2.3,2.9-2.8c0-0.8-0.6-1.4-0.6-2.2c-0.1-1.2,1.2-1.7,2.1-2.2c-2.3-1.5-4-3.8-4.8-6.4c-0.3-1-0.4-2.1-0.4-3.1
    		c0-0.6,0.1-0.6,0.6-0.8c0.5-0.2,0-0.6,0-1c-0.2-2.9-2.6-5.2-4-7.5c-1.7-2.7-0.4-5.2,2.7-3.2c-1.1-1.2-3.3-6.2-1-6.7
    		c-0.8-0.6-0.2-1.9-0.7-2.7c-0.5-0.6-0.4-1.4-1-2.1c-0.6-0.8-0.9-1.8-1.6-2.6c-0.6-0.7-1.2-1-1.7-1.9c-1-1.7-3.3-2.6-4.8-3.9
    		c-1.9-1.7-3.3-4.4-4-6.9c-1.1,1-3.7-1.6-4.4-0.5c-1,1.6,2.4,3.9,2.9,1.6c1.1,0.5,2.3,2.2,1.5,3.5c-0.7-0.5-1.5-0.8-2.2-1.2
    		c0.3,0.5,0.3,1,0,1.5c-1.2-0.3-1.6-1.6-2.3-2.4c-0.8-0.9-1.8-0.5-2.7-1.2c0,1,2.1,3.2,1.2,4.1c-0.5,0.4-2.2,0.9-1.2,1.9
    		c0.8,0.8,2.2,1.8,2.6,2.8c0.3,0.9,0.3,3-1.2,2c0.3,1,0.8,0.9,1.6,0.9c1.1,0,1.9,1.3,2.4,2.1c1.3,2.1,1.7,5.9,1.4,8.3
    		c-0.3,1.9,1.6,5.2-0.4,6.3c-2.3,1.3-1.1,3.5-2.3,5.6c-1,1.8-3.1,1.9-4.6,2.9c-1,0.6-2.2,3.6-3.6,1.1c-0.4-0.7-0.2-1.6-1.2-1.8
    		c-0.6-0.1-1.2-1.2-0.5-1.1c1.6,0.3,1-2.3,1.3-2.8c-1.1-0.3-3.1,0.7-3.4,1.8c-0.5,1.5,2.2,3.4,1.9,5.4c-0.3,1.6-1,2.6-1.9,3.8
    		c-1.6,2.1,1.7,3.3,1.6,5c-1.3-1.1-0.3,0.8-1.6,0.3c0.5,2.3-4.1-0.3-2.6,1.3c-0.4-0.3-1.4-0.3-1.6-0.9c-0.1-0.7,0.4-1.8-0.8-1.4
    		c-0.5,0.1-1,0.5-1.5,0.6c-1.3,0.4-2.6,0-3.8,0.6c-0.8,0.4-1.6,0.7-2.5,0.8c-0.9,0.1-2.7-0.4-3.4,0c-1,0.6-1.3,0.2-2.2-0.3
    		c-1-0.6-2.7,0.5-3.6,0.9c1.6,1.8-4.8,6.7-5.2,8.1c-0.5,1.4-3.4,0.2-3.4,1.6C2250.5,402.8,2251.5,404.1,2252,405"
          />
          <path
            id="path3512"
            class="countryIsland"
            d="M2251.8,417.7c0.2,0.9,1.5,0.2,1.5-0.5C2252.8,417,2252.2,417.3,2251.8,417.7"
          />
          <path
            id="path3522"
            class="countryIsland"
            d="M2259.7,386c-0.6-0.1-0.7,0.2-0.3,0.8C2259.5,386.6,2259.6,386.3,2259.7,386"
          />
          <path
            id="path3524"
            class="countryIsland"
            d="M2260,435.1c-1.3,0.2-0.2,1.7,0.5,1.8C2262,437.3,2260.9,435.3,2260,435.1"
          />
          <path
            id="path3526"
            class="countryIsland"
            d="M2260.4,384.3c-0.6,0.3-0.7,1.1,0.1,1.3C2261.7,385.9,2261,384.6,2260.4,384.3"
          />
          <path
            id="path3528"
            class="countryIsland"
            d="M2262.9,405.3c-0.2-0.5-1.2,0.2-1.4,0.3C2262,405.5,2262.4,405.4,2262.9,405.3"
          />
          <path
            id="path3530"
            class="countryIsland"
            d="M2262.2,402.2c-0.8,0.3-0.8,0.5,0.2,0.7C2262.3,402.7,2262.3,402.4,2262.2,402.2"
          />
          <path
            id="Japan_Shikoku"
            class="countryIsland"
            d="M2264.5,407.6c-0.5,1.2-1.9,1.5-2.7,2.4c0.9,0.1,1.5-0.8,2.2-1.1c0.2,0.4,0.5,1.7,1.1,1.6
    		c0.8-0.1,1.1,0.5,0,0.7c0.8,0.3,0.8,2.5,1.9,2.4c1-0.1,1.1,1,1.9,1.3c0.8,0.3,1.6-0.2,2.2,0.5c-1.5-1-0.6-3.9,0-5.2
    		c1.6-3.3,4.3-0.9,6.3,0.4c0.2-0.8,0-1.5,0.2-2.3c0.3-0.9,1.1-1.5,1.6-2.3c0.4-0.8-1.5-2.7-1.5-3.8c-1.7,0.3-2.8-0.8-4.3-1.1
    		c-1.3-0.2-2.4,1.5-3.4,0.9c1.3,1.2,0.7,2-0.8,2.4c-2.1,0.6-1.8-0.8-3.7-1.2C2265.4,404.4,2265.5,407.3,2264.5,407.6"
          />
          <path
            id="path3534"
            class="countryIsland"
            d="M2262.9,403c-0.7,0.4-0.2,0.9,0.4,0.7C2263.2,403.5,2263.1,403.2,2262.9,403"
          />
          <path
            id="path3536"
            class="countryIsland"
            d="M2262.9,431.8c-0.8,0.4-0.7,4.1,0.5,3.7C2262.9,434.3,2263.5,433,2262.9,431.8"
          />
          <path
            id="path3538"
            class="countryIsland"
            d="M2266,402c-0.6-0.1-0.6,0.1,0,0.7C2266,402.4,2266,402.2,2266,402"
          />
          <path
            id="path3540"
            class="countryIsland"
            d="M2271.8,306c-0.5-0.1,0.9,0.9,1.1,1C2272.8,306.5,2272.3,306.1,2271.8,306"
          />
          <path
            id="path3542"
            class="countryIsland"
            d="M2274.8,399.9c-0.5-0.3-1,0-1.3,0.4C2274,400.8,2275.3,401,2274.8,399.9"
          />
          <path
            id="path3544"
            class="countryIsland"
            d="M2274.1,307.8c-0.1,0.9,0.9,1.3,1.5,0.7C2275.2,308.1,2274.7,307.9,2274.1,307.8"
          />
          <path
            id="Japan_Hokkaido"
            class="countryIsland"
            d="M2276.4,306c0.6,1,0.8,2.2,1.7,3c1.4,1.2,3,2.5,3.9,4.2c0.7,1.5,1.6,3.2,2.2,4.7
    		c0.5,1.5-1,2.1,0.1,3.4c1.1,1.3,3.6,4.4,0.1,4c-1.6-0.2-3.4-1.5-4.9-1.5c-0.9,0,0.1,1.5,0.4,1.7c0.9,0.6,1.7,0.9,1.5,2.2
    		c-0.3,2.3-2.9,1-1.8,4c0.8,2.3,3.2,2.2,4.6,3.8c0.9,1,0.4,2.6,1.2,3.7c1.2,1.8,1.7,0.6,3.1,0c-1-0.8,0-1.7,0.9-1.9
    		c0.2,0,3.3,0.8,2.5-0.5c-0.6-0.9-2.6-1.4-3.4-2c-1.3-0.9-3-0.7-4.3-1.7c-0.7-0.5-1.2-1.6-0.8-2.5c0.5-0.9,1.9-0.1,2.5,0.2
    		c0.8,0.4,1.4,1.1,2.2,1.4c0.8,0.3,1.8-1.6,2.6-2c2.5-1.4,5.8,1.8,8,2.6c1.6,0.6,4.9,2.7,6.6,2.5c1-0.1,0.5-1.2,0.2-1.8
    		c-0.7-1.3-0.9-2.3-0.3-3.8c1.2-3.1,3.1-3.2,6.1-2.4c-1.3-1.7,3.1,0.2,1.7-1.7c0.7-0.3,1.5-0.5,2.2-0.4c-0.1-0.6,0.3-1.1,0.9-1.4
    		c-0.9-1.1-0.9,0.3-1.6,0.7c-0.4,0.2-1.2-0.7-1.3-0.8c-0.3-0.3-3-2.8-1-1.9c-1.4-0.6-3-0.9-3.3-2.7c-0.2-1.2,0.1-3.4-1.2-3.9
    		c-0.2,1.1-0.5,3.1-1.8,3.5c-1.6,0.5-4-1.9-4.9-0.9c-0.3-0.9-0.9-0.8-1.7-0.7c-1,0.2-1.2-0.6-2.1-0.9c-2.7-1-5.1-1.9-7.6-3.4
    		c-2.9-1.7-5.5-3.8-8.4-5.5c-0.9-0.6-2.1-1.6-3.2-1.8C2277.2,305.5,2277.3,306.7,2276.4,306"
          />
          <path
            id="path3548"
            class="countryIsland"
            d="M2279.3,399.7c-1.1-0.1-1.8,2.1-1,2.8C2279.7,403.8,2279.2,400.1,2279.3,399.7"
          />
          <path
            id="path3550"
            class="countryIsland"
            d="M2278.2,333.6c0,0.6,0.3,0.9,0.9,0.8C2279.1,333.8,2278.8,333.5,2278.2,333.6"
          />
          <path
            id="path3552"
            class="countryIsland"
            d="M2289.7,367.4c-0.8,0.3-1.7,1.9-0.6,2.4c0.9,0.4,0.8,0.7,0.6,1.8c1.2,0,1.7-1.1,1.1-2.1
    		c-0.2-0.2-0.4-0.3-0.7-0.4C2289.7,368.9,2289.8,367.8,2289.7,367.4"
          />
          <path
            id="path3556"
            class="countryIsland"
            d="M2310.2,397.6c-0.3,0.7-0.1,0.9,0.6,0.7C2310.7,398.1,2310.4,397.9,2310.2,397.6"
          />
        </g>
        <g id="nz" class="countryGroup">
          <path
            id="path3560"
            class="countryIsland"
            d="M2409.5,1134.3c-0.5-0.8-4.2,1.4-4,2.3c0.5-0.2,1.4,0.1,1.8-0.3
    		C2407.9,1135.3,2408.4,1134.9,2409.5,1134.3"
          />
          <path
            id="New_Zealand_Stewart_Island"
            class="countryIsland"
            d="M2446.5,1103.3c1.9,0.9,1.1-3.4-0.7-1.2c-1.3,1.6-2.4,3.1-4.5,3.8
    		c0.7,0.5,3-0.4,3.8-0.6c0.6-0.2,1.6,0,2-0.5c0.6-0.9-0.6-1.3-1.2-0.9C2446,1103.7,2446.3,1103.5,2446.5,1103.3"
          />
          <path
            id="New_Zealand_South_Island"
            class="countryIsland"
            d="M2442.3,1095.8c0.8-0.2,1.6-0.6,2.3-1c-0.1,0.9-1.3,0.8-1.6,1.6
    		c0.9,0,1.3-0.9,2.2-0.9c-0.9,0.3-2.4,1-2.7,2c1.2,0.1,3.1,1,4.2,0.2c1.3-0.9,2.7-0.3,1.8,1.4c0.7,0,1.5-0.5,2.2-0.3
    		c1.1,0.3-0.1,1.4,0,2.1c0.6-0.3,2.2-0.4,2.8-0.3c0.5,0.1,0.1,0.9,1,0.9c2.5,0,4.7-1.1,7-1.7c0.4-0.1,1.2-1.1,1.7-1.3
    		c1.1-0.5,2.3-0.8,3.3-1.4c0.9-0.6,1.6-1.5,2.8-1.8c1-0.3,2.3-0.1,2.9-1.1c-0.5,0.2-1,0.4-1.5,0.5c2.8-1.2,4.7-3.9,7.1-5.7
    		c1.1-0.8,2.4-1.4,3.5-2.3c1.2-1,2-2.5,3.2-3.5c2.1-2,4.9-2.8,7.5-3.9c1.3-0.6,2.9-0.8,4.2-1.5c0.9-0.4,2.2-1.9,3.1-0.8
    		c-0.5,0.2-1,0.4-1.5,0.6c1.2-0.5,2.7,0.6,3.7,0.2c2.4-0.9,0.7-1.7-0.6-2.2c1.2-0.3,1.5-2.2,2.4-2.9c1.6-1.3,3.8-1.9,5.6-2.8
    		c1.6-0.9,2.8-2.1,4.1-3.2c1.2-1,2.5-1.4,3.8-2.1c1-0.5,1.6-1,2.3-1.9c0.9-1.1,2.4-1.4,3.5-2.3c1.3-0.9,0.8-2.8,1.9-3.8
    		c1.1-1.1,3.1-1,3.9-2.4c-0.5,0.6-3.1,2-3.7,1.1c1.1-0.2,3.3-0.6,3.2-2.1c-1.2,0-1.1,0.8-2,1.1c-0.5,0.1-1.9,1-0.2,0.7
    		c-0.8,0.1-1.5,0.4-2.2,0.6c0.7-0.4,3-1.9,1.4-1.9c0.6-0.1,1.2-0.5,1.6-1c-1.4,0.2-2.6,1.2-4,1.6c-1.3,0.4-2.6,2-4,1.4
    		c0.6-1.1,2.5-2.8,2.2-4.2c-0.4,0.2-2.1,0.5-1.8-0.5c0.5-1.3,2.1-1.9,3.4-1.6c-1.9-0.9-6.1,1.3-7.6,2.6c-0.9,0.8-1.6,1.8-2.3,2.8
    		c-1,1.3-2.3,2.1-3.6,3.2c-1.3,1-2.5,1.3-4.1,1.8c-1.4,0.4-2.4,1.7-3.4,2.8c-2.3,2.5-4.8,4.7-7.8,6.4c-1.3,0.8-2.6,1.3-4,1.7
    		c-1.6,0.5-2.9,1.5-4.4,2.2c-3.2,1.5-6.3,2.9-9.6,4.2c-3.1,1.3-6.4,1.9-9.4,3.5c-1.2,0.7-5.9,2.5-5.2,4.3c-0.5-1.7-2.7,0.7-3.5,0.9
    		c-0.8,0.3-3.7,1.3-3.2,2.2c-0.7-0.3-1.6,0.2-2.2,0.6c-0.6,0.4-1.8,1.2-0.4,1.2c-1.1,0-0.8,1.3-1.6,1.9c0.7-0.8,0.6-0.9-0.4-0.6
    		c0.1-0.4-0.2-1.4-0.6-0.7c-0.4,0.8-1.6,1.3-2.4,1.8c0.8,0.2,1.5-0.2,2.3-0.4c-0.8,0.3-1.6,0.6-2.3,0.9c0.5,0.2,1,0.2,1.5,0
    		c-0.5,0.2-1,0.4-1.6,0.5c2.1,0.4-1.4,0.9-1.9,1C2443.6,1094.1,2442.4,1094.5,2442.3,1095.8"
          />
          <path
            id="path3568"
            class="countryIsland"
            d="M2527.8,1050.3c-0.8,0.4-1.9,0.8-2,1.8C2526.7,1051.9,2527.4,1051.1,2527.8,1050.3"
          />
          <path
            id="New_Zealand_North_Island"
            class="countryIsland"
            d="M2530.2,1055.3c-0.1,0.5-0.2,1-0.3,1.5c0.6-0.3,1.3-0.3,1.9-0.6
    		c0.1,0.6,0,1.1-0.5,1.5c1.4,1.1,4.1-1,5.3-1.6c2.5-1.5,4.7-3.1,7-4.9c1-0.8,2.2-1.9,3.4-2.3c1.1-0.3,1.5-1.4,2.4-2
    		c1-0.8,2.2-1.1,3.1-2c1.2-1.1,2.2-2.4,3.4-3.5c-2.2-0.4,1.7-3.4,2.3-3.8c1.3-0.9,6.6-2.7,5.6,0.4c1.8-1.1,1.8-3.7,3.7-4.7
    		c0.7-0.4,1.5-0.5,2.2-0.9c1.2-0.6,1.8-1.6,2.6-2.6c1.3-1.6,2.4-3.4,4-4.6c0.8-0.6,0.7-1.3-0.2-1.8c-1.2-0.6-2.9,0.5-3.9,1.1
    		c-2.5,1.3-4.7,3.6-7.7,2.3c-0.7-0.3-4.8-2.3-4.9-3c-0.1-0.6,3.4-6.9,1.7-6.5c0.5-0.5,1.1-0.8,1.7-1.1c-0.6,0-1.1,0.1-1.7,0.3
    		c0.2-0.8,0.3-1.6-0.1-2.3c-0.7,0.3-0.6,0.4-0.4,1c0.2,0.7-0.8,1.4-1,1.9c-0.6,1.2-0.6,3.2-2.2,3.5c-0.9,0.1-0.1-1.7-0.1-2
    		c0.1-1-2.8-1.2-3.5-1.1c0-0.1,1.1-0.8,1.4-1.3c0.4-0.8,0.6-1.3,1.2-2.1c0.4-0.5,1.6-0.8,1.3-1.7c-0.4-0.9-1.1-2.6-0.2-3.4
    		c-0.5,0-0.9-0.2-0.7-0.7c0.3-0.8,1.3,0.1,1.7,0.4c0.6-1.7,1-3.5,0.9-5.3c-0.5,0.1-1,0.2-1.4,0.1c0.1,0.3,0.2,0.5,0.4,0.8
    		c-0.3,0.1-0.5,0.1-0.8,0.1c0.1-0.6-0.1-1.7,0.9-1.6c-1-0.1-1.1-2.4-2.2-0.9c0.7-1.1-3.2-1.4-1-1.8c-1-0.4-1.3,0.9-2,1.3
    		c0.7-0.9,0.3-3.5-0.1-4.5c0.6,0.1,1-0.3,1.2-0.8c-0.6,0.2-2.8,0.1-2.8,0.7c0,0.7,0.4,1.4,0.5,2c0.2,1.1,0.8,4.1-1.2,4
    		c0.3,1,0.3,2,0.4,3c0.5-1,2.2-0.8,2.4-2.1c0.1,1.6-2.2,1.1-2.4,2.8c-0.2,2.3,1.1,4.3,0.6,6.8c1.4,0.3,1.1-1.5,0.9-2.3
    		c0.6,0.2,1,0.6,1.1,1.2c-0.1-1.5,0.8,0,1.6-0.9c-0.7,0.3-1.3,0.6,0,0.9c-0.4,0.3-0.9,0.2-1.3,0.5c-0.4,0.2-0.4,1.2,0.6,0.5
    		c-0.6,0.7-0.7,1.6-1.3,2.2c-0.4-0.4-0.5-0.9-0.4-1.5c-1.2,0.3-0.9,3.8-1.2,4.8c0.7,0.1,2.1-1.1,2.4,0c0.3,0.8-1.5,1.7-1.7,0.4
    		c-0.6,0.3-1.6,2.3-0.4,2.5c-0.8,0.2-1.1,1.1-1.3,1.8c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.8-0.8,0.8-1.2,1.5c-0.4,0.6-0.4,1.7-0.9,2.2
    		c-0.3,0-0.5,0-0.8,0c-0.6,0.5-1,1-1.5,1.6c-1.4,1.7-2.4,4-4.3,5.2c-1.8,1.2-4.3,1-6.3,2.1c-0.8,0.5-1.6,1.2-1.6,2.3
    		c-0.1,1.2,1.3,1.2,1.9,1.8c0.9,0.9,1,1.7,2.4,2.1c1.3,0.5,1.6,1.7,1.1,2.9c-0.9,2.5-3.4,4.2-5.4,5.7c-0.8,0.6-1.5,1.8-2.6,2.1
    		c-1,0.3-2.2,0.9-2.8,1.9C2529.3,1056.3,2529.9,1056,2530.2,1055.3"
          />
          <path
            id="path3572"
            class="countryIsland"
            d="M2558.3,1016.4c-0.4,0.1-0.9,0.3-1.3,0.5C2557.5,1017.3,2558.1,1017,2558.3,1016.4"
          />
          <path
            id="path3574"
            class="countryIsland"
            d="M2563.4,1010.4c-1,0.5-1.3,2.8-0.6,2.4C2563.4,1012.5,2563.2,1011,2563.4,1010.4"
          />
          <path
            id="path3576"
            class="countryIsland"
            d="M2571.4,1078.8c0,0.3,0,0.7,0,1c0.7-0.3,1.4-0.6,2.2-0.7c-0.7-0.7-0.2-3,1-1.9
    		c-0.2,0.3-0.5,0.5-0.7,0.7c0.7-0.6,1.4-1.1,2.2-1.5c-1.3,0.3-3.7-0.8-4.4,0.9c0.5-0.2,1-0.2,1.5,0.1
    		C2572.9,1078.3,2572.2,1078.7,2571.4,1078.8"
          />
          <path
            id="path3578"
            class="countryIsland"
            d="M2572.2,1081.4c0.5-0.1,0.7-0.2,0.8-0.7C2572.8,1080.9,2572.5,1081.2,2572.2,1081.4"
          />
        </g>
        <g id="cu" class="countryGroup">
          <path
            id="Cuba_island"
            class="countryIsland"
            d="M667,509.2c1.4-0.5,2.9-1.8,4.4-1c0.5-1.1-0.9-2.1,0.1-3.2c0.8-0.9,2.1-2.3,3.1-2.9
    		c1.2-0.8,2.7-1.1,4-1.5c1.1-0.4,2.2-1.1,3.4-1.1c2.6,0.1,4.7-1.3,7.3-1.4c1.2-0.1,2.6-0.2,3.7,0.3c0.3,0.1,0.6,0.3,0.6,0.6
    		c0.9-0.4,2-0.6,2.9-1.1c-1.5,0.4,0.1,1.2,0.9,0.9c0.7-0.3,3-0.3,3.3,0.1c0.4,0.7,1.5,1.7,2.4,1.4c1-0.3,2.4,0.9,3.3,0.1
    		c-0.2,0.3-0.4,0.5-0.6,0.8c1.7,0.4,1.3,1.2,2.5,2.3c1.5,1.5,2.2,1.1,4.1,1.3c1.9,0.2,2.6,1,4.1,1.9c0.6,0.3,1.4,0.3,1.8,0.9
    		c0.4,0.6,0.8,1.7,1.6,0.8c-0.3,0.9,0.4,1.5,1.2,1.6c1,0.1,1.2,0.9,2.1,1.2c-0.3-0.7-0.6-1.6-1.4-1.8c0.8,0.2,3.3,1.1,2.9,2.3
    		c-0.5-0.1-1-0.2-1.5-0.4c0.4,0.5,0.5,1,0.8,1.4c1.1-1.9,1.4,0.3,2.7,0.3c-0.2,0.3-0.4,0.5-0.6,0.7c0.5-0.3,1-0.4,1.5-0.2
    		c-0.1,0.3-0.2,0.5-0.3,0.8c0.5,0.1,1,0.3,1.4,0.7c-0.1-2,2.7,0.3,3.2,0.7c0.7,0.5,1.4-0.1,2.2-0.2c1.3-0.1,1.7,1.7,0.3,1.8
    		c0.3,0.1,0.5,0.3,0.8,0.4c-0.6,0.2-1.1,0.6-1.5,1.1c2.1,0.4,5.5-0.3,7.2,1.1c1.4,1.2,1.8,2.4,3.7,2.7c2.3,0.3-0.7,1.9-1.7,2
    		c-0.3,0-5.2,1.3-5.3,1.2c0-0.3,0.2-0.5,0.4-0.6c-0.9,0-0.9,1.2-2.4,1c-1.6-0.2-3.2-0.7-4.9-0.8c-4.2-0.2-8.4,1.4-12.6,1.3
    		c0.1-2.2,2.8-2.8,4.3-3.8c0.9-0.6,1.4-1.2,0.5-1.9c-0.6-0.5,0.3-2.2-1.3-1.8c-2.8,0.7-5.7,0.2-7.3-2.4c-0.6-0.9-0.5-2.2-0.6-3.2
    		c-0.1-0.5-0.2-1-0.5-1.4c-0.4-0.5-1.2-0.3-0.4-0.7c-1.3-0.6-2.6,0.3-3.8,0.5c-1.3,0.1-2.4-1.1-3.6-1.3c-1.2-0.2-1.9,0-2.5-0.8
    		c-1-1.2-2.3-1.1-2.5-3.1c-0.2,1.6-5.1,1.1-5.2-1c-0.3,0.5-0.4,1-0.4,1.5c-0.4,0-0.8-0.2-1-0.5c-0.6-0.6-1.6-0.4-2.4-0.4
    		c-1.3,0-2.4-0.7-3.2-1.6c0.7-0.7,2-0.4,2.8-0.5c1.3-0.2,1.2-1.5,0.1-1.9c-2.2-0.7-6.3-0.8-8.3,0.5c-1.2,0.8-2.6,1.7-3.6,2.8
    		c-0.9,1-1.6,0.3-2.7,0.7c-0.6,0.3-1.3-0.1-1.9,0.2c-0.6,0.4-0.4,1-0.9,1.5c-1,1-2.6,0.9-3.7,1.9c0.3-2.9-3.1,0.8-3.5-1.2
    		C666.8,509.1,666.9,509.2,667,509.2"
          />
          <path
            id="Cuba_Isla_de_la_Juventud"
            class="countryIsland"
            d="M681.3,513.1c-0.8-0.2-1.6-0.5-1.8-1.4c1.5,1.7,0.9-1.3,0.9-1.8
    		c0-1,1.6-1.4,2.3-1.1c0.8,0.4,1.4,1.8,1.4,2.7C684.1,512.7,682.1,512.9,681.3,513.1"
          />
          <path
            id="path3586"
            class="countryIsland"
            d="M703.2,500c-0.3,0-0.5-0.1-0.8-0.1c0.2-0.2,0.5-0.3,0.8-0.4C703.2,499.6,703.2,499.8,703.2,500"
          />
          <path
            id="path3588"
            class="countryIsland"
            d="M714.4,521.1c-0.3-0.2-0.5-0.5-0.8-0.7C714.3,520.4,714.2,520.6,714.4,521.1"
          />
          <path
            id="path3590"
            class="countryIsland"
            d="M716,504.6c-0.5-0.2-1-0.4-1.4-0.6c0.8-0.4,2.3-1,2.6,0.4C716.8,504.4,716.4,504.5,716,504.6"
          />
          <path
            id="path3592"
            class="countryIsland"
            d="M718.8,505.9c-0.6-0.4-1.6-0.4-1.8-1.3C718.1,504,718.4,505.2,718.8,505.9"
          />
          <path
            id="path3594"
            class="countryIsland"
            d="M719.8,507.4c-0.8-0.1-1.3-1-0.8-1.7C719.3,506.1,720.1,506.8,719.8,507.4"
          />
          <path
            id="path3596"
            class="countryIsland"
            d="M720.8,509c-0.5-0.4-1-0.8-1.4-1.2C720.3,506.9,722.4,508,720.8,509"
          />
        </g>
        <g id="ve" class="countryGroup">
          <path
            id="Venezuela_mainland"
            class="country"
            d="M742.2,619.2c2-2.3,3-4.8,3.6-7.7c0.6-2.8,1.2-5,2.7-7.4c1.2-2,3.9-3.2,5-5.3
    		c1.3-2.4,3.5-1.8,5.7-3.1c-0.9,2.1-3.1,1.3-4.4,2.8c-1.1,1.3,0.1,4.1,1.4,4.6c-1.2-0.1,0,2.7,0.1,3.2c0.4,1.6-1.2,3.2-2.2,4.2
    		c-1.4,1.3-2.1,2.9-1.2,4.6c0.3,0.7,0.4,1.5,1.1,2c0.7,0.5,0.8,0.3,0.7,1.2c-0.3,2,1.3,2.5,2.9,1.5c1.7-0.9,2.3-2.3,2.5-4.2
    		c0.3-2.4-0.8-3.7-2-5.6c-0.5-0.8-0.9-1.6-1.2-2.5c-0.3-0.8,0.4-1.8-0.2-2.5c0.9,0.1,1.7-1.3,2.4-1.7c1.3-0.7,2.5-1.7,3.9-1.9
    		c1.3-0.2,2.3-0.4,3.4-1.1c0.4-0.3,1.7-1.3,2.2-1c-0.1-0.4-0.3-0.7-0.6-1c0.5,0.2,2.2,1.6,2.8,1c0.4-0.5,0-1.6-0.5-1.8
    		c-0.9-0.4-2,0.5-2.9,0.5c0.2-1-0.7-6.3,2.3-5c1.3,0.5,1.1,3.5,1.3,4.6c0.5,2.5,3.2,0.8,5,1.5c2,0.7,4.5,1.2,5.4,3.6
    		c0.5,1.4-0.9,4.6,1.5,5.1c2.8,0.6,6.4-0.7,9.2-1.1c2.4-0.3,4.9-0.8,6.4,1.3c0.6,0.9,1.5,1.6,2.5,2c0.8,0.3,1.7,0.5,2.5,0.7
    		c-0.1,0,2.8,0.6,1.5,0.6c2.3,1.2,3.3-1.4,5.1-1.6c1.1-0.1,1.3-1.2,2.2-1.7c1.2-0.6,2.7,0.5,3.9-0.2c-1.1-1.2-3.7,0.1-4.4-1.4
    		c0.6,0,1.3,0,1.9,0c0.9,0,1.1-0.5,1.8-0.6c0.3,0.1,0.6,0.2,0.9,0.4c0.9,0.2,1.8,0,2.7-0.1c3.7-0.6,7.6,0.1,11.2-0.7
    		c-0.6,1.2-2.4,0.9-3.5,1.5c-1.7,0.9-3.6-0.5-5.2,0.8c0.5-0.5,1-0.4,1.2,0.3c-0.6,0-1.1,0.3-1.2,0.9c1.4-0.8,2.4,0,2.4,1.6
    		c0,1.3-2.2,0.4-2.8,0.4c0.5,1.8,3.4,0.5,3.1-1.1c0.8,0.1,1.1,1,1.2,1.7c0.1,0.9,0.9,1.9,0.8,2.8c0.6-0.6,0.8-1.4,0.6-2.2
    		c0.7,0.7,0.8,2.1,0.2,2.9c0.8-0.5,0.4-1.6,0.4-2.4c0.4,0.1,0.8,0.2,1.2,0.4c-0.1-0.1-2.5-1.3-1.1-1.2c1.3,0.2,3.1,1.6,2.4,3
    		c0.3-0.6,0.8-0.5,0.4-1.1c0.5-0.3,1.1-0.3,1.7-0.1c-1.2-1.4,0.8-0.1,1.2,0.6c0.8,1.3,2.3,0.8,3.3,1.6c2.8,2.2-0.5,2.2-1.2,4.2
    		c0.2-0.3,0.5-0.5,0.7-0.7c0.2,1-0.9,2.8-1.4,3.7c-0.3,0.5-0.2,1-0.9,1c-0.6,0-2.5-0.5-2.9,0c0.9,0.5,2.3,0.6,2.8,1.7
    		c0.6-0.7,1.4-1.3,1.5-0.1c0.8-0.9,1.4-1.3,2.7-1.6c1.1-0.2,1.6,1,2.6,0c1.8-1.8,5.1,2.6,4.1,3.1c-1,0.5-1.6,1.3-2.4,2.1
    		c-0.8,0.7-2.1,1.1-2.7,1.7c-0.4,0.5-0.8,1.1-1.2,1.6c-0.8,1,0.4,1.7,0.3,2.8c-0.1,0.7,0.2,1.5,1,1c1-0.5,1.7,0.7,0.9,1.5
    		c-0.8,0.8-2,1.1-2.8,1.8c-0.4,0.4-1-0.2-1.4-0.2c-0.3,0-0.5,0.6-0.9,0.8c-3,1.2,0,3.1-1.6,4.8c-0.4,0.5-2,1.5-1.5,2.3
    		c1,1.3,2,2.7,3,4c1.3,1.5,3.8,3.9,1.4,5.7c-0.8,0.6-1.4,1.7-2.2,2.3c-0.5,0.3-2,0-2.1,0.1c-0.4,0.7-1.7,0.6-1.8,1.3
    		c0,0.9-1.6,0.9-2.2,1.2c-1.2,0.6-2.6,1.3-3.9,0.7c-0.9-0.4-1.7,0.7-2.6,0.8c-1.1,0.2-1.1,1.4-0.9,2.3c0.4,1.3-0.7,2.3-1.8,1.3
    		c-0.8-0.7-1.1-1.6-1.8-2.3c-0.9-1-1.5,0.3-2.4,0c-1.2-0.4-1.7-0.1-2.9-0.2c-1-0.1-0.8,0.3-1.1-0.9c-0.4-1.1-1.8-1.2-2.8-1
    		c-1.3,0.3-1.2-0.9-2.2-1.3c-0.8,0.8,0.7,2.7,1.2,3.3c1.5,1.8,2.7,2.1,2.7,4.6c0,0.8,0,1.6,0.3,2.4c0.3,1,1.3,2,1.2,3.1
    		c-0.2,1.8-0.2,2.6,1.9,2.7c0.6,0,3-0.5,2.8,0.7c-0.4,2.1-1.5,2-2.5,2.7c-0.8,0.5-2.2,0.7-2.5,1.4c-0.4,0.8-0.3,1.4-0.8,2.2
    		c-0.5,0.7-1.2,1.3-1.7,2.1c0-1.9-3,1-3.5,1.2c-1.4,0.3-2.2,1-3,2.2c-0.5,0.8-1.7,2.7-2.6,3c-0.6-0.7,0.2-1.6,0-2.4
    		c-0.3-1.3-2.3,0-2.8,0.3c-2.9,2.1-5.2-0.2-7.1-2.7c-0.9-1.2-0.8-2.5-1.2-3.9c-0.5-1.6-1.6-3.8-1.6-5.4c0.1-1.3-3.4-5.6-5-4.4
    		c0-1.5,2.3-3,3.3-3.8c1.8-1.4,0.6-2.9-0.7-4.3c-2.8-3.2-2-8.5-2-12.3c0-1.4,0.4-2,1.3-2.9c0.5-0.6,0.3-1.8,0.7-2.6
    		c0.5-0.8,1.2-1.4,1.4-2.2c0.4-1.3-1.4-2.6-2.1-2.3c-1.8,0.8-3.2,1-4.8,0.9c-0.7,0-2.2,0.7-3.8,0.3c-1.1-0.3-1.1,0.1-2.5,0.3
    		c-0.3,0-2.3-0.6-2.2-0.5c-0.8-0.5-1.2-1.6-1.7-2.4c-0.7-1.1-1.4-2.1-2-3.3c-0.3-0.6-0.8-1.3-1.6-1c-1.5,0.5-2.5-1-4-1.2
    		c-0.9-0.1-1.3,0.7-2.2,0.9c-1.3,0.3-2.4-0.6-3.7-0.3c-0.8,0.1-1.3-0.4-2.1,0c-0.8,0.4-1.9-0.1-2.4-0.8c-0.4-0.6-0.5-1-0.7-1.7
    		c-0.2-0.9-1.5-0.8-1.9-1.4c-0.9-1.2-0.2-3.8,0.5-4.8c1.4-1.9-1-3.8-1.6-5.2c-0.7-1.5,0.1-4.9-2.1-4.3c0-0.5,0-1-0.2-1.5
    		C744.2,618.8,743.4,619.2,742.2,619.2"
          />
          <path
            id="path3412"
            class="countryIsland"
            d="M834.2,625.2c-0.7-0.1-1.5-0.3-2.2-0.6C832.7,624.3,834.2,624.1,834.2,625.2"
          />
          <path
            id="path3414"
            class="countryIsland"
            d="M834.9,623.4c0.4-0.7,1.1-1.5,2-1.1C836.7,623.3,835.7,623.1,834.9,623.4"
          />
          <path
            id="path3416"
            class="countryIsland"
            d="M835.2,625c-0.2-0.2-0.4-0.4-0.6-0.6c0.5,0,1-0.1,1.5-0.3C836.1,624.6,835.7,625,835.2,625"
          />
          <path
            id="path3418"
            class="countryIsland"
            d="M835.8,623.8c-0.2-0.1-0.5-0.2-0.7-0.3c0.5-0.3,0.9-0.3,1.4-0.1
    		C836.3,623.5,836.1,623.6,835.8,623.8"
          />
          <path
            id="path3420"
            class="countryIsland"
            d="M836.7,624c-0.1-0.5,0.1-0.6,0.8-0.3C837.3,623.8,837,623.9,836.7,624"
          />
          <path
            id="path3422"
            class="countryIsland"
            d="M837,621.6c-0.2,0-0.5,0-0.7,0c0.2-0.5,0.6-0.9,1.2-0.7C837.3,621.2,837.1,621.4,837,621.6"
          />
          <path
            id="path3424"
            class="countryIsland"
            d="M837.3,619.8c-0.7-0.2-0.7-0.5,0.2-0.7C837.5,619.3,837.4,619.5,837.3,619.8"
          />
          <path
            id="path3426"
            class="countryIsland"
            d="M837.5,620.1c-0.7,0.3-1.2,1-1.9,1.4C835.8,620.7,836.6,620.2,837.5,620.1"
          />
          <path
            id="path3428"
            class="countryIsland"
            d="M837.6,619.7c0.1-0.3,0.4-0.5,0.7-0.6C838.1,619.3,837.9,619.5,837.6,619.7"
          />
          <path
            id="path3598"
            class="countryIsland"
            d="M804.6,604.4c-0.2-0.1-0.5-0.1-0.7-0.2c0.2-0.2,0.4-0.4,0.7-0.6
    		C805,603.9,805,604.2,804.6,604.4"
          />
          <path
            id="path3600"
            class="countryIsland"
            d="M813.9,604.6c-0.2-0.5-3.6-1.3-1.8-1.8c0.9-0.3,1,0.7,1.5,0.6c0.9-0.1,1.1-1.2,1.9-1.5
    		C816,603.8,815.8,603.6,813.9,604.6"
          />
          <path
            id="path3602"
            class="countryIsland"
            d="M823.5,607.9c0.4,0.5,0.7,1,0.9,1.5C824,609.1,823.5,608.5,823.5,607.9"
          />
        </g>
        <g id="pt" class="countryGroup">
          <path
            id="Portugal_mainland"
            class="country"
            d="M1240.2,358.7c-0.1-0.2-0.2-0.5-0.2-0.7c2.3-0.2,3.1-3.9,3.8-5.7
    		c0.6-1.5,0.9-3.2,1.3-4.7c0.1-0.4,0.5-0.5,0.8-0.8c0.2-0.2,0.2-1.8-0.4-0.1c0.3-1.8,0.7-2.9,0.3-4.8c-0.1-0.6-1.1-3.8,0.2-3.8
    		c-1.8,0.5-0.4-2.1,0.6-2.5c1.1-0.5,2.6-0.5,3.5-1.4c-0.1,0.6,0.2,0.9,0.8,0.9c-0.4,0.3-1.8,2.1-0.5,2.1c1.1,0,2.1-0.8,3.2-0.7
    		c1.4,0.2,1.9,0.8,3.3,0c0.4-1.5,2.5-0.3,3.8-0.5c1.4-0.1,0.6,0.9,0.7,1.7c0.1,0.5,1.8,0.8,2.1,1.1c0.8,0.8-4.4,4.9-5.1,5.1
    		c0.6-0.1,0.3,3.7,0.3,4c0,0.5,0.2,1.5,0,2c-0.3,0.7-1.3,0.4-1.5,1.2c-0.1,0.5,1.7,2.5,0.1,3c-0.6,0.2-0.3,1-0.8,1.3
    		c-0.7,0.4-2.6,0.3-3.4,0c0.3,0.6,0.8,0.9,1.3,1.3c0.6,0.5,0.1,1.1,0.4,1.8c0.4,0.7,2,1.8,1.7,2.7c-0.5,1.4-2,2.2-2.4,3.6
    		c-0.3,1.3,0.9,3.7,2.4,3.1c-0.7,1.7-1.8,1.2-2.7,2.9c-0.4,0.8-1.2,1.4-1.6,2.2c-0.5,1.1,0.6,2.3,0,3.4c-0.9,1.7-3.5,1.9-5,1.3
    		c-1.8-0.7-4.2-0.8-5.8,0.5c-0.2-0.7,0.3-1.7,0.6-2.4c0.5-1,0.4-2.3,0.9-3.2c0.6-1.1-0.2-1.8-0.1-2.9c0.1-1.6,1.1-2.6,0.1-4.2
    		c0.5,1.6,1.1-0.2,1-0.8c-0.3,0.8-2.7,1-3.5,1.3c0.4-0.7,0.2-1.5,0.4-2.2c0.3,0.1,0.6,0.2,0.9,0.4c0.1-0.6,0.5-0.7,0.9-0.9
    		c0.6-0.4-0.1-1.3-0.1-1.8c-0.5,0.7-2.2,3.1-3.3,2.3C1237.9,362.9,1240.2,360,1240.2,358.7"
          />
          <path
            id="path3614"
            class="countryIsland"
            d="M1102.8,365.5c-0.6,0.1-1.9-0.7-0.8-1C1102.7,364.2,1103.3,364.8,1102.8,365.5"
          />
          <path
            id="path3616"
            class="countryIsland"
            d="M1105.4,366.6c-0.6-0.4-1.4-0.3-1.9-0.8c0.6-1.6,2.7,0,3.5,0.6
    		C1106.4,366.4,1105.9,366.4,1105.4,366.6"
          />
          <path
            id="path3620"
            class="countryIsland"
            d="M1108.7,365.2c-1-0.2-2.5-0.5-2.9-1.6C1106.8,363.9,1107.9,364.3,1108.7,365.2"
          />
          <path
            id="path3622"
            class="countryIsland"
            d="M1113.6,364.5c-0.7-0.1-2.4-1-0.9-1.5C1113.8,362.6,1115,363.9,1113.6,364.5"
          />
          <path
            id="path3624"
            class="countryIsland"
            d="M1124.5,372.4c-0.7-0.1-3.4-0.5-2.2-1.7c1.2,1.4,3.1,0.2,4.5,0.6
    		C1126.8,372.3,1125.2,372.3,1124.5,372.4"
          />
          <path
            id="path3626"
            class="countryIsland"
            d="M1126.7,379.1c-0.4-0.2-0.7-0.4-0.9-0.7C1126.3,378.2,1126.7,378.4,1126.7,379.1"
          />
          <path
            id="Portugal_Madeira"
            class="countryIsland"
            d="M1181,416.1c-0.7-0.1-1.3-0.5-1.8-1c-0.4-0.4,0.3-1,0.8-0.6c0.6,0.6,1.4-0.4,2,0.4
    		C1182.6,415.7,1181.8,416.3,1181,416.1"
          />
        </g>
        <path
          id="co"
          class="countryGroup country"
          d="M697.3,684.8c-0.3-0.1-0.5-0.1-0.8-0.2c0.3-1.7,2.1-2.3,3.5-1.4c0.8-1.5-1.1-2.9-0.4-4.3
    	c1-1.9,3.1-1.8,4.9-2.1c0.3-0.1,0.2-0.9,0.7-0.6c0.3,0.2,1.1,0.4,1.1-0.2c0-1.2,1.6-2.7,0.6-3.5c1.7,0,2-2.3,2.5-3.4
    	c0.4-0.9,1.2-1.6,2-2.1c-1-0.8,0.2-1,0.5-1.8c-0.7,0-1.6,0.6-1.9-0.4c0.6-0.1,1-0.6,0.9-1.2c-0.5-0.2-1.7,1.3-1.8,0.1
    	c-0.1-1.2,0.6-1.4,1.5-1.9c-1.4,0-0.8-1.9-0.6-2.7c0.4-1.6,0-3.6-0.1-5.2c-0.1-1.2-0.6-2.3-0.7-3.5c0-0.7,2-0.9,1.7-2.4
    	c-0.2-0.9-2.3-3-0.9-3.5c1.3-0.5,0.2-3.1-0.4-3.7c-1.1-1.2-1.2-3.1-2.4-4.1c-0.8-0.7,0-1.5,0.5-2.2c0.5-0.7,0-1.7,0.7-2.4
    	c0.4,0.4,0.6,0.9,0.8,1.4c1.5-1.1,3.7-3.2,3-5.3c-0.7-2.1-2.1-2.6-0.8-4.6c0.7,1,1.6,2.3,2.2,3.3c0.7,1.1,2.3,1.4,0.8,2.8
    	c2,2.2,1-4.2,0.7-4.9c-0.4-1,4.2-3.3,4.8-4.1c0.7-1,1.3-2.7,2.5-3.2c0.7-0.3,2.3-0.1,2.7-0.9c0.6-1.1-0.5-1.5-0.3-2.2
    	c0.4-1.2,0.6-2.7,1.3-3.8c-1.1-0.1-0.8-0.5,0-0.8c0.7-0.3,0.4-1.2,0.4-1.8c0-1.3,2.2-1.7,2.2-2.2c0-0.7,2.7-3.4,3.3-3.4
    	c1.3,0,2.3,1.1,3.6,1.2c-1.1-0.1-0.9,1-1.9,1c0.7,2.7,2.3-0.9,2.7-1.9c0.9-2.4,2-2.3,4.6-1.8c3.5,0.6,4.6-1.7,7.4-3.4
    	c1.5-0.9,6-2,4.6-4.6c0.6-0.2,2.2-0.7,1.3,0.5c1.1-0.2,0.4-1.2,1.6-1.8c1.4-0.6,2.7-0.6,3.9,0.4c4.2,3.5-4.8,4.9-6.1,6.6
    	c-0.7,0.9-1,2.2-1.8,3c-0.6,0.6-1.5,0.4-2.1,1c-1,1.1-2,2.8-2.7,4.2c-0.9,2-1,4.1-1.5,6.2c-0.5,2.1-2,4.6-3.4,6.2
    	c1.1,0,2-0.4,2.9-1.2c0.2,0.5,0.3,1,0.2,1.5c2.2-0.6,1.4,3.3,2.6,4.7c1.6,2,2,3.2,1.1,5.7c-0.5,1.2-1.2,3.9,0.5,4.5
    	c1.9,0.7,0.9,3,2.9,3.4c0.8,0.2,1.5-0.3,2.3-0.4c1.3-0.1,2.4,0.7,3.7,0.6c1.1-0.1,2.1-0.9,3.2-0.9c1,0,1.8,1,2.8,1.2
    	c0.7,0.2,1.4-0.4,2,0.1c0.9,0.7,1.4,2.1,2.1,3c0.6,0.9,1.1,1.7,1.6,2.6c0.7,1.2,1,0.8,2,1.1c1.5,0.4,2.9-0.8,4.8,0
    	c0.6,0.2,1.9-0.3,2.6-0.4c1.4,0,2.6,0.1,3.8-0.7c1.4-0.8,3.7,0.8,3,2.3c-0.4,0.8-1.4,1.5-1.6,2.4c-0.2,1.2,0.2,1.8-0.9,2.5
    	c-1.5,1-0.9,5-1,6.7c-0.2,1.9,0.6,4,1,6c0.3,1.5,3.2,3.6,2.8,5.2c-0.6,1.9-4.2,2.7-4.2,5c1.3-1,4.6,2.6,5,3.7
    	c-0.1,0.3-0.1,0.6-0.1,0.9c0.2,0.6,0.6,1.1,0.6,1.7c0,1.2,0.7,2.6,1,3.8c0.1,0.5,0.8,2.4,0.9,3.1c0.1,0.8-0.3,0.3-0.9,0.5
    	c-1,0.3-1.3-0.2-1.1-0.8c0.4-1-0.2-7.3-2.7-7.2c-1,0-3.7,5.1-4.4,2.3c-0.3-1-1.6-1.5-1.5,0c0.2,1.7-2,1.1-3.2,1.1
    	c-1.7,0-3.5,0-5.2,0c-1.2,0-2.4-0.7-3.5-0.2c-1.7,0.7-0.3,4.5-0.5,5.9c1.4-0.9,4.6-0.1,5.2,1.4c0.2,0.6,0.4,2.4-0.7,2.3
    	c-0.7-0.1-1.1-0.9-1.8-0.8c-1.6,0.3-4.3,0.7-4.4,2.5c-0.1,2.2-0.3,5.3,1.6,6.8c1,0.7,2.1,1.4,2.1,2.8c0,1.3,0.6,1.8,1.2,3
    	c1.2,2.6-0.3,6-0.7,8.7c-0.9,6.3-0.7,13.1-2.7,19.2c-0.5-1.2-1.4-2-2.2-2.9c-0.9-1-2.1,0-3.2-0.6c-1-0.6,1.5-4.8,1.9-5.4
    	c0.7-1.2,2.7-3.5,1.7-4.6c-0.5-0.5-0.9-1.8-1.6-1.5c-1.3,0.5-2.8-1.6-3.1-1.9c-1.2-1-2.3,0-3.6,0.6c-1.3,0.6-2.1-1-3.2-1.2
    	c-0.7-0.1-2.7,3-5,2.3c-0.8-0.2-1.4-0.2-2.4-0.1c-1.4,0.2-2.1,0-3.3-0.8c-0.9-0.7,0-2.5-0.3-3.5c-0.4-1.4-2.6-0.6-2.9-2
    	c-0.2-1-0.7-2.1-1.1-3.1c-0.4-1-1.7-0.6-2.3-1.4c-0.7-0.9-1.1-1.2-1.9-1.5c-1.1-0.4-1.8-1.9-1.8-2.9c-0.2-1.9-3.6-5-5.4-5.5
    	c-0.3-0.1-0.7-0.5-1,0c-0.3,0.5-0.3,0.5-1,0.2c-1-0.5-2.6-1-3.5-1.7c-0.7-0.5-3.8-4.5-4.2-1.7c-0.2,1.5-5.7-0.4-6.9-0.6
    	c-1.9-0.3-0.5-1.9-1.9-2.5c-1.2-0.5-0.7-1.5-2.2-1.6c-1.2,0-3.6-1.4-4.5-2.1C699.7,688,698.4,686.4,697.3,684.8"
        />
        <g id="mr" class="countryGroup">
          <path
            id="path3878"
            class="countryIsland"
            d="M1178.4,529c0.5-0.6,0.6-1.4,0.7-2.2C1178.5,527.3,1178,528.2,1178.4,529"
          />
          <path
            id="Mauritania_mainland"
            class="country"
            d="M1174.6,515.2c0.2-0.4,0.7-1.3,1.6-1.3l27.9,0c1.4-0.7,0.4-6.9,0.3-9.3
    		c-0.2-2.4-0.4-3.9,1.5-5.7c1.6-1.5,1.5-1.1,6.6-3.2l1.1-21.8l24.9-0.3l0.3-11.1c9.5,6.4,18.8,12.9,27.9,19.8h-13.2l5.4,72.9
    		c0.4,1.2,1.7,1.7,1.8,2c0.7,1.9-0.2,2.4-0.3,3.8c-0.1,1.7-0.2,3.2-0.7,3.4l-15.9,0c-9,0.3-12.9,1.5-13.7-1.1
    		c-0.4,2.4-4,2.8-5.7,2.1c-2.8-1.1-4.6-0.6-5.7,2.5c-0.7-1.1-1.6-2-2.6-2.9c-1.2-1.1-2.2-1.7-2.9-1.4c-2.3,1.3,0.9,7.7-4.7,7.2
    		c0,0.1-1.5-1.1-1.9-1.9c-0.5-1-1.1-0.7-2.1-1.5c-1.1-0.9-1-2.4-1.8-2.9c-2.3-1.5-1.4-1.6-1.6-2.6c-0.3-1.3-1.6-2.9-2.2-2.9
    		c-3.4,0-3.6-2.8-5.8-3.9c-1.1-0.5-2.1-0.8-3.6-0.9c-2.3-0.1-3.1,2.2-8.5,1.8c-1.1-0.1-1-0.8-1.9-0.6c-0.9,0.3-2,3.3-2.4,4.1
    		c0-5.2,3.4-9.4,3.9-14.5c0.3-3.1-0.1-5.7-0.5-8.7c-0.1-1.1-0.1-2.3-0.8-3.2c-0.6-0.8-1.7-1.4-1.8-2.5c0.8,0.2,1.7-0.3,1.6-1.2
    		c-0.5,0.1-1,0.6-1.2,1.1c0.2-1,1-1.8,1.5-2.7c0.3-0.6-0.1-1.5,0.2-1.9c1.6-1.8-0.8-5-1.4-6.7c-0.2,0.4-0.4,0.9-0.5,1.4
    		c-1.6-1.2-1.2-3.7-2.8-4.9c-0.2,1.1-0.6,2.1-0.9,3.2C1173.9,517.5,1174,516.6,1174.6,515.2L1174.6,515.2z"
          />
        </g>
        <g id="ao" class="countryGroup">
          <path
            id="path3888"
            class="country"
            d="M1390.1,847.8c-0.1-3.5,0.8-7,0.4-10.6c-0.1-0.5-0.5-1.1-0.2-1.6c0.3-0.8,1.2-1.3,1.6-2.1
    		c0.3-0.7,0.3-1.4,0.4-2.1c0.1-0.6,0.9-1.4,0.7-1.9c-0.4-1.1,1.1-4.2,1.4-5.5c0.4-1.8,0-3.8,1-5.4c0.7-1.1,0.2-3.3,1-4
    		c0.8-0.7,2.1-2.3,2.6-3.2c0.8-1.4,0.3-3.7,2.6-3.9c1.6-0.1,1.8-1.4,2.6-2.8c1.9-3.3,2.4-8.5,1.6-12.1c-0.4-1.7-1.1-2.9-2-4.3
    		c-1-1.5-1-2.7-1.6-4.2c-0.2-0.7-0.9-1.2-1-2c-0.1-0.9,0-1.8-0.4-2.7c-0.6-1.3-1.8-3,0-3.9c-0.5,0.4-0.8,0.9-0.8,1.5
    		c0.4-0.7,0.8-1.5,1.4-2.1c0.5-0.5,1.2-0.4,1.3-1.2c0.2-2-0.2-3.7-1.1-5.4c-1.1-1.9-1.6-3.9-2.4-6c-0.4-0.9-0.3-1.9-0.6-2.8
    		c-0.4-1.4-1.7-2.3-2.4-3.5c-0.6-1-2.6-4.4-0.8-4.9c0.8-0.2,1.7-0.3,2.5-0.4c1.2-0.2,1.7-1.2,3-1.3c5.9-0.6,11.8,0.1,17.8,0
    		c2.2,0,4.6-0.2,6.8-0.1c2.1,0.1,1.7,1.5,2.6,2.9c0.4,0.6,0,2.3,0.2,3c0.3,1.4,1,2.6,1.5,3.9c0.4,1-0.2,1.9,0.6,2.8
    		c0.9,1,1.1,1.7,1.7,2.8c0.3,0.7,1.9,4.1,2.9,3.8c0.6-0.2,3.7,0.1,3.7,0.1c-0.2-0.4,3.3-1.4,4-1.3c1.8,0.4,3.4,0.6,5.6,0.6
    		c-0.3-1.3,0.5-2.5,0.1-3.7c1.1,0.2,1.2-0.8,1-1.6c-0.2-1.1,0.7-2.4,0.3-3.3c1.8,0,3.7,0,5.6,0c0.6,0,0.2-0.6,0.7-0.8
    		c0.6-0.2,1.4,0,2,0c-0.4,2.1-1.5,3.2,1.1,3.2c2.3,0,4.6-0.1,6.9-0.1c1.9,0,1.6,1.9,1.1,3.2c-0.2,0.7-0.6,1.5-0.6,2.2
    		c-0.1,1.2,0.9,2.4,1.2,3.7c0.4,2.5-0.5,5.4-0.7,7.9c-0.2,2.7,0.7,3.7,2.3,5.7c1.1,1.4,1.6,5.1,1.3,6.8c-0.1,0.6-0.7,1-0.9,1.6
    		c-0.4,1.1,0.7,2.1,0.3,3.2c0.9,0,1.8-1.3,2.5-1.7c0.7-0.3,2.6,0.2,3.3,0.4c1.3,0.4,2.8-1.1,4.2-1c1.3,0,2.8,0.9,3.3-0.9
    		c0.6,0.9,0.7,3.7,0.5,4.7c-0.2,1.4-0.2,2.8-0.6,4.1c-0.4,1.6,0.6,3.1,0.2,4.7c-0.4,1.6-1.6,2.9-0.2,4.5c-2.8,1.2-6.8,0.6-9.8,0.6
    		c-1.5,0-3.3-0.4-4.8-0.2c-1.3,0.2-0.7,5.1-0.7,6.7c-0.2,4.2,0.1,7.2-0.1,10.4c-0.2,3.2,0,6.5-0.1,9.7c-0.1,3.3,2.2,4.2,4.1,6.5
    		c2,2.5,4.1,5,6.8,6.8c-4.5,0.9-8.9,1.6-13.3,2.8c-1.8,0.5-2.4,0-4.1-0.2c-0.7-0.1-1.4,0.3-2.1,0.5c-1.1,0.4-2.1-0.2-3.2-0.5
    		c-3.7-1-7.7-0.6-11.5-1.2c-1.6-0.3-2.5-1.7-3.2-2.9c-0.7-1.1-2.9-0.7-4.1-0.6c-9.3,0.4-18.9,0-28.2,0c-1.9,0-2.1-0.1-3.8-1.4
    		c-1.7-1.3-3-2.9-5.2-1.9c-1.7,0.7-3.4,2.7-5.3,1.8C1392.2,846.4,1391.9,847.5,1390.1,847.8"
          />
          <path
            id="Angola_Cabinda"
            class="country"
            d="M1392.4,741.9c1.3-1,2.8-3.6,4.4-4c0.8-0.2,0.7-1.4,1.5-1.4c0.9,0,1.5,1.5,2.2,2
    		c-1.5,1-2.9,2-4.2,3.3c-0.6,0.6-0.5,0.6-0.2,1.4c0.4,1,0.2,2.3,0.1,3.3c-0.1,1.5-2.9,2.6-2.9,0.7c0-1.1,0.6-1.3,0.2-2.5
    		c-0.2-0.9-1.5-2.3-0.5-3.1C1392.9,741.8,1392.6,741.9,1392.4,741.9"
          />
        </g>
        <g id="de" class="countryGroup">
          <path
            id="Germany_mainland"
            class="country"
            d="M1366.7,226.3c-0.7,0.3-0.8,1.2-0.6,1.8c0-0.4,0.4-0.7,0.8-0.9
    		C1366.6,227.1,1366.4,226.7,1366.7,226.3L1366.7,226.3z M1367.6,227.1c-0.2,0-0.4,0-0.6,0.1c0.4,0.1,0.9,0,1.2,0
    		C1368,227.1,1367.8,227.1,1367.6,227.1z M1369.3,227c-0.6-0.1-0.4,0.1-1,0.2c0.4,0.2,0.9,1.5,1.1,1.9c0.2,0.4,1.2,1,0.9,1.5
    		c-0.5,0.8-1.8,0.3-2.3,1.3c0.7,0.3,1.4,0.2,2.1,0c-1.6,0.7,0.1,1.2,0.4,2.1c-0.7,0-0.9,0.4-0.4,0.9c0.6,0.6,1.4,0.4,2.1,0.6
    		c0.8,0.2,1.1,0.8,1.6,1.4c0.6,0.7,1.3,0.8,2,1.3c-1.7-0.2-2.2-1.6-3.5-2.4c-0.9-0.5-1.9,0.1-2.8,0c-1.1-0.1-2.2-0.5-2.3,1
    		c-0.1,0.6,0.4,1,0.4,1.6c-0.2,0.4-0.2,0.9-0.3,1.3c-0.1-0.7,0.1-1.4-0.7-1.8c-1-0.5-1.3,0.4-0.7,1.1c-0.5,0.1-1,0-1.4-0.3
    		c2.4-1.6-4.1-1.9-4.7-1.7c-0.8,0.2-2.1,1.4-2.1,2.3c0,0.7,2.8,1.1,2.3,0.9c-1.1-0.5-1.9,3.7-2.1,4.6c-0.3,1.2-1.3,0.3-2.2,0.9
    		c-1.4,0.9,1.2,1.4,1.5,1.9c1,1.6-2.4,2.1-1.4,3.4c1,1.2-3.6,0.9-4.2,0.6c0.4,1.2-0.3,0.1-1.2,0.7c0.1,0.3-0.2,0.3,0,0.7
    		c0.9,0.3,2,2.5,1.5,3.4c-0.4,0.9-2.2,2.1-1.4,3.3c0.5,0.6,0.1,1.5,0.2,1.7c0.3,0.7,1.9,0.7,1.2,1.8c1-0.2,0.9,1,1.2,1.6
    		c-3,0-3.5,2.3-0.6,3.7c2,1,0,2.9,0.7,3.7c0.1,0.1,2,2.5,2.5,2.4c0.7-0.1,0.1-0.5,1.2-0.4c0.5,0.1,0,1,0.9,0.7c0.3-0.1,0.8,0,1.2,0
    		c1.3-0.4,1.4,0.2,2.6,0.6c1.1,0.3,2.4,0.5,3.5,0.7c-0.9,0.8-1.8,1.6-2.5,2.5c-0.8,1.1-1.1,2.8-1.7,4c-0.4,0.8-1.8,6.7,0.3,4.9
    		c-0.7,0.8,2.9,0.2,3.6,0.3c1.3,0.1,2.4,0,2.7-0.8c-1.6,0.6-1-1.5,0.8-0.6c1.3,0.6,4.4,1,5.8,1.8c0.8,0.5,5.1,0.2,4,2.1
    		c1.6,0,1.7-1.5,2-2.6c0.7,0.6,2.2,0.6,3,0.5c-0.7,0.4,1.1,0.9,1.5,0.9c0.9,0,1.4-0.7,2.2-0.8c1.6-0.2,3.1-1.4,4.8-0.8
    		c0-0.3,0-0.5-0.1-0.8c0.8,0.1,1.6,0.5,2.4,0.5c0.4-0.1,0.8-0.2,1.2-0.3c0.4,0,0.6,0.9,1,1.2c1.1,0.9,1.1,0.4,1.4-0.6
    		c0.3-1-1-0.6-0.9-1.5c0.1-1.1,0.1-1-0.7-1.9c-0.6-0.7-0.2-1.2,0.5-1.5c0.8-0.4,3.4-0.8,3.4-2c0-2.1,1.4-0.3,2.2-1.2
    		c1.4-1.7-1.1-2.9-2.1-3.5c-1.3-0.8-2.3-1.8-3.6-2.5c-1.6-0.9-2-3-3.5-3.8c1.2-1,0.7-1.8-0.5-2.4c-0.9-0.5-1.1-1.4-1.5-2.1
    		c0.7-0.1,1.2,0.2,1.4,1.1c0.2-2.7,3.7-1.6,5-2.7c2.2-1.8,5.8-2.7,8.5-3.7c-0.2-0.2-0.5-0.4-0.7-0.6c1-0.2,1.2-0.5,2.1,0.6
    		c2.1,2.7,3.5-2.3,2.4-3.6c-0.1-0.8-1.4-0.9-1.5-1.5c-0.1-0.9,0-1.2-0.7-1.9c-0.4-0.5,0.2-0.9,0.4-1.3c1-2.7-1.6-3.4-0.9-4.2
    		c1.4-1.6-2.5-2.2-3-3.7c0.7-0.6,3-4,1.5-4.2c-0.3-1.1-0.6-2.3-1.8-2.7c-0.7-0.3-1.9-0.5-2-1.4c0-0.5-0.3-2-1-2
    		c-0.4,0-1.3,0-1.1,0.5c-0.8-0.5-2.6-2.6-3.3-2.6c-1.6-0.1-2.7,0-3.9,1.2c-0.2-1.4,2.1-1.5,2.9-1.3c-1.7-0.6-1.9-0.1-3.1,0.8
    		c-0.6,0.4-1.9,0.9-1.5,1.9c0.2-1.6-3.1-0.2-3.8,0.3c1.5-0.7-0.2,0.5-0.4,1.2c-0.6-0.6-4.1-1.3-3.3,0c-0.1-0.5-0.5-0.8-1-0.9
    		c1-1,1.8-1.7,2.1-3.1c-1.3,0.3-2.1,0.8-3.4,0.2c-0.8-0.4-2.1-0.9-2.5,0.3c-0.1-0.5-0.1-0.6,0.2-1c-0.7-0.3-1.5-0.3-2.1,0
    		c0.5-0.2,1.7-1.7,0.4-1.7c2-0.9-4.8-1.5-4.5-1.4C1371.4,227.3,1370,227,1369.3,227L1369.3,227z"
          />
          <path
            id="path3832"
            class="countryIsland"
            d="M1367.7,228.9c0.2-0.8-0.1-0.8-0.7-0.1C1367.3,228.8,1367.5,228.8,1367.7,228.9"
          />
          <path
            id="path3834"
            class="countryIsland"
            d="M1384,231c0.4,0.1,0.9,0.1,1.3,0c-0.3-0.8-1.1-1-1.8-0.7C1383.7,230.6,1383.8,230.8,1384,231"
          />
          <path
            id="path3892"
            class="countryIsland"
            d="M1396.9,231.7c1.1-0.1,0.6-1.2,0.2-1.7c0.8-0.1,1.5,0.2,2.2,0.5c-0.3-0.9-2.2-0.6-0.7-1.6
    		c-0.1,1.5,1.5,0.2,1.7,1c0.1,0.4-0.6,1-0.1,1.3c0.4,0.2,0.7,0.3,0.7,0.8c-0.7-0.1-1.3-0.5-1.9,0
    		C1398.4,232.5,1397.2,232.3,1396.9,231.7"
          />
          <path
            id="path3894"
            class="countryIsland"
            d="M1401.4,234.1c0-0.3,0-0.7-0.2-1c0.7,0.9,2.1,0.9,2.8,1.8c0.6,0.9-1.9,0.9-2.4,0.7
    		c1-0.2,0.1-1.2,1.2-1.3C1402.3,234.3,1401.8,234.2,1401.4,234.1"
          />
        </g>
        <g id="th" class="countryGroup">
          <path
            id="Thailand_mainland"
            class="country"
            d="M2034.4,537.8c0.4,0.9,2.3,0.8,2.9,0.2c0.7-0.8-0.7-2.8-0.5-3.8
    		c0.1-0.6,0.5-0.9,0.6-1.5c0-0.9-0.7-1.5-0.4-2.4c0.2-0.6,1.3-2.7,1.7-2.6c1.2,0.2,2.4,0.8,3.7,0.5c0.6-0.1,1.2-0.8,1.8-0.8
    		c0.6,0,0.8,0.6,1.3-0.2c0.5-0.8-0.4-2.2,0.8-2.5c0.8-0.2,4.1,0.4,2.3-1.8c1.2-0.1,4.6-2.2,4.5,0.4c0.7-0.2,0.9-1.2,1.7-0.9
    		c0.4,0.1,1.6,1.7,1.8,2c0.7,0.8-0.3,2-0.5,2.8c-0.3,1.1,0.4,1.8,1.2,2.5c0.3,0.2,1.1,0,1.4,0.1c0.7,0.3,0.5-0.5,0.9-0.6
    		c0.4-0.1,0.3,0,0.6-0.1c0.4-0.1,0.9-0.2,1.2-0.2c1.5,0.2,1.3,2.7,2.2,5c0.3,0.7-0.5,1.6-0.3,2.4c0.5,1.5-0.3,1.3-0.7,2.3
    		c-0.1,0.4,0.5,1.3,0.6,1.9c0.1,1,0.4,1.5,0.1,2.5c-0.8,2.4-1.2,4.4,1,4.2c0.1,0,3.4-3.1,4-4.9c1.2,0.7,1.3-1.3,2.2-1.3
    		c0.6,0,1.5,1.2,2.1,1.5c0.5,0.2,1.5,0.3,1.8,0.8c0.9,1.5,1.9-0.7,3.1-0.5c0.6,0.1,0.8-2.3,1.8-2.4c-1.7-1.9,3.5-0.8,4-0.7
    		c3.1,0.9,4.2,5.2,7.2,6.8c2.4,1.3,1,5.9,1.7,8.1c0.5,1.4,0.7,1.9,1.7,2.8c1.6,1.6,2.5,2,4.3,2.8c-1.6,1.1,0.8,1.9,1.5,2.5
    		c1.8,1.3-1.7,3.8,0.3,3.3c-0.3,0.7-1,1.4-0.5,2.2c0.3,0.5,0.9,0.7,0.8,1.3c-0.2,1.7,0.5,3.4-1.2,4.5c-1.1,0.7-1.8,2.2-2.6,0.6
    		c-0.9-1.6-3.5,0-4.9-0.4c-0.9-0.3-1.8,0.1-2.8,0.2c-1,0.1-1.4-0.5-2.3-0.5c-1.2-0.1-2.5,0.4-3.6,0.9c-1.7,0.7-2.1,2.6-2.7,4.2
    		c-0.2,0.7-1,1.4-1.6,2c-0.4,0.4-1.1,0.4-1.6,0.5c0.6,1.4-0.1,2.5,1.3,4.5c0.7,1.1,0.9,2.5,1.9,3.6c0.9,1.1,1.2,2.1,1.2,3.5
    		c-0.1,2.3,1.6,3.2,1.4,5c0-0.9-0.8-1.4-1.1-2.1c-0.6-1.1-0.5-2.4-1.9-2.8c0,0.4,0.1,0.9,0.1,1.3c-0.4-1-3.6-1.4-1.7-2.7
    		c-0.5,0.3-0.9,0-1.1-0.5c0,1.6-1.8-0.8-2.2-1.6c0,1.4-1-1.4-2.3-0.8c-0.6,0.3-1.3,0.8-2,0.7c-0.9-0.2-2-0.7-2.9-0.5
    		c-1.9,0.5-0.8-1.5-0.9-2.6c-0.1-0.7-0.1-1.3-0.2-2c-0.1-0.9,0.6-1.6,0.4-2.5c-0.8,0.6-2.9,0-3.2-0.9c0.3,2-5.6,0.2-3.9,3.3
    		c0.9,1.5,0.3,2.5,0,4.1c-0.3,1.7,0.9,3.3,0.6,5c-0.1,0.7-0.6,1.2-1,1.8c-0.4,0.7-0.1,1.5-0.6,2.2c-0.9,1.3-1,3.3-1.4,4.8
    		c-0.4,1.8-0.7,3.2-1.4,4.9c-0.3,0.6,0.3,2.3-0.9,2.1c1.3,1.6-0.1,4.5,0.8,6.4c0.4,0.9,1,1.5,0.5,2.4c-0.5,1,1.4,1.3,1.9,1
    		c1.9-1,3.1-1,3.4,1.6c0.1,0.9,1.3,7,2.6,5c0.8,1.8,0.9,3.7,1.5,5.5c0.5,1.6,1.3,3.3,1.3,5.1c-1.2-0.2-1.4-5.5-2.7-4.6
    		c-0.9,0.7,0.5,2.7,0.8,3.2c0.4,0.6,0.9,1.1,1.5,1.6c0.5,0.4,1.1,0.3,1.6,0.7c1,1,1.2,2,3,2.1c1.3,0,2.7-0.5,3.8,0.4
    		c1.2,0.9,1.5,2.6,2.7,3.6c1.2,1,2.1,1.6,1.4,3.2c-0.3,0.8-0.8,2.3-1.7,2.5c-0.7,0.2-0.9-1-1.4-1.3c-1.5-0.6-2.3,2-3.2,2
    		c-1.8,0-1.4-1.8-0.8-2.8c0.7-1.1-0.5-2.1-1.3-2.3c-1.1-0.3-0.6-1.9-2-2.1c-1.3-0.2-2.1-0.2-2.9-1.3c-1.2-1.5-0.8,1.1-1.3,1.8
    		c-0.1-2-5.2-4-3.2-6.1c-1.7,1-1-1.6-1.9-2.2c0.1,1-0.8,0.8-1.2,0.2c-0.5-0.8-0.4-2.1-1.1-2.8c-0.5-0.5-1.4,0.1-1.5-0.6
    		c-0.1-0.7,0.1-0.9-0.4-1.5c-0.6-0.9-0.5-0.8-1.4-1c-0.6-0.1-0.4-1-0.6-1.4c-0.3-0.6-0.6-1.6-1.5-1.4c-1,0.2-0.6,1.1-0.9,1.8
    		c-2.4-0.5-1.9-4.1-1.6-5.8c0.6-3.5,1.3-7.1,2-10.5c0.3-1.3,1.4-3.1,1.4-4.5c0-1.6,0.6-1.6,1.2-3c0.8-1.7,5.3-6.4,3.8-8.3
    		c-0.7-0.8-1.2-3-1.5-4c-0.4-1.5-0.9-2-1.7-3.3c-0.4-0.7-1.4-2.8-1.3-3.6c0.4-2,0.3-3.4-0.5-5.3c-1.3-3.1-4.3-4.9-6.3-7.6
    		c-0.9-1.2-1.7-2.3-1.9-3.8c-0.1-0.8,0.1-2.8,1.3-1.7c-0.3-1,0.9-0.8,1.2-1.5c0.3-1-0.3-3.4-0.4-4.5c-0.3-1.8,1.2-0.9,1.8-1.9
    		c0.4-0.6,0.4-2-0.2-2.6c-0.2,0.6-0.5,1.1-0.9,1.5c-1.2-1.5-1.6-3.3-2.1-5c-0.3-1.1-2-2.3-2.8-3.1c-1.2-1.4-2.8-2.8-3.9-4.3
    		c-0.6-0.8-0.3-1.6-0.6-2.5c-0.2-0.8-1.1-1.4-0.9-2.3C2035.4,540.8,2034.8,538.8,2034.4,537.8"
          />
          <path
            id="path3902"
            class="countryIsland"
            d="M2049.7,627.8c-0.4,1.2-0.2,2.5,0.3,3.7C2051,630.6,2050.9,628.4,2049.7,627.8"
          />
          <path
            id="path3904"
            class="countryIsland"
            d="M2051.4,628.4c0.2,0.5,0.5,1,0.6,1.5C2052.1,629.3,2051.9,628.8,2051.4,628.4"
          />
          <path
            id="path3906"
            class="countryIsland"
            d="M2056.1,634c-0.1-0.6-0.3-1.1-0.7-1.5C2055.4,633.1,2055.6,633.6,2056.1,634"
          />
          <path
            id="path3908"
            class="countryIsland"
            d="M2060.7,640.6c-0.5,0.5-0.6,1.3,0.3,1.4C2060.9,641.5,2060.8,641,2060.7,640.6"
          />
          <path
            id="path3910"
            class="countryIsland"
            d="M2061.9,615.7c-0.5,0.5-0.5,0.9,0.1,1.3C2062.5,616.6,2062.5,616.1,2061.9,615.7"
          />
          <path
            id="path3912"
            class="countryIsland"
            d="M2061.8,613.8c-0.2,0.5,0.2,0.7,0.6,0.7C2062.2,614.3,2062,614.1,2061.8,613.8"
          />
          <path
            id="path3914"
            class="countryIsland"
            d="M2077.7,594.7c0.2,0,0.5,0,0.7,0.1c0-0.6-0.4-1-0.9-1.3C2077.6,593.9,2077.7,594.3,2077.7,594.7
    		"
          />
          <path
            id="path3916"
            class="countryIsland"
            d="M2080,596.9c-0.2,0.6-0.1,1,0.4,1.4C2080.5,597.8,2080.3,597.3,2080,596.9"
          />
        </g>
        <g id="pg" class="countryGroup">
          <path
            id="Papua_New_Guinea_island"
            class="country"
            d="M2374.3,777.6l1.4-18.3c-0.1-1.2-1.2-1.9-0.9-3.2c0.2-1.1,0.9-2.5,1.3-3.5
    		l1.7-31.5c1.1,0.4,3.4,1.2,4.5,1.7c2.9,1.5,5.6,3.6,8.9,4.5c1.6,0.4,3.3,0.2,4.8,0.9c1.8,0.8,2.9,2.2,4.5,3.2
    		c1.3,0.8,1.5-0.1,2.5,0.1c1.3,0.2,1.3,1.6,2.5,1.9c1.4,0.3,1.6,1.8,2.5,2.7c0.9,0.9,2.2,0.6,3.2,1.5c1.1,1,1.9,2.4,2.2,3.9
    		c0.2,1.3-0.8,2.3-0.6,3.4c0.2,1.6,4.1,1.9,5.2,2.3c1.2,0.4,2.5,1.3,3.6,1.9c0.5,0.3,0.7,0.9,1.4,1c0.7,0.2,1.4-0.2,2.2,0
    		c1.8,0.5,4.5,4.5,3,6.2c-1.2,1.4-4.2,0.2-5.7,0.3c-2.4,0.1-0.1,5.1,0.6,6.2c0.9,1.3,2.2,2.2,3.2,3.5c0.6,0.8,2.2,2.3,3.4,1.8
    		c-0.1,1.2,0.1,3.2,0.5,4.3c0.2,0.6,0.6,0.8,1.1,1.1c0.7,0.4,0.4,1.4,0.6,2.1c0.4,2,2,1.7,3.6,1.1c1.1-0.4,2.9-0.5,2.2,1.1
    		c-1.2,2.7-0.1,3.5,2.6,3.7c0.5,0,2.6-0.2,2.6,0.6c0.1,0.9-2,1-2.5,1.3c0.5,0.5,0.6,1.3,1.2,1.7c0.7,0.6,1.9,0.7,2.7,1.2
    		c1.2,0.8,3.1,1.2,4.5,0.7c-0.7,0.3-1.7,1-2.5,0.9c-0.7-0.1-1.4-0.5-1.5,0.5c1.6,0.4,3.4,1.2,1,1.9c-1.6,0.5-3.7,1.1-4.6-1
    		c0.5,0.1,1,0,1.4-0.4c-0.5-0.3-0.9-0.2-1.2,0.2c-0.1-1.5-2.3-1.4-3.4-1.5c-1.8-0.1-3.6-1.1-5.4-0.8c0.1-0.3,0.1-0.5,0.2-0.8
    		c-0.8,0.3-1.6,0.6-2.4,0.4c-0.6-0.1-1.8-1.1-2.3-0.8c-1.4,0.9-2.3-0.9-3.1-0.1c-0.2-0.9-1.4-1.3-1.4-2.3c0-0.8-0.6-1.8-1-2.4
    		c-0.5-0.7-1.5-0.2-2-0.9c-0.4-0.6-1.4-2.1-0.8-2.9c-2.4-0.3-2.1-1.4-2.7-3.1c-0.6-1.6-2-5.2-3.6-5.9c-0.5-0.2-1-0.7-1.4-1
    		c-0.6-0.5-1.5,0-2.2-0.1c-1-0.1-2.1-0.8-3.2-1.1c-1.9-0.4-1.1-0.7-1.4-2.1c0,1.2-1.2,0.6-1.5-0.1c-0.1,1.5-1,0.2-0.9-0.4
    		c-0.4,0.7-0.6,1.3-0.9,0c0,0.7,0.1,1.4,0.1,2.1c-0.4-0.5-0.8-0.8-1.4-1c-0.2,3.2-4.1-1.5-4.5-1.7c0.5,1.6,1.6,3,1.9,4.8
    		c-0.3-0.1-0.6-0.2-0.9-0.3c0.4,1.9-2.6-0.4-3.5-0.5c0.4,0.6,2.6,2.3,1.2,2.8c-0.9,0.4-1.9,0.3-2.8,0.5c-0.8,0.1-1.5,0.5-2.3,0.2
    		c-0.6-0.2-1.1-0.5-1.8-0.3c-1.1,0.3-1.7,0.8-2.2-0.6c0,1,0.5,1.4,1.4,1.1c1.3-0.3,2.3,0,3.4,0.8c2.2,1.4,3.9,4,0.9,5.3
    		c-1.4,0.6-2.8,2.1-4.3,2.2c-1.4,0.1-2.6-1.2-4.2-1c-1.7,0.2-3.6,0.6-5.3-0.2C2376.2,777.4,2375.2,779.2,2374.3,777.6"
          />
          <path
            id="path3962"
            class="countryIsland"
            d="M2394.6,774.1c0.4-1.5-2-2.1-2.9-2.4C2392.7,772.4,2393.8,773.2,2394.6,774.1"
          />
          <path
            id="path3964"
            class="countryIsland"
            d="M2394.3,772c1-1-0.9-1.2-1.5-0.6C2393.3,771.7,2393.8,771.9,2394.3,772"
          />
          <path
            id="path3966"
            class="countryIsland"
            d="M2395.2,771.6c0.1-0.6-0.3-0.8-0.8-0.7C2394.7,771.1,2395,771.4,2395.2,771.6"
          />
          <path
            id="path3968"
            class="countryIsland"
            d="M2395.8,768.7c0.1-0.5-0.1-0.6-0.7-0.2C2395.3,768.5,2395.5,768.6,2395.8,768.7"
          />
          <path
            id="path3970"
            class="countryIsland"
            d="M2398,766.3c-0.1-0.4-0.3-0.6-0.7-0.7C2397.5,765.8,2397.8,766,2398,766.3"
          />
          <path
            id="path3972"
            class="countryIsland"
            d="M2414.7,739.8c0.9-0.4,1-1.3,0.4-2C2414.4,738.2,2413.8,739.1,2414.7,739.8"
          />
          <path
            id="path3974"
            class="countryIsland"
            d="M2423.3,745.8c1.4-0.1,0.7-2.2-0.4-2.2C2422.2,743.6,2423.1,745.5,2423.3,745.8"
          />
          <path
            id="path3976"
            class="countryIsland"
            d="M2429.7,749.2c1.1-0.8,0.8-3-0.8-3C2427,746.2,2429.1,748.7,2429.7,749.2"
          />
          <path
            id="path3978"
            class="countryIsland"
            d="M2429.4,718.9c0.6,0.2,0.7-0.1,0.4-0.7C2429.7,718.4,2429.6,718.7,2429.4,718.9"
          />
          <path
            id="Papua_New_Guinea_New_Britain"
            class="countryIsland"
            d="M2436.4,750.1c0.5,0.6,1.3,0.9,1.6,1.7c0.9-0.7,2.3-1,3.2,0
    		c0.6,0.7,0.6,1.4,1.8,1.3c1.5-0.2,3.1,0.2,4.6-0.1c2-0.4,3.1-2.3,4.8-2.3c1.8,0,4.2-2.5,3.9-4.2c1,0.1,5.8,0.8,5.1-1.7
    		c-0.1-0.4-1.8-3.4-0.4-3.1c2.4,0.5,3-2,3-3.8c0-0.7,0.2-1.4-0.5-1.7c-0.7-0.3-1.4-0.6-0.6-1.4c-0.7,0.3-2.1,1.9-2.8,0.9
    		c-0.7-1-2.6-1-2.4,0.6c0.2,1.5,1,2.7,0.7,4.3c-0.2,1.6-2.4,0.7-3.5,1.6c-1.2,0.9-1.4,2.4-2.3,3.6c-0.4,0.5-1.1,0.4-1.6,0.6
    		c-1.2,0.3-1-0.2-2.1-0.4c-0.9-0.2-1.9,1.5-2.6,0.5c-0.9-1.2-0.9-3.2,0.6-3.9c-1.2-1.3-2.3,2.4-2,3c0.6,1.1-4.5,1.6-5,1.6
    		c-0.7,0-1.1-0.4-1.7-0.6c-0.8-0.3-1.2,0.1-2,0.1c-0.7,0-2.4,0.3-2.8-0.2c-0.6-0.9-1.6,1.3-0.7,2.2c0.6,0.7,1.3,0.5,2.1,0.8
    		C2435.5,749.4,2435.7,750,2436.4,750.1"
          />
          <path
            id="path3984"
            class="countryIsland"
            d="M2442.2,739.4c0.1-0.7-0.1-0.7-0.7-0.2C2441.7,739.3,2442,739.3,2442.2,739.4"
          />
          <path
            id="path3986"
            class="countryIsland"
            d="M2444.7,712.4c0.8-0.7-0.7-2.4-1.4-1.8C2442.9,711,2444.4,712.3,2444.7,712.4"
          />
          <path
            id="path3988"
            class="countryIsland"
            d="M2445,781c0.3-0.5,0.3-3.4-1-2.6C2442.5,779.2,2444.3,780.5,2445,781"
          />
          <path
            id="path3990"
            class="countryIsland"
            d="M2448.8,782.6c1.5-1.6-0.6-2.4-1.8-2.8c-2.1-0.8,0,1.5-1.2,2.2
    		C2446.6,781.7,2448.1,782,2448.8,782.6"
          />
          <path
            id="path3992"
            class="countryIsland"
            d="M2446.6,720.3c0.6,0.6,1,1.3,1.9,1.4c0.9,0.1,2.1-0.5,1.4-1.5
    		C2449.3,719.2,2446.8,718.7,2446.6,720.3"
          />
          <path
            id="path3994"
            class="countryIsland"
            d="M2448.1,790.8C2449.3,788.8,2446,790.7,2448.1,790.8"
          />
          <path
            id="path3996"
            class="countryIsland"
            d="M2448.9,785.1c0.2,0,0.4,0,0.6,0c-0.9,0.6,0.6,1.2,1.1,1.3c1.1,0.4,1.5-1.3,0.8-1.9
    		c-0.4,0.2-0.6,0.5-0.7,0.9c-1.2-0.4-1.7-1.9-2.7-2.5C2448.4,783.6,2448.8,784.3,2448.9,785.1"
          />
          <path
            id="path3998"
            class="countryIsland"
            d="M2448.5,791c0.2-0.3,0.5-0.5,0.9-0.6C2448.8,790.1,2448.4,790.4,2448.5,791"
          />
          <path
            id="path4000"
            class="countryIsland"
            d="M2451.7,775c-0.5-0.9-0.2-2.7,0.2-3.6c-0.8,0.1-1.4,1.3-0.2,1.4
    		C2451.4,773.5,2450.7,774.4,2451.7,775"
          />
          <path
            id="path4002"
            class="countryIsland"
            d="M2452.4,724.4c0.5-0.1,1-0.2,1.4-0.4C2453.2,723.7,2452.7,723.9,2452.4,724.4"
          />
          <path
            id="Papua_New_Guinea_New_Ireland"
            class="countryIsland"
            d="M2466.5,731.9c-1.1-0.1-1.5-1.9-2.1-2.6c-1-1.1-2.8-2.7-4.1-3.2
    		c-1.4-0.6-2.2-2.1-3.6-2.7c-0.5-0.2-0.9,0.4-1.1-0.4c-0.2-0.8-1.8-1.3-2.3-1.8c0.3,0.6,0,0.9-0.6,0.9c0.7,0.6,1.7,0.5,2.4,1.2
    		c0.9,0.8,1.7,1.6,2.6,2.3c1,0.8,1.9,1.8,3.1,2.4c0.9,0.4,1.8,0.6,2.6,1.2c1.5,1.2,2,3.2,2.8,4.9c0.6,1.1,0,2.3,0.1,3.5
    		c0.1,1.1,0.9,2,1.4,3c0.9-0.7,2.5-4.1,2-5.2c-0.4-0.9-1.2-1.5-1.6-2.3C2467.6,731.9,2467.5,732.3,2466.5,731.9"
          />
          <path
            id="path4006"
            class="countryIsland"
            d="M2462.2,791.3c0.2-0.2,0.5-0.4,0.7-0.6c-0.7,0-1.4,0-2.2-0.1
    		C2461.2,790.8,2461.7,791,2462.2,791.3"
          />
          <path
            id="path4012"
            class="countryIsland"
            d="M2464.2,778.5c2.3-0.1-0.2-3.5-1.7-1.8C2463.3,777.1,2463.4,778.1,2464.2,778.5"
          />
          <path
            id="path4014"
            class="countryIsland"
            d="M2465.2,796.2c-0.1-0.5-0.1-0.8-0.7-0.6C2464.7,795.8,2464.9,796,2465.2,796.2"
          />
          <path
            id="path4016"
            class="countryIsland"
            d="M2467.5,799.3c0.5-0.1,1-0.4,1.4-0.7c-0.7-0.6-2.7-2-3.7-1.4c0.5,0.4,0.5,1.1,0.9,1.5
    		C2466.7,799.2,2467.1,798.2,2467.5,799.3"
          />
          <path
            id="path4018"
            class="countryIsland"
            d="M2466.5,726.5c0.3-0.6,0.1-1.2-0.4-1.5C2465.9,725.6,2466,726.1,2466.5,726.5"
          />
          <path
            id="path4020"
            class="countryIsland"
            d="M2471.9,797.5c0.4-0.3,0.9-0.5,1.4-0.4c-0.6-1-1.4,0-2.2,0
    		C2471.3,797.2,2471.6,797.4,2471.9,797.5"
          />
          <path
            id="path4024"
            class="countryIsland"
            d="M2480.6,745.8c1-0.9,1.1-2.8,0-3.7C2480,743.2,2480.2,744.6,2480.6,745.8"
          />
          <path
            id="Papua_New_Guinea_Bougainville"
            class="countryIsland"
            d="M2482.9,752.4c0.6,0.5,1.3,0.7,1.4,1.5c0.1,1,0,2,0.8,2.8
    		c1.1,1.2,5.2,1.5,4.8-0.7c-0.2-0.9-0.4-1.6-1-2.4c-0.4-0.5-0.8-1.4-1.3-1.6c-0.5-0.2-1-0.2-1.3-0.8c-1.1-2.4-2.6-4.7-5.2-5.6
    		c0.7,0.8,0.2,2,0.2,2.9C2481.1,750.1,2482.3,751.2,2482.9,752.4"
          />
          <path
            id="path4028"
            class="countryIsland"
            d="M2488.2,760.2c0.2-0.1,0.5-0.1,0.7-0.2c-0.1-0.9-1.2-1.1-1.4-0.1
    		C2487.7,760,2488,760.1,2488.2,760.2"
          />
          <path
            id="path4030"
            class="countryIsland"
            d="M2490.7,759c0.1-0.5,0.1-1,0-1.4C2490.7,758,2490.7,758.5,2490.7,759"
          />
          <path
            id="Papua_New_Guinea_Manus"
            class="countryIsland"
            d="M2420.4,717.9c-1-1.1,0.7-2.1,1.6-2.4c1.5-0.4,3.2,0.4,4.7,0.7
    		c-1.3,0.3-1.4,1.3-2.9,1.3c-0.6,0-1.2-0.6-1.9-0.5C2421.3,717.1,2420.9,717.7,2420.4,717.9"
          />
        </g>
        <path
          id="iq"
          class="countryGroup country"
          d="M1585.8,409.8c4.4-2.1,8.2-4.2,12.1-6.9c1.2-0.8,2.6-1.9,3.3-3c1.4-2.1,0.3-4.8,0.6-5.6
    	c0-3.2,0.9-3.6,0.7-5.2c-0.2-1.7-2.7-4.8-0.8-6.3c1.1-0.9,2.4-0.8,3.6-1.8c0.9-0.8,3.5-2.9,2.7-3.7c-0.3-0.4,0.3,0,0.9-0.1
    	c0.8-0.3,1.1-1.1,1.5-1.7c0.4-0.7,4.2,0,5,0.3c0.7,0.3,1.3,0.5,2,0.6c1.1,0.2,1.5-0.5,2.5-0.7c2.5-0.5,0.8,2.8,2.2,2.7
    	c1-0.1,1.4-1.7,2.6-1.6c1.4,0,1.4,2.5,2,3.4c0.3,0.4,0.7,0.3,0.9,0.8c0.3,0.6-0.1,1.5,0.4,2c1.1,1.1,3.5,2.1,2.5,4
    	c1.6-0.4,2.3,1.1,3,1.4c1.4,0.5,1.4-0.6,4.4,0.2c-0.3,1.1-1.6,0.9-2,1.7c-0.8,1.5,1.4,2.6,1.6,3.9c0.2,1.8-2-0.2-1.9,1.6
    	c0,0.8-0.8,0.7-1.1,1.4c-0.2,0.9,0.2,1.5,0.3,2.3c-0.5,0-1.1-0.1-1.5-0.4c-0.1,0.7-0.6,1.3,0,1.9c0.6,0.7,1.1,0.9,0.7,2
    	c-0.3,1-1.2,1.3-0.4,2c0.8,0.7,1.4,1.5,2.3,2.1c0.8,0.6,5.2,4.3,2.7,5c1,0.2,0.7,1.4,1.5,1.2c1.2-0.3,1.8,0.5,2.7,1
    	c1.7,1,2.8,3.2,5.2,3.3c1.1,0.1,3.2,3.7,4.2,4.6c1.5,1.3,0.6,2.7,0.2,4.5c-0.2,0.9-0.1,3.3,1.2,3.4c0.4,0,1.2-0.1,1.6,0.4
    	c0.4,0.6,0.4,1.7,0.3,2.5c-0.2,1.6,0.6,2,2.3,3.3c0.9,0.6,0.7,2.6,1.9,2.9c-0.7,0-4.5-0.5-4.6-1.2c0.5,1.3-1.7,0.2-2.5,0.2
    	c-1.2,0-2.9,0.2-3.4,1.4c-1.2,2.6-1,7.8-5.2,7.3l-10.5-1.2c-3.5-0.5-3.7-2.4-6.9-4.4c-8.9-6.6-17.4-14.4-27.4-18.8
    	c-3.6-1.6-6.8-1.4-10-2.1c2-2.2-0.4-1.2-1-2.2c-0.2-0.2-0.3-0.7-0.1-0.9c0.5-0.6,0-1.2-0.3-2
    	C1587.1,413.6,1586.4,411.7,1585.8,409.8"
        />
        <g id="hr" class="countryGroup">
          <path
            id="path4066"
            class="country"
            d="M1401.4,305.8c0.5,0.1,1,0.2,1.5,0.4c0.6,0.1,1.2-0.5,1.5-0.6c0.2-0.1,1.6,1.3,3.7-1.3
    		c1.3,1.3,2.4,0.8,3.8,1.6c1.8,1,0.9-1.2,1.8-1.4c-0.4-0.1-0.7-0.4-1-0.5c2.8-2,3.5,0.1,2.5-3.8c-0.1-0.5,2.4-0.9,2.8-1.3
    		c0.6-0.6,0-0.4,0.6-0.8c0,0,1,0.2,1.2,0.3c-1.9-2.2,1.7-1.3,2.4-0.6c0.5,0.5,3.2,2.1,3.6,2.6c1.4,1.6,3.6,2.4,5.8,3.2
    		c5.4,0.3,2.9-1.1,5.3-1.4c0-0.5,0.4,0.6,0.7,1.2c0.4,0.9,0,2.2,1.4,2.2c-2.3,1.6,2.8,2.5,2.2,2.8l-2.2,0.5c0.7,1.2-0.2,3.1-1.7,2
    		c-1-1.8-1-0.8-1.5-1.3c-0.9-0.3-1.8-0.5-2.7-0.3c-1,0.3-1-0.5-2,0c-0.7,0.4-1.2,0-1.9-0.2c-1.7,0-3.2-0.6-4.8-1
    		c-1.2,0.1,0.5,0.6-1.4,0.1c-1.3-0.4-1.4,0-1.9,0.8c-1,1.8-1.1,1-2.8-0.3c-2.2-1.6-1.9,1.3-1.8,2.4c0.1,0.7-0.1,0.8,0.6,1.1
    		c0.6,0.3,1.7,0.9,2.1,1.6c0.9,1.3,0,3.1,1.9,4.2c1.9,1.1,3,3,4.7,4.3c1.4,1.1,2.1,2.4,3.3,3.4c1.5,1.3,0.7,2.8-0.9,0.8
    		c-1.7-2-6-5-8.8-3.9c-0.7,0.3-1.1,0-1.6-0.6c-0.6-0.8-0.2-1-1.3-1.5c-1.6-0.8-3.8-2.6-4.4-4.3c1-0.2,1.9,0,2.9,0.1
    		c-1.3-1-2.9-1.7-3.8-3.1c-0.8-1.2-0.3-2.7-1-3.8c-0.7-1-2.1-2-3.2-2.3c-0.8-0.2-1.6,2.2-1.5,2.9c-0.4-0.2-0.5,0-0.8-0.4
    		c-0.1,0.8-0.3,1.7-0.9,2.3c-0.5-0.4-2.2-2.4-1.4-3.1c-0.9,0.2-1.3-2.3-1.6-3C1401,305.7,1401.2,305.7,1401.4,305.8"
          />
          <path
            id="path4068"
            class="country"
            d="M1430,327.9c0.7-0.7,1.7,0.3,2.7,1.2c1.3,1.1,4.1,1.4,3.2,2.4c-1.2-0.2-3.2-1.8-4.1-2.5
    		c-0.8-0.6-1.8-0.5-2.8-0.9c-1-0.3-1.8-1.2-3-1.1c1.2-0.9,3.5,0.5,4.4,1.2C1430.4,328.2,1430.2,328,1430,327.9"
          />
          <path
            id="path4070"
            class="countryIsland"
            d="M1406.8,309.9c-0.4-0.4-0.5-0.9-0.4-1.5c0.8,1.4,1,3,1.4,4.5
    		C1406.9,312.7,1406,310.6,1406.8,309.9"
          />
          <path
            id="path4072"
            class="countryIsland"
            d="M1408,314.3c-0.4-0.5-0.8-1-1.2-1.4C1407.3,313.3,1407.7,313.8,1408,314.3"
          />
          <path
            id="path4074"
            class="countryIsland"
            d="M1408.6,309.9c-0.7-0.1-1.8-1.4-0.7-1.7c0.8-0.2,1.3,1.4,1.9,1.7
    		C1409.4,310.3,1409,310.3,1408.6,309.9"
          />
          <path
            id="path4076"
            class="countryIsland"
            d="M1409.1,311.3c0.2,0.1,0.5,0.3,0.7,0.4C1409.2,312,1409,311.9,1409.1,311.3"
          />
          <path
            id="path4078"
            class="countryIsland"
            d="M1410.6,316.7c-0.2-0.2-0.5-0.4-0.7-0.6C1410.2,316.2,1410.4,316.4,1410.6,316.7"
          />
          <path
            id="path4080"
            class="countryIsland"
            d="M1411.3,314.6c-0.7-0.7-1.4-1.4-1.9-2.2c0.9,1.2,2.4,1.7,3.3,2.9
    		C1412,315.8,1411.6,315.2,1411.3,314.6"
          />
          <path
            id="path4082"
            class="countryIsland"
            d="M1411.4,318c-0.2-0.2-0.5-0.4-0.7-0.6c0.9,0.1,1.2,1.2,1.9,1.6
    		C1412,318.8,1411.6,318.5,1411.4,318"
          />
          <path
            id="path4086"
            class="countryIsland"
            d="M1413,319.5c0.6,0.3,1.1,0.7,1.5,1.2C1414,320.3,1413.4,320,1413,319.5"
          />
          <path
            id="path4088"
            class="countryIsland"
            d="M1413,318.2c-0.2-0.2-0.5-0.3-0.7-0.5C1412.7,317.7,1412.9,317.9,1413,318.2"
          />
          <path
            id="path4090"
            class="countryIsland"
            d="M1415.8,321.2c0.3,0.1,0.5,0.2,0.8,0.4C1416.3,321.4,1416,321.3,1415.8,321.2"
          />
          <path
            id="path4092"
            class="countryIsland"
            d="M1419.9,322.6c0.2,0,0.5,0,0.7-0.1C1420.3,322.6,1420.1,322.7,1419.9,322.6"
          />
          <path
            id="path4094"
            class="countryIsland"
            d="M1421,324.1c-0.2-0.1-0.5-0.2-0.7-0.4C1420.6,323.8,1420.9,323.9,1421,324.1"
          />
          <path
            id="path4096"
            class="countryIsland"
            d="M1423.2,324.7c-0.7,0-1.7-0.2-1.8-1.1c1,0,2,0.1,2.9,0.4C1424,324.4,1423.6,324.6,1423.2,324.7"
          />
          <path
            id="path4098"
            class="countryIsland"
            d="M1424.6,327.8c-0.5-0.1-1-0.2-1.5-0.2c0.9-0.8,2.8-0.6,3.7-0.1
    		C1426,327.7,1425.2,327.3,1424.6,327.8"
          />
          <path
            id="path4100"
            class="countryIsland"
            d="M1425.8,325.9c-1.5,0-3-0.1-4.4-0.5c1.7-0.6,3.4,0.3,5.1,0.3
    		C1426.3,325.8,1426.1,325.9,1425.8,325.9"
          />
          <path
            id="path4102"
            class="countryIsland"
            d="M1430.7,329.5c-0.7-0.3-1.5-0.4-2.2-0.5C1429.2,328.9,1430,329.1,1430.7,329.5"
          />
        </g>
        <g id="gl" class="countryGroup">
          <path
            id="path4242"
            class="countryIsland"
            d="M1021.9,110c0.6,0.1,1.2,0.4,1.7,0.6c-1.2-0.2-3.1-0.9-3.3,0.9c0.6,0,1.2,0.3,1.5,0.8
    		c-0.9-0.4-2.2-1-3,0c1.4,1.2,3.5,1,5.3,0.9c-1.9,0.7,0.5,0.3,0.8,0.2c-1.9,0.9,1.7-0.5,2.2-0.4c-0.7,0-2.2,0.7-0.7,1
    		c-1.5,0-3.1-0.1-4.6,0.1c-0.1,2.6,6.9,0.7,8,0.5c1.2-0.2,6-0.8,5.9-2.6c0-1.2-2.2-1.3-2.9-1.5c-1.7-0.5-2.5-2.1-4.4-2.3
    		c-1.8-0.2-3.4-0.7-5.2-0.1C1022.5,108.4,1020.2,109.3,1021.9,110"
          />
          <path
            id="path4252"
            class="countryIsland"
            d="M1022.1,106.5c-0.3,0.2-0.3,0.5,0.1,0.8c0.3-0.1,0.5-0.3,0.7-0.6
    		C1022.7,106.7,1022.4,106.6,1022.1,106.5"
          />
          <path
            id="path4262"
            class="countryIsland"
            d="M1023.9,96.5c0.4,0.9,1.4,0.4,1.7-0.3C1025,96.3,1024.5,96.4,1023.9,96.5"
          />
          <path
            id="path4322"
            class="countryIsland"
            d="M1033.2,100.7c-1.1,0-2.3,0.6-2.9,1.4C1031.5,102.6,1034.5,102.7,1033.2,100.7"
          />
          <path
            id="path4330"
            class="countryIsland"
            d="M1036.2,99.1c1-0.1,2.1-0.5,3-1c-1,0.2-2.3-0.3-3.3,0C1035,98.5,1035.5,99.2,1036.2,99.1"
          />
          <path
            id="path4332"
            class="countryIsland"
            d="M1035.8,100.4c-1.3,1.6,3.1,0.8,3.8-0.1C1038.3,100.4,1037,100,1035.8,100.4"
          />
          <path
            id="path4336"
            class="countryIsland"
            d="M1038,99.2c-0.6-0.1-1.1,0.1-1.5,0.4C1037,99.5,1037.5,99.3,1038,99.2"
          />
          <path
            id="path4342"
            class="countryIsland"
            d="M1040.6,103c-0.6-0.1-0.9,0.2-0.8,0.8c0.8,0.1,1.6,0.2,2.4-0.2
    		C1041.6,103.4,1041.1,103.2,1040.6,103"
          />
          <path
            id="path4344"
            class="countryIsland"
            d="M1041.7,105.3c-0.2-0.6-1-0.6-1.5-0.5C1040.6,105,1041.1,105.2,1041.7,105.3"
          />
          <path
            id="path4346"
            class="countryIsland"
            d="M1040.4,106.1c0.2,0.1,0.4,0.2,0.7,0.2C1040.9,106.3,1040.7,106.2,1040.4,106.1"
          />
          <path
            id="path4348"
            class="countryIsland"
            d="M1042.6,106.5c-0.5-0.4-1-0.5-1.6-0.4C1041.5,106.3,1042.1,106.4,1042.6,106.5"
          />
          <path
            id="path4228"
            class="countryIsland"
            d="M1015.7,134c0.5,0,1-0.1,1.5-0.4C1016.7,133.6,1016.2,133.7,1015.7,134"
          />
          <path
            id="path4528"
            class="countryIsland"
            d="M1019.2,132.8c-1.3,0-2.5,0.5-3.7,0.7C1016.5,132.7,1018,132.8,1019.2,132.8"
          />
          <path
            id="path4248"
            class="countryIsland"
            d="M1020.5,124.1c0.4,0.7,0.7,0.6,0.8-0.3C1021,123.9,1020.7,124,1020.5,124.1"
          />
          <path
            id="path4260"
            class="countryIsland"
            d="M1024.9,121c-0.5,0.2-1,0.5-1.4,0.8C1024.1,122.1,1025.4,122,1024.9,121"
          />
          <path
            id="path4268"
            class="countryIsland"
            d="M1025.5,120.5c-0.2,0.1-0.5,0.2-0.7,0.4C1025.1,120.8,1025.3,120.6,1025.5,120.5"
          />
          <path
            id="path4272"
            class="countryIsland"
            d="M1026.3,119.6c-0.3,0.1-0.5,0.1-0.8,0.2C1025.8,119.7,1026,119.6,1026.3,119.6"
          />
          <path
            id="path4282"
            class="countryIsland"
            d="M1027.2,119.4c-0.2,0.1-0.5,0.1-0.7,0.2C1026.8,119.5,1027,119.4,1027.2,119.4"
          />
          <path
            id="path4294"
            class="countryIsland"
            d="M1027.5,123.3c0.6,0.3,2.7-0.3,0.8,0.5c1.2-0.1,2.8-0.2,3.7-1.1
    		C1030.6,123.1,1029.1,123.2,1027.5,123.3"
          />
          <path
            id="path4304"
            class="countryIsland"
            d="M1029.1,124c0.4,0.7,3.2,0.1,3.7-0.4C1031.7,123.3,1030.3,123.7,1029.1,124"
          />
          <path
            id="path4256"
            class="countryIsland"
            d="M1022.1,113.4c0.2,0.2,0.4,0.2,0.7,0.1C1022.6,113.4,1022.4,113.4,1022.1,113.4"
          />
          <path
            id="path4270"
            class="countryIsland"
            d="M1030.2,119.5c-1.8-0.3-3.6,0.3-5.3,0.6C1026.2,120.5,1029,120.4,1030.2,119.5"
          />
          <path
            id="path4338"
            class="countryIsland"
            d="M1041.6,110.6c-0.6-0.1-1.1,0-1.6,0.3c2.1-0.1-2.5,1.2-1.1,2.4c0.7,0.6,1.3,0.1,1.6-0.6
    		c0.5-0.9,1.5-0.8,2.3-1.4C1042.5,111,1042.1,110.7,1041.6,110.6"
          />
          <path
            id="path4274"
            class="countryIsland"
            d="M1028.7,90.3c-1-0.2-2.3,0.2-3,0.8C1026.6,91.1,1028,91.1,1028.7,90.3"
          />
          <path
            id="path4276"
            class="countryIsland"
            d="M1026.7,91.9c0.3-0.1,0.6-0.2,0.9-0.4c-0.6-0.1-1.1,0-1.7,0.3
    		C1026.2,91.9,1026.4,91.9,1026.7,91.9"
          />
          <path
            id="path4278"
            class="countryIsland"
            d="M1028.6,84c-0.8,0.1-1.6-0.1-2.2,0.5c1-0.1,2-0.1,2.9-0.5C1029,84,1028.8,84,1028.6,84"
          />
          <path
            id="path4280"
            class="countryIsland"
            d="M1030.3,93.1c-1.2,0-2.8,0.3-3.8,1.1C1027.5,95.3,1029.4,93.8,1030.3,93.1"
          />
          <path
            id="path4284"
            class="countryIsland"
            d="M1027,92.1c0.2,0.2,0.4,0.1,0.7-0.1C1027.5,92.1,1027.3,92.1,1027,92.1"
          />
          <path
            id="path4286"
            class="countryIsland"
            d="M1027.2,85.1c0.3,0.1,0.5,0.1,0.8,0C1027.8,85.1,1027.5,85.1,1027.2,85.1"
          />
          <path
            id="path4288"
            class="countryIsland"
            d="M1028.1,83.2c-0.2,0-0.5-0.2-0.8,0C1027.5,83.2,1027.8,83.2,1028.1,83.2"
          />
          <path
            id="path4290"
            class="countryIsland"
            d="M1028.1,78.3c-0.3,0.1-0.5,0.2-0.8,0.3c1.5-0.2,3.4,0.6,4.8-0.4
    		C1030.7,78,1029.4,78.4,1028.1,78.3"
          />
          <path
            id="path4292"
            class="countryIsland"
            d="M1031.4,89.8c-0.9,0.4-3,1.3-4,1.3c1.7-0.1,3.2,0.4,4.7-0.9C1031.9,90.1,1031.7,90,1031.4,89.8"
          />
          <path
            id="path4296"
            class="countryIsland"
            d="M1029.9,87c-0.9-0.1-1.6,0.6-2.3,1.2C1028.5,88,1029.3,87.5,1029.9,87"
          />
          <path
            id="path4298"
            class="countryIsland"
            d="M1028.3,85c0.5-0.3,1-0.5,1.5-0.6C1029.2,84.4,1028.7,84.6,1028.3,85"
          />
          <path
            id="path4300"
            class="countryIsland"
            d="M1029.8,83.4c0.6-0.1,1.1-0.3,1.7-0.6c-1-0.1-2.4-0.7-3.1,0.4
    		C1028.9,83,1029.4,83.1,1029.8,83.4"
          />
          <path
            id="path4302"
            class="countryIsland"
            d="M1030,85.9c-0.4,0.2-0.8,0.4-1.2,0.6C1029.4,86.8,1029.8,86.5,1030,85.9"
          />
          <path
            id="path4306"
            class="countryIsland"
            d="M1029.4,83.4c0.2,0.2,0.5,0.2,0.8,0.1C1029.9,83.5,1029.7,83.5,1029.4,83.4"
          />
          <path
            id="path4308"
            class="countryIsland"
            d="M1031.1,91.7c-0.5-0.1-1-0.1-1.5,0.1C1030.1,92,1030.6,92,1031.1,91.7"
          />
          <path
            id="path4310"
            class="countryIsland"
            d="M1029.7,89.6c-0.1,0.6,0.1,0.6,0.7,0.1C1030.2,89.6,1029.9,89.6,1029.7,89.6"
          />
          <path
            id="path4312"
            class="countryIsland"
            d="M1030.5,77.7c-0.3,0.1-0.5,0.2-0.8,0.3C1030.3,78.3,1030.6,78.2,1030.5,77.7"
          />
          <path
            id="path4314"
            class="countryIsland"
            d="M1031.2,89.3c0.2-0.2,0.5-0.4,0.7-0.6c-0.8-0.3-1.6-0.4-2.2,0.1
    		C1030.2,89.1,1030.7,89.2,1031.2,89.3"
          />
          <path
            id="path4316"
            class="countryIsland"
            d="M1030.9,84.8c-0.2,0-0.5,0-0.7,0c0.8,0.3,1.5,0.8,2.3,1.1C1032,85.4,1031.4,85.1,1030.9,84.8"
          />
          <path
            id="path4318"
            class="countryIsland"
            d="M1030.3,85.4c0.3,0.2,0.2,0.3,0.7,0C1030.8,85.4,1030.6,85.4,1030.3,85.4"
          />
          <path
            id="path4320"
            class="countryIsland"
            d="M1031.8,90.9c-0.6,0-1.1,0.2-1.5,0.6C1030.9,91.6,1031.4,91.4,1031.8,90.9"
          />
          <path
            id="path4324"
            class="countryIsland"
            d="M1031.7,86.6c0.6-0.2,1.1-0.4,1.6-0.7C1032.7,86,1032.2,86.3,1031.7,86.6"
          />
          <path
            id="path4216"
            class="countryIsland"
            d="M997.7,58.7c0.5,0.1,1,0,1.4-0.1c-0.4,0-2.7,0.5-0.6,0.2C998.2,58.8,997.9,58.8,997.7,58.7"
          />
          <path
            id="path4204"
            class="countryIsland"
            d="M969.3,59c-0.5,0-1,0.2-1.4,0.6c1.8,0.2,3.5,0.3,5.2-0.1C971.9,59.5,970.6,59.1,969.3,59"
          />
          <path
            id="path4208"
            class="countryIsland"
            d="M973.8,65.9c0.3-0.1,0.5-0.2,0.8-0.3c-0.4-0.1-0.8-0.2-1.2-0.1
    		C973.5,65.6,973.6,65.7,973.8,65.9"
          />
          <path
            id="path4210"
            class="countryIsland"
            d="M974.3,59c0.3,1.3,4.5,0.5,5.4,0.3C977.9,59.3,976.1,58.7,974.3,59"
          />
          <path
            id="path4212"
            class="countryIsland"
            d="M974.7,64.8c0.8,0.2,1.7,0.2,2.5,0C976.5,64.4,975.4,64.3,974.7,64.8"
          />
          <path
            id="path4214"
            class="countryIsland"
            d="M996.6,58.1c0.5,0,1.1-0.1,1.6-0.3C997.6,57.8,997.1,57.9,996.6,58.1"
          />
          <path
            id="path4522"
            class="countryIsland"
            d="M997.7,58.7c0.5-0.1,1-0.2,1.4-0.1C998.6,58.8,998.2,58.8,997.7,58.7"
          />
          <path
            id="path4524"
            class="countryIsland"
            d="M997.7,58.7c0.3,0,0.6,0,0.9,0.1C998.2,58.8,997.9,58.8,997.7,58.7"
          />
          <path
            id="path4226"
            class="countryIsland"
            d="M1015.6,39.4c0.5,0.2,1,0.3,1.5,0.1C1016.6,39.2,1016.1,39.2,1015.6,39.4"
          />
          <path
            id="path4380"
            class="countryIsland"
            d="M1095.4,32.2c-1.1-1.1-3-0.2-4.2-1.2c-1.2-0.9-3.2-0.9-4.3,0.1
    		C1088.6,32.9,1093.1,32.4,1095.4,32.2"
          />
          <path
            id="path4384"
            class="countryIsland"
            d="M1092.1,30.9c0.6,0.1,1.1,0.1,1.6-0.2C1093.1,30.5,1092.6,30.6,1092.1,30.9"
          />
          <path
            id="path4388"
            class="countryIsland"
            d="M1104.4,28.1c0.5,0.1,1.1,0.1,1.6,0C1105.4,28,1104.9,28,1104.4,28.1"
          />
          <path
            id="path4396"
            class="countryIsland"
            d="M1110.8,29.9c0.4,0,0.7-0.2,0.9-0.5c-0.8-0.2-1.7-0.3-2.4,0
    		C1109.8,29.5,1110.3,29.6,1110.8,29.9"
          />
          <path
            id="path4398"
            class="countryIsland"
            d="M1112.4,28.1c1.1,0,2.3,0.3,3.4-0.3C1114.7,27.6,1113.3,27.2,1112.4,28.1"
          />
          <path
            id="path4400"
            class="countryIsland"
            d="M1116.1,29.4c0.7,1.1,2.6,1.2,3.8,1.3c1.8,0.1,3.8,1.2,5.6,0.9c-0.3-0.2-0.5-0.4-0.8-0.6
    		c0.9-0.4,2-0.6,3-0.6c-2.7-2.8-9.7-2-13-1.6C1115.2,29,1115.6,29.3,1116.1,29.4"
          />
          <path
            id="path4402"
            class="countryIsland"
            d="M1119.5,27.8c0.6,0,1.2,0,1.7,0c-1.1,0-2.2-0.5-3.3-0.4C1118.4,27.5,1119,27.6,1119.5,27.8"
          />
          <path
            id="path4406"
            class="countryIsland"
            d="M1144.5,25.9c0.8,0,1.5,0,2.3,0C1146,25.7,1145.2,25.7,1144.5,25.9"
          />
          <path
            id="path4408"
            class="countryIsland"
            d="M1151.4,27c-1.8-1-4.4-1.1-6.5-0.9C1147.1,26.5,1149.2,27,1151.4,27"
          />
          <path
            id="path4410"
            class="countryIsland"
            d="M1152.6,27c0.6,0,1.1-0.1,1.6-0.5c-1.6-0.2-3.4-0.7-5-0.1C1150.3,26.7,1151.5,27,1152.6,27"
          />
          <path
            id="path4412"
            class="countryIsland"
            d="M1156.1,26.4c0.5,0,0.9,0,1.4-0.1c-1.8-0.6-6.4-1.7-8-0.6C1151.7,25.7,1153.8,26.2,1156.1,26.4"
          />
          <path
            id="path4416"
            class="countryIsland"
            d="M1154.1,25.2c0.8,0.2,1.7,0.5,2.4-0.2C1155.6,24.9,1154.8,25,1154.1,25.2"
          />
          <path
            id="path4470"
            class="countryIsland"
            d="M1234,34.2c1-0.1,2,0,3-0.4c-0.5-0.1-1-0.1-1.5-0.2C1235,33.8,1234.5,34,1234,34.2"
          />
          <path
            id="path4478"
            class="countryIsland"
            d="M1237.9,32.7c0.6,0.1,1.2,0,1.6-0.5c-1.1-0.4-2-1.2-3.2-0.9
    		C1236.2,32.2,1237.3,32.4,1237.9,32.7"
          />
          <path
            id="path4488"
            class="countryIsland"
            d="M1245,34c0.3-0.1,0.5-0.2,0.8-0.3c-1-0.5-2.2-0.8-3.2-0.2C1243.4,33.6,1244.2,33.8,1245,34"
          />
          <path
            id="path4490"
            class="countryIsland"
            d="M1243.8,32.1c0.2,0,0.5,0,0.7-0.1c-0.4-0.4-0.9-0.5-1.5-0.4
    		C1243.3,31.8,1243.6,31.9,1243.8,32.1"
          />
          <path
            id="path4450"
            class="countryIsland"
            d="M1224,55.5c-0.6-0.1-1.2,0.3-1.5,0.7C1223.1,56,1223.6,55.8,1224,55.5"
          />
          <path
            id="path4456"
            class="countryIsland"
            d="M1228.3,54.2c-0.6-0.2-1.1-0.1-1.7,0.1C1227.2,54.5,1227.8,54.5,1228.3,54.2"
          />
          <path
            id="path4458"
            class="countryIsland"
            d="M1226.8,55.2c0.3,0.1,0.5,0.1,0.8-0.1C1227.3,55.2,1227,55.2,1226.8,55.2"
          />
          <path
            id="path4460"
            class="countryIsland"
            d="M1229.2,56.4c1.1,0.2,2.2,0.3,3.2,0.4c-0.9-1.3-3.9-1.3-5.2-1.1
    		C1227.8,56,1228.5,56.3,1229.2,56.4"
          />
          <path
            id="path4466"
            class="countryIsland"
            d="M1234.6,50.6c0.2,0,1.2-0.2,0.6-0.7c-0.6-0.5-1.7,0.3-2.3,0.4c0.7,0.6,1.6,0.7,2.4,0.4
    		C1235.1,50.7,1234.9,50.6,1234.6,50.6"
          />
          <path
            id="path4468"
            class="countryIsland"
            d="M1234.5,52.9c-0.3,0-0.5,0-0.8,0c0.2,0.2,0.5,0.4,0.8,0.5C1234.5,53.2,1234.5,53.1,1234.5,52.9"
          />
          <path
            id="path4472"
            class="countryIsland"
            d="M1235.7,54.5c-0.5-0.1-1.2,0.2-1.6,0.4C1234.7,54.9,1235.2,54.8,1235.7,54.5"
          />
          <path
            id="path4474"
            class="countryIsland"
            d="M1234.3,54c0.5-0.1,1-0.2,1.5-0.4C1235.2,53.4,1234.6,53.5,1234.3,54"
          />
          <path
            id="path4476"
            class="countryIsland"
            d="M1239.4,42.2c-0.8-1.3-3.8-0.5-4.6,0.3C1236.3,43,1237.9,42.4,1239.4,42.2"
          />
          <path
            id="path4480"
            class="countryIsland"
            d="M1241,56.1c-1.1-0.3-2.5,0.8-3.3,1.4C1238.8,57.7,1241,57.7,1241,56.1"
          />
          <path
            id="path4482"
            class="countryIsland"
            d="M1239.6,51.3c-0.6-0.2-1.2,0.1-1.6,0.6C1238.5,51.7,1239.1,51.5,1239.6,51.3"
          />
          <path
            id="path4484"
            class="countryIsland"
            d="M1240.8,50.1c-0.5,0.1-0.8,0-0.8,0.7C1240.3,50.6,1240.6,50.3,1240.8,50.1"
          />
          <path
            id="path4486"
            class="countryIsland"
            d="M1243.8,47.9c-0.8,0-2,0.5-2,1.4c0.7-0.5,1.6-0.5,2.4-0.7C1244,48.3,1243.9,48.1,1243.8,47.9"
          />
          <path
            id="path4448"
            class="countryIsland"
            d="M1224.7,66.2c-0.5-0.3-1.6-0.3-2.3,0C1223.2,66.6,1223.8,66.1,1224.7,66.2"
          />
          <path
            id="path4424"
            class="countryIsland"
            d="M1192.8,89.5c-1-0.2-2,0.1-2.9,0.4C1190.9,90.1,1192,90.5,1192.8,89.5"
          />
          <path
            id="path4426"
            class="countryIsland"
            d="M1192.6,86.6c3-0.5,6,1.4,8.9,0.4c-2.7-0.4-7.7-2.9-10-0.4C1191.9,86.6,1192.3,86.6,1192.6,86.6
    		"
          />
          <path
            id="path4432"
            class="countryIsland"
            d="M1208.1,89.4c-2.4-1.1-11.5-2.5-13.5,0.1c2-0.2,5.6-0.4,7.4,0.3c0.5,0.2,1.4,1.2,1.9,1.1
    		c1.2-0.2,2.4-0.1,3.6,0.1c-0.1-0.6-0.5-0.9-1.1-0.9C1207,89.9,1207.5,89.7,1208.1,89.4"
          />
          <path
            id="path4434"
            class="countryIsland"
            d="M1216.4,78.8c-0.6-0.1-6.4,0.6-5,1.6c2.1,1.5,6.4,0.7,8.7,0.2c-0.8-0.2-1.1-1-1.8-1.4
    		C1217.7,78.9,1217,79,1216.4,78.8"
          />
          <path
            id="path4436"
            class="countryIsland"
            d="M1220.2,64.8c0.2-0.2,0.5-0.4,0.7-0.6c-0.8,0-1.6-0.1-2.3,0.4
    		C1219.1,64.7,1219.7,64.8,1220.2,64.8"
          />
          <path
            id="path4438"
            class="countryIsland"
            d="M1222,76.9c0.5-0.2,1-0.4,1.5-0.7c-0.5-0.5-1.2-0.7-1.9-0.4c0.4-0.2,0.7-0.4,0.9-0.7
    		c-0.7-0.2-1.6-0.5-2.3-0.2c-0.7,0.3-0.8,1.2-1.6,1.4C1219.5,77,1221,77.4,1222,76.9"
          />
          <path
            id="path4442"
            class="countryIsland"
            d="M1222.4,65.1c-0.8-0.1-1.4,0.5-2.2,0.4C1220.8,65.9,1222,66.1,1222.4,65.1"
          />
          <path
            id="path4446"
            class="countryIsland"
            d="M1222.9,64.6c-0.6-0.1-1.1-0.1-1.5,0.3C1221.9,64.8,1222.4,64.7,1222.9,64.6"
          />
          <path
            id="path4452"
            class="countryIsland"
            d="M1224.9,65.7c-0.7-0.3-1.7,0.1-2.3,0.3C1223.4,65.8,1224.2,65.9,1224.9,65.7"
          />
          <path
            id="path4454"
            class="countryIsland"
            d="M1228.5,77.7c-0.7-0.6-1.5-0.3-2.3,0C1226.9,78.3,1227.9,78.4,1228.5,77.7"
          />
          <path
            id="path4462"
            class="countryIsland"
            d="M1234.1,72.4c-0.7-0.1-0.9,0.5-1.5,0.6c-0.6,0-1.1-0.5-1.7-0.4c-1.3,0.2-2.3,1.1-2.4,2.5
    		c1.6-0.3,3.6-0.4,5.2-0.2c1.2,0.2,2.3,0.6,3.1-0.7c-0.7-0.3-1.1,0.2-1.7,0.2c-0.8,0.1-1.5-0.4-2.1-0.8c0.5-0.2,1.1-0.4,1.6-0.6
    		C1234.4,72.8,1234.3,72.6,1234.1,72.4"
          />
          <path
            id="path4464"
            class="countryIsland"
            d="M1232.2,67.4c0.2-0.4,0.9-2.8,0.7-3.2c-0.7-1.3-2.4,0.4-0.7,0.2c-0.4,0.1-1.5,0.2-1.6,0.8
    		c-0.1,0.8,0.7,2.7,1.2,3.3C1232.1,68.1,1232.3,67.8,1232.2,67.4"
          />
          <path
            id="path4414"
            class="countryIsland"
            d="M1152.5,123.4c0.5,0.2,1,0.2,1.5,0C1153.6,122.9,1152.9,122.7,1152.5,123.4"
          />
          <path
            id="path4418"
            class="countryIsland"
            d="M1183.5,102.4c-1,0-2,0.3-2.9,0.6c-1.6,0.5-3,0.3-4.6,0.6c-1.5,0.3-2.8,1.2-4.2,1.6
    		c-0.5,0.1-0.9,0.2-1.4,0.3c-0.7,0.1-0.8,1-1.5,1.2c2.1,1.2,5-0.1,7.2-0.3c2.9-0.3,5.8-0.1,8.7-1.1c-0.2-0.2-0.5-0.4-0.7-0.6
    		c1.5-0.1,0.2-1.6-0.8-1.5C1183.3,102.8,1183.4,102.6,1183.5,102.4"
          />
          <path
            id="path4420"
            class="countryIsland"
            d="M1174.3,103.7c-0.8-0.2-1.9-0.3-2.7,0.1C1172.1,105.1,1173.7,104.2,1174.3,103.7"
          />
          <path
            id="path4422"
            class="countryIsland"
            d="M1180,106.3c-0.5-0.3-1.2-0.1-1.6,0.2C1179,106.8,1179.5,106.8,1180,106.3"
          />
          <path
            id="path4428"
            class="countryIsland"
            d="M1193.7,112c-0.6-0.2-1.2,0.1-1.6,0.4C1192.6,112.7,1193.3,112.5,1193.7,112"
          />
          <path
            id="path4430"
            class="countryIsland"
            d="M1203.7,93.4c-0.7,0.1-1-0.3-0.8-0.9c1.2,0.3,2.5,0.6,3.8,0.5c0-0.8-1.8-1.4-2.4-1.7
    		c-0.3,0.1-0.6,0.1-0.9,0c-0.6-0.4-1.2-0.8-1.8-1.2c-1-0.7-3.2-0.4-4.4-0.5c-1.6,0-3-0.5-2.9,1.4c0,1.5,4.8,2,5.9,2.5
    		c1.3,0.6,4.5,2.7,5.4,0.5C1204.9,93.9,1204.3,93.7,1203.7,93.4"
          />
          <path
            id="path4374"
            class="countryIsland"
            d="M1077.4,152.8c-0.2-0.1-0.1-0.4-0.7,0C1076.9,152.8,1077.1,152.8,1077.4,152.8"
          />
          <path
            id="path4376"
            class="countryIsland"
            d="M1078.2,153.3c0.8-0.1,1.6-0.1,2.4-0.1C1080.4,151.8,1078.6,152.5,1078.2,153.3"
          />
          <path
            id="path4378"
            class="countryIsland"
            d="M1083.8,151.5c0.1-0.6-0.4-1.1-1.1-1c0.6-0.6,0.7-1.8,0-2.4c-0.2-0.1-1.5-0.5-1.2,0.2
    		C1082.3,149.8,1082,150.9,1083.8,151.5"
          />
          <path
            id="path4382"
            class="countryIsland"
            d="M1089.8,145.1c0.2-0.1,0.5-0.3,0.7-0.4c-0.5-0.2-1-0.1-1.4,0.1
    		C1089.3,144.8,1089.5,144.9,1089.8,145.1"
          />
          <path
            id="path4386"
            class="countryIsland"
            d="M1101.3,142.8c0.3-0.5,1.4-0.5,1.9-0.6c1.5-0.2-0.1-1.1,1.3-0.9c-1.2-0.7-2.2-1.3-3.4-0.1
    		C1100.6,141.8,1100,143,1101.3,142.8"
          />
          <path
            id="path4390"
            class="countryIsland"
            d="M1105.6,142.7c0.3-0.2,0.5-0.5,0.7-0.7c-0.5-0.3-1-0.3-1.5,0.1
    		C1105,142.3,1105.3,142.5,1105.6,142.7"
          />
          <path
            id="path4392"
            class="countryIsland"
            d="M1107,141.5c0.4-0.2,0.7-0.5,0.7-1C1107.2,140.6,1106.9,141,1107,141.5"
          />
          <path
            id="path4394"
            class="countryIsland"
            d="M1108,141.1c0.4-0.5,1-0.9,1.6-1.2C1108.9,139.8,1108,140.2,1108,141.1"
          />
          <path
            id="Greenland_island"
            class="countryIsland"
            d="M976.1,63.4c3,0.7,6.1,0.7,9.2,0.4c-0.1,1.4-2.8,0.8-3.6,0.8c-1.7,0-4.5,0.5-6,1.3
    		c1,1,3.1,1.5,4.5,1.6c-1.6,0.3,0.1,0.5,0.6,0.6c0.8,0.1,1.6,0.1,2.3,0.1c1.7,0,3.7,1,5.3,0.3c-0.8-0.3-1.3-1.2-2.2-1.3
    		c1.8-0.2,1.9-0.7,3.4,0.4c0.9,0.7,3.4-1.6,5.1-0.7c-0.6,0.2-1.1,0.5-1.5,0.9c1,0.7,2.1,0.4,2.7-0.4c0.2-0.2,1.6,0.2,2.1-0.1
    		c0.3-0.1,0.7-0.1,1,0c0.5,0.1,0.5-0.5,1-0.6c1-0.2,2.3-0.5,2.2,0.7c0.5,0,7.8-2.5,7-0.3c1.1-0.9,2.8-0.4,4.1-0.2
    		c1.8,0.3,3.6,0.2,5.4,0.5c-0.1,0.2-0.3,0.5-0.4,0.7c0.8,0.2,4-0.5,3.6,0.6c0.5,0,1.1-0.1,1.6-0.2c-0.2,0.2-0.5,0.4-0.7,0.6
    		c1,0.3,2.1-0.2,3.1,0.1c-1.6,0.1-0.2,0.9,0.4,1c0.8,0,1.5-0.2,2.2-0.2c-2,0.6,0.6,0.1,0,1.3c-0.5,1.1-1.9,0.8-2.9,1.1
    		c0.8,0.3,1.5-0.3,2.3-0.3c-0.7,0.3-1.2,1.5,0,1.4c-0.2,0.2-0.5,0.4-0.7,0.6c1.5,0.9,4.1,0.4,5.2,1.8c-0.6,0.4-1.7,0.2-1.7,1.2
    		c1.4-0.9,2.4,1.1,3.3,0.8c-0.7,0.7-2.1,0.8-3,0.6c0.7,0.5,1.5,0.4,2.3,0.3c-0.7,0.5-1.5,0.3-2.3,0.4c0.5,0.3,1,0.4,1.6,0.3
    		c-2,0.7-4.2,0.6-6.1,1.3c2.1,0.3,4.2-0.4,6.3-0.9c-0.4,0.5-0.9,0.9-1.5,1.2c0.5,0.2,1.1,0.4,1.7,0.4c-1.8,0.2,0.3,0.9,0.7,1
    		c-0.9,0.1-1.4,1-0.2,1.1c-0.2,0.1-3.7,0.1-1.6,0.3c-1.7,0.3,2.4,1.6,2.6,2.3c-0.6-0.3-3.4,0.4-1.6,0.8c-0.9,0.2-1.5,1.2-2.4,0.8
    		c1.1,0.6,2.1,0.1,3.1,0.4c1.5,0.6,0.7,1.8-0.6,1.9c0.5,0.3,1,0.2,1.5-0.1c-1.1,0.4-1.9,1.2-3,1.6c1,0.3,2.1,0.3,3.1,0.5
    		c-2.3,0.5-4.8-0.8-7.1,0.3c1.1,1.1,3.5-0.3,4.6,0.6c-1.2,0.1-1.9,1-3,1.3c-1,0.3-2.4,0.6-3.1,1.4c2.6,1.2,4.4-0.7,6.8-1.5
    		c-1.8,1.2-3.2,1.9-5.4,2.2c-1.4,0.2-3.4,0.5-4.5,1.5c0.8,0.2,0.8,0.9,1.1,1.5c0.3,0.7,2.2,1.2,1.3-0.2c1.2,1,3,1.1,4.5,1
    		c0.7-0.1,1.4-0.2,2-0.5c1.8-0.7,0.1-0.8,0.7-2.1c0.4,0.5,0.9,0.6,1.5,0.5c-0.9-1.3,3.1-0.1,2.7-2.4c-0.1-0.8-0.8-1-0.5-1.9
    		c0.3-1,1.1-0.7,1.9-0.7c-2.7,0.1-0.5,2.2-1.1,4c1.1-0.5,2.7-1.3,3.9-1.4c-1,0.4-2.9,1.3-3.8,2c1.5,0.2,3,0.4,4.5,0.6
    		c1.3,0.1,2.7-1,4-0.6c-2.3,2-5.8,0.7-8.3,2.1c2.9,0.2,6.3-1.2,9.1-0.5c-1.1,0.7-2.6,0.2-3.8,0.4c-1.3,0.3-2.3,1.3-3.7,1.5
    		c1.6,1,3.8-0.8,5.3-1.1c0.3,0.8,0,1.1-0.9,0.8c-1,0.1-1.9,0.4-2.8,0.8c1.1,0.9,3.4,0.1,4.7,0.1c-2,0.5,1,0.9,1.5,0.8
    		c-1.8,1.2-3.7-1.1-5.5,0c1,0.3,1.9,0.9,3,1c0.8,0,1.6,0.1,2.3,0.2c0.8,0.2-0.2,1-0.8,0.8c-1-0.4-1.8-0.1-2.7-0.1
    		c0.5,0.6,2.8,0.5,3.7,0.8c-0.5-0.1-1.1-0.1-1.6,0c0.2,0.2,0.5,0.5,0.7,0.7c-0.8-0.4-2.1-0.4-2.9-0.3c0.4,0.3,2.6,1,0.7,0.8
    		c1,0.7,2.2,0.2,3.1-0.4c-0.5,0.3-0.6,0.8-1,1.2c-0.6,0.6-2-0.1-2.6-0.2c-2.2-0.5-3.6-1-5.6-2c-2-1-4.2-0.2-6.3-0.7
    		c-1.9-0.5-4.2-0.6-5.9,0.7c1.1,0.8,2,1.8,3.3,2.1c1.8,0.4,4.1,0,5.6,1.4c1.4,1.3,2.4,1,4.2,1.3c0.3,0.1,1,0.3,1.3,0.2
    		c0.8-0.3,1.7-1.1,2.5-0.4c-0.5,0.1-1,0.2-1.5,0.4c1.9,0.3,3.8-0.6,5.8-0.5c-0.3,0.6-1.2,0.6-1.7,1c0.6,0.1,0.9,0.5,0.7,1.1
    		c-1.2-0.1-2.2-0.2-3.1,0.6c0.6-0.2,1.1,0,1.5,0.4c-0.8-0.2-2.5-0.7-2.5,0.7c0.9,0,2.2-0.6,2.9,0c-2-0.3-4.3,0.9-5.7,2.2
    		c1.4,0.4,2.1-0.7,3.4-0.7c0.6,0,0.7,0.9,1.3,1c-0.7,0.3-1.5,0-2.2,0.6c0.5,0.1,1,0.2,1.4,0.5c-0.5,0-1,0.2-1.4,0.5
    		c-0.1-1.3-1.5-0.9-2.4-0.8c0.2-0.2,0.5-0.4,0.7-0.6c-0.9-0.2-1.6,0.5-1.9,1.3c-0.3,0.8-1.3,0.7-1.7,1.4c1,0.2,2-0.4,3-0.4
    		c-0.7,0.2-2,0.4-1.4,1.4c-1.1-0.5-2.2,0.6-3.3,0.5c-1.1-0.1-2.4-0.5-3.5-0.2c0.5-0.1,0.9-0.3,1.4-0.5c-1,0.5-2.1-0.2-3,0.4
    		c0.5,0,1.1,0,1.6,0.1c-1-0.1-2.2,0.1-3.1,0.5c1.9,0-1.1,0.7-1.5,0.8c-1.1,0.2-2.2,0.3-3.2,0.8c1.3,0,2.5-0.6,3.8-0.3
    		c-0.6,0-1.2,0.3-1.6,0.8c0.9-0.9,3.3,0.1,4.5-0.1c1.3-0.2,3.6,0.3,4.5-0.9c-0.6,0.1-1.1-0.1-1.5-0.5c1.6,0.4,3.1-0.4,4.6-0.4
    		c-0.7,0.3-1.4,0.3-2.1,0.6c-0.6,0.3-0.7,1-1.4,1.1c0.5,0.1,1,0.3,1.4,0.5c-0.8-0.1-1.5-0.4-2.2,0c2,0.5,3.9,1.7,6,1.9
    		c-2.3,0.4-4.2-1.2-6.5-1c-1.1,0.1-2.3,0.2-3.5,0c-1.2-0.2-2.4-1.2-3.7-0.8c0.5,0.2,1.1,0.4,1.5,0.8c-1.7-0.5-3.4-1.2-5.2-0.8
    		c1,0,1.3,0.3,2,0.9c1.2,1,2.8,0.7,4.3,0.7c-2.1,0.6-4-0.9-6-0.5c0.5,0.2,1,0.3,1.5,0.2c-0.8,0.2-1.5,0.2-2.2,0.6
    		c1.8-0.1-0.1,0.5-0.7,0.1c1.5,1.9-2.5-0.4-1.7,1c-0.8-0.3-1-0.1-0.7,0.6c0.2-0.2,0.4-0.4,0.7-0.6c0.5,1-1.6,0.5-1.4,1.9
    		c2.1,0,3.7-1.6,5.7-2c2.6-0.5,5.3-1,8-1.4c-1.4,0.8-3.1,0.4-4.5,0.9c0.8,0.8,2,0.8,3,0.4c-1.8,0.5,1.4,0.6,2.2-0.1
    		c-0.2-0.3-0.4-0.5-0.6-0.8c1,0,2-0.9,3-0.1c-0.7,0.2-1.5,0.2-2.2,0.4c0.2,1.4,2.5,0.7,3.3,0.4c-1.8-0.2-0.1-0.6,0.4-0.6
    		c0.7,0,1.3,0.2,1.9,0.5c-1.9,0.5-4,0.9-6,0.9c1.9,1.2,3.8,0.1,5.8-0.1c-1.2,0.5-2.6,0.6-3.8,1c1.1,0.8,2.4,0.8,3.7,0.7
    		c-2.2,0.6-4-0.8-6.1-1.1c-2.4-0.4-5.1-0.6-7.6-0.8c2.1,0-1.8,0.4-2.2,0.6c1.8-0.3,0,0.2-0.3,0.3c-0.9,0.2-1.8,0.5-2.7,0.8
    		c-1,0.3-3.2,1-2.9,2.4c1-0.2,2-0.6,3.1-0.8c1.2-0.3,2.4,0.2,3.7-0.1c3-0.7,6.1,0,9.1-0.7c-0.7,0.2-1.5,0.4-2.2,0.4
    		c0.8,2.7,3.4,0.7,5.3,1.2c-1,0.1-4.1,1.1-4.8,0.3c-1.1-1.2-0.8-1.5-2.7-1.3c-1.8,0.2-3.5,0.2-5.3,0.4c-1,0.1-2,0.1-3,0.2
    		c-0.2,0-3.4,0.3-1.1,0.4c-1,0.2-2.1,0-3,0.6c0.9,0.5,2,0.4,3,0.1c-0.7,0.2-1.5,0.3-2.2,0.3c0.6,0.6,1.5,0.4,2.2,0.5
    		c-1,0.1-2,0.1-3,0.1c1.6,0.7,3.4,0.4,5,0.6c-1.2,0-2.7-0.2-3.7,0.7c1.5-0.3,5.7-2.1,7-0.9c-1,0.2-1,0.4,0.1,0.6
    		c-0.7,0.4-1.4-0.1-2.1-0.1c-0.8-0.1-1.6,0.3-2.4,0.1c0.5,0.1,1,0.2,1.5,0.2c-0.6,0.6-1.5,0.4-2.2,0.6c0.7,0.4,1.5,0,2.3,0.1
    		c-1.5,0.5-3.1,0.1-4.6,0.6c0.9,1.3,4,1,5.3,0.7c-1,0.4-4.2,0.6-5.2,0.3c1.8,0-0.3-0.2-0.6-0.1c-0.8,0.2-1.3,0.8-2,1.3
    		c-1.4,0.9,2.8,0.6,3,0.6c-1.2,0.2-2.6,0.2-3.5,1.2c1.7,0.7,3.8-0.4,5.2-1.2c1.8-0.9,3.9-1,5.5-2.3c1.4-1.1,3.3-1.5,5-2
    		c2.3-0.7,4.5-1.4,6.9-1.6c-0.5,0.2-1,0.3-1.5,0.2c0.7,0.4,1.5,0.4,2.3,0.4c-0.6,0.3-1.3,0.2-1.9,0.1c-1.3-0.2-2.2,0.1-3.4,0.4
    		c0.7,0.8,2.2,0.6,3.1,0.6c-1.7,0.5-3.4-0.3-5.1,0.2c-1.8,0.5-3.7,1.1-5.3,2.2c-1.5,1-3.5,0.7-4.9,1.8c-1.5,1.2-3.5,1.2-5.2,1.9
    		c0.5,0.1,1,0.2,1.5,0.2c-0.5,0.2-1,0.3-1.5,0.2c0.8,0.8,2,0.1,2.9-0.2c-0.7,0.3-1.5,0.6-2.2,0.8c1.3,1.1,3.1-0.7,4.5-0.6
    		c1,0.1,0.2,0.9,1.4,0.3c0.9-0.4,2-0.6,3-0.8c0,1.2-0.8,0.5-1.7,0.6c-0.9,0.1-1.3,0.6-2,1c-0.2,0.1-1.6-0.2-1.7-0.2
    		c-1.3,0-2.5,0.3-3.7,0.8c0.5,0.1,1.1,0.1,1.6-0.1c-0.5,0.4-1,0.5-1.6,0.5c1,0.8,2.2,0.3,3-0.6c0,0.6-0.3,0.9-0.9,1
    		c1.9-0.2-0.3,0.6-0.7,0.9c0.9,0.5,1.7-0.4,2.3-0.9c-0.5,0.5-1,1-1.5,1.4c1-0.4,1.1-0.2,0.3,0.6c0.5-0.1,1-0.2,1.6-0.2
    		c1.1-0.2,1.8-0.6,2.7-1.2c1-0.7,2.2-1,3.4-1.3c1.5-0.3,2.8,0.5,4.2,0.2c-0.5,0.4-0.3,0.6,0.4,0.5c-2.5,0.4-4.9-1.6-7.3,0.4
    		c-0.9,0.7-1.8,0.8-2.7,1.3c-0.6,0.2-1.2,0.4-1.8,0.6c-0.6,0.2-0.7,1.1-1.3,1.4c0.7-0.3,1.3-1.4,2.2-0.9c-0.2,0.2-0.3,0.5-0.5,0.7
    		c0.5-0.2,1-0.4,1.4-0.6c-0.9,0.6-2,1.3-1.4,2.6c-0.4-0.1-0.6,0.1-0.8,0.4c-0.2,1.1,2,0.1,0,0.8c1.1-0.1,2.6-2.3,3.8-1.4
    		c-0.2,0.2-0.5,0.4-0.7,0.6c0.8-0.3,1.5-0.6,2.3-0.7c-1.6,0.5-4.3,1.1-5.7,2.1c-0.4,0.3-1.2,3.4-1.1,3.9c2.8,0.2,4.5-5.3,7.3-4.2
    		c-0.6,0.1-1.1,0.5-1.4,1c1.3,0.2,3.8-0.8,4.1-2.2c0.2-0.6,0.2-0.9-0.4-0.8c-0.5,0.1-0.2-1.3-0.2-1.6c0.5,0.4,1,0.5,1.5,0.2
    		c-1.1,0.1-1.1,1.5-0.8,2.3c0.7,1.9,2.5,0.5,3.7,0.1c-0.4,0.2-2.4,1.9-0.6,1.3c-2.4,1.4,1,1.4,0.9,2.9c-0.8-0.3-2-0.5-2.6-1.3
    		c-0.7-0.9-0.4-2.1-1.9-1.5c2,0.4-1.2,0.4-1.5,0.4c0.5,0.3,1,0.5,1.5,0.8c-1.8,0.4,0.8,0.8,1.6,0.4c-0.7,0.9-1.9,0.7-2.9,0.6
    		c-0.6,0-3.5,0.5-1.7,1c-1.5,1.2-3.5,0.1-5.2,0.5c0.5,0.1,1,0.1,1.6,0.1c-0.5,0-1,0.1-1.5,0.4c2.3,1.2,6.3-0.4,8.2-1.7
    		c-0.5,0.4-0.9,0.7-1.4,1.1c0.7,0.1,2.4-0.7,2.5,0.5c-0.5-0.5-3.3-0.2-4,0c-1.5,0.5-3.5,1.1-5,0.7c0.8,0.2,0.9,0.7,0.3,1.2
    		c0.9,0.6,2.1-0.1,3-0.3c-0.8,0.2-1.4,0.7-2.2,0.9c-0.3,0.1-3.7,1-1.5,0.6c-1.6,0.3,0.2,1,0.7,1.6c0.3-1.5,3-1,4.1-1.1
    		c-1,0.4-3.1-0.3-3.5,1c0.7,0,1.4-0.1,2.2-0.1c-1,0.3-2,0.3-3,0.8c0.8-0.1,1.3,0.6,2,0.6c0.8,0,1.7-0.2,2.5-0.4
    		c-1.2,0.7-3.4-0.1-4.4,0.8c-0.7,0.6,0.8,0.9,1.3,0.6c-1.9,1,0.6,0.2,1.1,0.2c0.9-0.1,1.8-0.1,2.7-0.1c-0.7,0.2-2.4,0.6-2.8,1.2
    		c-0.5,0.8,1,1,1.5,0.6c-0.5,0.5-0.9,1-1.2,1.5c0.7-0.4,1.4-1.2,2.3-1.2c-0.8,0.5-1.7,1-2.3,1.7c1.1,0,2.1-0.6,3-1.2
    		c-1.1,1-2.9,1.5-3.7,2.8c-1,1.5,3.8,2.1,4.5,2.3c-0.6-0.1-1.2,0.2-1.5,0.6c0.5,0,1,0,1.5,0.1c-0.8,0.5-1.6-0.1-2.3,0.4
    		c1,0.2,0.7,0.4,0,0.8c0.7-0.4,1.5,0,2.2-0.2c0.8-0.2,1.1-1.1,2-1.3c-0.1,0.6,0.1,0.9,0.7,0.9c-0.8,0-1.6,0.2-2.4,0.6
    		c-0.6,0.3-2.6,2.2-0.9,1.2c-0.2,0.3-0.3,0.5-0.5,0.8c1.3-0.1,2-2.3,3.5-1.7c-0.8,0.2-2.4,0.8-2.6,1.8c0.6,0,1.1-0.1,1.6-0.4
    		c-0.6,0.3-1,0.7-1.4,1.2c0.7,0.1,1.5,0,2.2,0c-1.1,0.2-2.8-0.2-3.7,0.6c1.6,1.3,3.5-0.2,5.3-0.1c-1,0.2-1,0.3,0,0.3
    		c-1.4,0.1-3.5,0-4.5,1.3c1-0.7,2.5-0.3,3.6,0.1c-0.4,0.4-0.9,0.6-1.5,0.5c-0.2,1.1,1,0.7,1.6,0.5c-0.2,0.2-0.4,0.5-0.7,0.7
    		c1.2,0.1,1.4-1.9,3.2-1.5c-0.9,0-1.5,0.8-2.2,1.1c0.4,1,1.3,0.6,2,0.4c-0.1,1.4-2.5,0.8-3.3,1.2c1.2,0,2.4-0.1,3.7-0.1
    		c-1.2,0.5-2.6,0.7-3.7,1.4c1.2,0.1,4.3,0.5,5.3-0.5c-0.7,0.1-1.5,0.3-2.2-0.1c1.8-0.2,3.5-0.4,5.3-0.2c-0.3,0.2-0.5,0.4-0.8,0.6
    		c0.6,0.5,1.8,0.3,1.4-0.7c0.9,0.1,3.4-2,3.7-0.3c0.7-0.2,1.2-0.5,0-0.9c1.1,0.4,3.2-1,1.7-1.3c0.8,0.4,1.4-0.5,2.2-0.4
    		c-0.3,0.2-0.5,0.5-0.8,0.7c0.2,0.2,0.5,0.3,0.7,0.5c-0.7,0.9-2.4,0.5-2.6,1.8c1.4-0.7,3.5-0.6,4.2-2.3c0.1,1.2,0.7,0.6,1.5,0.1
    		c-1.6,2.2-4.8,1.3-6.8,2.9c0.5-0.2,1-0.3,1.5-0.3c-0.8,0.3-1.6,0.4-2.2,0.9c1,0.6,1.8-1.1,2.9-0.6c-0.2,0.2-0.5,0.4-0.8,0.6
    		c1.4,0.1,2.7-1.1,3.7-1.9c0.7,1.1-0.6,0.8-1.4,1.1c-0.5,0.2-3.2,1.3-1.4,1.3c-0.8,0.2-1.5,0.5-2.2,0.9c0.9,0.3,1.4-0.7,2.2-0.6
    		c-1.5,0.8,0,0-0.1,1.2c0.8-0.8,1.7-1.5,2.9-1.4c-0.9,0.2-1.6,0.7-2.2,1.4c0.8-0.2,1.5-0.7,2.3-0.9c-0.6,0.3-1,0.7-1.4,1.2
    		c1.8,0.1,2.7-1.7,4.3-1.9c-0.9,0.5-1.3,1.5-2,2.1c-0.7,0.7-2.2,1.1-2.4,2.2c1.6-0.8,3.4-2.4,5.1-2.8c-1.6,1.4-3.6,2-5.2,3.2
    		c0.4,0.6,1,0.6,1.4,0.1c0.2,1,0.9,0.7,1.2,0c-0.9,1.9,1.3-0.3,1.7-0.5c0.8-0.5,1.7-0.2,1.7-1.4c0.5,0.6,1,0.5,1.2-0.2
    		c-0.1,0.6-0.4,1.1-0.8,1.4c0.4,0.1,0.9,0.1,1.3,0.2c-0.6-0.2-1.1,0-1.4,0.4c0.4,0.4,0.9,0.5,1.5,0.5c-1.6,0.4-0.2,0.6,0.4,0.8
    		c0.8,0.2,1.4-0.1,1.3-1.1c0.7,0.2,1.3,0.6,2.1,0.6c-0.1-1.1-3-1.1-3.8-1.2c1.5-0.3,3,1.1,4.5,0.3c-0.1-0.6-0.5-0.9-1.1-0.7
    		c0.5-0.3,1-0.4,1.5-0.3c-0.3-0.8-1.9-0.9-0.5-1.2c-0.8,0.1-1.5,0.5-2.2,0.6c0.6-0.3,1.1-0.7,1.4-1.2c-1.2-0.4-2.6-0.3-3.8-1.1
    		c0.8,0.3,2.2,0.8,2.9-0.1c0.3,1.4,3.6,1.3,1.8,0.6c1,0.3,2.1,0.1,3-0.2c-0.5,0-1-0.1-1.5-0.2c0.8,0.1,1.7,0.1,2.2-0.6
    		c-1,0.4-1.9,0.5-2.9,0.1c0.9,0.1,2.3,0.5,2.5-0.7c-0.9-0.4-2-0.2-2.9-0.6c1.2,0.2,2.6,0.6,3.7,0.1c-1.2-0.4-2.6-0.4-3.7-1
    		c0.9,0.3,1.9,0.5,2.8,0.4c1-0.1,1.2-1,2.1-1.3c-1.6,0.3-3.6,0-5-0.6c1.7-0.1,3.6,0.8,5.2-0.1c-0.8-0.2-1.5-0.4-2.3-0.5
    		c0.9,0,2.4,0.6,3-0.4c-0.5,0.1-0.9,0.1-1.4,0.1c1.8-0.5-1-0.6-1.5-0.8c1.3,0.1,4.8,0.9,4.5-1.4c-0.9,0-2.1,0.3-2.9-0.5
    		c0.9,0.2,2.4,0.6,3.3,0.3c0.7-0.2,1-1,1.7-1.3c-1,0.6-2.1,0.1-2.9-0.5c0.7,0,4.8,0.8,3.5-1c0.7,0.1,1-0.2,0.7-0.9
    		c-0.6,0.1-1.2,0.3-1.7,0.4c0.7-0.5,2.6-1.5,2.1-2.6c-0.4-0.9-2.4-1.1-3.1-1.7c1.5,0.1,2.9,1.5,4.4,1c-0.5-0.2-1-0.5-1.4-0.8
    		c1.5,0.3,0.4-0.8-0.5-0.7c-0.9,0.1-2.1-0.8-2.7-1.4c0.8,0.4,1.7,0.5,2.6,0.6c0.3,0,0.8,0,1-0.3c0.1-0.2,0.1-0.5-0.1-0.7
    		c1,0,1.1-0.1,0.1-0.4c0.9,0.2,1.7,0,2.5,0.6c-0.8-0.1-1.7-0.1-1.9,0.8c0.6,0,1.2-0.1,1.8-0.3c2-0.7-0.5-0.4,1.2-0.7
    		c0.5-0.1,1.7-0.8,2.1-0.6c0.3-1.6-3.1-0.3-2.2-2.2c0.4,0.8,2,1.4,2.8,1.2c-0.1-0.6-0.5-1-1.1-1.2c0.5,0,2.2,1.3,2.4,0.4
    		c0.5-1.7-2-0.8-1.5-2.2c0.7,0.3,2.8,2.3,3.6,1.7c0.3-0.2-1.8-1.7-2-1.9c0.8,0.3,1.5,0.7,2.2,1.2c-0.4-0.4-0.9-0.8-1.4-1.1
    		c0.7,0.5,0.9,0.3,0.7-0.5c0.3,0.2,2.4,1.8,0.9,0.1c0.5,0.5,0.8,0.4,1.2-0.2c0.2-0.4,1.2,0.1,1.6,0c-0.3-1.4-3.1-2.1-4.2-1.9
    		c0.8-0.9,2,0,2.8,0.2c1.1,0.3,2.2-0.1,3.2,0.3c-0.2-0.7,0.6-1.6-0.5-1.9c1-0.2,2.1-1.6,0.4-1.7c-1.3-0.1-2.9,0.5-3.9-0.7
    		c1-1.1,2.5-0.2,3.7-0.7c1.2-0.4,2,0.3,3.1,0c0-0.6-0.4-0.9-0.9-0.9c1.5-1.6-0.7-2.3-1.4-3.5c0.8-0.1,0.5-0.4,0.8-0.9
    		c0.4-0.7,2-0.3,2.7-0.5c0.5-0.1,1,0.5,1.3,0.7c0.5,0.2,3.1-0.3,1.6-1c1,0.2,1.4-1.1,2.4-0.8c-0.2-0.2-0.4-0.5-0.6-0.7
    		c0.3-0.2,0.5-0.3,0.8-0.4c-0.6-0.2-1.1-0.4-1.7-0.5c0.4-1.3,1.1-0.3,1.9-0.4c0.7-0.1,0.8-1.2,1.5-1.2c0.6,0,0.4,0.9,0.9,1
    		c0.7,0.1,1.8-0.5,1.2-1.3c1.3,0.5,2.4,1,3.8,0.9c-0.2-0.2-0.4-0.5-0.7-0.7c0.9,0,1.5-0.6,1.3,0.6c0.9-0.9,1.9,0,2.7-1.7
    		c-0.6-0.3-1.9-0.7-1-1.6c0,1.5,1.8,0.8,2.4,0.2c0.4-0.4-0.6-0.8,0.3-0.9c0.8-0.1,0.8-1.1,1.6-1.4c-0.6-0.1-1.1-0.3-1.5-0.6
    		c1.8-0.1,3.5,0.5,5.3,0.5c-1.4,0.1-4.4,1.5-4.8,3.1c0.8-0.6,2.2,0.5,3.1,0.6c1.2,0.1,0.2-1.7,1.6-1.6c-0.5,0.4-0.7,0.9-0.3,1.4
    		c0.7-1.1,3-0.2,3.2-1.8c0.6,0.2,0.9,0.7,0.6,1.3c0.8-0.5,1.9-0.1,2.7-0.8c1.1-0.9,1.3-0.6,2.5-0.8c-0.4-0.8,0.3-1.6-0.8-2
    		c0.9-0.7,0.8,0.5,1.7,0.8c0.7,0.3,1.5,0.4,2.2,0.4c-0.6-0.5-0.4-1.1,0.2-1.4c-0.4,2.1,3.9-0.8,4.5-0.9c-0.5-0.6-0.2-1.1,0.4-1.4
    		c-0.2,1.6,1.4-0.4,0.3,1.2c0.6-0.3,1.8-1.9,2.6-1.3c-0.2-0.7,0-1.8,1-1.4c-1.2-1,2.2-0.6,2.4-1.6c0.1-0.8,1.2-0.8,0.7-1.6
    		c0.9,0.4,1.5-0.5,1.4-1.3c1,0.3,1.2-0.6,1.9-1c0.8-0.5,2.6-0.8,3.5-1.2c0.7-0.2,2.3,0.3,2.6-0.4c0.3-0.5,0.1-1.4,1-1.2
    		c-0.1-1-1.1-0.9-1.8-1.3c0.5,0.1,1,0.1,1.5-0.2c-0.3-0.4-1.4-0.7-1.5-1.2c-0.2-0.8,0.8-0.7,0.9-1.5c0.2,0.7,0.2,1.9,1.2,1.2
    		c0.2,1.8,1.2,1.3,2.5,1.7c-1.5,0.1-0.3,1.1,0.6,1.1c1.8,0,3.6,0.2,5.3-0.2c-0.3-0.5-0.7-0.9-1.2-1.2c0.8,0,1.1,0.7,1.7,1
    		c0.7,0.3,1.3,0.1,2,0.1c-0.1-0.6-0.5-1-1.2-1c0.7-0.1,1.5,0.3,1.8-0.6c0.2-0.8,1.3,0.4,1.7,0.5c0.7,0.3,3.5,0.6,3.2-0.9
    		c1.2,0.7,2.6,0.3,3.8-0.1c0.6-0.2,1.1-0.7,1.8-0.4c0.6,0.3,1.2-0.1,1.2-0.8c0.6,0.8,2.5,0.3,3.2,0.1c1.2-0.4,2.2-0.1,3.5-0.5
    		c0.9-0.3,1.8,0,2.7-0.2c0.7-0.2,1-0.9,1.7-0.9c1.5,0,2.6-1.3,4-1.6c-1.8,0.1,0.7-0.5,1.4-0.2c-0.3-1.3,0.1-0.4,1-0.6
    		c0.6-0.1,1.3-1-0.1-0.8c0.8-0.9,2,0.1,3-0.2c-1.8-0.3,0.9,0.2-0.3-1.2c0.7,0.2,1.5,0.2,2.2,0.2c0.6-0.1,2.7-0.9,0.9-1.2
    		c0.8,0.1,2.2,0.6,2.9,0.2c1-0.6,0.8-1.5-0.5-1.5c0.6,0.1,3.3,0.5,3-0.8c0.7,0.3,1.4,0.9,2.2,0.4c-0.4-0.4-0.9-0.5-1.4-0.4
    		c1.6-0.1,4.8-0.5,6.1-1.4c-0.7-0.8-2.3-0.4-3.2-0.3c-1.6,0.2-3.5,0.1-5-0.3c-1.3-0.4-2.8-0.1-4.1-0.7c-1.5-0.8-2.7-0.7-4.3-0.3
    		c0.2-0.3,0.4-0.5,0.7-0.8c-1.7-0.3-3.3,0.6-4.9,0.9c-2.1,0.4-4.3-0.5-6.1,1.1c-1.4,1.2-1.8,0.2-3.2,0.5c-1.2,0.2-3.6,0.2-4.6-0.4
    		c1.6,0.2,4.7,0.1,6.2-0.6c0.9-0.4,1.8-0.9,2.8-1c1.3-0.1,2.6,0.3,3.8-0.6c-1.7-1.7-6-0.2-8,0c-0.8,0.1-1.5,0.2-2.2,0.1
    		c-0.7-0.1-0.3-1-1.1-0.7c-0.9,0.3-3.3,0.8-4.1,0.2c1.4,0.1,4,0,5.2-1c1.1-1,3.8-1.7,0.9-2.8c1.1,0.3,2.7,0.4,3.8,0.1
    		c-0.5-0.2-0.9-0.6-1-1.2c0.8-0.2,1.2,0.5,1.7,1c0.7,0.7,2.1,0.5,3,0.4c1.8,0,6.7-0.4,7.5-2.1c0.2-0.5,1.7,0.6,1.5-0.7
    		c-0.1-0.7-1-1-1.6-1.2c-0.9-0.2-1.9,0.1-2.9,0.1c-1.6,0-3.2-0.3-4.8-0.3c2-0.5-1.1-0.5-1.6-0.5c-1.5-0.1-3,0.7-4.5,0.2
    		c1.6-0.2,4.9,0.4,6.1-1.1c-0.5-0.1-4.2-1.6-2.2-1.3c-1-0.3-2.1-0.3-3.1-0.2c0.5-0.5,0.4-1.1-0.3-1.4c1.6,0.8,3.1,1,4.6,1.9
    		c1.1,0.6,2.6,2.5,4,2.3c0.6-0.1,1-0.5,1.6-0.5c0.8-0.1,1.5,0.4,2.3,0.4c2.1,0.2,3.4,1.8,5.6,1.6c1.3-0.1,2.8,1.7,2.6,2.9
    		c-0.4,2.2,1.4,2.6,3.2,3.2c1,0.3,3.5,0.9,4.4,0.1c0.6-0.5,0.2-3.1,1.6-2.2c-1.9,3,1.5,1.5,1.9,2.4c0.6,1.6,3.6-1.4,1.5-1.1
    		c0.1-0.6,0.6-1,1.2-0.9c-0.5-0.3-1-0.4-1.5-0.6c0.9,0.4,1.2-0.8,1.9-1.1c-0.5-0.2-1.1-0.2-1.6-0.1c0.2-0.2,0.5-0.5,0.7-0.7
    		c-0.8,0.1-1.5,0.2-2.2,0.1c0.8-0.2,2.2-1.2,2.9-0.7c0-0.6-0.3-0.9-0.9-0.8c0.5-0.1,1-0.2,1.5-0.4c-1.1-0.2-1.1-0.4,0-0.6
    		c-1.9-0.5-3-0.1-4.5,1.1c0.3-2.1,1.8-2.8,3.8-3.2c-1.4,0-2.6,0-3.8,0.9c-0.1-0.6,0.3-1,0.9-1.1c-1.1-0.4-2.2,0.3-3.1,0.6
    		c0.3-1.1,2.4-1.6,3.4-1.7c-1-0.9-2.4-0.3-3.2-1c-0.9-0.8-2.7-0.4-2.6-1.6c-1.3,0.5-3.2-0.7-4.4-1.2c-2-0.7-3.2,1.1-5,1.4
    		c0.5-0.3,0.9-0.8,1.1-1.4c-0.8,0-1.6,0.2-2.3-0.1c0.7,0,6.8,0.3,6.3-1.3c-0.9-3-7.1-0.7-8.7,0c0.2-0.3,0.5-0.5,0.7-0.8
    		c-0.8,0.1-1.6,0.1-2.4,0.1c0.5-0.1,1.1-0.2,1.6-0.4c-1.5-0.5-3.2,0.2-4.6-0.5c1.2,0.1,2.4-0.1,3.6-0.1c1.1,0,2,0.7,3.1,0.6
    		c1.1-0.1,2.1-0.7,3.2-0.8c1.1-0.1,2.3,0,3.1-1c-0.9-0.5-2-0.2-3-0.5c-1.6-0.4-2.9-0.7-4.5-0.3c-1.1,0.3-2.6-0.1-3.6,0.4
    		c-0.7,0.4-1.2,1.2-2.1,1.1c0.9-0.3,0.9-1.5-0.2-1.4c1.1,0,2.2-0.5,3.2-0.2c1.2,0.3,2,0.1,2.9-0.8c-1.6,0.1-3-0.7-4.5-1.2
    		c1.2-0.6,2.1,0.8,3.2,0.9c1.5,0.1,2.9,0.7,4.4,0.6c1.3-0.1,2.1-0.7,3.2-1.3c1.2-0.6,2.5-0.4,3.8-0.6c-1.2-0.8-3.2-1.6-4.2-2.7
    		c1.2-0.6,2.7,1.3,3.5,1.9c1.3,1,2.3-0.2,3.3-0.9c1.3-0.9,3.2-0.2,4.6,0.2c2.1,0.6,3.7,0.6,5.8,0.6c-2,0.6-3.6,0.3-5.5-0.3
    		c-1.1-0.3-3.1-0.9-3.7,0.5c4.2,1.8,7.3,3.3,11.7,1.5c1.8-0.7,3.4-0.2,5.3-0.3c1.6-0.1,0.8-0.2,1.4-1.3c0.4-0.7,1.3-1,1.5-1.8
    		c-2.3-0.1-4.6-1.2-6.8-1.2c-2.5,0,0.3,2.4-1.6,2.8c0.3-2.3-0.3-2.2-2.5-2.9c0.8-0.2,1.8,0.1,2.2-0.9c-0.4-0.4-0.9-0.7-1.5-0.7
    		c1.2,0.2,2.7,0,3.8-0.6c-0.2-0.6-0.7-1-1.2-1.2c0.9-0.1,1.5,0.9,2.5,0.9c2,0,3.9,0.1,5.9,0.1c0.6,0,1,0.9,1.5,1.2
    		c0.6,0.3,1.2,0.2,1.9,0.2c1.8,0.2,3-0.9,4.5-1.7c-0.8,0.1-1.8-0.4-1.5-1.3c-1.5,0.1-2.8,0.8-4.3,0.1c-1.5-0.7-3.2,0.4-4.7,0.1
    		c0.8,0,1.6-0.1,2.3-0.6c-0.9-0.8,0.1-1.6,0.6-2.2c-1.8-0.2-3.2,0.2-4.9,0.6c-1.7,0.4-2.2-1-3.6-1.2c1.5,0.1,2.1,1.3,3.8,0.8
    		c1.8-0.6,3.7-0.2,5.5-0.7c-2-2-5-1.6-7.5-2.3c1,0.1,2.1-0.1,3.1,0.2c-0.8-0.9-1.9-1-3-1.2c1.9-0.7,3.8,1,5.5,1.7
    		c0.8,0.4,5.8-0.1,4.8,1.3c1,0.3,2.3,0.4,3.1-0.3c0.8-0.7,1.3-2.3,0.1-3c0.2-0.1,0.5-0.3,0.7-0.4c-2.8-2.8-8.1-1.5-11.6-1.5
    		c1.7-0.2,3.5,0,5.2,0c1.7,0,3.3-0.7,5-0.6c1.8,0,1-1.4-0.4-1.3c-1.7,0.1-3.4-0.7-5.1-0.3c1.1,0.2,2,0.8,3,1.1
    		c-1.1,0.1-1.9-0.7-2.9-0.9c-1.1-0.2-2.1,0.5-3.2,0.2c0.2-0.3,0.4-0.5,0.7-0.8c-1-0.8-2.7-0.5-3.9-0.4c1.1-0.7,2.6,0.3,3.8-0.4
    		c-0.5,0-1.1,0-1.6,0.1c0.5-0.1,1-0.3,1.5-0.4c-0.5-0.3-1-0.3-1.5-0.1c1.5-0.2-0.8-0.9-1.5,0.1c-0.2-0.5-0.6-1-1.2-1
    		c0.3-0.3,2-1.2,2.4-1.3c1-0.1,1.3,1,2.1,1.2c1.4,0.4,3.3-0.6,4.6-1c-1.2-0.6-2.6-0.2-3.8-0.4c1.7-0.5,3.7,0.7,5.3-0.2
    		c-0.5-0.3-1-0.3-1.5-0.2c2.5-0.8,5.3-0.1,7.8,0.4c1.6,0.3,4.7,1.3,6,0.1c2.7-2.3-1.8-3.6-3.6-2.5c-0.9,0.6-1.7,0-2.2-0.6
    		c-1-0.9-3.9-0.4-5.2-0.4c1-0.3,2.1,0.1,3.1-0.1c-1.5-0.4-3.1-0.4-4.7-0.6c1,0,2,0.3,3,0.1c-1.9-0.4,0.6-0.3,0.9-0.3
    		c-0.8-0.6-2.1-0.8-3.1-0.6c3.1-1,6.1,0.5,9.2,0.3c-0.4-1.8-4.5-1.6-5.8-1.8c-1.2-0.2-2.2-0.8-3.5-0.3c-1.5,0.5-2.3,2.2-3.7,2.5
    		c2.1-0.7-1.2-0.9-1.6-0.9c1.2-1.2,2.7-2,4.2-2.7c-0.5,0-1-0.1-1.5-0.1c0.8-0.1,2.3,0.1,2.4-1.1c0.1-0.8,1.6-1.5,2.2-1.7
    		c-0.4-0.4-0.9-0.5-1.5-0.3c0.4-0.2,3.3-1,1.3-1.2c1-0.2,2.2-0.7,3.2-0.5c1,0.2,2.4,0.5,3-0.5c0.6-1,1.2-0.6,2.2-0.9
    		c0.6-0.2,1-0.8,1.6-0.9c0.7-0.2,0.8,0.6,1.5-0.2c-1.6-0.5-2.5,0.6-3.8,0.5c0.6-0.3,1-0.7,1.4-1.2c0.3-0.3-0.4-0.6,0.1-0.8
    		c1.4-0.7,1.7-1.6,3.5-1.5c3.2,0.1,5.5-0.3,8.5-1.5c-2.1-1.6-5.3-0.2-7.7-0.2c-1.2,0-2.5,0-3.7,0.2c-0.6,0.1-3.4,1.6-1.6,1.2
    		c-1.5,0.6-1.8-0.2-3.2-0.1c0.7-0.6,2.9-1.1,1.4-1.4c1.6-0.2,3.1-0.9,4.7-1c2.4-0.1,4.8,0.4,7.2,0.4c3.6,0,6.8-0.3,10.1-1.7
    		c-4.2-1-8.6,0.1-12.9-0.4c-1.8-0.2-3.6-0.5-5.4-0.4c-2,0.2-4,0.7-6.1,0.4c3.3-0.4,6.5-0.7,9.8-0.4c1.3,0.1,2.6,0.2,3.9,0
    		c1-0.2,1.9-0.9,2.9-0.9c2.7,0,5.3,0.7,8,0.3c1.3-0.2,2.5-0.6,3.8-0.6c1.1,0,2.6,0.2,3.4-0.7c-0.6,0-1.1-0.2-1.4-0.6
    		c1.5-0.6,3.1,0,4.7-0.3c1.2-0.2,2.4-0.7,3.6-0.9c2.1-0.3,5.1-0.2,6.8-1.7c-3.8-0.3-7.5-1.2-11.3-1.5c-4.6-0.4-9.2,0-13.8,0.4
    		c0.2,0.2,0.3,0.5,0.5,0.7c-0.8,0-3.4,0.6-1.4,0.5c-1.5,0.8-3.8,1-5.5,0.7c-0.9-0.1-1.8-0.5-2.8-0.5c-1.5,0.1-3,0.7-4.5,0.8
    		c0.3-0.8,1.2-1.1,1.9-1.3c-4.3,0.6-8.4,1.6-12.5,2.8c-1.9,0.5-4.2,0.8-5.9,1.4c-1.9,0.7-3.4,1.4-5.4,1.9c0.4-0.2,2.8-0.9,0.7-0.8
    		c2.2-0.5,4.2-1.5,6.2-2.3c1.7-0.7,3.4-1,5-1.7c0.9-0.4,2.3-1,2.8-2c0.8-1.5-2.4-1.6-3.1-1.6c-1.6,0.1-3.3,0-4.8,0.3
    		c-1.8,0.3-2.4,1.8-4.4,1.8c-2.4,0-4.7,0.6-7,0.8c-2.3,0.2-5.1,1.2-7.4,0.8c0.5,0,1,0,1.5-0.1c-0.5-0.2-1-0.3-1.6-0.4
    		c1.8-1,4.5-0.7,6.5-0.9c1.6-0.2,4.6,0.2,5.5-1.5c-1.6-1.3-5.5-0.5-7.4-0.5c-3.3-0.1-6.8-0.2-10.1,0.1c-6.4,0.6-12.8,0.9-19.1,1.8
    		c0.6-1.7,4.4-1.6,5.8-1.6c3.1,0,6.2,0.1,9.3-0.7c-1.7-1.3-4.5-0.6-6.5-0.6c3.7-0.4,7.5,0.5,11.2,0.2c4.4-0.3,8.8,0,13.1,0
    		c4.2,0,8.4-0.7,12.7-0.8c2.1,0,4.1,0.1,6.1-0.5c1.4-0.4,3.3-0.4,4.6-1.1c-3.5-1.7-8-1.2-11.7-0.8c0.3-0.2,0.6-0.4,0.9-0.6
    		c-3-1-6.2,0.7-9.3,0.6c1.9-0.1,3.7-0.6,5.5-1.2c-3.7-1.8-8.1-0.4-12-0.4c-4.8,0-9.5-0.5-14.3,0c-4.2,0.4-8.1,0.5-12.3,0.6
    		c-1,0-1.8,0-2.6,0.6c-0.2,0.2-1.9-0.4-2.2-0.4c-2.1,0-4.1,0.7-6.1,0.7c0.4-0.1,2.7-0.5,0.9-0.6c4.5-0.6,9-0.6,13.5-0.6
    		c-1-0.6-2.1-0.4-3.2-0.6c2.7,0,5.5,0.6,8.2,0.3c2.8-0.3,5.5-0.4,8.3-0.4c6.1,0,12.3,0.3,18.4-0.5c-3.6-1.7-7.8-0.8-11.6-0.9
    		c2.2,0.3-1.9,0.1-2.3,0c0.5-0.1,1.1-0.1,1.6-0.1c-0.9-0.4-1.8,0.1-2.7,0c-1-0.1-1.9-0.5-2.9-0.5c-2.1,0-4.1,0.1-6.2,0.1
    		c-4.1-0.1-8.3-0.5-12.4-0.1c0.3,0.1,0.6,0.3,0.9,0.4c-1,0.4-1.5-0.4-2.3-0.5c-0.7-0.1-2.3,0.2-3.1,0.2c-2.7,0.1-5.7-0.6-8.3,0.3
    		c0.6,0.1,1.1,0.3,1.5,0.6c-1.8,0.2-3.4-0.8-5.2-0.6c-1.8,0.2-3.7,0.6-5.5,0.3c-0.5,1.7,6.4,1.5,7.3,1.4c-2.7,0.7-5.4,0.2-8.1,0.4
    		c1.9,0.5,3.9,0,5.9,0.2c-2.7,0-5.5-0.5-8.1,0.2c0.8,0.1,1.4,1,2.2,0.8c-0.7,0.6-1.9,0-2.6-0.3c-1.1-0.5-2.2-0.4-3.4-0.4
    		c-2.7,0.1-5.4-1.2-8.1-0.6c2.2-0.3-1.3-0.9-1.8-1c-1.3-0.1-2.9-0.2-4.1,0.3c0.6,0,1.2,0,1.8,0.1c-1.4,0.2-2.9,0-4.3,0
    		c1.3,0.5,2.8,0.3,4.1,0.6c-1.9-0.1-3.9-0.1-5.8-0.3c-2-0.2-4,0.4-6,0.1c1.8,0.6,3.7,0.7,5.5,1.1c-1.7-0.2-3.3-0.2-4.9-0.4
    		c-2-0.2-4.1-0.9-6,0c0.8,0,1.7,0.1,2.4,0.4c-1.1-0.1-2.2-0.4-3.3-0.1c1.2,0.6,2.7,0.5,4,0.5c0.8,0,1.7,0,2.5,0c0.3,0,2.9,0.1,1-0.3
    		c4.4,0.2,8.8,0.1,13.2,0.3c1.8,0.1,3.5,0.3,5.3,0.4c0.6,0,5.2,0.5,3.1,1.2c1.5,0.2,0,1-0.3,0.7c-0.6-0.6-1.4-0.9-2.2-1.1
    		c-1.5-0.5-3.8-1.1-5.4-0.4c0.2,0.1,0.5,0.3,0.7,0.4c-0.6,1.8-1.3-0.4-1.9-0.6c-1.5-0.4-3.3-0.3-4.8-0.3c-1.9,0.1-3.8,0.1-5.7,0.1
    		c-1.7,0-3.8-0.5-5.4,0.2c1.2,0.4,2.4,0.7,3.6,0.9c0.6,0.1,1.2,0.3,1.9,0.4c0.4,0.1,3.1,0.1,1,0.6c2.1,0.4,4.3,0.2,6.4,0.6
    		c-2.2,0.1-4.4-0.1-6.6-0.4c-0.9-0.1-1.8-0.3-2.7-0.1c-0.5,0.1-2.6,1-0.8,1.1c-0.8,0.2-1.7,0.1-2.4,0.6c0.5,0,1,0.1,1.5,0.1
    		c-1.8,0.6,0.3,0.6,0.8,0.6c-2.7,0.6-5.1,0.1-7.6-0.8c-2.1-0.8-4.3-0.5-6.4-1.2c-1.9-0.7-4.4-1-6.5-1.4c-2.7-0.5-5.6-0.4-8.3-0.1
    		c0.7,0.5,0.4,1.4,1,1.8c1.1,0.7,2.8,0.9,4,1.1c-2.3,0.7-4.8,0.4-7.1-0.1c1.4,0.8,3.2,0.8,4.6,1.7c-1.7,0.4-3.1-0.6-4.6-0.9
    		c-1.9-0.5-4.1-0.6-6-0.9c-0.6-0.1-1.6-0.4-2.2-0.2c-0.8,0.2-1.3,1-2.1,1.2c-1.2,0.3-3.1,1.5-4.3,1.3c0.7-1.2,2.8-1.4,3.2-2.8
    		c0.8-2.2-6.8-1.2-7.7-1.1c2.2-0.1-2.4,0.4-3,0.4c-1.5,0.2-2.9,0-4.4,0.1c-4,0.3-8.1,0.2-12.1,1c0.8,0.3,1.6,0.1,2.4,0.4
    		c0.7,0.2,1.2,0.9,1.8,1.2c0.7,0.4,1.7,0.3,2.4,0.5c1.2,0.3,2.3,0.9,3.4,1.2c-2.2,0.1-4.3-1.1-6.5-1.2c-1,0-1.3-0.2-1.8-1
    		c-0.5-0.8-1.9-0.5-2.8-0.6c-2.1-0.1-4.1-0.4-6.2-0.2c-1.3,0.1-5,1.9-1.8,2.3c-1,0.5-2.2,0.3-3,1.1c0.2,0.2,0.4,0.5,0.6,0.7
    		c-2.3,0.8-5.1-0.1-7.4-0.2c-1.2-0.1-2.7-0.3-3.8,0.4c-0.8,0.5,0.2,1.5,0.4,2.1c-0.7-0.5-1-1.3-1.6-1.8c-0.8-0.8-2.8,0.2-3.8,0.3
    		c-2.9,0.3-5.6,1.4-8.4,1.8c-2.4,0.3-4.7,0.8-7.1,1.2c-1.3,0.2-3.9,0.5-4.5,1.9c1.1-0.1,2.1,0.7,3.3,0.6c1.3-0.1,2.5,0.2,3.8,0.3
    		c3.1,0.1,6.4-1.1,9.5-0.6c-2.2-0.1-5.3-0.1-7,1.4c-0.8,0.6-0.7,1.6-1.5,2.3c-1,0.8-2.5,1.1-3.8,1.4c-3.1,0.7-5.4,1.3-8.6,1
    		c-1.8-0.2-3.3,0.3-5,0.5c-2.3,0.3-4.7,0.1-6.9,0.8c2.1,0.3-0.7,0.6-1,0.6c-1.2,0.1-2.5,0.1-3.7,0.3c-1,0.2-2,0.2-3,0.3
    		c-0.9,0.1-1.7,0.8-2.5,0.9c-1.7,0.1-3.2-0.1-5,0.2c-1.2,0.2-2.3,0.2-3.5,0.3c-1.1,0.1-1.8,1.1-2.9,1.1c2,0.4-1.6,1.3-2.2,1.1
    		c1.2,0.2,2,1.1,3.2,1.6c1.1,0.4,2.6,0.4,3.8,0.3c-0.5,0.2-1,0.4-1.5,0.6c2.5,0.6,4.9-0.6,7.4-0.4c-1.3,0.4-2.6,0.7-4,0.9
    		c1.5,0.7,3.8,0.4,5.3-0.1c-1.2,0.9-3,0.7-4.4,1c2,0.7,4.2,0.8,6.2,0.7c1-0.1,1.2-0.2,1.7-0.9c0.5-0.7,1.6,0.6,2.2,0.7
    		c2.1,0.3,4-0.3,5.9-0.9c1.3-0.4,3.8-0.9,4.3,0.9c-0.7,0.4-2,0.4-2.7,0.4c-0.9,0-0.6,1.1,0.3,1c-1.7,0.4-4-0.8-5.9-0.7
    		c-2.5,0.1-5.1,0.2-7.5,0.6c1.4,1.1,3.7,0.7,5.4,0.6c2.7-0.1,5.3,0.6,7.9,0c-2.7,1.2-26.8-1.6-26.3,1.6c0.8-0.2,1.5,0.2,2.3,0.1
    		c-0.5,0.1-1.1,0.2-1.6,0.2c2.3,2.4,5-1.5,7.6-0.6C978.4,62,976.7,62.5,976.1,63.4"
          />
          <path
            id="path4232"
            class="countryIsland"
            d="M1017.8,161.9c-0.2-0.1-0.5-0.1-0.7,0.1C1017.3,162,1017.5,161.9,1017.8,161.9"
          />
          <path
            id="path4234"
            class="countryIsland"
            d="M1017.5,161.1c-0.2,0.3-0.5,0.2-0.4,0.7C1017.2,161.6,1017.3,161.4,1017.5,161.1"
          />
          <path
            id="path4244"
            class="countryIsland"
            d="M1020.6,168.5c-0.3,0.1-0.5,0.2-0.7,0.4C1020.2,168.8,1020.4,168.7,1020.6,168.5"
          />
          <path
            id="path4246"
            class="countryIsland"
            d="M1020.9,170.9c-0.2,0.1-0.5,0.2-0.7,0.3C1020.4,171.1,1020.7,171,1020.9,170.9"
          />
          <path
            id="path4250"
            class="countryIsland"
            d="M1022,172.9c-0.2,0-0.5,0.1-0.7,0.1C1021.5,172.9,1021.8,172.9,1022,172.9"
          />
          <path
            id="path4254"
            class="countryIsland"
            d="M1022.8,175.9c-0.3,0-0.5,0-0.8,0.1C1022.3,176,1022.5,175.9,1022.8,175.9"
          />
          <path
            id="path4350"
            class="countryIsland"
            d="M1042.9,182.6c-0.2-0.2-1.3,0.8-1.4,0.9C1042.1,183.4,1042.6,183.1,1042.9,182.6"
          />
          <path
            id="path4360"
            class="countryIsland"
            d="M1046.6,184.7c0,0.3,0.1,0.5,0.3,0.7C1046.8,185.2,1046.7,185,1046.6,184.7"
          />
          <path
            id="path4362"
            class="countryIsland"
            d="M1050.5,186c0,0.2,0,0.4,0,0.6c0.3-0.2,0.6-0.3,0.8-0.6C1051,185.8,1050.8,185.8,1050.5,186"
          />
          <path
            id="path4366"
            class="countryIsland"
            d="M1056.6,179.1c0.5,0.3,1,0.2,1.5-0.1C1057.6,178.9,1057.1,178.9,1056.6,179.1"
          />
          <path
            id="path4218"
            class="countryIsland"
            d="M1012.3,142.8c0.6,0.3,1.4,0.2,1.5-0.6C1013.2,142.1,1012.7,142.4,1012.3,142.8"
          />
          <path
            id="path4220"
            class="countryIsland"
            d="M1013.2,143.9c0.2-0.6,0-1-0.7-0.4C1012.7,143.7,1013,143.8,1013.2,143.9"
          />
          <path
            id="path4224"
            class="countryIsland"
            d="M1016,145.9c-0.3-0.1-0.6,0.1-0.8,0.4C1015.4,146.1,1015.7,146,1016,145.9"
          />
          <path
            id="path4230"
            class="countryIsland"
            d="M1018.2,152.3c-0.5-0.4-1.2,0-1.4,0.4C1017.3,152.8,1017.8,152.6,1018.2,152.3"
          />
          <path
            id="path4236"
            class="countryIsland"
            d="M1020.4,150.5c-1-0.4-2.2,0.9-3,1.3C1018.7,151.9,1019.6,151.5,1020.4,150.5"
          />
          <path
            id="path4240"
            class="countryIsland"
            d="M1022.1,150.8c-1.4-1.2-2.8,1.8-3.7,2.4C1019.7,152.7,1020.9,151.6,1022.1,150.8"
          />
          <path
            id="path4258"
            class="countryIsland"
            d="M1024.3,175.3c-0.5-0.1-1-0.1-1.4,0.2C1023.4,175.6,1023.9,175.6,1024.3,175.3"
          />
          <path
            id="path4264"
            class="countryIsland"
            d="M1027,179.7c-0.9-0.4-1.8-0.2-2.7,0.1C1024.8,181,1026.5,180.8,1027,179.7"
          />
          <path
            id="path4266"
            class="countryIsland"
            d="M1025.4,177.4c-0.3,0.1-0.5,0.2-0.8,0.3c0.5-0.1,1-0.1,1.5-0.1
    		C1025.9,177.5,1025.6,177.4,1025.4,177.4"
          />
          <path
            id="path4326"
            class="countryIsland"
            d="M1036.8,179c0-0.1,0-0.3,0.1-0.4c-1.2,0.1-3,0.5-3.8,1.5C1034.2,179.3,1035.6,179.5,1036.8,179"
          />
          <path
            id="path4328"
            class="countryIsland"
            d="M1036,179.9c-0.6,0.1-1.1,0.3-1.5,0.6C1035,180.4,1035.5,180.2,1036,179.9"
          />
          <path
            id="path4334"
            class="countryIsland"
            d="M1037.8,180.4c-0.6,0-1.1,0.1-1.6,0.5C1036.8,180.9,1037.3,180.7,1037.8,180.4"
          />
          <path
            id="path4340"
            class="countryIsland"
            d="M1041.5,183c-1.3-0.3-1.5,0.6-2,1.5C1040.3,184.2,1041.1,183.8,1041.5,183"
          />
          <path
            id="path4352"
            class="countryIsland"
            d="M1042.5,182.6c-0.2,0.1-0.5,0.2-0.7,0.4C1042,182.9,1042.3,182.7,1042.5,182.6"
          />
          <path
            id="path4354"
            class="countryIsland"
            d="M1044.3,186c0.7,0.1,1.4,0,1.9-0.4C1045.7,184.6,1044.6,185.1,1044.3,186"
          />
          <path
            id="path4356"
            class="countryIsland"
            d="M1045.7,186c-0.2,0.2-0.5,0.4-0.7,0.6c0.6,0.5,1.6-0.3,2.4-0.6
    		C1046.8,185.8,1046.2,185.8,1045.7,186"
          />
          <path
            id="path4358"
            class="countryIsland"
            d="M1047.3,187.7c0.6-0.1,1.2-0.3,1.6-0.8c-1.2-0.8-2-0.5-2.8,0.6c0.6,0,1.1-0.2,1.5-0.6
    		C1047.5,187.2,1047.4,187.4,1047.3,187.7"
          />
          <path
            id="path4364"
            class="countryIsland"
            d="M1051.4,186c-0.2,0.2-0.3,0.5-0.4,0.7C1051.3,186.5,1051.4,186.3,1051.4,186"
          />
          <path
            id="path4368"
            class="countryIsland"
            d="M1066,166.5c0.6-0.7,0.5-0.9-0.4-0.7C1065.8,166,1065.9,166.3,1066,166.5"
          />
          <path
            id="path4370"
            class="countryIsland"
            d="M1070.8,163.5c0.4-0.3,0.8-0.7,1-1.2C1071.3,162.5,1070.8,162.9,1070.8,163.5"
          />
          <path
            id="path4372"
            class="countryIsland"
            d="M1073.7,161.6c0.1-0.6-0.1-0.6-0.7-0.1C1073.2,161.5,1073.4,161.5,1073.7,161.6"
          />
          <path
            id="path4534"
            class="countryIsland"
            d="M1047.8,186c0.3-0.1,0.5-0.2,0.8-0.3c-0.6,0-1.1-0.1-1.5-0.5c0.7-0.8,5.2-0.5,5.3,0.8
    		c-0.8,0-1.4-0.3-2.1-0.6C1050.7,187.1,1048.7,186.4,1047.8,186"
          />
          <path
            id="path4536"
            class="countryIsland"
            d="M1065.3,164.9c0.4-1.2,1.8-0.9,2.7-0.6C1067.2,164.5,1066.3,164.9,1065.3,164.9"
          />
          <path
            id="path4538"
            class="countryIsland"
            d="M1071,159.1c0.3-0.8,1.2-1,1.6-0.1C1072,158.6,1071.4,158.6,1071,159.1"
          />
          <path
            id="path4544"
            class="countryIsland"
            d="M1221.1,66.1c0.7,0,1.4,0.4,2,0.7C1222.4,66.6,1221.7,66.4,1221.1,66.1"
          />
        </g>
        <path
          id="ne"
          class="countryGroup country"
          d="M1302.9,573.8c-1.1-1.3,0.2-3.5,0.1-5c1.3,0.4,2.8,0.3,4.2,0.3c1.6,0,2-0.8,2.9-1.9
    	c1.1-1.3,2.4-1,4-1.1c2-0.2,4.1-0.3,6.1-0.4c1,0,1.9,0,2.9,0.1c1.9,0.2,0.1-0.6,1-0.8c1.4-0.4,3.3,1.6,3.9-0.1
    	c0.5-1.4,2.2-1.6,2.8-2.9c0.6-1.3,0.6-2.7,1.3-4c1.5-2.7,1.2-5.7,1.3-8.8c0.2-4.4,0.2-8.8,0.1-13.2c0-2.7,0.2-3.4,2.9-4.1
    	c2.4-0.6,4.9-0.7,7.3-1.3c2.8-0.7,4.7-3.5,6.7-5.4c2.9-2.8,5.7-5.9,8.9-8.4c6.5-5.1,13.8-9.7,20.8-14.1c3.3-2.1,6.4-4.4,9.7-6.5
    	c3.1-2,7.2,0,10.5,0.9c2.9,0.8,5.2,2.6,7.1,4.8c0.8,0.9,1.1,1,2.1,0.3c1.5-1,3-1.8,4.6-2.7c0.7,3.8,1.3,7.6,1.6,11.4
    	c0.1,1.5,1,2.6,1.8,3.8c0.4,0.7,0.9,1.5,1.2,2.3c0.3,0.7-0.5,1.3,0.1,2.1c0.8,0.9,2.2,1.7,2.6,3c0.4,1.6-1.3,3.2-1.5,4.7
    	c-0.4,4.7-1.2,9.3-1.2,14c0,2-0.2,4-0.3,6c0,1.6,0.2,3.6-0.4,5.2c-0.5,1.3-1.8,2.5-2.7,3.6c-1.4,1.8-2.9,3.4-4.3,5.2
    	c-2.3,2.9-4.3,6.2-5.6,9.7c-0.3,0.8-0.7,1.7-1.3,2.4c-0.3,0.3-0.8,0.2-1.1,0.6c-0.7,1.3,0.9,4.9,1.1,6.3c-0.7,0-1.6-0.2-2.2,0.2
    	c-0.7,0.4-0.6,0.9-1.3,1.2c-1.7,0.7-2.9,1.4-4.1,2.7c-1.9,2-3.1,1.2-5.5,0.2c-2.8-1.1-5.2-1.3-8.2-1.4c-2.5-0.1-4.7,0.4-6.6,2.1
    	c-0.7,0.6-1.1,1.3-1.6,2c-0.9,1.2-1.4,0.5-2.6,0.6c-2.9,0.3-4.2-1.2-6.6-2.2c-1.8-0.7-3.8-3.2-5.9-2.1c-0.8,0.4-1.4,1.1-2.2,1.6
    	c-0.6,0.4-1.6-0.2-1.8,0.4c-1.1,2.4-3.1-0.6-3.5-1.6c-1.1-2.4-2.7-3.3-5.1-3.7c-1.4-0.2-2.4-1.4-3.8-1.5c-1.2-0.1-1.7,1.2-3.1,1.1
    	c-2.4,0-4.6-0.2-6.3,1.7c-0.6,0.7-1.2,1-1.2,1.9c-0.1,1.5-0.2,2.7-1.1,4c-1.2,1.7-2.7,2.8-2.6,5c0,0.5-0.2,5.3-0.5,5.1
    	c-1.4-0.8-4.5-6.2-6-6c-0.5,0.1-0.7,0.6-1.1,0.8c-0.6,0.2-1.8,0.1-2.2,0.6c-0.2,0.3,0.6,2.7,0.1,2.7c-0.5,0-1.7-2.1-1.9-2.5
    	c-0.8-1.3-0.2-1.5,0.9-2c-0.3-0.7-0.4-1.7-1.1-2.2c-1.2-0.9-2,1-3.2,0.8c-2.3-0.4-6.2-4.1-5.1-6.6c0.7,0.5,1.3,0.5,2,0.1
    	c-0.3-0.3-4.9-3.5-5-3.2c0.3-0.7-1.3-1.9-1.6-2.7C1303.9,575.9,1304.2,574.6,1302.9,573.8"
        />
        <g id="dk" class="countryGroup">
          <path
            id="Denmark_mainland"
            class="country"
            d="M1365.2,213.4c0.1-0.2,0.2-0.5,0.3-0.7c0.4,0.5,1.7,1.2,2.3,0.8
    		c0.3,1.2,1.1,0.8,1.3-0.1c0.5-2.1,3.4-1.2,1.9-0.4c0.7,0.1,1.6-0.1,1.6,0.9c0.2-0.9,0.2-1.3-0.7-1.2c0-0.7-0.2-1.8,0.4-2.4
    		c0.4-0.3,0.9-0.4,1.4-0.3c0.8,0.1,1.3-0.3,2-0.3c0.9,0,1.4,0,2.2,0.4c1,0.4,0.7,1.6,1.2,2.4c-1-0.1-2,0.2-2.9,0.6
    		c0.9,0,1.6-0.7,2.5-0.3c0.6,0.2-0.6,1.3-0.6,1.7c0.2-1.4,3.8-0.6,4.6-0.1c0.6,0.5-0.6,2.3-1.2,2.3c-0.6,0-0.9-0.1-1.1,0.7
    		c-0.8-0.2-0.9-0.7-0.4-1.2c-1.4-0.5-1.3,0.7-1.4,1.7c-0.1,1.6-1.3,1.3-2.4,1.6c0.4,0,1.2,0.5,0.8,1.1c-0.6,0.7-1.9,0.1-2.6,0.2
    		c0.5,0.2,1.1,0.4,1.6,0.6c-1.3,1.3-1.8,0.5-1,2.5c0.5,1.2-1.6,1.1-1.6,2.3c1-0.1,2.2,0.5,1.9,1.7c-0.7-0.1-0.7-0.8-1.3-0.2
    		c-0.7,0.6-1.6,0.4-2.3,0.2c-1.1-0.3-3.8-0.1-3.2-2c-0.6,0.2-1-0.1-1.2-0.6c3.4,0-0.7-2.8-0.9-3.3c-0.2,1.6-1,0.1-1.1-0.6
    		c-0.1-0.9,0.1-1.8-0.1-2.7c0.1,0.7,1.3,1.9,1.7,0.7c0.2-0.5-1.9-2.8-1.6-0.9C1364.5,216.8,1364.8,214.6,1365.2,213.4"
          />
          <path
            id="path4494"
            class="countryIsland"
            d="M1375,225.9c0.5,0.3,1,2.1,1.9,1.4C1378,226.7,1375.7,225.5,1375,225.9"
          />
          <path
            id="path4492"
            class="countryIsland"
            d="M1368.5,212.7c1-0.2,1.7-1.3,1.4-2.3c-0.4,0.3-0.8,0.5-1.3,0.6c-0.4,0.1-0.3,0.9-0.9,1.1
    		C1368,212.3,1368.2,212.5,1368.5,212.7"
          />
          <path
            id="path4496"
            class="countryIsland"
            d="M1375.1,222.1c0,1.2,1.6,2,1.9,3.2c0-1,0.7-0.5,0.9,0.1c0.2,0.5,2.3,0.6,2.7,0.5
    		c1.9-0.3,2.2-3,0.2-3.4c0.2-0.1,0.5-0.3,0.7-0.4c-0.2-0.3-0.4-0.6-0.6-0.8c0,0.7-0.4,1.4-1.2,1.3
    		C1381,221,1375.7,222.1,1375.1,222.1"
          />
          <path
            id="path4498"
            class="countryIsland"
            d="M1379.6,227.7c0.1-0.8-0.9-1-1.4-1.3C1378.6,226.9,1379,227.3,1379.6,227.7"
          />
          <path
            id="path4500"
            class="countryIsland"
            d="M1380.1,226.3c0.3,0.1,0.6,0.1,0.9,0.2C1381,225.7,1380.6,225.9,1380.1,226.3"
          />
          <path
            id="path4502"
            class="countryIsland"
            d="M1381.1,219c-0.4-0.1-0.7-0.3-0.9-0.7c0.4,0.5,0.4,1-0.1,1.4
    		C1380.7,219.9,1381.1,219.6,1381.1,219"
          />
          <path
            id="path4504"
            class="countryIsland"
            d="M1381.5,226.8c-0.6,0.5-0.6,1.2,0.1,1.7c0.3-0.9,1.4-1.9,0.9-3
    		C1382.3,226,1382,226.5,1381.5,226.8"
          />
          <path
            id="path4506"
            class="countryIsland"
            d="M1383.1,208.2c0.4-0.1,0.6-0.4,0.7-0.8c-0.6,0-1,0.2-1.4,0.6
    		C1382.6,208.1,1382.8,208.1,1383.1,208.2"
          />
          <path
            id="Denmark_Sjaelland"
            class="countryIsland"
            d="M1383.9,223.4c0.7,0.2,0.8,0.9,1.3,1.2c0.5,0.2,1.3,0,1.8,0c1.4-0.1,1.7,1.5,0.5,1
    		c0.3,0.1,1.3,0.4,1.4,0.8c-0.2,0.2-0.4,0.3-0.7,0.1c0,0.6,0.9,2.7,1.2,3.2c0.2-0.5,1.2-1.6,1.2-2.1c0-0.6-1.1-0.8-1.3-1.3
    		c0.6,0.2,1.1,0.2,1.7,0c-0.8-1.2,0.1-1.6,1.1-2c1.2-0.5-0.1-1.1-0.6-1.5c-1-1,0.9-1.4,1.4-1.8c1.2-0.9-0.5-2.2,0.7-3
    		c-1.4-1.4-2.9-0.3-4.4,0.3c1.1,0.4,1.7,2.7,0.1,2.8c0.4-0.6,0.8-1.3,0.4-2c-0.3,0.2-1.1,2.1-1.5,1.8c-0.7-0.4-0.5-1.9-0.1-2
    		c-0.6-0.5-1.6-0.4-2.3-0.3c0.4-0.1,0.6,0,0.7,0.3c-0.3,1.3-2.7,1.5-3.7,1.5C1383.2,220.8,1385.9,223.3,1383.9,223.4"
          />
          <path
            id="path4510"
            class="countryIsland"
            d="M1383.2,227.9c1,0.7,4.4,2.1,5.4,0.9c1.1-1.4-1.1-1.4-1.8-1.3c-1.3,0.2-2-1.6-3.3-0.6
    		c0.2,0.2,0.3,0.5,0.5,0.7C1383.8,227.8,1383.5,227.9,1383.2,227.9"
          />
          <path
            id="path4512"
            class="countryIsland"
            d="M1390.9,226.4c-0.1,0.3-0.2,0.6-0.2,0.9c0.3-0.1,2.6-0.6,2.4-1
    		C1392.8,225.7,1391.2,226.3,1390.9,226.4"
          />
          <path
            id="path4514"
            class="countryIsland"
            d="M1392.9,221.2c0.3,0.8,0.6,0.8,0.8-0.1C1393.5,221.1,1393.2,221.1,1392.9,221.2"
          />
          <path
            id="Denmark_Bornholm"
            class="countryIsland"
            d="M1408.8,224.7c-1-0.8-2.6,0.1-1.4,1C1409.2,227.1,1410.4,224.9,1408.8,224.7"
          />
          <path
            id="path4550"
            class="countryIsland"
            d="M1366.9,212.7c-1.5-0.9-0.2-2.5,0.8-3.2c1.3-1,3.6-0.5,5.1-0.6c1.4-0.1,2.1-1.6,2.9-2.6
    		c0.9-1.1,3.5-1.8,4.9-2.2c-1.6,1.4,0.3,2.4-0.6,4.1c-0.9,1.8-1.9,1.9-3.8,1.3c-0.9-0.3-1.7-0.1-2.6,0.2c-1,0.3-1.8-0.1-2.7,0
    		c-0.8,0.2-2,0.4-2.6,1.1c-0.4,0.4-0.7,1.8-1.4,1.8c0.6-0.1,1,0.2,0.9,0.9C1367.5,213.2,1367.2,212.9,1366.9,212.7"
          />
        </g>
        <path
          id="lv"
          class="countryGroup country"
          d="M1446.3,214c0.1,0.2,0.2,0.5,0.3,0.7c-0.1-1.2-0.4-2.6,0.5-3.6c0.4-0.5,1.1-0.8,1.2-1.4
    	c0.2-0.8,0-1.4,0.4-2.2c0.8-1.5,1.8-2.1,3.3-2.5c0.8-0.2,2.8-1.4,3.4-0.7c0.9,1.2,2,1.7,3.1,2.7c1.2,1.1,1.7,2.6,3.5,3.2
    	c1.3,0.4,2.7-0.1,3.8-0.9c1.6-1.1,1.4-2.5,0.7-4.1c-0.3-0.6-0.6-1.4-0.4-2.1c0.6,0.2,1.2-0.3,1.7-0.6c1-0.6,2.8-1.4,3.9-0.7
    	c0.7,0.4,2,0.3,2.8,0.8c1.3,0.7,2.4,0.9,3.6,2c0.9,0.9,1.8,1.4,3.1,0.8c1.4-0.7,2.9,0.3,4.3,0.4c1,0,3.7,2.2,3.4,3
    	c-0.1,0.4-0.7,2.2-1.1,2.5c2.1-0.3,1.3,0.6,2.2,1c1.1,0.4,2.1,4.3,2.3,5.3c-0.4,0.2-1.1-0.5-1.9-0.1c-0.6,0.3-1.1,1-1.4,1.6
    	c-0.2,0.3-0.1,0.8-0.5,1c-1.2,0.6-2.6-0.7-3.9-0.1c-1.5,0.7-2.2,1.5-3.9,0.5c-1.4-0.8-2.7-1.8-4-2.7c-1.3-0.9-2.9-0.4-4.2-1.2
    	c-1.1-0.7-1.3-2.2-2.8-1.3c-1.3,0.8-1.8,0.7-3.2,0.9c-1.3,0.1-2.8-0.7-4.2-0.7c-1.5,0-1.7,0.3-3.1-0.2c-0.9-0.3-3.1-0.1-4.1-0.1
    	c-1.5,0-2.8,0.2-4.2,0.6c-0.9,0.2-2.3,0.4-2.7,1.4c-0.2,0.7-1,1-1.4,0.4C1446,216.3,1446.2,215.1,1446.3,214"
        />
        <path
          id="ro"
          class="countryGroup country"
          d="M1446.7,300.4c0.8-0.5,1.4-0.5,2.2-0.3c0.8,0.2,0.8-0.8,1.4-1c0.7-0.2,1.4-0.1,2.1-0.3
    	c1-0.3,0.8-1.5,1.3-2.2c0.4-0.7,1-1.3,1.5-2c0.6-0.9,0.6-1.6,1.1-2.4c0.5-0.7,1.1-1.5,1.4-2.3c0.3-0.9-0.1-1.4,0.6-1.7
    	c0.7-0.4,0.9-0.6,1.3-1.2c0.7-0.9,2-0.3,2.9-1c0.7-0.6,1.2-1.4,2-1.9c1.1-0.7,2.3,0.2,3.4,0.3c1.1,0.1,2.1,0.4,3.2,0.5
    	c1.3,0.1,2.9-0.4,4,0.3c2.2,1.4,2.4,2.2,4.4,0.3c1.3-1.2,3.9-0.6,5.5-0.9c1.1-0.2,0.9-0.9,1.5-1.6c0.7-0.7,1.9-0.8,2.8-0.7
    	c2,0.2,3.1,2.3,3.9,3.9c0.9,1.7,2.2,3.3,3.6,4.6c2.1,2,3,3.4,3.8,6.2c0.3,1-0.3,2-0.5,2.9c-0.2,1.1,0.2,2.2,0.3,3.3
    	c0.1,1.8,0.6,2.6,2,3.6c0.6,0.4,1.9,1.3,2.7,0.9c0.9-0.5,5.6-3.1,6.2-0.4c0.8,3.5-1,4.7-4.2,4.7c0.4-0.3,0.7-2.2-0.1-2.2
    	c-1,0-0.5,2.3-1.2,2.9c0.5-0.1,0.9-0.2,1.4-0.2c-0.7,0.3-2,0.4-1.2,1.4c0.3-0.2,0.5-0.5,0.7-0.7c-0.3,1.1-1.5,1.8-1.7,3
    	c-0.2,1.3,0.7,3.3-0.1,4.5c-0.5-0.4-1.6,0-2-0.2c-0.4-0.2-1.8-1.2-2.5-1.9c-1-0.3-2,0.2-3.4-0.5c-1.1-0.5-3-1.2-5.1-0.4
    	c-4.7,0.5-5.7,3.1-7.8,3.6c-1.3,0.3-3.1-0.3-5-0.6c-2.3-0.3-3.5,0.4-4.8,0.3c-1.1,0-2.3-1.1-4.2-1.2c-0.9,0-4,0.8-4.6-0.3
    	c-0.9-1.5,2.8-1.6-0.6-2.8c-0.8-0.3-1.1-0.3-2.3-1.8c-1-1.4,1.3-1,1.8-1.3c-0.5-0.7-1.7-1.4-2.6-1.1c-0.6,0.2-0.8,1.8-1.5,1.6
    	c-0.7-0.2-1.8-1.3-2.7-1.6c-1-0.3-2.2-0.5-2.9-1.3c0.8-0.2,1-1.3,0-1.4c1-0.9,0.4-1.5-0.7-1.9c-1.5-0.5-2.7-1.4-4-2.3
    	C1451.3,303.3,1448.1,301.9,1446.7,300.4"
        />
        <path
          id="zm"
          class="countryGroup country"
          d="M1467.6,838.5c0-8.2,0-16.4,0.1-24.6c0-0.9-0.3-2.7,0.9-2.9c1.1-0.2,2.4,0.1,3.5,0.1
    	c3.6,0.1,7.5,0.7,11-0.4c-1.2-1.3-0.6-2.4,0-3.8c0.7-1.7-0.2-3-0.1-4.6c0.2-3.1,1.5-6.6,0.2-9.6c1.2,0,1,1.3,2.2,1.5
    	c1.3,0.3,0.7,2.2,0.2,3c1.6,0.3,1.4,0.3,3-0.4c1.5-0.7,3.2-1.1,4.9-1.2c-0.6,1.2-0.3,3.3,0.6,4.2c0.7,0.7,1.9,0.3,2.8,0.7
    	c1.3,0.6,2.2,1.2,3.7,1.2c2.4,0,4.6,1.6,5.4-1.4c0.9-3.3,1.9,0.4,3.1,1.4c0.8,0.7,0.7,1.8,1.2,2.6c0.9,1.3,1.9,0.1,2.7,0.6
    	c1.2,0.8,1.7,1.5,3,1.5c1.5,0,1.2,2.5,2.2,3.4c1.5,1.4,1.8,2.5,2.6,4.3c1.1,2.2,4.2-2.1,5.4-0.6c-0.5,0.4-0.5,1.1,0.1,1.4
    	c1.2,0.5,0.8-1.9,0.8-2.4c0.1-2.9,0.2-5.8,0.2-8.7c-0.5,0.2-1.1,0.2-1.6,0.4c-0.9,0.3-0.7,1.1-1,1.7c-0.7,1.1-3.7-1.4-4.4-2.1
    	c-2.1-2.2-4.5-4-3.3-7.6c0.7-2,1.2-3.6,1.8-5.3c0.4-1.2-0.4-3.1-0.3-4.4c0.2-2.1,1.2-6.6-1.6-7.5c0.9-2.4,6-3.8,4.1-7
    	c4.2-0.9,7.9-1.2,12.7-2.2c0,0.8-1,1.8-1,2c0.1,0.4,1.4-0.2,0.7,1c0.1,0.3,0.7-1.2,2.4,0.6c1.8,1.8,2.5,0.6,2.3-0.3
    	c2.1,0,2.6,0.7,3.4,2.3c0.4,0.7,0.8,0.4,1.3,0.2c0.7-0.3,1,1,1.6,1.2c1.2,0.5,3.4,0.3,3.8,1.8c0.1,0.4,0.9,0.3,1.4,0.4
    	c0.8,0.3,1.6,0.5,1.9,0.9c0.3,0.4,0.3,0.8,0.1,1.3c-0.4,1.2,2.4,0.7,2.9,2.5c0.2,0.6-0.3,1-0.2,1.5c0.1,0.9,0.8,1.2,1.4,1.7
    	c0.5,0.5,0.7,2,0.9,2.7c0.4,1.2-1,2.4-2.2,2.7c-1.3,0.4-0.5,1.4-0.2,2.3c0.5,1.5-0.4,2.3-0.5,3.8c-0.1,2.1-1,6.6,1.6,7.4
    	c-1.1,1.1-4.3,1.7-4.3,3.6c0,1.3,0.3,1.7,0.1,3.2c-0.4,2.1-1.7,3.3-2.2,4.7c-0.3,0.8,0.7,1.5,1.3,2c0.9,0.7,1.4,0.2,1.7,0.8
    	c-7.1,3.3-14.8,5.3-22,8.3c-0.9,0.7,1.6,4,1.1,5.6c-0.7,0.1-2.6,0.4-3.3,0.1c-1.3-0.5-2.9,0.3-4.1,0.7c-1.8,0.6-4.1,1.6-4.4,3.7
    	c-0.2,0.9,0,1.8-0.3,2.7c-0.6,1.2-2.1,1.3-3.2,1.7c-1.9,0.7-4.4,2.3-5.4,4c-1.1,1.8-5.2,9.6-8.3,7.8c-0.7-0.4-1.4-0.8-2.2-1
    	c-1.1-0.3-2.2,1.1-3.1,0.6c-0.7-0.4-0.9-1.7-1.8-1.4c-0.2,0-2.4,0.6-3.5-0.4c-1.2-1.2-1.8-2-2.8-2.2c-1.1-0.2-1.8,0.9-3.7-0.3
    	c-1.5-0.2-2.6,0.6-4.1,0.7c-0.8,0.1-2.2,0.7-2.7,0.5c-2.6-1-4.3-4-6.2-6c-1-1.1-1.8-2-2.9-3.1C1468.3,840.9,1468,840,1467.6,838.5"
        />
        <g id="mm" class="countryGroup">
          <path
            id="Myanmar_mainland"
            class="country"
            d="M1992.6,515.2c0.1-0.6-0.2-2,0.6-2.1c1-0.1,1.6,0.9,2.9,1.2c-0.8-1.9-1-3.9-1.4-5.8
    		c0,0,0.9-0.8,0.7-1.6c0.7,0.3,1.1,1.8,2,1.5c0.7-0.2,0.4-1.5,0.5-2c1.5,0.9,0.9-1.3,0.6-2c-0.8-1.9-0.6-3.3-0.9-5.3
    		c1.5,1.1,1.5-1.2,1.5-2.1c-0.1-2.3-0.5-4.3-1.3-6.5c0.3,0,0.7,0.1,0.9,0.3c0.7,0.5,0.6,0.1,1.4,0c0.8-0.1,3.2,0.9,3.9,1.4
    		c0-3.3,4.5-9.2,2-11.8c-1.4-1.4,1.5-3.3,1.8-4.6c0.4-1.6,0.8-2.5,0-3.9c-0.7-1.2-0.8-3.4,0.4-4.2c0.4-0.3,1.6,0.1,1.7-0.6
    		c0.1-0.6,1.4-1.1,1.8-1.5c0.6-0.6,1-1.2,1.4-2c0.7-1.3,2.6-1.1,3.8-1.3c0.6-0.1,1.3-0.5,1.9,0c0.7,0.7,1.4,2.3,2.8,1.7
    		c-0.2-1.1-1.3-1.6-2-2.4c-1.2-1.4-0.4-2.6,0.7-3.6c1.6-1.6,0.8-1.3,0.8-3c0-0.6,0.5-0.5,0.4-1c-0.1-0.7,0.6-1.7,0.6-2.3
    		c1.3,1.5,4.1,1.7,5.1,3.6c0.4,0.7,0.3,1.5,0.6,2.3c0.5,1,1.2,1.8,1.7,2.7c0.6-0.2,0.9-0.6,0.6-1.2c3,1.4,3.2,4.4,4.1,7.4
    		c0.4,1.5,0.5,2.7,0.6,4.2c0,0.6,0.7,2.7-0.9,2.1c0.4,0.6,2.7,3,0.3,2.3c-0.4-0.1-1.1,1.1-1,1.4c0.2,0.9-1.1,0.7-1.6,0.7
    		c-0.2,0.5-0.3,4.5-1.9,3c0.1,1.3-0.6,2.3,0.2,3.6c-1.3,0.1-1.2,0.9-1.2,2c0.1,1.1,0.9,1.2,1.3,2c0.2,0.4,0.7,1.5,0.6,1.9
    		c-0.3,0.7-1.4,1.7-0.5,2.4c0.4,0.3,2.9-1.6,3.3-1.8c1.8-1,4,0.6,5.7-0.7c0.3,1.1-1.2,1.6-0.8,2.7c0.2,0.6,0.7,1,1.2,1.4
    		c0.6,0.6-0.2,1.2,0.2,1.6c1.3,1.1,0.9,2.7,2,3.2c1.5,0.7,2.9-0.7,4,1.9c-1.7-0.1-1,2.5-0.8,3.5c0.2,1.1-1.7,3.8,0,3.7
    		c1-0.1,4-0.5,5.2,0.5c1.2,1.1-0.6,3.1,1.9,3.1c-0.3,0.5-0.1,1.4,0.3,1.9c0.6,0.7,1.1-0.8,1.9-0.3c1.6,1,3.7-1.5,4.9-2.4
    		c0.1,1.3,1,2-0.1,3c-0.5,0.5-1.8,0.8-2.1,1.4c-0.2,0.4-1.8,4.1-0.3,3.2c-0.1,1.1-1.8,0.5-2.4,1c-1.1,0.8-0.7,2.5-1,3.7
    		c-0.6-1.4-2.6,0.2-4.4,0.3c0.5,0.6,1,1.2,0.2,1.6c-0.8,0.3-1.8,0.2-2.5,0.3c-0.9,0.1-0.4,1.7-0.5,2.3c-0.2,0.7-0.8,0.8-1.3,0.4
    		c-0.5-0.3-1.5,0.7-2.4,0.9c-0.3,0-3.3-0.6-3.2-0.6c0.3-0.3-1.7,2-1.6,3.1c0.1,0.6,0.4,1,0.5,1.5c0.1,0.7-0.7,1.8-0.6,2.6
    		c0.2,0.9,1.2,1.9,0.6,2.9c-0.6,0.9-2.5,1-3.1,0c0.3,1.1,0.9,3,2.4,2.6c-0.1,0.7,0.3,1.2,0.7,1.7c0.6,0.8,0.3,1.6,0.6,2.4
    		c0.5,1.5,2.5,3.1,3.4,4.2c1.1,1.3,2.6,1.8,3.4,3.8c0.7,1.7,0.9,3.5,2.1,5c0.4-0.4,0.7-0.9,0.9-1.5c0.9,0.7,0.8,2.3-0.1,3
    		c-0.8,0.7-1.8-0.1-1.4,1.8c0.2,1,0.7,2.8,0.5,3.9c-0.1,0.9-1.6,0.7-1.3,1.7c-0.7-0.6-1.1-0.1-1.3,0.7c-0.2,1.1,0.3,2.5,0.9,3.4
    		c1.5,2.5,4,4.3,5.6,6.3c1.7,2.2,2.6,4.4,2.3,6.9c-0.1,1.1-0.4,1.2,0.2,2.5c0.5,1.2,0.7,1.8,1.4,2.9c0.8,1.1,1.2,2,1.4,3.1
    		c0.1,1.2,1.2,3.3,1.6,4c0.5,1.2-1,3.4-1.9,4.8c-0.8,1.3-1.8,2.5-3,4.7c-0.6,1,0.1,1.6-0.4,2.5c-0.6,1.3-0.7,2.6-1.5,3.9
    		c-0.9-1.4-0.7-3.3-1.1-4.9c-0.1-0.6-0.5-1.2,0.1-1.5c0.8-0.5,0.2-0.7,0.5-1.3c0.8-1.2,0.9-2.7,0.6-4.2c-0.1-0.7-0.6-3,0.7-3
    		c-0.2-0.3-0.3-0.7-0.3-1.1c-0.4,0.5-0.9,0.9-1.4,1c-0.2-1,1.2-1.6-0.2-1.4c1.7-0.8-0.8-2,0.6-2.8c-0.6,0.2-1.1-0.2-1.3-0.7
    		c0.4-0.1,0.8-0.2,1.1-0.4c-0.7-0.4-1.1-1.1-0.1-1.4c-0.9-0.3-0.4-1.2-0.5-2c-0.2-1.3-0.7-2.7-1.2-4c-0.5-1.3-1.3-2.6-2-3.8
    		c-0.6-1.2-0.7-2.7-1.5-3.8c0.4,1,0.8,3.4,0.1,4.3c0.3-0.9-0.5-1.6-0.5-2.4c-0.1-0.8-0.1-2-0.3-2.7c-0.2-0.7-2.9-5.4-1.2-4.5
    		c-0.1-0.3-0.2-0.5-0.4-0.8c-0.2,0.1-0.5,0.3-0.7,0.4c-1.2-3.1-1-6.6-2.1-9.6c-0.2-0.6-0.8-0.9-1-1.5c-0.2-0.5,0-1.1,0-1.6
    		c0-0.9-0.7-2.8,0.5-3.3c-0.8,0.1-2.2,0.7-2.8-0.1c-1.1-1.4-1.9-3-2-4.8c-0.7,0.5-0.6-0.6-1.2-1.1c-0.5-0.5-1.2-0.9-1.4-1.6
    		c-0.2,0.2,0.1,1.7,0.1,2.1c0,1.2,0.7,2.5,0.1,3.7c-0.5,0.9-1.1,2.2-2.3,2.3c-1.2,0.1-2.6-1.6-1.7-2.7c-1.4,0.4-0.2,1.4,0.2,2.3
    		c0.5,1.2-0.8,2.6-1.8,1.5c1.2,0.8-1.2,2.3-2,1.4c0.3,1.1-0.6,4.9-2.4,4.2c-0.9-0.4-0.1-2.8-0.3-3.6c0,0.1-1,3.5-0.5,1.2
    		c-0.3,0.6-0.4,1.3-0.4,1.9c-0.9-0.4-1.2-2.4-0.4-3c-1.1-0.7-0.6,4.3-2.1,3.1c-0.7-0.6-0.1-3.8,0.5-4.2c-1.1,0.4-1.3,2.6-1.9,3.5
    		c-0.3-0.2-0.6-0.4-0.8-0.7c0.9-0.4,1-1.3,1.2-2.1c-0.4,0.7-0.6,1.8-1.6,1.9c1.1-1.5,0.6-3.3,0.8-5c-0.1,0-0.3,0-0.4,0
    		c0.6,1.2-1.1,3.9-2.2,4.3c-1.6,0.7-0.3-6.2-0.1-7c0.2-0.8,0-3,0.4-3.4c1.1-1-0.1-3.9-0.6-5c-0.7-1.4-0.4-3.6-1.5-4.8
    		c-0.4-0.5-0.7-1.2-1-1.7c-0.4-0.9-1.6-1.4-0.4-1.7c-0.8-0.6-1.9-0.8-1.9-2c0-0.9,0-2.4-0.4-3.2c0.1,0.7,0.4,2.2-0.8,1.4
    		c0.6,0.7,1,2,0.6,2.9c-1.7-0.7-2.8-2.5-3.8-4c-0.3-0.4,0.6-0.8,0.9-0.3c0.2,0.4,0.8,0.6,1.2,0.7c0.9,0.3,2.1-0.2,1.3-1.4
    		c-0.5-0.6-2.7-1.5-1.4-2.4c-0.6,0-1.2-0.1-1.7-0.2c0.2-0.5,0.2-1,0.2-1.5c-0.7,0.3-1.6-0.5-1.9-0.9c-0.7-0.9-1.3-0.3-2.2-0.7
    		c0.3,0.8,0.8,1.5,1,2.3c-0.3-0.5-0.6-1-0.9-1.5c-0.1,0.5,0,1,0.3,1.4c-0.9-1.2-1.3-1.9-0.6-3.3c-0.3,0.1-0.7,0.1-1,0.1
    		c0.2-0.5,0.4-1,0.6-1.5c-0.1,0-0.3,0.1-0.3,0.2c-0.2-0.6-0.2-1.1,0-1.7c-0.5,1.2-0.4,2.6-1,3.7c-1-0.4-0.5-0.9-0.4-1.7
    		c0.1-0.9-0.9-1.6-1.3-2.3c0.1,0.7,0.6,1.5,0.1,2.2c-0.4-1.3-1.4-2.4-2.5-3C1993.5,519,1993.3,516.7,1992.6,515.2"
          />
          <path
            id="path4568"
            class="countryIsland"
            d="M1998,521.7c0,0.4,0.1,0.6,0.5,0.8C1998.3,522.2,1998.1,522,1998,521.7"
          />
          <path
            id="path4570"
            class="countryIsland"
            d="M1999.5,525.5c0.3,0.5,0.6,1,0.9,1.5c-0.2-0.8-0.6-1.6-1.2-2.2
    		C1999.3,525,1999.4,525.3,1999.5,525.5"
          />
          <path
            id="path4572"
            class="countryIsland"
            d="M2003.2,525.9c-0.2,0.6,0.5,1.2,0.9,1.5C2004.1,526.8,2003.7,526.3,2003.2,525.9"
          />
          <path
            id="path4574"
            class="countryIsland"
            d="M2006,535.2c-0.4,0-0.8,0.1-1.1,0.4C2007.3,538.2,2007.3,535.1,2006,535.2"
          />
          <path
            id="path4576"
            class="countryIsland"
            d="M2008,531c-0.4-0.8-1.5-0.9-2-1.6C2004.9,530.5,2007,532.4,2008,531"
          />
          <path
            id="path4578"
            class="countryIsland"
            d="M2016.3,558c-0.5,0.5-2.3,2.2-1.2,3C2015.8,560.2,2016.9,559.2,2016.3,558"
          />
          <path
            id="path4580"
            class="countryIsland"
            d="M2018,560.8c-0.2,0.3-0.3,0.2,0,0.7C2018,561.3,2018,561,2018,560.8"
          />
          <path
            id="path4582"
            class="countryIsland"
            d="M2037.8,555.8c-0.4,0.2-0.5,1.7,0,1.7C2038.8,557.5,2038.7,555.9,2037.8,555.8"
          />
          <path
            id="path4584"
            class="countryIsland"
            d="M2044.8,592.2c0.2-0.2,0.3-0.5,0.4-0.8C2044.3,591.2,2044.2,591.5,2044.8,592.2"
          />
          <path
            id="path4586"
            class="countryIsland"
            d="M2045.8,597.7c0-0.5-0.2-1-0.5-1.4C2045.5,596.7,2045.6,597.2,2045.8,597.7"
          />
          <path
            id="path4588"
            class="countryIsland"
            d="M2045.6,593.3c0.1,0,0.3,0,0.4,0c-0.2-0.3-0.4-0.6-0.7-0.8
    		C2045.4,592.7,2045.5,593,2045.6,593.3"
          />
          <path
            id="path4590"
            class="countryIsland"
            d="M2046.6,586.3c0.4-0.6,0.2-1.2-0.4-1.4C2046.3,585.3,2046.4,585.8,2046.6,586.3"
          />
          <path
            id="path4592"
            class="countryIsland"
            d="M2048.1,605.8c-0.1-0.8-0.5-1.6-1.2-2.1c-0.2,0.2-0.3,0.5-0.4,0.7
    		C2047.5,603.9,2047.8,605.1,2048.1,605.8"
          />
          <path
            id="path4594"
            class="countryIsland"
            d="M2046.8,599.5c1.2-0.1,1.1-2.3,0.6-3C2047.1,597.5,2046.9,598.5,2046.8,599.5"
          />
          <path
            id="path4596"
            class="countryIsland"
            d="M2047.3,591.5c0.4,0.5,2-2.7-0.3-2.2C2047.1,590,2047.4,590.8,2047.3,591.5"
          />
          <path
            id="path4598"
            class="countryIsland"
            d="M2047.6,612c0,0.2,0.1,0.5,0.1,0.7c0.4-0.3,0.7-0.7,0.6-1.2
    		C2048.1,611.7,2047.9,611.9,2047.6,612"
          />
          <path
            id="path4600"
            class="countryIsland"
            d="M2048.6,598.1c0.2,0.4,0.5,0.6,0.9,0.6C2049.9,596.4,2047.3,596,2048.6,598.1"
          />
          <path
            id="path4602"
            class="countryIsland"
            d="M2048.5,594.7c0.2,0.3,0.4,0.7,0.6,1c0.3-0.2,0.5-0.4,0.7-0.6c-0.6-0.2-1.1-0.6-1.4-1.1
    		C2048.4,594.2,2048.5,594.5,2048.5,594.7"
          />
          <path
            id="path4604"
            class="countryIsland"
            d="M2048.4,591.1c0.1-0.4,0.4-0.2,0.1-0.8C2048.5,590.6,2048.4,590.8,2048.4,591.1"
          />
        </g>
        <path
          id="et"
          class="countryGroup country"
          d="M1552.1,630.3c0.1-1.2,1.2-1.8,1.3-3c0-2,1.4-2.2,3.1-1.9c1.5,0.3,2.8,0.7,3.9-0.9
    	c0.8-1.2,0.3-3.2,0.1-4.4c-0.3-2.1-0.6-4.4,0.2-6.5c0.3-0.8,0.7-1.6,1-2.4c0.4-1.2,0-2-0.1-3.2c0-1.2,0.6-2.4,1.4-3.2
    	c0.9-1,2.2,0.2,2.2,1.3c0.5-0.6,2.5-3.6,1.5-4.6c-0.6-0.5,0.3-1.9,0.5-2.4c0.3-1.1-0.4-2.5,0.6-3.4c1.6-1.4,2.2-3.3,3.1-5.2
    	c0.4-0.8,0.7-1.7,1.7-1.7c0.4,0,2.4,0.2,2.5-0.3c0.5-1.5,0.4-3.4,0.7-4.9c0.1-0.9,0.5-1.6,0.9-2.4c0.7-1.5,0.5-2.8,0.8-4.4
    	c0.6-2.9,1.8-1.8,4-1.9c0.8,0,1-1.7,2.2-1c0.9,0.5,1.4,1.8,2,2.6c0.9-2.2,1.8-4.5,2.4-6.8c0.3,0.5,0.7,1.2,1.3,1.5
    	c0.6,0.3,1.3,0.1,1.7,0.8c0.7,1.5,1.7,1.7,3.2,1.4c0.6-0.1,1.2-0.3,1.6-0.7c0.3-0.2,0.5-1.1,0.8-1.2c0.5-0.1,1.4,1.6,1.5,2
    	c1.5-1.9,4,0.3,5.5-0.1c2.1-0.6,4.2,1.7,6,2.4c2.4,1,3.1,4.1,5,5.6c1.2,0.9,2.7,1.7,3.4,3c0.7,1.2,1,2.3,2.1,3.2
    	c2,1.5,2.8,2.7,1.5,4.9c-1.5,2.7-3.6,4.2-3.1,7.5c0.2,1.6-0.4,3.6,1.9,3.5c1.4-0.1,2.7-0.8,4.1-1.1c1.1-0.3,1.5,0.7,2.5,0.3
    	c-0.1,1.7-2.1,3.2-1.1,5.1c1.1,2.4,2.2,3,3.7,5.1c0.5,0.7,1.4,3.1,1.9,3.7c0.6,0.6,1,0.4,1.7,1c1,0.8,2.6,2.6,3.8,3
    	c5.1,1.8,9.7,3.8,14.8,5.6c2.4,0.9,4.4,1.9,6.9,2.5c2.3,0.5,5.3,0.2,7.6,0.2l-21.4,25.4c-1.3,1.5-2.3,1.7-4.2,1.4
    	c-3.1-0.4-7.3-0.6-11.3,3.1c-1.1,1-1.4,2.2-2.9,2.5c-1.4,0.3-2.9,0.2-4.2,0.9c-1.4,0.8-1.3,2.1-3.2,1.7c-1.6-0.4-3.7,0.9-5.1-0.6
    	c-0.6-0.6-1.1-1.6-2-1.8c-0.9-0.1-2.2,0.6-3,0.9c-1.3,0.6-3.4,1.7-4.2,2.9c-0.9,1.3-1.5,2.3-2.1,3.3c-1-0.9-3.2-1.1-4.4-1
    	c-1,0.1-1.7-0.7-2.5-0.9c-0.9-0.2-1.9,0.2-2.8,0.1c-3.7-0.3-0.6-0.8-10.3-6.6c-1.7-1-4.1,0.1-6.1-0.6c-0.9-0.3-2.4-2.2-2.8-3
    	c-0.6-1.2,0.4-2.8,0-4.1c-0.3-1.2-2.8-1.9-3.6-0.9c-0.5-0.5-1.2-1.9-1.6-2.5c-0.9-1.4-1.3-3.4-1.9-5c-0.5-1.2-0.7-2.6-1.3-3.7
    	c-0.3-0.5-0.7-0.7-1.2-1c-0.6-0.3-0.6-0.8-1-1.2c-0.3-0.2-0.4-0.3-0.7-0.3c-0.8-0.2-0.9-1.1-1.3-1.6c-0.9-1.3-1.6-2.8-2.8-3.9
    	c-0.9-0.8-2-1.3-3.2-1.4C1554.2,631.5,1552.6,631.5,1552.1,630.3"
        />
        <path
          id="gt"
          class="countryGroup country"
          d="M602.8,572.7c0.8-1,0.7-2,1-3.2c0.1-0.6,0.9-1,0.8-1.6c-0.3-1.2-0.3-1.4,0.4-2.5c1-1.7,1.9-3.9,2.6-5.3
    	c0.7-1.5,3.5-0.6,4.9-0.6c0.8,0,5.2,0.5,5.7-0.2c0.1-0.2,0.5-2.9,0.4-3c-0.5-0.4-1.2-0.2-1.3-1c-0.2-0.9-0.4-1.4-1.3-2
    	c-1.7-1-2.7-2.6-4-4c1,0,2.8,0.5,3.4-0.6c0.6-1.1-0.1-2.9,0.7-3.9c0.9-1.1,5-0.4,6.3-0.4c2.5,0,5,0,7.6,0c-0.6,2.7-0.7,5.5-1.2,8.3
    	c-0.5,2.8-1.1,5.6-1.6,8.4c1.3-0.1,4.2-0.2,4.4,1.7c0.2-0.5,0.4-1.1,1-1.2c-0.2-0.3-0.4-0.5-0.6-0.7c1,0.4,1.7,1.2,2.6,1.7
    	c-2.4,1-3.9,3.5-6.2,4.8c-1,0.6-2.4,1.3-2.1,2.8c0.2,1,0.4,1.7-0.4,2.5c-0.8,0.7-3,1.2-2.8,2.3c0.1,0.7-0.4,0.5-0.9,1
    	c-0.1,0.1-0.9,1.2-0.8,1.1c-1,0.4-3.5,0.6-3,2.6c-1.2-0.5-2.2-1.4-3.5-1.6c-1.3-0.2-2.7,0.2-4.1,0
    	C607.5,577.7,605,574.9,602.8,572.7"
        />
        <path
          id="sr"
          class="countryGroup country"
          d="M859.8,667.7c-0.8-0.5-2.2-3.5-2.2-4.5c0-1.4,0.2-5,0.2-6.3c0-2.4,3.2-1.8,4.8-1.8
    	c0.8,0,2.2-2.1,0.6-2.5c1.6-1.9,1-6.2,4.5-5.7c1.1,0.2,7.1,0.7,6.5,2.6c0.8-1.5-0.4-2.6,2-2.6c0.9,0,3.8-0.1,3.9,1.3
    	c0.5-0.6,1.4-0.3,2-0.3c-0.2-0.1-2.1-0.8-1.8-1c1.3-0.9,4.8,0.5,6.2,0.7c2.5,0.4,2.4,2.2,1.3,4c-1.2,2-2.8,3.1-2.7,5.7
    	c0.1,2.2-0.2,4.9,0.9,6.9c0.9,1.6,3,3,1.8,5c-0.4,0.7-0.8,1.5-1,2.3c-0.2,0.8,0,1.9-0.3,2.7c-0.6,2-2.1,4.3-2.8,4.3
    	c-0.6,0-2.5-1.4-2.8-1.8c-1-1.2-3,0.3-4.4,0.5c-1.1,0.2-1.3,0.5-2.3-0.1c-1.1-0.6-1-0.1-1.4,0.6c-1,1.6,0.4,1.5,0.7,3.3
    	c0.4,2.2-3.5,1-4.4,0.4c-0.6-0.4-1.7-1-2.3-1.5c-0.7-0.7-0.7-1.2-0.7-1.8c0-1.6-2.5-3.6-2.5-5.1c0-0.6-0.2-3.6-1-3.7
    	c-0.5-0.1-1.4,0.5-1.9-0.1C860.1,668.9,860.1,668.1,859.8,667.7"
        />
        <path
          id="cz"
          class="countryGroup country"
          d="M1390.8,264.9c0.7,0,1.3,0.6,1.5,1.3c0.3-2.6,4-1.9,4.7-2.5c2.6-2,6-3,8.8-3.9
    	c-0.2-0.2-0.5-0.4-0.7-0.6c0.6-0.1,1.4-0.4,1.8,0.2c0.4,0.5,0.8,1.4,1.6,1.1c0.5-0.2,1.2-0.1,1.3-0.7c0.1-0.8,0.4-0.6,0.9-0.5
    	c0.8,0.2,1.1,0.3,1.3,1.1c0.7,1.9,4.5-0.2,4.6,2.1c0.4-0.4,1.3-0.5,1.8-0.4c1.8,0.4,0,1.4-0.6,1.9c1.2,0.4,1.7,1.1,2.4,2.1
    	c0.9,1.2,2.3-0.2,3.1-0.7c-0.5-0.4-0.7-0.9-0.8-1.5c1.9,0.5,3.6,1.9,5.6,1.3c0,0.9-0.1,2.5,1,2.4c1.1-0.1,1.6-0.3,2.8,0.3
    	c1.3,0.6,1.6,0.2,2.2,1.6c-1.1-0.8,1.3,0.2,2.4,2.5c-1.3,0.5-3.3,0.7-4.4,1.6c-0.8,0.6-0.8,2-1.7,2.4c-0.9,0.4-2,1.6-4,1.5
    	c-1.3,0-2.2,0-3,1.6c-0.7-2.4-4-0.7-5.5-1.2c-1.2-0.4-2.4-0.9-3.7-1.3c-1-0.3-2.9-0.9-4-0.8c0.1,0.7-0.2,1.3-0.1,2
    	c-1-0.1-1.8,0.7-1.8,1.7c-1.5-0.6-1.5,0-3-0.1c-1-0.1-1.8-1.2-2.6-1.7c-1-0.7-2.1-1.2-3.1-1.8c-1-0.6-2.1-2-3.1-2.4
    	c-1.7-0.7-2.1-3.1-3.7-3.9C1395.1,267.8,1391,267.7,1390.8,264.9"
        />
        <path
          id="td"
          class="countryGroup country"
          d="M1403.2,573.8c0.3-0.7,0.9-0.5,1.3-1.1c0.3-0.5,0.4-1.2,0.9-1.5c0.6-0.5,0.7-2.2,1-2.9
    	c1.4-3.7,4.6-7.1,7.1-10.1c2.3-2.7,4.9-5,5.1-8.7c0.2-4.6,0.3-9.2,0.6-13.9c0.3-3.6,0.1-8.2,1.8-11.4c1-1.8,0.3-2.7-0.9-4.1
    	c-0.3-0.4-0.8-0.7-1.2-1.1c-0.6-0.7,0.2-1.3-0.1-2c-0.6-1.6-2-2.9-2.7-4.5c-0.7-1.3-0.2-2.9-0.4-4.4c-0.2-2.3-0.9-4.6-1.3-6.9
    	c0-0.3-0.5-1.2-0.1-1.4l8.2-3.9l59.6,33.7l0.6,33c-2-0.6-2.2-0.4-4.2,0c-1.4,0.2-3.1,0-3.6,1.3c-0.3,0.9,0.6,2.7-0.1,3.6
    	c-0.6,0.8-1.6,3.8-3,4.2c-1.5,0.5-0.9,3.2,0.2,4.3c-2.5,2-4.1,3.2-2.2,6c1.3,1.9-1.7,2.7-2.6,4.3c-0.6,1.1-0.8,1.5,0.1,2.3
    	c0.7,0.7,2,0,2.8,0c1,0,1.8,0.5,1.2,1.3c-0.9,1.1,1,2.5,0.2,4.5c0.3-0.1,0.5-0.3,0.8-0.4c0.6,0.9-0.1,2-0.1,3c0,1.1,0.1,1.1,0.8,1.9
    	c0.6,0.7,2.2,0.3,2.3,2.2c0,0.8-0.8,2.4-0.5,3.3c-0.4-0.7-2.5-1.4-3.4-1c-0.6,0.3-1.3,0.5-1.7,1c-0.4,0.7-0.5,0.2-1,0.8
    	c-0.8,0.9-2.7,0.7-3,2c-0.1,0.6,0.3,1,0.2,1.6c-0.6,2-2.4,2.8-3.8,4c-1.1,1-1.7,2.5-2.7,3.6c-1.8,2-3,2.4-5.6,3.4
    	c-1.4,0.5-5,0.8-6.6,0.6c-0.5,0-3.7,0.7-2.6,1.6c0.5,0.4,1.8,0.6,1.5,1.5c-0.3,1-1.5,2.5-2.3,3.2c-0.6,0.6-1.2,2-1.9,2.2
    	c-1.5,0.4-3.3,0.1-4.8,0.2c-2,0.2-3.5,0.7-5.2,1.7c-0.9,0.6-2.7,1.1-3.2,2.2c-0.7-1-1.6-1.7-1.9-2.9c-0.6,0.2-0.9,1.1-1.2,1.6
    	c-0.2,0.4-1.4,0.5-1.9,0.7c-2.1,0.9-2.8,0.7-5,0.8c1.2-1.5-0.1-2-0.6-3.4c-0.6-1.9-1.1-3.9-2.3-5.5c-1-1.3-2.5-2.2-3.8-3.3
    	c-1.3-1-2-2.3-3-3.6c-0.7-0.8-2.5-2.4-1.7-3.7c0.9-1.2,1.3-2.1,2.9-2c1.8,0.1,3.6,0.8,5.4,0.2c0.8-0.3,1.5,0.1,2.2,0.4
    	c0.7,0.3,1.6-0.3,2.2-0.5c-3.6-2-5.2-6.8-5.1-10.7c0-0.9,0.5-1.6,0.6-2.5c0.1-0.9-0.1-1.8-0.2-2.7c0-0.7,0.1-1.8-0.3-2.3
    	c-0.3-0.5-1.2-0.1-1.1-0.9c0.1-1-0.3-2.4-0.6-3.4c-0.2-0.7-1.2-0.8-1.7-1.2c-1.7-1,0.4-2.6-2.8-3.1c-0.8-0.1-0.6,0.5-1.4-0.2
    	c-1.2-1.4-2.7-4.1-3.1-4.8C1403.4,578.5,1403.6,576,1403.2,573.8"
        />
        <path
          id="al"
          class="countryGroup country"
          d="M1442.3,347.1c0.4-0.7,0.7-1.5,0.4-2.2c1.1,0.2,0.5-2.3,0.7-3c0.2-0.6-0.8-0.6-0.4-1.4
    	c0.2-0.6,0.5-1.2,0.6-1.8c0.2-1.1,0-2.2-1.3-1.9c-0.5-1.6-0.5-3.7,0.5-5.1c0.8-1.1,1-2.8,2.1-0.8c0.7,1.3,1.8-1.5,2.9,0.6
    	c0.2,0.4,0.2,0.7,0.6,1.1c0.7,0.7,1.2,0,2.2,1.4c0.2,0.2,0.5,1.8,0.4,3c-1.1,0.7,0.3,1.9-0.9,2.4c0.1,0.4,0.4,0.8,0.6,1.1
    	c0,0.3,0.1,0.5-0.1,0.8c-0.1,0.7,0.4,1.6,0.9,2c0.6,0.4,0.4,1.5,1.4,1.4c1-0.1,0.9,0.5,1.2,1.2c1.2,2.7-0.7,2.1-1.4,3.8
    	c-0.2,0.6-0.8,1.9-1.5,2.2c-1.7,0.9-1.6,0.8-0.9,2.4c-1.5-0.2,0.5,0.9-1.5,1.2c-1.2,0.2-1.5-0.4-1.6-1.4c-0.4-2.4-3.6-3.2-4.9-5.1
    	c0.5,0.1,0.8,0.3,1,0.8c0.7-0.8-0.7-1.4,0.1-1.7C1442.9,347.9,1442.5,347.5,1442.3,347.1"
        />
        <g id="fi" class="countryGroup">
          <path
            id="Finland_mainland"
            class="country"
            d="M1436,117c0.8-0.9,2,0.1,2.9-0.4c-0.2-0.2-0.5-0.4-0.7-0.6c2.7-1.6,5.2,0.4,7.1,2.3
    		c1.4,1.4,4.1,2.2,6,1.7c1.1-0.3,1.8-0.5,2.6-1.1c0.8-0.6,3,0.3,3.8,0.6c1.2,0.3,1.3,1,2.5,0.8c1.1-0.2,1.3-2,2.7-2.1
    		c0.7-0.1,1.4-0.1,1.2-1c-0.3-1.5,0.3-3,0.7-4.5c0.9-1.2,2.6-2.4,4.3-2c1.6,0.4,2.6-0.2,4-0.9c1.6-0.7,2.8,0.7,4.2,1.2
    		c1.5,0.5,2.9,0.9,4.4,1.2c4.5,1.1-1.4,3.4,1.1,5c-1.2,0.6-2.6,0.7-3.6,1.1c0.6,0.1,2.9,0.2,1.8,1.3c-1.1,1.1-1.5,1.9-0.1,3
    		c1.3,1.1,2.9,1.1,4.4,1.9c1.2,0.7,2.5,1.5,3.7,2.3c2.8,1.9-4.9,4.5-3.4,6.6c2.2,3,5.9,4.9,7.2,8.7c-0.8,0-1.5,0.3-2.3,0.4
    		c1,0.3,0.5,1.8,0,2.5c-0.4,0.5,1.2,0.9,1.4,1.4c-1.4,0.3-1.4,1.4-0.5,2.2c0.9,0.8,2.3-0.2,3,1.2c-2.8,1.2,1.2,2.6,2.3,3.2
    		c2.4,1.2-1.2,3.5-2.3,4.1c3.4,1.6,6.6,3.4,9.6,5.5c1,0.9,0.5,1.3,0.1,2.4c-0.7,1.9-2,2.8-3.5,4c-3.5,3.9-6.7,7-11.9,10
    		c-0.9,0.7-1.9,1.3-2.7,2.1c-0.4,0.3-0.8,0.6-1.1,1.1c-0.5,0.7-1.5,0.2-2.2,0.2c-1,0.1-1-0.8-2.1-0.3c-0.8,0.4-1.6,0.7-2.5,0.7
    		c-0.4,0-0.7-0.1-1.1-0.3c-0.1,0.3-0.3,0.6-0.6,0.7c-0.8,0.2-1.8-0.2-2.5-0.6c0.6,0.7,0.3,0.9-0.4,1c0.1,0.2,0.2,0.5,0.3,0.7
    		c-0.8,0.2-1.6-0.1-1.6-1c-0.7,1.2-2.8,1.2-4,1.5c-0.7,0.1-1.3,0.4-1.9,0.6c-0.5,0.2-0.5,0.8-1,0.9c-0.9,0.2-1.2-0.6-2.2-0.3
    		c-1.5,0.4-3.1,1.2-4.6,0.9c0.3-0.2,0.6-0.4,0.9-0.6c-1.2,0.4-2.1,1.4-3.4,1.5c0.5-0.3,1.1-0.5,1.3-1c0.6-1.1-1.2-0.1,0.5-0.8
    		c-0.7,0.1-1.2-0.2-1.7-0.6c-0.9-0.6,0-1.3,0.6-1.9c-0.6,0.2-4.8,1.6-3.4-0.2c-1.3-0.3-2.7-0.3-3.9-1.1c-0.5-0.3-1.9-1.4-1.6,0
    		c-0.4-0.3-1.5-0.7-1.4-1.1c0.3-0.7-0.6-2.6-1-3.3c2.4,0-0.5-4.9,1.9-3.6c-1.1-0.7-2.3-2.6-2.7-3.8c-0.3-1,0.4-1.6-0.2-2.7
    		c-0.5-0.8-1-1.9-1.5-2c0.6-0.7,1.9-3.7,3.1-3.2c-0.6-0.4-1.1-1.2-0.7-1.9c1.1,1.2,3.7,0.5,4.8-0.2c-0.3-0.3-1.5-2,0-0.9
    		c-0.2-0.2-0.5-0.5-0.7-0.7c0.9,0.9,1.4-1.7,3.2-0.9c-0.3-1.5,2-2,3-2.2c-0.1-0.3-0.1-0.5-0.2-0.8c1,0,1.4-0.6,2-1.3
    		c0.9-1.1,2.3-1.6,3.3-2.6c0.6-0.7,0.9-1.6,1.7-2.2c1.2-1,2.5-0.3,3.8,0c-0.2-0.2-0.3-0.5-0.5-0.7c0.4-0.1,0.7-0.4,1-0.6
    		c-0.4-0.5-1.5-0.8-1.4-1.3c0.2-0.8,0.6-1.7-0.1-2.3c-1.1-0.9-4.2-0.7-4.5-2.4c-1.1,0.7-2,0.1-2.7-0.8c-0.8-1-1.6-1.6-2.5-2.5
    		c-1.5-1.5,2.1-3.7,0-5.5c-0.4-0.3-1.7-1.3-1.4-1.8c0.2-0.4,1.3-1.4,0.5-1.7c-1.1-0.4-1.5,0-1.6-1.5c0-0.5,0-1-0.1-1.4
    		c-0.1-0.5,0.9-0.4,0.8-0.6c-0.2-0.9-2.1-1.4-2.8-1.8c-0.6-0.4-0.7-1.1-1.5-1.4c-0.8-0.3-1.7-0.3-2.5-0.5c-1.5-0.3-3.1-1-4.4-1.6
    		C1440.4,119.2,1438.3,117.3,1436,117"
          />
          <path
            id="Finland_Eckero"
            class="countryIsland"
            d="M1435.5,183.6c-0.6,0.6-0.6,0.8,0.3,0.9C1435.8,184.2,1435.7,183.9,1435.5,183.6"
          />
          <path
            id="Finland_Fasta_Aland"
            class="countryIsland"
            d="M1437.2,185.1c1,0.2,1-0.8,0.6-1.4c0.5-0.1,1,0.8,1.5,0.6c1-0.5-2.1-1.6-2.6-1.5
    		c0.5,0.4,0.5,1,0.1,1.5c0-0.6-0.4-1-1-0.7C1436,184.3,1436.3,185.4,1437.2,185.1"
          />
          <path
            id="Finland_Lemland"
            class="countryIsland"
            d="M1437.9,185.4C1440.6,186.5,1438.8,184.6,1437.9,185.4"
          />
          <path
            id="path4636"
            class="countryIsland"
            d="M1440,186c0.3,0.1,0.5,0.1,0.8,0C1440.5,186,1440.2,186,1440,186"
          />
          <path
            id="path4638"
            class="countryIsland"
            d="M1443.6,161.2c1.5-0.2,0.4-1.6-0.7-0.6C1443.1,160.8,1443.4,161,1443.6,161.2"
          />
          <path
            id="path4642"
            class="countryIsland"
            d="M1446,181.7c0.3,0,0.5-0.1,0.8-0.1C1446.2,181.1,1446,181.2,1446,181.7"
          />
          <path
            id="path4644"
            class="countryIsland"
            d="M1448.4,159.5c-0.3,0.1-0.5,0.2-0.8,0.3C1447.9,159.8,1448.2,159.7,1448.4,159.5"
          />
          <path
            id="path4646"
            class="countryIsland"
            d="M1449.3,159.8c-0.2,0-0.5,0.1-0.7,0.1C1449.3,160.6,1449.5,160.5,1449.3,159.8"
          />
          <path
            id="path4648"
            class="countryIsland"
            d="M1449.9,182.8c-0.2-0.2-0.5-0.5-0.8-0.6c-0.6,1,0.6,1.3,1.3,1.1
    		C1450.2,183.2,1449.9,182.8,1449.9,182.8"
          />
          <path
            id="path4650"
            class="countryIsland"
            d="M1449.7,184.6c-0.2-0.1-0.4-0.3-0.7-0.3C1449.2,184.4,1449.5,184.5,1449.7,184.6"
          />
          <path
            id="path4652"
            class="countryIsland"
            d="M1450,184.5c0.5,0.2,0.7,0.1,0.7-0.3C1450.4,184.3,1450.2,184.4,1450,184.5"
          />
          <path
            id="path4660"
            class="countryIsland"
            d="M1452.9,156c-0.3,0-0.5,0.1-0.8,0.1c0.2,0.2,0.5,0.3,0.8,0.4
    		C1453,156.2,1453.3,155.9,1452.9,156"
          />
          <path
            id="path4662"
            class="countryIsland"
            d="M1452.3,183.4c0.2,0,0.5,0.1,0.7,0.1C1452.7,182.9,1452.7,183.2,1452.3,183.4"
          />
          <path
            id="Finland_Kimitoon"
            class="countryIsland"
            d="M1452.7,186c0.6-0.1,1.2,0,1.7,0c1.1-0.1,0.7-1.7,1.6-2.2c-0.8,0.5-2.1,0.8-3.1,0.6
    		C1454.6,184.9,1452.3,184.7,1452.7,186"
          />
          <path
            id="path4666"
            class="countryIsland"
            d="M1455.2,186c0.4,0.7,1.7,1.2,1.7,0C1456.4,186.1,1455.8,186.1,1455.2,186"
          />
          <path
            id="path4670"
            class="countryIsland"
            d="M1461.7,147.1c1.4,0.7,1.3-0.6,2.3-0.2C1463.6,146.3,1461.9,146.3,1461.7,147.1"
          />
          <path
            id="path4688"
            class="countryIsland"
            d="M1451.6,183.9c-0.3-0.2-0.5-0.3-0.9-0.2c0.5-0.2,1.2-0.4,1.6,0.1
    		C1452,183.8,1451.8,183.8,1451.6,183.9"
          />
          <path
            id="path4690"
            class="countryIsland"
            d="M1452.4,183.1c0.1-0.2,0.2-0.2,0.4-0.2C1452.7,182.9,1452.5,183,1452.4,183.1"
          />
        </g>
        <path
          id="sy"
          class="countryGroup country"
          d="M1562.8,410.7c0.6-0.8,1.8-2.4,2.9-2.7c-1.7-1.6,0.7-2.6,2.2-2.2c-1.3-1.5,0.6-1.7,1.1-3.2
    	c0.2-0.8-0.8-2.4-1.7-2.6c-1.1-0.2,0.9-1.7-0.7-1.3c-2.7,0.8-2.9-1-3.1-2.4c-0.1-1.3,0.7-3.6-0.4-4.6c-0.9-0.8-0.2-2.8-0.2-3.9
    	c0-0.8,1.5,0.5,1.8,0.6c0,0.1,0.4-0.7,1-1.1c0.8-0.5,0.3-1.6,0.4-2.4c0.7,0.2,1.4,0,2.2-0.1c-0.6-1.2-0.9-1.5-1.1-2.8
    	c-0.1-0.5,0-1.9,0.7-2.1c0.5-0.1,1.1-0.1,1.5,0.1c1.2,0.6,1.4,1.6,2.6,1.4c1.4-0.2,2.4-0.4,3.8-1c1.4-0.6,2.4-1.5,4-1.2
    	c1.2,0.2,1.9,1,2.8,1.5c1.1,0.5,3.3,0.3,4.6,0.2c3.1-0.3,5.3-2,8.2-3.2c2.8-1.1,6,0.5,8.9-0.7c1.5-0.6,3.7-2.9,3.9,0.7
    	c0,0.8-2,2.5-2.6,3.1c-1.1,1-2.2,1-3.4,1.7c-2.6,1.5,0.1,4.8,0.3,6.6c0.1,1.1-0.5,2.4-0.6,3.5c-0.1,1.6,0,4.3,0,5.9
    	c0,3.8-16,11-24.1,16.6c-2.2,1.5-5.8,5.1-8.6,3.1c-1-0.7-1.8-1.3-2.9-2c-1.9-1.3-2.7-0.3-3-0.4
    	C1563.1,415.6,1563.9,411.1,1562.8,410.7"
        />
        <path
          id="kg"
          class="countryGroup country"
          d="M1796.3,352.1c1.1-2,3.4-1.4,4.5-0.9c0.5,0.2,3.2,1.1,2.2,2c1.1-0.3,2.3-2,2.9-2.5c1.2-1,1.4-1.2,3-0.6
    	c0.4,0.2,0.8,0.6,1.2,0.8c0.6,0.2,0.5-0.9,1.1,0c0.3,0.5,1.5-0.4,2.2-0.4c-2.2-1.1,2-1.4,2.4-1.4c-0.3-0.5-0.6-1-0.5-1.7
    	c1.2,0.5,2.1,0.8,2.7-0.6c0.2-0.3,2.2-1,1.4-1.4c-0.4-0.2-1.7-0.4-2.2-0.3c-1.6,0-2.6-2.1-4.4-1.1c-0.1-1.8-1.1-1-2.2-1.9
    	c-1.1-0.9-2.4-1.8-3-2.8c0.1,0.6,0.4,1.4,0.1,2c-0.2,0.3-0.5,0.5-0.9,0.5c0.2,0.4,0.2,0.8,0,1.2c-1.3-1.2-3.9,0.3-4.8-1.7
    	c-0.4-1.1-0.9-1.2-1.9-0.9c-0.8,0.3-1.8-0.8-2.7-0.8c0.4-2.4,4.5-4.4,6.1-5.8c-2.1-1.1-3.5,0.1-2.3-2.6c0.3-0.7,0-1.3,0.8-1.7
    	c0.8-0.5,2-0.2,2.7-0.7c1.5-1,5.4,0.4,6.9,0.9c0.6,0.1,1,0.3,1.5,0.7c0.9,0.7,1.3,0.4,2.4,0.5c1,0.1,2,1.4,3,1c-1.1-1-2-4.6-0.4-5.5
    	c0.4-0.2,3.4-0.9,3.1-1.6c2.1,0.7,4.2,1.8,6.2,2.8c0.8,0.4,4.3,1.6,5.2,1.2c0.7-0.4,0.1-1.2,1.1-1.2c1.4,0,2.9,0.5,4.3,0.4
    	c1-0.1,2-0.6,3.1-0.6c1.1,0,2.1,0.8,3.2,0.5c1-0.3,2.2,0,3.2,0.2c1.3,0.2,2.6,0,3.9,0.1c2.2,0.1,5.4,1,7.1,2.4
    	c0.7,0.6,1,1.4,2.1,1.4c0.9,0,1.5,0,2.3,0.5c1,0.6,2.6,1.5,3.1,2.6c0.1,0.3-2.7,1.3-3.2,1.7c-1.8,1.5-4.5,2.1-6.4,3.4
    	c-0.9,0.6-1.4,0.9-1.4,2c0,0.9-0.7,2-2,1.9c-1.1-0.1-1.9,0.2-3,0.3c-1.2,0.1-2.3-0.2-3.5-0.3c-1.6,0-1.5,1.9-1.7,3.2
    	c-0.5,3.5-2.3,2.2-4.3,2.8c-2.3,0.7-2-1.6-3-2.4c-0.6-0.5-1.6,1-2.1,1.3c-1.1,0.6-1.8-0.1-3-0.3c-0.1,0.5-0.1,1,0.2,1.4
    	c-0.9,0-1.5,0.9-2,1.5c-0.7,1-2.2,0.4-3,1.4c-1.1,1.5,1.7,3.3-0.2,4.5c-0.8,0.5-1.7-0.4-2.5,0.3c-0.9,0.8-2.2,0.6-3.3,0.5
    	c-1.3-0.2-4.2-0.4-4.5,1.6c-1.7-1.6-2.9-0.5-3.9-1.4c-0.1,0.2-0.1-0.5-0.5-0.9c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.3-0.2-0.7-0.6-1
    	c-0.3-0.2-3,2-4.1,1.8c-0.8-0.1-1-0.4-1.5-1c-0.8-0.9-2.1-0.5-3.1-0.2c0-0.3-0.1-0.5-0.1-0.8c-0.6,0.4-1.4,0.5-2.4,0.4
    	c-2.2-0.3-2.8,0.9-3.8,0.3c-1-0.7-1.3-5,0.1-3.2C1796,352.8,1796.2,352.3,1796.3,352.1"
        />
        <g id="sb" class="countryGroup">
          <path
            id="path4726"
            class="countryIsland"
            d="M2494.8,767.4c0-0.5,1.1-1.4,0.7-1.9c-0.2-0.2-0.9-1.4-1.2-1.4
    		C2492.6,764.2,2494,766.9,2494.8,767.4"
          />
          <path
            id="path4728"
            class="countryIsland"
            d="M2493.8,769.6c0.2-0.8,0.3-1.6-0.3-2.2C2493.5,768.1,2493.4,768.9,2493.8,769.6"
          />
          <path
            id="path4730"
            class="countryIsland"
            d="M2496.4,760.2c0.9,1.4,3.1,3.1,4.9,2.3c-0.6-0.6-1.4-0.9-1.9-1.5c-0.7-0.8-0.7-2-1.4-2.8
    		c-1.1-1.1-2.6-1.9-4-2.4C2493.1,757.3,2495.8,759,2496.4,760.2"
          />
          <path
            id="path4732"
            class="countryIsland"
            d="M2498,769.1c0.6-0.5,0.8-2.5-0.3-2.5C2495.9,766.4,2496.6,769,2498,769.1"
          />
          <path
            id="path4734"
            class="countryIsland"
            d="M2499.3,774.1c-0.8-1,0.7-1.7,0.1-2.7C2498,772.4,2498.4,772.9,2499.3,774.1"
          />
          <path
            id="path4736"
            class="countryIsland"
            d="M2502.5,772.8c0.9,0.3,1-0.5,0.9-1.2c-0.2-1.2-1.2-1.6-1.9-2.5c-0.3-0.5-0.2-2.2-1.4-1.2
    		c-0.6,0.5-1.9,1.7-1.7,2.6c0.9,0.2,1.9-0.8,2.7,0.2c0.1,0.3,0.1,0.5,0,0.7C2501,772,2502.2,772.6,2502.5,772.8"
          />
          <path
            id="path4738"
            class="countryIsland"
            d="M2501,774.7c0.2-0.7-0.1-0.8-0.7-0.3C2500.5,774.5,2500.8,774.6,2501,774.7"
          />
          <path
            id="path4740"
            class="countryIsland"
            d="M2501.8,763.1c0.2-0.7-0.1-0.8-0.7-0.4C2501.3,762.9,2501.6,763,2501.8,763.1"
          />
          <path
            id="path4742"
            class="countryIsland"
            d="M2502.8,763.3c0.7,0.1,0.7-0.1,0-0.7C2502.8,762.8,2502.8,763,2502.8,763.3"
          />
          <path
            id="path4744"
            class="countryIsland"
            d="M2503.9,774.5c1.3-0.4,0.5-1.2,0.7-2.2C2503.2,772,2502.8,773.8,2503.9,774.5"
          />
          <path
            id="path4748"
            class="countryIsland"
            d="M2508.1,764c-0.3-0.3-0.1-0.5-0.7-0.4C2507.6,763.7,2507.8,763.9,2508.1,764"
          />
          <path
            id="path4750"
            class="countryIsland"
            d="M2508.9,764.8c-0.3-0.6-0.9-0.9-1.5-0.5C2507.9,764.5,2508.4,764.6,2508.9,764.8"
          />
          <path
            id="path4752"
            class="countryIsland"
            d="M2512.9,768.8c1.4,1.5,3.5,2.1,5,3.5c0.8-1.8-1.8-4.5-3.4-4.8c-1.1-0.2-2.3-1.5-3.1-2.3
    		c-0.4-0.4-0.7-0.9-1.3-1.1c-0.6-0.2-1.1-0.1-1.6-0.5C2509.2,765.7,2510.8,768.1,2512.9,768.8"
          />
          <path
            id="path4754"
            class="countryIsland"
            d="M2512,777.4c0.4-0.2,0.6-0.3,0.6-0.9C2511.9,776.5,2511.7,776.8,2512,777.4"
          />
          <path
            id="path4756"
            class="countryIsland"
            d="M2518.3,783.6c1.3,0.2,2.2-0.2,3.4,0.5c0.7,0.4,2.2,0.9,2.6-0.3c0.4-1.4-1.9-3.5-3.2-3.6
    		c-1.2-0.1-2.8,0.6-3.7-0.6c-0.5-0.8-1.2-1.1-1.9-0.1C2514.6,780.9,2516.9,783.2,2518.3,783.6"
          />
          <path
            id="path4758"
            class="countryIsland"
            d="M2516.9,772.5c-0.2-0.5-0.3-0.9-0.2-1.4C2515.4,771.1,2516.2,772.2,2516.9,772.5"
          />
          <path
            id="path4760"
            class="countryIsland"
            d="M2516.4,798.7c0.5,0.2,1.6,0.3,1.9,0.5c0.8,0.5,0.7,1.6,1.7,1.8
    		C2521,799.6,2516.1,797.1,2516.4,798.7"
          />
          <path
            id="path4764"
            class="countryIsland"
            d="M2520.9,777.6c0.4-0.6-0.1-1.2-0.8-1.2C2520,777,2520.3,777.5,2520.9,777.6"
          />
          <path
            id="path4766"
            class="countryIsland"
            d="M2520.7,778.2c0.4-0.3,0.9-0.5,1.3-0.7C2521.4,777.3,2520.9,777.6,2520.7,778.2"
          />
          <path
            id="path4768"
            class="countryIsland"
            d="M2525.4,777.7c1.5,1.1,2.2,2.8,3.4,4.1c0.4-0.9-0.4-4.1-0.8-5c-0.2-0.6-0.9-1.7-1.4-2
    		c-0.9-0.5,0.6-1.2,0.2-1.8c-0.4-0.6-0.6-1.7-1.3-2.2c-0.3-0.2-1.7-0.8-1.4,0.3C2524.7,773.2,2524.4,775.7,2525.4,777.7"
          />
          <path
            id="path4770"
            class="countryIsland"
            d="M2531.3,791.6c1.2,0.4,2.6,0.4,3.8,0.7c-0.8-2.4-1.4-2.6-3.4-3.7c-0.6-0.3-3.7-2.6-4.1-1.1
    		c0.5,0.3,1.1,0.5,1.3,1.1C2529.4,790.1,2530.1,790.7,2531.3,791.6"
          />
          <path
            id="path4772"
            class="countryIsland"
            d="M2530.1,783.3c0.5-1.2-0.2-2.5-1-3.4c0.1,0.7,0.1,1.4-0.1,2
    		C2529,782.5,2529.9,782.9,2530.1,783.3"
          />
          <path
            id="path4774"
            class="countryIsland"
            d="M2561.9,792.6c0.6-0.8,2.1-0.5,2.1-1.7c-1.1-0.3-2.3,0.1-2.9,1.2
    		C2561.4,792.3,2561.7,792.5,2561.9,792.6"
          />
          <path
            id="path4778"
            class="countryIsland"
            d="M2568.3,799.8c0.2-0.1,0.4-0.2,0.6-0.3c-0.2-0.6-0.8-0.9-1.3-0.4
    		C2567.8,799.3,2568,799.5,2568.3,799.8"
          />
        </g>
        <g id="om" class="countryGroup">
          <path
            id="Oman_Musandam"
            class="country"
            d="M1718.7,473c0.2-0.6,0.5-1.1,0.8-1.7c0.7,0.8,1.3-0.3,1-0.9c0.6,0.3,1.8,2.3,0.3,1.9
    		c0.9,0.7,0.2,4.7-0.8,4.5C1718.6,476.6,1720.5,473.1,1718.7,473"
          />
          <path
            id="path4786"
            class="country"
            d="M1692.8,533.3c10.5-2.4,17.1-5,21.9-7.5c1.9-3.7-0.2,2.1,0.8-2c0.8-3.4,1.3-6.8,2.1-10
    		c0.5-2.2,1.8-5,0.5-7.2c-0.9-1.5-2.8-2.8-3.4-4.5c-0.7-2.1,3.3-9.4,1.3-10.6c0.9-1.1,2.6-0.1,3.4-1.2c0.8-1.1-0.5-0.7-0.8-1.3
    		c-0.7-1.5-1.3-4.7-0.3-6.3c0.5-0.7,1.4,0.4,1.2,0.9c-0.5,1.7,1.3-0.1,1.6-0.4c0.8-0.8,0.8-0.7,1.4,0.5c0.9,1.9,2.1,3.7,3.5,5.3
    		c2.2,2.5,5.4,3.4,8.6,4c1.5,0.3,2.8,0.9,4.3,1.1c2,0.2,3.1,2.7,4.3,4.1c0.9,1.1,1.6,2.5,2.5,3.5c1,1.1,1.5,1.2,2.8,1.7
    		c3,1.2-0.4,5.5-1,7.2c-1.1,3.3-4.7,5.1-5.7,8.7c-0.2,0.8-0.5,3.3-1.9,2.9c-1.1-0.3-0.9-2.3-2.2-1.5c-1.6,1-1.5,4.5-1.9,5.9
    		c-0.6,1.9,0.1,3.4,0.6,5.2c0.4,1.5,0.7,2.6-1.2,2.7c-2.9,0.1-6.1,0.9-6.8,4.1c-0.3,1.2-0.5,2.6-1.3,3.6c-1,1.3-2.9,1.2-4.4,1.3
    		c-1.8,0.1-2.6,0.4-3.7,1.9c-1.1,1.4-0.4,1.7-0.4,3.3c-0.1,1.3-1.4,2.2-2.4,2.7c-0.5,0.2-1,0.6-1.5,0.2c-0.9-0.7-1.6-0.5-2.7-0.5
    		c-3.2,0-5.9,3.1-9.1,3c-2.1-0.1-1.1-5.1-3.3-5.4c0.9-1-1.3-4.4-1.8-5.3C1695.8,540.5,1693.9,536.5,1692.8,533.3"
          />
          <path
            id="Oman_Masirah"
            class="countryIsland"
            d="M1742.1,524.1c0.7-0.6,0.9-1.4,1.2-2.2c0.2-0.2,0.4-0.4,0.7-0.6c0.3-0.3-0.4-1.3-0.5-1.7
    		C1743.1,521.2,1741.4,522.3,1742.1,524.1"
          />
        </g>
        <g id="pa" class="countryGroup">
          <path
            id="Panama_mainland"
            class="country"
            d="M668.7,627c-0.7-0.7,1.1-1.5,1.2-2.1c0.2-1.2-1-2.5,0.7-3.2c1.3-0.6-1.1-1.4-1.1-1.9
    		c0-1,0.8-3.4,0.6-3.8c1-0.9,1.9,1.2,2.5-0.1c0.5,0.4,1.2,1,1.4,1.7c0,0.5,0,1-0.1,1.4c0.1,0.4,0.8,0,0.7,0.5
    		c-0.2,2.4,2.1,1.5,3.6,1.8c-0.3-0.5-0.6-1-0.9-1.4c1.2-0.4,2.5,2.6,3.9,2.8c3,0.4,4.7-1.9,7.3-2.9c1.8-0.7,3.8-1.2,5.1-2.5
    		c0.7-0.7,1.1-1.7,2.3-1.7c1.3,0,2.6,0.6,3.9,0.4c-0.2,0.3-0.5,0.6-0.6,0.9c2.6-0.3,6,0.3,7.9,2.3c0.9,0.9,2.9,4.8,4.5,4.3
    		c-1.2,1.9,0.2,2.7,0.8,4.6c0.7,2.1-1.5,4.2-3,5.2c-0.2-0.5-0.4-1-0.8-1.4c-0.5,0.8-0.2,0.9-0.3,1.5c0,0.6-0.8,1.2-1.2,2.5
    		c-2-1.4-3.7-4.8-3.8-7.2c1.6,0.6,1.1-1.4,1.8-2.1c0.8-0.8,2.8,1.2,3.5,1.6c-0.5-0.5-1.1-0.9-1.6-1.3c-0.8-0.6-0.6-1.7-1.1-2.4
    		c0.2,0.6-0.1,1.1-0.6,1.4c0.3-1.5-0.9,0.1-1.4-1.3c0.2,0.6,0.1,1.2-0.1,1.7c-0.8-1.5-0.9-3.1-2.2-3.9c-0.6-0.4-1.2-0.8-1.7-1.2
    		c-1-0.8-0.5-0.8-1.6-0.7c-1.6,0.1-4.2,0.4-4.6,2.4c-0.3,1.5-2.5,4.1-4.4,3.8c-2.9-0.5,0.2,3.9,0.9,4.6c0.5,0.6,1.6,1.7,1,2.5
    		c-0.5,0.7-1.8,0.2-2.5,0.8c-0.9,0.8-2.9,2-4.2,1.3c-0.3-0.2-0.2-2.2-0.4-2.6c-0.4-0.9-0.1-2.4-0.7-3.2c-0.4,0.5-0.8,0.5-1.2,0.1
    		c1.2,2.4-1.5,3-2.6,1c-1-1.7-0.9-3-2.9-3.7c-0.7-0.2-1.3,0.3-1.8-0.4c-0.6-0.9-1.3-0.2-1.8-0.9c-0.7,1-1.8,0-2.7,0.4
    		c-1,0.5-0.3,1.7-1,2.4C669.2,628.2,669,627.5,668.7,627"
          />
          <path
            id="path4792"
            class="countryIsland"
            d="M673,627.2c0,0.5,0.2,0.5,0.5-0.1C673.3,627,673.2,627,673,627.2"
          />
          <path
            id="path4794"
            class="countryIsland"
            d="M677.8,632.4c-0.5,0.5-1.2,0.8-1,1.6c0.2,0.7,0.9,1.1,1.5,0.6
    		C677.8,634,677.9,633.2,677.8,632.4"
          />
          <path
            id="path4796"
            class="countryIsland"
            d="M681.4,633.9c0.6-0.2,1-0.5,1.4-0.9C682.3,633.2,681.8,633.5,681.4,633.9"
          />
          <path
            id="path4802"
            class="countryIsland"
            d="M700,625.4c-0.7,0.1-0.8,1-0.6,1.5C700.4,627,700.2,626,700,625.4"
          />
        </g>
        <g id="ar" class="countryGroup">
          <path
            id="path4518"
            class="country"
            d="M788.7,951.8c0.1-0.7,1-0.9,1.1-1.6c0.2-0.9-0.3-2.3-0.3-3.2c0.1-2.8,1.7-3.9,2.7-6.3
    		c0.7-1.5,0.7-3.1,1-4.7c0.2-0.8,0.4-1.7,0.8-2.4s1.3-0.1,2-0.5c3-1.4-0.1-3.2-1.1-4.8c-1.1-1.8,0.9-2.9,0.2-4.6
    		c-0.5-1.2-4-7.5-1-7.7c-0.3-0.8-1.9-2.6-1.7-3.4c0.6-1.8,1.7-3.1,3.4-3.8c1.5-0.7,4-1.3,4.7-3c0.7-1.7,0.6-3.9,0.9-5.7
    		c0.1-0.7-0.1-2.7-0.4-3.4c-0.2-0.5-1.3-0.2-1.2-0.7c0.6,0,2.4-4.4,4.2-5.4c2-1,2.1-1.2,2.4-3.6c1.6,0.3,2.5,2,3.9,2.6
    		c1.7,0.7,3.6-0.2,5.4,0.1c4.2,0.6,4,3.8,6.1,6.8c0.1-1.3,0.1-2.6,0.6-3.9c0.3-0.7,0.8-3.1,1.5-3.4c0.5-0.2,1.5-0.1,2.1-0.3
    		c1.1-0.3,2.5-0.1,3.6-0.1c0.7,0,2.2-0.4,2.4,0.4c0.3,1.1,2.4,2.3,3.2,3.1c0.9,0.9,1.5,1,2.1,2.2c0.4,0.9,1,1.8,1.6,2.6
    		c1.4,1.8,3.9,3.1,5.8,4.5c1.1,0.8,1.6,1.9,2.6,2.7c0.9,0.8,2.2,0.7,3.2,1.1c1.4,0.6,2.4,0.9,4,0.9c1.2,0,2.1,1.1,3.1,1.6
    		c2.6,1.3,4.6,3.8,7.5,4.8c0.8,0.3,1.8,0.4,2.5,1c1,0.9,1.4,0.4,2.4,1c1,0.6,2.2,0.7,3,1.6c0.5,0.7,1.1,1.2,1.6,1.9
    		c1,1.3-0.9,2.7-1.1,4.2c-0.4,2.2-2.4,2.8-1.9,5.4c0.4,2.4-1.4,3.1-1.9,5.2c-0.3,1-0.6,0.7-0.1,1.9c1.5-0.6,3-0.6,4.6-0.3
    		c2.7,0.5,5.2,2.1,7.9,1.2c0.8-0.3,1.2,1,2.1,0.4c0.8-0.6,1.7,0.8,2.4,1c0.5-1,0.5-2.3,1.9-2.5c1-0.1,1.5,1,2.4,0.9
    		c1.4-0.2,1.2-1.9,1.5-2.8c0.4-1.1,2.7-1,2.5-1.6c0-0.2,1.3-2.1,1.5-2.1c1,0.2,0.8-2.2,0.9-3c0.2-1.6,0.2-3.4-0.2-5
    		c-0.4-1.6,0.6-1.1,1.5-0.5c0.2-1.3,1.9-1.2,2.8-0.8c1.6,0.6,1.9,2.2,2.4,3.6c0.7,2,0.9,3.1,1,5.3c0,1.1,0.3,2.1,0.1,3.3
    		c-0.1,0.6-0.2,1.1-0.3,1.7c-0.4-0.1-0.7-0.1-1,0.1c-1.2,0.7-2,1.6-3.3,2.1c-0.6,0.3-2.8,1-2.9,1.9c-0.1,1.3-1.6,1.4-2.5,2
    		c-0.5,0.3-0.6,1-1.1,1.5c-0.7,0.7-1.5,1.1-2.1,1.8c0.6,0.2,0.9,0.7,0.6,1.3c-1.3-1.4-3.9,4.1-4.7,5.1c-1.3,1.8-1.6,5.6-4.1,6.6
    		c-1.1,0.4-1,1.9-1.5,2.8c-0.3,0.5-0.7,0.7-1.2,0.8c0.3,0.8,0,1.8-0.6,2.3c-1.6,1.4,0.3,1.6,0.5,3.2c0.1,0.8-0.4,0.9-0.4,1.5
    		c0,0.7,0,0.9,0.1,1.6c0.1,0.7,0,1.3-0.3,2c-0.6,1.3,0.6,0.6,0.5,1.8c-0.1,1.6-1,1.8-0.7,3.6c0.3,1.5,1.1,2,0.7,3.5
    		c-0.4,1.3,0.5,3,1.1,4.1c1.2,1.9-1.5,1.2-1.3,2.4c0.1,1.5-0.7,3,0,4.5c0.4,0.8,3.5,5.3,1,5.2c1.1,0.9,1.3,2.1,2.6,3
    		c1.5,0.9,3,1.7,4.5,2.4c2.6,1.2,6.2,3.2,4.8,6.4c-1,2.3,2,7,4.8,5.8c1.1-0.5,1.6,2.8,1.8,3.4c0.7,1.8-0.5,3.7-1.2,5.3
    		c-0.7,1.5-2.3,3.5-2.4,5.1c0,1.7,0.1,2.2-1.4,3.3c-2.8,2-6.1,3.1-9.4,3.7c-4.1,0.8-8,2-12.2,2.2c-1.6,0.1-3,0-4.5-0.1
    		c-1.8-0.2-3-1.3-4.7-1.5c0.2,0.5,0.6,0.9,1,1.2c0.4,0.4,0,1.3,0.2,1.8c0.1,0.3,3.4,2.7,1.3,1.9c1,0.7,1.4,1.2,1.6,2.4
    		c0.2,0.9,0.3,2.3-1,1.6c0.2,0.9,0.4,1.8,0.4,2.7c0,0.7-0.8,1.3-0.2,2c0.4,0.5,0.9,0.8,1.4,1.2c0.7,0.6,0.3,1.6,1.3,1.1
    		c-0.6,3.5-4.2,4.1-7.2,4.3c-3.9,0.4-7.2-2.3-11.1-2.9c1.6-0.3-0.1-0.9-0.6-0.6c-0.7,0.3-1.1,1.2-1.1,2c-0.1,2.1,2.5,3.7,2.9,5.7
    		c0.3,1.7,0.4,3.8,2.2,4.5c1,0.4,3.6,0.6,2.8,2.2c0.4,0.1,4.1,0.5,3.8-0.4c-0.2-0.8-1.9-0.8-2.5-1.1c1.2,0.2,1.9-1.1,3-1.2
    		c1.2-0.1,2.4,1.6,2.6,2.6c0.2,0.9,1.4,2.3,0.7,3.3c-0.5,0.7-2.1,0.9-2.9,0.8c-1.2-0.2-1-1.5-1.6-2.1c-1-1-2.3-1.4-3.5-0.6
    		c-3.2,2.1,2.1,3.9,3.9,3.4c-0.2,1-1.2,1.3-2,1.7c-1.6,0.7-1.5,1.4-2.1,2.8c-0.6,1.5-0.7,2,0.3,3.4c0.4,0.5,0.8,1.1,0.9,1.7
    		c-0.1,0.4-0.1,0.8,0,1.3c0.4,0.4,0.7,0.9,0.7,1.5c0,0.3,0.1,1.2-0.3,1.4c-0.6,0.2-1.3,0.4-1.4,1.1c-0.1,1.2,1.7,1.4,1.5,2.3
    		c-0.2,1-7.4-0.4-5.8,2.2c-3.1-0.7-3.9,3.1-4.3,5.3c-0.7,3.8,1.8,5.3,4.6,7.2c1.4,0.9,2.6,2.2,4.2,2.9c2,0.9,4.4-0.3,6.3,0.8
    		c2.6,1.6,3,6.8-1.1,6.3c0.8,0.3,1.3-0.4,2-0.4c1.1-0.1,1.5,0.7,2.2,1.4c-0.6,0.1-0.8,0.4-0.5,1c-1.2-0.2-3.6,2.8-4.3,3.6
    		c-1,0.9-1.7,1.9-2.4,3c-0.5,0.8-0.9,1.3-0.9,2.2c0,0.8,0.1,1.5-0.6,2.1c0.6,0.1,1-0.2,1.2-0.8c0.1,2.1,2.4,5.8-0.8,6.9
    		c-2.4,0.8-3.1-2.8-5.1-3.2c0.5,0.2,1.9,1.2,1.4,1.9c-0.3,0.5-1.7,0.1-2.2,0.5c2-0.8,2.8,0.5,4.5,0.9c-0.2,1.3-2.3,1.2-2.8,2.7
    		c-0.6,2,0.7,3.3-0.4,5.3c1.5-1.8,3.6,2.2,4.1,3.2c0.8,1.6-2.5,0.7-3.3,1.3c0.9-0.3,2.9,0.5,3.2,0.3c1.2-0.7,2.1,0.9,3,1.6
    		c1.7,1.4,3.6,2.4,4.4,4.4c-0.4-0.7-1.4-0.7-2.1-0.8c-1.3-0.1-2.5-0.8-3.7-1.2c-3.1-0.9-6.4-1.3-9.6-1.3c-1.4,0-2.8,0-4.3,0
    		c-1.2,0-3.7,0.5-4.7-0.2c-1.6-1-3-3.2-5-3.5c0.7-1,0-2.5-0.8-3.2c-1.1-0.8-0.2-1.8-0.8-2.8c-0.4-0.6-1.5-2.2-2.4-1.8
    		c-0.7,0.3-1.3-0.1-2,0c-0.5,0.1-0.8,0.8-1.2,1c-0.9,0.5-2.3-2-2.6-2.6c-0.6-1.2-3-2-3.2-3.2c-0.1-0.6-0.4-0.7-0.7-1.2
    		c-0.2-0.4,0-1-0.2-1.4c-0.2-0.6-1-0.9-1.2-1.5c-0.3-0.7,0-1.5-0.2-2.2c1.2,0.2,1.3-0.5,1.6-1.4c0.2-0.5-0.1-1,0.3-1.4
    		c0.4-0.4,1.1-0.1,1.5-0.6c0.8-0.9-0.1-2.4-0.7-3.2c-0.7-0.9,1.1-1,1.2-1.7c0.1-0.8-1.1-1.9-1.7-2.3c-1.2-0.8-1.4-1.4-1.4-2.8
    		c0-1,0.2-4.5,2-4.2c-0.4-0.8-1.3-0.9-1.3-1.7c0-0.5-0.5-1-0.3-1.5c0.2-0.4,0.6-0.6,0.8-1c0.8-1.6-1.9-4.1-3-4.9
    		c1,0,1.6-1.9,0.5-2.2c-0.8-0.2-2-2-1.2-2.7c0.7-0.7,0.9-0.2,1.4-1.3c0.4-0.9,0.6-0.9-0.2-1.7c-0.7-0.7-1.5-1.3-2.2-1.8
    		c-1.1-0.8-2.7,0-3.7-1.1c-0.9-0.9,4.7-0.5,5.2-1.2c-0.2,0.3,0-1.8,0.1-1.5c-0.8-2.4-4.7,0-5.6-2.3c-0.2-0.4-0.5-0.8-0.7-1.1
    		c-0.2-0.6,0.8-1.2,0.4-1.8c-0.5-0.8-1.8-1.2-1.1-2c0.9-1.1-3.6-1.9-2-3.3c0.9-0.8-0.2-1.4-1-1.6c-1.5-0.3-2.1-0.8-2.6-2.3
    		c-0.4-1.1-0.4-2.5-0.8-3.6c-0.3-0.8-1.5-1.5-0.4-2.3c0.7-0.5,2-0.1,1.1-1.3c-0.8-1.1-2.5-2.5-2.3-3.9c0.3-2.5-2.3-4.7-2.7-7
    		c-0.2-0.9-0.1-2.1,0.3-2.9c0.7-1.3-1.2-1.6-1.4-2.7c1.7,0.6,0.6-1.9,0.1-2.5c-1-1.2-0.7-1.7,0.7-1.8c-0.9-0.9-0.4-3.3-0.8-4.5
    		c-0.6-1.8,1.2-2.2,2.3-3.2c1.2-1.1-1.4-2.8-1.8-4.2c-0.5-1.7-3.1-3.8-2.8-5.5c0.2-1.1-1-3.9-1.5-4.9c-0.6-1.2-0.9-3.7,0.1-4.7
    		c0.5-0.5,0.9,0,1.2-0.6c0.2-0.5,0.2-1.2,0.5-1.6c0.3-0.3,1.1-0.5,1.1-1c0.1-1.2-0.3-2.4-0.8-3.5c-0.4-0.9-0.8-1.8-1.3-2.6
    		c-0.1-0.2-1-0.8-1-0.9c0-0.8,1-0.4,1.1-1.3c0.1-0.9-0.1-1.8-0.2-2.6c-0.1-0.8,0.1-4.8,1.4-4.6c1.2,0.2-0.5-2.9-0.6-3.2
    		c-0.5-1.2,0.1-5.2-1.2-5.6c-0.8-0.2-0.3,1-1.4-0.3c-0.8-1-0.6-1.5-0.4-2.5c0.1-0.8-0.5-1.2-1.1-1.6c-0.7-0.5-0.5-1.1-0.7-1.8
    		c-0.5-1.7-2.2-2.7-2.7-4.4c1.6,0.7-0.2-1.5-0.8-1.8c-1.5-0.8-2-3.2-2-4.6c-0.1-1.8,1.3-1.1,0.9-3c-0.3-1.4,1.1-3.9,0.1-5.1
    		c2.4,1.6,1.5-2.6,1.2-3.5C790.1,956.4,787.9,953,788.7,951.8"
          />
          <path
            id="Argentina_Tierra_del_Fuego"
            class="country"
            d="M865.5,1151.5c1.5,1.3,3.3,2.2,4.4,3.9c-1-1.8-3.3,1.4,0.5,1.4
    		c1.4,0,1.9,1.6,2.8,2.4c1.1,1,2.8,1.2,3.8,2.2c2.8,2.5,6.8,3.6,10.2,5.2c1.5,0.7,3.8,1.6,5.5,1.5c0.8,0,1.7-0.2,2.5-0.2
    		c1.2,0.1,0.9,1.3,0.6,2.3c-0.9-0.5-2.1,0.4-3.2,0c-1.2-0.5-2.9,1.1-4.4,0.8c-1.7-0.4-3.2-0.8-5-0.8c-2-0.1-4.5,0-6.4-0.8
    		c-1.2-0.5-1.9,0.7-3-0.8c-0.9-1.4-1.5-3.2-2.2-4.7C869.5,1159.9,867.3,1155.8,865.5,1151.5"
          />
          <path
            id="path4200"
            class="countryIsland"
            d="M867.4,1036.5c0.1-0.5-0.1-0.6-0.7-0.2C866.9,1036.4,867.2,1036.4,867.4,1036.5"
          />
          <path
            id="path4202"
            class="countryIsland"
            d="M868.6,1038c0.1-0.8-0.8-1.3-1.5-0.9C867.6,1037.5,868.1,1037.8,868.6,1038"
          />
          <path
            id="path4874"
            class="countryIsland"
            d="M900,1170.1c1-1.2,3.1-0.9,4.5-1.4c-1.8-0.5-3.5,0.2-5.2,0.8
    		C899.5,1169.8,899.7,1170,900,1170.1"
          />
        </g>
        <g id="uk" class="countryGroup">
          <path
            id="United_Kingdom_Northern_Ireland"
            class="country"
            d="M1260.6,231c0.6-1,2-1,2.9-1.5c-0.3-0.2-0.7-0.5-0.9-0.8
    		c1.8,0.6,2.6-1.5,3.4-2.5c0.8-1,2.6,0.3,2.8-1.5c1.6,0.8,3.7-1.1,5.3,0c0.7,0.5,1.1,1.5,1.4,2.2c-0.1-0.1,1.2,1.4,1.2,1.1
    		c0,0.3-1.4,1-1.6,1.2c0.5-0.1,2-0.7,2.3,0c0.3,0.7,1,2-0.2,2.3c0.2-0.7-0.1-1.7-1-1.4c0.6,0.3,0.5,0.9,0.1,1.4
    		c0.3-0.2,0.5-0.1,0.8,0.1c0.1,0.5-1.1,1.1-1.4,1.2c-1,0.1-1,1.7-2.2,1.4c-1-0.3-1.2-0.6-2-0.1c-0.7,0.5-1.9,0-1.6-1
    		c-0.4,0-3.2-3-3.3-1.1C1266.2,235.4,1262,232.1,1260.6,231"
          />
          <path
            id="path2640"
            class="countryIsland"
            d="M1268.9,209.2c-0.9-0.3-0.4-1.3,0.4-1.6C1268.1,205.2,1266.9,209.7,1268.9,209.2"
          />
          <path
            id="path2642"
            class="countryIsland"
            d="M1269.2,206c0-0.2,1.3-1.1,0.7-1.3c-0.7-0.2-2.2-0.3-2.2,0.8
    		C1268.3,205.5,1268.8,205.6,1269.2,206"
          />
          <path
            id="path2644"
            class="countryIsland"
            d="M1268.9,206.8c0.2-0.8,0-0.9-0.7-0.4C1268.4,206.5,1268.7,206.7,1268.9,206.8"
          />
          <path
            id="path2646"
            class="countryIsland"
            d="M1270.4,214.5c0.3-0.3,0.7-0.5,1.1-0.7C1270.9,213.7,1270.5,214,1270.4,214.5"
          />
          <path
            id="path2648"
            class="countryIsland"
            d="M1273.1,202.1c1.2,1.6,2.5-1.4,1.2-0.9c0.7-0.1,3-1.1,1.5-1.4c1.3-0.6,1.1-2.4-0.3-1.4
    		c-0.7,0.5-1.5,0.6-2.2,1c-0.8,0.5-0.1,1.3-1.1,1.6c0-0.3,0-0.5-0.1-0.8c-0.6,0.1-1,0.3-1.4,0.7c0.6,0.4,1,0.9-0.1,1.2
    		c0.5,0.1,1,0.3,1.4,0.6c-0.5,0.2-1,0.5-1.4,0.8C1271.2,204.8,1272.8,203,1273.1,202.1"
          />
          <path
            id="path2650"
            class="countryIsland"
            d="M1272,213.5c0.6-0.2,1-0.5,1.3-1C1272.7,212.7,1272.2,213,1272,213.5"
          />
          <path
            id="path2652"
            class="countryIsland"
            d="M1273.6,221.6c1.2-0.5,2.2-1.7,0.6-2.6c-1-0.6-1.6,1.2-2,1.8c0.5-0.3,1-0.5,1.5-0.7
    		C1273.8,220.6,1273.8,221.1,1273.6,221.6"
          />
          <path
            id="path2654"
            class="countryIsland"
            d="M1276.5,209.9c0.8-0.2,2.2-1,2-2c-3.2,1.1-1.8-2.7-3.5-3.3c-0.2,0.5-0.3,0.9-0.3,1.4
    		c-1.4-0.3-1.2,0.6-2.3,0.4c-0.2,1.4,1.6,0.5,2.2,1.2c-1.6,0.1,0,1.1,0.7,1.1c0.7,0,1.5-0.4,2.1-0.1
    		C1276.9,208.8,1276.5,209.2,1276.5,209.9"
          />
          <path
            id="United_Kindgom_Great_Britain"
            class="countryIsland"
            d="M1275.7,267.6c0.9,0.2,2.1-1.5,2.8-2.1c1.2-1,3.7-0.2,4.5-1.7
    		c0,0.9,1.9,1.8,2.6,1.9c1.4,0.1,2.4-2.7,2.3-3.8c0.4,0.8,2,0,2.6-0.2c1.5-0.5,3.1,0.2,4.2,1.2c0.1-0.9,1-0.6,1.7-0.5
    		c0.7,0.1,2.6-0.5,0.8-0.8c0.8,0,6.1,0,4.6-1.4c0.9,0.9,2,0.2,3,0.4c0.3,0,0.4,0.2,0.4,0.5c0,0.4,1.7,0,1.7,0c1.5-0.2,3-0.3,4.5,0.2
    		c1.3,0.4,3-0.6,4.3-0.9c1.1-0.3,1.3-0.6,2.1-1.3c0.8-0.7,2.3-0.6,2.8-1.6c0.5-1.2-5.1-1.4-5.7-1.5c0.4-0.1,0.8-0.3,1.2-0.4
    		c-0.7-0.2-1.5-0.3-2.2,0.1c0.7-0.7,1.5-0.4,2.3-0.6c1.4-0.3,1.3-1.7-0.1-1.8c0.5,0.1,0.9-0.3,1.3-0.5c0.8-0.4,1.5,0.4,2.4-0.4
    		c-0.2-0.5-0.6-0.8-1.2-0.9c0.6-0.1,1.9,0,0.4-0.6c2.1,0.6,2.4-0.7,3.3-2.2c1.2-1.9,1-3.8-1.3-4.9c-1.3-0.6-4.3-1.5-5.6-0.4
    		c-0.4,0.4-0.6,1.2-1.2,1.4c-0.9,0.3-1.4-1.1-2.4-0.9c1.2-1.8,3.6-1.9,1.6-4.5c-1.7-2.1-3.5-2-5.9-2.3c1.6-0.7,4.3-0.1,5.6,0.9
    		c-0.3-1-3.5-3.8-1.2-4.4c-1.5-0.4-1.8-1.9-2.7-2.8c-0.9-0.9-2.3-0.9-3.5-1c-1.9-0.3-2.5-4.9-2.6-6.4c-0.2-2.5-3.3-4.6-5.7-5.1
    		c-0.6-0.1-1.2-1-1.8-0.4c-0.4,0.4-0.8,0.6-1.4,0.6c-1.6,0-3.7,0.1-4.9-1.2c2.4,1.8,4.1-0.7,6.5-0.7c1.4,0,0.9-1.1,0.2-1.6
    		c-1-0.8-2.1,0.5-3.2,0.3c2.8-0.6,5.2-1.9,7-4.1c0.8-1,1.1-2.2,1.8-3.2c0.5-0.7,1.8-1.2,1.4-2.3c-0.7-1.9-4.5-1.2-6.1-1
    		c-1.6,0.2-3.2-0.5-4.8,0c-1.3,0.4-2.9,1.2-4.2,1.5c0.2-0.7,1-0.7,1.2-1.4c-0.8-0.1-1.5,0.3-2.2,0.5c0.9-0.9,3.1-0.3,3.5-1.7
    		c-1-0.2-2.3,0-3.2-0.7c1.7,0.7,1.4,0.2,2.5-0.5c0.9-0.6,2-0.9,3-1.5c0.4-0.2,5.3-3.4,2.7-3.9c-1.6-0.3-3.5,0.4-5,0.7
    		c-0.8,0.2-1.7,0.4-2.6,0.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.3-1.2,0.5-1.6,0.6c0.8-1.3,0.1-0.4-0.5-0.7c-0.2-0.1-0.6-0.6-0.9-0.5
    		c-0.6,0.1-0.4,2-1.3,2.4c0.3,0.1,0.9,0.4,0.9,0.6c-2.2-1-1.5,1.4-2.6,1.7c0.6,0.2,1,0.7,1.2,1.2c-0.7,0.3-3.2-0.7-3,0.8
    		c-0.2-1.3-1.8,0.9-0.1,2c-0.5-0.1-1-0.1-1.4-0.1c-0.1,1.4,1,1.4,2.1,1.3c-0.5,0.2-1,0.4-1.4,0.8c0.6-0.2,1.3-0.1,1.7,0.5
    		c-0.9-1.1-2.2,1-0.1,0.9c-0.2,0.1-3.7,0.2-1.3,1.1c-1-0.9-2.2,0.8-0.8,1.1c-0.6-0.1-0.9,0.2-0.9,0.8c-0.8-0.4-1.8-0.3-2.4,0.3
    		c1.1,0.3,2.7-0.5,3.7,0.2c-0.8-0.4-1.3,0.6-2.2,0.2c0.7,3,4.4-0.6,5.6-1.3c-0.5,0.5-0.8,1.3,0.4,0.9c-0.6,0.1-3.6,1.5-1.7,1.3
    		c-0.2,0.2-0.5,0.5-0.7,0.7c0.5,0,1,0,1.5-0.1c-0.3,0.2-1.7,0.2-1.7,0.3c-0.2,0.3-0.6,0.6-0.9,0.8c-0.4,0.2-0.1,0.9-0.2,1.3
    		c0,0.4-2,3.2-0.3,1.2c-0.8,0.6-0.7,2.8,0.5,1.8c-1.3,0.7-1.4,2.2-2.2,3.3c-0.6,0.8,1.3,1.3,1.5-0.2c0.1-1.1,1.6-2.4,1.2-3.4
    		c-0.5-1.5,1.7-2.4,2.6-2.9c-0.6,0.5-2.2,1.3-2.1,2.4c0.1,0.5,0.4,0.6,0.8,0.4c0.2,0.5,0.6,0.9,0.9,1.2c0.2-0.6-0.2-1.2-0.7-1.5
    		c0.6-0.8,0.9-0.3,1,0.4c1-0.9,0.3-2.6,1.5-2.4c-0.5,0.4-0.6,0.9-0.2,1.4c-0.2-1.4,0.7,0.1,1.5,0.4c-0.6,0-1.8-0.5-2.1,0.3
    		c-0.5,1.6,0.6,1.6,1.1,2.7c0.8,1.9-3.4,3-2.6,5.3c-0.1-0.2-1-1-1.2-0.5c-0.2,0.9,1.3,2.2,1.8,2.8c-0.6-1.7,0.1-2,1.7-1.3
    		c1.3,0.6,1.5,0.5,1.6-1c1.5,1.5,2.4,0.9,4.1,0.4c0.5-0.2,1.3-0.3,1.2-1c1.2,0.1,2.3,0.1,3.5,0.1c-1.3,0.9-2.8,1-3.6,2.5
    		c-0.5,1,0.4,4.3,1.9,3.5c-0.2,0.5-0.1,1,0.3,1.4c0.3-0.4,0.6-0.8,0.8-1.3c0.2,0.9,1,0.4,1.4,0.1c-0.5,0.6-0.5,1.5-1,2.2
    		c-0.3,0.4-1.6,2.1,0.2,1.8c-1.8,0.9-1.2,3.9,0.9,3.2c-1,0.7-3.9-2-2.4,0.9c-2-1.7-4.8,0.1-7,0c0.8-1-1.7-1.9-2.3-1.4
    		c-1.3,1.1,0.8,2.6,2.1,1.5c-1.2,1.3-2.8,2.1-4,3.5c1.5,0.1,2.8-1.5,4.2-0.8c-0.4,0.6,0,1.2,0.5,1.5c-0.9,0.4-0.6,1.5,0.4,1.4
    		c-0.8,0.1-0.9,0.9-1.2,1.5c-0.4,0.8-1.4,1.3-2.2,1.5c-1.7,0.6-4.2,1.1-5.4,2.5c0.6,0.2,0.8,0.8,0.4,1.3c0.8,0.2,1.6,0.1,2.1-0.6
    		c-0.4,0.7-1.1,0.9-1.9,0.9c1,1.2,4-0.2,5-0.9c0,1,1.2,1.1,1.9,0.9c-0.3,0.4-0.7,0.6-1.2,0.4c0.2,1.3,1.1,0.6,1.9,0.3
    		c1.1-0.5,1.8,1.2,2.8,1.7c1.8,0.8,2.1-0.9,3.6-1.1c1.6-0.2,2.7-1.1,4-2c-1.2,1.5-3,2.5-4.3,3.9c-0.3,0.4,0.2,0.8-0.6,0.9
    		c-0.8,0.1-1.7,0.2-2.5,0c-1.2-0.2-3.2-0.7-4.3,0c-0.7,0.4-0.7,1.2-1.4,1.6c-1,0.6-1.2-0.9-1.5,0.9c-0.1,0.7-1.8,3.3-2.7,3.1
    		c-0.5-0.1-0.7-0.1-0.9,0.5c-0.3,1-1.7,1.4-2.5,1.7c-0.7,0.3-1.4,0.6-2,1c-0.2,0.2,0.1,0.8,0.3,0.8
    		C1274.1,267.1,1275.2,266.3,1275.7,267.6"
          />
          <path
            id="path2658"
            class="countryIsland"
            d="M1274.5,210.5c0.1-0.1,0.3-0.1,0.4-0.2c-0.2-0.7-0.7-0.8-1.2-0.3
    		C1274,210.2,1274.3,210.3,1274.5,210.5"
          />
          <path
            id="path2660"
            class="countryIsland"
            d="M1274.4,215.8c1.3-0.1,2.4-0.6,3.7-0.9c-0.2-1.4-3.1-2.7-4-1.2c0.6,0.1,1.1,0.3,1.4,0.7
    		c-0.5,0.4-1.2,0.9,0.2,0.7C1275.2,215.2,1274.8,215.5,1274.4,215.8"
          />
          <path
            id="path2662"
            class="countryIsland"
            d="M1275.7,219.8c0.6-0.9,1.7-1.7,1.9-2.9c-1,0.6-1.5,1.7-2.5,2.2
    		C1275.2,219.3,1275.4,219.6,1275.7,219.8"
          />
          <path
            id="path2664"
            class="countryIsland"
            d="M1276.5,207.4c0.2-0.5,0.4-1,0.4-1.5C1276.5,206.3,1276.4,206.8,1276.5,207.4"
          />
          <path
            id="path2666"
            class="countryIsland"
            d="M1280.4,222.8c1-0.4,0.7-2.4-0.4-2.4C1278.7,220.5,1279.3,222.8,1280.4,222.8"
          />
          <path
            id="path2670"
            class="countryIsland"
            d="M1295.2,195.8c0.1-0.6,0-1.2-0.7-1.2C1294.4,195.1,1294.7,195.6,1295.2,195.8"
          />
          <path
            id="path2672"
            class="countryIsland"
            d="M1295.2,194.2c0.8,0.7,2.8,1,3.4,0c-0.8-0.1-2.1,0-2.4-1C1296,192.4,1294,193.2,1295.2,194.2"
          />
          <path
            id="path2674"
            class="countryIsland"
            d="M1297,196.1c0.2-0.2,0.4-0.5,0.4-0.9C1296.6,195.2,1296.4,195.5,1297,196.1"
          />
          <path
            id="path2676"
            class="countryIsland"
            d="M1297.8,192.1c0.1-0.5-0.1-0.8-0.6-1C1296.9,191.7,1297.2,192.1,1297.8,192.1"
          />
          <path
            id="path2678"
            class="countryIsland"
            d="M1298.9,192.5c0.4-0.3,0.9-0.6,1.4-0.9C1299.6,191.6,1299.1,191.9,1298.9,192.5"
          />
          <path
            id="path2776"
            class="countryIsland"
            d="M1307.8,186c-0.1,0.3-0.2,0.5-0.4,0.8c1.6,0,1.7-2.7,2.2-3.7c-0.6-0.1-0.9-0.4-0.8-1
    		c-0.2,0.2-0.5,0.3-0.7,0.5c-0.1-0.5,0-1,0.3-1.4c-0.9-0.1-0.7,1-1.7,0.8c0.4,0.5,0.9,0.9,1.4,1.2c-0.7,0.4-1.4,0.6-2.2,0.5
    		c0.2,1.3,0.3,0.3,1.3,0.7C1308.1,184.5,1308.5,185.3,1307.8,186"
          />
          <path
            id="path2778"
            class="countryIsland"
            d="M1309.4,182.1c0.5-0.4,1.3-1.6,0.1-1.7C1309.6,181,1309.1,181.6,1309.4,182.1"
          />
          <path
            id="path2780"
            class="countryIsland"
            d="M1311.2,180.7C1312.3,178.9,1309.2,180.4,1311.2,180.7"
          />
          <path
            id="path2782"
            class="countryIsland"
            d="M1310.9,181.6c0.2-0.1,0.5-0.2,0.7-0.3C1310.9,180.9,1310.7,181,1310.9,181.6"
          />
          <path
            id="path5354"
            class="countryIsland"
            d="M1302.4,262.8c0.6-0.1,1.2-0.4,1.5-0.9c-1.2-0.7-1.8-0.4-2.9,0.2
    		C1301.4,262.2,1301.9,262.5,1302.4,262.8"
          />
        </g>
        <g id="cr" class="countryGroup">
          <path
            id="Costa_Rica_mainland"
            class="country"
            d="M647.9,608.9c0.4-0.3,1.6-2,1.7-2.5c0.1-0.9-1.8-1.9-1.1-2.4
    		c0.9-0.5,1.1-1.3,1.7-2.2c0.2-0.4,3.1,1,3.5,1.2c2,1,1.7,0.3,3.6-0.2c0.8-0.2,1.2,1,2.5,0.8c0.6,0.8,0.8,1.6,1.9,1.8
    		c1.3,0.2,3.3,0.3,3.2-1.1c0.6,1,0,1.2,0.6,2c0.5,0.7,0.8,1.6,1.1,2.4c0.7,1.7,1.3,2.3,2.5,3.4c1,1,1.6,2.7,2.9,3.3
    		c0.7,0.4,0.6,1.2-0.3,0.9c-0.7-0.2-0.9-1-1.7-0.4c0.4,0.7-0.5,2.3-0.5,3.4c0,1.1,2.7,1.5,0.8,2.5c-1.5,0.8,0.5,2.5-0.8,3.6
    		c-1.5,1.3-0.1,2-0.4,3.6c0.1-0.8-0.3-1.4-0.8-2c-0.3-0.2-0.6-0.5-0.9-0.8c0.1-0.3,0.2-0.6,0.2-0.9c-0.3-1-1.4-3.1-2.7-2.3
    		c0.2,1.2,1.7,1.6,1.3,3c-1.1-0.3-2.8-0.7-3-2c-0.2-0.9,1-1.5,1.1-2.4c0.2-2.2-2.2-3-3.6-4.1c-0.9-0.7-1.1-1.1-2.3-1.2
    		c-1.2,0-1.9-0.6-1.8-1.8c0.1-2.9-3.3-2.9-4.5-5c0.2,0.6,0.3,1.2,0.5,1.7c0.3,0.9,1.1,0.8,1.7,1.3c0.9,0.7-0.2,2.2-1,2.6
    		c-1,0.4-1.8-1.7-2.8-1.9C648.9,612.9,647.5,610.7,647.9,608.9"
          />
          <circle
            id="Costa_Rica_Cocos_Island"
            class="countryIsland"
            cx="641"
            cy="652"
            r="0.2"
          />
        </g>
        <path
          id="py"
          class="countryGroup country"
          d="M833.8,891c0.7-2.9,1.1-5.8,1.4-8.8c0.2-1.6,0-3.1-0.2-4.7c-0.1-1.6,0.7-2.9,1.4-4.3
    	c0.9-1.9,0.4-4.7,3.3-5c3-0.4,6.1-1.1,9-2c4.8-1.5,8.1-1.2,12.4,1.2c1.7,1,4.1,2.3,4.2,4.5c0.1,1.3,1.1,5.4,2.5,5.8
    	c-0.9,1.1,0.5,1.4,0.8,2.2c0.2,0.6,0,2.5-0.1,3.2c-0.2,0.5-0.2,1-0.3,1.5c0.1,0.7,0.6,1.3,0.3,2c-0.3,0.8-0.7,1.9-0.6,2.8
    	c0,0.5,5.4,2.1,6.1,2.1c1.1,0,2.5,0.5,3.6,0.4c1.1-0.1,1-2.5,2.1-1.7c1.1,0.8,2.8,1.6,4.1,1.8c1,0.1,1.1,0.4,1.3,1.5
    	c0.2,1.1,1,2,1.1,3.3c0.2,1.6,1.7,2.5,1.8,4.1c0.2,1.9,0.7,5.6,3.2,5.4c1.4-0.1,2.4-1.2,3.7-1.5c1.3-0.2,2.2,1.3,3.1,1.9
    	c-0.6,0.4-0.6,0.9-0.3,1.5c0.6,1.1-0.4,1.7-0.1,2.8c0.3,1.1-0.4,3.1-0.6,4.2c-0.2,1.1-0.5,3.3-0.3,4.4l1,7c0.1,0.6-0.4,2.2-1.3,2.4
    	c-0.4,0.1-1.6,1.8-1.4,2.2c0.2,0.4-1.7,0.7-1.9,0.8c-0.5,0.2-1.1,0.7-1,1.2c0.2,0.6-1,3.3-1.9,2.4c-2-1.7-3-0.3-3.9,1.6
    	c-0.6-0.2-1.3-1.2-1.9-1.1c-0.8,0.2-1,0.9-1.8-0.1c-0.6-0.7-1.3,0.3-2,0.3c-0.7,0-1.6-0.3-2.3-0.4c-2.3-0.6-4.6-1.5-7.1-1.2
    	c-1.2,0.1-1.9,0.9-2.3-0.5c-0.1-0.3,0.8-2.8,1.1-3c2.5-1.6-0.1-5.9,2.3-7c1.1-0.5,1.1-3.1,1.7-4.2c1.1-2-0.1-2.9-1.8-4.2
    	c-1.3-1-3.2-1-4.4-1.9c-0.7-0.6-1.5-0.8-2.4-0.9c-1-0.2-2-0.9-2.8-1.4c-2-1.1-3.5-2.2-5.3-3.7c-2-1.6-3.8-1.2-6-2.1
    	c-1.4-0.5-3.3-0.8-4.5-2.1c-1.8-1.9-4.6-3.6-5.9-4.8c-1.5-1.4-3.2-3.2-3.6-4.3C836.8,892.9,835,892.6,833.8,891"
        />
        <g id="gn" class="countryGroup">
          <path
            id="Guinea_mainland"
            class="country"
            d="M1186.9,603.6c1-1.2,0.9-3.3,2.2-4.3c0.9-0.7,2.5-0.9,3.5-1.4c1-0.5,2.1,0.1,3.1-0.4
    		c0.7-0.4,1.3-1,1.2-1.8c-0.2-1.2-2.3-1.7-1.9-2.6c0.3-0.8,2-0.6,2.3-1.5c0.3-1-0.5-2.3-0.2-2.6l3.6,0c0.5,0.1,1.1,0.5,1.5,0.6
    		c0.3,0.1-0.3,1.7,0.8,1c0.8-0.5,2.3,0.6,2.8,0.9c0,0,1.7,0.5,1.6,0.5c1.1-0.1,1.8-0.9,2.9-0.8c1.7,0.2,3.3-0.5,4.5,0
    		c-0.6,1.2-1.4,2,0.1,3.3c0.9,0.7,2.2-2.1,3.2-1.7c2.5,0.9,0.9,3.9,3.1,1.5c2.8-3.2,4.5,0.5,5.6,0.2c0.6-0.2,1.1-0.8,1.6-1.2
    		c0.5-0.4,1.6-0.5,1.8-0.9c0.4-0.7-1.3-2.4,1.1-1.8c0.5,0.1,1.8,0.7,1.7,1.7c0,0.3,0.4,2,0.6,2.2c1.2,1.1-0.2,4,1.2,3.2
    		c0.2,1.4,2.8,1.5,2.6,3.2c-0.6,0-0.9,0-1.3,0.5c-0.6,0.7-0.9,1.6-1.1,2.4c0.8-0.1,1.2-0.7,2-0.8c1.4-0.1,1,1.6,0.9,2.6
    		c0,0.7-0.3,1.9,1,2.7c0.2,0.1,1.4,0.9,1.6,1.5c0.2,0.9-0.6,1.3-1.3,1.8c-0.4,0.3-0.4,1.8-0.4,2.5c0,1.3,0.4,4.1,2.2,2.9
    		c-0.4,2-0.2,1.7,0.9,3c-1.3,0.2-1.3,1.3-1.7,2.4c2.1,0.3,1.9,2.1,2.4,3.7c-1.1-0.4-4.1-1.6-4.5-0.5c-0.5,1.3,1.5,2.7,2.2,3.6
    		c-0.8-0.4-1.3,1.1-1.3,1.1c0.2,0.8,0.4,0.5,0.3,1c-0.2,0.5-1,1.8-1,1.9c-0.2-0.2-1.3-0.7-1.9-0.2c-0.3,0.3,0.1-0.6-0.9-1
    		c-0.9-0.3-1.2,1.7-1.5,2.1c-0.7,1.3-1.2,1.6-2.6,2.2c-0.1-1.7-1.4-1.9-2.8-1.4c0.4-1.1,0.6-2.2,0.7-3.4c0-0.4-0.1-2.9-0.3-2.7
    		c-0.1,0.2-0.3,0.6-0.3-0.8c0-0.2-0.5-1,0-1.7c-2.3-2.7-2.5-1.2-2.8-1.4c-0.8,0.6-1,0.2-2.1,0.1c-0.7-0.1-1.5,0.1-2.2,0.5
    		c-0.4,0.2-2,1.1-2.2,1.4c-0.2-1.5,1.8-3.3,1.5-3.8c-1.3-1.7,0.7-2.2-1.3-3.5c-0.6-0.4,0.2-1-0.1-1.6c-2.1-3.9-2.9-6.9-7.6-6.2
    		c-1.3,0.2-1.6,0.6-2.7,0.8c-2,0.5-2.6-0.6-3.4,1.4c-0.7,1.5-1.2,3.3-2.2,3.8c-0.9,0.4-0.9,0.7-1.3,1.6c-0.5,1-1.7,0.2-2.4,0.9
    		c-0.1-0.7,0.3-1.2,1-1.2c-0.1-0.3-0.3-0.5-0.6-0.7c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1-0.5-0.1-1-0.1-1.4c-0.3,0-0.5,0-0.8,0.1
    		c0.2-0.9-1.1-1.1-0.5-1.9c-1.3,1.3-0.7-2.1-1.2-2.4c0.1,0.6-0.2,0.8-0.8,0.6c0.2-0.5,0.4-1,0.5-1.6c-0.3,1.1-1,0.7-1.7,0.2
    		c-0.9-0.7-0.4-1.2-0.8-2.1c-0.5,0.2-0.8,0.5-1,1c-0.1-1.8-3.9-1.7-2.9-4c-0.3,0.1-0.7,0.2-1,0.2c0.6-1.1,1-2.2,1.3-3.4
    		c-0.4,0.4-0.8,0.9-1,1.5c-0.1-1.2-0.6-0.6-0.9,0c-0.2-0.8-0.3-2.5,0.7-2.8C1188.6,603.6,1187.8,603.6,1186.9,603.6"
          />
          <path
            id="path5380"
            class="countryIsland"
            d="M1186.7,603.8c-0.4,0.5-0.4,1.5,0.4,1.6c-0.2-0.6-0.1-1.1,0.4-1.5
    		C1187.3,603.8,1187,603.8,1186.7,603.8"
          />
        </g>
        <g id="ie" class="countryGroup">
          <path
            id="path5402"
            class="country"
            d="M1245.8,254.3c-0.2,0-0.5,0.1-0.7,0.1c0.9-0.9,2-1.9,3.3-2.1c-1.3-0.2-3.3,1.6-4.5,0.6
    		c-1.3-1.1,2.9-3.2,3.8-2.9c-1.3-0.6-3-0.1-4.2,0.2c-0.3-1,0.7-1.2,1.5-1.3c0.4-0.1,0.8-0.1,1.2-0.3c0.3-0.2,1,0.5,1.8,0.3
    		c-0.2-0.6-0.4-1.1-0.8-1.5c1.4,0,1.8-1.3,3.2-1.2c1.7,0,3.2-0.6,4.8-0.8c-0.6,0-1-0.3-1.2-0.9c-2.1,1.3-3.7,1.3-6.1,1.7
    		c0.7-0.5,3.9-2.1,3.5-3.2c-0.6-1.6,2.6-1.5,3.3-2.2c-1.2-0.1-2.4-0.1-3.7-0.1c-0.6,0-1.7-0.7-2.4-0.8c0.1-0.2,0.3-0.5,0.4-0.7
    		c-0.7-0.2-1.3,0.5-2,0.1c0.2-0.2,0.5-0.3,0.7-0.5c-0.2-0.2-0.5-0.3-0.7-0.5c0.8-0.1,0.5-0.2,1.1-0.6c0.5-0.3,1.2,0,1.8,0
    		c-0.8,0.1-1.6-0.6-0.8-1.2c0.3-0.3,1.1-0.2,1.5-0.3c2-0.7-1.9-0.9-1.8-0.6c-0.3-0.4,0.6-0.4,0.8-0.3c-0.5-0.7,0.3-2-0.9-1.3
    		c0.3-0.4,0.3-0.8-0.1-1.2c-0.2,0.2-0.4,0.5-0.6,0.7c0.1-0.5,0.3-1,0.7-1.4c0.1,1.1,1.7-0.1,2.8-0.1c1.1-0.1,2.4,0.2,2.6,1.5
    		c0.5-2.4,3,0.1,4.2-1.2c-0.3-0.1-0.5-0.3-0.8-0.4c0.8-1,3-0.9,3.2-2.2c-1.2-0.2-2.5,0.1-3.7-0.5c0.3-0.9,1.2-1.1,1.9-0.8
    		c-0.3-0.6-0.3-1.1,0.6-0.7c-0.6-0.8,0-2,0.8-2.4c1.3-0.7,2.5-0.1,3.8-0.3c-0.6-1,0.4-1.1,0.7-0.5c0.6,1,0,1-0.3,1.9
    		c1.1,0,1.5-1.2,0.7-1.9c1.6-1.1,2.2-0.5,3.7-0.2c-0.8,0.9-2.7,1.1-3.1,2.4c-0.4,1.1-1.7,2.7-3.2,2.1c0.3,0.3,0.6,0.6,0.9,0.8
    		c-1.3,0.8-3.6,0.6-1.8,2.2c0.8,0.7,1.9,1.1,2.8,1.4c0.8,0.3,1.5,0.6,1.9-0.8c0.2-0.7-0.1-1.4,1.5-1c1,0.3,1.5,1.5,2.1,1.7
    		c0,1.4,0.9,1,1.7,0.9c0.6-0.1,0.6-0.6,1.5,0.3c-0.5,0.3-1.1,0.3-1.6,0.7c-0.1,0.1,0.7,1.1,0.6,1.7c-0.1,0.8,1.9,2.8-0.1,3.2
    		c1.3,2,0.8,3.2-0.1,5.3c-0.4,1.1-1,3.2-2.5,3c1.6,0.2,0.6,1.6-0.6,1.4c-0.9-0.2-2.4,0.6-2.7-0.8c0.6,1.8-2.8,1.2-3.7,1.5
    		c-0.9,0.3-0.3,1.2-1.7,1.2c-1.1,0-1.6,1.5-3,1.4c0.8-1.2-0.7-0.9-1.3-0.6c1.1,0.3,0.7,0.8,0,1.3c-0.3,0.1-0.6,0.3-0.8,0.6
    		c-0.2,0.5-0.7,0.1-1.1,0.2c-1.1,0.4-2.2,0.4-3.3,0.8c-1.2,0.4-2.6,0-3.8,0.6c0.3-0.5,0.7-0.9,1.3-1.2c-0.5,0.1-1,0.2-1.5,0.4
    		c0.6-0.6,1.4-0.9,2.2-1.1C1248.4,253.1,1246.5,253.7,1245.8,254.3"
          />
          <path
            id="path5352"
            class="countryIsland"
            d="M1258.6,226.3c0.1,0.1-0.1,0.5,0.2,0.1C1259,226.1,1258.8,226.4,1258.6,226.3"
          />
          <path
            id="path5348"
            class="countryIsland"
            d="M1248.2,235.3c1.2-0.5-1-0.9-1.5-0.6C1247.2,234.7,1247.7,234.9,1248.2,235.3"
          />
        </g>
        <g id="ng" class="countryGroup">
          <path
            id="Nigeria_mainland"
            class="country"
            d="M1321.2,630.3c0.9-3.1-0.4-6.8,1-9.8c0.3-0.7,1.6-0.1,2.2-0.8c1-1.2,0.1-2.6,1.2-3.8
    		c0.7-0.8,0.7-2,1.5-2.5c0.9-0.5,2.2-1.6,1.8-2.9c-0.3-1-0.8-1.8,0.5-2c1.8-0.4,0.3-3,0.2-3.8c-0.2-0.8,0.1-1.5-0.3-2.3
    		c-0.3-0.7-0.9-1.2-1.3-1.9c-0.7-1.4,0.3-2.5,0.7-3.8c0.5-1.5,0.2-3.2,0.3-4.8c0.1-1.9,0.7-2.3,2-3.7c1.2-1.1,1.7-2.9,1.6-4.5
    		c0-1.3,0.9-2.1,1.9-2.9c1.1-0.9,2.5-1.2,4-1.3c1.9-0.1,2.9-0.8,4.6-1.2c1-0.3,2.3,0.9,3.2,1.3c1.4,0.7,2.9,0.3,4.2,1.5
    		c1.1,1.1,1.6,2.3,2.4,3.6c0.3,0.5,0.7,1,1.2,1.2c1.2,0.4,1.4-0.9,2.3-1c3.2-0.3,4.2-3.2,7.5-0.8c0.5,0.3,1,0.7,1.3,1.2
    		c0.1,0.2,1,0.1,1.3,0.3c1.4,0.7,1.9,1.8,3.7,1.8c1,0,3.2,0.6,4.1,0.2c1.3-0.6,1.7-2.2,2.8-3.1c2.6-2,6.6-2.2,9.8-1.9
    		c1.7,0.2,3.1,0.6,4.6,1.4c1.2,0.6,2.8,1.6,4.2,1.1c1.4-0.5,2.1-2.1,3.2-3c0.6-0.5,1.2-0.9,2-1c1.2-0.1,0.4-0.3,1.1-0.8
    		c2.6-1.7,3.5,0.7,4.4,2.5c0.8,1.5,2.4,1.6,2.1,5.8c0,0.5,0.7,2.3,1.1,2.6c0.5,0.4,0.9,0,1.5,0.3c3.2,1.2,0.3,4.4,1.3,5.9
    		c1.2,1.9-2.8,3.4-4.2,3.3c-2.2-0.2-3,4.6-3.6,6.3c-0.3,1-0.5,2-0.8,3c-0.3,1.1-1.6,0.9-1.6,2.2c0,1.1,0.2,2.5-0.6,3.5
    		c-0.7,0.8-1.8,1.2-2.7,1.7c1.1,0.2,0,3-0.1,3.6c-0.2,1.8-1.4,2.8-3.2,2.9c0.2,0.9-0.6,1.1-1.1,1.7c-0.6,0.7-0.1,1.8-0.3,2.7
    		c-0.4,2.1-1.4,4.2-2.7,5.9c-1.1,1.5,0.9,2.3-1.6,4c-1.1,0.7-0.4,1.5-0.8,2.5c-0.2,0.7-1.1,1.3-1.5,1.9c-0.3,0.6-1.4,0.2-1.9,0.1
    		c-0.6-0.2-0.4-1.9-0.5-2.4c0-0.6-2-1.1-2.3-1.8c-0.4-1.1-0.9-0.7-1.2-1.9c-0.2,0.8-0.3,2.1-1.2,2.3c-1.2,0.3-1.7-1-2.7-0.7
    		c-1.8,0.6-2.2,3.3-3.3,4.1c-1.5,1-6.3,5.9-5.6,6.5c1,1-0.8,3.7-0.8,4.4c0,1.3-0.5,2.3-2,4.8c-0.6-0.2-1.2-1-0.9-1.6
    		c-0.7,0.4-1.2-0.2-1.4-0.7c0.6,2.4,1.2,2.9-1.5,3.3c-1.1,0.2-4.7,0.9-3.7-1.4c-0.5,0.9-0.5,1.1-1.6,1.1c-1.2-0.1-1-0.4-2.2-1.2
    		c0.6,0.4,1.2,2.5-0.1,1.9c-0.9-0.3,0.4-2.4-1.1-1.7c0.4,0.9,0.8,1.7,0.4,2.7c-0.7-0.3-1.5-2.9-1.3-3.7c-0.8,0.7,0.5,2.8,0.1,3.8
    		c-0.6,1.4-0.6-1.8-0.6-2.1c-0.1,0.4-0.4,2.6-0.4,0.6c0,0.6-0.1,1.2-0.5,1.7c0-0.5-0.2-1-0.4-1.4c0.3,0.6,0.3,1.6-0.7,1.5
    		c-1.7-0.2-1.2,0.2-1.7-1.4c0,1.5-1.2,2.9-1.4,0.6c0,1.4-0.4,0.5-1.4,0c-1-0.5-1.7-1.5-2.3-2.5c-0.5-0.9-1.1-1.9-0.9-3
    		c0.2-0.7,0.3-1.4-0.7-1.1c-0.2-0.8-0.1-1.2,0.7-1.4c1.6-0.3,0.1-0.9,1.3-1.6c-0.8,0.1-1.6,1.2-2.4,1c-2.6-0.6,0.9-1.5,1.6-1.6
    		c-0.4-0.5-0.9-0.6-1.4-0.2c0-0.3,0-0.6,0.1-0.9c-0.2,0.9-1.3,1.5-1.7,0.4c-0.4-1,0.8-1.5,1.4-2c-0.7,0.3-1.2,0.7-1.7,1.2
    		c-1-2.2-3.2-4.7-5.6-5.4c-1.2-0.3-2.6-0.5-3.8-0.4c-1,0.1-2.4,0.5-3.2-0.2c1,0.2,2.6-0.5,3.2-1.4c-1.1,0-1.8,1.2-2.9,0.4
    		c-0.4,0.5-0.5,1-0.3,1.5c-1.7,0.2-3.5-0.1-5.2,0.3c-0.1-1.1,0.6-2,0.6-3.1c0-1.1-0.5-1.9-0.2-3
    		C1322.2,635.5,1322.3,631.9,1321.2,630.3"
          />
          <path
            id="path5382"
            class="countryIsland"
            d="M1355.4,660.7c0.9-0.1,1.2-0.6,0.5-1.2C1355.5,659.8,1355.4,660.2,1355.4,660.7"
          />
          <path
            id="path5386"
            class="countryIsland"
            d="M1357.3,660.2c0.2-0.1,0.5-0.3,0.7-0.4c-0.5,0-1,0.1-1.4,0.4
    		C1356.8,660.2,1357.1,660.2,1357.3,660.2"
          />
        </g>
        <g id="tn" class="countryGroup">
          <path
            id="Tunisia_mainland"
            class="country"
            d="M1359,403.9c0.2-0.8,1-1.2,1.6-1.6c1-0.7,1-1.4,2.1-2.2c0.8-0.6,1.5-1.8,1.8-2.9
    		c0.7-2.1,0.5-3.8,0.6-5.9c0.1-1.3-0.8-2.4-0.6-3.7c0.2-1.5,1.8-4.3-0.5-4.8c0.8-1,2.2-1.5,2.9-2.6c0.2-0.4,0.1-1.2,0.7-1.3
    		c0.7-0.2,1.1,0,1.6-0.5c1.1-1.2,2.3-1.7,3.9-2.2c0.7-0.2,1.7-0.8,2.5-0.4c0.9,0.4,0,0.8,0.1,1.5c0.8,0,0.9-0.4,0.5-1
    		c1.4,0.3,1.8,0.1,2.4,1.4c0.2,0.6-0.5,0.6,0.2,1.1c0.6,0.4,0.7,1.1,0.1,1.6c0,0,0.6,0.7,1.3,0.4c0.8-0.3,1.5-1,2.2-1.6
    		c0.3-0.2,1.2-1.2,1.6-1c1,0.5,0.9,1.5,0.4,2.3c-0.9,1.6-4,3.2-4.1,5c-0.1,1.4,0.7,3.1,2,3.7c1.9,1,1.8,1.1,2.3,3.2
    		c0.6,2.7-1.3,5.6-3.4,7.2c-2.1,1.7-5.3,2.6-3.2,5.8c0.5,0.7,1.1,1.4,1.8,1.7c0.9,0.4,1.2-0.1,2,0c0.3,0,0.4,1.3,0.6,1.6
    		c0.4,0.7,1.5-1.3,2.1-1.1c0.8,0.3,0.3,1,0.5,1.5c0.3,0.8,1.5,1.5,0.3,1.6c0.6,0.7,2.1-0.1,2.7,0.7c0.5,0.7-0.3,3.2-0.2,4.2
    		c0.3,1.5,1.3,2.1-0.3,3c-1.6,0.9-4.8,2-5.7,3.3c-0.5,0.4-0.3,1.7-1.1,2c-1.6,0.6-3.1,1.2-2.5,3.6c0.5,1.9,1.2,3.5,0.1,5.4
    		c-1.3,2.2-2.5,3.1-4.8,4.1c-0.6-3.6-1.6-7.1-2.3-10.7c-0.7-3.7-1.1-5.8-4.2-7.6c-1.5-0.8-2.1-1.8-2.5-3.4c-0.3-1.4-1-2.4-2.3-3.2
    		c-0.7-0.4-1.3-0.7-1.7-1.4c-0.4-0.7-0.3-1.6-0.7-2.3C1358.9,406.9,1358.4,405.4,1359,403.9"
          />
          <path
            id="Tunisia_Djerba"
            class="countryIsland"
            d="M1383.2,407.5c-0.1-1-0.8-0.2-1-0.6c-0.5-1.1,0-1.5,0.9-1.5
    		C1385.3,405.4,1384,406.6,1383.2,407.5"
          />
        </g>
        <path
          id="pl"
          class="countryGroup country"
          d="M1403.9,243.8c0-1.7,2.5-2.2,1.6-4.2c-0.3-0.7-1.1-1.7-0.8-2.6c0.1-0.4,1.5,0.1,1.8,0.1
    	c-0.1-0.6,0-1.1,0.4-1.5c-0.1,0.1-0.2,0.2-0.2,0.4c-0.4-0.4-0.9-0.6-1.5-0.6c0.3,0.8-0.9,0.3-0.9-0.4c1.7,0.6,4.3-0.8,5.8-1.4
    	c1.8-0.7,6.2-0.5,7.3-2c1.4-1.8,4.7-2.7,6.8-3.3c2.7-0.7,6.5-1.3,8.8,0.7c-0.6-0.4-1.4-0.9-2.2-0.6c0.9,0.9,1.2,2.6,2.6,3
    	c1.7,0.5,3.9-0.2,5.5-0.7c-0.7,0.4-1.6,0.5-2.2,1c-1,0.7,1.1,0.3,1.5,0.1c2.6-1.3,4.8-0.3,7.9-0.3c4-0.1,9.7,0.9,13.7,0.4
    	c3-0.4,3.7,1,4.5,3.7c0.8,2.8,4.2,6.1,3.2,9.2c-0.5,1.6-2.5,1-3.3,2.2c-0.8,1.2-1.6,2.1,0.5,2.5c3.2,0.6,0.5,3.8,1.5,5.5
    	c1,1.8,1.4,4.1,4.2,5.4c-0.4,0.2-0.8,0.4-1.2,0.6c1.2,1.1,1.6,2.3-0.2,3c-1.8,0.7-3.3,2.4-4.6,3.7c-1,1.2-3.3,2.8-2.6,4.6
    	c0.3,0.8,0.2,1.6,0.5,2.4c0.2,0.6,0.9,0.4,0.8,1.2c-1.2-0.8-3-0.8-4.4-1.3c-1.3-0.5-2-1.5-3.4-1.9c-1.3-0.4-2.9-0.6-4.2,0
    	c-1.7,0.7-1.9,0.1-3.6-0.1c-1.3-0.2-1.8,0.1-2.7,0.9c-0.4,0.3-0.9,0.8-1.5,0.8c-0.9,0.1-0.6-0.9-1-1.4c-0.4-0.5-1.3-0.9-1.8-1.5
    	c-0.8-1-2.8,1.2-3.7,1.3c0.2-0.8-3.1-4.2-4.2-4.6c-1.2-0.3-1.8-0.9-3.1-0.5c-1.3,0.4-1.4-1.7-1.2-2.5c-2,0.6-3.8-0.8-5.6-1.3
    	c0,0.6,0.3,1.1,0.8,1.5c-0.8,0.5-2.2,1.9-3.1,0.7c-0.7-1-1.1-1.7-2.4-2.1c0.3-0.3,1.6-0.9,1.3-1.5c-0.3-0.8-2.1-0.4-2.5,0
    	c-0.1-2-3.7-0.6-4.4-1.7c-0.7-1.1,0-0.9-1.6-1.6c-1.2-0.5-0.2,1.4-1.8,1.1c1.1-2.8,1.9-3.7-0.3-5.1c-0.9-0.6-0.2-1.3-0.9-2
    	c-0.9-0.8,0.1-1.2,0.3-2.1c0.1-0.6-0.1-1.5-0.2-2.1c-0.1-0.6-1.2-0.4-1-1.4c0.2-0.6,0.9-1.2,0.1-1.7
    	C1405.7,245.4,1404.8,244.6,1403.9,243.8"
        />
        <path
          id="na"
          class="countryGroup country"
          d="M1390.1,847.8c1.1-0.2,1.9-1.1,3-0.9c1,0.2,1.8,0.9,2.9,0.7c2.1-0.4,3.6-2.9,6-2.2
    	c1.9,0.5,2.9,2.7,4.9,3.3c1.1,0.4,4.5,0.2,6.3,0.2c8.2,0.1,16.4,0.2,24.7-0.1c2.2-0.1,2.9-0.2,3.9,1.7c1.3,2.4,3.2,1.9,5.6,2.2
    	c3.1,0.3,6.3,0.2,9.2,1.2c1.1,0.4,2.1,0.5,3.1-0.1c0.9-0.5,1.7-0.3,2.9,0c1.3,0.3,2,0.1,2.8-0.1c5.1-1.2,10.9-2.4,16.4-3.4
    	c1.1-0.2,2.1-0.7,3.2-0.5c0.7,0.1,1.6,0.7,2.3,0.4c2.1-1,3,1.1,4.3,2.1c-1-0.3-2.1,0.3-3.1,0.7c-1.2,0.5-1.5,2.2-2.6,1.2
    	c-1.8-1.6-5.3,3.7-6.6,4.4c-0.6-1.2-1.3-4-3.2-4.2c-2.4-0.2-5.3,1-7.6,1.5c-2.7,0.6-5.9,0.7-8.6,1.2l-1,31.9l-6.8-0.1l-1.5,54.4
    	c0.2,2.5-2,1.4-3.1,2.6c-0.6,0.6-0.5,1.1-1.7,1.5c-0.8,0.3-1.2,0.6-0.5,1.3c-1.8,0.5-1.7-0.1-3.4-0.4c-1.8-0.3-3.2,0.7-5.3,0.2
    	c-3.3-0.7-5.2-1.9-5.4-2.5c-0.4-1.7,0-1,0-2.3c-0.1-0.5-0.6-0.4-1-0.8c-0.6-0.6-1.1-1.9-1.7-1.5c-1.9,1.2-1.4,3-2,3.5
    	c-0.9,0.7-2.1,1.7-3.1,0.5c-1.3-1.6-3-2.9-4.2-4.7c-1-1.6-1.9-3.2-2.7-4.9c-0.4-0.9-0.5-1.8-0.6-2.8c-0.2-1-0.8-1.5-1-2.4
    	c-0.3-1.6,0.4-2.3-0.8-3.8c-0.5-0.6-0.3-1-0.3-1.8c0-1.3-0.4-2.3-0.5-3.6c-0.1-0.9,0-1.7-0.3-2.6c-0.4-1.2,0.2-2.4,0-3.6
    	c-0.4-2.1-1.4-4-2.1-5.9c-0.8-2.2-0.2-4.1-0.4-6.3c-0.2-1.5-0.4-3.4-0.2-4.2c0.1-0.4,1-4,0.6-4c-0.1-2-1.1-3.9-2.2-5.5
    	c-0.6-0.9-1.4-1.7-1.9-2.7c-0.5-0.9-0.6-2-1.2-2.8c-2.9-3.7-3.6-8.2-5.8-12.3c-2-3.7-2.7-8.8-5.9-11.7
    	C1390.2,856.8,1389.8,852.3,1390.1,847.8"
        />
        <path
          id="za"
          class="countryGroup country"
          d="M1501.9,955.1c0.9,0.9,0.9,2.3,1.6,3.3c0.7,0.8,0.4,2,1.1,2.7c0.9,0.9,3.2,3.8,4.7,2.6
    	c0.7-0.5,1.2-2.3,1.7-3.1c0.7-1.2,2-1,3.2-1.3c0.9-0.2,2.8-1,3.1-1.9c0.5-1.2,0.5-1.8,1.4-2.9c0.3-0.4,1.3-1.5,1.2-2
    	c-0.5-1.8-1.3-1.9-2.5-3.2c-0.9-1-1.9-1.9-2.9-2.9c-0.8-0.9-2.2,0.5-2.9,0.8c-0.8,0.3-1.2,0.7-2,1.2c-0.9,0.5-1.9,0.3-2.7,1.3
    	c-0.8,1-1.2,2.3-1.9,3.4C1504.2,954.1,1503,954.2,1501.9,955.1 M1424.8,945.8c0.8-0.7,1.9-0.6,2-1.7c0.1-0.9,0.8-2.6,1.8-2.9
    	c1.3-0.4,1.2,1.4,2.1,1.7c1.6,0.5,0,2,0.7,2.9c1.3,1.7,3.4,1.8,5.3,2.4c1.8,0.6,3.2-0.2,4.9-0.2c1.6,0,2.2,0.9,3.8,0.5
    	c-1-1.1,0.2-1.3,0.9-1.5c0.9-0.3,0.9-1.5,2.1-1.8c0.7-0.2,2.2-0.3,2.3-1.3l0.7-31.1c0.8,1.2,2.3,1.8,3.1,3c0.9,1.4,1.5,3.3,2.1,4.9
    	c0.6,1.6,1.1,3.3,0.3,5c-1,2.1-1.7,3.3-0.8,5.6c1.7-2,6,1,7.8-0.8c0.3-0.3,0.1-1.1,0.5-1.2c0.5-0.1,0.9,0,1.4-0.3
    	c1.3-0.7,1.6-2.4,2.9-3.2c1.5-0.9,2.4-1.8,2.8-3.6c0.3-1.5,0.2-2.8,1.5-4c1.1-1,2.5-1.2,3.9-0.6c1.1,0.5,2.7,2.9,3.8,2.8
    	c2.4-0.1,5.2,2.8,7.4,1c1-0.8,2.5,0.3,3.8-0.6c1.3-1,1.8-2.7,2.4-4.2c0.3-0.8,0.6-1.6,0.7-2.5c0.2-1.4-0.4-1.3,0.9-1.7
    	c0.8-0.2,3.2-0.6,3.6-1.4c0.8-1.4,2.4-2,3-3.4c0.7-1.6,0.4-3.2,1.5-4.6c0.9-1.3,1.9-1.4,3.1-2.2c1.2-0.8,2.8-2.1,3.7-3.3
    	c0.8-1,1.3-2.5,2.5-3.1c1.2-0.6,2.6-0.7,3.8-1.1c1.2-0.4,1.2-1,1.7-1.9c0.5-0.8,1.8-1,2.7-1.2c3.3-0.7,5.4,1.5,8.6,1.5
    	c2.9,0,5.5-1.1,6.5,2.5c0.5,2,1,4,1,6.1c0,1.9,1.2,3.5,1.9,5.2c1.4,3.6,1.1,7,0.9,10.8c-0.1,1.5,0,3.3-0.5,4.8
    	c-0.3,0.8-0.4,0.7-0.1,2.9c0.1,0.1-0.9-1.1-1.9-1.4c-0.9-0.3-1.8-0.2-2.6-0.2c-0.6,1.2-1.6,1-2.3,2.6c-0.9,1.9-1.7,4.8-1.9,4.6
    	c1.4,1.3,1.9,2.3,2.8,3.3c1.4,1.6,3.5,2.5,5,1c0.4-0.4-0.2-0.6,1.3-2.8l1.3,0l4.5,0.2c-0.8,3.8-1.9,7.1-2.9,10.9
    	c-0.5,1.9-1.6,5.9-4.2,5.7c0.4,1-0.8,0.9-1.4,1c-0.7,0.2-1.3,1-1.8,1.5c-1.6,1.8-3.2,3.9-4,6.2c-0.4,1.3-1.6,2.1-2.2,3.2
    	c-1,1.8-2,3.5-3.2,5.2c-2.1,3-4.3,5-7.1,7.3c-2.3,1.9-3.7,4.7-6,6.6c-2.9,2.4-5.8,4.9-8.9,7.2c-2.4,1.8-5.1,4-8.2,4.4
    	c-1.5,0.2-2.9-0.6-4.4-0.2c-1.1,0.3-2.5,1.6-1.1,2.5c-1.7,0.8-3.7-1.4-5.3-0.3c-1.9,1.3-2.3,2.1-4.9,1.1c-2-0.7-4.5-1.5-6.5-0.8
    	c-0.9,0.3-0.5,1-1.9,0.5c-0.8-0.3-2.3,0.2-2.7-0.7c0,0.1,0,0.2,0,0.4c-2.1-0.3-5.5,0.6-6.6,2.6c-0.9,1.7-5.7-0.1-7.2,0.6
    	c-0.9,0.4-1.8,0.1-2.7,0.5c-1,0.5-2.2,1.5-3.1,2.2c-1.1,0.9-0.7,0.7-2,0.4c-0.6-0.2-1.2,0.2-1.7-0.2c-0.6-0.5-1.1-1-1.9-1.3
    	c1.3-1.8-2.7-1.9-3.6-2c0.5-1.4,0-2.7-1.7-2.3c-1.1,0.2-0.7,1.5-0.7,2.3c-2.1-0.8-0.4-3.2-0.2-4.5c0.2-1.1-0.4-2.2-1-3.1
    	c-0.3-0.5-2.9-4.1-1.2-2.4c-0.7-0.7-0.4-1.5-1.5-1.5c-0.9,0,0.1-2.3,0.2-2.6c2.3,1.4,2.9-1.3,3-3.1c0.1-3.1-0.8-5.7-2.4-8.3
    	c-2.4-3.9-4.4-7.8-5.8-12.2c-0.6-2.1-1-4.2-1.8-6.2S1425,948,1424.8,945.8"
        />
        <path
          id="eg"
          class="countryGroup country"
          d="M1485.4,439c-1.4-2.3,1.5-4.7,1.2-7c-0.4-2.5-2.7-4.3,0.9-7.2c0.1,1.8,2.2,1.2,3.3,0.8
    	c2-0.6,3.7,0.1,5.7,0.3c2.2,0.2,4.3,0.7,6.4,1.3c0.9,0.2,1,1.2,2,1.5c0.8,0.2,1.4,0.1,2.2,0.1c0.5-0.1,0.8,0.8,1.4,0.9
    	c0.7,0.1,1.3,0.1,2,0.2c2.2,0.2,3.6,1.6,5.6,1.9c2,0.3,3.8-1,5.2-2.2c0.7-0.6,1.4-1.3,2.1-2c0.6,0.4,1,0.9,1.7,0.8
    	c-0.4-0.2-0.3-0.3-0.9-0.3c0.7-0.4,1.4-1.1,1.6-1.9c1.2,0.7,2.4-0.6,3.6-0.6c-0.8,0.4-1.6,0.8-2.2,1.5c0.2-0.1,5.2-1.6,2.8-1.8
    	c1.8-0.6,2.9,1.4,4.6,1.2c0.8-0.1,1.6-0.9,2.4-0.6c0.8,0.3,1.2,1.4,1.9,1.9c-0.7-0.5-1.4-1.6-2.4-1.7c0.3,0.7-0.8,2.2,0.5,2.4
    	c1.3,0.3,0.7,0.6,1.6,1.4c0-0.3,2.3-1.3,0.7-2.1c1.8,0.4,2.4,2.1,4.5,1.9c0.7-0.1,2.7-1.3,2.4,0.2c1.3-0.8,2.8-0.3,4.2-0.8
    	c1.2-0.4,2.5-0.9,3.5-1.7l6.1,16c-1.5,2-1.2,5.6-2.2,7.9c-0.6,1.4-0.6,2.2-0.5,3.6c0,1.2-1.6,2.4-1.2,3.6c-0.8-0.7-1.7-1-2.6-1.6
    	c-1-0.7-1.8-1.8-2.5-2.8c-0.9-1.3-3.6-2.2-3.2-4.1c0.4-2-1.5-3.3-2.5-4.7c-1.3-1.9-2.3-4.1-2.8-6.4c-0.9,1-1.9,3.1-0.9,4.2
    	c1.6,1.9,1.4,4.2,2.8,6.2c1.2,1.7,2.3,3.8,3.8,5.2c0.7,0.6,1.4,1.2,2,1.9c0.3,0.4,1.6,2.5,0.1,1.3c1.2,1,0.2,2.2,1,3
    	c1.1,1.1,1.6,2.2,2.2,3.5c0.3,0.7,0.7,1.2,1.1,1.8c0.3,0.5-0.3,1-0.3,1.5c0,0.6,0.9,1.5,1.2,2c0.6,1.2,1.3,2.3,1.9,3.5
    	c2,3.9,4.1,7.6,6,11.5c0.8,1.5,1.8,2.6,2.8,4c0.9,1.3,2.4,1.7,3.2,3c-0.8,0-1.5-0.1-2.2-0.4c-0.5,2.1-0.3,5.3,0.8,6.5
    	c1,1.2-1.3,3.5-2.8,3.6c-0.7,0-1-0.5-1.7,0.3c-0.6,0.6-0.9,1.7-1.1,2.5c-0.3,0.9-0.7,1.8-1.8,2c-0.8,0.2-2.3,0-2.8,1
    	c-0.8,1.7-0.3,3.8-2.8,3.6c-2.9-0.2-4.3-2.5-5.8-2.5c-5,0.2-7.8,0.7-11.7,0c0.1-0.4,0.6-1.2,0.3-1.6c-0.7-1-1.2,0.8-1.5,1.2
    	c-0.9,1.3-5.6,0.5-7.1,0.5l-40.1-0.1l-1.4-54.2c-0.2-2.5-0.1-5.2-0.4-7.7C1487,444.1,1486.4,440.9,1485.4,439"
        />
        <g id="tz" class="countryGroup">
          <path
            id="Tanzania_mainland"
            class="country"
            d="M1527.7,745.3c0.6-4.2,0.7-1.3-1.1-5.1c-0.3-0.7,0.4-2,0.1-3.2c1.8-0.2,2-0.3,3.2-1.6
    		c0.9-1,2.4-2.5,3.3-3.6c0.7-0.8-0.7-1.6-0.5-2.2c0.3-0.7,1.8-0.1,1.5-1c-0.2-0.6,1.3-1.4,1.6-1.7c0.8-0.8-0.4-1.8,0.4-2.6
    		c-2.3,0.6-3.7-0.7-2.4-2.8c-1.2,0.1-0.4-0.8,0.1-1.5c0.6-0.9,1.7-0.6,2.2-1.5c0.9-1.8-0.5-4.9-1.3-6.6c-0.6-1.2-2.5-3.9-0.6-4.5
    		c2.2-0.7,7.3-0.2,9.7-0.1c1,3.8-0.8,3.9-0.6,4.3c0.5,1.5-0.9,3.3-0.8,3.7c0.8,1.1-0.1,2.1,0.1,2.6c0.6,1.8,1,0.4,1.2,4.9
    		c0.1-0.9,0.8-1.5,1.4-0.4c-0.2-1.8,0-2,1.2-1.9c-0.9-0.5-0.3-1.5,1.1-2.3c0.3,0.3,1.6,3.1,2.9,1.3c-0.2,0.4,0.8,0.6,1,1.2
    		c0.3,1.1,0.3,3.2-0.7,2.7l0.6,0.8c0.5-0.6-0.4-0.8,1.6-0.9c-1.2,0.2-2.9-6.4,3.1-2.6c0.3-1.6,4.4-3.5,3.1-3.6
    		c-1.5-0.2-1.3,0.5-2.2,0.4c-2.5-0.3-2.4-0.8-2.5-0.7c0.6-0.7,0-0.7,2.7-0.9c-0.2-1-2.6-0.4-2-0.6c0.3-0.2-0.8-0.6,2.1-1
    		c0.3,0,0.8-0.6,0.6-1.1c-0.1-0.4,0.3-0.1,0.5-0.2c-1.1-1.2-0.3-1.3-0.1-1.5c0.3-0.2,0.6,0.2,1.8,0.4c-0.2,0-1-1.1-0.4-1.7
    		c0.3-0.5,0,0.5,0.3,0.1c-0.4-0.3-0.3-1,0-1.3c0.3-0.4,0.7-0.8,1-1.2l22.8,14.3c1.9,1.2,4.3,2.1,4.9,4.5c0.2,1-0.6,1-0.8,1.8
    		c-0.2,0.6,0.9,1.4,1.3,1.9c1.2,1.8,3.7,3.1,5.4,4.5c1,0.9,5.6,3.8,5.4,5.1c-0.3,2.1-1,4.4-1.9,6.3c-0.4,1-0.9,1.9-1.2,2.9
    		c-0.2,0.5-0.2,1-0.3,1.5c0,0.7,0.6,1.3,0.5,1.9c-0.5,2.6,3.7,2.8,3.2,5.6c2.3-0.7,2,2,1.1,3c-1.3,1.7-0.6,3.7-1.5,5.4
    		c1.6-1.4,1.5,1.5,1.1,2.3c-0.5,1.1-1,2.5-0.8,3.7c0.2,1.2,1.8,3.1,0.6,3.4c1,0.2,0.4,2,1.2,1.9c1.2-0.1,0.6,1.2,0.3,1.8
    		c-0.5,1.1,0.8,1.5,1,2.5c0.1,0.6-0.3,0.9,0,1.4c0.4,0.7,0.6,1.2-0.2,1.8c1.3-0.2,1.8,0.8,2,1.9c-0.1-1.8,4.3,0.8,2.9,2.8
    		c-3,2.3-5.7,3.7-8.5,5.4c-1,0.4-1.2-0.3-2.2,0.1c-1.1,0.4-2.7,1.7-3.8,2c-1.1,0.4-2.4-1.8-3.6-1.1c-1.4,0.7-2,2.9-3.5,3.5
    		c-2.5,1.1-3,0.3-3.8-0.4c-1.6-1.3-2.1,1.1-3.8,0.9c-2-0.2-2.6,0-3.7-1.3c-0.8-1.1-1.8-1.6-3-0.4c-0.9,0.8-3.9,0.7-5.4,0.3
    		c-0.2-0.1-0.2-0.9-0.4-1.2c-0.6-0.8-1.1-1.6-1.6-2.5c-0.6-1.3-0.2-2.6-0.5-3.9c-0.3-1.8,0-3.4-0.5-5.2c-0.5-1.8-1.6-3.5-3-4.6
    		c-1.4-1.1-1.7,1-1.6,1.4c-1.4-1.5-2.8-0.1-4.1-1.6c-0.4-0.5-2-0.6-2.6-0.9c-1-0.6-2-1.1-3.6-1.3c-0.2,0-0.5-0.4-0.6-0.6
    		c-0.5-0.6-0.9-0.5-1.6-0.8c-0.6-0.3-1.4-0.2-2-0.5c-0.5-0.3-0.7-1-1.2-1.2c-0.4-0.2-0.9,0.3-1.3,0.2c-0.7-0.2-0.8-1-1.2-1.5
    		c-0.7-1-1.7-1.2-2.7-1.1c-0.5-1.1-2.9-6.1-4.1-9.2c-1.1-2.8-0.3-8-4.2-9.5c-0.6-0.2-0.1,0.5-0.8,0.1c-1.4-0.7-2.9-2.3-0.3-4.8
    		C1529.8,748.8,1528.1,746.9,1527.7,745.3"
          />
          <path
            id="Tanzania_Mafia"
            class="countryIsland"
            d="M1602.9,767.7c0.8-0.7,2.1-1.2,1.6-2.5C1604,766.3,1602.7,766.4,1602.9,767.7"
          />
          <path
            id="Tanzania_Zanzibar"
            class="countryIsland"
            d="M1599.7,750.7c0.3,1.8,0.5,2.4,2,3.7c1,0.9,1.3-3.5-0.3-2.2c0.3-1.1-0.1-2.9-0.7-3.9
    		C1600.4,749.1,1599.4,749.8,1599.7,750.7"
          />
          <path
            id="Tanzania_Pemba"
            class="countryIsland"
            d="M1603.4,741.8c0.1,0.9,0.9,1.7-0.2,1.8c0.2,0.2,0.5,0.3,0.7,0.5c-1.5,0.8-0.2,2.1,0.5,0.8
    		c0.7-1.6,0.5-3.2-1-3.8C1603.5,741.3,1603.5,741.6,1603.4,741.8"
          />
        </g>
        <g id="sa" class="countryGroup">
          <path
            id="Saudi_Arabia_mainland"
            class="country"
            d="M1624.7,556.6c0.1-0.9-0.5-3.1-1.3-3.6c-0.4-0.3-2.4-4.5-2.3-2.5
    		c-0.2-3.7-2.7-4.6-4.7-7.1c-0.8-0.9-1.8-2.3-2.2-3.4c-0.5-1.5-1.3-1.9-2.1-3.1c-0.4-0.7-0.1-1.3-0.6-2c-0.4-0.5-1-2.2-1.2-2.8
    		c-0.2-0.6-0.4-1.3-0.7-1.9c-0.3-0.5-0.8-0.6-1.1-1.1c-0.2-0.5,0-1.1-0.4-1.5c-0.3-0.4-0.9-0.5-1.3-0.8c-1-0.9-2.1-1.8-3-2.8
    		c-1.1-1.2-2.3-0.7-3.5-1.6c-1.9-1.4-3.3-4.1-4.5-6.2c-0.7-1.2-0.5-1.9-0.6-3.1c-0.1-1.4-1.1-2.5-1.2-3.9c-0.1-1.8,0.6-3.6,0.3-5.4
    		c-0.2-0.8-0.6-1.5-1.1-2.2c-0.2-0.4-1.6-2.2,0-0.9c-0.3-0.9-1.1-0.9-1.3-1.9c-0.2-0.9-0.6-1.9-1-2.7c-1-2.3-2.8-3.9-4.6-5.6
    		c-1.3-1.2-2.6-1.4-4.1-1.8c-0.7-0.2-1-1.5-1.2-2c-0.4-1.2-1.2-2-2-2.9c2.5-0.9-1-5.1-1.8-6.1c-0.6-0.7-2.1-1.9-2.1-2.8
    		c0.1-1.5-1.9-2.4-2.5-3.8c-2-4.2-5.2-7.7-7.4-11.8c-0.7-1.3-1.4-3.2-2.9-3.6c-0.7-0.2-3.4-0.6-3.4,0.6c-0.9-0.8,0.6-3,0.8-3.8
    		c0.5-1.8,0.1-3.7,0.4-5.5c0.4-2.3,0-0.9,0.3-2.2c1.7,0.5,6.5,1.8,8.2,1.4c3.4-0.7,3.4-5.7,6.6-6.2c1.2-0.2,4.2,0.1,4-1.5
    		c-0.3-3.7,1.3-2.6,3.6-3.3c-2.9-2.8-5.7-5.7-8.4-8.8c5.2-1.1,8.6-2.3,14.8-5.5c2.6,0.5,7.2,0.8,10.1,2.1
    		c9.6,4.4,17.4,11.2,25.7,17.5c1.8,1.3,3.5,2.7,5.1,4.1c1.9,1.7,3.2,1.6,5.6,1.8c2.8,0.3,5.8,0.7,8.7,0.9c1,0.1,1.6-0.4,2.7-0.2
    		c1.5,0.3,3.1,0.5,4.7,0.7c1.7,0.1,1.8,2.1,2.3,3.3c0.8,1.8,3.4,0.7,4.9,0.8c1.5,0.1,1.7,1,2.2,2.2c0.5,1.4,3.2,3.1,2.8,4.6
    		c-0.1-0.2-0.3-0.5-0.5-0.6c-0.5,1.1,0.6,1,0.5,1.9c0,0,4.6,0.4,2.9,1.8c0.7,0.2,2.1,3.7,2.8,1.7c-0.5,1.7,3.7,3.7,4.7,4.7
    		c-0.5-0.2-0.9-0.5-1.2-0.9c0,1.9,1.2,1.9,1.6,3.5c0.2,0.9,0.1,2.8-1.2,1.6c0.1,1-0.2,1.2,0.7,1.4c0.1,0,1.4,2.7,1.4,3
    		c-0.3-0.2-0.5-0.5-0.8-0.7c0.8,1.2,2.2,1.8,2.8,3.1c0.6,1.3,0.8,2.5,1.6,3.8c0.6,1,1.4,2.7,2.7,3c0.6,0.1,2.6-0.4,2.3,0.7
    		c0.2-0.6,0.7-0.8,1.2-0.4c-0.7,0.8-1.3,3.1,1,2.5c-0.2,0,1.2,2.6,1.3,2.8c2.7,3,6.2,8.3,7.7,8.9c8.8,1,12.8,1.5,17.6,2.5
    		c0.6-0.1,1.2-0.5,1.4-0.9c1.1,2.5,3.5,3.7,3.9,5.3c0.4,1.4,0.3,2.4-0.1,3.9c-1.3,5-2,13.9-3.8,14.9c-12.5,6.7-28.1,9.1-43,11.5
    		c-1.9,0.7-3.8,1.6-5.5,2.6c-2.6,1.5-5.2,6.1-6.1,8.9c-0.4,1.3-1.9,2.7-3.3,2.7c-1.5,0-1.7-2.4-2.9-2.8c-1.3-0.5-2.3,0.2-3.5,0.1
    		c-1.8-0.2-4.2,0.1-5.8-0.8c-1.9-1-3.4-0.5-5.5-0.5c-1.9,0-3.9,1.2-5.8,1.1c-2.3,0-2.7-1.8-3.9-1.5c-1.6,0.4-2.1,2.6-2.2,3.9
    		c-0.1,1.3,1.6,2.1,0.2,3.1c-0.3,0.2-0.6,2.1-2.6,2.8C1624.6,556.8,1624.7,556.8,1624.7,556.6"
          />
          <path
            id="path3380"
            class="countryIsland"
            d="M1619.8,555.2c0-0.8-0.4-1.6-1.1-2c0,0.3,0,0.5-0.1,0.8c-0.9,0-1.3-1-2.1-1.2
    		C1617.3,554.1,1619.4,554.1,1619.8,555.2"
          />
          <path
            id="path5480"
            class="countryIsland"
            d="M1617.4,551.4c0.5,0.1,0.7,0.3,0.4,0.5C1617.6,551.8,1617.5,551.6,1617.4,551.4"
          />
          <path
            id="path5482"
            class="countryIsland"
            d="M1617.7,551.9c0.2,0.3,0.3,0.6,0.1,1c-0.3-0.1-0.5-0.2-0.7-0.4
    		C1617.3,552.4,1617.5,552.1,1617.7,551.9"
          />
          <path
            id="path5488"
            class="countryIsland"
            d="M1669,462.3c0.3-0.5,0.9-0.4,1.3,0C1669.9,462.4,1669.4,462.4,1669,462.3"
          />
        </g>
        <g id="vn" class="countryGroup">
          <path
            id="Vietnam_mainland"
            class="country"
            d="M2065.6,504.8c1.2,0,1.3-2.2,2-2.8c1.2-1,2.6,0.8,3.2,1.4c1.1,1.1,1.8,0.3,2.5-0.9
    		c1-1.6,1.6-0.2,2.3,0.7c0.3-0.6,0.3-2.2,1.2-1.2c0.6,0.6,1.2,1.4,1.9,1.8c0.5,0.3,0.8-2.7,2.1-2.8c-0.4,2,3.4,0.2,3.9-0.5
    		c0.6-0.7-0.2-1.3,0.1-1.9c0.3-0.9,2.6-1.1,3.1-2.4c1.1,1.5,2.4,2.6,4,3.4c1.3,0.6,2.5-0.6,3.4,0.6c0.9,1.1,2.4-0.6,3.5,0.5
    		c0.6,0.6,1.3,2.9-0.3,2.3c0,2,1,3.4,1.6,5.3c0.6-0.5,2.7,0.2,2.7,1c0,1.2,2.4,1,2.7,2.2c0.2-0.1,0.5-0.3,0.7-0.4
    		c0.4,1,1,0.3,1.8,0.1c1.3-0.3,4.8,0.8,3.6,1.5c-0.9,0.8-1.9-0.9-2.8-0.1c-0.8,0.7-1.1,1.6-2.1,1.8c-1.1,0.2-0.2,1.2-0.7,2
    		c-0.4,0.7-2.8,1.5-1.6,0.1c-0.8-0.2-1.8,1-1.8,1.4c-0.6-0.4-0.9-1.5-1.8-1.2c0.3,0.3,0.6,0.5,1,0.7c-0.6,0.7-0.2,1.1,0.3,1.9
    		c0.4,0.7-1.1,0.6-1.3,0.5c0.3,0.7,0.5,2.6-0.2,3.1c0.1,0,0.2,0,0.3,0c-0.1,0.5-2.3,3-2.8,2.4c-1.2,0.6-1.6,2.4-1.7,3.6
    		c-0.2,1.7,0.5,2.8-0.3,4.4c-1,1.8,0.9,2.2,1,3.8c0.4-1.1,0.7,0.6,1.1,1.2c0.6,0.8,1.4,1.5,2.2,2.1c0.8,0.6,1.6,1.1,2.3,1.8
    		c1,1,0.6,2,0.9,3.2c-0.4-0.3-0.9-0.5-1.5-0.6c1.8,0.3,2.6,2.5,4.1,3.7c1,0.8,4.3,2.4,3.4,4.2c1.1-0.6,2.9,1.6,3.5,2.2
    		c-0.3-0.1-0.6-0.3-0.9-0.4c1.3,0.8,2.6,1.8,3.5,3.1c1.2-1.4,3.6,1.8,2.9,2.5c0.6-0.4-0.4-1.8,0.9-1.2c-1.9,1.7,4,5.5,4.1,7
    		c0.5-1.3,0.7-0.2,1.1,0.4c0.6,0.9-0.3,0.5-0.2,1.2c0.3,1.3,1.3,2.4,1.8,3.6c0.7,1.6,1,3.4,1.8,5c0.3,0.7,0.6,1.3,0.8,2
    		c0.1,0.3,0.1,2.2-0.3,0.4c0,1,1.1,3.8,0,2.1c0.2,0.6,0.6,1.1,1.1,1.5c-1.3,0.2-0.3,0.9,0,1.6c0.3,1.1,0.6,1.8,1.2,2.8
    		c0.2,0.4,0.2,2.4,0.2,3c-0.4-0.4-0.7-0.9-0.9-1.4c-1.4,0.8-0.1,2.4,0.4,3.4c-0.4-0.3-0.9-0.5-1.4-0.6c0.6,0.6,1.3,3.7,0.9,4.5
    		c0-0.5-0.1-1-0.4-1.4c0.5,0.7,0.1,1.5-0.1,2.2c0.8,0,0.9,0.6,0.6,1.2c-0.2,0.7-1.2,1-1.2,1.2c-0.4,1.4,0.1,1.4-1.3,2.1
    		c-0.4,0.4-0.9,0.7-1.3,1c-0.6,0.3-0.8,1.2-1.3,1.3c-0.8,0.2-1.6,0.6-2.4,1.1c-0.3,0.2-0.8,1.8-1.1,1.7c-0.7-0.2-2,0.7-2.5,1.1
    		c-0.8,0.6-2.2,1.1-2.8,1.8c-0.5-1-1.9-1-1.9-2.2c-0.1,0.5-0.2,1-0.3,1.5c-0.1-0.4-0.3-0.8-0.5-1.2c0.4,0.8,0.2,0.8-0.4-0.1
    		c0.8,0-0.8-0.5-0.8-0.5c0.2,0.9-0.1,2-1.2,1.7c0.3,0.8,1.4-0.4,1.7,0.7c0.3,1.5-2.2,0.7-2.8,0.7c0.9,0.7,2.4,0.6,2.9,1.7
    		c-0.9,0.1-0.9,1.5-1.9,0.8c-0.7-0.5-1.1-1.9-2-2c0.6,0.7,3.3,3,2.9,3.7c-1.8-0.7-2.7-2.5-4.2-3.6c0.3,1.2,5.6,4.7,3.2,6
    		c-1.6,0.8-4.2-3.3-5.3-4c0.8,1.3,3.6,3.2,3.1,4.9c-0.4,1.3-2.6,1.7-3.6,2.2c-1.8,0.9-1.8,2.2-2.9,3.6c-0.7,1-2.6,2.6-3.9,1.4
    		c0.4-0.5,0.9-0.8,1.4-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.3,0.1-0.6,0.3-0.9,0.4c0-1.8-0.3-3.6-0.3-5.4c0-0.9,0.1-1.8,0.3-2.7
    		c0.2-0.6,1.5-3,1.7-1.3c0-2.8-5.4-2.4-4.9-4.8c-0.1,0.4-0.7,0.1-1-0.3c0.5-0.2,1.2-0.7,1.7-0.8c4.2-0.4,2-2.1,3.3-3.6
    		c1.4,1.3,3.3-0.1,4.8-0.4c0.5-0.1,0.9,0.3,1.1,0.7c0.6,0.9,1.6,0.8,2.5,1.1c0-0.8-0.8-1.4,0.1-1.6c-0.2-1.3-1.9-1.4-2.4-2.3
    		c-0.5-0.8-0.8-1.8-1-2.7c-0.2-0.8,0.7-0.9,1.1-1.6c0.6-1,2.8,0.5,3.6,0.6c-0.5-0.8,0.1-1.8-0.5-2.6c0.7-0.2,1.6,0.1,2.1-0.1
    		c0.6-0.2,1.6-1.1,2.2-1.5c0.6-0.5,0.9-1,1.7-1.3c0.6-0.2,1.5-0.1,2-0.6c1.4-1.3-0.7-4.9-0.6-6.5c0.1-1.4,0.7-2.5,0-3.8
    		c-0.2-0.4-0.5-0.8-0.7-1.3c-0.4-0.8-0.1-1.3-0.4-2c-0.4-1-1-0.9-1.1-2.2c-0.1-1.6,0.9-2.5,1-3.9c0-0.3-0.1-1.9-0.3-2.1
    		c-1.2-1,0-0.9,0.5-2c0.7-1.6-1.2-3.2-2.4-3.9c-0.5-0.3-1.9-1.1-1.8-1.9c0.1-0.9,1.6-1.3,2-2.2c-1-0.4-2.3-1.2-3-2.1
    		c-0.5-0.7-0.9-0.1-1.5-1c-0.7-1.2-1.1-0.1-2-0.6c-0.5-0.3-1.1-1.6-1.2-2.2c-0.3-1.8-1.1-3.4-2.6-4.7c-0.4-0.4-1.4-0.6-2-1.2
    		c-1.1-1.2-2.4-2.3-3.4-3.6c-0.6-0.8-0.9-1.9-1.5-2.7c-0.2-0.3-0.7-0.7-1.1-0.4c-0.4-0.7-1.3-0.9-1.7-1.6c-0.3-0.5-1.2-1.7-0.6-2.3
    		c0.7-0.7-2.7-1.4-2.9-1.5c-2.3-0.5-3.8-3.1-5.9-3.7c-0.6-0.2-2.3-1-0.9-1.6c1.9-0.9-0.5-1.4-0.1-2.4c0.2-0.5,2.8,0.3,3,0.3
    		c1.1,0.2,3.9-1.6,2.4-2c0.3-0.5,1.3-0.7,1.3-1.4c0-0.9-1-1.7-1.8-1.5c-0.6,0.1-0.6-0.3-0.8-0.8c-0.4-1.1-1.5-0.8-2.3-1.1
    		c0.2-0.8,1.3-0.6,1.4-1.2c0.1-1.1-1.1-1.5-1.8-2c-0.8-0.6-1.4-1.3-2.6-1.1c-1.8,0.3-1.4,1.3-2.2,2.4c-0.8-0.5-2-1.4-2.9-1.3
    		c-1,0.2-2.9-1.6-3.4-2.3c-0.6-0.8-1.1-2.8-0.8-3.8c0.5-1.8-0.9-1.4-1.3-2.7c0.2,0.6,0.1,1.1-0.3,1.5c-0.9-0.3-1.2-2-2-2.6
    		c-1.1-0.9-2.9-2.4-3.5-3.7"
          />
          <path
            id="path3474"
            class="countryIsland"
            d="M2108.2,614.2c0.2,0.8,0.7,1.6,1.5,1.8C2109.1,615.5,2108.6,614.9,2108.2,614.2"
          />
          <path
            id="path3476"
            class="countryIsland"
            d="M2112.8,606.3c0.2,0.8-0.4,1.6,0.4,2.2C2113.6,607.7,2113.3,606.9,2112.8,606.3"
          />
          <path
            id="path3478"
            class="countryIsland"
            d="M2114,607.6c-0.1,0.2-0.1,0.5,0,0.7C2114,608.1,2114,607.8,2114,607.6"
          />
          <path
            id="path3480"
            class="countryIsland"
            d="M2130.7,589.2c0.1,0.3,0.4,0.6,0.7,0.6C2131.1,589.7,2130.9,589.4,2130.7,589.2"
          />
          <path
            id="path5494"
            class="countryIsland"
            d="M2102.7,518.2c0.1-0.3,0-0.6-0.4-0.7C2102.4,517.8,2102.6,518,2102.7,518.2"
          />
          <path
            id="path5496"
            class="countryIsland"
            d="M2103.4,518.3c0.3,0.4,0.7,0.8,1.2,1C2105.2,518.4,2104,518.1,2103.4,518.3"
          />
          <path
            id="path5498"
            class="countryIsland"
            d="M2107,514.5c-0.2,0.6-0.3,1.3-0.4,1.9C2107.4,516,2108.2,515,2107,514.5"
          />
          <path
            id="path5500"
            class="countryIsland"
            d="M2108,516.6c-0.4,0.3-0.7,0.7-0.9,1.2C2107.6,517.6,2108,517.2,2108,516.6"
          />
          <path
            id="path5502"
            class="countryIsland"
            d="M2109.9,513.6c0.2,0.1,0.5,0.1,0.7-0.1C2110.3,513.5,2110.1,513.5,2109.9,513.6"
          />
        </g>
        <g id="ru" class="countryGroup">
          <path
            id="Russia_Kaliningrad"
            class="country"
            d="M1439.7,230.9c1.1-0.8,1.9-1.7,2.1-3.1c0.1-0.8,0-1.1,0.9-1.2c1.2-0.1,2.5,0,3.5-0.7
    		c0.8-0.6,1.4-2.2,2.5-2.3c-1,0.9-2.2,1.8-2.9,3c2.5,0,4.4,1.4,4.3-1.6c-0.1-2.7,3.4-0.3,4.4,0.1c2.1,0.9,5-0.4,6.7,1.4
    		c0.7,0.8,1.3,0.9,0.4,1.9c-0.7,0.8-0.5,2.8,0.3,3.4c-7,0.7-14.1,0.2-21.1-0.6c0.6-0.3,0.8-1,1.4-1.3c0.9-0.4,1.8-0.2,2.4-1
    		c-0.9-0.1-1.8-0.2-2.6-0.1C1441.2,228.9,1440.8,230.8,1439.7,230.9"
          />
          <path
            id="path2924"
            class="country"
            d="M1537.4,177.4c-0.4,0.1-1.2,0.8-1.4,1.1c-0.2,0.3-0.2,0.5-0.4,0.6c-0.4,0.2-0.5,0.3-1,0.3
    		c-0.3,0-0.6,0-0.8,0c-0.2,0-0.6,0.1-0.8-0.1c-0.2-0.1-0.5-0.2-0.6-0.3c-0.1-0.1-0.1-0.6,0-0.7c0.2-0.2,0.6-0.3,0.7-0.5
    		c0.1-0.1,0.1-0.5,0.2-0.6c0-0.1,0.2-0.4,0.1-0.5c-0.2-0.4-0.7-0.6-0.9-1c-0.2-0.4-0.6-0.9-0.9-1.2c-0.2-0.2-2-0.2-2.4-0.4
    		c-0.1-0.1-0.3,0-0.4,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.3,0.1-0.3,0c-0.4-0.2-0.6-0.4-0.9-0.7c-0.1-0.1-0.2-0.1-0.3-0.1
    		c0,0,0-0.1,0-0.1c-0.1-0.1-0.3,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.6-0.2-1.3-0.6-1.7
    		c-0.1-0.1,0-0.3-0.2-0.4c-0.2-0.1-0.2-0.4-0.3-0.5c0,0-0.1-0.2-0.1-0.2c0.5,0,0.8,0.7,1.2-0.1c0.1-0.3-0.2-0.5-0.3-0.6c0,0,0-1,0-1
    		c0.1-0.1,0.4,0.3,0.4,0.3c0.2,0.1,0.6,0.3,0.8,0.4c0.2,0.1,0.5-0.1,0.7,0c0.2,0.1,0.5,0.4,0.6,0.5c0,0.1,2.5,0.1,2.5,0.1
    		c0,0,0.4-0.2,0.5-0.2c0.3-0.4,0.2-1,0-1.4c-0.2-0.3-0.2-0.6-0.4-0.8c-0.1-0.1-0.8,0-0.9,0c-0.3,0-0.7,0-1-0.1
    		c-0.4-0.1-0.8,0.1-1.3-0.1c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.9-0.8-1.2-1.1c-0.2-0.2-0.6-0.1-0.7-0.3c-0.3-0.3-0.9-0.9-0.9-1.4
    		c0-0.2-0.4-0.6-0.3-0.8c0.1-0.1,1.3,0.6,1.5,0.6c0.1,0,0.7,0.4,1,0.4c0.5,0.1,2.7,0.8,2.9,1.1c0.1,0.1,0.8,0.2,1,0.2
    		c0.4,0.1,0.7,0.3,1.1,0.4c0.2,0.1,0.4,0,0.5,0.1c0.1,0,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,0.9,0.3c0.1,0.1,0.2,0.2,0.2,0.3
    		c0,0.1,0.4,0.4,0.4,0.5c0.1,0.1-0.2,1.3-0.2,1.5c0,0.2,0.1,0.8,0,0.9c-0.2,0.3-0.4,0.7-0.3,1.2c0.1,0.3,0.2,0.4,0.3,0.6
    		c0,0.1,0,0.1,0,0.2c0.1,0.2,0.4,0.6,0.7,0.8c0.3,0.3,0.8,0.4,1.1,0.6c0.6,0.3,1.3,1.1,1.7,1.5c0.1,0.1,0.1,0.3,0.2,0.4
    		c0.2,0.2,0.6,0.4,0.8,0.7c0.1,0.2,0.1,1-0.1,1.2C1537.7,176.9,1537.8,177.2,1537.4,177.4L1537.4,177.4z M1512.8,176.5
    		c-0.3-0.2-1.7-0.1-1.9-0.2c-0.4-0.2-0.7-0.3-1.2-0.6c-0.5-0.2-1.1-0.5-1.4-0.8c-0.1-0.1-0.2,0-0.2-0.1c-0.2-0.2-0.5-0.7-0.8-0.8
    		c-0.4-0.2-0.8-0.5-1.2-0.9c0,0-1.4-0.3-1.5-0.3c-0.2-0.1-1.1-0.5-1.2-0.4c-0.2,0.1-0.1,0.3-0.1,0.4c-0.3,0.5-0.7,1-1.1,1.6
    		c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0.2-0.4,0.4-0.6,0.5c-0.5,0.2-1.2,0.2-1.6,0.6c0,0,0,0.2-0.1,0.2c-0.3,0.3-0.1,0.4-0.2,0.7
    		c-0.3,0.7,0.5,1.1,1.1,1.1c0.3,0.2,1.9,1,1.9,1.1c0.1,0.2,0.3,0.4,0.4,0.6c0,0.1,0,0.2,0,0.2c0.2,0.2,0.1-0.1,0.2,0.2
    		c0.2,0.9,0.1,1.4,0.7,1.9c0.2,0.2,0.5,0.3,0.9,0.4c0.2,0.1,0.4-0.1,0.6,0c0.2,0.1,0.4,0.4,0.6,0.6c0.2,0.2,0.4,1.2,0.5,1.5
    		c0.2,0.4,0.6,0.7,0.8,1.1c0.1,0.2,0.2,1.1,0.2,1.2c0.1,0.2,0.4,0.6,0.6,0.7c0,0.1,0.4,0.1,0.5,0.1c0.5,0,0.9-0.3,1.2-0.6
    		c0.1-0.1,0.1-0.3,0.1-0.3c0.1-0.1,0.4-0.3,0.4-0.4c0.1-0.1,0.1-0.5,0.1-0.6c0.2-0.2,0.5-0.7,0.7-0.8c0.2-0.1,0.5-0.1,0.8-0.1
    		c0.2,0,0.8-0.2,1,0c0.3,0.3,0.7,1,1.3,1.1c0.3,0.1,0.6,0,0.9,0.1c0.3,0.1,0.7,0.1,1-0.1c0.4-0.2,0.7-1.4,0.9-1.8
    		c0.1-0.2,0-1,0.2-1.4c0.2-0.4,0.6-0.3,0.9-0.4c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.3-0.1-1.3-0.2-1.6c-0.2-0.3-0.8-0.7-1.1-0.8
    		c-0.1-0.1-0.3,0-0.4-0.1c-0.1-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.9-0.5-1-0.6c-0.3-0.2-0.8-1.3-0.9-1.5c-0.1-0.2-0.5-0.2-0.6-0.2
    		C1513.1,176.4,1513.1,176.4,1512.8,176.5L1512.8,176.5z M1840.8,57.4c-1.3,0-2.6,0.3-3.9,0.5c-1.5,0.3-2.8,1.8-4.4,1.5
    		c0.4,0.1,0.9,0.2,1.3,0.3c-0.7,0.5-1.3,0.9-2,1.4c0.2,0.2,0.4,0.5,0.7,0.7c-1.5-0.4-1.1,1.1,0.2,1.4c0.7,0.2,1.7,0.2,1.5,1.2
    		c-0.1,0.2,1.9,0.6,3.9,0.9c-0.4,0-0.8,0-1.2,0c-1,0.1-2.4-0.6-3.2-0.4c-3,0.8-6.2,0.4-9.2,0.1c2.1,1.9,5.5,1,7.6,3
    		c-3.7-0.3-6.1-1.7-9.9-0.7c2.4,0.4-1.2,0.4-1.5,0.5c0.5,0.1,1,0.2,1.5,0.4c-0.8,0.3-1.9,0.5-2.7,0.3c-1-0.2-1.7,0.6-2.6,0.5
    		c-0.9-0.1-1.5-0.9-2.5-0.5c-0.8,0.3-1.6,0.5-2.4,0.6c0.4-0.5,0.9-0.9,1.5-1.3c-4.2-1.3-8.6,0.2-12.8-0.3c0.5,0.2,1.1,0.3,1.6,0.3
    		c-1.4,0.6-3.1-0.5-4.3,0.1c-1.2,0.5,0.4,1.2,1,1.1c-2.1-0.3,1.3-0.3,1.8-0.3c1.1,0.1,2.1,0.1,3.1-0.1c-2.6,1.3-5.8,1.3-8.7,1.5
    		c-3.3,0.2-6.7,0.6-10,0.9c0.8,0.7-1.4,0.9-2.1,1c-0.3,0-3.7-0.1-1.7,0.1c-1.6-0.1-2.3,1.6-3.7,2c-1.8,0.6-3.6-0.7-5.4-0.2
    		c0.8,0.7,4.4,0.1,4.3,1.4c-0.8-0.1-1.6-0.6-2.4-0.4c1.5,0.2-0.3,1,0.8,1.8c-1.3-0.5-0.7,0.2-0.3,0.6c-1.8,0.9-3.5-1.6-5.3-1.2
    		c2,0.6-1.2,0.4,0.4,1.2c0.5,0.2,1.1,0.3,1.6,0.4c1.1,0.2,2.1,0.1,3.2,0.2c-2.1,0.1,0.8,0.6,1.2,0.6c0.6,0.1,1.3,0.2,1.7,0.6
    		c-0.4,0-1.3,0.7-1.7,0.4c-0.3-0.3-0.6-0.5-1-0.6c-1-0.4-1.9,0-2.9-0.1c0.6,0.1,1.1,0.3,1.5,0.7c-0.5-0.2-1.1-0.3-1.6-0.3
    		c1,1.1,2.6,0.7,3.9,0.9c1.6,0.3,3,1.1,4.7,1.2c-0.5,0-1,0-1.5,0c0.3,0.4,0.8,0.8,1.3,1c-1.1,0.9-4.4,0.1-5.8,0.2
    		c-1.2,0.1-2,1.1-3.1,1.2c-1.4,0.1-2.7-0.6-4.1-0.3c-3.5,0.6-7.5,0.6-11.1,0.6c-2,0-7-0.6-8.5,1.2c1.7-0.3-0.2,0.8-0.7,1.1
    		c0.5,0,2.7,1,0.8,1c1.9-0.1,3.9,1.1,4.4,2.8c0.6,1.9,2.9,2.6,4.6,2.8c2.3,0.3,4.7-0.1,6.8,1.3c0.8,0.5,1.2,0.9,2.1,1.3
    		c0.8,0.4,1.6,0.7,2,1.5c-2.3,0.5-4.7,0.7-6.9,0c-1.8-0.5-3.5-1.4-5.3-1.8c2,0.4-0.2-0.7-0.6-0.8c-0.9-0.3-1.9-0.3-2.8-0.4
    		c-1.8-0.2-3.5-0.9-5.2-1.2c-1.7-0.3-3.8-0.2-5-0.1c-1.2,0.1-2.8,1.9-4,1.2c-0.5,1.4,1.2,0.9,1.9,0.8c1.2-0.2,2.6,0.3,3.2,1.3
    		c-2,1.5-4.2,0.1-6.2-0.5c-1.9-0.6-3.7,0.2-5.6,0.5c1.2,0.9,2.2,2.3,3.7,2.7c1,0.3,2.1,0.4,3.1,0.7c1.4,0.4,4.9,2,6.2,0.5
    		c0.2,1.2,1.4,0.4,2.1,0.9c0.8,0.6,1.2,1.7,2.4,1.2c0,0.3,0,0.5,0,0.8c1.1-0.2,2,0.1,3-0.5c-1.2,3.6-11.6-3.5-11.7-0.4
    		c-1-1.7-3.8-0.8-5.3-1c-1.7-0.2-4.3-0.2-5.5-1.5c1.7,0.2,0.4-0.9-0.2-1.4c-1.4-1-1.5-1.3-1.2-3.2c0.4-2.8-4.6-6.5-7.3-5.1
    		c1.2,0.8,6.2,3.9,3.2,5.3c-1.5,0.7-3.5,0.4-4.7,1.5c-0.4,0.3-0.2,1.1-0.4,1.5c-0.2,0.6-0.8,1.1-1.3,1.5c3.1,0.8,5.7,2.7,8.4,4.3
    		c0.7,0.4,2.4,1.1,2.5,2c0.1,0.6-0.9,1.5-1.1,2.1c-0.2,0.7,0,1.4,0.1,2.1c0.2,1.9,1.3,2.2,2.6,3.3c0.9,0.8,1,2.7,2.3,2.8
    		c1.8,0.1,4,0,5.8-0.4c1.8-0.4,2.9-1.1,4.8-0.6c1.6,0.4,3.7,0.3,5.1,0.8c1.7,0.6,3.2,1.1,4.9,1.5c1.8,0.5,2.1,1.8,3.4,2.9
    		c0.7,0.6,1.6,0.9,2.1,1.6c0.5,0.6-1.7,0.5-1.8,1.3c-0.2,1,0.8,2.6,1.8,2.7c1.7,0.2,3.4,0.6,5.1,0.7c0.6,0,0.6,0.7,1.2,0.2
    		c0.6-0.4,1.4,0.2,1.9,0.5c-3.4-0.7-9,1.1-11.6-2.1c1.4,0.4,0.4-0.8,0.1-1.4c-0.3-0.8-0.7-1.6-1.1-2.3c-0.9-1.6-1.9-1.6-3.5-2.2
    		c-1.3-0.5-2.5-2-3.7-2.3c-2.1-0.6-4.8,0.4-6.8,0.9c-1,0.2-3.8,0.1-3,1.7c0.5,1.2,0.1,1.6,1.4,2.3c1.7,0.9,6.1,3.8,2.6,5.4
    		c-2.1,1-0.8,2.3-1.2,4c-0.4,1.8-2.7,1.5-3.8,2.4c-1.1,0.8-2.7,0.2-2.1,2c0.2,0.7-0.8,1.5-1.5,1.5c-1.1,0-2.1-0.6-3.1-0.8
    		c-2.1-0.4-4,0-6.1-0.2c-1.9-0.2-3.9-0.8-5.8-1c-2-0.2-2.5-1.8-4.2-2.3c1.5-0.7,3.3-0.1,4.9,0c1.5,0.1,2.4,1.2,4,0.8
    		c1.6-0.4,3.1-1.4,4.6-1.6c0.6-0.1,4-1.2,1.8-1.6c0.5-0.2,1-0.3,1.5-0.2c-0.3-0.2-0.6-0.4-0.8-0.7c1.7-0.7,1.1-3.2,3.6-3.5
    		c-0.7-0.7-0.3-1.8-1.1-2.7c-0.5-0.6-0.5-0.8-0.2-1.5c0.4-1,1.3-1.2,0.2-2.1c-0.7-0.5-1.6-0.6-2.3-0.9c-0.9-0.4-6.5-1.8-5.6-3.7
    		c0.5-1-0.6-0.7-0.5-1.9c-0.6-0.9-1.1-1-1.1-2.1c-0.1-1.3-2.6-2.4-2.9-3.6c-0.3-0.9,1.3-0.9,0.5-1.7c-0.6-0.7-0.5-1-0.8-1.8
    		c-1.4-3.6-6.5-2.3-8.5-5.3c0.5-0.2,1.3-0.2,1.6-0.7c0.4-0.6-0.4-1.1,0-1.5c0.6-0.6,0.6-2,0.6-2.7c-0.1-1.9-2.8-3.8-4.5-4.1
    		c-1.2-0.2-2.4,0.1-3.5-0.3c-1.3-0.5-2.7-0.5-4.1-0.4c-2.7,0.2-5.2,0.6-7.9,0.2c1.7-0.4-0.4-0.6-1-0.2c-0.7,0.5-1,1.3-1.1,2.1
    		c-0.2,1.9,1.5,4.8,0.2,6.6c-1,1.3-2.9,2-4.4,2.4c-1.2,0.3-2.6,2.7-0.7,2.3c-1.7,1,0.7,1.7,1.6,1.4c2.1-0.6,1.9,1.1,2.5,2.4
    		c0.2,0.4,0.2,1.5,0.6,1.7c0.5,0.3,0.9,0.4,1.2,0.9c0.6,0.9-1.6,0.8-1.9,0.8c0.2,1.1,0.2,2.6,1.6,2.9c0.1-1.4,2.5-0.1,3.5,0.2
    		c0.9,0.3,2.4,0.8,3.2,0.1c0.6,0.8,0.4,1.7,1.3,2.3c0.7,0.5,1.3,0.8,2,1.2c0.8,0.4,1.6,1.1,2.5,1.1c0.8,0,1.5-0.6,2.2,0
    		c-1.2,0.1,0.3,0.8-0.1,2c-0.2,0.6-0.8,1.5-0.5,2.1c1.1,2.5-3.5-0.6-3.7-0.6c-3-0.8-5.8-3-8.8-3.4c-1.3-0.2-2.6-0.4-3.9-0.7
    		c-0.5-0.1-4.3-1-2.3-1c-0.9-0.1-4.4-1.5-4.8-0.5c-0.4,1-1.8-0.5-0.3-0.8c-2.9-0.6-5.7-1.8-8.7-2.4c-2.8-0.6-5.8-0.6-8.7-0.7
    		c-1.6-0.1-3.3-0.1-4.8-0.5c-0.9-0.2-2-0.6-2.8,0.1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.3,0-0.5,0.1-0.8,0.1c-0.6,0.4-1.1-0.2-1.5,0.6
    		c1.2,0.7,2.7,1.3,3.5,2.5c0.8,1.1,1.8,0.1,2.4,1.1c2,3.2-4.2,2.3-4.9,3.3c0.4,0.2,1.8,1,1.5,1.6c-0.4,0.9-2.4,0.3-3,0.2
    		c-1.6-0.3-3.2-2.1-0.8-2.7c-0.6-0.4-4.1-1.6-2.1-1.8c-1.6,0.3-3.4-0.2-4.6,1c0.2,0.3,0.4,0.5,0.6,0.7c-0.1-0.1-2.5-1.3-2.3-0.4
    		c0.1,0.6-0.7,0.7-1.2,0.7c-0.8,0-1.2,0.9-2,1.1c-0.8,0.2-1.6,0-2.3-0.2c-2.2-0.6-10.4-0.9-9.7,3c-0.9,0.4-1.7-1.1-2.4-0.8
    		c-0.6,0.3-1.7,1.2-1.6-0.1c-1,1.2-4,0.7-5.3,0.5c0-1.7,2.6-0.5,3.3-1.4c-0.3-0.5-2.7-2.8-0.6-3c-0.8-0.3-1.7-0.2-2.4-0.5
    		c1.5-0.4,3.2,0.3,4.5-0.6c-3-0.1-8.6,0.4-10.7,3c0.9-0.1,1.7,0.5,2.3,1.1c-0.5,0.1-2.4,1.5-2.7,0.7c-0.3-0.7,0.6-1.1-0.4-1.4
    		c-1-0.3-2.3,0.1-3.2,0.7c-0.9,0.6-2.2,0.3-3.2,0.4c-1.9,0.1-3.5,2-5.1,2.4c-2.3,0.6-3.9,1.7-6.4,1.2c1.1,0.4,2.1,1.2,2.9,1.9
    		c-0.9-0.5-3.8-0.8-4.7-0.1c-0.9,0.6-1.7-0.1-2.3,0.5c1.3,0.7,0.6,1.6,0.3,2.6c-0.2,1,1.3,1.6-0.1,2.4c-0.5,0.3-2.1,0.1-2.7,0.2
    		c-1.1,0.2-2.6,0.1-3.4,0.8c0.2-0.2,0.4-0.5,0.6-0.7c-1.4-0.1-3.2,0.4-4.4-0.4c-0.9-0.6-1.3-1.6-2.3-2.1c-1.1-0.5-2.6-0.2-3.4-1.1
    		c-1-1.2,0.6-1.8,1.5-2c-0.1-0.3-0.2-0.5-0.3-0.8c2.4-0.9,5.1-0.1,7.6-0.8c-0.8-1.9-2.9-2.4-4.5-3.4c-1.8-1.1-2.9-1.8-5-2
    		c-2.3-0.2-4.8,0.7-7,0.1c-1.1-0.3-3.3-1.5-4.2-0.4c1.7,0.5,3.9,1.2,5.4,2.1c0.9,0.6,0,2,1.2,2.1c-1.1,0.2-0.7,0.6-0.1,1
    		c-1.2,0.1-0.3,0.5-0.4,1.2c-0.1,0.6-0.3,1.2-0.5,1.7c-0.3,0.7-0.9,1.9,0.1,2.3c1.4,0.6,2.7,0.3,3.9,1.6c0.7,0.7,0.2,2.1,1.4,2.4
    		c-2.6,0.8-1.6,4.5-0.4,5.9c-0.9-0.7-1.4-2.1-2.7-2.3c-0.7-0.1-2,0-1.9,1c-0.3-0.1-0.6-0.3-0.8-0.5c0.4-0.4,0.9-0.7,1.5-0.8
    		c-0.3-1.2-2.4-1.6-3.4-1.6c-1.6-0.1-2.3-0.1-3.8-0.5c-1.5-0.4-2.4,0.6-3.3,1.6c-1.1,1.2-2.4,1.6-3.9,1.9c-1.6,0.4-2.5,1.4-3.8,2.2
    		c-0.7,0.5-2.9,1-2.7,2.2c0.1,1.1,2.7,2.3,3.5,3c1.2,1.1,2.3,2.3,2.9,3.8c-0.9-0.3-3.1-0.9-4-0.7c-1.4,0.3-2,0.4-3.4-0.2
    		c-1.5-0.6-3.1-1.1-4.7-0.9c-1,0.1-2.2,1.4-2.5,0c0.6,0.1,1.2-0.3,1.6-0.7c-2.4,0.7-4-1.7-6.2-2.1c-0.8-0.2-2.4-1-2.9,0.1
    		c-0.6,1.2-0.7,1.6-2.3,1.5c1.2,1.7,3.2,2.6,4.8,3.9c0.8,0.6,1.8,0.5,2.6,0.2c1.1-0.3,1.8,0.4,2.8,0.7c-0.8,1,2.3,3.4-1.2,3
    		c-1-0.1-1.2,0.9-2.6,0.5c-1.4-0.4-3-0.6-4.4-1c-1.2-0.4-1.8-1.4-2.8-2c-0.6-0.3-0.5,0.7-1.1-0.1c-0.3-0.3-0.6-0.6-1-0.7
    		c-1.2-0.6-1.9,0.5-3-0.1c-0.9-0.4-1.8-1.1-2.8-1.4c0.2-0.2,0.4-0.5,0.6-0.7c-0.3-0.2-0.5-0.3-0.8-0.5c0.3-0.2,0.6-0.3,0.9-0.5
    		c-1-0.1-1.1-1.5-2.1-1.6c1-1.2-1.5-2.1-2.1-2.7c-0.6-0.6,2.3-1.4,1.3-2.8c2.1,0.5-1.1-2.4-1.6-2.7c-2.2-1.3-5.1-1.7-7.6-1.8
    		c0.7-0.3,1.9-0.1,1.6-1.3c-0.9,0.2-3,0-3.9-0.2c0.8-0.4,1.9-0.4,2.8-0.2c-0.9-0.9-2.4-0.1-3.5-0.4c0.8,0.1,1.6-0.7,2.4-0.3
    		c-0.9-0.7-2.1-0.5-3.1-0.8c1.9,0.2-1.5-1.2-2.2-1.1c0.2-0.3,0.5-0.4,0.7-0.7c-1.3-0.9-2.8-0.7-4.1-1.3c1,0.2,2-0.4,2.9,0
    		c1,0.4,2.2,0.5,3.3,0.5c-2.2,0.1,2.2,2.2,2.7,2.4c0.7,0.2,1.9-0.3,2.4,0.1c0.7,0.5,1.5,0.6,2-0.2c-0.3,1.3,1.6,0.5,2.2,1.7
    		c0.1,0.1,2.6-0.3,3.1,0c2.7,1.2,5.2,1.8,8.2,1.9c3.5,0.2,6.6,1.3,10.1,1.6c3.1,0.3,5.8-0.1,8.8-0.8c2.7-0.6,4.4-1.7,6.5-3.5
    		c1.3-1.2,2.9-3.7,0.4-4.3c-0.5-0.1-1.1-1.7-1.3-2.2c-0.4-1.3-0.9-1-1.9-1.5c-1.1-0.6-2.3-0.4-3.3-1.3c-0.9-0.8-2-1.5-3.1-1.9
    		c0.2,0.3,0.3,0.5,0.5,0.8c-2.5,0.3-5.3-1.1-7.4-2.3c0.3,0.2,0.6,0.4,0.8,0.6c-2.7-0.5-4.8-2.5-7.3-3.2c-3-0.9-6.1-1.8-9-2.9
    		c-2.6-1-4.9-0.3-7.5-0.7c-1.1-0.2-2.2-0.7-3.3-0.7c-1,0-2.1,0.4-3.1,0c0,0.7-0.3,1-0.7,1.2c-0.7,0.5-1.7,0.6-2.1,1.5
    		c-0.6-1.2,1.5-1.1,2.1-1.5c-0.5-0.2-1.4-0.5-0.1-0.7c-1-0.2-0.7-0.3,0-0.7c-0.8-1-2.2-0.5-3.1,0.1c0.2-0.3,0.3-0.4,0.5-0.7
    		c-0.8,0-1.7-0.3-2.5-0.4c-0.4,0-0.7,0.1-1,0.2c0.1,0,0.3-0.2,0.4-0.2c-0.9-0.5-1.9-0.7-2.8-1.1c0.1-0.3,0.2-0.5,0.4-0.7
    		c0.8,1,5.4,2.2,5.6,0.2c-1.2-0.5-2.6-0.3-3.8-0.9c-0.8-0.4-2.2-1.1-3.1-0.7c0.2,0.2,0.5,0.4,0.7,0.6c-0.4,0.1-1.5-0.1-1.6,0.5
    		c-0.1,0.4-1.1,1-1.4,0.8c-0.9-0.5-2.3-0.6-3.3-0.8c0.7,0.5,1.2,1.4,0,1.7c-1.2,0.3-2.3-0.3-3.3-0.7c0.9,2.1-2.2,2.1-3.4,2.3
    		c-1.4,0.3-0.4,1-1.2,1.7c-1.1,1-3,1-4.3,1.5c0.8,0.1,3,0.3,1.6,1.5c-1.2,1.1-1,2.1,0.4,3c1.3,0.9,3,0.9,4.3,1.8
    		c1,0.7,2.9,1.5,3.7,2.4c1.8,2.1-5.8,3.7-3.4,6.7c2.2,2.8,5.6,4.6,6.8,8.3c-0.8,0-1.5,0.3-2.2,0.4c1,0.3,0.4,1.8,0,2.5
    		c-0.4,0.7,1.1,0.9,1.3,1.4c-1.6,0.3-1.2,2.1,0.1,2.5c0.9,0.3,1.9-0.2,2.4,0.8c-2.8,1.2,1.2,2.7,2.3,3.2c2.4,1.2-1.3,3.6-2.4,4.1
    		c3,1.7,6,3.2,8.9,4.9c1.2,0.7,1.7,1,1.1,2.3c-0.7,1.5-1.1,2.6-2.5,3.6c-3,2.3-5.3,5.2-8.2,7.6c-2.8,2.3-6.3,3.8-8.8,6.5
    		c1.4,0,2.1,0,3.3-0.8c0.5-0.3,1.6-1,2.1-0.4c-0.7,0.4-0.2,1.3,0,1.8c-0.5-0.2-1-0.5-1.5-0.8c0.9,1,2.9,2.6,4.1,3
    		c1.7,0.6,3.2-0.6,4.8,0.6c0.1,0.1,3.6,1.9,2,1.9c-1.7,0-3.2-1.1-4.9-1.1c-1.5,0-1.8,1.6-3.1,1.7c-0.8,0.1-1.7-1-2.2,0
    		c-0.2,0.3-0.2,0.8-0.6,0.8c-0.7,0-2.1-1.4-1.8,0c0.1,0.7,0.7,0.9,0.2,1.6c0.5,0.2,1,0.5,1.2,1c-1.7,0-1.6,1.2-2.2,2.4
    		c-0.4,0.9-1.7,1.3-1.7,2.3c0,1.1,0.9,2,0.7,3c-0.1,0.6-0.3,1.2,0.3,1.7c0.7,0.6,1.2,2.2,2,2.3c-2.1,1.7-3.3,2.7-0.1,4.1
    		c1.4,0.6,0.9,3,0.1,4c1.6-0.2,2.9,2,3.6,3.2c0.3,0.5,0.6,3.7,1.5,3.3c1.6-0.7,2.4,0.9,4,0.5c0.8-0.2,1.7-0.2,2.4,0.1
    		c0.8,0.4,0.4,0.9,0.5,1.5c0.3,1.6,4.3-0.8,5.3-0.5c0.7,0.2,4.7,2,4.7,2.5c0.1,1.1-0.4,1.6,0.3,2.7c0.4,0.5,0.8,1.6,0.1,2.1
    		c-1.3,0.9,1.9,3.1,2.5,4c0.6,1,1.2,2.1,2.3,2.6c0.8,0.4,2.2,0.2,2.4,1.3c0.2,0.6-0.9,1.9,0.6,1.6c1.1-0.2,1.4-0.1,2.5,0.2
    		c1.8,0.5,0.2,0.6,1.2,1.3c0.6,0.5,1.9,0.9,2.1,1.7c0.2,0.6-3,2.4-3.6,2.4c-1.8,0.2-3.3-1.7-5.1-0.2c-0.9,0.7,0.5,1.7,1,2.1
    		c1.1,0.9,0.7,0.9,0.9,2.1c0.3,1.8,0.9,3.8,2.8,4.4c1.6,0.5,2.9-0.3,2.8-2c1.8,0.1,2.9,0.8,4.6,0.1c1.6-0.6,2.7,0.5,4.1-0.1
    		c1.5-0.6,2.5,1.5,2.9,2.5c0.5,1,2.1,1.2,2.5,2.2c-0.6,0.4-1.6,0.1-2,0.9c-0.2,0.4,2.2,2.6,1.1,3.2c0.9,0.3,3.3,1.1,4.2,0.6
    		c1.5-0.7,2.1,1.3,3.5,1.2c-0.4,0.8,0.8,3.1,1.1,3.9c0.5,1.4,1.6,2.1,2.9,1.3c1.2-0.7,1.9,0.2,2.9,0.7c0.1,0.1,2.1,0.9,2.1,0.9
    		c1.7-0.1,3.3-1.9,5.2-1.7c1.1,0.1,1.7,1.8,2.3,2.5c0.7,0.8,2,0.8,2.4,1.9c1.7-1,0.9-1.7,2.9-0.6c1.1,0.6,4,1.7,4.8,0.7
    		c0.8,1.5,1.9,0.6,2.7,1.6c0.6,0.7,1.6,1.1,2.5,1c0.6,0,0.8-0.6,1.5-0.2c-1.1,1.1,1.5,3.4-1,4.4c-0.7,0.3-1.2,0.3-0.7,1.2
    		c0.7,1.1,1.7-0.4,1.8,1.1c-0.9-0.3-2.3,0.5-1.8,1.6c0.2,0.4,2.8,1.3,1.4,2.3c0.3,0.2,0.6,0.3,1,0.2c0.2,0.9-0.5,3.8-1.5,3.9
    		c-1.1,0.1-2.3-0.3-3.4-0.1c-0.9,0.2-2-0.6-2.6,0.5c-0.3,0.6-0.7,1-1.3,1.2c-1.3,0.4-2.7,2.6-1.1,2.9c-1,0.9-0.2,1.8,0.9,1.3
    		c0.3-0.1,0.5-0.3,0.7-0.4c-0.1,0.1-0.3,0.3-0.4,0.5c1.4,0.2,2.6-0.8,3.9-1c1.7-0.3,0.9,1.5,2.2,1.1c-1,0.9-2.8,1.9-4.3,2.1
    		c-3,0.4-0.8,0.5-1.1,1.9c-0.9,0.1-1.6-0.5-2.5-0.2c-0.8,0.3-1.5,0.6-2.4,0.2c0.7,2.1,1.8,1.7,3.3,2.6c0.6,0.4,2.8,1.6,2.6,2.5
    		c-0.7,0-1.5-1.1-2.2-0.8c-0.6,0.3-0.5,1.6-0.9,2.1c-0.1-0.5-0.2-0.9-0.3-1.4c-0.9,0.6-0.9,4.3-2.4,3.4c-0.1,0.5,0.3,2.5,0.8,0.7
    		c0.1,0.7,0.7,1.4,0.3,2.2c-0.5-0.2-3.8-0.5-3.5,0.1c-1-0.8-3-2.1-3.5-0.4c0.2-0.2,0.5-0.4,0.7-0.5c0,0.6,0.4,1,1,1
    		c-0.7,0.4-1.5,0.5-1.8,1.3c2,0.9,4.2,0.6,5.4,2.7c0.7,1.1,4,1.9,2.9,0.6c1.5,0.4,2.2,2.1,3.5,2.8c1.4,0.8,3.1,1,4.5,1.8
    		c3.3,1.8,5.5,5.2,8.9,6.9c-0.2-3.5,7.6-0.3,9.1,0.4c1.2,0.5,2.5,0.9,3.8,1.1c1.2,0.2,2.2-0.2,3.3-0.2c0.8,0,1.5,0.6,2.3,0.6
    		c0.8,0,1.6-0.1,2.3,0.4c0.8,0.6,6.7,3,5.8,3.9c2.2,1.6,5.9-2.8,7.4,0.3c0.1-1.6,2.4-1,3.1-0.2c1.1,1.4,1.8,0.3,3.2,1
    		c0.9,0.5,0.2,1.6,0.3,2.4c0.1,0.8,1.8,1.4,2.4,1.6c2.3,0.9,4.9,1.1,6.7,2.8c0.7,0.7,1.4,1.1,2.2,1.5c0.9,0.5,0.9,1.8,1.9,2.2
    		c0.8,0.4,2.5,1.1,3.4,0.7c0.5-0.2,0.9-1.5,1.3-1.9c0.8-0.9,1.8-1.5,2.4-2.6c0.7-1.1-0.3-1.4-1.1-2.2c-0.9-1-1.6-2.1-2.6-3
    		c-0.9-0.8-2.1-1.5-2.7-2.5c-0.7-1.1-1-2.2-2.2-2.9c-0.8-0.5-0.6-2.3,0.1-2.8c-1.8-0.3-0.7-3.9-0.1-4.8c-1.3-0.8-1.2,2.1-1.1,2.8
    		c-1.3-0.2-0.4-0.8-0.4-1.7c-0.1-0.9-0.7-1.7-1.2-2.4c-0.8-1.2-1.4-1.7-2.7-2.4c-0.9-0.5-2.6-0.8-2.5-2.2c0.1-1,2.2-1.8,1.4-2.8
    		c0.2,0.3,0.4,0.5,0.6,0.7c0.8-2.8,3.2-5.3,0.9-7.9c0.9,0,1.4,0.9,1.8,1.6c-0.2-0.6-0.3-1.4-0.8-1.8c0.8,0.2,1.6,0.8,2.3,1.2
    		c-0.3-1.6,1.2-0.5,2.1-1c0.5-0.2,0.7-0.8,1.3-0.7c-1.2-1.5,2-0.3,2.3-0.1c0.1-0.6-0.2-0.9-0.8-0.9c0.4-0.5,0.3-1-0.4-1.1
    		c0.5-0.3,1-0.2,1.5,0.1c0-1,1.1-1,1.7-0.6c-0.1-0.5-0.2-1-0.2-1.5c0.3,0.1,0.5,0.3,0.8,0.4c-0.2-1.5-6.1-2.8-5.7-3.6
    		c0.5-1,3.5,0.7,3.1-0.7c-0.3-1-1.5-1.8-2.1-2.5c-2.2-2.4-3.7-5.3-7.2-5.9c-0.8-0.2-2.6-0.5-3.2,0.3c-0.7,1-1.8-1.8-2.2-2.2
    		c0.2-0.2,0.5-0.3,0.7-0.5c-1.1-0.9-0.9-1.6-2.4-2c-0.9-0.2-1.9-0.5-2.4-1.3c-0.9-1.5,0.3-3.8,1.5-4.7c1.7-1.2-1.4-2.3-1.6-3.4
    		c-0.5-2.4,0.1-3.4,1.8-4.6c1.1-0.8-1.1-3.8,1.6-3.7c2.3,0,3.9,3.8,5.8,4.7c1,0.5,2.6-0.1,3.3-0.9c0.6-0.6-0.4-1.3-0.7-1.8
    		c-0.6-1-1-3.4-2.2-3.8c1.8,0.5,3.3-0.4,4.7-1.5c0.5-0.4-0.3-1.2-0.2-1.7c0.2-0.8,1.3-0.5,1.8-0.5c1,0,1.3-1,2.1-1.2
    		c0.8-0.2,1.8-0.2,2.2-1c0.7-1.3,0.2-2.7,2-1.8c-0.2-0.5-0.3-1-0.5-1.5c1.1,0.2,3.8,0.5,4.1,1.6c0.4,1.7,1.7-0.4,2.5,0.9
    		c0.4-0.5,0.4-1.4,1-1.7c0.9-0.4,1.7-0.4,2.6-0.9c1.1,3.6,4.3,2.1,7.1,2.4c1.6,0.2,1.8,1.7,3,2.2c1.1,0.5,2.1,0.2,2.9,1.2
    		c0.6,0.8,3.1,1.7,3,2.2c-0.1,0.7,0,1.7,0.6,2.2c1,0.8,1.2-1,0.9-1.6c-0.4-0.8-1.2-1.4-1.5-2.2c1.3-0.9,3.1,0.8,4,1.5
    		c1,0.7,2.5,0.9,3.3,1.6c1,0.9,2.9,0.5,3.3-1c0.3-1.1,1.9-1.3,1.8-2.5c0.6,0,1.1,0.3,1.5,0.6c0-1,3.1-1.2,3.3-0.6
    		c0.2,0.6,1.6,1.3,2.2,1.5c0.7,0.2,1-0.6,1.7-0.3c-0.1-3.3,0.8-0.4,2.6-1.7c1.5-1.1,2.7,1.2,3.2,2c0.8,1.2,2.6,1.5,3.9,1.6
    		c0.6,0,3.6,0.2,2.8,1.4c0.8-0.1,2.1-0.2,2.2-1.3c0.1-0.5-0.1-1.4,0.6-1.6c0.7-0.2,1.3,0.8,1.7,1.1c0.6,0.5,1.7,0.6,2.4,0.6
    		c1.1,0,5.1-0.4,5.1-1.9c0-0.9-1.1-2.9,0.4-3.1c-1.4-0.5-1.7-1.4-3.4-1.6c-1.3-0.1-1.8-1.1-2.9-1.4c-1.3-0.3-2.1,0.2-3-1.1
    		c-0.5-0.8-3.3-0.5-3.3-1.6c0,0,2.5-1.4,2.7-1.5c0.8-0.3,1.3,0,1.9-0.6c0.4-0.3,0.8-0.9,1.2-1.1c-0.4-0.3-3.2-3-3-3.2
    		c0.3-0.6,1.8-1.3,1-2.1c0.8-0.1,1.9-0.2,2.7-0.2c0.6,0,1.2,0.4,1.8,0.4c0.4,0,4.1-0.1,2.2-1.2c-1.8-1.1-3.8-1-5.7-1.5
    		c-2.8-0.8,1-1.4,1.3-1.8c-0.6-0.8-1.1-0.5-1.9-0.4c-0.8,0.1-1.8-0.4-2.5-0.8c2.4-0.2,0.5-1.8-0.2-2.4c0.5-0.1,0.9,0,1.4,0.1
    		c-0.4-1.6,3.3-0.2,3.9-0.1c0.9,0.2,1.7-0.6,2.6-0.6c0.9,0,1.2,0.9,2.1,1c-0.4-2.7,3.1-0.6,3.2-1.9c0.1-1.2,4.3-0.6,5.1-0.8
    		c0.1-0.2,0.1-0.5,0.2-0.7c0.4,0.1,0.9,0,1.3-0.1c1.1-0.1,1.7-0.4,2.8-0.2c0.7,0.1,1.3-0.6,1.9,0c0.8,0.8,0.8-0.4,0.8-0.9
    		c0-1.9,6.5-1.7,8.1-2c1.7-0.4,3.4-0.7,5.1-1c1.3-0.3,4-0.9,4.8-1.9c-1.6-0.3-0.3-0.8,0.1-1c0.7-0.4,1.9-0.2,1.5-1.4
    		c0.6,0.2,1.2,0.2,1.8,0.1c-0.2-0.2-0.5-0.5-0.7-0.7c0.9,0.1,2.1,1,2.7,0.4c0.9,0.1,1.9,0.2,2.8,0.6c0.9,0.4,3.3,2,4.2,1
    		c1.3-1.3,2.1-1.2,3.7,0.1c0.9,0.7,1,1.9,1.7,2.7c0.6,0.6,1.4,0.9,2.1,1.3c-0.9,1,1.6,3.2-0.5,3.3c1.3,1.6,2.4,0.6,3.6,1.2
    		c0.7,0.4,1.4-0.4,1.8-0.8c0.5-0.5,1.7,0.3,2.3,0.2c-0.6-0.3-0.8-0.8-0.7-1.4c1.1,0.5,1.8,1.8,3,1.9c-1,0.8,0.1,1.1,0.5,2
    		c0.4-0.4,0.9-0.7,1.5-0.7c-0.3-0.5-0.9-0.8-1.5-0.5c1.1-3,6,3.2,7.3-0.3c0.5,0.4,1,1,1,1.7c-1-0.1-2.7,0.5-2.3,1.8
    		c0.3,1,1.6,0.9,1.9,1.8c0.8-0.7,2.2-2.4,3.4-1.4c0.7,0.5,1.1-0.3,1.7,0.3c0.4,0.3,0.5,0.7,1.1,0.7c-0.1-2,1-2.2,2.5-2.7
    		c0.7-0.3,3-1.1,2-2.2c1.2-0.1,2.3-0.5,3.4-1c0.1-0.1,1.3-1.1,1.4-1.1c1.2,0.2,2.2-0.6,3.3-1c-0.2,0.3-0.4,0.5-0.6,0.8
    		c1,0.3,0.7,1.1,0.2,1.7c-0.3,0.4-1.2,0-1.6,0.3c3.3,2.5,7.3,3.9,10.9,6c3.3,2,6,4.6,8.8,7.3c2.9,2.7,6,5.3,8.9,8.1
    		c1.8,1.7,4.4,5.8,7,6.3c-0.9-1,0.8-0.5,1.4-1.7c0.4-0.9-0.5-1.2-0.2-2c0.1-0.3,1.6-0.6,1.7-0.6c0.8,0,1.2,0.9,1.9,0.8
    		c1.4-0.1,0.6,0.8,0.8,1.6c0.2,0.7,1.3,0.3,1.7,0.3c0.8,0,1.3,1.3,1.9,1.7c0.8,0.6,2.2-0.2,3.1-0.1c0.8,0.1,1.1,0.7,1.9,0.3
    		c0.4-0.2,0.8-0.2,1.2-0.2c1.2,0,1.4-1.1,2.2-1.2c1.1-0.2,0.7,0,1.5-0.8c0.3-0.3,0.8-0.2,1.1-0.1c3.2,0.5,6.4,2.6,8.2,5.2
    		c1.3,2,4.8,1,6.2,2.6c1,1.1,1.7,2.5,2.8,3.4c1.1,0.9,3.9,0.6,5.3,0.8c0.7,0.1,1.9,1.2,2.3-0.2c0.2-0.5,0.8-1,1.1-1.3
    		c0.7-0.6,1.4-0.4,1.1,0.6c-0.4,1.3,1,1,1.7,1.5c0.9,0.7,1.5,2,2.7,2.1c1.2,0.1,1.1,1,2,1.3c1.3,0.5,4.3-0.6,4.4-2
    		c0.1-1.1,1-1.6,2.1-1.4c0.9,0.1,2.2,1.1,2-0.3c0.7,0.3,1.6,0.6,2.3,0.1c-0.1-0.2-0.4-0.5-0.5-0.7c1.2-0.2,2-0.5,3.1-0.9
    		c-0.6-0.3-0.9-1-1.1-1.6c0.9-0.6,2.2,0,2-1.3c0-0.2,1.8-0.9,2-1c0.9-0.3,1.5-0.1,2.1-0.8c0.6-0.6,1.1-1.1,1.9-1.4
    		c1.3-0.4,1.6-0.1,2.3-1.3c0.4-0.6,0.9-0.7,1.5-0.7c0.8-0.1,2.9,0,2.2-1.4c1.5,0,1.9,2.1,3.5,1c1.4-1,0.9,0.6,1.9,1
    		c2.4,0.9,5.4-0.5,7.9,0.5c1.3,0.5,2,1.7,2.9,2.6c1.2,1.2,2.9,1.8,4.5,2c0.6,0.1,3.9,1.1,4.3,0.8c1-0.7,1.8-0.9,2.9-0.8
    		c1.8,0.2,3.5,0.8,5.3,1c1.3,0.1,2.9,1.3,4.2,1c1.1-0.2,1.3-1.5,2.5-1.6c1.3-0.2,1.6-1.9,1.4-2.9c-0.2-1.6-0.8-1.8-2.1-2.3
    		c-1.3-0.5-1.4-2.1-2.5-2.6c-1.2-0.6-2.8-5.5-0.6-4.7c-0.6-1.3-0.4-2.2,1-2.6c1.1-0.3,0.7-2.2,0.5-2.9c2.7,0.8,5.2,2,8,2.8
    		c2.8,0.9,5.7,0.8,8.6,1.9c2.2,0.9,4.5,0.9,6.8,1.3c2,0.4,2.8,3,3.9,4.4c0.6,0.7,1.4,1.2,1.9,2c0.5,0.8,1.5,0.8,2.2,1.4
    		c0.7,0.6,2.2,1,3.1,1.1c1.2,0.1,1.6,0.6,2.7,1c3.9,1.4,7.6-1,11.3-1.8c1.9-0.4,4.5-0.2,6.3,0.4c1.5,0.5,2.5,0,4,0.2
    		c1.4,0.2,2.5,1.3,3.6,2c1.4,1,2.8,1.1,4.4,1.1c1.9,0,2.3,0.5,3.4,1.9c0.9,1.1,2,1.4,3.1,2.1c3,2,5.8,0.7,9,1.8
    		c2.1,0.7,4,0.6,6.2,0.6c1.2,0,2.6,0.7,3.7,0.1c0.7-0.3,1.1-0.9,1.8-1.2c0.7-0.4,1.6-0.1,2.4-0.5c1.1-0.5,3-0.5,4.2-0.8
    		c1.3-0.3,3.1,0.5,3.7-1.1c0.5-1.2,0.6-2,1.6-2.8c2.7-2.2,5.5-3.5,8.9-1.7c1.6,0.9,2.9,2,4.7,2.3c1.6,0.3,2.5-0.7,4-0.9
    		c1.9-0.2,3.1,0.9,4.6,1.8c1.4,0.8,3.1,0.9,4.6,1.2c1.2,0.2,3.6,1.6,4.6,0.5c0.8-0.9,1.3-2.4,2.5-2.8c0.8-0.3,4.4-0.6,3.6-2.2
    		c-0.4-0.8-2.2-1-2.3-1.6c-0.2-1.1-0.1-1.8,0.1-2.8c0.2-0.7-0.6-1.2-0.3-1.9c0.3-0.6,0.3-1.4,0.2-2.1c-0.1-1.2-0.8-2.8-0.3-4
    		c0.6-1.6,2.2-1.6,1.5-3.5c-0.4-1.1-1.9-1.9-2.5-3.1c-0.5-1-2.1-1.2-3.1-1.1c-1.4,0.1-1.9-0.1-2.8-1.2c2.4-0.8,1.4-4.1,4.2-4.3
    		c2.8-0.2,5.3-1.8,8.1-1.5c3.2,0.3,6.5-1.2,9.5,0.4c0.7,0.4,1.5,0.3,2.2,0.6c0.6,0.3,1.2,0.8,1.8,1.1c1.2,0.6,2.7,0.4,3.9,1
    		c0-1.3,1.4-0.8,2.1-0.5c0.8,0.3,3,0.7,3.4,1.3c0.6,1,2.2,1.3,3.2,1.8c0.9,0.5,0.9,0.4,1.2,1.2c0.3,0.7,1.4,0.7,1.9,1.1
    		c1.2,0.9,2.4,1.6,3.6,2.5c1.1,0.9,1.4,2.2,2.6,3c0.9,0.6,2.1,2.1,3,2.4c0.7,0.3,2.1,1.5,2.5,2.1c1.8,2.5,5.4,3.5,7,6.1
    		c0.9,1.5,1.6,1.8,3,2.6c0.8,0.5,1.1,2.1,1.8,2.8c1.1,1.1,2.4,2.1,4,2.5c1.7,0.4,2.6,0.2,4.2,0.1c1.2-0.1,1.9,0.1,2.9,0.8
    		c1.2,0.8,2.6,0.7,3.9,1.2c-1.1-1.9,1.6,0.5,1.7,0.5c1,0.3,1.6,0.3,2.4,1.1c1.5,1.4,3.4,2.4,5.4,2.2c2.9-0.2,0.9,1.6,2.7,2.6
    		c1.6,0.9,3.1,1.7,3,3.6c0,1.1,4.4,4,5.4,3.8c0.6-0.1,1.8-0.7,2.4-0.2c0.7,0.5,1,0.5,1.9,0.4c1.2-0.1,4.9,0.2,4.9-1.3
    		c0-2.1,2.7-2.8,4.3-2.1c-0.2-0.1,1.7-1.6,1.9-1.7c1.3-0.8,2.9-1.1,4.2-0.4c1,0.5,1.2,0.2,1.3,1.3c0.1,0.7,0.4,1.3,0.8,1.8
    		c0.8,1,2,1.3,2.3,2.6c0.4,1.8-0.8,1.1-1.4,2.4c-0.4,0.8,0.4,1.3,0.7,1.9c0.2,0.6,0.1,1.2,0.3,1.8c0.2,0.9,0.8,1.5,1,2.3
    		c0.1,0.5-0.1,1.2,0.2,1.6c0.5,0.7,1.9,1.8,0.3,2.6c1.5,1.1-0.3,1.7,0.3,2.9c0.3,0.7,1.3,1.6,0.2,2.1c-1.4,0.7,0,2.3,0.4,3.1
    		c1.1,2.1-4.2,0.5-4.8,0.3c-1-0.3-3.2-1.5-4.2-1.3c-0.5,0.1-0.9,1.8-1.1,2.2c-0.6,1-1.5,0.8-2.4,1.1c-0.7,0.2,1.6,2.2,1.8,2.4
    		c2,2.3,3.6,4.6,4.9,7.4c1.1,2.3,3,3.3,3,6c0,0.5,0.4,1.6-0.4,1.6c-1.2,0.1-2.4,0-3.1,1.3c1.8,0.5,2.9,2.2,4,3.6
    		c0.2-1.6-0.3-2.3-1.7-3c0.8-0.8,2.9,0,3.7,0.4c0.2-0.8,0.4-2.6-0.3-2.9c1.9,0.3-0.2-3.4,1.7-3.2c1.4,0.1,0.1,2.1,1.5,2.1
    		c0.6,0,1.3-1.6,1.2-2.1c0.3,0.4,2,3.6,2.3,3.4c0.7-0.4,1.9,0.5,2.8,0.5c0.2,0,2.6,0.6,2.7,0.8c0.7,1,1.4,0.4,2.3,0
    		c2.8-1.4,5-2.8,7.2-5.1c0.8-0.8,2.9-4.2,1.4-4.9c0.9-0.6,0.4-2.1,0.6-2.9c0.2-1.1,0.1-1,1-1.7c0.7-0.6-0.7-2.3,0.5-2.4
    		c0.4,0,0.4-1.7,0.5-2c0.3-1,0.1-1.7,0.7-2.6c0.5-0.7,0.7-1.4,1.1-2.1c0.9-1.8,1.8-4.7,1.2-6.7c-0.2-0.9,0.1-1.1,0.1-1.8
    		c-0.2-0.4-0.3-0.8-0.5-1.2c-0.3-1.1-0.7-2.8-0.1-3.8c1.3-2.2-0.6-4.6,0.5-6.9c0.9-1.9,2.6-3.6,1.2-5.6c-0.6-0.8-0.8-3.6-2-3.1
    		c0.7-0.3-0.2-2-0.9-2.2c0.3-0.9,0-2-0.5-2.7c-1-1.6-3-2-1.9-4.1c-1.6,0.5-4.1-3.5-4.8-4.5c-0.7-1-0.4-2.5-1.1-3.4
    		c-0.5-0.7-2.2-2-0.9-2.8c-0.8-0.2-1.4-1.7-0.7-2.2c0.8-0.6-0.3-1.8,0.7-2.4c-1.8-0.5-0.4-2.2-2.1-2.5c-1-0.2-3-0.7-3.1-1.9
    		c-0.2-3.3-5-3.7-7.3-5.3c1.3-0.2,2.6,0.7,3.7,1.1c-0.2-1.9,0.5,0.2,0.7-0.7c0.5-1.8-3.9-2.4-4.6-2.7c-1.3-0.6-2.4-1.5-3.8-2
    		c-1.2-0.4-2.4-0.8-3.4-1.5c-1.8-1.2-4.4-4-6.9-3.7c-1.4,0.1-2.1,0.9-3.5,0.5c-1-0.3-2.3-0.9-3.4-0.6c1.1,0.2,1,1.1,1.6,1.8
    		c0.7,0.7,0.9,0.7,1.4,1.6c0.9,1.7,1.7,2.7-0.5,3.1c0.2-0.8,0.5-3.5-0.7-3.7c-1.2-0.2-0.1,1.9-1.2,2.6c-1,0.7-3.5,1.4-4.6,0.8
    		c-0.3-0.2,0.8-3,1.2-3.3c-1.4-0.4-3.5-0.2-4.5-1.4c1,0.1,1.3-1,1.4-1.7c-0.9,0.3-3.3-0.1-2.6,1.6c0.3,0.8,1.1,0.3,1.6,0.8
    		c0.4,0.4,0.4,1,0.5,1.5c-1.3,0.2-3.1,1.2-3.6-0.7c-0.3-0.9-0.6-1.6-1.2-2.4c-0.2-0.3-1.3-1-1.3-1.4c-0.2-2.2-3.3-1.9-4.7-1.8
    		c-2.1,0.2-3.9-0.1-5.9-0.7c-1.4-0.4-4.2-1.6-1.9-3.4c0.7-0.6,1.6-0.9,2.1-1.7c0.3-0.6-0.1-1.5,0.4-2c0.4-0.5,1.2-0.4,1.5-1.1
    		c0.3-0.7,1-1.3,1.5-1.8c1-0.9,0.4-3.9,2.1-4c-0.8-0.3-1.3-0.7-1.1-1.6c0.1-0.7-0.2-2.2,0.3-2.6c0.7-0.7,0.7-1.8,1.4-2.4
    		c0.7-0.6,1.3-0.9,1.6-1.9c0.3-0.8-0.2-1.9,1-2c1.2-0.1,1.1-0.7,0.6-1.5c-0.5-0.8-1.4-2.1-1.1-3.1c0.3-1.1,1.9-1.4,2.5-2.3
    		c0.7-1.1-0.2-2.3,0.2-3.4c0.5-1.4,2-1.8,3.3-2.2c1.2-0.4,1.9-0.1,3.1-0.2c1.3-0.1,2.3-0.6,3.6-0.4c3.1,0.4,6.2-0.4,9.3-0.1
    		c1.2,0.1,1.4,0.9,2.3,1.5c1,0.7,2-0.1,3,0.3c-2.7-3,0.5-1.2,2.3-1.4c1.1-0.1,2.5,0.1,3.5,0.6c0.9,0.4,1.6-0.3,2.4,0.2
    		c-0.2-0.3-0.4-0.5-0.5-0.8c1.7-0.6,3.2-0.5,4.6,0.6c0.8,0.6,2.1-0.2,3,0.3c-0.5-1.3-3-0.6-2.8-2.2c0.7,0.4,1.6,0.1,2.3,0.2
    		c-0.9-0.4-2.3-0.8-1-1.7c1.5-1.1,4.2,0,5.8,0.4c0.5,0.1,6.8,2.5,5.9,0.7c2.2-0.4,4.3,0.6,6.1,1.7c1.4,0.8,3.2,0.2,4.4,1.2
    		c-0.9,0.7-2.1,0.4-3.1,0.4c-1.1,0-2,0.4-3,0.5c2.4,1.6,4.9,1.8,7.7,1.5c-0.2-0.2-0.3-0.5-0.5-0.7c1.2-0.5,4.4,0.1,5.5,0.7
    		c-1.1-0.7-0.4-1.3,0.3-1.9c0.9-0.7,0.5-0.7,2-0.4c0.6,0.1,5.7,1.8,4.2,0c1.8-0.4,2.9,1,4.7-0.4c-1.4-0.4-5.1-3.8-6.8-1.9
    		c0.2,0.2,0.5,0.4,0.8,0.5c-0.7-0.3-1.5-0.1-2.2-0.3c-0.8-0.2-1.4-1.9-0.1-1.2c-0.6-0.3-3.9-2.7-1.7-2.3c-0.7-0.8-1.1-1.8-1-2.8
    		c0.1-1.3,0.8-1.3,1.5-2.1c0.4-0.5,1-1.4,0.8-1.9c-0.3-0.8-1.5-2.7-0.2-3.4c1.1-0.6-0.7-1.7-1.1-2.2c1.2-0.3,0.7-2.2,2.2-1.7
    		c0-0.3,0-0.5-0.1-0.8c1.2,0.1,2.4,0.2,3.6,0.5c0.7,0.1,1.8-0.5,2.6-0.5c1.1,0,2-0.7,3-0.8c1.1-0.1,2,0.7,2.9,1
    		c1,0.3,1.4,1.6,2.3,0.1c0.5-0.9,1.2-1.7,2.2-0.8c0.7,0.5,1.3,1.5,1.7,2.3c0.4,0.7-0.6,1,0.5,1.4c-0.9,0,1.8,1.3-0.4,0.7
    		c1,0.6,2,1.2,2.6,2.2c0.9,1.4,1.4-0.6,2.7-0.1c1.1,0.4,0.6,1.8,1.3,2c0.7,0.3,0.7,1.1,1.4,1.5c0.4-1.1,1.5-1.6,2.6-1.1
    		c-1.4-1.4,0.7-3.4,0.7-4.9c0-1.9,1.4-2.4,2.9-2c-1.8-0.5,0.2-0.8,0.8-0.5c0.7,0.4,1.3,0.9,2.1,1.2c-0.2-0.2-0.3-0.5-0.4-0.7
    		c0.2-0.1,0.5-0.2,0.7-0.3c-1.2-1.2-2.9-0.8-4-2.3c-0.6-0.7-3.2-2.3-2-3.2c-0.9-0.1-1.8-0.4-2.1-1.3c2.3-0.7,5.1-1.9,7.5-0.8
    		c2.4,1.1,5,1,7.6,1.4c-1,0.2-2-0.1-3-0.1c0.7,0.5,1.5,0.7,2.2,1.1c-1.2-0.5-4.2-1.9-5.4-1c-2.1,1.5,0.7,3.4,1.9,4.3
    		c1,0.8,1.1,1.4,1.5,2.5c0.6,1.4,1.9,0.8,2.8,1.6c1.2,1,0.1,1,0.3,2.1c0.2,1,1.4,1.2,2.2,1.4c-0.3,0.1-1.1,1.2-1.4,1.1
    		c-0.6-0.3-1.5-0.4-2.1,0c-0.4,0.2-0.3,0.9-0.6,1.2c-0.8,0.6-1.7,1.1-2.5,1.6c0.4,0.7,1.3,0.9,1.6,1.7c0.2,0.6-0.8,1.1-0.9,1.6
    		c-0.2,1,0.3,1.9-0.3,2.8c-0.6,0.9-1.4,0-1,1.4c0.5,1.6-1.5,3,0.1,4.4c1.2,1.1-0.3,2.6,0.9,3.5c-1.1-0.7-0.6,0-1,0.7
    		c-0.2,0.4-0.5,0.9-0.8,1.3c-0.4,0.7-0.9,1.5,0.2,1.8c-1.2-0.6-2.6,0.4-3.8-0.2c-0.3,0.5,0.4,1.8-0.2,2c-1,0.4-2.3-0.6-3.4-0.3
    		c0.7,1.3,2.4,1.8,3.4,2.7c0.9,0.8,0.9,2.2,1.6,3.1c-1-0.4-1.3,2-1.2,2.9c-0.4-1.7-0.4,0.2-0.2,0.6c0.3,0.8,0.8,1.4,1.3,2
    		c1,1.4,2,2.8,3,4.1c2.8,3.5,5.5,6.6,8.9,9.5c4,3.4,8,6.9,11.9,10.4c2.5,2.2,6.2,7.7,9.9,7.8c-1.5,0.1,0,0.9,0.6,1.4
    		c0.6,0.6,1.3,1.1,2,1.7c1.4,1.2,2.7,2.4,3.8,3.8c1,1.2,2,2.6,3.4,3.4c1.2,0.7,2.7,1.2,3.2,2.7c0.9-1,1.1-2.2,1.8-3.2
    		c0.5-0.7-0.4-1,0.3-1.7c1.6-1.8,0.7-4.5-0.2-6.4c-0.4-0.9-1.4-1.3-2-1.9c-0.5-0.5-0.3-1-0.7-1.4c-0.4-0.4-0.9-1.7-1.5-1.8
    		c-0.6-0.1-1.2,0-1.3-0.8c0.9-0.4,1.6,0.4,2.2,0.9c0.8-0.9,2.1-2.6,3.5-2.6c0.6,0,1.1-0.5,1.5,0.2c0.3,0.6,1.4,0.8,2,0.9
    		c-0.9-1.6-2.8-2.2-4.2-3.2c1.7,0.8-1.8-2-2.1-2.4c-0.8-1.1-2.1-2.5-1.1-3.8c0.9-1.2,1-2.8,2.9-2.7c0.9,0.1,1.7,0.9,2.6,0.8
    		c0.7-0.1,1.4-0.3,2.1-0.1c-0.2-0.7,0.6-1.7,0.1-2.4c-0.7-0.9-2-1.4-2.9-1.9c-1.8-1-3.6-2-5-3.5c-1.3-1.3-1.7-2.9-2.5-4.4
    		c-0.6-1.2,2.1-2.4-0.5-2.9c0.3-0.9,1.3-1.1,2-0.6c-0.2-0.7,0.7-0.9,0.8-0.3c0.1,0.8-0.9,0.8-0.3,1.8c0.7,1,3.4,2.6,4.2,1.2
    		c0.9-1.4-4-4.8-5.3-5c-1.6-0.3-2.1,0-3.4-1.2c-0.9-0.8-1.7-1.7-2.6-2.4c-0.5-0.4-1.5-1-1.3-1.7c0.2-1.1,0.8-1.6-0.2-2.6
    		c-1.1-1.1-3.2-1.2-4.6-1.7c-2.4-0.7-1.2,0.6-0.8,1.6c-0.6-0.8-2.4-0.9-3.2-1.2c-1.6-0.7-2.5-1.5-3.4-3c-0.2-0.4-2.5-6.6-0.2-5.6
    		c-0.4-0.5-1.1-0.8-1.7-1c0.5-0.2,1.1-0.2,1.6,0c-0.2-0.2-3.3-2-1.1-1.6c-0.3-0.5-3.1-1.7-2.7-2.2c0.8-1-1.4-1.2-1.3-2.5
    		c0.6,0.2,1-0.2,1-0.8c-0.4,0-0.8,0-1.1-0.1c1.5-1.1,3.2,0.9,4.4,0c-1.6-0.6,0.8-0.8,1.6-0.2c0.8,0.5,1.4,1.2,2.2,1.6
    		c1,0.4,2-0.4,1.3-1.4c-0.4-0.6-1.7-0.7-2.3-0.9c0.9-0.1,1.4,0.1,2.1-0.5c0.9-0.6,0.8-1.9,1.5-2.3c0.9-0.6,2.3,1.2,2.8,1.7
    		c0.7,0.6,2.4,4.6,3.7,3.2c0.9-0.9,0.2-3.1,0-4.1c0.5,0.1,1,0.1,1.5,0c-0.2-0.2-0.4-0.5-0.6-0.7c1.6,0.9,3.5-0.8,5.1-1
    		c1.7-0.2,4.1-0.2,5.8,0.1c4.1,0.8,8,3.5,11.9,5.2c0.8-1.1-2-3.8-2.8-4.1c1,0.2,0.8,0,0.3-0.6c1,0.2,3.8-0.6,2-1.7
    		c0.8,0.3,1.5-1.1,1.5-1.4c0.9,0.8,1.3-0.3,0.6-0.7c-0.4-0.3-1-0.4-1.4-0.6c0.8,0.1,1.5,0.5,2.3,0.5c-0.4-0.5-0.9-0.8-1.5-0.8
    		c1,0,1-0.3,0-0.6c0.3,0,1.3,0.3,1.5,0c0.5-0.5-0.3-1-0.7-1c0.5,0.1,1,0.2,1.5,0.3c-0.6-0.7-1.6-0.9-2.4-1.1c1-0.6,2.1,0.7,3-0.1
    		c-0.5-0.5-0.8-1,0.3-0.9c-0.5-0.4-1.1-0.8-1.4-1.3c0.7-0.1,1.3,0.3,1.9,0.3c0.8,0,1.4-0.7,0.2-1c1.2-0.2,2.4,0.4,3.5-0.3
    		c-0.4-0.5-0.9-0.8-1.5-0.9c1.1,0.1,1.9,0.1,2.9-0.4c-0.2-0.3-0.3-0.5-0.5-0.8c1,0.3,2.9-0.7,3.6-1.3c1.5-1.2,1.7-2,3.8-2.1
    		c-1.1-0.2-2.2-0.2-3-1c-0.5-0.5-0.9-1-1.5-1.3c1.1,0.3,2,1.2,3.1,1.2c-1.5-1.7,1.4,0.4,1.7,0.6c1.1,0.8,3,0.4,4.2,0.6
    		c2.9,0.4,6,0.6,8.7,1.8c-0.1-0.6-0.6-1-1.1-1.1c0.9-0.1,0.9-1.6,0.3-2.1c-1.2-1-3.1-1-4.4-1.8c-1.1-0.7-1.2-1.7-2.7-2.1
    		c-1.3-0.3-2.6-0.7-3.9-0.9c0.3,0,0.6-0.1,0.8,0c-1.2-0.6-2.4-1.2-3.5-1.9c0.5,0.4,1.1,0.7,1.6,1c-1.2,0.6-2.3-1.4-3.7-0.5
    		c0-1.1,1.2-0.7,1.8-0.4c-1.1-0.4-1.1-0.5,0-0.3c-0.5-0.4-1-0.8-1.5-1.1c-0.6-0.4-1.4-0.9-2.1-1.1c-0.6-0.2-1.2-0.3-1.7-0.5
    		c-0.4-0.1-1.6-1.1-0.1-0.4c-1.5-1-3.2-1.6-4.7-2.4c-0.4,0.5-0.1,0.9,0.5,1c-2.3,0.2-6.6-0.7-8-2.7c-0.8-1.2-2.6-2.3-3.6-0.6
    		c-0.4,0.6-1.4,0-1.9-0.1c-0.8-0.2-1.4,0.4-2.1,0.5c0.2-0.2,0.5-0.5,0.7-0.7c-1.5-0.7-3.1-1.4-4.7-1.7c1.4-0.2,2.8,1.1,4.1,0.5
    		c1.4-0.6,2.6,1,4,0.5c-0.6-1.2-2.5-1.8-3.7-1.9c-0.7-0.1-1.4,0.2-2.1,0.3c-0.6,0.1-1.1-0.5-1.7-0.4c2.7-0.9,6.7,0.3,9.1,1.7
    		c2.6,1.5,5.8,1.2,8.7,1c-0.2,0.2-0.3,0.5-0.5,0.7c0.5,0.1,2.6-1.2,2.9-1.6c0.4-0.4,0.2-0.9,0.4-1.4c0.2-0.6,0.9-0.5,1.3-0.8
    		c0.7-0.6-0.7-2.1-1.2-2.2c-0.5-0.1-2.5-0.1-2,0.4c-2.1-1,2,0,1.7-1.3c-0.2-1.2-4.3-1.7-5.2-2c-1.4-0.6-3.4-1.5-3.8-3.1
    		c0.6,0.8,1.7,0.6,2.3,0c-0.8-0.3-1.6-0.7-2.3-1c0.5,0.1,1,0.1,1.5,0.1c-0.3-0.2-0.4-0.4-0.7-0.6c1.3,0.5,2.3,1.8,3.8,1.6
    		c0-0.5-0.2-1-0.5-1.4c1.4-0.1,3.7,2.9,2.1,2.7c1.5,0.6,2.9,1.5,4.5,1.8c1.4,0.3,2.1,1.7,3.4,2.1c1.4,0.4,3.8,0.5,5.2,0.1
    		c0.7-0.2,1.2-1,2-1c0.8,0,1.6,0.2,2.4,0.3c3.5,0.3,7.3,1.8,10.3,3.6c1.4,0.9,2.9,1.3,4.4,1.8c0.6,0.2,4.4,1.2,2.4,0
    		c2.9,1.6,6.6,1.6,9.7,2.8c2.2,0.9,6,1.3,2.2-1c2.1,0.4,3.6,3.6,6.1,2.5c-0.3-0.8-1-1.4-1.9-1.4c1.2-0.7,2.5,1,3.8,0.4
    		c-1.1-0.9-4.8-2.4-6.2-1.8c1-1.1-1.2-1.7-1.9-1c0-1,1.2-0.7,2.2-0.9c0.6-0.1,0.5-0.5,0.2-0.9c-1.1-1.5-2.7-1.3-4.3-1.5
    		c0.7-0.4,1.6-0.4,2.3-0.1c-0.8-0.4-1.4-1-2-1.6c-0.3-0.4-1.4,0.2-1.9-0.2c-1.4-1-2.9-1.2-4.5-1.6c1.6-0.3,3.1,0.2,4.6,0.7
    		c0.8,0.3,4.1,0.3,2.2,0.8c1,0.1,2-0.4,3-0.4c0.8,0,1.6,0.5,2.4,0.5c-1-1.9-4.3-1.7-6.1-2.6c2.4,0.4,4.6,1.6,7.1,1.6
    		c-0.1-1.3-3.3-2.2-0.9-3.3c-1.1-0.7-2.2-0.7-3.4-1c1.9,0.2,0-0.6-0.4-0.7c-0.6-0.2-1.5-0.2-2,0.3c0.3-1.6-7-2.8-8.1-3
    		c-1.6-0.4-3-1.2-4.5-1.8c-2.1-0.9-4.7-0.8-7.1-0.9c-0.2-0.1-2.2-0.3-2.5-0.3c1.3,0.5,2.7,0.3,4,0.7c-1.3,0.2-2.9,0.5-4.1-0.3
    		c0.2,0.3,0.4,0.5,0.6,0.8c-0.8,0.2-3.3-1.3-1.5-1.3c-0.8-0.2-1.6,0.1-2.3,0c-0.6-0.1-0.9-0.4-1.5-0.4c-1.4-0.1-3.5,0-4.9,0.2
    		c0.5,0.1,9.2,2.1,7.3,3.6c1.1,0.7,2.6,0.4,3.8,1c0.9,0.4,1.3,1.1,1.8,1.9c-0.5-0.1-4.3-2.9-3.6-1.2c-1.6,0.1-2.9-1.5-4.5-1.7
    		c-0.7-0.1-3.6-1.5-1.7-1c-1.7-1.2-3.8-1.8-5.4-3c-1.4-1-2.7-2.2-4.4-2.4c0.2,0.2,0.5,0.3,0.7,0.5c-0.7,0.6-4.9-2.3-5.6-2.5
    		c-2.5-0.8-5.3-1.1-7.9-1.7c-1.3-0.3-2.5-0.7-3.8-1c-0.9-0.2-1.9,0-2.7-0.3c-0.9-0.3-1.8-0.9-2.8-0.6c-0.4,0.1-2.2-0.7-0.5-0.4
    		c-0.5-0.1-1.1-0.2-1.6-0.4c-1.1,0.8-3.2-0.6-4.2-0.9c-1.8-0.6-3.6-0.5-5.2-1.4c0.7,0.1,1.4,0.3,2.1,0.5c-4.5-1.7-9.2-2.4-13.9-3.3
    		c-1.7-0.7-3.4-0.9-5.1-1.5c-1.6-0.6-3.8,0.4-5.1-1c0.8,0,1.6,0.4,2.4,0.5c-2.9-1.6-6.4-0.8-9.6-1.5c-3.3-0.8-6.7-1.1-10-1.9
    		c-3.4-0.8-6.1-0.4-9.5-0.5c-1.6,0-3.2,0.1-4.7,0s-2.9-0.8-4.5-0.6c0.8,0.2,1.4,0.5,2.2,0.7c-0.7,0.9-2.9-0.2-3.7-0.5
    		c-1.3-0.5-2.5-0.6-3.8-0.6c-4-0.1-8.1-1.3-12.1-1c1.6,0.4,3,1.6,4.3,2.5c-1.1,0.1-1.2,1.3,0,1.4c1.2,0.1,2.2-0.1,3.3,0.5
    		c2.3,1.1,4.7,1.9,6.8,3.4c-0.2,0.3-0.5,0.5-0.7,0.8c0.5,0.3,1,0.6,1.4,1c-0.5-0.3-0.9-0.6-1.4-0.8c0.3,0.6,0.8,1,1.4,1.1
    		c-2.1-0.7-4.3,0.3-6.4-0.2c-1.5-0.4-2.5-1.6-3.9-2.1c-3.1-1.1-6.8-0.2-9.6-2.3c-0.9-0.7-6.1-3.9-6.9-2.1c-0.9,2.2-4.6,1.5-6.4,1.2
    		c-3.8-0.7-7.7,0-11.5-1.2c-1.9-0.6-4.1,0.1-6.1,0.1c-2,0-3.9,0.1-5.8,0.1c-2,0-3.6,1.2-5.6,0.5c-1.6-0.5-3.3-0.8-5-1
    		c-1.6-0.2-3-0.3-4.4-1.1c-0.4-0.2-0.4-1.1-0.7-1.4c-0.2-0.2-0.5-0.3-0.8-0.3c0.3,0,0.5,0,0.8,0c-2-1.2-3.3-2.7-5.6-3.4
    		c-2.1-0.7-4.2-1.5-6.4-1.9c-4.8-0.9-9.8-1.1-14.7-1.6c-4.8-0.5-9.5,0.4-14.1,1.4c-4.2,0.9-8.9,0.5-13-0.7c0.8,0.1,1.6,0.2,2.3,0
    		c-1.6-1.5-4.3-2.1-6.5-2.4c-1.4-0.2-3-0.7-4.3,0c-0.7,0.4-1.8,0.7-2.6,0.7c1.5-1,1.7-1.7-0.3-1.9c-1.8-0.2-2.3,0.6-3.6-0.6
    		c-1.8-1.6-6.2,1.1-7.5-1.3c1,0,1.5,0.5,1.5-0.7c1,0,2,0.7,3,0.6c1.7-0.1-0.9-1.6-1.3-1.7c-3.4-1-6.7-1.6-10.3-1.9
    		c-1.9-0.2-4-0.3-5.9-0.2c-1.9,0.1-1.7,1.2-1.8,2.7c-0.2,2-5.4,2.1-6.6,1.2c1.7-0.2-0.9-0.9-1.4-1.3c1.6,0.3,3.1,0.1,4.6,0.3
    		c-0.4-0.8-1.3-0.7-2-1c-0.6-0.3-0.5-1.1-1.3-1.2c1-0.2,2.5,0.5,3.1,1.2c0-0.3,0-0.5,0.1-0.8c1.2,0,1,1.8,2,2.2
    		c1.2-2.7-0.5-3.2-2.5-3.2c-0.7,0-1.4,0.1-2.1,0.1c-1.7,0.1-4,0.1-5.6,0.6c-1.1,0.4-2.2,0.3-3.2-0.4c0.6-0.1,1.2,0,1.7,0.3
    		c-0.4-2.3,3.2-1.2,4.5-1c1.5,0.3,3.1,0.4,4.7,0.4c0.8,0,1.6-0.1,2.3-0.1c-5.7-2.4-13.1-1.8-19.1-2.3c-3.3-0.3-6.7-0.4-10-0.4
    		c-2.9,0-6-0.9-8.9-1.2c0.7,0.6,1.7,0.7,2.6,1c0.9,0.3,1.7,1.3,2.7,1.2c-1.4,0-2.6,0.7-4,0.7c-1,0-2.1-0.3-3.1,0
    		c-1.6,0.5,0,1.6,1.1,2.1c0.1-0.2,1.8-0.1,2.3-0.2c0.9-0.2,1.8-0.2,2.6,0.1c-0.6,0.3-1.1,0.4-1.7,0.1c-0.2,0.6,0.2,1.2,0.7,1.5
    		c-0.5,0.1-1,0.1-1.5,0.1c2.6,0.6,5.3,1.9,7.7,3.3c-1.9-0.6-3.5,0.7-5.4-0.2c-0.6-0.3-3.3-1.7-3.3-0.2c-0.6-0.8-1.6-0.4-2.4-0.4
    		c1.1,2.1,1.6,1.4,3.7,1.9c-0.6-0.1-1.1,0-1.6,0.2c0.3,0.2,0.6,0.3,0.9,0.5c-0.5-0.1-1.1-0.1-1.6,0c0.8,0.3,1.6,0.1,2.3,0.6
    		c-4.4-0.7-8.6-2.4-13.1-3.2c-1.1-0.2-2.3-0.4-3.4-0.3c-1,0.1-2.1,1.1-3,1c-1.2-0.1-0.4,1-0.1,1.5c-0.9-1.6-4.2-0.9-5.6-1
    		c-2.7-0.2-4.8-1.3-7.4-2.1c-0.9-0.3-4.2-2.6-3.6-0.1c0.3,1.3,1.8,3.5,3.2,3.6c-1.6,0.4,0.9,4.6-2.2,3.3c-2.1-0.8-4.3-1-6.3-1.9
    		c-2.5-1.1-5.4-1.2-7.8-2.7c-1-0.6-1.9-1.2-3.1-1.3c-0.6,0-1.5,0-1.6-0.8c0.8,0,1.7-0.6,2.5-0.2c-1.4-0.9-2.9-1.6-4.5-1.9
    		c0.8,0.5,1.7,0.9,2.5,1.3c-1.3,1.6-7.8-1.6-8.8-2.9c2.4,0.3,5.1,2.2,7.4,0.7c-1.4,0.1-0.6-0.4-0.1-0.7c-1.5-0.3-2.7-1.5-4.2-1.7
    		c-1.6-0.3-3.3,0.3-4.7-0.6c1,0,2,0.5,2.8-0.2c-0.3-0.1-0.6-0.3-0.9-0.5c0.5,0.1,1.2,0.2,1.5-0.3c-1.1-0.1-2.1-0.3-3.1-0.4
    		c0.7-0.2,1.4,0,2-0.4c-1.6-0.3-3.4-0.2-4.9-0.7c0.8-0.1,1.8-0.3,2.1-1.1c-0.7-0.5-1.2,0.4-1.7,0.1c-0.3-0.2-0.1-0.7-0.4-0.8
    		c-1.5-0.6-3.1-0.2-4.5-0.9c-1.2-0.6-2.4-1-3.2-1c-1.5,0-3.9-0.9-5.3-0.3c0.3,0.2,0.5,0.3,0.8,0.5c-0.9,0-1.8,0.4-2.7,0.3
    		c-0.6-0.1-2.5-0.3-0.7-0.8c-1.4-0.6-2.4,0.6-3.7,0.5c-0.4,0-0.5-0.7-1-0.7c-0.5,0-1-0.1-1.5-0.3c-1.6-0.7-3.2-0.7-4.9-1
    		c-1.3-0.2-2.7,0.2-3.9-0.3c0.3,0.2,0.6,0.4,0.9,0.7c-0.7,0.7-1.6,0.1-2.2-0.3c-0.3,1.8,2.7,3.2,4.1,3.4c-1.6,0.4,1.5,1.4-0.7,1.8
    		c-1.2,0.2-2.5-0.3-3.6-0.2c-2.7,0.2-5.6,0.3-8.3,0.2c-3.2-0.2-6.4-0.6-9.7-0.8c-2.2-0.1-5.1-0.5-6.8-2.2c0.6-0.7,1.4-0.3,2.2-0.4
    		c-1.9-2.4-8.4-0.8-11.1-1.1c-5.9-0.6-11.8-0.6-17.6,0.7c0.9,0.7,2.4,1.3,3.6,1c-1.6,0.5-1.9,0.2-3.4-0.2c-1.6-0.4-0.6-1-1.3-1.6
    		c-1.3-1.2-3.7-1.8-5.3-2.4c0.5,0.5,1,0.9,1.6,1.2c-1.1,1.3-3.9,0.9-5.3,0.7c-0.8-0.1-6.1-2-3.7-2.2c-0.6-0.3-1.6-0.6-1.6,0.3
    		c-0.1,0.9-2.4,0-2.8-0.1c-0.8-0.2-2.7-0.5-3.4,0.1c-0.5,0.4-0.2,0.8-1,1c1.6,3.1,5.7-1.1,7.9,0.9c-2.5,0.9-4.8,1.9-7.6,1.2
    		c0.5,0.3,1,0.5,1.5,0.6c-0.9-0.3-2,0.6-2.9,0.7c-0.8,0.1-1.7,0.2-2.5,0.3c1.9,0.4-1.6,0.8-2.1,0.8c-1.3,0.1-2.6-0.1-3.9,0
    		c-1,0.1-1.9-0.1-2.8-0.1c-1,0-0.5,1.1,0.1,1.3c-1.3,1.3-2.9,1.8-4.7,1.5c0.8,0,2.8-0.6,2.6-1.7c-0.1-0.7-0.5-1.8,0.7-1.9
    		c0.5-0.1,2,0.3,2-0.5c0-0.8,1-1.4,1.6-1.6c1.1-0.4,2.4-0.3,3.5-0.5c1.1-0.2,0.7-0.7,1.2-1.3c1-1.2,3.8-0.8,4.2-2.6
    		c-0.7-0.4-1.6-0.2-2.3-0.4c1.1-0.3,2.2-0.1,3.2-0.6c-0.3-0.2-0.5-0.3-0.8-0.5c1.8-0.3,2.7-0.4,4.2-1.3c0.7-0.4,1.5,0.3,2,0
    		c0.4-0.2,0.2-0.8,0.4-1.2c0.2-0.5,1.4-0.4,1.8-0.5c1-0.3,1.5-1,2.2-1.7c1.5-1.5-2.5-2.6-3.3-2.8c-1.1-0.3-2.1,0.6-3-0.3
    		c-1-0.9-2.4-0.8-3.6-1.2c2.9-0.5,5.8,1.6,8.6,1.6c-0.6-0.7-0.8-1.7-1.7-2c-1-0.3-1.3,0.2-2.3-0.3c-1.5-0.7-2.7-2.6-4.5-2.1
    		c0.7,0.4,1.5,0.6,2.2,0.9c-0.8-0.2-1.7-0.4-2.2,0.5c-0.2-0.7-2-1.6-2.6-1.9c-0.9-0.5-2.8-1.3-3.6-0.3c-0.1-2.1-8.6-2.5-10.1-2.4
    		c-2,0.1-4.1-0.2-6.1,0c-2.2,0.3-4.4-0.1-6.7,0.1c1.6,1.1-0.8,1.4-1.6,1.4c-1.7,0.1-3.6,0.6-5.2,0c1.2,0.1,3.1-2.4,1.1-3
    		c-2.9-0.9-6.4-0.2-9.3,0.1c0.6-0.1,1.1-0.4,1.1-1c-2.9,0.5-5.9,0.2-8.8,0.3c1.6-0.6,3.4-0.3,5-0.8c1.2-0.3,2.2-1.1,3.4-0.9
    		c-1.9-1.2-4.1-0.9-6.2-1.3c-1.9-0.4-3.6-0.5-5.4-0.8C1841.8,57.5,1841.3,57.4,1840.8,57.4L1840.8,57.4z M2341.5,122.1
    		c-1.6-0.5-3.3-1-4.9-1.3C2338.2,121.3,2339.9,121.7,2341.5,122.1z M2008.8,220c0-0.1,1.4,0.1,1.7,0.4c0.6,0.6,0.9,1.5,1.2,2.2
    		c0.7,1.9,1.5,3.8,2.4,5.6c0.5,0.9,0.4,1.9,0.9,2.9c0.4,0.7,1.1,1.2,1.5,1.9c0.8,1.3-0.9,2.7,0.4,4c-1.2,1.2-1.5-1.7-2.5-1.6
    		c-0.9,0.1-0.7,2.2-0.9,2.7c1.1-0.1,3.3-0.7,3.4,1c0.2,1.8-2.9,0.6-2.6,2.6c0.5,3.8-4.4,2.6-5.8,5c-0.3,0.5,0,1.6-1,1.3
    		c-0.7-0.2-1.2-0.5-1.9-0.3c-1.2,0.3,0,2.1,0.1,2.8c0.3,3.2-3.5,3.2-5.6,4c-2.7,1.4-6.5,0.1-9.1-1.1c1-0.8,2.6-1,3.8-1
    		c1.1,0,1.8-0.7,2.8-0.6c1.9,0.1,3.5-1.7,3.8-3.5c0.2-1.2,0.6-1.9,1.7-2.4c0.6-0.3,1.3-0.6,1.4-1.4c0.1-0.9,1.2-1.4,0.2-1.9
    		c-0.8-0.4,0.5-2,0.8-2.3c1.5-1.4,1.4-2.8,2.8-4.3c1.4-1.6,0-3.8,0.3-5.6c0.3-1.8,0.4-4.8-0.4-6.7c-0.4-0.8-1.7-1.6-0.9-2.6
    		C2007.9,220.7,2008.3,220.1,2008.8,220L2008.8,220z"
          />
          <path
            id="path2926"
            class="countryIsland"
            d="M1526.3,147.1c0.3,0.1,0.5,0.1,0.8,0c-0.2-1.3-1.1-1.5-2.2-1.2
    		C1525.3,146.4,1525.8,146.8,1526.3,147.1"
          />
          <path
            id="path2928"
            class="countryIsland"
            d="M1528,146.7c-0.2,0-0.5,0.1-0.7,0.1C1527.5,146.8,1527.8,146.8,1528,146.7"
          />
          <path
            id="path2938"
            class="countryIsland"
            d="M1551.7,149.7c1,0.2,2,0.4,3,0.8c-0.3-0.4-0.5-1-0.9-1.3c-0.6-0.4-1.4-0.2-0.5,0.7
    		C1552.7,149.6,1552.2,149.5,1551.7,149.7"
          />
          <path
            id="path2940"
            class="countryIsland"
            d="M1552.5,147.3c0.1,0.3,0.2,0.6,0.4,0.8C1552.8,147.8,1552.6,147.6,1552.5,147.3"
          />
          <path
            id="path3274"
            class="countryIsland"
            d="M2186.1,226.3c0,0.2-0.1,0.5-0.1,0.7c0.7-0.3,1.5-0.3,2.3-0.1c-0.4-0.4-0.5-0.9-0.3-1.4
    		C2187.2,225.2,2186.7,225.8,2186.1,226.3"
          />
          <path
            id="path3276"
            class="countryIsland"
            d="M2189.8,226.3c0,0.5,0.1,1,0.4,1.5c1.6-1.2,2.7,1.8,4.1,1.1c1.4-0.6-0.2-4-1.3-2.6
    		c0.4-1.6-2.2-1.1-2.9-1.7C2189.9,225.1,2190.4,226,2189.8,226.3"
          />
          <path
            id="path3278"
            class="countryIsland"
            d="M2195.7,231.2c-0.4-0.5-0.8-1-1.3-1.4C2194.5,230.5,2195,231.1,2195.7,231.2"
          />
          <path
            id="path3280"
            class="countryIsland"
            d="M2196.2,231.3c0.3-0.2,0.6-0.4,0.8-0.6c-0.2-0.2-0.5-0.3-0.8-0.4
    		C2196.4,230.6,2196.4,231,2196.2,231.3"
          />
          <path
            id="Russia_Sakhalin"
            class="countryIsland"
            d="M2230.4,234.5c0.6,0.2,1.3,0.7,1.5,1.3c0.2,0.8,1.3,0.3,1.7,1.1
    		c-0.5,0.2-1.1,0.3-1.6,0.2c0.3,0.9,1.3,0.8,2,1.2c-0.1,0.5-0.6,1.3-1.1,1.4c-0.9,0.1-0.6-0.8-1.3-1c-2.9-1-1.3,1.9-0.3,2.8
    		c1.4,1.4,2.9,2.8,3.7,4.7c0.8,1.9,1.4,3.1,2.6,4.8c2.1,2.9,6.4,3.9,8.8,6.5c1.2,1.2,1.6,2.9,2.4,4.4c1,1.9,2.9,3.1,4.2,4.9
    		c2.2,3,4.1,5.7,5.3,9.2c1.3,3.7,5.2,4.8,7.1,7.9c0.8,1.4,0.4,3,1.4,4.4c1,1.5,2.7,2.5,3.4,4.1c0.7,1.6,0.6,3.4,1.7,4.8
    		c1.4,1.9,3.1,3.6,5.1,5c-0.6-0.7-0.3-1.9-0.6-2.7c-0.4-0.8-0.5-1.6-0.6-2.4c-0.2-1.2,0.5-2.8,1.8-1.2c0.8,1,3.5,0.3,4.6,1
    		c1.1,0.7,2.2,2.4,2.8,3.6c-0.2-1.4-0.7-2.6-1.6-3.8c-0.5-0.6-0.9-1.2-1.4-1.8c-0.8-0.9-1.7-0.1-2.6-0.7c0.4,0.4,0.9,0.6,1.5,0.7
    		c-1.5,1.3-3.8-2.6-4.5-3.5c-1.8-2.3-4.8-3-6.7-5.2c-2.5-2.7-2.5-6.5-4-9.6c-0.4-0.8-1.6-2.1-0.4-2.9c0.8-0.5,1.8-0.6,2.7-0.5
    		c-0.9-0.2-1.9,0.1-2.8-0.4c1.1-1,6.2,1,7.3,1.5c1.3,0.7,2.8,1.4,3.9,2.3c0.9,0.7,1.8,1.8,2.9,2.1c-0.9-0.4-1.4-1.3-2.1-1.9
    		c-0.9-0.8-2.1-1.3-3-1.9c-2.4-1.7-4.2-3.9-6.3-5.9c-4.4-4.2-8.9-8.2-13.2-12.5c1.6,1.8-0.6-0.1-0.8-0.3c-0.7-1-1.4-1.7-2.5-2.4
    		c0.2,0.2,0.4,0.5,0.6,0.7c-0.8,0.3-1.2-0.5-1.4-1.1c-0.4-0.9-0.9-1.2-1.8-1.7c-1.6-0.8-3.5-3.6-3.9-5.3c0.3,0.5,0.6,1,1,1.4
    		c0-4.6-6.7-4.4-7.5-8.4c1.6,0.1,3.7,3.1,4.8,4.1c-1.2-1.4-2.4-3-4-4c-1.9-1.1-3.5-2.8-5.4-3.9c0.6,0.2,1.1,0.5,1.5,0.9
    		c-0.5-0.5-1-1-1.6-1.3c-0.8-0.4-0.5-1.1-0.9-1.8c-0.9-1.4-2.7-1.8-4-2.7c0,0.9-0.9,1.3-1.7,1.1
    		C2227.9,232.9,2229.4,233.5,2230.4,234.5"
          />
          <path
            id="path3284"
            class="countryIsland"
            d="M2242.2,193.8C2244.6,194.8,2241.4,191.2,2242.2,193.8"
          />
          <g id="xr" class="countryGroup">
            <path
              id="path3296"
              class="countryIsland"
              d="M2312.4,314c-0.6,1.4,0,3-0.7,4.5c-0.4,0.9-1,2.6,0.7,2.1c-0.7-1.6,1-5.8,3.2-5.3
    			c0-0.3,0-0.5-0.1-0.8C2314.4,315,2313.4,314.4,2312.4,314"
            />
            <path
              id="path3302"
              class="countryIsland"
              d="M2324.7,305.5c-1.2-0.3-1.4,1.2-2,1.8c-1.2,1.2-2.5-0.4-3.4-1c-0.1,0.6,0.2,0.7,0.5,1.1
    			c0.5,0.6-0.6,1.1-0.4,1.9c-0.3,0-0.5-0.1-0.8-0.1c0.2,1-1.9,3.1-0.4,3.5c-1.1,0.2-1.4,2-0.3,2.4c0.3-1.6,2.9-3,1.9-4.9
    			c1.7,0.8,2.1-1.4,3.2-2.2C2323.4,307.6,2327.5,306.6,2324.7,305.5"
            />
            <path
              id="path3304"
              class="countryIsland"
              d="M2320.9,319.5c-0.6,0-1.1,0.3-1.5,0.7C2320.1,321.2,2321.2,320.6,2320.9,319.5"
            />
          </g>
          <path
            id="path3308"
            class="countryIsland"
            d="M2329.8,300.9c-0.4,1.3-1.7,2.2-1,3.8c0.6,1.3,3.5-4.5,3.8-5
    		C2331.3,298.9,2330.8,300.5,2329.8,300.9"
          />
          <path
            id="path3314"
            class="countryIsland"
            d="M2335.6,260.3c0.4,0.4,1,0.6,1.6,0.6C2337.1,260.2,2336.2,259.5,2335.6,260.3"
          />
          <path
            id="path3316"
            class="countryIsland"
            d="M2338.3,291.8c-0.3,0.6-0.5,2.5-1.5,2.2c0.2,0.3,0.4,0.6,0.6,0.8c0.7-0.9,0.9-2,1.3-3
    		C2338.6,291.8,2338.4,291.8,2338.3,291.8"
          />
          <path
            id="path3320"
            class="countryIsland"
            d="M2341.5,267.6c-1-1.4,1-1.3,1.4-2.2c0.5-1.2-1.7-5.2-2.8-3.7c-0.9,1.2,1.6,2.1-0.8,2.9
    		C2336.8,265.4,2340.9,267,2341.5,267.6"
          />
          <path
            id="path3322"
            class="countryIsland"
            d="M2339.5,284c0.7,0.2,0.6,0-0.4-0.6C2339.3,283.6,2339.4,283.8,2339.5,284"
          />
          <path
            id="path3324"
            class="countryIsland"
            d="M2339.8,286.8c0.3,0,0.5,0,0.8,0.1c-0.2-0.3-0.4-0.5-0.6-0.7
    		C2339.9,286.3,2339.9,286.5,2339.8,286.8"
          />
          <path
            id="path3326"
            class="countryIsland"
            d="M2340.7,273.6c1.7,1,0.2-2.8-0.8-3C2340.1,271.5,2340,272.8,2340.7,273.6"
          />
          <path
            id="path3328"
            class="countryIsland"
            d="M2340.2,278.3c0.6-0.3,0.9-0.9,0.4-1.5C2340.4,277.2,2340.2,277.7,2340.2,278.3"
          />
          <path
            id="path3330"
            class="countryIsland"
            d="M2340.6,274.6c0.3,0.3,0.5,0.5,0.8,0.8C2341.3,275,2341,274.7,2340.6,274.6"
          />
          <path
            id="path3332"
            class="countryIsland"
            d="M2342.6,260.4c-0.6-0.1-1.2,0.2-1,0.9c0.2,1,1.4,1,2.2,1.2
    		C2343.6,261.6,2343.1,260.9,2342.6,260.4"
          />
          <path
            id="path3354"
            class="countryIsland"
            d="M2325.9,197.5c0.9,1.1,1.3-0.3,1.8-1c0.6-0.8,1.5-1.7,2.6-1.5c0-0.8-2.1-3.2-3-2.7
    		c-0.9,0.5-2,1.5-3,1.5C2325.5,193.7,2326.9,196.7,2325.9,197.5"
          />
          <path
            id="path3356"
            class="countryIsland"
            d="M2371.4,223.5c-0.8,0.1-1.6-0.3-2.3,0.1c3,2.2,6.4,4,10,5c-1-1.3-2.5-1.7-3.9-2.4
    		c-0.7-0.4-1.5-0.7-2.2-1.2C2372.4,224.7,2372.1,223.9,2371.4,223.5"
          />
          <path
            id="path3358"
            class="countryIsland"
            d="M2382.8,227.4c1.9,1.6,4.6,2.2,7,2.9C2387.9,228.9,2385.2,227.4,2382.8,227.4"
          />
          <path
            id="path3220"
            class="countryIsland"
            d="M2058.4,82.6c0.6-0.3,1.3-0.6,1.9-0.9c-1.9-1.3-4-2.2-6.3-1.4
    		C2054.3,81.9,2057.1,82.3,2058.4,82.6"
          />
          <path
            id="path3212"
            class="countryIsland"
            d="M2018.9,72.6c0.7,0,1.4,0.1,2.2-0.1c-1-0.5-0.3-1.5-1.5-1.7c-1.4-0.2-2.5-1-3.8-1.3
    		C2016.5,70.7,2017.8,71.7,2018.9,72.6"
          />
          <path
            id="path3214"
            class="countryIsland"
            d="M2024.5,71.1c0.8-0.6,1.6,0.3,2.1,0.8c0.7,0.6,1.7,0.2,2.4,0.8c-0.8,0.3-1.6-0.2-2.4,0.1
    		c1.7,1.1,3.7,1.6,5.7,1.9c2,0.4,3.9,1.4,5.9,1.7c2,0.3,4.6,1.1,6.6,0.9c1.5-0.1-0.5-1.8-0.8-2.1c1.3,0,2.3,0.8,3.5,1
    		c1.2,0.2,2.5-0.3,3.7-0.6c2.1-0.4,5.1-1.4,7.1-0.1c-0.5-0.2-1.1-0.2-1.6-0.1c1.1,0.5,2.9,1,3.9,0.4c1.1-0.7,3.5,0.3,4.9-0.4
    		c-2.5-1-5.4-0.5-7.9-1.4c-1.3-0.5-2.6-0.7-3.9-1.2c-1.2-0.6-2.3-1.8-3.7-1.9c1.1,0,1.1-0.1-0.1-0.3c0.3,0.1,2.8,0.1,0.9-0.3
    		c0.9,0.1,5.7-0.1,3.4,1.7c3.4,1.4,7,2.7,10.7,2.8c1,0.1,2.6,0.2,3.3-0.7c1-1.3-1.9-1.3-2.3-2c0.8-0.2,1.5-0.5,2.2-0.6
    		c-2.3-0.7-4.7-0.8-7.1-1.3c-1.8-0.4-4.2-1.5-6-0.8c-4.4,1.6-9.1-1.6-13.5-1.9c0.8,0.3,1.6,0.6,2.3,0.9c-0.9-0.2-2.3-0.7-3,0.2
    		c1,1.1,2.6,1.5,3.8,2.3c-0.8,0-1.6,0.2-2.4-0.1c-0.8-0.3-1.3-0.9-2.2-1.2c-0.8-0.2-1.6,0.1-2.4-0.1c-0.8-0.1-1.3-0.7-2.1-0.9
    		c-1.7-0.6-3.6-0.4-5.4-0.9c-0.5-0.2-4.8-1.4-3.1,0.2c-0.3-0.2-1.9-0.9-1.6,0c0.2,0.5-1.3,0.3-1.6,0.5c-0.4,0.3,2.2,1.4,2.4,1.5
    		c-0.8-0.2-1.7-0.3-2.4,0.3C2024.2,70.6,2024.4,70.8,2024.5,71.1"
          />
          <path
            id="path3216"
            class="countryIsland"
            d="M2029.1,80.2c1.9,1.7,4.8,1.4,7,2.5C2035.9,80.8,2030.5,80.3,2029.1,80.2"
          />
          <path
            id="path3218"
            class="countryIsland"
            d="M2043.2,70.7c-0.6-0.2-1.1-0.3-1.7-0.4C2042.1,70.7,2042.7,70.8,2043.2,70.7"
          />
          <path
            id="path3222"
            class="countryIsland"
            d="M2060.2,85.7c-0.5,0-1,0-1.5,0.1c1.4,1.1,3.1,0.2,4.6-0.1c1.9-0.3,3.8,0.5,5.6,0.8
    		c3.8,0.6,8,1,11.9,0.7c-4.3-2.6-9.2-4.2-14.2-4.7c-0.8-0.1-4.1-0.2-4.8,0.5c-0.4,0.4,0.5,1.3,0.4,1.9
    		C2062.2,85.6,2060.8,85.6,2060.2,85.7"
          />
          <path
            id="path3224"
            class="countryIsland"
            d="M2066.8,99.2c-1.3-0.8-3.2-0.7-4.7-0.4c0.5,0.7,1.4,0.8,2.2,0.9c-1.8-0.5,0.1-0.3,0.4-0.2
    		C2065.4,99.8,2066.4,100,2066.8,99.2"
          />
          <path
            id="path3226"
            class="countryIsland"
            d="M2068.2,98.2c0.3-0.1,0.5-0.1,0.8-0.1c-0.5-0.2-1.1-0.2-1.6-0.1
    		C2067.7,98,2068,98.1,2068.2,98.2"
          />
          <path
            id="path3228"
            class="countryIsland"
            d="M2071.8,63.9c0.4,0.9,3,0.1,3.7,0.2C2075.6,62.8,2072.3,63.5,2071.8,63.9"
          />
          <path
            id="path3230"
            class="countryIsland"
            d="M2095.4,76.7c1.9,0,4.4,0,5.9-1.3c-1-0.3-0.3-1.5-1.7-1.3c-1.5,0.3-2.8-0.2-4.2-0.5
    		c-1.4-0.2-2.9-0.4-4.3-0.4c-1.6,0-2.9,0.7-4.5-0.1c-1.6-0.8-5.2-1.9-6.9-0.8c-2.5,1.6-4.4-1.7-6.8-0.9c1,0.3,1.4,1.8,2.6,2.3
    		c1.9,0.7,4.2,0.8,6.2,1.3C2086.1,75.8,2091,76.7,2095.4,76.7"
          />
          <path
            id="path3232"
            class="countryIsland"
            d="M2098.4,67.6c0.3,0.1,0.5,0.1,0.8,0.1c-0.4-0.6-1-0.9-1.7-0.6
    		C2097.8,67.3,2098.1,67.4,2098.4,67.6"
          />
          <path
            id="path3068"
            class="countryIsland"
            d="M1695.8,45.8c1.1,1,3.3,0.4,4.6,0.4c-2.3-1.1-5.2,0.2-7.4-0.9
    		C1693.8,45.9,1694.9,46,1695.8,45.8"
          />
          <path
            id="path3072"
            class="countryIsland"
            d="M1700.5,37.7c-0.1,0.2-0.2,0.5-0.4,0.7c2,0.3,4.3,0.4,6.2-0.3
    		C1704.7,37,1702.4,37.8,1700.5,37.7"
          />
          <path
            id="path3106"
            class="countryIsland"
            d="M1749.7,36.3c1.2,1.5,5.6,1.3,7.1,0.5C1755.3,35.2,1751.6,36.1,1749.7,36.3"
          />
          <path
            id="path3202"
            class="countryIsland"
            d="M1975.7,83c-0.4-0.4-1-0.7-1.7-0.6C1974.5,82.8,1975.1,83,1975.7,83"
          />
          <path
            id="path3204"
            class="countryIsland"
            d="M1985.1,85.2c-0.4-0.3-0.8-0.7-1.2-1C1984.1,84.8,1984.5,85.2,1985.1,85.2"
          />
          <path
            id="path3206"
            class="countryIsland"
            d="M1993.5,85.2c1.1,0.1,2.2,0.3,3.2,0.2C1995.7,85.2,1994.6,84.8,1993.5,85.2"
          />
          <path
            id="path3208"
            class="countryIsland"
            d="M2006.7,89.4c0.2,0,0.5,0,0.7,0c-0.5-0.3-1-0.3-1.6-0.2C2006.2,89.3,2006.4,89.3,2006.7,89.4"
          />
          <path
            id="path3210"
            class="countryIsland"
            d="M2009.4,89.8c-0.4-0.2-0.9-0.3-1.4-0.3C2008.4,89.6,2008.9,89.7,2009.4,89.8"
          />
          <path
            id="path3200"
            class="countryIsland"
            d="M1957.3,87.6c-0.5-0.1-0.9,0.1-1.3,0.4c0.8,0.8,2.2,0.7,3-0.1
    		C1958.5,87.8,1957.9,87.7,1957.3,87.6"
          />
          <path
            id="path3190"
            class="countryIsland"
            d="M1889.3,64.4c1,0.8,2.7,1,4,1.1C1892,64.7,1890.9,64.1,1889.3,64.4"
          />
          <path
            id="path3194"
            class="countryIsland"
            d="M1896.7,66c0.6,0.1,1.1,0.1,1.7,0.1c-0.6-0.1-1.1-0.3-1.6-0.5
    		C1896.8,65.7,1896.8,65.9,1896.7,66"
          />
          <path
            id="path3196"
            class="countryIsland"
            d="M1912.6,81.2c0-0.9,2.4-1.9-0.1-2.6c-2.2-0.6-4.7-0.3-7-0.5c0.8,0.9-0.4,1.3-1.2,1.2
    		C1905.2,81.2,1910.9,81.2,1912.6,81.2"
          />
          <path
            id="path3198"
            class="countryIsland"
            d="M1927.2,79.8c0.3,0,0.5,0.1,0.8,0.1c-0.3-0.2-0.6-0.4-0.9-0.6
    		C1927.2,79.5,1927.3,79.7,1927.2,79.8"
          />
          <path
            id="path3152"
            class="countryIsland"
            d="M1797.9,69.3c-0.3,0.1-0.5,0.1-0.8,0.1C1797.4,69.6,1797.6,69.5,1797.9,69.3"
          />
          <path
            id="path3154"
            class="countryIsland"
            d="M1807.5,60.6c-1.5,0.6-3.2,0.3-4.6,1.1c0.8,0.4,2.2,0.1,3.1,0.3
    		C1807,62.1,1809.2,61.5,1807.5,60.6"
          />
          <path
            id="path3156"
            class="countryIsland"
            d="M1803.8,63.8c-0.3,0.1-0.5,0.1-0.8,0.2c0.5,0.2,1,0.3,1.6,0.2
    		C1804.4,64.1,1804.1,63.9,1803.8,63.8"
          />
          <path
            id="path3158"
            class="countryIsland"
            d="M1804.9,66.7c-0.2,0.1-0.5,0.1-0.7,0.2c0.5,0.2,1.1,0.3,1.7,0.3
    		C1805.6,67,1805.3,66.8,1804.9,66.7"
          />
          <path
            id="path3160"
            class="countryIsland"
            d="M1806.7,64c-0.2-0.1-0.5-0.1-0.7-0.2c0.4,0.4,0.9,0.5,1.5,0.2C1807.2,64,1807,64,1806.7,64"
          />
          <path
            id="path3164"
            class="countryIsland"
            d="M1808.6,62.4c-0.5-0.1-1.1-0.2-1.6-0.1C1807.5,62.6,1808.1,62.7,1808.6,62.4"
          />
          <path
            id="path3166"
            class="countryIsland"
            d="M1810.6,63.8c-0.8,0-1.6,0.4-2.4,0.3C1808.8,64.3,1810.6,64.8,1810.6,63.8"
          />
          <path
            id="path3168"
            class="countryIsland"
            d="M1814.1,67.7c-0.3-0.2-0.6-0.4-0.9-0.6c0.6-0.4,1.2-0.2,1.7,0.3c-0.6-2.5-5.1,0.1-6.6-0.9
    		C1809.7,68.1,1812.3,67.1,1814.1,67.7"
          />
          <path
            id="path3172"
            class="countryIsland"
            d="M1815.3,63.6c-0.5-0.3-1-0.3-1.5-0.2C1814.3,63.5,1814.8,63.6,1815.3,63.6"
          />
          <path
            id="path3174"
            class="countryIsland"
            d="M1815.7,67.3c0.3-0.1,0.5-0.3,0.8-0.4c-2.1,0.8,0.6-0.8-0.9-0.7
    		C1814.7,66.3,1815.3,67.1,1815.7,67.3"
          />
          <path
            id="path3176"
            class="countryIsland"
            d="M1816.7,64.6c-0.5-0.4-1-0.8-1.6-0.9C1815.3,64.5,1816,64.6,1816.7,64.6"
          />
          <path
            id="path3180"
            class="countryIsland"
            d="M1819,67.7c-0.8,0.1-1.5,0.4-2.3,0.5C1817.5,68.7,1818.5,68.5,1819,67.7"
          />
          <path
            id="path3112"
            class="countryIsland"
            d="M1762.4,43.1c0.8,0.4,1.6,0.9,2.5,1.1c1.6,0.3,3.1-0.1,4.6,0.4c-0.5,0.1-1,0.2-1.5,0.2
    		c2.3,0.8,4.9,0.3,7.1-0.6c-1.1-1.6-3.8-1.1-5.4-1.2c-2.8,0-5.7-0.1-8.5-0.3C1761.6,42.7,1762.1,42.8,1762.4,43.1"
          />
          <path
            id="path3114"
            class="countryIsland"
            d="M1762.7,41.5c2.4-0.2,5.7-1.1,8,0.1c-1.8-0.4-3.7-0.3-5.5-0.1c1.3,0.3,2.6,0.3,3.9,0.6
    		c-1-0.1-2.1-0.3-3.1,0c2.1-0.1,4.2,0.5,6.2,0.8c2.5,0.3,4.3-0.3,6.7-0.7c1.9-0.3,3.9-0.4,5.8-0.3c1.4,0.1,3.8,0.5,4.8-0.8
    		c-0.9,0.1-3.2-0.8-3.4-1.9c1,0.2,2.4,0.2,3.3-0.4c-2.8-1-6-0.5-8.7-1.7c-2.4-1.1-5.7-2-8.2-1.1c0.8,0.1,1.6,0,2.4,0.1
    		c-3.8,0-7.3,1.4-11,1.8c0.2,1.2,2,0.5,2.8,0.9c-0.7,0.2-0.8,0.7-0.3,1.2c-1,0-2.1-0.2-3,0.4c1.1,0.1,2.2,0,3.2,0.3
    		c-1.5,0-3.7-0.6-4.9,0.5C1762.1,41.4,1762.4,41.4,1762.7,41.5"
          />
          <path
            id="path3122"
            class="countryIsland"
            d="M1766.9,45.2c0.3,0,0.5,0,0.8,0c-0.9-0.6-2.1-0.4-3.2-0.5C1765.2,45.1,1766.1,45.1,1766.9,45.2"
          />
          <path
            id="path3136"
            class="countryIsland"
            d="M1769.6,46.7c0.8,0.1,1.7,0.3,2.4-0.1C1771.2,46.3,1770.4,46.7,1769.6,46.7"
          />
          <path
            id="path3144"
            class="countryIsland"
            d="M1773.3,46.8c0.5,0.1,1,0.1,1.5,0C1774.3,46.5,1773.8,46.5,1773.3,46.8"
          />
          <path
            id="path3146"
            class="countryIsland"
            d="M1779.2,43.1c-0.6,0.1-1.1,0.3-1.6,0.6c0.8,0.1,1.6,0.3,2.4,0.6c-2.1,0.5-4,1.5-6.2,1.6
    		c0.8,0.2,1.5,0.4,2.3,0.5c0.1,0,4.6-0.4,2.4,0.1c0.6,0.2,1.2,0,1.8-0.1c1-0.1,1.6,0.8,2.4,1.1c1.9,0.9,3.7,1.4,5.8,1.1
    		c1.1-0.1,2,0.3,3,0.5c1.3,0.3,2.8,0.2,4.1,0.2c1.1,0,2.2,0.2,3.3,0.5c0.5,0.1,3.7,0.4,1.7,0.6c2.1,0,4,0.1,6.1,0
    		c1.5-0.1,4,0.5,4.9-1.1c-2-1-4.4-0.9-6.3-2c0.8,0,1.6,0.4,2.4,0.1c-0.5-1.2-1.6-1.8-2.3-2.8c0.3-0.1,0.6-0.1,0.9-0.2
    		c-2.5-1.4-6.6-2.4-9.4-1.4c0.6-0.1,0.8,0.3,0.6,0.8c-0.8-0.2-1.7-0.3-2.4-0.1c0.5,1-0.8,1-1.4,0.9c1.2-0.1,0.5-1.4,1.2-2
    		c-1.3,0-2.4-0.6-3.7-0.6c-1.7,0-3.4,0.4-5.1,0.4C1784.2,42.5,1780.9,42,1779.2,43.1"
          />
          <path
            id="path3162"
            class="countryIsland"
            d="M1806.6,44.9c0.4,0.8,0.8,0.8,1.4,0.2C1807.5,45.1,1807,45,1806.6,44.9"
          />
          <path
            id="path3178"
            class="countryIsland"
            d="M1816.3,53c0.1,0.1,0.2,0.2,0.4,0.4c-0.6,0.5-1.1,1.2-1.4,1.9c2.7,0.7,4.8,0.2,7.4-0.5
    		c2.7-0.7,5.2-0.4,7.9-0.4c1.4,0,3.1-0.4,4.6-0.5c2.4-0.2,4.8-0.5,6.9-1.6c-1.1-1.1-2.7-1.7-4.2-2c-0.7-0.1-1.4,0-2.1,0
    		c-1-0.1-2.1-0.8-3.1-1.1c-1.4-0.4-3.5-1.3-4.9-0.7c-0.9,0.4-2.3,2.1-3.3,1.7c0.5-1.1-0.9-2,0-3c-0.9,0-1.7-0.4-2.5-0.5
    		c-0.6-0.1-3.5,0.3-1.6,1c-1.1,0-1.9-0.7-2.9-0.7c-0.7,0-0.8,1-1.5,1.2c0.3,0.9,1.3,0.8,2.1,0.9c-0.5-0.1-1.5,0-1.8,0.4
    		c-0.4,0.4,2.1,1.2,2.4,1.3c-0.4-0.1-3-0.7-3.1,0.1C1815.5,51.7,1816.1,52.4,1816.3,53"
          />
          <path
            id="path3182"
            class="countryIsland"
            d="M1847.6,54.3c0.5-0.1,1-0.3,1.5-0.6c-0.8-0.7-1.5,0.4-2.4,0.1C1847,54,1847.3,54.2,1847.6,54.3"
          />
          <path
            id="path3184"
            class="countryIsland"
            d="M1855,55.2c0.2-0.1,0.5-0.3,0.7-0.4c-1.8-1.1-4.3-0.2-6.3-0.3
    		C1851.1,55.3,1853.3,54.2,1855,55.2"
          />
          <path
            id="path3186"
            class="countryIsland"
            d="M1855.5,59.5c0.5,0,1,0,1.5-0.2c-0.5-0.4-1.1-0.4-1.7-0.1C1855.3,59.2,1855.4,59.3,1855.5,59.5"
          />
          <path
            id="path3188"
            class="countryIsland"
            d="M1860.9,60.5c0.5-0.1,1.1-0.2,1.6-0.3c-0.7-0.5-1.6-0.4-2.4-0.5c1,0.3,1.1,0.5,0.1,0.6
    		C1860.4,60.4,1860.7,60.4,1860.9,60.5"
          />
          <path
            id="path3016"
            class="countryIsland"
            d="M1605.3,121.6c0.9,0.4,2.4-0.2,3.2-0.5C1607.4,121,1606.3,121.1,1605.3,121.6"
          />
          <path
            id="path3042"
            class="countryIsland"
            d="M1624.6,69c0.5,0.2,1.2-0.1,1.7-0.4C1625.7,68.8,1625.1,68.9,1624.6,69"
          />
          <path
            id="path3044"
            class="countryIsland"
            d="M1626,69c0.3,0,0.5,0,0.8-0.1C1626.5,68.9,1626.3,69,1626,69"
          />
          <path
            id="path3046"
            class="countryIsland"
            d="M1627.9,68.1c0.3,0,0.6-0.1,0.9-0.1C1628.4,68,1628.2,68,1627.9,68.1"
          />
          <path
            id="path3048"
            class="countryIsland"
            d="M1628.5,117.8c0.5,0,1,0,1.5,0.1C1629.6,117.7,1629,117.7,1628.5,117.8"
          />
          <path
            id="path3050"
            class="countryIsland"
            d="M1629.9,67.4c0.6,0.7,1.7-0.1,2.3-0.3C1631.4,67.2,1630.7,67.4,1629.9,67.4"
          />
          <path
            id="path3054"
            class="countryIsland"
            d="M1633.8,106.4c0.2-0.2,0.5-0.3,0.8-0.2c-0.5-0.2-1.1-0.2-1.6,0
    		C1633.3,106.3,1633.5,106.3,1633.8,106.4"
          />
          <path
            id="path2944"
            class="countryIsland"
            d="M1563.7,42.8c0.5,0.2,1,0.2,1.5,0C1564.7,42.6,1564.2,42.6,1563.7,42.8"
          />
          <path
            id="path2952"
            class="countryIsland"
            d="M1576.1,41c0.5,0.2,1,0.3,1.6,0.1C1577.2,41,1576.6,41,1576.1,41"
          />
          <path
            id="path2966"
            class="countryIsland"
            d="M1586.6,40.7c0.4,0.2,1.2,0,1.6-0.3C1587.6,40.2,1587,40.3,1586.6,40.7"
          />
          <path
            id="path2976"
            class="countryIsland"
            d="M1589.7,40.7c0.2,0,0.5,0,0.7-0.1C1590.1,40.6,1589.9,40.6,1589.7,40.7"
          />
          <path
            id="path2982"
            class="countryIsland"
            d="M1593,40.7c0.8,0.2,1.7,0.3,2.5,0.1C1594.6,40.7,1593.8,40.6,1593,40.7"
          />
          <path
            id="path2988"
            class="countryIsland"
            d="M1595.9,40.2c0.3,0.1,0.5,0.1,0.8,0C1596.4,40.2,1596.2,40.2,1595.9,40.2"
          />
          <path
            id="path2990"
            class="countryIsland"
            d="M1596.3,36.5c1.1,0.6,3,0.4,4-0.3C1598.9,36.2,1597.6,36.4,1596.3,36.5"
          />
          <path
            id="path3000"
            class="countryIsland"
            d="M1600.3,37.2c1.2,0.4,2.9,1,4,0.1C1603.1,37.1,1601.5,36.4,1600.3,37.2"
          />
          <path
            id="path3002"
            class="countryIsland"
            d="M1600.4,34.2c0.6,0.2,1.2,0.2,1.7-0.1C1601.5,34.1,1600.9,34.1,1600.4,34.2"
          />
          <path
            id="path3006"
            class="countryIsland"
            d="M1601.7,36.7c0.5,0.2,1.1,0.2,1.6-0.1C1602.7,36.6,1602.2,36.6,1601.7,36.7"
          />
          <path
            id="path3012"
            class="countryIsland"
            d="M1605.1,39.6c0.3,0,0.5-0.1,0.8-0.1C1605.7,39.5,1605.4,39.5,1605.1,39.6"
          />
          <path
            id="path3032"
            class="countryIsland"
            d="M1613.3,42c0.5,0.1,1.1,0.1,1.6,0C1614.3,41.9,1613.8,41.9,1613.3,42"
          />
          <path
            id="path3034"
            class="countryIsland"
            d="M1616.4,34.2c0.5,0.3,1.1,0.3,1.7,0.1C1617.5,34.2,1617,34.2,1616.4,34.2"
          />
          <path
            id="path3036"
            class="countryIsland"
            d="M1617.9,33.8c1.9,0.3,5.5,1.6,7.1,0C1622.7,33.2,1620.1,33.2,1617.9,33.8"
          />
          <path
            id="path2930"
            class="countryIsland"
            d="M1529.1,145.7c-0.5-0.1-1.1-0.1-1.6,0.1C1528,145.8,1528.6,145.8,1529.1,145.7"
          />
          <path
            id="path2932"
            class="countryIsland"
            d="M1541.2,39.5c0.4,0.7,2.8,0.4,3.5,0.4c1.7,0.1,2.6,0.9,4.3,0.2c1.3-0.5,3.1-0.9,4.5-1.3
    		c1.8-0.5,3.6,1.1,5.5,0.5c-0.5-0.2-1.1-0.3-1.6-0.4c2-0.4-1.5-0.7-1.9-0.7c-1.5-0.1-3.1,0.1-4.6,0.4
    		C1547.7,39.3,1544.4,39.2,1541.2,39.5"
          />
          <path
            id="path2934"
            class="countryIsland"
            d="M1555.4,42.5c1.1,0.3,2.2-0.1,3.3-0.1c0.4-0.1,0.9-0.3,1.3-0.4c0.5,0.1,1,0.2,1.5,0.2
    		c-0.3-0.9-1.2-0.8-1.9-0.8c1-1.2,3.9-0.4,5.3-1c2.3-0.9,4.9-0.5,7.3-1.1c-1.9-1.4-4.1,0-6.2-0.4c0.8-0.3,1.6-0.5,2.4-0.5
    		c-1.1-1-2.8,0-4-0.2c0.5-0.2,1-0.3,1.5-0.2c-1.9-0.5-3.9,0.4-5.7,0.6c0.8,0.3,1.6,0.7,2.4,0.4c-2.1,0.9-2.8,1.2-5,0.8
    		c-1.5-0.2-3,0.4-4.5,0.7c1.4,0.1,2.8-0.4,4.1,0.1c-0.7,0.1-1.5,0.1-2.2,0.1c0.5,0.2,1,0.4,1.6,0.4c-2,1.1-4.3-0.5-6.3,0.2
    		c2,1.1,4.2,0.1,6.3,0.4C1556.1,41.9,1555.7,42.2,1555.4,42.5"
          />
          <path
            id="path2942"
            class="countryIsland"
            d="M1562.3,133.6c-0.2,0.7,0,0.7,0.7,0.1C1562.8,133.7,1562.5,133.7,1562.3,133.6"
          />
          <path
            id="path2946"
            class="countryIsland"
            d="M1566.1,42.8c0.6,0,1.2-0.3,1.5-0.9c-1-0.3-2.2-0.3-3.1,0.4C1565,42.5,1565.6,42.6,1566.1,42.8"
          />
          <path
            id="path2948"
            class="countryIsland"
            d="M1565.6,37.2c0.3,0.4,1.9-0.4,2.2-0.4C1567,36.5,1566.2,36.5,1565.6,37.2"
          />
          <path
            id="path2950"
            class="countryIsland"
            d="M1569.7,43.1c-0.8,0.2-1.6-0.1-2.4,0.1c2.2,0.2,4.7,0.9,6.9,0.2
    		C1572.6,43.1,1571.3,42.4,1569.7,43.1"
          />
          <path
            id="path2954"
            class="countryIsland"
            d="M1577.2,41.8c2.2,0.6,5,0.6,7.1-0.3c-1.4-0.1-2.7-0.8-4.1-0.8c-0.5,0-0.9,0.2-1.3,0.4
    		c-0.9,0.4-1.6,0.1-2.5,0.4C1576.7,41.6,1576.9,41.7,1577.2,41.8"
          />
          <path
            id="path2956"
            class="countryIsland"
            d="M1580.4,40.2c0.5,0.2,1,0.1,1.5-0.1c-0.7-0.4-1.5-0.7-2.3-0.5
    		C1579.9,39.8,1580.2,40,1580.4,40.2"
          />
          <path
            id="path2958"
            class="countryIsland"
            d="M1582.7,35.8c0.3,0,0.5,0,0.8,0.1c-0.5-0.2-1-0.3-1.5-0.4C1582.2,35.7,1582.5,35.7,1582.7,35.8"
          />
          <path
            id="path2960"
            class="countryIsland"
            d="M1583.1,38.4c0.5,0.7,2.8,0.5,3.5,0.5c2,0.1,3.9-0.2,5.9-0.4
    		C1589.4,38.3,1586.2,37.6,1583.1,38.4"
          />
          <path
            id="path2962"
            class="countryIsland"
            d="M1583.4,40.2c0.7,0.5,1.8,0.5,2.4-0.2C1585,39.6,1584,39.5,1583.4,40.2"
          />
          <path
            id="path2964"
            class="countryIsland"
            d="M1584.4,37.3c2.7,0.7,5.9,0.8,8.7,1c2.1,0.2,5.2,1.6,7.1,0.1c-2.8-0.5-5.9-1.3-8.7-1.3
    		C1590.2,37.2,1585.4,36.1,1584.4,37.3"
          />
          <path
            id="path2968"
            class="countryIsland"
            d="M1587.3,40.2c0.7,0.2,1.5,0.5,2.2,0C1588.8,39.9,1588,39.9,1587.3,40.2"
          />
          <path
            id="path2970"
            class="countryIsland"
            d="M1589,36.3c1.3,0.5,3.1,0.4,4.5,0.2c2-0.2,4.2-0.1,6.1-0.7c-2.4-0.6-5.1-0.7-7.6-0.4
    		c0.5,0.3,1,0.5,1.6,0.6c-1.8,0.1-3.7-0.8-5.4-0.1C1588.5,36.1,1588.7,36.2,1589,36.3"
          />
          <path
            id="path2972"
            class="countryIsland"
            d="M1598.4,115c-2.2-0.7-4.5-1.9-6.9-1.3c-0.8,0.2-1.6,0.5-2.1,1.2c-0.8,1,0.1,1.6,0.1,2.6
    		c0,1.8,3.9,1.7,5.1,1.5c1.1-0.2,2.3-0.3,3.2-1c0.5-0.4,2.2-3,2.4-1.2C1601.1,115.9,1599.2,115.2,1598.4,115"
          />
          <path
            id="path2974"
            class="countryIsland"
            d="M1589.2,41.6c0.7,0.3,1.8,0.2,2.3-0.4C1590.7,41,1590,41.5,1589.2,41.6"
          />
          <path
            id="path2978"
            class="countryIsland"
            d="M1590.4,39c1.5,0.7,5,1.3,6.4,0C1594.7,38.6,1592.6,38.9,1590.4,39"
          />
          <path
            id="path2980"
            class="countryIsland"
            d="M1591.9,35.9c3.4,0.4,6.9,1.9,10.4,0.9C1598.9,36.5,1595.4,36,1591.9,35.9"
          />
          <path
            id="path2984"
            class="countryIsland"
            d="M1594,42.3c0.3,1.1,5.5,0.4,6.3,0.2c-1.3-1.8-4.1-2-6.1-1.2c0.2,0.2,0.4,0.5,0.6,0.7
    		C1594.5,42.1,1594.2,42.2,1594,42.3"
          />
          <path
            id="path2986"
            class="countryIsland"
            d="M1600.2,35.5c0.5,0,1-0.2,1.5-0.4c-2.6-0.6-5.4-1-7.9,0C1596,35,1598.1,35.3,1600.2,35.5"
          />
          <path
            id="path2992"
            class="countryIsland"
            d="M1598.9,33.8c1.5-0.1,4.6,0.6,5.8-0.8c-2.2-0.3-4.5-0.3-6.6,0.2c1,0,1.1,0.2,0.1,0.5
    		C1598.4,33.7,1598.7,33.7,1598.9,33.8"
          />
          <path
            id="path2994"
            class="countryIsland"
            d="M1602.8,42.5c0.7,0.2,4.1-0.6,2-1c1.5-0.5,3.1-0.2,4.7-0.4c-3.5-1.2-7.6-1.1-11.3-0.7
    		C1599.9,40.5,1600.9,42.4,1602.8,42.5"
          />
          <path
            id="path2996"
            class="countryIsland"
            d="M1599.2,39.6c0.7,0.7,2.5,0.5,3.2-0.1C1601.3,39.4,1600.3,39.3,1599.2,39.6"
          />
          <path
            id="path2998"
            class="countryIsland"
            d="M1603.4,91.1c1-0.1,2-0.1,3,0.1c-0.8-0.1-1.8-0.3-2.2,0.6c0.5-0.2,0.9-0.3,1.4-0.2
    		c-0.4,0.5-0.9,0.7-1.5,0.7c0.1,0.6,0.6,1,1.2,1c-0.5,0.2-1.1,0.3-1.5,0.6c-0.3,0.3,0.3,1-0.2,1.1c-1.2,0.4-2.6-1-3.8,0
    		c-1.4,1.3,0.4,2.7,1.2,3.6c0.5,0.5,1.2,0.7,1.8,0.7c0.6,0,3.1,0.3,1.3-0.5c1.6,0.5,3.2-0.2,4.5,1c0.1-0.2,0.2-0.5,0.4-0.7
    		c0.2,0.2,0.5,0.4,0.7,0.6c0.1-0.6,0.4-0.9,1-0.7c-0.9,2.5,2.3,0.7,2.9,0.8c-0.6,0.2-1.1,0.6-1.4,1.2c1.2,0.7,2.7,0.5,3.9,1.1
    		c-0.4-0.2-2.9-0.5-0.9,0.3c-0.7-0.2-1.5-0.2-2.2,0.1c0.7,0.1,1.7,0.6,1.4,1.5c-0.5-0.4-1-0.4-1.5-0.1c1.2-0.1,2.5,0.2,3.7,0.6
    		c0.6,0.2,1.3-0.2,1.9,0.1c0.7,0.2,1.3,0.6,2,0.5c-0.2-0.3-0.5-0.5-0.8-0.7c0.8,0.7,3.1,2,3.9,0.7c0.5-0.7,1.4-0.4,2.1-0.1
    		c0.5,0.2,3.2,0.8,1,0c0.9,0.4,3.7,1.4,4.6,0.6c-0.8-0.5-1.8-0.2-2.4-0.9c2.2,0,4.1,1.2,6.3,1.1c-1-0.3-2.2-0.2-3.1-0.9
    		c0.7,0.4,1.6,0.5,2.3,0.5c0.5,0,2.6-0.6,0.7-0.8c1.6-0.2-0.4-0.6-0.9-0.8c-1.1-0.3-2.2-0.5-3.2-0.8c-1.7-0.5-4.1-1.1-5.3-2.5
    		c-1.5-1.7-3.6-3-5.3-4.5c-0.5-0.5-0.1-1.8-0.4-1.9c-0.9-0.5-1.1-1.4-2.1-1.6c0.5,0.1,1,0.1,1.4-0.1c-0.4-0.5-0.8-0.7-1.4-0.8
    		c0.5,0,1,0.2,1.4,0.3c0.9,0.2,0.4-1-0.2-1.1c0.5,0.1,1.1,0.2,1.6,0.1c-0.7-0.6-1.5-0.4-2.2-0.8c0.4,0.2,3.8,0.4,3.3-0.6
    		c-0.4-0.8-2.4-0.4-3.1-0.5c1,0.1,2.3,0,3.1-0.6c-0.8,0-1.6,0-2.4,0c1.1-0.2,2.3,0,3.2-0.7c-1.2-0.9-3.4-1.1-4.9-1.1
    		c-2.5,0-4.3-1.1-6.8-0.1c-1.6,0.7-4.3-0.4-5.7,0.9c-0.4,0.4,0.6,2.1,1.2,2.2c-1.6-0.3-3.5-0.5-4.5,1.1
    		C1602.4,90.8,1602.9,90.9,1603.4,91.1"
          />
          <path
            id="path3004"
            class="countryIsland"
            d="M1601.5,38.4c0.3,1.3,4,0.3,4.8-0.1C1604.7,37.8,1603.1,38,1601.5,38.4"
          />
          <path
            id="path3008"
            class="countryIsland"
            d="M1602.1,35.6c1.3,0.3,2.8,0.5,4-0.1C1604.8,35.3,1603.4,35.2,1602.1,35.6"
          />
          <path
            id="path3010"
            class="countryIsland"
            d="M1603.4,37c0.3,0,0.6,0,0.9-0.1C1604,36.9,1603.7,36.9,1603.4,37"
          />
          <path
            id="path3014"
            class="countryIsland"
            d="M1605.1,36.3c1.2,0.4,2.6,0.3,3.8,0.1C1607.7,36.1,1606.4,36.3,1605.1,36.3"
          />
          <path
            id="path3018"
            class="countryIsland"
            d="M1611.6,84.2c-0.6,0.3-2.9,0.6-1.9,1.7c-0.3,0.1-0.6,0.3-0.9,0.4c1.4,0.7,3.1-0.4,4.5-0.5
    		c0.9-0.1,1.8,0.2,2.7,0.4c0.7,0.1,3.7,1.1,2.6-0.4c0.4,0.7,1.3,1,2.1,1.1c0.7,0.1,3.2,0.6,2.2-0.7c0.9,0.7,1.8-0.1,2.2-0.9
    		c-1-0.4-2.3-0.4-3.1-1.3c1.1,0.5,2.9,1.4,4.1,0.4c0.8-0.7-4.9-1.6-5.4-1.8c1.2,0.3,2.4,0,3.6,0.4c0.8,0.3,1.7,0.9,2.5,0.6
    		c2-0.8-2.6-2.2-3.2-2.3c1.6,0.1,0.6-0.4,0.1-0.6c1.7-0.1,2.8,1.8,4.5,1c-0.2-0.2-0.3-0.5-0.5-0.7c0.6,0.4,2.6-0.1,0.7-0.3
    		c0.5-0.2,1-0.3,1.5-0.2c-0.6-1.6-2.2-1.8-3.5-2.5c1.1,0.3,2.6,1,3.7,0.7c0.6-0.1,2.1-1.1,0.6-1.2c1.1,0.1,1.1,0,0-0.5
    		c1.1,0.3,2.9,1.6,3.8,0.3c-0.6-0.1-1.2-0.3-1.7-0.6c0.7,0.3,1.6,0.5,2.3,0.4c0.5-0.1,2.6-0.4,0.7-0.9c0.5,0,1,0,1.5,0
    		c0-1.5-2.5-0.4-3.2-1c0.7,0,1.9,0.3,2.1-0.7c0.4,0.4,1,0.6,1.6,0.6c-0.5-1.2,1.7-0.9,1.8-2c0.7,1,1.6,0.2,2.3-0.3
    		c1.1-0.7,2.4-0.8,3.7-1c0.8-0.1,3.5-0.2,3.9-0.8c0.2-0.3,2.9-0.5,3.6-0.6c6.8-1.3,13.8-1.8,19.9-5.4c-0.8-0.1-1.2-1.3-1.9-1.7
    		c-1.5-0.8-3.2-1.1-4.9-1.2c-3-0.2-6.8,0.3-9.1,2.3c0.4,0.3,0.9,0.5,1.4,0.7c-1,0.2-2.1-0.3-3.1-0.1c-0.6,0.1-1.2,0.6-1.9,0.5
    		c-0.7-0.1-1.3,0.8-2.1,0.9c-1.5,0.2-3.1,0-4.7,0.3c-2.6,0.5-4.5,0.5-7.1,0.3c-1.3-0.1-2.6-0.2-3.9-0.2c-0.9,0.1-2.3,0.9-0.7,1.4
    		c-0.8,0-1.5,0.8-2.3,0.2c0.3-0.2,0.5-0.3,0.8-0.5c-1-0.2-2.4-0.2-3.2,0.5c0.6-0.1,1.1,0,1.6,0.3c-1.6-0.1-3.5,0-5,0.7
    		c-1,0.5-2.4,0.7-3.5,1.1c-0.5,0.1-3.4,0.5-1.5,1c-0.8,0.2-1.6,0.2-2.3,0.6c0.6,0.1,1.1,0.5,1.4,1c-1.5,0.2-3.4-1.1-4.6,0.3
    		c0.2,0.2,0.5,0.3,0.7,0.5c-1.2,1.8-3.9-1-4.8,0.2c-1.3,1.9,5.2,0.8,2.8,1.4c0.5,0,1,0.1,1.4,0.2c-1,0.2-2.2,0.2-3,0.9
    		c0.9,0.3,1.9-0.1,2.9,0.3c0.8,0.3,1.7,0.5,2.6,0.5c-1.2,0.2-2.3-0.3-3.5-0.1c-0.6,0.1-2.4-0.1-2.8,0.4c-0.6,1,3.2,1.1,3.7,1.2
    		c-1.5,0.2-3.4-0.3-4.6,0.8c0.8,0,1.7,0.2,2.4,0.6c-0.9-0.3-2.4-0.5-3.2,0.1c1.6,0.1,3.2,0.3,4.6,1c-1.2,0.1-2.4-0.4-3.6-0.5
    		c-0.8-0.1-3.4,1.3-1.6,1.4c-1.8,0.5-3.6,1-5.3,1.6c1.4,0.1,2.7,1.6,4.1,0.7c1.3-0.8,3-0.4,4.4-0.4
    		C1613.1,83.9,1612.4,84.2,1611.6,84.2"
          />
          <path
            id="path3020"
            class="countryIsland"
            d="M1610.3,103c0.9-2-1.4-1.8-2.4-2.8c0.5,0.2,1,0.4,1.5,0.6c-0.8-0.7-3.7-1.4-3.9,0.2
    		C1607.3,101.4,1609,101.7,1610.3,103"
          />
          <path
            id="path3022"
            class="countryIsland"
            d="M1606.9,43.5c-0.1,0-0.2,0-0.4,0.1c1.1,0,2.2,0.5,3.3,0.1C1608.8,43.6,1607.9,43.2,1606.9,43.5"
          />
          <path
            id="path3024"
            class="countryIsland"
            d="M1608.7,35.8c-0.2,0-0.4,0-0.6,0.1c1.2,0.2,2.7,0.5,3.8-0.1
    		C1610.9,35.9,1609.8,35.7,1608.7,35.8"
          />
          <path
            id="path3026"
            class="countryIsland"
            d="M1609,40c2.2,1.1,5.4,0.6,7.8,0.6c1.6,0,4.5-0.1,5.1-1.9c-3.1-1.7-7.1,0-10.4-0.2
    		c1.9-0.2-0.4-0.2-0.7-0.1c-0.9,0.1-1.8,0.5-2.4,1.1C1608.5,39.7,1608.8,39.8,1609,40"
          />
          <path
            id="path3028"
            class="countryIsland"
            d="M1608.9,43.1c1.7,0.2,3.4,0.8,5,0C1612.4,42.4,1610.3,41.9,1608.9,43.1"
          />
          <path
            id="path3030"
            class="countryIsland"
            d="M1611,37.4c2,0.8,5.4,1.1,7.1-0.6C1615.7,36.6,1613.2,36.7,1611,37.4"
          />
          <path
            id="path3040"
            class="countryIsland"
            d="M1623.1,38.3c1.6,1.3,4,0.9,5.9,0.8c2.4-0.2,4.8-0.3,7.1-1c-0.7-2-4.6-1.9-6.2-1.4
    		c-1,0.3,0,0.9-1.2,0.9C1626.8,37.6,1625,37.7,1623.1,38.3"
          />
          <path
            id="path3056"
            class="countryIsland"
            d="M1640.4,119.1c0.6,0.4,2.5,0,3-0.4C1642.4,119,1641.4,118.8,1640.4,119.1"
          />
          <path
            id="path3058"
            class="countryIsland"
            d="M1644.4,110c0.8,0.4,1.6,0.6,2.4,1.1c-2.2-1.2,1.6-0.3,2.1-0.1c0.9,0.3,1.4,1.1,2.4,1.2
    		c0.8,0.1,5.3,0.2,3.2-1.3c-1.8-1.2-3.9-1.8-5.9-2.5c-1.2-0.4-2.9-1.7-4.2-1.5c-0.8,0.1-1.3,1.1-2.2,0.7c0.5,0.2,1.1,0.4,1.7,0.6
    		c-0.5,0.1-1.1,0.1-1.6-0.1C1642.9,108.7,1643.5,109.6,1644.4,110"
          />
          <path
            id="path3060"
            class="countryIsland"
            d="M1650.1,116.3c-0.7-1-2-1-3-1.4C1648,115.4,1649.1,115.9,1650.1,116.3"
          />
          <path
            id="path3062"
            class="countryIsland"
            d="M1687.5,116.6c1.3,0.3,2.5,0.9,3.8,1.2C1690.1,117.2,1688.9,116.5,1687.5,116.6"
          />
          <path
            id="path3064"
            class="countryIsland"
            d="M1692,113.7c0.5,0.3,1,0.4,1.6,0.1C1693.6,112.7,1692.5,113.1,1692,113.7"
          />
          <path
            id="path3066"
            class="countryIsland"
            d="M1692.5,85.9c0.5,1,0.9,1.1,0.8,2.3c-0.1,1.3,1-0.1,1.1-0.1c2.7,0.4,5.2,0,7.9-0.7
    		c-1.3-0.9-1.2-0.4-2.6-0.4c-0.7,0-1.5-0.8-0.8-1.4C1696.7,85.3,1694.5,85,1692.5,85.9"
          />
          <path
            id="path3070"
            class="countryIsland"
            d="M1698.8,86.4c0.7,0.6,0.9,0.5,0.8-0.4C1699.3,86.1,1699.1,86.3,1698.8,86.4"
          />
          <path
            id="path3076"
            class="countryIsland"
            d="M1719,134.3c-0.8-0.1-1.7,0.1-2.4,0c-0.9-0.2-1.3-1.1-2.3-0.4c1.6-0.3,2.5,1.3,3.8,1.7
    		C1719.5,136,1720.9,135.1,1719,134.3"
          />
          <path
            id="path3078"
            class="countryIsland"
            d="M1715.7,88.5c1.1,0.8,2.6,0.8,3.8,1.2c-0.5-0.5-1.5-1.8,0-1.7
    		C1718.3,88,1716.7,87.4,1715.7,88.5"
          />
          <path
            id="path3082"
            class="countryIsland"
            d="M1720.3,85.9c0.7-0.6,3.2,0.4,2.9-1.1C1722.2,84.9,1721,84.8,1720.3,85.9"
          />
          <path
            id="path3084"
            class="countryIsland"
            d="M1722.5,134.4c-0.5,0-1,0.1-1.5,0.3c0.9,1,2.6,1.2,3.8,0.9
    		C1724.1,135,1723.3,134.6,1722.5,134.4"
          />
          <path
            id="path3086"
            class="countryIsland"
            d="M1723.9,85.1c1.2,0.5,2.7,0.4,4,0.6C1726.7,85.2,1725.2,84.8,1723.9,85.1"
          />
          <path
            id="path3088"
            class="countryIsland"
            d="M1726.1,87.6c0.8,0.1,1.7,0.5,2.4-0.1C1727.7,87.5,1726.9,87.3,1726.1,87.6"
          />
          <path
            id="path3090"
            class="countryIsland"
            d="M1733.6,93.4c1.7,1.5,6,0.4,7.4-1.1c-1.2-0.6-2.5-0.9-3.8-0.9
    		C1735.7,91.4,1734.8,92.7,1733.6,93.4"
          />
          <path
            id="path3092"
            class="countryIsland"
            d="M1734.4,58.6c0.5,0.2,1,0.3,1.5,0.3C1735.4,58.6,1734.9,58.5,1734.4,58.6"
          />
          <path
            id="path3094"
            class="countryIsland"
            d="M1734.8,77.7c0.7,0.3,1.5,0.6,2.3,0.6c-0.6-0.8-1.5-0.7-2.4-0.8
    		C1735.8,77.7,1735.8,77.8,1734.8,77.7"
          />
          <path
            id="path3096"
            class="countryIsland"
            d="M1740.5,68.9c1,0.4,2.1,0.3,3.1,0.4C1742.6,69,1741.5,69,1740.5,68.9"
          />
          <path
            id="path3098"
            class="countryIsland"
            d="M1740.6,89.5c0.6,1.6,3.6,1.2,5,1.4c0.3-1.1-0.6-1.5-1.4-1.9C1742.4,88,1742,88.4,1740.6,89.5"
          />
          <path
            id="path3100"
            class="countryIsland"
            d="M1745.4,71.7c-0.3,0-0.5,0-0.8,0c1.1,1.4-0.7,0.7-1.2,0.4c-0.3,0.9,0.6,1.2,1.2,0.9
    		c0.5-0.2,1.3,0.8,1.8,0.9c-0.2-0.2-0.3-0.5-0.5-0.7c0.3-0.1,0.5-0.2,0.8-0.3c-0.4-0.5-0.9-0.7-1.6-0.6
    		C1745.3,72,1745.4,71.9,1745.4,71.7"
          />
          <path
            id="path3102"
            class="countryIsland"
            d="M1747,69c0.5-0.2,1.1-0.2,1.6-0.1c-1.4-0.6-3.2-0.7-4.6-0.4C1744.9,68.8,1746,68.5,1747,69"
          />
          <path
            id="path3104"
            class="countryIsland"
            d="M1746.4,85.3c-0.4-0.4-1.1-0.6-1.7-0.4C1745.3,85.1,1745.8,85.2,1746.4,85.3"
          />
          <path
            id="path3108"
            class="countryIsland"
            d="M1754.7,80.8c-0.2,0-0.5,0.1-0.7,0.1c0.5,0.4,1,0.6,1.6,0.6C1755.3,81.2,1755,81,1754.7,80.8"
          />
          <path
            id="path3110"
            class="countryIsland"
            d="M1756.5,81.3c1.2,0,2.6,0.6,3.8,0.1C1759.4,80.8,1757.3,80.4,1756.5,81.3"
          />
          <path
            id="path3116"
            class="countryIsland"
            d="M1762.7,81.7c0.8,0.3,1.7,0.5,2.5,0.4C1764.6,81.4,1763.6,81.8,1762.7,81.7"
          />
          <path
            id="path3118"
            class="countryIsland"
            d="M1764,78.4c-0.3,0.1-0.5,0.1-0.8,0.2c0.4,0.4,1,0.6,1.6,0.6c-1.1-0.3-1.1-0.5,0-0.5
    		C1764.5,78.6,1764.2,78.5,1764,78.4"
          />
          <path
            id="path3120"
            class="countryIsland"
            d="M1765,77.8c-0.2,0.1-0.5,0.1-0.7,0.2C1764.6,78,1764.8,78,1765,77.8"
          />
          <path
            id="path3124"
            class="countryIsland"
            d="M1766.3,76.3c-0.5,0.2-1,0.4-1.5,0.4c0.9,0.2,2.1,0.5,3,0.3
    		C1767.4,76.5,1766.9,76.3,1766.3,76.3"
          />
          <path
            id="path3126"
            class="countryIsland"
            d="M1768.6,78.2c-0.7-0.3-1.5-0.3-2.2-0.2C1766.8,78.5,1768.5,79.3,1768.6,78.2"
          />
          <path
            id="path3128"
            class="countryIsland"
            d="M1767,79c0.5,0.1,1.1,0.1,1.6,0.1C1768,78.9,1767.5,78.9,1767,79"
          />
          <path
            id="path3130"
            class="countryIsland"
            d="M1770.2,61c-0.9-0.3-1.1,0-0.7,0.7C1769.9,61.6,1770.1,61.3,1770.2,61"
          />
          <path
            id="path3132"
            class="countryIsland"
            d="M1770.3,78.8c0.2-0.1,0.5-0.3,0.7-0.4c-0.5-0.4-1.6-0.8-1.6,0.3
    		C1769.7,78.7,1770,78.8,1770.3,78.8"
          />
          <path
            id="path3134"
            class="countryIsland"
            d="M1771.2,80.7c-0.5-0.1-1.1-0.1-1.6-0.1C1770.1,80.8,1770.6,80.9,1771.2,80.7"
          />
          <path
            id="path3138"
            class="countryIsland"
            d="M1773.2,75.2c-1,0.1-2.4-0.2-3.2,0.6c1,0.6,2.1,0.4,3.2,0.5c-2-0.5,0.2-0.5,0.7-0.4
    		C1773.7,75.6,1773.5,75.4,1773.2,75.2"
          />
          <path
            id="path3140"
            class="countryIsland"
            d="M1771.2,60.8c0.7-0.1,1.5,0.1,1.8-0.7c-0.7-0.3-1.6-0.3-2.3,0
    		C1770.8,60.4,1771,60.6,1771.2,60.8"
          />
          <path
            id="path3142"
            class="countryIsland"
            d="M1771.4,76.3c0.5,0.3,1,0.4,1.5,0.4C1772.4,76.4,1771.9,76.3,1771.4,76.3"
          />
          <path
            id="path3148"
            class="countryIsland"
            d="M1775.5,61c-0.3,0-0.6,0-0.9,0c0.6,0.2,1.2,0.3,1.9,0.2C1776.2,61.1,1775.8,61,1775.5,61"
          />
          <path
            id="path3150"
            class="countryIsland"
            d="M1776.4,82.5c-0.5-0.2-1-0.4-1.5-0.5C1775.4,82.3,1775.9,82.4,1776.4,82.5"
          />
          <path
            id="path3264"
            class="countryIsland"
            d="M1723,133.9c1.6-1,4.4-1.6,5,0.8C1726.5,134.9,1723.5,136,1723,133.9"
          />
          <path
            id="path3342"
            class="countryIsland"
            d="M2196.5,104.1c-0.8-0.2-1.5-0.6-2.3-0.8C2194.7,104,2195.6,104.3,2196.5,104.1"
          />
          <path
            id="path3344"
            class="countryIsland"
            d="M2245.5,110c-1.9,1.6,9.6,3.1,9.9,3.2c0.1-1.7-1.9-2.4-3.2-2.5
    		C2250.2,110.5,2247.6,109.4,2245.5,110"
          />
          <path
            id="path3346"
            class="countryIsland"
            d="M2293.1,99.1c-1.5,1-3,2.6-0.9,3.9c0.7,0.4,2,1.2,2.8,1.2c0.8,0,3.2-0.4,3.8-1
    		c1-1,3.9,0.1,5.1,0.3c1.5,0.2,5.8-0.4,6.8-1.5c-1.4-2.2-5.4-2.1-7.7-2.6c-1.6-0.3-3-0.8-4.7-0.8C2297.2,98.5,2294,98.4,2293.1,99.1
    		"
          />
          <path
            id="path3360"
            class="countryIsland"
            d="M2409.4,149.2c0.8,0.4,1.7,0.1,2.4-0.3C2411,148.5,2409.1,147.4,2409.4,149.2"
          />
          <path
            id="path3362"
            class="countryIsland"
            d="M2409.7,149.6c0.5,0.3,1,0.4,1.5,0.5C2410.7,149.8,2410.2,149.6,2409.7,149.6"
          />
          <path
            id="path3364"
            class="countryIsland"
            d="M2318.1,186.3c0.2,0.2,0.5,0.5,0.7,0.7C2318.2,187.1,2318.2,186.9,2318.1,186.3"
          />
          <path
            id="path5504"
            class="countryIsland"
            d="M2222,326.9c-0.3-0.2-0.6-0.4-0.9-0.5C2221.1,327.2,2221.4,327.4,2222,326.9"
          />
          <path
            id="path3798"
            class="countryIsland"
            d="M343.2,139.3C346.7,136.9,344.8,140.4,343.2,139.3"
          />
          <path
            id="xq"
            class="countryGroup country"
            d="M1555.4,306.2c-0.9,0.5-1.4,0.7-2.2-0.2c-0.1,0.5-0.4,0.7-0.7,0.8c-0.3,0.1-0.7,0.2-1.1,0.1
    		c-0.4-0.1-1.4-0.7-2.2-1.5c-0.6-0.6-1.8-2.1-2.5-2.7c0.4,0.9,3.9,4.5,3.7,4.9c-3.5-1-1.3-1.4-2.6-3c-0.1-0.1-3-1.4-2.5-2.1
    		l-1.4,0.8c0.7-0.6,0.6-1,0.5-1.3l-0.1-0.3c0,0.1-0.1,0.1-0.2,0.1c-0.6,0.4-3-2.1-2.8-0.1c-0.6-1.4-1.1-1-1.2-0.5
    		c-0.8-0.5-1.4-1-1.8-1.4l-1,0.6c0,0.7,0.3,1.6,1.2,1.6c-0.3,1.3-3.8,1.8-4.9,2.2c-0.5,0.2-4,2.5-2.5,2.7c1,0.1,1.9-0.3,2.9,0.3
    		c1,0.6,1.7,1.1,2.9,1.3c2.2,0.3,1.9,5.2,0.4,4.9c2,3.3,5.4,1.1,7.3-0.7c1.1-1.1,2.3-1,3.7-1.2c1-0.1,1.6-1.1,2.5-1.5
    		c0.4-0.2,0-0.8,0.7-1.1c0.7-0.3,1.4,0.2,1.9,0.5c0.9,0.5,1.4,0.6,2.4,0.4c0.9-0.1,1.9-0.1,1.9-1.2c0-0.9,0.1-1.4,1-1.6
    		c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1C1557.9,306.1,1556.5,305.6,1555.4,306.2L1555.4,306.2z"
          />
        </g>
        <g id="ht" class="countryGroup">
          <path
            id="Haiti_Hispaniola"
            class="country"
            d="M744,537.3c1.7,0.7,2.3,0.5,4,0.5c-2,0.2,0.9,0.7,1.4,0.8c1.1,0.1,2.2,0.2,3.3,0.4
    		c1.5,0.3,1.2-0.4,2.5-0.8c0.8-0.3,2,0.3,2.1-0.7c0.2-1.7-3.1-2.3-2.6-4.5c0.4-2.2,0.8-3.7-2.1-4.2c-1.1-0.2-3.8-0.1-1.5-1.7
    		c2.6-1.8,4.6-0.6,7.2,0.1c1.7,0.5,4.8-0.2,4.9,2.2c0,0.8-0.7,1.9-0.1,2.8c0.6,1-1,1.7-1.2,2.2c-0.1,0.4,0.6,0.5,0.5,1.3
    		c-0.1,1.1-1.3,1.9-2.4,1.8c1.3,1.1,2.2,3.3,1.2,4.9c-1.5-2.2-4.2-1.9-6.5-1.3c-2.2,0.5-7.2-2.1-8.6,0.4c-1,1.9-2.3-1.3-3.8-1.5
    		C739.6,539.7,742.2,536.1,744,537.3"
          />
          <path
            id="path5514"
            class="countryIsland"
            d="M747.3,542.4c-0.3-0.1-0.5-0.1-0.8-0.2C747.1,541.9,746.9,542.2,747.3,542.4"
          />
          <path
            id="path5516"
            class="countryIsland"
            d="M753.7,536.8c-0.2-0.1-4.4-1.9-2.9-2.2C751.8,534.4,754.5,535.5,753.7,536.8"
          />
          <path
            id="path5518"
            class="countryIsland"
            d="M755.7,525.5c-0.2-0.1-0.5-0.1-0.7-0.2c0.6-0.5,1.3-0.3,1.7,0.2
    		C756.3,525.5,756,525.5,755.7,525.5"
          />
        </g>
        <path
          id="ba"
          class="countryGroup country"
          d="M1416.4,309.2c-0.1-2.3,1.9-0.7,2.8,0.1c1,0.8,1.7,0.1,2.1-0.9c0.4-1,1.8-0.3,2.5-0.2
    	c0.1-0.9,4.1,0.6,5,0.6c0.6,0,1.8,0.6,2.2,0.3c1.2-0.8,0.9,0.2,1.9-0.1c1.3-0.3,2.3,0,3.5,0.5c1,0.4,0.4,1.3,1.6,1.4
    	c0.8,0,1.8-0.8,2.5-0.5c1.1,0.6,0.2,2.1-0.3,2.7c-1.5,2,0.2,2.7,1.7,3.9c2.6,2-0.7,1-1.1,1.5c0,0.1,1.8,2.3,1.8,2.9
    	c0,0.5-3.4,1.1-4,1c-0.1,0.9,1,1.3,0.6,2.2c-0.6-1.8-3.2,0.5-2.4,2c-2.8-0.6,0.8,3.9-1,3.9c-1.1,0-3.1-1.4-3.9-2
    	c-0.4-0.4-0.5-0.8-1.1-0.9c-0.6-0.1-0.7,0.5-1.3-0.3c1.9,0-1.7-3-2-3.4c-1.7-2.1-2.9-2.8-4.5-4.5c-0.8-0.9-1.8-1.4-2.6-2.2
    	c-0.9-1-0.3-2.6-1.2-3.7c-0.7-0.8-1.6-1-2.4-1.6C1416.3,311.6,1416.5,309.9,1416.4,309.2"
        />
        <g id="in" class="countryGroup">
          <path
            id="India_mainland"
            class="country"
            d="M1810.8,492.9c-0.1-1.7,1.8-1.7,3-1.7c1.4,0,0.7-2.2,0.7-3c0.9,0.5,3.3,0.2,4.1,0.3
    		c2,0.2,3.3,1.3,5.6,0.9c0.2,0,0-1.1,0.7-1.2c0.8,0,1.8-1.3,2.9-0.7c1.3,2,2.4,1.4,4.1,0c-2.4-1.7-0.5-2-0.6-2.5
    		c-0.8-2.9-2.8-4.5-3.4-6.1c-0.4-1,0.2-2.8-1.7-2.5c-2.4,0.5-3.3-1.3-3.2-2.9c0.1-1.3,0.6-3.6-0.5-4.2c-1.7-1.1-4.4-0.5-5.2-3
    		c-0.5-1.8,1.1-3.9,2.1-5.3c0.7-1,1-1.8,1.5-2.8c0.4-0.7,1.5-2.3,2.6-2c1.4,0.4,1.3,2.6,2.8,2.7c0.9,0.1,1.6-0.6,2.3-0.9
    		c0.9-0.4,2.1-0.3,3.1-0.5c2.1-0.5,1.9-2.2,3.2-3.6c1.6-1.6,0.7-3.8,3-5.1c2.1-1.2,3-2.7,3.7-5.1c0.5-1.7,0-4,2.1-4.8
    		c0.7-0.2,1.6-0.4,2-1.1c0.6-1-0.8-1.3-0.8-1.8c0-0.6,1.7-2.9,2-3.4c0.7-1.1,2.4-1.7,2.9-2.8c-1.5,0-1.2-1.5-1.2-2.5
    		c0-1.3-1.2-2.2-1.2-3.3c0-1.9,3.7-2.3,4.8-3.5c1.8-2.1-3.2-2.3-4.1-2.6c-0.9-0.3-0.7-1.7-1.7-2.2c-0.7-0.4-1.5-0.4-2.2-0.7
    		c0.3-0.8,0-1.2-0.7-1.6c-0.7-0.4-2.7-1.5-2.2-2.5c0.5-0.9,0.9-1.8-0.1-2.5c-0.6-0.4-1.6-1.3-0.8-2.1c0.3-0.3,2.4-1.7,0.7-1.9
    		c-0.3,0-3,0-2.2-1c1-1.2-0.3-1.1-1.1-1.7c-1-0.7,0.6-2.7,1.3-3.1c1.6-1,3.3-0.2,5,0.1c1.9,0.4,3.8,0.9,5.7,1.3
    		c1.6,0.4,2.6-0.6,4-1.1c1.2-0.4,2.6-0.4,3.8-0.8c0.4-0.1,2.6-2.4,2.4-2.6c-0.5-0.8,1.8-2,2.5-2.5c0.5-0.4,1-0.9,1.7-1.1
    		c0.5-0.2,0.5,0,0.9,0.3c1.4,1,1.5,1.5,2,2.1c0.8,1,0.9,3.2,2.3,4.2c1.7,1.2,4.8,2.7,6.3,3.8c0.5,0.4-0.8,0.9-1,1.2
    		c-0.5,0.6-0.3,0.6,0.2,2.4c0.4,1.5,1.1,2.8,2.2,4.1c1,1.1,2.8,1,3.6,1.8c0.4,0.4,0.1,1,0.3,1.5c0.3,0.5,0.5,0.9,0.8,1.4
    		c1.2,1.7-0.5,2.3-1.2,2.9c-1,0.8-1.7,2.1-2.9,0.2c-0.9-1.5-1.7-1.8-3.2-0.7c0.5,0.6,0.7,1.3,0.9,2c0.2,0.8,1,1.3,1.6,1.8
    		c1.7,1.6,0.8,2.7,1.9,3.8c0.6,0.7-0.2,3.5,1.3,3c0.5-0.2,0.8-0.7,1.3-0.9c0.9-0.3,1.3,0.7,1.6,1.2c0.5,0.8,1.9,2.4,2.8,2.6
    		c1.1,0.2,2-0.5,2.9,0.5c0.5,0.6,1.1,0.6,1.8,0.9c0.7,0.3,0.7,0.9,0.6,1.4c-0.2,0.8,1,0.9,1.7,1.1c2.1,0.6,3.6,1.1,4.9,2.7
    		c-2,0.1-2.2,3.9-3.6,4.8c-0.1,0.1-0.4,1.2-0.6,1.5c-0.6,1.2,0.6,1,0.4,2.1c-0.2,1.1-1.7,2.4-0.8,3.3c0.7,0.7,3.3,2.9,3.3,1.4
    		c1.2,0.8,3,1.4,4,2.1c1.8,1.1,2.3,2,3.7,2.8c0.4,0.3,3,2,3.6,1.8c1.4-0.6,2.5,0.8,3.7,1.4c0.5,0.3,2.2,0.3,2.5,0.6
    		c1,1.2,1.1,1.5,2.8,1.9c0.9,0.2,1.8,0.8,2.7,0.2c1.2-0.9,2.2,0.1,3.5,0.7c-0.8-2.3,4.8,0,5.2,0.2c1.6,0.5,1,2.6,2.5,2.9
    		c1.2,0.3,2.5,0.8,3.2,1.9c0.6,0.9,2-0.3,2.7-0.4c1.8-0.1,0.9,2.5,3.2,1.6c0.7-0.3,3.7,0.8,4.4,1.2c1.7,1,2.1,0,3.7-0.5
    		c0.1,1.1,1.5,2,2.8,1.4c0.4-0.2,2.7,0,3.3,0c1.2,0,1.5-0.3,1.7-1.6c0.3-2.2-0.8-2.7-1.7-4.6c-0.5-1-0.1-2.5,0-3.6
    		c0.1-1.3-0.2-2.4-0.3-3.7c1.3-0.1,2.9-1.2,4.2-0.8c2.6,0.8,0.3,4,1.4,5.4c0.2,0.2,1.2,0.8,0.9,1.2c-0.5,0.7-0.9,0.6-0.4,1.5
    		c1.2,2.1,2.7,2,4.7,2.3c1.3,0.2,2.2,1.4,3.5,1.2c0.6-0.1,1.3-0.1,1.8-0.5c0.5-0.3,1.6-1.6,2.3-1.1c1.8,1.3,4.1,0.9,6.2,0.8
    		c0.6,0,1.6-0.9,2-0.5c0.6,0.7,2.5,0.1,3.3,0c2-0.2-0.3-1.3,0-2.9c0.6-3.4-3-1.4-3.4-3c-0.2-1-0.9-2.2,0.5-1.9
    		c1.2,0.3,2.3-0.1,3.3-0.4c1.4-0.4,2.6,0.2,2.9-2.5c0.2-1.1,2.9-2.2,3.1-3.7c0.2-2.4,3.1-1.1,4.5-2.4c1.1-1.1,1.8-2.4,3-3.3
    		c0.4-0.4,1.1-0.8,1.6-1.1c0.4-0.3,2.6,1.3,3,1.6c-0.5-0.5-0.3-0.6,0.7-0.1c0.7,0.3,1.5,0.9,2.3,0.8c0.8,0,0.7-1,1-1.5
    		c0.5-0.9,2.3-1.7,3.3-2c0.2-0.1,2.4,1.9,2.7,2.1c-1.7,1.5-1.5,1.3-1,1.9c0.6,0.6,1.9-0.6,2.2-0.7c-0.3,1.1,1.6,1.8,1.3,2.6
    		c-0.5,1.3-1.1,1.6-1.4,2.9c-0.2,0.9,1.4-0.2,1.8-0.3c1.3-0.4,2.7,1.4,4.1,1c1.2-0.4,2,1.1,2,2.1c0,0.4,0.6,0.7,0.5,1
    		c-0.1,0.7-1.2,1.5-1.7,1.9c-2.4,2.7,1.2,3.7,1.6,5.7c-1.7,0.7-2.4-2.1-3.4-1.9c-3.5,0.7-4.4,0.6-5.1,1.8c-0.6,1-1,1.8-1.9,2.1
    		c-0.7,0.2-0.4,0.5-0.8,0.5c-0.4,0.4,0,0.7-0.7,1.1c-0.4,0.2-1.6-0.2-2.1,1.1c-0.3,0.8-0.1,1.8,0.1,2.6c0.2,0.6,0.7,1,0.8,1.6
    		c0.2,0.8-0.3,1.5-0.3,2.3c-0.2,2.1-3.1,3.3-2.3,5c0.3,0.6,1.1,1.4,1,2.1c-0.2,3.6-2.2,6.3-2.9,10c-0.7-0.6-2.6-1.1-3.5-1.4
    		c-0.5,0-0.9,0.2-1.3,0.3c-0.6-0.1-0.6-0.6-1.3-0.5c0.7,2,1.1,3.8,1.2,5.9c0.1,0.9,0.2,3.8-1.5,2.6c0.3,2,0.1,3.4,0.9,5.3
    		c0.3,0.7,0.9,2.9-0.6,2c0.2,3.6-1.2,2-2.5,0.4c0.1,0.6-0.2,1.3-0.7,1.6c-1.2-4.9-2.7-9.4-4.3-14.7c-0.2-1.3-1.8,0.7-2.6,0
    		c0.4,0.6,0.2,1.4-0.2,2.1c-0.5,0.9,0,1.8,0,2.7c0,0.9-0.5,1.9-1.6,1c-0.8-0.6-1.1-1.7-1.8-2.3c0.1,0.5,0.1,1-0.1,1.4
    		c-0.7-1.5-1.7-2.9-2-4.4c-0.3-1.6,1.4-3,1-4.4c0.8,0.1,2.1,0.3,1.9-0.9c0.5,0.8,1.2-0.4,1.9,0.2c0.2-1.9,2.9-3.8,1.7-6
    		c0.4,0,2.1,0.1,1.2-0.8c-1-1.1-2.7-1.8-4.1-1.8c-1.2,0-2.2-0.2-3.8-0.1c-1.7,0.1-3.2,0.3-4.9,0.2c-2.4-0.2-7.5,0.3-7.2-2.9
    		c0.1-1.4-0.5-5.9-2.7-5.7c0.3,0.6,0.2,1.2-0.1,1.8c-1-0.6-2.5-0.8-3.2-1.8c-0.6-0.8-0.8-2.3-2.1-2.1c0.5,0.4,0.8,0.9,1,1.5
    		c-0.6-0.2-1.4-0.1-2.3-0.7c-0.4-0.3-0.5-0.9-1-1.3c-0.6-0.5-1.4-0.8-2-1.2l-0.2,1c0.3,0,1.3,0.1,1.6,1c-2.3,0.9-3.7,4.1-1,5.2
    		c1.1,0.4,1.5,1.6,2.4,2.3c0.7,0.5,2-0.3,2.1,0.6c0.2,1.4,2.1,0.9,1.2,2.4c-0.6-0.3-1.6-0.2-2.3-0.2c-0.9,0.1-1.1,0.1-1.2,1
    		c-0.1,0.9-1,2.8-1.7,1c-0.1,1.5-1.8,2.2,0.3,3.9c1.1,0.9,2.8,0.8,3.7,1.6c1.8,1.5,0.9,2.2,1.2,2.9c0.2,0.8-1.9,1.6-0.6,2.8
    		c1.2,1.1,1.1,0.9,1.4,1.3c0.3,0.4-0.2,1.3,0.1,2c0.1,0.3,1,0.3,1.2,0.3c0.8,0.2,0.3,1.5,0.4,1.9c0.1,0.9,0.4,1.5,0.5,2.5
    		c0.2,1.3,0.6,2,1,3.1c0.5,1.2,1,2.7,0.4,3.9c-0.3-0.2-0.6-0.3-0.9-0.5c0.2,0.5,1.9,2.4,1.3,2.9c-0.6,0.5-1.5-0.4-1.5-1
    		c-0.1,0.8-0.7,1.5-1.2,0.4c-0.5-1.1,0.1-2.5,0.1-3.6c-0.7,0.3,0.1,1-0.7,1.7c0.2-0.8-0.3-1.5,0.1-2.2c-1.4,1-0.1,4.4-0.4,5.8
    		c-0.3-1,0.1-2.5-0.9-3.2c0,0.1,0.2,2.7-0.2,2.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.8-1.2-0.1,1.7-0.8-0.5c0.3,0.5,0.3,1,0.1,1.6
    		c-1-0.9-1-2.8-1.1-4c-0.2-1.9-1.7-1.5-2.2-3c0.2,1.5,2.7,1.2,1.6,3.2c-1.3,2.2-2.5,3.1-5,3.8c-1.3,0.4-3.1,1.1-3.4,2.6
    		c-0.2,1,0.8,4.5,1.6,5.2c-0.5,0.4-1.3,0.8-1.5,1.4c-0.4,1,0.6,1-0.6,2c-1.4,1.1-1.9,3.2-3.6,1c0.2,0.8,1.2,1.2,1.9,1.6
    		c-2.1,1.1-4.3,2.1-6.7,2.4c0.7-0.4,0.9-2.2-0.2-1.8c-1.2,0.4-2.3,2.2-1.9,3.4c0.2-0.5,1.3-2,1.7-1.2c-0.7,0.2-1.9,2.1-2.6,2.8
    		c-1.6,1.5-2.4,3.4-3.5,5.3c-1.1,1.9-2.4,3.8-4.5,4.8c-1.8,0.8-2,3.2-3.7,4c1.2,0.7-4.5,3.3-5.1,3.9c-0.6,0.6-1.3,1.4-1.6,2.1
    		c-0.5,1.2,1.1,1.5,0.6,2.9c-1.1,2.8-4.4,2.5-6.8,2.7c-1,0-1.1,0.8-1.3,1.6c-0.2,0.9-1.2,2.5-1.1,3.4c-0.8-0.4-0.8-1.2-1.1-1.9
    		c0.3,0.8-0.2,1.6-0.4,2.3c0.5-2.4-3-1.1-3.6,0.1c-0.9,1.7-2.1,4.4-1.4,6.3c0.6,1.6,1.2,3.3,1.2,4.9c0,2.8,0.6,5,1.4,7.7
    		c-0.2-0.5-1.3-2.7-1.2-0.9c-0.6-1.3-0.6,0.4-0.1,0.7c0.6,0.4,1.2,0.4,1.7,1.1c0.8,1.3,0.1,4.5-0.1,5.9c-0.4,2.4-1.8,4.3-2.5,6.6
    		c-0.6,1.8,0.3,4.4-1,5.8c0.2-0.2,0.5-0.4,0.7-0.6c0.8,0.9,0.8,2.3,0.8,3.5c0,1.9,0.4,3.8,0.3,5.7c-0.5,0-1-0.1-1.5-0.2
    		c1.9,0.6,0,0.3-0.4,0.2c-0.7-0.2-1.8-0.6-2.2,0.3c-0.2,0.5-0.6,1-0.3,1.5c0.4,0.8-0.8,1.6-1.1,2.2c-0.7,1.2-2.1,3.7-0.1,4.6
    		c0.5,0.2,1.2,0.2,1.7,0c0.4-0.1,1.1,1.2,1.3,1.4c-2-2-5.3-0.8-7.4,0.3c-2.8,1.4-1.5,4-2.8,6.1c-0.7,1.1-2,1.8-3.1,2.3
    		c-1.8,0.8-2.8,0-4.1-1.2c-0.6-0.6-5.3-5.5-3.8-5.9c-1.3,0.5-1.8-2.2-2.3-3.3c-0.7-1.6-1.4-3.5-0.9-5.2c0.4,0.6,0.6,1.4,0.6,2.1
    		c0.2-1.7-0.1,1.7,0.9,1.8c-0.4-0.9-0.1-2-0.5-3c-0.4-0.8-1.2-1.2-1.2-2.2c0,1.8-0.6,0.1-0.5-0.3c0.1-0.9-0.4-1.2-0.9-2.2
    		c-0.8-1.8-1.4-3.7-2.1-5.5c-0.3-0.7-4.5-7.5-4.2-7.9c-2.7-3.3-4.5-7.2-5.4-11.3c-0.3-1.5-0.5-2.8-1-4.1c-0.3-0.9-0.7-1.7-1.1-2.6
    		c-0.3-0.7-1.3-2.4-0.1-2c-1.3-0.4-1-2.3-1.7-3.2c-0.4-0.6-2.7-1.8-0.8-2.2c-1.1,0.5-1.7-0.8-2-1.6c-0.2-0.6-0.5-1.3-0.8-1.9
    		c-0.3-0.6-1.3-0.9,0-1.1c-0.6-0.2-1.2-1.1-1.2-1.7c0-0.6-0.7-0.8-1.2-1.5c-1.2-1.7-2-3.7-2.6-5.7c-0.4-1.4-0.8-2.8-0.8-4.2
    		c0-1.5-0.8-2.9-1.1-4.4c-0.2-1.4-1-2.6-1.4-3.9c-0.3-0.9-1.8-3.7-0.4-3.2c-0.3-0.3-2.2-2.9-0.5-1.9c-0.3-1.1-1.6-1.7-1.1-3
    		c0.2,0.2,0.5,0.4,0.7,0.6c0-0.6-0.2-1.1-0.7-1.5c1-0.4,0.5-1.4,0.3-2.2c-0.1,0.6-0.3,1.2-0.7,1.6c-0.8-1.3-0.5-4.1,1.2-2.1
    		c-0.3-0.7-2.9-1.2-2.1-2.1c0.3-0.3-0.9-1.8-1.1-2.2c-0.4-1.2-0.4-2.3-0.2-3.6c0.4-1.9,1.5-3.9,0.8-5.8c-0.1-0.3-0.6-3.6-1.6-2.3
    		c0.3-0.9,0.1-0.9-0.6-0.1c0.9-1.1-0.6-1.4-0.4-2.2c0.2-0.5,0.3-1,0.4-1.6c0.1-0.8,2.7-1.3,3.2-2.2c-1.9,1.4-6.2,0.3-3.3-1.8
    		c-1,0.4-2.2-0.3-1.4-1.4c0.6-1,1.6,0,2.2-0.9c-1,0.3-3.1-0.1-3.6-1.1c-0.1,0.5-1,0.7-1,0.6c-0.1,0.5,0.6,0.5,0.6,1.3
    		c0,1-0.7,1.3-1.5,1.5c0.2,0.2,0.4,0.5,0.6,0.7c-0.6-0.2-1,0.2-1.2,0.7c2,0.6,2.9,2,1.6,3.8c-0.5,0.7-0.2,1.6-1.1,2
    		c-1.1,0.4-2.1,1.2-3.2,1.8c-1.8,1.1-3.8,2.4-6,1.7c-2.2-0.7-4.8-2.8-6.1-4.7c-1.9-2.8-4.8-4.6-7.1-7.1c-0.6-0.7-2.3-2.9-0.3-3.1
    		c0.5-0.1,0.6,0.9,1,1.2c0.8,0.4,2.2,0.1,2.3-0.9c0.8,1.1,4.2-0.9,5-1.7c0.6-0.6,0.5-1.6,0.9-2.3c0.5-0.7,1.7-1.3,0.7-2.2
    		c-0.5,0.5,0,1.7-1,1.7c-1.1-0.1-1.9,0.1-3,0.5c-0.9,0.3-1.2,1.1-2.2,1.1c-1.2-0.1-2.4-0.6-3.5-1.2c-1.1-0.6-2.3-1-3.2-1.7
    		c-0.8-0.6-0.8-2-1.8-2.3c-3.1-1.1,0.5-3.5,1.4-4.2c-1.7-0.2-2.8,3-4.4,2.2C1811.1,493.9,1811.1,493.4,1810.8,492.9"
          />
          <path
            id="path5460"
            class="countryIsland"
            d="M2007.8,593.2c-0.2,0.3-0.3,0.2,0,0.7C2007.8,593.7,2007.8,593.4,2007.8,593.2"
          />
          <path
            id="path5466"
            class="countryIsland"
            d="M2013.7,624.7c0,0.2,0,0.5,0.1,0.7C2013.8,625.1,2013.8,624.9,2013.7,624.7"
          />
          <path
            id="India_Little_Andaman"
            class="countryIsland"
            d="M2004.6,605.3c-0.2-0.3-0.4-0.7-0.6-1c-0.6,0.5-1,1.6-0.8,2.4
    		C2003.6,608.7,2005.3,606.5,2004.6,605.3"
          />
          <path
            id="India_Andaman"
            class="countryIsland"
            d="M2005.2,587c-1.4,1.2,0,4.8,0.1,6.2c-1.8-0.3-2.1,5.6,0.2,5.4c-1.1-0.7,0.1-0.8,0.1-1.6
    		c0-1-0.6-1.9-0.2-2.9c0.2-0.8,0.9-1.4,0.8-2.3c-0.2-1.3,0.4-0.4,0.6-1.7c0.2-1.2-1.2-2.5-0.8-3.5c0.3-0.9,2.1-2.9,0.2-3.3
    		c0.8-0.6,0.6-1.5,0.1-2.2C2004.4,582.6,2005.6,585.1,2005.2,587"
          />
          <path
            id="path5454"
            class="countryIsland"
            d="M2004.5,586.4c-0.1,0-0.2,0-0.4,0c0.1,0.3,0.2,0.5,0.4,0.8
    		C2004.5,587,2004.5,586.7,2004.5,586.4"
          />
          <path
            id="path5456"
            class="countryIsland"
            d="M2007.1,618.6c-0.3,0.2-0.5,0.4-0.5,0.8C2007.4,619.6,2007.6,619.4,2007.1,618.6"
          />
          <path
            id="path5458"
            class="countryIsland"
            d="M2006.8,594.7c0.2,0.3,0.4,0.4,0.7,0.4C2007.3,595,2007,594.8,2006.8,594.7"
          />
          <path
            id="path5462"
            class="countryIsland"
            d="M2012.3,629.3C2010.2,630,2014.2,630.8,2012.3,629.3"
          />
          <path
            id="path5464"
            class="countryIsland"
            d="M2013.3,627.5c-1,0.1-0.3,1.2,0.1,1.5C2013.2,628.6,2013.1,628,2013.3,627.5"
          />
          <path
            id="path5468"
            class="countryIsland"
            d="M2014.9,634.4c-0.6,0.2-0.7,0.9-0.4,1.4C2015,635.6,2015.3,635,2014.9,634.4"
          />
          <path
            id="path5470"
            class="countryIsland"
            d="M2016.2,636.4c-2.7-1.3-0.5,2.8,0,3.7C2017.1,638.3,2016.2,638,2016.2,636.4"
          />
        </g>
        <g id="ca" class="countryGroup">
          <path
            id="Canada_mainland"
            class="country"
            d="M814.5,95.7c-0.9,0.1-1.8,0.4-2.3,0.7c0.8,0.6,2.2,0.6,3.1,0.2c-0.9,0.5-1.9,0.4-2.9,0.5
    		c-0.8,0.1-1.3,0.6-2,0.8c-1.3,0.5-2.9,0.1-4.1,0.9c0.7,0.5,1.5,0.5,2.3,0.4c-0.9,0.2-1.8,1.7-2.6,1.5c-0.9-0.2-0.8-1-1.9-0.8
    		c-1.5,0.3-2.5,0.9-3.6,1.8c-0.8,0.7-3.1,1.6-3.1,2.7c0,1.4,2.2,0.6,2.9,0.4c-0.5,0.3-1,0.4-1.5,0.6c0.3,0.2,0.5,0.3,0.8,0.5
    		c-1.8-0.2-3.8,0.5-5.2,1.6c-0.8,0.6-2.6,2.3-0.6,2.6c-0.2,0.2-0.5,0.5-0.7,0.7c0.5,0.7,1.4,0.6,2.1,0.6c0.5,0,0.7,0.8,1.2,0.9
    		c1.1,0.3,2.4,1.1,3.3-0.1c0.5,0.7,0.7,1.6,1.6,1.7c1.3,0.2,2.3-0.6,3.5-0.6c0,0.6-0.3,1-0.9,1.1c0.4,0,0.7,0,1.1,0
    		c-1.2,0.6-2.4,0.9-3.6,1.4c0.4-1.1,1.9-1.4,2.8-1.7c-1.3-0.2-4.5,0-5.2,1.4c0.3,0.2,0.5,0.3,0.8,0.5c-1.1,0.2-2.2,1.2-3.3,1.2
    		c-0.4,0-2.3,1.2-1.9,1.5c1.4,0.9,3.8-0.4,4.9-0.8c-0.5,0.2-1,0.3-1.5,0.4c0.3-0.5,2.2-2.1,2.7-1.3c0.8,1.2-1.8,2.2-2.4,2.7
    		c0.5,0.2,1,0.3,1.5,0.1c-2.7,0.5-5,1.8-7.5,2.9c-2.4,1.1-4.3,1.2-6.9,1.4c-0.7,0.1-3.9,1.6-4,2.4c-0.1,1,0.9,0.9,0,1.7
    		c-0.5,0.4-0.8,0.9-0.3,1.5c-1.9,0.3-2.2,2.6-4.1,2.7c-0.2,0-0.9-0.1-1.5-0.2c-0.9-0.1-1.8-0.2-1.8,0.4c-0.1,0.6,0.5,1.2,1.1,1.1
    		c-0.5,0.5-1.2,0.9-1.9,1.2c1.7-0.8,0-2.3-0.5-3.3c0.9-0.7,1.5,0.7,2.4,0.1c-0.1,0.3,0.3,0.5,0.7,0.5c0.6,0,1.4,0,1.8-0.1
    		c1-0.2,1.2-0.9,1.7-1.7c-0.7-0.2-1.5-0.2-2.2,0.2c0.7-0.5,1.5-0.8,2.3-1.1c-1.1-1-2.7,0.5-3.8,0.6c0-1,1.1-1.1,1.7-1.5
    		c-0.6,0.1-1.1-0.1-1.5-0.5c1.3-1,2.3-1.2,3.5-2.3c1.3-1.2,2.8-2.9,4.5-3.4c-1.2,0.2-2.7,0.4-3.8,1c-0.7,0.3-1.3,0.9-2.1,0.8
    		c0.8-0.9,2.1-1.1,3-1.9c-1.2,0.2-2.4,0.6-3.6,0.2c1.5-0.2,0.1-1.3-0.5-1.6c-0.4-0.2-1.7-0.5-2.1-0.5c-0.8,0-1.3,1.5-2.3,1.6
    		c-1.1,0.2-2.2-0.3-3.3-0.4c-0.2,0.9,1.1,1.6-0.1,2.2c0.5-1.5-0.9-0.5-0.7,0.3c0.1,0.5,0.4,1,0.3,1.5c1-0.8,3.3-1.7,4.4-0.9
    		c1.5,1,0,1.3,0.4,1.9c-1.8,0.1-4.3,1.5-5-1c-0.8-2.7-3.1-1.2-1.5,0.3c-1.5,0-3.1,1-4.5,0.7c-1.3-0.3-2.4-0.8-3.7-1
    		c-3-0.4-5.7,0.6-8.7,0.7c-1.3,0-3.5-0.4-4.7,0c-1,0.3-1.3-0.4-2.2-0.5c-0.8-0.1-3-1.3-1.9-2.2c0.1,0.1-9.4,1.2-6-1
    		c-0.5-0.1-1,0-1.5,0.1c0.2-0.2,0.5-0.4,0.7-0.6c-1.6,0-1.6,0.4-3-0.7c0.9-0.9,1.2-0.1,1.6-1.4c0.2-0.8,0.9-1.6-0.1-2.1
    		c-1.7-0.9-4.6,0.1-6.2,0.5c-5,1.4-10.1,1-14.6,3.9c0.5,0.2,1,0.2,1.5,0c-0.6,0.8-0.4,0.9,0.4,0.4c-2,1,1.1,0.6,1.5,0.5
    		c0.7-0.1,1.5-0.3,2.2-0.1c-0.3-0.2-0.5-0.4-0.7-0.7c1.3-1.3,2.6,0.4,4-0.1c0.7-0.3,1.7-0.7,2.5-0.6c0.4,0.1,1.4,0.4,1.7-0.1
    		c1-1.7,5.1-1.4,7-1.9c-1.5,0.7-1.8,1.7-3.6,1.8c-1.5,0.1-3.5,0.3-4.7,1.2c-1.3,0.9-2.7,0.5-4.1,0.9c-1.4,0.4-2.9,0.6-4.3,0.8
    		c1.7-0.5,0.1-0.5-0.1-0.2c-0.4,0.6-0.2,0.8,0.6,0.6c-1,0.7-2.9,0.3-3.4,1.6c-0.4,1,0.6,1.4-0.5,2.4c-0.8,0.8,0.2,1.8-0.4,2.2
    		c-0.7,0.4-0.4,1-0.7,1.7c-0.8-1.9-2,1.7-2.5-0.3c0.6-0.1,1.2-0.3,1.5-0.8c-0.4,0.2-1.3-0.1-1.6,0.3c-0.3,0.4-0.6,1.2-1.2,1.4
    		c-1.1,0.3-0.2,2.5,0,3.1c-1-0.3-1-2-1-2.8c-0.2,0.2-0.5,0.4-0.7,0.6c0.6-0.7-0.2-1.1-0.8-1.3c0.6,0.1,1.2-0.3,1.3-0.9
    		c-0.7-0.1-1.9-0.4-1.2-1.3c0.8,0.8,3,1.2,3.8,0.2c1-1.2-0.3-3-1.6-1.8c0.3-0.5,0.7-0.9,1-1.4c-1-0.7-2.2,1.1-2.9,1.4
    		c-0.7-1,1.1-1.7,1.2-2.7c-1-0.1-2-0.2-3,0.2c0.3-0.6-0.2-1.7-1-1.2c0.4-0.2,0.7-0.5,0.9-0.9c-0.7-0.3-3,0.4-3.7,0.6
    		c-1.5,0.3-3.1,0.4-4.5,1c1.9-1.2-1.7,0.2-2.3,0.2c-0.9,0.1-1.3-0.2-2.1,0.3c-0.4,0.2-1.9-0.3-2.4-0.3c-1,0.1-2.1,0.3-3.1,0.4
    		c-2.2,0.3-4.7,0.1-7-0.2c-2-0.2-4.5-1.4-6.5-1.3c0.8-0.6,2.1-0.3,2.9-0.8c1.2-0.8,0.7-1,2.3-1.1c1.5-0.1,2.5-1.1,4.1-0.8
    		c1.1,0.2,2.2-0.1,3-0.9c1.1-1-0.9-3.2-2-3.3c-1.3-0.2-2.4-1.6-3.8-1.1c-0.9,0.3-1.7-0.1-2.6,0.2c0.1,0.2,0.3,0.5,0.4,0.7
    		c-1,0.8-4.7-0.2-5.7-0.4c-1.5-0.3-3.2-0.4-4.5-1.2c-1.7-1.1-2.8-1.6-4.8-1.7c-1.7-0.1-2.8-0.5-4.1-1.6c-1.7-1.3-3.1-1.8-5.2-1.8
    		c-2-0.1-3.8-0.7-5.7-0.1c-1.9,0.5-3.5,2-5.4,2.6c-2,0.7-4.2,1-6.2,0.5c1.1-1.2,3.1-1.8,4.6-2.1c-0.5-0.2-1.1-0.4-1.6-0.4
    		c0.7-0.9,1.7-1.9,2.8-2.3c-1.7,0.7-3.7-1-5.1,0.8c1.6-0.7,2.4-0.3,3.6-0.5c-1.3,0.5-4.4,0.5-5.3,1.7c0.6-0.1,1.1,0,1.6,0.3
    		c-1-0.5-3.1,0-3.8,0.8c2,0.4-2.1,1.1-2.6,1.3c0.8-0.2,1.6-0.4,2.3,0.1c-1.6,0.5-4,1-5-0.8c-1-1.7,0.6-2.6,1.1-4
    		c0.4-1.2-0.1-2.8-1.3-3.3c-1.1-0.4-3.5,1.3-1.8,1.3c-0.2,0.2-0.5,0.4-0.7,0.6c0.5,0.1,1,0.1,1.5,0.3c-1.5,0.4-3.2,0.1-4.6,0.8
    		c-1,0.5-1.9,1.1-2.9,1.5c-1,0.4-4.4,2.3-5.3,1.2c0.7-1.1,2.2-0.8,3.1-1.6c-3.3,1.5-7,1-10.4,2.1c-2.8,0.9-5.4,3.2-8.3,3.7
    		c0.3-0.2,3.4-2.1,1.5-1.3c0.6-0.2,1.1-0.6,1.5-1c-0.5,0.3-1,0.6-1.5,0.8c0.8-0.3,1.3-1.6,2.1-1.7c0.5-0.1,1,0,1.5-0.2
    		c1.4-0.6,3-1,4.5-1.3c2.4-0.5,6-0.4,7.8-2.4c-1.4,0.1-0.6-0.5-0.1-0.9c-1.1,0.5-2.4,1.3-3.6,1.2c-0.6-0.1-3.3,0-1.6-0.5
    		c-1.5,0.5-3.1,0.7-4.6,1.3c-0.9,0.3-2.6,1.7-2.3,0.3c-1.3,0.8-3.1,1.2-4.5,1.5c-0.7,0.2-1.4,0.3-2.1,0.3c-0.2,0-0.7-0.1-0.9-0.2
    		c-1,0.2-2.6,0.6-0.9,0.6c-1.4,0.3-2.5-0.3-3.8,0.4c-1,0.6-1.8,1.2-3,1.4c-2.4,0.5-4.8,0.7-7.2,1.3c-1.2,0.3-2.5,0.6-3.5,1.3
    		c-0.8,0.5-0.6,1.5-1,2.2c-0.9,1.8-1.5-0.5-1.4-1.3c-1.4,0.1-3.4-0.6-4.6,0.4c1.7,0-0.3,0.4,0.3,1.4c-1,0.2-1.8-0.9-2.6-1.3
    		c-1.3-0.7-3-0.1-4.4-0.3c-1-0.1-5.8-1.3-5.2-2.9c-0.9,0.9-1.8-0.4-1.9-1.3c-0.1-0.5-2.1-0.8-2.4-0.8c-1.8-0.3-4.7-0.5-6.6-0.5
    		l-85.8,71.2c2,0.9,2.9-0.2,4.6,0.7c2.4-0.3,4.5-1.7,7-1.1c-0.8,0.6-2.2,1.1-2.7,2c-0.6,0.9,1,1.1,0.9,2.1c-0.1,1.4,0.5,2.7,1,3.9
    		c0.4,1.2-0.6,2.3-1.2,3.2c1.5-0.5,3-0.8,4.5-1.3c1.1-0.4,2.6-0.6,3.6-1.1c1.2-0.6,1.8-1.8,3.2-1.9c1.3-0.1,0.8-1.1,1.7-1.3
    		c1-0.2,1.8,0.1,3.6-0.7c0.6-0.2,1.7-0.8,2.3-0.4c2.1,1.5-0.5,2-0.6,3.3c-0.1,0.9,1.6,1.2,1.2,2.1c-0.4,0.8-0.8,1.4-0.3,2
    		c0.7,0.8,1.4,2,0.7,3.5c-0.1,0.2-0.6,2.7-0.7,3.2c-0.3,1.2-0.4,2.5-0.5,3.7c-0.1,0.9-0.1,1.8-0.8,2.5c-0.5,0.5-1.2,1.2-0.1,1.6
    		c-1.2,0.5-1.3,2.2-1.5,3.3c0.9-0.1,4.4,1.6,4.5,2.5c0.1,0.8,0.8,0.5,0.8,1.1c0,0.6,1.2,0.5,1.5,0.5c-0.8,2.1-3,2.2-4.2,4.5
    		c-0.5,1.1-1,1.9-2,2.5c-0.7,0.4-1.7,0.6-1.6,1.6c0.4-0.3,0.8-0.6,1.2-0.8c-0.4,0.3-0.7,0.7-0.9,1c0.7-0.1,1.5,0,2.2-0.1
    		c-1.4,0.8-2.8,0.3-4.2,1.4c-0.8,0.6-2.1,1.7-0.3,1.8c-1.1,0.3-1.5-0.9-2.6-0.4c-0.8,0.4,0.2,1.9,1.1,1.2c-0.7,0.4-1.4,0.9-1.5,1.7
    		c-0.3-0.8-0.1-1.8-0.4-2.6c-0.6,0.5-2.9,2.6-1.1,2.4c-0.3,0.3-0.6,0.6-0.9,1c0.4,0.4,1.6,0.4,2.8,0.2c-0.8,0.2-1.3,0.6-1.2,1.8
    		c0-1.7-1.3-1.5-2-0.3c-0.8,1.3-0.1,4,0.7,5.1c1.2-0.8,2.1-2.1,3.4-2.7c0.7-0.3,1.8-0.6,2.2-1.4c-0.9,1.5,2.3-0.6,2.8-1
    		c-0.1,0.9-1.6,1.1,0,1.4c-1.3-0.5-4.4,1.1-3.5,2.7c0.3,0.6,2,0.2,2.8,0.4c-1,0-2.1,0-2.8,0.8c-0.2-0.4-0.8-1.7-1.3-1.7
    		c-0.4,0-0.4,0.7-0.5,0.9c-0.4,0.5-1.4,3-0.2,1.8c1.1,1.3-1.5,1.6-1.3,2.8c0.2,1.5,1.9,0.2,2.6,0c-1.2,0.7-1.9,1.9-3.1,2.6
    		c-0.9,0.6-2.2,1.7-2.9,2.4c1.1,0.1,2.1-0.6,3-1.2c-0.2,0.2-0.3,0.5-0.3,0.8c0.3-0.1,0.6-0.2,0.8-0.4c-0.4,0.5-0.7,1-0.7,1.4
    		c1.5-1,4-1.5,6.1-2.4c-0.2,0.4-0.3,0.9-0.1,1.2c0.1,0.2,0.8,0.1,1.3,0c-1.3,0.5-0.3,1.6-1,2.6c-0.2-0.8,0.1-2.3-0.9-2.2
    		c-0.7,0-1.2,0.3-1.7,0.6c-0.4,0.3-2.3,1.1-2.6,1.1c-0.6,0.3-1.1,0.7-1.6,1.1c-0.5,0.5-1.3,0.2-1.8,0.7c-0.9,0.8-2,2.2-1.8,3.5
    		c0.5-0.1,1-0.2,1.3-0.5c1.1-0.8,1.6-2.1,3.2-2.3c-0.5,0.2-1,0.5-1.2,1c1.6-1.1,4.7,1.3,5.7-0.9c-0.3,1.7-3.1,1.1-4.2,1
    		c-1.3-0.1-2.4,0.5-3.5,1.2c-0.8,0.5-1.5,1.1-2.1,1.6c1.2-0.2,2.5,0.1,3.7-0.1c-0.2,0.1-6.5,0.7-4.8,1.8c0.2,0.1,0.4,0.2,0.6,0.3
    		c-2.8,1.4,4.1,2,1.3,1.3c1.9,0.4,3.8,0.6,5.1-1c-0.3,0.6-0.2,0.9,0.1,1c-0.6,0-1.2,0.1-1.4,0.8c0.4-0.2,1.8-0.7,2.2-0.3
    		c0.7,0.8-0.9,1.3-1.3,1.6c2.8-0.6,4.7-0.8,6.7-3.2c-0.8,3-4.9,3.3-7.4,3.5c0.4,0.6,1.2,0.1,1.7,0.2c-0.8,0.2-1.7,0.1-2.2,0.9
    		c0.9-0.1,3.2-0.5,3.2,0.5c0.8-0.6,1.8-2.2,3-2c-1,0.2-1.4,1.1-1.9,1.8c0.9-0.1,1.8,0.6,2.7,0.1c0.7-0.4,1.3-0.9,2-1.3
    		c0.9-0.5,0.9-1,1.4-1.7c0.4-0.5,0.9-0.6,1.3-1c0.3,0.4-2.2,2.9-2.8,3.2c-0.5,0.2-1,0.4-1.4,0.7c-0.2,0.2-0.6,0.8-0.6,1
    		c0.1-0.1,0.3-0.2,0.7-0.6c-1,1.5,1,0.6,1.4,0.3c0.8-0.5,1.8-0.6,2.7-0.7c-0.5,0.2-3.3,0.6-3,1.4c0.2,0.6-1.5,2.5-2.1,2.6
    		c-0.7,0.1,0.5,1.6,0.9,1.7c0.7,0.1,1.5,0,2.1-0.3c0.6-0.3,1.3-1.7,1.2-0.1c0.1-0.6,0.3-1.5,1-1.7c0.7-0.2,1.8-0.7,0.9-1.5
    		c2,0.6-1,1.6-1.1,2.2c-0.1,0.9-1.3,1.4-0.6,2.3c0.2,0.2,0.5,0.2,0.8,0.1c-0.8,0.3-1.5,0.6-2,1.3c0.9-2.2-1.3-1.5-0.8-0.3
    		c0.4,1,1.7,1.5,2.5,0.7c0.9-0.9,2.2-1.2,3.3-1.8c-1.6,1.2-2.4,3.7-0.6,2.9c-1.1,0.4-2.4,0.6-2.3,1.8c0.3-0.1,0.5-0.2,0.8-0.3
    		c-0.6,0.2-1,0.7-1.3,1.2c0.8,0,1.9-0.5,2.4,0.6l185.4-0.2l0.3-3.8c0.6-0.7,2.1,0.5,1.8,1.8c-0.2,1-1.1,2.4-0.9,3.4
    		c0.2,1.2,5.2,0.5,4.4,2c1.7,0.5,2.9-0.9,4.5-0.9c1.2,0,2.3,0.8,3.4,1c-0.4,0.6,0,1,0.6,1c-0.5,0.5,0,1.9,0.5,1.2
    		c0.5-0.7,0.7-0.8,1.5-0.3c0.6,0.4,1,0.9,1.8,1c-0.1,0.3-0.1,0.8,0.3,0.9c1.9,0.7,4.1-1.3,5.9-1.3c-0.8,1.4,1.6,1.2,2.3,1.1
    		c1.6-0.1,2.5,0.9,4.1,0.8c3.7-0.2,4.9-4.8,8.7-4.5c-0.8,0.5-1.2,1.4-2,1.9c2.2-0.5,2.8-2.8,4.5-3.9c1.4-0.9,1.7,0.4,0.5,1.1
    		c-0.8,0.5-1.7,0.9-2,1.8c0.7-0.3,1.1-1.1,1.9-1.2c0.9-0.2,1.8-0.5,2.2-1.3c0.5-0.9-0.2-2.5,1.6-2.2c0.8,0.1,3.3,1.1,3.8,1.7
    		c0.3,0.4,1.4,0.1,1.8,0c0.7-0.1,1.1,0.2,1.8,0.3c1.7,0.3,3.1-0.3,2.8,2.2c-0.1,1.2-1,3.1,0,4.1c1.6,1.6,4.7,0,6.7,0.3
    		c-0.5,1-2.9,2.7-1.9,3.8c0.9,1,1.5,1.3,0.5,2.5c-0.3,0.4-2.1,1.6-1.3,2c0.4,0.2,1.7,0.4,1.6,1.1c-1.2-0.2-2.5,2-1.1,0.8
    		c-0.2,0.8-1.2,1.1-1.4,1.9c0.8,0.2,4.4-0.3,2.3,1.3c1,0.7,2.3,0.3,3.3,0.8c1.2,0.6,2.7,0.8,4,0.8c1.7,0,3.3-0.3,4.9,0.1
    		c0.9,0.2,3.4,0,2.3,1.2c0.5-0.3,1-0.6,1.5-0.7c-0.3,0.2-0.5,0.4-0.8,0.6c1.7-0.4,0.2,0.5-0.2,0.7c1.2-0.6,2.6,0.5,3.6-0.5
    		c-0.9,1.2,0.7,0.3,1.5,0.6c0.5,0.1,0.9,0.5,1,1c0.1,0.7-0.2,2.5,1,1.7c0,0.6-0.8,2,0.1,2.3c0.5,0.2,1.1-0.2,1.3-0.5
    		c0.3,0.7-0.8,0.9-0.6,1.4c0.3,0.6,0.4,1-0.5,0.9c0.4,0.9,1.1,1.6,1.1,2.6c0.3-0.1,0.5-0.3,0.8-0.4c-0.7,1.7-2.5-0.6-2.9,0.6
    		c-0.4,1.1,0,2.4-1.5,2.7c-2,0.4-2.9-3.1-4.9-1.1c0.2-0.5,0.5-1,0.8-1.5c-0.5-0.2-1.1-0.1-1.5,0.2c0.5-0.3,0.9-0.8,1.2-1.3
    		c-1.7,0.5-0.9-1.2-1.1-1.8c-0.3-0.9-1.7-1-2.4-1.1c2.2,2.2,0.8,5.4-1.3,7.1c-2.1,1.6-3.8,3.7-4.7,6.2c-1.2,3.2-3.1,4.5-6.3,5.6
    		c-1.9,0.7-2.2,4-4.2,4.1c2.9,2.6-1.3,1.7-3,1.9c-1.1,0.1-2.6,2-1.1,2.7c1,0.5,2.3-0.7,2.9,0.5c1.1-1.4,3-2.4,4.7-2.6
    		c1.6-0.2,2.7-1.6,3.9-2.4c3.3-2.1,7.1,0.5,10.7-0.1c-0.8-0.1-1.6-0.2-2.3-0.4c1.7-1.5,3.7-1.9,5.8-2.2c1.8-0.3,3.8,0.4,5.5-0.4
    		c-1.2-1,0.1-2,0.3-3.1c-1.2,0.2-6.5,1.5-3.8-1.1c1.7-1.6,3.9-3.1,6.1-3.7c2.9-0.8,5.9-0.9,8.9-1.4c0.7-0.1,4.4,0.2,3.9,1.3
    		c0.7-0.3,1.5-0.5,2.3-0.6c-1.8,0,0.2-1.2,0.7-1.5c-0.5,0.2-1,0.4-1.5,0.5c0.5-1.3-2.8-0.6-3.3-0.5c0.9-0.3,1.7-0.6,2.6-0.7
    		c0.5-0.1,3-0.2,1.1,0.6c1.1,0.2,1.9-0.7,2.9-0.9c0.9-0.2,1.9-0.3,2.8-0.7c1.9-0.9,3.8-1.6,5.5-2.7c1.8-1.1,5.7-2.9,7.8-3.3
    		l21.3,0.2c1.8,0,0.9,0.1,1.9-1.5c0.8-1.3,2.2-0.8,3.4-1.5c0.1,0.2,0.2,0.5,0.3,0.7c0.2-0.1,1-0.6,1-0.8c0.1-0.7,0.5-0.1,0.8-0.5
    		c0.7-1,0.9-1.5,2-2.2c1.8-1.1,2.4-1.9,3.2-3.7c0.5-1.2,1.6-1.5,2.5-2.4c1.2-1.1,1.8-2.7,3-3.8c1.1-1,2.2-1.9,3.4-2.9
    		c0.4-0.3,2.9-2.9,3.3-2.1c0.3,0.6,0,1.3,0.5,1.9c1,1.2,4.3-1.6,5.7-0.6c2.9,1.9,0.1,5.9-0.8,8.4c-0.5,1.3-2.8,4.5-1.2,5.5
    		c0.8,0.5,1.4,0.5,0.8,1.3c-0.5,0.8-1.2,2.1-0.3,3c0.8-0.5,1.7-1.2,1.6,0.1c1.2-0.8,0.8,0.5,1.7,0.7c1,0.2,1.8-1.1,2.7-0.6
    		c0.5,0.3,1.4-0.7,2-0.8c0.8-0.1,0.9-0.4,0.3-1c0.7-0.2,1.3-0.7,1.8-1.1c-0.3,0.6-0.6,1.1-1.1,1.5c1.3,1.6,4.7-0.9,5.9-1.5
    		c1.1-0.5,3.2-0.7,4-1.4c1.5-1.4,1.7-1.6,1.8-3.4c0.1,0.8,0.4,1.6,0.7,2.3c0.4-0.4,0.9-0.6,1.5-0.6c-1.6,0.7-2.8,1.9-4.3,2.7
    		c-0.4,0.2-1.1,0.5-1.3,0.9c-0.3,0.7,0.3,1.1,0.9,0.6c1.1-1,3-0.6,4.3-0.5c1.7,0.1,3.4-0.1,5.1,0.3c-1.5,1-7-0.1-6.2,3.1
    		c-0.6,0-0.2-1.8-1.4-0.9c0.4-0.5,0.4-1,0.2-1.5c-3.3,1.9-8,1.8-10.5,4.9c0.5-0.1,1-0.3,1.5-0.4c-0.8,0.4-2.1,1.8-2.4,0.5
    		c-1.2,0.8-2.6,1.4-3.6,2.5c0.9-0.5,1.8-1.7,3-1.5c-1.7,1.3-6,4.8-3.9,7.5c0.4-0.3,0.8-0.6,1.1-1c0.2,0.3,0.2,2.1,0.5,2.2
    		c0.6,0.2,1.4-0.3,1.5,0.7c0.3-1.1,1.5-1,1.5-2.3c0.2,0.1,0.5,0.3,0.7,0.4c0.2-0.3,0.3-0.5,0.4-0.8c0.4,2.1,2.7-0.8,3.3-1.3
    		c0.9-0.8,3.9-2.1,3.9-3.3c0.3,0.1,0.5,0.2,0.8,0.3c0.1-0.9,2-3.4,1.8-1.5c0.7-0.1,0.5-0.3,0.5-0.8c0-0.6,1.1-0.9,1.5-1
    		c-1.5,1.4,2.9,3.3,2-0.1c0.8,1.6,3.1-0.1,4.2-0.1c1.9,0,4-1.1,5.6-1.9c1.7-0.8,4-1.8,5.8-2c1.1-0.1,3.5,0,3.8-1.4
    		c-0.8-0.3-1.7,0.3-2.3-0.6c1.7,0.1,1.2-2.2,0-2.3c-0.6,0-1,1-1.7,0.4c-0.5-0.5-0.6-1.3-0.9-1.9c-1.5,1.2-4,2.1-5.9,2
    		c0.2-0.4,0.6-0.7,1.1-0.8c-1-0.6-2.1-0.4-3.2-0.4c-0.9,0-1.3-0.7-2.1-0.8c-1.6-0.3-2.3-0.2-3.6-1.5c0.8-0.2,1.7-0.2,2.3-0.8
    		c-1.2-0.5-2.5-0.7-3.8-0.8c-1.7-0.2-0.2-1.3-1.6-0.9c1.5-0.6,0.2-3.7,0.6-5c0.1-0.3,1.4-1.4,0-1.4c-1,0-1.8,0.4-2.8,0.1
    		c3.2-1.1,4.2-3.8,6.8-5.5c-1.3,0.6-0.4-0.5-1.4-0.7c-0.6-0.1-1.2,0.1-1.7,0.2c-1.3,0.3-2.5,0.8-3.6,1.5c-0.1-2.3-0.4-2.3-2.5-3
    		c-0.8-0.3-1.3-0.6-2.1-0.5c-0.8,0.1-1.4,0.6-2.2,0.3c1.3-0.2,2.2-1,3.5-1c1,0,2.2-0.4,3-0.3c1.7,0.2,3,1.9,4.7,1
    		c2.4-1.2,4.4-2.5,7-3.3c0.7-0.2,1.6-1.3,1.4-2.1c-0.2-0.4-0.5-0.7-1-0.8c1.1-0.2-1.2-0.6,0.5-0.5c0.4,0.1,0.9,0.1,1.3,0.3
    		c0.9-2.1-3.9-3.5-5.2-3.7c-2.3-0.3-4.7,0-7,0.4c-2.4,0.5-4.6,1.4-7,2.2c-2.3,0.8-4.6,0.9-6.9,1.8c-4.8,2-10.5,4.7-14.4,8.3
    		c-2.1,1.9-4.5,3.7-6.9,5.2c-2.3,1.5-5.1,2.2-7.7,3.1c1.5-0.9,3-1.7,4.4-2.7c1.2-0.8,2.5-2.7,3.8-3.2c2.9-1.2,5.3-3,7.1-5.6
    		c-0.3-0.1-0.5-0.3-0.7-0.5c0.3,0.2,0.6,0.4,0.7,0.4c0.8,0.1,2-1,2.6-1.4c2-1.4,3.3-3.5,5.6-4.5c1.1-0.5,4.1-1.5,4.2-3
    		c0.2,0.5,0.1,0.9-0.3,1.2c0.5-0.3,1-0.5,1.5-0.4c-1-1.4,3.2-1.6,4.1-1.7c1.5-0.1,2.7-0.5,3.6-1.7c1.3-1.6,5-6.4,7.6-5.1
    		c-0.8-1.7,1.8-0.5,2.3-0.7c1.7-0.7,3.3-0.6,5.1-0.8c2.8-0.3,6.2,0.9,9,0.2c1.7-0.4,3,1,4.6,0.4c1.5-0.5,2.8-0.6,4.4-0.5
    		c1.5,0.1,3.1,0.4,4.6,0.5c0.6,0,0.6,0.8,1.1,0.8c0.4-0.1,0.8-0.2,1.2-0.4c-0.4,0.3-0.8,0.5-1.3,0.7c2.5-0.2,4.4-1,7-0.9
    		c1,0,1.8-0.3,2.8-0.2c0.8,0.1,1.5,0.2,2.2-0.1c0.9-0.4,1.3-1.4,2.2-1.6c0.5-0.1,1.2,0,1.5-0.5c1.1-1.5,3.1-1.9,4.5-3
    		c-0.1,1.3,0.5,0.5,0.7-0.1c0.5-1.1-0.1-0.8,1-1.2c0.7-0.2,1.5-0.8,2.1-1.1c0.5-0.3-0.3-0.6,0-0.8c1.1-0.8,2.7,0,3.9-0.3
    		c1.4-0.3,2.6-1.2,4-1.5c1.4-0.3,2.7,0.7,4.1,0.2c1.5-0.5,2.7-1.7,4.1-2.3c1.5-0.7,3-1.2,4.3-2.2c0.5-0.4,1.1-0.9,1.8-1
    		c-0.3-0.5-0.2-1,0.2-1.4c-0.8-0.3-1.5-0.8-2.2-1.2c1,0.4,2.1,0.2,3,0.6c0.4-1.7-3.7-1.7-4.6-1.9c1.1,0.1,3.3,0.7,3-0.5
    		c0.7,0.1,1.6,0.9,1.8-0.3c-0.7,0.1-1.6,0-1.7-0.9c2.3,0.1,2.7-1.1,0.5-2c1.1,0,2.4,0.1,3-1.1c0.4-0.8,0.1-2.1-1-1.7
    		c0.4-1-1.2-1.6,0.3-1.7c-1.1,0.1-2.7-0.1-3.6-0.8c0.6,0.2,1.1-0.1,1.3-0.7c-0.9,0.9-2.1-0.1-3.1,0.5c-0.9,0.5-1.5,1.8-2.7,1.7
    		c0.6-1-0.4-0.8-1.1-0.8c1.2-0.8,2.5-1.1,3.7-1.8c-0.9-0.6-0.3-2.4-1.1-2.8c-0.6-0.3-1.5-0.1-2.1-0.2c-1-0.2-3.8-1.1-3.9,0.6
    		c1-0.1,2-0.3,2.9,0.1c-0.9,0.4-2,0.2-2.9,0.4c-1.1,0.2-2.1,0.6-3.1,0.9c-1.1,0.4-2.8,2.2-3.8,2.1c-0.9-0.1-1.6,0.7-2.4,1
    		c-0.8,0.3-2.1,0-2.7,0.8c-0.8,1-3,2-4.2,1.6c0.5-0.1,1-0.3,1.5-0.4c-2-0.2,0.9-1.1,1.4-1.1c-0.3-1-1.5-1.4-2.4-1.7
    		c-0.9-0.3-2.2-0.2-1.4-1.4c0.3,0.5,3.1,2.2,3.5,2.1c1.5-0.4,2.1-2.1,4-2c2.8,0.2,7.6-1.5,9.8-3.2c-0.9,0.2-8,2.6-8.2,1.3
    		c2.9,0.5,5.5-1,8.3-1.5c1.1-0.2,1.9-1.1,3-1.2c1.3-0.2,2.6,0.1,3.7-0.7c-0.5,0-0.9,0-1.4,0.1c0.9-0.4,2.1,0.1,2.5-1.1
    		c-0.7-0.8-1.9-0.4-2.7-1c-0.5-0.4-2.8-0.7-3.4-0.7c-0.9-0.1-2.4-1.2-3.1-0.9c1.5-0.2,0-0.9,0.8-1.7c-1.1,0-1.9,0.9-2.9,1
    		c0.8-0.4,1.6-1,2.1-1.7c-2.3-0.2-4.2,4-6.5,3.6c1-0.5,1.7-1.5,2.7-1.9c0.7-0.3,2-0.8,1.6-1.8c-0.7,0.6-2.2,1.3-2.9,0.5
    		c0.8,0.2,1.6-0.4,1.3-1.3c-1.4,0.9-2.7,1.7-4.1,2.4c0.6-0.7,1.6-0.9,2-1.8c-1.6-0.4-3.5,1.2-4.6,2c0.7-1,2-1.5,3-2.1
    		c-0.5,0.1-1,0.4-1.5,0.6c0.7-0.9,3-1.2,3.1-2.5c-0.5,0.1-1,0.3-1.5,0.6c0.8-0.3,0.9-1,0.7-1.8c-0.1-0.3,1-0.8,1-1.2
    		c-0.9-0.2-1.4,0.9-2.1,1.2c0.4-0.4,0.7-0.9,0.7-1.5c-0.5,0.5-1.2,0.9-1.9,0.7c0.7,0,1.1-1,0.5-0.9c-0.5,0.1-1.6,0-1.9-0.3
    		c0.2-0.2,0.5-0.4,0.7-0.6c-0.5,0.1-2.6-0.3-0.7-0.8c-1.8,0.8-0.1-0.9,0.2-1.3c-1.2,0.4-3,0.4-3.9-0.6c0.2,0,3.6,0.4,1.5,0
    		c2.2,0.2-1.3-1.1-1.5-1.3c0.9-0.6,2.4,0.2,3-0.9c-1-0.3-2-0.1-3-0.3c0.6,0,1.2,0,1.7-0.1c-1-0.2-2.3-0.7-2.8-1.7c1,0,2,0,3,0.1
    		c-0.8,0.1-1.6-0.2-2.2,0.6c0.6,0.3,4.4,1.6,3.5-0.1c-0.3,0.1-0.5,0.3-0.7,0.5c0.5-0.9,3.1-2.4,4.2-2.1c0.8,0.2,0.2-1.3,0-1.5
    		c-0.6-0.7-1.7-0.2-2.3-0.8c2.8-1.2-1.9-1.4-2.3-1.9c0.7,0.4,3.4-0.6,3.8-1.2c0.3-0.6-0.5-0.8-0.3-1.3c0.2-0.5,0.7-0.9,0.4-1.4
    		c-0.7,0.5-1.7,0-2.1,0.9c0.8-1.2-0.5-0.9-1.2-0.8c0.7-0.3,1.5-0.3,2.3-0.5c0.1-0.8-0.1-1-0.8-0.5c0.2-0.2,0.3-0.5,0.5-0.7
    		c-0.9,0-2.1-0.1-2.9,0.4c-0.7,0.4-1.9,1.3-2.8,1c0.8-0.6,1.8-0.9,2.7-1.1c0.4-0.1,3.2-0.9,1.3-1.1c1.4,0.2,1.3-0.8,2-1.7
    		c-1.2,0.1-2.6,0.1-3.6,0.8c2.2-1.2-2,0.2-2.3,0.2c0.5-0.1,2.3-1.1,0.6-0.9c0.9-0.2,4.5-0.3,4.5-1.7c0-0.6,0.9-1.1-0.1-1.4
    		c-0.6-0.2-1.2,0.3-1.8,0.1c0.7,0,2.2-1.6,0.5-0.7c0.4-0.4,0.9-0.8,1.5-1c-1.4-0.1-3.9-0.3-5,0.8c-0.1-0.3-0.1-0.5-0.1-0.8
    		c1.8-0.1,3.2-0.1,4.3-1.7c-0.4,0.3-1,0.5-1.5,0.5c0.6-0.1,0.9-0.4,0.9-1c-0.6,0-1.1,0.2-1.5,0.6c0.3-0.8-0.2-1-0.9-1.1
    		c0.8-0.1,1.7-0.1,2.2-0.9c-0.5-0.4-1-0.4-1.5,0c0.7-0.9,0.6-1-0.3-0.5c0.2-0.2,0.4-0.5,0.6-0.7c-1.5,0.2-0.1-2.5,0.6-2.6
    		c-1-0.1-1.6,0.8-2.5,0.8c0.4-0.6,0.6-1,1.2-1.4c-0.9,0.3-1.8,0.6-2.7,0.6c0.6-0.2,4.2-1.7,2.3-1.3c1.8-0.8-0.4-0.8-0.8-0.6
    		c0.5-0.2,1-0.3,1.5-0.4c-1.6-1.3-3.1,1.1-4.1,1.9c-0.5,0.4-2.4,1.7-1.5,2.2c-1.2-0.2-2.7-0.7-3.1,1c-0.2,1,1.4,1.2,1.6,2.1
    		c-0.8-0.6-1.7-0.5-2.6-0.8c0.4,0.6,0.2,1.8-0.7,1.1c0.2,0.9-0.9,1.4-1.5,0.7c-1,1.3,0.8,1.3,1.5,1.8c-0.6-0.2-1.5,0-2.1,0
    		c-0.4,0-3.4,1.3-1.4,1.5c-0.5,0.2-1.4-0.2-1.8,0.2c-0.7,0.7,0.5,0.9,0.3,1.5c-0.2,0.9-1.6,2.1-2.4,2.4c0.5-1,2.9-3.7,0.1-4
    		c-1.1-0.1-1.9,1.6-2.5,2.2c-0.6,0.7-1.2,0.3-2,0.5c-1,0.3-2,0.8-2.9,1.2c-2.1,0.8-3.2,1.6-4.6,3.3c0.5-1.4,2.4-2.6,1.8-4.2
    		c-1,1-1.8,2.5-3.3,2.7c0.9-0.7,2.5-1.4,2.7-2.6c0.4-2.1-2,0.1-2.4,0.6c-0.3,0.4-0.7,0.7-1,1c-0.9,0.9-1.7,1.6-3.1,2
    		c-1.9,0.5-3.8,1-5.6,1.7c2.4-1.6,6.6-1.7,8.8-3.7c0.7-0.7,1.3-1.6,1.4-2.8c0.3-2.8-1-2.6-3.5-2.5c-2.2,0.1-3.8,2.2-5.9,2.3
    		c0.2-0.2,0.5-0.6,0.7-0.8c-0.8,0.3-1.4-0.4-2.1-0.5c1.2,0.1,3.4-0.3,3.2-2c1,0.3,0.4,1.2,0.6,1.9c0.7-0.4,3.8-2.5,2.1-2.9
    		c0.6-0.5,1.3-0.8,2.1-0.5c0.3-1.4-1.6-0.8-2.3-1c0.6-0.2,0.5-0.6,0.6-1.1c0.1-0.5,0.9-0.6,1.2-0.8c0.3-0.2,0.9-2.1,0.8-2.5
    		c-0.1-0.7-2.5-0.6-3-0.6c-1.6,0-2.8-0.3-4.4-0.6c1.2-0.2,2.5,0.4,3.7,0.4c0.7,0,3.9,0.2,3.4-0.3c2.7,0.3,2.1-4.3,4.4-5.3
    		c0.7-0.3,1.7-0.3,2.1-1.1c0.4-0.8-0.2-2.2-1.1-1c-0.7,0.9-2,1.6-3.3,0.9c2.5-1.7-2.1-1.3-2.3-1.4c-0.7-0.4-1.5-0.6-2.3-0.7
    		c-1-0.2-1.7,0.6-1.7-0.6c-0.9,0.7-1.4-0.5-1.4-1.2c0.5,0.1,1.1,0,1.5-0.3c-0.5,0-1,0-1.5-0.2c0.8-0.5,1.6-0.7,2.5-1
    		c-0.5-1.7-1.8-0.7-3-0.4c0.6-0.3,1-1.2,0-1.2c0.2-0.2,0.3-0.5,0.5-0.7c-1.8-0.2-2-0.3-3.6,0.2c0.8-0.4,2.2-1.1,2.1-2.2
    		c-1.5,0.7-1.6-1.2-2.7-1.6c-1.7-0.6-2.3-1.1-4.1-0.4c-0.8,0.3-5.9,1-4.9,2.2c-2.1-2-4.4-1.6-6.9-0.5c0.5-0.5,1.1-0.6,1.6-1
    		c-2.3,0-4.5-1.3-6.7-1.9c-2.3-0.6-3.6,0.2-5.6,1c-1.9,0.7-3,1.3-3.9,3.1c-0.7,1.4,0,3,1.5,3.6c-0.9-0.4-2.6,1.8-3.1,2.5
    		c-1.1,1.5-3.7,1.4-4.4,3.3c0.5-0.1,0.9-0.2,1.4-0.2c-1.7,0.8,1.2,0.1,1.6,0.1c-0.7,0.6-1.6,0.9-2,1.7c0.5,0.2,0.9,0.3,1.4,0.4
    		c-0.6,0.3-1.8,0.4-2.2,1c-0.2,0.4,0.5,1.1,0.4,1.7c-0.2,0.5-0.4,0.9-0.9,1.2c0.6-0.2,1.1-0.1,1.5,0.3c-0.7,0.3-1.2,1.7-1.9,1.8
    		c-0.7,0.1-1.5,0.2-1.4,1.1c-0.5-0.2-0.9-0.4-1.4-0.5c0.1,0.9-3,2.5-1.3,2.5c-2.4,0.5-3.8,2.7-6,3.4c-2,0.7-1.4,1.3-2.6,2.7
    		c0.7-0.1,1.6,1.1,1.9,1.6c0.4,0.8,1.2,1,1.8,1.4c1.5,1.1,2,3.3,1.9,5c-0.2,4.7-3.2,9.4-6.8,12.4c-3.9,3.3-8.9,5.8-13.6,7.7
    		c-2.3,0.9-4.9,1.9-7.3,2.4c-1.6,0.4-3.9,0.3-5,1.7c1,0.3,0.5,1.1,0.2,1.7c-0.5,1.1,0.8,1.5-0.3,1.8c0.5,0.2,1,0.4,1.5,0.6
    		c-1.2,0.2-0.7,0.6,0,1c-0.7,0.3-1.4,1.4-0.2,1.6c-0.9-0.3-1.2,0.7-2,0.8c1.2,0.1,0.6,0.7,0,1c2-0.4-0.8,0.8-0.6,1.5
    		c0.3,1-0.9,2.4-1.4,3.2c0.2,0.1,0.5,0.2,0.7,0.3c-0.7,0.4-0.4,0.8,0.3,0.9c-0.2,0.6-0.7,0.9-1.3,0.8c0.2,0.6,0.4,1.3-0.1,1.8
    		c0.8-0.1,0.8,0.7,0.6,1.2c-0.3,0.7-1.2,1.1-0.3,1.3c-1.4,0.2-2.3,1.8-3.5,2.3c-0.5,0.2-0.9,0.1-0.7,0.7c0.1,0.5-1.3,1.1-1.6,1.3
    		c0.9,0.9-0.3,1.9,0.9,1.9c-0.9,0-2.8,1.7-2.3,2.7c-0.6-0.2-0.1-1.5-0.1-2c0.1-1.3-1.7-0.8-0.7-1.9c-1.8-0.2-3.5,1.8-4.4,3
    		c-1.2,1.7,0.8,2.7,0.1,4.5c0.1-2.2-1.9-5.2-4.3-4.8c-1.9,0.3-3.5,2.5-5.4,2.3c2.4-1.5,6.5-2.4,5.6-5.9c-0.4-1.7-1.7-2-2.8-3
    		c-1.2-1.1-1.7-1.2-3.3-0.6c0.7-0.7,1.7-0.7,2.5-1.1c1.2-0.6,0.2-2.3-0.2-3c-0.5-1-0.9-1.5-1.5-2.3c0.8-1.2,2-2,2.8-3.1
    		c0.8-1.1,1-2.3,1.9-3.4c1.1-1.4,0-2.8,0.7-4.1c0.8-1.5,2.4-2.6,3.5-3.8c0.8-0.9,0.9-2.2,1.7-3c-0.8,0.4-1.1-0.4-1.8-0.6
    		c-0.6-0.2-2,0.1-2.5,0.5c0.3-0.2,0.5-0.4,0.7-0.7c-0.7-0.5-2.1-0.1-2.9-0.3c-0.7-0.1-2-1.1-1.3,0.3c-1.3-0.3-1.1-1.1-2.7-0.7
    		c-0.9,0.2-2.5,0.2-3.5,0.3c-1.3,0.1-2.3-0.5-3.6,0.1c-1.1,0.5-2,1.4-3,2c0.9-1.2,2.7-1.7,3.4-3.1c-1.1-0.2-1.4-1.2-2-1.9
    		c-0.8-1-2.3-1.1-3.4-1.4c-1.3-0.4-5.4-2.6-6.7-1.8c1.6-2.1-0.9-3.8-2.2-5.1c-0.8-0.8-1-1.5-2.1-1.8c-1-0.3-2.1-0.4-3.1-0.7
    		c-3.3-0.9-5.4-2.8-9.1-2.2c-3.1,0.5-5.9,2-9,2.3c0.8-0.2,1.6-0.6,2.3-1c-1.8-0.1-3.2,1.3-5,1.2c2.6-0.7,5.1-2.4,5.7-5.2
    		c0.6-2.6,1.1-6,2.4-8.4c1-1.8-0.8-0.7-1.7-1.2c-0.8-0.5-2.1-0.3-3-0.2c-0.6,0.1-1.2,0.3-1.6,0.6c0.1-0.1,0.2-0.3,0.3-0.4
    		c-1,0.6-1.2-1.3-2.1-0.9c0.3-0.3,0.5-0.7,0.7-1.1c-0.4-0.2-0.8-0.3-1.3-0.4c0.4-0.2,1.3-0.1,1.6-0.5c0.5-0.7,1-1.2,1.7-1.6
    		c1.1-0.6,1.1-2.4,1.9-3.2c0.6-0.6,5.7-5,4.7-6c0.7,0.3,0.9,0.1,0.6-0.4c2.3,0.8,4.9-3.5,7-4c0.3-0.1,1.6-1.4,1.6-1.5
    		c-0.3-1,0.9-0.3,1.4-0.5c-1.1-2.4,5.6-2.2,6-3.9c-0.6-0.1-0.8-0.4-0.7-1c0.9,0.1,2.4,0.9,2.3-0.7c1.4,1,1.7-0.8,2.6-1.1
    		c0.9-0.3,1.9-0.8,0.4-1.2c0.8,0,2,0,2,1c0.3-0.2,0.6-0.4,0.8-0.7c-0.3-0.2-0.5-0.3-0.8-0.4c0.9-0.1,2.7-2,3.1-1.6
    		c0.8,0.6,1.4-0.7,2.2,0c0.4,0.4,1.3-0.2,1.6-0.4c-0.6-0.4-3.7-1.5-1-1.8c1.6-0.2,2.8,0,4.4,0.2c1.1,0.1,2-0.4,3-0.8
    		c0.9-0.3,1.8,0,2.7-0.6c0.6-0.4,1.3-0.9,1.6-1.6c0.5-1-1-1.5,0.4-1.5c-0.7-0.7-1.7-0.3-2.5-0.8c-0.4-0.3-0.7-0.9-1-1.3
    		c-0.5-0.6-2-0.2-3.4,0.3c0.2-0.1,0.5-0.3,0.7-0.5c-1.7-0.7-4.3-0.9-5.6-2.1c-0.1,1.9-2.8,0.5-2-0.2c0.8-0.7,2.2,0.3,1.4-1.5
    		c0.6,3.2,6.9,2.9,9.2,3.5c0.8,0.2,6.4,2.3,6,0.3c0.8,0.1,1.5,0.6,2.3,0.5c0.7-0.1,1.1-0.7,1.7-1c0.8-0.4,0.8-2.6,2.3-1.3
    		c0.3-0.5,0.3-0.9,0-1.4c0.4-0.3,0.9-0.2,1.3-0.4c-0.1,0.1-0.2,0.3-0.3,0.4c0.5,0.3,1,0.7,1,1.3c0.1-1.4,1.8-0.3,2.4-0.1
    		c-0.1-0.5-0.1-0.9,0-1.4c0.6,3.3,7.4-0.3,8.8-1.2c1.7-1.1,3.7-2.9,5.6-3.3c1.5-0.4,3.6-1.5,4.5-2.7c1.2-1.6-2.8-1.4-3.4-1.4
    		c-2.2,0.1-4.3-0.2-6.5-0.4c-0.4,0-1.4-1.4-1.5-1.7c-0.6-1.3-1.5-1.8-2.9-2.1c-1.2-0.2-2.7-0.8-3.9-0.6c-1.1,0.2-0.2,0.9-2,0.2
    		c1.2-1.5,4.5,0.1,6-0.1c-1.7,0.3,0.7,0.5,1.2,0.5c0.5,0,1.5,0.1,1.9-0.2c-0.3-0.3-0.6-0.4-1-0.6c2.6-0.1,4.5,2.1,7.2,2.3
    		c-0.7,0-1.5,0.1-2.2,0c1.6,0.8,2.2,2.4,4.1,2.3c2-0.1,3.5-0.1,5.2-1.2c1.7-1.1,3.7-1.3,5.3-2.6c1.4-1.1,3.4-1.3,4.8-2.3
    		c-0.6-1.3-2.9-0.6-3.2-2c0.6,0,0.9-0.3,0.8-0.9c1.2,0.4,4.5,0.4,5.7,0c2.3-0.8,1.8,0.1,1.3,1.9c1.6-0.2,2.9,0.6,4.4,0.8
    		c-0.3-0.6-0.3-1.6,0.7-1.5c0,1.2,0.1,0.4,1,0.8c0.5,0.2,1.1,0.6,1.6,0.5c1.4-0.3,0.8-2.3,0.1-2.9c0.2-0.2,0.5-0.3,0.7-0.4
    		c-1.1-0.1-0.8-0.5-0.3-1c-0.5-0.1-1-0.2-1.5-0.3c2.5,0.3-1.7-0.7,0.8-0.6c-1.3-0.9-2.4,0.5-3.7,0.3c1-0.9,2.4-1.1,3.7-0.9
    		c-1.9-0.4,1.3-0.1,1.6,0c-0.4,0-0.8,0-1.2,0c0.6,0.1,1.1,0.5,1.2,1.1c-1.2-0.2-1,1.1,0,0.8c0.7-0.2,1.5-0.6,2-1.1
    		c-0.2,0.3-1.9,2.1-1.9,2c0.3,0.7,1.9,1,0.7,1.9c2.8,0.1,6.4-1.5,8.9-2.6c1-0.5,1.6-0.3,2.6-1c1.1-0.8,1.8-1.3,3.2-1.3
    		c1.6,0,5.8-2.4,4.6-4.5c-0.9-1.6-2.7-2.7-0.1-3.8c-0.5,0.1-1,0.1-1.5-0.1c0.6-0.3,1.1-0.7,0-1c1.3-0.2,0.6-0.6,0-0.9
    		c0.3-0.2,2.8-0.9,3-0.7c1,0.7,0.6,0.3,1.9-0.1c1.7-0.5,4.1-0.2,4.5-2.4c-1.3-0.1-2.4-0.5-3.7-0.2c1.4-0.4,5.4-0.5,5.7-2.3
    		c-0.9,0.1-1.7-0.5-2.5-0.6c-0.7-0.1-1.3,0.3-2,0.3c1.8-0.5-1-1.4,0.9-1.2c-1.5-0.6-3.1-0.7-4.6-1c1-0.1,2.5,0.4,3.4,0
    		c0.5-0.2,0.9-0.6,1.4-0.7c-3.1-0.7-6.1-0.5-9.1-1.5c-1.3-0.5-2.8,0.2-4.1,0.4c-0.9,0.1-1.7-0.3-2.5-0.3c0.5,0.2-1.7,0.7-1,1.9
    		c-0.6,0-2.2,1.4-0.6,0.7c-0.6,0.1-0.9,0.5-0.6,1.1c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0.9,0.8,1.3,1.5,1.1c-1.3,0.5,0.5,0.6,1.4,1.1
    		c-0.3,0-3.5-0.2-1.4,0.5c-0.4,0.2-1.5-0.3-1.7,0.2c-0.2,0.4-0.2,0.6-0.8,0.4c0.4,0.5,0.8,0.6,1.4,0.4c-1.9,1.9-4.8-0.8-6.5,2
    		c-1.7,2.7-4.9,3.9-7.7,5.1c-0.8,0.4-4.1,4-4.6,3.6c-1.3-0.9-2.1,0.6-3.2,1c-0.7,0.2-2.3,1.1-2.8,0.6c2.1-1.2,0-2.7-0.4-4.2
    		c-0.5-1.9,2.1-4.1,3.6-4.7c-0.2,0.2-0.3,0.5-0.5,0.7c2.3,0.5,3.3-1.4,4.1-3.1c0.7-1.3-1.5-3.2-2.5-3.9c-1.5-1.1-3.6,0.9-4.9,1.4
    		c-0.9,0.3-1.3,1.1-1.8,1.8c-0.7,0.8-1.7,0.6-2.4,1.2c0.6,0.9-0.7,1.3-1.5,1.7c-0.7,0.4-1.7,1.1-2.5,1.1c0.4-0.6,0.5-1.1-0.5-0.8
    		c1-0.1,1-0.2,0-0.5c0.4-0.3,0.8-0.7,1.2-1.1c0.5-0.5,2.6-1.9,1-1.7c2.1-0.5,0.2-3-0.7-3.6c0.5,0,1-0.1,1.5-0.3
    		c-1.3,1.1,1.4,0.1,1.7-0.1c0.8-0.6,2.1-0.9,3.1-0.7c-1.4-1.1-4.4-0.5-6-0.6c0.8-0.1,1.5-0.4,2.2-0.7c-1.5-1.1-3,1.2-4.5,0.8
    		c-0.7-0.2-0.8-0.8-1.5-1.1c-0.8-0.3-1.5,0.1-2.3,0c0.5-0.2,1-0.3,1.5-0.3c-0.5-0.1-1-0.1-1.5,0c2.2-0.7,4.3-1.8,6.6-2.1
    		c-0.6-0.8-1.6-0.7-2.4-0.3c1.7-1.6,4-0.6,6-0.8c-0.4-0.5-0.3-1,0.2-1.4c-0.8-0.3-1.4,0.5-2.2,0.3c0.8-0.2,1.3-1.3,0.1-1.3
    		c1.2-0.5,0.3-1.5-0.7-1.4c0.1-0.2,0.3-0.5,0.4-0.7c-0.4-0.1-0.7-0.2-1.1-0.3c1.3-0.1,2.5-1.7,3-2.7c0.7-1.4-0.4-2.3-1.5-2.9
    		c0.3-0.2,0.4-0.5,0.7-0.7c-1.4-0.4-2.5-0.3-3.8,0.3c0.5-0.4,1.1-0.9-0.2-0.7c0.5-0.4,1-0.6,1.5-0.9
    		C816.3,95.8,815.4,95.7,814.5,95.7z M592.5,112c0.2-0.1,0.6-0.2,0.7-0.2C592.3,111.8,592.3,111.9,592.5,112z M638.3,131.9
    		c1-0.2,2.1,0.6,3,0.2c-2,0.5,1,0.4,1.5,0.7c-1.8,1.1-4,0.9-5.9,1.6c-1.8,0.7-4.3,1.6-6.1,1.9c0.6,0.6,3.1,0.8,3.8,0.4
    		c-2,0.4,0.3,0.5,0.7,0.4c0.9-0.2,1.8-0.1,2.7,0c1.9,0.1,3.8-0.4,5.6-0.9c-0.1-0.1,1.4-0.9,1.7-1c1.1-0.4,2.3-0.6,3.5-0.8
    		c-1,0.2-1,0.4,0,0.4c-1.1,0.2-2.1,0.8-3,1.2c0.8,0.4,1.5-0.1,2.3,0c-1.4,0.3-1.9,1.3-3,1.9c-0.5,0.3-4.1,0.7-2.2,1.2
    		c-1.2,0.3-2.6,1.2-3.7,1.3c0.7,0.7,1.5,0.4,2.3,0.1c-0.5,0.2-1,0.4-1.5,0.5c2,0.3-1.5,0.9-2.1,1c-0.8,0.1-1.9,0.4-2.6,0.2
    		c-0.8-0.2-1.3-1.2-2.1-1.2c-0.8,0-1.5,0.1-2.3,0c-0.4,0-2.7,0.3-0.8,0.5c-1,0.5-2.1,0.7-3.1,0.9c-0.8,0.6,0.5,1.2,1,1.1
    		c0,1-1.3,1-2,1c-1.3,0.1-2.6,0.8-3.8,1.2c-1.7,0.6-3.3,1.1-5,1.6c-1,0.3-3.1,1.5-4.1,1.1c-0.9-0.3,1-1.2,1.2-1.2
    		c1.3-0.2,2.3-0.7,3.5-1.1c2.3-0.7,5.4-1.2,6.8-3.4c-4.5-0.8-7.6,1.7-11.3,3.7c-2.5,1.4-7.7,0.8-10.1-0.5c1.3-0.7,2.9-0.5,4.3-0.9
    		c1.5-0.4,0.9-0.8,1.7-1.6c0.7-0.7,2-0.8,2.9-0.9c1.4-0.2,2.6-0.8,3.9-1.4c-0.3-0.9-1.2-0.9-1.9-1.3c3-0.7,6.3,1.2,9.1-0.6
    		c-0.8-0.4-1.2-1.3-2-1.6c-0.9-0.4-2,0-2.9,0c-2.4,0-3.4,0.2-5.3,1.5c0.3-1.6-4.5-0.2-5.2-0.8c-0.5-0.4-2.2-0.2-2.7,0.2
    		c-0.4,0.4-0.8,0.9-1.3,1.1c-0.7,0.3-0.9-0.7-1.5,0.3c-1.4-1.2,0.9-2.6,2-2.5c1.7,0.1,4,0.1,5.6-0.3c3.4-0.9,6.6-1.3,9.9-1.6
    		c1.2-0.1,2.4-0.7,3.7-0.8c1.9-0.1,3.7-0.8,5.5-1.3c2.2-0.6,4.6-0.8,6.9-1C636.6,132.3,637.5,132.1,638.3,131.9L638.3,131.9z
    		 M662.6,163.3c2.6-0.1,5.6,0.2,7.7,1.1c-0.5,0.2-1,0.4-1.5,0.6c0.5-0.2,1-0.3,1.5-0.3c-1.1,0.7-2.5,1.1-3.7,1.3
    		c0.5-0.2,1.1-0.5,1.5-0.8c-5.4-2.3-12.4-0.8-17,2.5c2.2-1.1,4.8-2.2,7.3-2.3c1.9-0.1,4.4-1.6,6.2-0.5c-1-0.1-2.8-0.6-3.5,0.3
    		c1.2,0,2.6-0.1,3.7,0.3c-2.3,1-4.8,0.7-7.2,1c0.7,0.4,1.5,0,2.3,0c-0.8,0.1-1.6,0.3-2.3,0.7c1.7-0.5,3.4-0.6,5.1-0.8
    		c-1.4,0.7-2.9,0.7-4.3,1.2c0.5-0.1,1-0.1,1.5,0c-1.5,0.4-3-0.2-4.4,0c-0.6,0.1-4.2,1.3-2.4,0.9c-1.5,0.6-3,0.9-4.4,1.7
    		c0.5-0.1,1-0.2,1.5-0.2c-3,1.5-6.2,3-9.4,4.2c-2.6,1-4.9,0.6-7.5,1.1c-1.6,0.3-2.2,0.9-3.1,2.1c-0.9,1.2-2.8,0.7-4,0.8
    		c-2.9,0.3-7.2,2.4-10.1,1.2c-2,0.2-3.3-1.2-5.1-1.3c-0.7,0-1.7,0.2-2.3-0.2c-0.8-0.5-0.3-1.7-1.4-1.9c1-0.1,1.5,1.3,2.5,1.1
    		c0.9-0.2,1.8-0.1,2.6-0.5c1-0.6,1-1,2.3-0.6c1.3,0.3,2.3,0.5,3.5,0c-0.1-0.2-0.2-0.6-0.3-0.8c0.8-0.3,1.8,0,2.5-0.6
    		c0.7-0.6,1.1-1.1,1.9-1.5c1.6-0.9,2.9-0.3,4.6-0.7c1-0.3,3.4-0.5,2.4-1.8c-0.5-0.7,0.5-2.2-0.9-2c0.8-0.3,1.3-1.1,1.4-1.9
    		c-0.8,0.3-2-0.5-2.2-1.3c-0.2-0.6,0.3-0.8,0.8-0.8c0.9,0.1,0.3,0.6,0.8,0.9c0.9,0.5,2,0.6,2.9,1.3c1.7,1.2,2,0.4,3.8,0.6
    		c-0.2,0.2-0.3,0.5-0.5,0.7c1.3,0.2,0.9,2.3,2,2.2c1.3,0,2.4,0.2,3.7,0c3.3-0.6,6-0.9,9-2.5c3.2-1.7,6.4-3.6,10-4.3
    		C660.9,163.3,661.8,163.3,662.6,163.3L662.6,163.3z M489.4,221.1c-0.4,0.5-0.8,1.7,0.3,1.5c-0.6-0.1-1.4,0.2-2.2,0.6
    		C488.1,222.6,488.7,221.8,489.4,221.1L489.4,221.1z M676,235.4c0.9-0.1,2.1-0.1,2.9,0.3c0.6,0.3,1.7,1.5,2.5,0.8
    		c-0.2,0.6-0.7,6.2-0.9,6.1c-0.3,0-2.5,7.1-2.4,7.9c0.1,0.8,0.4,2.2-0.3,2.5c-0.8,0.4,0.4,3.4,0.2,4c-0.4,1.5-4,3.4-3.1,5.3
    		c-0.6,0.1-1.1-0.6-1.5-0.4c-0.6,0.2-0.8,1-1.1,1.5c-0.6,0.9-2.2,1.4-2.1-0.1c0.1-1.6,2.1-3.9,3.2-4.8c0.9-0.7,2.3-1.1,2.8-2.2
    		c-1.2-0.1-1.8,1.1-2.9,1.3c0.6-1.1,4.4-2.6,3.5-4.1c-0.7-1.2-2.9,1.9-3.7,1.5c0.4-1.7,2.8-2.4,1.8-4.4c-0.4,0.3-1,0.4-1.5,0.4
    		c0.5-0.2,0.9-0.7,1-1.2c-1.3-0.5-1.9,1.4-2.8,1.9c-1.4,0.8-0.7-2.8-0.9-3.2c-0.3-0.3-0.4-0.6-0.5-0.9c0.2-0.1,0.3-0.4,0.3-0.6
    		c-0.3-0.8-0.9-1.4-0.9-2.3c-0.1-2,2.6-1.4,3.6-2.4c-1.4-0.2-2.9,0-3.9-1.1C670.9,239.7,673.7,235.6,676,235.4L676,235.4z M484,239
    		c0.4,0.2,0.5,0.6-0.1,1.5C483.6,239.9,483.6,239.4,484,239z M487.1,243.8c0.1,1.1-0.8,1.6-1.7,2
    		C486.1,245.3,486.7,244.7,487.1,243.8z M478,258c-1.3,0.7-2.9,0.9-4.3,0.9c0,0-0.4,0-0.7-0.1C474.7,258.9,476.8,258.3,478,258z
    		 M834.2,280.6c1.7-0.2,3.7,0.9,5.1,1.6c-0.4-0.1-0.9-0.2-1.3-0.4c-1-0.5-2.5-0.6-3.5,0c0.2-0.3,0.3-0.5,0.5-0.8
    		c-0.5-0.2-1-0.4-1.5-0.3C833.7,280.6,833.9,280.6,834.2,280.6z"
          />
          <path
            id="path3776"
            class="countryIsland"
            d="M480.3,228.2c-0.6-0.1-1.1,0.1-1.6,0.6C479.3,228.7,479.8,228.5,480.3,228.2"
          />
          <path
            id="path4128"
            class="countryIsland"
            d="M475.8,229.4c-1-0.3-1.7,0.5-2.3,1.2C474.3,230.3,475.1,229.9,475.8,229.4"
          />
          <path
            id="Canada_Vancouver_Island"
            class="countryIsland"
            d="M466.9,267.6c-0.2,0.2-0.4,0.5-0.6,0.7c0.7,0.7,1.7,0.5,2.1-0.4
    		c-0.7,1.4,0.5,0.1,1.3,0.6c-0.3,0.5-0.5,1-0.6,1.5c0.9-0.3,1.8,0.4,3,0.2c-1.9,1-3.2,0.4-4.7,2.4c1.3-0.1,2.5,0.1,3.7-0.4
    		c-0.2,0.3-0.4,0.5-0.6,0.8c0.6,0,1.1-0.2,1.4-0.6c-0.4,0.5-0.9,0.9-1.4,1.2c0.5,0.2,1,0.1,1.4-0.3c-0.2,0.6,0,1,0.6,0.9
    		c-0.6,0.5-1.4,1.1-2.3,0.9c1.5,1.8,1.5,1.1,3.5,0.7c-2.1,0.8,1.2,0.4,1.7,0.3c1.1-0.2,2.1-1.1,2.6-2.1c-0.3,1.9-2.7,2.2-4,3.1
    		c-0.9,0.6-1.1,0.7-0.3,1.5c0.4,0.4,2,1.6,2.5,1.3c0.5-0.3,3.2,2,4.6,1.7c0.7-0.2,1.6-0.4,2.2-0.6c0.7-0.3,0.6-1.5,0.9-2.1
    		c-0.6,0.2-1.1,0.5-1.4,0.9c0.6-1,1.5-2.6,0.6-3.7c1.9-0.6-0.1-2.2-0.9-2.7c-1.3-0.8-3.4-0.9-2.2-2.9c0.5-0.8-0.1-1.7-0.2-2.5
    		c-0.1-0.9,0.6-2.2,0.4-2.9c-0.2-0.7-4.4-1.4-4.9-1.5c-1.5,0-3.7-0.6-5-1.5c-1.7-1.2-2.7-2-4.9-1.5c-1,0.3-3.3,1.9-1.5,2.9
    		c0.6,0.3,1.3,0.2,1.9,0.1c0.5-0.1,2.8-0.9,1.1-1.1c0.8-0.1,1.5,0.3,2.2,0.2c-0.8,0-1.6,0.6-1.7,1.5c0.6-2.4-5.1,1.2-2.5,0.6
    		c-0.3,0.8-1.2,1.2-1.9,1.8c0.8,0.3,3.1-1.1,3,0.6c0.6-0.5,1.5-0.4,2.2-0.7C467.8,266.9,467.3,267.2,466.9,267.6"
          />
          <path
            id="path4806"
            class="countryIsland"
            d="M473.4,245.5c1.7,0,2.3-2.1,3.8-2.5c-0.8,1-2,1.5-2.7,2.6c1.4,0.8,4.5-2.8,4.3-4.1
    		c-0.4-2.8-4.2,0.5-5,1.5c0.7,0.5,2.6-0.2,2.2-1.3c0.2,0.2,0.4,0.5,0.5,0.8C475.4,242.5,472.4,243.9,473.4,245.5"
          />
          <path
            id="path4808"
            class="countryIsland"
            d="M477.8,244.4c-1.2,0.2-2.5,2.2-2.7,3.4C476.4,247.2,477.2,245.7,477.8,244.4"
          />
          <path
            id="path4810"
            class="countryIsland"
            d="M478.5,249.7c1.4-0.6,3.4-0.6,4.2-2.2c-2.8,0-5.5,1.8-7.4,3.7
    		C476.6,251.4,477.3,250.2,478.5,249.7"
          />
          <path
            id="path4812"
            class="countryIsland"
            d="M479.1,238.4c-0.9,0.4-1.8,0.9-2.4,1.7C477.9,239.6,478.6,239.6,479.1,238.4"
          />
          <path
            id="path4814"
            class="countryIsland"
            d="M479.3,244.2c-0.3,0.1-0.7,0.2-1,0.3c-0.6,0.2-0.8,1.1-1.2,1.5
    		C477.9,245.5,478.6,244.9,479.3,244.2"
          />
          <path
            id="path4816"
            class="countryIsland"
            d="M479.6,264.4c0.3-0.1,0.5-0.3,0.8-0.4c-0.5,0.1-1,0.3-1.5,0.4
    		C479.1,264.4,479.4,264.4,479.6,264.4"
          />
          <path
            id="path4818"
            class="countryIsland"
            d="M480.1,271.1c-0.1,0.3-0.1,0.5,0.1,0.8C480.2,271.6,480.1,271.3,480.1,271.1"
          />
          <path
            id="path4820"
            class="countryIsland"
            d="M480.2,267.6c0.6-0.3,1.6-1.8,1.3-2.4C480.1,265.4,480.7,266.7,480.2,267.6"
          />
          <path
            id="path4822"
            class="countryIsland"
            d="M481.9,263.8c-0.5,0.1-1,0.3-1.5,0.6C480.9,264.7,481.7,264.5,481.9,263.8"
          />
          <path
            id="path4824"
            class="countryIsland"
            d="M482.4,264c-0.4,0.5-0.8,0.7-1.4,0.8c1,0,1.2,1.1,0.4,1.6C482.2,266.1,483.2,264.8,482.4,264"
          />
          <path
            id="path4826"
            class="countryIsland"
            d="M482.8,266c-0.6,0.2-0.9,0.7-0.8,1.3C482.5,267,482.8,266.5,482.8,266"
          />
          <path
            id="path4828"
            class="countryIsland"
            d="M484.2,271.9c-0.1-1.3-0.7-1.8-1.7-2.5C481.9,270.4,483.4,271.3,484.2,271.9"
          />
          <path
            id="path4830"
            class="countryIsland"
            d="M482.8,271.7c-0.2,0.7,0.1,0.7,0.7,0.2C483.3,271.9,483,271.8,482.8,271.7"
          />
          <path
            id="path4832"
            class="countryIsland"
            d="M483.9,265.2c-0.6,0.2-0.9,0.9-0.5,1.4C483.8,266.2,484,265.7,483.9,265.2"
          />
          <path
            id="path4834"
            class="countryIsland"
            d="M484.4,276.6c-0.6,0.4-0.5,1.2-0.5,1.8C485,278.3,485,277.3,484.4,276.6"
          />
          <path
            id="path4836"
            class="countryIsland"
            d="M485.2,265.2c-0.4,0.1-0.6,0.3-0.9,0.6C484.9,266,485.2,265.8,485.2,265.2"
          />
          <path
            id="path4838"
            class="countryIsland"
            d="M484.7,276.1c0.2,0.5,0.6,0.9,1.2,0.9C485.5,276.7,485.1,276.4,484.7,276.1"
          />
          <path
            id="path4842"
            class="countryIsland"
            d="M486.5,269.5c-0.7,0-1.3,0.4-1.5,1C485.4,270.2,485.9,269.8,486.5,269.5"
          />
          <path
            id="path4848"
            class="countryIsland"
            d="M489,272.6c-0.2,0.1-0.5,0.4-0.7,0.6C488.5,273,488.7,272.8,489,272.6"
          />
          <path
            id="path4850"
            class="countryIsland"
            d="M489.5,271.8c-0.4-0.1-0.5,0-0.8,0.4C489,272,489.2,271.9,489.5,271.8"
          />
          <path
            id="path5230"
            class="countryIsland"
            d="M889.6,192.9c-1-0.7-1.2,1-1.8,1.4C888.7,194.6,889.4,193.7,889.6,192.9"
          />
          <path
            id="path5010"
            class="countryIsland"
            d="M758.7,171.6c0.4-0.1,0.7-0.2,1-0.5C759.2,170.8,758.7,170.9,758.7,171.6"
          />
          <path
            id="path5042"
            class="countryIsland"
            d="M786.9,241.2c-1.3-0.4-2.8-0.5-4.1-0.2c-1.5,0.3-4,1.5-1.4,2.4c1.5,0.5,3.9,1.9,5.6,1.5
    		C788.5,244.4,787.3,242,786.9,241.2"
          />
          <path
            id="path5048"
            class="countryIsland"
            d="M793.6,250.2c-0.8-0.1-2.9,0.4-2.9,1.5C791.8,251.2,792.9,251.3,793.6,250.2"
          />
          <path
            id="path5052"
            class="countryIsland"
            d="M794.8,241.3c-0.4,0.1-0.6,0.4-0.7,0.7C794.3,241.8,794.5,241.6,794.8,241.3"
          />
          <path
            id="path5054"
            class="countryIsland"
            d="M794.3,240.5c0.7,0.1,0.8-0.2,0.4-0.9C794.2,239.7,794.3,240.1,794.3,240.5"
          />
          <path
            id="path5074"
            class="countryIsland"
            d="M806.9,218.9c1.1,0.8,2.9-1.2,3.8-1.7c-0.4,1.2-1.7,1.6-2.6,2.4c0.6-0.4,1.4-0.4,2.1-0.6
    		c1.1-0.4,2.2-1.6,3.1-2.4c0.1,1-0.9,1.6-1.5,2.2c1.3-1,2.2-2.4,3.4-3.5c0.2,1.5-2.2,2.7-3,3.6c1.1-1,2.1-2.2,3.4-3
    		c0.9-0.6,0.2-1.5,0.1-2.3c0-0.2-4.3,3-4.7,3.3c1-0.8,2.1-1.7,2.7-2.9C811.5,216,808.6,216.9,806.9,218.9"
          />
          <path
            id="path5090"
            class="countryIsland"
            d="M812.5,211.2c-0.5,0.1-0.7,0.1-0.7,0.7C812.1,211.6,812.3,211.4,812.5,211.2"
          />
          <path
            id="path5092"
            class="countryIsland"
            d="M813.9,217.6c-0.5,0.8-1.3,1.4-1.9,2.2C812.8,219.2,813.5,218.5,813.9,217.6"
          />
          <path
            id="path5094"
            class="countryIsland"
            d="M813.3,210.5c-0.4,0.1-0.7,0.2-0.9,0.5C813.1,211.4,813.2,211.3,813.3,210.5"
          />
          <path
            id="path5098"
            class="countryIsland"
            d="M817.8,214.8c-0.9-0.5-2.6,1.8-3,2.4C816.2,216.9,817.2,216.1,817.8,214.8"
          />
          <path
            id="path5602"
            class="countryIsland"
            d="M875.9,285.1c-0.3,0.6-0.7,1-1.2,1.4C873.9,285.6,875.3,285.3,875.9,285.1"
          />
          <path
            id="path5606"
            class="countryIsland"
            d="M876.2,284.3c-0.1,0.3-0.3,0.5-0.4,0.8C875.7,284.6,875.9,284.6,876.2,284.3"
          />
          <path
            id="path5598"
            class="countryIsland"
            d="M850.5,311.5c0,0.6-0.4,1-0.9,1.2C849.5,312.1,849.9,311.6,850.5,311.5"
          />
          <path
            id="path5600"
            class="countryIsland"
            d="M852.9,315.3c-0.3,0.2-0.5,0.4-0.8,0.6C852.3,315.6,852.5,315.4,852.9,315.3"
          />
          <path
            id="path5126"
            class="countryIsland"
            d="M826.5,187.8c0.2-0.3,0.4-0.6,0.5-0.9c-0.6,0.1-1.1,0.5-1.3,1.1
    		C825.9,187.9,826.2,187.8,826.5,187.8"
          />
          <path
            id="path5150"
            class="countryIsland"
            d="M842.5,179.6c-1-0.2-2.1,0.1-3,0.6C840.5,180.4,841.6,180.2,842.5,179.6"
          />
          <path
            id="path5104"
            class="countryIsland"
            d="M816.4,205.8c0.4-0.1,0.6-0.2,0.6-0.7C816.7,205.3,816.6,205.5,816.4,205.8"
          />
          <path
            id="path5078"
            class="countryIsland"
            d="M808.3,216.5c1.7-0.5,3.3-1.3,4.5-2.6C812,214.5,808,215.4,808.3,216.5"
          />
          <path
            id="path5172"
            class="countryIsland"
            d="M852.9,43.8c0.8,0.1,2-0.3,2.7,0.1c0.8,0.5,0,0.8,1.4,0.9c0.9,0,3-0.8,3.3-1.7
    		c0.2-0.5-2.1-0.7-2.4-0.7C856.2,42.1,854.3,42.5,852.9,43.8"
          />
          <path
            id="path5194"
            class="countryIsland"
            d="M865.8,53.8c-0.4,0-0.7,0.1-1,0.3c0.2,0.2,0.5,0.3,0.7,0.4C865.6,54.2,865.7,54,865.8,53.8"
          />
          <path
            id="path5200"
            class="countryIsland"
            d="M870.5,43.1c0.5,0.3,1,0.4,1.5,0.5c-0.8,0.1-1.6,0-2.4,0.1c1.6-0.1,0,0.6,0.9,1.1
    		c0.6,0.3,1.4,0.4,2.1,0.4c2.3,0.1,4.7-0.2,6.9-0.9c-2.5,2.6-6.7,0.2-9.6,1.9c0.4,0.5,1,0.8,1.7,0.4c-0.2,0.2-0.4,0.5-0.7,0.7
    		c1.8,0.6,5.3-0.3,7.2-0.6c-0.8,0.2-1.6,0.3-2.4,0.7c1.8,0.5,3.8-0.3,5.5-0.9c-1.1,0.5-1.1,0.6,0,0.4c0.8-0.1,1-0.7,1.5-0.7
    		c0.5-0.1,0.8,0.4,1.6,0.3c0.8-0.1,1.5-0.2,2.3-0.1c-0.7,0.2-1.5,0.2-2.2,0.4c2.1,0.2,4.3,0.2,6.4-0.1c-2.5,0.9-5.3-0.3-7.8,0.7
    		c1.6,0.7,3.4,0.1,5.1,0.2c1.8,0.2,3.7,0.1,5.5-0.2c-1.6,0.5-3.4,0.4-5,0.4c-1.8,0.1-3.6,0.4-5.4,0.2c-3.5-0.3-6.9,0.2-10.4,1
    		c0.7,1.3,2.5,0.9,3.7,1.3c-1,0.5-2.2-0.1-3.2,0.1c1.3,0.9,1.5,1.1,3.2,0.9c1.8-0.2,3.6,0.4,5.4,0.4c-2.3,0.6-4.8-0.2-7.1,0.5
    		c0.7,1.2,3.5,1.7,4.8,1.9c1.1,0.2,2.2,0.4,3.3,0.4c0.7,0,4.2-0.9,2.2-1.1c1.6-0.6,2.9,0,4.2,0.9c0.6-0.9-0.4-2.8,1.2-2.9
    		c-0.6,3.3,3,2.9,4.7,1.7c0.5-0.4,1-1.8,1.8-1.5c1.4,0.6,2.2,0.7,3.6,0c-0.3-0.2-0.5-0.4-0.8-0.6c1.5-1.4,3.4-2.1,5.4-2.6
    		c-1.2,0.7-2.9,0.9-3.6,2.3c1.9,1.7,5.8-2.2,7.7-1.7c1.2,0.3,3.5-0.8,4.9-0.9c2.1-0.1,4.3-0.4,6.4-0.8c-1.1-2.6-1.8-1.8-3.9-1.1
    		c0.3-0.2,0.5-0.5,0.7-0.7c-2.1-1.8-4.7,0.8-7,0.2c1.7-1,3.7-1.3,5.4-2.4c-1,0.2-2,0.4-3.1,0.5c1-0.2,2-0.6,3-0.6
    		c-0.5-1.2-2.2-0.8-3.2-0.7c0.3-0.3,3.9-0.9,2.9-1.9c-0.9-1-3.8-0.3-4.7,0.5c0.3,0.2,0.5,0.4,0.8,0.6c-1.3,0.6-2.7,0.8-3.7-0.4
    		c0.3,0,2.9-0.6,0.9-0.6c2.3-0.5-2.4-0.7-2.5-0.8c-0.6-0.6-2.5-0.4-3.2-0.2c1.6-0.3,0.5-0.6,0-1.2c-0.4-0.6-0.8-1.4-1.7-1.6
    		c2-0.4-2.5-1.5-3.2-1.5c-1.8-0.1-4.8-0.5-6.5,0.3c1.3,1.2,3.3,0.1,4.8,0.6c-1.8,1-4.3,0.6-6.3,0.4c0.5,0.2,1,0.4,1.5,0.4
    		c-0.8-0.1-1.5,0.2-2.3,0.3c-0.8,0.1-1.4-0.3-2.1-0.4c-0.7-0.1-4.5,0.6-2.8,0.9c-0.5,0.1-1,0.2-1.5,0.2c0.7,0.4,1.5,0.3,2.3,0.2
    		c-2.2,0.1,2.4,0.4,2.7,0.5c-0.8,0.2-1.6,0.1-2.4,0.1c0.7,0.6,1.6,0.5,2.4,0.7c-1.9,0.2-3.8-0.4-5.7-0.2c-1.7,0.2-3.7,0.7-5.3,0.6
    		c0.6,0.1,1.1,0.3,1.5,0.6c-1.8,0.3-3.7,0.2-5.5,0.2c1,0.1,1,0.2,0,0.4c1.8,0.2,3.6,0.6,5.5,0.3c-0.5,0.1-1,0.2-1.4,0.2
    		c1.4,1.2,4.8,0,6.5,0.1c-1.1,0.3-2.2,0.2-3.2,0.4c0.2,0.2,0.4,0.4,0.7,0.6c-3.3,0.3-7-1.5-10.2-0.7
    		C872.6,42.9,871.2,42.9,870.5,43.1"
          />
          <path
            id="path5218"
            class="countryIsland"
            d="M883.5,39.2c-1.6-0.2-3.3-0.2-4.8,0C879.5,39.6,883.2,39.9,883.5,39.2"
          />
          <path
            id="path5242"
            class="countryIsland"
            d="M898.7,52.7c-1,0-2.4,0.6-3,1.3C896.8,53.8,897.7,53.2,898.7,52.7"
          />
          <path
            id="path5262"
            class="countryIsland"
            d="M913.9,48.9c-1.3,0.3-2.6,0.8-4,1c2,0.3,5.4,0.3,7.1-0.9C916,48.8,914.9,48.9,913.9,48.9"
          />
          <path
            id="path5032"
            class="countryIsland"
            d="M790.5,53.5c1.3,0.3,3,0.2,4.2-0.4c2.5-1.4-2.6-2.3-3.7-2.2c-2.5,0.2-4.9,1-7.4,1.3
    		c-2.9,0.3-5.8,0.2-8.6,1.2c0.9,0.8,2.4,0.3,3.3,0.1c1.6-0.3,2.9,0.5,4.4,0.5c1.3,0,2.3-1.1,3.6-0.3
    		C787.5,54.2,789.2,53.6,790.5,53.5"
          />
          <path
            id="path4926"
            class="countryIsland"
            d="M657,92.3c-1.3,0.6-2.6,1.6-4,1.7c-1.7,0.2-2.6,1.9-4.3,2c1.5,0.2,3.1-0.3,4.6,0.1
    		c-2,0.3,0.7,1,1.2,1.1c0.7,0.2,1.9,0.7,1.7,1.7c-0.2,0.9-2,2.8-0.2,3.2c2.7,0.6,6.6-1.3,9.1-2.2c1.7-0.6,4,0,5.9-0.3
    		c2.2-0.3,3.8-1.1,5.5-2.4c3.9-3.1,8.6-2.9,12.9-5.2c2.1-1.1,4.6-0.9,6.7-1.9c2.1-0.9,4.4-1.1,6.6-1.5c2.6-0.5,5.1-1.1,7.7-1.6
    		c2-0.4,4.2-0.4,6.2-1.1c0.4-0.1,2.7-0.8,2.4-1.5c-0.2-0.4-1.2-0.6-1.6-1c-0.6-0.6-0.9-1.4-1.5-2c-1.4-1.6-4.1-1.5-6-1.4
    		c-2.4,0.2-4.5,1.6-6.7,1.8c0.7-0.5,1.7-0.6,1.9-1.4c-1.6,0.1-3.5,0.1-4.7,1.3c0.5-1,1.9-1.1,2.8-1.4c-2.4,0.2-3.7-0.9-5.7-1.8
    		c-2.6-1.1-6.4,0.2-9.1,0.3c-3,0.1-6.4-0.1-9.3,0.6c-1.2,0.3-4.6,3.3-2.2,4.2c-3.8,2.2-8.9,2.1-12.3,5c1.7-0.1,0.1,1-0.6,1.1
    		c-0.8,0.1-1.6-0.2-2.4,0.1c-0.7,0.2-0.9,1.3-1.6,1.7C659,91.9,657.9,92,657,92.3"
          />
          <path
            id="path4960"
            class="countryIsland"
            d="M716.3,69.5c0.7-0.1,3.8-0.4,4-1.2c0.2-0.7,2.5-2.1,3.2-2.1c-0.1,0.6-0.4,1.1-0.7,1.5
    		c1,0.1,2-0.2,2.8-0.9c0.9-0.7,2.3-0.4,3.2-1.1c-0.3-0.2-0.5-0.3-0.8-0.5c1.6-0.8,3.2-0.1,4.7-1.2c1.3-0.9,2.4-0.3,3.7-1
    		c-1.1,1.3-3.1,2.1-4.7,2.6c3.1,2.3,5.4-0.9,8.5-1c1.2-0.1,2.5,0,3.7-0.2c0.8-0.2,3-1.2,1.3-1.8c1-0.6,2.1,0,3.2-0.2
    		c-0.5-0.2-1-0.7-1.1-1.3c2.1-0.6,4.3-0.4,6.3-1.2c-0.7-2.8-5-0.9-6.6-1.1c0.3,0.2,0.5,0.3,0.8,0.5c-0.5,0.5-3.2,0.6-3.9,0.5
    		c-1.8-0.2-3.5-0.4-5.3-0.3c-4.4,0.2-8.2,2.1-12.4,2.8c-3.7,0.6-7.2,1.8-10.8,2.8c-1.9,0.5-3.9,0.3-5.9,0.5
    		c-2.2,0.3-4.2,1.5-6.4,2.1c1.1-0.1,2.1-0.5,3.2-0.6c-1,0.3-1,0.4,0,0.3c-0.8,0.4-1.6,0.6-2.4,0.9c1.5,1.1,3.8-0.6,5.5-0.2
    		c1.1,0.2,1.7,0.6,3,0.1c1.1-0.4,1.8-1.5,3-1.2c-0.2,0.2-0.5,0.4-0.7,0.6c0.2,0.1,0.5,0.3,0.7,0.4c-0.5,0.3-1,0.6-1.5,0.9
    		C714.9,69.6,715.6,68.9,716.3,69.5"
          />
          <path
            id="path4962"
            class="countryIsland"
            d="M704.1,68c-0.2,0.1-0.4,0.2-0.6,0.3c0.5,0,1-0.1,1.4-0.3C704.6,68,704.4,68,704.1,68"
          />
          <path
            id="path4964"
            class="countryIsland"
            d="M703.7,69c0.2,0.1,0.5,0.1,0.7,0C704.2,69,703.9,69,703.7,69"
          />
          <path
            id="path4990"
            class="countryIsland"
            d="M712.5,69c-0.9-0.3-2.4,0.7-3.1,1.3C710.5,70,711.7,69.7,712.5,69"
          />
          <path
            id="path4994"
            class="countryIsland"
            d="M731,67.7c-2.4-0.1-4.8,0.9-7.2,1.4c-2,0.4-4.9,0.6-6.5,2.2c1.5,0,2.6,0.6,4.2,0.2
    		c1.3-0.3,2.5-0.8,3.7-1.1C727.1,69.8,729.8,69.3,731,67.7"
          />
          <path
            id="path4996"
            class="countryIsland"
            d="M728.7,74.4c0.2-0.1,0.5-0.1,0.7-0.1c-0.5,0.2-0.9,0.4-1.4,0.4c0.7,0.4,3,1.2,3.3-0.1
    		c0.1-0.5,2-0.2,2.6-0.5c-0.3,0.2-0.5,0.4-0.8,0.6c0.7,0.9,2.5,0.5,3.4,0.2c0.6-0.1,3.7-1.9,1.7-1.6c0.6-0.5,0.7-0.2,1.3,0.1
    		c0.7,0.4,1.9-0.5,2.6-0.7c0.6-0.2,0.4-1.1,1.1-0.4c0.7,0.6,1.9,0,2.7,0.1c-1,0.1-1.9,0.3-2.8,0.6c-0.5,0.2-2.8,0.5-1.1,0.9
    		c-0.5,0.2-1.1,0.3-1.7,0.4c1,1,2.7,0.3,3.9,0.2c1.3-0.1,3.6-1.4,4.7-0.9c-0.3,0.2-0.5,0.3-0.8,0.5c2.8,1.2,5.6-1.1,8.4-0.6
    		c-3.6,1.7-7.6,1.5-11.4,1.8c-2,0.1-3.9,1-6,1c-1.9,0-3.8,0.5-5.6,1.1c2.3,4.3,12.2,1.1,15.5,0.6c3.1-0.4,5.7-1.7,8.9-1.8
    		c2.8-0.1,5.8-1.8,8.5-1.4c-1.8,0.9,0.9,0.8,1.6,0.8c1.3,0.1,2.7-0.1,3.7-1c-0.2,0.2-0.4,0.5-0.6,0.7c1.9,1.4,5.7,0,7.8-0.3
    		c2.6-0.4,5.1-1.7,7.4-2.9c0.7-0.4,4.1-2,3.7-3.1c-0.3-0.8-2.8-0.8-3.5-0.8c-0.7,0-5,1.3-2.9,1.6c-0.8,0-1.6,0.3-2.2,0.8
    		c0.1-0.6,0.5-1,1-1.3c-1.9-0.4-3.6,0.2-5.4,0.6c0.8-0.8,2-0.9,3-1.2c-0.8-0.9-2.2-0.5-3.2-0.5c1-0.7,2-0.7,3.1-1.2
    		c0.8-0.4,1.1-1.3-0.1-1.2c0.7-0.3,2.8-1.6,2.7-2.5c-3.2-0.7-6.3,1.4-9.3,2.1c-1.1,0.2-2.5-0.1-3.4,0.8c0.4,1.2,2.2,0.8,3,1.6
    		c-0.9,0.3-1.9,0.7-2.8,1c-0.9,0.3-1.9,0-2.7,0.6c1.3,0.2,2.8,0.3,3.8,1.2c-1.3,1.7-3.5,1.2-5.4,1c-2.6-0.2-5.2,0.2-7.8,0
    		c-0.1-0.8,1-2.1,1.5-1.9c-1.3-0.2-2.7-0.3-3.9,0.1c1-0.6,2.1-0.4,3.2-0.6c-2.3-2.7-5.3-2.3-8.7-1.9c0.2-0.6,0.7-1,1.2-1.2
    		c-1-2.3-8.5-0.5-10,0.6c1.1,0.8,3.5,0.9,4.8,0.8c-1.9,0.5-4,0.1-5.9-0.1c-2.7-0.3-4.1,1-6.5,1.7c1,0.7,2.5,0.5,3.7,0.3
    		c1.9-0.2,3.7,0,5.6-0.2c-2.1,1-4.7,0.4-7,0.6c-2.7,0.2-5.4,0.4-8,1.3c1.3,0.7,3.1,0.6,4.5,0.4c2.4-0.3,5-0.5,7.3-1
    		c-5.7,1.6-11.7,1-17.3,2.9c1.4,1.7,4.4,0.8,6.2,0.4c-0.3,0.2-0.5,0.4-0.8,0.6C728.2,74.3,728.5,74.3,728.7,74.4"
          />
          <path
            id="path5002"
            class="countryIsland"
            d="M754.7,63.8c1.4-0.1,2.8,0,4-0.9c-1.7-1.2-5.5-0.6-7.1,0.5C752.5,63.6,753.6,63.6,754.7,63.8"
          />
          <path
            id="path5006"
            class="countryIsland"
            d="M759.3,69.2c-0.4-0.1-1.1,0.1-1.5,0.2C758.3,69.4,758.8,69.4,759.3,69.2"
          />
          <path
            id="path5024"
            class="countryIsland"
            d="M786.6,56c1.1-0.1,2.2-0.3,3.2-0.7c-1.2-1-3.3-0.5-4.7-0.4c-2.1,0.2-4.3,0.4-6.4,0.5
    		c-1.6,0.1-11.7-0.1-11.1,3.3c0,0.1,2.2,1.1,2.5,1.2c1.2,0.2,2.6-0.2,3.8-0.4c3.3-0.5,7.4,0.4,10.1-2.1c-0.7-0.6-1.6-0.3-2.4-0.4
    		C782.5,55.5,785.2,56.2,786.6,56"
          />
          <path
            id="path5044"
            class="countryIsland"
            d="M791.9,74.6c0.9-0.2,3.3-1.9,1.1-2.3c-2.2-0.4-3.8,1.2-5.8,1.7
    		C787.8,75.7,790.7,74.7,791.9,74.6"
          />
          <path
            id="path5056"
            class="countryIsland"
            d="M797.7,67.9c2.5,0.3,9.2,1.7,10.6-1.4c-1.7-0.5-3.5,0-5.2,0.2C801.3,66.9,799.1,66.6,797.7,67.9
    		"
          />
          <path
            id="path5058"
            class="countryIsland"
            d="M807.9,67.9c-1.5-0.5-3.1,0.1-4.6,0.3c-1.5,0.2-3.3,0-4.7,0.9C801.4,69.1,805.6,69.8,807.9,67.9
    		"
          />
          <path
            id="path5060"
            class="countryIsland"
            d="M801.2,69.2c-0.8-0.1-1.6,0-2.4,0C799.6,69.7,800.5,69.4,801.2,69.2"
          />
          <path
            id="path5062"
            class="countryIsland"
            d="M799.9,66.3c2.7,0,5.4,0.3,7.9-0.8c-0.7-0.5-2.9-1.1-3.7-0.9c2.1-0.6-1.9-0.3-2.6-0.1
    		C800.4,64.8,800.8,65.8,799.9,66.3"
          />
          <path
            id="path5064"
            class="countryIsland"
            d="M808.6,68.8c-2-1.6-6.5,0.5-8.6,1.2C803,70.4,805.8,69.4,808.6,68.8"
          />
          <path
            id="path5068"
            class="countryIsland"
            d="M804.8,61.2c0.9-0.2,2.5,0,3.1-0.9c0.6-0.8-0.7-1.2-1.3-1c2.2-0.9-0.3-2.5-1.6-2.4
    		c-0.6,0-1.6,1.4-1.6,1.9c0,0.5-0.7,1.1-0.4,1.5C803.4,60.8,804.3,60.8,804.8,61.2"
          />
          <path
            id="path5076"
            class="countryIsland"
            d="M808.9,60.8c-0.6-0.1-1.3,0.1-1.7,0.6C807.8,61.4,808.4,61.2,808.9,60.8"
          />
          <path
            id="path5100"
            class="countryIsland"
            d="M816.2,52.4c-0.6,2.8,9,0.6,10.4,0.8c-2.2,0.4,2.6,0.6,3.1,0.6c1,0.1,3.3,0,3.7,1.2
    		c-1.1,0.2-1,0.8-0.2,1.3c1.2,0.7,2.7,0.5,4,0.4c1.2-0.1,2.6-0.2,3.7-0.9c0.8-0.6,1.1-2.4-0.4-2.1c0.7-1,2-1.3,3-1.7
    		c-0.5-0.4-1-0.5-1.6-0.4c0.9-0.2,1.3-0.8,0.2-1.1c-1.3-0.4-2.6,0.5-3.8-0.1c0.4-0.4,0.9-0.6,1.5-0.7c-1.1-0.2-2-0.9-3.1-0.9
    		c-1.7,0-3.5,0.7-5,1.2c0.5-0.8,1.5-0.7,1.3-1.8c-0.3-1.3-2.6-1-3.7-1.1c-3.2-0.2-7-0.3-10,1.4c0.9,0.9,2,0.3,3.1,0.5
    		c-0.9,0.8-2.1,0.9-3.1,1.4c2.1,0.4,4-1.4,6.1-1c-0.5,0.8-1.4,0.9-2.3,1.1c0.5,0.6,2.8,0.3,3.5,0.1c-1.2,0.9-2.6,0.6-3.9,0.9
    		c0.8,0.2,1.5,0.1,2.3,0.1c-1,1-2.5,0.8-3.7,0.6C819.6,51.9,817.9,51.8,816.2,52.4"
          />
          <path
            id="path5102"
            class="countryIsland"
            d="M823.9,63.7c0.2-0.3-3.2,0-3.5,0c-1.3,0.2-3,0.1-4.1,1C818.9,64.9,821.4,64.1,823.9,63.7"
          />
          <path
            id="path5122"
            class="countryIsland"
            d="M827.3,56.2c-1.2-0.2-4.7-0.7-5,1c2.1,0.8,4.7,0.3,6.9,0C829,56.3,828,56.3,827.3,56.2"
          />
          <path
            id="path5124"
            class="countryIsland"
            d="M825.4,63.7c0.9,0,1.8,0.6,2.6,0.1C827.2,63.5,826.1,63.3,825.4,63.7"
          />
          <path
            id="path5152"
            class="countryIsland"
            d="M841.8,61.7c-0.5,0-1,0.1-1.4,0.3C840.5,62,842.1,62.3,841.8,61.7"
          />
          <path
            id="path5164"
            class="countryIsland"
            d="M847,68.6c-0.9-0.1-2.4-0.1-2.9,0.9C845.2,71.1,846.3,69.5,847,68.6"
          />
          <path
            id="path5166"
            class="countryIsland"
            d="M846.4,67.5c-0.6,0.1-1.1,0.3-1.6,0.6C845.4,68.1,845.9,67.9,846.4,67.5"
          />
          <path
            id="path5168"
            class="countryIsland"
            d="M850,56.9c0.8-0.1,1.5-0.4,2.3-0.4c-2.2,0,0.5-0.3,0.8-0.3c1.2-0.1,2.3-0.1,3.5-0.1
    		c2,0,4.1-0.2,5.8-1.2c-0.2-0.5-0.7-0.9-1.4-0.8c1.2-0.4,2.6-0.4,3.8-0.9c-1.5-1.6-3.6-0.3-5.3-1.1c1.9-0.5-1.9-1.3-2.5-1.4
    		c-1-0.3-2-0.4-3-0.3c-0.6,0-4.7,0.3-3.2,1.7c-0.8,0.1-1.6-0.2-2.3,0.4c0.3,0.2,0.5,0.3,0.8,0.5c-1.7,0.8,1.1,0.6-0.7,1.1
    		c1.3,0.1,2.7,0.1,4,0.5c-1.1,1.3-3.2,0-4.6,0.6c0.8,0.2,1.4,0.7,2.2,0.8C849.3,56.1,849.3,56.4,850,56.9"
          />
          <path
            id="path5066"
            class="countryIsland"
            d="M810.8,75.2c1.6-0.1,3.1-0.3,4.5-0.9c-1.9,0.8,0.8,0.8,1.4,0.5c0.5-0.2,1.4,0.3,1.9,0.2
    		c0.8-0.1,1.9-0.3,2.6-0.2c0.4,0.1,1-1,1.4-1.3c-0.1,1.1,1.2,0.7,1.8,0.6c-0.1-0.6-0.5-1-1.1-1.1c0.7-0.4,1.5-0.9,2.3-0.8
    		c-2.1-0.2,1.2-0.7,1.5-1c0.2,1.5,2-0.3,2.5-0.6c-0.5-0.2-1.1-0.3-1.6-0.5c0.7-0.4,1.7-0.4,2.3-1c0.4-0.4,0.8-0.8,1.3-1
    		c0.4-0.2,1-0.4,1.3-0.7c0.6-0.7,0.2-1.5,1.3-1.7c-0.4-0.8-1.3-1-2.1-1c1.8-0.2-1-0.8-1.7-0.7c0.5,0.2,1,0.2,1.5,0.1
    		c-0.7,0.3-2.8,0-3.1,0.5c-0.5,0.8-1.1,0.6-1.8,1.1c0.6-0.7,0.5-0.9-0.4-0.8c-0.8-0.2-1.5-0.6-2.4-0.7c-1.3-0.1-2.6,0.1-3.9,0.3
    		c-0.4,0.1-2.3,0.3-1.8,1.1c0.5,0.8,2.3,0.1,3,0.6c-0.7,0.4-1.9,0.3-2.7,0.2c0.7,0.8,2.4,0.9,3.4,0.9c-1.1,0.5-2.7-0.6-3.2,0.9
    		c0.5,0.4,1.1,0.4,1.7,0.3c-1.1,0.3-2.1,0.4-3.2,0.4c0.9-1.2-1.7-1,0.2-1.4c-0.5,0-1,0.1-1.5,0.1c0.6-0.8,0.1-1-0.6-0.9
    		c1-0.9-0.3-1.3-1.1-1.4c-1.7-0.2-3.1,0.4-4.6,1.4c1.3,0.2,2.6,0.1,4-0.1c-0.2,0.1-4.5,0.7-3.8,1.5c0.3,0.3,1.2,0.2,1.6,0.2
    		c-0.5,0.1-1,0.3-1.5,0.4c0.2,0.9,1.2,1.1,1.9,0.8c-2.4,0.9-4.4-1.1-7,0c1.8,0.3-1,0.6-1.5,0.6c-0.8,0.1-2.6,0.2-2.5,1.3
    		c3.1,0.2,6.2-0.2,9.3-0.6c3.7-0.4,7.4-0.3,11-0.7c-1,0.4-2.1,0.2-3.2,0.3c-0.6,0-4.5,0.8-2.5,0.8c-0.8,0.1-1.7,0.1-2.5,0.2
    		c0.5,0.1,0.9,0.1,1.4,0.1c-0.6,0.1-1.2,0.2-1.8,0.2c2-0.1-1.7,0.2-2.3,0.2c2,0.4-0.3,0.5-0.7,0.5c0.5,0.4,1,0.5,1.6,0.4
    		c-2.1,0.4,0.7,0.4,1,0.4c-0.9,0.2-2.4-0.4-3.1,0.4C809.3,74.5,810.3,75.1,810.8,75.2"
          />
          <path
            id="path5072"
            class="countryIsland"
            d="M816,81.2c-1.6-0.5-3.2,0.4-4.9,0.4c-1.8,0-3.6,0.6-5.4,0.7c0.4,0.4,0.9,0.6,1.5,0.6
    		c-2.1,0.1,0.3,0.2,0.7,0.3c0.9,0,1.8,0,2.7-0.1C812.4,82.8,814.7,82.5,816,81.2"
          />
          <path
            id="path5084"
            class="countryIsland"
            d="M813.8,71.3c0.3-0.1,0.5-0.2,0.8-0.4c-1.3,0.2-2.7,0.1-4,0.4C811.6,71.4,812.7,71.1,813.8,71.3"
          />
          <path
            id="path5088"
            class="countryIsland"
            d="M813,79.5c-0.5-0.1-1,0-1.5,0.3C812,79.9,812.5,79.8,813,79.5"
          />
          <path
            id="path5110"
            class="countryIsland"
            d="M821.5,77.6c-1.1,0.1-2.1,0.5-3.1,1.1C819.5,78.8,820.9,78.5,821.5,77.6"
          />
          <path
            id="path5132"
            class="countryIsland"
            d="M842.3,70.8c-2.9,0.2-5.6,1-8.4,1.5c0.5,0.1,1,0.1,1.5,0.1c-0.8,0.2-1.5,0.4-2.3,0.5
    		c1.8,0.2-0.9,0.4-1.3,0.5c-1.1,0.2-2.3,0.6-3.2,1.4c0.8,0,1.5,0.7,2.2,0.1c-1,1,2,1.6,2.5,1.7c2.2,0.6,4.2,0.8,6.5,0.7
    		C844,77.3,848.9,71.4,842.3,70.8"
          />
          <path
            id="path5134"
            class="countryIsland"
            d="M830.7,77.4c-0.6,0.1-1.1,0.3-1.5,0.6c0.7,0.2,1.5,0.4,2.3,0.3
    		C831.6,77.8,831.3,77.5,830.7,77.4"
          />
          <path
            id="path5138"
            class="countryIsland"
            d="M836.2,71.1c-1.1-0.2-6.5-0.3-6.3,1.7c1.1-0.2,2.5-1.4,3.6-1.2
    		C834.3,71.6,835.9,72.3,836.2,71.1"
          />
          <path
            id="path5156"
            class="countryIsland"
            d="M852.2,66.7c1.2,0.4,3.5-1.4,4.7-0.6c0.9,0.7-0.8,3,1.2,3.1c-1.2,0.7-1.1,1.9-2.7,2.2
    		c-1.4,0.3-3.3,1-4,2.4c0.5,0.2,1,0.3,1.5,0.2c-1.1,0.7-3.6,2-1.3,3c1.9,0.8,3.6-0.4,5.6-1.2c-0.4,0.5-1,0.8-1.6,0.9
    		c1.2-0.1,0.2,0.6,0.8,0.9c0.9,0.3,1.9,0,2.9,0.3c2.9,0.9,4.5-0.7,7.1-1.4c-0.2,0.3-0.3,0.5-0.5,0.8c1.2-0.2,2.5-1.9,3.7-0.6
    		c-1.1-0.1-2.4,0.9-3,1.7c1.4,0.8,4.7,0.5,6.1,0c0.5-0.2,3.6,1.4,3.4-0.3c1,0.8,2.2,0.6,3.1-0.2c-0.3,1.5,3.1,1,3.7-0.1
    		c-0.3,1.9,2.6-0.1,3.2-0.5c-0.2,0.2-0.5,0.5-0.7,0.8c1.7,1,5.2,0.5,7.1,0c1.3-0.3,2.8-2.6,4-1.7c-1.8,2.6,3.8,2.5,5.4,2.4
    		c1.7-0.2,3.3-0.8,5-0.6c1.9,0.2,3.4-0.4,4.9-1.4c-0.2-0.3-0.4-0.5-0.6-0.8c1-0.8,1.5,0.2,2.4,0.4c1,0.2,2.2-0.2,3.1-0.5
    		c-0.4-1.8-3.2-0.4-4.3-0.4c0.5-2.1,5.5-1.1,6.7-2.8c-0.8-1.1-2.3-0.7-3.4-0.6c0.6-0.2,1.3-0.2,1.9-0.6c-1.3-1.4-3.7-0.3-5.3-0.7
    		c0.2-0.2,0.5-0.3,0.7-0.5c-3.1-2.5-8.2,0.5-11.5-0.3c-2.5-0.6-4.9,0.5-7.3,0.7c-2.4,0.2-5.2,0.4-7.5,0.9c0.4,0.4,1,0.7,1.7,0.7
    		c-1.7,0.3-3.5,0.3-5.3,0.4c2-0.2-1-1.3-1.5-1.5c-1.1-0.3-2.3,0.1-3,0.9c0.9-1.6-2.9-0.3-3.6-0.2c1.4-0.3,0-1-0.6-1.2
    		c-1.3-0.5-1.4,0.9-2,1.2c-0.8,0.4-0.8,0.1-1.2-0.5c-0.5-0.8-2,0-2.6,0.1c2.4-0.5-1.8,0.1,0.7-0.5c1.1-0.2,2.2,0,3.1-0.7
    		c-0.3-0.9-1.3-1.1-2-0.5c1.2-1.2-0.6-1.1-1.4-1.1c-1.6,0.2-4.2-0.2-5.5,0.8c1.7-1.6,4.1-0.9,6.2-1.4c-1.1-0.2-2.1,0-3.2,0
    		c2-0.4-1.7-0.6-2.2-0.6c1-0.1,1.1-0.3,0.1-0.6c1.8-0.3,4.3,0.7,6.1,0.8c-2.1-0.4,1.3-0.2,1.7-0.2c1.1,0,2.3,0.1,3.3-0.4
    		c-1.3-2.2-6.6-1.6-8.7-1.6c1.4-0.8,3.1,0.2,4.6-0.1c-1-2.4-4.6-1.4-6.5-1.1c-1.5,0.2-3,0-4.6,0.1c-1.7,0.1-3.2,0.9-4.8,1.4
    		c1.2-0.7,2.5-0.9,3.6-1.6c2-1.3-3.2-1.5-3.7-1.6c-2.3-0.5-4.1-0.9-6.5-0.9c-1.3,0-2.7,0.1-4,0.3c-0.5,0.1-1,0.2-1.4,0.5
    		c0.1,0.6,0.4,0.9,1,0.8c-0.6,0-2.8,0-3.2,0.4c1.6,0.8,3.8,1.5,5.5,0.9c-1,0.6-2.1,0.5-3.2,0.6c0.1,1.4,3.9,1,4.8,1.2
    		c-1.1-0.1-2.1,0.3-3.2,0.3c1,0.7,2.2,0,3.2-0.1C849.5,66.3,850.9,66.8,852.2,66.7"
          />
          <path
            id="path5170"
            class="countryIsland"
            d="M867.6,57.4c-1.1-0.5-2.2-0.4-3.3-0.3c-1.7,0.1-3.4-0.2-5.1-0.1c-2.6,0.1-5.6-0.5-7.9,1.2
    		c0.9,2,5.6,0.8,7.2,0.7C861.8,58.7,864.6,59.6,867.6,57.4"
          />
          <path
            id="path5202"
            class="countryIsland"
            d="M873.5,65.4c1.3-0.5,2.6-1,3.7-1.8C876,61.9,871.1,63.4,873.5,65.4"
          />
          <path
            id="path5204"
            class="countryIsland"
            d="M874.3,60.2c-0.6,0-1,0.2-1.4,0.6c0.9,0.2,1.7,0.4,2.2-0.6C874.9,60.2,874.6,60.2,874.3,60.2"
          />
          <path
            id="path5208"
            class="countryIsland"
            d="M879.4,60.5c0.7-0.2,3.3-0.5,2.9-1.7c-0.5-1.3-2.8-1.3-3.9-1.1c-1,0.1-2,0.7-2.8,1.2
    		C873.7,60.2,878.7,60.5,879.4,60.5"
          />
          <path
            id="path5214"
            class="countryIsland"
            d="M881,64.5c-0.2,0.6-0.8,1.1-1.4,1.3c0.6-0.3,1.4-0.3,1.5-1c0.1-0.8,1.7-1.4,2.3-1.5
    		c-1.2,0.5-1.2,2-2.4,2.5c1.1,0.1,2.5,0.4,3.5,0.3c1-0.1,1.3-1.5,2.4-1.4c-0.4,1.6,1.8,1.3,2.7,1.1c1.1-0.3,2.6-1.8,3.3-0.8
    		c-0.6,0.1-1.2,0.5-1.7,0.9c3.1,0.4,6.3,1.1,9.4,0.4c-0.7-0.2-1.5-0.6-2.2-0.6c0.2-0.5,0.7-0.9,1.2-1c0.2,2.6,3.5-0.4,4.1-0.4
    		c1,0,0.4,1.4,1.6,1.4c1.1,0,3-0.1,2.9-1.7c0.7,0.4,0.7,1,0.1,1.5c1.4,0.7,4.3,0.7,5-1c-0.9,0-0.8-0.4-0.4-0.9
    		c0.5,1.3,2.1,0.5,3,0.6c-0.7,0.1-1.8,0-1.8,1c1.1,0.2,4.7-0.8,5,0.8c-1.1,0.4-2,1.2-3.1,1.7c3-0.6,6.3-1,9.3-1.1
    		c1,0,4.8-2.5,5.5-1.3c0.8,1.2,5.9-1.3,6.4-2.2c1-1.9-5.3,0.2-5.6,0c0.3-0.2,0.5-0.3,0.8-0.5c-0.8,0-1.6,0-2.3-0.1
    		c1.1-0.3,2.2-0.6,3.1-1.4c-2.2-1.5-4.5,0.6-6.8,0.4c0.5-0.2,1.1-0.4,1.6-0.6c-1.5-0.1-3.1-0.5-4.6-0.4c-2,0.1-2.8,0.4-4.8-0.2
    		c1.6-0.3,3.4,0.5,4.8-0.4c-0.6,0.1-1.1-0.1-1.5-0.5c0.5-0.3,0.8-0.8,0.7-1.4c0.8,0.4,1.4,1.2,2.1,1.7c1.1,0.7,2.6,0.7,3.9,0.9
    		c1.3,0.1,2.5-0.1,3.8-0.3c0.6-0.1,5-0.5,3,0c1.3-0.1,2.5-0.2,3.8-0.5c0.7-0.2,4.4-1.5,2.6-1.8c1.1-0.2,0.8-1.4-0.1-1.5
    		c1.2-0.1,2-0.1,3.1-0.1c1.5,0,3,0.4,4.5,0.2c1.2-0.1,2.4-0.8,3.6-0.6c1.3,0.2,2.6,0.3,3.5-0.8c-1.5,0-3.6,0.4-4.9-0.6
    		c2.2,0.2,7.6,1,9.4-0.9c-2-0.7-4.2-0.4-6.3-0.9c2.2-0.2,4.5,0.1,6.7,0c0.8,0,1.8,0,2.5-0.4c0.7-0.5,1.1-1.4,2-1.5
    		c-2.4-0.8-5-0.4-7.4-0.4c1.1-0.3,2.3,0,3.3-0.6c-4.3-1-8.4,0.8-12.7,0.9c1.2-0.5,2.5-0.8,3.8-1.2c-1.6-0.6-3.3-0.2-4.9-0.4
    		c2.5,0.1,5,0.2,7.6,0.1c1.9,0,4.1,0.4,5.8-0.3c-3.1-0.7-6.3-0.1-9.5-0.5c3.1,0.1,6.4-0.4,9.4,0c2.5,0.3,5.3,2.1,7.8,0.8
    		c-0.2-0.2-0.5-0.5-0.7-0.7c0.5,0,1.1-0.1,1.6-0.2c-4.8-0.4-9.6-0.5-14.3-0.1c2.1-0.3-1.1-0.5-1.6-0.7c1.1,0.1,2.2,0.2,3.2,0.2
    		c0.8,0,0.6-1,1-0.9c0.9,0.2,1.6,0.8,2.6,0.9c1,0.1,2.1,0,3-0.2c-1.1-1.4-3.3-0.4-4.8-1c3.5,0.4,7.2,1.2,10.7,0.6
    		c2.2-0.4,4.5-0.5,6.6-0.4c1.2,0,4-0.9,1.5-1.5c-1.6-0.4-3.4-0.3-5.1-0.3c2.6-1.4,5.4,0.2,8.1-0.2c-0.1,0.3-0.2,0.5-0.3,0.8
    		c3.3,0.9,7,0,10.2-0.9c-0.5-0.3-1.1-0.4-1.7-0.3c1.5-0.7,3.4,0,4.8-0.9c-0.8-0.8-2.4-0.4-3.4-0.2c-1.7,0.3-3.4,0.2-5,0.2
    		c0.8-0.2,1.6-0.2,2.5-0.3c-0.5-0.2-1-0.4-1.5-0.5c1.8,0.2,3.6,0.4,5.5,0.1c1.8-0.2,3.9,0.5,5.5-0.4c-1.1-0.3-0.3-1.4-1.4-1.7
    		c1-0.2,1.6,0.5,2.4,0.9c1,0.4,2.4,0,3.3-0.2c3.2-0.9,6.2-1.7,9.5-2.3c3.4-0.6,6.7-1.4,10.1-2c3.1-0.5,6.4-0.3,9.4-1.5
    		c-1.2-1-3.3-0.5-4.8-0.3c-2.3,0.3-4.7,0.5-7,0.6c-5.4,0.4-10.7,1.4-16,1.6c0.5-0.1,1-0.2,1.5-0.3c-0.6-0.1-1.2-0.1-1.7-0.1
    		c6.2-0.4,12.3-1.3,18.5-1.9c-3.2-1.1-6.7,0.8-9.9-0.1c0.6,0,1.2,0,1.7,0c-0.9-0.6-2.1-0.8-3.1-0.9c1.1-0.1,2.1,0.4,3.1,0.6
    		c1.5,0.4,3,0.1,4.5,0c3.4-0.3,6.8-0.2,10.1-0.4c-0.8-0.1-1.5-0.1-2.3-0.1c6.4-0.5,12.8-0.7,19.2-1.8c0.8-0.1,6.9-0.9,6.4-1.7
    		c-0.7-1.2-3.6-0.9-4.7-0.6c2.4-0.5-0.8-0.1-1-0.1c-1.3,0.1-2.5,0.3-3.8,0.3c0.8,0,1.6-0.3,2.3-0.6c-0.8-0.1-1.5-0.8-2.4-0.9
    		c0.5,0,1.1-0.1,1.6-0.1c-1.2-1.1-3.1-0.2-4.5-0.1c-0.9,0-1.6-0.7-2.5-0.5c-0.9,0.2-1.5,0.6-2.4,0.5c-4.9-0.4-10.2,0.2-15.1,0.8
    		c3.8-1.2,8.2,0.4,11.9-1.4c-1.9-0.4-4-0.4-6-0.3c-2.7,0.2-5.4-0.4-8-0.1c1-0.2,1-0.3-0.2-0.2c-1,0-1.9-0.3-2.9-0.3
    		c-1.8-0.1-3.9,0.1-5.7,0.5c0.8,0.1,1.5,0.4,2.2,0.6c-1,0-2.2,0-3.2-0.4c0.3-0.2,0.5-0.3,0.8-0.5c-3.4-0.4-6.9,0.1-10.3,0.8
    		c1.3,0.1,2.5,0.8,3.9,0.9c-1.2,0.6-3.1,0.1-4.3-0.4c-1.9-0.7-3.2-1.4-5.3-1.1c-2,0.2-3.9,0-5.9,0c-1.5,0-3.7-0.6-5,0.4
    		c1.6,0.3,3.2,0.9,4.8,0.9c-0.6,0-1.1,0.1-1.6,0.4c1.1,0.2,2.1,0.4,3.2,0.5c-1.5,0.1-3.1,0.9-4.6,0.6c0.6,0,1.1-0.2,1.6-0.6
    		c-1.3-0.3-2.1-0.6-3.3-1.2c-1.4-0.6-3.6,0.5-4.7-0.2c0.5-0.1,1.1-0.2,1.6-0.4c-1.4-0.4-3.3,0.7-4.6,0.1c-1.8-0.8-4.4,0.1-6.2,0.2
    		c0.7,0.4,1.6,0.3,2.4,0.4c-2.1,0.5,0.7,0.5,1.1,0.6c1.3,0.1,2.5,0.1,3.8,0.1c-1.1,0.1-2.2,0-3.2-0.1c-1.1-0.1-2.5,0.4-3.5,0.2
    		c-1.9-0.4-4.2-1.5-6.1-0.4c0.9,0.1,1.6,0.5,2.2,1.1c-2,0-5.3-1.8-7.1-0.1c0.8,0.1,1.6,0.3,2.5,0.4c-1.9,0.1-4.1-0.6-5.7,0.7
    		c3.9,1.2,8.3,0.7,11.9,2.8c-1.1,0.1-2-0.5-3-0.8c-1.3-0.4-2.9-0.4-4.3-0.6c-1.6-0.2-5.7-1.9-7.1-0.6c0.8,0.9,2.1,0.5,3.1,0.9
    		c-1.6-0.4-3.2-0.2-4.8-0.2c0.2-0.2,0.5-0.3,0.7-0.5c-1.5-1.7-4.3-0.8-6.3-1.3c2.1-0.2-0.7-0.4-1.2-0.4c-1.3,0-2.5,0.1-3.7,0.4
    		c0.6,0,1.1,0.3,1.5,0.7c-1.9,0.1-5.7-0.8-7.1,0.8c2.4,0.3,5.2-0.3,7.3,0.8c-0.7,0.9-4.7-0.8-3.4,0.6c-1.3-0.6-2.5-1.1-4-1
    		c-0.7,0-4.1,0.3-2.4,0.9c-0.8,0-1-0.8-1.7-1c-0.8-0.3-2.1,0-3,0c-1.3,0-2.6,0.1-3.9,0.3c-0.8,0.1-2.4,0.3-3,0.6
    		c2.2,0-0.8,0.2-1.3,0.1c-1.3-0.2-2.7-0.1-4.1,0c-2.9,0.2-5.8,1.1-8.7,1.4c1.9,0.1,3,0.4,5,0c2-0.4,3.9,0.1,5.9,0.1
    		c-2.2,0.3-4.8-0.1-6.8,1.1c1.7,1,4.5,0.1,6.3-0.2c3.3-0.6,6.4-0.7,9.8-0.2c-4.4-0.4-9-0.3-13.2,1c1.3,0.1,2.5,0.2,3.8,0.4
    		c-2.3-0.1-4.7-0.3-6.9,0.4c0.6,1.4,2.9,1,4.1,0.9c2.5-0.3,5-0.2,7.6-0.3c2.8-0.1,5.7-0.3,8.5-0.8c2.1-0.4,4.6-1,6.7-0.4
    		c-3.7,0.9-8.2,1.5-12,1.5c-4.2,0-8.5-0.6-12.6,0.6c4.8,3.1,10.5,0.8,15.4-0.3c3.2-0.7,6.6-0.4,9.8-0.7c3-0.3,6.2-1.1,9.1-0.3
    		c-4.2-0.3-8.4,0.5-12.6,0.7c-2.2,0.1-4.2,0.5-6.3,0.9c-1.5,0.3-3.4,0.4-4.5,1.6c0.9,0.2,1.9,0,2.8-0.1c0.4-0.1,3.3-0.5,1,0.4
    		c2.8-0.1,6.1,0.6,8.8-0.1c1-0.3,1.4-1.1,2.4-1.3c0.9-0.2,1.9-0.3,2.8-0.1c-0.8,0.2-1.6,0.2-2.2,0.6c2.9,0.1,5.9-1.6,8.7-0.6
    		c-2.4,0.4-6.3-0.4-8.3,1.5c3.9,0,7.7-0.4,11.5-0.7c4-0.4,7.4-1.5,11.2-2.4c-0.3-0.1-0.7-0.3-1-0.4c2.6,0.8,5.4-0.4,8-0.9
    		c-0.4,0-0.8,0-1.2,0c1.9-0.2,3.9-0.8,5.9-0.4c-2.1,0.3-4.2,0.5-6.3,0.9c2.2-0.1,0.5,0.1,0.1,0.2c-0.8,0.1-1.7,0.3-2.5,0.4
    		c-1.1,0.2-2.9,0.5-3.5,1.6c3.2-0.3,6.6-0.9,9.8,0.1c-5.3,0-10.8-0.2-16,1.3c2.7,0.4,5.5-0.1,8.3,0.1c-4.4,0.5-8.8,0.3-13.3,0.7
    		c-4,0.3-8.9-0.4-12.7,0.9c1.9,1.2,3.4,1.4,5.4,2.2c-0.9-0.2-1.5,0.6-2.2,1c2.5,1.3,5.8,0.7,8.4,0.4c-1.7,0.5-3.6,0.8-5.4,0.8
    		c-0.8,0-1.6-0.3-2.4-0.4c-0.8,0-1.5,0.3-2.3,0.1c1.4-0.5-0.1-0.9-0.7-1.5c-0.8-0.8-2.1-1.3-3.2-1.8c-2.6-1.3-5.4-0.4-8.1-0.6
    		c-2.3-0.2-5.2-0.9-7.1,0.9c-0.9,0.8,3.5,0.9,4.2,1.3c-1.4-0.5-5.5-1.3-5.9,0.9c1.1,0.1,2.8-0.2,3.8,0.4c0.7,0.4,1.2,1.5,2.1,1.5
    		c-0.2,0.5-0.4,1-0.7,1.5c1.1-0.2,1.9,0.8,3,0.9c-2.1,0.3-4.3-1.6-6.1,0.1c2.6,0.3,5.1,0.3,7.7,0.6c1.3,0.1,2.5,0.4,3.7,0.1
    		c1.3-0.2,2.5-0.9,3.9-0.7c-1.5,0.1-2.5,1.1-4.1,1.1c-1.8,0-4.1-0.6-5.8,0.2c1.3,0.3,2.6,0.1,3.9,0.5c-0.6-0.1-1.1,0-1.6,0.3
    		c0.2,0.2,0.5,0.4,0.7,0.6c-1.1,0.4-3.7-1.1-4.8-1.4c-2.1-0.7-4.9-0.6-7-0.6c-2.2,0.1-4.3,0.1-6.5,0.4c-1.1,0.2-2.2,0.4-3.2,0.7
    		c-0.4,0.1-3.3,1.1-1.3,1c-1,0-2,0-3,0.3c-0.7,0.2-3.4,2.2-1.1,1.9c-0.8,0-1.7,0-2.4,0.4c3.5,0.3,6.9-0.4,10.2-1.6
    		c-1.3,0.7-2.9,0.9-3.9,2c1.9-0.2,3.9-0.3,5.7-0.9c1.8-0.7,3.4-2,5.4-2.2c-0.5,0.2-3.2,1.4-1.4,1.4c-0.9,0.2-1.8,0.2-2.4,0.9
    		c1.3-0.3,2.6,0.3,3.9,0.1c-1.6,0.3-4.3-1.1-5.5,0.6c1.1,0.2,2.2-0.2,3.3,0c-2.4-0.1-5.5-0.2-7.8,0.9c1.8,1.1,4.3-0.2,6.3,0.2
    		c-1.6-0.2-4.2-0.6-5.5,0.6c0.5-0.1,1-0.2,1.5-0.1c-0.9,0-1.7,0.4-2.4,1c1.3,0.3,2.6,0,3.9-0.2c-0.8,0.2-1.6,0.5-2.3,1
    		c2.8,0.5,5.5-0.2,8.1-0.9c2.4-0.6,5.2-2.9,7.7-2.5c-2.3,0.5-4.2,1.8-6.4,2.5c-1,0.3-2.1,0.5-3.1,0.8c-0.6,0.2-3.3,0.6-1.4,1.2
    		c-1.7-0.1-3.6-0.8-5.1,0.2c-1,0.7-4.5-0.8-5.9-0.7c2.8-1.6-0.4-2.6-2-2.8c-2.5-0.4-6-0.4-8,1.5c1,0.2,1.3,1.3,0.3,1.7
    		c1.3,0.4,2.7-0.3,4,0.1c-0.5,0-1,0.1-1.5,0.1c-2.4,0.5,1.9,0-0.8,0.8c0.5,0,1.1,0.1,1.6,0.1c-0.7,0.5-1.5,0-2.2,0
    		c-0.9,0-1.7,0.5-2.5,0.5c-2,0.1-3.9,0-5.9,0.4c-1.5,0.3-3.7,0.3-5,0.9c-0.6,0.3-0.5,0.7-0.9,1c-0.7,0.5-1.7,0.5-2.3,1.2
    		c0.7-0.2,1.1,0.7,1.7,0.9C879.2,66,880,64.7,881,64.5"
          />
          <path
            id="path5258"
            class="countryIsland"
            d="M905.7,58.2c-0.6,0-1.1,0.1-1.7,0.3c1.2-0.2,2.1,0.6,3.2,0.1C906.8,58.2,906.3,58.1,905.7,58.2"
          />
          <path
            id="path5264"
            class="countryIsland"
            d="M914.7,67.2c0.3,0.1,0.5,0.1,0.8-0.1C915.2,67.2,915,67.2,914.7,67.2"
          />
          <path
            id="path5270"
            class="countryIsland"
            d="M927,67.7c-1.7-0.3-4.4,0.7-5.6,1.9c0.7,0.3,1-0.2,1.7-0.3c0.7-0.1,1.4,0.3,2.2,0
    		c-0.2-0.2-0.4-0.5-0.6-0.7C925.5,68.3,926.4,68.3,927,67.7"
          />
          <path
            id="path5318"
            class="countryIsland"
            d="M945.2,56.4c-0.6,0-1.1,0.2-1.6,0.6C944.2,56.9,944.7,56.7,945.2,56.4"
          />
          <path
            id="path5162"
            class="countryIsland"
            d="M843.3,110c0.8-0.1,1.7,0.2,2.5,0.2c0.3,0,2-0.4,1.1-0.8C846.2,109.1,842.9,108.4,843.3,110"
          />
          <path
            id="path5184"
            class="countryIsland"
            d="M861.7,111.6c-0.5-0.4-1.2-0.1-1.5,0.3C860.6,111.8,861.2,111.7,861.7,111.6"
          />
          <path
            id="path5196"
            class="countryIsland"
            d="M866.1,111.4c0.3,0.5,0.9,0.8,1.4,0.4C867.1,111.6,866.6,111.4,866.1,111.4"
          />
          <path
            id="path5198"
            class="countryIsland"
            d="M867.3,110.9c-0.1,0.6,0.2,0.7,0.8,0.4C867.9,111.2,867.6,111.1,867.3,110.9"
          />
          <path
            id="path5212"
            class="countryIsland"
            d="M879.7,113.7c1-1.1,4.8-0.4,4.9-2.2c-0.9-0.1-1.7,0.1-2.5,0.3c-1.2,0.3-1.7-0.2-2.8-0.3
    		c-0.8-0.1-0.1,0.6-0.6,0.7c-0.9,0.1-1.4-0.6-2.2,0C877.8,112.8,878.6,112.7,879.7,113.7"
          />
          <path
            id="path5216"
            class="countryIsland"
            d="M878.1,122.1c-0.7,0.1-1.1,0.7-0.7,1.3c0.5-0.2,0.9-0.4,1.4-0.5
    		C879.6,122.6,878.4,122,878.1,122.1"
          />
          <path
            id="path5220"
            class="countryIsland"
            d="M887.7,114.5c-1.6,0-2.6,1.3-4,1.9c-1.4,0.6-3.3,0.5-4.1,2.1c1.6,0.4,3.4-0.5,4.7-1.3
    		c1.3-0.8,3.4-1.1,4.2-2.5C888.2,114.6,887.9,114.5,887.7,114.5"
          />
          <path
            id="path5222"
            class="countryIsland"
            d="M880.4,121.4c1,0,1.5-0.9,2.3-1.4c-0.5-0.2-1-0.3-1.6-0.1c1.7,0.6-0.8,0.8-1.4,1.2
    		C879.9,121.2,880.1,121.3,880.4,121.4"
          />
          <path
            id="path5224"
            class="countryIsland"
            d="M883.8,128.9c-1.3,2.2,7,1.2,7.7,1c1.5-0.4,4.2-1,5.2-2.3c0.6-0.8,0.8-2,1.5-2.7
    		c0.5-0.5,1.7-0.8,1.5-1.7c-0.3-1.8-6.2-0.9-7.4-0.7C890.6,122.8,882.6,126.4,883.8,128.9"
          />
          <path
            id="path5228"
            class="countryIsland"
            d="M891.4,111.9c-2,0-3.5,1.3-5.4,1.8C886.8,113.8,892.4,113.1,891.4,111.9"
          />
          <path
            id="path5234"
            class="countryIsland"
            d="M895.3,114c-1.4-0.1-3,2.5-2,2.3c1-0.2,3.6-0.6,3.5-2.1C896.4,114.5,895.8,114.4,895.3,114"
          />
          <path
            id="path5250"
            class="countryIsland"
            d="M901,122.2c1.3,0.3,2.8-2.2,1.3-2.6c-0.7-0.2-1.7,0.1-2.1,0.6
    		C899.5,121.2,900.5,121.4,901,122.2"
          />
          <path
            id="path5252"
            class="countryIsland"
            d="M906.8,124.6c-0.9,0.2-3.5-0.1-2.7-1.2c-0.5,0.2-0.9,0.4-1.3,0.6c-0.6,0.3-1.1-0.3-1.7,0
    		c-1.5,0.8-0.3,2,0.9,2.2c2.1,0.3,5,0.6,6-1.6C907.6,124.6,907.2,124.5,906.8,124.6"
          />
          <path
            id="path5256"
            class="countryIsland"
            d="M904.1,119.7c-0.4,0.2-0.7,0.5-0.8,0.9c0.5,0,1,0,1.5-0.1C904.6,120.1,904.4,119.9,904.1,119.7"
          />
          <path
            id="path5260"
            class="countryIsland"
            d="M905.4,121.2c-0.7,0.4-0.6,1.1,0,1.5C906.1,122.3,906.1,121.7,905.4,121.2"
          />
          <path
            id="path5178"
            class="countryIsland"
            d="M855.1,81.9c0.2-0.2,0.4-0.3,0.6-0.5c-0.5,0.1-0.9,0.2-1.3,0.4
    		C854.6,81.9,854.9,81.9,855.1,81.9"
          />
          <path
            id="path5244"
            class="countryIsland"
            d="M900.2,89.7c1.7,0.5,2.3,1,4.2,0.7c2-0.3,4-0.8,6.1-0.8c2.9-0.1,8.1,1.8,10.6-0.1
    		c-0.8-0.1-0.9-0.4-0.3-0.8c0.2-0.5-0.6-0.6-0.6-1.1c0-0.8-1.2-1.7-1.8-2.2c-1.9-1.7-6.1-1.1-8.4-1c-1.2,0-2.5,0-3.7-0.2
    		c-1.1-0.2-2.1-0.8-3.2-0.8c-0.6,0-2.7,0.6-2.8,1.3c-0.2,1-1,0.7-1.6,1.4c0.5,1,1.7,0.2,2.2,0.9C901.3,87.7,900.3,89.1,900.2,89.7"
          />
          <path
            id="path5246"
            class="countryIsland"
            d="M900,92.6c-0.3,0.1-0.5,0.4-0.6,0.7C899.6,93.1,899.8,92.8,900,92.6"
          />
          <path
            id="path5254"
            class="countryIsland"
            d="M903.1,92.7c-0.3,0.1-0.5,0.4-0.7,0.6C902.7,93.2,902.9,92.9,903.1,92.7"
          />
          <path
            id="path5274"
            class="countryIsland"
            d="M922.6,97.6c0.2,0.1,0.5,0.1,0.8,0C923.2,97.6,922.9,97.6,922.6,97.6"
          />
          <path
            id="path5276"
            class="countryIsland"
            d="M924.3,94.3c-0.3,0-0.6,0.1-0.8,0.3C923.8,94.5,924,94.4,924.3,94.3"
          />
          <path
            id="path5284"
            class="countryIsland"
            d="M927.9,100.7c0.9,0,1.2-1.1,1.9-1.5c-0.5,0.2-1,0.3-1.4,0.4c0.2-0.2,0.5-0.5,0.7-0.7
    		C928.3,98.9,926.3,100.4,927.9,100.7"
          />
          <path
            id="path5286"
            class="countryIsland"
            d="M929.4,97.9c-0.6,0.1-1.2,0.4-1.5,0.9C928.4,98.5,928.9,98.2,929.4,97.9"
          />
          <path
            id="path5292"
            class="countryIsland"
            d="M930.6,98.3c-0.3,0.2-0.5,0.4-0.7,0.6c0.7,0.2,1.7,0.2,1.6-0.8
    		C931.2,98.2,930.9,98.2,930.6,98.3"
          />
          <path
            id="path5296"
            class="countryIsland"
            d="M933.4,102.4c-1.2-0.2-1.9,1.1-3.1,1c0.5,1.2,4.5,0.5,4.6-0.7
    		C934.4,102.6,933.9,102.5,933.4,102.4"
          />
          <path
            id="path5302"
            class="countryIsland"
            d="M938,101.8c-0.6,0-1.1,0.2-1.5,0.6C937,102.3,937.5,102.1,938,101.8"
          />
          <path
            id="path5320"
            class="countryIsland"
            d="M947.8,112.2c-0.6,0.7-1.8,0.5-2.6,0.9C946.2,113.6,947.5,113.4,947.8,112.2"
          />
          <path
            id="path5326"
            class="countryIsland"
            d="M949.6,113c-0.6,0-1.1,0.2-1.6,0.6C948.6,113.5,949.1,113.4,949.6,113"
          />
          <path
            id="path5266"
            class="countryIsland"
            d="M918.5,134.1c-0.2,0.2-0.5,0.4-0.7,0.6C918.5,135,918.7,134.8,918.5,134.1"
          />
          <path
            id="path5268"
            class="countryIsland"
            d="M922.9,171.6c-0.6-0.2-1.1-0.1-1.5,0.4C921.9,172.2,922.4,172.1,922.9,171.6"
          />
          <path
            id="path5272"
            class="countryIsland"
            d="M923.4,183.5c1.8-0.5-0.7-1.3-1.4-1.2C922.1,183.2,922.9,183.2,923.4,183.5"
          />
          <path
            id="path5282"
            class="countryIsland"
            d="M927.2,170.8c-1.6-0.6-1.2,1.5,0.3,1.5C927.4,171.8,927.3,171.3,927.2,170.8"
          />
          <path
            id="path5288"
            class="countryIsland"
            d="M931.1,141.5c-0.5,0-1,0.1-1.4,0.3C930.2,141.8,930.7,141.7,931.1,141.5"
          />
          <path
            id="path5290"
            class="countryIsland"
            d="M931.2,165.6c-0.6-0.1-1.1,0.1-1.5,0.4C930.3,166.2,930.8,166.1,931.2,165.6"
          />
          <path
            id="path5294"
            class="countryIsland"
            d="M933.9,166.2c-1.3-0.7-2.6,0-3.7,0.9C931.8,167.5,932.5,166.7,933.9,166.2"
          />
          <path
            id="path5298"
            class="countryIsland"
            d="M935.1,158.2c-0.2,0-0.4,0-0.6,0c0.2,0.3,0.4,0.5,0.7,0.7C935.1,158.7,935.1,158.5,935.1,158.2"
          />
          <path
            id="path5300"
            class="countryIsland"
            d="M938.5,149.4c-0.7-0.8-2.6,0.8-3,1.4C936.5,150.5,937.9,150.4,938.5,149.4"
          />
          <path
            id="path5304"
            class="countryIsland"
            d="M937.5,136.6c-0.2,0-0.5-0.1-0.7-0.1c0.4,0.5,0.9,0.7,1.4,0.4
    		C938,136.9,937.7,136.8,937.5,136.6"
          />
          <path
            id="path5306"
            class="countryIsland"
            d="M938.5,152.1c-0.6,0-0.9,0.3-0.9,0.9C938.2,153.2,938.5,152.7,938.5,152.1"
          />
          <path
            id="path5308"
            class="countryIsland"
            d="M939.2,157.6c-1.4-0.4-1.4,1.7-0.9,2.4C939.3,159.9,938.9,158.2,939.2,157.6"
          />
          <path
            id="path5310"
            class="countryIsland"
            d="M939.3,151.6c-0.5,0-0.6,0.2-0.1,0.8C939.2,152.1,939.3,151.9,939.3,151.6"
          />
          <path
            id="path5312"
            class="countryIsland"
            d="M940.2,118.6c0.4,0.7,2.4,0.9,3.1,0.6C942.3,119,941.2,118.8,940.2,118.6"
          />
          <path
            id="path5314"
            class="countryIsland"
            d="M941.2,156.8c-0.2,0-0.5,0-0.7,0C941.1,157.2,941,156.9,941.2,156.8"
          />
          <path
            id="path5316"
            class="countryIsland"
            d="M941.8,120c0.2,0.2,0.5,0.2,0.8,0.1C942.3,120.1,942.1,120.1,941.8,120"
          />
          <path
            id="path5322"
            class="countryIsland"
            d="M946,122.4c0.2,0.1,0.5,0.2,0.7,0.1C946.5,122.5,946.3,122.4,946,122.4"
          />
          <path
            id="path5324"
            class="countryIsland"
            d="M948.7,123.5c0.3-0.2,0.5-0.4,0.7-0.7c-0.7,0.1-1.5,0.2-2.2,0.5
    		C947.7,123.4,948.2,123.4,948.7,123.5"
          />
          <path
            id="path5328"
            class="countryIsland"
            d="M952.6,123.4c-0.2,0.6,0,0.7,0.7,0.1C953.1,123.5,952.8,123.4,952.6,123.4"
          />
          <path
            id="path5330"
            class="countryIsland"
            d="M959.7,127.3c-0.4,0.2-0.8,0.5-1.1,0.9c0.5,0.1,1,0,1.5-0.2C960,127.8,959.9,127.6,959.7,127.3"
          />
          <path
            id="path5332"
            class="countryIsland"
            d="M960.7,130.1c0.2-0.1,0.4-0.1,0.6-0.2c-0.8-0.3-1.6-0.6-2.3,0.1
    		C959.6,130,960.2,130,960.7,130.1"
          />
          <path
            id="path5334"
            class="countryIsland"
            d="M960.6,141.6c-0.5,0-0.9,0-1.4,0.1C959.5,142.5,960.8,142.8,960.6,141.6"
          />
          <path
            id="path5336"
            class="countryIsland"
            d="M961.5,131.2c0.2,0.1,0.5,0.1,0.7,0C961.9,131.2,961.7,131.2,961.5,131.2"
          />
          <path
            id="path5338"
            class="countryIsland"
            d="M962.5,131.7c-0.2-0.1-0.5-0.1-0.7-0.2C962.1,131.7,962.3,131.9,962.5,131.7"
          />
          <path
            id="path5340"
            class="countryIsland"
            d="M963.2,137.5c-0.3,0.1-0.5,0.2-0.8,0.4C962.6,137.8,962.9,137.6,963.2,137.5"
          />
          <path
            id="path5342"
            class="countryIsland"
            d="M963,131.6c1,0.5,2.3-0.3,3-0.9C964.9,130.6,963.9,131.2,963,131.6"
          />
          <path
            id="path5344"
            class="countryIsland"
            d="M966.1,130.4c-0.3,0-0.5,0-0.8,0.1C965.5,130.5,965.8,130.5,966.1,130.4"
          />
          <path
            id="path5226"
            class="countryIsland"
            d="M884.8,151c-0.3,0.1-0.5,0.2-0.8,0.3c0.2,0.1,0.5,0.2,0.8,0.3
    		C884.8,151.4,884.8,151.2,884.8,151"
          />
          <path
            id="path5232"
            class="countryIsland"
            d="M889.7,150.5c-0.9-0.1-0.9,1-1.7,1.2C888.9,151.8,889.2,151,889.7,150.5"
          />
          <path
            id="path5236"
            class="countryIsland"
            d="M898.2,164.2c-0.7-0.5-2.3-1-3-0.1c0.6-0.1,1.1,0.1,1.6,0.4c-1.4-0.3-0.6,2,1,1.7
    		C899,165.9,899.7,164.6,898.2,164.2"
          />
          <path
            id="path5238"
            class="countryIsland"
            d="M895.5,163.1c0.3,0.1,0.5,0.2,0.8,0.1C896,163.2,895.8,163.2,895.5,163.1"
          />
          <path
            id="path5240"
            class="countryIsland"
            d="M895.6,162.9c0.2,0.2,0.4,0.3,0.7,0.3C896.1,163,895.9,162.9,895.6,162.9"
          />
          <path
            id="path5248"
            class="countryIsland"
            d="M903.7,181.6c-1.4-0.5-3.6,1.4-4,2.7C900.6,184.2,905.1,183.1,903.7,181.6"
          />
          <path
            id="path5278"
            class="countryIsland"
            d="M928.1,173.5c-0.8-0.9-3.8-1.3-4.7-0.1c-0.3,0.4,1.7,1.3,2,1.5
    		C926.9,175.5,927.3,174.5,928.1,173.5"
          />
          <path
            id="path4998"
            class="countryIsland"
            d="M736.6,120.4c-0.8-0.2-1.7-0.2-2.4,0.4c0.8,0.9,2.1,1.1,3,0.3
    		C737.1,120.9,736.8,120.7,736.6,120.4"
          />
          <path
            id="path5000"
            class="countryIsland"
            d="M754.7,119c-1.1-0.8-3,0-3.7,0.9C752.3,120.3,753.9,120.4,754.7,119"
          />
          <path
            id="path5004"
            class="countryIsland"
            d="M758.7,115.8c-0.5,0.2-1.1,0.5-1.6,0.8C757.7,116.6,758.3,116.3,758.7,115.8"
          />
          <path
            id="path5012"
            class="countryIsland"
            d="M763.9,117.3c-0.8-0.9-2.6,0.6-2.7,1.5C761.2,119.3,764.9,119,763.9,117.3"
          />
          <path
            id="path5016"
            class="countryIsland"
            d="M763.5,113.6c-0.4-1.1-1.6-0.1-1.5,0.7C762.5,114.3,763,114.1,763.5,113.6"
          />
          <path
            id="path5020"
            class="countryIsland"
            d="M765.3,117.7c0.5-0.3,1.1-1.4,0-1.1C764.6,116.7,764.4,117.7,765.3,117.7"
          />
          <path
            id="path5026"
            class="countryIsland"
            d="M781.2,121.4c1.7-0.4,3.6-0.7,5.1-1.5c1.5-0.8,3.4-0.3,4.8-1.4c-1-0.8-2,0.6-3-0.2
    		c0.5-0.6,0.5-1.5,0.9-2.2c-0.7-0.2-1.2,0.1-1.5,0.7c0.3-1.2,1.7-1.5,0.3-2.6c-0.5-0.4-1.1-0.5-1.5-0.8c-0.7-0.5-0.5-1.6-1.5-0.8
    		c1.8-2.7-7.9-1.2-5.4,1.1c-0.9-0.8-3.9-0.1-2.5,1.2c-2.4,1-6,0.5-8.1,1.9c-0.6,0.4-0.6,1.7,0.3,1.6c0.8-0.1,1.7-0.7,2.5-0.5
    		c-0.2,0.2-0.5,0.4-0.7,0.6c1.1,0.7,1.9,0.8,3,0.1c-1.4,1.3,1.8,1.1,2.1,1.7C776.7,121.1,780,120.8,781.2,121.4"
          />
          <path
            id="path5030"
            class="countryIsland"
            d="M775.1,105.2c-0.7-0.1-1.5,0.2-1.7,0.9c0.5,0.2,1,0.3,1.5,0.4c-0.1-0.6,0.2-1,0.9-0.8
    		C775.6,105.6,775.4,105.4,775.1,105.2"
          />
          <path
            id="path5046"
            class="countryIsland"
            d="M790.9,113.3c-1,0.1-2.1-0.1-3.1-0.2C788.4,114.7,790.1,114.8,790.9,113.3"
          />
          <path
            id="path5050"
            class="countryIsland"
            d="M794.6,113.2c-0.9-0.9-3.9-0.2-3.5,1.4c0.8-0.3,1.2-1.5,2.2-1.2c-0.4,0.5-0.7,1-1,1.5
    		C793.2,114.7,794.6,114.4,794.6,113.2"
          />
          <path
            id="path5008"
            class="countryIsland"
            d="M759.6,84.8c-0.4-0.7-1.2-0.2-1.6,0.2C758.5,85,759,84.9,759.6,84.8"
          />
          <path
            id="path5018"
            class="countryIsland"
            d="M762.2,85.5c1.1-0.2,2.2,0.6,3,1.2c-0.2,0.2-0.4,0.5-0.7,0.7c1.4-0.2,0.5,1.9,1.4,2.1
    		c1.4,0.3,2.8-1.1,3.9-1.6c0.7-0.4,7.2-2.3,5.3-3.8C772.7,82.4,764.2,83.2,762.2,85.5"
          />
          <path
            id="path5034"
            class="countryIsland"
            d="M780.3,93.2c0.5,0.4,4.4,0.1,3.5,1.3c1.8-0.8,1.5,0.8,2.3,1.8c1.2,1.5,1.7,2,0.8,3.9
    		c2.7,0.7,4.8,0.3,7.5-1.1c-1.5-1.4,0.7-2.2,1.7-2.7c-0.5,0.4-1.1,0.8-1.6,1.2c1,1.3,3.6,0.9,4.9,0.6c1.2-0.3,2.3-0.9,3.5-1.1
    		c0.4,0,3.6-0.5,1.5-0.7c0.4-0.4,0.9-0.5,1.5-0.4c-0.3-0.2-0.5-0.3-0.8-0.5c0.6-0.2,1.4-0.1,1.8-0.5c0.6-0.6,0.7-1.4-0.3-1.5
    		c2.2,0.2,4.1-0.8,4.8-3c-1.2,0.6-2.6,0.5-3.9,1c0.7-0.4,1.6-1.5,0.3-1.7c0.6,0.1,1.1-0.2,1.1-0.9c-2.2-0.7-4.1-0.1-6.2,0.6
    		c0.3-1.4,2.7-1.6,3.8-1.9c2.2-0.5,4.2-1.2,6.5-1.6c-0.3-1-1.2-0.7-2-0.5c1.2-1.5,3.9-0.2,5.3-1.5c1-0.9-2.5-1.5-3.1-1.2
    		c2.2-0.8-0.7-0.2-1-0.1c-0.9,0.2-1.8,0.5-2.8,0.5c-1.5,0.1-3.4-0.1-4.8,0.7c-0.1-2.3-3.2-1.8-4.4-1.2c0.7,0.3,1.5,0.2,2.2-0.1
    		c-1,0.7-1.8,0.3-2.9,0.2c-1.4-0.2-3.3,0.2-4.5,0.8c0.5,0.3,1.1,0.6,1.6,0.9c-0.6,0.9-1.6,0.3-2.4,0c-1.2-0.4-2.5,0.4-3.7,0.7
    		c1.2,0.9,2.3,1.6,3.9,1.3c0.5-0.1,1-0.4,1.5-0.4c0.7-0.1,0.8,1.1,1.8,1c-0.6,0.3-0.8,0.2-1.4-0.1c-0.9-0.5-2,0-2.7,0.4
    		c2.1,0.1,0.3,2.2-1.1,2.1c0.6-0.1,1-0.6,1.2-1.2c-1.8,0.2-0.9,1.2-2.1,1.8c-1.3,0.6-4.3,0.8-5.7,0.4c0.9-0.3,0.2-0.7-0.1-1.7
    		c-0.5-1.7-3.6-0.4-4.5,0c-0.7,0.3-2.8,0.9-2.7,2c0.1,1.3,2.3,0.7,2.5,2C780.8,93.1,780.6,93.2,780.3,93.2"
          />
          <path
            id="path5070"
            class="countryIsland"
            d="M814,156.6c0.3,1.6-2.2,2.7-2.5,4.2c-0.3,2,5.6-0.3,6.2-0.6c2.5-1.3,4.8-2.8,7.6-3.7
    		c1.5-0.5,1.4-1.4,2.7-2.2c1.2-0.7,2.5-0.4,3.7-1c0.1,0.6-0.3,1-0.8,1.2c0.8,0.6,2,0.5,2.8,0.9c1,0.5-1.6,1.1-1.5,1.4
    		c0.1,0.4,1.3,0.4,1.6,0.4c1.3,0.1,2.1,0.3,3.3,0.9c3.2,1.5,5.8-0.6,9-1.4c-0.2-0.9-1.1-1-1.9-1.1c0.6,0.1,1.1-0.2,1.2-0.8
    		c-0.7,0-0.7-0.5-1.2-0.7c-0.6-0.2-1.1,0.4-1.6,0.4c-1.8,0-3.4,0.2-5.1,0.3c0.8-1.4,2.9-0.3,2.6-2.1c-0.1-0.7,0.9-1,0.9-1.8
    		c-0.1-0.8-0.8-1.4-1.4-1.8c-0.6-0.4-1.6,0-1.9-0.4c-0.6-0.7-1.1-0.8-1.9-1.2c-0.7-0.4-0.2-1.4-0.7-1.6c-0.8-0.4-2.5-0.3-3.4-0.5
    		c0.4-0.7-0.5-1.7-1.1-1.9c-0.8-0.2-2.3,1.6-3.2,1.9c-0.5-0.9,0.9-1.5-0.4-2.1c0.4-0.4,1.1-0.4,1.5-0.8c0.4-0.4,0.1-1.1,0.5-1.5
    		c-0.5-0.3-1-0.3-1.5,0c1.2-1.4-1.5-0.7-2-0.4c-1.3,0.6-2.3,1.6-3.3,2.5c-1.9,1.8-3.6,3.8-5.5,5.6c-0.9,0.9-2,1.6-2.7,2.7
    		c-0.6,0.9-0.1,2.4-1.4,2.8c-2.8,0.9-5.9,1-8,3.3c1,1.3,2.7,0.2,3.9-0.1C810.2,157,812.1,157.6,814,156.6"
          />
          <path
            id="path5080"
            class="countryIsland"
            d="M811.9,89.6c-0.8,0.1-1.7,0.2-2.1,1.1C810.7,90.7,811.5,90.4,811.9,89.6"
          />
          <path
            id="path5086"
            class="countryIsland"
            d="M813.7,87.5c-0.6-0.1-3.5,0.5-3.1,1.5C811,90.3,814.4,88.7,813.7,87.5"
          />
          <path
            id="path5096"
            class="countryIsland"
            d="M816,94.7c-0.8,0.1-1.6,0.1-2.2,0.6c0.8,0.6,2.5,0.5,3.5,0.5c0.6,0,1.2,0,1.7-0.1
    		c0.4-0.1,1.8-0.8,0.3-0.2c1.9-1,3.8-1.7,5.7-2.6c2.4-1.1,0.3-2.5-1.3-2.4c3.5-1.4,6.9,0.2,10.5,0.2c1.5,0,2.9-0.7,4.1-1.4
    		c0.7-0.4,1.3-0.8,2-1.1c0.6-0.3,2.7-0.8,1.1-1.2c2.1-0.1,3.8-2.2,5.7-2.2c2.1,0,3.8-2.6,5.9-2.2c-1.9-1.2-5.2-1.5-7.4-1
    		c-0.4,0.1-2.5,0.9-2.7,0.2c-0.4-1.2-1-0.7-2.1-1c-1-0.3-2.3-0.6-3.4-0.3c-1.4,0.3-2.6,0.2-4,0.4c-1.9,0.2-5-0.1-6.5,1.4
    		c0.3,0.1,0.6,0.3,0.9,0.4c-0.5,0.5-0.4,1,0.3,1.2c-0.8,0.4-1.5,0.1-2-0.4c-0.7-0.5-1.7-0.1-2.3,0.2c-1.9,0.8-2.4,3-4,4.1
    		c-0.7,0.5-3.7,1.8-2.9,3c1,1.5-1.1,2.6-2.1,3.8c0.7,0,1.5-0.1,2.2-0.1C816.5,94.7,816.3,94.7,816,94.7"
          />
          <path
            id="path5106"
            class="countryIsland"
            d="M817.2,166.7c-0.5,0.8,0.6,1.5-0.3,2.2c1.2,0.3,2.3-1,3.4-0.1c0.8,0.7,2.7-0.7,3.4-1.1
    		c2.8-1.5,5.4-2.4,7.9-4.3c-2.3-1.7-5,0.5-7.4,0.3c-1.3-0.1-1.9-0.4-3.1,0.6C819.9,165.5,818.6,165.9,817.2,166.7"
          />
          <path
            id="path5108"
            class="countryIsland"
            d="M817.5,112c0.6-0.1,1-0.6,1.2-1.1C818.1,111,817.6,111.4,817.5,112"
          />
          <path
            id="path5112"
            class="countryIsland"
            d="M819.3,115.8c1.6-0.1,0.7-1.9-0.6-0.6C819.6,114.9,819.8,115,819.3,115.8"
          />
          <path
            id="path5114"
            class="countryIsland"
            d="M819.9,112.7c-0.3,0.1-0.5,0.1-0.8,0.1C819.4,113,819.7,112.9,819.9,112.7"
          />
          <path
            id="path5116"
            class="countryIsland"
            d="M819.5,119.3c0.6,0.2,1.1-0.1,1.4-0.6C820.3,118.5,819.8,118.8,819.5,119.3"
          />
          <path
            id="path5118"
            class="countryIsland"
            d="M822.8,114.2c-0.4-0.5-1.9,0.4-2.3,0.6C821.3,115.9,822.2,114.8,822.8,114.2"
          />
          <path
            id="path5136"
            class="countryIsland"
            d="M831.7,139.6c-1.5-0.5-3.1,1.3-1.8,2.6C831.5,143.8,831.6,140.5,831.7,139.6"
          />
          <path
            id="path5140"
            class="countryIsland"
            d="M836,123.5c0.7-1.1-0.5-0.9-1.2-0.7c-1.2,0.4-1.9,1.1-2.6,2c-0.4,0.5-1.2,1.8,0.2,1.7
    		C834.1,126.5,835,124.7,836,123.5"
          />
          <path
            id="path5142"
            class="countryIsland"
            d="M837.4,139.4c-0.4-0.4-2.8-1.6-2.8-0.2c0,0.3,0.5,0.4,0.7,0.5c0.5,0.2-0.2,1.4-0.4,1.7
    		c0.7,0,1.5,0,2.2,0c-0.3,0.2-0.5,0.4-0.8,0.6c0.8,0.1,2.4,0.8,2.7-0.4c-0.8,0.1-1.5-0.3-2.2-0.6
    		C837.8,140.9,838.5,139.8,837.4,139.4"
          />
          <path
            id="path5144"
            class="countryIsland"
            d="M836.5,172.7c1,2,3.7-1.3,4.7-1.9c0.8-0.5,4-3.5,1.3-3.9C840.7,166.6,833.3,171.4,836.5,172.7"
          />
          <path
            id="Canada_Baffin_Island"
            class="countryIsland"
            d="M837.5,99.6c-0.8,1.7,10,1.3,10.4,1.4c-1.2,1.2,1.8,1.8,2.5,1.8
    		c-2.3-0.1-4.5,1.1-6.8,0.4c-1.8-0.5-4.2-1.3-6.1-1c0.2,0.2,0.4,0.5,0.6,0.7c-0.4,0.2-1.5,0.5-1.6,1c-0.2,0.7,0.8,1.2,0.7,1.9
    		c-0.1,1.3,3,1.3,3.8,1.7c-0.5,0.1-1,0.1-1.5,0c0.6,0.2,4.1,0.6,2.3,0.3c1.5-0.3,3,0.4,4.5-0.1c-0.2-0.2-0.5-0.4-0.7-0.6
    		c1-0.6,2.9,0.1,1.5,0.2c1.1,0.6,2.8-0.5,3.8-0.9c-0.6,0.3-2.8,1.1-2.3,2.1c0.7,1.7,3.8,1.6,5.2,1.4c-0.7-0.4-1.5-0.6-2.3-0.4
    		c1-0.5,2-0.3,3-0.1c1.1,0.1,1.8-0.4,2.5,0.3c1.1,1,3.6,0.6,4.9,0.5c1.6-0.1,3.2-0.4,4.8,0.1c0.5,0.2,1.1,0.3,1.6,0.5
    		c0.1,0,1.4,0.8,1.5,0.8c0.7,0,2.4-0.7,3.1-1.1c-0.7-0.1-1.2-0.6-1.7-1.1c-0.9-0.7-2.1-0.8-3-1.4c2.8,0.6,5.1,1.9,7.3,3.6
    		c0.5,0.4,2,0,1.2-0.6c-0.5-0.4-0.6-1-1.2-1.1c-0.6-0.1-0.9,0.2-1.4-0.3c2.4-1.9,6.9,2.2,9.2,0.6c0,1.6,4.9,0.8,5.8,0.6
    		c1.1-0.3,1.8-1.5,1.6-2.6c-0.1-0.6-2.6-0.9-3.1-0.9c0.4-0.3,0.9-0.5,1.3-0.6c0.5-0.2,0.7,0.7,1.2,0.2c0.5-0.5,1.9-2,2.7-1.3
    		c-0.5,0.3-1,0.5-1.6,0.6c0,0.6,0.2,0.8,0.8,0.7c0.7-0.1,0.1,1,1.1,0.6c-0.5,1.4,1.6,0.8,2.2,0.7c2.2-0.2-0.4,2.8-0.7,3.4
    		c1.5,0.5,3.1-1.1,4.5-1.1c-0.8,0.2-1.6,0.3-2.3,0.6c0.8,0.4,1.5-0.2,2.3,0.3c-0.2,1.2-1.7,0.9-2.5,1c1.1,1.5,3.9,0,5.3,0.1
    		c-0.9,0.4-2.2,0.1-2.9,0.8c-0.5,0.5,3.7,1.5,3.5,2.7c-0.2,1.5-3.9,1-4.9,1c-0.9,0-1.7,0.2-2.4,0.9c-0.1,0.2-0.1,0.4,0,0.6
    		c0,0.4-1,0.7-1.2,1.2c2.1,0.4,6-0.7,7.6-2.1c0.9-0.8,1.1,0.5,2,0.5c1.2,0,1.6-1.6,3-1.1c-0.3,0.3-3.5,1-1.6,0.8
    		c-1.9,0.5,0.5,0.1,0.8,0.1c-0.4,0.4-0.9,0.7-1.5,0.9c0.6,0.3,1.1,0.2,1.5-0.2c-1.4,0.5-0.1,2,0.7,2.3c1,0.4,2.7-0.3,1.3-1.3
    		c0.8-0.1,1.6-0.1,2.3,0.4c-0.4,0-1.5,1-1.8,1.4c-1,1.3,0.4,0.7,0.3,1.5c0.8-0.3,1.7-0.4,2.5-0.8c-0.4,0.3-0.9,0.5-1.4,0.7
    		c0.8-0.3,1.4,0.6,2.2,0.4c-0.5,0.7-0.5,1.7-1.2,2.2c1.8,0.8-0.5,3.6,0.7,4.9c-1.1,0.4-1.8,1.2-3,1.4c-1.1,0.2-2,0.5-2.9,1.2
    		c-1.7,1.4,1.2,1.3,2.1,1.4c1.8,0.2,4.3,1.5,6,0.1c1.9-1.6,2-2.9,4.8-2c-1.8,0.4,0.1,0.1-0.2,0.8c-0.2,0.6-0.2,0.8,0.7,0.9
    		c0.8,0.1,5.7,1.3,3.8,1.8c0.5,0,1-0.1,1.5-0.3c-0.8,0.3-1.6,0.4-2.4,0.8c0.7,0.3,1.5-0.6,2.1,0.1c-0.5,0.1-0.9,0.2-1.4,0.2
    		c0.6,0.2,1.1-0.1,1.7,0.1c-1,0.2-1.9,0.8-2.8,1.2c-1,0.4-2.3-0.2-3.5,0.1c-1.3,0.3-2.1-0.6-3.4-0.1c-1.5,0.6-2.9,2.1-4.6,2
    		c0.5-0.6,1.3-0.5,1.8-1c-1.3-0.3,0.3-0.7,0.7-1.7c0.2-0.7,0.3-1.6-0.5-1.9c-2.1-0.8-4.3-0.9-6.5-1c-1.7-0.1-13,2.8-12.6,4.7
    		c0.2,1.1,1.8,1.5,2.2,2.6c1,2.8-2.2,1.3-3.5,1.7c-1.2,0.4-2.3,0.8-3.4,1.3c-0.3,0.1-2.3-0.3-2.8-0.3c-0.8,0-0.8,1-2,0.9
    		c-1.1-0.1-2.3-0.7-3.3,0c0.9,0.6,1.7,2.2,2.5,1.7c-0.6,0.8-1.7,0.6-2.3,1.3c0.5,0.4,0.3,0.5,0.4,1c-0.9,0.1-1-0.7-0.8-1.3
    		c0.2-0.6,1.4-0.1,1.6-0.8c0.4-1.4-2.2-1.8-3.1-2.1c-0.7-0.2-6.8-1.8-6.3-0.4c-0.8-0.3-1.1,0.8-1.5,1.2c-0.5,0.7-1.6,0.6-2.3,0.8
    		c-0.9,0.2-1.9,0.4-2.6,0.9c-1,0.7-1.3,2-2.7,2c1.5-0.2,0.2,0.6,0.4,1.4c0.1,0.7,0.7,1.2,1.3,1.6c1.4,0.8,3.2,0.3,4.7,0.9
    		c0.8,0.3,0.4-0.5,0.9-0.7c0.7-0.4,1.6,0.2,2.3,0.1c0.7-0.1,1.3-0.4,2-0.5c0.5-0.1,1-0.2,1.5-0.1c-0.3-0.8-0.1-1,0.7-0.5
    		c-1.5-1.9,3-0.2,3.4-0.1c0.9,0.3,2.4,0.9,3.2,0.1c1.1-1.2,0.4-1.6-0.6-2.6c1.1,0,1.9-0.7,3-0.9c-0.5,0.5-2.1,1.1-1,1.9
    		c0.7,0.5,2.2-0.2,2.7-0.7c0,0.9-1.7,1.1-0.2,1.5c-0.9-0.2-0.9,1-1.6,1.3c0.8,0.2,2.4-0.8,2.2-1.7c0.6,0.6,1.7,0.5,1.8-0.5
    		c0.6,0.2,0.8,0.6,0.4,1.2c0.9,0,1.2-1.1,2.2-0.8c-0.6,0.5-1.4,0.7-2,1.2c-0.5,0.4,0.3,1.8,0.6,2c0.2,0,0.3-0.1,0.4-0.1
    		c0.4-0.1,0.4,1.5,0.5,1.6c0.2-0.2,0.5-0.4,0.7-0.6c0,0.6-0.2,1.1-0.6,1.5c0.8,0.9,2.1,0,3-0.1c-0.9,0.4-1.6,1-2.2,1.7
    		c1-0.5,2.1-0.1,3-0.5c-1.1-0.1-0.6-0.3,0-0.6c0.3,1.2,2.1,1.2,3,1.6c-1.7,1.4-3.9,1.5-6,1.2c1.5,0.2,1.1,1.2,1.8,2.2
    		c1,1.3,3,0.9,4,0c-0.5,0.7-1.3,1.1-2.2,1.2c1.5,0.1,2.6,1.3,4,1.8c1.7,0.7,3.6-0.1,5.3,0.1c-2,0.1-1.2,1.6-0.1,2.4
    		c1.2,0.9,3.1,1.6,4.6,1.8c2.3,0.3,4.1,0.4,6.2,1.3c1.8,0.8,3.7,1.7,5.8,1.2c-0.2-0.5-0.3-1-0.4-1.4c0.6,0.1,1.2-0.2,1.4-0.8
    		c-0.6-0.1-0.9-0.5-0.6-1.2c-0.5-0.1-1-0.2-1.5-0.1c0.9-0.1,1-0.3,0.4-0.6c-0.2-0.6,0.4-0.9,0-1.5c-0.4-0.5-1.7-0.6-2.2-0.7
    		c0.1-0.2,0.1-0.5,0.2-0.7c-1.1,0-0.8-2.1-2.2-1.2c1.6-0.6-0.3-0.7-0.9-0.1c0.2-0.6,0.7-0.9,1.3-0.9c-2.4-0.3-3.8-1.9-4.4-4.2
    		c-0.5-2.1,3.1-0.2,3.5-0.5c-0.1,0.9,1.3,2.1,2,2.4c0.2-0.9,0.5-2,0.3-3c0.4,0.9,2.5,5.4,3.5,3c-0.1,0.5-0.2,1-0.1,1.6
    		c0.2-0.2,0.5-0.5,0.7-0.7c0.1,0.8-0.6,1.7,0.4,2.1c-0.9-1.2,1.2-0.7,0.6-2.2c0.9,1.2,0.1,1.7,1.6,2.7c0.2-0.5,0.2-1,0-1.4
    		c0.4,0.7,1.4,1.4,2.2,0.7c-0.3,0.2-0.5,0.4-0.8,0.6c0.6,0.1,1.2-0.1,1.5-0.6c-1.2,1.5,1.1,2,2,0.9c0.2,0.5-2.3,3.3,0.3,2.7
    		c1.3-0.3,0.2-2.4,0.3-3.2c1-0.1,1.4,1.2,2.3,1c1.2-0.2,0.6-1.7,0.7-2.5c-0.6,0.3-0.9,0.1-0.9-0.6c0.6,0.1,1.1-0.2,1.4-0.6
    		c-0.3-0.1-0.5-0.3-0.8-0.4c0.8-0.3,0.7-1.3,1.6-1.4c-0.1-0.5-0.4-1.1-0.7-1.5c0.6,0.5,1.1,1.2,1.1,2c0,0.5-0.3,1.1,0,1.6
    		c0.5,0.8,1.3,0.1,0.9-0.7c1.4,0.2,1-1.7,1.7-2.3c-0.5-0.3-1-0.5-1.5-0.5c0.5-1.1,1.5,0.4,2.5,0.6c-0.3-0.2-0.5-0.5-0.8-0.7
    		c0.2-0.1,0.9-0.4,0.9-0.7c0-0.5-2,0.4-2.3,0.3c0.4-0.5,1-0.8,1.6-0.9c-0.7-0.5-1.5-0.5-2.3-0.4c1-0.4,0-1.1-0.7-1
    		c1.3-0.5,0.3-0.8-0.3-0.7c0.6-0.8,1-0.1,1.7-0.5c0.7-0.4,1.4-0.8,1.8-1.6c-0.7,0.1-1.4,0.1-2,0.5c1.6-1.2-0.7-0.5-1.5-0.4
    		c0.1-0.9,1.2-1.1,1.7-1.7c-1.8,0,1-1.3-0.7-1.2c-0.7,0-0.8,1.4-1.7,1.2c0.1-0.3,0.3-0.5,0.4-0.8c-0.7,0.1-1.1,0.6-1.7,0.9
    		c0.3-0.5,0.7-1,1.1-1.4c-0.8,0.1-1.7-0.3-1.8-1.2c-0.5,0.4-1,0.9-1.4,1.4c0-1,1-1.3-0.3-1.6c0.6-0.1,1.1-0.3,1.4-0.8
    		c-0.8,0.1-1.4,0.8-2.2,0.6c0.6-0.2,1.1-0.6,1.4-1.1c-1.1-0.1-0.5-1.5-2-0.9c0.8-0.4,2,0.1,2.5-1c-0.5,0-1,0-1.5,0.1
    		c0-0.6,0.4-1,0.9-1.2c-1-1.4-3.4,0-4.5,0.6c0.8-0.6,1.5-1.3,2.2-2c-0.5-0.1-1-0.2-1.5-0.1c0.5-0.7,0.3-1-0.6-0.9c0.9,0,1-0.4,0.4-1
    		c0.5,0.7,1.6,0.6,1.5-0.4c-0.4,0-0.8-0.2-1.2-0.4c-0.8-0.4-1.7,0-2.5-0.2c2-0.3,2.7,0.3,4.5,0.7c-0.6,0.2-1.1,1.5-0.1,1.6
    		c0.9,0.1,2.9-0.2,3.8-0.6c1.3-0.6-0.2-1.3-0.7-1.7c-0.7-0.5-0.3-1.2-0.6-1.9c1.4-0.3,2,1.7,3.3,1c-0.2-0.2-0.5-0.5-0.7-0.7
    		c0.5,0,1-0.1,1.5-0.3c-0.5-0.8-1.3-0.8-2.2-0.9c1.1-0.6,3.9,0.9,3.6-0.6c0.5,0.2,1,0.3,1.5,0.4c-1.5,0.3-0.6,2.1,0.6,1.4
    		c-0.2,0.5-0.5,1-0.9,1.4c0.8,0.2,1.5-0.3,2.2-0.4c-1.7,0.4,0.3,1.4,1.2,1.1c1.2-0.4,2.3-1.2,3.2-1.9c-0.7,0.7-4.7,2.1-4.3,3.3
    		c2-0.5,4.1-0.3,6.1-1.1c1.5-0.6,2.7-1.9,4.4-1.9c-1.1,0.7-4.2,0.9-3.8,2.8c-1.8-0.2-5.1,0-5.5,2.2c1.3,0.3,2.5-0.5,3.7-0.4
    		c-1,0.1-2,0.9-3,0.7c0.9,1.3,2,0.2,3-0.2c-0.3,0.3-3.1,1.6-3,1.8c0.3,0.6,3.2-0.2,3.8-0.2c-1,0-2.1,0.4-3,1
    		c1.4,0.2-0.4,1.7,1.3,1.2c0.8-0.2,1.7-1.4,2.4-1.9c-0.5,0.3-0.9,0.8-1.3,1.3c0.5,0.3,1,0.4,1.5,0.2c-0.6,0.1-1,0.5-0.9,1.1
    		c0.6-0.7,1.6-0.2,2.2-0.9c-1.4,1,1.1,0.8-0.6,1.2c0.6,0.1,1,0.4,1.1,1c0.6-0.7,1.5-1.2,2.1-1.8c0.4-0.3,0.8-0.8,0.8-1.3
    		c0.1-0.7-1-1.3,0.5-1.1c-0.2-0.3-0.4-0.5-0.7-0.8c0.5,0.1,1,0,1.5-0.3c-0.4-0.4-0.9-0.6-1.4-0.6c0.9-0.4,2.4-0.3,1.8-1.7
    		c0.6,0.8,2.6,3.6,2.8,1.1c-0.4,1,0.9,0.9,1.4,0.7c-0.3-0.2-0.5-0.3-0.8-0.5c0.7,0,1-0.2,0.6-0.9c0.5,0.2,3.8,0,2.5-0.9
    		c-0.5-0.4-2.1-0.4-2-1.2c1.1,1.4,3.1,0.5,4.5,0.7c-0.1-1.9-3.3-0.6-3.4-2.3c0.7,1,2.1,0.5,3,0.2c-0.1-0.6-0.5-1-1.1-0.7
    		c1-0.6,1.6,0.1,2.5,0.6c1.2,0.5,2.4-0.2,3.6-0.3c-0.7-0.4-1.5-0.5-2.2-0.3c0.7-0.5,1.5-0.4,2.2-0.5c-0.2-1.4-1.5-1.3-2.6-1.1
    		c0.7-0.3,1.4-0.7,2.2-0.2c0.7,0.4,1.1,0.9,2,0.6c1.4-0.4,0.6-2.2-0.7-2c2.1-0.5-1-0.5-1.4-0.4c0.5-0.2,1-0.3,1.5-0.2
    		c-0.4-0.6-1.1-0.5-1.5,0c0.8-0.9-0.3-1.1-0.9-0.7c-0.7,0.6-1.6,0.7-1.4,1.9c-0.2-0.4-0.5-1.7-1.2-1.5c-1.1,0.3-1.4,2-2.6,2
    		c0.7-0.6,1.4-1.3,1.7-2.2c-1.8-0.1-2.9,0.9-4.2,1.9c1.2-1.3-1-0.6-1.8-0.7c1-0.1,3-0.6,3.7-1.2c0.5-0.4,0.6-1.1,1.3-1.3
    		c0.7-0.2,1.2-0.4,1.6-1c-1.3-1.1-4.1,1.5-5.3,1.9c0.8-0.4,1.6-0.6,2.3-1.2c-1.8-1.6-6,0.7-7.8,1.3c0.8-1.8,3.5-1.2,5-1.7
    		c-1.3-0.6-3.3,0-4.6,0.4c1-0.5,2.1-0.3,3.1-0.6c-0.7-0.3-1.5-0.4-2.2-0.5c1.1-0.2,2.2,0.4,3.4,0.3c1.8-0.1,1.1-1.4-0.4-1.2
    		c0.8-0.2,1.7-0.3,2-1.2c-0.8,0-0.9-0.1-0.6-0.8c-0.8,0-1.5,0.2-2.3,0.4c0.5-0.2,1-0.3,1.5-0.4c0.1-1.6-1.8-0.5-2.3,0.1
    		c0-1.5-1.7-0.3-2.3,0.4c0.5-1.1,2.3-2.2,3.5-2.3c-0.8-1.8-4.6,0.6-4.5,2.4c-0.8-0.7-0.3-1.6,0.5-2c-1.1-1.6-3.4,1.2-4.2,1.9
    		c0.4-1.5,2.8-2.5,2.9-3.8c-0.7,1.1-4.3,2.6-5.5,2.2c1.2,0,1.9-0.7,3-1.1c-0.6-0.3-1.2-0.6,0.1-0.7c-0.8-0.6-1.8-0.3-2.2,0.6
    		c0.9-1.4-0.7-0.5-1.2-0.3c0.5-0.5,1-0.9,1.5-1.3c-0.8-0.1-1.6-0.5-2.4-0.1c-0.7,0.4-1.3,1-2.1,0.8c1.1,0.1,1.9-1.1,3-1.2
    		c-0.9-0.7-2.3-0.3-3,0.4c1.8-1.1-0.2-0.5-0.8-0.4c2.1-1,4.5-0.4,6.7-0.9c-1.1-0.5-2.2,0.3-3.2,0c-0.9-0.3-1.4-0.9-1.9,0.3
    		c0-0.6,0.3-1,0.9-0.9c-1.2-0.6-2,0.2-3,0.9c0.3-0.2,0.5-0.5,0.7-0.8c-1.3-0.5-2.5-0.5-3.8-0.4c1.8-0.4-0.5-0.4-0.8-0.2
    		c1.3-0.9,3.1-0.5,4.6-0.6c-0.9-0.6-2.1-0.5-3.1-0.5c-0.9,0-2.1-0.6-3-0.6c1,0,1-0.1-0.1-0.3c1.3-0.2,2.5,0.3,3.8,0.4
    		c1.3,0.1,2.6-0.1,3.9,0.2c-0.7-0.4-1.5-0.6-2.2-0.8c0.7-0.2,1.4,0.2,2.2,0.1c-0.5-0.7-1.4-1-2.2-0.8c1.4-0.6,3.1-0.1,4.5-0.3
    		c-1.7-1.5-5.2-1.7-6.8,0.1c0.7-0.7,0.6-0.9-0.3-0.6c1-0.2,2.2-0.2,3.1-0.6c-0.4-0.1-0.8-0.2-1.2-0.2c1.3-0.3,2.6,0,3.9,0.1
    		c-1.1-1.3-3.9-1.1-5.3-0.6c1.9-1.2,4.9-0.3,6.8,0.5c1.3,0.6,2.3,0.4,3.7,0.3c0.4,0,4.1,0.7,2.8-1c-1.5-2-5.2-0.9-7.3-1.6
    		c-1.4-0.4-2.5-0.4-3.9-0.1c-1.8,0.4-3.7,0-5.5,0.2c1.7-0.7,3.7-0.1,5.5-0.3c2-0.3,4-0.5,5.9-1.2c0.9-0.4,6,0.5,6-1
    		c-0.1-1.1-1-2.7-2.1-3c-1.8-0.6-3.5,1.5-5,2.2c-1.5,0.7-3.1,0.4-4.7,1.1c-0.7,0.3-1.3,0.2-2.1,0.2c-1.1-0.1-2,0.7-2.9,1.3
    		c2.4-2.7,7-1.3,9.8-3.7c-1.3-1.2-4-0.5-5.5,0c-0.8,0.3-1.1,1.3-2,1.2c-1.2-0.2-2.4,0.7-3.6,0.8c1.4-0.6,2.8-0.8,4.2-1.2
    		c-0.5-0.5-1-0.5-1.5-0.1c2.5-1.3,5.4-1.7,8.1-2c1-0.1,2.1-1.3,3-1.2c0.3,0.1,2-0.1,1.1-0.7c-1.2-0.8-4.1-1.8-5.4-1.5
    		c-1.4,0.3-2.5,1-3.9,1.1c-0.5-0.1-1,0-1.4,0.2c-0.3,0.4,0,0.8-0.8,0.7c0.5-1.8,3.8-1.7,5.1-2.4c-1.5-1-4.6,0.4-6.1,0.9
    		c-2.8,1-4.6,3.5-7.3,4.6c1.6-1,2.9-2.3,4.5-3.4c-2-1.2-3.1,1.3-4.9,1.6c1.1-0.3,0.6-0.6,0-0.9c1.3-0.3,2.3-1.6,3.6-1.4
    		c1.2,0.1,1.7-0.1,2.9-0.5c1.2-0.4,3.6-1,4.2-2.3c0.9-1.8-4.3,0.6-4.6,0.7c-1.4,0.3-2.9,0.6-4.3,0.8c-1.4,0.2-2.9,1.2-4.3,1.1
    		c1.2,0,2-0.8,3.1-1.2c-0.7-0.4-1.5-0.1-2.3-0.1c2-0.4,4.6-2.3,6.6-1.9c1.1,0.2,3.5-0.3,3.9-1.6c0.7-2.1-4.5-2.2-5.5-2
    		c-2.9,0.6-4.9,3.3-7.8,3.9c1.1-0.3,1.9-1.6,3.1-1.5c-2.1-1.7-3.3,1.5-5.3,1.4c1.4-0.2,2.5-1.5,3.8-2.2c-1.8,0.5,0-1,0.4-1.2
    		c-1.6-1.1-4.1,2-5.5,2.4c0.9-0.4,1.6-1,2.3-1.7c-0.5,0-1.1,0-1.6,0.1c1.8-0.5,3.4-1.7,5.3-2.1c-1.2-1-3.1,0.1-3.7,1.2
    		c0-0.6,0.3-0.9,0.9-1c-0.9-0.7-2.7,0.2-3.5,0.7c-0.6,0.3-0.7,0.8-1.1,1.3c-0.6,0.7-1.8,1-2.7,1.4c0.6-0.4,1.3-0.7,2-1.1
    		c0.8-0.4-0.3-1.4-1-0.9c1-0.6,2.2-0.4,3.2-1c-1.3-0.7-3.1,0.5-4.5,0.7c1.1-0.3,2.1-0.6,3.2-0.9c-0.7-0.4-1.5-0.2-2.3-0.1
    		c1.7-0.3,3.5-0.5,5.2-0.8c1.5-0.3,2.1-0.8,3.4-1.3c-2.2-3.7-9.4,2.2-11.9,1.9c1.2,0,2.1-0.7,3.1-1.4c1-0.6,2.2-0.6,3.1-1.3
    		c-2.5-0.8-4.6,0.8-6.9,1.4c1.5-0.8,3.1-1.6,4.8-1.7c1.4-0.1,3.1,0.3,4.3-0.7c2.1-1.6-2.6-3.7-4-1.8c0.8-1.4-4.4-1.9-5.5-1.9
    		c-1.8,0-3.7,0.4-5.4,0.9c-0.4,0.1-2.7,0.8-2.3,1.5c0.7,1.3,4.6,1.1,5.8,1.7c-1-0.3-2.6-0.5-3.4,0.4c0.2-0.2,0.5-0.5,0.7-0.7
    		c-0.9-0.8-3.7-1.3-4.2,0.2c0.5-1.3-1.2-1.3-1.4-0.4c-0.1,0.4,2.4,2.7,2.9,2.8c-0.9,0.2-1.1-0.9-1.9-1c-0.4,0.6-0.2,1.1,0.5,1.2
    		c-1.9,0.1-0.7-4.2-3.7-1.6c0.3,0.2,0.5,0.3,0.8,0.4c-0.8,0.2-1.5-0.3-2.3,0c0.9-0.9,2.2-1.3,3-2.3c-1.2-1.3-3.2-0.2-4.5,0.5
    		c0.9-0.4,1-1.3,1.5-2c-0.8,0.3-4.6,1.7-2.2,2.4c-1.4,0.3-1.3-1.9-3-0.1c-1.2,1.2-2.9,1.7-4.6,1.9c0.3-0.1,0.5-0.3,0.8-0.5
    		c-0.3-0.2-0.5-0.4-0.8-0.6c0.9-0.5,2-0.1,3-0.3c-2.2-0.6,2.2-2.1,2.6-2.5c-1.6-1.1-3.9,1.1-5.4,1.4c2.9-1,5.5-2.7,8.5-3.4
    		c-1.4-1,0-1.8,0.8-2.5c-0.5-0.8-1.4-0.8-2.2-1.1c2.2-2.6,0.8-3.6-2.3-3.3c-1.7,0.2-3.4,0-5.1,0.3c-1.9,0.3-3.7,0.8-5.6,1.2
    		c0.6,0.2,0.9,0.6,0.5,1.2c-1.1-1.2-3.3-0.9-4.7-0.4c-0.5,0.2-0.5,1.2-1.3,0.5c-0.7-0.6-2.1,0.1-2.6,0.6c1.7,1.4,4.1,0.5,5.9,1.6
    		c-1.7-0.2-3.8,0-5.4-0.5c-0.8-0.3-3-0.4-3.7,0.3c1.8,0.7,4.2,1.8,6,1.9c-2.1,0.2-3.9-1.3-5.9-1.4c-1,0-5.2,2.1-3.2,2.7
    		c-0.7,0-1,0.4-0.5,0.9c1,0.9,2.4,0.2,3.5,0c-2,0.6,0.3,0.6,0.8,0.6c-4.5,0.6,0.5,1.3-0.9,3c-0.1-1.6-2.3-2-3.6-2.2
    		c-0.9-0.1-1.4-0.1-2.1,0.6c-1,1-2.2,1-3.6,1.1c0.3,0.2,1.8,1,1.6,1.5c-0.2,0.2-0.5,0.2-0.8,0.1c0.6,1.1,2.6,1.4,3.7,0.8
    		c-0.1,1.7-1.6,1.8-2.5,3c-0.6,0.7-2.4,2.5-3.4,2.4c0.5-0.3,0.7-0.9,0.1-1.2c0.5-0.1,1-0.1,1.4-0.1c-1.7-2.1-8.6,1-10.7,0.9
    		c0.9-0.6,2-0.5,2.9-0.7c1.2-0.2,2.3-0.9,3.5-1c1.2-0.1,2.1,0.1,3.4-0.3c0.4-0.1,2.2-0.2,2.4-0.6c0.5-0.9-5.9-2.7-3.1-5.9
    		c0.8-0.8,1.9-1,2.7-1.7c0.8-0.8-1.1-1.3-0.2-2.3c1-1,2.6-1.4,3.8-2c1.8-0.9,3.5-1.9,5.4-2.6c1.9-0.7,3.8-1.2,5.8-1.7
    		c1.9-0.5,0.2-1.2-0.8-1.2c-4.1,0-8,0-12,0.6c-4.1,0.6-7.9,2.4-11.7,3.8c-1.3,0.5-2.6,1.1-3.4,2.3c-0.7,1.1-1.4,0.3-2.4,0.9
    		c-1.1,0.6-2.1,1.5-3.2,2c-0.6,0.3-3.2,1.9-1.3,1.9c-1.2,0.8-2.8,0.8-3.7,2.2C839.5,98,838.6,98.5,837.5,99.6"
          />
          <path
            id="path5148"
            class="countryIsland"
            d="M839.7,140.8c-0.3-0.2-0.5-0.1-0.8,0.2C839.2,141,839.4,140.9,839.7,140.8"
          />
          <path
            id="path5160"
            class="countryIsland"
            d="M844.5,137.6c-0.4,0.1-1.2-0.3-1.4-0.6C842.6,138,843.9,138.2,844.5,137.6"
          />
          <path
            id="path5174"
            class="countryIsland"
            d="M853,165.6c-0.1,0.6,0.1,0.6,0.7,0C853.5,165.6,853.3,165.6,853,165.6"
          />
          <path
            id="path5176"
            class="countryIsland"
            d="M854.3,165.6c-0.1,0.6,0.1,0.6,0.7,0.1C854.8,165.7,854.5,165.7,854.3,165.6"
          />
          <path
            id="path5180"
            class="countryIsland"
            d="M856.7,158.7c-0.8,0-1.5,0.4-2.3,0.4c0.1,2.2,2.3,3.2,4.1,2.2C861.4,159.5,858,159,856.7,158.7"
          />
          <path
            id="path5186"
            class="countryIsland"
            d="M861.8,122.2c-0.4-0.4-1.1-0.4-1.5-0.1C860.8,122.2,861.3,122.3,861.8,122.2"
          />
          <path
            id="path5188"
            class="countryIsland"
            d="M862.8,154.8c-0.5-0.5-1.7-0.1-2.2,0.2C861.3,155.3,862.1,155.3,862.8,154.8"
          />
          <path
            id="path5190"
            class="countryIsland"
            d="M865.6,158.7c0.8-1.5-2.8-1.8-3.6-1.3c0.4,1.7,2.6,2.5,4,1.6
    		C865.9,158.9,865.8,158.8,865.6,158.7"
          />
          <path
            id="path5206"
            class="countryIsland"
            d="M874.8,164.5c-0.2,0.2-0.4,0.4-0.7,0.6c0.9,0.3,2.1,0.9,3,0
    		C876.3,164.9,875.5,165.2,874.8,164.5"
          />
          <path
            id="path5210"
            class="countryIsland"
            d="M876.1,152.1c0.2,0.2,0.4,0.3,0.7,0.2C876.6,152.3,876.4,152.2,876.1,152.1"
          />
          <path
            id="path4862"
            class="countryIsland"
            d="M581.7,112.9c0.2,0,0.5,0,0.7-0.1C582.2,112.8,582,112.9,581.7,112.9"
          />
          <path
            id="path4864"
            class="countryIsland"
            d="M582.2,113c0.3,0,0.5-0.1,0.8-0.2C582.7,112.9,582.4,112.9,582.2,113"
          />
          <path
            id="path4854"
            class="countryIsland"
            d="M553.3,113.4c0.5-0.2,1.1-0.5,1.6-0.8c-0.8,0-1.6,0.1-2.3,0.5
    		C552.9,113.3,553.1,113.4,553.3,113.4"
          />
          <path
            id="path4856"
            class="countryIsland"
            d="M572.2,114c0.1-0.6-0.1-0.7-0.8-0.4C571.7,113.8,571.9,113.9,572.2,114"
          />
          <path
            id="path4858"
            class="countryIsland"
            d="M572.4,115c0.2-0.7,0-0.9-0.8-0.5C571.9,114.7,572.2,114.9,572.4,115"
          />
          <path
            id="path4860"
            class="countryIsland"
            d="M573.4,113.1c0.6-0.1,1.1-0.3,1.5-0.6C574.4,112.6,573.9,112.8,573.4,113.1"
          />
          <path
            id="path4922"
            class="countryIsland"
            d="M565.7,117.6c0.2-0.7,2.3-0.3,2.8-0.4c-1.5,0.5-1.6-1.3-0.4-1.5c0.7-0.1,1,1,1.8,0.8
    		c-0.4-0.1-1.1-0.4-0.8-1c0.5-0.9,1,0.2,1.5-0.2c0.6-0.4,0.7-0.4,1.4,0c0.9,0.4,1.1-0.6,1.7-1c1.1-0.7,2.6-0.2,3.8-0.2
    		c1.3,0,2.7-2.4,4.2-1.3c-1,0.1-1,0.4-0.3,0.8c0.6,0.2,1.2-0.2,1.8-0.4c-1.4,1.5-12.5,3.1-10.6,6.4c-1.5-0.5,0.6-2.9-1.9-1.6
    		c-1.1,0.5-1.6-0.6-2.5-0.3C567.5,117.7,566.4,118.4,565.7,117.6"
          />
          <path
            id="path4928"
            class="countryIsland"
            d="M677,110c-0.5,0.8-1.2,2.2,0.2,2.5c-1.8,0.6,0.3,0.8,0.8,0.6c-2.5,1.2,3.5,2.3,4,2.3
    		c3.2,0.1,6.2-0.3,9.4,0.3c-1,0.5-1.8,1.4-2.5,2.2c-0.3,0.5-0.8,1-0.8,1.6c0,0.8,1.5,0.7-0.1,1.2c1.7,1,3.7-0.1,5.5-0.1
    		c1.2,0,2.3,0.1,3.5,0.1c0.4,0,3.8,0.1,1.8-0.5c1-0.1,2.1-0.2,3.1-0.3c-2,0.5,1.1,0.3,1.6,0.3c1.5,0,2.8-0.5,4.3-0.4
    		c1.8,0,3.9-0.4,5.7-0.8c1.9-0.4,3.6-1.6,5.6-1.6c1.9,0.1,3.8-0.2,5.7-0.7c1.9-0.5,3.1-1.5,4.7-2.3c0.8-0.4,2.2-0.8,3.2-0.8
    		c1.6,0-0.9,1.7-1.3,1.9c0.9,0.8,2.2,0.3,3.2,0.3c1.3,0,2.3,1.1,3.6,0.5c-0.5,0.9-1.4,0.7-2.2,1.1c1.7,1.4,3.2,0,5,0.1
    		c0.8,0,1.5,0.8,2.4,0.8c1.1,0.1,1.5,0.2,2.6-0.1c0.9-0.3,1.9,0.3,2.9,0.1c1-0.2,1.9-0.7,2.9-0.9c1.7-0.4,4.1,0.1,5-1.9
    		c-0.5-0.3-1-0.3-1.5,0.1c0.7-0.6,1.4-1.3,2.3-1.6c-1.8-2.2-7.4,1-9.1,2.2c1-1.2,2.7-1.6,3.7-2.8c-1.4,1-0.1-0.5-1.4-0.7
    		c0.4-1.5,1.7-0.2,2.5,0.2c1.3,0.6,2.4-0.4,3.3-1.1c0.5-0.4,2.2-1.4,3-1.1c0.6,0.2,0.7,0.9,1.4,1.1c1.1,0.3,2-0.8,2.8-1.3
    		c-1.4,0.9-0.4,1.8,0.8,1.7c1.6-0.2,2.5-1.9,3.2-3.1c-0.5-0.3-1-0.5-1.5-0.6c-0.7-0.1-0.8,0.6-1.5,0.2c0.2-0.2,0.5-0.5,0.7-0.7
    		c-0.6-0.5-1.3,0.2-1.7-0.3c-0.6-0.6-2-1.2-2.8-1.5c1-1.3-1-0.9-1.5-0.6c-1.5,0.7-1.7-1.1-3-1.1c0.5-1.3-1.4-2.2-0.3-2.9
    		c1.1-0.7,3.6-1.3,4.1-2.3c0.6-1.1,0-2.4,0.5-3.4c0.6-1.2,1.6-2.2,2.3-3.3c0.3-0.5,0.6-0.9,0.8-1.4c0.2-0.5,0.8-0.7,0.8-1.1
    		c0.2-2.1-0.6-1.2-1.7-2.1c-1.8-1.4-2-1.3-4-0.9c-1.5,0.3-2.9-1.8-4.6-0.5c0.2,0.2,0.3,0.5,0.5,0.7c-2.6,0-4.4,2.4-5.6,4.5
    		c-0.6,1.1-3.5,3.2-1.8,4.7c-2.1-0.4-4.7,3-6.6,1.2c0.5-0.8,1.2-1.4,2.1-1.7c-0.3,0.1-0.6,0.1-0.9-0.1c-0.4-0.6,3.1-3.7,3.5-4
    		c-0.4-0.5-1-0.6-1.6-0.2c2.4-0.9-1.8-1.3-0.9-2.5c-1.6,0.6-3.4-1.6-5-0.3c-1.1,0.9,2.1,1.7,2.6,1.7c-0.8,0.8-1.9-0.2-2.8,0.4
    		c1.4-0.1,1,1.9-0.6,0.8c1.3,1.3-2.6-0.1-3.4,0.2c-0.7,0.3-1.4,0.7-2.1,1c-0.6,0.2-3,0.4-1-0.2c-1.5-0.4-3.4,0.7-4.5,1.6
    		c0.4-0.4,0.9-0.7,1.4-0.9c-2,0.4,1.2-0.9,1.7-1c1.3-0.3,2.9-0.6,3.9-1.6c-1-0.1-1.8-0.9-2.8-1.2c-1.1-0.3-2.2-0.5-3.3-0.7
    		c-2.1-0.4-4.4,0.4-6.2,1.5c1.8,0.4-1,0.8-1.5,1c2.2-0.6-1.5-0.1-1.9,0c-1.1,0.2-2.3,0.5-3.5,0.4c0.7-1,3.5-1.3,4.6-1.7
    		c1.3-0.4,2.7-1.4,3.6-2.4c1.8-2.1-5.2-1.1-5.8-0.9c-3.5,0.6-7,1.4-10.6,2c-2.4,0.4-4.3,1-6.6,1.7c-2.6,0.7-5.4,1.1-7.9,2.3
    		c0.3,0.5,0.9,0.8,1.4,0.7c-1.3,2.1-11.1,1.3-10.7,5.1c1.5-0.5,2.4,0,3.8,0c1,0,2.1-0.4,3.1-0.5c-0.5,0.2-1,0.4-1.5,0.5
    		c1.8,0-0.3,0.5-0.7,0.5c-0.8,0.1-1.6,0.1-2.4,0.4c0.5,0.8,1.7,0.8,2.5,0.8c1.4-0.1,2.1-0.5,3.5-0.4c1.8,0.1,3.8-0.4,5.6-0.6
    		c1.9-0.2,4,0.3,5.9-0.1c-1.9,0.9-4.1,0.3-6.1,0.6c0.5,0.3,1,0.3,1.5,0.2c-2,1-5,0.6-7.2,1.1c-2.1,0.5-4.4,0.2-6.5,0.7
    		c-1.2,0.3-2.9,0.5-3.9,1.3c-0.8,0.7,0.9,2.5,1.6,2.4c2.7-0.2,5.6,0,8.2,0.1c3,0.2,5.9-0.4,8.9-0.5c2.6-0.1,4.6,0.1,7.2,0.7
    		c0.8,0.2,3.1-0.1,3.7,0.6c0.6,0.7,0.6,1.2,2.1,0.9c-0.8,1-2.1,0.7-3.1,0.6c-1.3-0.1-2.5,0.5-3.9,0.5c-2.9-0.1-5.9-0.7-8.8-0.7
    		c-3.4,0-7,0.4-10.3,0.8C682.8,108.9,679.8,108.8,677,110"
          />
          <path
            id="path4950"
            class="countryIsland"
            d="M696.2,122.5c-0.5-0.3-1.2,0.2-1.5,0.5C695.1,122.7,695.7,122.6,696.2,122.5"
          />
          <path
            id="path4952"
            class="countryIsland"
            d="M697.5,125.1c-0.8,0.1-1.5,0.4-2.2,0.7C696,125.6,696.8,125.5,697.5,125.1"
          />
          <path
            id="path4954"
            class="countryIsland"
            d="M701.1,124.3c-0.3,0-0.5,0.1-0.8,0.2C700.6,124.5,700.8,124.4,701.1,124.3"
          />
          <path
            id="path4956"
            class="countryIsland"
            d="M702.1,121.1c0-0.2,0-0.3,0.1-0.5c-0.6,0.1-1.1,0.3-1.5,0.6
    		C701.1,121.2,701.6,121.1,702.1,121.1"
          />
          <path
            id="path4958"
            class="countryIsland"
            d="M703.1,124c0.4-0.1,0.7-0.2,1-0.4c-0.8,0.3-1.7,0.4-2.5,0.7C702.1,124.3,702.6,124.2,703.1,124"
          />
          <path
            id="path4966"
            class="countryIsland"
            d="M706.6,122.9c-0.7,0.1-1.5,0.3-2.2,0.7C705.2,123.5,705.9,123.1,706.6,122.9"
          />
          <path
            id="path4968"
            class="countryIsland"
            d="M706.2,126.3c-0.2-0.1-0.5,0-0.7,0.1C705.7,126.3,706,126.3,706.2,126.3"
          />
          <path
            id="path4970"
            class="countryIsland"
            d="M705.9,131.9c-0.1,0.2-0.3,0.5-0.4,0.7C706.3,132.7,706.4,132.5,705.9,131.9"
          />
          <path
            id="path4972"
            class="countryIsland"
            d="M706.3,124c-0.3,0.2-0.5,0.4-0.8,0.6C706.3,125,706.6,124.8,706.3,124"
          />
          <path
            id="path4974"
            class="countryIsland"
            d="M707.4,132.2c-0.6,0-1.2,0.6-1.5,0.9C706.5,132.9,707,132.6,707.4,132.2"
          />
          <path
            id="path4976"
            class="countryIsland"
            d="M708.2,124.8c-0.4,0.1-0.8,0.2-1.1,0.5C707.7,125.7,708.1,125.4,708.2,124.8"
          />
          <path
            id="path4978"
            class="countryIsland"
            d="M708.7,128.1c-0.3,0-0.6,0.2-0.8,0.4C708.1,128.4,708.4,128.2,708.7,128.1"
          />
          <path
            id="path4980"
            class="countryIsland"
            d="M709.4,127.8c-0.3,0.3-0.7,0.6-1.2,0.8C708.9,128.8,709.3,128.4,709.4,127.8"
          />
          <path
            id="path4984"
            class="countryIsland"
            d="M710.5,128.6c-0.5-0.3-1.3,0.4-1.5,0.8C709.5,129.4,710.1,129.1,710.5,128.6"
          />
          <path
            id="path4986"
            class="countryIsland"
            d="M711.2,127.3c-0.9-0.5-1.4,0.9-1.9,1.3C709.9,128,711,128.2,711.2,127.3"
          />
          <path
            id="path4988"
            class="countryIsland"
            d="M711,125.4c-0.4-0.4-1.2-0.1-1.6,0.1C710,125.4,710.5,125.4,711,125.4"
          />
          <path
            id="path4992"
            class="countryIsland"
            d="M713.1,125.1c-0.5-0.4-1.2,0.2-1.5,0.5C712.2,125.7,712.7,125.5,713.1,125.1"
          />
          <path
            id="path5370"
            class="countryIsland"
            d="M710.2,130.6c0.3,0.2,0.5,0.4,0.7,0.6c-0.5,0.4-1,0.1-1.1-0.5
    		C709.9,130.7,710,130.6,710.2,130.6"
          />
          <path
            id="path5388"
            class="countryIsland"
            d="M922.9,188.1c0.3-0.5,0.8-0.8,1.4-0.9C924.8,188.2,923.5,188.3,922.9,188.1"
          />
          <path
            id="path5392"
            class="countryIsland"
            d="M936.8,156c0.6,0.2,0.8-0.2,1.2-0.5c0.7-0.4,1.4,0.5,0.2,0.9c0.2,0.3,0.5,0.5,0.8,0.7
    		C938.2,157,937.5,156.5,936.8,156"
          />
          <path
            id="path5562"
            class="countryIsland"
            d="M925.7,214.9c-0.5-0.1-1-0.1-1.5-0.1c0.6,0.6,1.6,1.1,2.2,0.2
    		C926.1,215,925.9,214.9,925.7,214.9"
          />
          <path
            id="path5564"
            class="countryIsland"
            d="M926.2,213.6c-0.3,0.2-0.5,0.3-0.8,0.5c0.7,0.6,1.5,0.3,2.3,0.4
    		C927.4,214,926.9,213.7,926.2,213.6"
          />
          <path
            id="path5566"
            class="countryIsland"
            d="M928.9,210.7c-0.8-0.7-1.9,0.3-2.1,1.1c-0.7,2.3,2.7,0.3,0.7-0.1
    		C928.1,211.6,928.6,211.2,928.9,210.7"
          />
          <path
            id="path5568"
            class="countryIsland"
            d="M927.7,216.7c-0.6,0.2-0.9,0.6-1,1.2C927.5,218.3,928.6,217.5,927.7,216.7"
          />
          <path
            id="path5570"
            class="countryIsland"
            d="M927.5,206.3c0.5-0.1,1-0.4,1.3-0.8C928.2,205.3,927,205.3,927.5,206.3"
          />
          <path
            id="path5572"
            class="countryIsland"
            d="M929.4,203c-0.6-0.4-1.2,0-1.5,0.5C928.5,203.8,929.1,203.5,929.4,203"
          />
          <path
            id="path5574"
            class="countryIsland"
            d="M928.2,206.5c0.1,0.2,0.3,0.2,0.6,0C928.6,206.5,928.4,206.5,928.2,206.5"
          />
          <path
            id="path5576"
            class="countryIsland"
            d="M928.2,206.4c0.5-0.1,0.9-0.3,1.2-0.6C929,205.8,928.5,206,928.2,206.4"
          />
          <path
            id="path5578"
            class="countryIsland"
            d="M930.4,203.8c0-1.1-1.2-0.2-1.5,0.1C929.4,204.3,929.9,204.3,930.4,203.8"
          />
          <path
            id="path5604"
            class="countryIsland"
            d="M876,299.1c0-0.5-0.3-0.7,0.1-1.2c-2.2-0.3-2.1-0.1-1.4-2c-0.8,0.4-1.6,0.1-2.2-0.3
    		c0.8-1.6,2.7-2.3,4.1-3.2c-0.4,0.7-0.7,1.4-1.1,2.1c-0.5,0.8,1.5,1.9-0.3,2.7c1,0.4,1.5-0.8,2.4-0.6c0.1,0,1.6,0.5,1.7,0.6
    		c2.1,1.7,6.6-0.3,9.2,0.2c-0.7,0.5-1.6,0.9-2.4,0.9c-1,0-1.8,1.1-2.8,1.5c1.9,0-0.1,1.7-0.9,1.8c-1.1,0.2-1.3-1.1-2.2-0.9
    		c0.4-0.1,0.9-0.4,0.7-0.9c-0.3-0.8-1.2-0.1-0.3-1.1C878.9,300,878,300.3,876,299.1"
          />
          <path
            id="path5610"
            class="countryIsland"
            d="M890,304.8c-2.1-3.1,2.2-5.1,4-7c1.3-1.4,3-2.6,4.2-4c2-2.2,0.9,0.4,2.6,0.6
    		c-0.9,2-2.5,3.7-4,5.3c0.3-0.5,0.9-0.8,1.5-0.6c-0.8,0.5-1.4,1.3-2.3,1.6c-1.1,0.4-2,0.6-3,1.4c0.6-0.4,1.4-1.1,2.2-0.9
    		c-0.7,1.1-3.1,1.3-3,2.8c0.8-0.5,1.6-0.8,1.5,0.4c0.8-0.2,2.6-2,3.3-2.6c-0.7,0.3-1.5,0.9-2.2,0.1c1.3-0.8,2.7-1.5,4-2.4
    		c-0.8,0.1-1.4,0.8-2.2,0.9c0.6-0.5,3.7-2.6,2.9-0.4c0.6-1.6,1.4,0.3,2.4,0c-0.2,0.6-0.6,1.1-1.1,1.4c0.2,0.2,0.5,0.4,0.7,0.6
    		c-1.9-0.1-2.8,1.6-4.4,2.3c-0.8,0.3-2,0.6-2.9,0.6C892.9,304.8,891.2,305,890,304.8"
          />
          <path
            id="path5612"
            class="countryIsland"
            d="M891,290.3c0.5-1.5,3.4-2.8,4.9-2.8c-0.5,0.7-1.3,0.3-2,0.7c-0.6,0.3-1.2,0.8-1.8,1.2
    		c0.6-0.3,1.1-0.6,1.5-1c-0.6,0.9-1.8,1.1-2.5,1.9c0.3,0.2,0.5,0.3,0.8,0.5c-0.5,0.2-1,0.3-1.5,0.2
    		C890.6,290.8,890.8,290.5,891,290.3"
          />
          <path
            id="path5614"
            class="countryIsland"
            d="M892.8,305.2c-0.5,0.7-1,0.8-1.2-0.1C892,304.9,892.4,305,892.8,305.2"
          />
          <path
            id="path5618"
            class="countryIsland"
            d="M894.3,319.2c-0.2-0.1-0.5-0.2-0.7-0.3c0.8,0.1,1.5,0,2.2-0.2
    		C895.3,319.1,894.8,319.3,894.3,319.2"
          />
          <path
            id="path5624"
            class="countryIsland"
            d="M944.8,287.5c-0.5,0.7-0.9,1.6-1.7,2.1C943.5,288.8,943.9,287.9,944.8,287.5"
          />
          <path
            id="path5630"
            class="countryIsland"
            d="M950.1,282.9c0,1.4-1.8,0.9-2.4,0.2C948.6,282.8,949.4,283.4,950.1,282.9"
          />
          <path
            id="path5580"
            class="countryIsland"
            d="M943,227c-0.7,0.1-1.5,0.3-2.1,0.7c0.6,0.9,1.6,0.3,2.3-0.1C942.2,227.9,942.2,227.7,943,227"
          />
          <path
            id="path5582"
            class="countryIsland"
            d="M946,229.7c-0.2,0.1-0.5,0.2-0.7,0.4C945.9,230.4,946.2,230.3,946,229.7"
          />
          <path
            id="path5584"
            class="countryIsland"
            d="M951.2,238.7c-0.2,0.1-0.5,0.2-0.7,0.4c0.5,0.4,1,0.3,1.4-0.2
    		C951.7,238.8,951.5,238.7,951.2,238.7"
          />
          <path
            id="path5608"
            class="countryIsland"
            d="M888.9,269c3.2,0.3,5.1,2.5,7.8,3.8c2.6,1.1,2.2,2.9-0.6,2.7c-2.8-0.3-5.7-0.6-8.2-2.2
    		c-1.1-0.7-1.5-2.1-2.6-2.8c-0.9-0.6-2.1-1.3-3.2-1.6C884.4,267.5,886.6,268.9,888.9,269"
          />
          <path
            id="Canada_Newfoundland"
            class="countryIsland"
            d="M915.8,287.5c-1.7-0.1-7.8,1.9-5.5-2.1c0.7-1.2,2.3-1.8,3.5-2.4
    		c1.8-0.9,3.4-2.3,5.4-2.9c-2,0.3-3.8-0.5-5.8,0.2c0.1-1.4,2.3-1.8,3.2-2.3c-0.6,0.3-1,0.8-1.4,1.3c0.7-0.3,1.3,0.6,1.8-0.1
    		c0.5-0.8,1.4-1.7,2-2.5c0.8-0.9,1-1.3,2.2-1.3c0.9,0,1.2,1.3,2.2,0.7c-1.4,0.4-1.5-1.5,0.3-1.2c-1.4,0-0.6-0.4,0-0.7
    		c-3.8,0.4,1.3-4.5,1.1-2c1.1-0.1,0.2-1.5,0.8-2.5c0.7-1.1,1.8-1.9,2.7-2.8c0.9-1,2.9-5,4.8-4.2c-0.2-0.3-0.4-0.5-0.7-0.8
    		c0.9-0.4,1.9-0.6,2.6-1.2c1-0.8,1.6-2.2,2.6-3.2c2.1-2,5-1.8,7.3-3.4c-0.1,0.6,0.3,1,0.8,1.2c0.1-1.6,2.7-0.7,1.3,0.4
    		c-1.7,1.5-3.7-0.3-4.2,2c0.2,0,2.7-0.4,1.6,0.6c-0.5,0.4-0.8,0.8-1.2,1.3c-0.6,0.6-1.9,2.4-2.2,1c-0.7,0.6-0.7,1.7-1.6,2.4
    		c-1.2,0.9-2.1,2.1-3.2,3.2c-0.9,0.9-1.5,1.4-2.2,2.4c-0.3,0.4-1.1,0.6-1.4,1.1c-0.3,0.6,0.6,0.8-0.1,1.5c1.9-1.5,3.8-3.1,5.8-4.5
    		c0.2,0.5,0.4,0.6,0,1.1c1.4,0.1,2.9,0.6,4.2-0.1c-0.2,1.4-4.4,2.7-5.5,3.2c0.7-0.2,1.4-0.8,2.2-0.5c-0.7,0.3-0.6,1-1.2,1.4
    		c-0.5,0.4-1.1,0.4-1.5,0.8c0.7-0.2,1.3-0.6,1.9-0.7c0.7-0.1,1.1,0.6,1.9,0.4c-0.1,0.3-0.1,0.6-0.2,0.9c0.3-0.5,0.8-0.9,1.4-1.2
    		c0,0.5,0,1,0.1,1.5c0.9-0.4,0.7-1.5,1.5-1.9c0.5,1.3-2,3.4-2.8,4.2c0.5-0.2,1.1-0.4,1.6-0.6c-0.1-0.2-0.2-0.5-0.4-0.7
    		c0.8-0.4,3.1-0.1,3.1-1.4c0.2,1.6,2.2-0.9,3-1.4c0.1,0.8-0.3,1.6-0.7,2.2c0.6-0.3,0.9-1.2,1.5-1.3c0.6-0.1,1.2,0,1.7-0.2
    		c1.4-0.7,2.5,1.3,3.6,1.6c-1.3,1.1-4.2,3.5-6,3.7c0.8,0.2,1.5-0.2,2.2-0.1c-0.2,0.5-0.7,0.9-1.3,0.6c0,0.4,0,0.7,0.1,1.1
    		c0.6-0.1,1-0.5,1-1.1c0.1,0.6,0.6,0.9,1.2,0.5c-0.7,0.5-1.4,1-2.3,0.9c1.7,0.2-1.6,1.1-1.9,1.9c0.5-0.3,3.2-1.8,1.7-0.2
    		c0.5-0.3,1-0.7,1.4-1.1c-0.8,1.5,1.4,0.2,1.8-0.2c0.9-0.9,0.6-0.6,1.2-0.1c0.7,0.6,1.2-1.7,1.8-0.5c0.4,0.7-0.4,1.2-0.9,1.5
    		c-1.1,0.6-2.2,0.8-3.2,1.6c-0.7,0.6-1.2,0.7-2.1,0.4c-1.3-0.5-1.2,1.3,0.4,1c-0.4,0.4-0.9,0.5-1.5,0.4c0.5,0.2,1,0.1,1.5-0.1
    		c-0.1,0.9-1.4,2-1.9,1.6c-0.1,0.7-0.5,1.8,0.7,1.4c-0.3,0.3-0.4,1.6,0.4,1.3c0.3-0.1,1.3-2,1.6-2.3c0.9-1.1,3.4-3.3,4.9-2.6
    		c-1.3,0.5-2.2,2-3.1,3c-0.3,0.3-0.8,0.7-0.9,1.1c-0.2,0.5,0.2,1-0.1,1.5c2,0,2.3-2.4,4-2.7c-0.3,0.9-0.1,2-0.7,2.9
    		c-0.9,1.4-1.8,2.7-2.7,4.2c-0.3,0.4-2,3-2.7,2.3c-0.9-0.8-1.8-0.1-2.8,0.4c-0.6-0.9,0.2-1.7,0.9-2.2c-1.5,1.5-0.1-0.5,0.4-0.7
    		c-1.6-0.1,0-1,0.4-1.7c-1.9,0.6-3.2,3.4-5.4,2.8c0.8-2.9,4.2-3.7,4.1-6.6c0-0.6-0.1-3.1-1.3-2.1c-1.5,1.3-2.3,3.1-4,4.2
    		c0.4-0.5,0.8-1,1.3-1.5c-1.9,1.5-3.4,1.1-4.9,3c-1.5,1.9-3,2.5-5.3,2.6c-0.7,0-2.2-0.2-1.2-1.2c0.9-1,2.4-0.7,3.5-1.3
    		c1.2-0.7,1.8-1.5,2.8-2.4c0.6-0.5,1.5-0.4,2.2-0.7c0.6-0.3,0.8-0.9,1.4-1.1c-0.8,0.1-1.4,0.5-2.2,0.5c0.3-0.6,0.7-1,1.2-1.4
    		c-1.6,1-1.7,1.2-3.7,0.9c0.4,1.4-0.9,1.4-1.7,2.2c0-1.2-0.5-0.6-1.5-0.5c0.2-0.2,0.3-0.5,0.5-0.7c-0.8,0-1.5,0.4-2.2,0.6
    		c0.7-1,2.1-1,3.1-1.5c-1.6,1,0-1.5,0.1-2c-0.4,0.9-1.4,0.9-2.2,1.4c-0.1-0.2-0.2-0.5-0.4-0.7c-0.3,0.5-2.4,2.5-1.4,0.8
    		c-0.2,1.1-2.4,0.9-3.3,1.3c-0.8,0.3-1.6,0-2.3-0.2c-0.7-0.2-1.8,0.3-1.4-0.5c-1.2,0.2-2.4,0.4-3.6,0c-0.9-0.3-3.9,0.5-2.7-0.8
    		C916.6,286.9,916.2,287.2,915.8,287.5"
          />
          <path
            id="path5626"
            class="countryIsland"
            d="M945.1,261c-0.3,0.2-0.5,0.4-0.8,0.6C944.4,261.3,944.7,261,945.1,261"
          />
          <path
            id="path5628"
            class="countryIsland"
            d="M947.8,270.7c-0.5,0.6-2.4,1.8-2.5,0.5C946.1,270.8,947,271.1,947.8,270.7"
          />
          <path
            id="path5632"
            class="countryIsland"
            d="M951.1,269.8c0.1,0.2,0.3,0.5,0.4,0.7c-0.8,0.1-1.5,0.4-2.2,0.6
    		C949.2,270.3,950.3,269.7,951.1,269.8"
          />
          <path
            id="path5634"
            class="countryIsland"
            d="M468.9,268.6c-0.8,0-2.5,1.6-1.7,2.2C468.3,271.6,469.4,269.5,468.9,268.6"
          />
          <path
            id="path5636"
            class="countryIsland"
            d="M470.2,272.7c-0.6,0-1,0.4-0.9,1C469.8,273.7,470.2,273.3,470.2,272.7"
          />
          <path
            id="path5638"
            class="countryIsland"
            d="M471,274.1c-0.1,0.2-0.1,0.5-0.1,0.7C470.9,274.6,471,274.3,471,274.1"
          />
          <path
            id="path5640"
            class="countryIsland"
            d="M472.9,253.4c-1.5,0.1-1.9,2.5-1.4,2.3C472.2,255.5,472.7,254,472.9,253.4"
          />
          <path
            id="path5642"
            class="countryIsland"
            d="M472.7,262.5c0-0.5-0.2-0.6-0.8-0.1C472.2,262.4,472.4,262.4,472.7,262.5"
          />
          <path
            id="path5646"
            class="countryIsland"
            d="M475.4,250.6c-1.7-0.7-2.2,1.2-2.8,2.3C473.8,252.8,474.7,251.4,475.4,250.6"
          />
          <path
            id="path5648"
            class="countryIsland"
            d="M472.9,237.9c0.2-0.1,0.5-0.2,0.7-0.4c-0.5,2.4-0.6,4.2,1.7,1.7c-0.2-0.2-0.5-0.4-0.7-0.6
    		c1.2,0,0.6-2.4-0.1-3.3C473.3,234,473.2,237.8,472.9,237.9"
          />
          <path
            id="path5650"
            class="countryIsland"
            d="M473.6,249.9c0.3-0.2,0.7-0.5,0.9-0.8C474,249,473.7,249.3,473.6,249.9"
          />
          <path
            id="path5652"
            class="countryIsland"
            d="M475.1,249.5c-0.7-0.1-1.5,0.4-1.3,1.2C474.3,250.4,474.8,250,475.1,249.5"
          />
          <path
            id="path5654"
            class="countryIsland"
            d="M474.4,261.2c0.6-0.1,1.1-0.3,1.5-0.6C475.4,260.6,474.9,260.8,474.4,261.2"
          />
          <path
            id="path5656"
            class="countryIsland"
            d="M476.1,240c-0.7,0.2-2,1-1.5,1.9C475.3,241.6,476.1,240.9,476.1,240"
          />
          <path
            id="path5658"
            class="countryIsland"
            d="M476.7,262.6c-0.8,0.1-1.5,0.4-2.2,0.6C475.3,263.3,476.2,263.4,476.7,262.6"
          />
          <path
            id="path5660"
            class="countryIsland"
            d="M475.6,233.8c-0.4,0.1-0.6,0.4-0.8,0.7C475.1,234.3,475.3,234.1,475.6,233.8"
          />
          <path
            id="path5662"
            class="countryIsland"
            d="M475.7,249.6c-0.3,0.2-0.5,0.4-0.7,0.7c0.6,0.1,1.1-0.2,1.4-0.6
    		C476.2,249.7,476,249.6,475.7,249.6"
          />
          <path
            id="path5664"
            class="countryIsland"
            d="M478.4,260.8c-1.2,0-2.5,0.5-3.2,1.4C476.3,262.4,478.1,262.2,478.4,260.8"
          />
          <path
            id="path5666"
            class="countryIsland"
            d="M475.2,261.3c0,0.1,0,0.2,0,0.4c0.3-0.1,0.5-0.2,0.8-0.4C475.8,261.2,475.5,261.2,475.2,261.3"
          />
          <path
            id="path5668"
            class="countryIsland"
            d="M475.7,239.4c-0.2,0.2-0.3,0.4-0.3,0.7C475.5,239.9,475.6,239.6,475.7,239.4"
          />
          <path
            id="path5670"
            class="countryIsland"
            d="M477,247.6c-0.6,0.2-1,0.5-1.3,1C476.3,248.5,476.8,248.1,477,247.6"
          />
          <path
            id="path5672"
            class="countryIsland"
            d="M477,248.6c-0.6-0.2-1.3,0.1-1,0.9C476.6,249.5,477,249.2,477,248.6"
          />
          <path
            id="path5676"
            class="countryIsland"
            d="M473.9,247.2c-0.3,0.5-0.8,1-1.3,1.3C472.4,247.9,473,246.6,473.9,247.2"
          />
          <path
            id="path5678"
            class="countryIsland"
            d="M473.9,247.1c-0.7-0.9,1.5-1.2,1.9-1.4C475.4,246.3,474.9,247.8,473.9,247.1"
          />
          <path
            id="path5682"
            class="countryIsland"
            d="M455.7,249.8c-0.6,0.2-0.8,0.6-0.7,1.2C455.6,250.7,455.8,250.3,455.7,249.8"
          />
          <path
            id="path5684"
            class="countryIsland"
            d="M457.3,244.9c-0.5,0.1-0.9,0.3-1.3,0.6C456.6,245.8,457.1,245.5,457.3,244.9"
          />
          <path
            id="path5686"
            class="countryIsland"
            d="M456.8,244.1c-0.2,0-0.5,0.1-0.7,0.1C456.3,244.2,456.6,244.2,456.8,244.1"
          />
          <path
            id="path5688"
            class="countryIsland"
            d="M456.1,243.1c0.6,1,1.7,0.3,2.1-0.4C457.4,242.5,456.5,242.2,456.1,243.1"
          />
          <path
            id="path5690"
            class="countryIsland"
            d="M470.3,240.9c0,0.2,0,0.3,0,0.5c1-0.2,2.1-0.4,2-1.8c-0.1-0.9-1.4-2.4-2.4-2.2
    		C468.9,237.7,470.6,240.3,470.3,240.9"
          />
          <path
            id="path5692"
            class="countryIsland"
            d="M474.4,233.9c-0.9-0.4-3.4,0.5-3.3,1.7c0.2-1.6,0.4-0.1,1.1-0.4c0.5-0.2,0.8-0.5,1.3-0.4
    		c-0.6,0.1-1.1,0.4-1.4,0.9C472.9,235.8,474.6,235,474.4,233.9"
          />
          <path
            id="path5694"
            class="countryIsland"
            d="M472.8,236.1c-0.7,0.2-2.1,0.8-1.3,1.6C472.2,238.4,472.7,236.5,472.8,236.1"
          />
          <path
            id="path5696"
            class="countryIsland"
            d="M472.5,244.2c-1.3,0.1-1.3,2.1-0.6,2.9C472.9,246.4,473,245.3,472.5,244.2"
          />
          <path
            id="path5698"
            class="countryIsland"
            d="M472.4,238.6c0.3-0.1,0.7-0.2,0.9-0.4C473,238.3,472.6,238.4,472.4,238.6"
          />
          <path
            id="path5700"
            class="countryIsland"
            d="M473.6,241.2c-0.5,0.3-0.8,0.9-0.9,1.4C473.6,243,473.5,241.8,473.6,241.2"
          />
          <path
            id="path5702"
            class="countryIsland"
            d="M454.5,237.7c0.6-3.1,2.5-4.8,5.6-4.3c-0.2,0.6-0.6,1-1.1,1.3c0.5-0.4,3.5-1.7,2.9-0.1
    		c-0.7,2-3.4,1.9-5,2.6c0.5,0,1,0,1.5-0.1c-0.6,0.1-1.1,0.4-1.5,0.9c1.9-0.8,3.4-1.5,5-3c1.2-1.1,3-1,4.4-1.8
    		c-1.7,2.1-4.3,2.9-6.1,4.9c-1.8,2-3.8,2.8-6.4,3.4c0.1-0.6-0.2-1-0.8-1c0.4-0.1,2.5-0.6,0.7-0.9c0.5,0,1,0,1.5-0.1
    		c-0.4-0.8-1.4-0.9-1.6-1.8C454,237.8,454.2,237.7,454.5,237.7"
          />
          <path
            id="path5704"
            class="countryIsland"
            d="M455.1,241.4c0.6,0.1,4.1-1.3,3.7,0.2c-0.3,1.1-1,0.3-1.8,0.6c-0.8,0.3-0.9,1.4-1.9,1.5
    		c1.5-0.4,0.4,1.5,0.1,2c-0.4,0.7,0,3.2,1.3,2.8c-0.1,1.1-2.3,2-1.4,0.6c-0.2,0.2-0.5,0.4-0.7,0.5c-0.4-0.8,0.1-1.7-0.3-2.4
    		c-0.4-0.7-1.1-2.1-0.2-2.8c-1.2,0.6-0.9-0.3-0.5-0.9c0.3-0.5,1.1-0.6,1.6-0.9c-0.7-0.4-1.5-0.3-2.2-0.4
    		C453.4,241.5,454.3,241.7,455.1,241.4"
          />
          <path
            id="path5738"
            class="countryIsland"
            d="M732.6,286.1c-0.6-0.1-1.2,0.2-1.5,0.7C731.7,286.9,732.3,286.6,732.6,286.1"
          />
          <path
            id="path5740"
            class="countryIsland"
            d="M738,300.5c0.4-0.1,1.8-1.1,0.9-1.5C737.8,298.4,736.6,300.1,738,300.5"
          />
          <path
            id="path5742"
            class="countryIsland"
            d="M741.4,301.5c-0.6,0-1.1,0.4-1.4,0.9C740.6,302.7,741.7,302.4,741.4,301.5"
          />
          <path
            id="path5744"
            class="countryIsland"
            d="M749.7,305.4c1.1-0.6,2.1-1.5,2.9-2.4c-1.1,0.2-0.7-0.1-0.1-0.6c-0.6,0.2-1,0.6-1.4,1.1
    		c0.6-1.8-1.3-2.1-2.1-0.7c-0.1-2.1-2-0.4-2.9,0c-1.2,0.6-3.3-2.5-4.3-0.3c1.8,0,3.2,1.4,4.9,1.6c0.7,0.1,1.1,0.9,1.8,1
    		c0.8,0.1,1.5-0.7,2.2-1c-0.3,0.5-0.8,0.9-1.4,0.9C749.4,305.1,749.5,305.2,749.7,305.4"
          />
          <path
            id="path5746"
            class="countryIsland"
            d="M723.3,277.4c-0.6-0.4-2.6-0.3-2.2,0.9C721.9,277.9,722.8,278.3,723.3,277.4"
          />
          <path
            id="path5748"
            class="countryIsland"
            d="M723.8,277.4c-0.6-0.1-0.6,0.1-0.1,0.7C723.7,277.9,723.8,277.6,723.8,277.4"
          />
        </g>
        <path
          id="sv"
          class="countryGroup country"
          d="M618.4,579.7c-0.2-0.9,0.1-1.3,0.6-1.8c0.8-0.8,1.6-0.5,2.3-0.9c0.1-0.1,0.7-1.2,1.5-1.6
    	c0.3-0.1,0-0.6,0.1-1c0.2-0.9,1.6-0.5,2.2-0.4c0.9,0.3,6,3.4,5.5,4.6c1,0,3.3-1.9,3.7,0c0.9-0.6,2.4-0.2,2,1
    	c-0.1,0.3-0.8,3.8-1.1,2.7c-0.7,1,0.8,1.8-1.3,2.4c-1.9,0.5-3.3-1.4-5-0.9c1.9,0.5-1,0.3-1.5,0c-1.2-0.5-2.2-1.5-3.4-1.9
    	c-1.2-0.4-2.5,0.1-3.6-0.4C619.7,581.2,619.1,580.1,618.4,579.7"
        />
        <path
          id="gy"
          class="countryGroup country"
          d="M832.3,647.1c0.7-1.4,2-2.1,1.7-4.3c-0.1-1.5,0.5-1.9,1.4-2.4c0.2-0.1,0.7-1,1.4-0.6
    	c0.9,0.6,0.8,0,1.6-0.6c0.7-0.5,2-0.8,2.3-1.4c0.3-0.6-0.2-1.4-0.9-1.2c-1.8,0.6-0.8-1.2-1.6-2.2c-0.7-0.9,0.2-2,0.8-2.7
    	c0.7-0.9,0.6-0.8,1.6-1.4c1.4-0.7,2.2-2.1,3.4-2.8c1.7-0.8,0.3-1.5-0.4-2.9c1.2,1.4,2.9,2.3,4.3,3.5c-0.7-1-2-1.2-2.5-2.4
    	c1.7,0.8,3.4,1.8,4.6,3.2c1,1.1,2.3,3.2,3.5,4c1.4,0.8,1.7,3.4,1.2,4.7c-0.4,1-0.9,1.7-1,2.8c-0.1,1-0.1,2-0.7,2.8
    	c1.9-0.3,0.5-5,3.4-4c2.2,0.8,4.9,2.4,5.4,4.9c1.4-1.3,2.5,1.4,2.7,2.4c0.4,1.8-0.3,4.5-1.5,5.9c1.9,0.5,0.3,1.9-0.2,2.4
    	c-0.4,0.5-5.5-1.1-5.2,2.2c0.2,2,0.1,3.3-0.2,5.3c-0.2,1.3,1,4,2,4.9c1,0.9,0.3,2,1.8,2.2c0.3,0,1.1-0.3,1.3,0
    	c0.6,1,0.7,2.1,0.9,3.2c0.3,2,2.4,3.7,2.4,5.7c0,2,2.3,2,3,3.2c-2.5,1.6-4.9-1.6-7.2,0.3c-0.4,0.3-0.6,0.8-1,1.1
    	c-0.6,0.7-1.5,0.4-2.3,0.6c-1.4,0.5-1.5,1.2-3.2,1.2c-0.5,0-0.8,0.4-1.2,0.8c-0.3,0-0.5,0.1-0.7,0.3c0.1,0.3,0.1,0.6,0.1,0.9
    	c-0.4,0.7-2.5,1.1-3.2,0.8c-0.5-0.2-0.2-0.8-0.9-0.8c-0.6,0-1.8-0.5-2-1.1c-0.4-1-1.8-2.5-2.9-2.7c0.1-0.2,0.2-0.5,0.3-0.7
    	c-1,0.1-0.9-0.8-0.8-1.5c0.2-1.2-0.1-1.7-0.8-2.5c-1.2-1.5-1.1-4.2-0.8-5.9c0.3-1.3,1.3-2.5,1.3-3.9c0-1.2,0.3-1.1,1-2.1
    	c0.4-0.6,1.4-1.8,1-2.5c-0.3-0.6-1.2-1.5-1.2-2.2c0-0.7,0.4-1.4-0.4-1.7c-0.6-0.2-2-0.3-2.3-0.8c-0.3-0.5,0.8-2.2,0.9-2.8
    	c0.2-1,0-2.3-0.8-3c-1.2-1.1-3.3,0.6-4.8-0.1C835.3,652.4,833.2,648.9,832.3,647.1"
        />
        <path
          id="be"
          class="countryGroup country"
          d="M1327.7,258.6c1.8-0.9,3.9-1.9,5.9-2.4c-0.9,1,1.3,0.7,2,0.9c0.6,0.2,1,0.4,1.7,0.2
    	c0.9-0.2,1.9-1.7,2.2-0.3c-0.1-0.3-0.2-0.6-0.3-0.9c0.7,0.1,1.5,0.1,1.4-0.8c0.5,0.4,1.4,0.6,1.8-0.1c0.3,0.9,1.6,0.6,2.1,0
    	c0.6,2,3.5,2,5.3,2.7c-0.9,1.1-1.9,2.4-0.2,3.2c0.6,0.3,0.9,0.1,1.3,0.1c-0.3,0.7,2.1,1,1.1,2c1-0.2,0.9,1,1.2,1.6
    	c-1.2,0.2-1,0.8-1.8,1.4c-0.3,0.2-1-0.4-1.7,0.7c-0.6,0.9-1,1.3-0.8,2c0.2,0.8,1.3,1.7,0,2.7c-0.3,0-1.1,0-2-0.1
    	c-0.1-0.2-0.6-1-1.2-1.3c-0.8-0.4-2.3-0.6-2.8-1.2c-0.3-0.4-0.1-2.3-0.1-2.9c-0.7,0.3-0.9,0.8-1.3,1.3c-0.9,0.8-2.2,0.5-3.3,0.3
    	c0.2-0.9,0.6-2.3-0.1-2.7c-0.9-0.6-2.2-0.5-2.8-0.1c0.1-1.4-0.8-1-1.8-1.5c-1.2-0.5-0.9-0.5-1.5-1.5c-0.2-0.4-2.4-0.3-2.9-0.6
    	C1327.6,260.6,1328.8,259,1327.7,258.6"
        />
        <g id="gq" class="countryGroup">
          <path
            id="Equatorial_Guinea_Bioko"
            class="countryIsland"
            d="M1366.9,670.9c0.5-0.6,3-5.3,0.8-4.8c-0.8,0.2-1.2,0.7-1.5,1.4
    		c-0.3,0.9-0.9,1-1.5,1.7C1364.2,670,1366.6,670.7,1366.9,670.9"
          />
          <path
            id="Equatorial_Guinea_mainland"
            class="country"
            d="M1374.1,684.8c0.3,0.1,0.5,0,0.8-0.1c-1.6,0.8-0.1-1.6,0.1-2
    		c0.8-1.3,0.2-3.1,0.7-4.7c1.1,2.1,1.8,1.6,3.1,1.9l8.2-0.1v10l-9.1-0.2c-0.6,0.1-3.4,0.9-2.3-0.4c-0.9-0.5-3.1,0.5-3.7-0.6
    		C1371.5,687.5,1373.6,685.7,1374.1,684.8"
          />
        </g>
        <path
          id="ls"
          class="countryGroup country"
          d="M1501.9,955.1c0.9,0.9,0.9,2.3,1.6,3.3c0.7,0.8,0.4,2,1.1,2.7c0.9,0.9,3.2,3.8,4.7,2.6
    	c0.7-0.5,1.2-2.3,1.7-3.1c0.7-1.2,2-1,3.2-1.3c0.9-0.2,2.8-1,3.1-1.9c0.5-1.2,0.5-1.8,1.4-2.9c0.3-0.4,1.3-1.5,1.2-2
    	c-0.5-1.8-1.3-1.9-2.5-3.2c-0.9-1-1.9-1.9-2.9-2.9c-0.8-0.9-2.2,0.5-2.9,0.8c-0.8,0.3-1.2,0.7-2,1.2c-0.9,0.5-1.9,0.3-2.7,1.3
    	c-0.8,1-1.2,2.3-1.9,3.4C1504.2,954.1,1503,954.2,1501.9,955.1"
        />
        <path
          id="bg"
          class="countryGroup country"
          d="M1462.3,320.2c0-1.2,1-2.8,1.7-3.7c0.2-0.3,2.2,0.7,2.3,0.9c0.3,0.7-1.1,1.4-0.6,2.1c0.6,1,3.9,0,5,0.3
    	c1.7,0.4,3.5,1.5,5.3,0.9c1.4-0.5,3.1-0.1,4.6,0c2.2,0.2,3.3,1.1,5.3-0.4c1.6-1.2,2.6-2.1,4.6-2.5c1.8-0.3,3.8-1.3,5.5-0.4
    	c1.1,0.6,2.8,1.1,4,0.8c0.4-0.1,2.1,1.7,2.7,1.9c0.8,0.3,2.3-0.3,2.5,0.9c0.2,1.3,0.3,2.3-1.2,2.2s-2.3,1-3,2.4
    	c-0.6,1.1,0,2.4-0.6,3.6c-0.2,0.4-0.5,0.7-0.7,1.1c-0.4,0.6-1.1,0.2-1.5,0.9c2,0.4,2.3,1.9,3.6,3.2c1.6,1.6-1.9,1.6-2.9,1.3
    	c-1.1-0.3-1.2-1.4-2.6-1c-1,0.3-1.8,0.6-2.9,0.9c-1.6,0.5-0.5,0.7-0.9,0.9c-0.6,0.3-1.8,0.9-1.2,1.4c-4.9-1,0.7,2.1-2.2,3.2
    	c-1.3,0.5-1-0.3-4.7,0.5c-1.1,0.2-2-0.9-2.9-0.9c-1.3,0.1-1.4,0.5-2.6-1c-0.7-0.9-1.5-0.7-2.5-0.6c-1.4,0.2-2.3,1.9-3.8,1.8
    	c-1.9,0-2.8-0.1-3.4,0.4c-0.3,0.3-1.3,0-2.2-0.3c0.7-0.8,0-2,0-3.2c0.1-1.2-1.2-2.4-2.1-3.1c-0.9-0.5-1.6-1.7-2.3-2.5
    	c3.3-1-0.1-1.8,0.8-4.1c0.3-0.8,1.1-0.2,1.6-0.6c0.7-0.4,1.5-1.8,1.8-2.5C1465,324.7,1462.6,322.1,1462.3,320.2"
        />
        <path
          id="bi"
          class="countryGroup country"
          d="M1522.2,722.3c0.9-3,2.6,0.3,2.8,0.3c3.4,0.4,4-0.7,4.3-4.1c1.8,1.6,1.3-0.7,5.4,0.7
    	c-0.7,0.3-2.5,2.3-1.1,2.2c-1.2,3.1,1.2,3.2,2.4,2.8c-0.8,1.2,1,1.7-0.7,2.9c-1,0.7-1,0.3-1.2,1.7c-0.1,0.3-1.3,0.2-1.5,0.6
    	c-0.2,0.5,0.7,1.6,0.6,1.9c-0.1,0.2-0.6,1.2-1.5,1.9c-1.4,1-1.6,2.2-3,3.1c-0.7,0.5-1.5,0.6-2.1,0.6c-0.3-0.9-1.8-4.2-2.1-5.2
    	c-0.4-1.6,0.2-3.2-0.9-4.5c0.1-0.8,0.5-1.7-0.1-2.5C1523.1,723.9,1522.5,723.2,1522.2,722.3"
        />
        <path
          id="dj"
          class="countryGroup country"
          d="M1618.7,603.1c0-2.3-0.9-4.6,0.5-6.7c1.1-1.6,2-3,2.9-4.7c1.1-1.9,1.6-0.9,3.2-0.5c0.8,0.2,0.7-1,1-1.4
    	c0.5-0.6,1.3-0.9,1.9-1.2c0.7,1.5,3.5,4.2,2.1,5.9c-0.7,0.9-2.8,1.9-3.9,2.3c-1.1,0.4-1.2,1.7-2.4,1.9c0.8,1,3.2-0.7,4.3-0.2
    	c2.1,0.8,0,2.8-0.6,4c-0.8,1.7-2.2-0.1-3.5,0.3C1623,603.1,1619.5,604.8,1618.7,603.1"
        />
        <g id="az" class="countryGroup">
          <g id="az-">
            <path
              id="path5796"
              class="country"
              d="M1621.9,341.4c2.1-1.6,3.4-1.4,5.3,0.3c1.2,1,2.1,0.4,3.4,0.8c1.1,0.3,1.5,1,2.5,0.5
    			c2.2-1.1-1.7-3.1-2.1-3.3c-0.9-0.5-1.3-1.3-1.2-1.7c0.1-0.4,1.7-1.3,0.9-2c0.6-0.2,3.5,1.2,4.3,2.3c0.6,0.8,1.8,0.8,2.1,1.5
    			c0.4,0.9,0.7,1.7,1.6,2c0.8,0.3,1.8,0.5,2.7,0.5c0.8,0,1.2-1.1,1.6-1.8c0.2-0.3,3.4-2.8,2.7-3.7c1.5,0.5,3.2,2.3,4,3.6
    			c1.2,1.8,2.2,3.5,3.7,5.1c0.2,0.1,0.5,0.2,0.7,0.3c0,0.3,0,0.5,0.1,0.8c0.7,0.8,1.4,1.1,2.5,1.2c2.3,0.2,3.5,0.9,4.4,3
    			c-0.8-1.1-3.1-1.6-4.3-0.9c-1.7,1.1-1.8,1.9-2.1,3.7c-0.3,1.7,0,2.8,0.3,4.3c0.1,0.5-0.7,1.1-0.7,1.8c-0.1,1,0.3,1.7-0.8,1.5
    			c1.2-1.2-1.3-1.8-1-0.5c0.4,1.8-0.4,3.7,0.2,5.6c-0.5,0-1.4,0.1-1.9-0.2c-1.7-1.2-4.7-2.9-4.6-3.9c0.1-0.5,1.4-0.5,1.4-0.9
    			c0.1-0.9-1.9-2.5,0.3-3.3c-1.9-2.2-3.6-3.7-5.5-1.6c-1.9,2-3,1.8-3.2,2.5c-1.6,2.3-2.4,2.5-3.5,3.2c-0.5-0.6-1.1-2.7,0.1-2.8
    			c-0.3-0.4-1.9-1.1-1.6-1.8c0.1-0.3,0.8-0.5,0.3-0.8c-0.4-0.3-1-0.6-1.4-0.5c-1.5,0.4-3.6-2.3-5.3-3.4c0.8-0.4,2-0.1,2.4-1.1
    			c0.5-1.1-0.9-1.4-1.6-1.7c-1.2-0.6-4.6-3.1-1.8-4c-0.7-1.8-2.7-1.8-4-2.8C1624.6,343.3,1622.1,341.9,1621.9,341.4"
            />
            <path
              id="Azerbaijan_Nakhchivan"
              class="country"
              d="M1622.3,355.2c1.2-0.3,3.1-0.8,3,1.1c0.8-0.5,1.4,0.7,2.4,0.4
    			c0.5-0.1,1.8-0.5,2.2,0c0.8,1.1-0.3,1.3,1.2,2.1c0.6,0.3,1.4,2.9,2,3.6c-4.3-0.2-4.3-0.7-6.3-2.3
    			C1625.1,358.6,1623.1,357.2,1622.3,355.2"
            />
          </g>
          <g id="xn" class="countryGroup">
            <path
              id="xn-"
              class="disputed"
              d="M1639.2,358.8c-1.6,2.3-2.4,2.5-3.5,3.2c-0.5-0.6-1.1-2.7,0.1-2.8c-0.3-0.4-1.9-1.1-1.6-1.8
    			c0.1-0.3,0.8-0.5,0.3-0.8c-0.4-0.3-1-0.6-1.4-0.5c-1.5,0.4-3.6-2.3-5.3-3.4c0.8-0.4,2-0.1,2.4-1.1c0.2-0.4,0.1-0.7-0.1-0.9
    			c0,0,1,0.5,1.1,0.5s1.2-1,1.2-1s-0.2-0.6,0.1-0.9c0.2-0.3,0.7-0.5,0.8-0.5c0.2,0,1.2,1.8,1.3,1.9c0.2,0.1,2.1,1.5,2.1,1.6
    			c0,0.2,0.4,1.4,1.1,1.8c0.7,0.3,1.1,1,1.1,1s-0.3,0.5-0.2,1.3c0.2,0.8,0.9,1.4,0.9,1.6C1639.5,358.2,1639.2,358.8,1639.2,358.8
    			L1639.2,358.8z"
            />
            <circle id="xn_" class="circle" cx="1635" cy="351.6" r="4" />
          </g>
        </g>
        <g id="ir" class="countryGroup">
          <path
            id="path4560"
            class="countryIsland"
            d="M1697.4,466.8c0.2-0.1,0.5-0.2,0.7-0.1c-0.5-0.3-1-0.3-1.5-0.1
    		C1696.9,466.6,1697.2,466.7,1697.4,466.8"
          />
          <path
            id="path4562"
            class="countryIsland"
            d="M1703.2,469.4c0.1-0.6-0.1-0.6-0.7-0.1C1702.7,469.3,1702.9,469.4,1703.2,469.4"
          />
          <path
            id="path4564"
            class="countryIsland"
            d="M1716.2,465.6c-1.2,1-2,2.7-3.9,2.4c-0.2,1.8,2.5,0,3.1-0.3c0.6-0.3,1.4-0.1,2-0.4
    		c0.9-0.4,1.1-1.8,2.1-1.9C1718.3,464.2,1717.4,466.2,1716.2,465.6"
          />
          <path
            id="Iran_mainland"
            class="country"
            d="M1617.4,358c2-0.7,2.7,0.8,2.7-2.4c0-1.4,1.2-0.9,2-0.2c0.8,0.6,1.3,1.6,2.1,2.3
    		c0.8,0.7,1.6,1.2,2.3,1.9c1.5,1.4,2.2,2.2,4.4,2.5c1.4,0.2,3.3,0.4,4.6,0c0.7-0.2,3.2-2.4,3.4-3c0.3-0.8,1.4-1,2-1.5
    		c0.7-0.6,1.3-1.3,2.1-1.8c2-1.4,3.8,0.7,5,2.2c-2.6,1,0.9,3.2-0.5,3.6c-2.7,0.6,0.1,2.2,1.3,3c0.8,0.5,1.6,1.3,2.4,1.7
    		c0.3,0.2,1.4,0,1.5,0.2c1.1,2.2,0.9,5.9,3.4,7.2c2.1,1.1,4.6,0.6,6.7,1.5c1.4,0.6,1.8,2.1,3,3c1.2,1,3.1,1.7,4.5,2.3
    		c2.7,1.2,6.3,2,9.2,1.2c3.5-1,7.8-2.7,11.5-2.1c-0.7,0.3-1.5,0.2-2.3,0.3c0.5,0.4,2.6,1.2,2.9,0.1c0.3-0.9-1.2-3.5-1.5-4.5
    		c1.7,0.3,3,0.3,4.6-0.4c1.8-0.8,0.6-1.5,1.4-2.7c0.8-1.3,2.8-2.5,4.2-3.1c2-0.8,4,0.4,6,0c-0.7-1.1,0.8-1.3,1.7-1.3
    		c1.1-0.1,2.4,0.3,3.5,0.2c1.6-0.2,1.6,0,2.3,1.4c0.7,1.3,2.7,1.3,4.1,1.5c1.5,0.3,2.5,1.8,4.1,1.9c1.8,0.1,3.2-0.2,4.9,0.5
    		c1.7,0.6,2.2,1.3,3.2,2.7c0.9,1.4,3.1,1.2,4.4,2.1c1.2,0.8,1.6,2.3,2.8,3c1.3,0.8,4.8-0.8,5.8,0.4c0.9,0.9,1.1,3.6,1.2,4.6
    		c0.2,1.2-0.7,0.7,0.2,1.5c0.6,0.5,1.1,2.6,1.2,3.3c0.1,1.2-0.9,1.9-0.7,3.2c0.3,1.7,0.9,5.3-1.6,5.5c0.2,0.3,1.1,1,1,1.4
    		c-0.1,0.7-1.7,0.9-1.9,1.7c-0.5,1.6,0.4,5.2,2.3,5.4c2.4,0.2,0.2,2.1-0.1,3.3c-0.6,1.8,2,5.8,2.5,7.8c0.6,2.3,0.4,4.5,1.9,6.3
    		c1.3,1.4,4,0.7,5.6,1.5c1.7,0.9,1.6,3.5,1.2,4.9c-0.4,1.5-1.8,2.7-2.6,4.1c-0.6,1.1-2.2,3.6-2.8,4.1c0.8,0.8,1.4,1.4,1.8,1.8
    		c3.6,3.2,4.9,7.9,7.8,9.5c0.9,0.5,1.8,0.8,2.7,1.2c0.9,0.4,1.9,1.2,2.9,1.2c1.3,0.1,1.8,4.8,1.9,5.9c0.2,4.2,1,3,3.4,3.1
    		c1.2,0,0.5,3,0.4,4.2c-0.2,2.4-7.8,0.2-6.6,3.4c-0.7-0.2-2,0.3-2.4,0.9c-1.3,1.9-1.2,4.9-1.3,7.4c0,0.7,0.2,2.1-1,1.7
    		c0.6,1.9-3.6,0.2-4.2,0c-0.7-0.2-2.4-0.1-2.6-1.1c-0.3-1.4-1.9-0.6-1.2,0.1c-0.3,0.1-4,0-4.3-0.3c-1.2-0.9-3.4-0.8-4.8-0.4
    		c-1.1,0.3-1.9-0.3-2.8-0.9c-1.1-0.8-2.1-0.3-3.3-0.6c-1-0.3-1.8,0.5-2.8,0.1c-0.9-0.4-1.1-1.7-2.2-0.5c0.3-1.4-2.7-1-3.6-1.8
    		c-1.8-1.6-2.3-5.3-2.7-7.6c-0.5-2.8-3.1-4.4-5.9-4.2c-1.3,0.1-2.2,1.2-3.4,1.5c-1,0.3-2.3,0.5-1.7,1.6c0.4,0.7-2.2,0.4-2.4,0.5
    		c-1.3,0.3-1.8,1.6-2.9,2.2c-1.8,0.9-3.5-1.6-5.3-1.6c-2.2,0-3.4,0.4-4.8-1.6c-0.7-1-2.2-1.3-3.2-1.9c-1.3-0.7-2.7-1.6-4.1-2.2
    		c1.3-1.3-1.9-2.5-2.7-2.8c-0.7-0.3-1.3-0.9-2.1-1.1c-1-0.2-2.4,0.2-3.3-0.5c-1.9-1.4-2.4-2.8-3.7-4.8c-0.6-0.9-0.2-2.2-1.2-3
    		c-0.6-0.5-1.7-0.8-1.7-1.8c1.2,0.1,1-0.9,0.1-1.1c-0.6-0.1-2.1,0-1.5-1.1c0.8-1.4-1.5-2.8-2.2-3.5c-1.2-1.2-2.1-2.5-2.7-4
    		c-1-2.7-3.1,2-4.3,0.4c-0.8-1.1-1.9-0.3-2.4-1.3c-0.2-0.5-1.7-0.7-2.2-1.5c0.8,0,1.5,0.2,2.2,0c-0.2-1-1.4-1.3-2.2-0.8
    		c0.2,0.2,0.4,0.4,0.6,0.6c-2.1-0.3,1.3,4.6-2.4,3.1c0.2,0.7-0.4,0.9-1,0.7c-0.8-0.4-0.8-1.3-1.1-2c-0.6-1.5-2.7-1.6-2.9-3.4
    		c-0.2-1.2,0.4-3.5-1.3-3.4c-2.6,0.1-1.8-3.5-1.4-5.2c0.5-2.3-0.7-2.8-2.1-4.4c-1.4-1.7-1.1-2.6-3.4-3.1c-1.7-0.3-2.9-2.2-4.2-3.1
    		c-0.8-0.5-1.6-1.2-2.6-1.1c-1.6,0.1-0.6-0.8-1.8-1.2c2.6-0.8-2.5-4.8-3.3-5.5c-0.6-0.5-1.1-1.1-1.7-1.6c-0.9-0.8-0.1-1,0.3-1.9
    		c0.3-0.9,0.2-1.3-0.6-1.9c-0.7-0.6-0.2-1.4,0-2.1c0.5,0.3,1,0.4,1.5,0.4c-0.1-0.6-0.4-1.2-0.5-1.9c-0.1-1.1,1.1-0.9,1.2-1.8
    		c0.1-1.2,0.2-0.9,1.2-0.9c1,0,0.8-0.9,0.5-1.4c-0.5-0.9-1.8-1.8-1.5-3c0.3-1.1,1.9-0.8,2.2-2c-0.7,0.2-1.2-0.3-1.9-0.3
    		c-0.8,0.1-1.7,0.5-2.5,0c-1.2-0.7-1.4-1.7-3-1.3c1.1-2.5-3-3.3-2.8-5c0.1-1,0-1.3-0.9-1.7c-0.2-0.1-1.7-3.3-1.6-3.7
    		c0.4-1.5-0.7-0.9-1.4-2c-0.8-1-0.4-2.8-1.2-3.1c-0.4-0.2-2.1-0.7-2.1-1.4c0.1-1,0.6-2.4,1.4-3.2c-2.2-0.4-1.2-3.3-2.1-4.4
    		c-0.5-0.6-0.4-1.2-0.7-1.9C1618.8,360.4,1617.9,358.8,1617.4,358"
          />
        </g>
        <g id="my" class="countryGroup">
          <path
            id="Malaysia_mainland"
            class="country"
            d="M2064.3,642.9c0.4-0.6,0.2-1.4,0.5-2.1c0.4-0.9,0.9,0.8,1.9,1.2
    		c0.8,0.3,1.5,0.3,2.2,0.5c0.7,0.2,0.8,1.1,1.1,1.6c0.6,0.7,2.6,0.6,1.9,2.5c-0.3,0.8-1.3,3.3,1,2.8c0.8-0.2,1.2-1.8,2.4-2
    		c1.2-0.2,1.2,1.7,2.1,1.3c0.7-0.3,2.5-3.6,1.5-4.4c0.9,0.3,2.3,0.7,2.9,1.5c0.6,0.8,0.9,1.8,1.7,2.5c2.1,1.7,3.9,3.2,5.1,5.6
    		c1.2,2.4,2.1,4.9,2,7.7c0,1.1-0.2,2.3-0.4,3.3c-0.3,1.5,0,1.6,0.4,2.9c0.7,2-0.6,4.4,0.6,6.4c0.9,1.6,3,2.4,3.5,4.3
    		c0.6,2.2,2.1,4.1,2.6,6.4c0.8,3-2.7,1.4-1.1,0.3c-0.6,0-1.1-0.3-1.4-0.9c0.1,0.2,0.7,2.2-0.2,1.6c-0.8-0.6-2.8,0.7-3.6,1.1
    		c-0.8-2.6-4-3.4-5.9-5c-0.5-0.4-0.9-0.9-1.3-1.4c-0.8-0.7-2-0.7-2.8-1.3c-0.8-0.6-2.2-1.9-2.6-2.8c-0.3-0.6-0.8-0.7-1.4-0.8
    		c-0.9-0.2-1.3-1.1-2.1-1.6c-0.7-0.5-0.6-0.6-0.2-1.2c0.4-0.6-0.1-1.8-0.4-2.4c-0.8-1.8-2.8-3-3.5-4.8c-0.4-0.9-2.2-1.3-0.1-2
    		c-1.3,0.2-2.6-2.5-2.2-3.4c0.7-1.8-0.3-3.8-1.4-5.1c-1-1.2-0.8-4.9-0.8-6.4C2066.4,646.6,2065,644.9,2064.3,642.9"
          />
          <path
            id="Malaysia_Borneo"
            class="country"
            d="M2138.4,680.6c-0.2,2.6,5.4,4.8,5.3,2.4c0,0.7,1,0.7,0.9,1c-0.2,0.8,1.4,1.2,2,1.4
    		c0.9,0.3,1.5-0.4,2.3,0.5c0.7,0.8,1.8,0.9,2.8,1c-0.7-1-2.2-0.1-2.6-1.6c-0.2-1,0.5-1.8,1.3-0.9c-0.8-0.5-0.9-1.3-0.6-2.1
    		c0.2-0.4,0.3-0.8,0.4-1.2c0.2-1,0.7-0.6,1.4-1.2c-0.5,0-1,0.1-1.5,0.1c0.7-0.7,0-1.5,0.2-2.2c0.8,0,1.4,0.4,2.2,0.4
    		c-2.6-2,1.3-4.3,2.9-4.5c2.6-0.4,6.2-0.9,8.4-2.5c1.9-1.3,1.9-4,3.6-5.5c2.1-1.9,3.3-4.1,3.5-7c2.1,0,2,0.8,2.4,2
    		c0.3,0.7,2.3,2.5,3,2.8c1,0.3,1.1-3.2,0.8-4.1c-0.7-2.2,0.5-2.3,1.5-3.4c0.4,1.8,0,4.5,2.4,5c-0.2-1.5-0.1-3.1-1-4.2
    		c-1.2-1.7,0.8-0.6,1.4-1.1c0.5-0.4,2.3-1.8,0.9-2.4c-2.2-0.8,0-2.4,0.6-3.4c-0.2,1.8,2.1-0.2,2.5-0.9c0.6-1.2,0.8-2.5,1.4-3.7
    		c0.3-0.7,0.7-0.4,1.1-0.8c0.4-0.5,0.8-1.4,1.3-2.1c1-1.4,0.9-3.3,2.2-4.6c1,1.2-0.2,2.4,0.3,3.7c0.8-0.5,1.6-3.7,2.6-3.5
    		c0.1,0,2.5,5.6,2.9,3.1c0.1,1,2.1,1.5,1.9,2.8c-0.1,0.8-1.2,0.5-0.9,1.7c0.4,1.3,0.2,1.7-1.1,2.1c0.8-0.5,1.7,0,2.4-0.4
    		c0.6-0.3,0.9-1.2,1.8-0.9c1.6,0.7,0.1,1.8-0.7,2.1c0.2,1.6,1.7,0.1,2.3-0.3c1.3-0.8,2.8,1.1,2.9,2.2c0.9-0.9,2.4,0.7,2.7,1.4
    		c0.7-1,2.7-0.2,2.7,0.9c0,1.7-3.6,3-4.9,2.9c-0.9-0.1-4.4-1.3-3.3,1.3c0.4,1,2.7,1.4,2.6,2.6c0.7-0.4,1.3,0.2,0.9,0.9
    		c-0.4,0.7-1.4,0.6-2.2,0.6c-2.3,0.2-3.2,0.8-5.1-0.9c-0.2,0.5,0.4,1.7-0.2,2.1c-0.8,0.5-2.3-0.6-2.8-1c-1.3-0.9-3.8,0.6-5.4-0.9
    		c0,2.1-1.4,0-2.1,0.4c-1.4,0.8-1.9,0.2-3.1-0.2c-0.2,0.8-0.8,1-1.1,1.7c-0.4,0.8-0.7,2-0.8,2.9c-0.1,0.7,0.2,2.2,0.3,3
    		c0.1,1.3-0.5,0.7-0.8,1.8c-0.4,1.7,0.2,2.4-1.7,2.7c-2.1,0.3-0.8,3.4-0.2,4.2c-1.2,0.4-4.3,2.4-2.9,3.9c0.8,0.9-2.1,3.9-1.9,5.3
    		c-1.1,0-1-1-2.4,0c-0.8,0.6-1.6-0.4-2.4,0.2c-1.2,1-1.7,1.6-3.2,1.4c-1.6-0.2-3.7-1.2-4.1-2.1c-0.6-1.2-4.8-0.6-5.8,0.7
    		c-0.5,0.7-0.5,1.6-0.9,2.3c-0.1,0.1-1.9,1.1-2,1.2c-1.4,0.5-3.1-0.1-4.6-0.2c-1.5-0.1-2.5,0.7-3.8,1.3c-1.5,0.7-2.6-0.6-3.4-0.9
    		c-0.4-0.2-1-1.5-1.4-1.8c-1.1-0.9-2.1-1.5-2.8-2.4c-0.5-0.5-0.6-2-1-2.5C2137,682.4,2138.2,681.7,2138.4,680.6"
          />
          <path
            id="path2560"
            class="countryIsland"
            d="M2199.2,662.5c0-0.3-0.1-0.5-0.1-0.8c0.7-0.1,1.3,0.4,1.8,0.7
    		C2200.3,662.4,2199.8,662.5,2199.2,662.5"
          />
          <path
            id="path4708"
            class="countryIsland"
            d="M2151,676.9c0.1,0.5,0.2,0.7,0.7,0.8c-0.4-1,0.1-2.3-0.9-3C2150.7,675.4,2151,676.2,2151,676.9"
          />
          <path
            id="path4710"
            class="countryIsland"
            d="M2179.5,652.9c0.7,0.3,0.8,0,0.3-0.7C2179.7,652.4,2179.6,652.6,2179.5,652.9"
          />
          <path
            id="path4712"
            class="countryIsland"
            d="M2191.6,636.4c0.4-0.2,0.7-0.6,0.9-1.1C2191.9,635.4,2191.5,635.8,2191.6,636.4"
          />
          <path
            id="path4714"
            class="countryIsland"
            d="M2193.3,635.5c-0.2,0.5-0.2,0.9,0.1,1.4C2194.9,636.3,2194.3,634.4,2193.3,635.5"
          />
          <path
            id="path4716"
            class="countryIsland"
            d="M2195.4,640.9c0.3,0,0.6,0,0.8-0.2C2196,640.7,2195.7,640.8,2195.4,640.9"
          />
          <path
            id="path4718"
            class="countryIsland"
            d="M2204.4,658.1c0.4,0.4,0.9,0.5,1.4,0.2C2205.3,658.2,2204.9,658.2,2204.4,658.1"
          />
          <path
            id="path2284"
            class="countryIsland"
            d="M2108.1,674c0.7-0.4,0.8-1.3-0.1-1.5C2108,673,2108.1,673.5,2108.1,674"
          />
          <path
            id="path2300"
            class="countryIsland"
            d="M2131.6,673.5c0.2,0.2,0.5,0.2,0.8,0c-0.1-0.3-0.2-0.5-0.4-0.7
    		C2131.9,673,2131.7,673.2,2131.6,673.5"
          />
          <path
            id="path4700"
            class="countryIsland"
            d="M2062.2,642.7c-0.4,0.4-0.8,0.7-1.2,0.9C2061.7,645.1,2063.4,643.6,2062.2,642.7"
          />
          <path
            id="path4704"
            class="countryIsland"
            d="M2066.1,651.4c-0.9-0.4-1,0.4-0.9,1.1C2065.3,653.9,2066.2,652.3,2066.1,651.4"
          />
          <path
            id="path4706"
            class="countryIsland"
            d="M2096.4,673.8c-0.3,0.2-0.4,0.4-0.4,0.8C2096.8,674.7,2097,674.4,2096.4,673.8"
          />
          <path
            id="path5822"
            class="countryIsland"
            d="M2212.5,658.4c0.4-0.7,0.3-1.6-0.1-2.3C2212.5,656.9,2212.5,657.7,2212.5,658.4"
          />
        </g>
        <g id="ph" class="countryGroup">
          <path
            id="path5902"
            class="countryIsland"
            d="M2249.7,589.8c0.2,0.2,0.3,0.2,0.4-0.1C2249.9,589.8,2249.8,589.8,2249.7,589.8"
          />
          <path
            id="path5800"
            class="countryIsland"
            d="M2192.4,631.1c0.5-0.8,0.5-1.7-0.3-2.3C2191.8,629.5,2191.9,630.4,2192.4,631.1"
          />
          <path
            id="path5806"
            class="countryIsland"
            d="M2204.4,638.3c0.1-0.8-0.2-1-0.9-0.5C2203.9,638,2204.1,638.1,2204.4,638.3"
          />
          <path
            id="path5832"
            class="countryIsland"
            d="M2215.4,654.9c0.4-0.3,3.1-1.7,2.9-2c-0.4-1.2-1.2-0.1-1.8,0.4
    		C2215.8,653.8,2214.5,653.8,2215.4,654.9"
          />
          <path
            id="path5838"
            class="countryIsland"
            d="M2220.1,643.5c-0.2,0.4-0.1,0.8,0.1,1.2C2220.5,644.2,2220.5,643.8,2220.1,643.5"
          />
          <path
            id="path5840"
            class="countryIsland"
            d="M2226,647.9c2-1-1.1-1.9-2.1-1.8c-1,0.2-0.9,1.5-0.6,1.4C2223.9,647.2,2225.7,647,2226,647.9"
          />
          <path
            id="path5858"
            class="countryIsland"
            d="M2230.1,641.9c0.1,0.9,1,1.4,1.8,1c0.8-0.4,0.8-1.3,1.3-1.9c-0.7,0.1-1.2-0.5-1.9-0.6
    		c-0.7-0.1-1.2,0.7-1.9,0.9C2229.6,641.5,2229.8,641.7,2230.1,641.9"
          />
          <path
            id="path5860"
            class="countryIsland"
            d="M2229.5,646c0.2,0.6,0.6,0.7,1.1,0.5C2230.3,646.3,2229.9,646.1,2229.5,646"
          />
          <path
            id="path5874"
            class="countryIsland"
            d="M2237.1,635.6C2239.7,634.5,2234.9,633.9,2237.1,635.6"
          />
          <path
            id="path5882"
            class="countryIsland"
            d="M2241.7,619.9c1.2-0.1,0.7-1.2,0.2-1.8C2241.2,618.4,2240.8,619.5,2241.7,619.9"
          />
          <path
            id="path5888"
            class="countryIsland"
            d="M2242.7,616c0.7,0,0.9-0.3,0.7-0.7C2242.8,615.2,2242.7,615.5,2242.7,616"
          />
          <path
            id="path5892"
            class="countryIsland"
            d="M2243.7,610c0.2-0.2,0.4-0.5,0.6-0.7C2243.6,609.3,2243.7,609.4,2243.7,610"
          />
          <path
            id="path5900"
            class="countryIsland"
            d="M2250.8,620c0.3-0.8-0.3-1.9-1.1-1.2C2249.8,619.5,2250.2,619.9,2250.8,620"
          />
          <path
            id="path5908"
            class="countryIsland"
            d="M2257,652c0.2,0.1,0.5,0.2,0.7,0.1C2257.5,651.9,2257.2,651.8,2257,652"
          />
          <path
            id="path5910"
            class="countryIsland"
            d="M2259.6,639c0.2-0.8,0-1.5-0.1-2.3C2258.2,636.9,2259.3,638.2,2259.6,639"
          />
          <path
            id="Philippines_Palawan"
            class="countryIsland"
            d="M2193.8,624.3c-0.4,0.7-0.2,1.5-0.4,2.2c2-2.4,4.7-3.2,6.5-6c0.6-1,6.1-4.6,4.2-6.3
    		c1.2,0.3,0.4-0.5,0.4-1.2c0.1-0.9,1.5-1,2.1-1.1c1-0.1,1.2-0.5,1.4-1.5c0.2-1.3,1.2-1,2.1-1.6c1.8-1.2,0.5-2.3-0.3-3.7
    		c-0.9-1.6,0.6-3.9-0.8-5.2c-0.9,0.8,0.2,3.7-1.1,2.5c0.3,0.6,1.1,2.3,1.2,3c-0.7-0.4-1.6-0.9-1.9-1.7c0,0.9,0.5,1.6,0.7,2.5
    		c0.3,0.8-0.8,3.4-1.2,1.6c-0.1,0.6-0.4,0.7-0.9,0.9c-0.4,0.1-0.2,1.2-0.3,1.5c-0.1,0.3-0.8-0.1-1,0.2c-0.2,0.5,0.4,0.9,0,1.4
    		c-0.2-1.7-0.9,0.3-1.2,0.7c-0.4,0.8-0.8,1.5-1.4,2.2c-0.9,1-1.4,2.4-2.5,3.3c-0.4,0.4-0.9,0.2-1.4,0.5c-0.5,0.4-0.5,1-1,1.3
    		C2195.6,620.8,2194.4,622.7,2193.8,624.3"
          />
          <path
            id="path5812"
            class="countryIsland"
            d="M2211.4,600.1c0.2-0.8-0.1-0.9-0.8-0.4C2210.9,599.8,2211.2,600,2211.4,600.1"
          />
          <path
            id="path5814"
            class="countryIsland"
            d="M2211.9,592.5c-0.2-0.2-0.5-0.4-0.7-0.6c-1.3,2.4,1.9,2.8,3.5,2.8c0-0.4-0.2-1-0.6-1.3
    		C2213.3,593,2212.8,593.2,2211.9,592.5"
          />
          <path
            id="path5816"
            class="countryIsland"
            d="M2211.1,578.8c0.3,0.7,1,1.1,1.6,1.5C2212.7,579.4,2212,578.8,2211.1,578.8"
          />
          <path
            id="path5818"
            class="countryIsland"
            d="M2212.2,595.3c-0.3,0.1-0.6,0.1-0.9,0.2c0.2,0.9,1.2,1,1.2,1.9
    		C2213.1,596.9,2212.4,595.9,2212.2,595.3"
          />
          <path
            id="path5820"
            class="countryIsland"
            d="M2213.4,607.3c-0.1-1.3-1.7-0.1-1.7,0.7C2212.6,608.4,2212.5,607.2,2213.4,607.3"
          />
          <path
            id="path5824"
            class="countryIsland"
            d="M2213.2,582.1c0.1,0.4,1.8,1.7,2.1,2c1.6,1.3,1.2,2.9,2.4,4.3c0.5,0.6,4,6.9,4.8,3.2
    		c0.2-0.8,0.6-1.4,0.8-2.2c0.1-0.8-0.6-1.5-0.6-2.2c0-1.9-0.9-3-2.2-4.3C2219.5,581.8,2214.2,580.9,2213.2,582.1"
          />
          <path
            id="path5828"
            class="countryIsland"
            d="M2214.4,596.4c0-0.5-0.1-1-0.2-1.5C2213.9,595.5,2214,596,2214.4,596.4"
          />
          <path
            id="Philippines_Luzon"
            class="countryIsland"
            d="M2206.3,559c-0.3,1.6,1,1.4,1.1,2.3c0.2,1.4,0.4,3.2,1.1,4.4c0.7,1.2,1,2.2,1.2,3.5
    		c0.1,0.8,1.4,2.5,1.2,0.8c1.3,0.5,0.6,3.1,2.1,3.6c2.3,0.7,0.5-2.3,0.5-3.2c0.6,0.2,5.3,1.8,3.5,3.1c-0.7,0.5-1.3,1.1-2,1.7
    		c-1,0.9,0,2.8,0.1,4c0.6-0.1,0.9-0.4,0.6-1c1.3,0,1.9,0.7,1.7,2c0.4-1.5,1.4-0.2,1.1,0.6c0.7-0.1,1.8,0.3,2.4-0.3
    		c0.5-0.5,0.7-1.3,1.3-1.8c0.2-0.1,1.3-1,1.5-0.8c0.9,0.7,2.1,1.1,2.9,1.9c1.1,1,3.2,2.5,3.5,4c0.1,0.5,1,1.3,1.2,0.4
    		c0-0.5-0.1-1-0.4-1.4c-0.1-0.2-1.2-1.7-0.6-1.5c-1.1-0.7-0.9-2.3-1.7-3.2c1.6-0.2,3,1.6,3.8,2.7c1,1.4,2.7,1.5,3.6,3.3
    		c0.2,0.5-0.3,1.4,0.4,1.5c0.7,0.2,1.4,0.7,2,1c0.5,0.3,1,1,1.6,0.7c0.4-0.2,1.1-0.9,1.7-0.8c-0.1,0.9-1.7,1.3-1,2.3
    		c0.1,0.2,1.5,2.1,1.8,1.2c0.6-1.5,0-6.6-2.8-4.7c-0.3-0.6,0-1-0.1-1.6c-0.2-0.8-1.3-1.8-1.9-2.2c-1.7-1,1.9-2,2.8-1.4
    		c-0.2-1.1-1.9-1.5-2.8-1.6c-1.5-0.1-1.7-1.4-2.9-1.6c-0.3,1,1.6,2.5,0,3c-1.1,0.4-1.5-2.3-2-3.2c-1-1.8-5.4-3.3-5.3,0
    		c-0.2-0.3-0.4-0.6-0.6-0.9c-1,0.9,1,1.5,0.4,2.1c-0.9,1.1-2.9-0.4-3.6-1.1c-0.6-0.6-3.3-5.1-1.3-5.3c-1.8-1.2-3.6-4.8-2.8-6.8
    		c0.6-1.4-0.1-2.7,0.4-3.9c0.6-1.5,2.6-1.6,3.7-2.7c-0.1,0.4-0.2,0.8-0.4,1.2c0.9-1,0.6-2.1,1-3.3c0.6-1.8,1.4-3.7,1.1-5.6
    		c-1,0.5-0.5-0.9-0.9-1.4c-0.9-1.3-1.9-1.3-2.5-3.1c-0.6-1.8-0.5-3.2,0.2-4.9c0.4-0.9-0.8-3.3-1.5-2c-0.3,0.5-0.4,1.6-1.2,1.5
    		c-0.5-0.1-1.6-0.7-2.1-0.1c-0.3-0.6-0.9-1-1.4-1.4c-1.1-0.7-2.5-1.7-3.8-1.3c-0.6,0.2-1.2-0.1-1.7-0.2c-0.5,0-0.1,0.7-0.6,0.7
    		c-1.4,0-0.5,0.8-0.5,1.6c-0.1,1.5-0.5,3.3-0.7,4.8c-0.4,2.7,1.7,5.1,1.1,8c-0.4,2.3,0.5,4.1,0.8,6.2c0.4,2.7-3.7-0.9-4-2
    		C2205.9,557.2,2206,558.2,2206.3,559"
          />
          <path
            id="path5826"
            class="countryIsland"
            d="M2213.8,531c0.3,0.5,0.8,0.8,1.4,0.9C2215.4,531.2,2214.5,530.6,2213.8,531"
          />
          <path
            id="path5830"
            class="countryIsland"
            d="M2214.4,535.5c0.3,0,0.5,0,0.8-0.1C2214.8,535.3,2214.6,535.1,2214.4,535.5"
          />
          <path
            id="path5834"
            class="countryIsland"
            d="M2216.8,522.6c0.2-0.4,0.4-0.7,0.4-1.2C2216.8,521.7,2216.6,522.1,2216.8,522.6"
          />
          <path
            id="path5836"
            class="countryIsland"
            d="M2218.4,535.7c0.5-0.4,0.7-0.9,0.5-1.5C2218.4,534.6,2218.2,535.1,2218.4,535.7"
          />
          <path
            id="path5842"
            class="countryIsland"
            d="M2223,568.8c0.2,0.9,0.7,2.6,1.7,3C2225.1,570.7,2224.8,567.2,2223,568.8"
          />
          <path
            id="path5844"
            class="countryIsland"
            d="M2226.8,577.4c-0.2-0.9-1.4-1.5-2.2-1.7C2225.1,576.5,2226,576.9,2226.8,577.4"
          />
          <path
            id="path5846"
            class="countryIsland"
            d="M2224.6,582.2c0,1.6,2.5,2.8,2.6,0.5C2227.2,581.6,2225,581,2224.6,582.2"
          />
          <path
            id="path5848"
            class="countryIsland"
            d="M2226.6,571.2c0.2-0.7,0-0.9-0.7-0.6C2226.1,570.8,2226.3,571,2226.6,571.2"
          />
          <path
            id="path5850"
            class="countryIsland"
            d="M2226.5,596.7c2.3,0.4,1.8,2.2,1.7,4c0,1.3,0.2,2.2-0.2,3.5c-0.4,1.4,0.4,2.6,0,4
    		c1.3,0.3,2.2-2,3.6-2c1,0,2.7-1.4,2.4-2.3c-0.2-0.8,2.8-1.1,2.4-2.3c-0.6-1.6,0.6-3.7-1.9-1.9c0.6-0.7-0.3-1.6-1.1-1.4
    		c-0.7,0.2-1.1,1-1.7,0.4c-0.7-0.7-1.1-1.5-2.1-1.9c-0.9-0.4-2-0.6-2.5-1.4C2226.9,595.7,2226.6,596.2,2226.5,596.7"
          />
          <path
            id="path5852"
            class="countryIsland"
            d="M2227.1,590.4c-0.1,1.3-0.6,2.4,0.4,3.5c0.2-1.5,0.3-3,0.6-4.4
    		C2228.2,588.7,2226.4,589.2,2227.1,590.4"
          />
          <path
            id="path5854"
            class="countryIsland"
            d="M2227.1,571.6c0.2,0,0.5,0,0.7-0.1C2227.6,571.5,2227.4,571.5,2227.1,571.6"
          />
          <path
            id="path5856"
            class="countryIsland"
            d="M2229,590.4c0.6,0.1,0.6-0.1-0.2-0.7C2228.9,589.9,2228.9,590.1,2229,590.4"
          />
          <path
            id="Philippines_Mindanao"
            class="countryIsland"
            d="M2230.1,636.7c-0.4,2,2.4,2.9,2.6,0.5c0.1-0.9,0.4-3.3,1.1-3.5
    		c0.5-0.2,0.9-3.4,2.1-2.4c0.9,0.7,0.5,2.1,1.2,2.9c0.2-1.3,0.6-0.7,1,0c0.3-0.8,0-1.9,1-2.2c0.4,0.5,0.4,1.1-0.1,1.6
    		c1,0,1.6,1.3,2.6,1.1c-0.3-0.4-0.7-1-0.6-1.6c0-0.3,0.7-0.3,0.8-0.6c0.2-0.8-0.6-0.6,0.4-1.4c0.6-0.5,1.2,0.4,1.7,0.6
    		c1.6,1,3,1.3,3.6,3.4c0.3,1.1-1.3,2.2-1.4,3.4c-0.1,1.2,0.5,2.9,0.8,4.1c0.4,1.9,2,3,3.6,3.8c0.7,0.4,3.3,2.1,4.1,1.4
    		c0.6-0.6,0.3-1.9,1.5-1.8c0.1,0-0.2,5.6,1.5,4.4c1.2-0.8,2.2-3.6,2-5c-0.3-1.9-1.7-2.7-2.5-4.3c-0.4-0.9,1.8-6.2,2.6-5.8
    		c0.6,0.3,2,3.3,2.3,4c0.3,0.7,0.1,1.4,0.2,2.2c0.1,1.1,0.9,1.8,1.1,2.8c0-1.7,0.8-3.8-0.5-5.3c0.6-0.3,1.1,0.1,1.4,0.6
    		c-1.1-1,0.5-1.9,1-2.6c1-1.4,0.3-3.4,0-4.9c-0.2-1.2-1.8-1.2-1.2-2.5c0.4-0.8,0.4-2.7-1-2.1c2.3-2-1.9-2.6-1.9-3.7
    		c0-0.6,1.2-0.9,1.4-1.5c0.4-1.1-0.8-1.5-1.1-2.3c-0.1-0.4,0.3-2.3-0.4-1.9c-0.9,0.5-1.1,0.1-1.6-0.6c-0.6-0.8,0-1.3-1.3-1.7
    		c-1.4-0.4-1.7-1.7-2.9-2.2c-0.8,1.6,0.6,3.8,1.1,5.3c0.1,0.4,0.6,1.6,0,1.8c-0.8,0.2-2,0-2.3-0.9c-0.7,2.1,0,2.3-1.9,0.9
    		c-1.9-1.3-0.2,3.8-1.8,4c-1,0.1-1.2-1.1-2.1-0.7c-0.9,0.4-0.7,2.2-1,3c-0.4,1-1.8,0.5-2.5,1.1c-0.4,0.3-1.1,1.1-1.7,1.2
    		c-0.6,0,0.7-0.9,0.6-0.8c0.8-0.5,0.9-0.8,0.7-1.7c-0.1-0.7-1.1-4.3-2.4-2.6c-0.2-0.6-0.6-1.3-1.3-1.3c0.4,2-2.1,1.2-2.6,2.3
    		c-0.7,1.3-0.1,2.4-1.7,3c-1.6,0.5-4,0.1-4.7,2.1c-0.3,0.9-0.1,1.9-0.2,2.8C2231.2,634.4,2230.6,635.6,2230.1,636.7"
          />
          <path
            id="path5864"
            class="countryIsland"
            d="M2230.9,590.4c-0.3,0.2-0.5,0.4-0.7,0.6c0.6,0.5,1.2,1,1.8,1.5
    		C2232.7,591.5,2232.1,590.4,2230.9,590.4"
          />
          <path
            id="path5866"
            class="countryIsland"
            d="M2233.9,617c0.7,0.5,1.7,0.5,2.2,1.2c0.5,0.6,0.2,1.5,0.8,2c0.8,0.7,1.8-0.1,2.2-0.8
    		c0.7-1.3-0.6-2.2-1-3.3c-0.6-2.1,0.2-5.2,0.8-7.2c0.5-1.6,2.2-3.7,0-4.8c-0.7-0.4-1.6-0.5-2.4-0.2c-1.3,0.6-0.8,1.7-1.1,2.8
    		c-0.2,0.7-0.9,0.9-0.6,1.7c0.3,0.9,0.7,1.9,0.3,2.9c-0.6,1.4-3.2,0.5-3.2,2.1C2232.1,614.6,2233.2,615.9,2233.9,617"
          />
          <path
            id="path5868"
            class="countryIsland"
            d="M2232.8,608.5c0.6-0.3,2.2-2.3,0.8-2.6C2232.6,605.7,2232.3,608,2232.8,608.5"
          />
          <path
            id="path5870"
            class="countryIsland"
            d="M2237.1,588.7c-0.8-1.3-1.8-3.2-3.3-3.8C2233.1,586.2,2236.6,587.9,2237.1,588.7"
          />
          <path
            id="path5872"
            class="countryIsland"
            d="M2236.4,594.7c0,0.3-0.1,0.5-0.1,0.8c1-0.7,1.8-3.4,3-2c0.5,0.6,1.1,1.1,1.5,1.7
    		c0.6,0.8,1.7,0.8,2.3,1.6c1.1-2.1-1.8-3.5-2.8-4.6c-0.4-0.5-1.1-1.1-1.6-1.5c-0.3-0.3-2-1.1-2.4-1.1c0.3,0.7,0,2.5,0.4,2.9
    		C2237.3,593.6,2236.4,593.6,2236.4,594.7"
          />
          <path
            id="path5876"
            class="countryIsland"
            d="M2239.6,590.4c0.3,0.5,0.6,1,1.1,1.4c-0.1-1-0.4-2.2-1.4-2.7
    		C2239.1,589.6,2239.2,590,2239.6,590.4"
          />
          <path
            id="path5878"
            class="countryIsland"
            d="M2239.7,612c0.1,1.5-0.4,3-0.5,4.5c2.1,0.5,1.6-4.4,2.4-5.3c1-1.2,2.1-2.2,2.3-3.9
    		c0.2-2-0.4-4-0.4-5.9c-1,0.2-1.3,3.3-1.4,4.1C2241.7,607.8,2240.2,609.7,2239.7,612"
          />
          <path
            id="path5880"
            class="countryIsland"
            d="M2241.6,584.2c0.1-0.5-0.1-0.6-0.7-0.3C2241.1,584,2241.4,584.1,2241.6,584.2"
          />
          <path
            id="path5884"
            class="countryIsland"
            d="M2242.7,581.6c1.8-1.3,1.5-4.1-0.9-4.6C2241.1,578.5,2241.6,580.6,2242.7,581.6"
          />
          <path
            id="path5886"
            class="countryIsland"
            d="M2243.1,584.6c-0.2-0.2-0.5-0.2-0.8-0.1C2242.6,584.5,2242.9,584.6,2243.1,584.6"
          />
          <path
            id="path5890"
            class="countryIsland"
            d="M2243.3,614.5c-0.2,2.8,4.8-0.4,5.5-0.1c-0.4-1.4-0.4-4.3-3-3.6
    		C2244.9,611,2242.1,613.7,2243.3,614.5"
          />
          <path
            id="path5894"
            class="countryIsland"
            d="M2244.3,590.4c0.6,1.5,1.2,2.8,2.5,3.9c0.7,0.6,2.9,2.9,3.8,2.5c-0.3,0.7-0.3,1.7-1.2,1.9
    		c0.4,0.4,0.8,0.8,1.1,1.3c-0.8-0.8-1.2,0.2-1.8,0.6c-1,0.6-2.5-1-2.9-1.7c-0.7,0.9,0.6,5.2,1.7,5.6c-0.2-1.9,2.1,0.6,2.1,1.5
    		c0.1,2.5-0.3,4.4,2.3,5.7c-0.1-0.8-0.5-1.5-0.5-2.2c0.4,0.5,1.9,0.5,2-0.3c0.1-0.4-1-2.5-1.3-3c-0.6-1-0.8-0.9-0.9-2.3
    		c-0.1-0.9,0.1-2.3-0.6-3c0.6-0.2,2.3,1.5,2.3,1.5c0.7-0.8,3.1,0.2,3.8,0.6c-0.6-0.6-0.9-1.5-1.8-1.8c1.1-0.9-1.1-3.4-1.2-4.5
    		c-0.1-1.1,0.4-2-0.2-3c-0.4-0.6,1.1-0.4-0.1-1.1c-0.6-0.4-1.4-0.1-1.4-1.1c0.1-1.3-1-1.3-1.9-1.5c-1-0.2-1.3-0.2-2.3,0.1
    		C2246.7,590.6,2245.4,589.6,2244.3,590.4"
          />
          <path
            id="path5896"
            class="countryIsland"
            d="M2246.8,599.4c1.3,0.2,0.3-2.8-1.2-1.9C2245.9,598.2,2246.1,599,2246.8,599.4"
          />
          <path
            id="path5904"
            class="countryIsland"
            d="M2253.3,612c0.3,0.2,0.5,0.5,0.8,0.7c-0.1-0.7-0.5-1.8-1.4-1.5
    		C2252.9,611.5,2253.1,611.8,2253.3,612"
          />
          <path
            id="path5906"
            class="countryIsland"
            d="M2256.2,612c0.6,0.4,0.6,1,0.1,1.5c1.1,0.2,0.8-1.4,0.7-2c-0.1-0.6-0.5-1.2-0.6-1.8
    		c-0.1-0.6,0.3-1,0.1-1.6c-1.4,0.6-0.3,1.8-1.1,2.7C2256,611.1,2256.4,611.4,2256.2,612"
          />
          <path
            id="path5912"
            class="countryIsland"
            d="M2259.3,615.9c0.5-0.5,0.1-1.2-0.4-1.5C2258.9,614.9,2259,615.4,2259.3,615.9"
          />
          <path
            id="path5914"
            class="countryIsland"
            d="M2259.8,612c-0.4,0.5-0.7,2.5,0.5,2.3C2261.4,614,2260.5,612.2,2259.8,612"
          />
          <path
            id="path5920"
            class="countryIsland"
            d="M2246.2,607c0-0.3-0.1-0.5-0.1-0.8C2246.9,606.3,2246.9,606.6,2246.2,607"
          />
          <path
            id="path5924"
            class="countryIsland"
            d="M2250.5,600c0.1,0.3,0.2,0.6,0.1,0.9C2250.5,600.7,2250.5,600.4,2250.5,600"
          />
          <path
            id="path5936"
            class="countryIsland"
            d="M2240.4,603.1C2239.8,600.3,2243.7,601.8,2240.4,603.1"
          />
        </g>
        <path
          id="uy"
          class="countryGroup country"
          d="M879.7,962.8c0.6-0.9,1.9-1.7,1.4-3c1,0.7,1.9,1.2,3.1,0.6c0.7-0.3,0.7-1.4,1.5-1.5
    	c1.6-0.2,3.1,1.7,4.1,2.6c1.4,1.2,4,2.2,4.2,4.2c0.1,0.7,0,2,1,1.7c0.8-0.2,2.1-0.9,1.6-1.9c1.3,0.3,3.7,4.3,4.7,3.9
    	c1-0.3,1.1,0.4,1.9,0.9c1,0.7,2.1,0.7,3,1.6c0.7,0.7,1.2,1.5,2.1,2.1c1.1,0.7,2.4,0.8,3.3,1.7c1.8,1.6,2.4,3.6,4.6,4.7
    	c2.6,1.3,1.3,2,0.1,3.9c-0.9,1.4-0.3,5,1.1,5.9c0.9,0.6-0.6,3.9-1.2,5c-0.5,0.8-2.2,4.4-3.6,3.1c0.8,1-0.9,1.5-1.6,1.8
    	c-1.8,0.7-2.4,0.8-4.3,0.4c-1.5-0.3-3.2-1.1-4.7-0.3c-0.6,0.3-1.3,0.7-2.1,0.8c-1.1,0.1-1-0.7-1.8-1.1c-1.2-0.7-2.8-0.4-4-1.2
    	c-1.7-1-2.3-2-4.4-1.9c-1.4,0.1-2.5,0.7-3.5-0.5c-1.3-1.5-3.4-2.5-4.5-4.2c-0.8-1.2-1.9-6.3-0.2-6.8c1.8-0.5,0.5-2.5-0.1-3.5
    	c-0.4-0.7-0.6-1.4-0.9-2.1c-0.2-0.6,0.5-1.1,0.3-1.9c-0.3-0.7-1.5-2.8-0.9-3.5c1.4-1.6-0.4-2.5,0.2-3.6c0.4-0.8-0.1-4.2,0.1-4.3
    	C881.6,965.2,879.9,964,879.7,962.8"
        />
        <path
          id="cg"
          class="countryGroup country"
          d="M1385.6,732.6c0.6-1.8,0.9-2.3,2.4-3.4c1.3-1,1.7,2.3,3.4,1.2c0.2-0.1-0.1-1.6,0.2-2.1
    	c0.6-1.2-0.6-1.4-1.3-1.9c-0.6-0.4,1.1-4.2-1.6-3.5c0.9-1.3,0.7-2.8,0.3-4.2c1.1,0.3,1.5,0.1,2.6,0.2c0.5,0,0.7,0.7,1.1,0.6
    	c1.3-0.3,3.6,0,3.3-1.9c-0.2-1.3-0.7-4.2,1.9-2.8c0.9,0.5,2.6,3.3,2,4.3c1.2-0.7,2.3,0.8,3.5,0.5c1-0.3,2-1.9,2.3-2.9
    	c0.3,0.6,0.9,3.8,1.6,3.8c0.5,0,2-1.5,1.9-2.1c-0.1-0.6,0.1-2.8,0.7-3.2c0.8-0.5,1.6-2.1,0.6-2.8c1.4-0.5,0.7-3.3,0.4-4.3
    	c-0.5-1.5,1.1-3.3,0.4-4.6c-0.4-0.8-1.6-1.1-2.4-1.1c-0.4-0.3,0-1.5-1-1.6c-2.5-0.2-0.6-1.6-0.9-3.1c-0.2-1.1,0.2-3.1,1.1-3.9
    	c1-0.7,1.9-0.4,2.6-1.8c0.5-1.1-0.3-1.9-0.7-2.9c-0.7-1.7-1-3.3-3.2-3c-1.9,0.3-3.6,1.4-5.5,1.7c0.5-1.4,0-3-0.2-4.4
    	c-0.2-1.6,0.8-3.1,0.9-3.9c0-0.6,7.7,0.2,8.4,0.1c1.3-0.1,1.7,0.2,3,0.3c1,0.1,1.2,1.5,2.6,1.1c2.7-0.7,5.6,1.2,7.4,3
    	c0.9-1.3,0-2.2,0.1-3.5c0-1.1,1.1-1.7,1.5-2.7c0.6-1.3,1.2-2.6,1.6-4.1c0.3-1.1-0.2-2.2,0.1-3.3c0.2-0.8,0.4-2.1,1.2-2.5
    	c1.1-0.6,2.4,0,3.6-0.5c1.2-0.5,2.2-1.4,3.6-0.7c1.3,0.6,2.3,1.2,3.8,1c1.9-0.3,4.3-1,4.1,1.6c-0.2,2.3-1.8,5.4-2.9,7.3
    	c-1.3,2.3-1.3,3.8-1.3,6.4c0,1.5-0.3,2.9-0.7,4.4c-0.5,1.5-0.8,2.4-0.9,4.1c0,1.1-0.1,2.1-0.2,3.2c-0.1,1.1-0.4,2.5-0.7,3.5
    	c-0.7,2.7,0.6,4.4-1.6,6.9c-2,2.2-4.1,2.8-5.5,5.3c-0.7,1.2-1.2,2.4-1.7,3.7c-1,2.4-3.3,2.8-3.1,4.2c0.2,2.4,0,4.7,0,7.4
    	c0,2.6-1.2,6.6-3.6,7c-0.9,0.2-1.8,1.1-2.1,2.1c-0.2,0.5-1.8,1.2-2.2,1.8c-0.9,1-1.5,2.3-2.5,3.3c-1.3,1.3-2.5-0.2-3.3,0.7
    	c0-0.9-0.8-2.6-0.2-3.4c0.5-0.6,0.8-1.2-0.3-1.5c-1.3-0.4-3.1,2.3-4.2,0.8c-0.6,0.6-0.5,1.3-0.8,2c-0.4,0.9-1.6,1.3-2.3,1.9
    	c-0.1-1.2-1.8-1.4-2-2.5c-0.9,1.6-1.8-3.3-3.4-0.9c-0.5,0.8-1.4,0.8-2.1,1.3s-1.8,1.9-2.4,2.6c-1.6,1.8-2.1-1.5-2.3-2.5
    	C1390.1,736.4,1387.4,734.3,1385.6,732.6"
        />
        <g id="ee" class="countryGroup">
          <path
            id="Estonia_Saaremaa"
            class="countryIsland"
            d="M1451.8,202.7c1.1-0.4,0.8-1.3,1.4-2.1c0.4-0.5,1.3-0.3,1.8-0.6c0.3,0,0.7,0.1,1,0.1
    		c0.3-0.2,0.6-0.5,0.9-0.8c0.7-0.4,1.2-1.2,2.1-0.9c-1.2-1.2-2.4-1.3-4-1.2c-0.9,0.1-1.4-0.1-2.1,0.5c-0.6,0.5-1.8,0.1-2.4,0.2
    		c0.9,0.8-0.1,1.5,1.1,2.3C1453.1,201.2,1451,201.8,1451.8,202.7"
          />
          <path
            id="Estonia_Hiiumaa"
            class="countryIsland"
            d="M1454.7,193.3c-1,0.9-2.2,1-3.5,1.2c1,0.5,2.3,0,2.8,1.2c0.4,0.9,1.2,0.8,1.6,0
    		c0.2-0.4,1.6-0.2,1.3-1C1456.6,193.8,1455.2,193.9,1454.7,193.3"
          />
          <path
            id="Estonia_Muhu"
            class="countryIsland"
            d="M1458.3,197.5c0.5,0.2,0.9,0.1,1.2-0.3c-0.3-0.5-0.9-0.8-1.5-0.5
    		C1458.1,197,1458.2,197.2,1458.3,197.5"
          />
          <path
            id="Estonia_Vormsi"
            class="countryIsland"
            d="M1458.2,193.6c0,0.2,0,0.4,0,0.6c0.3-0.2,0.5-0.3,0.7-0.6
    		C1458.7,193.6,1458.4,193.6,1458.2,193.6"
          />
          <path
            id="Estonia_mainland"
            class="country"
            d="M1460.4,197.4c-0.5-1.5,1.3-1.2,2.2-1.6c-0.6-0.2-1.8,0.3-2.3-0.1
    		c-0.9-0.7,0.7-1.3,0.2-2.2c-0.2,0.1-0.5,0.3-0.7,0.4c0.2-0.5-0.1-1.2,0.3-1.6c0.8-1,2.3-0.7,3.4-0.7c-0.2-0.3-0.3-0.6-0.4-0.9
    		c1.1,0.6,1.5-0.8,2.6-0.6c0.5,0.1,2.2,0.8,2-0.4c0.6,0,4.9,0.7,4.2-0.9c1.6,0.5,3.7,0.3,5.4,0.5c1.5,0.2,2.7,0.8,4.2,1
    		c1.4,0.2,6.7-0.9,7.2,0.7c-1.4,0-1.6,0.7-1.9,1.9c-0.4,1.3-2,1.7-2,2.8c0,0.9,0.6,1.4,0.8,2.2c0.2,0.7-0.4,1.3-0.1,2
    		c0.2,0.5,0.9,0.9,1.2,1.3c0.6,0.6,0,1,1.1,1.6c-1,1.1-2.4,1.6-2,2.8c-0.9,0-2.1-0.5-2.9-0.6c-1-0.1-1.6,0.8-2.6,0.6
    		c-2-0.4-3.6-2.2-5.5-2.9c-0.5-0.2-1.5-0.7-2.1-0.7c-0.8,0.1-1.2-0.5-1.9-0.5c-1.6,0-3.2,1.9-4.8,1.4c0.1-0.8,0.4-2.7,0.8-3.3
    		c0.5-0.7-0.7-1.1-1.1-0.3C1464.6,201.6,1461.3,197.4,1460.4,197.4"
          />
        </g>
        <path
          id="rw"
          class="countryGroup country"
          d="M1521.2,720.1c-0.5-1.1,2.2-3,2.2-4.4c0-2.6,1.1-3.7,3.3-4.9c0.3-0.2,1.6-1,1.9-0.7
    	c0.5,0.4,0.6,1.6,1.4,0.8c0.6-0.6,2.5-3.5,3.6-3.1c-0.5,1.5,0.8,3.3,1.4,4.4c0.8,1.4,1.7,3.7,1.5,5.2c-0.3,2-1.4,2.2-3.1,1.6
    	c-1.9-0.7-2.3,1-3.9-0.4c-0.2,1.8-0.1,3.7-2.2,4.1c-1.1,0.2-2.1,0.4-2.6-0.7c-0.6-1.2-2.2-1.1-2.3,0.3
    	C1521.6,721.7,1521.2,721.1,1521.2,720.1"
        />
        <path
          id="am"
          class="countryGroup country"
          d="M1610.8,342.5c0.8,0,3,0.5,4.2,0c1.8-0.7,4-0.4,6-0.4c-0.1-0.4,0.5-0.7,0.9-0.7
    	c0.2,0.2,2.7,1.9,0.7,1.5c1.3,1,3.4,1,4,2.8c-2.6,0.8,0.3,3.1,1.4,3.8c0.6,0.4,1.9,0.6,2.1,1.4c0.3,1.6-1.7,1.3-2.5,1.7
    	c0.9,0.3,4.2,3.7,5.1,3.4c0.5-0.2,2.6,0.5,1.8,1c-1.2,0.7,0.9,1.7,1.3,2.2c-1.3,0.2-0.6,2.3-0.1,2.8c-0.2,0.1-2.6,0.6-2.7,0.3
    	c-0.8-1.3-1.2-3.2-2.6-3.9c-0.6-0.3-0.1-1.7-1-1.9c-0.6-0.1-1-0.1-1.8,0.1c-1,0.3-1.5-0.9-2.4-0.4c0.1-2.1-2.2-1.1-3-1.1
    	c-1.1-0.8-2.6-2.9-4.1-2.9c-1.6,0-2.9,0-4.4-0.7c0.6-0.4,0.2-1.1,0-1.7c-0.4-1-0.5-1.2-0.1-2.1
    	C1614.3,346.1,1611.4,345.5,1610.8,342.5"
        />
        <path
          id="sn"
          class="countryGroup country"
          d="M1170,571.1c-0.4,0-0.8,0.1-1.2,0.4c-0.1-1.2,2.2-1.6,2.9-2.3c1.6-1.5,2.8-3.6,3.7-5.6
    	c0.4-0.9,2.5-9.9,4.7-7.9c1,0.9,6.2,0,7.1-1c1.3-1.5,6.4,0.2,7.5,1.5c1.4,1.7,1.6,2.4,4,2.7c1.3,0.1,2.7,2.7,2.5,3.9
    	c-0.1,0.6,1.8,1.6,2.1,2.2c0.4,0.8,0.6,1.7,1.2,2.3c0.6,0.6,1.8,0.7,2.2,1.5c0.6,1.2,2.3,1.8,2.5,3.1c0.1,0.6-0.9,1.3-0.2,1.9
    	c0.6,0.5,1,0.8,1.1,1.7c0.1,0.9,0.9,3.2,0.2,3.9c-0.8,0.2-0.9,0.6-0.2,1.2c0.2,0.4,0.4,0.8,0.8,1.1c0.7,0.4,0,1.1,1,1.4
    	c0.5-2,2.2,1.5,2.4,2.1c0.4,0.8,0.7,1.5,0.4,2.4c-0.3,1.1,0.5,2.5,0.1,3.4c-1.2-0.5-2.6,0.3-3.9,0.1c-1.4-0.2-2.2,0.6-3.5,0.7
    	c-1.3,0.2-3.4-2.2-4.6-1.4c-0.9,0.6-0.6-1-0.7-1.1c-1.2-0.3-0.8-0.6-1.8-0.6l-12.7,0c-1.7,0-2.3,0.1-3.6,1.1
    	c-1.1,0.9-2.6,0.8-4.2,0.7c-1.4,0-2.4,0.8-3.7,1c-0.7,0.1-1.3-0.1-1.9,0.3c-0.1-0.3-0.8-1-0.5-1.4c0.9-1,1.4-1.5,2.6-0.7
    	c0.9,0.6,2-0.3,3-0.3c0.7,0,1.1,0.5,1.8,0.3c0.6-0.2,0.8-0.1,1.3,0.1c0.9,0.3,0.8-2,1.9-2.4c-1.5,0.1-0.7,1.4-1.6,2.1
    	c-0.8,0.6-3-0.2-3.2-1.2c-0.6,2.4-5.4,0.7-4.3-0.6c-0.4,0.6-0.4,1.7-1.4,1.9c-0.2-0.6,0-1.1,0.5-1.4c-1.3,0.3-0.6-1.6-0.3-2.5
    	c0.4-1.3,1.9-1.4,3-1.4l4.1,0c0.8,0.4-0.9-0.3,0.5-1.2c1.2-0.8,2.4-0.2,3.5-0.6c1.3-0.4,0.6-2.2,2.3-1.1c2.4,1.5,4.7,3,7.5,2.1
    	c1.1-0.3,2.2-1.4,0.9-2.1c-1.7-0.9-2,0.4-3.3,0.3c-1.3-0.1-1-2.1-2.5-1.4c-0.6,0.2-1.1-0.6-1.3-1c-0.4-0.8-1.6-0.5-2.4-0.1
    	c-1.1,0.7-1.3-0.4-1.9,0.2c-0.4,0.4-0.3,1-0.8,1.3l-7.5,0c0.3-0.6-0.3-1-1-1.1c0.1-0.6,0.5-0.8,1.1-0.6c-0.1-0.6-0.1-1.2,0.1-1.8
    	c-0.4,0.8-1,1.4-1.9,1.6c-0.3-0.8,2.1-2.9,2.9-3c-0.9-0.4-3.2,1.8-3.2,2.6C1174,576.3,1172.6,572,1170,571.1"
        />
        <path
          id="tg"
          class="countryGroup country"
          d="M1299.8,602.2c1.5,0.4,3,0.5,4.4,1.2c1.1,0.5,2.9,0.2,3.8-0.2c-0.6,1.7-1.8,4.5-0.5,6.1
    	c0.8,0.9,3.5,1.9,3.6,3.2c0.2,1.8-0.2,4,0.7,5.7c1.1,1.9,1.6,3.2,1.5,5.4c-0.1,3.9,0,7.8,0.1,11.7c0,0.6,0.2,2.9-0.9,2.9
    	c0.4,1.1,0.2,2.4,0.7,3.5c0.4,0.9,1.1,1.7,1.2,2.7c-1.6,0.1-3.2,0.9-4.6,1.5c0-0.7-1.8-1.7-2.3-2.1c-1.1-0.9-1.9-1.6-2.5-2.8
    	c-0.9-1.7,0.8-3.3,0.8-5c0-1.1-1.3-1.2-1.1-2.3c0.3-1.3,1-2,0.8-3.5c-0.1-0.8-0.2-1.6,0-2.3c0.2-0.5,0.8-0.9,0.7-1.4
    	c-0.4-1.5-1.9-2.1-2.5-3.5c1.7-0.2,0.6-2.5,1.1-3.6c0.3-0.6,0.3-1.6,0-2.2c-0.6-1.1-1.4,0.1-2.2,0c0.5-0.6,0-1.4,1.1-1.2
    	c-0.2-1.2,0.6-3.2,0.2-4.2c-0.7-1.7,0.5-1.9-1.2-2.9c-0.9-0.5-2.2-2.3-2.1-3.4c0.1-0.9,0.9-1.4,0.6-2.3
    	C1300.9,602.5,1300.1,602.5,1299.8,602.2"
        />
        <g id="es" class="countryGroup">
          <path
            id="Spain_mainland"
            class="country"
            d="M1244.1,328.1c0.1-0.1,0.2-0.2,0.2-0.4c-0.9,0.1-1-0.7-0.7-1.4c0.4-1.1,1.2-0.8,1.8-1.5
    		c1.3-1.4,4.1-0.7,5.8-1.5c-1.4,0.1-0.5-0.4,0-0.6c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2-0.9,3-3.4,3-1.8c0.5-0.3,1-0.6,1.4-0.9
    		c0.1,1.1,4.3,0.6,4.3,2.4c1.3-1.6,5-0.4,6.8-0.6c1.5-0.2,1.9-0.3,3.5,0c1.7,0.4,3.4,0.8,5.1,1.1c2.3,0.4,4.5,0.2,6.6-0.3
    		c1.4-0.3,3,0,4.2,0.7c1.6,1,1.4-0.4,2.9-0.3c1.3,0.1,2.6,0.6,3.9,0.9c0.8,0.2,3.3-0.7,3.7-0.1c0.4,0.6,2.7-0.1,2.3,1.3
    		c-0.1,0.3-0.5,0.7-0.2,1c0.6,0.7,0.4-0.1,1.1,0c2.1,0.1,4.8,2,5.6,2.4c0.4,0.2,0.9-0.2,1.3-0.1c0.5,0.2,0.9,1,1.4,0.9
    		c1.4-0.3,3.2-0.2,4.9,0.2c-0.2-0.6,0-1.1,0.4-1.5c0.9,0,1.5,0.1,3,1c1.3,0,2.4,0.5,2.2,2.6c1.7-0.3,1.6-1.5,1.8-0.7
    		c0.3,0.2,2.1,1.2,2.5,1.1c0.5-0.2,0.9-0.5,1.5-0.3c1.1,0.4,2.2,0.8,3.1,0.1c0.7-0.5,1.3-0.5,2.9-0.3c0.6,0.1,0.1,1.1,0.9,1
    		c0,0.8-0.5,0.5-0.9,0.9c-0.6,0.5,0,1.8,0,2.5c-0.1,1.9-4.1,3.2-5.5,3.8c-1,0.4-1.3,1.4-2.2,1.8c-1,0.5-2.4,0.5-3.5,0.8
    		c-2.7,0.7-4.3,1.8-6.4,3.3c1.7,1.3,0.1,1.6-1.1,2.2c-1.7,1-2,3.3-3.6,4.3c-2.8,1.8-3.8,6.9-1.5,9.4c1.4,1.5,2.5,1.9,0.2,3.4
    		c-1.3,0.8-2.3,1.2-3.4,2.4c-0.7,0.7-1.2,1.8-1.7,2.8c-0.3,0.6-0.6,1.4-0.6,2.1c0,0.6,1,1.4,0.4,0c0.9,1.5-2.8,1.6-3.5,1.7
    		c-1.7,0.2-4.2,1.8-4.6,3.5c-0.4,1.5-1.6,4.1-3.2,2.8c-1-0.8-1.7,0.3-2.5,0.6c-1.2,0.5-2.4-0.2-3.6,0c-2.6,0.4-5.1,0.1-7.7,0.2
    		c-1.4,0-1.7,0.3-2.7,1.2c-1.2,1.1-2.7,0.6-4,1.3c-0.7,0.3-1.3,2.6-1.9,2.5c-0.7-0.1-0.6,1.2-1.7,1.1c-0.9,0-1.6-1.6-2.5-1.5
    		c-1,0.1-1.7-1.9-1.9-2.6c1.3-0.2-0.3-1.1-0.7-1.4c-0.9-0.7,0.2-1.9,1.1-2.2c-0.6-0.2-0.8,0.1-0.9,0.6c-0.5-0.5-4.3-3.1-3.5-3.9
    		c-1.3-0.1,0,0.6-1.4,0.6c-0.6,0-1.4,0.3-2.1,0c-0.9-0.3-0.9-2.6-0.7-3.4c0.1-0.6,0.6-1.1,1.1-1.6c0.5-0.5,0.6-1.4,1.1-1.9
    		c1.1-1.1,1.5-0.2,2.2-1.9c-1.4,0.5-2.5-1.4-2.5-2.5c-0.1-1.8,2-2.7,2.5-4.2c0.3-0.9-1.4-2-1.7-2.8c-0.3-0.6,0-1-0.5-1.6
    		c-0.4-0.5-0.9-1-1.2-1.5c0.9,0.3,1.9,0.1,2.8,0.1c1,0,0.7-0.6,1-1.2c0.4-0.7,1.3-0.8,0.3-3.1c-0.3-0.7,1-1,1.1-1.1
    		c0.7-0.3,0.4-1.2,0.4-1.8c0-0.4,0.2-4.7-0.4-4.5c1-0.2,1.5-1.3,2.4-1.9c0.6-0.4,3-2.4,2.7-3c-0.3-0.7-2.2-0.6-2.3-1
    		c-0.1-0.7,0.4-2.5-0.3-1.9c-0.5,0.4-3.9-0.5-3.9,0.1c-0.2,1.2-2.1,0.8-2.8,0.5c-1.1-0.4-2.2,0.1-3.2,0.4c-2.1,0.8-0.8-1.4,0-2
    		c-0.6,0.1-0.9-0.3-0.8-0.9c-0.6,0.6-1.5,0.6-2.2,0.8c-1,0.2-1.6,1-2.4,1.4c-0.8-2.1,1.5-2.3,2-3.9c-0.4,0.7-1.1,0.9-1.9,0.9
    		c0.2-0.8,0.9-1.2,1.6-1.5c-0.7,0.3-1.6,0.4-1.6-0.6c0-0.7,1.2-1,1.3-1.7c-0.6,0.6-1.7,0.5-2,1.4c-0.7-1,0.4-1.9,1.2-2.4
    		C1245.4,329,1244.5,329.2,1244.1,328.1"
          />
          <path
            id="path5984"
            class="countryIsland"
            d="M1170.2,459.4c-1.1-0.7-0.2-1.8,0.9-1.5C1170.8,458.4,1170.5,459,1170.2,459.4"
          />
          <path
            id="path5986"
            class="countryIsland"
            d="M1172.2,451.7c-0.2,0.1-0.4,0.3-0.7,0.4c-0.1-0.7-1.4-2.7-0.1-3
    		C1173,448.8,1172.7,450.9,1172.2,451.7"
          />
          <path
            id="path5988"
            class="countryIsland"
            d="M1175.9,456.2c-0.7-0.5-0.8-1.8,0.4-1.6C1177.5,454.9,1176.7,456.1,1175.9,456.2"
          />
          <path
            id="path5990"
            class="countryIsland"
            d="M1180,456.5c-0.6-1-0.9-2.2-1.5-3.2c2.2-0.5,3.8-1.2,5.9-2
    		C1183.7,452.9,1181.8,455.8,1180,456.5"
          />
          <path
            id="path5992"
            class="countryIsland"
            d="M1188,458.6c-1.8-0.3-2.1-3.1-0.2-3.6C1190.5,454.4,1189.7,457.8,1188,458.6"
          />
          <path
            id="path5994"
            class="countryIsland"
            d="M1197.4,455.9c-0.2-0.1-0.4-0.3-0.7-0.4c2.1-0.7,2.4-4,3.9-5.4c1-0.9,0.4,2.7,0.3,3.1
    		C1200.3,454.8,1198.6,454.7,1197.4,455.9"
          />
          <path
            id="path5996"
            class="countryIsland"
            d="M1201.9,449c-0.2-0.1-0.5-0.2-0.7-0.3c0.3-0.7,2.6-3.7,3.2-2.2
    		C1204.8,447.8,1202.5,448.4,1201.9,449"
          />
          <path
            id="Spain_Ibiza"
            class="countryIsland"
            d="M1315.8,362.6c-1.4-0.3-0.9-1.6,0.1-2.1c0.5-0.2,1.1-0.3,1.5,0.1
    		C1318.1,361.3,1316.1,362,1315.8,362.6"
          />
          <path
            id="Spain_Formentera"
            class="countryIsland"
            d="M1315.9,364.3c-0.2-0.8,0-1,0.7-0.4C1316.4,364,1316.1,364.2,1315.9,364.3"
          />
          <path
            id="Spain_Majorca"
            class="countryIsland"
            d="M1329.5,357.6c-1.3,1.5-1.2,0.8-2.8,0.5c-1.7-0.3-0.6-2.1-2.5-1.3
    		c-1.8,0.8-0.3-1.4,0.2-1.7c1.5-1.1,3.1-1.6,4.8-2.2c-0.7,0.2-1,1.1-0.3,1.6c0.5,0.4,1.3-0.1,1.8,0.2
    		C1331.3,355.4,1329.6,357,1329.5,357.6"
          />
          <path
            id="Spain_Menorca"
            class="countryIsland"
            d="M1333.3,352.6c1.3-1.2,3.4-0.3,3.6,1.4C1335.9,354.2,1333.8,353.5,1333.3,352.6"
          />
        </g>
        <g id="ga" class="countryGroup">
          <path
            id="path5980"
            class="countryIsland"
            d="M1369,704.7c0.1,0.2,0.2,0.4,0.3,0.6c0.3-0.5,0.2-0.9-0.2-1.3
    		C1369.1,704.2,1369.1,704.4,1369,704.7"
          />
          <path
            id="Gabon_mainland"
            class="country"
            d="M1368,706.2c-0.5-0.6-0.8-1.5-0.4-2.2c0,0.6,0.2,1.1,0.7,1.5c0.2-1.6,1.1-0.3,1.1,0.7
    		c0.2-2,2.2-3.1,2.4-5.1c0.2-1.3-0.7-4,0.5-5c0.1,0.7,0.5,1.1,0.9,1.7c0.2-1.1,1.8-0.5,2.3,0.4c-0.1-0.9,0.9-1.3,1.6-1.2
    		c-1.8,0-3.2-0.4-4.3-2c-0.4-0.6-1.8-1.5-0.3-1.8c0.6-0.1,1.5,0.2,1.5,0.9c0.5-1.2-0.4-2.8,0-4.1c0.2-0.7,1-0.6,1.6-0.3
    		c0.9,0.5,1.6-0.1,2.6-0.1l9,0.1v-9.9c0.4-2.3,1.3-1.4,3.1-1.3c1.5,0.1,3.3,0.3,4.8,0.2c1.5-0.1,3,0.4,4,0.4
    		c2.3-0.1,4.2-1.3,2.6,2.4c-1,2.3,0.6,4.5-0.2,6.6c1.7-0.3,3.3-1.2,5-1.6c3.2-0.7,2.8,1.2,3.8,2.8c0.9,1.5,1.7,3.7-1.4,4.4
    		c-1.7,0.4-1.8,3.2-1.6,4.6c0.1,0.6-1.3,2.4,0.2,2.4c0.7,0,1.4,0.6,1.4,1.1c-0.1,0.8,1.6,0.6,2.2,1.3c1.4,1.4-0.3,3.2,0,4.7
    		c0.2,1,1.3,3.8-0.4,4.7c1.1,0.7,0.3,2.6-0.7,3c-0.3,0.1-0.7,2.5-0.6,2.6c0.6,0.7-1.1,2.5-1.9,2.4c-0.9-0.1-1.2-3.1-1.6-3.8
    		c-0.3,0.9-1.4,2.8-2.4,3c-1.1,0.1-2.3-1.2-3.4-0.6c0.7-2.8-4.7-7.3-3.9-2.6c0.4,2.8-0.6,2.8-3.2,3c-0.8,0.1-0.6-0.6-1.4-0.6
    		c-1.1,0.1-1.3,0-2.3-0.3c0.4,1.4,0.7,3-0.3,4.2c1-0.4,1.8,0.2,1.7,1.3c0,0.9-0.2,1.7-0.1,2.1c0.4,0.4,1,0.4,1.3,0.9
    		c0.6,0.9-0.1,1.1,0,1.9c0.1,0.7,0.5,1.6-0.7,1.6c-1.2,0.1-1.3-1-2.2-1.5c-0.9-0.5-2.9,2.8-3.1,3.4c-1.7-1.3-3.1-4-4.2-5.8
    		c-1.7-2.6-4.2-4.6-6.4-6.8c1.2-0.8,1.6,2,2.7,0.6c-0.8,0.1-2-0.9-2.8-1.1c-1.4-0.3-2.4-3.6-3.3-4.8c0.9,0.1,1.2,1.1,2.1,1.3
    		c-0.8-1.2-0.9-1-2-1.6c-0.5-0.3-0.3-1.2-0.5-1.6c-0.3-1.1-1.1-1.9-1.6-2.9c0.7,0.6,2.1,1.8,1.9,2.9c0.7-0.2,1.5-0.4,2.2-0.4
    		c-0.4-0.3-0.8-0.7-1-1.2c-0.2,0.6-0.6,0.9-1.2,0.6c0.1-0.8,0.7-1.4,0.4-2.2c-1.1,3.3-3.2-3.1-3.8-3.5"
          />
        </g>
        <path
          id="hu"
          class="countryGroup country"
          d="M1418.3,294c0.7-0.7,1.6-1.3,2.6-1.1c-0.3-0.9-0.6-2-0.3-3c0.2-0.8,1.2-1,1.5-1.8
    	c0.2-0.6-2.1-0.9-0.8-1.4c1-0.4,2,1.3,2.7,0.1c0.5-0.9,1-2,1.4-2.4c0.4-0.4,0.8,0,2,0.9c2.9,2.2,5.8,1.1,9.2,0.7
    	c-1.9-1.5,0.8-1.5,1.9-1.6c0.6,0,2-0.5,2.1-1.2c0.2-1.2,1.2-0.2,2.1,0.1c1.2,0.5,3,0,3.8-0.8c0.6-0.6,0.4-2.2,1.2-2.6
    	c1.5-0.6,3.3,0.3,4.8,0.4c0.7-1,1.6-0.3,2.2,0.3c0.9,0.9,1.8,1.3,2.9,0.8c2.1-0.9,3.1,1.6,5,2.3c2.2,0.8,0.7,1.9,0.1,2.4
    	c-0.9,0.8-2.4,0.1-3,1c-0.5,0.7-1.8,1.7-1.8,2.5c0,0.8-1.6,3.2-2.6,5.1c-0.5,0.8-1.5,1.6-1.7,2.6c-0.2,0.8,0,1.5-1.6,1.9
    	c-0.8,0.2-1.5-0.2-2.1,0.6c-0.6,0.9-1.3,0.2-2.1,0.4c-1.1,0.4-2.4,0-3.5-0.1c-0.8-0.1-2-0.5-2.7,0.1c-0.8,0.7-1,1.3-2.1,1.4
    	c-1,0-2.1,0.2-3.1,0.6c-0.9,0.4-1.1,1.3-2.2,1.4c-1.8,0.2-4.1-0.2-5.6-1.1c-0.8-0.5-1.6-0.4-2.3-1.3c-0.8-1.1-2-1.8-3.2-2.5
    	c-1.1-0.7-1.9-1.3-2.5-2.4C1420.2,295.1,1419.5,293.7,1418.3,294"
        />
        <path
          id="mw"
          class="countryGroup country"
          d="M1548.9,817.1c0.2-0.5,1.6-2.7,2.1-4.1c0.5-1.4,0.4-1.8,0.1-3.7c-0.3-1.7,3.6-2.8,4.4-3.7
    	c-2.8-0.9-1.6-5.5-1.7-7.5c0-1.7,1.1-2.4,0.2-4.1c-1.1-2,0.5-1.6,1.6-2.3c1.3-0.9,1.6-2.9-0.5-5.3c-1.7-2,0.4-2.6-1.2-3.6
    	c-1.3-0.9-1.8-0.8-2.2-1.2c-0.2-0.2,0.3-1.3-0.2-1.8c0.2,0.2,1.9,0.8,2.7,0.9c1,0.1,0.7,0.9,1.6,1c0.8,0.1,2.6,0,3.1,0.9
    	c0,1.3,0.1,2.9,1.7,4.8c1.4,1.7,0.1,2.1,0.3,3.5c0.2,1.2,0.6,6.7,0.6,7.4c0.1,2.6-3.2,5.1-2.3,6.6c3.4,5.8,2.3,5.8,2.1,6.9
    	c-0.8,4.9,2.5,3.8,2,6c-1.2,5.5,2,4.6,1.9,2l2.7,3.5c0.6,0.3,0.3-4.5-1.6-5.9c-1.7,0.2-0.9-1.3-1.2-1.7c1.3,0.6,1.4,0.7,3,3
    	c1,1.5,1.9,3.2,3.3,4.7c1.5,1.8,1.6,3.5,1,5.7c-0.5,2,0.2,3.9-0.1,6c-0.1,0.9-0.4,1.9-1.2,2.5c-0.8,0.6-2.1,0.4-2.8,1.1
    	c-0.8,0.7-0.5,2.1-1.7,3.1c0.3,0.3,1.1,2,1.2,3c0,0.4,0,1.6-0.4,2s-1.8,0.3-1.6-0.4c0.4-1.6,1.2-1.1-0.5-2.7
    	c-1.1-1.1-4.2-4.1-4.3-5.3c-0.3-2.1-0.8-1.6-0.4-3.2c0.2-0.7,0.7-2.3,1.2-3.1c0.9-1.1,0.7-1.4,0.8-2.9c0.2-1.7,0.2-3.9-1-5.5
    	c-0.4-0.7-1.8,0.2-3,0.4c-1,0.1-2.4-0.2-2.5,0.8c-0.6-0.6-4.4-5.1-4.6-5C1550.7,820.1,1548.7,817.9,1548.9,817.1L1548.9,817.1z"
        />
        <path
          id="tj"
          class="countryGroup country"
          d="M1782.4,358.8c0.6-1.1,0-3,2.1-2.9c1.6,0.1,3,0.7,4.7,0.6c1.2,0,1.5-1,1.3-2c-0.1-0.4-0.1-0.8,0.3-0.9
    	c1.1-0.3-0.1-1.3,1.1-0.3c-0.5-0.3-0.8-0.8-0.9-1.4c0.3,0,0.7,0,1,0.1c-0.5-1.1-1.8,0.1-2.6-0.8c1.3-1.4,3.4,0.2,4.9-0.4
    	c-0.7-0.6,0-1-0.5-1.8c-0.4-0.7-1.2-2.5-0.1-3c1.2-0.5,2.1,1,3,0.8c1.4-0.4,3.2-1.8,3.8-3c0.8,0.6,1.3,1.5,2.1,2.2
    	c1,0.8,0.6,1.3-0.5,2.1c-1.4,1-1,0.8,0.2,1.6c0.5,0.3,0.8,1.1,1.4,1.3c0.7,0.2,1.5-0.2,2.2-0.2c-0.6,0.7-2.1,2.5-3,2.5
    	c1-0.9-2.7-2.3-3.6-2.4c-2.1-0.2-3.3,0.8-3.4,2.6c-1.5-1.6-0.8,2.3-0.3,3c0.6,0.9,2-0.1,2.7-0.1c1.3-0.1,2.3,0.5,3.7-0.3
    	c0,0.3,0,0.5,0.1,0.8c0.7-0.3,1.5-0.6,2.3-0.4c0.8,0.2,1,1.1,1.7,1.5c1.1,0.5,3.2-1.1,4.1-1.6c1-0.6,0.8,0.5,1.2,0.8
    	c0.4,0.1,0.9,0.2,1.3,0.2c0.2,0.3,0.3,0.6,0.4,0.9c1.4,1,2.5-0.3,4,1.4c0.3-2.6,4.5-1.6,6.1-1.6c0.8,0,2.6-1.3,3.2-0.7
    	c0.3,0.6,0.1,1.5,0.5,2.1c0.6,0.9,1.6,1.5,1.9,2.2c-1.6,0,0,2.3,0.4,2.8c0.6,0.7,1.1,0.6,2,0.1c1.6-0.8,1.9-0.4,2.6-0.3
    	c2,0.4,3.7,0.7,3.4,3.3c0,0.4,0.1,1.3,0.9,1.9c0.8,0.7,1,1.8,1.2,2.2c0.3,0.7-0.2,1.3,0.2,1.7c0.5,0.6,1.1,0.6,1.5,1.4
    	c0.3,0.7,0,1.5-0.7,1.8c-0.1,0.1-1-0.8-1.4-1c-0.6-0.2-2.7-0.9-3.3-0.5c-1.2,0.9-3.2,1.8-4.3,1.3c1.9-2.1-2.9-2.2-4-1.3
    	c-1,0.8-1.8,1.4-2.4,2.7c-0.6,1.4-2.6,0.4-3.5,1.4c-5.9,5.9-6.4-3.2-6.2-7.2c0.1-2.7-1.3-0.5-2.4-1.5c-0.4-0.4-0.1-1.9-0.2-2.5
    	c-0.2-0.9-2-2-2.7-1.9c-3,0.1-3.2,3-4.4,4.3c-0.7,0.7,0.5,1.4,0.8,2c0.7,1.4-0.7,1.8-1.9,1.4c-1.2-0.4-2.5-0.4-3.2,0.2
    	c-1.1,0.9-0.1,1.5-0.1,2.3c0.1,3-2.5,0.8-2.7,0.1c-0.1-0.3-0.8-0.8-0.9-0.6c-0.7,1.4-1.1,0.4-1.3,0.5c-0.4,0.2-0.9,0.6-1.2,0.7
    	c-0.6,0.3-1.7,0.4-1.7,1.5c-0.9-0.2-1.2,0.8-2.1,0.2c-1.5-1-2.4-3.8-1.4-5.5c1.3-2.2,3.4-4.2,0.7-6.1c-2.1-1.5-0.2-5.6-3-5.7
    	c-0.8,0-1.9,0.2-2.3-0.7C1784.5,359.4,1783,360,1782.4,358.8"
        />
        <g id="kh" class="countryGroup">
          <path
            id="Cambodia_mainland"
            class="country"
            d="M2077.2,583.5c0-0.8,0-1.5-0.3-2.2c2.4,0.1,3.4-3.3,4.1-5.1c0.8-1.9,4.8-3.3,6.4-2.2
    		c0.7,0.5,1.5,0.2,2.6,0c1.2-0.3,0.9,0.5,4.1-0.2c3.1-0.6,1.8,2.7,4,0.7c0.1,3,3.6,0.8,4.8,2.8c0.6,1,2.6,1,2.4-0.5
    		c-0.1-0.8-1.9-1.8-0.8-2.6c0.5-0.3,0.9,0.3,1.1-0.4c0.1-0.6,0.6-0.4,1.1-0.4c0.6,0,0.5-0.9,1-0.9c0.7-0.1,1.3,0.9,1.8,1.2
    		c2.1,1.5,5-0.7,6-2.4c0,0.6,0,1.2-0.2,1.7c-0.2,0.6-0.8,1.5-0.8,2.1c0.1,0.7-0.1,1.2,0.3,1.7c0.7,0.8,0.7,1.6,0.9,2.3
    		c0.4,1.9,1.7,2.4,1.2,4.6c-0.2,0.9-0.4,1.7,0,2.8c0.3,0.8,0.5,1.8,0.7,2.6c0.8,3.5-2.1,2.8-2.6,3.1c-0.9,0.6-1.3,1.1-2,1.7
    		c-1.6,1.3-2.4,1-3.8,1.1c0.6,0.8,0,1.8,0.5,2.6c-0.8-0.2-3-1.6-3.6-0.6c-0.4,0.7-1.3,0.8-1.1,1.6c0.2,0.9,0.5,1.9,1,2.7
    		c0.5,0.9,2.2,1,2.4,2.3c-0.9,0.1-0.1,0.8-0.1,1.6c-0.7-0.2-1.5-0.2-2.1-0.6c-0.5-0.3-0.8-1.4-1.5-1.3c-1.5,0.3-3.4,1.7-4.8,0.4
    		c-0.8,0.8,0.2,1.9-0.8,2.8c-0.9,1-1.8,0.5-2.9,0.9c-0.8,0.3-0.9,0.9-1.9,0.4c-1-0.4-3-0.7-3.5-1.7c0.3,1.1-1.2,1.8-2,1.1
    		c-1-0.9-0.1-1.1,0.5-1.8c0.7-0.7-0.1-2.7-0.8-3.2c-1.1-0.9-1.3,1.5-1.5,2.1c-0.7-0.6-1.1,0.3-1.8,0c-1-0.5-0.4-2.1-0.6-2.9
    		c-0.2-0.7,0.5-1.8-0.3-2.3c-0.5-0.4-1.7-1.7-0.3-1.9c-0.6-0.3-1,0-1.1,0.6c0.2-1.2-0.9-2.3-1.3-3.4c-0.5-1.2,0.1-2.9-0.6-3.9
    		C2079.2,588.3,2078.1,586.1,2077.2,583.5"
          />
          <path
            id="path6044"
            class="countryIsland"
            d="M2091.9,608.2c-0.3,0.5-0.8,0.7-1.3,0.7c1,0.7,1.1,2,1.8,3
    		C2092.1,610.7,2093.1,609.2,2091.9,608.2"
          />
          <path
            id="path6024"
            class="countryIsland"
            d="M2083.8,599.9c-0.1,0-0.3,0-0.4,0c0.2,0.3,0.4,0.6,0.6,0.8
    		C2083.9,600.4,2083.8,600.2,2083.8,599.9"
          />
          <path
            id="path6026"
            class="countryIsland"
            d="M2086.1,606.2c0.4-0.6,0.1-0.8-0.6-0.7C2085.6,605.8,2085.9,606,2086.1,606.2"
          />
        </g>
        <g id="kr" class="countryGroup">
          <path
            id="path6028"
            class="countryIsland"
            d="M2205.8,371.5c-0.8,0.5,0.4,2.2,1.2,1.4C2206.8,372.3,2206.4,371.8,2205.8,371.5"
          />
          <path
            id="path6030"
            class="countryIsland"
            d="M2210.8,383.7c-0.2-0.5-0.6-0.8-1-1.1C2209.9,383.1,2210.2,383.5,2210.8,383.7"
          />
          <path
            id="path6032"
            class="countryIsland"
            d="M2215.5,396.6c-0.6,0.6-0.5,0.8,0.4,0.8C2215.8,397.1,2215.6,396.8,2215.5,396.6"
          />
          <path
            id="path6034"
            class="countryIsland"
            d="M2217.2,400.2c-0.4-0.3-0.8-0.5-1.3-0.6C2215.4,401.6,2217.6,401.1,2217.2,400.2"
          />
          <path
            id="Korea_Jeju"
            class="countryIsland"
            d="M2223.7,408.4c-1-0.1-5.5,1.1-3.6,2.6C2222.1,412.5,2226.4,409,2223.7,408.4"
          />
          <path
            id="path6038"
            class="countryIsland"
            d="M2219.6,401.2c0.2,0.4,0.6,0.6,1,0.5C2220.4,401.2,2220.1,401.1,2219.6,401.2"
          />
          <path
            id="path6040"
            class="countryIsland"
            d="M2226.5,396.5c-0.1,1.4,0.8,1.6,2,1.6C2228.4,397.3,2227.1,396.7,2226.5,396.5"
          />
          <path
            id="path6042"
            class="countryIsland"
            d="M2231.1,396.9c1.2,1.9,2.7-0.3,1-1C2232.1,396.5,2231.7,396.9,2231.1,396.9"
          />
          <path
            id="path6046"
            class="country"
            d="M2206.8,372.2c0.6-0.8,1.2,0.7,1.9,0.8c-2.4-1-1.3-4.6,0.5-5.6c1.5-0.9,3.5,0.1,5.1-0.2
    		c0.5-0.1,1.1,0,1.6-0.3c1.1-0.8,0-1.4,0.4-2.3c2.6,2,4.6,4.9,7.1,7.1c2.3,2,4.7,4,6.5,6.4c1.4,1.8,1.5,3.7,2.2,5.8
    		c0.2,0.6,1.9,4.6,2.4,2.7c0.9,1,1.5,3.9,1.1,5.2c-0.2-0.2-0.5-0.4-0.7-0.6c0.5,1.6,0.9,3.4-1,4.3c-0.1-0.9-2.2-0.6-3.3-1.2
    		c0.7,1.3-0.6,0.4-0.9,1.5c1-0.6,1.1,0.8,0.9,1.5c-0.7-1.7-2.9-0.1-3.4-2.1c-0.1,1.6-2.1,0.5-2.6,1.7c0.3,0.3,0.8,0.5,1.2,0.7
    		c0.7,0.5-0.7,0.4-0.2,1.4c-0.8-0.6-0.9-1.6-1.7-2.2c-0.2,0.4-0.4,0.7-0.6,1c0.5,0.5,1.3,0.9,1.6,1.6c-0.4,0-0.7,0-1.1,0
    		c0.2,0.2,0.5,0.3,0.7,0.5c-0.6,1.1-1.5-0.2-2-0.6c1,0.2,1.1-1.6-0.1-1.4c-1.1,0.1-1.3,2.2-1.4,3c-0.6-0.2-1.1-0.6-1.4-1.1
    		c0,0.7-0.3,1.4-0.6,2c-0.8-0.2-1.3-1.1-0.9-1.9c-0.7,0-2.8-0.6-2.1-1.7c0.2,0.5,0.6,0.9,1.1,0.9c-0.5-0.8-0.4-1,0.4-0.6
    		c-0.4-0.3-0.9-0.6-1.4-0.7c0.6,0.1,1-0.1,1.4-0.5c-0.2-0.3-0.4-0.5-0.7-0.6c-0.2,1.9-2.4-1.7-2.9-2.1c0.5,0.2,0.9,0.3,1.4,0.2
    		c-1.2-0.7-1.4-3.6,0-4.1c-0.5,0-1-0.1-1.5-0.2c0.1-0.5,0.4-0.8,0.9-1c0.4-0.3,0.4-1.1,0.6-1.6c-0.5,0.2-1,0.2-1.5,0
    		c0.2-0.6,0.7-1,1.2-1.4c-1.5,1.6-4.3-2.1-3.4-3.5c-1,0.5-0.9-0.3-1-1c-0.1-0.7-1.6-1.8-1.6-0.4c-0.5-0.8-1.6-0.8-1.9-1.8
    		c0.7,0,1.1-0.2,0.5-1c0.5,1.1,0.6,1.1,0.4,0c0.6-0.1,1,0.2,1.1,0.8c0.4-0.5,0.4-1-0.1-1.4c1.2,0.9,2.6,0.7,3.2,2.3
    		c-0.3-0.6,0.1-1.1,0.6-1.4c-0.7,0.1-1.7-0.3-1.4-1.2c-1,0.7-2.7-1.8-0.8-1.7c-0.9-0.2-1.8-1-2.4-1.7c-0.2-0.4-0.3-0.7-0.5-1.1
    		C2207.5,372.9,2207,372.9,2206.8,372.2"
          />
        </g>
        <g id="hn" class="countryGroup">
          <path
            id="Honduras_mainland"
            class="country"
            d="M624.6,573.7c0.5-1.3,2.3-1.7,1.5-3.3c-0.5-0.9,0.8-2.4,1.6-2.7c1.7-0.8,3-2.2,4.4-3.5
    		c0.5-0.5,1.3-0.9,1.9-1.3c0.7-0.4,1.1,0.2,1.7-0.5c0.8-1,2.7-1.8,3.6-0.8c0.7,0.7,4.2,0.8,5.2,0.7c1.5-0.1,3.7,0.2,4.9-0.6
    		c0.5-0.3,3.8-1,2-1.6c1.6,0,3.1,1,4.7,1c1.7,0,3.6-1.2,5.1,0.1c1.3,1.1,2,0,3.3,0.7c1.5,0.8,2.3,2.5,3.4,3.7
    		c-0.5-0.3-1-1.1-1.6-1.1c-0.6,0-1.2,0.1-1.7-0.4c0.5,0.3,0.5,0.8,0.6,1.3c0.1,0.3,1,1.1,1.3,1.4c0.2-0.6-0.1-1-0.6-1.1
    		c0.7-0.8,1.8,1.4,3.2,0.7c0.1,0.5,0.2,0.7-0.1,1.1c1.5-0.4,0.1-1.3-0.6-1.6c2.3,0.3,1.9,2.3,3.7,3c-1.3,0.2-2.5,0-3.7,0.8
    		c-1,0.7-3.1,1.4-4.3,1.4c-1,0-1.9,1-2.7,0.8c-0.6-0.2-1.2-0.4-1.7-0.7c-0.3-0.1-0.3-0.6-0.5-0.7c-1.4-0.3-1.5,1.5-2,2.2
    		c-0.3,0.5-0.9,0.7-1.2,1.2c-0.2,0.3,0.2,0.8-0.1,1c-1,0.7-4.1,2.5-4.6,4.1c-2.2-1.4-2.6-2.6-4-0.2c-1,1.6-3.9,0.1-3.8,1.6
    		c0.2,1.4,0.8,3.1-0.9,3.4c-0.9,0.1-0.7,1.1-1,1.7c-0.4,1.1-2,0.7-2.8,0.8c0.7-1.1-2.6-2.5-0.3-3.4c-0.7,0.2-1.7,0.2-1.8-0.7
    		c-0.2,0.8-0.9,1-1.5,0.5c0.9-0.3,0.7-1.2,0.7-1.9c0.1-1.1,1.1-2.4-0.9-2.4c-0.7,0.5-1,0.4-1.1-0.3c-0.2-0.3-0.5-0.4-0.8-0.3
    		c-0.9,0.1-1.7,0.8-2.7,0.9C631.3,577,625.4,574.4,624.6,573.7"
          />
          <path
            id="path6082"
            class="countryIsland"
            d="M647.1,557.8c0.9-0.6,1.8-1,2.8-1.4C648.9,556.4,647.6,556.8,647.1,557.8"
          />
        </g>
        <path
          id="is"
          class="countryGroup countryIsland"
          d="M1181.1,144.1c1.1,0,2.6-0.4,3.6-0.6c0.6-0.1,0.4-1,1.1-0.6c1,0.6,1.8-1.2,2,0.4c0.5-0.2,1-0.5,1.5-0.8
    	c0.1,1.1,1.5,0.8,2.2,0.1c-0.5,0.4-1,0.7-1.5,0.9c0.7,0.6,0.9-0.1,1.5-0.3c0.4-0.2,1.5,0.7,2.3,0.5c-1.9,0.5-4,0.9-5.5,2.2
    	c0.8-0.1,1.5,0.2,2.2,0.3c0.8,0.1,1.5-0.4,2.2-0.1c-1.6,2.5-6.5-0.9-8.2,1.6c-0.2-1.3-0.7-0.3-1.5-0.1c-0.9,0.2-1.8,0.1-2.8,0.1
    	c-1.9,0-0.8,1.8,0.6,1.3c1.4-0.5,2.3-0.6,3.9-0.5c0.8,0.1,1.5,0.1,2.3,0.1c0.8,0,1.7,1,2.4,0.5c-0.6,0.2-1.1,0.6-1.3,1.2
    	c0.6,0.1,0.9,0.4,0.9,1c1-0.9,2.7-1.5,4-1.5c-0.3,0.6-1.2,0.6-1.8,0.8c-0.7,0.2-2.4,1.7-0.6,1.3c-0.6,0-1.1,0.2-1.5,0.6
    	c1.3,0.3,2.4-1.1,3.7-0.5c-0.7,0.1-3.9,0.8-1.9,1.6c-0.6,0.1-1.2-0.2-1.7,0.1c0.1,1.2-0.8,0.9-1.8,1.1c-0.4,0.3-0.9,0.3-1.3,0.1
    	c-0.1-0.2-0.3-0.4-0.6-0.5c-0.9,0.3-0.8,1.3-0.9,2c1.7-0.1,3.7-0.1,5.3-0.4c0.8-0.2,1.6,0.2,2.4,0.1c0.9-0.1,1.2-0.9,2.2-0.7
    	c-0.1,0.3-0.3,0.5-0.4,0.8c0.4,0.1,1.3,0.6,1.7,0.2c0.8-0.6,1.2,0.5-0.1,0.4c0.8,0.6,1.7-0.1,2.2-0.6c0.2,0.3,0.3,0.6,0.4,0.9
    	c-0.3,0.1-0.7,0.1-1,0.2c0.8,1.6,2.5,1.1,3.9,1.3c1.7,0.3,3.2,0.9,4.9,1c1.3,0.1,2.7,0,3.9-0.3c0.6-0.1,1.2-0.3,1.7-0.6
    	c1.3-0.8-0.2-0.8,1.1-1.1c-1.8-0.4,1.8-0.7,2.3-0.7c1-0.1,2.8-0.1,3.1-1.2c0.1,1.8,3.2-0.1,4.1-0.6c1.8-0.9,3.7-1.6,5.4-2.6
    	c1-0.6,2.1,0.8,3.1,0.3c0.6-0.2,4.2-2.2,2.3-2.3c0.6-0.7,2.1-0.3,1.2-1.4c0.9,1.5,2.2-0.3,3.4-0.1c-1.4-0.7,1.3-0.9-0.7-0.9
    	c0.5,0,1,0,1.5-0.1c-0.7-0.3-1.4-0.6-2.2-0.7c1.3-0.9,2.4,1.2,3.6-0.3c0.8-1-1.8-0.9-2.2-0.9c0.8-0.1,1.5-0.1,2.2-0.6
    	c-0.8-0.2-1.6-0.2-2.4-0.1c0.8-0.2,2.5-0.8,2.2-1.9c-0.9,0.3-1.8-0.6-2.6-0.7c-1.1-0.2-1.8,0.3-2.7,0.9c0.6-0.6,2-1.1,1.7-2.2
    	c-1,0.1-2,0.4-3,0.4c0.2-0.8,1.3-1,1.6-1.7c0.3-0.7-2.3-0.8-2.6-1.4c0.9,0,0.8-0.2,1-0.9c0.2-0.7,1.8-0.7,2.4-0.9
    	c-1.4,0.3-4.3,0-4.8,1.8c-0.5-0.5-1.8-0.9-1.6-1.7c0.4-1.2-2.3-1.3-2.8-1c-0.6,0.3-1.7-0.4-1.5,0.7c0.3,1.4-0.9,1.3-1.1,2.4
    	c-0.2-0.3-2.6-1-2.9-0.9c-1.3,0.1-1.9,1.6-2.9,2.3c-0.3-0.9-3.1-3-4-1.8c-0.3,0.4,0,1.2,0.3,1.5c0.7,0.7,0.5,1.5,0.2,2.3
    	c-1-1.3-1.7-4.6-4-4.1c-0.7,0.1-0.7,0.8-1.2,0.9c-0.6,0.1-1.1-0.3-1.7,0c-0.8,0.4-0.2,1.7-0.8,2.4c-1.1,1-2.6-2.2-3.2-2.7
    	c-1.2-0.8-2.1,0.8-1.7,1.7c0.3,0.8-0.6,3.2-1.3,2.2c-0.2,1.8-0.9-0.4-1.5-0.5c-1.7-0.3-1.5,3.3-2.4,1.5c0,0.8-0.7,1.4-0.5,2.2
    	c-0.4-1,0-2.9-1.7-2.2c2.3-0.4-0.9-1.6-0.9-2.4c1.8,2.2,3.6-1.9,1.5-1.4c1.4-0.5,0.4-0.6-0.2-0.8c-0.8-0.3-0.6-0.7-1.2-1.2
    	c-0.7-0.5-1.6-0.1-2-0.9c-0.2-0.4-1.1-0.7-1.5-0.8c-0.3-0.1-3.6,0.5-3,0.9c0.9,0.6,2.6,0.7,3.6,0.6c-0.9,0.3-2.3-0.4-2.9,0.7
    	c0.6,0.3,2.1,0.5,2.2,1.3c0.1,0.6-1.2,0.8-1.5,1.3c1.3-1.7-0.2-1.5-1.4-0.9c0.5-0.9,0.3-1-0.6-0.4c0.2-0.2,0.3-0.5,0.4-0.7
    	c-0.6-0.3-1.2-0.7-1.9-0.8c-0.6-0.1-2,0.3-0.4,0.7c-1,0.2-1,0.5,0,0.9c-0.7-0.4-1.4-0.5-2.2-0.6c-0.4,1.3,1.4,1.3,2.2,1.7
    	c-0.9-0.1-2.3-0.8-2.9,0.2c0.9,0.5,2,0.4,2.9,0.6c-0.5,0-1,0.1-1.5,0.2c0.4,0.2,0.8,0.3,1.2,0.3c-1.4,1-2.9-1.2-4.5-0.7
    	c0.2,0.5,0.6,0.9,1.2,1.1c-1,0.2-1,0.4,0,0.7c-1.2-0.4-2.7-1.2-3.5,0.3C1179,143.4,1180.1,143.4,1181.1,144.1"
        />
        <path
          id="ni"
          class="countryGroup country"
          d="M636.8,585.6c0.9,0.4,0.9,1.3,2.1,1.2c0.2-0.3-0.3-0.5,0.1-0.6c0.5-0.1,1.8-0.1,2.2-0.3
    	c0.9-0.3,0.3-1.6,1-2.1c0.5-0.4,1.3-0.1,1.4-0.8c0.2-0.9-0.6-2.7-0.1-3.5c0.5-0.7,1.7-0.1,2.4,0c0.8,0.1,1.2-0.6,1.6-1.2
    	c1.8-2.1,2-0.6,3.8,0.6c0.3-1.7,2.7-2.9,4.1-4.1c1-0.1,0-0.8,1.1-1.7c0.9-0.7,0.8-1.9,1.8-2.6c1-0.8,1.4,0.5,2.3,1
    	c1.2,0.7,1.8-0.1,3-0.5c1.3-0.4,3.5-0.6,4.7-1.2c0.5-0.3,1-0.8,1.6-0.9c0.8-0.2,1.5,0.1,2.3,0c-0.7,0.3-1,1.7-1.9,0.9
    	c-0.8,1.4,1.3,3.7,0.6,5.5c-0.7,1.7-2.1,2.7-2.6,4.7c-0.7,2.4-0.4,4.7-0.8,7.1c-0.2,1.2,0.3,4.2-1.1,4.4c0-1.4,0.8-2.4,0.6-3.8
    	c-0.9,0.2,0,1.9-1.7,2.4c1.2,1.5,0.3,3,0.4,4.7c-0.3-0.5-0.3-0.9-0.2-1.4c-0.2,1-0.8,1.9-0.8,2.9c1.9-0.7,0.4,1.8,0,2.4
    	c-0.9,1.4-1.5,3.1-0.4,4.7c0.5,0.7,1.4,1.4-0.1,2c-0.9,0.4-2.4,0.3-3.2-0.3c-0.2-0.2-0.4-0.4-0.6-0.7c-0.6-1-0.4-0.6-1.3-0.8
    	c-0.7-0.1-1.1-0.9-1.8-0.8c-0.6,0.1-1.1,0.3-1.6,0.6c-0.3,0.2-1.2-0.1-1.6-0.3c-2.2-1-3.2-1.4-3.5-1.5c-0.1,0-0.7-0.1,0.2-0.2
    	c0.6,0.1,7.1,3.5,5.4-1c-0.3-0.4-1.9-3.2-2.2-3.5c-0.3-0.3-2.3-1.7-2.5-2.2c-0.2-0.6-1.8-1-2.2-0.9c-1.3,0.2-0.5,4-0.7,3.6
    	c0.3,0.3,1.1,3.5,2.3,4c-0.2,0,0,0.3-0.3,0.2c-0.9-0.3-0.6,2.6-1.8,0.1c-0.7-1.4-2.1-2.4-3.2-3.5c-2.2-2.1-3.1-5-5.4-7
    	C638.6,590,635.3,587.4,636.8,585.6"
        />
        <g id="cl" class="countryGroup">
          <path
            id="path6426"
            class="countryIsland"
            d="M871.4,1174.7c-0.2-0.2-0.4-0.4-0.7-0.4C870.9,1174.4,871.1,1174.5,871.4,1174.7"
          />
          <path
            id="path6432"
            class="countryIsland"
            d="M885.3,1178.3c0-0.2,0-0.4,0.1-0.6c-0.5-0.1-1-0.1-1.5,0.2
    		C884.4,1177.8,884.9,1178,885.3,1178.3"
          />
          <path
            id="path6440"
            class="countryIsland"
            d="M887.7,1178.2c0.1-0.5-0.1-0.6-0.7-0.3C887.2,1178,887.4,1178.1,887.7,1178.2"
          />
          <path
            id="path6442"
            class="countryIsland"
            d="M888.7,1178.3c-0.2-0.3-0.4-0.5-0.7-0.7C888.2,1177.9,888.4,1178.1,888.7,1178.3"
          />
          <path
            id="path6428"
            class="countryIsland"
            d="M885.6,1172c-1.1-1.5-7.2-2.8-8.5-0.9c1.1-0.6,1.3,0.9,1.9,1.5c0.5,0.5,3.8,0.4,2,0.6
    		c0.5,0.1,1,0.1,1.5,0.1c0.4-0.1-0.1-0.5,0.1-0.6c0.6-0.5,1.2,0.8,1.5,1C885.1,1174.1,885.9,1172.8,885.6,1172"
          />
          <path
            id="path6430"
            class="countryIsland"
            d="M883.5,1176c0.2-0.2,0.3-0.4,0.5-0.7C883.2,1175,883,1175.2,883.5,1176"
          />
          <path
            id="path6434"
            class="countryIsland"
            d="M886.6,1177.3c-0.2-0.5-0.5-0.9-1-1.2c-0.2,0.2-0.4,0.5-0.5,0.8
    		C885.6,1177.1,886.1,1177.2,886.6,1177.3"
          />
          <path
            id="path6436"
            class="countryIsland"
            d="M885.4,1170.8c0.1,0.6,1,0.6,1.5,0.6C886.4,1171.2,885.9,1171,885.4,1170.8"
          />
          <path
            id="path6438"
            class="countryIsland"
            d="M887.3,1172.8c-0.4,0-0.7,0.2-0.9,0.5c0.5,0.3,0.9,0.3,1.4,0.2
    		C887.6,1173.3,887.5,1173,887.3,1172.8"
          />
          <path
            id="path6444"
            class="countryIsland"
            d="M889.4,1173.2c0.6-0.6,0.1-1-0.6-0.7C889,1172.8,889.2,1173,889.4,1173.2"
          />
          <path
            id="path6364"
            class="countryIsland"
            d="M832.8,1156.5c-0.5,0-0.8-0.1-0.8,0.6C832.3,1156.9,832.5,1156.7,832.8,1156.5"
          />
          <path
            id="path6372"
            class="countryIsland"
            d="M835.6,1159.3c0.2-0.2,0.3-0.5,0.5-0.7C835.2,1158.4,835,1158.6,835.6,1159.3"
          />
          <path
            id="path6374"
            class="countryIsland"
            d="M837.5,1157.7c-0.4,0-0.7,0.1-1.1,0.2c0.2,0.2,0.4,0.5,0.6,0.7c-0.5-0.1-1-0.3-1.5-0.3
    		C836.1,1158.4,838.4,1159.2,837.5,1157.7"
          />
          <path
            id="path6376"
            class="countryIsland"
            d="M844.5,1163.8c0.8-0.5,1.7-1,2.6-1.2c-0.8-0.3-0.9-1.6,0.1-1.1c-0.3-1.5-1.8-0.8-2.4-1.7
    		c-0.4-0.7-1.7-1.3-1.6,0c-0.3-0.6-1.4-1.7-1.5-0.3c-0.4-0.6-1.7-2.3-2.6-1.7c0.1,0.2,0.3,0.5,0.4,0.7c-0.5-0.1-1-0.1-1.5,0
    		c1,0.5,1.8,1.1,3,1.4c-1.1,0.1-2.9-1.5-3.7-0.1c0.6-0.2,1,0.1,0.9,0.7c1.9-0.8,1.3,0.4,2.1,1.5c0.4,0.6,1.1,0.8,1.5,0.1
    		c0.5-0.9,1.6-1,2.6-0.6c-0.2,0.2-0.5,0.5-0.7,0.7c0.3,0.2,0.5,0.4,0.8,0.6c-0.5,0.2-1,0.4-1.5,0.5
    		C843.5,1163.4,844,1163.6,844.5,1163.8"
          />
          <path
            id="path6378"
            class="countryIsland"
            d="M841.6,1163.8c0-0.9-1-0.9-1.5-0.5C840.7,1163.2,841.2,1163.3,841.6,1163.8"
          />
          <path
            id="path6380"
            class="countryIsland"
            d="M847.7,1163.3c-0.1,0-0.3,0.1-0.4,0.1c0.4,0.6,0.3,0.9-0.4,1
    		C847.8,1165.4,849.2,1163.8,847.7,1163.3"
          />
          <path
            id="path6382"
            class="countryIsland"
            d="M848.1,1166.2c0.2-0.4,0.2-0.7,0.8-0.6c-0.5-0.4-1-0.4-1.5,0
    		C847.6,1165.9,847.9,1166.1,848.1,1166.2"
          />
          <path
            id="path6384"
            class="countryIsland"
            d="M848.3,1161.5c-0.3,0-0.5,0.1-0.7,0.3C847.9,1161.7,848.1,1161.6,848.3,1161.5"
          />
          <path
            id="path6386"
            class="countryIsland"
            d="M851.1,1162.2c-0.6-0.2-1.1,0.4-1.4-0.3c-0.3-0.6-1.6,0.6-2,0.7c0.4,1.2,3.5,1.3,3.1,2.8
    		c2,0,0-1.4-0.4-2c0.4,0.5,0.9,0.6,1.4,0.4C851.4,1163.3,851.2,1162.8,851.1,1162.2"
          />
          <path
            id="path6388"
            class="countryIsland"
            d="M849.6,1165.2c-0.2-0.2-0.5-0.4-0.8-0.7C849.1,1164.7,849.4,1164.9,849.6,1165.2"
          />
          <path
            id="path6390"
            class="countryIsland"
            d="M849.8,1166.6c-0.2-0.1-0.5-0.2-0.7-0.2C849.3,1166.5,849.5,1166.5,849.8,1166.6"
          />
          <path
            id="path6392"
            class="countryIsland"
            d="M850.2,1166.3c0.3-0.7,0-0.9-0.7-0.5C849.7,1166,850,1166.2,850.2,1166.3"
          />
          <path
            id="path6394"
            class="countryIsland"
            d="M854,1162.8c-0.7-0.3-1.8-0.5-2.6-0.3c0.2,0.1,2.2,4,2.2,1.2c0,1.7,1.9,0.1,1.6,1.7
    		c0.6-0.1,1.1-0.3,1.6-0.6c-0.4-0.5-2.1-2.6-1.7-0.7C854.5,1163.9,854.1,1163.4,854,1162.8"
          />
          <path
            id="path6396"
            class="countryIsland"
            d="M852.7,1168.9c-0.1-0.5,0-0.7-0.7-0.7C852.2,1168.4,852.4,1168.7,852.7,1168.9"
          />
          <path
            id="path6398"
            class="countryIsland"
            d="M853.7,1164.7c0.1,0.4,0.1,0.6,0.6,0.7C854.1,1165.1,853.9,1164.9,853.7,1164.7"
          />
          <path
            id="path6400"
            class="countryIsland"
            d="M859.7,1164.6c-0.3-0.9-1.2-1.4-1.6-2.2c0.5,0.5,0.9,0.5,1.4,0.1c-1.1-0.5-1-2.2-2.1-2.7
    		c-1.6-0.8-0.8,1.4-0.1,1.9c-0.5-0.2-1-0.2-1.5,0.1C856.8,1163.2,857.9,1164.3,859.7,1164.6"
          />
          <path
            id="path6402"
            class="countryIsland"
            d="M859.4,1170.5c0.1-0.2,0.1-0.4,0.2-0.6c-1,0.1-1.9-0.5-2.9-0.2c0.1,0.3,0.2,0.5,0.4,0.7
    		C857.8,1169.6,858.8,1169.9,859.4,1170.5"
          />
          <path
            id="path6404"
            class="countryIsland"
            d="M860.5,1163.9c0.2-0.9,0-1-0.7-0.3C860,1163.7,860.3,1163.8,860.5,1163.9"
          />
          <path
            id="path6406"
            class="countryIsland"
            d="M863.9,1172.6c0.2-0.6,0.4-1.2,1.2-0.7c-0.4-1-2-0.8-2.8-0.9c0.6,0,1.1-0.3,1.3-0.9
    		c-0.6-0.1-1.1,0.7-1.7,0.5c-0.7-0.1-1.4-0.3-2.1-0.1C860.5,1172.2,863.1,1170.8,863.9,1172.6"
          />
          <path
            id="path6408"
            class="countryIsland"
            d="M861.4,1164.9c0.1-0.9-1-0.7-1.4-0.4C860.5,1164.7,861,1164.8,861.4,1164.9"
          />
          <path
            id="path6410"
            class="countryIsland"
            d="M862.7,1169.6c-0.6-0.1-1.1,0.1-1.5,0.4C861.8,1170.1,862.3,1170,862.7,1169.6"
          />
          <path
            id="path6412"
            class="countryIsland"
            d="M866,1170.2c-0.2,0.2-0.4,0.5-0.6,0.7C866.1,1170.9,866,1170.7,866,1170.2"
          />
          <path
            id="path6414"
            class="countryIsland"
            d="M867.1,1172.9c-0.2-0.3-0.4-0.4-0.7-0.5C866.6,1172.5,866.8,1172.7,867.1,1172.9"
          />
          <path
            id="path6418"
            class="countryIsland"
            d="M867.1,1171.2c1.3-0.2,2.8,0.1,3.8-0.9c-1.1-0.2-3.6-0.6-4.5,0.2
    		C866.7,1170.8,866.9,1171,867.1,1171.2"
          />
          <path
            id="path6420"
            class="countryIsland"
            d="M866.9,1171.9c0.3,0.7,2.3,0.9,2.9,0.8c-0.7,1.3,2.7,2.2,3.5,1.7c-0.5-0.4-1-0.7-1.5-1.1
    		c-1.9-1.3,1.4-0.6-0.8-1.3c0.9-0.3,2,0.8,3,0.9c1,0.1,1.6,1.2,1.2,1.7c0.8-0.5,3,0.1,3.9,0.3c1,0.2,1.2,1.6,2.5,1.2
    		c-0.8-0.9-1.5-1.7-2.7-2.2c-0.9-0.3-2.4-0.1-3-1c0.8,0.7,2.1,0.4,3,0.1c-1.8-1.2-4.5-0.2-6-1.9c1.4,0.6,1.9,0.4,3.3,0.4
    		c1.1,0,0.5-1.1-0.3-1.2c-1.1-0.2-2.3,0.2-3.3,0.5c-0.6,0.2-3.9-0.4-2.8,1.1C869.3,1170.8,867.6,1171.2,866.9,1171.9"
          />
          <path
            id="path6422"
            class="countryIsland"
            d="M867.7,1173.4c-0.7,0.1-0.6,0.3,0.3,0.6C867.9,1173.8,867.8,1173.6,867.7,1173.4"
          />
          <path
            id="path6424"
            class="countryIsland"
            d="M869.9,1174.2c-0.2-0.4-0.2-0.6-0.7-0.5C869.5,1173.9,869.7,1174.1,869.9,1174.2"
          />
          <path
            id="path6318"
            class="countryIsland"
            d="M820.1,1145.3c0.9-0.3,0.2-1.2-0.3-1.4C819.7,1144.4,819.8,1144.9,820.1,1145.3"
          />
          <path
            id="path6322"
            class="countryIsland"
            d="M822,1147.2c0.4-0.9,0.3-3.8-1.3-3.6c0.2,0.5,0.5,1,0.8,1.4
    		C820.2,1144.7,821.3,1146.7,822,1147.2"
          />
          <path
            id="path6326"
            class="countryIsland"
            d="M822.7,1147.3c0.5-0.6,0.8-2.6-0.5-2.2C822.2,1145.9,822.6,1146.5,822.7,1147.3"
          />
          <path
            id="path6328"
            class="countryIsland"
            d="M824.3,1148.8c0.8-1-1.1-0.7-1.5-0.8C823.3,1148.3,823.8,1148.5,824.3,1148.8"
          />
          <path
            id="path6332"
            class="countryIsland"
            d="M823.3,1146.7c0.5-0.1,0.9-0.4,1.2-0.8C823.9,1145.8,823.4,1146.1,823.3,1146.7"
          />
          <path
            id="path6334"
            class="countryIsland"
            d="M826.5,1145.8c0-1-2.2-1.9-3-1.9C824.4,1144.7,825.4,1145.3,826.5,1145.8"
          />
          <path
            id="path6340"
            class="countryIsland"
            d="M826.8,1144.5c0.2-0.1,0.3-0.2,0.5-0.4c-0.8-0.4-1.2-1.3-2-1.7
    		C825.6,1143.6,825.5,1144,826.8,1144.5"
          />
          <path
            id="path6344"
            class="countryIsland"
            d="M828.5,1149.4c0.4-0.1,0.7-0.3,0.9-0.7c-0.9-0.5-2.6-2.4-3.7-1.3c0.9-0.3,1.6,0.4,2.3,0.8
    		C826.3,1147.7,828.1,1149.2,828.5,1149.4"
          />
          <path
            id="path6346"
            class="countryIsland"
            d="M837,1157c0.6,0.2,1.3,0.2,1.9,0.1c-1.1-0.3-1.9-1.2-3-1.5c2.3,2.2-3.1-2.2-0.7,0.1
    		c-1.6-1.2-3.1-1.2-4.9-1.7c-1.4-0.4-2.5-1.9-4.1-1.5c1.9,0.9,3.2,2.1,5.2,2.7c1,0.3,2,0.1,3,0.5C835.2,1156,835.9,1157.4,837,1157"
          />
          <path
            id="path6348"
            class="countryIsland"
            d="M827.4,1147.3c-0.2-0.4-0.3-0.5-0.8-0.4C826.9,1147.1,827.1,1147.2,827.4,1147.3"
          />
          <path
            id="path6350"
            class="countryIsland"
            d="M828.4,1144.6c-0.1-0.7-1-0.9-1.5-1C827.4,1144,827.9,1144.3,828.4,1144.6"
          />
          <path
            id="path6352"
            class="countryIsland"
            d="M830.5,1148c-0.3-1.4-2.4-2.3-3.7-2.4C827.7,1146.9,829.5,1147.1,830.5,1148"
          />
          <path
            id="path6354"
            class="countryIsland"
            d="M828.3,1145.4c-0.3-0.3-0.1-0.6-0.7-0.4C827.8,1145.1,828.1,1145.2,828.3,1145.4"
          />
          <path
            id="path6356"
            class="countryIsland"
            d="M830.5,1149.4c0.2,0,0.3-0.1,0.5-0.1c-0.5-1-1.7-1.5-2.7-1.4
    		C829,1148.4,829.9,1148.7,830.5,1149.4"
          />
          <path
            id="path6358"
            class="countryIsland"
            d="M829.1,1146.2c0.5-0.4,0.3-0.6-0.5-0.7C828.8,1145.7,828.9,1146,829.1,1146.2"
          />
          <path
            id="path6360"
            class="countryIsland"
            d="M829.6,1147.9c0.1-0.5-0.1-0.6-0.8-0.3C829.1,1147.7,829.4,1147.8,829.6,1147.9"
          />
          <path
            id="path6362"
            class="countryIsland"
            d="M831.4,1152.2c1-0.6,0.6-2.7-0.7-2.5C829.7,1149.9,831.4,1151.8,831.4,1152.2"
          />
          <path
            id="path6366"
            class="countryIsland"
            d="M833.4,1155.4c0.2,0.3,0.4,0.4,0.7,0.5C833.8,1155.7,833.6,1155.5,833.4,1155.4"
          />
          <path
            id="path6368"
            class="countryIsland"
            d="M835.7,1154.1c-0.2-1-1.6-0.9-2.2-0.5C834.2,1153.3,835,1153.6,835.7,1154.1"
          />
          <path
            id="path6370"
            class="countryIsland"
            d="M833.8,1146.6c0.6-0.2,0.9-0.7,0.6-1.3C833.9,1145.5,833.6,1146,833.8,1146.6"
          />
          <path
            id="path6272"
            class="countryIsland"
            d="M811.5,1128.7c-0.5-1.5-2.1-2.2-3.6-2.4c0.2,0.8,0.9,1.9,1.8,1
    		C809.8,1128.4,811,1128.1,811.5,1128.7"
          />
          <path
            id="path6286"
            class="countryIsland"
            d="M813.9,1132c0.3-0.5,0.6-1,1.1-1.4c-0.8,0.6-1.6,0.5-1.4-0.6c-0.4,0.2-0.7,0.1-1-0.2
    		c-0.6-0.5-1.3,0.1-2,0c0.5,0.4,2.4,0.7,2.3,1.7c-1,0.2-0.8-1-1.8-0.9c0,0.4,0,1,0.4,1.3c0.6,0.6,1.8-0.2,1.1,1.1
    		C813.3,1133,813.7,1132.6,813.9,1132"
          />
          <path
            id="path6290"
            class="countryIsland"
            d="M814.1,1136.1c0.2-0.7-0.4-1.3-0.7-1.9c0.6,0.3,1,0.1,1.1-0.6c-0.8,0.2-1.5-0.4-2.2,0.1
    		c0.7,0.2,0.8,0.8,0.2,1.2C813.4,1135.1,813.2,1136.2,814.1,1136.1"
          />
          <path
            id="path6294"
            class="countryIsland"
            d="M814.7,1132.8c-0.2-0.2-0.4-0.2-0.7-0.1C814.2,1132.7,814.5,1132.8,814.7,1132.8"
          />
          <path
            id="path6300"
            class="countryIsland"
            d="M816.2,1136.3c0.1-0.6-0.1-1.4-0.9-1.3C815.3,1135.6,815.6,1136,816.2,1136.3"
          />
          <path
            id="path6304"
            class="countryIsland"
            d="M816.7,1133.7c0.3-0.3,0.5-0.7,0.6-1.1C816.6,1132.7,816.3,1133.1,816.7,1133.7"
          />
          <path
            id="path6306"
            class="countryIsland"
            d="M818.7,1136.9c0-0.6-1.6-2.2-2.2-1.7C815.5,1136.2,818,1136.9,818.7,1136.9"
          />
          <path
            id="path6308"
            class="countryIsland"
            d="M819.4,1138.8c0.4-0.7,1.2-1.2,2-0.7c-1.2-0.8-1.3-2.6-3.2-2.3c0.4,0.5,0.8,1,1.2,1.5
    		c-0.8-0.1-1.5-0.3-2.3-0.4c0.1,0.8,1.5,1.8,2.2,1.1C819.3,1138.3,819.4,1138.6,819.4,1138.8"
          />
          <path
            id="path6310"
            class="countryIsland"
            d="M817.7,1143.1c1.1-0.3,0.5-1.8,0.6-2.5c-0.5,0.4-1,0.2-1.2-0.4
    		C817.2,1141.1,817.1,1142.4,817.7,1143.1"
          />
          <path
            id="path6314"
            class="countryIsland"
            d="M821.7,1136.6c0-0.8-1.2-1.3-1.8-1.6c1.4,0,0.4-0.2-0.1-0.8c-0.6-0.6-1.1-0.9-1.9-0.5
    		c0.6,0.4,0.8,1,1.2,1.5C819.7,1136.1,820.8,1136,821.7,1136.6"
          />
          <path
            id="path6316"
            class="countryIsland"
            d="M819.2,1141.8c0.8-0.2,1.3-1.1,1.5-0.2c0-0.3,0-0.6,0-0.9c0.3,0.2,0.5,0.3,0.8,0.5
    		c-0.7-1.2-1.6-2.5-2.7-0.6C819.3,1140.8,819.6,1141.3,819.2,1141.8"
          />
          <path
            id="path6320"
            class="countryIsland"
            d="M821.7,1139.6c0.1-0.8-0.9-0.8-1.2-1.5C819.8,1139,821.1,1139.3,821.7,1139.6"
          />
          <path
            id="path6324"
            class="countryIsland"
            d="M822.7,1139.7c0.2,0,0.3-0.1,0.5-0.1c-0.6-0.7-1.1-1.5-2-2.1
    		C821.7,1138.2,822.2,1139,822.7,1139.7"
          />
          <path
            id="path6330"
            class="countryIsland"
            d="M824.7,1141.6c0-0.7-0.6-1.2-1.4-1.1C823.6,1141,824.1,1141.4,824.7,1141.6"
          />
          <path
            id="path6338"
            class="countryIsland"
            d="M824.7,1141c0.2-0.1,0.4-0.3,0.7-0.4c-0.3-0.6-0.8-0.8-1.3-0.3
    		C824.3,1140.5,824.5,1140.8,824.7,1141"
          />
          <path
            id="path6342"
            class="countryIsland"
            d="M826.6,1141c0-0.6-0.3-0.9-0.9-0.9C825.7,1140.6,826,1141,826.6,1141"
          />
          <path
            id="path6206"
            class="countryIsland"
            d="M806.8,1118.6c1.3-1.3-3.2-6.1-4.9-5.1c0.3,0.4,1.4,0.9,1.5,1.4c0.1,0.8,1.2,0.7-0.1,1.2
    		c0.7,0.3,1.5-0.5,1.8,0.2C805.3,1117,806.1,1118.2,806.8,1118.6"
          />
          <path
            id="path6218"
            class="countryIsland"
            d="M803.7,1111.3c0.2-0.5,0.1-0.8-0.3-1.2c-0.2,0.2-0.4,0.4-0.5,0.7
    		C803.2,1111,803.4,1111.2,803.7,1111.3"
          />
          <path
            id="path6220"
            class="countryIsland"
            d="M804.1,1118.5c1,0.2,1.6-0.5,1.1-1.4c-0.7-1.1-1.3-0.3-2.2-0.1c0.2,0.9,1.1,0.9,1.8,1.2
    		C804.5,1118.2,804.3,1118.4,804.1,1118.5"
          />
          <path
            id="path6224"
            class="countryIsland"
            d="M804.4,1119.4c0.6-0.2,1.2-0.6,1.6-1c-0.9-0.3-1.4,0.7-2.3,0.3
    		C804,1118.9,804.2,1119.2,804.4,1119.4"
          />
          <path
            id="path6226"
            class="countryIsland"
            d="M804.7,1110.9c0.3-0.7,0-0.9-0.7-0.6C804.2,1110.5,804.5,1110.7,804.7,1110.9"
          />
          <path
            id="path6230"
            class="countryIsland"
            d="M806.5,1113.2c0.2-0.8-0.1-1.7-0.8-2.1c-0.3,0.5-0.3,1,0,1.4c-0.5-0.4-1.8-0.7-1.5,0.4
    		C805,1112.3,805.7,1113.1,806.5,1113.2"
          />
          <path
            id="path6232"
            class="countryIsland"
            d="M807,1116.4c2.3-1.7-1.1-3-2.6-2.7C805.1,1114.8,806,1115.7,807,1116.4"
          />
          <path
            id="path6234"
            class="countryIsland"
            d="M807.9,1121.8c-0.1-1.2-2.3-2.3-3.3-1.7C804.8,1121.6,807.2,1120.6,807.9,1121.8"
          />
          <path
            id="path6236"
            class="countryIsland"
            d="M806.4,1113.8c-0.2-0.6-0.9-0.7-1.5-0.7C805.5,1113.2,806,1113.4,806.4,1113.8"
          />
          <path
            id="path6242"
            class="countryIsland"
            d="M805.9,1121.9c0.1-0.5-0.1-0.6-0.7-0.3C805.5,1121.7,805.7,1121.8,805.9,1121.9"
          />
          <path
            id="path6254"
            class="countryIsland"
            d="M806.9,1123.4c-0.2-0.9,1.5-1.7-0.2-2.2C806.8,1122.1,805,1123.1,806.9,1123.4"
          />
          <path
            id="path6258"
            class="countryIsland"
            d="M807.9,1121.1c0.3-0.1,0.5-0.2,0.8-0.4c-0.4-0.8-1.2-1.2-2.1-1.2
    		C807,1120.1,807.5,1120.6,807.9,1121.1"
          />
          <path
            id="path6260"
            class="countryIsland"
            d="M807.2,1113c-0.2-0.3-0.4-0.5-0.6-0.8C806.8,1112.4,807,1112.7,807.2,1113"
          />
          <path
            id="path6262"
            class="countryIsland"
            d="M807.8,1119.7c0-0.5-0.2-0.8-0.7-1.1C806.8,1119.3,807.2,1119.6,807.8,1119.7"
          />
          <path
            id="path6264"
            class="countryIsland"
            d="M810.6,1118.5c1.3-0.3-2.5-4-3-4.6c0.1,0.5,0.2,1,0.3,1.5c0.2,0.6,0.8,0.8,0.9,1.4
    		c-0.5-1.3-2.1-0.5-1.3,0.7C808.1,1118.4,809.8,1118.1,810.6,1118.5"
          />
          <path
            id="path6266"
            class="countryIsland"
            d="M808.1,1125.7c-0.2-0.2-0.4-0.3-0.7-0.4C807.6,1125.4,807.9,1125.6,808.1,1125.7"
          />
          <path
            id="path6268"
            class="countryIsland"
            d="M813.9,1129.5c0.7,0.3,1.6,0.3,2.2,0c-0.2-0.2-0.4-0.4-0.6-0.7c1.3,0.9,0.8-1.3-0.3-1.5
    		c2,0.4-0.9-2.1-0.5-0.1c-0.2-1.7-0.3-4.1-1.6-5.3c-0.5-0.5-0.2-1.2-1-1.4c-0.5-0.2-1.1-2.8-1.1-0.9c-0.8-3.1-3.6,1.3-1.5,0.4
    		c-1.6,0.4-0.1,1.5,0.7,0.7c-0.5,0.7,0.8,2.7,0.9,3.5c-0.4-0.4-0.9-0.7-1.4-0.9c1,2-1.8-0.9,0,1.2c-0.3-0.5-0.9-0.8-1.4-0.7
    		c0.5,0.4,1,0.9,1.4,1.4c-0.8-0.5-1.3-1.2-2.2-1.7c-0.1,1.3,1.3,1.6,1.9,2.5c0.7,1,1.8,2.5,3.1,2.6c0.1-1-1-1.3-1.1-2.2
    		c-0.1-0.9,0.9-1,0.9-1.8c0.8,0.1,1.1,1.1-0.2,0.9c0,0.6,0.4,1,0.9,1.2c-0.3,0.1-0.5,0.3-0.8,0.4c0.2,0.6,1,0.7,1.5,0.9
    		c1,0.6-0.8,1.3,0.8,1C814.5,1129.1,814.2,1129.3,813.9,1129.5"
          />
          <path
            id="path6270"
            class="countryIsland"
            d="M808.2,1118.9c0.8-0.4,0.5-0.8-0.4-0.7C808,1118.5,808.1,1118.7,808.2,1118.9"
          />
          <path
            id="path6274"
            class="countryIsland"
            d="M808.2,1111.7c0.2-0.3,0.4-0.3,0.2-0.8C808.4,1111.1,808.3,1111.4,808.2,1111.7"
          />
          <path
            id="path6276"
            class="countryIsland"
            d="M810.6,1123.5c0.3-1.2-1.8-2.9-2.2-1.6C808.2,1122.4,810.3,1123.2,810.6,1123.5"
          />
          <path
            id="path6278"
            class="countryIsland"
            d="M809.2,1123.3c-0.2-0.2-0.4-0.3-0.7-0.3C808.8,1123.1,809,1123.2,809.2,1123.3"
          />
          <path
            id="path6280"
            class="countryIsland"
            d="M810,1115.6c1.1-0.8-0.3-1.6-1.1-1.5C809.2,1114.5,809.6,1115.1,810,1115.6"
          />
          <path
            id="path6282"
            class="countryIsland"
            d="M810,1112.5c0.9-0.3,1.9-0.1,2.5-0.8c-1.2-0.6-2.4-0.1-3.7-0.3
    		C809,1112,809.4,1112.5,810,1112.5"
          />
          <path
            id="path6284"
            class="countryIsland"
            d="M812.1,1116.6c0.2-0.7-0.9-1.1-1.3-1.4C810.4,1116.2,811.6,1116.3,812.1,1116.6"
          />
          <path
            id="path6288"
            class="countryIsland"
            d="M813.6,1116.8c-0.3-0.6-0.9-0.9-1.5-0.8C812.6,1116.4,813.1,1116.6,813.6,1116.8"
          />
          <path
            id="path6292"
            class="countryIsland"
            d="M814.2,1112.8c-0.1-0.4-0.3-0.6-0.7-0.7C813.7,1112.4,814,1112.6,814.2,1112.8"
          />
          <path
            id="path6296"
            class="countryIsland"
            d="M815.4,1126.3c0.2-0.2,0.4-0.3,0.7-0.5c-0.4-0.5-0.9-0.8-1.4-1
    		C814.9,1125.3,815.1,1125.8,815.4,1126.3"
          />
          <path
            id="path6298"
            class="countryIsland"
            d="M815.4,1113.6c-0.2-0.2-0.5-0.3-0.7-0.4C815,1113.4,815.2,1113.5,815.4,1113.6"
          />
          <path
            id="path6166"
            class="countryIsland"
            d="M798.9,1093.3c-0.2-0.2-0.5-0.3-0.8-0.4C798.3,1093.1,798.6,1093.2,798.9,1093.3"
          />
          <path
            id="path6176"
            class="countryIsland"
            d="M799.3,1093.5c0.2,0.1,0.5,0.1,0.7,0.1c0-0.5,0-1-0.1-1.4
    		C799.2,1092.4,798.9,1092.9,799.3,1093.5"
          />
          <path
            id="path6222"
            class="countryIsland"
            d="M804.6,1095.1c0.1-0.6,0.2-1.7-0.8-1.5C803.9,1094.2,804.1,1094.7,804.6,1095.1"
          />
          <path
            id="path6240"
            class="countryIsland"
            d="M805.6,1095.7c0.2-0.4,0.5-0.8,1-0.9C805.9,1093.9,804.7,1094.9,805.6,1095.7"
          />
          <path
            id="path6244"
            class="countryIsland"
            d="M805.9,1093.8c-0.1-0.4-0.3-0.6-0.6-0.8C805.4,1093.3,805.6,1093.6,805.9,1093.8"
          />
          <path
            id="path6248"
            class="countryIsland"
            d="M806.4,1088.6c-0.2-0.3-0.4-0.5-0.6-0.7C805.9,1088.1,806.1,1088.4,806.4,1088.6"
          />
          <path
            id="path6250"
            class="countryIsland"
            d="M806,1105.6c0.4-0.2,0.5-0.5,0.5-0.9C805.7,1104.6,805.5,1104.9,806,1105.6"
          />
          <path
            id="path6252"
            class="countryIsland"
            d="M807.3,1097.6c0.3-1.1-0.6-2-1.5-1.1C806.4,1096.7,806.9,1097.1,807.3,1097.6"
          />
          <path
            id="path6256"
            class="countryIsland"
            d="M807.2,1096c-0.2-0.4-0.5-0.7-1-0.9C806.3,1095.7,806.6,1096.1,807.2,1096"
          />
          <path
            id="path6144"
            class="countryIsland"
            d="M796.7,1079.6c-0.4-0.2-0.3-0.4-0.8-0.1C796.2,1079.5,796.4,1079.6,796.7,1079.6"
          />
          <path
            id="path6174"
            class="countryIsland"
            d="M799.8,1082.5c0.4-0.7-0.4-0.7-0.8-0.4C799.3,1082.3,799.5,1082.4,799.8,1082.5"
          />
          <path
            id="path6130"
            class="countryIsland"
            d="M794.1,1060.3c-0.7-0.5-0.9-0.1-1.2,0.4c-0.4,0.7-1.9-0.8-2.4-0.8c1.2,1.3,0.1,3,0.8,4
    		c1.1,1.7,0.9,4.3,1.8,6c0.4,0.7-0.4,1.2-0.1,2c0.3,0.8,1.3,1,2.1,1.2c0.6,0.2,1.3,0.4,2,0.4c1.4,0.2,0.2-1.8,0.1-2.2
    		c2.1-0.2,0-1.7-0.7-2.3c0.5,0.1,1,0.1,1.4,0c-0.3-0.5-4.2-3-2-3c-1.6-1,0.1-1.5,0.9-2.2C795.6,1062.9,794.6,1061.7,794.1,1060.3"
          />
          <path
            id="path6132"
            class="countryIsland"
            d="M791.5,1075.7c0.3-0.2,0.5-0.4,0.7-0.6c-0.5-0.3-1-0.3-1.4,0
    		C791,1075.4,791.2,1075.6,791.5,1075.7"
          />
          <path
            id="path6134"
            class="countryIsland"
            d="M793.8,1086.7c0.2-0.6-0.1-0.9-0.7-0.7C793.3,1086.2,793.6,1086.5,793.8,1086.7"
          />
          <path
            id="path6136"
            class="countryIsland"
            d="M795.1,1084.6c-0.1-0.4-0.1-0.6-0.6-0.7C794.7,1084.1,794.9,1084.3,795.1,1084.6"
          />
          <path
            id="path6138"
            class="countryIsland"
            d="M796.1,1067.2c0.1-0.6-0.1-0.6-0.7-0.1C795.7,1067.1,795.9,1067.1,796.1,1067.2"
          />
          <path
            id="path6140"
            class="countryIsland"
            d="M797.1,1066.4c-0.2-0.7-0.8-1.1-1.5-0.9C796.2,1065.8,796.7,1066,797.1,1066.4"
          />
          <path
            id="path6142"
            class="countryIsland"
            d="M797.3,1084.7c-0.3-0.7-1.1-0.7-1.5-0.2C796.3,1084.6,796.8,1084.7,797.3,1084.7"
          />
          <path
            id="path6146"
            class="countryIsland"
            d="M797.2,1083.4c0.3-0.3,0.4-0.8,0.3-1.2c-0.4,0.5-0.9,0.6-1.5,0.3
    		C796.2,1083.1,796.6,1083.5,797.2,1083.4"
          />
          <path
            id="path6148"
            class="countryIsland"
            d="M797,1079.9c-0.2-0.1-0.5-0.1-0.7-0.1C796.5,1079.8,796.8,1079.8,797,1079.9"
          />
          <path
            id="path6150"
            class="countryIsland"
            d="M797.1,1081.3c-0.2-0.2-0.4-0.2-0.7-0.1C796.6,1081.2,796.8,1081.3,797.1,1081.3"
          />
          <path
            id="path6152"
            class="countryIsland"
            d="M797.9,1078.3c-0.1-0.5,0.2-1,0.8-1.1c-0.8,0.2-1.7-0.2-2.3,0.6
    		C797,1077.8,797.5,1078,797.9,1078.3"
          />
          <path
            id="path6154"
            class="countryIsland"
            d="M798.4,1086.1c0.2-0.8-0.8-1.1-1.4-1.1C797.4,1085.5,797.9,1085.9,798.4,1086.1"
          />
          <path
            id="path6156"
            class="countryIsland"
            d="M797.7,1060.5c0-0.4-0.2-0.7-0.6-0.8C797.3,1060,797.5,1060.3,797.7,1060.5"
          />
          <path
            id="path6158"
            class="countryIsland"
            d="M798.9,1070.2c-0.4-0.4-0.9-0.5-1.4-0.4C797.9,1069.9,798.4,1070,798.9,1070.2"
          />
          <path
            id="path6160"
            class="countryIsland"
            d="M799.1,1085.7c0.8-0.3,1.3-1.1,2.2-1.1c-0.7-0.7-1.9-0.9-2.8-0.7
    		C796.9,1084.4,798.1,1085.4,799.1,1085.7"
          />
          <path
            id="path6162"
            class="countryIsland"
            d="M799.2,1081.6c0-0.3,0.1-0.6,0.3-0.9c-0.5-0.2-1-0.2-1.6-0.1
    		C798.2,1081,798.6,1081.4,799.2,1081.6"
          />
          <path
            id="path6168"
            class="countryIsland"
            d="M799.1,1078.6c-0.2-0.2-0.4-0.3-0.7-0.2C798.6,1078.5,798.9,1078.6,799.1,1078.6"
          />
          <path
            id="path6170"
            class="countryIsland"
            d="M799.7,1083.5c0.1-0.2,0.2-0.4,0.3-0.7c-0.5-0.2-1-0.3-1.5-0.4
    		C798.7,1083,799.1,1083.4,799.7,1083.5"
          />
          <path
            id="path6172"
            class="countryIsland"
            d="M799.7,1080.5c-0.1-0.4-0.4-0.7-0.9-0.9C798.8,1080.2,799.1,1080.5,799.7,1080.5"
          />
          <path
            id="path6178"
            class="countryIsland"
            d="M799.1,1087.3c0.9,0.4,2-0.2,2.8-0.4C801,1086.1,799.7,1086.1,799.1,1087.3"
          />
          <path
            id="path6180"
            class="countryIsland"
            d="M800.3,1089.8c0.2-0.6-0.4-1.1-0.9-1.2C799.4,1089.2,799.8,1089.7,800.3,1089.8"
          />
          <path
            id="path6182"
            class="countryIsland"
            d="M800.2,1081.6c0.1-0.8-0.2-0.8-0.8-0.1C799.7,1081.5,800,1081.6,800.2,1081.6"
          />
          <path
            id="path6186"
            class="countryIsland"
            d="M800.2,1090.6c0.2-0.7-0.1-0.8-0.7-0.3C799.8,1090.4,800,1090.5,800.2,1090.6"
          />
          <path
            id="path6188"
            class="countryIsland"
            d="M800.9,1079.9c0.3-0.8-0.6-1.3-1.2-1.4C800,1079,800.4,1079.5,800.9,1079.9"
          />
          <path
            id="path6190"
            class="countryIsland"
            d="M800.5,1082.7c0.4-0.5,0.2-0.7-0.5-0.7C800.2,1082.2,800.4,1082.5,800.5,1082.7"
          />
          <path
            id="path6192"
            class="countryIsland"
            d="M800.7,1086.1c0.1-0.3,0.1-0.5,0-0.8c-0.3,0.2-0.5,0.3-0.7,0.6
    		C800.3,1086,800.5,1086,800.7,1086.1"
          />
          <path
            id="path6194"
            class="countryIsland"
            d="M801.1,1093.9c1-0.5,1.3-2.5-0.1-2.6C799.4,1091.2,800.9,1093.3,801.1,1093.9"
          />
          <path
            id="path6196"
            class="countryIsland"
            d="M802.1,1087.3c-0.6,0.3-1.3,0.4-1.9,0.6c0.2,1.5,3,1.4,4,1.2
    		C804.1,1088.2,803.1,1087.2,802.1,1087.3"
          />
          <path
            id="path6198"
            class="countryIsland"
            d="M802.8,1090.3c0.5-0.1,0.9-0.1,1.4-0.1c-0.4-0.8-2.1-1.2-2.9-0.8
    		C801.7,1089.9,802.2,1090.2,802.8,1090.3"
          />
          <path
            id="path6200"
            class="countryIsland"
            d="M802.5,1086.9c0.4-0.8-0.4-1.5-1.2-1.2C801.6,1086.2,801.9,1086.6,802.5,1086.9"
          />
          <path
            id="path6202"
            class="countryIsland"
            d="M802.4,1085.1c0.3-0.1,0.6-0.1,0.9-0.2C802.3,1082.8,800.6,1084.4,802.4,1085.1"
          />
          <path
            id="path6204"
            class="countryIsland"
            d="M802.2,1063c0.1-0.5-0.1-0.7-0.7-0.4C801.7,1062.8,801.9,1062.9,802.2,1063"
          />
          <path
            id="path6208"
            class="countryIsland"
            d="M802.6,1092.2c0.4-0.7,1.2-0.4,1.8-0.2C804,1090.9,801.9,1090.9,802.6,1092.2"
          />
          <path
            id="path6210"
            class="countryIsland"
            d="M803,1085.9c0.2,0,0.3,0,0.5,0c0-0.6-0.3-0.9-0.9-0.7C802.8,1085.4,802.9,1085.7,803,1085.9"
          />
          <path
            id="path6212"
            class="countryIsland"
            d="M803.6,1091c0.1-0.7-0.2-0.7-0.8-0.2C803.1,1090.9,803.4,1090.9,803.6,1091"
          />
          <path
            id="path6214"
            class="countryIsland"
            d="M803.9,1079c-0.1-0.8-0.2-1.5-1.1-0.9C802.9,1078.6,803.4,1078.9,803.9,1079"
          />
          <path
            id="path6216"
            class="countryIsland"
            d="M804.1,1093.4c1.1-0.7-0.5-1.7-1.3-1.2C803.1,1092.8,803.6,1093.3,804.1,1093.4"
          />
          <path
            id="path6228"
            class="countryIsland"
            d="M808.4,1085c-0.2-0.3-0.4-0.5-0.6-0.8c1.1-0.3,0.8-1.4-0.2-1.4c-0.3,0-0.6,0-0.9-0.2
    		c-0.9-0.7-0.7,0.3-1.8-0.3c-0.2,0.6,0.2,1.1,0.7,1.2c-0.5,0.4-1,0.5-1.6,0.5c0.2,0.7,0.7,1.7,1.7,1.5c-1.6,0.3,0,1,0.6,1.1
    		C807.2,1086.7,807.9,1085.6,808.4,1085"
          />
          <path
            id="path6238"
            class="countryIsland"
            d="M806.6,1093.7c0-0.4-0.4-2.8-1-2.5C803.8,1091.9,805.9,1093.2,806.6,1093.7"
          />
          <path
            id="path6464"
            class="countryIsland"
            d="M802.2,1094.1c-0.1-0.3-0.1-0.5-0.2-0.8C803,1093.4,803.1,1093.7,802.2,1094.1"
          />
          <path
            id="path6462"
            class="countryIsland"
            d="M836,1147.9c0-0.3,0-0.5-0.1-0.8c0.2,0.2,0.3,0.5,0.4,0.8C836.2,1147.9,836.1,1147.9,836,1147.9
    		"
          />
          <path
            id="path6470"
            class="country"
            d="M771.6,857.3c1.6-0.9,3.5-0.1,4.1-2.3c0.3-1-1.1-2.5-0.3-3.4c0.2-0.1,0.4-0.2,0.6-0.1
    		c0.6,0,1.2-1.1,1.6-1.5c0,1.2,1.3,1.8,1.7,2.9c0.3,1.1,1,1.5,2.1,1.7c-1,0.9,0.5,3.6,0.8,4.6c0.9,2.6,1.4,4.1,3.6,5.9
    		c2,1.6,1.1,2.1,0.3,4.2c-0.1,0.4,1.5,1.4,1.5,2.1c0,0.9-1.3,0.9-1.9,1.3c1.1,1,0.2,2.1,0.9,3.2c0.3,0.5,1.2,0.4,1.5,0.9
    		c0.5,0.7-0.1,0.8,0,1.5c0.3,2.2,3.2,3.2,3.5,5.8c0.3,3.1,2.3,5.6,3.2,8.5c0.7,2.2,0.6,5,3.8,4c3.2-1,3.4,1,3.1,3.7
    		c-0.2,2-0.1,5.9-1.8,7.4c-3.7,1.1-6.3,2.7-7.1,5.1c-0.3,0.9,1.2,2.6,1.6,3.5c-2.3,0.1-0.7,3.7-0.2,4.8c0.4,0.9,1.1,2.2,1.4,3.2
    		c0.5,1.5-0.9,1.8-0.8,3.2c0.1,1.9,3.2,3.3,2.8,4.9c-0.3,1-1.9,1.8-2.7,1.4c-1-0.5-1.4,2.3-1.4,2.7c-0.2,2.5-1.1,5.7-3,8.4
    		c-1.4,2,0.4,3.5-0.4,5.7c-0.4,1.1-1.6,1.2-1.2,2.6c0.3,1.2,1.2,2.1,1.5,3.3c0.2,0.7,0.4,1.2,0.6,1.8c0.2,0.6,0.5,1.1,0.7,1.7
    		c0.4,1.2-0.9,3.1-2,1.6c0.8,1,0.1,2.9,0.1,4c0,0.8,0.5,1.9-0.3,2.5c-0.5,0.5-0.6,0.4-0.6,1.3c-0.1,1.7,1.2,3.4,2,4.8
    		c0.4,0.7,0.8,0.5,1.3,1.1c0.4,0.5,0.3,1.4-0.4,1c0.5,1.9,2.5,2.9,2.8,4.8c0.1,0.8,0.5,1.3,1.3,1.7c1.1,0.5,0,1.7,0.2,2.6
    		c0.6,2.2,2.9,0.3,2.9,2.9c0,2-0.3,3.7,0.8,5.4c0.2,0.3,1.1,1.8,0,1.9c-0.7,0-0.8,0.4-0.9,1c-0.2,0.8-0.4,1.7-0.5,2.5
    		c-0.2,1,0.2,1.9,0.3,2.9c0.1,1.4-0.6,1.7-1,1.6c-0.8-0.3,0.2,0.6,0.6,1.1c1.3,1.9,2,3.9,2.3,6c0.1,0.7,0,1.1-0.7,1.2
    		c-0.6,0.1-0.8,1-0.8,1.5c-0.1,1.7-1.5,0.1-1.9,2.1c-0.2,0.9-0.1,1.6,0.2,2.5c0.3,0.8,0.3,1.7,0.8,2.5c1.2,2.1,1,3,1.2,4.3
    		c0.2,1.6,1.7,2.5,2.3,3.9c0.4,0.9,0.8,1.8,1.5,2.6c0.3,0.4,1,1.4,0.8,2c-0.6,1.4-2.8,1.3-2.4,3.2c0.3,1.2-0.3,4.1,0.6,4.9
    		c-1,0.3-1.7,0.6-0.8,0.8c0.4,0.1,2.4,4.1,0.3,3.5c0.2,1.1,1,1.2,1.3,2c0.2,0.7-0.2,1.6-0.3,2.2c-0.4,1.9,0.3,3.2,1.7,4.5
    		c1.1,1.1,1.3,3.1,1.4,4.8c0.1,1.5,1.7,2.4,2.5,3.6c-0.6,0.6-1.7,0.3-1.9,1.2c-0.2,0.8,1,2.1,1.1,3.1c0.1,1.1,0.7,3.4,1.7,4.1
    		c0.9,0.6,2.6,0.2,2.7,1.7c0.1,1.3-1.9,0.3,0.1,1.9c0.4,0.3,1,0.8,1.3,1.2c0.8,0.9-0.1,0.6,0.1,1.2c0.2,0.6,0.7,0.8,1,1.3
    		c0.5,0.8-0.3,1.3,0.1,2.1c0.5,0.9,0.6,1.7,1.6,1.9c1.4,0.3,3.1-0.3,4.2,0.8c0.3,0.3,0.1,2-0.2,2.2c-1,0.9-2.8,0.5-3.9,0.5
    		c-2.5,0.1,0.2,1.1,1.3,1.2c2.5,0.2,6.1,2.8,3.2,5c-0.3,0.2-0.8-0.1-1,0.3c-0.4,0.6,0.8,1.3,0.9,1.9c0.1,0.5,0.7,0.6,1,1
    		c0.5,0.6-0.3,1.9-1,1.9c1,0.8,3.9,3.3,3,4.8c-0.4,0.7-1,0.9-0.5,1.8c0.1,0.3,0,1.1,0.3,1.3c0.5,0.2,0.7,0.6,0.9,1.2
    		c-1.1-0.2-2,2.8-2,3.6c0.1,0.9-0.2,1.8,0.4,2.7c0.5,0.7,3.1,2.1,2.9,3.2c-0.2,0.7-1.1,0.7-1.3,1.3c-0.3,1,1.6,2.2,0.7,3.5
    		c-0.5,0.7-1.4,0.3-1.7,1.1c-0.4,1-0.1,2.7-2,2.4c0.2,1-0.2,2.4,0.7,2.9c1.2,0.7,1.7,2.3,1.8,3.1c0.1,1.2,2,2,2.7,2.9
    		c0.6,0.9,1.1,2.2,2.1,2.7c0.7,0.3,1.1-0.2,1.7-0.6c0.7-0.5,1.2-0.2,1.9-0.2c1.6,0,1.8,0.2,2.8,1.7c0.7,1.1-0.3,2.3,0.7,2.9
    		c0.9,0.5,1.5,2.4,1,3.2c1.5,0.2,3.5,1.9,4.3,3c1,1.3,5.5,0.5,6.8,0.4c5.6-0.5,11.3,0.8,17.4,3c-1.5,0-2.8-0.6-4.2-0.9
    		c-2.6-0.5-1.3,0.9-2.1,2c-0.7,0.9-2.1,0-3,1c-1,1.1-2.8,0.9-4.2,1.3c0.9,1.2,0.9,2.6,1.2,4c0.3,1.3,1.1,2.1,1.7,3.2
    		c1.7,3-2.3,2-3.8,1.7c-1.8-0.4-5.9-1.5-6.6-3.5c-0.7-2,3.2-0.8,1.5,0.5c0.9,0,0.6-1.1,1-1.6c0.3-0.3,2.3,1.4,1.3,2.4
    		c1.6-0.5-1.3-3,1.7-3.5c0.5-0.1,0.6-1.5,0.6-1.8c0-1-1.7-0.8-2.3-1.3c0,1.1-1.5,2-2.2,2.6c-1.3,0.9-2.4-0.5-3.6,0.3
    		c0.9-0.2,1.6,0.6,2.2,1.1c-0.5,0.1-1,0.1-1.5-0.2c0.2,0.5,0.1,1.1-0.2,1.5c0.9,0.1,1.6,0.8,2.2,1.4c-2.2,0.6-5.9-1.8-7.4-3.2
    		c1.1,0.5,2.6,0.5,3.7,0.4c-0.2-1-2.5-1-2.5-2.9c0-1.1,1.9-1.6,1.5-0.2c1.3-0.4,1.4-0.2,2.3-1.3c0.9-1.1,3.3,0.5,4.5-0.3
    		c-0.8-1-6.1-1.8-6.3,0c-0.7-0.2-1.2-0.6-0.1-1c-0.7,0-3.4,0.1-2.8,1.4c0.5-0.5,1-0.5,1.5-0.1c-0.2,0.6-0.7,1-1.2,1.2
    		c0.4,0.6,1.5,2.2,0.1,2.3c-0.9,0.1-2.9-1.1-1.1-1.6c-0.9,0.3-1.6-0.5-2.2-0.9c0.5,0.1,1,0.1,1.5,0c-0.3-0.1-0.5-0.3-0.8-0.4
    		c1-0.6-0.1-1.8,1.2-1.4c-1.1-0.4-1.1-0.6,0-0.5c-0.5-0.2-1-0.2-1.5,0c0.5,0.4,0.7,1,0.3,1.5c-0.2-0.5-0.7-0.9-1.3-0.9
    		c0,0.6-0.3,1.1-0.9,1.4c0.3-0.7,0.1-1-0.7-0.8c1.6-1.3-2.3-3.8-2.6-5c0.7,0.8,1.9,1.5,3,1.5c0.5,0,2.4-1.7,1.9,0.2
    		c0.8-0.4,2.2,0.3,1.1-1c0.4,1.7-0.7-0.5-0.7-1.4c0.8,0.1,1.6,0.9,2.2,1.4c1.1,0.9,0.4,1.7-0.3,2.7c0.7-0.5,1.5-0.1,2.2-0.1
    		c-1-0.5-0.2-1.7-1.1-2.2c-0.5-0.3-2.3-1.7-0.7-1.9c-1-0.8-1.4-2-2.5-2.6c-1.2-0.7-2.6-0.7-3.6-1.7c-0.2,0.2-0.3,0.4-0.4,0.7
    		c0.9-0.1,5.3,1.1,5.2,2.3c-0.1,1-4.6-1.3-5.3-1.3c0.5,0.6,1.1,0.5,1.7,0.9c0.6,0.5,0.2,1.3,1.2,1.4c-0.4,0.4-0.6,0.9-0.7,1.4
    		c-0.7-1-1.2-2.2-2.2-2.9c0.8,1,1.9,2.3,1.9,3.7c-1.1-1-2.6-1.4-3.2-2.9c0.5,0.4,1,0.7,1.5,1.1c-0.4-0.7-1.6-1.1-1.4-2.1
    		c-0.5,0-1,0.5-1.4,0.4s-0.4-0.8-0.9-0.9c-0.6-0.1-1.4-1.7-1.5-2.2c1.3,0.3,2,1.4,3,2.2c-1-1.1-1.6-2.5-2.7-3.5
    		c1.6,1.4,0.4-0.4-0.4-0.5c-0.1,0.2-0.1,0.5-0.2,0.7c-0.6-0.2-1.1-0.4-1.6-0.9c1,1.6-1.4-1.1-1.8-1.4c-0.5-0.4,0.7-0.6,0.8-0.7
    		c0.3-0.1,0.6-0.1,1-0.1c1.2,0.1-1.2-1.4,0.7-0.4c-0.6-0.6-0.6-1.7,0.4-1.7c-0.8,0-1.8-0.8-1.1-1.7c-0.8,0.7-0.1,2.2-0.6,3.1
    		c-0.6,1-3.1-2.4-3.7-2.7c0.5,0.2,1,0.4,1.5,0.8c-1.5-1.4,0.1-0.4,0.9-0.4c-0.9-0.8-3-0.8-3.4-1.9c0,1.7-1.9-0.8-2.9-0.9
    		c1-2,4.3,1.1,5.9,0.9c-1.4-0.3-4.4-1.2-4.6-3c1.2-0.1,2,1.2,3.2,0.7c-0.8,0-0.9-0.5-0.6-1.2c-0.7,1.7-4.2-0.9-1.7-1.4
    		c-0.6,0.1-2.5-0.5-1.4-1.2c1.5-0.9,2.6,0.7,3.9,1.2c-0.9-0.6-2-1.2-2.8-1.7c-0.4-0.3-1.1-0.3-1.1-1c0-0.7,0.7-0.8,1.2-0.5
    		c-0.8-0.4-2.2-3.3-0.6-2.4c-0.5-0.3-1-0.3-1.5,0.1c0.4,0.4,0.4,3.3,0.1,3.7c-1.1-1.1-2.9-3-2.8-4.6c0-0.4-1.3-1.1-0.7-1.6
    		c0.5-0.4,1.6-0.4,2.1-0.2c-0.2-0.2-3.9-0.6-2.6-1.8c0.6-0.5,2,0.2,2.6,0.4c-0.6-0.3-0.9-0.9-0.4-1.5c-0.5,0.1-3.3-0.2-1.5-0.8
    		c-1.8,0.8-2.7-2.5-4.5-1.9c-0.3-1.9,2.6,0.3,3.1,0.8c-0.3-0.5-0.7-1-1.1-1.4c1.3-0.2,2.4,0.5,3.6,0.8c0.5,0.2,1.1,0,1.6,0.3
    		c0.7,0.5,1.5,0.2,2,0.7c0.3-0.4,0.2-0.8-0.1-1.2c-0.3,1.6-2.7-0.6-2.8-1.4c0.9,0.7,2,0.5,3,0.8c-0.4-0.2-0.8-0.2-1.2-0.2
    		c-0.8-0.1-1.2-0.9-1.8-1.4c-0.5-0.4-1.1-0.2-1.5-0.8c-0.4-0.5-0.1-1.1-0.6-1.5c0.6,0.8,0.8,2.8-0.8,2.5c-1.4-0.3-4.3-0.1-5.3-1.1
    		c0.6-0.2,0.8-0.6,0.4-1.2c1.4-0.4,2,1.3,3.3,1.6c-1.1-0.2-0.4-1.3,0.4-1c-0.2-1.3-0.9,0-1.7-0.5c-0.6-0.3-0.8-1-1.5-1
    		c-0.7,0,0.3-3.4,1.5-1.4c-0.2-0.6,0-1,0.7-0.9c-0.1-0.4-0.2-0.7-0.4-1c-0.1,0.9-1,1.2-1.4,0.3c0.5-0.2,1-0.3,1.4-0.4
    		c-0.5-0.7-1.5-0.8-2.1-1.2c-1.4-0.9-1.7-1.3-3.3-0.4c0.6-0.2,1.2,0,1.5,0.5c-1.2-0.3-2.4-0.5-3.5-1.1c-1.9-1.1-0.6-1.5-1.3-2.8
    		c-0.7,1.4-3.5,1.6-3.6,2.8c1.3-2.1,3.4,3.2,0.5,0.9c-1.9-1.5-0.2-5.5,2.1-4.5c-0.7-0.1-0.9-0.6-0.5-1.2c1.2,0.9,0.6-1.3,1.8,0
    		c-1-0.7,0.3-1.6-0.8-2.3c0.7,2.8-2.8,0.5-0.7,0.6c-0.7-0.1-1.6-0.3-1.8-1.2c1,0.3,1.9-0.6,2.9-0.4c0.9,0.2,1.1,1.1,2,1.2
    		c-1.3-0.9,0.1-1.2,0.8-1.3c0.9-0.1,0.6,1,1.6,1.1c-0.5,0.7,0.6,2,0.3,0.5c2.1,1-1.1,2.4-2.3,1.8c0.8,0.5,3.5,0.9,4.5,0.9
    		c-0.5-0.2-0.9-0.8-0.9-1.4c0.6,0.4,1.4,0.6,1.7,1.4c0.3,0.8-0.6,1.5-0.1,2.4c1.4-0.2,1.8-3.6,1.6-4.7c-0.2,0.8-0.4,1.6-0.9,2.2
    		c0.1-1.5-2-5.4,1-5.3c-0.9-0.5-1.7,0.2-2.2,0.9c-0.2-0.7-0.6-1.6-0.4-2.3c0.2-0.8,1.2-0.8,1.4-1.7c0.5,0.7,1.3,0.9,2.1,1.3
    		c0.9,0.5,1.2-0.5,0.4-0.5c-0.6,0-1-0.2-1.5-0.6c-0.9-0.6-1.9-0.1-2.8-0.6c1.6-1.2-2.2-2.4,0.8-2.7c2.1-0.2,3.1-3.2,2-4.9
    		c-0.7,1.2-5.3-1.2-5.2-2.4c0.2-0.2,0.4-0.3,0.8,0c-1.3-1.4,1.3-2.3,0.5-3.5c-0.2,0.9-1,0.9-1.1,0c0-1.2-0.8-0.7-1.3-1.8
    		c-0.5-1,0-1.3,0.2-2.2c0.1-0.5-0.8-1.9,0.6-1.6c0-0.9-0.6-1-1.1-1.6c-0.4-0.5-0.3-1.1-0.6-1.6c-1.1-1.9,0.3-0.8,1.5-1.1
    		c-1.1-0.2-2.3-1.4-2.6-2.4c1.6-0.6,2.4,0.8,3.2,1.9c-0.2-1.5-1.2-3-2-4.3c-1.5,1.4-3.6-0.6-1.8-1.8c1.1-0.8,2.7-1.3,1.2-2.7
    		c0.9,3.9-2.2,1.1-4,0.6c-1.4-0.3-0.4,1.8-1.2,2.3c-1.1,0.7-5.3-0.5-3-1.5c-4-0.1-4.5-6.3-4.9-9c-0.2-1.2-1-3.9-0.4-5.2
    		c0.6-1.2,1.4-0.1,1.1-1.7c-0.2-1.1,0.3-2.1,0-3.2c-0.4-1.6-0.9-2.5-2-4.2c-1.2-1.7-2.6-3.3-3.2-5.3c-0.4-1.2,0-2.1-1-3.2
    		c-0.6-0.7-1.3-1.4-1.7-2.2c-0.4-0.7,0.1-1.1-0.1-1.6c-0.5-1-1-1.6-0.8-2.8c0.8,0.6,2.3,1,2.8-0.3c0.5-1.6-1.1-2.8-0.5-4.4
    		c0,0.6,0.4,0.9,0.9,0.9c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.7,0.4-1.5,0.4-2.3c0-1.5-0.9-2.5,0-4c0.7-1.2-0.6-1.8,0-3.2
    		c0.4-1,1.2-2.8,1.4-3.9c0.4-2.7-0.8-5.5-0.7-8.1c0.1-1.5,1.4-2.7,1.3-4c-0.1-1.1-1-2.5-1.4-3.5c-0.4-0.9,0.2-2.1,0.5-2.9
    		c0.4-0.9-0.3-2.3-0.4-3.1c-0.1-0.6,0.3-1.1-0.1-1.7c-0.5-0.6-0.9-1.2-1.1-1.9c-0.5-1.6-0.8-3.1-1.4-4.7c-1.2-2.9-2.6-6-3.1-9.2
    		c-0.3-1.6,0-1.7,1.2-2.5c0.9-0.6,0-2,0.6-2.8c0.6-0.8-1.2-5-1.9-5.5c-0.8-0.7-1.8-2.5-1.2-3.5c0.8-1.4,0.9-2.7,1.4-4.2
    		c0.3-1-0.4-2.7-0.4-3.8c0-1.7,1.3-2.8,0.7-4.5c-1-2.6,0.4-5.9,0.4-8.7c0-2.4-1.5-4.5-1.4-6.8c0.1-1.3,1.5-1.6,1.4-2.8
    		c-0.1-1.7-1.3-3.4-1.7-5c-0.4-1.8-0.1-3.5-0.3-5.2c-0.1-0.9-0.5-1.5-0.2-2.4c0.2-0.7,0.5-1.5,0.3-2.3c-0.2-0.9-1.6-0.4-1.8-1.4
    		c-0.2-1.1,0.1-2.3,0.2-3.3c1.7,1.5,1.6-3.4,1.6-4.2c-0.1-4.2,0.8-9.1-0.8-13c-1.7-4.2-0.6-8.9-2.2-13.2c-0.7-1.8-1.4-4-1.7-5.9
    		C772.4,860.3,773,857.9,771.6,857.3"
          />
          <path
            id="path6472"
            class="country"
            d="M856.2,1156.9c-0.5-0.7-1.3-2.1-0.7-2.9c0.2,1,1.7,0.9,1.5-0.2c-0.1-0.6-1.8-1.1-2.2-1.3
    		c0.8-0.8,1.9,0.2,2.8,0.4c1.9,0.4,1-1.8,1.6-2.6c0.6-0.8,2.3,1.1,2.9,1.3c1.1,0.3,2-1,3.1-0.4c1.1,0.7,1.2,2.3,1.9,3.3
    		c1,1.4,1.7,3,2.3,4.6c1.6,3.7,3.9,7.1,5.2,10.8c-0.7-0.4-1.5-0.5-2.2-0.9c0.4,0.4,0.9,0.7,1.4,0.9c-1,1.1-3.5,0.4-4.7,0
    		c-0.6-0.2-1.3-1.2-2-1c-0.9,0.3-1.1,0.5-1.8-0.2c0.2,0.3,0.3,0.6,0.5,0.9c-0.5-0.6-2.9,0.6-2.1-1c-0.1,0.8-2.6,1.2-3.3,0.9
    		c0.5-0.4,0.9-0.8,1.4-1.1c-0.9-0.5-2.1,0.5-3,0.7c0-0.6-0.3-1.1-0.7-1.4c-0.3,1.8-1.6-0.3-2.2-0.7c1,1.4-1,0.9-1.7,0.8
    		c-0.5-0.1-1.2,0.5-1.7,0.3c-0.5-0.2-0.7-1.1-1.1-1.4c0.9-0.1,1.5,0.6,2.3,0.8c0-0.6-0.4-1-1-0.9c0.6-0.2,1.1-0.1,1.5,0.4
    		c-1.6-1.6,3.2,0.1,3.7-0.5c0.4-0.5,0-1.4,1.2-1c0.9,0.2,0,2.7,1.4,2.3c-0.5-0.3-0.8-0.8-0.7-1.4c1.3,0,2.5,1.6,3.8,1
    		c-1.2-0.7-2.5-1-3.6-1.9c-1-0.8-2.3-0.2-3-1.4c2,0.3,4.3,0.9,6,2.2c-0.6-0.4-0.8-1.3,0.2-1.1c0.9,0.1,1.6,1.1,2.3,1.6
    		c-0.2-0.6-0.6-1-1.1-1.3c0.9-0.3,2,0.9,2.6,0.5c1-0.7,2.5,0.9,1.7,1.9c0.3-0.6,0.8-0.8,1.4-0.4c-0.9-0.9-0.1-0.9,0.7-0.9
    		c-2.7-2.3-8-1.5-10-4.7c-1.4-2.3,4.1-1.8,3.4-3.8C863,1155.4,857.8,1159.6,856.2,1156.9"
          />
          <circle
            id="Chile_Easter_Island"
            class="countryIsland"
            cx="495"
            cy="930"
            r="0.5"
          />
        </g>
        <g id="ma" class="countryGroup">
          <path
            id="ma-"
            class="country"
            d="M1207.6,456.9c1.4-0.5,3-0.6,4.4-0.9c2.7-0.6,5.5-1.5,7.5-3.4c2-1.9,4.1-3.4,6.2-5.2
    		c2-1.7,3.6-4,5.2-6.1c1.1-1.4,3.6-5.5,1.5-7c-0.8-0.6-1.4-0.6-0.9-1.7c0.5-1.1,0.5-2.2,0.5-3.4c0.2-3.3,1.4-4.8,3.4-7.3
    		c1.2-1.4,0.8-2.9,1.3-4.5c0.3-1.2,1.3-2.1,2.2-2.9c1.9-1.7,3.4-3.8,5.7-4.9c2.6-1.2,5.3-2.1,7.8-3.5c4.9-2.8,7.2-9.5,9-14.5
    		c0.2-0.5,0.8-2.9,1.6-2.8c0.9,0.1,1.8-0.3,2.6-0.7c1-0.5,1.2,0.8,1.4,1.5c0.6,1.3,1.3,2.3,2.5,3.1c2.5,1.8,4.5,1.3,7.4,0.9
    		c2.3-0.3,5.3,1.1,6.8-1.6c0,0.9,0.2,1.1,0.3,1.8c0.1,0.7,1.5,1.1,0.3-0.1c0.6,0.9,1.8,1.3,2.8,0.8c1-0.5,1.7,0.5,2.3,1.1
    		c0.7,0.6,1.9,1,2.3,1.9c0.5,1-0.5,1.9,0.9,2.5c-0.8,0.6-0.5,1.7-0.4,2.5c0.2,1.3-0.2,2.4,0,3.6c0.1,1,0.8,1.6,0.5,2.7
    		c-0.4,1.6-0.2,2.2,0.6,3.6c0.4,0.7,0,1.4,0.4,2c0.6,1,1.5,1.4,2.3,2c1.6,1.2-3.1,3.3-0.6,4.2c-2.6,0.7-11.7-2.4-13,1.1
    		c-0.7,1.8-2.5,2.5-4.5,2.4c-1.5-0.1-3.3,2-1.3,2.5c-1.7,1.6,1.4,2.7,0.7,3.5c-0.9,0.8-2.3,0.5-3.3,1.2c-1,0.7-2,1.8-3.2,2.2
    		c-0.9,0.3-1.8,0.3-2.7,0.7c-1.6,0.9-2.2,2.7-3.4,4c-1.3,1.5-3.8,2.1-5.7,2.1c-1.4,0.1-3-0.5-3.6,1.2c-0.6,1.8-2.4,0.5-3.9,0.6
    		c-1,0.1-1.6,0.6-2.3,1.3c-0.7,0.7-1.4,0.6-2.3,0.9c-1,0.4-1.6,1.3-2.3,2c-0.8,0.7-1.8,1.1-2.7,1.8c-1.6,1.3-3,1.5-2.9,3.8
    		c0.2,2.3-0.1,4.8-0.3,7l-33.3,0.2C1206.1,458.8,1207.2,457,1207.6,456.9L1207.6,456.9z"
          />
          <g id="eh" class="countryGroup">
            <path
              id="eh-"
              class="country"
              d="M1175.1,511.1c0.2-1.5,0.6-2.9,1.4-4.2c1-1.6,2.7-1.6,3.3-3.5c0.3-1,0.3-2.5,1.3-3.1
    			c0.6-0.3,0.2-1.5,0.7-2.1c0.7-1,3.5-4.6,2.8-5.9c-0.5,0.3-1,0.8-1.3,1.4c1.2-2.7,4.4-4.7,6.4-6.8c2.8-2.8,2.2-5.7,3.6-9.2
    			c1.1-2.8,1.9-5.9,4.7-7.5c3-1.7,4.7-3.1,5.9-6.4c0.8-2.1,1.4-3.7,1.8-4.5l33.5-0.3l-0.5,14.6l-24.9,0.3l-1.1,21.8
    			c-1.5,1.1-3.6,1.2-5.1,2.1c-2.3,1.4-3.3,3-3.1,5.7c0.1,1.6,1.3,9.9-0.4,10.4h-27.7c-2.1,0-2.5,3.5-2.2,5
    			C1173,516.8,1174.7,513.3,1175.1,511.1L1175.1,511.1z"
            />
            <path
              id="xz"
              class="countryGroup disputed"
              d="M1204.3,513.8c1.4-3.3-0.7-9.6,0.5-13.6c2.3-3.3,4.9-3.5,7.8-4.6l0.9-21.8l25.1-0.1l0.3-14.2h-1.2
    			c0.1,0.8,0,1.7,0,2.5c0.3,0.6,0.2,1-0.1,1.3c-0.3,0.3-0.7,0.4-1.2,0.4c-1.4,0.2-3.2-0.6-4.3,0.7c-0.9,0.8-1.9,2-3.1,1.1
    			c-0.8-0.8-1.7,0.8-2.5,0c-0.9-0.6-1.8-1.1-2.9-1.2c-1.7-0.2-3.2,0.9-4.9,1.1c0.4,1,0.8,2.2-0.3,2.8c-1,1.2-2,2.5-2.4,4.1
    			c-0.5,0.3-1.2,0.2-1.8,0.4c-0.6,0.1-1.4,0.3-1.6,1.1c-0.3,1.6-0.5,3.1-0.8,4.7c-0.4,1.1-0.7,2.4-1.1,3.5c-0.4,1.1-1,2-2,2.7
    			c-1.5,1.1-3.5,1.9-4.2,4c-0.6,1.3-1.3,2.7-2.8,2.9c-1.3,0.7-3,1.2-3.4,2.9c-1.2,2.7-1.7,5.6-2.3,8.5c0,1.8-0.6,3.5-2,4.5
    			c-0.6,0.7-1.3,1.5-1.7,2.3c0.2,1.1-0.4,2.4-1.2,3.2c-1.7,0-3.4,0-5.1,0c-1.8,0-3.9-0.1-6-0.1c-3.7,0-4.1-0.3-5.4,0.2
    			c-0.4,1.6-0.6,2.8-0.3,3.3c0.6-1.9,0.7-2.5,3-2.4L1204.3,513.8z"
            />
          </g>
        </g>
        <path
          id="lr"
          class="countryGroup country"
          d="M1213.1,638.6c1-0.4,1.1-2,1.8-2.8c1.4-1.4,3-2.6,4.3-4c0.8-0.9,0.2-2.2,0.9-3.1c1-1.2,1.6-0.5,2.2-2.4
    	c0.3-0.7,0-0.9,0.2-1c-1.4-0.5,1.4-0.4,2.1-0.2c0.7,0.2,1.1-0.5,1.7-0.5c0.9,0,1.6,1.1,2.2,1.6c-0.4,0.5-0.2,1.1,0,1.6
    	c0.2,0.4-0.1,1.4,0.3,0.9c0.5,2.1,0.2,4-0.4,6c1.4-0.5,2.7-0.2,2.8,1.4c1.6-0.7,2.3-1.1,2.8-2.8c0.2-0.5,0.3-1.5,1-1.6
    	c0.7-0.1,1.4,1.5,1.7,2c0.9,2,0.9,3.1,0.6,5.3c-0.2,1-0.8,1.8-1.2,1.9c-0.6,0.2-0.9,0.8-1,0.9c0.1,0.2,0.5,0.6,1.6,0.7
    	c-0.6,2.1,3.7,0.4,4.1,2c0.2,0.6-0.1,1.3,1,2.1c0.9,0.6,1.8,1.4,2.2,2.3c0.8,2.2,0,3.5-0.3,4.6c-0.7,2.6-1.7,4.4-0.5,6.8
    	c-1.2,0.2-2.1-0.3-3.1-0.8c-1.3-0.7-2.8-0.8-4-1.8c-2.1-1.7-4.8-2.5-6.8-4.4c-2.2-2.2-3.9-4.7-6.1-6.8c-0.7-0.7-5.8-2.4-4.6-3.6
    	c-0.9,0.1-2.1-1.3-2.9-1.7C1214.1,640.7,1214.3,639.8,1213.1,638.6"
        />
        <path
          id="cf"
          class="countryGroup country"
          d="M1481.1,617.1c-0.1,2.1-1.9,2.3-1.6,3.8c0.3-0.1,0.5-0.2,0.8-0.3c0.2,0.8-0.2,1.5-0.5,2.2
    	c1.6,0.3,3.5-0.1,5,0.7c0.4,0.1,0.6,0.4,0.6,0.9c-0.4,1.9-0.1,2.5,1.4,2.8c1,0.2,2.4,0.1,3.2,0.7c1,0.8,1.5,1.6,2.6,2.4
    	c1.7,1.3,0.4,2.2,0.4,2.9c0,1.1,1.5,1.8,2.3,2.3c0.9,0.5,1.8,1.1,2.8,1.6c1.8,0.9,1.2,1.4,1.9,2.4c0.5,0.7,2.4,1.1,1.9,2.2
    	c-0.7,1.4-0.1,1.7,0.8,2.5c0.8,0.8-0.1,1.4,1.3,2.3c0.9,0.6,1.8,0.9,2.8,1.4c1.6,0.8,2.2,1.8,2.1,3.5c-0.1,1.6,1,2.5,1.5,3.8
    	c-1.3-1.4-2.9-1.9-4.1-0.8c-0.5,0.5-1.4,0.4-2,0.3c-0.3-0.1-0.6-0.1-0.8,0.1c-0.7,0.3-0.9-0.3-1.4-0.5c-1-0.4-1.8-1.2-2.9-0.9
    	c-2.1,0.6-2.2-0.8-3.6-1c-0.9-0.1-1.5,0.4-1.3,1.3c0.3,1.4-1.4,1.6-2.4,1.9c-1,0.3-2.3,0.8-3.2,0.1c-0.5-0.4-1.6-1.9-1.8-0.9
    	c-0.4,1.3-3.1,2.2-4.2,2.4c-2.1,0.4-3.4,2.3-4.3,1.4c-1-1.1-2.1-2.6-3.8-0.7c-0.5,0.6-0.4,1.5-0.8,2.1c-0.5,0.9-0.6,2.1-1.6,2.7
    	c-1.3,0.9-4.4-1.1-6-1c-0.9,0-1.1,0.3-2.2,0c-1.1-0.4-1.5,0.2-2.3-0.8c-0.8-1-2.4-0.1-3.6-0.3c-1.4-0.3-1.4-2.2-2.2-2.9
    	c-1.3-1.3-3.4-2.2-4.7-3c-1.8-1.1-4.4,1.2-5.4,2.8c-0.5,0.9-1.3,1.8-1.7,2.8c-0.3,0.8-1.4,0.6-1.7,1.1c-0.5,0.7,0.7,2.1,0.6,3
    	c-0.1,1.2-0.2,1.7-0.2,4.4c-1-1.7-3.5-0.2-5.2-0.5c-0.8-0.1-2.1-0.7-2.8-1.1c-1.4-0.6-2.5,0.7-3.9,0.9c-2,0.4-3.7-0.3-4.1,2.2
    	c-0.2,1.4-0.1,2.8-0.3,4.2c-0.3,1.8-1.5,3.5-2.1,5.2c-0.4-2-0.8-3.7-1-5.8c-0.1-2-2.3-1.9-3.3-3.1c-0.5-0.6-5.9-6.9-3.5-7
    	c-1.3-0.9-1-2.9-2.3-3.9c-1.7-1.3-1.5-3.3-1.5-5.3c0-0.8-1-1.3-1-1.8c0-0.7,0.5-1.5,0.5-2.3c0-0.9,0-1.8-0.2-2.6
    	c-0.2-0.8-1.2-0.4-1.5-1.4c0.5-0.8,1.2-1.3,1.9-1.9c1.1-0.9,1.2-1.9,1.9-3.1c1.2-1.9,2-4.8,3.4-6.7c1.7-2.3,1.9-0.7,4.1-1.3
    	c1.1-0.3,2-0.6,3.1-0.9c1.1-0.3,0.8-1.5,1.9-1.9c0.3,1.2,1.3,1.9,1.9,2.9c0.6-1.2,2.4-1.8,3-2.2c1.8-1.4,4.6-1.9,6.7-1.9
    	c1.9,0,3.7,0.5,4.6-1.2c0.6-1.2,3.3-3.4,3.1-4.5c-0.1-0.7-1.7-0.5-1.8-1.5c-0.1-1.4,4.2-1.5,5.4-1.4c3.6,0.1,7.5-1.3,9.8-4.2
    	c1.2-1.5,2-3.1,3.6-4.3c0.8-0.6,3-2.6,2.6-3.5c-0.6-1.2,0.4-2.3,1.8-2.7c1.6-0.4,2.8-2.4,4.7-2.4c2.2,0,2.8,1.4,3.8,2.6
    	c1.6,1.9,3.2,3.3,4.2,5.3C1481.2,613.4,1481.3,615,1481.1,617.1L1481.1,617.1z"
        />
        <path
          id="sk"
          class="countryGroup country"
          d="M1423.5,279.2c0.8-2,1.8-1.7,3.6-1.7c0.7,0,3.8-1.5,3.8-2.2c0.3-2.5,6.3-4.5,5.8-2.6
    	c0.8-0.1,2.4-1.8,3.3-1.5c1.3,0.5,2.7,1.9,2.4,2.5c1.2,0.9,1.6,0.3,2.8-0.6c0.4-0.3,1.7-0.7,2.3-0.5c2.4,0.9,3.4-0.2,5.2-0.1
    	c2,0,3,0,4.7,1.6c0.7,0.6,2.4,0.8,3,1c0.4,0.1,0.4,0-0.2,1.5c-0.5,1.4-1.8,2.7-1.9,3.9c0,0.2,0.5,0.6-0.3,0.6
    	c-2.6,1.4-3.9-1.4-4.5-1.3c-0.6,0.2-0.7,0.5-1.4,0.3c-0.8-0.2-1.8-0.5-2.6-0.6c-2-0.2-2.4,0.2-2.6,2.1c-0.1,1.3-3.2,2-4.3,1.5
    	c-2.6-1.4-1.5-0.3-2.6,0.7c-0.9,0.8-5.8,0.3-3.5,2.2c-1.6,0.2-3.2,0.8-4.9,0.6c-0.6,0-1.6,0.1-2.4-0.1c-0.8-0.2-1.6-0.8-2.3-1.2
    	c-1.1-0.5-2.4-1.4-3-2.5c-0.2-0.5,0-1-0.2-1.5C1423.5,280.3,1423.6,280,1423.5,279.2"
        />
        <path
          id="lt"
          class="countryGroup country"
          d="M1446.8,217.5c1,0.2,1.3-0.9,1.9-1.4c0.8-0.5,2.1-0.7,2.9-0.9c2.2-0.5,4.9-0.5,7.2-0.4
    	c1.3,0.6,3,0.1,4.2,0.4c1.5,0.1,4.7,0.8,6.1-0.1c1.9-1.3,2,0.1,3.2,1.1c0.7,0.6,1.7,0.5,2.6,0.6c1.2,0.2,2.3,0.7,3,1.6
    	c0.5,0.5,3.6,2.6,4.2,2.2c-0.5,2.2-0.3,2.7,1.6,3.2c-1.2,0.6-0.3,1.6-2,1.2c-1.3-0.3-1.4,1-2.4,1.5c-2.3,1.3-0.9,2.9-2.8,5
    	c0.5,0.2,1.4,0.3,1.6,0.9c0.2,0.8-0.9,1-1.4,0.8c0.6-1.5-1.7-1-1.8-0.7c-0.6,1.4-2.3,0.4-3,1.8c-0.2,0.5-1.4,0.5-1.9,0.8
    	c-0.9,0.5-2.5-0.1-3.4,0c-0.5,0-2.6,0.3-2.8-0.5c-0.2-1-1.2-2.3-2.1-2.7c-1.3-3.4-0.4-3.2,0.2-4.5c0.1-0.3-1.8-1.8-2.1-1.8
    	c-0.8,0-4.8,0.2-6.1-0.8c-1.4-1.1-3.4-1.5-3.6-0.4c0-1.1-1.7-2.5-2.2-3.4C1447.1,219.9,1447.2,218.7,1446.8,217.5"
        />
        <path
          id="zw"
          class="countryGroup country"
          d="M1491.9,852.7c1,0.2,1.9,0.4,2.9,0.1c0.9-0.3,1.4,1.1,2,1.5c0.7,0.4,1.8-0.6,2.5-0.7
    	c0.8-0.1,2.1,0.8,2.8,1.1c2.1,0.9,3.4-1,4.5-2.5c1.5-2,3-3.9,4.1-5.8c0.5-0.9,1.2-1.2,2-1.8c1.1-0.8,2.2-1.3,3.5-1.9
    	c2.5-1,2.9-1.3,3.1-4.1c0.1-1.4,2.4-3.1,3.6-3.6c1.2-0.5,2.6-1.1,3.8-1.1c1.5-0.1,3.1,0.4,4.6,0c0,2.9-0.2,3.1,2.9,3.1
    	c2,0,3.1,0.5,5,1.3c1.1,0.4,3.6,1.9,4.6,2.4c1.5,0.8,3.9,0.5,4.5,1.7c0.4,0.9,1.2,1,2.1,0.7c-0.2,1.3-1,2.1-0.4,3.5
    	c0.7,1.6-0.2,2.8,0.3,4.3c0.5,1.5-0.6,3.1-0.3,4.7c0.2,1.1,0.9,2-0.1,2.9c-0.7,0.6-0.1,2.1-0.5,2.4c-1,0.6-1.3,0.5-1.7,1.8
    	c-0.2,0.6,0.8,0.8,1.1,1c0.2,0.2,0,1.1,0,1.3c-0.1,0.6-0.6,0.9-0.7,1.4c-0.1,0.4,0.5,2,0.7,2.5c0.2-0.1,0.5-0.2,0.7-0.3
    	c-0.1,1.6,0.5,2.2-0.4,3.8c-0.8,1.3-1.2,3.4-2.7,4.1c-1.6,0.7-0.4,1.7-0.7,2.9c-0.4,1.5-1.5,2.3-0.4,3.8c-1.2-0.9-6.6,7.1-7.6,7.8
    	c-0.9,0.6-1.4,1.9-1.8,1.9c-0.8-1.8-4.2-1-5.9-1c-1.5,0-2.8-0.6-4.2-1c-3.1-1.1-4.1-0.3-4.3-0.5c-0.8-1-0.9-0.5-1.9-1
    	c-1.1-0.6,0-1.5-0.3-1.9c-1.5-2-7.1-0.7-7.9-2.8c-0.8-2-2.4-3.9-1.7-5.9c0.9-2.8-0.4-3.4-3.2-2.8c0.5-2.6-0.2-3.7-2.6-4.4
    	c-1.9-0.6-4.5-2.7-5.5-4.4c-1.6-2.5-1.7-5.7-3.6-8.2C1494,857.9,1491.2,854.1,1491.9,852.7"
        />
        <g id="lk" class="countryGroup">
          <path
            id="path6496"
            class="countryIsland"
            d="M1907.7,619.8c0.2,0.5,1,0.8,1.4,1.2C1909,620.3,1908.5,619.8,1907.7,619.8"
          />
          <path
            id="path6498"
            class="countryIsland"
            d="M1908.8,632.8c0,3.2,1.2,16.3,7.1,14.4c2.7-0.9,6.7-2.7,8.1-5.3c1.2-2.1,1.7-5.9,0.2-8
    		c0,0.4-1.3-2.2-1.7-2.5c1.9,1.3-0.4-1.5-0.7-1.9c-0.9-1.4-0.9-3-1.6-4.5c-0.6,0.5-1.2,0.3-1.5-0.3c1.8,0.1-2.2-4.9-2.5-5.6
    		c-0.2-0.8-4.8-6-5.6-5.2c0.9,0.2,1.4,1.1,2.1,1.7c-1-0.6-2.8-3.1-4-1.5c0.5,0.8,1.5,0.9,2.2,1.5c0.1-0.9,2.5,0.5,2.9,1.2
    		c-0.4-0.3-2.8-0.9-3.5-0.9c0.5,0.7,0,0.7-0.3,1.2c-0.5,0.6,0.6,1.7-0.1,2.7c-1.5,2.4-1.2,6.7-1.3,9.6c-1.2-0.3-0.6-1.8-0.7-2.7
    		C1907.1,628.5,1908.6,631,1908.8,632.8"
          />
          <path
            id="path6500"
            class="countryIsland"
            d="M1908.8,614.7c0.1,0.5,0.1,0.7,0.7,0.6C1909.2,615.1,1909,614.9,1908.8,614.7"
          />
        </g>
        <g id="il" class="countryGroup">
          <path
            id="il-"
            class="country"
            d="M1555.7,425.2c1.4-2.5,1.8-5.4,2.4-8.1c0.2-0.9,0.2-2.2,0.9-2.9c0.5-0.5-0.3-1.5,0.2-2
    		c0.4-0.5,1.5,0,2,0.1c1.2,0.1,1-1.1,1.4-1.8c1.7,0.1,0.4,6.7,0.6,8.6c0.1,2.8,0.3,3.8-0.2,6.7c-0.2,1-0.1,1.7,0,3.4
    		c0,1.5-0.6,3.4-1,4.7c-0.9,2.5-0.2,6.9-2,9.4l-6.1-16C1554.5,426.9,1555.3,425.9,1555.7,425.2L1555.7,425.2z"
          />
        </g>
        <g id="ps" class="countryGroup">
          <g id="ps-">
            <path
              id="gaza_strip"
              class="country"
              d="M1555.7,425.2c0.9,0.5-1,2.9-1.4,3.3c-0.2-0.3-0.3-0.7-0.4-1
    			C1554.5,426.8,1555.2,426.1,1555.7,425.2z"
            />
            <path
              id="west_bank"
              class="country"
              d="M1560.7,427c-1,0.4-2.1,0.5-1.7-1c0.1-0.4,1.6-2.6,1.8-2.5c-0.4-0.1-1.6,0.3-1.8-0.5
    			c-0.5-1.3,0-2.4-0.2-2.9c0.4-0.9,0.5-3.3,2-3c0.5,0.1,2.3,1,2.4,1.5c0.2,2.6,0.2,5.5-0.2,7.3
    			C1562.7,426.5,1561.3,426.8,1560.7,427L1560.7,427z"
            />
          </g>
          <circle id="ps_" class="circle" cx="1564.3" cy="422.4" r="4" />
        </g>
        <path
          id="la"
          class="countryGroup country"
          d="M2052.8,522.5c0.3-1.2-0.1-2.8,1-3.7c0.6-0.5,2.3,0.1,2.4-1c-1.5,0.9-0.3-1.8-0.1-2.2
    	c0.3-0.8,0.3-1.2,1.2-1.6c0.7-0.3,2.1-1.1,1.9-2.1c1,0.7,0.7,3.8,2.5,2.9c1.2-0.6,1.2,0.5,2.4,0.6c1.3,0.1-0.1-1.9-0.2-2
    	c-0.3-1,0.6-1.4,0.2-1.9c-0.8-1.1-0.9-1.9-1.5-2.5c-0.8-0.9-0.9-2-1.1-2.6c-0.2-0.8,0.1-2.7,1.3-2.4c0.5,0.1,0.3,0.6,0.5,0.6
    	c2.6-0.3,1-0.2,2.2,0.1c0.1,0,0.3,0.7,1,1.5c0.9,0.9,1.8,1.7,2.8,2.6c0.7,0.7,0.8,2.1,1.8,2.4c0.7-0.7,0.1-1.7,0.4-1.3
    	c0.5,1,0.9,0.6,1.3,1.6c0.2,0.6-0.3,1.6,0.1,3.2c0.4,1.6,2.4,3.9,3.9,3.9c1.3,0,1.8,0.5,3,1.3c0.6-0.8,0.5-1.7,1.5-2.2
    	c1.3-0.7,2.2,0.1,3.3,0.9c0.6,0.5,1.6,0.9,1.8,1.8c0.1,0.9-1.2,0.8-1.4,1.5c0.7,0.3,2.1,0.1,2.3,1c0.2,0.8,0.6,0.8,1.1,0.9
    	c2.7,0.4,1.6,2.7,0.3,3c1.5,0.4-1.4,2.2-2.4,2c-0.2,0-2.8-0.8-3-0.3c-0.2,0.7,1.4,1.6,0.9,2c-0.5,0.4-2,0.9-0.5,1.6
    	c1,0.5,0.7,0.1,1.7,0.8c1.1,0.6,3.2,2.4,4.1,3c0.7,0.5,4.8,0.9,3.9,1.8c-0.3,0.3-0.6,0.6,0.5,2.2c0.5,0.8,1.4,1.6,2.1,1.7
    	c0.8,0.1,1.2,1.3,1.5,1.9c0.6,1.2,1.4,2.1,2.3,3.1c0.5,0.6,3,2.7,3.5,2.9c1,0.3,2,2.6,2.2,2.8c0.7,0.9,0.5,2.3,1,3.1
    	c0.2,0.3,0.7,1.3,1,1.3c0.4,0.1,0.8-0.3,1.2,0c0.8,0.5,0.5,0.9,1.5,1.2c0.4,0.1,1.8,1.9,3.4,2.4c-0.4,0.9-1.6,1.2-1.9,2
    	c-0.4,1,2.3,2.1,2.8,2.7c0.9,1.1,1.9,1.5,1.4,3.1c-0.2,0.5-0.9,1-0.9,1.3c0,0.4,0.2,0.2,0.6,1.4c0.4,1.4-1.4,3.1-3.9,3.8
    	c-2,0.5-2.9-1.5-3.8-1.7c-0.6-0.1-0.2,0.6-0.7,0.8c-0.4,0.2-1.3-0.1-1.3,0.3c0,0.6-0.6,0.8-1.1,0.6c-0.1,0-0.7,0.6-0.5,0.9
    	c0.5,0.8,1.8,1.8,0.8,2.8c-0.5,0.5-1.7,0.3-2.3-0.6c-0.4-0.5-2-0.9-3-0.9c-1.1,0.1-2.3-1.3-1.4-2.1c0.7-0.6,1.8-1.2,1.7-2.3
    	c-0.1-1,0.3-2.2-0.1-3c-0.2-0.4-0.8-0.6-0.8-1.4c0-0.6,0.5-1.1,0.7-1.6c-0.3,0.1-0.5,0.1-0.8,0c-0.1-0.8,1.7-1.9,0.5-3.1
    	c-0.7-0.7-3.3-1.5-1.6-2.6c-2.8-1.1-5.7-3-6.1-6.3c-0.4-2.6,0.6-6.1-2.1-7.7c-2.7-1.6-3.5-5.7-6.6-6.4c-0.4-0.1-5.6-1.4-4,0.6
    	c-0.9,0.2-1,2.4-1.8,2.5c-0.2,0-1.1,0.3-1.6,0.7c-1,0.8-1.6-0.6-1.9-0.6c-1.6-0.2-2.1-1.3-3.1-1.9c-1.1-0.7-1.3,2.1-2.6,1.1
    	c-0.1,2-2.5,3.4-3.6,4.7c-0.5,0.6-1.3,0.3-1.9-0.3c-0.6-0.7-0.1-1.9,0.2-2.6c0.4-1.1,0.6-1.8,0.4-3c-0.1-0.7-0.8-1.9-0.7-2.5
    	c0.2-0.4,0.4-0.7,0.7-1c0.4-0.6,0.1-0.6,0-1.3c-0.1-1.4,0.5-2.1-0.1-3.5c-1-1.8,0-4.6-3.1-3.8c-0.4,0-0.6,0.1-0.8,0.4
    	c-0.3,0.7-0.4,0.1-1,0.2c-1,0.2-1.7-0.5-2.2-1.5c-0.7-1.4,1.3-2.8,0.4-3.9c-0.3-0.4-1.4-1.8-1.8-2c-0.7-0.4-0.9,0.6-1.7,0.8
    	C2052.8,523,2052.8,522.7,2052.8,522.5"
        />
        <path
          id="kp"
          class="countryGroup country"
          d="M2182.2,351.8c0.3-1,0.8-1.7,1.6-2.5c0.5-0.5,1.3-0.3,1.5-0.9c0.3-1.3,3-1.4,3-2.8
    	c0-0.8,0.9-0.4,1.4-0.6c0.9-0.2,1.1-1.7,1.2-2.3c0.5-1.8-0.5-4,0.8-4.6c0.5-0.3,1-1.1,1.7-0.8c1,0.5,1.6,1.3,2.6,1.9
    	c1.7,0.9,3.4,1.2,5.2,1.4c1,0.1,3.1,0.2,2.4-1.4c-0.3-0.7-1.2-0.8-1.8-1.3c-0.7-0.6-1.3-1.5-1.9-2.2c1.6-0.4,3.4-0.3,5.1-0.4
    	c2.2-0.2,1-1.6,1.4-2.7c0.4-1,1.7-0.3,2.4-0.6c1-0.5-0.7-3.9-0.9-4.6c1.5-0.1,2.8,0.2,3.6,1.6c1.1,1.9,4,2.4,5.4,4.2
    	c-0.6,0.5-1.2-0.2-1.9-0.1c-0.4,0.1-0.7,1.2-0.8,1.5c-0.5,1.2-1.6,3.2-1.3,4.5c0.2,0.9,5.1,5.6,2.9,6.7c-0.7,0.4-1.6,0.6-2,1.4
    	c-0.2,0.5,0.2,1.2-0.2,1.6c-0.6,0.6-1.3,0.6-1.7,1.4c-0.4,0.6-0.6,1.4-1.2,1.9c-0.6,0.6-1.6,0.4-2.3,0.7c-0.6,0.3-1.1,0.8-1.6,1.3
    	c-0.7,0.6-1.4,0.9-0.5,1.7c0.7,0.6,0.9,1.8,1.1,2.7c-0.3-1.2-0.8-0.5-0.7,0.3c0.1,1.6,1.9,1,2.9,1.6c1.6,1,7,3.2,6.8,5.5
    	c-0.4,3.4-6.6,0.1-8.2,2.7c-0.6,0.9-0.6,3.3-1.7,3.1c-0.6-0.1-3.8-1.2-2.6,0.6c-0.7-0.4-1.9-0.6-2.4-1.1c-0.8-0.7-1.6-1.3-2.8-1.3
    	c0.4,0.4,0.9,0.6,1.4,0.9c-0.7,0.1-0.9,1.4-1.8,2.2c-0.6-1,0.6-0.6,0.5-1.2c-0.1-0.8-1.2-1.2-1.1-0.1c-0.6-1.2-2.2,0-2.9-1.2
    	c1.7,0-0.4-1.2,1.3-0.6c-0.9-0.9-2.7-0.4-3.9-0.6c0.1-0.6,0.6-0.9,1.2-0.6c-1.4-1-0.1-2.4-1.1-3.5c1.1,0.8,0.6-0.6,1.9-0.7
    	c0.8,0,1.9,0.1,2.6,0.5c-0.9-1.8-4.4,0-4.5-2.6c0-1,0.5-2.5,0.3-3.5c-0.3-1-1.3-0.9-0.9-2.1c-1.8,1.2-4-1-5.6-1.7
    	c0.4,0.5,0.3,1-0.1,1.4c-0.2-1-1.1-1.4-1.9-1.7c-0.5-0.2-2.7-1.8-1-1.9C2182.8,352.3,2182.5,352,2182.2,351.8"
        />
        <g id="gr" class="countryGroup">
          <path
            id="Greece_mainland"
            class="country"
            d="M1447.7,355.4c0.5,0.1,1.2,0.2,1.7,0c1.1-0.5-0.7-1.2,0.9-1.1c-0.6-1.4-1-1.4,0.4-2.1
    		c0.9-0.5,1.5-1.1,1.8-2.1c0.4-1.3,0.9-1.2,1.7-2.1c0.8-0.9-0.4-2-0.4-2.9c0.6,0.4,1.6-0.2,2.6-0.1c2.2,0,3.4-1.3,5.2-2.1
    		c1.2-0.5,2.6,0.2,3.9-0.3c0.8-0.3,0.3-1.4,1.5-1.4c0.8,0,1.7,0.8,2.4,0.3c0.9-0.7,2.3-0.3,3.4-0.4s2.1-1.3,3.1-1.6
    		c0.8-0.3,2.1-0.7,2.8,0c0.6,0.6,1.1,1.8,2.2,1.4c1.2-0.4,1.9,0.5,3,0.8c1,0.2,1.9-0.4,2.9-0.4c0.9,0,2.1,0.3,2.9-0.3
    		c1-0.7-0.1-1.7-0.4-2.5c-0.5-1.4,2.5-0.4,2.8-0.2c1,0.6,0.8,1.5,1.2,2.5c-2.2,1-1.7,1.5-1.8,2.9c-0.1,1.1-0.7,2-2.1,2.6
    		c0.4-1.1-4.9-1.4-5.7-1.7c-1.6-0.6-1.6-0.3-3,0.6c-1,0.6-1.9-1.2-2.8-0.7c-1.3,0.6-1.4,2.4-3.3,1.7c-1.5-0.5-1.4,0.2-1,1.1
    		c0.2,0.5,1,0.1,0.6,0.9c-0.3,0.5,0.3,1.1,0.7,0.7c0.8-0.9,2.9,1.9,3.3,2.4c-1.1,0.6-2.6-1.8-3.8-1.9c-2.7-0.2,0.9,2.5,1,3.2
    		c-1.3,0.4-2.6-1.8-3.7-2.2c-0.9-0.4-1,0.2-0.7,0.9c0.5,1,1.4,1.2,2.2,1.9c-1.9,0.8-2.3-0.9-2.8-2c-0.6-1.3-2.6-1.5-3.4-2.6
    		c0.6-0.2,0.8-0.6,0.7-1.2c-4,0.6-2.2,5-0.7,7c0.8,1,6,5.8,2.3,6.1c1.6-0.6,0.1-2.7-1.1-2.4c-1.5,0.4-0.2,3.3,1,2.8
    		c-0.8,1-2.1,1.6-3.3,1.2c0.8,0.4,3.1,1,3.4,1.9c0.3,0.6,1.1-0.4,1.5,0.3c0.4,0.6-0.6,0.7,0.8,1.1c1.5,0.4,2.5,1.3,3.9,2
    		c1.1,0.5,1,1.4,0.9,2.4c-0.2,1.1,0.8,2.1,0.2,3.2c-1.2-1-2.3-2.6-3.9-2.9c-1.2-0.2-2.4,0.7-3.5,0.6c0,1,0.9,0.1,1,1.2
    		c0,0.7,1.2,3.2,1.4,1.4c0.2,0.5,1.5,1,1.1,1.7c0.1-0.2-2.2,1-1.8,1c-1,0-0.3-1.1-1.1-1.4c-0.8-0.3-1.7-0.4-2.3-0.9
    		c-0.1,1.8,1.6,3.3,2.1,4.9c0.3,0.9,0.8,1.8,0.6,2.8c-0.2,0.9,1.1,1.1,0.9,2c-0.1,0.4-2.6-2.2-2.8-2.5c-1.3-2-2.4,1.2-2,2.4
    		c-1.1,0.2-1.5-2.9-2.3-3.5c-0.5-0.4-0.3-1.3-1.1-1.2c-1.1,0.1-0.9,1.5-0.8,2.2c-2.2-0.4-2.9-2.9-2-4.6c0.7-1.4-3.6-4.2-4.2-5.2
    		c0.8-0.3,1.3-1.5,1.9-2c0.6-0.6,1.3,0,2-0.5c0.4-0.4,0.9-1.3,1.7-1.2c1.3,0.3,2.2,1.6,3.7,1.7c1.2,0.1,1.9,0.9,2.8,1.5
    		c0.7,0.4,1.1-0.4,1.9-0.9c0.6-0.4,1.4-0.9,0.1-1.1c-0.8-0.1-1.4,0-2.1-0.4c-0.8-0.5-0.9-1.4-1.6-0.3c-0.4-0.5-0.8-1-1.3-1.4
    		c0.1,1.9-2.6,0.4-3.3,0.6c-1.9,0.4-3.2,1-4.2-0.8c-0.1,0.7-0.7,1.1-1.3,1.4c-0.4-1.2-0.7-2.5-1.7-3.3c-0.6-0.4-2.1-1.5-1.3-2.4
    		c0.3-0.3,4.2,0.9,2.4-0.7c-0.6-0.5-2.7-0.6-2.5,0.6c-0.6-1.7-2.2-2-3.3-3.1C1449,357.7,1449.1,355.5,1447.7,355.4"
          />
          <path
            id="path6492"
            class="countryIsland"
            d="M1498.3,381.3c-1.2-1.1,1.7-2.1,2.5-1.9C1500.2,379.9,1498.4,380.3,1498.3,381.3"
          />
          <path
            id="path6518"
            class="countryIsland"
            d="M1447.5,357.3c0.3,0.2,0.5,0.3,0.8,0.5c-1.4,0.3-3.2-2.1-3.2-3.4
    		C1447.9,354.2,1445.6,356.7,1447.5,357.3"
          />
          <path
            id="path6520"
            class="countryIsland"
            d="M1451.7,366.6c0-0.2,0-0.5,0-0.7c0.5,0.4,0.3,1.2,0.8,1.6c0.5,0.4,1.1,0.9,1.3,1.6
    		c-0.6,0.2-2.4,0-2.3-0.9c-0.2,0.1-0.5,0.3-0.7,0.4C1450,367.3,1451.2,367.5,1451.7,366.6"
          />
          <path
            id="path6522"
            class="countryIsland"
            d="M1452.7,362.6c0.4,0.4,0.3,2.5-0.6,1.9C1451.6,364.2,1451.9,362.5,1452.7,362.6"
          />
          <path
            id="path6524"
            class="countryIsland"
            d="M1453.1,367.2c-0.4-0.4-0.6-0.9-0.8-1.4C1452.6,366.2,1452.9,366.6,1453.1,367.2"
          />
          <path
            id="path6526"
            class="countryIsland"
            d="M1454.1,372.9c-0.7-0.4-1.6-1.1-1.5-2C1454.2,370.9,1455,371.7,1454.1,372.9"
          />
          <path
            id="path6528"
            class="countryIsland"
            d="M1469.8,383.8c0.5,0.6,1.2,1.7,0,1.9C1469.6,385.2,1469,384.3,1469.8,383.8"
          />
          <path
            id="path6530"
            class="countryIsland"
            d="M1472.9,369.8c0.5,0.8,0.1,0.9-0.6,0.9C1472.4,370.4,1472.6,370.1,1472.9,369.8"
          />
          <path
            id="path6532"
            class="countryIsland"
            d="M1473,372.7c-0.2-0.2-0.4-0.5-0.5-0.8C1473.3,371.6,1473.5,371.9,1473,372.7"
          />
          <path
            id="Greece_Euboea"
            class="countryIsland"
            d="M1473.4,365.8c0.3-1.5-3.7-4.2-4.9-3.2c0.6-0.6,1.2-1.7,2.2-1.7c1.1,0,1.6,1.3,2.2,1.9
    		c1,1,2.5,0.6,3.7,1.1c1.4,0.6,0.7,2.5,1.5,3.5c0.6,0.8,1.9,0.6,2.3,1.6c0.7,1.5-1.6,0.3-1.9,0c-0.7-0.7-1.2-2.3-2.1-2.7
    		C1475.6,366,1474.1,366.9,1473.4,365.8"
          />
          <path
            id="path6536"
            class="countryIsland"
            d="M1474,360.6c-0.3-0.2-0.5-0.4-0.7-0.6C1473.8,359.8,1474.1,360,1474,360.6"
          />
          <path
            id="Greece_Crete"
            class="countryIsland"
            d="M1478.7,394c-1,0.2-3,0.1-3.9-0.3c-0.9-0.4-0.3-1.9-0.3-2.6c0.2,0.1,0.5,0.3,0.7,0.4
    		c0.3-0.5,0.4-1,0.3-1.5c0.6,1.8,2,1,3.4,0.9c-1.3,0.5-0.4,0.8,0.2,1.3c0.9,0.8,1.7,0.6,2.7,0.3c2.2-0.8,4.4,0.6,6.6,0.7
    		c0.6,0,1.8-0.6,1.8,0.5c0,0.4-0.3,1,0.4,1c1-0.1,2.2-1,3.2-0.8c0.5,0.1,0.6,1.1,0.3,1.5c-0.4,0.4-1.2,0.4-1.7,0.4
    		c-2.1,0-4.1,0.2-6.1,0.6c-0.8,0.1-1.7,0.4-2.4,0c-0.6-0.4-0.5-1.1-1.2-1.4C1481.4,394.2,1480,394.3,1478.7,394"
          />
          <path
            id="path6540"
            class="countryIsland"
            d="M1478.8,374c-0.3-0.6,0-1.1,0.6-1.3C1479.4,373.2,1479.2,373.6,1478.8,374"
          />
          <path
            id="path6542"
            class="countryIsland"
            d="M1479.5,381.5c0-0.3,0-0.5,0-0.8c0.5,0.5,1.1,0.4,1.5-0.1C1481,381.5,1480.1,381.3,1479.5,381.5
    		"
          />
          <path
            id="path6544"
            class="countryIsland"
            d="M1479.8,347.7c-1-0.3-1.1-2.1,0.1-1.9C1481,346.1,1480.8,347.6,1479.8,347.7"
          />
          <path
            id="path6546"
            class="countryIsland"
            d="M1480.1,363.3c-0.1-0.8-1.2-0.8-0.9-1.7c0.9,0.1,1.2,1,1.5,1.7
    		C1480.6,363.2,1480.3,363.3,1480.1,363.3"
          />
          <path
            id="path6548"
            class="countryIsland"
            d="M1482.1,379.3c-0.1-0.2-0.2-0.5-0.4-0.7C1482.5,378.6,1482.7,378.8,1482.1,379.3"
          />
          <path
            id="path6550"
            class="countryIsland"
            d="M1483.3,352.6c0.8-0.1,1.6,0.4,2.4-0.1c-0.3,0.5-0.4,1-0.4,1.5
    		C1484.1,353.5,1482.4,354.8,1483.3,352.6"
          />
          <path
            id="path6552"
            class="countryIsland"
            d="M1483.4,372.6c-0.7-0.7-1.2-1.5-2-2.1c0.2-0.2,0.4-0.4,0.7-0.6
    		C1482.5,371.1,1484.3,371.1,1483.4,372.6"
          />
          <path
            id="path6554"
            class="countryIsland"
            d="M1483.7,372.7c0.7,0.2,1.7,0.1,1.7,1.1C1484.7,373.9,1484,373.4,1483.7,372.7"
          />
          <path
            id="path6556"
            class="countryIsland"
            d="M1483.9,382c-0.3-0.1-0.5-0.3-0.8-0.4C1483.7,381.5,1483.6,381.8,1483.9,382"
          />
          <path
            id="path6558"
            class="countryIsland"
            d="M1485.5,378.7c-0.6-0.5-0.6-1.1,0.2-1.4C1486,377.7,1485.9,378.3,1485.5,378.7"
          />
          <path
            id="path6560"
            class="countryIsland"
            d="M1486.4,375c-0.6-0.7-0.4-1,0.5-0.6C1486.7,374.5,1486.6,374.7,1486.4,375"
          />
          <path
            id="path6562"
            class="countryIsland"
            d="M1486.8,381.5c-0.2-0.2-0.5-0.4-0.7-0.6C1486.7,380.6,1487.2,380.9,1486.8,381.5"
          />
          <path
            id="path6564"
            class="countryIsland"
            d="M1486.9,348.4c0,0.2,0,0.5,0,0.7c-0.6,0.1-1-0.2-1.1-0.8
    		C1486.2,348.2,1486.5,348.2,1486.9,348.4"
          />
          <path
            id="path6566"
            class="countryIsland"
            d="M1487.3,379.2c-0.3-0.4-0.8-1-0.6-1.5c0.2-0.3,0.9-0.9,1.2-0.7
    		C1488.6,377.4,1487.8,379,1487.3,379.2"
          />
          <path
            id="path6568"
            class="countryIsland"
            d="M1489.9,380.5c0.3-0.8,1.1-1.2,1.9-1.2C1491,379.6,1490.5,380.2,1489.9,380.5"
          />
          <path
            id="path6570"
            class="countryIsland"
            d="M1490.7,368.5c-1.6-0.6,0.3-2.5-1.4-3.2C1491.2,362.9,1492.6,367.4,1490.7,368.5"
          />
          <path
            id="path6572"
            class="countryIsland"
            d="M1490.9,374.1c0.1-1.3,1.7-1.1,2.5-1.5C1493,373.5,1491.8,374,1490.9,374.1"
          />
          <path
            id="path6574"
            class="countryIsland"
            d="M1493.2,361.6c-0.8-0.1-1.6-0.5-2.2-0.9c0.2-0.2,0.5-0.3,0.7-0.5c-0.7-0.1-1.5,0.3-2.1-0.1
    		c-1.1-0.8,0.1-1.1,0.8-1.3c2.2-0.6,2.9,0.2,4.1,2.4C1494,360.7,1493.4,361,1493.2,361.6"
          />
          <path
            id="path6576"
            class="countryIsland"
            d="M1496.7,372.9c-0.1-0.1-2.7-0.9-1.1-1.3c0.8-0.2,1.8,0.4,2.6,0.2
    		C1497.9,372.4,1497.3,372.9,1496.7,372.9"
          />
          <path
            id="path6578"
            class="countryIsland"
            d="M1497.3,377.6c-0.2-0.2-0.5-0.4-0.7-0.6C1497.2,377,1497.2,377.2,1497.3,377.6"
          />
          <path
            id="path6580"
            class="countryIsland"
            d="M1498.2,379.1c-0.6-0.7-0.4-0.9,0.4-0.7C1498.5,378.7,1498.3,378.9,1498.2,379.1"
          />
          <path
            id="path6582"
            class="countryIsland"
            d="M1500.2,392.3c-0.3-1.4-0.2-2.4,0.3-3.7C1500.7,389.4,1500.9,391.9,1500.2,392.3"
          />
          <path
            id="Greece_Rhodes"
            class="countryIsland"
            d="M1505.5,387.1c-0.7,2-1.3-0.2-1.4-1c-0.2-1.5,2.1-2.6,3.3-2.8c0.2,0.9-0.3,1.7-0.5,2.5
    		C1506.4,387.2,1506.3,386,1505.5,387.1"
          />
        </g>
        <path
          id="tm"
          class="countryGroup country"
          d="M1673.2,337.7c3.1-5.1,8.9-5.9,14.2-3.5c2.6,1.2,3.8,3.5,5.6,5.4c2.5,2.6,4.8,1.7,8.2,1.9
    	c1.5,0.1,2.8,0.2,4.4,0.2c1.6,0,0.7-1.3,0.5-1.6c-2.1-3.5-1.3-4,0.4-5.7c1.3-1.3,3.9,0.6,4-2.3c0-2.5,3.2,0.6,4.1,0.8
    	c-0.3-0.4-2.9-2.6-2.9-2.7c-0.1-0.5,1.4,0.4,2.6-0.9c0.5-0.6,1.4,0.1,1.9,0.7c0.7,0.8,1.6,0.8,2.7,1.2c0.9,0.3,0.7,1.4,1.7,1.6
    	c0.9,0.2,1.8,0,2.7,0c1.2,0,2.3,0.7,2.2,2c-0.1,1.5,1.4,1.2,1.9,2.5c-0.7-0.1-1,0-0.6,0.7c0.6,0.9,0.6,1,0.5,2
    	c-0.1,1.6,3.8,2.1,4.8,2c1.2-0.1,2.2,0.3,3.3,0.6c1.5,0.3,0.8-1,1.8-1c2.7,0,4.2,2.8,5.3,4.8c1.6,1.8,2.8,3.7,3.8,5.7
    	c1.1,2.2,4.8,3.6,6.8,4.8c2.6,1.5,6.4,4.5,7.9,4.7c1.4,0.2,4.2,2,6.9,3.7c2.1,1.2,3.4,2.7,6,2.7c1-0.1,2.3,0.5,3.5,1.2
    	c1.1,0.7,2.6,0.7,2.5,0.9c0.5,0.6-0.1,1.2,0,1.9c0.1,1.1,0.2,2.9,0.9,3.5c-3.2,1-4.4-1.1-5.2-1.4c-1.8-0.6-1.7,0.9-1.7,1.3
    	c0,1.2-0.8,1.4-1.7,1.4c-0.9,0-2-0.2-2.8,0.2c-1,0.6-1,1.7-1,2.8c-0.1,1.4,0.2,1.9,0,3.4c-0.2,1.2-0.6,1.9-1.5,2.4
    	c-2.3,1.5-5.3,3.3-8.3,2.9c0.2,0.6,0.6,1.1,1.2,1.4c-1.1,0.6,0.4,1.3-1.7,2.7c-1.1,1.5-3.4,0.7-4.2,2.1c-0.4-1.2-1.2-2.2-2.5-2.4
    	c-1.9-0.3-1.5,0.5-2.7-0.2c-0.4-0.3-2.1-1.7-2.8-1.4c-0.3-0.5-0.5-1.9-1-2.5c-0.7-1.2-0.8-0.1-0.4-1.5c0.2-1-0.6-3-0.8-4.1
    	c-0.2-1.3-1.2-1.2-2.3-1.1c-1.1,0.1-2.3,0.4-3.4,0.1c-1.4-0.3-2.2-2.3-3.2-3.1c-1.3-1-3.4-0.8-4.5-2.2c-1-1.4-1.4-2.2-3.1-2.8
    	c-1.7-0.7-3.1-0.4-4.8-0.5c-1.5-0.1-2.5-1.3-3.9-1.8c-1.3-0.4-3.8-0.2-4.5-1.6c-0.6-1.2-1.1-1.8-2.4-1.5c-0.6,0.1-6.4-1.2-5,1.2
    	c-1.8,0.4-3.5-0.6-5.2-0.2c-2.1,0.4-3.4,1.8-4.8,3.2c-0.7,0.6,0,1.5-0.5,2.2c-0.3,0.4-0.9,0.6-1.4,0.8c-1.1,0.4-3,0.9-4.1,0.1
    	c-0.8-0.6-1.1-2.5-1.3-3.4c-0.5-2-0.4-4.1-0.8-6.1c-0.3-1.2,0.8-3.1-0.3-4c-0.6-0.5-1.9-0.9-1.8-1.9c0,1.4-0.9-0.3-1.5-0.9
    	c-0.7-0.6-1.9-1.5-2.4-0.2c-0.9-1.1-0.8-2.7,0.4-3.5c-1.5,1.5,1.9,1,2.5,1.2c-0.6,0-0.9-1-1.1-0.9c-0.8,0.3-0.6-1.2-0.6-1.6
    	c-0.1-2.1-3.2-0.8-4.5-1.5c0.9,1.5-0.7,0.3-1-0.1c-0.4-0.5-0.6-1.2-0.6-1.9c-0.1-1.4,0.3-2.8,0.3-4.2c0-0.6,0.2-1.5,0-2.1
    	c-0.1-0.4-1.2-1.5-0.8-1.4c0.1,0-0.1-0.3,0-0.3c0.4,0.1,0.8,1.9,1.8,1.9c-0.9,0,0,1.4,1.1,0.4c-0.2,0.5,0,1,0.8-0.2
    	c0.2,0.9,1.3,1.7,1.4-0.6c0.5,0.1,1,2.4,1.5,2.5c0.5,0.1,2.6-0.6,2-0.9c0.3,0.1,1.2-0.2,1.3-0.2c0.2,0-0.7,0.8,1.1,0.5
    	c0.2,0.1,0-0.2-0.2-0.1c0,0,0.1-0.1,0.3-0.2c-1.1,0-0.6-0.4-1.5-1.4c0.3-0.7,1.8-0.2,1.5,0.5c1.5,0.4,2.5-1.2,1.2-2.1l0.6,0.2
    	c-0.5-1.7-5.5-2.9-5.8-4.2c-0.2-0.7-1.8-4.8-2.7-5.1c-2.9-1-5.7,1.8-6.3,2.3c-0.4,0.2-0.2,1.8,0.1,1.8c0.5-1.3,0.8,0.9,0.7,1.9
    	c0,0.3,1.4,3.3,0.9,2.9c-0.3-0.9-0.8-1.6-1.5-2.2C1674.4,339.4,1674.3,338.3,1673.2,337.7"
        />
        <g id="ec" class="countryGroup">
          <path
            id="Ecuador_mainland"
            class="country"
            d="M681.9,717.5c1.5,0.5,1.6-1.8,1.5-2.7c-0.1-0.7-0.4-1.3-0.6-2c-0.4-1.2,0.9-2.1,0.4-3.2
    		c-0.5-1.2-1.9-2.9,0.4-3.1c1-0.1,1.3-0.5,1.5-1.4c0.2-1.2,0.9-0.9,1.9-1.1c-1.7-0.2-1.8-2.3-0.8-3.3c1.5-1.5,2.6-2.6,2.7-4.9
    		c0-0.7-0.9-3.6-0.3-4c0.7-0.4,3.4-2.4,3.4-0.5c-0.4-1.2,1-1.7,1.9-2c0.9-0.3,2.8-1.5,3-0.4c0.4-0.9,0.7-1.9,1.4-2.7
    		c2,1.9,5.1,5.3,8.1,5.2c0.9,0,1,1.1,1.9,1.4c1.2,0.4,0.1,2.8,2.2,2.7c1.6,0,2.8,0.7,4.4,0.8c0.5,0,1.7,0.7,2-0.3
    		c0.1-0.3-0.5-0.6-0.1-0.8c1.5-0.7,2.6,0.6,3.5,1.6c1.3,1.6,3.2,1.7,4.8,2.8c-0.9,0.2-1.7,0.1-2.6-0.1c-0.1,0.6,1.8,2.4,2.1,2.9
    		c1.1,1.4,0.3,2.9,1,4.6c-0.6,0.1-1-0.1-1.4-0.5c-1.1,5.7-4.4,10.8-9.2,14.1c-2.3,1.6-5.3,2.1-7.9,3.2c-1.3,0.6-2,1.4-2.9,2.5
    		c-0.3,0.3-0.6,0.7-0.8,1c-0.2,0.6,0.2,0.9-0.3,1.5c-0.5-2.3-1.5,2.2-1.6,2.4c-0.8,1.4-1.6,3.4-1.5,5c0.1,1.9-1.3,1.9-1.8,3.4
    		c-0.5,1.6-0.4,1.8-1.9,1.8c-1.8,0-2.2-2-2.8-3.3c-0.7-1.7-1.5-0.4-2.7-1c-0.8-0.4-1.6-2-2.6-1c-1.3,1.3-2.7,1-1.3-0.9
    		c-1-0.1-1.2-1.5-0.5-2.1c0.4-0.4,1.3-0.1,1.8-0.5c0.8-0.6-0.4-4.5-1.3-4.9c1.1-0.6,2-0.3,2.7-1.6c0.6-1.1,0.7-2.4,1.1-3.6
    		c0.4-1.5,0.5-1.5,0-2.9c-0.5-1.3-0.6-2.6,0.3-3.8c-1.6,0.8-0.6,2.6-0.8,3.9c-0.4,2.2-1.9,0.2-0.3,0.1c-0.8,0-0.9-0.7-0.6-1.3
    		c-0.5,0.5-2.3,2.7-1.9,3.5c-1.2-0.8-2.1-2.1-3.3-2.8C683.1,719,682.1,718.7,681.9,717.5"
          />
          <path
            id="path6790"
            class="countryIsland"
            d="M690.4,721.4c0.3-0.5,0.2-1-0.1-1.4C690.2,720.5,690.2,720.9,690.4,721.4"
          />
          <path
            id="path6602"
            class="countryIsland"
            d="M689.3,721.6c-0.8,0.1-1.6,0.7-1.9,1.4c-0.1,0.3,0.1,2.1,0.7,1.7c0.2-0.2,0.6-1.1,0.6-1.4
    		c0-0.4,1.1-0.8,1.4-1.3C689.8,721.9,689.5,721.8,689.3,721.6"
          />
          <path
            id="Ecuador_Fernandina"
            class="countryIsland"
            d="M601.2,702.8c0.9-0.2,1.1-1.7,0.1-1.9C599.6,700.5,600.2,702.4,601.2,702.8"
          />
          <path
            id="Ecuador_Isabela"
            class="countryIsland"
            d="M600.4,698.5c1.3,0.2,1.3,1,1.6,2c0.4,1.3,1.6,2.1,2,3.4c-1.4,0.4-4.1,2.3-1.7,3.4
    		c1.3,0.6,4-0.5,4.3-2c0.2-1.1-1.4-1.1-1.3-2c0.2-1.3-0.9-1.8-1.5-2.8C602.7,698.9,602.8,697.1,600.4,698.5"
          />
          <path
            id="Ecuador_Santiago"
            class="countryIsland"
            d="M608,701.8c0.8-1-0.5-1.8-1.4-1.8C604.6,700,607.9,701.8,608,701.8"
          />
          <path
            id="Ecuador_Marchena"
            class="countryIsland"
            d="M608.9,696.3c0.5-0.7,0.4-0.9-0.4-0.7C608.6,695.8,608.8,696.1,608.9,696.3"
          />
          <path
            id="Ecuador_Santa_Cruz"
            class="countryIsland"
            d="M610.1,705.3c1.5-0.4,1.5-2.6-0.3-2.4C608,703.2,608.4,705.3,610.1,705.3"
          />
          <path
            id="Ecuador_Floreana"
            class="countryIsland"
            d="M609.3,710.3c0.2-0.2,0.4-0.5,0.6-0.7C609,708.8,608.4,709.9,609.3,710.3"
          />
          <path
            id="Ecuador_San_Cristobal"
            class="countryIsland"
            d="M616.2,706.9c0.8-0.1,2.3-1.5,1.7-2.4C617.1,704.6,615.5,706,616.2,706.9"
          />
        </g>
        <path
          id="bj"
          class="countryGroup country"
          d="M1307,605.9c0.3-0.8,0.4-2,1-2.6c0.5-0.6,0.7-0.5,1.2-1c1-1,2.6-3.9,3.9-2.5c0.4,0.4,4.5-0.6,5.3-2
    	c0.5-0.9,1.2-2.1,1.2-3.1c0-0.6-0.8-2.1,0.2-2.3c0.7-0.1,1.6,0,1.9-0.4c1-1.5,2.3,0.2,3.1,1c0.6,0.6,0.9,1.1,1.4,1.8
    	c0.6,0.7,1.5,1.5,2.2,2.4c0.3,0.5-0.4,1.7-0.5,2.2c-0.3,1.1,0.8,2.1,1.4,2.9c0.6,1,0.1,2,0.5,2.9c0.5,1.1,1.1,1.9,0.4,3.1
    	c-0.4,0.6-1.2,0-1.4,0.8c-0.4,1.1,1.1,1.9-0.2,3.3c-0.6,0.7-1.4,0.9-1.9,1.6c-0.4,0.7-0.6,1.5-1.2,2.2c-1.2,1.4,0.6,3.4-1.8,4
    	c-0.8,0.2-1.3-0.2-1.5,0.8c-0.1,0.9-0.6,1.7-0.6,2.6c0.1,2.2,0.1,4.4-0.1,6.5c-0.1,1,0.9,2.9,0.7,4.2c-0.1,1.2-0.2,2.2-0.3,3.3
    	c-0.3,1.5,0.3,2.2,0.2,3c-0.1,0.9-0.7,2.3-0.6,2.9c-1,0-2.7,0.2-3.7,0.4c-1.5,0.3-3.1,0.6-4.6,0.8c0.4-0.3,0.8-0.5,1.3-0.5
    	c-0.1-0.8-0.6-1.6-1-2.3c-0.6-1-0.7-2.7-0.9-3.9c0.2,0,1-0.2,0.9-2.3c-0.2-3.8,0-7.7,0-11.5c0-2.2-0.1-3.8-1.3-5.7
    	c-1.2-1.8-0.8-3.8-0.8-5.7c0-1.5-2.3-2.3-3.5-3.3C1306.6,608.5,1306.9,607.1,1307,605.9"
        />
        <path
          id="si"
          class="countryGroup country"
          d="M1401,301.6c-0.1-0.6,0.5-1.4,0.9-1.7c-0.7-0.2-1.3-0.5-1.9-0.9c1.5-1.6,0.8-2.6,2.7-2.2
    	c0.7,0.2,4.9,1.6,5.5,1.1c1.2-1.1,2.3-2.2,4-2.1c0.9,0.1,2,0.5,2.9,0c0.9-0.5,1.9-0.6,2.8,0c-0.8-1,0.4-2.5,1.5-1.6
    	c0.8,0.7,1.8,2.3,2.4,3.2c-0.8-0.1-3.4-0.8-2,0.9c-0.8,0-1.2-0.6-1.6,0.4c-0.2,0.5-2.2,1-2.8,1.3c-0.4,0.3,0.4,1.6,0.3,2.2
    	c-0.1,0.9-2.2,1-3.1,1.7c0.3,0.1,0.7,0.4,1,0.5c-0.6,0.1-0.2,2.4-1.4,1.6c-1.4-0.9-2.8-0.5-4.1-1.8c-0.5,1.1-2.1,2-3.3,1.5
    	c-0.5-0.2-0.5-0.2-0.9,0.2c-0.6,0.6-1.9,0.1-2.5-0.1c0.8-0.7,2.3-0.9,1.7-1.9c-0.5-0.8-2.5-0.6-1.5-1.9"
        />
        <g id="no" class="countryGroup">
          <g id="xv" class="countryGroup">
            <path
              id="path6656"
              class="countryIsland"
              d="M1386.8,53.5c-1.1-0.8-2.7-0.7-3.8-1.7c-1-0.9-1.8-2.3-3.4-1.6c0.4,1.3,2.1,2.1,3.3,2.5
    			c1.4,0.4,3.2,1.6,4.7,1.5C1387.3,53.9,1387.1,53.7,1386.8,53.5"
            />
            <path
              id="path6658"
              class="countryIsland"
              d="M1381.6,44.8c-0.5-0.4-1-0.4-1.6-0.1C1380.5,44.7,1381,44.7,1381.6,44.8"
            />
            <path
              id="Norway_Spitsbergen"
              class="countryIsland"
              d="M1382.6,48c0.2,1.2,2.1,0.3,2.7,0.1c-0.2-0.3-0.4-0.5-0.7-0.7
    			c0.8,0.4,1.7-0.1,2.3,0.6c-0.6,0.2-1.2,0.5-1.7,0.9c0.6,0.2,1.2,0.4,1.9,0.6c-1.3,0.9-2.9-0.8-4.2,0.2c1.3,0.3,1.8,1.8,3.1,2.1
    			c1.6,0.4,3.3,0.1,4.8,0.6c-1.1,0.1-2.2-0.1-3.2,0.4c1.6,0.9,3.3,1.6,5.1,1.6c1.3,0,2.4-0.1,3.7-0.4c0.9-1,1.7-0.4,2.9-0.9
    			c-0.5-0.3-1-0.5-1.6-0.6c0.7-0.1,0.8-0.6,0.3-1.1c1.5-0.5,3.3,1.2,4.1-0.9c1,0.4,0.8,1.6-0.3,1.6c0.8,1.1,2.4,0.9,3.5,0.5
    			c0.7-0.2,1.3-0.5,1.9-0.9c1.1-0.7,1.3-0.2,2.5-0.2c-0.8,0.3-1.6,0.7-2.2,1.4c1.3-0.1,2.7,0.1,4,0.2c-2.2,0.7-4.9-0.5-7,0.6
    			c-0.9,0.5-1.8,0.2-2.7,0.7c-1.7,0.8-3.3,0.3-5,0.8c0.2,0.3,0.4,0.5,0.7,0.7c-0.8-0.4-2.7-1-3.5-0.4c-1.7,1.1,2.2,1.9,2.7,1.9
    			c2.2-0.1,4.4-0.6,6.6-0.7c1.3-0.1,2.7,0.1,4,0c1.2-0.1,2.5-0.7,3.7-0.6c-0.5,0.5-0.6,0.9,0.4,0.9c-1.9-0.5-3.8,0.1-5.7,0.1
    			c-2,0-4.1,0.2-6.1,0.6c2.5,0.8,5.3,0.2,7.7,1.4c-2.3,0-4.9-1.3-7.3-0.6c-1,0.3-1.9-0.4-2.8-0.3c-1.9,0.2-1.2,1.3-0.1,2
    			c1.6,0.9,3.8,1.2,5.6,1.6c1.3,0.3,2-0.2,3.2-0.4c1-0.1,2,0.4,3,0.6c-1.5,0.6-3.3-0.1-4.8,0.7c1.4,0.3,3.2,0.9,4.3,1.8
    			c1.2,0.9,5.2-0.9,2.8-1.4c0.5-0.3,1.1-0.4,1.5-0.8c-0.1-0.2-0.2-0.5-0.3-0.7c0.3-0.1,0.6-0.3,0.9-0.6c0.5-1.5,1.6-2.3,3.1-2.3
    			c1.1,0,2.2-1.5,1.4-2.4c-0.9-1,3-1.3,3.3-1.6c-0.3-0.1-0.5-0.3-0.8-0.4c1.1,0,0.5-1.2,0.2-1.7c2.5-0.3,4.7-1.2,7.2-1.1
    			c1.2,0,4.4,0.4,4.8-1.3c-2.6-0.6-6.1-0.3-8.5-1.5c-0.9-0.5-1.7-0.7-2.7-0.6c-1.4,0.2-1.9-0.6-3.1-0.6c2.1-0.4-0.7-1.9-1.5-2.1
    			c-0.8-0.2-1.5,0.5-2.2,0.9c-0.3,0.2-0.7,0.3-1,0c-0.7-0.8,0.6-1.1,1.1-1.7c-1.1-0.3-2-0.8-3.1-1.1c-0.9-0.2-1.5,0.3-2.4,0.2
    			c-0.8-0.1-3.4-1.6-3,0.3c-3.7-1.1,2.3,5.5,2.4,5.9c-1.4-0.4-2.1-1.9-3.7-1.4c0.7-1.2-2.9-3.2-3.6-3.6c-1.1-0.6-1.9-0.5-2.9,0.3
    			c-0.7,0.5-1.4,0.4-2,0.8c-0.3,0.2,0.4,1.3,0.3,1.6c-1.1-1.5-2.5-0.6-3.9-1.2c0.1-0.3,0.3-0.5,0.4-0.8c-1.3,0-2.7,0.5-4,0.1
    			c1.2,0,2.3-0.6,3.6-0.6c1.1,0,2.3,0,3.3-0.4c-0.2-1.4-2.8-0.6-3.6-0.5c-0.9,0.1-1.8,0.4-2.7,0.4c-0.6,0-3.2-1.1-2.3,0.5
    			c-0.9,0.3-1.3-0.8-2.2-0.9c-0.6-0.1-3.4,0.8-1.7,1.3c-0.4,0-3.8,0.3-1.5,0.4c-0.6,0-1.2,0-1.7,0.3c0.8,0,0.7,0.1,0.6,0.7
    			C1380.8,47.4,1382.2,47.7,1382.6,48"
            />
            <path
              id="path6718"
              class="countryIsland"
              d="M1417.7,43.1c-0.8,0.3-1.6,0.3-2.4,0.6c3.1,0.7,6.3,1.5,9.5,0.9c3.5-0.7,7-0.4,10.5-0.2
    			c-2.6,0.9-5.4,0.7-8.1,0.6c0.7,0.5,1.5,0.5,2.3,0.6c-2.2,0.3-4.3-0.4-6.5-0.1c1.3,1.3,4.8,1.3,6.6,1.5c2.8,0.2,6,0.1,8.7-0.2
    			c-0.3,0.2-0.5,0.3-0.8,0.5c2,1.3,5.5,1.5,7.7,0.7c1.7-0.7,2.5-0.8,4.2-0.6c1.2,0.1,2.7-0.3,3.6-1c-0.2-0.2-0.4-0.5-0.6-0.7
    			c2-0.7,4.1-1.2,6.2-1.6c-0.8-2.5-4.1-1.9-6.1-1.8c-1.6,0.1-3.2-0.5-4.8-0.5c-0.7,0-1.6-0.3-2.3-0.1c-0.7,0.2-1-0.8-1.8-0.5
    			c2.2,0.2-1.5,0.6-1.9,0.9c-0.9,0.7-2.2,0.2-3.2,0.3c1.4-0.7-0.4-1.3,1.1-1.4c-0.7-0.5-1.6-0.4-2.3-0.6c0.3,1.1-1.1,1.1-1.7,0.5
    			c0.6,0.4,0.7,0.5,0.2,1c-0.3,0.4,0,1.2-0.2,1.3c-1,0.8-2.6-1.1-3.9-0.6c2.2-0.7-1-0.7-1.5-0.6c-1,0.1-2,0.3-2.9-0.3
    			c-1.4-0.9-3.9-1.6-5.5-1.1c0.5,0.3,1,0.5,1.6,0.5c-1.1,1.3-2.5,0.2-3.9,0.1c0.4,0.8,1.3,1.1,2.2,1c-0.8,0.9-2.3,0.1-3.2-0.1
    			c-1.5-0.4-3.1,0.1-4.6,0.2c0.4,0.1,3.7,0.6,1.6,0.5c1,0.2,2.1,0.4,3.1,0.2C1418.4,43.1,1418.1,43.2,1417.7,43.1"
            />
            <path
              id="path6720"
              class="countryIsland"
              d="M1416.7,41.7c0.2-0.2,0.4-0.3,0.7-0.5c-0.8-0.2-1.6-0.4-2.2,0.2
    			C1415.7,41.5,1416.2,41.6,1416.7,41.7"
            />
            <path
              id="path6730"
              class="countryIsland"
              d="M1423.8,79.5c0.5-0.1,0.8-0.5,0.8-1c-0.8-0.3-1.6-0.2-2.3,0.1
    			C1422.8,79,1423.3,79.3,1423.8,79.5"
            />
            <path
              id="path6732"
              class="countryIsland"
              d="M1424.8,47.1c0.3,0,0.5,0,0.8,0c-0.7-0.3-1.6-0.5-2.3,0.1C1423.8,47.2,1424.3,47.1,1424.8,47.1
    			"
            />
            <path
              id="path6738"
              class="countryIsland"
              d="M1426.8,49.3c0.7-0.1,1.5-0.2,2.2-0.5c-1-0.2-2.2-0.5-3,0.3
    			C1426.2,49.2,1426.5,49.2,1426.8,49.3"
            />
            <path
              id="path6740"
              class="countryIsland"
              d="M1427.7,39.3c0.2-0.1,0.5-0.1,0.7-0.2c-0.8-0.1-1.5-0.1-2.2-0.2
    			C1426.7,39,1427.2,39.2,1427.7,39.3"
            />
            <path
              id="path6742"
              class="countryIsland"
              d="M1426.2,52.5c1.3,0.3,2.3,0.3,3.4,1.2c1,0.7,2,0.4,3.1,0.4c0.5,0,5.3-0.1,3.6-1.6
    			C1434.4,50.8,1428.4,51.8,1426.2,52.5"
            />
            <path
              id="path6744"
              class="countryIsland"
              d="M1427.2,39.6c-0.2-0.2-0.4-0.3-0.7-0.1C1426.7,39.5,1427,39.5,1427.2,39.6"
            />
            <path
              id="path6754"
              class="countryIsland"
              d="M1430.6,59c3.1,0.8,6.2-0.8,9.3-0.6c-0.2,0.2-2,1-1.4,1.5c1.2,0.9,2.3,0,3.5-0.3
    			c1.3-0.4,2.2-0.2,3.4-1c1.4-0.9,2.8-1.2,4.4-1.3c-1.1-0.8-2.6-1.2-3.9-0.9c-1.5,0.4-3.1-0.4-4.5-0.8c0.4-0.5,0.9-0.8,1.5-1
    			c-1.9-0.8-3.9-0.3-5.9-0.3c-2.2,0-4.4,0-6.5,0.4c0.7,0.8,2,1.1,3,1.2c-0.1,0.6-0.6,1.3-1,1.8C1432,58.4,1430.8,57.8,1430.6,59"
            />
            <path
              id="path6780"
              class="countryIsland"
              d="M1458.3,51.6c0.2-0.2,0.5-0.3,0.7-0.5c-0.8-0.1-1.5-0.9-2.2-0.1
    			C1457.3,51.2,1457.8,51.4,1458.3,51.6"
            />
            <path
              id="path6784"
              class="countryIsland"
              d="M1461.6,42.1c-0.2,0.1-0.5,0.2-0.7,0.4c0.5,0,1,0,1.5-0.2C1462.1,42.2,1461.8,42.2,1461.6,42.1
    			"
            />
            <path
              id="path6786"
              class="countryIsland"
              d="M1464.7,50.4c2.2-0.7,5.1,0.4,7-0.6c-2.6,0.3-5.6-1.3-7.8,0.5
    			C1464.1,50.3,1464.4,50.4,1464.7,50.4"
            />
            <path
              id="path6788"
              class="countryIsland"
              d="M1479.3,42.7c3.1-0.3,6.3-0.3,9.4-0.9C1485.7,41.5,1482,41.4,1479.3,42.7"
            />
          </g>
          <path
            id="Norway_Jan_Mayen"
            class="countryIsland"
            d="M1273.3,104.2c1.1-0.5,2.2-1.1,3.4-1.2c0.7-0.1,2.1,0,2.6-0.7c1-1.5-2.6,0.1-2.9,0.3
    		C1275.4,103.3,1273.8,103,1273.3,104.2"
          />
          <path
            id="Norway_mainland"
            class="country"
            d="M1347,174.9c1.5-0.7,3.2-0.1,4.8-0.6c-0.4-0.1-0.7,0-1,0.1c-0.7,0.2-1.3-0.3-2-0.3
    		c2.3-0.7-1.5-0.3,0.7-0.8c-0.8-0.1-1.6,0-2.4,0.1c0.8-0.2,1.6-0.2,2.4-0.4c-0.7,0.2-2.4,0.7-2.4-0.6c0.6,0.1,1.1-0.1,1.5-0.4
    		c-1.9,0.2,1-1.1,1.5-1.1c0.6,0,0.9,0.5,1.5,0.6c0.6,0.1,2.4-0.5,1.1,0.8c0.3-0.8,4.3-1.3,5.1-0.9c-0.9-0.5-1.9,0.1-2.8,0
    		c-0.5-0.1-4.5-0.3-2.4-0.3c-1.2-0.1-3.2,0.5-4.3-0.4c0.6,0,1.1-0.2,1.5-0.6c-0.3-0.4-1.8-0.6-1.8-1.2c0.1-0.9,2.1,0.7,2.2,1.3
    		c0.3-0.1,0.5-0.3,0.7-0.5c-1.3,0-0.3-1.6,0.9-0.1c0.1-0.7,0.9-1.2,1-0.3c0.1,0.5,2.1,0.4,2.4,0.4c-0.6-0.1-2.6-0.9-1.4-1.7
    		c1.5-0.9,1.7,0,2.8,0.9c-1.9-3.8,4.8-1.1,2.1,0.6c0.6,0.1,1.1,0.1,1.6-0.3c-0.6-0.1-1-0.6-0.4-1.2c0.3-0.3,1.2,0.1,1.6-0.1
    		c-1.7,0.1-1.9-0.8-3.2-1.3c-1.1-0.4-2.2,0.2-3.3-0.1c0.8-0.3,1.7-0.2,2.6-0.3c-0.8-0.5-1.7-0.1-2.4-0.7c1.4-0.3,3-0.7,4.4-0.2
    		c1.2,0.4,3.1,1.1,4.2,0.3c-0.5,0.1-1.1,0.2-1.6,0.1c0.2-1.6,3-1.4,4-1.1c-1.3-1.4-3.2,0.8-4.6-0.2c0.5-0.1,1-0.3,1.5-0.4
    		c-1.1,0.1-2.9,1.1-3.8,0c0.5-0.2,0.9-0.3,1.4-0.2c-0.6-0.1-1.2-0.4-1.6-0.9c0.8-0.5,1.7-0.7,2.5-0.2c0.8,0.6,1.6-0.3,2.5-0.1
    		c0.8,0.2,1.5-0.3,1.8,0.7c0.3,0.9,1.9,0.9,2.6,1.4c-0.8-1.4-2.9-1.4-3.2-3.1c0.6-0.4,1.5,0.4,1.8,0.8c0.8,0.9,0.4,0.8,1.9,0.8
    		c-1.4-0.1-0.5-0.7,0.2-0.9c-0.3,0-2.9-0.1-0.8-0.2c-0.3,0-2.2-0.7-1.2-0.9c1.2-0.3,2.7-0.3,3.9-0.5c-0.8,0.1-1.5,0.2-2.2-0.2
    		c0.3-0.3,0.5-0.6,0.8-0.9c0.4-0.5,0.8-0.2,1.4-0.4c0.8-0.3,1.7,0,0.8,0.9c0.7-0.3,1.5-0.5,2.3-0.6c-0.6,0.1-1.1,0.1-1.7-0.1
    		c0.4-0.3,1-0.5,1.5-0.6c-0.5-0.1-1-0.1-1.5,0c0.8-0.5,1.9-0.8,2.8-1c1.1-0.3,2.5,1.5,1,2.2c0.8-0.3,1.5-0.1,2.3-0.1
    		c-2-0.5,0.4-0.9,1-0.9c0.9,0,1.8,0.2,2.7,0c-0.5-0.7-0.4-0.9,0.4-0.9c-0.5-0.2-1-0.1-1.5,0.1c1.2-0.9,2.5-1.6,4-1.8
    		c-0.1-0.6-0.5-0.9-1.1-0.6c0.2-1,1.3-0.9,2-1.3c-0.7-0.1-2.5,0.3-0.8-0.6c-1.5,0.1-2.4,1.7-3.9,1.9c0.5,0,1.1-0.1,1.5-0.4
    		c0.9,1.2-1.9,2.1-2.6,2.3c-2.3,0.8-2.8,1.2-4.1-0.7c0.6,0,1.2-0.3,1.7-0.7c-1,0.2-2.1,0.5-3.1,0.9c0.6-0.4,1.2-0.9-0.1-0.9
    		c1.1-0.5,2.7-1.6,4-1.1c-0.5-0.3-1.1-0.3-1.6,0.1c0.2-0.2,0.5-0.4,0.7-0.6c-1.6,0.3,0.1-0.6,0.5-0.9c0.5-0.4,0.2-1,1.1-0.6
    		c-0.7-2,3.7-1.5,3.3-3.2c0.8,0.2,1.3,1,2.1,1.2c-0.1,0.3-0.2,0.5-0.4,0.8c0.7-0.5,1.6-0.9,2.4-1c-1.4,0.1-0.6-0.9,0.2-0.9
    		c-0.5-0.2-1-0.3-1.5-0.3c0-1.1,1.6-1.2,2.4-1.4c-0.4,0-0.8-0.1-1.2-0.1c1.3-1,3.2-1.3,4.8-0.6c-2.6-1.3-4.4,1.2-6.9,1.2
    		c1-0.3,1-0.5-0.1-0.6c0.7-0.4,1.6-0.2,2.3-0.6c-0.6,0.2-1.1,0.3-1.7,0.1c0.5-0.2,1-0.3,1.5-0.2c-2.3-0.5,2.6-0.7,0-0.5
    		c0.7-0.1,1.4-0.2,2-0.6c-1.8,0.1,0-0.8,0.7-0.1c0.1-0.9,1.2-1,1.4-0.1c-0.6,0.2-1.2,0.3-1.8,0.1c1.7,0.8,4-0.5,5.2-1.6
    		c-0.5,0.3-1,0.6-1.6,0.8c-0.5,0.2-2.5,0.2-0.7-0.1c-1-0.1-0.9-0.5-0.4-1c-0.6-0.1-1.2,0.2-1.5,0.6c-0.5-0.7,0.1-2.3,1.1-2.2
    		c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.5,1.3,0.1,1.8-0.1c-0.2,0.1-1.9-0.6-2-0.7c-0.9-1.5,1-1.1,1.7-0.7c-1.9-0.7,0.4-3.6,2.6-1.4
    		c-0.3-1.2-2.1-1.7-3-1.6c1,0,2.8-1,3.8-1.4c0.9-0.3,0.6,0.2,1.2,0.5c0.4,0.2,1-0.1,1.4,0c-1.2-0.3,1.4-0.8,2-1.4
    		c-2,0.5-4.1,0.7-6.1,1.2c0.5-0.8,1.4-0.9,2.2-0.9c-0.8-0.1-1.6,0.1-2.4-0.1c0.7-0.5,0.6-1-0.2-1.4c0.7-0.5,1,0.4,1.7,0.4
    		c0.2,0,2.2-0.9,2.3-1.1c-1.2-0.3-2.3,1-3.2-0.4c0.5-0.1,1.1-0.2,1.6-0.1c-0.4-0.1-0.8-0.3-1.2-0.4c1-0.3,2,0.3,3-0.1
    		c-0.3,0-2.5-0.1-0.7-0.3c-2-0.2,1.2-0.3,1.5-0.3c-0.8-0.1-1.5-0.6-2.3-0.9c0.8-0.4,1.8-0.8,2.8-0.6c1.2,0.2,2-0.8,3.2-0.3
    		c-0.5-0.3-1-0.4-1.6-0.4c0.3-1,3.6-1.5,3.6-0.8c0.9-0.5,2.2-0.2,2.9,0.4c0-0.8,0.1-0.9,0.9-0.6c-1-0.2-2-0.6-3-0.6
    		c-1.4-0.1-2.5,0.6-3.9,0c0.6-0.2,0.9-0.8,1.5-0.9c0.7-0.2,1,0.7,1.5-0.1c-0.5,0-1,0-1.5-0.2c0.7-0.4,1.6-0.9,2.4-0.4
    		c-0.3,0.1-0.6,0.3-0.9,0.4c0.7-0.2,1.6-0.3,2.3-0.1c0.7,0.2,1.2,1.1,1.9,0.8c-1.6-0.1,0.2-1.4,0.9-1.4c-1.3-0.1-2.5,0.5-3.8,0.1
    		c2.4-0.5-1.1,0,0.3-1c0.4-0.3,1.2,0.1,1.7,0c-1.8-0.3,0.8-0.3,1.5,0.1c-1.8-1.9-3.6,0.2-5.7,0.2c0.3-0.5,0.9-0.9,1.5-0.9
    		c-0.6,0-1.1-0.2-1.6-0.6c0.5-0.1,1-0.1,1.5-0.1c-2.4-0.1,1.9-0.6,2.4-0.6c0.7-0.1,2.2,0.4,2.5-0.6c-1.2-0.6-2.5-0.1-3.8-0.2
    		c0.5-0.9,1.4-0.6,2.2-1c-1.2,1.6,1.3-0.8,2.1-0.3c1,0.6,0.2,2.6,1.9,2.5c-0.6-0.1-1-0.6-1.2-1.2c0.3,0.3,2.8,1.2,0.7,0
    		c0.8,0.5,1,0.3,0.6-0.5c0.5,0.2,1.1,0.2,1.6,0.1c-0.8-0.1-1.7-0.2-2.4-0.4c0.4-0.2,0.8-0.3,1.2-0.4c-0.5-0.1-1.1-0.2-1.7-0.2
    		c1.1-0.1,1.1-0.3,0-0.7c1.1,0.3,2.1,1,3.2,1.2c-1-0.3-1.9-0.9-2.7-1.6c1.2-0.1,2.3-0.4,3.5,0.1c0.5,0.1,1,0.1,1.5,0.1
    		c0.5,0.1,0.4,0.6,1.1,0.6c-0.4,0-0.6-0.2-0.6-0.5c0.2-0.5,1.3-0.3,1.7-0.1c-2-0.4,1.1-0.5,1.5-0.4c-1.6-0.5-2.6-0.5-4.1-0.3
    		c-1,0.1-2.7,0.3-3.6-0.4c1-0.2,0.6-1.3,2-1.5c1.3-0.2,2.8-0.1,4.1,0.1c-0.5-0.2-1-0.5-1.6-0.6c0.7-0.5,1.5-0.1,2.2,0
    		c-1.3-0.2-0.7-0.5-0.1-0.9c-2,0.2,0.3-2.1,1.9-1.8c-1.9-1.4,2.5-3.9,2.2-0.6c0.2-1.3,1.7-1,2.7-0.6c-1-0.5-2.3-0.5-2.9-1.6
    		c0.9-0.2,1.7,0.1,2.4,0.5c0.9,0.5,2.8,0.5,2.2,1.5c0.6-0.1,1-1.1,0.2-1.1c-0.9,0-1.7,0.2-2.2-0.7c0.4-0.5,0.9-0.6,1.5-0.4
    		c-0.5-0.2-1-0.3-1.6-0.1c-0.2-2,2.6-1.8,3.9-2c-0.1,1.1,0.1,1.9-0.4,3c0.7-0.5,1-1.8,2.1-1.3c-1.6-0.1-0.4-2.4-0.1-1.3
    		c0.3-0.8,1.4-0.5,1.5-1.5c1.9,0.8-0.7,4.2-1.4,5c0.8-0.5,1.8-0.7,2.2-1.7c0.4-1,1.9,0.1,2.6,0.2c-2.4-0.8-1.4-2.9,0.7-3.3
    		c-0.3,0.2-0.5,0.4-0.7,0.6c0.9,1.2,1-0.6,2,0.1c-1.7-2.5,4.6,0.7,5,0.7c-0.4-0.4-0.8-0.7-1.4-0.7c0.9,0,1-0.1,0.2-0.2
    		c-0.4-0.3-0.5-1.4,0.6-0.5c-0.3-0.8-2.2-0.8-0.5-1.2c-1.2,0.4-3.6,0.1-4.5-0.9c0.5-0.2,1-0.1,1.5,0.1c0.1-0.3,0.1-0.5,0.2-0.8
    		c0.6,0.3,1.1,0.6,1.6,0.9c0.2-1.8,1.6,0,2.5-0.7c-0.2,0.9,0.8,1.1,1.4,1.2c-0.6,0-0.9-0.4-1-0.9c1-0.2,2.1,0.2,3,0.4
    		c-0.2,1.1-1.8,0.9-2.7,1c1.1,0,2.2,0,3.2-0.6c0.1,1.9,1.9,1.1,3.1,1.1c-0.1-0.4-1.2-0.3-1.7-0.7c0.7-0.4,1.3-0.8-0.1-0.9
    		c0.3,0,2.8-0.2,0.7-0.4c0.8,0.2,1.3-0.9,1.9-1.3c0.9-0.5,1.9-0.3,2.9-0.3c-1.5-1.2,1.2-1.1,1.8-1.4c-0.7-0.4-1.6-0.3-2.2-0.8
    		c0.8-0.7,1.6,0.4,2.4-0.4c-2.1-0.4,1.1-0.8,1.9-0.6c0,0,2.9,2,1.5,0.1c1-0.1,2,0.9,3,0.4c-0.6,1.6-2.9,1.7-4,2.9
    		c0.6-0.1,0.9,0.1,0.9,0.7c-0.4,0-1.5,1.1-1.4,1.9c0.5-0.3,1.5,0.3,1.7-0.1c0.2-0.4,0.5-0.6,0.9-0.8c0.4-0.3-0.2-0.5,0-0.9
    		c1.2-1.5,4.1-3.7,6-4.2c0.3,1.2,0.4,1.9-1.1,2c1.4-0.2,1.3,1.4,0.9,2.2c0.6,0.1,0.7-0.3,1-0.6c0.5-0.5,1.3-0.1,1.9-0.2
    		c-0.2-0.3-0.4-0.5-0.6-0.8c0.5-0.1,1-0.1,1.5,0.1c-0.6-0.1-0.9-0.6-0.9-1.2c0.9,0.1,1.5-0.8,2.4-0.4c-0.6-0.7-1.5-0.6-2.3-0.8
    		c0.6-0.1,5.1-2.5,5.4-0.9c0.1,0.3,1.4,0.4,1.7,0.6c0.7,0.7-3,1.1-3.2,1.3c0.6-0.1,1.1,0.1,1.5,0.4c-0.8,0.2-1.5,0.6-2.3,0.7
    		c1,0,2.4-0.9,3.3-0.1c-0.7,0.5-1.6,0.8-2.4,1.2c1,0.1,1.9-0.3,2.9-0.1c-0.5,0.3-0.8,0.8-0.7,1.4c-0.1-1,0.9-1.6,1.8-1.5
    		c-1.9,0,0.3-2.2,0.9-2.6c0.8-0.5,3.8,0,3,1.4c0.7-0.2,2.3-1,2.6,0.2c1-1,3.6,0.4,1.9,0.8c1.6-0.1,3.9-0.3,5.2,0.9
    		c0.8,0.8-1.8,1.1-2.2,1.2c-1.7,0.3-2.6,1.3-4.5,1.1c-1.5-0.2-4.4-1.3-5.7-0.2c0.9,0.1,1.8,0.2,2.8,0.4c0.6,0.1,3.9,0.2,1.9,0.9
    		c0.8,0.1,2.8,0.6,0.8,1.4c0.7-0.1,1.3-0.2,2-0.3c0.1,0.6,0.4,0.6,1,0.6c-0.1-0.4-0.2-0.8-0.2-1.2c0.8,0,1.7,0.1,1.9,1
    		c0.4-1.3,2.2-0.8,2.6,0.2c0.7,2.1-3.6,0.7-4,0.2c0.8,1.5-0.9,1.9-2,2.1c-0.6,0.1-2.1,0.1-2.2,0.8c0,0.4,0.2,0.9-0.9,1.6
    		c-0.6,0.3-1.1,0-1.5-0.3c-1-0.6,0.3-1.2,0.7-1.6c1-1,1.5-2.4-0.4-2.9c-1.5-0.4-3-0.6-4.4-1.2c-1.6-0.6-2.9-2.2-4.6-1.1
    		c-2.8,1.8-7.1-0.2-8.1,3.4c-0.2,0.8-0.4,1.6-0.5,2.5c0,0.5,0.5,1.4,0,1.9c-0.6,0.5-1.2,0.2-1.8,0.6c-0.6,0.3-1,1-1.4,1.5
    		c-1,1.2-2.7-0.2-3.9-0.5c-0.8-0.2-1.6-0.4-2.3-0.6c-0.9-0.3-1,0.5-1.7,0.8c-2.2,1-4.5,0.8-6.8-0.2c-0.9-0.4-2.6-2.1-3.5-2.8
    		c-1.6-1.2-3-1.5-4.8-0.6c0.2,0.2,0.5,0.4,0.7,0.6c-1.4,0.8-3.8,0-5.4,0.7c2.2,0.9,0.4,2.6-0.8,3.3c0.5,0.1,1,0.3,1.5,0.5
    		c-2.4,1.9-5.4-0.3-7.9-0.2c-1.3,0.1-2.1-0.8-3.4-0.3c-0.9,0.3,0.2,1.8,0,2.5c-0.5,2.5-3.7,0.6-5.3,1.1c-2.6,0.9-3.6,3.5-6,4.6
    		c0.6,0.5,2.1,1.4,1.9,2.4c-0.2,0.8-3.1,2.6-3.8,3.4c-0.5,0.5-0.8,0.7-1.4,1c-0.7,0.4,0.4,1.2,0,1.8c-0.9,1.2-4,1.2-5.3,1.1
    		c0.2,0.8,0.9,5.8-0.1,6.4c-0.8,0.4-1.2,1.8-1.9,2.5c-1,0.9-1.9,1.9-2.8,2.9c1.4,0.5,4,1.4,2.9,3.4c-0.8,1.4-4.7,0.4-6.1,0.6
    		c-1.3,0.2-5,2.2-5.1,3.8c0,0.8-0.8,1.4-0.7,2.2c0,0.6,1.3,2.1,0.8,2.5c-1,0.7-0.4,2.7,0.1,3.7c1,2.1-0.2,3.5,0.1,5.5
    		c0.1,1.3,1.8,1.7,2.8,2.1c0.5,0.2,1.3,0.5,1.4,1c0.1,0.4-0.7,2.5-1.2,2.5c-1.3,0-2.6,0.4-2.1,1.9c0.3,0.9,1.2,1.5,1.6,2.4
    		c0.4,1.1-0.5,1.6-0.4,2.6c0.1,0.9-0.9,1.6-1.6,1.9c-1.2,0.4-2.2-0.1-2.1,1.2c0,0.7-0.4,1.2-0.7,1.8c-0.4,0.7,0.3,1.7,0.2,2.4
    		c-0.1,0.7-0.4,3.3-1.6,3.1c-0.5-0.1-0.9-1-1.2-1.4c-0.3-0.4-0.9-0.4-1.4-0.6c-1.3-0.7-2.2-0.6-2.7-2.1c-0.2-0.7-0.3-1.4-0.3-2.1
    		c0-0.4,0.5-2.3,0.7-0.6c0.7-1.4-1.1-1.9-1.3-0.4c-0.1,0.8,0.9,1.6,0,2.2c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1,0.6-0.5,0.8-1.1,0.5
    		c1.1,1,1.2,1.6,0.9,3c0-1.4-0.1-0.2-0.6,0.2c-0.4,0.3-0.9,0.5-1.4,0.8c-0.7,0.5-0.2,0.9-1.2,0.4c-0.7-0.3-1.3-0.7-2-1
    		c1.9,0.8-0.4,2.2-1.5,2.1c0.2,0.2,0.3,0.5,0.5,0.7c-0.6,0.2-1.2,0.3-1.9,0.3c0.1,0.3,0.2,0.5,0.4,0.8c-1.4,0.2-1.9,1.7-3.1,2.4
    		c-1.4,0.8-2.9,1.2-4.5,1.8c-0.7,0.2-1.5,0.8-2.2,0.8c-0.7,0-1.3-0.4-2.1-0.4c-1.1,0-3.2,0.4-3.9-0.7c0.5-0.4,1-0.8,1.6-1
    		c-2.5,1.6-10.6-3.5-7.1-5.4c-0.2-0.2-0.5-0.4-0.7-0.5c1.5-0.1,2.6,1.3,4.1,1.2c-0.3-0.2-0.5-0.4-0.8-0.6c0.9-0.5,2.1-0.7,3.1-0.9
    		c-1.2,0.1-2.4,0.3-3.4,1.1c0.1-0.6-0.2-1.2-0.8-1.4c0.5-0.2,1-0.3,1.5-0.4c-1.8-0.4,1.6-1.3,2.2-1.4c-0.5,0-1,0.1-1.5,0.2
    		c-0.9,0.3-1.3-0.1-0.1-0.2c-0.5,0-1,0-1.5-0.1c1.1-0.7,2-1.4,3.2-1.8c-0.7,0.2-1.4-0.5-1.8,0.3c-0.3,0.6-1.3,1.1-1.9,1.4
    		c0-0.6,0.4-0.9,0.9-0.9c-0.8,0-1.7-0.1-2.3,0.5c0.6-0.3,0.9,0,0.9,0.6c-0.5-0.1-1.1,0.1-1.5,0.4c0-0.6,0-1.1,0-1.7
    		c-0.3,0.1-0.7,0.3-0.9,0.5c1.9,0.2-0.7,2.4-0.1,0.4c-0.1,1.6-1.1,0.1-1.1-0.5c0-0.9,0.8-1.7,1.7-2c-0.1,0.7-0.2,1.6-1.2,1.6
    		c0.8,0.5,1.1-0.1,1.3-0.7c0.3-0.9,1.6-0.1,2.3-0.5c-2-0.2,1-0.7,1.4-1c-0.8,0.5-2,0.2-2.7-0.3c0.7-0.9,2.1-2.2,3.3-2.2
    		c-1.9-0.6,1.7-2.1,2.3-2.5c0.3,0.8-0.2,1.5-0.4,2.2c1.2-1.4,1.6-2.6,3.6-2.7c-1.6-0.3-3.3,0.1-4.7,0.6c-0.4,0.2-1.8,1.2-2.1,1.7
    		c-1.9,3.3-0.9-0.2-2.4,0c0.2-0.5,0.3-1,0.4-1.4c-0.2,0.5-0.9,2-1.8,1.7c-0.7-0.3-0.4-1.5-1.2-1.7c0.5-0.1,1-0.6,1.2-1.1
    		c0.7,2.1,2.7-0.4,2.2-1.8c-1,0.2-1.5,1.4-2.6,1.4c-1.1,0-1.1-1.6-2-1.9c0.9,0,1.9,0.4,2.4,1.1c-0.1-0.6-0.3-1.7,0.7-1.7
    		c-0.9-0.2-2.6,1.2-2.8-0.5c0.7,0.5,0.8,0.5,1.1-0.3c-0.8,0.2-1.4-1,0.2-0.7c1.7,0.3,2.8-0.4,4.4-0.4c1.2,0,2.7,0.7,3.8,0
    		c1.2-0.8,3.4,1,1.8,1.5c0.5-0.3,1-0.3,1.6-0.1c-1.6-1.2,2.3-2,3-2c-2.2-0.3-1.3-1.1-0.2-2.2c-1,0.4-2.2,1.4-1.3,2.5
    		c-0.8-0.1-1.5,0.3-2.3,0.1c0.2-0.2,0.5-0.5,0.8-0.6c-0.7,0.5-4.3,0.6-2.7-0.7c-0.9,0.1-0.7,0.9-1.2,1.4c-0.7,0.7-1.8,0-2.6-0.2
    		c-0.9-0.1-2.5,0.4-3.3,0.6c-0.9,0.3-1.7-0.6-2.5-0.8c1.4,0,0.6-0.5,0-0.9c0.9-0.4,2-0.6,3-0.5c-1-0.3-2.1,0-3,0.4
    		C1348.1,174.7,1347.9,174.5,1347,174.9"
          />
          <path
            id="path6606"
            class="countryIsland"
            d="M1347.1,177.1c-0.2-0.2-0.5-0.5-0.8-0.6C1346,177.3,1346.3,177.5,1347.1,177.1"
          />
          <path
            id="path6608"
            class="countryIsland"
            d="M1347.4,183.2c-0.3-0.2-0.6-0.5-0.9-0.7c0.2,0.5,0.3,1,0.3,1.6
    		C1347.3,184.1,1347.6,183.8,1347.4,183.2"
          />
          <path
            id="path6610"
            class="countryIsland"
            d="M1346.7,181.8c0.3,0.2,0.5,0.4,0.8,0.6C1347.3,182,1347.1,181.8,1346.7,181.8"
          />
          <path
            id="path6612"
            class="countryIsland"
            d="M1346.8,181.4c0.2,0,0.5-0.1,0.7-0.1C1347.3,181.3,1347,181.3,1346.8,181.4"
          />
          <path
            id="path6614"
            class="countryIsland"
            d="M1348,170.6c0.2-0.7-0.3-0.7-0.8-0.5C1347.5,170.3,1347.8,170.5,1348,170.6"
          />
          <path
            id="path6616"
            class="countryIsland"
            d="M1347.7,191.4c-0.4,0.6-0.2,1.1,0.4,1.4c0.3-0.6,0.3-1.5-0.3-2
    		C1347.8,190.9,1347.7,191.1,1347.7,191.4"
          />
          <path
            id="path6618"
            class="countryIsland"
            d="M1348.1,187.4c-1.1,0-0.5,1-0.7,1.7c0.2-0.6,0.6-0.9,1.2-0.6
    		C1348.7,188,1348.4,187.7,1348.1,187.4"
          />
          <path
            id="path6620"
            class="countryIsland"
            d="M1348.7,186.2c-0.4,0.5-0.2,1.8,0.6,1.8C1350.3,188.1,1349.3,186.3,1348.7,186.2"
          />
          <path
            id="path6624"
            class="countryIsland"
            d="M1349.6,186c-0.2,0.1-0.5,0.1-0.7,0.1c0.7,0.1,1.3,0.8,1.8,0
    		C1351.2,185.4,1349.9,185.5,1349.6,186"
          />
          <path
            id="path6626"
            class="countryIsland"
            d="M1352.2,168c-0.5-0.1-1-0.2-1.5-0.1C1350.7,169,1351.9,168.7,1352.2,168"
          />
          <path
            id="path6628"
            class="countryIsland"
            d="M1353.4,167.1c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0.5-0.1,1-0.2,1.4
    		C1353.1,168.1,1353.4,167.7,1353.4,167.1"
          />
          <path
            id="path6630"
            class="countryIsland"
            d="M1354.9,166.6c-0.2-0.1-0.5-0.1-0.7-0.1C1354.4,166.5,1354.6,166.6,1354.9,166.6"
          />
          <path
            id="path6632"
            class="countryIsland"
            d="M1354.3,166.8c0.3,0.1,0.5,0.2,0.8,0.3C1354.9,166.8,1354.7,166.7,1354.3,166.8"
          />
          <path
            id="path6634"
            class="countryIsland"
            d="M1358.4,164.5c-0.3,0-0.5,0-0.8,0c0.3,0.2,0.7,0.3,1,0.4
    		C1358.5,164.7,1358.4,164.6,1358.4,164.5"
          />
          <path
            id="path6636"
            class="countryIsland"
            d="M1359,163.6c-0.3,0-0.5,0-0.8,0C1358.8,164,1358.8,163.6,1359,163.6"
          />
          <path
            id="path6638"
            class="countryIsland"
            d="M1362.9,162.8c0.2-0.2,0.5-0.3,0.7-0.5c-0.2-0.3-0.4-0.5-0.7-0.7c0.1,0.8-0.2,1-0.9,0.4
    		C1362,162.6,1362.3,162.9,1362.9,162.8"
          />
          <path
            id="path6642"
            class="countryIsland"
            d="M1366.4,159.7c-0.3-1.1-1.7-0.7-2.2-0.1C1364.9,159.8,1365.7,160.1,1366.4,159.7"
          />
          <path
            id="path6644"
            class="countryIsland"
            d="M1365.7,161.3c0.3-0.1,0.5-0.1,0.8-0.1c-0.5-0.5-1.1-0.6-1.5,0
    		C1365.2,161.2,1365.5,161.3,1365.7,161.3"
          />
          <path
            id="path6648"
            class="countryIsland"
            d="M1367,160.8c0.5,0.3,1,0.4,1.5,0.3C1368.4,160.2,1367.5,160.2,1367,160.8"
          />
          <path
            id="path6650"
            class="countryIsland"
            d="M1367.2,158.8c0.8,0.9,4.2,0.2,4.6-1c-0.5-0.4-1.2-0.4-1.9-0.3c-0.5,0.1-2.2,0.4-0.4,0.6
    		C1368.7,158.2,1367.8,158,1367.2,158.8"
          />
          <path
            id="path6652"
            class="countryIsland"
            d="M1370.3,156.7c-0.8-0.7-2.2,0.3-3,0.4C1368.4,157.1,1369.5,157.6,1370.3,156.7"
          />
          <path
            id="path6654"
            class="countryIsland"
            d="M1369,159.5c-0.2,0-0.5,0.1-0.7,0.1c0.3,0.2,0.6,0.3,0.9,0.4
    		C1369.1,159.7,1369,159.6,1369,159.5"
          />
          <path
            id="path6662"
            class="countryIsland"
            d="M1382.6,147.4c-0.3,0.2-0.5,0.3-0.8,0.5c0.5-0.1,1.1-0.3,1.6-0.5
    		C1383.1,147.4,1382.8,147.4,1382.6,147.4"
          />
          <path
            id="path6664"
            class="countryIsland"
            d="M1382.5,147.9c0.8-0.2,1.5,0.7,2.2-0.1C1384,147.4,1383.2,147.6,1382.5,147.9"
          />
          <path
            id="path6666"
            class="countryIsland"
            d="M1384.7,151c0.2,0,0.5-0.1,0.7-0.1c-0.6-0.2-1.2-0.5-1.7-0.9
    		C1383.7,150.6,1384.1,151,1384.7,151"
          />
          <path
            id="path6668"
            class="countryIsland"
            d="M1384.8,149.4c-0.3,0-0.5,0.1-0.8,0.1c0.2,0.2,0.5,0.4,0.8,0.6
    		C1384.8,149.9,1384.8,149.7,1384.8,149.4"
          />
          <path
            id="path6670"
            class="countryIsland"
            d="M1385.1,150c-0.2,0.5,0,0.6,0.7,0.4C1385.6,150.3,1385.3,150.2,1385.1,150"
          />
          <path
            id="path6672"
            class="countryIsland"
            d="M1387.6,146.1c-0.6-0.1-1.2,0.1-1.5,0.6C1386.7,146.7,1387.2,146.5,1387.6,146.1"
          />
          <path
            id="path6674"
            class="countryIsland"
            d="M1387.5,142.2c0.3,0.2,0.6,0.3,1,0.2C1388.3,141.5,1387.9,141.7,1387.5,142.2"
          />
          <path
            id="path6676"
            class="countryIsland"
            d="M1391.7,140.3c0.6-0.3,1.2-0.5,1.9-0.6c-1-0.4-2.1-0.3-2.6,0.8
    		C1391.2,140.4,1391.4,140.4,1391.7,140.3"
          />
          <path
            id="path6678"
            class="countryIsland"
            d="M1392.2,138.4c-1.4-1.1-1.2,1.6,0.3,0.4C1392.4,138.6,1392.3,138.5,1392.2,138.4"
          />
          <path
            id="path6680"
            class="countryIsland"
            d="M1393.8,124.5c-0.2,0.2-0.4,0.5-0.5,0.7c0.9,0.6,1.9-0.5,1.5-1.4
    		C1394.4,124,1394.1,124.2,1393.8,124.5"
          />
          <path
            id="path6682"
            class="countryIsland"
            d="M1394.2,137.9c-0.2,0.1-0.5,0.2-0.7,0.3C1393.8,138.2,1394,138.1,1394.2,137.9"
          />
          <path
            id="path6684"
            class="countryIsland"
            d="M1394.8,137.6c-0.3-0.1-0.6,0-0.8,0.2C1394.3,137.7,1394.5,137.6,1394.8,137.6"
          />
          <path
            id="path6686"
            class="countryIsland"
            d="M1395.2,124.5c0.7,0.1,1.2-0.3,1.4-0.9C1396,123.2,1395.1,123.7,1395.2,124.5"
          />
          <path
            id="path6688"
            class="countryIsland"
            d="M1396.2,133.4c0.5,0,1-0.1,1.5-0.1C1397.2,133.1,1396.7,133.2,1396.2,133.4"
          />
          <path
            id="path6690"
            class="countryIsland"
            d="M1397.3,123.4c-0.2,0.2-0.3,0.5-0.5,0.7c1.1-0.8,2.8-0.4,3.7-1.4c-0.7,0.1-0.9-0.5-1.5-0.5
    		c-0.6,0-0.9,0.5-1.6,0.3c0.4,0.5,0.2,0.7-0.5,0.6C1397,123.2,1397.2,123.3,1397.3,123.4"
          />
          <path
            id="path6692"
            class="countryIsland"
            d="M1400.7,122.7c0.5-0.5-0.3-0.6-0.7-0.6C1400.3,122.2,1400.5,122.5,1400.7,122.7"
          />
          <path
            id="path6694"
            class="countryIsland"
            d="M1400.4,130.9c0.2,0.8,0.8,1.1,0.8-0.1C1400.9,130.8,1400.6,130.9,1400.4,130.9"
          />
          <path
            id="path6696"
            class="countryIsland"
            d="M1401.2,122.8c1.2,0.9,3.9-0.5,4.5-1.7c-0.6,0-1.1,0.2-1.5,0.6
    		C1403.7,120.3,1401.7,122.5,1401.2,122.8"
          />
          <path
            id="path6698"
            class="countryIsland"
            d="M1404.5,120.3c0.9,0,2.1-0.2,2.8-0.9c-0.7-0.2-0.6-0.3-0.4-0.8c0.2-0.6-1.2-1.2-1.5-1.4
    		c0,0.5,0,1-0.2,1.5c-0.8-0.9-2.3,0.5-3.4,1c1.1,0.9,2.6-0.9,3.8-0.4c-0.5,0.2-1,0.4-1.5,0.4C1404.2,120,1404.3,120.1,1404.5,120.3"
          />
          <path
            id="path6700"
            class="countryIsland"
            d="M1403.6,120.9c0.5,0.1,0.9-0.1,1.2-0.4c-0.7-0.3-1.4-0.3-2,0.1
    		C1403.1,120.7,1403.3,120.8,1403.6,120.9"
          />
          <path
            id="path6702"
            class="countryIsland"
            d="M1404.8,118.1c0.1-0.6-0.2-0.6-0.8,0C1404.2,118.1,1404.5,118.1,1404.8,118.1"
          />
          <path
            id="path6704"
            class="countryIsland"
            d="M1404.1,122.9c0.3-0.1,0.5-0.2,0.8-0.4C1404.6,122.5,1404.3,122.6,1404.1,122.9"
          />
          <path
            id="path6706"
            class="countryIsland"
            d="M1405,125.3c0.5,0,1-0.1,1.5-0.3C1406.1,124.6,1405.3,124.5,1405,125.3"
          />
          <path
            id="path6708"
            class="countryIsland"
            d="M1407.4,119.4c0.1,0.2,0.2,0.5,0.2,0.7c-0.5,0.2-0.9,0.3-1.4,0.3c0.8,0.8-0.5,1.2-0.6,2
    		c0.7-0.6,1.5-0.9,2.4-1.2c-0.2,0.3-0.4,0.5-0.7,0.8c0.4,0,1,0.4,1.4,0.3c0.5-0.2,0.8-1,1.2-1.4c-0.1,1.7,0.7,0.2,1.1-0.2
    		c0.4-0.5,1.1-0.3,1.6-0.3c1,0,1.9-1.4,0.9-2.1c-0.4-0.3-1.4-0.5-1.8,0c-0.5,0.7-1.7,1.2-2.5,2.1c0-0.2,2.3-2.8,0.4-1.4
    		c0.7-0.8,0.4-2.1-0.9-1.5c-0.6,0.3-0.9,1.7,0,1.8C1408.3,119.3,1407.8,119.3,1407.4,119.4"
          />
          <path
            id="path6710"
            class="countryIsland"
            d="M1408.7,117.4c1-0.2,2.3-1,2.6-2.1c-1.4-0.7-3.5,1.2-3.9,2.4
    		C1407.9,117.9,1408.3,117.8,1408.7,117.4"
          />
          <path
            id="path6712"
            class="countryIsland"
            d="M1408.7,124.3c-0.2,0.1-0.5,0.2-0.7,0.3c0.7,0.1,1.5,0,2.2-0.1
    		C1409.7,124.4,1409.2,124.4,1408.7,124.3"
          />
          <path
            id="path6714"
            class="countryIsland"
            d="M1411.4,121.2C1413.5,122.7,1413,119.3,1411.4,121.2"
          />
          <path
            id="path6716"
            class="countryIsland"
            d="M1413.4,118.1c0.2-0.1,0.5-0.2,0.7-0.4c-0.5-0.3-1-0.2-1.5,0.1
    		C1412.9,118,1413.1,118.1,1413.4,118.1"
          />
          <path
            id="path6722"
            class="countryIsland"
            d="M1415.9,115.2c0.3,0,0.5-0.1,0.8-0.1c-1.1,0.2-1.1,0.5,0,0.7c-0.5,0.2-1,0.4-1.5,0.5
    		c0.9,0.5,2,0.3,2.8-0.2c1-0.6,2.3,0,3.4-0.3c-0.9-0.2-1-0.4-0.1-0.7c0.5-0.5,0.7-1.7-0.5-1c1.8-0.5-0.1-0.6-0.7-0.9
    		c-1.9-0.9,0.4,1.5-1.2-0.2c1.6,1.4-1.1,0.1-1.9,0.5c0.4,0.4,1,0.6,1.6,0.5c-1.1,0.4-2.3,0.2-3.4,0.4
    		C1415.8,114.5,1416,114.6,1415.9,115.2"
          />
          <path
            id="path6724"
            class="countryIsland"
            d="M1416.7,119.1c-0.1-1.1-1.2-0.5-1.5,0.1C1415.7,119.2,1416.2,119.1,1416.7,119.1"
          />
          <path
            id="path6726"
            class="countryIsland"
            d="M1416.8,118.2c0.4,0.5,1.1,0.6,1.7,0.2C1418.1,118,1417.1,117.4,1416.8,118.2"
          />
          <path
            id="path6728"
            class="countryIsland"
            d="M1425.8,112.3c0.4-0.2,0.8-0.4,1.2-0.7c-0.7-0.3-1.4-0.9-2.2-0.6c1,0.3,0.7,0.7,0.1,1.2
    		c-0.1-0.3-0.3-0.5-0.4-0.8c-0.5,0.3-1.6,0.9-0.1,0.9c-0.5-0.2-2.7,0.7-0.8,0.4c-0.6,0-1.1,0.2-1.6,0.6
    		C1422.7,113.5,1425.9,113.7,1425.8,112.3"
          />
          <path
            id="path6734"
            class="countryIsland"
            d="M1424.8,110c0.5-0.3,1-0.5,1.5-0.7c-0.5,0.1-1,0.1-1.5,0C1424.8,109.5,1424.8,109.8,1424.8,110"
          />
          <path
            id="path6736"
            class="countryIsland"
            d="M1425.6,110c-0.5,3,4.3,0.1,4.8,0c-0.7,0.1-1.2-0.1-1.6,0c-0.2,0-0.5-0.5-0.8-0.6
    		C1427,109.4,1426.3,109.8,1425.6,110"
          />
          <path
            id="path6746"
            class="countryIsland"
            d="M1426.8,108.6c0.2,0.2,0.5,0.3,0.8,0.4C1427.7,108.3,1427,107.6,1426.8,108.6"
          />
          <path
            id="path6748"
            class="countryIsland"
            d="M1428.2,109c0.3,0.1,0.6,0.1,0.9-0.1C1428.8,108.9,1428.5,109,1428.2,109"
          />
          <path
            id="path6750"
            class="countryIsland"
            d="M1430.7,110c-0.5,0.3-1,0.7-1.4,1.1c0.8-0.1,1.7-0.2,2-1.1C1431.2,110,1431,110,1430.7,110"
          />
          <path
            id="path6752"
            class="countryIsland"
            d="M1431.6,108.9c-0.6-0.5-1.4-0.7-2.2-0.7c1.2,0.6,1.5,2,3,1.1
    		C1432.1,109.2,1431.9,109,1431.6,108.9"
          />
          <path
            id="path6756"
            class="countryIsland"
            d="M1434,108.9c0.5,1.3,2.2,0.7,2.3-0.5C1435.5,108.1,1434.6,108.4,1434,108.9"
          />
          <path
            id="path6758"
            class="countryIsland"
            d="M1435.8,111.4c0.1-0.2,0.3-0.5,0.4-0.7c-0.6-0.2-1,0-1,0.6
    		C1435.4,111.3,1435.6,111.3,1435.8,111.4"
          />
          <path
            id="path6760"
            class="countryIsland"
            d="M1436.2,110c0.5,0.4,1.2,0.4,1.7,0C1437.5,110.1,1436.6,109.6,1436.2,110"
          />
          <path
            id="path6762"
            class="countryIsland"
            d="M1441.2,110c0-0.2-0.1-0.2-0.5-0.3C1440.8,109.8,1441,109.9,1441.2,110"
          />
          <path
            id="path6764"
            class="countryIsland"
            d="M1450.1,104.5c0.1-0.1,0.2-0.2,0.3-0.3c-0.7,0.1-1.5,0.2-2.2-0.1c1.6,1.8-3.6,0-1.2,1.2
    		c-0.8,0-1.3-0.6-2-0.1c-0.6,0.5-1.9,0.1-2.7,0.2c0.5,0.2,1,0.4,1.5,0.5c-0.2,0.2-0.5,0.4-0.7,0.6c1-0.7,2.4-0.1,3.5-0.3
    		C1448,106.2,1449,105.2,1450.1,104.5"
          />
          <path
            id="path6770"
            class="countryIsland"
            d="M1447.2,108.2c0.3,0,0.5,0,0.8,0c-0.4-0.8-2.1-1.1-2.9-0.8
    		C1445.6,108.1,1446.4,108.1,1447.2,108.2"
          />
          <path
            id="path6772"
            class="countryIsland"
            d="M1451.2,105.9c0-0.1-0.1-0.2-0.1-0.4c-1,1.2-2.6,0.6-3.8,1.5c0.8,0.6,1.8-0.4,1.8,0.9
    		C1449.7,107.6,1452.3,107,1451.2,105.9"
          />
          <path
            id="path6774"
            class="countryIsland"
            d="M1453.4,106.2c0.8-1.1-0.3-1.3-1.2-1.3C1450.3,104.8,1452.1,106.5,1453.4,106.2"
          />
          <path
            id="path6776"
            class="countryIsland"
            d="M1452.6,102.9c0.4,0.4,0.9,0.6,1.5,0.4C1453.8,103,1453,102.3,1452.6,102.9"
          />
          <path
            id="path6778"
            class="countryIsland"
            d="M1453.1,102.4c0.2,0.1,0.5,0.1,0.7-0.1C1453.5,102.3,1453.3,102.4,1453.1,102.4"
          />
          <path
            id="path6782"
            class="countryIsland"
            d="M1463.7,102.9c0.3,0,0.5,0,0.8,0c-1.1,0.1-0.8-0.4-0.4-0.9c-0.6-0.2-1.1-0.3-1.7-0.4
    		c-0.8,0-1.3,0.5-2.1,0.6c0.5,0.1,0.8,0.6,1.1,0.9C1462,103.6,1463.2,103,1463.7,102.9"
          />
          <path
            id="path6796"
            class="countryIsland"
            d="M1347.1,181c-0.2-0.1-0.5-0.3-0.7-0.4c0.6-0.3,1.1-0.1,1.4,0.4
    		C1347.6,181,1347.3,181,1347.1,181"
          />
          <path
            id="path6798"
            class="countryIsland"
            d="M1347.9,171.1c0.3,0.1,0.5,0.2,0.8,0.4c-0.8,0.1-1.6,0.1-2.4-0.2
    		C1346.8,170.9,1347.3,170.8,1347.9,171.1"
          />
          <path
            id="path6800"
            class="countryIsland"
            d="M1350.2,182.4c-0.5-0.2-1-0.4-1.4-0.8c0.7-0.4,1.6-0.6,2.2-1.2
    		C1351.2,181.3,1351.2,182.2,1350.2,182.4"
          />
          <path
            id="path6802"
            class="countryIsland"
            d="M1353.8,190.3c0.3-1.3,1.1-0.6,2.1-0.9C1355.1,189.6,1354.4,189.8,1353.8,190.3"
          />
          <path
            id="path6806"
            class="countryIsland"
            d="M1364,162.3c0.1-0.2,0.2-0.4,0.3-0.6C1364.5,162.2,1364.3,162,1364,162.3"
          />
          <path
            id="path6812"
            class="countryIsland"
            d="M1486.9,110.7c-1.4-0.4-0.7,1.7,0.9,0.4C1487.1,111.5,1486.8,111.4,1486.9,110.7"
          />
        </g>
        <g id="md" class="countryGroup">
          <path
            id="md-"
            class="country"
            d="M1488.6,282.3c0.7-0.4,0.7-0.1,0.9-0.8c0.2-0.4,0.9-0.4,1.3-0.4c1.1,0.1,2.4,0.2,3.4-0.6
    		c2-1.5,3.5,3.3,5.6,2.4c0.2,1.2,1-0.3,1.3,1c0.3-1.2,1.5-0.7,2.1,0c0.3,0.3,1.1,1.3,1.4,0.9c0.6-0.6,0.7,0.2,1.2,0.8
    		c1.1,1.5,0.6,1.9,1.6,3.2c0.3,0.4,0.2,1.2,0.6,1.6c0.4,0.4,0.5-0.6,0.9-0.1c0.8,0.9-1,4-0.6,2.8c0.7-2.2,5.7,2.9,3.6,3.6
    		c0.7,0.3,0.8,0.9,1.5,1.2c-1.6,1.7-4.6-2-5.7,0.2c-0.2-0.4-0.3-0.9-0.5-1.3c-2.6,0.9-0.5,2.8-1.9,4.7c-0.8,1.1-1.3,1.9-1.9,3
    		c-0.1,0.3-0.1,0.8-0.4,0.9c-0.5,0.1-1.5-0.3-1.6,0.6c-1-0.8-0.6-1.2-1-2.2c-1.1-3.1,0.5-5.1,0.1-7.3c-0.2-1.3-1.9-3.8-2.7-4.6
    		c-2-1.9-3.1-3-4.5-5.4C1492.1,284.7,1491.1,282.4,1488.6,282.3"
          />
          <g id="xp" class="countryGroup">
            <path
              id="xp-"
              class="disputed"
              d="M1501,283.9c0.3-1.2,1.5-0.7,2.1,0c0.3,0.3,1.1,1.3,1.4,0.9c0.6-0.6,0.7,0.2,1.2,0.8
    			c1.1,1.5,0.6,1.9,1.6,3.2c0.3,0.4,0.2,1.2,0.6,1.6c0.4,0.4,0.5-0.6,0.9-0.1c0.8,0.9-1,4-0.6,2.8c0.7-2.2,5.7,2.9,3.6,3.6
    			c-0.1,0-0.5,0-0.6,0s-2.7-1.3-3.4-1.7c-0.8-0.5-0.6-1.1-0.7-1.4c0-0.3-1.3-1.2-1.5-1.4c-0.2-0.2-0.1-0.9-0.1-1.4
    			c0-0.5-0.7-1.7-1.1-2.4c-0.4-0.7-0.7-2-1-2.3c-0.3-0.3-0.8-0.3-1.3-0.6c-0.4-0.3-0.8-0.8-1.1-1C1501,284.2,1501,283.9,1501,283.9
    			L1501,283.9z"
            />
            <circle id="xp_" class="circle" cx="1502.2" cy="289.1" r="4" />
          </g>
        </g>
        <g id="ua" class="countryGroup">
          <path
            id="ua-"
            class="country"
            d="M1528.1,248c-2.5,0.8-1.6,0.7-5.3,0.1c-0.3,2.7-1.8,2.3-3.2,1.9c-0.7-0.2-1.8-0.2-2.5,0
    		c-0.2,0.1-1.2,0.6-1.5,0.4c-1.6-0.8-2.8,0.8-3.4,2.1c-0.9,1.8,0.5,2.4,0.1,4c-0.3,1.3-2.5-1.2-3-1.3c-0.8-0.2-1.7,0.6-2.4,0.2
    		c-0.6-0.3-0.9-0.2-1.4,0.1c-1.7,1.2-1.6-0.5-2.7-1.1c-1.1-0.6-2.8-0.1-2.6,1.4c-0.6-0.2-0.6-1.1-1.1-1.3c-0.5-0.2-1.2,0.4-1.6,0
    		c-0.6-0.5-1.3-0.4-2-0.3c-1,0.1-1.5-0.6-1.6,0.6c-0.8-0.2-1.4-1-2.3-0.7c-1,0.3-1.6-0.4-2.2-1.1c-0.6-0.7-3.2-0.4-4.1-0.6
    		c-1.7-0.4-3.1-1.1-4.9-1c-2,0.1-4.1,0.3-6.1,0.3c-0.9,0-2.4-0.3-3,0.6c-0.5,0.7-1.2,1.3-1.9,1.7c-0.8,0.4-2.9-1.1-3,0.4
    		c-0.1,1.7,2.4,5.5,4.1,5.9c-0.4,0.2-0.8,0.4-1.2,0.6c2.1,1.9,0.2,2.5-1.4,3.5c-1.4,0.9-2.6,2.2-3.7,3.4c-1.4,1.4-3.1,2.8-2.2,4.9
    		c0.3,0.6-0.1,1.8,0.5,2.2c0.4,0.3,0.8,0.4,0.7,0.9c-0.9-0.7-1.9-1-2.3-0.7c-0.4,0.3-0.2,1.3-0.5,2c-0.6,1.3-1.9,2.3-1.7,3.8h0
    		c0.9,0.2,1,0.8,1.6,1.3c0.5,0.4,0.8,0.1,1.4,0.7c0.8,0.9,2.3,0.3,2.3,1.8c1.3-0.3,1.5-1.6,3-0.6c0.9,0.6,1.8,0.3,2.8,0.4
    		c1,0.2,1.5,0.5,2.5,0.4c0.8-0.1,1.7-0.4,2.5-0.1c1.7,0.6,2.7,3,4.5,1c1.2-1.4,3-1,4.7-1.2c1.3-0.1,2.1,0,2.5-1.3
    		c0.2-0.6,0.9-0.8,1.4-1c0.5-0.1,1.5,0,1.9-0.4c-0.2-0.8,0.5-0.9,1.1-0.8c3,0.7,3.7-1.1,4.8-0.8c1.3,0.4,2.9,3.3,4.4,2.6
    		c0.2,1.2,1.1-0.4,1.3,0.9c0.3-1.5,1.9-0.3,2.5,0.4c1.4,1.7,0.8-0.8,2.2,1.3c0.5,0.8,0.7,1.6,1.2,2.3c0.2,0.3,1.1,3,1.4,2.7
    		c0.9-1.2,0.9,1.3,0.5,1.9c1.6,0.2,4.9,3.3,3,4.2c0.7,0.3,0.9,0.9,1.6,1.1c-1.6,1.7-4.6-2-5.7,0.2c-0.2-0.4-0.3-0.9-0.5-1.3
    		c-1.9,0.7-1.6,1.1-1.4,2.6c0.2,1.7-0.8,2.6-1.7,3.8c-0.8,1.1-0.3,2.1-2.1,2.4c-1.7,0.3,0.6,1.5,0.9,1.7c2,1.4,2.4,0.1,4.2-0.4
    		c1.4-0.3,4-1.8,4.3,1.3c1.2-0.4,0.6-2.3-0.4-2.4c0.2-0.2,0.3-0.5,0.5-0.7c-1.4,0.7-0.8-1-0.3-1.5c0.2,2.2,1.9-0.9,2.9-1
    		c-0.1,0.5-0.5,0.9-0.8,1.3c1.4-0.9,3.1-2.2,2.8-3.2c-0.3-1.1-2-1.1-2.3-2.2c1-0.7,2.4,1.6,2.6,2.3c1.5-1.6,1.1-4.1,3.7-4.5
    		c0.6-0.1,1.8,0.4,2.2-0.1c0.3-0.4,0.4-1.2,1-1.3c-0.3,0.5-0.4,0.9-0.1,1.4c0.5-0.3,2.4,0.2,2.4-0.6c0-0.6,0-1.1,0-1.6
    		c0.1,0.7,0.2,1.3,0.6,1.8c1.2,1.2,3,0.5,4.3,0.1c-2,1.5-5.1,1.8-7.5,0.6c0.9,1.3,5.6,0.8,1.9,2.2c1.3-0.4,2.5,0.5,3.5,1.1
    		c1.2,0.7,2,0.5,3.4,0.5c1.2,0,2.7-0.6,3.8-0.1c0.7,0.3,1,0.5,1.2,0.4c0,0,0.1-0.1,0.2-0.1l1.5-0.9c-0.1-0.1-0.3-0.2-0.4-0.3
    		c3.9,0.7,3.3,0.2,3.2-0.5c0.2,0.2,1.1,1.2,1.2,1.5c0.1-1.1,0.5-0.5,0.8-0.4c0.3,0,0.7-0.1,0.7-0.1c0,0.3,0,0.3-0.1,0.8
    		c-1.4-1-0.1,3.2,0.9-0.8c1.3,0.5,0.2,1,0.3,1.3c0.1-0.3,1.3,0.7,1.5,1.4c0-0.2-0.8-2.8-0.5-3c0.8-0.5,2.6-0.8,1.7-2.2
    		c2.7,0.4-0.7,3-0.8,3.4c2.4-0.6,2.8-4,5.2-4.7c1.4-0.4,2.4-0.1,3.8-0.8c0.8-0.5,2-0.5,2.6,0.4c0.3-1.3,1.7-1.9,2.9-2
    		c1-0.1,1.1-1.1,2.1-1.3c1-0.2,3.1,0.3,3.9-0.2c0.6-0.4,0.1-1.2,0.6-1.7c-0.5,0-1.7-1.3,1.2-3c1-0.3,1-1.3,1.7-1.6
    		c0.8-0.4,1.4,0.1,2.2-0.1c1.3-0.3,3.4,0.9,4.2-0.5c0.3-0.4,1-2.8,0.8-3.4c-0.4,0.1-0.7,0-1-0.2c1.4-1-1.3-2-1.5-2.3
    		c-0.5-1.1,1-1.9,1.8-1.6c0-1.6-1.3,0-1.9-1.3c-0.5-1,0.8-0.8,1.3-1.2c1.8-1.3-0.5-3.4,0.5-4.4c-0.8-0.3-1.1,0.6-2.2,0.3
    		c-0.6-0.2-1.3-0.5-1.8-1c-0.9-1-2.1-0.1-2.8-1.6c-0.9,1-3.7-0.2-4.8-0.7c-2-1.1-1.3-0.4-3,0.6c-0.4-1.2-2.3-1.5-3-2.8
    		c-0.4-0.8-1.2-2-2.4-1.7c-2.1,0.5-3.5,1.8-4.6,1.8c-1.6-0.5-3-2-4.3-1.8c-0.8,0.1-1.8,1.2-2.9,0c-2.4-4.5-1.8-4.7-1.8-5.1
    		c-1.3,0.1-2.1-1.9-3.4-1.3c-1.1,0.6-3.5-0.3-4.4-0.6c1.1-0.6-1.3-3-1.1-3.3c0.4-0.4,1.4-0.5,2-0.8c-0.7-1.4-1.9-0.7-3-3.2
    		c-0.3-0.7-1.6-2.1-2.3-1.7C1530.5,248.8,1529.3,248,1528.1,248L1528.1,248z"
          />
          <g id="xd" class="countryGroup">
            <path
              id="xd-"
              class="disputed"
              d="M1566.7,281c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3
    			c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
    			c-0.1,0.2-0.1,0.4-0.1,0.5c0,0,0,0,0,0c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.2,0c-0.2,0.1-0.4,0.2-0.6,0.3
    			c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.1,0.5c0.1,0.2,0.2,0.4,0.4,0.5c0,0.2-0.1,0.3-0.1,0.5
    			c0,0,0,0,0,0c-0.1,0.3-0.1,0.5,0,0.7c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0
    			c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.4,0.4,0.6,1.1,0.9,1.5c0,0.2,0,0.3-0.1,0.5c-0.2,0.5-0.5,0.9-0.4,1.4
    			c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.4-0.6,0.9,0.2,0.7c0.4,0.1,0.7,0.1,1.1,0.1c0.3,0,0.6-0.1,0.8,0c0.1,0,0.1-0.1,0.2-0.2
    			c0.1-0.1,0.1-0.1,0.1-0.1c0.1-0.2,0.1-0.5,0.2-0.8c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1-0.1-0.1
    			c-0.2-0.2-0.6-0.3-0.8-0.5c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.4
    			c0.2-0.2,0.4-0.4,0.8-0.6c0.7-0.2,1.1-0.8,1.7-1.4c0.2-0.3,0.3-0.4,0.4-0.5c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1
    			c0,0,0.1-0.1,0.2-0.1c-0.2-0.3-0.5-0.4-0.7-0.7c-0.2-0.3-0.4-0.3-0.6-0.7c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.3-0.3-0.3-0.4
    			c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3
    			c-0.1-0.2-0.2-0.4-0.3-0.5c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.2-0.3C1567.2,281,1566.9,280.9,1566.7,281
    			L1566.7,281z"
            />
            <circle id="xd_" class="circle" cx="1566" cy="286" r="4" />
          </g>
          <g id="xl" class="countryGroup">
            <path
              id="xl-"
              class="disputed"
              d="M1574,277.9c-0.4,0.1-0.4,1.4-1,0.6c-0.5-0.6-1.5-0.8-1.9-0.1c-0.6,0-1.3-0.1-1.6,0.5
    			c-0.8,0.2-1,1.1-0.3,1.6c0.4,0.5-0.2,1,0.5,1.4c0.2,0.5-0.3,0.7-0.4,1.2c-0.1,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.4
    			c0.2,0.2,0.2,0.4,0.4,0.6c0.3,0.4,0.5,0.5,0.6,0.7c0.2,0.3,0.5,0.4,0.7,0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.7-0.4,1.4,0.4,2.2,0
    			c0.4-0.2,0.7,0.1,1.1,0.1c0.6-0.2,1.7,0.5,2-0.4c0.8-0.3,0.7-1.2,1.1-1.5c0.1-0.7,0.1-1.2,0-1.8c-0.4,0.1-0.9-0.3-0.2-0.7
    			c0.2-0.6,0-0.5-0.5-1.1c-0.6-0.1-1.3-0.7-1.7-1.2c-0.4-0.5-1.3-0.3-1.2-1.1C1574.1,277.9,1574.1,277.9,1574,277.9L1574,277.9z"
            />
            <circle id="xl_" class="circle" cx="1569" cy="279" r="4" />
          </g>
        </g>
        <path
          id="np"
          class="countryGroup country"
          d="M1892.9,447.9c0.4-1.2,1.3-1.8,0.7-3c-0.4-0.8-0.3-0.9,0-1.7c0.6-1.6,0.6-1.2,1.3-2.2
    	c1-1.6,1.6-4.7,3.4-3.4c0.3,0.2,0.8,1,1.3,0.6c0.8-0.6,0.9-1.2,0.8-2.2c0.9,0.3,1.1-0.9,1.7-0.7c1.1,0.3,2,0.3,3.1,1.1
    	c2.2,1.8,4.5,3.7,6.6,4.8c1.7,0.9,3.1,1,4.3,2.4c1.2,1.4,2,3.3,3.7,2c0.8-0.7,1.9-0.1,2.6,0.5c0.9,0.7,1.1,2.3,1.5,2.7
    	c1.3,1.1,3.9,2.6,5.1,2.5c0.8,0,1.5-0.9,2,0.3c0.3,0.7-0.8,1,0.1,1.8c0.7,0.5,3.3,1.1,4.1,0.1c0.5,1.4,2.1,1.9,2.7,3.1
    	c0.8,1.6,0.8-1.3,1.1-1.6c0,0.9,2.3,2.7,3,1.5c0.2-0.5,0.4-1.2,1.2-0.8c0.7,0.4,1,0.2,1.6,0.6c2,1.3,2.4,1.1,3.8,1.2
    	c1.3,0.1,3.6-0.6,5.1-0.1c1.1,0.3,0,4.5,0.2,5.7c0.3,2.3,2.4,3.2,1.8,5.7c-0.4,2.1-2.1,1.1-4,1.3c-1.5,0.2-3.6,0.6-3.8-1.4
    	c-1.3,0.5-1.8,1.6-3.3,0.7c-0.7-0.4-4-1.6-4.8-1.3c-1.6,0.6-1.3-0.2-2.1-1c-1.3-1.3-2.2,0.7-3.5,0c-0.7-0.3-1.7-1.7-2.9-1.9
    	c-1.5-0.3-1.4-0.9-2-2.2c-0.6-1.2-7-3.1-6.2-1.1c-0.8-0.4-2-1.3-3-0.9c-1.3,0.7-1.7,0.8-3.2,0.1c-0.8-0.4-2-0.4-2.4-1.4
    	c-0.6-1.4-1.7-0.4-2.8-1.1c-1.1-0.6-2.1-1.8-3.1-1.6c-1.5,0.4-2.9-1-4.4-1.7c-1.3-0.6-2.1-2-3.4-2.8c-1.3-0.8-2.8-1.4-4.1-2.2
    	c0,1.3-1.5,0.1-2.2-0.4C1893.7,449.6,1892.3,448.7,1892.9,447.9"
        />
        <g id="er" class="countryGroup">
          <path
            id="path6816"
            class="countryIsland"
            d="M1603.6,561.3c0,0.6,0.6,2.3-0.4,1.4c0.4,1.3,2.6,1.2,3.7,1.1c-0.5-1.1-1.9,0-2.3-1.2
    		C1604.5,562.2,1604.4,560.8,1603.6,561.3"
          />
          <path
            id="Eritrea_mainland"
            class="country"
            d="M1577,567.5c1.2-1.1,1.1-2.8,1.6-4.2c0.5-1.6,1.1-3.1,1.7-4.6c0.5-1.4-0.9-7.2,1.3-7.5
    		c0.4-0.1,2.1,0.3,2.4-0.2c0.7-1.2,0.2-2.1,1.6-2.6c1.2-0.4,2.5-1.3,3.7-1.8c1.8-0.7,1.8-2.5,3.1-3.7c2.2,4.5,3.9,8.8,5,13.6
    		c0.3,1.2,0.2,2.3,0.8,3.4c0.6,1.2,1.1,2.1,1.3,3.4c0.2,0.9,1.3,1,1.5,1.9c0.2,0.9,0.4,2.4,1.2,3c1-0.7-0.6-3.3,0.4-3.4
    		c1-0.2,1.6,1.1,1.6,1.8c0.1,0.9,0.7,2.3,1.5,2.7c1.2,0.5,1.9-1,3.1,0.3c1,1.1,1.1,1.9,2.7,2.1c1.8,0.3,2.5,1.7,3.5,3.1
    		c0.9,1.4,1.8,2.8,3.2,3.7c0.7,0.4,1.3,1,1.9,1.5c0.3,0.3,1.7,2.2,1,0.5c1,0.6,1,2,1.3,2.9c0.3,0.8,1.2,0.6,1.7,1.2
    		c0.5,0.6,2.6,4.4,3.4,2.5c0.8,0.7,1.2,1.1,0.7,1.6c-1.2,0.5-2.1,1.3-2.1,1.9c-0.1,2.2-1.8-1-3.8,0.6c0.2-0.6-0.3-2.3-2.5-3.8
    		c-1.9-2.9-3.1-4.8-4.3-5.1c-2.1-0.6-2.7-3.1-4-4.8c-1.3-1.8-3.3-1.9-5-3.2c-1.6-1.2-2.5-0.2-4-0.6c-1.2-0.3-3.2-1.4-4.3,0
    		c0-0.9-1.8-2.7-1.4-2.1c0.2,0.3-1.3,1.9-2.4,2c-0.7,0-2.2,0.3-2.9-0.9c-1.3-2-1.8-0.4-3.3-2.9c-0.6,2.3-1.5,4.6-2.4,6.8
    		c-0.5-0.7-1.7-3.2-2.9-2.6c-0.7,0.4-0.7,1.1-1.7,1.1c-2.8-0.2-2.8-0.3-3.5,1.5c-0.5-2.5-0.7-6.4-0.7-8.8"
          />
        </g>
        <g id="us" class="countryGroup">
          <path
            id="United_States_Alaska_mainland"
            class="country"
            d="M274.9,226.2c-0.4,0.4-0.9,0.5-1.5,0.6c0.6-0.9-1-1.8-1.1-0.8
    		c-0.1,1.2-2,1.6-3.1,1.8c1.6-1.8,3.7-2.7,5.8-3.5c-1.9,0.6,0,0.5,0.4,0.3c0.7-0.4,1.2-0.7,2-0.9c1.9-0.4,2.8-1.3,4.5-2.1
    		c3.6-1.6,7.1-2.7,11-3.4c-1.9,0.4,1.8,0.1,2.3,0c-1.1,0.7-1.5,0.9-1.8,2.2c0.9,0.2,3.2-3,3.6-0.5c0.3-0.1,0.5-0.2,0.7-0.4
    		c-2.5-2,10.5-5.9,11.3-6.1c1.8-0.5,3.5-1.2,5.2-1.8c0.8-0.3,1.3,0,2.2,0.1c1,0.1,1.5-0.9,2.2-1.5c1.3-1.2,3.4-1.8,5-2.5
    		c2-0.9,4.1-1.9,6.3-2.2c-1.2,1.5,1.5,0.6,1.9,0.1c-0.5,0.3-1.1,0.5-1.7,0.4c0.8-0.4,1-1.4,1.6-2c0.7-0.7,1.7-1.1,2.4-1.7
    		c1.1-0.9,3.5-3,5.1-1.7c-0.4-0.5-1-0.7-1.5-0.4c0.1-2,3.5-2.9,5-3.4c3-1,5.3-2.9,8.1-4.2c-1.6,0.4-2.9,1.5-4.4,2.1
    		c-1.3,0.4-2.6,0.7-3.9,1.1c-1.3,0.4-4.7,2-6,0.8c0.5-0.3,0.6-0.7,0.5-1.3c-0.1-0.4,1.2-1,1.5-1.1c0.6-0.3,2.6-1.2,2.1,0.3
    		c0.4,0,0.6-0.2,0.7-0.6c-0.6-0.1-1.3-0.7-1.2-1.4c-0.3,0.7-1.1,1.2-1.8,1.5c-0.5,0.3-3.4,1.6-1.7,0.1c-1,0.9-3,1.6-3.6,2.8
    		c-0.4,0.8-1.6,2.1-2.7,1.6c-1-0.4,1.5-3.3-0.3-3.3c0.3-0.2,0.5-0.3,0.8-0.5c-1.1-0.5-2.1,0.7-3.1,1c0.2-0.3,0.3-0.6,0.5-0.9
    		c-0.6,0.2-1-0.1-1.2-0.6c0.3-0.1,0.5-0.3,0.8-0.4c-1.7-1-6,1.4-7.8,1.6c-1.3,0.1-2.3,1.1-3.6,1.4c-0.5,0.1-0.9,0.2-1.4,0.4
    		c-0.6,0.2-1.1-0.6-1.8-0.4c1.1-1,3.2-0.3,4.1-1.6c1.2-1.8,1.6-1.5,3.5-2.3c-0.7-0.1-1.5-0.1-2.2,0.1c0.7-2.3,3.5-2.1,5.1-3.2
    		c0.6-0.4,1.1-2.3,1.5-3c0.7-1,1.7-1.9,2.8-2.6c-0.5,0-1,0.1-1.4,0.4c2.1-2.5,5.3-3.1,8.1-4.4c-0.7,0.1-1.6,0.9-1.4-0.2
    		c-0.5,0.8-7.7,3.6-7.6,3.9c1.1,2.7-11.9,3.5-13.3,3.2c0.2-0.9,1-1,1.7-1.4c-1.1-0.3-0.8-2.2,0.1-2.6c-1.7,1-2.6-0.6-0.5-1.3
    		c-0.5-0.2-1-0.2-1.5,0c0.9-1.3,2.9-1.2,4.2-1.9c0.4-0.2,1-0.4,1.3-0.8c0.6-0.7,1.3-0.2,2-0.5c-1.2,1.2,0.3,0.5,0.9,1.2
    		c0.4,0.5-2.4,1.4-2.8,1.5c1.8-0.3,3.4-1.4,5.2-1.7c-0.2,0.6-0.6,1.1-1.1,1.4c0.9,0.2,2.6-0.2,3.3-0.9c0.6-0.7-0.2-1.2-0.8-1
    		c0.7-0.3,1.5-0.2,2.2-0.5c-1,0-1-0.2,0-0.6c-1.7,0-3.2,1.2-5,0.9c-1.2-0.2-2.9-1.1-4-0.3c0.3-1.1,2.5-1.4,3.4-1.5
    		c-0.6-0.2-1.1-0.1-1.5,0.3c0.1-0.6,0.4-1,1-1.2c-0.5,0.2-0.9,0.4-1.4,0.4c0.3-0.9,1.6-1.1,2.2-1.7c-1.7,0.2-2.8,2.6-4.6,2.2
    		c-0.1-0.9,1.1-1.1,0.4-1.8c-0.5-0.6,1.8-1.2,2.2-1.4c-0.4-0.4-0.9-0.5-1.4-0.3c0.4-1.6,2.2-1,3.4-1.2c-1.6,0.1,0.2-1.2,1.2-1.1
    		c1.5,0.1,3.3-2,4.7-2.6c1.9-0.8,3.8-1.7,5.8-2.4c0.6-0.2,1.3-0.6,2-0.4c1,0.2,0.3,1-0.3,1.2c0.6-0.1,1.1-0.3,1.6-0.6
    		c-2-0.3,3-2.1,3.7-2.3c-1.1,0.3-2.1,0.8-3.2,1.1c1.2-1.7,4.7-3.8,6.8-3.1c-1.2-1.3,2.9-1.8,3.5-1.9c0.6,0,1.3,0.1,1.9,0.5
    		c1,0.6-2.2,1.4-2.6,1.6c2.5-1.1,5.3-0.4,7.8-1.5c2.4-1.1,4.9-2.1,7.4-2.7c-0.3,0.2-0.5,0.5-0.8,0.7c3.8,0.1,7.3-0.4,10.7-2.2
    		c1.3-0.7,2.3-1.8,3.4-2.8c0.7-0.6,2.3-3.5,0-2.5c0.6-0.3,9.5-1.7,7.3-3.7c0.4-0.1,0.8-0.2,1.2-0.3c-1.3,0.1-2.5,0.7-3.7,1.2
    		c-1.2,0.4-2.6-0.2-3.8,0.4c2-0.4-0.8,0.4-0.9,0.4c-0.9,0.2-1.8,0.4-2.7,0.5c-1.9,0.4-3.5,1.6-5.3,2c0.9-1.1,1.3-2.7-0.7-1.9
    		c0.3,0.2,0.5,0.4,0.8,0.6c-0.8,0.3-1.2,1.1-1.8,0.3c-0.3-0.4-0.9-0.6-1.4-0.8c-1.2-0.3-2.4-0.2-3.6-0.1c-2.7,0.3-4.7,1-7.5,0.5
    		c-0.4-0.1-4.4-0.7-3.4-1.6c0.5-0.5,2-0.6,1.7-1.4c-0.5-1.4,0.8-2.6,2.1-2.8c-0.5,0.2-1,0.5-1.5,0.8c0.9,0.8,2,0.2,2.8-0.2
    		c0.9-0.5,1.9-0.3,2.9-0.2c-1.2-1.6-4-1.2-5.7-1.4c-0.6-0.1-1.4-1-1.6-1.5c-0.4-0.8,2.2-1.2,2.7-1.2c-0.8,0.3-1.5,0.5-2.3,0.7
    		c1.8,0.4,3.5-0.8,5.2-1.2c1.5-0.4,3.2-0.1,4.6-1c3-2.1,6.9-0.7,10.1-1.4c-0.3-0.2-0.5-0.3-0.8-0.5c1.5-1.7,4.3-1.5,6.3-1.7
    		c3.2-0.3,6.2-1.7,9.5-1.2c-0.8-0.1-2.1,0.9-2.7,1.4c-1.2,0.8-2.5,1.2-3.9,1.7c0.7-0.2,0.6,0.1,0.9,0.5c0.2,0.5,1.3,0.3,1.7,0.3
    		c0.9-0.1,1.8-0.1,2.6-0.1c0.6,0,3-0.4,1.2,0.7c1.9-1,4.2,0.3,6.1-0.5c0.9,0,3.2-2.4,5-1.2c-0.2,0.3-0.5,0.5-0.8,0.7
    		c0.8-0.3,1.7-0.5,2.1-1.4c-1.3-1.1-3.1-0.7-4.5-0.1c0.7-0.6,2.4-1,2.3-2.1c-0.1-0.9-1.3-0.8-0.6-1.7c1.2-1.7,3.3,0.1,1.7,1
    		c-3.3,1.8,2.7,1.5,2.7,1.5c1.5,1.5,5.4,1.2,6.3-1c-0.5,0.1-1.1,0.2-1.6,0.3c-0.7,0-1.3-0.6-2.1-0.5c-1.2,0.2-2.5,1.3-3.8,0.8
    		c-2.1-0.8,2.8-3.2,3.8-3.1c-0.4-1.6-2.8,0.1-2.8-0.9c-0.4,0.4-1,0.6-1.6,0.6c0.7-0.5,1.5-0.8,2.2-1.2c-0.9-0.1-1.6,0.6-2.4,0.9
    		c-1,0.4-3.1,0-4.2-0.2c-2.4-0.5-0.4-1.3,0.4-2.4c1.1-1.5,0.3-4.1-1.6-4.9c-0.6-0.3-1.7-0.1-1.9-0.9c-0.3-1.2-1-1.2-2.1-1.3
    		c2.6-0.2,4.8-1.5,7-2.7c2.5-1.4,4.6-1,7.4-1.2c6.2-0.4,11.5-1.9,17.3-4c-0.5,0.3-1,0.5-1.5,0.7c2.9-0.5,5.4-2.9,8.3-3.7
    		c3.5-1,6.8-3,10.4-3.5c-0.6,0.3-0.6,0.9-1.4,1c2.5-0.4,5.1-0.6,7.6-1.3c2.3-0.7,4.6-1.7,7.1-1.7c-0.6,0.5-1.5,0.9-2.3,0.9
    		c2.4-0.4-0.4,0.7-0.9,0.9c0.8-0.3,0.9,0,0.3,0.7c0.8-0.6,1.6-1.1,2.5-1.5c1.1-0.5,2.4-0.1,3.5-0.4c-0.7,0-1.5,0-2.2,0.1
    		c0.3-0.8-0.4-1.1-1.1-0.8c2.3-1,4.9-1.2,7.3-1.8c-0.5,0.3-1,0.5-1.6,0.6c3.3,0.8,8-0.2,11.2-1c4-0.9,7.8-2.4,11.7-3.5
    		c-0.6,0.2-1.1,0.4-1.6,0.8c0.4,0.2,3.7,0.5,2.9,1.3c-1.1,1.2-3.7,1.3-5.2,1.3c0.2,0.6-0.2,1-0.7,1.2c1.5-0.4,3-0.8,4.5-1.3
    		c0.9-0.3,1.8-0.7,2.7-1c0.4-0.1,3.1-1.2,1.1,0.2c0.3-0.2,1.5-0.6,1.8,0c0.3,1-1.8,1.2-2.3,1.2c1.5,1.4,3.8,0.3,5.5,0
    		c2.3-0.4,5.2-0.4,7.5,0.1c-0.8,0.9-2,1.1-3,1.6c0.5,0.2,0.9,0.4,1.4,0.4c-1,0.1-2.1,0-3.1,0.2c1.5,0.2,3.1,0,4.6,0
    		c-0.5,0.2-1.1,0.4-1.6,0.6c1.3,0.7,2.8,0.6,4.2,0.3c0.9-0.1,1.7-0.3,2.6-0.4c0.5-0.1,2.6-0.5,1.2,0.4c2.3,0,4.6-0.7,6.9-0.4
    		c0.7,0.1,1.8,0.3,2.4,0.8c0.9,0.7,2.1,0.1,3.1,0.7c2.2,1.3,4.6,0.6,7.1,0.9c0.8,0.1,2.5-0.4,2.8,0.5c0.1,0.4,2.3,0.1,1.1,0.7
    		c1.4,0,2.7,0,4.1,0c0.7,0,1.4-0.1,2-0.2c1.1-0.5,1.3-0.4,0.7,0.2c1.4-1.6,5.3-0.9,7-0.4c1.2,0.3,1.5,1.6,2.7,1.8
    		c0.9,0.2,0.9,0.3,1.4,1c0.6,0.8,1.5-0.3,2.2,0.2l-85.8,71.1c0.8,0.2,1.6,0.5,2.4,0.1c0.9-0.4,1.5,0.4,2.4,0.5
    		c1,0.1,2.2-0.7,3.2-0.9c1.2-0.3,2.3-0.3,3.5-0.1c-0.8,0.6-2,1-2.5,1.9c-0.6,0.9,0.9,1.1,0.8,2.2c-0.1,1.4,0.7,2.6,1.1,3.9
    		c0.4,1.2-0.8,2.4-1.4,3.3c2.5-0.9,5.1-1.3,7.5-2.4c1.1-0.5,2.1-1.1,2.9-1.9c0.4-0.1,0.7-0.2,1.1-0.2c0.5-0.2,0.5-0.8,1-1
    		c0.8-0.4,1.7-0.3,2.6-0.5c0.9-0.2,3.2-1.3,4-0.9c2.9,1.4-1.7,2.7-0.3,4.1c0.4,0.4,1.3,0.7,0.9,1.4c-0.3,0.6-0.8,1.8-0.2,2.3
    		c1.8,1.5,0.7,2.4,0.4,4.2c-0.3,1.6-0.8,3.2-1,4.9c-0.1,1.1,0,2.6-0.7,3.5c-0.5,0.6-1.4,1.3-0.2,1.8c-1.2,0.5-1.3,2.2-1.6,3.3
    		c1.3-0.2,3.4,1.3,4.4,2.1c1,0.8,0.8,2.2,2.5,2c-0.5,1.3-1.7,1.7-2.7,2.4c-1.4,0.9-1.7,2.5-2.8,3.6c-2.2,2.2-6,3.9-8.5,5
    		c0.4-0.5,0.7-1,1.2-1.5c-0.6,0.6-1.4,1.2-2.2,1.4c0.5-2.4,4.7-3.5,6.6-4.5c-0.5,0.2-5.1,2.4-4.8,1.1c0.2-0.9,3.2-1,3.8-0.9
    		c-0.5-0.1-1-0.1-1.5-0.1c0.9-1.1,2.8-2.5,2.9-4.1c0.1-1.7,0.9-1.5,2.2-2.5c-1.7,0.5-3.2,1.5-5,1.5c0.5-0.2,0.9-0.4,1.4-0.5
    		c-0.5,0.2-4,0.7-3.9,1.2c0.2,0.8-0.4,0.7-0.9,1.2c-0.8,0.8-0.8,0-1.5,0.9c-0.4,0.6-1.8,0.6-1.3-0.2c0.6-1,1.7-1.5,2.6-2
    		c1-0.6,1.9-1.2,2.7-2.1c0.8-0.9,2.1-0.4,3.1-0.5c-0.8-0.2-1.8-0.1-1.9-1.2c-0.1,0.7-1.4,2-2.1,1.5c-1.1-0.7,0.8-2.3,1.3-2.7
    		c-0.1,0.5,0.1,0.9,0.6,0.9c-0.3-0.6-0.3-1.2,0.1-1.7c-0.3-0.1-0.5-0.2-0.8-0.3c0.9-0.3,1.3-1.3,2.2-1.5c-0.7,0.3-1.3,0.5-1.8-0.3
    		c-0.6-1,0.9-0.9,1.1-1.7c-0.7,0.5-1.7,0-1.6-0.9c-0.9,1-2-0.1-0.7-0.8c1-0.5,2.1-0.2,3.2-0.5c-0.7,0.1-1.5,0.3-2.2,0.1
    		c1.2-0.4,0.3-1.9,1.9-1.9c-0.8,0.2-1.1-0.7-0.3-1c0.6-0.2,1.4,0.8,1.7,1.1c0.3-0.8-0.9-1.2-0.6-1.9c0.5-1.3,2.2-0.9,3.2-0.6
    		c-1.3-0.9-2.6-0.3-3.7,0.5c-0.5-0.9,1.5-2.3,2.3-2.7c-0.5,0.1-2.2,1.5-2.4,0.9c-0.2-0.6,0.5-1.3,0.9-1.7c1.1-1,2.8-2,4.3-2.4
    		c-1.4-0.1-2.9,0.9-3.9,1.8c-1.4,1.3-1.9-0.5-3.3-0.7c0.6-0.5,0.6-1.3,0.8-2c0.2-0.6,1-0.9,1.4-1.4c-1.6,1.4,0.2-2,0.5-2.4
    		c0.8-1,1.7-2.1,2.7-2.9c-0.6,0.3-1.1,0.8-1.7,1.1c-1,0.4-0.3,0.7-1,1.6c-0.3-1.5-1.3,0.6-1.7,1.3c-0.6,0.9-1.8,2.1-2.1,3
    		c-0.4,1.2-1.3,2.5-2.6,2.8c-1.2,0.2-0.5-1.2-0.2-1.6c-0.7,0.8-2.2,0.3-3,0.4c1.4-1.7,2.4-3.5,4.7-4c-0.5-0.4-1.2-0.3-1.6,0.2
    		c1.2-1.9-1.7,0.7-2,0.8c-1.2,0.3-0.5-1.5-0.9-2c-0.2,1.1-1.3,0.6-1.5-0.1c-0.4,1-1.9,1.1-2.7,1.6c1-0.4,2-0.1,3-0.1
    		c-0.2,0.6-0.6,2.2,0.6,1c-0.4,0.4-0.9,0.7-1.5,0.9c0.7,0,1.7-0.6,1.9,0.4c-0.7,0.1-0.6,0.3-0.6,0.9c-0.1,0.8-1.7,1-2.2,1.1
    		c1.1-1.3-0.7-0.8-1.2-0.5c1.8-0.2-0.1,0.2-0.5,0.2c-0.7,0-0.8,1-1.6,1c0.9-1.1-3.9-2.3-1.7-3.4c-1.1,0.3-1.1-0.9-1-1.6
    		c0.3-1.2-0.2-1.9-1.5-2.2c0.4-0.3,0.8-0.5,1.2-0.6c-1.2-0.1-3.2,0.2-3.2-1.6c-0.9,0.7-1.3-0.6-1.9-0.9c0.7-0.8,1.9-0.9,2.7-1.6
    		c0.7-0.7,1.1-1.5,2-1.9c2.3-1-0.9,2.5-0.9,2.6c0.8-1,2.3-2.5,3.7-1.5c-0.7-0.4-1.4-1.8-2.3-1.6c-1.2,0.4-2.4,1.2-3.7,1.5
    		c-3,0.8-5.6,1.1-8.4-0.5c0.7-0.5,1.9-0.7,2-1.8c-1.1-0.1-1.8,1.1-3.1,0.9c-1.7-0.3-3.4-0.7-5.1-1c-3-0.5-6.2,1.8-9.1,0.6
    		c1.9-0.2-1.6-2.2-2.4-2.1c1.4-1.8,3.8-2,5.4-3.3c-2.1,0.3-3.8,1.5-5.7,2.3c-0.5,0.2-1.4,0.7-2,0.5c-0.7-0.2-1.1-1.1-1.9-0.8
    		c0.9-0.7,2-1.1,3-1.6c-0.4,0.1-3.6,0.6-1.6-0.2c-0.8,0.1-1.5,0.3-2.3,0.4c0.7-0.5,1.5-0.6,2.2-0.9c-1.3-0.1-2.5,0.6-3.7,0.3
    		c1.1-0.7,2.9-1.2,4.2-1c-0.8-0.5-2.1,0.5-2.9-0.1c-0.9-0.8,3.2-2,4.1-1.8c-1-0.9-2.8-0.1-3.8,0.4c-1.3,0.6-3,0.5-4.5,1
    		c0.2-0.2,0.4-0.5,0.6-0.7c-0.5,0.2-1,0.4-1.5,0.6c0.6-0.8,1.6-1.2,2.2-1.9c-1.2,0.6-2.3,2.2-3.7,2.4c0.2-0.2,0.5-0.5,0.7-0.7
    		c-0.6,0.1-1.2,0.4-1.5,0.9c-1.3-1.3,3.7-3,4.4-3.5c-1.3,0.4-2.5,1-3.6,1.6c-1,0.6-2.2-0.5-3.2,0.5c0.8-0.1,1.5-0.5,2.3-0.6
    		c-1.7,1.4-3.5,1.3-5.3,2.1c2-0.2-1,0.6-1.4,0.9c0.8-0.1,1.5-0.6,2.3-0.6c-0.2,0.2-0.4,0.5-0.6,0.7c0.5-0.1,1-0.3,1.5-0.6
    		c-0.9,1.8-3.4,1.5-5,2.2c1.6-0.3,2.9-0.5,4.5-0.9c0.7,1.8-4.4,2-5.2,3c0.5,0,1-0.1,1.4-0.4c-0.3,0.3-1.5,1.3,0,0.4
    		c-0.9,0.7-2.1,1.2-3.2,1.5c-0.6,0.2-1.1,0.2-1.7,0.5c-1.1,0.5-1.9-0.2-3-0.2c0.3-0.2,0.7-0.4,1-0.5c-1.1,0-2,0.7-2.9,1.3
    		c0.5-0.5,1-1.1,1.4-1.7c-0.9,0.1-4.3,1.8-4.3,2.9c-0.2-0.6,0.1-1.1,0.7-1.3c-0.5,0.2-1.1,0-1.6,0.3c-0.6,0.3-0.6,1.1-1.2,1.5
    		c0.1-1.4-1-0.5-1.6-0.1c-1.1,0.7-2.8,1.3-4,1.6c0.9-0.8,2-1.2,3-1.8c-1.4,0.4-2.5,1.4-3.8,1.9c0.2-0.3,0.4-0.5,0.6-0.8
    		c-1.2-0.1-2.1,1.2-3.2,1.5c-1.7,0.5-1.8,0.9-3.5,0.4c0.7,1.2-0.4,0.6-1,0.5c-0.8-0.2-1.8,0.4-2.5,0.6c-0.6,0.2-1.3-0.4-1-1
    		c0.1-0.3,1-0.5,1.2-0.6c2.9-0.9,5.8-2.1,8.7-3c-1.5-0.4-3.4,1.7-4.7,0.5c-1.2-1,5.2-3.6,6.1-4.1c1.2-0.6,2.5-1.2,3.6-2
    		c0.7-0.5,1-1.9,1.9-2.1c2.9-0.9,5.9-1.7,8.9-2.5c0,1.3,1.2,0.9,2,0.7c1.4-0.4,3.4,0.2,4.8,0.7c-1-1.2-5.1-1.6-1.9-3.2
    		c1.8-0.9,4-1.3,6-1.6c-1.9-0.9-3.9,0.7-5.6,1.3c-0.6,0.2-3.8,1.3-3.1-0.5c-0.9,0.9-2.4,1.1-3.5,1.2c-1.4,0.1-2.5,1.2-3.8,1.5
    		c-1.4,0.3-2.7,0.3-4,1.1c-0.9,0.6-1.7,1.1-2.8,1.3c-2.2,0.5-3.5,0.9-5.2,2.3c-0.9,0.8-2.2,1-3.3,1.4c-1.1,0.4-1.8-0.9-2.7-0.4
    		c1.5-0.3,1.2,1.7,0.2,2.2c-1.8,0.9-3.7,0.8-5.6,1.4c1.6-0.1,0.4,1-0.2,1.2c-0.8,0.2-1.7,0-2.5,0.4c2.1-1.3-0.5-0.2-0.8,0
    		c-0.8,0.4-2,0.4-2.7,0.8c-0.3,0.2-0.3,0.6-0.7,0.8c-0.6,0.3-2,0.5-2.7,0.5c1.9,0-0.6,0.5-1,0.7c-0.9,0.4-2.1,0.9-2.6,1.8
    		c1.1-0.1,2.6-0.5,3.5,0.2c1.7,1.1-1.7,2.3-2.3,2.6c-1.5,0.5-3.1,0.5-4.4,1.4c-0.7,0.4-1.4,0.3-2,0.9c-0.9,0.8-2.1,0.6-3.1,1.3
    		c0.5-0.2,1-0.4,1.5-0.4c-0.8,0.4-3.1,2.7-3,1.1c-0.7,1-2.9,1-4,1.2c-1.2,0.2-2,0.7-3.1,1.2c-1.2,0.6-2.6,0.5-3.8,1
    		c-1.5,0.6-2.8,1.7-4.4,1.7c-0.9,0-1.2,0.9-1.9,0.7c-0.9-0.4-3.1,1-4,1.3c1.6-0.2,0,0.9-0.5,1.2c-0.8,0.6-2,0.6-2.8,1.1
    		c-0.7,0.5-1.6,0.5-2.4,0.6c-1.2,0.1-1.8,0.8-2.8,1.2c-0.5,0.2-0.5-0.7-1-0.5c-0.5,0.2-1.9,0.6-2.1,1.2c-0.2,0.8-1.1,0.8-1.8,0.7
    		c-1.2-0.3-1.8,0-3,0.4c2,0.2-1,0.8-1.5,0.8c-1.7,0-3.3,0.8-4.8,1.7c0.8-0.1,1.4-0.7,2.2-0.4c-0.6,0.5-0.9,0.7,0.1,0.8
    		c-0.7,0.4-1.5,0.4-2.2,0.7c-0.6,0.3-1,1.1-1.7,1.2c0.2-0.8,1-1.3,1.8-1.5c-0.9-0.6-2,0.8-2.6,1.3c-0.9,0.6-2.3,0.9-3.3,0.9
    		c-1.4,0.1-2.5,0-3.8,0.7c-1.2,0.7-2.2,2.1-3.7,2c0.9-0.4,1.6-1,2.3-1.7c-0.8-0.9-2.8,0.1-3.7,0.5c-0.6,0.3-1,0.4-1.7,0.4
    		c-0.8,0-1.9,1-2.6,1.4c0.9-1.5-0.5-0.3-0.8-0.1c-0.5,0.4-1.1,0.4-1.7,0.1c-0.3-0.1-1.6,0.5-1.9,0.6c-0.9,0.3-2,0.1-2.9,0.4
    		c1.1-1.1,2.2-1.5,3.7-1.4c-1.7-2.1-4.6,0.7-6.1,1.6c-0.7,0.4-1.4,0.4-2.1,0.7c-0.6,0.3-0.9,0.9-1.5,1.2c-0.6,0.3-0.4-0.9-1.1,0
    		c-0.4,0.6-1.3,0.6-1.9,0.4c0.9-0.3,0.8-1.2,1.1-1.9C276.4,223.8,276.4,226.6,274.9,226.2 M339.9,201.6c-2.2,0.9,1,1.8,1.7,2.5
    		c-0.2,0.2-0.5,0.5-0.8,0.6c0.5-0.2,1.5-0.3,1.5-1c0.1-0.6,1.5-0.6,1.8-1.2C342.7,201.9,341.5,201.1,339.9,201.6 M358,191.3
    		c1.8,0.5,3.5-0.7,5.2-0.7c1.4-0.1,3.3,0.5,4.5-0.4c-1,0.3-1,0.2,0.1-0.3c0.8-0.4,1.6-0.7,2.5-0.9c1.4-0.4,2.9-0.7,4.3-1.2
    		c-1.7-0.1-3.6-0.1-5.2,0.1c-1.4,0.2-2.6,0.8-4,1C362.7,189.1,359.8,189,358,191.3"
          />
          <path
            id="United_States_lower_48"
            class="country"
            d="M432.2,367.2c0.6-2-2.1-3.6-1.6-5.8c0.6-2.5,2.7-4.7,2.8-7.3c0-1-1.6-3.2-1.3-3.9
    		c1-2,2.1-3.7,4.2-4.6c-1,0.5-1.1,0.4-0.2-0.3c0.6-0.6,1.2-1.2,1.4-2c0.3-1.4,1.9-2.3,2.5-3.5c0.5-1,0-2.4,0.6-3.1
    		c1-1.2,0.4-1.6,0.6-2.9c0.2-1,0.9-1.7,1.5-2.5c0.7-0.9,0.5-1.8,0.9-2.8c0.5-1.1,1.4-2.1,2.2-2.9c0.3-0.4,2.3-2.4,1.8-1.2
    		c0.2-0.6,0.6-0.5,1.1-0.7c0.3-0.1,1.1-1.5-0.3-0.3c0.8-1,1.7-2.6,3.1-2.7c-1.6,0.4-0.1-0.9,0.3-1.5c0.6-0.8,1.2-1.5,1.8-2.3
    		c0.9-1.1,1.5-2.5,2.6-3.5c1.6-1.4,3.1-3.9,4.3-5.7c0.4-0.7,1.3-2.4,2.3-2.2c-0.4-1.1,0.9-2.6,1.6-3.4c0.9-1,1.1-2.5,2.6-2.6
    		c1.1-0.1,3.2,0.9,4.1,0.2c-0.6,0.2-1.3-0.4-1.8-0.6c-0.9-0.5-1.4-0.2-2.3,0c-1.4,0.2,0.2-2.3,0.5-2.7c-0.2,0.5-0.4,1-0.6,1.5
    		c1.2-0.3,1.7-3,3.2-2.4c-0.5-0.4-1-0.5-1.6-0.4c0.2-1.7,1.6-1.6,3-2c-0.6,0-1.1-0.3-1.5-0.7c-0.2,0.2-0.4,0.5-0.6,0.7
    		c0.7-2.3,2.5-4.7,2.3-7.3c-0.1-1.2,1.4-5,2.6-4.7c1.9,0.5,3.3,1.6,5.3,1.9c1.2,0.2,4.4-0.1,4.4,1.1c0.1-0.6,0.6-1,1.2-1.1
    		c-0.3,0.6-0.1,1.3-0.3,1.9c-0.2,0.7-1.9,1.5-2.5,2c1.6-1.7-0.6-0.1-1,0.1c-1,0.7-1.9,1.5-2.9,2.3c0.7,0.6,1.5,0.1,2.2-0.3
    		c-0.5,0.1-1,0.3-1.4,0.5c0-1.1,1.3-1.7,2.2-2.1c1.5-0.6,2.4-1.5,3.8-2.3c0.4,1.1-2.4,1.9-2.8,3.1c1.4-0.8,0,2-1.2,2.1
    		c0.2-0.2,0.4-0.5,0.6-0.7c-0.9,0.1-1.4,1-2,1.7c0-0.6,0.3-1.1,0.8-1.4c-1.1,0.4-1.8,1.5-3,1.7c1-0.2,1-0.1,0,0.5
    		c0.8-0.6,0.9-0.5,0.6,0.4c0.3-0.8,1-1.2,1.7-0.8c0.5,0.3,1.5-0.7,1.9-1c1.3-0.9,3.1-1.4,3.3-3.3c0.1-1.4,3.1-2.3,2.8-3.3
    		c-0.3-0.9,0.7-1.6,0.4-2.6c-0.2-0.8-1.2,0.2-0.6-1.1c1.7,0.9,1.9-1.2,2.8-2.1c-1.1,0.2-0.4-1.5,0.1-2l185.2-0.1l0.4-3.3
    		c0.1-0.6,1.7-0.4,1.9,0.9c0.3,1.7-2.2,4.5-0.1,4.8c0.7,0.1,3.8,0.3,3.7,1.2c-0.1,1,3.2-0.4,3.8-0.6c1.3-0.5,2.8,0.6,4.1,0.9
    		c-0.4,0.6-0.1,1,0.6,1c-0.3,0.3-0.2,1.6,0.4,1.3c0.3-0.1,0.4-0.8,0.7-0.8c0.8,0,1.2,0.5,1.8,0.9c0.5,0.4,0.8,0.4,1,0.4
    		c-0.1,0.4,0,0.9,0.6,0.9c2,0,3.7-1.3,5.6-1.3c-0.8,1.4,1.7,1.1,2.4,1.1c0.6,0,1.4-0.1,2,0.2c1.5,0.8,1.9,0.6,3,0.5
    		c-4.2,1.3-8.5,2.7-12.4,4.8c-1.6,0.9-3.1,2-4.7,2.9c-1.8,1-3.8,1.6-5.4,2.9c1.4,2.1,7.8-1.4,9.6-1.5c-0.2,1-1.3,2.2-2.2,2.7
    		c0.5,0.1,1.4-0.5,1.7-0.2c0.5,0.5,0.4,0.6,1.3,0.6c1.5,0,3-0.9,4.3-1.5c1.6-0.7,3.2-0.3,4.7-1c2-1,3.9-1.7,5.8-2.9
    		c-0.3,0.7,0.2,1.2,0.4,1.9c0.1,0.7-0.6,1.2-0.8,1.9c0.4-0.3,3.4-2.3,1.6-0.6c1.9-1.5,3.4-0.2,4.3,1.6c0.9,1.6,1.9,1,3.4,1.6
    		c2.1,0.8,4.1-1.2,6.1-1.7c1.2-0.3,2.4,0,3.5-0.1c1.5-0.1,3-0.5,4.4-0.7c-0.5,0.8-1,1.5-1.2,2.4c1.4,0.3,2.7,0.2,4,0
    		c1.2-0.2,0.8,2.2-0.1,2.4c0.9,0.1,1,1.1,1.6,1.7c-1.4,1.3-3.7-0.7-5.2,0.2c-0.1,0-1,1.1-1,1.1c-0.9,0.1-1-1.3-1.8-1.6
    		c-2.5-1-4,0.8-6.4,0.7c-2.6-0.1-3.7,2.2-5.9,2.9c0.4-0.9,1.3-1.3,1.9-2c-1.5,0.3-2.2,1.2-3.6,1.6c0.1-0.6,0.3-1.1,0.6-1.5
    		c-2.5,0.9-4.6,4.1-6.7,5.7c-0.5,0.4-0.7,1.1-1.3,1.5c-0.6,0.5-1.4,0.4-2,0.9c-0.9,0.7-2.2,1.9-2.4,3.1c1.8-0.4,3.5-3.4,5.5-2.6
    		c0.7,0.3-1.7,2.5-1.9,2.8c-0.8,1-1.3,2.3-2.2,3.2c-1.9,1.8-2.7,4-4.5,5.9c-0.9,1-1.7,2.2-2.1,3.5c-0.3,1.2-0.1,2.4-0.9,3.5
    		c-1.4,1.7-2.1,5.5-1.4,7.6c0.6,1.8,3.1,0.7,4.2,0.2c2.5-1.1,4.4-3,6.2-5.1c1-1.1,1.8-2.3,2.4-3.6c0.5-1.1,0.5-3.8,1.7-4.4
    		c-1.9-1.1-0.3-3.2,0.6-4.4c0.6-0.8,0.4-1.6,0.8-2.4c0.4-0.9,1.7-1.5,2.4-2.2c0.7-0.8,1-1.6,1.4-2.6c0.4-0.9,1.2-0.9,1.9-1.5
    		c0.8-0.7,0.9-1.1,2-1.3c1.3-0.3,2-1.7,3.2-2.1c-0.8,1-1,2.3-1.9,3.2c0.6-0.3,1-0.6,1.4-1.2c-0.1,0.6-0.5,1.1-0.9,1.5
    		c1.5-0.6,2.1-2.1,2.8-3.5c0.8-1.7,2.7-1,4.1-1.8c-2.3-0.4,1.1-3,2.1-3.2c0.6-0.1,2,0.7,2.6,1.1c1.4,0.7,2.5,1.7,3.9,2.3
    		c1.2,0.5,1.7,2.2,0.6,2.7c-1.1,0.5-0.7,2.1-0.9,3.1c-0.2,1.1-1.2,3.1-2.4,3.5c-1.3,0.4-1.7,1.8-2.9,2.3c-1.1,0.5-2.6,1.1-2.9,2.6
    		c-0.3,1.9,2.2,0.4,2.7,0.2c1.1-0.3,5-4.5,6.2-2.7c0.9,1.4-0.4,4.7-0.6,6.2c-0.2,1.9-1.3,6.2-3.8,6.4c0.8-0.9-0.3-1.4-0.9-0.6
    		c-0.5,0.6-0.7,1.5-1.4,2c-0.7,0.6-1.5,0.9-2.2,1.5c-0.7,0.6-1.1,1.4-1.9,2c-0.5,0.4-3.2,2.1-1.7,2.6c0.5,0.2,1.1,0.3,1.6,0.5
    		c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.3,1.1,0,1.6,0.3c-0.7,0.2-1.5,0.1-2.2,0.5c0.7,0.4,1.5-0.2,2.2-0.1c0.5,0.1,1,0.3,1.5,0.3
    		c1.7,0,3.2-0.7,4.9-0.6c1.7,0,3.2-1.4,4.6-2.1c1.6-0.8,3.5-1.2,5.2-1.8c3.6-1.1,7.3-2.4,10.5-4.4c1.4-0.8,2.9-1.6,4.2-2.6
    		c0.8-0.5,2-1.2,0.5-1.2c-1.2-0.8,0-2,0.2-3c0.6-0.7,2.8-0.8,4-1c1.3-0.2,2.7-0.2,3.9,0c1,0.2,1.8,1.3,2.8,1.1
    		c1.3-0.3,2.5-0.1,3.8-0.3c1.4-0.2,2.6-1.1,4-1.6c1-0.4,2.2-0.4,2.6-1.6c0.4-1.3,1.2-2.1,2.2-2.9c-0.6,0.3-1.5,0.5-0.8-0.6
    		c-0.8,0.6-1,0.1-0.6-0.4c0.4-0.5,1.1-1.4,1.7-1.7c4-1.9,7.9-4.8,12.4-5.9l22.4,0.1c0.5-0.7,0.9-1.8,1.6-2.2
    		c0.8-0.5,1.8-0.2,2.7-0.7c0.1,0.2,0.2,0.5,0.4,0.7c0.9-0.4,0.6-1.3,1.6-1.1c-0.5-0.2,2.3-2.6,3-3c0.9-0.4,1.6-1.3,2-2.6
    		c0.3-0.8,1.4-2,2.5-2.7c1.1-0.7,1.1-2,1.9-2.9c1.1-1.3,2.6-2.2,3.9-3.3c1.1-0.9,5-5.3,4.7-1.8c-0.2,1.9,4.1-0.1,4.6-0.2
    		c3.3-0.9,2.8,3.3,2.1,5.1c-0.6,1.6-1.4,3.2-2,4.9c-0.3,0.8-1.8,3.1-1.2,4c0.6,0.9,1.9,0.2,1.3,1.6c-0.4,1-1.1,2-0.4,3
    		c0.6-0.6,1.2-0.4,1.4,0.4c0.2,0.9-0.7,1.2-0.8,2c1-0.7,1,0.1,0.5,0.6c-0.5,0.5-1.3,0.7-1.9,0.8c-0.8,0.2-1.5,0.3-2.1,0.7
    		c-0.5,0.3-2.3,1.7-1.6,0c-0.7,0.2-1.1,1-1.9,1c-0.6,0-0.4,1.1-1.1,1.2c0-0.4,0-1.6-0.8-1.4c-0.9,0.2-1.4,1.5-1.5,0.1
    		c0,0.6-0.2,0.6-0.7,0.5c-0.5,0-0.5,1.3-0.5,1.6c-1.2-0.7-1-1.7-0.8-2.9c-0.3,0.8-1.2,0.9-1.8,1.5c-0.6,0.6-0.9,1.6-1.5,2.2
    		c-1.4,1.6-2.7,0.8-4,2.5c-0.2-0.5-0.3-0.6-0.1-1.1c-0.2,0.2-0.5,0.4-0.7,0.6c0.5-1.8-0.9,0.2-1,0.6c0-0.8,0.2-1.6,0.8-2.2
    		c-0.4,0.2-0.8,0.5-1,0.9c0.7-0.2-0.3,1.9-0.7,2.2c0-0.3-0.1-0.6-0.1-0.9c-0.4,0.3-1.5,1.3-0.2-0.1c-1.2,1.7-0.5,0.2-0.3-0.3
    		c-0.8,0.5-2.4,1-2.5,2.1c0,0.3-1.6,1.7-2,1.9c-1.4,0.8-6.3,5-4.1,6.7c-0.7,0.4-3.5,1.5-3.3,2.5c0.1,0.5,1.2,0.7,1.5,1.1
    		c0.7,1.1-0.4,2.5,0.9,3.7c1.1,1,3.2,0,3.4-1.3c-0.9,0-0.1-0.9-1-1.4c1.5-0.1,1.3,2.2,0.8,3c-0.6,1-4.4,1.6-5.5,1.5
    		c0.2-0.5,0.5-1,0.7-1.5c-1.5,0.8-3.1,1.9-4.8,2c0.3-0.8,0.5-1.6,1.2-2.2c-0.7,0.4-1.5,1.3-1.7,0c-1.3,1.6-1.4,3.1-3.8,3.5
    		c-0.5,0.1-4.4,0.7-4.3-0.2c0,0.9-1.5,0.9-2.1,0.9c-1.4,0-2.1,0-3.5,0.5c-2.7,1.1-5.3,2-7.6,3.7c1.1-1.4,2.2-2.8,1.9-4.7
    		c-0.9,1.6-0.3,4.6-2.7,5.3c-1.9,0.6-2,2,0,2.2c-0.4,1.9-1.6,3.5-2.6,5.1c0.2-0.5,0.5-1,0.7-1.4c-1.6,0-2,4.4-3.8,4.2
    		c-0.6,0-2,2.3-2.4,2.7c-0.8,0.9-2,2.1-3.4,2.2c0.2-0.5,1.3-1.6,0.7-2.1c-1.1-0.8-1.9-0.8-2.7-2.1c-1.4-2.3,2.3-3.1,3.5-4.1
    		c-1.8,0.7-4.8,1.9-4,4.4c0.5,1.5,0,2.9,0.6,4.4c0.5,1.2,0.2,2.1-0.6,2.6c1.1,0.2,0.6,1.2,0.1,1.8c0.8-1.6-0.2-0.2-0.3,0.2
    		c-0.2,0.6-1.1,1-1.5,1.4c-1.2,1.1-2,2.2-2.9,3.5c-0.9,1.1-2.1,1.9-2.9,3c-0.5,0.6-1,2.4-2.1,2.4c-0.4-1.6,1.1-3.3,2.1-4.4
    		c0.5-0.6,1.8-2.4,2.4-2.5c-0.6-0.2-1.1-0.2-1.7,0.2c0.2-0.7,2-2.1,0.2-2.1c0.4-0.5,0.6-1,0.8-1.5c-0.7,1.2-1.2-0.6-1.4,1.1
    		c-0.1-0.4-1.2-1.1-1.1-1.5c0.2-0.7,1.3-1.1,0.7-1.9c0.5,0,1,0.1,1.4,0.4c-0.1-0.6-0.9-1.8-1.7-1.4c0.3-1,0.9-0.3,1.6-0.7
    		c1.1-0.7-0.6-1.1-1.3-0.6c0.4-1.2,2-0.7,2.2-2.1c-0.2,0.2-0.5,0.4-0.7,0.6c-0.6-2,2.3-2.5,3.6-2.7c-0.2-0.2-0.5-0.4-0.7-0.6
    		c0.6-0.3,1-0.6,1.4-1.2c-0.5,0.3-1,0.6-1.5,0.9c0.2-0.3,0.4-0.5,0.7-0.8c-0.8-0.1-1.1,0.3-1.5,0.9c-0.3,0.4-1.7,1.6-0.8,0.1
    		c-0.4,0.3-0.7,0.7-0.9,1.2c-0.3-1.4-1.6,0.2-1.5,0.9c-0.3-0.1-0.5-0.2-0.8-0.4c0.1,0.5,0.3,1,0.7,1.4c-0.9,0.1-0.8,0.2-0.2,0.8
    		c-2.1-1.3-1.5,5.1-2,5.8c-1.1-0.7-1.3-1.8-0.9-3c-0.3,1-0.6,1.8,0.1,2.7c0.6,0.7,1.3,1.7,0.4,2.4c-0.3-1.1-2.9-1.1-2.5-2.4
    		c-0.4-0.1-0.5,0.2-0.3,0.7c-0.5-0.3-0.9-0.7-1.1-1.3c-1.2,1-1.7-0.3-0.7-1.3c0.8-0.7,2.6-1.4,1.9-2.8c0,1.4-4,3.9-3.1,4.7
    		c0.5,0.5,1.4-0.1,1.8,0.6c0.7,1.2,0.9,0.9,2.2,1.2c0.5,0.1,0.9,1.3,1.3,1.7c0.8,0.6,0.7,1,0,1.8c-1.8,2-3-1.1-3.6-2.5
    		c-0.1,1.3,1,3.4,2.2,4c1.4,0.8,1.1,2-0.7,1.7c0.1,0.3,0.2,0.5,0.3,0.8c-1.1,0.6-1.7-0.9-2.2-1.5c0.8,1.4,3.6,3.4,1,4.3
    		c-0.9-2.6-2.6-2.4-4.9-3.2c0.2,0.5,1.8,0.6,2.4,1c0.8,0.6,2.5,2,1.6,3.1c0.5-0.5,1-0.4,1.4,0.1c-0.1-1.2,1.1-0.8,1.8-0.6
    		c0.8,0.2,0.6,0.9,0.6,1.7c0,1.1-0.1,2.1-0.2,3.2c-0.1,1.4,0.7,2.8,0.2,4.2c-0.1-1.2-0.1-2.4-0.4-3.5c-0.3-1.1,0.5-2.2-0.3-3.2
    		c0,0.4-0.2,1.2-0.7,0.4c0,1.4,0.9,2.9,0.2,4.2c0.1-0.6-0.1-1.1-0.4-1.5c-0.3,0.8-0.9,0.9-1.4,0.2c0.1,0.3,0.3,0.5,0.4,0.8
    		c-0.6,0.4-1.1,0.3-1.5-0.2c0.7,1.5-0.7,0.3-1.2,0.1c0.2,0.2,0.4,0.5,0.6,0.7c-0.7,0.2-1.3,1-2.1,0.5c-0.8-0.5-0.4-1.6,0-2.2
    		c-0.4,0.3-1.4,2.7-0.7,3c0.6,0.3,1.3-0.4,1.9-0.2c0.5,0.2,2.7-0.6,2.6,0.4c-0.1,0.7-0.5,1.7-0.9,2.2c0.6-0.2,0.9-0.8,1.1-1.4
    		c0.2-0.8,1.1-0.6,1.6-1.1c0.5,1.1-0.5,1.9-0.6,3c-1-0.7-1.7,0.9-2.2,1.5c-1,1-2.8,0.4-3.8-0.2c0.2-0.3,0.3-0.5,0.5-0.7
    		c-0.6,0.1-1,0.5-0.8,1.2c-0.9-0.5-2.1-0.3-3-1c0.5,0.6,4.1,1.9,2.6,2.5c-0.6,0.2-0.2,0.9-0.7,1.3c-1.2,0.8-1.8,0.7-2.9-0.2
    		c0.1,1,0.8,1.4,1.7,1.2c0.6-0.1,2.6-0.9,2.3,0c0.3-0.2,0.7-0.3,1-0.4c-0.4,0.6-1.1,1.4-1.7,1.8c-0.4,0.2-2.6,0-2,0.6
    		c-1.1-0.2-2.7-0.4-3.6,0.4c-0.6,0.5-1.8,1.4-1.4-0.1c-0.4,0.3-0.3,0.9-0.5,1.3c-0.1,0.5-1.1,0.6-1.5,0.9c-1.6,0.9-2.6,2.5-3.5,4
    		c0.1-0.5,0.2-1,0.1-1.4c-0.6,2.8-3.2,1.9-5.5,2.6c-2.6,0.8-4.2,3.1-5.8,5.1c-0.4-0.5-0.2-1.1,0.3-1.4c-0.9,0.5-0.2,2-1.2,2.2
    		c-0.5,0.1-0.3,0.5-0.6,0.8c-0.6,0.5-0.8,0-1.3,0.3c-0.6,0.4-3.2,3.6-3.5,1.6c0.3,1.6-1,1.8-2.1,2.4c-0.7,0.4-1.3,0.7-2.1,0.6
    		c-0.3-0.1-2.5,0-0.7,0.3c-2.2,0.4,1.6,0.2-0.7,1.1c1.7-0.2-1.2,1.3-0.9-0.4c-0.2,1.9-0.8-0.3-0.9-0.7c0,0.6,0.3,1.3,0.3,1.9
    		c0,0.8-1.7,2-0.7,0.6c-1.1,1.7-1.3,2.3-3.3,3c0.1,0.2,0.2,0.5,0.3,0.7c-0.5,0.3-0.9,0.3-1.4,0.1c0,0.3,0,0.5,0.1,0.8
    		c0.3-0.1,0.5-0.2,0.8-0.3c-0.2,0.9-1,1.3-1.9,1.2c0.9,0.5,0.5,1.8-0.2,1.1c0.2,1.2-0.4,0.7-1.3,0.5c0.6,0.2,1,0.5,1.3,1
    		c-0.7,0.7-1.5,0.6-1.9,1.6c-0.3,0.7-1.4,2.5-0.8,3.2c0.3,0.3,0.2,0.5-0.1,0.7c-0.7,0.5-0.2,0.6-0.1,1.4c0.2,1.5-0.1,3.3-0.1,4.9
    		c0,3.4,0.8,6.7,2,9.9c0.6,1.5,0.5,1.9,0,3.3c-0.4,1.4,0,2.8,0.2,4.2c-0.6-1.5-0.9-3.1-0.2-4.6c0.7-1.7-1-1.3-1.1-2.8
    		c0,2.6,0.3,5.2,1,7.7c0.4,1.4,1.3,4.2,0.3,5.5c2.4-0.3-0.6,9.7-1.1,10.8c-0.8,1.8-1.9,5.5-3.7,6.5c-0.6,0.3-0.3-1.1-1.1,0
    		c-0.5,0.7-1.3,0-2,0.3c-1.2,0.4-2.9-0.1-1.7-1.5c0.2,0.5,0.7,0.9,1.2,1.1c0.3-0.8-1-1.6-1.4-2.2c-0.7-1.3-0.2-2.7-1.7-3.6
    		c-0.9-0.5-1.8-0.6-1.9-1.8c-0.1-1.2,0.7-2.9-0.8-3.6c0.9-0.3,1.3-1.2,2-1.7c-0.7,0.2-1.2,1.1-1.9,1.4c-0.6,0.2,0-3.2,0.4-3.6
    		c-0.5,0.3-1,0.3-1.4-0.1c1,1.2-0.6,1.4-1.2,0c-0.5-1.3,0.1-3.6-1.3-4.5c1.1-0.1,3.3-2.7,2.7-3.8c-0.2,0.2-0.5,0.3-0.7,0.5
    		c0.1-0.6-0.3-1.1-0.9-1.4c-0.3,0.5-0.4,0.9,0.2,1.3c0.5,0.3-0.6,1.4-0.8,1.6c-0.3-0.5-0.6-1.2-0.5,0c-0.9-2.9,2-5.7,2.7-8.5
    		c0.2-1,0.2-1.8,0-2.8c-0.3-1.7-1-1.1-2-2.1c-1.5-1.4-2-3.5-2.9-5.3c-0.4-0.9-1.4-1.8-2.3-2.1c-1.4-0.4-2.2,0.9-3.5,0.8
    		c0.2,1.1-0.8,0.8-1.7,1.4c-0.4,0.2-2,1.3-1.8,0.3c-1,0.8-2.4,1-3.6,1c0.8-1,0.1-2.2-0.9-2.7c0.5,0.1,0.4-0.2-0.2-0.8
    		c1,0.8,1.3-0.2,0.3-0.3c-1.5-0.1-0.4-1.2,0.4-1.4c-0.7,0-1.4,0.1-2.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c-1.4,0.4-3.8-1.3-5-1.9
    		c0.2,0,2.6,0.4,2.5-0.1c-0.2-0.4-1-0.7-1.4-0.8c-0.7-0.2-1.4,0.5-2.1,0.7c-1.1,0.4-2.7,0.4-3.8,0.4c0.7-0.1,1.3-0.3,1.9-0.6
    		c-0.6-0.2-0.8-0.7-0.4-1.2c-0.4,0.4-0.7,0.8-1,1.3c0.4-2-0.8-0.1-1.2,0.3c-0.6,0.6-1.3,1-2.2,0.9c0.5-0.1,1.9-0.8,1.1-1.5
    		c-0.6,1.6-2.8,2.5-4.4,1.9c1.6,0.6,0.9-3.3,0.6-4.1c-0.4,0.8-0.9,1.8-1.4,2.6c-0.4,0.6-1.4,0.2-2,0.6c-0.3,0.2-1.9,0.2-1.7,0
    		c-0.6,0.9-1.4-0.6-2-0.3c0.3,0.1-1.9,0.6-2.4,0.6c-1.2,0.1-1.4,0.8-2.5,1.2c-2.1,0.7-2.7-1.7-4.6-1.6c-0.7,0-2.9,1.9-1.8,2.5
    		c0.7,0.4,2,0.3,2.7,0c0.6-0.2,1.9-0.1,2.3-0.5c-0.3,0.3-1.8,1-1.6,1.5c0.5,1.3,1,0.6,1.6-0.1c0.8-0.9,1.9,0.3,1.4,1.2
    		c-0.5,0.8-2.1,0.1-1.8,1.5c-1-0.4-1.1,0.3-0.8,1.1c0.4,1.2,1.3,1.3,2.4,1.7c0.5,0.2,0.8,0.9,1.2,1.4c0.4,0.4-1,1.2-1,1.7
    		c-0.8-1.1-1.6,0-2.2,0.5c0.4-0.5,0.8-1.1,1.1-1.7c-0.7-0.1-0.5-0.4-0.8-0.9c-0.2-0.3-1.9-0.9-2.2-0.9c0.6-0.9-2.2-2.3-2.5-2
    		c-0.2,0.2,0.5,1.2,0.5,1.6c-0.2,1.1-0.5,2.1-1.7,2.4c0-1,0.1-1.4-1-1.6c-1.8-0.4-1,1-2.3,1.3c-2,0.4-4.2-1.9-3.1-3.9
    		c-0.5,0.9-1.6,0.5-2.1-0.1c-0.4-0.4-0.6-0.9-0.5-1.4c-0.7-0.1-2.1,0.5-1.2-0.7c-0.9,0-3.1,0.9-2.2,2.1c-1.4-0.2-2.6,0.5-4,0.1
    		c-1.7-0.4-3.2-1.5-4.9-2c0.8-0.7,1.1-1.3,0.7-2.4c-0.3,0.8-0.5,2.6-1.4,2.8c-0.9,0.2-2.6,0.4-3.4,0c0.4-0.6,1.3-1.1,1.2-2
    		c-1,0.5-2,1.5-1.2,2.7c-2.7,0.3-5.4,1.2-7.6,2.8c0.6-0.8,1.7-1.4,2.7-1.7c-0.8-0.1-1.5,0.1-2.2,0.3c0.5-0.6,1-1.4,0.8-2.2
    		c-0.6,0.1-0.7,0.8-1.3,1c-0.5,0.2-0.6-0.7-1.1-0.1c-0.4,0.5,0.1,2.5,0.8,2.7c-1.2,1.2-2.6,2-3.8,3.3c-1.6,1.8-4.7,1.5-6.1,3.1
    		c0.5-0.1,1-0.3,1.4-0.4c-1.2,0.6-2.4,1.2-3.7,1.7c0.6-0.5,1.3-0.8,1.9-1.4c-0.5,0.1-1,0.3-1.4,0.5c1.1-0.7-1.3-0.5-1.6-1.3
    		c0.1,0.5,0,0.9-0.4,1.3c0.2-2.1-2.6,1.3,0,1.2c-0.9,0.8-2.3,1.2-2.9-0.1c0.1,1.2-2,3.8-1.7,2.2c-0.5,1.1-1.9,0-2.3,1.3
    		c0.6,0.2,1.1,0,1.5-0.4c-0.3,2.2-2.5,1.8-4.1,2.3c0.3,0,0.6,0.1,0.9,0.1c-0.2,0.5,0.2,0.9,0.3,1.4c0.2,0.6-0.9,1.8-1.1,2.2
    		c-0.7,1.1-2.3,1.2-2.9-0.1c0.3,0.5,0.3,1-0.1,1.5c0.7,0.2,1.5,0.3,2.2-0.1c-0.2,0.8-0.6,2.1-1.4,2.5c-0.7,0.3,0,2,0,2.5
    		c0,1.6-1,4.9,0.3,6.2c0.2-0.2,0.5-0.4,0.7-0.6c0.2,0.8-1.7,1.5-2.2,1.6c-1,0.1-1.7-1.6-2.9-1.6c-1,0-2.2,0.2-3.1-0.3
    		c-1.1-0.6-2.4-2.1-3.5-2.3c-2.3-0.4-1.6-2.1-2.5-4c-0.7-1.4-0.8-2.7-0.5-4.1c0.1-0.6,0.5-1.3,0.3-1.9c-0.2-0.6-1.3-1.1-1.6-2
    		c-0.9-2.3-2.2-4.1-2.9-7.2c-0.2-1.2-0.1-2.5-0.4-3.7c-0.1-0.6-0.5-1.1-0.7-1.7c-0.2-0.7-0.9-1.2-1.2-1.8c-0.6-1.2-1.5-1.8-2.2-2.9
    		c-0.1-0.1-2.1-0.3-2.3-0.3c-2.4-0.3-4.8-0.6-6.9,0.7c-2.2,1.4-2.3,4.8-5.2,6.1c-1.1,0.5-2.5-0.8-3.3-1.4c-0.8-0.5-1.6-0.9-2.3-1.5
    		c-2.3-1.6-3.3-3.2-3.4-5.4c0.6-1.5,0.2-3.1,0.2-4.6c0.2-1.5-0.9-2.2-2.1-2.6c-1.2-0.5-2.2-2.9-3.1-4.5c-3.6-1.9-1.2-3.9-4.1-4.2
    		l-13.1,0l-0.2,3.8l-20.2-0.1l-23.7-10c0.3-0.7,1-1.2,1.4-1.8c-6,0.2-12.1,1-18.1,1.6c-0.1-1.7,0.5-3.3,0.6-5c0-2-1.4-2.8-2.5-4.3
    		c-1.2-1.5-1.2-0.6-2.6-1.2c-1-0.4,1.1-2.9-1.7-2.8c-1.7,0.1-2.4-0.4-3.3-1.8c-0.9-1.5-2.9-1.2-4.4-1.6c-1-0.2-4.1,0.4-3.7-1.6
    		c0.2-1.3,1.6-2.4,1.6-3.7c0-1.2-1.5-1-0.7-2.4c0.5-0.9-0.6-1.9-1.2-2.6c-0.6-0.7-0.5-1.5-0.6-2.4c-0.1-1.3-1.8-2.6-1.5-3.7
    		c0.5-1.8,0.8-1.8,2-3.1c1.5-1.5,0.2-1.8-1-2.6c-1.8-1.2-0.3-2.4-0.6-3.9c-0.2-0.9,0.8-2.9,1.9-3c0,0.5-0.5,1-0.4,1.4
    		c0.1,0.6,0.9,1.1,1.2,1.5c0.6-2-0.6-4.2,1.8-5.2c-2.1-2.3-1.9,2.1-3.2,1.8c-0.9-0.2-1.9-2.5-2.8-1.2c0.5-0.6,1.2-2.4,1.7-0.8
    		C432.7,368.4,432.7,367.7,432.2,367.2"
          />
          <path
            id="path3780"
            class="countryIsland"
            d="M483.5,289.6c0-0.4,0-0.7,0.2-1.1c-0.5,0.3-0.9,0.8-0.9,1.4C483,289.8,483.3,289.7,483.5,289.6"
          />
          <path
            id="path3782"
            class="countryIsland"
            d="M484.4,286.9c-0.3,0.1-0.5,0.4-0.4,0.7C484.2,287.4,484.3,287.1,484.4,286.9"
          />
          <path
            id="path3784"
            class="countryIsland"
            d="M488.2,282.5c-0.9-0.5-1.3,0.6-0.6,1.2C487.2,283.1,487.6,282.6,488.2,282.5"
          />
          <path
            id="path3818"
            class="countryIsland"
            d="M487.7,281.1c0.6,0.7-0.3,0.6-0.7,0.9c-0.7,0.5-0.2,1.3-0.7,1.9c1.1-0.1,0.9,1.1,0,1.2
    		C485.2,283.5,486.2,282,487.7,281.1"
          />
          <path
            id="path3764"
            class="countryIsland"
            d="M436.3,404.8c0-0.3,0-0.6,0.1-0.9c-0.5,0.1-1,0.2-1.4,0.4C435.2,404.9,435.7,405.3,436.3,404.8"
          />
          <path
            id="path3766"
            class="countryIsland"
            d="M437.7,403.7c-0.9,1.4,1.8,0.7,2.2,0.1C439.2,404.3,438.5,403.8,437.7,403.7"
          />
          <path
            id="path3768"
            class="countryIsland"
            d="M443.5,413.1c-0.1,0-0.2,0-0.4,0c0,0.6,0.3,1.1,0.7,1.4C444,414,443.9,413.6,443.5,413.1"
          />
          <path
            id="path3770"
            class="countryIsland"
            d="M444.8,418.7c0.6,0.5,0.7,1.8,1.7,1.3C446.4,419.1,445.5,419,444.8,418.7"
          />
          <path
            id="path3820"
            class="countryIsland"
            d="M584.4,460.2c0.6-0.6,1.1-1.4,1.6-2.2c-1.1,1.2-3,2.6-2.9,4.4
    		C583.4,461.7,583.9,460.9,584.4,460.2"
          />
          <path
            id="path3822"
            class="countryIsland"
            d="M589.3,454.7c0,0.2,0,0.3,0,0.5c1-0.7,2.1-1.2,3-2.1C591.2,453.6,590.3,454.2,589.3,454.7"
          />
          <path
            id="path4840"
            class="countryIsland"
            d="M485.7,279.3c-0.8,0.1-1,0.7-0.4,1.3C485.8,280.4,486.1,279.9,485.7,279.3"
          />
          <path
            id="path4844"
            class="countryIsland"
            d="M487.7,278.5c-0.5,0.2-1,0.5-1.4,0.9C486.9,279.6,488.2,279.5,487.7,278.5"
          />
          <path
            id="path4846"
            class="countryIsland"
            d="M487,279.8c-0.3,0.2-0.4,0.5-0.5,0.8C486.6,280.3,486.8,280,487,279.8"
          />
          <path
            id="path4852"
            class="countryIsland"
            d="M489.1,278.3c0,0.2,0,0.5,0.1,0.7C489.2,278.7,489.1,278.5,489.1,278.3"
          />
          <path
            id="path3836"
            class="countryIsland"
            d="M676.8,441.7c-0.6-0.3-0.3-1.1-0.1-1.5C676.5,440.7,676.5,441.2,676.8,441.7"
          />
          <path
            id="path3860"
            class="countryIsland"
            d="M708.2,430.8c-0.2,0.5-0.5,1-0.8,1.5C707.5,431.7,707.7,431.2,708.2,430.8"
          />
          <path
            id="path3824"
            class="countryIsland"
            d="M605.8,445.1c-0.6,0.6-1.4,1-2,1.6c1-0.6,2.1-1.1,2.9-1.9C606.3,444.9,606,445,605.8,445.1"
          />
          <path
            id="path3826"
            class="countryIsland"
            d="M629.5,442.8c-0.5-0.9-1.6-0.5-2.2-0.1C628,443.2,628.9,443.7,629.5,442.8"
          />
          <path
            id="path3828"
            class="countryIsland"
            d="M633,445.6C633.9,443.6,630.7,445.8,633,445.6"
          />
          <path
            id="path3830"
            class="countryIsland"
            d="M649,438c-0.2,0.1-0.5,0.3-0.7,0.4C649,438.9,649.3,438.8,649,438"
          />
          <path
            id="path3838"
            class="countryIsland"
            d="M678.4,442.2c0.9,0.4,2.2-0.5,3-0.9C680.7,441.8,679.2,442.8,678.4,442.2"
          />
          <path
            id="path3862"
            class="countryIsland"
            d="M708.4,453.5c0,0.2,0,0.5-0.1,0.7c-0.2-1-0.4-2,0.3-2.9C709.1,452.1,708.5,452.8,708.4,453.5"
          />
          <path
            id="path3864"
            class="countryIsland"
            d="M709.2,457.7c0.4,1.7,0.7,3.5,0.6,5.2C709.5,461.2,709.1,459.4,709.2,457.7"
          />
          <path
            id="path3856"
            class="countryIsland"
            d="M706.1,479.4c-0.8,1.1-1.7,2-2.7,3C704.3,481.6,704.8,479.6,706.1,479.4"
          />
          <path
            id="path3840"
            class="countryIsland"
            d="M693.8,466.7c0,0.2-0.1,0.5-0.1,0.7C693.6,467.2,693.7,466.9,693.8,466.7"
          />
          <path
            id="path3842"
            class="countryIsland"
            d="M693.9,469.1c0.2,0.2,0.5,0.4,0.7,0.6C694.3,469.7,693.5,469.1,693.9,469.1"
          />
          <path
            id="path3844"
            class="countryIsland"
            d="M694.5,469.3c0-0.5-0.1-1-0.2-1.5C694.6,468.2,694.7,468.7,694.5,469.3"
          />
          <path
            id="path3848"
            class="countryIsland"
            d="M695.7,468.9c0.3-0.7,0.8-1.2,1.5-1.4C696.6,467.9,696.3,468.5,695.7,468.9"
          />
          <path
            id="path3872"
            class="countryIsland"
            d="M761.3,393.7c-0.4,0.1-0.8,0.1-1.2,0.1c1.9-0.6,2.4-2.8,2.7-4.5
    		C762.7,390.8,762.3,392.4,761.3,393.7"
          />
          <path
            id="path3874"
            class="countryIsland"
            d="M786.7,347.1c-0.3,0.5-0.8,0.9-1.4,1.1C785.6,347.6,786,347.1,786.7,347.1"
          />
          <path
            id="path3876"
            class="countryIsland"
            d="M791.4,346.9c-0.2,0-3.5,0.3-1.4,0.5c-0.8,0.2-1.6,0.1-2.3,0.4c1.7-0.5-0.2-0.3-0.7-0.5
    		c0.9-1.2,1.3-1.3,2.7-1.7c0.8-0.2,1.1-0.9,1.9-0.9c0.7,0,1.3,0.2,2,0.2c1,0,2.1-0.5,3.2-0.5c1.7-0.1,2.8-1.3,4.3-1.6
    		c-1.1,0.6-2,1.3-3,1.9c0.9,0.5,1.3-0.4,2-0.8c0.9-0.4,2.3-0.1,3.3-0.5C800,346.1,795.2,346,791.4,346.9"
          />
          <path
            id="path4104"
            class="countryIsland"
            d="M809.7,338.6c-0.1,0.6-0.5,1.2-1.1,1.5C808.7,339.5,809,338.7,809.7,338.6"
          />
          <path
            id="path4106"
            class="countryIsland"
            d="M813.8,340c0.4,1.2-1.5,1-2.2,1C812.4,340.8,812.9,340,813.8,340"
          />
          <path
            id="path4108"
            class="countryIsland"
            d="M817,341.9c-0.5,0-1,0-1.5-0.3c0.7-0.1,1.7,0,1.7-1C817.3,341.1,817.3,341.5,817,341.9"
          />
          <path
            id="path4110"
            class="countryIsland"
            d="M834.7,315.2c-0.2,0.2-0.3,0.5-0.5,0.7C834.2,315.6,834.3,315.3,834.7,315.2"
          />
          <path
            id="path4112"
            class="countryIsland"
            d="M836,316c0,0.6-0.3,0.9-0.9,0.9C835.2,316.4,835.5,316.1,836,316"
          />
          <path
            id="path4114"
            class="countryIsland"
            d="M837.3,316.7c-0.3,0.1-0.5,0.2-0.8,0.3C836.8,316.9,837,316.8,837.3,316.7"
          />
          <path
            id="path4116"
            class="countryIsland"
            d="M839.2,314.6c0.6,0.9-0.8,1.3-1.3,1.7C837.2,315.6,838.3,314.3,839.2,314.6"
          />
          <path
            id="path4190"
            class="countryIsland"
            d="M715.4,290.1c0-0.2,0-0.3-0.1-0.5c0.7-0.1,1.7,0.3,1.8-0.7c-1.5-0.2-3.6,0.2-4.8,1
    		c-0.4,0.3-1.4,0.8-1.6,1.4c-0.2,0.7,0.7,0.6,1,0.4c-0.6,0.2-0.9,0.7-0.7,1.3C712.3,291.7,713.7,290.6,715.4,290.1"
          />
          <path
            id="path4192"
            class="countryIsland"
            d="M715.6,283.1c-0.8-0.5-6.3,1.7-5.9,2.8C711.8,285.3,713.6,283.8,715.6,283.1"
          />
          <path
            id="path4194"
            class="countryIsland"
            d="M739.9,300.4c-0.6,0.4-1.1,0.8-1.5,1.3C739.4,301.7,740.9,301.9,739.9,300.4"
          />
          <path
            id="path4196"
            class="countryIsland"
            d="M725.6,303.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5,0.4-0.7,0.9-0.8,1.5
    		C725.2,304.7,725.6,304.2,725.6,303.6"
          />
          <path
            id="path4198"
            class="countryIsland"
            d="M710,309.9c-0.8,0.5-0.6,1.9,0.6,0.8c1.1-1,2-2.2,2.9-3.3C712,307.6,711.3,309.2,710,309.9"
          />
          <path
            id="path3630"
            class="countryIsland"
            d="M103.3,510.1c0.3-0.8,1.5-1.1,1.4-2C104.1,508.6,102.9,509.1,103.3,510.1"
          />
          <path
            id="path3632"
            class="countryIsland"
            d="M109.1,509.3c0.8-0.5,2.7-3.2,0.6-3.1C107.2,506.3,105.9,508.9,109.1,509.3"
          />
          <path
            id="path3634"
            class="countryIsland"
            d="M120,514.6c2,0.3-0.1-3.6-0.5-3.6c-0.5,0-2.3,0.8-2.3,1.4C117.3,513.9,119.3,513.8,120,514.6"
          />
          <path
            id="path3636"
            class="countryIsland"
            d="M126.5,516.4c0.7,0,1.1-0.4,1.1-1.1c-1.2,0.3-3.6-1.1-4,0.7C124.6,516,125.5,516.2,126.5,516.4"
          />
          <path
            id="path3638"
            class="countryIsland"
            d="M125.6,519.1c1.4-0.4,0.3-1.9-0.8-1.4C125,518.2,125.3,518.6,125.6,519.1"
          />
          <path
            id="path3642"
            class="countryIsland"
            d="M129.2,520.5c0.8-0.3,3.5-0.3,3-1.8c-0.4-1.4-1.6-0.9-2.6-1.1c-0.6-0.1-1.3-1.4-1.8-0.5
    		c-0.4,0.7-0.2,1.1,0.4,1.6C129.2,519.2,128.6,519.7,129.2,520.5"
          />
          <path
            id="path3644"
            class="countryIsland"
            d="M131.1,534.4c0.9,0.3,2.1-1.1,2.7-1.6c1.3-1.2,3-1.2,4.4-2.1c1.5-1.1-0.1-1.8-0.5-3
    		c-0.2-0.7,0.3-1.1-0.3-1.7c-0.7-0.6-1.5-1-2.3-1.4c-0.7-0.4-2.1-1.7-2.7-0.5c-0.2,0.5,0.2,0.9,0.1,1.4c-0.1,0.7-1.3,1.2-1.7,1.6
    		c-1.2,1.1,0.1,2.8-0.1,4.1C130.5,532.6,129.6,533.3,131.1,534.4"
          />
          <path
            id="path3920"
            class="countryIsland"
            d="M2435.9,244.8c0.3-1.3,1.8-0.4,2.6-0.6c-1.9-0.2-5.1-3-6.7-0.8c0.6-0.1,1.6,0,2.2,0.3
    		c0.2,0.2,0.3,0.4,0.4,0.6C2434.6,244.6,2435.6,244.6,2435.9,244.8"
          />
          <path
            id="path3922"
            class="countryIsland"
            d="M2443.9,248.1c-0.4-1.3-1.3-1.4-2.2-0.4C2442.4,247.8,2443.3,247.6,2443.9,248.1"
          />
          <path
            id="path3924"
            class="countryIsland"
            d="M2458.5,183.6c-0.8-0.9-2.2-0.5-3.2-0.8c-1.1-0.3-2.4-1.2-3.6-0.9
    		C2453.8,183.1,2456.6,182.5,2458.5,183.6"
          />
          <path
            id="path3926"
            class="countryIsland"
            d="M2472.1,252.5c-0.1-0.6,0.3-1.1,0.9-1c-1.2-0.4-0.1-1.4-1.4-1.1c0.1,0.2,0.2,0.5,0.4,0.7
    		C2470.9,251.2,2471.4,252.3,2472.1,252.5"
          />
          <path
            id="path3928"
            class="countryIsland"
            d="M2479.6,252.9c-0.4-0.3-0.9-0.4-1.4-0.4C2478.7,252.6,2479.2,252.8,2479.6,252.9"
          />
          <path
            id="path3930"
            class="countryIsland"
            d="M2479.5,251.8c0.2-0.6-0.1-0.7-0.7-0.3C2479,251.7,2479.2,251.7,2479.5,251.8"
          />
          <path
            id="path3932"
            class="countryIsland"
            d="M2484.1,254.1c-0.4,0-0.9,0-1.4,0c1.1,0.6,2.4,0.7,3.5,1.1c1.1,0.4,2.3,1.4,3.6,0.9
    		C2488,255.4,2486,254.8,2484.1,254.1"
          />
          <path
            id="path3934"
            class="countryIsland"
            d="M2487.1,252.1c0.4-0.9-0.9-1.1-1.4-1.2C2485.1,251.8,2486.4,252,2487.1,252.1"
          />
          <path
            id="path3936"
            class="countryIsland"
            d="M2505.3,254.4c-0.2-0.9-1.4-1.7-0.1-2c-1,0-3-1.3-3.8-0.1c0.7,0.2,1.6,0.3,2.2,0.7
    		c0.1,0.2,0.1,0.4,0.1,0.6C2503.9,253.9,2505.1,254.1,2505.3,254.4"
          />
          <path
            id="path3938"
            class="countryIsland"
            d="M2503.4,209.1c0.3-1-0.9-0.9-1.4-0.5C2502.4,208.8,2502.9,208.9,2503.4,209.1"
          />
          <path
            id="path3940"
            class="countryIsland"
            d="M2506.6,253.9c0.7-1.1,1.8,0.1,2.6-0.7c-0.6-0.4-0.9-1.7-1.8-1.4c0.2,0.3,0.4,0.5,0.6,0.8
    		c-0.7,0.3-1.4,0.9-2.2,0.9C2506.1,253.6,2506.4,253.7,2506.6,253.9"
          />
          <path
            id="path3944"
            class="countryIsland"
            d="M2515.4,252.9c-0.2-0.6,0-0.7,0.6-0.3c-0.5-0.4-1-0.4-1.4,0
    		C2514.9,252.7,2515.1,252.8,2515.4,252.9"
          />
          <path
            id="path3946"
            class="countryIsland"
            d="M2522.7,249.9c-0.1,0.7-3.1,0.7-3.7,1c2.3,0,4.5-0.9,6.8-0.9c-0.6-0.2-1.1-0.6-1.5-0.9
    		c2-0.2-2.2-2.5-2.1-0.4C2523.2,248.3,2523.9,249.4,2522.7,249.9"
          />
          <path
            id="path3948"
            class="countryIsland"
            d="M2530.6,250.8c0.9-0.4,1.9-0.3,2.9-0.6c-2.5-0.3-4.9-0.4-7.4-0.3c0.7,0.1,1.2,0.5,1.9,0.6
    		C2528.9,250.7,2529.8,250.4,2530.6,250.8"
          />
          <path
            id="path3950"
            class="countryIsland"
            d="M2534.9,249c0.2-0.3,0.4-0.6,0.4-0.9c-0.5-0.2-1-0.1-1.5,0.1
    		C2533.9,248.7,2534.4,249.1,2534.9,249"
          />
          <path
            id="path3952"
            class="countryIsland"
            d="M2543.6,246.6c0.1-0.4,0.2-0.7,0.5-0.9c-0.5-0.3-1-0.4-1.5-0.2
    		C2542.6,246,2543.1,246.4,2543.6,246.6"
          />
          <path
            id="path4038"
            class="countryIsland"
            d="M2433.9,159.2c-1.5-0.4-2.4,0.9-4.1,0.4c-1.9-0.6-2.6-1.9-4.1-2.9c1.6,0.4,3.1,1,4.7,1.1
    		c1.6,0.1,2.5-0.7,4-0.7c3.1,0,5.7,2.6,8,4.2c-2-1.1-7.2-3.1-9.4-2.3C2433.3,159.1,2433.6,159.2,2433.9,159.2"
          />
          <path
            id="path4040"
            class="countryIsland"
            d="M2511.8,254.4c-0.9-0.3-0.7-1.5-1.7-1.8c0.5,0.1,1,0.2,1.5,0.1c-0.9-0.2-2.1-1.9-0.7-1.5
    		c0.6,0.2,2.5,1.2,2.9,1.8c-0.8,0.3-1.1,1.6-1.9,0.7C2511.8,254,2511.8,254.2,2511.8,254.4"
          />
          <path
            id="path4042"
            class="countryIsland"
            d="M2513.1,252.3c0.7-0.4,1.6,0.1,1.5,0.9C2514,253.1,2513.5,252.8,2513.1,252.3"
          />
          <path
            id="path3778"
            class="countryIsland"
            d="M482.5,226.1c-1.1,0.3-2.4,0.9-3,1.9C480.6,227.6,481.8,227,482.5,226.1"
          />
          <path
            id="path4136"
            class="countryIsland"
            d="M479,227.9c-0.4,0.1-0.6,0.4-0.7,0.8C478.6,228.4,478.8,228.1,479,227.9"
          />
          <path
            id="path4134"
            class="countryIsland"
            d="M479.3,211.7c-0.9-0.5-2.8,1.5-2.4,2.4c0.5-0.4,1.1-0.1,1.6-0.4
    		C479.4,213.1,479.2,212.6,479.3,211.7"
          />
          <path
            id="path4142"
            class="countryIsland"
            d="M464.5,223c-1-0.3-1.9,0.5-2.2,1.4C462.9,223.8,463.8,223.4,464.5,223"
          />
          <path
            id="path4152"
            class="countryIsland"
            d="M465.1,222c-0.3,0.1-0.6,0.4-0.7,0.7C464.7,222.5,464.9,222.3,465.1,222"
          />
          <path
            id="path4166"
            class="countryIsland"
            d="M469.2,218.4c-0.3,0-0.5,0.1-0.7,0.2C468.8,218.5,469,218.4,469.2,218.4"
          />
          <path
            id="path3772"
            class="countryIsland"
            d="M473.9,205.9c0.5-0.1,1-0.2,1.5-0.3c-0.6,0.2-2.9,1.1-1,1.3c-1.8,0.5-3,1.5-4.3,2.7
    		c1.2,0.3,2.7-0.6,3.7-1.1c0.6-0.3,0.9-1,1.5-1.2c0.8-0.3,1.5,0.1,2.2-0.6c-0.2-0.2-0.5-0.3-0.7-0.5c1.1,0.1,1.6-0.6,1.8-1.6
    		c0.2-0.6,0.1-1.5,0.5-2c0.3-0.5,0.9-0.6,1.2-1.2c0.4,1.3-1.2,2.3-0.9,3.7c0.6-1.2,1.5-3.6,2.4-4.5c-1,0-2-0.2-3-0.1
    		c0.1-0.5,0.2-1,0.3-1.4c-0.8-0.1-2.4,2.2-0.7,1.3C476.7,202.2,475,203.8,473.9,205.9"
          />
          <path
            id="path3774"
            class="countryIsland"
            d="M475.6,223.6c1,0.1,1.6-0.9,2.2-1.5c-0.3,0.5-0.7,1-1.2,1.4c1.1-0.6,2.1-1.1,2.7-2.2
    		c-0.2,1.4-1.8,2.2-3,2.6c0.9,0.4,1.7-0.3,2.2-0.9c-0.3,0.5-0.7,1-1.3,1.2c1.6,0.8,4.1-1.8,4.9-2.9c0.5-0.6,1.1-1.8,0.5-2.5
    		c-1-1.2-2.4,0.8-3.5,0.6c2,0.1-0.3,0.6-0.7,0.7c1.8,0.1-0.1,0.4-0.5,0.6c-0.5,0.2-0.2,0.8-0.6,0.9
    		C476.5,221.9,474.8,222.6,475.6,223.6"
          />
          <path
            id="path3654"
            class="countryIsland"
            d="M228.1,238.6c-1.1-1.9-5,1.2-6,1.7c-2.7,1.4-5.6,2.5-8.3,3.8
    		C218.6,242.3,223.6,241.1,228.1,238.6"
          />
          <path
            id="path3656"
            class="countryIsland"
            d="M226.8,240.6c2.3,0.3,4.8-1.2,7.1-1.6c1.2-0.2,5.1-1.1,5.7-2.5c-0.5,0.2-1,0.3-1.5,0.4
    		c0.9-0.6,4.1-0.9,3.9-2.4c-1,0.5-2,0.9-3,1.4c0.5-0.5,0.9-1,1.2-1.5c-1.3,0-6.7,1.8-3,2.4c-0.6,0.3-1.2,0.1-1.9,0.1
    		c-0.7,0.1-1.1,0.8-1.9,0.7c0.6,1.3-6.6,2.3-7.3,2.7C226.3,240.4,226.5,240.5,226.8,240.6"
          />
          <path
            id="path3658"
            class="countryIsland"
            d="M240.2,236.9c0.6-0.3,1.3-0.6,1.8-1.1C241.3,236,240.4,236,240.2,236.9"
          />
          <path
            id="path3660"
            class="countryIsland"
            d="M245.3,234.2c0.8-0.4,1.7-0.3,2.4-0.8c-1.9,0.2,0.5-0.8-1.1-0.7c-0.8,0.1-1.8,0.6-2,1.4
    		C244.7,234.2,245,234.2,245.3,234.2"
          />
          <path
            id="path3662"
            class="countryIsland"
            d="M248.1,233.6c0.7-0.4,1.3-1.1,1.9-1.5C249.2,232.1,248.4,232.7,248.1,233.6"
          />
          <path
            id="path3664"
            class="countryIsland"
            d="M249.2,234.1c0.1-0.6-0.1-0.7-0.7-0.2C248.7,234,249,234.1,249.2,234.1"
          />
          <path
            id="path3666"
            class="countryIsland"
            d="M252,233.5c-0.5-0.1-1,0.1-1.4,0.4C251.1,233.9,251.6,233.8,252,233.5"
          />
          <path
            id="path3668"
            class="countryIsland"
            d="M257.1,229.4c-1.4,0.6-1.3,1.7,0.3,1.7c1.6,0,2.9-1.3,4.5-1.7c1.6-0.3,3.4,0.3,4.9-0.5
    		c0.2-0.1,1-0.7,1.3-0.5c0.6,0.3,1.5,0.9,1.7-0.2c-1.7,0.2-0.1-1.6-0.1-2.2c-2.1-0.5-4.1,1.1-6.1,0.8
    		C261.3,226.5,259.1,229,257.1,229.4"
          />
          <path
            id="path3670"
            class="countryIsland"
            d="M269.7,230.4c-0.5,0.1-0.7,0.1-0.7,0.7c0.6,0.1,1,0,1.4-0.4
    		C270.2,230.6,269.9,230.5,269.7,230.4"
          />
          <path
            id="path3672"
            class="countryIsland"
            d="M277.4,226.5c-0.6-0.1-2,0-1.5,1C276.5,227.3,277,226.9,277.4,226.5"
          />
          <path
            id="path3730"
            class="countryIsland"
            d="M390.8,190.9c-0.5-0.1-1.1,0.3-1.5,0.5C389.9,191.4,390.4,191.3,390.8,190.9"
          />
          <path
            id="path3732"
            class="countryIsland"
            d="M393.4,182.2c-0.6-0.3-1.8,0.8-2.2,1C391.9,182.9,392.6,182.6,393.4,182.2"
          />
          <path
            id="path3742"
            class="countryIsland"
            d="M414.4,186c-0.7,0.7-1.8,0.9-2.5,1.7c1.7-0.2,3.4-0.7,4.7-1.8c1.4-1.1,3.5-0.8,4.7-2.2
    		c-1.1,0.2-0.7-0.1-0.1-0.6C419.1,184.3,416.8,185.6,414.4,186"
          />
          <path
            id="path3746"
            class="countryIsland"
            d="M418.6,182.6c0-0.2,0.1-0.4,0.1-0.6c-0.9,0.1-3.1,1.4-3.2,2.5
    		C416.4,183.8,417.5,182.7,418.6,182.6"
          />
          <path
            id="path3748"
            class="countryIsland"
            d="M418.6,180c-0.4,0.2-0.6,0.2-0.6,0.8C418.2,180.5,418.4,180.2,418.6,180"
          />
          <path
            id="path3752"
            class="countryIsland"
            d="M420.9,178.7c-0.6,0.2-1.2,0.6-1.7,1.1C419.9,179.7,420.8,179.6,420.9,178.7"
          />
          <path
            id="path3754"
            class="countryIsland"
            d="M427.7,182.9c-0.4-0.6-1.4-0.6-2.1-0.5c-0.5,0.1-2.7,0.8-1,0.8c-0.6,0.1-1.1,0.4-1.4,0.9
    		C424.7,183.5,426.2,183.1,427.7,182.9"
          />
          <path
            id="path3760"
            class="countryIsland"
            d="M435.3,186c-1.1,0.4-2.3,0.7-3.2,1.4c1.1-0.6,2.4-0.9,3.6-1.4C435.5,186,435.4,186,435.3,186"
          />
          <path
            id="path3700"
            class="countryIsland"
            d="M343.9,213.2c-0.8-0.1-1.7,0.1-2.2,0.8C342.5,214.2,343.3,213.8,343.9,213.2"
          />
          <path
            id="path3706"
            class="countryIsland"
            d="M356.1,208.9c-1.2-0.7-3.4-0.5-3.7,1.2C353.6,209.7,354.9,209.2,356.1,208.9"
          />
          <path
            id="path3712"
            class="countryIsland"
            d="M363,200.9c-0.5,0.1-0.8,0.3-1.1,0.7c0.5,0.2,1,0.2,1.5,0C363.3,201.3,363.1,201.1,363,200.9"
          />
          <path
            id="path3718"
            class="countryIsland"
            d="M366.5,202.4c-0.4-0.1-0.3-0.3-0.7,0.1C366,202.5,366.3,202.5,366.5,202.4"
          />
          <path
            id="path3720"
            class="countryIsland"
            d="M372.8,197.2c-0.8-0.5-1.9,0.2-2.2,0.9C371.4,198,372.2,197.7,372.8,197.2"
          />
          <path
            id="path3722"
            class="countryIsland"
            d="M371.7,197.1c-0.3,0.1-0.5,0.2-0.8,0.4C371.2,197.3,371.4,197.2,371.7,197.1"
          />
          <path
            id="path3724"
            class="countryIsland"
            d="M373.1,200c-0.3,0.1-0.6,0.2-0.8,0.5C372.5,200.3,372.8,200.2,373.1,200"
          />
          <path
            id="path3674"
            class="countryIsland"
            d="M282.3,225.1c-0.4-0.2-0.8-0.2-1.2,0.1c0.8-0.5,1-0.3,0.4,0.5
    		C281.8,225.5,282,225.3,282.3,225.1"
          />
          <path
            id="path3676"
            class="countryIsland"
            d="M291.2,223c-1.2,0-2.3,1-2.8,2c0.5-0.1,1,0.2,1.4,0c0.7-0.3,1.4-1.4,2.1-1.9
    		c-0.4,0.4-0.9,0.6-1.5,0.6C290.7,223.4,290.9,223.2,291.2,223"
          />
          <path
            id="path3678"
            class="countryIsland"
            d="M292.6,225.7c1.1-0.1,1.8-1,2.5-1.7c-0.6,0.7-1.6,0.6-2.3,1c-1,0.5-2.3,1.6-3.2,2.3
    		C290.5,226.6,291.6,226.3,292.6,225.7"
          />
          <path
            id="path3680"
            class="countryIsland"
            d="M293.1,223.4c-0.5-0.2-1.2,0.2-1.5,0.5C292.2,224.3,292.7,223.9,293.1,223.4"
          />
          <path
            id="path3682"
            class="countryIsland"
            d="M294.9,222.7c-0.4-0.3-1.1,0-1.4,0.4C294,223,294.5,222.8,294.9,222.7"
          />
          <path
            id="path3684"
            class="countryIsland"
            d="M297.2,224.3c-1,0.1-1.5,1.1-2.3,1.5C295.9,225.8,296.6,224.9,297.2,224.3"
          />
          <path
            id="path3686"
            class="countryIsland"
            d="M296.5,225.8c-0.5,0.3-1,0.6-1.4,0.9C295.6,226.6,296.1,226.3,296.5,225.8"
          />
          <path
            id="path3688"
            class="countryIsland"
            d="M295.7,186c0.2-0.1,3.3,1.4,3.5,1.9c0.3-1.4,3.5-1,4.5-1.1c1.2-0.1,3.1-2.4,3.6-3.5
    		c-0.4,0.1-0.7,0-1-0.2c-0.7-0.4-1.6,0-2.4-0.1c-1.1-0.1-2.6,0.4-3.5,0.9C299.5,184.6,294.7,183.4,295.7,186"
          />
          <path
            id="path3690"
            class="countryIsland"
            d="M319.2,196.7c-0.2,0.3-0.5,0.5-0.7,0.8c1.7,0.2,3.3-1.2,4.6-2C321.8,196,320.4,196,319.2,196.7"
          />
          <path
            id="path3694"
            class="countryIsland"
            d="M323.8,213.4c-0.2,0.1-0.5,0.3-0.7,0.4c0.6,0.1,1.1-0.1,1.5-0.4
    		C324.3,213.4,324.1,213.4,323.8,213.4"
          />
          <path
            id="path3696"
            class="countryIsland"
            d="M328.4,218.8c-0.7,0-1.3,0.5-1.4,1.2C327.5,219.6,328,219.2,328.4,218.8"
          />
          <path
            id="path3698"
            class="countryIsland"
            d="M341.7,213.3c-1.3-0.3-3,0.6-3.7,1.6C339.1,214.3,340.5,214.1,341.7,213.3"
          />
          <path
            id="path3702"
            class="countryIsland"
            d="M344.8,213.6c-0.6-0.1-1.1,0.1-1.5,0.4C343.8,214,344.3,213.9,344.8,213.6"
          />
          <path
            id="path3704"
            class="countryIsland"
            d="M347.6,210.7c-0.8,0.3-1.5,0.6-2.2,0.9c1.3,0.9,4.1-0.8,5.3-1.5c-0.3-0.2-0.5-0.3-0.8-0.5
    		c2.1-0.4,4.5-0.9,6.5-1.5c-0.1-0.3-0.1-0.5-0.2-0.8c0.8,0,3.2,0.7,3.7-0.1c0.5-0.8-1-1-1.4-0.8c1.5-0.6,2.6,0.5,3.9,0.1
    		c1.1-0.3,1.9-1,2.9-1.5c-0.5-0.2-1-0.2-1.4,0c0.1-0.6,2.8-2,1.4-2.4c-0.8-0.2-2.8,0.7-3.2,1.4c0.2-0.5,0.7-0.9,1.2-1.1
    		c-0.5-0.4-1-0.7-1.5-1c0.3,2.2-3.4,0.9-2.9,2.3c-0.5-0.5-1.1-0.3-1.5,0.2c0.4-0.4,0.8-0.9,1-1.5c-1.1-0.2-2.1,0.3-2.9,0.9
    		c0.2,0.2,0.5,0.4,0.7,0.6c-0.5,0.1-1,0.3-1.5,0.4c0.1,0.3,0.2,0.5,0.4,0.8c-1.1-0.7-2.2,1.1-2.2,1.9c-0.6-0.8,0.9-2.1,0.4-2.6
    		c-1-1-3.8,0.6-4.7,1.1c-1.8,0.8-1.7,1.6-2.7,3c-1.8,2.5,1.7,0.8,2.7,0.1c-0.8-0.2-1.5,0.1-2.2,0.5c0.7-0.9,2-1,3-0.6
    		c-0.5,0.2-1,0.5-1.5,0.8c1.1-0.1,2.1-0.7,3.1-1.1c-0.7,0.5-1.6,0.6-2.2,1.2C350.3,209.6,348,210.7,347.6,210.7"
          />
          <path
            id="path3710"
            class="countryIsland"
            d="M360.2,202.2C357.6,202.3,362,204.2,360.2,202.2"
          />
          <path
            id="path3714"
            class="countryIsland"
            d="M364.2,202c1.4-0.2,2.6-1.1,3.8-1.7c-0.3,0.2-0.5,0.4-0.8,0.6c1.2-0.2,2.1-0.4,3-1.1
    		c-0.6,1.8,1.7,0.2,2.2-0.3c-0.6-0.1-1.1,0.1-1.5,0.5c0.4-0.4,0.9-0.8,1.4-1c-0.8,0.2-1.6-0.5-2.2,0.3c0.3-1.3-2.1-1-2.4-0.1
    		c-0.3,1.1-2.7-0.5-2.3,1.2C364.2,200.3,362.6,200.9,364.2,202"
          />
          <path
            id="path3726"
            class="countryIsland"
            d="M373.9,190.7c-0.6,0.1-1.1,0.3-1.5,0.6C373,191.4,373.5,191.2,373.9,190.7"
          />
          <path
            id="path3728"
            class="countryIsland"
            d="M377.2,194.4c-0.5-0.3-1.1-0.1-1.5,0.3C376.2,194.6,376.7,194.5,377.2,194.4"
          />
          <path
            id="path3708"
            class="countryIsland"
            d="M361.4,157.9c-0.1-1-2,0-2.3,0.3C359.9,158.4,360.7,158.3,361.4,157.9"
          />
          <path
            id="path3796"
            class="countryIsland"
            d="M311.6,161.3c1.6-1.1,2.8-2.6,4.8-2.2c0.6,0.1,1.1,0.8,1.7,1c0.7,0.3,1.5-0.3,2.4,0
    		c-1.2,1.5-2.8,0.9-4.5,1.1c2-0.3-1,0.1-1.6,0.3c-1,0.4-1.9,1.1-2.9,1.4c0.4-0.4,0.7-0.9,0.6-1.5c-0.2,0.3-0.4,0.5-0.6,0.8
    		C311.7,161.9,311.7,161.6,311.6,161.3"
          />
          <path
            id="path3800"
            class="countryIsland"
            d="M411.6,186c0.7-0.4,1.5-0.8,2.3-1.1C413.4,185.5,412.5,185.7,411.6,186"
          />
          <path
            id="path3802"
            class="countryIsland"
            d="M412.5,185.4c-0.5,0.2-1,0.4-1.5,0.4c0.9-0.1,1.4-0.9,2.3-0.9
    		C413,185.1,412.8,185.3,412.5,185.4"
          />
          <path
            id="path3814"
            class="countryIsland"
            d="M480.9,199.3c0.3,0.2,0.4,0.4,0.5,0.7C479.8,200.6,479.6,199,480.9,199.3"
          />
          <path
            id="path4118"
            class="countryIsland"
            d="M463.4,213.3c0.7-0.3,1.4-1.2,2.2-0.8c-1.8,0.9-3,1.9-3,4.1c1.8-0.7,3.1-2.5,4.2-3.9
    		c0.6-0.7,1.2-1.4,1.7-2.2c0.7-1.1,1-2.6,1.8-3.6c-0.6-0.4-1.5-1-2.2-0.4c1.8-0.5-0.5-0.4-1.2,0c-0.5,0.3-1.8,1.5-0.5,1.6
    		c-0.4,0.3-1.5,1.1,0.1,0.5c-1.4,0.3-1.4,2.4-3,2.7c2,0.3-1,1.6,0.9,0.9C464.1,212.4,463.7,212.8,463.4,213.3"
          />
          <path
            id="path4120"
            class="countryIsland"
            d="M464.8,218.1c0.5-0.2,0.8-0.6,1-1.1c0.1-0.5,1.2-0.6,1.5-1c-1.2,1.6,0.6,0.1,0.9-0.1
    		c0.5-0.4,1.1-0.5,1.6-0.9c0.5-0.4,0.6-1.2,1.1-1.6c0.3-0.1,0.6-0.1,0.9,0.1c0.6-0.1,1-0.7,1.3-1.2c-0.9-0.1-1.5,0.5-2.3,0.7
    		c1-0.4,2.5-1.5,1-2.4c0.2,1.7-0.8-0.5-1.4,1.1c-0.3-0.9-1.3,0.4-1.6,0.9c1.8-0.3-0.1,0.9-0.6,1c2.6,0.9-3.1,2.4-1,1.9
    		C466,215.9,464.9,216.8,464.8,218.1"
          />
          <path
            id="path4122"
            class="countryIsland"
            d="M465.6,202.9c-0.5,0.8,0.2,1.5,0.5,2.2c-1.3,0-0.4,0.5-0.4,1.4c-0.7-1.4-0.9,0.5,0.7,0.2
    		c0.8-0.2,2.7-2.2,0.9-1.9c0.6,0,1-0.3,0.9-0.9c1.1,0.5,1.8,2.4,3,2.5c1.1,0.1,1.9-1.4,2.3-2.1c-0.6-0.4-1.2,0-1.9-0.1
    		c-0.6-0.1-0.8-1.2-1.4-1.5c1.4-0.1,2.1,1.5,3.7,0.9c-0.2-0.2-0.3-0.5-0.5-0.7c0.6,0.1,2.9-0.4,1.7-1.3c-1.5-1.2-3,0.5-4.5,0.9
    		c0.5-1.1,2.2-0.8,2.7-1.9c-1.1-0.5-3.7-0.5-4.5,0.6c0.3-1.3-0.6-0.3-1.2,0.1c-0.6,0.5-0.4,1.3-0.2,1.9c-0.2-0.6-0.6-0.9-1.2-0.8
    		c0.3-0.3,0.5-0.7,0.6-1.1c-0.8,0.3-1.9,1-1.8,1.9C465.3,203.1,465.5,203,465.6,202.9"
          />
          <path
            id="path4124"
            class="countryIsland"
            d="M471.6,206.8c-0.3-0.2-0.6-0.2-0.9,0c0.1,0.3,0.2,0.5,0.4,0.7
    		C471.2,207.3,471.4,207,471.6,206.8"
          />
          <path
            id="path4126"
            class="countryIsland"
            d="M471.7,214.6c0.7-0.1,1.5-0.3,2.2-0.4c0.6-0.1,3.3-1,1.4-1.2c0.5-0.2,0.9-0.7,0.8-1.4
    		c1,3,3.5-1,2.4-1.7c-0.8-0.4-6.2-1.1-4.5,0.9c-1.2-0.1-0.9,1,0.1,0.3C473.4,212,471.3,213.3,471.7,214.6"
          />
          <path
            id="path4130"
            class="countryIsland"
            d="M477.4,214.4c-0.8,0.2-3.7-0.1-3,1.5C474.8,217,477.2,215,477.4,214.4"
          />
          <path
            id="path4132"
            class="countryIsland"
            d="M478.4,215.4c-0.7-0.3-2.8,0.8-3.1,1.5c-0.2,0.6,0.7,1.2,0.6,1.9c1.1-0.2,1.5-1.5,2.6-1.7
    		c-0.3-0.6-0.9-0.9-1.5-0.6C477.6,216.3,478.1,215.9,478.4,215.4"
          />
          <path
            id="path4140"
            class="countryIsland"
            d="M465.4,207.4c-0.9,0-3,1.9-3.4,2.7C463,210.1,466.1,208.9,465.4,207.4"
          />
          <path
            id="path4144"
            class="countryIsland"
            d="M464.1,218.9c-0.3-0.8-1.6,0-1.6,0.6C463.1,219.3,463.6,219.1,464.1,218.9"
          />
          <path
            id="path4146"
            class="countryIsland"
            d="M463.6,222.7c0.3-0.2,0.6-0.5,0.8-0.9c-0.5,0.2-1,0.5-1.5,0.7
    		C463.1,222.6,463.3,222.6,463.6,222.7"
          />
          <path
            id="path4148"
            class="countryIsland"
            d="M465.5,224.3c-1.4,0.1-4.1,4.3-1.9,4.7c0.8,0.1,0.8-1.5,0.7-1.9
    		C464.2,226.4,465.1,224.8,465.5,224.3"
          />
          <path
            id="path4150"
            class="countryIsland"
            d="M464.9,223.5c-0.7,0.1-1.5,0.4-1.8,1.1C463.9,224.7,464.8,224.5,464.9,223.5"
          />
          <path
            id="path4154"
            class="countryIsland"
            d="M465.9,227.1c-0.8-0.8-1.3,0.5-1.1,1.1C465,227.6,465.4,227.2,465.9,227.1"
          />
          <path
            id="path4156"
            class="countryIsland"
            d="M467.8,225.9c-0.2,0.1-0.5,0.3-0.7,0.4c2.2-0.1-1.5,1.8,0.6,1.4c-0.3,0.2-0.5,0.4-0.8,0.6
    		c1.3,1.5,3.4-1.2,4-2.2c-0.5,0.1-1,0.2-1.5,0.2c1.1-0.5,2.1-1.1,3-1.8c-0.5,0.1-1,0.1-1.5-0.1c0.6-0.1,1.1-0.4,1.4-0.9
    		c-0.4-0.4-0.9-0.6-1.5-0.5c0.5-0.2,1-0.3,1.6-0.4c-0.7-0.8-1.6-0.3-2.4,0c1-0.5,2.2-1.4,3-0.1c0.4-0.7,0.8-2.7,0.9-3.5
    		c0.1-0.9-1.1-1.2-1.7-1.4c0.5-0.6,1.5-1.3,1.2-2.2c-1.4,0.2-2.5-0.2-3.7,0.9c0.4,0.4,0.9,0.5,1.5,0.4c-0.5,1-4,0.2-4.5,2.2
    		c1.3-0.7,3.2-0.4,4.2-1.6c-0.5,1.1-2,1.7-2.1,2.5c0.3-0.2,0.5-0.4,0.8-0.6c-0.5,1.1-2.7,0.8-2.5,2.3c0.8,0,1.5-0.4,2.2-0.5
    		c-0.9,0.6-2,1.1-1.3,2.4c-1.1,0.1-2.3-0.7-3,0.6c0.5,0.1,1,0.2,1.5,0.1c-1.8,0.6,0.4,0.4,0.8,0c-0.6,0.9,0.8,1.3,1,0.2
    		c0,0.6-0.4,1.1-0.9,1.3C468.3,225.1,468.4,225.2,467.8,225.9"
          />
          <path
            id="path4158"
            class="countryIsland"
            d="M465.1,219.1c0.4,0.1,0.7,0,0.9-0.4C465.6,218.7,465.3,218.9,465.1,219.1"
          />
          <path
            id="path4160"
            class="countryIsland"
            d="M466.9,224.7c-0.3,0.5-0.8,1.1-1.4,1.4C466.4,226.3,467.2,225.6,466.9,224.7"
          />
          <path
            id="path4162"
            class="countryIsland"
            d="M466.6,222c-0.3,0-0.6,0.2-0.8,0.4C466.1,222.2,466.3,222.1,466.6,222"
          />
          <path
            id="path4164"
            class="countryIsland"
            d="M468.2,219.8c-0.6,0.1-2.9-0.6-2,0.8C466.7,219.9,467.5,220.1,468.2,219.8"
          />
          <path
            id="path4168"
            class="countryIsland"
            d="M475.4,226.4c-0.7,0.1-1.4,0.2-1.9,0.6C474.2,227.5,475.1,227.2,475.4,226.4"
          />
          <path
            id="path4170"
            class="countryIsland"
            d="M474.9,222.9c-0.7,0.4-1.6,1.1-1.2,1.9C474.4,224.5,475.6,223.8,474.9,222.9"
          />
          <path
            id="path4172"
            class="countryIsland"
            d="M475.9,224.3c-0.8-0.6-1.9,1.2-2.1,1.7C474.8,226.5,476.1,225.4,475.9,224.3"
          />
          <path
            id="path4176"
            class="countryIsland"
            d="M469.5,219.2c-0.2,0.2-0.5,0.5-0.8,0.6C468.6,219.1,468.8,218.9,469.5,219.2"
          />
        </g>
        <g id="kz" class="countryGroup">
          <path
            id="path3898"
            class="country"
            d="M1717,301.8c1.8,0.8,2.4-0.5,1.9-0.5c0.4,0.6,2.1-0.3,2.6,0.4c0.5,0.7-0.7,0.5-0.2,1.2
    		s-1.4,0.3-0.9,0.9c0.5,0.7-3.7-0.4-3.3,0.1l-1.6-0.3L1717,301.8z M1712.7,303.2c-0.8-0.5-0.2-0.7-0.9-1.1c-0.7-0.4-2.1,0.8-2.6,0.5
    		c-0.5-0.3,0,1.5-0.3,1.3c-0.2-0.1-0.2,0-0.3-0.1c0.4,0.4,0.8,1.1-0.5,1.4l-16.1,4.7l6.8,31.5c-0.9,0-1.6,0-2.5-0.1
    		c-1.2-0.1-2.6-0.9-3.3-1.7c-1.7-2-3.2-4.3-5.6-5.4c-2.7-1.2-4.4-1.5-7.3-1.2c-2.9,0.3-5.2,2.2-6.8,4.6c0.2-1.3-1.1-2.4-0.6-3.7
    		c0.6-1.4,1.6-3.2,0.1-4.3c0.2,0.2,0.4,0.4,0.7,0.5c1.1-1.5-3.2-1.8-4-2.1c-0.9-0.3-1.5,0.5-2.3-0.3c-0.6-0.6-1.3-1.3-1.6-2.1
    		c-0.5-1.2-2.6,1.3-2.8-1.5c-0.1-1-0.3-1.5-1.1-2.1c-1.1-0.8-1.6-2.2-2.3-3.3c-1-1.6-2-2.4-3.8-2.7c-1-0.2-3.2-1.2-1.8-2.5
    		c0.9-0.9,2.9-0.4,4-0.1c0.7,0.2,1.2,0.7,1.8,0.9c0.9,0.2,2-0.3,3-0.1c-0.8-1.3-2.9-1.4-4-2.4c-1-0.9,0.8-4,1.9-4.4
    		c0.3-0.1,1.7-0.9,1.8-0.9c0.4,0.1,0.7,0.2,1,0.4c0.6,0.2,1.5-0.1,2.1-0.2c2.2-0.2,5.3,1.6,7.2,0.5c-1.1-0.5-3.1-0.8-3.6-2
    		c-0.7-1.8,2-3.9,1.4-6c-0.6-2-1.7-3.6-2.8-5c-0.6-0.7-2.8-1-3.4-0.3c-3.1,2.9-8.8-4.7-11.1-0.1c-0.1,0.2-1.6,0.3-1.7,0.3
    		c-1-0.1-1.1,1-1.8,1.5c-0.6,0.5-1.3,0.8-2,1.1c-0.4,0.2-0.9,0.4-1.4,0.4c-0.8,0-1.9-1.1-1,0.4c-0.5-0.3-1-0.6-1.5-0.8
    		c0.3,0.3,0.6,0.6,0.9,0.9c-0.3-0.1-0.6-0.1-0.7,0.2c-0.2,1-1.2-0.1-0.3,0.9c-0.9-0.6-5.3-2.1-5.4-2.8c-0.3-1.6,5.4,1.1,1.9-2.4
    		c-2.6-2.6-4.1-5.8-7.2-6.8c-1.1-0.3-2.5-0.7-3.6-0.3c-0.6,0.4-0.7,0.8-1.1,0.6c-0.9-0.5-1.1-1.6-1.7-2.4l0.7-0.5
    		c-2.1-2.6-1-1.2-2.2-2c-0.8,0-1.9-0.6-2.3-0.9c-0.4-0.3-0.6-1.6-0.5-1.7v-0.4c0.3-1.1,0.6-2.2,1.6-2.8c0.5-0.3,0.9-0.8,0.5-1.4
    		c-0.8-1.3-2.1-1.3-2.1-3.2c-0.1-2.1,0.6-2.4,1.9-3.6c0.9-0.8-0.3-2.5,0.6-3.4c2.2-2.1,5.8,4.3,7.6,4.5c1.1,0.2,3.9-0.9,2.4-2.2
    		c-1.2-1-1-3.8-2.6-4.3c2,0.2,2.5-0.2,4.3-1.4c1.5-1-1.1-2,0.7-2.5c3.1,0,1.5-1,4.9-1.5c1.5-0.4,0.4-3.6,2.6-2.5
    		c-0.2-0.5-0.3-0.9-0.5-1.4c0.7,0.1,4,0.7,4.1,1.2c0.7,2.4,1.8-0.2,2.6,1.2c0.5-0.6,0.2-1.3,0.9-1.7c1.1-0.2,1.8-0.6,2.7-0.9
    		c1.4,4.4,5.6,1.1,8.4,3c1.1,0.9,0.3,1.4,4.1,2.3c1.3,1.3,2.3,1.7,3.6,2.5c-0.4,0.6,0.3,3.2,1,2.6c1.5-1.4-1.1-3.1-1.1-4
    		c0.9-0.6,2.8,0.5,3.4,1.2c1.2,1.2,3,1,4,1.9c1.2,1,1.9,0.6,3-0.4c0.4-0.3,0.2-1,0.6-1.3c0.6-0.4,1.4-0.8,1.3-1.7
    		c0.6,0,1.2,0.3,1.6,0.6c0-1.1,3.1-1.4,3.5-0.6c0.3,0.5,1.1,1,1.5,1.3c0.8,0.5,1.4-0.5,2.2-0.1c0-2.3-0.3-1.7,1.7-1.4
    		c1.1,0.2,1.3-1.2,2.4-0.5c0.8,0.5,1.4,1.6,2,2.4c1.6,2.2,7.6,0.9,6.4,2.7c0.9-0.1,1.7,0.1,2.1-0.8c0.2-0.5-0.2-1.8,0.5-2.1
    		c0.9-0.4,1.4,1,2,1.3c0.8,0.4,2.2,0.4,3.1,0.4c1.1,0,3.9-0.4,4.1-1.7c0.1-0.8-0.9-3.2,0.5-3.3c-1-0.3-1.8-1.3-2.9-1.4
    		c-1-0.1-1.2,0.1-2-0.6c-0.8-0.7-1.3-0.9-2.4-0.9c-1.4,0-1.3-0.7-2.4-1.3c-0.7-0.4-1.3-0.4-2.2-0.7c-0.8-0.3-1-1.2,0-1.1
    		c4.3-2.8,1.8,0,5.1-2.8c-0.4-0.3-3-2.5-3-2.9c-0.1-0.9,2-1.4,1.1-2.4c3.2-0.4,1.4-0.5,4.8,0.2c0.5,0.1,1.3-0.1,2.1-0.5
    		c0.9-0.4-1-1-1.6-1.2c-1.6-0.4-3.3-0.6-4.8-1.1c-2-0.7,1.8-1.6,1.8-1.7c-0.5-0.7-0.9-0.6-1.6-0.4c-1,0.2-2-0.3-2.8-0.8
    		c2.4-0.2,0.4-1.8-0.3-2.4c0.5-0.1,0.9-0.1,1.4,0c-0.4-1.4,3.3-0.4,3.8-0.2c1.2,0.5,1.3-0.3,2.4-0.5c1.2-0.2,1.5,0.9,2.5,1
    		c-0.3-1.9,1.6-1.5,3-1.5c0.1-0.2,0.2-0.5,0.3-0.7c1.7-0.7,3.2-0.3,4.8-0.4c0.3-0.2-0.2-0.9,1.1-0.8c0.8,0,2-0.4,3-0.2
    		c0.8,0.1,1.4-0.6,2.1-0.1c1,0.7,0.9,0.1,0.9-0.7c0-2.3,6.4-1.7,7.8-2c3.5-1.3,6.2-0.7,10.4-3c-0.7-0.1-1.2-0.1-0.6-0.6
    		c1.6-1.3,2.3-0.3,2.1-1.8c0.6,0.2,1.2,0.2,1.8,0.1c-0.2-0.2-0.4-0.5-0.6-0.7c0.8,0.1,2.1,0.8,2.7,0.4c0.8,0.1,1.3,0.1,2.1,0.3
    		c0.6,0.1,4.3,2.6,5.2,1c1-1.7,3.2,0.1,3.7,0.7c0.6,0.7,0.7,1,1,1.8c0.4,1,1.6,1.4,2.5,1.9c-0.9,1,1.6,3.2-0.5,3.3
    		c1.3,1.7,2.1,0.3,3.6,1.2c0.5,0.3,1.1,0,1.7-0.7c0.5-0.6,1.9,0.2,2.4,0.1c-0.6-0.3-0.8-0.9-0.7-1.5c1.1,0.5,1.9,1.9,3.1,2
    		c-1,0.8-0.1,1,0.3,1.9c0.4-0.4,1-0.6,1.6-0.6c-0.3-0.5-0.9-0.8-1.5-0.5c1.1-3,6,3.2,7.3-0.3c0.5,0.4,1,1.1,1,1.8
    		c-1.4-0.2-2.3,0.8-2.3,1.5c0,1,1.9,1.3,1.9,1.9c1-0.9,1.6-1.7,3-1.6c0.9,0.8,1.1,0.1,1.9,0.4c0.6,0.3,0.8,0.9,1.3,0.9
    		c-0.1-2.1,1-2.3,2.5-2.8c0.7-0.3,2.9-1.1,1.9-2.2c0.9-0.1,1.7-0.2,2.5-0.5c0.9-0.2,1.5-1,2.2-1.5c0.5-0.2,0.9,0,1.4-0.2
    		c0.9-0.4,1.2-0.5,2.1-0.8c-0.2,0.3-0.4,0.5-0.6,0.8c0.7,0.2,0.8,0.7,0.5,1.4c-0.4,0.9-1.2,0.2-1.9,0.6c3,2.3,6.7,3.6,10,5.4
    		c3.3,1.9,6,4.5,8.9,7c3.2,2.8,6.3,5.8,9.5,8.8c1.9,1.8,4.5,6,7.2,6.6c-1.3-1.2,2.1-0.8,1.3-2.6c-0.7-1.6,0.2-1.2,0.7-1.6
    		c2-0.3,1.4,0.4,2.6,0.6c0.9,0.1,0.7,0,1,0.3c0.6,0.9-0.7,2.2,1.3,1.7c1.4-0.3,1.8,1.7,2.9,1.9c0.6,0.1,1.2-0.2,1.8-0.4
    		c0.9-0.4,2.1,0.8,2.5,0.4c1-0.9,1.1,0.4,3-1.3c0.3-0.2,0.8-0.2,1.1-0.3c0.6-0.1,0.5-0.6,1-0.7c3.9-0.6,7.8,3.3,9.4,5.4
    		c1,1.3,3.3,1.2,4.7,1.6c1.7,0.5,1.6,1.8,2.8,2.9c0.5,0.5,1,0.8,1.4,1.4c1.6,0.7,4.8,0.2,5.8,0.9c1.5,0.9,1.8-0.7,2-1
    		c0.3-0.4,1.9-1.9,2.1-0.5c0.1,0.7-0.5,1.5,0.8,1.5c0.7,0,1.2,0.7,1.8,1.4c0.6,0.7,1.2,1,1.9,1.1c0.7,0.1,1.3,0.9,1.5,1.2
    		c-1.1,0-4.2-0.5-3.1,1.7c0.8,1.6,0.3,2.7-1.2,3.6c-1.3,0.8-3.5-0.4-3.8,1.6c-0.6,3.6,1.8,4.8,3.1,7.8c1.2,2.6-1.8,2.8-2.6,3.4
    		c-0.9,0.7-1.6,1.2-2.6,0.1c-0.9-1-2.7-0.5-3.8-0.4c-3.2,0.3-4.6-1-6.8-1.7c-2.3-0.8-0.5,3.9-1.6,5.6c-0.1,1.8,0.8,4.2-0.1,7.4
    		c-0.3,2.4,3.8,0.3,3.3,3.8c-0.2,1.2-1.1,0.5-1.7,0.2c-1-0.5-1.7,0.3-2.7,0.1c-0.8-0.1-2.4-1.5-3.2-1.1c-1.1,0.5-2.5,0.5-3.6,0.9
    		c-1.4,0.5-5.9,1-6.2,2.2c-0.4,1.8,4.4,0.3,4.2,2.2c-0.3,2.9,2.4,6.2,3.2,7.3c0.8,1,1.8,2.2,2.1,3.4c0.3,1.4,2,1.8,1.1,2.7
    		c-0.9-0.6-1.7,0.4-2.1,1c0.5,0.2,1.1,0.5,1.5,0.9c-3.4,0.6-1.8,2.5-1.8,2.6c0.4,1,1.7,2.7,1.6,4c-1-1.5-3.7-3.2-4.8-3
    		c-2.1,0.4-2.4-1.3-4-2.1c-1.4-0.6-4.3-1.7-5.8-1.6c-3.4,0.3-6.6-0.6-7.4-0.2c-0.8,0.4-1.3-0.3-2.1-0.4c-0.9-0.2-2.4-0.1-3.2,0.4
    		c-1.2,0.7-5.7-0.7-5.6,0c0.4,3.5-9.9-2.4-11.9-3c0.4,0.6-2.6,1.4-3,1.6c-1.6,0.9-0.6,4.5,0.4,5.4c-0.8,0.3-1.8-0.5-2.5-0.8
    		c-3.2-0.4-2.1-0.1-3.7-1.1c-2-0.7-4.5-1.5-6.7-1.4c-2.3,1-2.3,0.5-3.5,0.9c-0.2,0-0.7,0.9-0.9,1.8c-0.4,1.6-1.2,1.5,1,2.1
    		c-1.5-0.1-2.4,1.3-2.8,1.5c-0.9,0.5-1.6-0.7-2.3,0.7c-1.5,2.9-2.7,2.2-4.7,3.6c-0.6,0.4-1.3,0.9-1.9,1.4c-0.7,0.6,0.2,1.6-0.4,1.9
    		c-1,0.5-1.7,1.6-2.1,2.3c-0.6,1,0.6,1.6,0.7,2.7c-1.3-0.4-4.9-0.3-4.4-2.4c0.2-1.3-0.9-2.6-2.3-2.6c-2.3-0.1-7,1.9-8.5-0.6
    		c-0.9-1.5-1.5-3.1-2.1-4.7c-0.8-2.1-2-0.9-3.3-1.8c-2.1-1.4-1.7-5.9-2-8.1c-2.4,0.7-2.6-0.1-4.1-1.8c-2-2.1-3.3-2-4.2-3.5
    		c-2.6-2.4-2.7-0.1-4.8,0.3c-3.5,0.5-6.4-0.4-9.6-0.5c-1.7,0-3,0.5-4.6,0.9c-2.8,0.7-4.3-1.3-5.7-2.6c-1.7-1.6-3.3-3.3-5-4.8
    		c-1.9-1.7-1-1.2-3.3-2.3l-7.7-3.8l0.4-2.2c-0.8-0.8-1.7-2.3-3.1-1.8c0.6,0.6,1,0.7,2,1.9l-0.9,1.5l-5.1-2.8
    		c0.1-0.4-1.1-2.2,0.4-1.5c-0.7-0.4,0.1,0.1,0.4-0.7L1712.7,303.2z M1715.1,301c0.1-1.4-1.9-2.4-1.5-2.2c3.3,0.1,2.4,1.8,2.2,2
    		L1715.1,301z M1817,294.3c0.3,0.6,2.6,1,3.4,0.9c0.9-0.1,2.3-1.2,3.2-0.8c0.6,0.3,1.3,0.1,1.9,0.3c0.7,0.2,0.8,0.9,1.4,1.1
    		c0.6,0.3,1-0.2,1.6-0.1c0.6,0.1,3.1,1.5,1.6,0c0.6,0.4,1.4,0,1.9,0.6c0.6,0.7,1.3-0.2,2.1-0.4c1.1-0.2,2.1-0.2,3.1,0.1
    		c1.2,0.3,2.2,0.9,3.3,1.2c0-0.5-0.1-1-0.1-1.5c0.7,0.2,0.7-0.2,1.2-0.5c0.7-0.4,1.7,0.5,2.3,0.6c-0.5-0.2-1-0.6-1.2-1.1
    		c0.7,0,3.8-0.6,3.4,1.2c0,0.2-0.4,0.3-0.6,0.4c-0.4,0.2,0,0.8-0.1,1.2c-0.4,1.2-4.5,0.1-3.8,1.4c-0.6-0.9-1.5-0.2-2.3-0.2
    		c-0.9,0-1.6-0.8-2.5-0.8c-1.1,0-2.1-0.9-3.2-0.8c-1.5,0.2-2.5,0.2-4,0c-0.9-0.1-9.3,0.5-8.4-1.4c-1.7,0,0.4,1-0.8,1.4
    		c-0.8,0.3-1.2,1-1.8,0.3c-0.1,0.6-0.4,0.8-0.9,1c-0.4,0.2-0.2,1.4-0.3,1.8c-0.3,1.7-3.3,0.2-2.7,1.8c0.2,0.5,0.2,0.8,0.3,1.3
    		c0.1,0.5,0.1,0.9,0.1,1.4c-0.2,0.2-0.5,0.4-0.7,0.6c-0.2,1.6,2.3,2.9,1.6,4.5c-0.8,0.4-0.8-0.5-1-1c-0.3-1.1-1.7-1.5-2.5-2.1
    		c-0.6-0.5-2.5-1.5-2.8-2c-0.4-0.6,0.7-1.4-0.2-2c1.2,0.4,1.2-1.3,0.7-1.9c-1.2-1.3,1.2-1.3,1.9-1.3c-1.7-0.8,1.1-2.8,1.9-3
    		c1-0.3,0-1.2,0.7-1.7C1815.5,294.3,1816.4,295,1817,294.3L1817,294.3z M1719.6,294.8c1.5-0.2,1.6,0.3,1.8,1
    		c0.1,0.2,0.1,0.3,0.2,0.6c0.3,0.6,0.9,0.9,1.6,1.1c0.2,0,2.6-0.2,1.3-1.2c0.5-0.4,0.9-0.4,1.1-0.4c0.2,0-1,1.7-0.9,1.6
    		c1-0.3,1.1,1.3,0.3,1.7c-0.7,0.4-1.6,1.4-2.5,1c0.2-0.4,0.2-0.7-0.1-1c-0.7-0.9-0.3-1-1.3-0.6c-0.8,0.4-1.3-0.5-2-0.6
    		c-0.7-0.1-1.2,0.3-1.8,0c-0.8-0.4-1.3-1.4-0.4-2c1.5-1,1.7,0.4,2.3,1.3c0.1-0.4,0.1-0.7,0.1-1.1c0.8,0.4,1.2,0.6,1.8-0.2
    		C1720.4,295.6,1719.9,295.4,1719.6,294.8L1719.6,294.8z"
          />
          <path
            id="path7142"
            class="countryIsland"
            d="M1718.2,303.3c0.7,0.4,3.1-0.3,1.8-0.6c0.9-0.4,1.2-0.7-0.4-1.1l-2.6,1.1
    		C1716.1,303.3,1716.3,303.5,1718.2,303.3z"
          />
        </g>
        <g id="tf" class="countryGroup">
          <path
            id="tf-"
            class="countryIsland"
            d="M1772.1,1121.9c-0.3-0.2-0.5-0.4-0.6-0.7c-0.3,0.2-0.5,0.5-0.7,0.7c0.4-0.7,1.9-1.8,1.7-2.7
    		c-0.4,0.4-0.9,0.5-1.5,0.5c0.5-0.2,0.9-0.7,0.9-1.4c-0.8,0.3-2.2,1.8-1.5,2.7c-1-0.1-1.4,1-0.7,1.7c-1-0.1-0.8,2.3-1.4,3.3
    		c-1.1,1.6,1.3,1.3,2,0.3c0.8-1,1.2-0.5,2.3,0c0.9,0.3,2.1,0.5,1.5-0.8c1,0,0.4,1.2,1.2,1.7c1.1,0.7,1.4-0.7,2.3-0.8
    		c1.3-0.2,0.1-1.3-0.5-0.7c-0.8,0.8-2.4,0.1-1.9-1.1c0.6,0.5,1.1,0.5,0.9-0.4c0.7,0.3,1.7-0.2,2.3,0.2c0.4,0.3,2.1,1.2,0.9-0.2
    		c2.3,0.8,1.8-4.4-0.3-1.7c-0.3,0.3-1.8,0.1-2.2,0.3c-0.5,0.2-0.8,0.7-1.3,0.9c-0.4,0.2-2.1,0.2-0.4-0.3c-0.8,0.2-1.7-0.1-1.7-1
    		c-0.3,0.1-0.6,0.2-0.8,0.4c0.7-0.8,2.7-0.2,2.2-1.8C1774.3,1121.8,1773,1121.9,1772.1,1121.9c0.8-0.2,1.5-0.8,1.6-1.7
    		C1772.8,1120.4,1772.1,1121,1772.1,1121.9"
          />
          <circle id="tf_" class="circle" cx="1776.7" cy="1123.8" r="6" />
        </g>
        <path
          id="uz"
          class="countryGroup country"
          d="M1692,309.9l16.3-4.8c1.5-0.5-2,4.7,0.1,7.5c0.1,0.1,0.3,1.7,0.7,2.4c0.4,0.7,0.9,0.6,1.1,0.6l1-1
    	c-0.2,0-0.7-2.1-0.7-2.3c-0.8-1.8-0.1-2.4-0.3-2.8c0.3-0.6,1.1-0.5,0.5-1.4c-0.4-0.6-1.1-1.8-0.6-2.6l5.1,2.8
    	c-0.9,1.7-0.7,0.1-1.8,3.9c-0.7-1.3,1.1,1.8,1.4,1.1c0.8-2.9,2.9-1.5,2.1-4.6l7.7,4c1.9,0.2,4.9,3.8,8.6,7.3c0.9,0.9,2,1.9,3.3,2.3
    	c1.5,0.5,3.6-0.5,5.1-0.8c3.9-0.7,8.1,1.2,11.9,0.3c1-0.2,1.9-1.8,3-1.2c1.4,0.8,2.3,2.1,3.7,2.9c1.4,0.7,1.9,1.7,2.9,2.9
    	c1.1,1.2,1.6,0.7,3,0.6c0.3,2.2,0,6.6,2,8.1c1,0.7,2.4-0.1,3.2,1.2c0.9,1.5,1.2,3.3,2,4.9c1.5,3.1,6.1,0.9,8.7,1
    	c1.6,0,2.6,1.3,2.4,2.7c-0.2,2.1,3.1,1.9,4.3,2.3c-0.1-0.8-1.5-2-0.7-2.8c0.8-0.7,0.8-1.6,1.9-2.1c0.9-0.4,0-1.4,0.5-1.9
    	c0.7-0.7,2.3-1.6,3.2-2.2c1-0.7,2.3-0.7,3-1.8c0.3-0.5,0.8-1.7,1.5-1.8c1.1-0.2,1.1,0.5,2.1-0.5c1.1-1.1,2.1-1.2,3.5-0.5
    	c-1.6,1.5-5.7,3.5-6.1,5.8c0.9,0,1.3,0.7,2.2,0.8c1,0.1,1.7-0.9,2.3,0.5c0.9,2.3,3.5,0.7,4.9,2c0.2-0.4,0.2-0.8,0-1.2
    	c0.4,0,0.7-0.1,0.9-0.5c0.3-0.6,0-1.4-0.1-2c0.6,1.2,1.6,1.3,2.6,2.4c1,1.1,2.5,0.5,2.5,2.3c1.8-1.1,2.6,0.9,4.1,1
    	c1,0,1.8-0.1,2.6,0.4c0.7,0.4-1,1-1.2,1.3c-0.7,1.2-1,1.5-2.9,0.7c-0.1,0.6,0.2,1.1,0.5,1.7c-0.4,0-4.6,0.2-2.4,1.4
    	c-0.6,0-1.2,0.7-1.9,0.5c-0.6,0.2-0.3-0.9-1-0.1c-0.5,0.2-1.1-0.6-1.6-0.8c-1.3-0.4-2.1,0.1-2.9,0.5c-0.7,0.3-0.9,0.2-1.4,0.3
    	c-1.7,0.4-1.4-1-3.3-1.9c-0.7-0.3,1.4-1.2,1.7-1.9c0.2-0.4,0-0.9-0.3-1.1c-1-0.6-1.4-1.6-2.2-2.3c-0.3,1-3.4,3.8-4.7,3
    	c-0.8-0.6-2.4-1.5-2.7,0.3c-0.1,0.4,0.1,1.3,0.6,2c0.5,0.9,0,1.4,0.6,1.9c-1.5,0.6-3.6-1-4.9,0.4c0.8,0.6,2-0.4,2.5,0.7
    	c-0.3,0-0.7-0.1-1-0.1c0.3,0.7,0.2,0.8,0.4,1c-0.1,0,0,0.3-0.2,0.5c-0.2,0.2-0.8,0.1-0.8,0.8c0.2,1.1,0.4,2-1.1,2.3
    	c-1.2,0.3-4.1-0.7-5.1-0.6c-0.3,0-1,0.2-1.1,0.6c-0.7,1.8-0.3,1.9-0.7,2.3c0.4,0.9,1.4,0.8,1.9,1.1c1.2,0.7,0.2,1.1,1.8,1.4
    	c0.8,0.1,1.3-0.2,2.3,0.3c0.7,0.3,0.7,3.1,1.1,4.3c0.3,1,1.4,1.3,1.9,2.2c0.8,1.4-0.4,3.1-1,3.9c-1.2,1.3-1.7,3.1-0.3,5.6
    	c-1.7-1.3-1.5-1-2.2-0.9c-2,0.2-2.2,0.4-2.9-0.4c-0.4-0.5-1.1-0.8-1.8-0.9c-0.8-0.2-1.9,0.2-2.6,0.1c-1-0.8-0.7-1.8-1-2.6
    	c-0.3-1,1.1-2.7-0.5-3.1c-1-0.3-1.8-0.4-2.7-1c-1.5-1-2.7-0.7-4.3-1c-1.8-0.3-3.2-1.8-4.6-2.7c-1.4-0.9-2.8-1.3-4.2-2.3
    	c-1.2-1-2.7-0.8-4-1.7c-1.5-1.1-3.2-2-4.7-3.1c-2.4-1.7-7.5-3.4-8.7-6.2c-1.2-2.6-3.2-4.2-4.5-6.8c-0.7-1.4-1.6-2.3-3-3
    	c-0.4-0.2-1.1-0.5-1.5-0.4c-0.6,0.2-0.2,1-0.9,1c-1.4,0.1-2.5-0.8-4.7-0.6c-1.2,0.1-2.4-0.4-3.5-0.9c-0.2-0.1-0.6-0.4-0.8-0.8
    	c-0.2-0.4,0.2-1,0-1.4c-0.1-0.5-0.7-1.1-0.7-1.5c0-0.3,0.4-0.2,0.8-0.2c-0.5-1.2-1.9-0.8-2-2.3c-0.1-1.5-1-2.1-2.5-2
    	c-0.7,0-2.9,0.2-3.2-0.7c-0.4-1.4-1.9-1-2.9-1.6c-0.5-0.3-1.7-1.8-2.4-1.3c-0.8,0.6-1,0.9-2.2,0.8c-1.7-0.2,2.2,2.4,2.4,2.9
    	c-1-0.2-1.2-0.8-2.2-1.3c-0.3-0.2-1.7-1-1.8,0.5c-0.1,3.1-2.9,1-4.2,2.5c-1.7,2-1.8,2.3-0.8,4.7c0.3,0.7,1.4,1.5,0.8,2.3
    	c-0.4,0.5-2.4,0.1-3,0.1c-2.2-0.1-3.9-0.2-4.6-0.1L1692,309.9"
        />
        <path
          id="mn"
          class="countryGroup country"
          d="M1896,274.5c1.7,0.1,1.1-1,1.4-2c0.7-2.2,4.5,0.4,4.3-1c1.2,0.1,1.5,0.6,2.2,0.1
    	c-0.1-0.2-0.3-0.5-0.4-0.7c1.3-0.2,1.9-0.5,3.1-0.9c-0.6-0.3-0.9-1-1.2-1.6c0.3-0.2,2.4-0.4,2.2-1c-0.3-0.7,0.7-0.7,1.2-1
    	c0.7-0.5,1.9-0.3,2.4-0.7c0.6-0.4,1.2-1.3,2.3-1.8c0.7-0.3,1.4,0,2-0.8c0.4-0.6,0.6-1,1.3-1.2c0.8-0.1,3.5,0.1,2.8-1.5
    	c1.4,0,2,1.7,2.9,1.3c0.2-0.1,1.2-1,1.5-0.7c0.7,0.8,0.7,1.5,1.9,1.6c1.6,0,3.2,0,4.8,0c1.7-0.1,3.1,0.2,4.2,1.7
    	c2,2.8,4.2,3,7.4,3.7c1.7,0.4,2.5-0.4,4-0.4c0.2,0,1.7,0.1,1.9,0.2c1.7,0.3,3.8,0.6,5.7,0.9c1.3,0.2,3.4,1.8,4,0.7
    	c0.6-1.1,1.1-1.2,2.2-1.4c1.1-0.3,1-1.9,1-2.7c-0.1-1.2-0.8-1.8-1.9-2.2c-1.6-0.7-2-2.1-3.3-3c-0.9-0.7-2.2-5.1,0-4.4
    	c-0.5-1-0.7-2.1,0.7-2.5c1.3-0.4,1-2.1,0.8-3.1c1.6,0.4,3,1.1,4.7,1.7c1.6,0.6,3.2,1.3,4.9,1.6c1.8,0.2,3.1,0.6,4.9,0.8
    	c1.6,0.2,4.1,1.6,5.6,1.2c3-0.8,6,1.7,6.5,3.6c0.3,1.3,1.8,2.1,2.5,3.1c0.9,1.3,3,2,3.9,2.5c1.1,0.6,2.7,0.1,4.1,1.3
    	c0.5,0.4,2.5,0.7,3.5,0.4c1.4-0.5,1.6,0.2,3.5-0.6c1.5-0.6,3-1.1,4.5-1.5c3-0.7,7.2,0.3,10.2,0.6c1.5,0.1,2.7,1.3,3.9,2.1
    	c2,1.2,3.6,0.8,5.8,1.2c1.1,0.2,1.7,1.3,2.4,2c0.9,1,1.9,1.4,3.1,2c1.6,1,3.2,1.2,4.5,1.1c2.3-0.1,3.8,0.4,5.8,0.9
    	c1.6,0.3,3.1,0.2,4.7,0.3c1.2,0,2.8,0.6,3.5-0.1c1.1-1.2,2-1.4,4.2-1.6c0.9-0.1,3.7-1.1,5.3-0.9c1.5,0.2,2.4-0.1,2.7-1.6
    	c0.4-1.6,1.5-2.3,2.8-3.2c1.2-0.8,1.8-1.1,3.4-1.3c1.4-0.2,2.9-0.3,4.7,0.9c1.2,0.8,2.9,1.8,4.4,2.1c1.3,0.2,2.1-0.2,3.2-0.7
    	c1.7-0.8,4.3,0.5,5,1.3l0.9,12.4c1.4,1.1-1,1.7-0.2,2.7c0.8,1,2.4,2.1,3.3,2.5c1.2,0.6,1.7-1.2,3-1c1.7,0.1,3.3-0.4,5,0.4
    	c1.4,0.6,2.2,1.3,3.3-0.2c0.9-1.2,1.6-1.9,3.2-1.8c2.8,0.2,6.4,2.3,8.7,3.8c2.7,1.7,5.7,3.3,7.9,5.7c2.7,2.8-1.7,2.6-3.5,2.4
    	c-1.2-0.2-2.4-1.3-3.2-0.5c-0.3,0.3-1.8-0.2-2.3-0.2c-1.3,0.1-2.1,0.7-3.2,1.3c-0.8,0.4-2.4-0.7-2.6,0.3c-0.2,1-0.2,1.5-1.4,1.4
    	c-1.5-0.1-2.9-0.3-3.6,1.3c-0.2,0.6-0.4,1.2-0.4,1.9c0,0.7,0.8,1.4,0.7,1.9c-0.1,0.6-2.1,2-2.6,2.5c-1,0.8-2.7,0.8-3.9,0.8
    	c-0.9,0-2.4-0.8-3,0.3c-0.5,1-0.6,2.2-1.5,3c-0.6,0.5-1.2,0.4-1.7,1.1c-0.3,0.5-0.6,1-1.3,1c-1.5-0.1-3.1-0.4-4.6-0.7
    	c-2.5-0.5-5.4-3.6-8.1-1.6c-1,0.8-1,2.1-0.5,3.2c0.3,0.7-0.2,1.3,0,2c0.2,0.7,0.8,0.8,1.3,1.2c0.9,0.9,6.9,4.2,4.4,5.3
    	c-0.6,0.3-1.6,1.3-2.1,1.8c-0.7,0.7-2,0.8-2.4,1.8c-0.5,1.5-1.1,3.2-2.3,4.3c-1,0.9-2.8,1.3-4,2c-1.4,0.8-3.8,0.6-5.4,0.7
    	c-2,0.1-3.7-0.3-5.7,0c-4.1,0.7-7.7,1.1-11.5,3c-1.6,0.8-3.2,1.6-4.5,2.7c-1.2,1-1,1.2-2.5,1c-0.9-0.1-1.8,0-2.6-0.6
    	c-1.4-1.2-1.5-1.2-3.5-1c-3.7,0.4-7.3-1.5-10.8-2.1c-1.7-0.3-3.5-0.6-5-1.3c-1.7-0.8-2.7-2.4-4.6-2.8c-1.7-0.3-3.4,0-5.1-0.4
    	c-1.5-0.3-3-0.5-4.5-0.8c-1.7-0.3-3,0.7-4.7,0.9c-2.2,0.2-4.6-0.4-6.8-0.5c-3.7-0.1-7.4-1.6-11.2-1.2c-1.1,0.1-2.9,0.7-3.9,0.2
    	c-0.5-0.2-0.8-0.8-1-1.2c-0.4-0.7-1.4-1.2-2-1.5c-2.9-1.6-4.2-4.8-6.6-6.9c-0.9-0.8-2.4-1-2.5-2.4c-0.2-1.4-3.2-0.8-4.5-1.4
    	c-1.5-0.7-3.1-1.5-4.6-2.4c-1.3-0.8-2.8-0.7-4-1.7c-2.8-2.2-6.7-1.3-10-1.6c-2.7-0.3-5.6-0.8-8.3-1.1c-2.4-0.3-5.5-2-5.8-4.7
    	c-0.2-1.3,1.5-2.5,0.6-3.6c-1.2-1.4-0.6-3.2-1.6-4.5c-1.3-1.6-2.4-2.9-4.2-4.1c-1.6-1.1-2.1-2.6-3.3-3.9c-0.8-0.9-2.3-1.5-3.4-2.3
    	c-0.9-0.8-0.3,0.3-1.1,0.3c-0.7,0-1.3-0.5-1.8-0.8c-1.3-0.9-1.5-0.6-2.9-0.5c-1.8,0.1-3.7-1.2-5.1-2.2c-1.1-0.8-2.2-1.8-3.5-2.1
    	c-1.3-0.3-1.9-0.2-1.8-1.5c0-0.9-2.2-1-2.6-1.9c-0.1-0.3,0.3-0.3,0.4-0.5C1897.3,275.8,1896.2,274.9,1896,274.5"
        />
        <path
          id="bt"
          class="countryGroup country"
          d="M1959.6,463.7c0-0.6,0.1-0.7,0.5-1c0.6-0.5,0.2-1.4,0.8-1.9c1.3-0.9,1.8-3.1,2.7-4.4
    	c1-1.4,2.3-2.7,4.2-2.7c0.4,0,1.9,0.2,2.1,0.7c0.2,0.5-0.3,1.1,0.4,1.4c1.1,0.4,2.4,0.2,3.6,0.2c0.7,0,1.1,1.1,1.8,0.4
    	c0.6-0.6,0.8-0.8,1.7-0.5c1.2,0.4,2.7,0.7,2.6,2.1c0,1.6,0,3.2,2.1,3.1c2.1-0.1,1.7,1.7,1.6,2.9c0,0.5,1.2,1.8,0.7,2.1
    	c-1.3,0.7-2.6,0.8-3.9,0.3c-0.6-0.2-1.8,0.5-2.4,0.6c-1,0.1-2,0.1-3,0c-1.5,0-2.6-1.4-4-0.6c-1.1,0.6-2.6,1.7-3.9,1.2
    	c-1.2-0.4-1.8-1.4-3.2-1.3C1961.3,466.5,1960.5,465.1,1959.6,463.7"
        />
        <g id="au" class="countryGroup">
          <path
            id="path3366"
            class="countryIsland"
            d="M2402.7,858.6c0.9-0.6,0.2-1.5-0.1-2.2C2401.8,857,2402.2,857.9,2402.7,858.6"
          />
          <path
            id="path3368"
            class="countryIsland"
            d="M2419.6,874.5c0.7-0.3,0.6-1,0.3-1.5C2419.8,873.5,2419.7,874,2419.6,874.5"
          />
          <path
            id="path3294"
            class="countryIsland"
            d="M2308.5,796.7c0.5-1,0.3-2,0-3C2307.7,794.8,2308.2,795.6,2308.5,796.7"
          />
          <path
            id="path3298"
            class="countryIsland"
            d="M2314.2,799.5c0.1-0.1,0.3-0.3,0.4-0.4C2314.2,798.8,2314.1,799,2314.2,799.5"
          />
          <path
            id="path3310"
            class="countryIsland"
            d="M2335.1,803.1c0.3-0.3,0.6-0.6,0.9-0.9C2335.4,802.2,2335,802.6,2335.1,803.1"
          />
          <path
            id="path3312"
            class="countryIsland"
            d="M2335.4,799.7c0.8-0.3,1.6-0.8,2-1.5C2336.8,798.9,2335.8,798.8,2335.4,799.7"
          />
          <path
            id="path3318"
            class="countryIsland"
            d="M2338,797.7c1.2-0.7,2.2-2.2,2.2-3.6C2340,795.5,2338.7,796.5,2338,797.7"
          />
          <path
            id="path3288"
            class="countryIsland"
            d="M2292,801c0.4,0,0.7-0.2,1.1-0.4c-0.2-0.8-1.1-0.8-1.1-1.7c-0.1-0.3-0.3-0.6-0.5-0.9
    		c0.1-0.5,0-1-0.1-1.5c-1.1,0.4-1.3,1.1-1.4,2.2c-0.1,0.8-1.3,1.3-0.9,2.2C2290.1,800.6,2290.9,800.5,2292,801"
          />
          <path
            id="path3290"
            class="countryIsland"
            d="M2298.1,797.1c-0.2-1-1.3-1-1.5,0c-0.7-1-1.8,0-2.8-0.2c-0.4-0.1-1.6-1.6-1.9-0.7
    		c-0.6,1.7,1.6,5.9,3.8,5.4c1.2-0.3,3.6-1.9,4-3.1c0.2-0.5,1.1-2.2-0.3-2.5C2298.3,795.7,2297.4,795.8,2298.1,797.1"
          />
          <path
            id="path3334"
            class="countryIsland"
            d="M2347.3,1049.6c0.4-0.2,0.6-0.5,0.9-0.9C2347.6,1048.7,2347.3,1049,2347.3,1049.6"
          />
          <path
            id="path3336"
            class="countryIsland"
            d="M2348.9,1048.8c0.4-0.1,1.7-1.2,0.8-1.5c-0.7-0.3-2.6,0.1-2.4,1.1
    		C2348,1048.2,2348.5,1048.3,2348.9,1048.8"
          />
          <path
            id="path3286"
            class="countryIsland"
            d="M2291.1,1010.7c0.8-0.2,1.3-0.8,2-1.2c1-0.7,1.5-0.2,2.6-0.3c0.9-0.1,1.6-1.1,0.3-1.3
    		c-0.2,0-0.3,0.2-0.4,0.3c-0.5,0.4-1.4-0.3-1.9-0.3c1.9-1.8-5.8-0.3-6.2-0.2c-0.8,0.2-2.9,1.4-1.8,2.5
    		C2287.3,1011.5,2289.6,1009,2291.1,1010.7"
          />
          <path
            id="Australia_Tasmania"
            class="countryIsland"
            d="M2329.7,1068.3c0.1,0.8,0.6,1.7-0.4,2.1c1.3,0.3,0.9-1.2,1.9-1.5
    		c1.2-0.4,1.9,1.3,0.6,1.8c0.3-1.6-1.1-0.3-1.1,0.5c0.1,0.9,0.9,0.8,1.3,0.1c-0.4,2,1.7-1.5,2-1.9c-1.3-0.1-0.4-0.8,0.2-1.4
    		c0.7-0.8,1.5-1.5,2.2-2.3c0.6-0.7,0.9-1.5,1.6-2.1c0.6-0.5,1.7-1,1.9-1.9c0.4,0.8,0.1,1.6-0.4,2.2c1.1-0.4,1.3-1.7,1.7-2.7
    		c0.6-1.3,1.6-2.5,2.3-3.7c1-1.7,3.9-4.5,2-6.2c-0.9-0.8-1.2,0.9-2.2,0.7c-1.3-0.3-1.9,0.4-3,0.9c-0.5,0.3-6.1-0.6-4.6,1.5
    		c-0.8,0.3-1,0-0.7-0.7c-1.9-0.1-3.6,1.5-5.4,0.1c-0.6-0.5-3.8-2-3.4-2.8c-1.1,0.5-3.1,0.5-3.5-0.9c-2,2-2.6,4-3.5,6.6
    		c-0.7,2,1.3,5.2-0.8,6.7c0.2-0.2,0.5-0.3,0.8-0.4c-0.1,1,0.6,2.3-0.6,2.9c0.2-0.8,0-1.5-0.4-2.2c-2.5,2.4-1.1,5.9-1.3,8.8
    		c0.8-0.4,1.4,0.3,2.2,0.1c0.4,1.1-0.9,1.3-1.4,0.6c-0.3,0.5-0.5,1-0.8,1.5c1-0.2,2.9,0.6,3.7-0.3c0.1,2.8,2.9-0.1,3.5-0.6
    		c0.9-0.7,1.1-1.1,1.3-2c0.2-0.8,1-0.1,0.8,0.6C2327.6,1072.6,2329.7,1069.6,2329.7,1068.3"
          />
          <path
            id="path3306"
            class="countryIsland"
            d="M2320,1044.5c-0.2,0.4-0.4,0.7-0.6,1.1c1.6-0.1,3.6-2.3,3.6-4c0-1.4-1.2,0.1-1.4,0.2
    		C2320.9,1042.4,2319.9,1043.5,2320,1044.5"
          />
          <path
            id="path3338"
            class="countryIsland"
            d="M2348.3,1044.5c-0.1,0.4-0.5,2.3,0.3,2.3c1.7,0.1,1.7-1.7,2.6-2.6c-1-0.2-1-1.3-1.6-1.9
    		C2348.8,1042.8,2348.7,1043.7,2348.3,1044.5"
          />
          <path
            id="Australia_mainland"
            class="countryIsland"
            d="M2139.1,989.8c1.9,0,3.9-2.1,4.8-3.8c0.9-1.8-0.2-4.5,1.7-5.9
    		c-0.5,0.4-0.7,0.9-0.6,1.5c0-0.6,0.4-0.9,0.9-0.7c-0.5-1.6,0.8-3,1.3-4.3c1.1-2.9,0.6-5.2,0.1-8.2c-0.4-2.7-1.5-5.7-0.7-8.4
    		c0.7-2.6,2-6.2,1.6-9c-0.2-1.1-0.8-2.1-0.8-3.3c0-1.2,0-2.2-0.4-3.4c-0.4-1.1-1.4-2.1-1.5-3.2c-0.1-1.4,0.6-2.7,0.6-4
    		c0.1-2.7-0.7-5.4-2.2-7.6c-0.6-0.9-1.3-1.9-1.6-3c-0.2-1,0.7-1.5,0.6-2.5c0.2,0.7,0,1.5-0.1,2.2c0.6-0.6,0.8-1.4,0.8-2.2
    		c-0.1,1,0.5,1.9,0.3,3c0.4-2,0.2,1.1,0.1,1.5c1-1.6,0.5,0,0.6,0.7c1.9-1,2.8-1.9,1.2-3.8c-1.1-1.3-1.9-4.3-0.5-5.6
    		c0.6,0.6,1.1,1.6,1.3,2.4c0.3,1.1-0.7,1.9-0.6,3c0.9,0.1,1.4-0.8,1.5-1.6c0.1,1.2-0.2,2.4,0.4,3.5c0.4,0.6,1.3-0.4,1.5-0.7
    		c0.8-1.9,1.5-3.6,0.5-5.5c-0.9-1.6-0.8-3.5-1.9-5c-0.4-0.6-0.2-1-0.2-1.7c0-1.4-0.7-2.6-0.9-4c-0.3-2.1,1-4.6,2.2-6.3
    		c1.4-2,2.4-3.4,2.9-5.9c0.2-1.1-0.6-2-0.6-3.1c0-1.2,1-2.1,1.5-3.1c0.7-1.4,1.3-3.9,3.1-4.4c0.2,0.9-0.2,1.7-0.5,2.5
    		c-0.4,1.1-0.1,2.3-0.4,3.5c2.4-0.8,2.4-2,3.5-4c1.2-2,3.1-2.8,5.2-3.5c3-0.9,5.1-3,7.4-4.9c0.6-0.5,1.4-1.8,2.1-1.9
    		c1.8-0.2,3.1-0.9,4.3-2.3c-0.3,2,1.8,0.2,2.6,0.3c0.8,0.1,1.1,0.9,1.9,0.9c1.1,0,2.1-0.4,3.1-0.9c2-1.2,3-2.5,5.5-2.5
    		c1,0,2.1,0,3-0.7c0.9-0.8,1.3-2.2,2.7-2.2c2.2,0,3.6,0.4,5.8-0.2c2.5-0.7,4.8-1.3,7.2-2.5c1.9-1,8.8-5.2,7.4-8.3
    		c0.8,0.1,1-0.3,1.2-1c0.3-1,1.2-1.4,2.1-1.9c1.2-0.8,3.9-2,2.7-3.5c-1.1-1.3-0.4-5,0.3-6.3c0.4-0.8,2.3-3.7,3.4-2.4
    		c-0.7-1.3,0.3-1.2,1.2-1.6c1.1-0.6,1.1-2.6,2.6-3.3c0.6,0.8-1.3,2.7,0.6,2.6c-1.4,1.9,2.4,5.7,2.2,7.7c1.1-1.4,0.2-3.8,1.1-5.2
    		c0.8,0.4,1.1,1.3,1.7,2c-0.1-1.3-0.3-2.2,0.9-3c-1,0.3-3-1.6-2.2-2.5c-0.6,0-0.9-0.4-0.9-0.9c1.1,0.8,0.4-0.2,1.3-0.4
    		c1.1-0.2-0.1-1.5-0.3-1.9c1-0.6,2.8,0.1,1.3,0.4c0.6,0.2,0.9,0.7,0.6,1.3c0.7-0.2,0.8-1.1,1.4-1.2c0.9-0.1,0.9,0.5,1.4,1
    		c0.7,0.6,1.1-0.4,1.8,0c0.8,0.5,1.8-0.1,2.6,0.4c-0.7-0.9-1.4-0.4-2.2-0.7c-1.2-0.4-0.3-1.3-0.2-2.2c0.2,0.2,0.3,0.5,0.5,0.7
    		c1-0.7,0.6-2.3,1.8-2.8c-0.6-0.2-1.1,0.1-1.2,0.7c-0.9-0.9-1.5-2.9,0-3.6c0.7-0.3,2.8-1,1.7-2c0.8,0,1.4,0.6,2,1
    		c0.2,0.3,0.4,0.6,0.6,0.9c0.4,0.1,0.8,0.2,1.1,0.3c-1.1-0.7-0.3-1.9-1.9-2c0.3-0.5,0.7-0.9,1.3-1.1c-0.6,0.1-1,0.3-1.4,0.7
    		c-0.1-1,2.3-2.8,2.2-1.2c0.1-1.6,1.2,0.4,2.2,0.2c-0.4-0.7-1.2-1.4-0.6-2.2c-2.2-0.1,0.4-2,0.5-2.8c0.8,0.9,1.1-0.6,2-0.1
    		c0.2-0.8,0.2-1.7,0.3-2.5c1.1,0.2,0.6,2.2,0,3.1c1.3-1.5,0.8-1.6,1.9,0.2c0-0.8,0.9-0.8,1-1.4c0.1-0.4,0.4-1.5,0.6-1.9
    		c0.3-0.6,1.3-0.5,0.7-1.4c-0.2-0.2-0.4-0.2-0.7-0.1c-0.3,0-1-1.4,0.4-0.3c-0.3-0.9,1.3-1.5,0.3-0.1c1.6-1.5-0.4,1.9,1.5,1.4
    		c-0.4-0.9,0.5-1.6,1.3-1.7c-0.5,0.6,0,1.2,0.4,1.7c0.7-0.8,2.1-1.1,2.5-2.1c0.3-0.8-1-1.2,0.5-1.8c0.8-0.3,1.2,1.7,2.5,1.8
    		c1.1,0,1.2,0.6,2,1.4c1,1,1.5,2.4,2.5,3.4c0.6,0.6,1.4,1.4,1.4,2.3c-0.1,1-0.7,2-0.9,3c-0.2,1-0.2,2.4-1,3.1c0.9-0.5,0.3-1.6,0.9-2
    		c0.9-0.6,1.1,0.7,1.2,1.3c0.4-1-0.8-2-0.4-2.9c0.6-1.2,1.2,0.1,2.1-0.3c-0.9-3.5,6-2.2,5.2,1.1c1.2-0.4,0.7-1.8,0.7-2.7
    		c1.6,0.1,2.1,3.3,3.5,3c-0.3-0.8-1.1-2.2-0.2-2.8c0.7-0.5,1.5-0.1,2.2-0.7c-1.3,0.2-2-0.2-2.4-1.4c0.6,0,1.1-0.2,1.5-0.6
    		c-1.1,0.3-3.6-0.5-2.7-2.1c0.4-0.6,0.6-1.6,1.1-2c0.5-0.4,1.6-0.1,1.9-0.9c0.4-1.2,0.6-2.6,1.2-3.7c0.5-1,1,0.1,1.7-0.4
    		c0.5-0.4,1.6-1.7,2.3-1.3c-0.6-0.6-1.3-1.8-1.2-2.7c0.3-1.5,0.9-0.3,1.6-1.4c0.5-0.7,0.3-1.5,1.1-2c0.6-0.4,1.1,0.7,1.8,0.5
    		c-0.8-0.3-1-2.5-0.1-2.8c0.9-0.3,1.6,1.9,2.4,2.1c-0.9-0.7-1.1-3.2,0.6-2.4c0.2-0.6-0.2-1.3,0.5-1.7c0.8-0.3,1.1,0.8,1.6-0.4
    		c1.2,2.8,8.4-0.1,8.6,1.6c0-1.2,1.2-2.1,2.3-1.4c-1.1-1.2,0.2-3-0.1-4.3c-0.5-2.1-2.4-1.8-4-1.1c0.1-1-1.2-1.4-1.7-1.9
    		c-0.4-0.3,1.2-1.3,1.3-1.5c0.7,0.6,0.9,1.4,1.1,2.2c0.4-0.7,0.5-1.5,0.1-2.2c0.4,0.8,1,0.9,1.4,0.1c0.2,1.1,1.9,2.3,2.5,3.3
    		c0.4-0.6,1.9-0.6,1.4-1.7c0.9,0.6,1.3,3.4,2.4,3.4c1,0,2.3-0.1,2.2,1c1.1,0,1.9-1.3,2.9-0.9c-1.9,1.4,2.3,1.1,1.7,3
    		c0.7-1.3,0.9-0.4,1.9-0.2c1.1,0.2,1.7-0.7,2.8-0.8c0.3,2.3,2.5,2,4,2.5c-1.1-1.8,3.8-0.9,2.2-2.5c0.9-0.2,2.4-0.5,2.4-1.7
    		c0.7,0.9-0.6,1.5-1.2,1.9c1.4,0,0.3,0.5-0.2,1c-0.5,0.5-0.6,1.1-0.2,1.8c0.5-0.7,1.1-1.5,2.1-1.5c-1,1,0.2,0.4,0.2,1.6
    		c0,0.4,0.1,0.9,0.5,1c0.9,0.3,1.8-1,2.1-1.7c-2.6,0,1.6-2.6,2-3.1c-1.2,0.7-0.2,3,0.8,3.3c-0.6-1.2,0.8-1,1.1-0.5
    		c0.3,0.4,0,1.1-0.2,1.5c-0.7,1.4-2,3.4-3.5,4c0.5,0.4,0.8,0.9,1.1,1.5c-0.2-0.2-0.5-0.4-0.8-0.5c-0.2,1.1-0.3,2.1-1.5,2.7
    		c0-0.5,0-1,0.1-1.4c-0.5,0.3-1,0.7-1.4,1.2c-0.1-1.7-1.1,0-1.1,0.6c-0.2-1.6-1.8,0.3-0.9,1.2c-2.1-0.4-0.6,3.7,0.6,1.7
    		c-1.1,2.2-2.1,4.2-3.5,6.2c-0.6,0.9-1.2,1.8-2,2.5c-0.9,0.8-1.4,0.5-0.6,2c1,2,3.3,2.7,4.6,4.4c0.8,1,1.2,1.9,2.3,2.6
    		c0.8,0.5,1.5,1.1,1.2,2.1c0.8-0.3,1.7,0.4,2.2-0.6c0.8,1.2,2.2,1.8,3.4,2.5c1.6,0.9,2.3,2.1,3.4,3.5c1.9,2.4,4.6,1.5,6.9,3.2
    		c1.2,0.9,0.3,2.7,1.4,3.6c0.6,0.5,1.4,0.5,2,1c0.6,0.6,1.3,1,2,1.5c2.1,1.5,6.8,0.1,8-2.2c1.8-3.3,4.1-6.5,5.5-10.1
    		c0.8-2,1.3-4.1,2.1-6.1c0.8-2.2,1.7-3.8,1.6-6.2c-0.1-1.9,0-3,0.7-4.7c0.7-1.7-0.9-3.2,0-5.1c0.7-1.5,2.2-2.7,1.7-4.5
    		c-0.5-2.1,1.2-2.2,1.6-3.9c0.4,0.4,0.7,0.9,0.6,1.5c0-0.6,0.4-1,0.9-1.2c-0.3,0-1.8-1.7-1.9-2c-0.2,0.2-0.5,0.4-0.7,0.6
    		c-0.2-0.8,0.4-1.9,0.9-2.5c0.2-0.2,0.5-0.4,0.8-0.4c-0.4-0.8,0.1-1.6,0.6-2.2c0.2,0.6,0.6,0.8,1.1,0.7c-0.1-3,1.7-5.3,2.1-8.2
    		c0.1-0.9,0.1-1.6,1.1-2c1-0.4,1.4-2.1,2.7-1.3c-0.4,0.5-0.7,1-0.9,1.5c0.2-0.2,0.5-0.3,0.8-0.4c0.3,0.9,1.2,1.4,1,2.4
    		c-0.1,0.4,0,0.8,0.1,1.2c0.3,0.9-0.2,0.9-0.2,1.8c0,0.9-0.2,1.8,0,2.7c0.2,1.1,2,1,2.8,1.4c-1.4,1.1-1.9,2.2-0.8,3.4
    		c1.2,1.3,1,2.5,0.6,4.1c1.6-0.3,0.8,6.5,0.5,7.3c-0.4,1,0.2,1.7,0.5,2.6c0.4,1.1-0.2,2.2,0.3,3.3c0.9,1.9,3.2,0.7,3.4-0.9
    		c1.5,1.8,2.1-2.3,2.8,1.6c0.1,0.9,2,2,2.6,2.8c0.1,0.1,2.2,1.5,2.4,1.5c-0.5,0.7-1.8,2.2-0.4,2.7c-2,2.4,0.4,5-0.5,7.6
    		c-0.6,1.6-0.9,2.4-0.1,3.9c0.4,0.7,1.7,2.2,1.1,3.1c0.4-0.4,0.9-0.6,1.5-0.6c-0.4,1.5-0.6,2.1-0.2,3.7c0.3,1.2,0.3,2.4,0.1,3.6
    		c-0.2,1.3-0.9,2.2-1.4,3.4c-0.6,1.4,1,1.8,0.9,2.9c-0.1,0.9,1.2,0.2,0.8,1.4c-0.3,1-1.4,1.8-0.8,2.9c0.4,0.7,1.6,2.1,2.5,2
    		c0.8-0.1,1.5,2,2.4,0.3c-0.1,1.9,3,2.2,2.7,0.9c0.5,1.1,0.2,4,1.6,4.4c0-0.6,0.4-0.9,0.9-0.9c-0.4,1.5,1.2,1.6,2.3,2
    		c0.9,0.3-0.2,2.2,1.5,1.7c0.4-0.1,0.1-0.7,0.4-0.9c0.7-0.3,0.6,0.8,0.7,1.2c0.2,0.7,0.9,0.3,1.2,0.9c0.3,0.7,0.3,1.5,0.6,2.2
    		c-0.4-0.8-1.4-0.9-1.7,0c-0.5,1.1,0.9,2.2,0.2,3.2c3.9-1.4,1.1,5.6,3.7,5.5c-0.9,1.1-0.9,2.8-0.8,4.1c0.1,1.1,1.2,3,0.3,4
    		c1.4-0.6,1.6-0.2,2.2,1.1c0.6-1.3-0.1-2.7,0.9-3.9c1.2,1.1,1.8,2.6,3.2,3.5c0.2-0.4,0-1.2,0.4-1.5c0.6-0.6,1.1-0.1,1.2,0.6
    		c0.2,1.9,0,3-0.6,4.8c-0.4,1-0.1,2.1-0.4,3.2c-0.7,2.2,0.8,0.8,1.5,2.1c-0.2-0.5-0.1-1,0.2-1.4c1.6,0.5,1.5,4.3-0.1,1.7
    		c0.2,1.1,3.5,5,3.5,2.9c1.6,1.3,1.2,3.3,2.1,5c0.4,0.8,1.3,1,1.8,1.7c0.6,0.8,0.2,1.8,0.3,2.7c0.3,2,2.3,1.2,2,3.2
    		c-0.1,0.7-0.8,1.2-0.9,1.9c-0.2,1,0.7,1.5,0.3,2.7c0.2-0.3,0.5-0.5,0.7-0.7c1.1,0.9-0.6,2.1-0.9,3c-0.5,1.5-1,3.3-1.3,4.8
    		c-0.2,1.2,0.1,2.2-0.9,3c-1.1,0.9-1,1.7-0.2,2.8c0.5,0.7,0.4,2.8,0.2,3.6c-0.1,0.6-0.4,1.2-0.3,1.8c0,0.7,0.5,1.1,0.4,1.8
    		c-0.8,3.5-2.4,5.7-4.4,8.5c-2.1,3.1-3.3,6.6-5.7,9.6c-2,2.5-2.1,5.5-4.1,8c-0.9,1.2-1.7,2.6-2.7,3.7c-1,1.1-2.6,1.8-3.3,3.1
    		c-0.5,0.8-0.7,2.4-1.4,3.1c-0.4,0.4-1.1,0.4-1.6,0.7c-0.8,0.6-1.7,1.5-2.8,1.7c1.9,0.2-1.1,0.9-1.6,1c-1.3,0.4-2.4,1.6-3.3,2.6
    		c-0.6,0.7-1.6,1-2.2,1.7c-0.8,1-0.6,2-2.4,1.8c-0.1,0.6,0,0.9,0.5,0.7c-0.5,0.8-0.9,1.7-1.5,2.4c-0.4,0.6-1.1,0.4-1.5,1.1
    		c-0.9,1.5-2.6,1.9-3.4,3.5c-0.4,0.8-0.3,0.5-0.9,0.7c0,0-0.4,1.4-0.6,1.6c-0.4,0.4-2.5,2.3-1.3,2.8c-0.4,0.6-0.8,1.2-0.9,0
    		c-0.5,0.5-0.3,1.4-1.1,1.5c-1,0.1-1.4,0.4-2.1,1.2c-1.7,1.9-3.5,3.7-5,5.7c-1.3,1.7-2.2,3.8-3.6,5.5c-0.8,1-2.2,3.7-3.4,4.1
    		c1,0.2,0.1,1.8-0.2,2.2c-0.3,0.4-0.6,0.8-0.9,1.2c-0.6,0.5-1.2,0.1-1.7,0.4c-1.7,0.9-2.5,2-4.6,2.2c-2.2,0.3-4.5,0-6.8,0.3
    		c-4.6,0.4-8.4,3.2-12,5.7c-1.7,1.2-3.4,1.7-5.4,1.9c-2,0.2-1.4-0.2-1.8,1.7c0.4-0.4,0.9-0.6,1.5-0.8c-0.2,1-1.1,2.3-2.1,2.7
    		c-0.1-0.6,0.2-1.7-0.2-2.2c-0.7-0.8-1.1,0.1-1.9,0.1c0.2-0.7,0.1-1.5,0.2-2.2c-0.9,0.2-1.6,0.7-1.8-0.5c-0.2-1.3,1-1.3,1.4-2.4
    		c0.6-1.6-2.9,0.7-3.1,0.9c-1.5,1.2-2,0.8-2.8-0.7c1,1.1,2.2-0.1,2.9-0.8c1.2-1.1,0.7-1.8,0.7-3.2c-1.9,0.3-3.6,1.6-5.2,2.6
    		c0.7,0.2,1.5-0.2,2.2,0.4c-0.7,0.9-2,0.7-3,1c-1.4,0.5-2.9,1.3-4,2.2c-1.1,0.9-2.3,1.4-3.6,2c-1.1,0.5-1.3-0.3-2.4-0.9
    		c-1.9-1-3.1-3.1-5.4-2.7c-1.3,0.2-1.8-1.3-3.1-1c-0.7,0.1-0.9,0.7-1.5,0.9c-0.4,0.2-1.1-0.1-1.5,0c1.9-2.2-3.8-2.8-4.5-3.8
    		c-0.7-1-1-2.3-1-3.5c0-0.8-1.1-1.2-0.9-2.5c0.3-2.3,2.5-3.5,3-5.6c0.8-3.4-0.4-6.3-2.5-8.9c1.8,1.2,2.7,3.4,2.9,5.5
    		c0.3-1.4-0.1-2.7-1-3.8c-0.6-0.8-1.6-2.3,0.2-2.2c0,0.5-0.1,1-0.1,1.5c0.9-0.2,0.7-0.7,0.8-1.3c0.1-0.8,1.5-0.6,1.1-1.5
    		c-0.5-1-3.5,0.2-2.8,1.3c-0.4-0.1-2.8-0.4-0.7,0.3c-1.7-0.2-4.9,2.1-6.4,0.6c0.7-1,2.1-1.6,3.1-2.2c0.9-0.6,1.5-1.8,2-2.7
    		c1.3-2.5,1.1-5.2,0.5-8c-0.6,0.4-0.7,1.1-1.2,1.6c-0.6,0.6-1.2,1-1.7,1.7c-1,1.3-1.8,2.8-2.7,4.2c-0.9,1.5-1.2,1.3-2.8,1.1
    		c-1.6-0.2-3.2,1.5-4.9,1.4c0.3-1,1.3-2,2-2.7c1.2-1.1,2.8,0.3,4-0.8c2.1-2,1.9-5.4,3.9-7.6c1.1-1.2,1.7-2,3.1-2.8
    		c1.7-1,1-2.1,1.6-3.6c0.3-0.8,1.2-1,1.9-1.5c0.2-0.2,0-1.3,0-1.5c0-0.5,0.8-2.8,0-3.2c-0.3-0.1-0.8,2.2-0.9,2.5
    		c-0.6,1.2-2.5,2-3.4,3c-0.9,1-1.8,2.1-2.7,3.1c-0.4,0.4-0.8,1.1-1.4,1.3c-0.5,0.2-1-0.3-1.5-0.1c-0.9,0.3-2,1.5-3,1.9
    		c-1.6,0.6-2.7,1.8-3.9,3c-0.4,0.4-1.9,2.5-2.5,2.4c-1.5-0.3-1.8,1.9-2.7,2.6c0.5-0.2,1-0.4,1.5-0.6c-0.6,0.6-0.7,1.6-1.4,2.2
    		c-0.5-1.5-1.8-0.5-2.4-1.7c-0.4-0.8-0.9-2.7-2.2-1.9c0.3-0.6,0.8-1,1.3-1.4c-0.2,1,1,1.7,1.2,0.5c0.2-1.5,0.6-3,0.6-4.5
    		c0-1.4-1.9-2.3-1.3-3.8c0.2-0.6,0.6-1.2,0.3-1.8c-0.3-0.5-0.6-0.8-0.2-1.3c-0.7,0.6-1.2-0.1-2,0.1c-1.1,0.3-0.6-0.5-0.8-0.9
    		c-0.1-0.2-0.7-1.5-0.5-1.7c0.2-0.3,1.6-2.4,1.2-0.6c0.7-0.5,1.4-1.6,0.8-2.4c-0.7-1-1.8-0.1-2.6,0c0.2-0.5,0.9-1.2,0.9-1.7
    		c-0.1-0.7-0.8-1-0.9-1.8c0-0.4-2.4,0.7-2.5,0.7c-1.5-0.2-2.6-1.5-3.9-2c-1.5-0.7-1.6,0.9-2.7,0.7c-2.9-0.5-4-4.5-7.1-4.8
    		c-1-0.1-1.8,0.5-2.7,0.8c-1.1,0.4-2.3-0.1-3.5-0.1c-2.8,0-6.2,0.1-8.9,0.7c-5.1,1.1-9.7,4.1-14.9,5.1c-2.5,0.5-4.8,0.4-7.3,0.2
    		c-3.1-0.3-4.7,1-7.3,2.3c-2.1,1.1-4.6,2.5-6.9,3.1c-2.5,0.7-4.1,0.8-5.7,3c-1.2,1.7-2.4,3.3-4.1,4.6c-0.3,0.2-1.6,1.4-2.1,1.2
    		c-1.1-0.5-1.7,0.5-2.8,0.7c0.7-1.8-4.7-1.1-5.5-0.8c-1.3,0.5-1.5,1.3-2.1-0.5c0,0-3.6-0.3-4.1-0.3c-2-0.2-4.3,0.3-6.3,0.7
    		c-1.5,0.3-2.7,0.1-4.2,0.4c-1.8,0.3-6,1.2-5.5,3.8c-0.4-0.2-1.7,0.6-2.2,0.8c-0.7,0.4-1.3-0.5-2.1-0.4s-1.3,0.8-1.8,1.2
    		c-0.6,0.5-1.1,0.7-1.8,1c-1.1,0.4-1.2,1-2.1,1.6c-1.1,0.8-2.7,1.2-4.1,1.2c1.8,0.8-2,0.4-2.7,0.3c-2.4-0.2-4.8-0.1-7.2-0.6
    		c0.1-0.2,0.2-0.5,0.3-0.7c-2.1,0.3-3.1-1.4-3.9-2.8c-0.7-1.2-2.6-3.5-4.1-2C2135,994.5,2136.6,988.2,2139.1,989.8"
          />
          <path
            id="path3350"
            class="countryIsland"
            d="M2307.9,800c-0.1-0.2-0.2-0.5-0.3-0.7C2308.4,799.3,2308.4,799.4,2307.9,800"
          />
          <path
            id="path3956"
            class="countryIsland"
            d="M2381.9,791.8c0.6-0.3,0.8-0.9,0.2-1.4C2381.5,790.7,2381.3,791.3,2381.9,791.8"
          />
          <path
            id="path3958"
            class="countryIsland"
            d="M2382.1,786.7c0.3-0.2,0.4-0.5,0.5-0.9C2381.8,785.8,2381.6,786.1,2382.1,786.7"
          />
          <path
            id="path3960"
            class="countryIsland"
            d="M2383.1,787.4c0.2-0.3,0.3-0.7,0.3-1.1C2382.6,786.4,2382.6,786.9,2383.1,787.4"
          />
          <path
            id="Australia_Macquarie_Island"
            class="countryIsland"
            d="M2332,1162c-1,0.5-1.3,2.8-0.6,2.4C2332,1164.1,2331.8,1162.6,2332,1162"
          />
          <g id="cx" class="countryGroup">
            <circle id="cx-" class="countryIsland" cx="2102" cy="789" r="0.8" />
            <circle id="cx_" class="disputed" cx="2102" cy="789" r="4" />
          </g>
          <g id="cc" class="countryGroup">
            <circle id="cc-" class="countryIsland" cx="2039" cy="801" r="0.2" />
            <circle id="cc_" class="disputed" cx="2039" cy="801" r="4" />
          </g>
          <g id="hm" class="countryGroup">
            <circle
              id="hm-"
              class="countryIsland"
              cx="1792"
              cy="1149"
              r="0.8"
            />
            <circle id="hm_" class="disputed" cx="1792" cy="1149" r="4" />
          </g>
          <g id="nf" class="countryGroup">
            <path
              id="nf-"
              class="countryIsland"
              d="M2551.3,937.1c-0.8-0.5-1.6-0.9-2.4-1.4c-0.8,0.5-1.1,1,1.1,1.3"
            />
            <circle id="nf_" class="disputed" cx="2549" cy="933" r="4" />
          </g>
        </g>
        <g id="cn" class="countryGroup">
          <g id="cnx">
            <path
              id="path2708"
              class="country"
              d="M1826.5,357.3c1,0,1.7-0.5,1.5-1.6c-0.3-1.5-1-1.2-0.6-2.8c0.4-1.3,1.4-0.8,2.4-1.2
    			c1.1-0.4,1.5-2,2.8-2c-0.2-0.5-0.3-0.9-0.2-1.4c0.7,0.2,2.2,0.9,2.9,0.3c0.5-0.5,1.1-1.2,1.8-1.3c0.8-0.1,1.1,1.9,1.7,2.3
    			c0.9,0.8,2.6-0.3,3.7-0.2c2.1,0.2,2.2-2.7,2.4-4.2c0.2-1.2,0.7-1.6,2-1.4c1.6,0.2,3.1,0.3,4.7-0.1c0.8-0.2,2,0.1,2.6-0.5
    			c0.7-0.7,0.6-2.1,1.3-2.9c1.8-2.1,5.2-2.3,7.3-4c0.8-0.7,1.3-1,2.3-1.1c1.4,0,1-0.5,0.6-1.5c-0.5-1.3-1-2.4-1.6-3.6
    			c-0.8-1.6,0.9-1.9,2-2.2c-0.4-0.4-0.9-0.7-1.4-0.9c0.4-0.7,1.2-1.6,2.1-1c0.6-1.3-1-1.4-1.1-2.5c-0.1-1.4-1.5-2.4-2.3-3.5
    			c-1.1-1.5-2.2-2.9-2.9-4.7c-0.4-1.1,0.3-3-0.8-3.5c-0.9-0.4-4.7,0-3.6-1.5c0.6-0.9,2.8-1.1,3.8-1.4c1.8-0.6,3.6-0.7,5.4-1.4
    			c1.8-0.6,2.1,0.3,3.6,0.9c1.2,0.5,2.2-0.7,3.4,0.1c1.4,1,1.4-0.6,1.1-1.6c-0.4-1.4-2.2-0.6-2.9-1.6c-0.6-0.9,0-2.6,0.1-3.6
    			c0.1-1.5-0.2-3-0.2-4.5c0-1.4,0.6-2.9,0.3-4.3c-0.3-1.6,0.5-2.1,2-1.4c1.2,0.6,2.3,1.1,3.6,1.5c1.8,0.4,3.4-0.2,5.2-0.1
    			c1.3,0.1,1.8,1.9,3.1,0.9c0.9-0.8,2.3-0.9,3.2-1.7c1.7-1.5-2.5-5.5-2.9-7.1c-0.2-0.9-0.4-3.2,0.4-3.8c0.8-0.6,2,0,2.9-0.4
    			c2.3-1,2.1-2.1,1.4-4.3c-0.5-1.4,1.1-1.4,2.1-1.4c1,0,2.5,0.7,4.4-0.2c0.6,0.5,1.4,1.5,0.9,1.6c-1.2,0.3,1,1.3,1.3,1.5
    			c1.7,0.7,0.1,1.3,1.2,1.8c1.4,0.7,3.3,0.7,4.4,2c1.2,1.5,4.5,3.2,6.3,2.6c1.9-0.5,3.8,2.5,4.6,1c0.8,0.2,2.8,1.4,3.7,2.2
    			c1.2,1.2,1.8,2.7,3.1,3.8c1.1,1,2.5,1.8,3.7,3.2c1.6,2,1.5,1.1,1.6,2.3c0.4,3.5,2,3.7,1.1,5c-1,1.4-0.9,2.8,0.3,4
    			c0.9,0.8,2,2,3.3,2.3c1.6,0.4,3.2,0.6,4.9,0.8c1.6,0.2,3.4,0.5,5,0.6c1.6,0.1,3.1-0.1,4.6-0.1c2.9,0.1,5.6,1.7,7.8,2.8
    			c0.8,0.4,1.6,0.7,1.9,0.9c2,1.2,2.8,1.6,4.4,2.2c1.1,0.4,2,0.2,3.2,0.4c1.5,0.2,0.9,1.4,1.6,2.1c1.3,1.1,2.3,1.2,3.3,2.7
    			c0.8,1.2,1.9,2.4,2.8,3.5c1.1,1.3,2.8,2,4.1,3c0.9,0.7,0.9,1.8,2,2c1.4,0.2,3.1-0.4,4.7-0.5c2.5-0.2,7.1,1.3,10.8,1.5
    			c1.8,0.1,3.6,0.5,5.4,0.4c1.7,0,2.9-1.1,4.7-1c1.9,0.1,4,0.7,5.8,0.9c1.6,0.2,3.1-0.4,5.1,0.6c1.4,0.7,2.3,2,3.8,2.6
    			c2,0.8,4.4,1.1,6.6,1.6c3.2,0.8,6,2,10,1.7c1.4-0.1,1.5,0.4,2.7,1.1c1.2,0.7,4.4,0.5,3.6,0.7c-1.3,0.2,6.7-4.5,8.4-5
    			c1.2-0.3,3.3-1.2,5.1-1.3c2-0.1,3.8-0.7,6.2-0.7c3.1,0,6.9,0.6,9.1-0.8c1.2-0.8,3.2-1.2,4.2-2.3c0.9-1.1,1.1-2.7,1.8-3.8
    			c0.6-1,1.6-0.8,2.6-2c0.4-0.5,1-0.8,1.5-1.4c0.3-0.3,0.7-0.1,0.8-0.5c0.5-2.1-4.7-4.3-6-5.6c-0.9-1,0.2-1.5-0.3-2.7
    			c-0.7-1.5,0.3-3.1,1.7-3.6c2-0.7,3.5,1.4,9.4,2.7c0.8,0.2,2.9,0.6,3,0c0.3-1.3,2-1.4,2.6-2.4c0.5-0.9,0.5-2,1.2-2.8
    			c0.6-0.8,2.4,0,3.2,0c2.2,0.2,4.7-1.2,6-3.1c0.6-0.9-1.1-1.6-0.4-3.2c0.6-1.3,0.2-2.4,3.4-2.1c1,0.1,1.7,0.3,1.8-1.1
    			c0.1-1.2,1.9-0.3,2.4-0.3c0.4,0,1.9-1.1,2.8-1.3c0.6-0.1,1.2-0.1,1.8,0c1.3,0.6,2-0.5,1.8-0.3c0.5,0,1.6,0.6,2.2,0.7
    			c1.1,0.2,2.1,0.4,3.1,0.3c3.6-0.4,0.2-3.3-1.2-4.3c-4-2.7-7.9-4.9-11.8-6.8c-1.8-0.9-4.7-1.5-5.8,0.6c-1.5,2.9-3.8-0.4-6,0
    			c-0.8,0.2-2.4,0-3.2,0.1c-1,0.2-1.1,1.6-2.5,0.8c-0.9-0.5-4-2.1-3.2-3.4c0.4-0.8,1.1-0.7,0.4-1.7c-0.6-0.9-0.6-1.7-0.6-2.8
    			c0.1-3.2-0.3-6.4-0.5-9.6c2.4,1.1,5,1.1,7.3,2.1c2.6,1.2,2.8-1.2,4.4-2.6c1.1-1,3.9-0.8,4.2-2c0.2-1-1.5-1.3-2.1-1.9
    			c-0.5-0.5-0.2-1-0.3-1.6c-0.1-0.9,0.5-1.3,0.1-2.2c-0.2-0.4-0.3-0.9-0.1-1.3c0.4-0.9-0.2-1.5-0.2-2.4c0-2-0.9-3.6,0.9-5
    			c2.1-1.5-0.8-3.2-1.5-4.6c-0.7-1.2-2.2-2.2-3.7-1.6c-1.3,0.5-1.9-0.5-2.6-1.3c2-0.7,1.4-4,3.8-4.4c2.9-0.6,5.6-1.7,8.6-1.5
    			c2.9,0.2,5.4-1.3,8.2-0.1c1,0.4,3.1,0.7,4.1,1.5c1.4,1.1,3.3,1,4.9,1.8c-0.5-2.1,6.2,0.1,6,0.9c-0.2,0.7,2.8,1.5,3.5,1.9
    			c0.6,0.3,0.2,0.7,0.7,1c0.8,0.5,1.5,0.9,2.2,1.4c1.5,1.1,3.6,1.9,4.3,3.3c0.6,1.1,1.6,2.1,2.6,2.7c0.9,0.6,1.5,1.7,2.9,2.2
    			c1.5,0.5,2.2,2.4,3.3,3.2c1.3,1,2.7,1.9,4,2.9c1.1,0.9,1.3,2.2,2.3,3.1c1.1,1,2.1,0.7,2.6,2.2c0.9,2.6,4.9,4.8,7.5,4.3
    			c1.1-0.2,2.7-0.3,3.7,0.2c1.6,0.9,3.2,1.1,4.9,1.8c-1.3-2.3,2,0.6,2.5,0.6c1.2,0.1,2.7,1.9,3.8,2.5c1.7,1,3.2,0.2,4.9,0.9
    			c0.5,0.2,0.2,1.4,0.6,1.9c0.4,0.5,1.3,0.9,1.9,1.2c1.7,1,1.3,2,2,3.5c0.5,1,3.8,2.9,4.8,3.4c0.6,0.3,1.3-0.4,1.9-0.4
    			c1,0,1.9,0.6,2.9,0.5c1.2-0.1,5.1,0,4.7-1.3c-0.4-1.4,1.8-2.5,3.2-2.4c0.1,0,1.3,0.5,1.4,0.2c0.2-0.8,0.1-1.1,1.1-1.3
    			c0.9-0.2,2.3-1.4,3.3-1.1c0.6,0.1,2.4,0.6,2.8,1c-0.1,1,0.2,1.8,0.9,2.5c1,1,3.1,2.2,2.1,3.9c-0.2,0.4-0.9,0.8-1.2,1.1
    			c-0.8,0.8,0.4,1.4,0.7,2c0.2,0.5,0.1,1.1,0.2,1.7c0.2,1,1.1,1.8,1,2.9c-0.1,1.2,2.6,3.2,0.5,3.9c1.3,0.9,0.2,1.3,0.3,2.4
    			c0,0.8,1.1,1.4,0.8,2.2c-0.3,0.9-1.8,0.2-0.8,2.4c0.3,0.6,0.8,1.3,0.9,1.9c0.2,1.1-5.5-0.7-6.4-0.9c-0.4-0.1-2.9-1.2-3-0.8
    			c-0.4,0.8-0.3,2.1-1.1,2.6c-0.6,0.4-1.7,0.5-2.1,1c-0.4,0.5,1.3,1.6,1.5,1.8c2.1,2.2,3.7,4.7,5,7.4c1.1,2.2,3.4,3.7,3.2,6.3
    			c-0.1,1.2,0.6,1.8-0.8,1.6c-1.1-0.2-2.2,0-2.8,1.2c2.1,0.5,3.1,2.4,3.9,3.4c1.1,1.4-4.4-2.5-5.5-4c-0.9-1.3-2-1.6-3.5-1.6
    			c0.3,0.9,1.7,3.5,1.1,4.4c-0.5,0.7-1.8,0-2.4,0.5c-0.9,0.8,0.8,2.4-1.3,2.9c-1.8,0.5-3.7,0-5.5,0.5c0.9,1.3,4.4,2.8,4,4.5
    			c-0.2,0.9-4.4,0.3-5.3,0.2c-0.9-0.1-1.8-0.3-2.6-0.8c-0.9-0.5-1.8-2.1-3-2c-0.6,0.1-0.8,0.8-1.5,0.8c0.1,1.6,0.2,2.5-0.4,4.1
    			c-0.2,0.7-0.4,1.9-1,2.3c-0.3,0.2-1.9,0.3-1.8,0.8c0.1,0.7-0.5,0.6-0.8,1.1c-0.4,0.3-0.8,0.5-1.3,0.6c-0.4,0.2-0.9,1.3-1.1,1.3
    			c-1.6,0.5-2.8,2.2-2.8,3.9c0,2.1-3.1,2.1-4.3,0.9c0.2,1.1-1.4,1-2,0.9c0.3,0.8-0.4,1-1.1,1.1c-1.2,0.2-2.1,1.2-3.1,1.9
    			c-1,0.7-1.6,1-1.8,2.4c-0.1,1-1.2,0.9-0.9,1.8c-0.5-0.3-1-0.5-1.6-0.4c0.8,1-2.6,3.6-3.3,1.1c-0.4-1.3,2.1-1.3,2.4-1.6
    			c0.5-0.5-0.1-1-0.2-1.5c-0.1-0.7,1-1,1.3-1.5c-2.2,1-3.4-0.5-5.4-0.9c0.4-0.5,0.9-0.9,1.5-1c-1.3-1.9-0.4-1.6,1-3
    			c0.6-0.6,0.9-2.6,1-3.4c0.3-3.1-4.6-2.5-5-5.3c0.2,1.8-1.6,1-2.6,0.7c-1.2-0.4-3.9,0.9-2,1.7c-1.4,0.3-2.3,2.5-2.1,3.8
    			c0.1,1.1-3.2,2.3-3.9,2.8c-0.7,0.5-1.4,0.8-1.8,1.7c-0.7,1.3,0.6,2,0.3,3c-0.8,2.4-2.8,1.6-4.6,2.7c-1.2,0.8-2.5-1.2-3.9-1
    			c-2,0.4-1,3.6-0.5,4.8c0.8,2.1,3.2,2.9,4.9,4.1c0.5,0.4,1.8,0.5,2.4,0.3c1-0.4,1.3,1.1,2.1,1.2c0.2-0.5,0.2-1,0-1.5
    			c1.5,0,3.5,1.5,4.1,2.8c0.9,1.8-0.4,3.5,1.6,5c1.3,1,2.9,1.3,4.5,1c1.1-0.2,2.5-0.8,1.2-1.9c0.6-0.4,3.5-2.7,1.4-2.7
    			c1.2,0.2,2.3-0.8,3.4-1.2c1-0.3,2.7,0.8,3.3,1.5c0.5,0.7,1,0.6,1.7,0.4c0.4-0.1,0.9,0.7,1.2,1c1,1,2.7-0.5,3.7,0.6
    			c0.1-0.3,0.2-0.6,0.3-0.9c1.5,1.1,3.6,0.3,5.1,1.4c-0.3,0.1-0.5,0.3-0.8,0.4c1.6,1.3-0.8,1.7,0.7,2.7c0.6,0.4,0.5,1.1-0.3,1.4
    			c-1.3,0.5-1.4-0.7-2-1.5c-0.2,0.4-0.4,0.7-0.6,1.1c-0.2-1.1-0.8-0.8-1.4-0.2c-0.4,0.3-2.9,2.1-1.4,0.6c-0.8,0.5-2.3,1.9-3.2,2.1
    			c-0.6,0.1-2.2-0.8-1.8,0.6c1.1-0.9,2,0.5,1.4,1.4c-0.3-0.8-1.2-0.8-1.4,0.1c-0.1,0.7,0.7,1.2,0.5,2c-0.1,0.4-1.1,0.9-1.5,0.8
    			c-1-0.2-0.6-1.4-1.4-1.9c1,1.4-0.4,0.2-1.1,0.4c0,0.9,1.2,2.3,2.2,2.2c-2,0.3-0.9,3.9-3.3,3.3c0,1-0.2,3-0.7,3.9
    			c-0.8,1.4-0.1,2.3,0.3,3.7c1-1,2.7,0.4,3.5,1c1.6,1.2,3.5,1.6,5.3,2.5c1.4,0.6,1.9,2.8,2.8,4c1.3,1.8,2.5,3.9,4.1,5.4
    			c1.1,1,2.2,2.5,2.3,4.1c0.1,1.8,2.9,2.1,4.2,2.7c1.1,0.5,0.6,1.2,1.2,2.1c0.4,0.7,1.1,0.7,1.8,1c1.1,0.6,3.5,1.8,2.8,3.4
    			c-1.6-0.4-3.3-2-5-1.5c-2.3,0.7-2.2-1-4.1-1.7c-2-0.7-2.8,1.8-4.5,0.9c-0.8-0.4-1.3-1.2-1.8-1.9c-0.6-0.9-1.6-0.9-2.5-1.4
    			c0.4,0.3,0.6,0.9,0.4,1.4c0.4-0.5,0.8-0.5,1.3-0.1c-0.3-0.2-0.5-0.4-0.8-0.6c0.9-0.3,2.6,2,0.9,1.2c1.2,0.8,2.5,2.5,4.2,1.8
    			c0.7-0.3,0.6-0.9,1.4-0.9c0.7,0,1.6,0.4,2.1,0.9c0.3,0.4,0,0.8,0.4,1c0.8,0.4,1.7,0.5,2.6,0.8c1.8,0.7,3.3,2.3,5.1,3.2
    			c0.9,0.5,3,2.5,3,3.7c0,0.6-1.2,0.3-1.5,0.4c-1.2,0.1-1.9,1.1-2.8,1.8c-0.6,0.4-1.3,0.7-1.5,1.5c-0.1,0.4-0.1,1.2-0.6,1.2
    			c-0.9,0-1.4-0.8-2.4-0.4c-0.8,0.3-1.4,1.1-2.1,1.6c0.5-0.2,2.2-1.7,2.7-1.3c1,0.7,1,2.5,2.4,2.5c-0.3-0.2-0.6-0.4-0.9-0.5
    			c1.7,0.3,2.8-1.8,4.4-1.3c1.1,0.3,1.7,1.2,2.5,2c0.9,0.9,3,1.1,4.2,1.4c-0.9,0.4-1.2,1-1.8,1.7c-0.2,0.4-0.4,0.7-0.7,1
    			c-0.4,0.3-1,0.2-1.4,0.6c0.1,0.3,0.3,0.5,0.6,0.7c0.4-0.6,1.3-1.5,2.1-0.8c-0.4-0.4-0.3-1.6,0.5-1.1c0.9,0.5,0.1,1.3,0.6,2
    			c0,0,0.9,2.4,0,1.8c-0.5-0.3-0.5-1.2-0.9-1.6c0.1,0.5,0.2,1,0.2,1.4c-0.6,0.2-1-0.2-1.1-0.8c0.3,1.3-0.1,0.5-0.4,0
    			c-0.1,0.6-0.4,1.1-0.7,1.6c0.9-0.2,2.3,0.7,2.4,1.7c-0.4-0.5-0.8-0.4-1,0.1c0.8,0,1.6,1,0.8,1.5c-1.1,0.7-2.6-0.6-3.5-1
    			c0.5,0.4,0.8,1.2,1.4,1.4c0.8,0.3,1.4-0.3,1.9,0.7c0.2,0.5,1.6,1.7,0.9,2.3c-0.6,0.5-1.4,0.5-1,1.6c-0.9-0.4-1-1.7-1.9-2.1
    			c-0.3,0.5-0.3,0.6-0.8,0.8c1.4,0.2-0.7,4.4-3,1.6c0.3,1,2.6,0.8,2.3,2.1c-0.3,1-1.3,2.9,0,3.6c-0.7,0.3-0.7,0.8-0.6,1.5
    			c0.2,1.5-1-0.1-0.6,0.9c-0.5-0.4-1-0.9-1.4-1.3c0,0.5,2.5,2.3,0.4,1.9c0.4,0.9-0.3,1.6-1.1,1.9c0.9,1.2,1.5,3.4-1,2.7
    			c0.8-0.3,1.1-1.1,0.8-1.9c-0.3,0.3-0.6,0.6-0.8,1c-0.5-0.4-0.9-0.9-1-1.4c-0.3,0.5-0.8,0.9-1.4,0.6c0.4,0.4,0.5,0.9,0.4,1.4
    			c1.2-0.5,2.6,1.5,0.9,1.4c0.1,0.9,1.2,1.4,1.4,0.3c0.9,0.9-0.1,0.9-0.7,1.5c-0.6,0.6-0.9,1.5-1.2,2.3c-0.8-0.7-2-1.1-3-1.4
    			c0.8,0.5,1.3,1.9,2.4,1.7c1.1-0.2,1.5-1.3,2.4,0c-0.7,0.3-0.5,1.5-0.7,2.3c-0.4,1.3,1.6,1.3,1.1,2.6c-0.2-1-0.5-1-0.8,0
    			c-0.4-0.7-1.1-1.3-1.9-1.6c-0.1,0.6-0.4,1.1-0.9,1.4c0.5,0.3,1.8,0.5,1.6,1.4c-0.3,1.3-1.3-0.6-1.3,1.1c-0.2-0.7-0.9-2-1.7-0.9
    			c1,0.3,0.1,1.8,1.3,1.9c-0.3,0.8-1.9,2-1.9,0.6c-0.8,0.6-0.6,0.8,0,1.2c0.8,0.5,0.1,1.8-0.3,2.3c-0.4-0.4-0.8-0.9-1.2-1.3
    			c-0.2,0.9-1.5,1.5-2,0.4c-0.6,0.8-1.9,0.3-1.1,1.4c-0.6-0.2-1.1-0.2-1.7-0.1c0.5,0.5,1.2,0.8,1.9,0.5c-0.1,0.6,0.1,0.9,0.7,0.9
    			c-0.2,0.5-0.7,0.5-1.1,0.9c-0.6,0.5-0.2,1.5-1.1,2c0.3-0.7,0.1-1-0.6-0.8c0.3,0.8-0.2,1.5-0.2,2.2c-0.1-1.5-1.5,0.1-2.3,0.1
    			c-0.2,0.2-0.7,2.7-1.5,1.9c-0.7-0.7-1.1-1-1.3,0.2c-0.1,1.4-0.8,1.7-2.2,1.5c0.8,0.3,1.9,0.3,2.1,1.4c-0.9-1.1-0.9,0.6-1.5-0.1
    			c-0.3,0.9,0.2,2-0.9,2.4c-0.8,0.2-2.1,0.5-2.8,0.1c1.1,1.1-3.1,1.7-2.2-0.1c-0.6,0.7-1.4,0.4-2.2,0.1c0.1,0.6,1.8,1.4,1.4,2.2
    			c-0.3,0.5-0.9,0.1-1.1-0.1c-0.8-0.6-1.6-0.5-1.5-1.5c-0.4,0.6-2.1,1.6-2.7,1.2c1.3,0.4,0.4,1.4-0.3,1.2c-0.9-0.2-0.9-1.3-0.3-1.9
    			c-0.9,0-1.6,0.4-2.1,1.2c1,0.2-0.1,1.2,1,1.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.5-1.5-4-1.4-4.8-0.1c-0.1-2.1-2.7-2.7-2.4-4.9
    			c-0.3,1.4-0.2,0.6-0.9,0.1c0.5-0.8,1.3-0.9,2.2-0.8c-1.1-0.8-2.1,0.8-3.2,0.3c1.6,0.6-0.1,1.9-1.1,1.5c0.4,0.2,1.9,0.7,2,1.2
    			c-0.2,0.1-0.3,0.1-0.6,0c0.9,0.6,3.1,3.5,2.2,4.8c-1,1.4-3-2.6-3.5-3.1c-0.1,1.3,1.5,2.4,2.1,3.5c-0.5,0.1-0.9,0.5-1,1.1
    			c-0.5-0.3-0.9-0.8-0.9-1.4c-1.3,0.6,0,2.1-0.8,3c-1-1.3-1.7-0.2-2.4,0.7c-0.4,0.6-2-0.7-1.1-1.5c-0.6,0.2-1-0.1-0.9-0.7
    			c-0.1,0.8,0.5,1.7,0.2,2.5c-0.3,1.1-3.1-0.9-3.6-1c1.3,1.6-0.4,0.9-1.4,0.9c1.6,0.6-0.7,3.1-1.8,1.6c-0.2,0.5-0.5,1.1-1.1,1.3
    			c0.5-1.1-0.8-0.4-1.5-0.3c-0.9,0.2-2.2,0.5-2.8,1.3c0.2-0.4,0.4-0.8,0.6-1.2c-0.6,0.4-1.1,1.1-0.6,1.9c-1.6,0.5-1-0.6-1.9-1.4
    			c-0.4,0.8,0.7,1.4,0,2.1c-0.5,0.5-1.6,1.1-1.2,2c0.3,0.5,1,0.6,1.5,0.7c0.7,0.2,0.5,1.8-0.1,0.1c0.1,1.3,1.9,1.9,1.8,3.2
    			c-0.1,2.1-3,1.1-4.2,1.6c0.1-0.7-0.8-1.9,0.5-1.2c-0.3-1.2-2.5-1-1.6-2.7c-1,0.1-0.4-1.3-1.2-1.4c-0.5-0.1-0.7-2.1-0.3-2.4
    			c0.7-0.7-0.2-1.2,0.8-1.8c1.5-0.9-0.6-1.4-1.2-1.9c0.1,0.3,0.2,0.5,0.4,0.8c-1.1,0.2-1.7-1.4-2.2-2.1c-0.4,0.6-0.1,1.2,0.5,1.4
    			c-0.4,0.5-1.8,1.7-2.4,0.7c-0.9,2-1.1-1-1.5-1.4c-0.5-0.4-2.4,0.4-1.3-1.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.1,0.5,0.1,1,0.4,1.4
    			c-0.9-0.1-1.3-1.8-2.2-1.6c0.4-0.5,0.2-1-0.4-1.2c-0.2,0.5-0.4,1.2-0.6,0.2c-0.2,1.1,1.4,1.7,0.8,2.8c-0.3,0.5-1.3-0.7-1.7-0.6
    			c0.3,0.6,0,1.2-0.6,1.5c0-0.6-1.7-1-2.5-1.2c-1.8-0.5-2.8,1.3-3.2-0.2c-0.2,0.1-0.5,0.3-0.7,0.4c-0.7-1-1.7-0.9-2.5-1.6
    			c-0.4-0.3-0.2-0.6-0.4-1c-0.2-0.4-1.5-1.1-2.5-0.7c-0.6-1.9-1.7-3.4-1.6-5.3c1.7,0.6,0.9-3.7-1.7-2.5c-1,0.5-1.3-0.3-2.1-0.7
    			c-1-0.5-1.4,0.4-2.6-0.1c-2.1-0.9-3-1.8-4.2-3.5c-0.5,1.2-2,1.4-2.9,2.1c-0.6,0.5,0.5,1.5-0.2,2.2c-0.6,0.6-4.4,2.4-4,0.4
    			c-1.3,0-1.6,3.1-2.1,2.8c-0.7-0.4-1.2-1.1-1.7-1.6c-1.1-1.2-1.1,0.1-1.4,1c-1.4-1.6-1.6-2-2.7,0.1c-1,1.9-2.4-0.1-3.4-0.9
    			c-2.1-1.7-2.4,0.7-3.2,2.1c-0.5,0.9-1.1-0.1-1.7-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.5,0.1-0.4-0.7-0.8-0.7c-1.9,0-1,3.6-0.5,4.3
    			c0.5,0.8,1.2,1.5,1.5,2.5c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.5-0.4,1-0.4,1.5c0,0.6,1.2,1.7,0.6,2.2c-0.7,0.7-1.5-0.8-2.4-0.6
    			c-1.4,0.4-1.5,0.5-2.1-0.9c-0.5-1.2-1.2-2.4-1.3-3.7c-1.2,0.9-3.3,3.4-4.9,2.4c-0.8-0.5-0.9,0.7-1.6,0.5c-0.6-0.1-0.9-1.6-0.6-2.1
    			c-2.5,0-0.7-2.5-2-3.2c-1.5-0.7-3.3-0.6-4.8-0.4c-2,0.3-0.4-2.6-0.3-3.3c0.2-1-1-4,0.9-3.9c-0.5-1.5-1.5-1.7-2.9-1.7
    			c-1.9,0-1.8-1.6-2.6-2.8c-0.1-0.1-0.8-0.9-0.6-1c0.7-1-0.7-1.6-1.1-2.4c-0.7-1.2,1.1-1.7,0.8-2.9c-1.7,1.3-3.9-0.4-5.7,0.7
    			c-0.4,0.2-2.9,2.2-3.3,1.8c-0.9-0.7,0-1.4,0.3-2.1c0.3-0.7-0.2-1.8-0.6-2.4c-0.6-0.9-0.9-0.8-1.2-2.1c-0.2-1,0.2-1.7,1.3-1.7
    			c-0.8-1.3-0.1-2.3-0.1-3.6c1.7,1.5,1.8-2.4,1.9-3c2.5,0.2,0.7-0.9,2.3-2.1c0.2-0.2,1.1,0.3,1.2-0.1c0.1-0.9-1-1.5-1.3-2.3
    			c1.5,0.6,0.7-2.1,0.7-2.8c0-1.6-0.4-3.4-0.9-4.9c-0.4-1.2-0.4-2.5-1.1-3.6c-0.5-0.8-1.2-1.7-2.5-2.4c0.3,0.6,0,1-0.6,1.2
    			c-0.5-1-1.5-2.1-1.8-3.2c-0.3-1-0.3-1.8-1.1-2.5c-1.3-1.2-3.4-1.7-4.5-2.9c-0.1,0.8-0.5,2.1-0.6,2.6c0,0.2-0.3,0.4-0.4,0.5
    			c-0.2,0.2,0.1-0.3-0.7-1.1c-0.9-0.9-1.3,0.1-2.2-0.3c-0.8-0.4-1.8-1.1-2.7-1.1c-0.8,0-1.2,1.1-2.1,0.7c-0.6-0.3-0.1-1.2,0.1-1.5
    			c0.3-0.6,0.9-1.1,1.1-1.8c0.2-1-1.6-1.4-1.2-2.7c-0.6,0.2-1.3,1.1-2,0.9c-1.6-0.4,0.2-1.9,0.7-2.1c-0.7-0.6-1.5-1.4-2.2-1.9
    			c-0.7-0.5-2.8,1.1-3.4,1.5c-0.6,0.4-0.3,1.4-0.9,1.7c-0.5,0.4-1.4-0.1-1.9-0.3c-0.2-0.1-2.3-1.4-1.4-0.3c-0.7-0.4-1.7-1.2-2.4-1.4
    			c-0.8-0.2-1.4,0.5-2.2,0.9c-1.2,0.7-1.8,1.9-2.6,2.9c-1.1,1.2-2.1,1.2-3.6,1.5c-1.6,0.3-1,1.4-1.7,2.4c-0.7,0.9-2.3,1.4-2.6,2.5
    			c-0.4,1.4-0.4,2.5-2.3,2.6c-1.3,0-2.2,0.8-3.4,0.7c-0.5-0.1-1.2,0.1-1.5,0c-0.3-0.2-0.1-0.7-0.1-1.2c0-0.9-2.3-1.4-3.1-1.4
    			c-1.2,0-0.8,1.4-2.5,0.3c-2.1-0.1-3.3-0.1-4-0.5c-1.1-0.6,0-1.4-1.2-1.8c-1.4-0.4-2.7-0.2-3.9,0.6c-1.3,0.9-2.1,3-2.8,4.4
    			c-0.5,1.1-1.8,2.1-1.9,3.3c-2.7-2.1-0.7-3.7-1.3-5.3c-0.8-2.4-3.5-0.6-5.4-0.4c0,0.3,0.1,0.5,0.1,0.8c-1.6-0.5-3.9,0.1-5.6,0.1
    			c-1.6,0-2.6-0.6-4-1.4c-0.5-0.3-1.4-0.8-2-0.5c-0.5,0.2-0.2,1.1-0.8,1.2c-0.9,0.2-2.6-1.1-2.6-2c-0.2,0.2-0.2,3.1-1,1.9
    			c-0.8-1.2-2.4-2-2.8-3.4c-0.2,1.2-4.2,0.7-4.4-0.4c-0.2-0.5,0.4-1.5,0-2c-0.5-0.5-1.8,0.6-2.6,0.1c-1.3-0.8-4.1-1.7-4.6-3.2
    			c-0.6-1.8-2.5-3.8-4.4-1.9c-0.7,0.7-2.9-2.3-3.3-2.7c-1-1.2-2.9-1.4-4.4-2.3c-2.3-1.4-4.3-2.9-6.3-4.7c-0.7-0.7-1.8-0.6-2.7-0.9
    			c-1-0.3-1,0.9-2,0.5c0.4,2.6-1.1,3-2.6,1.3c-1.4-1.5-3.1-2.2-5-2.7c-0.4-0.1-1.3-0.2-1.6-0.6c-0.4-0.5,0.4-1.4-0.6-1.8
    			c-0.7-0.3-1.1-0.4-1.7-1c-1.1-1-1.8-0.3-2.9-0.6c-1.7-0.5-2.4-2.4-3.6-3.5c-1.2-1.2-2.4,2.1-3.2-0.2c-0.3-0.8,0.1-1.6-0.4-2.4
    			c-0.5-0.8-0.7-1.4-0.8-2.3c-0.2-1-1.1-1.5-1.8-2.1c-1-0.9-0.8-2.2-1.6-3.1c2-1.5,2.3-0.4,3.5,1c0.6,0.7,1.4,0.8,1.8,0
    			c0.6-0.9,1.5-0.9,2.1-1.7c0.7-1-0.3-2-0.8-2.7c-0.5-0.5-0.2-2.1-0.8-2.2c-2.3-0.7-2.2-0.5-3.9-2.2c-1.9-1.9-2.1-5.4-2.1-5.8
    			c0-0.2,2.5-1,0.4-1.7c-1.8-0.6-3.2-2-5-3c-2.4-1.4-1.6-4.7-5.5-7c-2.3,0.1-9.3-0.4-9.5-3.7c-0.7,0.2-1.7,1.3-2.4,0.6
    			c-0.6-0.5-2.1-1.4-2.4-2.1c-0.3-0.6-0.2-1.8-0.4-2.5c-0.3-1-0.7-1.8-1.5-2.5c-1.1-1-2.8-0.4-3.5-1.4c-0.7-1.1-3-2.4-3.9-0.8
    			c-0.7-1.7-2.7-0.5-3.4-1.7c-0.9-1.7,2.2-1,2.7-1c1.9-0.3,1.1-2.1,0.1-2.7c-0.9-0.6-0.5-0.8-0.5-1.7c0-0.4-0.7-1.6-0.9-2
    			c-0.3-0.6-1-0.7-1.2-1.3c-0.3-0.8,0-1.6-0.2-2.4c-0.3-1.5-2.7-1.9-3.9-2.1c-1.6-0.4-2.7,1.8-3.9,0.2c-0.4-0.5-2.1-2.8-0.4-2.8
    			c-0.5-0.7-1.2-1.3-1.8-1.9C1826.6,359.1,1826.8,358.1,1826.5,357.3"
            />
            <path
              id="path2726"
              class="countryIsland"
              d="M2164.1,358.1c0.1-0.6-0.2-0.8-0.7-0.6C2163.6,357.7,2163.9,357.9,2164.1,358.1"
            />
            <path
              id="path2730"
              class="countryIsland"
              d="M2172.9,359c-0.2-0.2-0.4-0.3-0.7-0.3C2172.4,358.9,2172.7,358.9,2172.9,359"
            />
            <path
              id="path2740"
              class="countryIsland"
              d="M2187.8,423.5c1.4,0.6,4.4,4.1,6.1,2.4C2192.8,425.1,2189,422.3,2187.8,423.5"
            />
            <path
              id="path2744"
              class="countryIsland"
              d="M2196.9,455.5c0,0.1,0.1,0.2,0.1,0.4C2198.3,455.5,2197.2,453.9,2196.9,455.5"
            />
            <path
              id="path2748"
              class="countryIsland"
              d="M2198.8,439c0.8-0.1,1.5,0.4,2.3,0c-0.5-0.8-2-1.5-2.7-0.7
    			C2198.5,438.5,2198.6,438.7,2198.8,439"
            />
            <path
              id="path2750"
              class="countryIsland"
              d="M2198.6,436.4c0.2,0.4,0.6,0.5,1,0.4C2199.5,436.2,2199.1,436,2198.6,436.4"
            />
            <path
              id="path2752"
              class="countryIsland"
              d="M2200.4,441.7c0.1-0.6-0.1-0.7-0.7-0.2C2199.9,441.6,2200.2,441.6,2200.4,441.7"
            />
            <path
              id="path2734"
              class="countryIsland"
              d="M2177.2,493.5c0.2,0.2,0.4,0.3,0.6,0.5C2178.8,493,2177.3,492.6,2177.2,493.5"
            />
            <path
              id="path2742"
              class="countryIsland"
              d="M2191.6,478.1c0.7,1.3,1-0.5,0.8-1.1c-0.3,0-0.5,0.1-0.8,0.2c0.3,0.2,0.5,0.4,0.8,0.6
    			C2192.1,477.9,2191.8,478,2191.6,478.1"
            />
            <path
              id="path2766"
              class="countryIsland"
              d="M2187,473c0.5,0.3,1,0.5,1.5,0.6C2187.9,473.9,2187.3,473.5,2187,473"
            />
            <path
              id="path2736"
              class="countryIsland"
              d="M2181.6,486.3c-0.2,0.6,0,0.9,0.6,0.9C2182.4,486.6,2182.2,486.2,2181.6,486.3"
            />
            <path
              id="China_Hainan"
              class="countryIsland"
              d="M2118.5,531.4c-0.4,0.6,0.4,2.9,0.5,3.6c0.2,1.1,0.1,2.7,1,3.5c1,1,2.7,0.8,3.8,1.5
    			c1.1,0.7,2.9,0.8,4.1,0.8c-0.5-1.4,1.4-2.2,1.9-1.3c-0.4-0.7,2-2.6,2.4-3c0.6-0.5,1.3-3.4,0.4-3.8c0.9-0.5,1.9-3,1.7-4
    			c1.9,1.4,0.8-2.2,0.5-2.9c-0.5-1.2-3.8-2.4-2.4,0.2c-0.4-0.5-2.6-1.4-1.5,0.1c-0.6-1.9-2.6-0.7-3.2,0.1c-0.6-1.2-2.7-1.1-3.5-0.4
    			c0.2,0.3,0.3,0.5,0.5,0.8c-0.9,0.3-1.4-0.8-2.2-0.6c-1,0.3-0.5,1.6,0.3,1.8C2120.9,528.5,2119.8,530.2,2118.5,531.4"
            />
            <path
              id="path2712"
              class="countryIsland"
              d="M2128.1,517.2c0.8-0.2,1.6-0.5,2.2,0.4C2130.8,516,2128.5,516,2128.1,517.2"
            />
            <path
              id="path2714"
              class="countryIsland"
              d="M2130,515.1c-0.6,0.4-0.5,0.6,0.4,0.7C2130.2,515.6,2130.1,515.4,2130,515.1"
            />
            <path
              id="path2716"
              class="countryIsland"
              d="M2139.1,512c0.4-0.2,0.6-0.5,0.6-0.9C2139.2,511.2,2139.2,511.5,2139.1,512"
            />
            <path
              id="path2718"
              class="countryIsland"
              d="M2145.1,510.8c-0.4,0.1-0.7,0.4-0.8,0.9C2144.9,511.6,2145.2,511.3,2145.1,510.8"
            />
            <path
              id="path2720"
              class="countryIsland"
              d="M2146.4,511.9c0.1-0.6-0.1-1.1-0.4-1.5C2146.1,510.9,2146.2,511.4,2146.4,511.9"
            />
            <path
              id="path2722"
              class="countryIsland"
              d="M2149.2,500.4c0.1,0.2,1.1,1.6,1.6,1.2C2150.4,501.1,2150,500.5,2149.2,500.4"
            />
            <path
              id="path2724"
              class="countryIsland"
              d="M2150.3,501.8c0.1,0.4,0.1,0.6,0.6,0.7C2150.7,502.3,2150.5,502.1,2150.3,501.8"
            />
          </g>
          <g id="mo" class="countryGroup">
            <path
              id="mo-"
              class="country"
              d="M2151.1,506.5c0.1-0.1,0.2,0.4,0.1-0.1c0.1,0.3,0.1,0.6,0.1,0.9
    			C2151.2,507,2151.2,506.7,2151.1,506.5"
            />
            <circle id="mo_" class="disputed" cx="2148.7" cy="508.5" r="4" />
          </g>
        </g>
        <g id="tw" class="countryGroup">
          <path
            id="path2738"
            class="countryIsland"
            d="M2183.7,486.3c-0.3,0.2-0.5,0.4-0.9,0.5c0.4,0.3,0.9,0.3,1.4,0.2
    			C2184.1,486.8,2183.9,486.6,2183.7,486.3"
          />
          <path
            id="path2746"
            class="countryIsland"
            d="M2197.9,494.9c0.2,0.7,0.6,1.3,0.3,2.1c-0.2,0.6-0.4,1.2,0.1,1.7c1,1,1.3,2.6,2,3.8
    			c0.7,1.5,2.3,1.9,3.3,3.2c0.3,0.4,0.9,3,1.7,2.7c1-0.5,0.4-2.2,0.3-3c-0.2-1.3,0.2-2.3,0.9-3.4c1.8-2.6,2.1-7.3,1.9-10.3
    			c-0.1-0.6-0.5-1.6-0.2-2.2c0.2-0.6,0.5-1,0.6-1.7c0.2-1.6-0.4-2.7-0.4-4.3c0-0.8,0.8-1.4,0.1-2.1c-0.4-0.4-2.7-2.2-3.3-1.6
    			c-0.9,0.9-2.7,1.1-3.2,2.4c-0.5,1.3-0.4,2.1-1.3,3.2c-0.9,1-1.1,2.5-1.4,3.7C2198.8,491.1,2197.5,492.9,2197.9,494.9"
          />
          <g id="tw-" class="st4">
            <path
              id="path2738-"
              class="countryIsland"
              d="M2183.7,486.3c-0.3,0.2-0.5,0.4-0.9,0.5c0.4,0.3,0.9,0.3,1.4,0.2
    				C2184.1,486.8,2183.9,486.6,2183.7,486.3"
            />
            <path
              id="path2746-"
              class="countryIsland"
              d="M2197.9,494.9c0.2,0.7,0.6,1.3,0.3,2.1c-0.2,0.6-0.4,1.2,0.1,1.7c1,1,1.3,2.6,2,3.8
    				c0.7,1.5,2.3,1.9,3.3,3.2c0.3,0.4,0.9,3,1.7,2.7c1-0.5,0.4-2.2,0.3-3c-0.2-1.3,0.2-2.3,0.9-3.4c1.8-2.6,2.1-7.3,1.9-10.3
    				c-0.1-0.6-0.5-1.6-0.2-2.2c0.2-0.6,0.5-1,0.6-1.7c0.2-1.6-0.4-2.7-0.4-4.3c0-0.8,0.8-1.4,0.1-2.1c-0.4-0.4-2.7-2.2-3.3-1.6
    				c-0.9,0.9-2.7,1.1-3.2,2.4c-0.5,1.3-0.4,2.1-1.3,3.2c-0.9,1-1.1,2.5-1.4,3.7C2198.8,491.1,2197.5,492.9,2197.9,494.9"
            />
          </g>
        </g>
        <g id="hk" class="countryGroup">
          <g id="hk-">
            <path
              id="path2764"
              class="country"
              d="M2154.3,503.8c0.8-0.3,1.5-0.6,2.1,0.1c-0.2,0.2-0.5,0.4-0.7,0.6c0.3-0.3,0.9-0.4,1.2-0.2
    				c1.1,0.9-1.2,0.1-0.3,1.4C2155.8,505.6,2152.6,504.9,2154.3,503.8"
            />
            <path
              id="path2762"
              class="countryIsland"
              d="M2153.3,506.5c0-0.8,0.9-0.9,1.4-1.2C2154.8,506.1,2154,506.5,2153.3,506.5"
            />
            <path
              id="path2760"
              class="countryIsland"
              d="M2155.8,505.8c0,0.4,0.2,0.6,0.6,0.7C2156.3,506.2,2156.1,505.9,2155.8,505.8"
            />
          </g>
          <circle id="hk_" class="disputed" cx="2156.9" cy="505.9" r="4" />
        </g>
        <g id="gf" class="countryGroup">
          <g id="gfx">
            <path
              class="country"
              d="M883.3,678.4c1.3-0.1,2.3-2.3,2.8-3.2c0.8-1.8,0-3.8,1.2-5.5c1.5-2,0.1-3.4-1.1-4.9
    		c-1.3-1.6-1.2-4.5-1.4-6.5c-0.1-2.3,0.2-3.6,1.9-5.2c0.6-0.6,1.4-1.3,1.6-2.1c0.3-1.1,0.6-2.6,2.2-2c1,0.4,1.8,1.2,2.9,1.5
    		c1.2,0.3,2.7,0.3,3.7,1.1c1.2,1,2.8,4.6,4.7,4.5c-0.3,0.5-0.5,1-0.6,1.5c0.5-2.4,4.6,1.7,2.3,3.3c1.1-0.4,0.5-1.5,1-2.2
    		c0.9-1.1,1.5,1.5,1.6,2.1c0.3,2.7-1.8,5.6-3.2,7.7c-1.6,2.5-2.7,4.6-3.8,7.3c-0.4,1-0.9,1.9-1.8,2.6c-0.4,0.3-1,1-1.5,1.1
    		c-0.8,0.2-1.4-0.4-2.2,0c0.3-1-0.3-1-1-0.8c-1,0.3-1.9-0.1-2.9-0.4c-0.5-0.2-2.4,1.2-2.7,1.6C886.4,680.7,883.7,678.8,883.3,678.4"
            />
          </g>
        </g>
        <g id="fr" class="countryGroup">
          <g id="frx">
            <path
              id="France_mainland"
              class="country"
              d="M1278,284.1c0.9-0.4,2-0.7,2.9-0.5c0.3-1.5-1.7-0.5-2.1-1.5c0.9-0.3,2-0.2,3-0.1
    			c-0.8-0.1-1.8,0.1-1.2-0.8c-0.6,0-2.6,1.1-2.8,0.1c-0.3-1.3,1.7-2.1,2.7-2.3c1.3-0.3,2.5-0.4,3.8-0.3c1.8,0,3-1.8,5.1-1.5
    			c-0.2,0.7,2.2,3,2.9,2.7c0.5-0.2,0.9-0.5,1.3-0.8c0.9-0.5,0.9,0.2,1.7,0.3c0.8,0.1,1.4-0.5,1.4,0.8c0.5-0.3-0.2-0.8,0.2-1.3
    			c0.4-0.6,0.7,0.3,1,0.4c0.9,0.2,2,0,2.9-0.1c-1.9-0.6-1.2-3.2-1-4.6c0.1-0.7-0.9-1.4-1.3-1.8c-0.6-0.8,0-2-0.9-2.7
    			c0.7,0.1,1.2,0.4,1.9,0.6c0.7,0.1,1.6-0.5,2.2-0.2c1,0.5-0.2,2,1.1,2.7c0.4,0.2,1.1-0.2,1.5-0.2c1.2,0,2.3,0.4,3.4,0.6
    			c1.1,0.2,2.1,0.2,3.1-0.4c0.9-0.5,2.1-0.5,2.9-1.1c-0.7,0.3-1.8,0.4-2.4,0c-1.3-0.7,1.7-2.5,2.2-2.7c1.6-0.7,3.3-0.6,4.9-1.2
    			c0.9-0.4,2.1-2.5,3.3-1.9c-1-0.6-0.4-5.1,0.4-5.9c0.8-0.8,4.9-2.3,6-1.4c0.5,0.5-0.5,2.2,1.1,2.6c1.6,0.4,1.2,0,2.4,0.4
    			c1.1,0.3,0.7,1.2,1.6,1.6c1.1,0.5,2.2-0.1,2.1,1.6c0.7-0.4,1.8-0.3,2.5-0.1c1.3,0.4,0.8,2,0.5,3c0.9,0.1,1.8,0.4,2.7,0.1
    			c0.8-0.3,1.1-1.3,1.9-1.7c0.1,0.5-0.2,2.9,0.6,3c1.1,0,3.2,1.5,3.6,2.5c-0.1-0.3,4.1,0.4,4.3,0.4c1,0.1,1.8-0.1,2.6,0.7
    			c0.6,0.7,1.1,1.7,2,1.9c0.2-0.1,0.4-0.2,0.5-0.4c0.3-0.3,0.5,0.1,0.8,0.1c0.2,0.1,0.2,0.2,0.2,0.4c-0.1,0.4,0.9,0.2,1,0.2
    			c1,0.1,1.6-0.5,2.6,0.2c1,0.8,3.1,0.9,4.3,1.1c-1.5,1.4-3,2.9-3.5,4.9c-0.2,1-0.8,1.9-1,2.9c-0.1,0.6,0.1,0.9-0.1,1.7
    			c-0.3,1,0.5,1.9-0.2,2.5c-0.6,0.5-1,1-1.9,1c-0.7-0.1-0.8-1.1-1.7-0.6c-0.6,0.3-0.7,1.5,0.4,1.3c-0.8,0.8-1.5,1.8-2.4,2.5
    			c-0.6,0.4-1.1,0.7-1.6,1.1c-0.4,0.4-0.4,1.2-0.8,1.6c-0.6,0.8-1.8,1.1-2,2.2c0.1,0.4,0.2,0.8,0.3,1.2c-0.2,0.7-0.8,1-1.1,1.6
    			c1.2-0.1,1.6-0.6,1.9-1.7c0.3-1.1,2.4-1,3.2-0.8c1,0.3,0.3,1.9,1,2.7c1.3,1.4,0.5,1.9-0.7,2.9c0.6,0.9,2.9,2.4,2.3,3.7
    			c-0.4,0.8-3.6,2-3.6,2.1c0,1,2,1.8,2.6,2.4c1,0.8-1.3,2.3-1,3.4c0.4,1.3,2.9,2.8,4.2,2.3c1.7-0.7,1.4,0.3,0.8,1.4
    			c-1.5,2.7-4.4,4-7,5.8c0.2,0.2,0.3,0.5,0.5,0.7c-1,0.1-1.8,0.6-2.7,1c-1,0.3-1.6-0.2-2.5-0.1c-1.4,0.1-2.3-1.1-3.9-0.9
    			c0.7-1.7-2.2-0.4-2.2-1.4c0.2-0.3,0.5-0.4,0.9-0.2c0-0.6-0.4-0.9-0.9-0.9c0.5,0.7,0.1,0.8-0.6,0.9c-1.1,0-1.2,0-1.5-1.3
    			c0.4,2.6-0.3,0.9-1.9,0.9c-1,0-1.8-0.6-2.7-0.7c-1.2-0.1-2.4,1.2-3.4,1.7c-0.8,0.4-3.6,1.4-3.4,2.5c0,0.4-0.1,0.7-0.3,1
    			c-0.4,0.4-0.4,0.8,0.1,1.1c0.4,1.3,0.1,1.9,0.9,3.1c-0.7,0.2-1.3-0.1-2-0.1c-0.7,0-1.1,0.6-1.7,0.8c-1.5,0.4-2.4-1.2-3.9-0.1
    			c-0.1,0.1-2.1-0.9-2.3-1c-0.6-0.4,0.7-0.4-0.2-1c-0.5-0.4-1.5-0.5-1.9,0.1c-0.2-1.3-1.6-0.8-2.4-1.4c-1.1-0.8-3.3-1.4-2.9,0.6
    			c-1.2-0.2-2.6-0.5-3.8-0.3c-0.7,0.1-1.1,0.3-1.7-0.2c-1-0.9-0.9-0.5-2-0.6c-1,0-6-3.3-6.5-1.9c-0.9-0.4-0.3-1.1-0.2-1.7
    			c0.1-0.8-2.2-0.4-2.7-1c2.9-1.1,3.1-5,3.6-7.6c0.2-1,0.4-2.6,1.2-3.3c1.2-1.1-0.4-1.2-0.5,0c-0.2-2.4,0.3-5.4,1.4-7.6
    			c1,0.6,2,1.2,2.4,2.4c0.1,0.5,0.2,1,0.3,1.5c0.2,0.6,0.9,0.7,0.9,1.5c0.3-1-0.7-1.8-0.8-2.7c-0.1-1.5-0.7-2.5-2-3.2
    			c-0.5-0.3-1.2-0.7-1.6-1.1c-0.7-0.7,1.2-0.4,1.6,0c-0.9-0.4-1-4.3-0.4-5c-1.2,0.8-4-0.8-4.6-1.9c-0.5-1-1.6-1.5-1.8-2.6
    			c-0.2-1,1.7-2.2-0.7-2.3c0.3-0.3,0.3-0.9,0.7-1.2c0.9-0.6,1.8,0.4,2.6,0.5c-1.5-1-2.1-0.7-3.7-0.5c-0.8,0.1-2.4-1.1-0.9-1.3
    			c-0.4-0.4-0.2-0.7,0.5-0.8c-1.1-0.3-2.8,0.6-3.7-0.4c0.5,0,1-0.1,1.4-0.4c-0.9-0.6-2.6-0.4-2.8,0.9c0-0.8-0.9-1.5-0.1-2.2
    			c-0.3,0.9-1.9,0.8-0.9-0.3c-2,1.7-4.7-2.2-6.7-0.1C1280,285.1,1279.3,284.4,1278,284.1"
            />
            <path
              id="path2166"
              class="countryIsland"
              d="M1288.5,290.4c-0.3-0.3-0.7-0.6-1.1-0.9C1287.6,290.1,1287.9,290.5,1288.5,290.4"
            />
            <path
              id="path2168"
              class="countryIsland"
              d="M1294.7,293.7c-0.2-0.5-0.2-0.6-0.8-0.5C1294.2,293.3,1294.5,293.5,1294.7,293.7"
            />
            <path
              id="path2172"
              class="countryIsland"
              d="M1300.2,300c-0.3-0.6-1-0.4-1.5-0.4C1299.2,299.7,1299.7,299.8,1300.2,300"
            />
            <path
              id="path2174"
              class="countryIsland"
              d="M1300.5,303c0.5-0.7-0.1-1.9-1-1.4C1299.9,301.9,1300.3,302.4,1300.5,303"
            />
            <path
              id="France_Corsica"
              class="countryIsland"
              d="M1367.2,332.1c0.4-0.4,0.5-1.3,1-1.5c1.1-0.5,2.3-0.7,3.4-1.4c0.9-0.6,0.3-2.4,1.5-2.3
    			c0.2,2.2,1.2,5.4,0.7,7.5c-0.6,2.2-1.7,4.4-2.6,6.5c-0.4-0.6-3.9-1.9-2-2.7c-0.5-0.1-1-0.3-1.4-0.6c0.5-0.3,0.8-0.8,0.8-1.4
    			c-1,0.2-1.2-0.9-0.6-1.4c-0.9-0.2-1.4-1.3-0.2-1.6C1367.5,332.9,1367.3,332.5,1367.2,332.1"
            />
          </g>
          <g id="gp" class="countryGroup">
            <g id="gp-">
              <path
                id="path2582"
                class="countryIsland"
                d="M836.5,557.8c-0.3,0.6-0.9,4.3-1.8,1.8C834.2,558.2,835,556.8,836.5,557.8"
              />
              <path
                id="path2584"
                class="countryIsland"
                d="M836.5,557.7c0.6-0.6,0-1.7,1-1.9c0.1,0.9,0.9,1.6,1.6,2C838.5,558.1,836.9,558.8,836.5,557.7
    				"
              />
              <path
                id="path2586"
                class="countryIsland"
                d="M838.4,561.2c-0.6-0.6-0.5-0.9,0.4-0.9C838.9,560.7,838.8,561,838.4,561.2"
              />
            </g>
            <circle id="gp_" class="circle" cx="839.4" cy="554.2" r="4" />
          </g>
          <g id="mq" class="countryGroup">
            <path
              id="mq-"
              class="countryIsland"
              d="M840.3,574c-0.6-0.9-1.3-0.1-1.6-1c-0.3-0.8-0.6-1.7-0.7-2.6c0-1.1,1.2-0.1,1.5,0.2
    			C840.2,571.4,841.1,573,840.3,574"
            />
            <circle id="mq_" class="circle" cx="843.6" cy="574.4" r="4" />
          </g>
          <g id="re" class="countryGroup">
            <path
              id="re-"
              class="countryIsland"
              d="M1719.5,880.3c-0.1-2-4-0.8-3.6,1.1C1716.6,884.2,1721.9,884.4,1719.5,880.3"
            />
            <circle id="re_" class="circle" cx="1718.2" cy="880.8" r="4" />
          </g>
          <g id="yt" class="countryGroup">
            <path
              id="yt-"
              class="countryIsland"
              d="M1643.4,811c0.5-0.6,1.1-2,0-2.5C1643.2,809.1,1643,810.5,1643.4,811"
            />
            <circle id="yt_" class="circle" cx="1644.6" cy="811.2" r="4" />
          </g>
        </g>
        <g id="nl" class="countryGroup">
          <g id="nlx">
            <path
              id="path6448"
              class="countryIsland"
              d="M1336.5,253.1c-0.2,0-0.5,0.1-0.7,0.1c0.8,0.3,1.7,1.4,2.5,0.6
    			C1337.8,253.3,1337.2,253,1336.5,253.1"
            />
            <path
              id="path6450"
              class="countryIsland"
              d="M1336.8,252.8c1.2-0.5,1.9,1.6,3.1,0.7C1339,253.1,1337.8,251.7,1336.8,252.8"
            />
            <path
              id="path6452"
              class="countryIsland"
              d="M1342.9,242.8c0.6-0.2,1.1-0.8,0.6-1.4C1342.9,241.6,1342.6,242.2,1342.9,242.8"
            />
            <path
              id="path6454"
              class="countryIsland"
              d="M1344.6,240.5c-0.2,0.1-0.5,0.3-0.7,0.5C1344.1,240.8,1344.3,240.6,1344.6,240.5"
            />
            <path
              id="path6456"
              class="countryIsland"
              d="M1345.9,239.8c0.8-0.2,1.5-0.4,2.2-0.8C1347.3,239.2,1346.5,239.4,1345.9,239.8"
            />
            <path
              id="path6458"
              class="countryIsland"
              d="M1349,239.2c0.5,0,1-0.1,1.5-0.4C1350,238.8,1349.5,238.9,1349,239.2"
            />
            <path
              id="path6482"
              class="country"
              d="M1333.3,256.7c0.1-1.3,4.8-0.3,5.8-0.3c-0.8,0.8-2.1,1.4-3.3,0.9
    			C1335.1,256.9,1333.6,257.3,1333.3,256.7"
            />
            <path
              id="Netherlands_mainland"
              class="country"
              d="M1342.7,243.8c-1.1,2.9-2.3,5.4-4.6,7.6c-0.9,0.9,1.3,1.6,1.8,1.7
    			c1.2,0.4,2.7-0.6,3.1,0.3c-1.6-0.3-3.2,1.2-4.7,0.5c1.3,0.9-0.2,0.2-0.8,0.6c0.6,0.4,1.6,0.2,1.7,1.1c-1.1,0.3-1.8-0.7-2.5-1.3
    			c-0.9,0-2.1-0.3-2.7,0.7c0.7,1,2.1,0.8,3.1,0.7c0.8-0.1,3.6,1.4,3.4-0.3c0.5,0.4,1.3,0.6,1.7-0.1c0.3,0.9,1.7,0.6,2.1,0
    			c0.6,2,3.5,2,5.2,2.7c-1.2,1.4-2.3,3.4,0.7,3.4c1.3,0-0.2-2,0-2.4c0.7-1.2,2.1-2.4,1.6-3.9c-0.2-0.8-0.8-1.7-1.6-2
    			c-0.1-1.8,1.8,0.1,1.3-1.3c0.6,0.3,4,0.6,4.2-0.3c0.1-0.4-0.6-1,0-1.3c0.3-0.1,0.5-0.2,0.7-0.4c2.6-2.6-1.9-2.5-1.2-3.7
    			c0.5-0.9,2.3,0.1,2.4-0.7c0.1-1.1,0.8-2,1-3c0.4-1.6-2.3-3.6-3.9-3.4c-2.5,0.3-6.6,0.8-7.3,4.4c-0.4,1.7,1.5,2.7,1.8,2.9
    			c0.8,0.4,0.5,0.9,0,1.3c-0.5,0.4-0.9,0.8-1.4,1.2c-0.7,0.6-1.8-0.5-2.6-0.5c0.2-0.5,3.2-1.2,2-3.2
    			C1345.3,243.1,1343,242.9,1342.7,243.8L1342.7,243.8z"
            />
          </g>
          <g id="bq" class="countryGroup">
            <path
              id="bq-"
              class="countryIsland"
              d="M782.7,594.6c-0.2-0.4,0.1-1.5-0.4-1.7c-0.4-0.2-0.6-0.1-0.5-0.8
    			C782.7,592.8,783.9,593.4,782.7,594.6"
            />
            <circle id="bq_" class="circle" cx="784.9" cy="596.4" r="4" />
          </g>
        </g>
        <g id="lb" class="countryGroup">
          <path
            id="lb-"
            class="country"
            d="M1559.1,412.3c1.6-1.9,1.5-5.2,2.9-7.3c0.7-1-0.1-2.4,0.7-3.4c0.6-0.8,2.2-1.6,1.7-2.8
    		c0.6,0.1,1.6,0.4,2.1,0c1.4-0.8,0.4,0.7,0.6,1.2c0-0.1,0.9,0.3,1,0.4c0.7,0.7,0.3,0.5,0.8,1.5c1.1,2-2.4,2.3-1,4.1
    		c-1.4-0.4-3.9,0.6-2.2,2.2c-0.6,0.1-2.8,2.2-2.9,2.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2,0.5-0.2,1-0.5,1.5
    		C1561.4,413,1559.9,411.6,1559.1,412.3"
          />
          <circle id="lb_" class="circle" cx="1563.8" cy="405" r="6" />
        </g>
        <g id="me" class="countryGroup">
          <path
            id="me-"
            class="country"
            d="M1435.7,330.6c1.9-0.3-1.9-4.5,1.1-3.9c-0.8-1.5,1.8-3.9,2.4-2c0.4-1-0.7-1.3-0.6-2.2
    		c1.2,0.4,1.5,1.1,2.5,2c1.4,1.2,1.8,1.7,3.4,2.6c0.6,0.3,2.5,0.1,2.9,0.8c0.6,1.1-1,1-1.1,1.1c-0.5,0.4,1.3,1.5,0.4,2
    		c-0.6,0.3-1.4,0.7-1.9,0c-0.3-0.4-0.5-1.2-1.1-0.5c-1.6,1.8-2.2,3.9-1.4,6.2c-1.2-0.7-2.4-1.9-3.2-2.9c-0.2-0.3-2.2-2-2.7-2
    		c0.7-0.3,0.6-0.3,1.4,0.1C1438,330.6,1435.3,332.6,1435.7,330.6"
          />
          <circle id="me_" class="circle" cx="1440.7" cy="329.2" r="6" />
        </g>
        <g id="sz" class="countryGroup">
          <path
            id="sz-"
            class="country"
            d="M1531,929.7h0.2c1,0.9,0.8,0.8,1.6,1.7c0.7,0.8,0.7,1.2,1.4,1.9c0.9,0.9,2.8,2.1,4.3,0.9
    		c0.7-0.5,0.3-0.6,0.8-1.4l0.9-1.8h1.3v-0.5c-1.6-6.2,0.4-2-1.1-6c-0.8-0.9-1.8-1.8-2.6-2.1l-2.4-0.1c-0.8,1-1.4,1.1-2.2,2.2
    		c-0.4,0.6-0.6,1.1-0.8,1.5C1532.3,926.5,1531.6,929.2,1531,929.7L1531,929.7z"
          />
          <circle id="sz_" class="circle" cx="1536.8" cy="928.4" r="6" />
        </g>
        <g id="nc" class="countryGroup">
          <g id="nc-">
            <path
              id="path2568"
              class="countryIsland"
              d="M2534.2,877.6c0.8,1,1.2,2.5,2.3,3.2c0.5,0.3,0.3,1.1,0.5,1.5c0.3,0.7,1.4,0.9,1.5,1.6
    			c0.3,1.3,1.9,1.6,2.6,2.4c1.1,1.1,2.3,1.6,3.7,2.3c-0.9,0.9,0.8,2.6,1.7,1.7c-0.3,0.4-0.2,0.6-0.2,1.2c0.9-1.7,1.6,0.7,2.5,0.4
    			c1.2-0.3,2.5-0.3,1.8-2.1c-0.3-0.8-1-0.8-1.6-1.3c-0.7-0.6-1.2-1.5-1.7-2.3c-1.1-1.4-2.9-1.9-4.1-3.2c-0.9-1-1.4-2-1.9-3.2
    			c-0.6-1.6-0.9-1.4-2.3-2.4c-1.2-0.9-1.6-2.6-2.9-3.4c-0.6-0.3-1.1,0-1.7-0.2c-0.8-0.3-1-1.2-1.7-1.6c0.4,0.6,0.6,1.4,0.8,2.2
    			c0.1,0.5-0.2,0.9,0,1.4C2533.7,876.5,2534.1,877,2534.2,877.6"
            />
            <path
              id="path2570"
              class="countryIsland"
              d="M2550.3,877.8c1.1-0.4,0.8-1.4,1.3-2.2C2551.1,876.3,2550.9,877.2,2550.3,877.8"
            />
            <path
              id="path2572"
              class="countryIsland"
              d="M2553.5,894.7c0.2-0.2,0.4-0.5,0.5-0.7C2553.2,893.4,2552.6,894.4,2553.5,894.7"
            />
            <path
              id="path2574"
              class="countryIsland"
              d="M2555.8,881.8c0.9-0.7,0.5-4.7-1.2-4.1c1.4,1-0.3,1-0.6,1.8
    			C2553.7,880.8,2555.2,881.1,2555.8,881.8"
            />
            <path
              id="path2576"
              class="countryIsland"
              d="M2559.5,885.9c0.9,0,1.3-0.9,1.5-1.6c-0.9,0.2-0.8-0.8-1.4-0.9
    			C2558.2,883.4,2558.9,885.5,2559.5,885.9"
            />
            <path
              id="path3370"
              class="countryIsland"
              d="M2503.9,865.9c0.3-0.5,0.5-1,0.6-1.5C2504.1,864.8,2503.7,865.4,2503.9,865.9"
            />
          </g>
          <circle id="nc_" class="circle" cx="2542.7" cy="881.9" r="6" />
        </g>
        <g id="fj" class="countryGroup">
          <g id="fj-">
            <path
              id="path5538"
              class="countryIsland"
              d="M2638.1,847.1c0.2-0.2,0.5-0.4,0.7-0.6C2638.2,846.5,2638.3,846.7,2638.1,847.1"
            />
            <path
              id="path5544"
              class="countryIsland"
              d="M2640.5,844.3c0.5-0.3,1-0.7,1.4-1.2C2641.4,843.4,2640.9,843.8,2640.5,844.3"
            />
            <path
              id="path5536"
              class="countryIsland"
              d="M2638.5,855.6c1.5,0.6,2.9,1.3,4.5,0.7c1.1-0.4,3.5-0.8,4.4-1.5c0.6-0.5,0.3-0.6-0.1-1
    			c-0.2-0.2,0-1.3,0.1-1.7c0.1-0.9-0.7-3.1-1.8-2.5c1.4-1.8-4.2-0.3-4.8,0C2639.2,850.3,2634.8,855,2638.5,855.6"
            />
            <path
              id="path5542"
              class="countryIsland"
              d="M2640.7,864.3c0.5-0.3,0.9-0.7,1.4-1.1c0.5-0.4,1.2,0.2,1.7-0.1c-0.4-1.7-3.5,0.1-3.8,1.1
    			C2640.2,864.3,2640.5,864.3,2640.7,864.3"
            />
            <path
              id="path5546"
              class="countryIsland"
              d="M2648.8,852.2c0.3-0.2,0.6-0.4,0.8-0.6C2648.8,851,2648.6,851.2,2648.8,852.2"
            />
            <path
              id="path5548"
              class="countryIsland"
              d="M2661.1,838.5c-2.5-0.1-4.4,1.8-6.8,2.1c-1.3,0.2-2.2,1-3.2,1.7c-0.8,0.6-2.5,0.3-2.4,1.7
    			c1-0.2,1,0.9,0.9,1.6c1.3-0.2,4.6-3.4,5.1-1.8c0.3,0.8,2.3-0.2,2.8-0.4c1.7-0.5,2-0.7,2.5-2.4c-0.9,0.4-2.6,2.8-3.7,2
    			C2658.1,841.7,2659.3,839.7,2661.1,838.5"
            />
            <path
              id="path5550"
              class="countryIsland"
              d="M2652.3,855.3c0.5-0.6,0.4-1.3-0.4-1.5C2652,854.4,2652.1,854.9,2652.3,855.3"
            />
            <path
              id="path5552"
              class="countryIsland"
              d="M2655.5,859.9c0.2-0.2,0.4-0.5,0.6-0.7C2655.5,859,2655.2,859.3,2655.5,859.9"
            />
          </g>
          <circle id="fj_" class="circle" cx="2640.1" cy="840" r="6" />
        </g>
        <g id="kw" class="countryGroup">
          <g id="kw-">
            <path
              id="path5440"
              class="countryIsland"
              d="M1657.1,439.2c0.1,0.3-1.7,3.4,0.6,3.1C1659.7,442.1,1657.7,439.8,1657.1,439.2"
            />
            <path
              id="path5486"
              class="country"
              d="M1645.8,446.7c3.2-1.2,1.7-7.7,5.1-8.3c1.3-0.2,3.1-0.4,4.8,1c0.4,0.4,1.6,2.7,1.1,3.3
    			c-0.9-1.3-2.3,0.9-2.8,1.6c1.5-0.2,3.1,0.4,3.4,2c0.4,1.9,1.9,3.6,2.6,5.6c-1.2-0.7-4.3,0.5-5.5-0.4c-0.9-0.7-1.1-2.1-1.4-3.1
    			C1652.5,447.1,1647.8,447.5,1645.8,446.7"
            />
          </g>
          <circle id="kw_" class="circle" cx="1653.3" cy="444.1" r="6" />
        </g>
        <g id="tl" class="countryGroup">
          <g id="tl-">
            <path
              id="path2786"
              class="country"
              d="M2253,778.2c0,2.7,0.7,1.6,2.3,0.6c1.6-1,4-1.5,5.8-2.2c2.8-1.1,6.9-2.2,8.9-4.7
    			c1.1-1.5-1.9-1.2-2.6-1c-1.8,0.5-3.4,0.8-5.2,1.1c-2,0.3-3.8,0.3-5.8,0.7c-0.8,0.2-1.8,0.1-2.4,0.6c-0.7,0.5-1.1,1.8-1.8,2.4
    			C2254,776.4,2253.8,776.9,2253,778.2L2253,778.2z"
            />
            <path
              id="Timor-Leste_Oecusse"
              class="country"
              d="M2244.1,780c1.3-1,2.3-1.4,4-1.7c-0.3,0.2-0.1,3.3-0.8,3.1
    			c-0.6-0.1-0.7-0.8-0.9-0.6C2245.7,781.6,2244.6,779.8,2244.1,780"
            />
          </g>
          <circle id="tl_" class="circle" cx="2260.9" cy="775.7" r="6" />
        </g>
        <g id="bs" class="countryGroup">
          <g id="bs-">
            <path
              id="path6100"
              class="countryIsland"
              d="M738.8,496.1c-0.2-0.1-0.5-0.2-0.7-0.3C738.4,495.8,738.6,495.9,738.8,496.1"
            />
            <path
              id="path6086"
              class="countryIsland"
              d="M721.3,480.5c0.9,0.8,1.6-0.1,1.6,1.5c0,1.2,1.3,2.2,1.1,3.4c-0.3,1.5-3.5,3.9-3.1,1.3
    			c-0.9,0.3-1.7-0.4-2.1-1.1c0.5-0.6,1.1-0.5,1.3,0.3c0.7-0.8-0.4-1.3,0.3-2.1C721.3,482.9,721.2,481.8,721.3,480.5"
            />
            <path
              id="path6088"
              class="countryIsland"
              d="M721.8,489.7c-0.2-0.5,0-0.7,0.7-0.5C722.2,489.3,722,489.5,721.8,489.7"
            />
            <path
              id="path6084"
              class="countryIsland"
              d="M719.6,469.4c-0.6-0.2-1-0.7-1.3-1.2c1.1,1.2,1.7-0.1,2.5-0.7c1.3-0.8,3.4,0.6,5.1-0.5
    			C726.2,468.2,720.5,469,719.6,469.4"
            />
            <path
              id="path6090"
              class="countryIsland"
              d="M723.3,493.3c-0.3-0.1-0.5-0.1-0.8-0.2c0.6,0,1.1-0.3,1.5-0.7c-1.2,0.8-1.5-0.5-1.7-1.4
    			c-0.1-0.3-0.2-0.8-0.5-1c0.7-0.5,1.3-1.3,2.2-1.7c0,0.8-1,1.3-0.7,2.2c0.5-0.4,0.8-0.9,0.9-1.4
    			C725.4,490.2,724.7,492.6,723.3,493.3"
            />
            <path
              id="path6092"
              class="countryIsland"
              d="M726.4,482.4c0-1,1-0.8,1.7-0.6C727.6,482.1,727,482.3,726.4,482.4"
            />
            <path
              id="path6094"
              class="countryIsland"
              d="M729.7,474.7c-0.2-0.6-0.6-1-1.2-1.2c0.8-0.7,1.4-1.6,1.8-2.5c0.2-0.5,0.4-1,0.5-1.5
    			c0.3-1.3-0.1-0.5-0.8-1.3c-1.1-1.3-1.8-3.1-4-2.3c2.2-1.3,3.9,1,5.1,2.5c0.9,1.1,0.7,1.9-0.2,2.9
    			C730.1,472.3,730.3,473.6,729.7,474.7"
            />
            <path
              id="path6096"
              class="countryIsland"
              d="M733.6,478.7c-0.6,0-1-0.4-1-1c1.2,0.9,2.8,1.6,3.5,2.9c0.9,1.6-0.7,2.9-0.4,4.5
    			c-1.1-0.9,0.5-2.6,0.6-3.5C736.5,480.2,734.4,479.5,733.6,478.7"
            />
            <path
              id="path6098"
              class="countryIsland"
              d="M735.5,493.7c1,0.1,1.2,1.2,1.9,1.8C736.7,495.1,735.6,494.6,735.5,493.7"
            />
            <path
              id="path6102"
              class="countryIsland"
              d="M740.3,489.9c-0.3-0.9,0.9-0.8,0.2-1.6c-0.6-0.7-1.1-2.3-1.1-3.2
    			C739.8,486,742.4,489.6,740.3,489.9"
            />
            <path
              id="path6104"
              class="countryIsland"
              d="M740.8,493.8c1,1.4,0.6,2.8,1.2,4.2c0.3,0.9,2,1.3,1.1,2.4c-0.2-1.3-1.6-1.4-2.1-2.5
    			C742.6,499.2,740.7,494.6,740.8,493.8"
            />
            <path
              id="path6106"
              class="countryIsland"
              d="M744.4,494c-0.2-0.1-0.5-0.1-0.7-0.2C744.4,493.2,744.6,493.2,744.4,494"
            />
            <path
              id="path6108"
              class="countryIsland"
              d="M747,506.4c1.1-0.8,1.9-2,3.2-2.7c-1.5-0.9-0.2-1.9,0.2-1.2C751.4,504.4,748,505.8,747,506.4"
            />
            <path
              id="path6110"
              class="countryIsland"
              d="M747.1,490.2c0.3,0,0.5-0.1,0.8-0.1c-0.1,0.6-0.5,1.1-1.1,1.3C747,491,747.1,490.6,747.1,490.2
    			"
            />
            <path
              id="path6112"
              class="countryIsland"
              d="M748.7,502.5c-0.8-0.1-1.6-0.7-1.4-1.6C747.8,501.3,748.8,501.7,748.7,502.5"
            />
            <path
              id="path6122"
              class="countryIsland"
              d="M749.9,517.6c-0.7-1.1,1-2.2,1.9-2.4c0.6-0.2,0.8,0.2,1.3,0.3c0.9,0.2,1.6-1,2.2-1.4
    			c-0.2,0.9-0.4,2.1-1.2,2.7C753,517.7,751.1,517.1,749.9,517.6"
            />
            <path
              id="path6124"
              class="countryIsland"
              d="M755.2,513.1c-0.2-0.6,0.1-0.9,0.7-0.8C755.7,512.6,755.4,512.9,755.2,513.1"
            />
            <path
              id="path6126"
              class="countryIsland"
              d="M758,505.8c-0.5-0.9-2.4-0.2-2.4-1.3C756,504.4,758.5,504.8,758,505.8"
            />
          </g>
          <circle id="bs_" class="circle" cx="732.9" cy="495.2" r="6" />
        </g>
        <g id="vu" class="countryGroup">
          <g id="vu-">
            <path
              id="path6058"
              class="countryIsland"
              d="M2569.9,822.5c0.9-0.5,0.9-2.1-0.3-1.2C2569.1,821.7,2568.9,822.8,2569.9,822.5"
            />
            <path
              id="path6062"
              class="countryIsland"
              d="M2570,819.3c0.9-0.4,1.1-1.4,0.4-2.1C2569.4,817.6,2569.7,818.5,2570,819.3"
            />
            <path
              id="path6048"
              class="countryIsland"
              d="M2565.5,829.4c-0.3-0.5-0.4-1.1-0.1-1.7c-1.2,0.5-2.1,3.5-2.2,0.4c0-1.2-1.4-4-1.6-1.2
    			c-0.1,1.3,0.6,2.5,0.1,3.7c-0.4,1.2,0.3,1.9,0.3,3.1C2564.5,833.8,2566.6,832,2565.5,829.4"
            />
            <path
              id="path6052"
              class="countryIsland"
              d="M2566,842.2c0.7-0.4,3.4-0.6,2.7-2c-0.6-1-1.6-1.6-2.2-2.6c-2.1-4-2.5,1.8-1.1,0.5
    			C2566.3,839.3,2565.1,840.9,2566,842.2"
            />
            <path
              id="path6054"
              class="countryIsland"
              d="M2565.1,834.9c0.3-0.3,0.3-0.7,0.1-1.1c-0.3,0.2-0.6,0.4-0.8,0.6
    			C2564.7,834.6,2564.9,834.8,2565.1,834.9"
            />
            <path
              id="path6056"
              class="countryIsland"
              d="M2570.4,852.8c1.3,0.1,2-1.7,0.9-2.4c-0.7-0.5-2.2,1.1-2.4,1.6
    			C2570.2,851.1,2569,852.6,2570.4,852.8"
            />
            <path
              id="path6064"
              class="countryIsland"
              d="M2570.5,832.6c0.5-0.5,1-1.1,1.4-1.8c-0.7,0.1-1.5,0.2-1.9,0.9
    			C2569.6,832.2,2569.8,832.7,2570.5,832.6"
            />
            <path
              id="path6066"
              class="countryIsland"
              d="M2571.3,840.2c0.5-0.1,1-0.2,1.4-0.5c-0.5-0.3-0.8-0.9-0.6-1.4c-0.8,0-1.4,0.6-2.2,0.6
    			C2570,839.6,2570.7,839.9,2571.3,840.2"
            />
            <path
              id="path6068"
              class="countryIsland"
              d="M2572.9,844.3c0.1-0.7-1.6-1.6-2.1-1.9C2569.6,844.4,2572,843.5,2572.9,844.3"
            />
            <path
              id="path6072"
              class="countryIsland"
              d="M2572.4,837c1-0.8,1.1-3.5,0.4-4.5C2572.5,834,2572.2,835.4,2572.4,837"
            />
            <path
              id="path6074"
              class="countryIsland"
              d="M2572.8,831.8c1-0.9,1-2.6,0.4-3.7C2573.1,829.3,2572.8,830.5,2572.8,831.8"
            />
            <path
              id="path6076"
              class="countryIsland"
              d="M2574.7,863c0.7-0.4,0.3-3.6-0.9-3.2c-0.5,0.1-0.8,1.5-0.9,1.9
    			C2572.7,862.4,2574.2,862.7,2574.7,863"
            />
            <path
              id="path6078"
              class="countryIsland"
              d="M2574.5,868.7c0.9-1-0.4-1.9,0-3C2572.8,865.9,2573.4,868.1,2574.5,868.7"
            />
            <path
              id="path6080"
              class="countryIsland"
              d="M2576.2,873.8c0.2-0.2,0.5-0.5,0.6-0.7C2576.2,872.6,2575.3,873.3,2576.2,873.8"
            />
          </g>
          <circle id="vu_" class="circle" cx="2568.4" cy="840.7" r="6" />
        </g>
        <g id="fk" class="countryGroup">
          <g id="fk-">
            <path
              id="path4876"
              class="countryIsland"
              d="M912.7,1145.9c0.4-0.1,0.6-0.4,0.4-0.8c-0.7-0.9-1,1-1-0.8
    			C910.6,1144.6,912.1,1145.6,912.7,1145.9"
            />
            <path
              id="Falkland_Islands_West_Falkland"
              class="countryIsland"
              d="M917.2,1141.2c0.1,0.2,0.2,0.4,0.3,0.6c-1.6,0.6-2.9-0.9-4.5-0.8
    			c0.7,0.7,1.4,0.4,1.9,1.2c0.4,0.7,2.1,1.2,2.8,1.4c-1.2-0.3-2.3,0-3.5,0.3c0.8,1.2,2-0.8,3,0.4c-0.6-0.1-0.9,0.1-0.7,0.7
    			c-0.2-0.3-0.4-0.5-0.6-0.8c0,0.7-0.9,2.1,0.5,1.2c-0.4,1.1-2.7,1-3.6,1.2c0.4,0.2,3.6,1.6,1.6,0.4c0.4-0.1,1.8,1.5,2,0.9
    			c0.5-0.9,1.9-0.5,1.3-1.9c2,0.7,2.7-1.6,3.6-2.9c0.3-0.4,0.7-1,0.8-1.6c0-0.6-1.5-1.4-1.2,0.1
    			C920.1,1141.2,918.2,1140.7,917.2,1141.2"
            />
            <path
              id="path4880"
              class="countryIsland"
              d="M915.3,1141c0.4,0.2,0.8,0.2,1.2,0.1c-0.2-0.6-0.6-0.9-1.2-0.7c0.3,0.2,0.5,0.4,0.8,0.6
    			C915.8,1141,915.6,1141,915.3,1141"
            />
            <path
              id="path4882"
              class="countryIsland"
              d="M916.9,1140.3c0.2,0.2,0.5,0.3,0.8,0.3C917.4,1140.5,917.1,1140.4,916.9,1140.3"
            />
            <path
              id="path4884"
              class="countryIsland"
              d="M918.1,1139.9c0.4,0.6,1.7,0.6,2.2,0.3C919.6,1140,918.8,1140.1,918.1,1139.9"
            />
            <path
              id="path4886"
              class="countryIsland"
              d="M921.8,1148.2c0.5-0.4-0.2-0.8-0.6-0.7C921.4,1147.8,921.6,1148,921.8,1148.2"
            />
            <path
              id="Falkland_Islands_East_Falkland"
              class="countryIsland"
              d="M921.6,1147.1c1,0.5,1.7,1.7,3,1.6c-0.5-0.3-0.8-0.9-0.5-1.4
    			c0.7,0.3,1.4,0.7,2.2,0.8c0-0.9-1-1.4-1.7-1.7c0.9-0.1,1.4,0.5,2.2,0.5c0.5,0,0.9-0.4,1.4-0.2c0-1-1.9-1.2-2.4-1.9
    			c1,0.6,2.2,0.7,3.4,0.6c0.7-0.1,1.8-1,0.7-1.2c1.1,0.2,2.2-0.1,3.2-0.5c-0.3,0.1-2.8-1-3.1-1.3c0.8-0.4,1.5,0.1,2.3-0.1
    			c-0.7-0.5-3.9-2.5-4-0.2c0.6,0.1,1.2,0.4,1.6,0.9c-0.9,0-3.5-1.7-1.7-1.4c-0.8-1.4-3-1.3-4.3-1.2c0.4,0.6,0.1,1.2-0.5,1.4
    			c0.6,0.1,0.9,0.3,0.8,0.9c-0.3-0.1-0.5-0.3-0.8-0.4c0.2,0.8,1.4,0.9,1.4,1.9c-1.2-1.4-3.5,1.4-2.6,2.7
    			C922,1146.9,921.8,1147,921.6,1147.1"
            />
            <path
              id="path4890"
              class="countryIsland"
              d="M929.1,1146.3c0.2,0.2,0.4,0.3,0.7,0.4C929.6,1146.6,929.4,1146.5,929.1,1146.3"
            />
          </g>
          <circle id="fk_" class="circle" cx="921.3" cy="1144.2" r="6" />
        </g>
        <g id="gs" class="countryGroup">
          <path
            id="gs-"
            class="countryIsland"
            d="M1082.5,1170.3c0.2-0.1,0.4-0.3,0.6-0.4c-0.2-0.2-0.5-0.4-0.8-0.6c0.5,0.4,1.2,0.3,1.6-0.2
    		c-0.4-0.4-2.2-1.6-2.1-2c0.2-0.5-2.3-1.9-2.7-1.9c1,1.6-1.3-0.3-1.9-0.1c1.2-1.1-1.6-1.2-2.4-1.5c-1-0.4-2.2-0.1-3.3-0.4
    		c-1-0.3-2.5-0.4-3.6-0.1c1.7,0.9,3.4,1.2,5.3,1.2c-1.8,0,0,0.9,0.6,0.9c1.1,0,1.9,0.5,2.8,1.1c0.9,0.6,1.7,0.9,2.7,1.4
    		C1080.4,1168.3,1081.4,1169.5,1082.5,1170.3"
          />
          <circle id="gs_" class="circle" cx="1075.4" cy="1164.7" r="6" />
        </g>
        <g id="gm" class="countryGroup">
          <path
            id="gm-"
            class="country"
            d="M1173.6,582.9c1.4-1.1,1.2-1,1.9,0.5c0.5,1.2,2.2-0.4,3.1,0.2c-1.8-0.9,3.7-1.5,4-1.7
    		c-1.4-0.1-3.1,0-4.4,0.5c-1.1,0.5-1.8,0.8-2.5-0.5c-0.6-1.2,0.6-1.2,1.4-1.1c2.1,0.2,4.3,0.1,6.4,0.1c0.6,0,0.3-1.7,1.6-1.4
    		c1,0.3,2-1,3.1-0.4c0.5,0.3,0.7,1,1.2,1.3c0.5,0.3,1-0.2,1.5,0c0.6,0.3,0.4,1.1,1,1.4c0.7,0.3,1.5-0.2,2.1-0.4
    		c0.7-0.2,2.5-0.1,2.4,1c-0.1,1-1.8,1.4-2.6,1.5c-3.4,0.5-7-3.4-7.5-2.5c-0.6,1.1-0.7,1.5-2.8,1.4c-0.8,0-2.8,0.4-2.4,1.6
    		c0.1,0.2-6.7-0.5-7,1.1C1173.8,584.7,1173.5,583.8,1173.6,582.9"
          />
          <circle id="gm_" class="circle" cx="1184.4" cy="582.5" r="6" />
        </g>
        <g id="qa" class="countryGroup">
          <path
            id="qa-"
            class="country"
            d="M1679.8,479.8c0-0.4-0.6-2.8,0.5-1.7c-0.1-0.4-0.3-2.5,0.4-0.7c0-1.8,0.7-6.6,3.2-4
    		c0.6,0.7,1.8,1.1,1.8,2.2c0,0.7-0.4,1.2-0.5,1.8c-0.2,0.6,0.2,1.3,0.3,1.9c0.1,0.6,0.7,0.6,0.8,1.1c0.3,0.8,0.1,1.5-0.3,2.3
    		c-0.6,1.3-0.4,2.2-1.8,2.7c-1.2,0.4-2.2,1.3-3-0.4C1680.5,483.4,1680.2,481.5,1679.8,479.8"
          />
          <circle id="qa_" class="circle" cx="1682.5" cy="481" r="6" />
        </g>
        <g id="jm" class="countryGroup">
          <path
            id="jm-"
            class="countryIsland"
            d="M711.5,540.6c2-2.6,5.7-2.2,8.6-1.7c1.8,0.3,3.1,1.4,4.8,1.9c1.7,0.5,1.8,1.3,2.7,2.8
    		c-0.8,0.4-1.6,0.4-2.4,0.4c-0.8,0-1.2-0.8-2-0.6c1.1-0.2-0.3-0.4-0.6-0.1c-0.3,0.3-0.6,0.8-1.1,0.8c-0.9,0-2.1-0.1-1.3,1.2
    		c-1,0-1.1-1.2-2-1.1c-0.9,0.1-2.4,0-2.9-0.9c-0.3-0.5-0.8-1-1.2-1.5C713.4,541.1,712,541.5,711.5,540.6"
          />
          <circle id="jm_" class="circle" cx="720.4" cy="541.5" r="6" />
        </g>
        <g id="cy" class="countryGroup">
          <path
            id="cy-"
            class="countryIsland"
            d="M1537.6,395.8c-0.1-0.3-0.2-0.5-0.3-0.8c1,1,2.4-0.9,3.6-0.8c1.7,0.1,0.4-1.6,2-1.6
    		c1.7,0,3.2,0.3,5-0.1c1.9-0.5,3.7-2.5,5.7-2.5c-1.3,0.8-2.4,2.7-3.9,3.2c-1.6,0.5,0,2,0.6,2.7c-0.7,0.1-2.2,0-2.7,0.4
    		c-0.6,0.5-0.8,1.2-1.6,1.5c-0.7,0.2-3.4,0.5-2.9,1.7C1540.9,398.3,1538.7,398.6,1537.6,395.8"
          />
          <g id="xc" class="countryGroup">
            <path
              id="xc-"
              class="disputed"
              d="M1545,395.8c-0.1-0.2,0.3-0.1-0.1-1.3c0,0-2.1-0.1-2.6,0.1c-2,0.8-1.8-0.5-1.6-0.4
    			c1.8,0.1,0.4-1.6,2-1.6c1.7,0,3.2,0.3,5-0.1c1.9-0.5,3.7-2.5,5.7-2.5c-1.3,0.8-2.4,2.7-3.9,3.2c-1.1,0.3-0.7,1.2-0.1,1.9
    			c0.2,0.3-0.4,0.1-0.7,0.2c-0.1,0-0.2-0.2-0.4-0.2c-0.1,0-0.1,0.3-0.3,0.3C1546.5,396,1545.1,396.1,1545,395.8L1545,395.8z"
            />
            <circle id="xc_" class="circle" cx="1548.7" cy="391.8" r="4" />
          </g>
          <circle id="cy_" class="circle" cx="1539.5" cy="397.5" r="6" />
        </g>
        <g id="pr" class="countryGroup">
          <path
            id="pr-"
            class="countryIsland"
            d="M804.8,543.2c-2.4-0.2-5.6,0.7-7.9,0c-0.5-0.2-1.7,0.2-1.3-1c0.2-0.3,0.3-0.7,0.4-1.1
    		c0-0.6-0.8-0.8-0.7-1.5c0.3-2.3,7.7-0.9,8.9-0.5c1,0.3,3.2-0.4,3.2,1.3C807.4,541.3,805.6,542.8,804.8,543.2"
          />
          <circle id="pr_" class="circle" cx="799" cy="539.9" r="6" />
        </g>
        <g id="bn" class="countryGroup">
          <g id="bn-">
            <path
              id="path6824"
              class="country"
              d="M2171.6,658.8c2.9-0.1,3.9-2.6,6.3-3.6c1.1-0.4,0.6,1,0.1,1.4c-1.1,0.9-1.3,1-1.1,2.5
    			c0,0.1,0.7,5.6-1,4.4c-0.6-0.5-2.1-1.5-2.6-2.2C2172.8,660.4,2173.1,659.2,2171.6,658.8"
            />
            <path
              id="Brunei_Temburong"
              class="country"
              d="M2178.6,657.6c-0.2-1.3,0.5-0.4,0.9-1.5c1.1,1.3,1.5,3.3,1.4,5
    			C2179.2,660.7,2178.7,659.1,2178.6,657.6"
            />
          </g>
          <circle id="bn_" class="circle" cx="2175.7" cy="660" r="6" />
        </g>
        <g id="tt" class="countryGroup">
          <g id="tt-">
            <path
              id="Trinidad_and_Tobago_Trinidad"
              class="countryIsland"
              d="M829.8,611.7c0.8-1.3,3.1-1.1,3.6-2.6c0.3-0.9,0.1-3-1.2-2.9
    			c1-1,2.6-1.2,3.9-1.3c2.6-0.2,0.4,1.4,0.6,2.9c0.2,1.4,1,2.7-0.8,3.4C833.9,611.9,831.8,610.9,829.8,611.7"
            />
            <path
              id="Trinidad_and_Tobago_Tobago"
              class="countryIsland"
              d="M838.8,602.2c0.2-0.9,1-1.5,1.8-1.7C840.7,601.5,839.4,601.7,838.8,602.2"
            />
          </g>
          <circle id="tt_" class="circle" cx="834.5" cy="607.2" r="6" />
        </g>
        <g id="cv" class="countryGroup">
          <g id="cv-">
            <path
              id="path5762"
              class="countryIsland"
              d="M1110.7,552.2c0.7,0.1,2.4-1,2.1-1.9C1112.2,548.8,1109.5,550.8,1110.7,552.2"
            />
            <path
              id="path5764"
              class="countryIsland"
              d="M1112.8,553.4C1114.9,553.2,1112,551.5,1112.8,553.4"
            />
            <path
              id="path5766"
              class="countryIsland"
              d="M1116.6,570.4c0.6-0.2,1.1-1.4,0.4-1.8C1115.9,567.9,1115.5,569.8,1116.6,570.4"
            />
            <path
              id="path5768"
              class="countryIsland"
              d="M1117.8,556c0.2-1.6,1.7,0,2.2-0.9c-0.9-0.4-2-0.9-2.9-0.6
    			C1117.3,554.9,1117.6,555.4,1117.8,556"
            />
            <path
              id="path5770"
              class="countryIsland"
              d="M1123.2,569.7c1.2-1.2-0.8-2.6-1.3-3.6C1121.4,567.5,1121.1,569.6,1123.2,569.7"
            />
            <path
              id="path5772"
              class="countryIsland"
              d="M1126,567.8C1128,565.6,1123.9,566.5,1126,567.8"
            />
            <path
              id="path5774"
              class="countryIsland"
              d="M1129.1,560.3c2-0.8,0.3-1.9-0.7-2.2C1128.3,559,1127.5,560.3,1129.1,560.3"
            />
            <path
              id="path5776"
              class="countryIsland"
              d="M1128.3,555c0.5-0.7,0.3-1.5,0.2-2.2C1127.7,553.4,1128.3,554.2,1128.3,555"
            />
          </g>
          <circle id="cv_" class="circle" cx="1118.7" cy="559.5" r="6" />
        </g>
        <g id="pf" class="countryGroup">
          <g id="pf-">
            <path
              id="path2790"
              class="countryIsland"
              d="M158.1,842.9c0.3-0.7,0.1-1-0.6-0.7C157.6,842.4,157.8,842.7,158.1,842.9"
            />
            <path
              id="path2792"
              class="countryIsland"
              d="M158.6,844.8c0.5-0.7,0.4-0.9-0.4-0.7C158.4,844.4,158.5,844.6,158.6,844.8"
            />
            <path
              id="path2794"
              class="countryIsland"
              d="M171.9,850.6c0.2-0.6,0.2-1.2-0.7-0.5C171.4,850.3,171.7,850.4,171.9,850.6"
            />
            <path
              id="path2796"
              class="countryIsland"
              d="M176.1,852.2c0.3,0.5,0.8,0.9,1.4,0.9c0.2-0.8-0.4-0.9-1-1.1c-0.8-0.4-0.7-1.5-1.5-1.9
    			c-0.8-0.4-1.7,0.4-1.2,1.2C174.4,852.2,175.3,852.2,176.1,852.2"
            />
            <path
              id="path2798"
              class="countryIsland"
              d="M233.4,776.1c0.3-0.4,0.7-0.6,1.2-0.6c-0.1-1-1.1-0.7-1.8-0.9
    			C232.8,775.2,233,775.7,233.4,776.1"
            />
            <path
              id="path2800"
              class="countryIsland"
              d="M234.5,780.3c0.7-0.6,0.3-0.9-0.5-0.7C234.2,779.8,234.4,780.1,234.5,780.3"
            />
            <path
              id="path2802"
              class="countryIsland"
              d="M237.8,776c0.2-0.2,0.5-0.3,0.7-0.5C237.8,775,237.5,775.2,237.8,776"
            />
            <path
              id="path2804"
              class="countryIsland"
              d="M242.8,783.8c0.2-0.7,1.2-0.5,1.6-1c-0.7,0.1-1.3-0.4-2-0.1
    			C241.7,783.1,242.2,783.8,242.8,783.8"
            />
          </g>
          <circle id="pf_" class="circle" cx="175" cy="850" r="6" />
        </g>
        <g id="ws" class="countryGroup">
          <g id="ws-">
            <path
              id="path4782"
              class="countryIsland"
              d="M2721.8,815.5c-0.4,0.2-0.9,0.2-1.4,0.1c0.4,1.5,1.8,3.1,3.5,2.1c0.7-0.4,0.9-1.3,0.4-2
    			C2723.7,814.7,2722.6,815.3,2721.8,815.5"
            />
            <path
              id="path4784"
              class="countryIsland"
              d="M2729.6,820.2c0.4-2.1-3.2-2.4-4.4-1.5C2726.2,820.2,2728.1,820,2729.6,820.2"
            />
          </g>
          <circle id="ws_" class="circle" cx="2724.5" cy="816" r="6" />
        </g>
        <g id="lu" class="countryGroup">
          <path
            id="lu-"
            class="country"
            d="M1348.8,268.6C1348.8,268.4,1349.3,268.7,1348.8,268.6c0.5-1.3,0.6-1.8,1.6-2.1
    		c1.1-0.4,0.7,0.7,1.1,1.3c0.5,0.8,1.6,1,2.1,1.8c0.6,0.8-0.5,2-0.4,2.9c-0.7-0.3-3.5-0.4-4.4-0.7c1.3-0.9,0.4-1.6,0-2.6"
          />
          <circle id="lu_" class="circle" cx="1350.9" cy="270.7" r="6" />
        </g>
        <g id="km" class="countryGroup">
          <g id="km-">
            <path
              id="Comoros_Grande_Comore"
              class="countryIsland"
              d="M1631,801.8c0.4-0.4,0.2-1.2,0-1.6c-0.4-1.1-0.2-2.2-0.5-3.3
    			c-1.4,0-0.8,1.8-1.1,2.6C1628.9,800.8,1630.4,801.1,1631,801.8"
            />
            <path
              id="Comoros_Moheli"
              class="countryIsland"
              d="M1633.9,805.7c-0.2-0.7-0.9-1.2-1.7-1.1C1632.2,805.6,1633.1,805.6,1633.9,805.7"
            />
            <path
              id="Comoros_Anjouan"
              class="countryIsland"
              d="M1638.9,805.7c0.8-2.2-0.8-1.4-2.2-1.7C1637.5,804.5,1638.3,805,1638.9,805.7"
            />
          </g>
          <circle id="km_" class="circle" cx="1633.8" cy="801.8" r="6" />
        </g>
        <g id="mu" class="countryGroup">
          <path
            id="mu-"
            class="countryIsland"
            d="M1734.2,871.6c-0.5,0.6-3,4.3-1.3,4.5C1735.6,876.4,1736,871.2,1734.2,871.6"
          />
          <circle id="mu_" class="circle" cx="1730.8" cy="872.4" r="6" />
        </g>
        <g id="fo" class="countryGroup">
          <g id="fo-">
            <path
              id="path4614"
              class="countryIsland"
              d="M1273.9,170.1c0.3-0.2,0.7-0.4,1-0.6C1273.5,168.9,1272.7,170,1273.9,170.1"
            />
            <path
              id="path4616"
              class="countryIsland"
              d="M1276.1,169.1c-0.3-0.1-1.8-1.7-2-1c-0.3,1.1,1.9,2.3,2.7,2.6c0.1-0.6-0.1-0.7-0.5-0.9
    			C1276,169.7,1276,169.4,1276.1,169.1c0.7,0.7,0.9,0.6,0.4-0.3c0.2,0.4,0.5,0.7,0.8,1c0.9-1-1.2-1.8-1.8-2.1
    			C1275.1,168.4,1275.4,168.9,1276.1,169.1"
            />
            <path
              id="path4624"
              class="countryIsland"
              d="M1276.1,169.1c-0.6-0.2-1-0.7-0.7-1.4c0.7,0.3,2.8,1.1,1.8,2.1c-0.3-0.3-0.6-0.6-0.8-1
    			C1276.9,169.7,1276.8,169.8,1276.1,169.1"
            />
            <path
              id="path4618"
              class="countryIsland"
              d="M1276.6,175.1c0.2-1-0.9-1.5-1.5-1.9C1275.2,174,1275.8,174.7,1276.6,175.1"
            />
            <path
              id="path4620"
              class="countryIsland"
              d="M1276.9,172.3c0.3-0.6,0.2-1.3-0.7-0.6C1276.4,171.9,1276.7,172.1,1276.9,172.3"
            />
            <path
              id="path4622"
              class="countryIsland"
              d="M1278.2,169c0.7-0.5,0.7-1.1-0.1-1.4C1278.1,168,1278.1,168.5,1278.2,169"
            />
          </g>
          <circle id="fo_" class="circle" cx="1275.1" cy="171" r="6" />
        </g>
        <g id="st" class="countryGroup">
          <g id="st-">
            <path
              id="Sao_Tome_and_Principe_Principe"
              class="countryIsland"
              d="M1357,685.3c0.3-0.5,0.3-1,0.1-1.5
    			C1356.5,684.3,1356.5,684.9,1357,685.3"
            />
            <path
              id="Sao_Tome_and_Principe_Sao_Tome"
              class="countryIsland"
              d="M1350.3,698.4c0.7-0.4,1.4-1,1.6-1.7c0.1-0.4,0.1-1.7-0.7-1.5
    			C1349.6,695.6,1350.1,697.1,1350.3,698.4"
            />
          </g>
          <circle id="st_" class="circle" cx="1353" cy="690.6" r="6" />
        </g>
        <g id="vi" class="countryGroup">
          <g id="vi-">
            <path
              id="path4052"
              class="countryIsland"
              d="M808.1,542.1c0.3-0.5,0.9-0.8,1.4-0.6C809.1,541.9,808.6,542.1,808.1,542.1"
            />
            <path
              id="path5520"
              class="countryIsland"
              d="M812.4,545.6c0-1.5,1.6-0.4,2.4-0.7C814.1,545.4,813.2,545.5,812.4,545.6"
            />
          </g>
          <circle id="vi_" class="circle" cx="808.4" cy="547.8" r="6" />
        </g>
        <g id="cw" class="countryGroup">
          <path
            id="cw-"
            class="countryIsland"
            d="M779,594.4c-1.2-0.3-2.9-1.4-3-2.8C776.7,591.7,778.8,593.8,779,594.4"
          />
          <circle id="cw_" class="circle" cx="779.1" cy="587.8" r="6" />
        </g>
        <g id="sx" class="countryGroup">
          <path
            id="sx-"
            class="country"
            d="M826.8,542.3c-0.1,0.4-0.2,0.1-0.3,0.2c0,0.1-0.2-0.1-0.3-0.1c-0.1,0,0-0.1,0-0.2"
          />
          <circle id="sx_" class="circle" cx="819.6" cy="543.1" r="6" />
        </g>
        <g id="dm" class="countryGroup">
          <path
            id="dm-"
            class="countryIsland"
            d="M837.1,567.1c-0.1-1.1-1.1-2.5-0.5-3.7C838.1,563.8,838.9,566.5,837.1,567.1"
          />
          <circle id="dm_" class="circle" cx="842.7" cy="564.2" r="6" />
        </g>
        <g id="to" class="countryGroup">
          <g id="to-">
            <path
              id="path5930"
              class="countryIsland"
              d="M2686.6,882.6c0.3-0.4,0.6-0.8,0.9-1.2c-0.7,0.9-1.3-0.3-2.2-0.1
    			C2685.7,881.8,2686.1,882.2,2686.6,882.6"
            />
            <path
              id="path5932"
              class="countryIsland"
              d="M2687.9,884.2c0-0.3-0.1-0.5-0.2-0.8C2687.8,883.7,2687.8,884,2687.9,884.2"
            />
            <path
              class="countryIsland"
              d="M2702,859.2c-0.6,0.1-1,0.5-0.8,1.2C2701.6,860.1,2701.8,859.7,2702,859.2"
            />
          </g>
          <circle id="to_" class="circle" cx="2687.3" cy="881.4" r="6" />
        </g>
        <g id="ki" class="countryGroup">
          <g id="ki-">
            <path
              id="path4790"
              class="countryIsland"
              d="M99,683.8c0.1,0,0.3-0.1,0.4-0.1c-0.5-0.6-1.1-2.5-2.1-2.5c0.4,0.9,0.5,1.6-0.6,1.4
    			C97.3,683.2,98.3,683.2,99,683.8"
            />
            <path
              id="path4788"
              class="countryIsland"
              d="M2630.3,681.4c0.2-0.6,0.2-1.2-0.7-0.5C2629.8,681.1,2630.1,681.2,2630.3,681.4"
            />
          </g>
          <circle id="ki_" class="circle" cx="2627.5" cy="682.2" r="6" />
        </g>
        <g id="fm" class="countryGroup">
          <path
            id="fm-"
            class="countryIsland"
            d="M2507.2,639.9c1.3-0.3,0.3-1.9-0.7-1.5C2506.5,639,2506.8,639.5,2507.2,639.9"
          />
          <circle id="fm_" class="circle" cx="2508" cy="635" r="6" />
        </g>
        <g id="bh" class="countryGroup">
          <path
            id="bh-"
            class="countryIsland"
            d="M1676.9,472.1c0.2,0.5,0.3,1,0.3,1.5c-0.1,0.7,0.7,1.2,0.9,1.8
    		C1678.4,474.6,1678.4,470.5,1676.9,472.1"
          />
          <circle id="bh_" class="circle" cx="1675.6" cy="470.5" r="6" />
        </g>
        <g id="ad" class="countryGroup">
          <path
            id="ad-"
            class="country"
            d="M1317.5,330.3c0.6-0.7,1.8-0.4,2.3,0.3c-0.5,0.6-1.3,1-2.1,1.1
    		C1317.7,331.2,1317.6,330.8,1317.5,330.3"
          />
          <circle id="ad_" class="circle" cx="1317.9" cy="330.8" r="6" />
        </g>
        <g id="mp" class="countryGroup">
          <path
            id="mp-"
            class="countryIsland"
            d="M2403.8,568c0.2-0.5,0.3-0.9,0.1-1.4C2403.5,566.9,2403.4,567.5,2403.8,568"
          />
          <circle id="mp_" class="circle" cx="2400.2" cy="566.1" r="6" />
        </g>
        <g id="pw" class="countryGroup">
          <path
            id="pw-"
            class="countryIsland"
            d="M2326.1,635c0.6-0.8,1-2,0.6-3C2326.1,632.7,2325.4,634,2326.1,635"
          />
          <circle id="pw_" class="circle" cx="2322.4" cy="630.1" r="6" />
        </g>
        <g id="sc" class="countryGroup">
          <path
            id="sc-"
            class="countryIsland"
            d="M1724.5,740c0.1-0.7-0.2-1.6-1.1-1.4C1724,738.9,1724.4,739.4,1724.5,740"
          />
          <circle id="sc_" class="circle" cx="1725.2" cy="734" r="6" />
        </g>
        <g id="io" class="countryGroup">
          <path
            id="io-"
            class="countryIsland"
            d="M1842,762c0.2-0.5,0.3-0.9,0.2-1.4C1841.7,760.9,1841.6,761.5,1842,762"
          />
          <circle id="io_" class="circle" cx="1837" cy="758" r="6" />
        </g>
        <g id="ag" class="countryGroup">
          <g id="ag-">
            <path
              id="path6116"
              class="countryIsland"
              d="M835.7,551.6c-0.9,0-1.2-1.3-0.2-1.4C836.4,550,836,551.2,835.7,551.6"
            />
            <path
              id="path6118"
              class="countryIsland"
              d="M836.1,546.8c-0.6-0.1-0.9-0.6-0.9-1.2C836.1,545.4,836.3,546.2,836.1,546.8"
            />
          </g>
          <circle id="ag_" class="circle" cx="840.7" cy="543.9" r="6" />
        </g>
        <g id="bb" class="countryGroup">
          <path
            id="bb-"
            class="countryIsland"
            d="M849.6,585.6c-1-0.4-0.8-1.3-0.8-2.2C849.6,583.8,851,585.1,849.6,585.6"
          />
          <circle id="bb_" class="circle" cx="851.8" cy="585" r="6" />
        </g>
        <g id="tc" class="countryGroup">
          <g id="tc-">
            <path
              id="path5528"
              class="countryIsland"
              d="M761.2,510.5c-0.1-0.2-0.2-0.5-0.4-0.7c0.5,0.3,1,0.3,1.5,0.1
    			C762,510.1,761.6,510.3,761.2,510.5"
            />
            <path
              id="path5532"
              class="countryIsland"
              d="M765.8,510.5c-0.2-0.1-0.5-0.3-0.7-0.4C765.9,509.3,766.1,509.8,765.8,510.5"
            />
            <path
              id="path5534"
              class="countryIsland"
              d="M767.1,511.2c-0.3-0.2-0.5-0.5-0.7-0.7C767.1,510.4,767,510.7,767.1,511.2"
            />
          </g>
          <circle id="tc_" class="circle" cx="765.6" cy="507.1" r="6" />
        </g>
        <g id="vc" class="countryGroup">
          <path
            id="vc-"
            class="countryIsland"
            d="M837,585c-1-0.6-0.3-1.6,0.2-2.2C837.8,583.5,837.5,584.3,837,585"
          />
          <circle id="vc_" class="circle" cx="840.2" cy="587.7" r="6" />
        </g>
        <g id="lc" class="countryGroup">
          <path
            id="lc-"
            class="countryIsland"
            d="M839.2,579.9c-1.4-0.6-0.7-2,0-2.8C840.2,577.7,839.9,579.3,839.2,579.9"
          />
          <circle id="lc_" class="circle" cx="834.1" cy="576.6" r="6" />
        </g>
        <g id="gd" class="countryGroup">
          <path
            id="gd-"
            class="countryIsland"
            d="M832.5,594.7c-0.8-0.7-0.1-1.7,0.7-2C833.4,593.4,833.2,594.3,832.5,594.7"
          />
          <circle id="gd_" class="circle" cx="830.5" cy="594.9" r="6" />
        </g>
        <g id="mt" class="countryGroup">
          <path
            id="mt-"
            class="countryIsland"
            d="M1409.4,388.9c-0.8-0.1-1.6-0.8-1.2-1.7C1408.8,387.5,1410.1,388,1409.4,388.9"
          />
          <circle id="mt_" class="circle" cx="1410" cy="389.2" r="6" />
        </g>
        <g id="mv" class="countryGroup">
          <path
            id="mv-"
            class="countryIsland"
            d="M1845.1,675.4c0.1-0.7-0.2-1.6-1.1-1.4C1844.5,674.3,1845,674.8,1845.1,675.4"
          />
          <circle id="mv_" class="circle" cx="1844.2" cy="669.4" r="6" />
        </g>
        <g id="ky" class="countryGroup">
          <path
            id="ky-"
            class="countryIsland"
            d="M690,531.3c0.7,0,1.4-0.2,2.2-0.2c-0.7,0.4-1.4,0.8-2.2,0.7C690,531.7,690,531.5,690,531.3"
          />
          <circle id="ky_" class="circle" cx="692.3" cy="530.7" r="6" />
        </g>
        <g id="kn" class="countryGroup">
          <g id="kn-">
            <path
              id="Saint_Kitts_and_Nevis_Saint_Kitts"
              class="countryIsland"
              d="M828.9,549.6c-0.5-0.4-1-0.9-1.4-1.4
    			C828.3,547.9,828.9,548.9,828.9,549.6"
            />
            <path
              id="Saint_Kitts_and_Nevis_Nevis"
              class="countryIsland"
              d="M829.3,550.7c0-0.3-0.1-0.5-0.2-0.8C829.5,550.3,829.5,550.4,829.3,550.7"
            />
          </g>
          <circle id="kn_" class="circle" cx="820.2" cy="555.7" r="6" />
        </g>
        <g id="ms" class="countryGroup">
          <path
            id="ms-"
            class="countryIsland"
            d="M831.6,554.4c0-0.3,0.1-0.2,0.1-0.4c0.2,0.2,0.3,0.3,0.3,0.4c0,0.1,0,0.2-0.2,0.3"
          />
          <circle id="ms_" class="circle" cx="831.4" cy="560.7" r="6" />
        </g>
        <g id="bl" class="countryGroup">
          <path
            id="bl-"
            class="countryIsland"
            d="M827.9,543.5c-0.3,0-0.5,0-0.8,0C827.6,543.8,827.5,543.6,827.9,543.5"
          />
          <circle id="bl_" class="circle" cx="830" cy="548.9" r="6" />
        </g>
        <g id="nu" class="countryGroup">
          <path
            id="nu-"
            class="countryIsland"
            d="M26.5,867c0.2-0.6,0.2-1.2-0.7-0.5C26,866.7,26.3,866.8,26.5,867"
          />
          <circle id="nu_" class="circle" cx="31" cy="867" r="6" />
        </g>
        <g id="pm" class="countryGroup">
          <path
            id="pm-"
            class="countryIsland"
            d="M928.6,292.3c-0.2,0.8-0.6,1.4-0.8,2.2c-0.1-0.8,0.2-1.5,0.2-2.3
    		C928.2,292.3,928.4,292.3,928.6,292.3"
          />
          <circle id="pm_" class="circle" cx="926.5" cy="293.3" r="6" />
        </g>
        <g id="ck" class="countryGroup">
          <path
            id="ck-"
            class="countryIsland"
            d="M101,883c0.3-0.7,0.1-1-0.6-0.7C100.5,882.5,100.8,882.8,101,883"
          />
          <circle id="ck_" class="circle" cx="101" cy="883" r="6" />
        </g>
        <g id="wf" class="countryGroup">
          <path
            id="wf-"
            class="countryIsland"
            d="M2700.5,814.3c-0.6,0.1-1,0.5-0.8,1.2C2700.1,815.2,2700.3,814.8,2700.5,814.3"
          />
          <circle id="wf_" class="circle" cx="2701.2" cy="814.7" r="6" />
        </g>
        <g id="as" class="countryGroup">
          <path
            id="as-"
            class="countryIsland"
            d="M2734.4,822.9c0.3-0.3,0.7-0.7,1-1C2734.8,822,2734.4,822.4,2734.4,822.9"
          />
          <circle id="as_" class="circle" cx="2732.6" cy="826" r="6" />
        </g>
        <g id="mh" class="countryGroup">
          <path
            id="mh-"
            class="countryIsland"
            d="M2607.8,640.8c0.2-0.3,0.2-0.6-0.1-0.9c-0.3,0.2-0.5,0.4-0.6,0.7
    		C2607.3,640.6,2607.6,640.7,2607.8,640.8"
          />
          <circle id="mh_" class="circle" cx="2604.1" cy="639.7" r="6" />
        </g>
        <g id="aw" class="countryGroup">
          <path
            id="aw-"
            class="countryIsland"
            d="M769.8,590.5c-0.1-0.2-0.2-0.5-0.4-0.7c0.8-0.3,1.1,0.8,1.2,1.4C770.3,591,770,590.8,769.8,590.5"
          />
          <circle id="aw_" class="circle" cx="767.1" cy="586.1" r="6" />
        </g>
        <g id="li" class="countryGroup">
          <path
            id="li-"
            class="country"
            d="M1373.4,292.4c0-0.6,0.2-1.2,0.4-1.8C1374.2,291.2,1374.7,292.7,1373.4,292.4"
          />
          <circle id="li_" class="circle" cx="1373.2" cy="291.9" r="6" />
        </g>
        <g id="vg" class="countryGroup">
          <g id="vg-">
            <path
              id="path5522"
              class="countryIsland"
              d="M813.1,540.1c-0.2-0.1-0.5-0.2-0.7-0.3C813.1,539.5,813.3,539.6,813.1,540.1"
            />
            <path
              id="path5524"
              class="countryIsland"
              d="M818,536.8c-0.2-0.1-0.5-0.2-0.7-0.3C817.8,536.4,817.7,536.6,818,536.8"
            />
          </g>
          <circle id="vg_" class="circle" cx="809.9" cy="535.5" r="6" />
        </g>
        <g id="sh" class="countryGroup">
          <path
            id="sh-"
            class="countryIsland"
            d="M1250.3,847.4c0.3-0.5,0.3-1,0.1-1.5C1249.8,846.3,1249.8,846.9,1250.3,847.4"
          />
          <circle id="sh_" class="circle" cx="1250.8" cy="844.2" r="6" />
        </g>
        <g id="je" class="countryGroup">
          <path
            id="je-"
            class="countryIsland"
            d="M1296.4,274.6c0.1-0.2,0.1-0.4,0.2-0.6c-0.6-0.2-0.9,0-0.9,0.6
    		C1295.9,274.6,1296.2,274.6,1296.4,274.6"
          />
          <circle id="je_" class="circle" cx="1298.2" cy="277.9" r="6" />
        </g>
        <g id="ai" class="countryGroup">
          <path
            id="ai-"
            class="countryIsland"
            d="M825.9,541.4c0.5-0.3,0.9-0.7,1.4-0.9C827,541,826.5,541.3,825.9,541.4"
          />
          <circle id="ai_" class="circle" cx="820.6" cy="531.2" r="6" />
        </g>
        <g id="gg" class="countryGroup">
          <path
            id="gg-"
            class="countryIsland"
            d="M1293.2,272.1c0.5-0.2,0.6-0.2,0.5-0.8C1293.5,271.6,1293.4,271.8,1293.2,272.1"
          />
          <circle id="gg_" class="circle" cx="1288.5" cy="271.1" r="6" />
        </g>
        <g id="sm" class="countryGroup">
          <path
            id="sm-"
            class="country"
            d="M1393.2,320.5l0.5-0.7l0.7,0.5l-0.6,0.6L1393.2,320.5z"
          />
          <circle id="sm_" class="circle" cx="1394.4" cy="319.7" r="6" />
        </g>
        <g id="bm" class="countryGroup">
          <path
            id="bm-"
            class="countryIsland"
            d="M832.1,419.5c0.2-0.2,0.5-0.3,0.8-0.4C832.6,419.2,832.3,419.3,832.1,419.5"
          />
        </g>
        <g id="tv" class="countryGroup">
          <path
            id="tv-"
            class="countryIsland"
            d="M2660.3,771.2c-0.6,0.1-1,0.5-0.8,1.2C2659.9,772.1,2660.2,771.7,2660.3,771.2"
          />
          <circle id="tv_" class="circle" cx="2658.6" cy="769.5" r="6" />
        </g>
        <g id="nr" class="countryGroup">
          <path
            id="nr-"
            class="countryIsland"
            d="M2561.1,682.1c0.2-0.1,0.4-0.2,0.6-0.3c-0.2-0.6-0.8-0.9-1.3-0.4
    		C2560.6,681.7,2560.8,681.9,2561.1,682.1"
          />
          <circle id="nr_" class="circle" cx="2562.7" cy="680.5" r="6" />
        </g>
        <g id="gi" class="countryGroup">
          <path
            id="gi-"
            class="country"
            d="M1267.1,386.1c-0.2,0.9-0.4,0.9-0.7,0.1C1266.6,386.1,1266.9,386.1,1267.1,386.1"
          />
          <circle id="gi_" class="circle" cx="1266.3" cy="386.5" r="6" />
        </g>
        <g id="pn" class="countryGroup">
          <path
            id="pn-"
            class="countryIsland"
            d="M342,911c0.3-0.7,0.1-1-0.6-0.7C341.5,910.5,341.8,910.8,342,911"
          />
          <circle id="pn_" class="circle" cx="329" cy="917" r="6" />
        </g>
        <g id="mc" class="countryGroup">
          <path
            id="mc-"
            class="country"
            d="M1358.9,320.7c-0.1-0.1,0.2-0.4,0.4-0.5C1359.5,320.2,1359.2,320.8,1358.9,320.7"
          />
          <circle id="mc_" class="circle" cx="1357.2" cy="320.3" r="6" />
        </g>
        <g id="va" class="countryGroup">
          <path
            id="va-"
            class="country"
            d="M1394.5,337.7L1394.5,337.7l-0.2,0l0.1-0.1l0.1,0"
          />
          <circle id="va_" class="circle" cx="1394.5" cy="337.1" r="6" />
        </g>
        <g id="im" class="countryGroup">
          <path
            id="im-"
            class="countryIsland"
            d="M1281.8,234c0.9-0.2,2.6-0.7,3-1.6c0.3-0.6-0.7-1-1.1-0.8C1282.8,232,1282.3,233.2,1281.8,234"
          />
          <circle id="im_" class="circle" cx="1282.4" cy="233.5" r="6" />
        </g>
        <g id="gu" class="countryGroup">
          <path
            id="gu-"
            class="countryIsland"
            d="M2398.4,584c0.2-0.3,1.3-2.7,1.2-3.2C2398.1,580.9,2397.4,583,2398.4,584"
          />
          <circle id="gu_" class="circle" cx="2397.8" cy="581.1" r="6" />
        </g>
        <g id="sg" class="countryGroup">
          <path
            id="sg-"
            class="countryIsland"
            d="M2093.4,686.2c-1,0.3,0.5,2.1,1.8,0.4C2094.7,686.3,2094.1,686.2,2093.4,686.2"
          />
          <circle id="sg_" class="circle" cx="2095.1" cy="687.1" r="6" />
        </g>
        <g id="tk">
          <path
            id="tk-"
            class="countryIsland"
            d="M2737.9,782.8c-0.1-0.1,0.2-0.1-0.1-0.1C2737.6,782.6,2737.5,782.8,2737.9,782.8"
          />
          <circle id="tk_" class="circle" cx="2738.5" cy="779.6" r="6" />
        </g>
      </g>
    </svg>
  </div>
</template>
