<template>
  <div class="signup-form">
    <div v-if="showSuccess" class="text-center mt-20">
      <div class="p-8 border border-jade">
        <div class="text-lg md:text-xl max-w-md mx-auto mt-2">
          We have received your submission, <br />and will be in contact soon.
        </div>
      </div>
    </div>

    <div v-else class="relative flex max-w-xl">
      <form class="form-wrapper w-full">
        <div class="flex flex-col lg:flex-col">
          <div class="field-row">
            <div
              class="field-item"
              :class="v$.signupForm.name.$error ? 'has-error' : ''"
            >
              <label class="label">Name</label>
              <div class="relative">
                <input
                  type="text"
                  v-model="signupForm.name"
                  name="name"
                  placeholder="..."
                />
                <div v-if="v$.signupForm.name.$error" class="field-error">
                  Name is required.
                </div>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div class="field-item">
              <label class="label">Organization</label>
              <div class="relative">
                <input
                  type="text"
                  name="organization"
                  v-model="signupForm.organization"
                  placeholder="..."
                />
              </div>
            </div>
          </div>
          <div class="field-row">
            <div
              class="field-item"
              :class="v$.signupForm.emailAddress.$error ? 'has-error' : ''"
            >
              <label class="label">Email</label>
              <div class="relative">
                <input
                  type="text"
                  name="email"
                  v-model="signupForm.emailAddress"
                  placeholder="..."
                />
                <div
                  v-if="v$.signupForm.emailAddress.$error"
                  class="field-error"
                >
                  Email is required.
                </div>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div
              class="field-item relative"
              :class="v$.signupForm.subject.$error ? 'has-error' : ''"
            >
              <div class="relative">
                <label class="label">Subject</label>
                <input
                  type="text"
                  name="subject"
                  v-model="signupForm.subject"
                  placeholder="..."
                />
                <div v-if="v$.signupForm.subject.$error" class="field-error">
                  Subject is required.
                </div>
              </div>
            </div>
          </div>
          <div class="field-row">
            <div
              class="field-item relative"
              :class="v$.signupForm.message.$error ? 'has-error' : ''"
            >
              <div class="relative">
                <label class="label">Message</label>
                <textarea
                  name="message"
                  type="textarea"
                  v-model="signupForm.message"
                  placeholder="..."
                />
                <div v-if="v$.signupForm.message.$error" class="field-error">
                  Message is required.
                </div>
              </div>
            </div>
          </div>

          <div class="field-item mt-8 flex">
            <a
              class="btn btn--green w-full uppercase text-center"
              label="Send"
              @click.prevent="submitHandler"
              >send
            </a>
          </div>
        </div>
      </form>
      <div class="col-span-1 order-1 lg:order-2 mb-8"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "SignupForm",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showSuccess: false,
      signupForm: {
        name: "",
        organization: "",
        emailAddress: "",
        subject: "",
        message: "",
      },
    };
  },
  validations() {
    return {
      signupForm: {
        name: { required },
        emailAddress: { required, email },
        subject: { required },
        message: { required },
      },
    };
  },
  methods: {
    async submitHandler() {
      const result = await this.v$.$validate();
      if (!result) {
        //console.log("invalid form");
      } else {
        let formData = new FormData();
        formData.set("q3_name", this.signupForm.name);
        formData.set("q11_organization", this.signupForm.organization);
        formData.set("q4_email", this.signupForm.emailAddress);
        formData.set("q5_subject", this.signupForm.subject);
        formData.set("q6_message", this.signupForm.message);
        const res = await axios.post(
          "https://submit.jotform.com/submit/220240484301138",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.status === 200) {
          this.showSuccess = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.signup-form {
  .field-item {
    input[type="textarea"] {
      height: 175px;

      &::placeholder {
        position: absolute;
        top: 0;
      }
    }

    label {
      font-size: 11px;
    }
  }

  button {
    @apply w-full;
  }
  .btn {
    cursor: pointer;
  }

  .btn {
    @apply py-4 px-8 font-medium rounded border-0;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
      0px 1px 2px rgba(0, 0, 0, 0.3);

    @screen md {
      @apply py-4 px-8;
      width: inherit;
    }
  }

  .btn--green {
    // @apply bg-green;
    background: $teal-dark;

    &:hover {
      background: $teal-faded;
    }

    &:active {
      transform: translateY(1px);
    }
  }
}
</style>
