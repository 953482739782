<template>
  <div class="dropdown">
    <select v-model="selected" @change="onChange($event)">
      <option :value="'none'" :key="'no_selection'">All</option>
      <option v-for="item in items" :value="item.key" :key="item.key">
        {{ item.display }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: ["reset", "items", "selectedValue"],
  data() {
    return {
      selected: "none",
    };
  },
  methods: {
    onChange(event) {
      this.selected = event.target.value;
      this.$emit("itemSelected", this.selected);
    },
  },
  watch: {
    reset: function () {
      this.selected = "none";
      this.$emit("itemSelected", this.selected);
    },
    selectedValue: function () {
      this.selected = this.selectedValue;
    },
  },
  created() {
    if (this.selectedValue !== "none") {
      this.selected = this.selectedValue;
    }
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  option {
    @apply capitalize;
  }
}
</style>
