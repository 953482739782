<template>
  <div class="contact_view">
    <div class="container">
      <div class="mb-8">
        <h1><span>Contact</span></h1>
        <div class="subtitle max-w-xl">
          For technical assistance or any other inquiries about the VDO
          Dashboard, please fill out this form and a member of our team will be
          touch.
        </div>
      </div>
      <div class="content">
        <SignupForm />
      </div>
    </div>
  </div>
</template>
<script>
import SignupForm from "../components/SignupForm.vue";

export default {
  name: "ContactView",
  components: { SignupForm },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.contact_view {
  position: absolute;
  font-size: 1rem;
  top: $topnav_height;
  padding: 1rem 120px;
  @screen md {
    padding: 2rem 120px;
  }

  h1 {
    position: relative;
    background-color: $bg-primary;
    //font-size: 24px;
    margin-bottom: 2rem;

    @screen md {
      //font-size: 40px;
      margin-bottom: 2rem;
    }

    span {
      background-color: $bg-primary;
      position: relative;
      padding-right: 0.7em;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      border-top: 1px solid $teal;
    }
  }

  .container {
    width: 100%;
    margin: auto;

    .content {
      max-width: 1500px;
    }

    h2 {
      font-weight: bold;
      font-size: 19.2px;
      line-height: 24px;
      margin: 8px 0;

      span {
        display: block;
        margin-bottom: 1rem;
      }
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin: 8px 0;
      display: inline-block;
    }
  }

  @include breakpoint(handheld) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
