<template>
  <div class="view_toggle">
    <div
      class="icon timeline_view"
      @click="listViewSelected(true)"
      :class="{ active: listView }"
    >
      <img
        class="active"
        src="@/images/timeline-view_active.svg"
        alt="Deselect Timeline View"
      />
      <img
        class="inactive"
        src="@/images/timeline-view_inactive.svg"
        alt="Select Timeline View"
      />
    </div>
    <div
      class="icon map_view"
      @click="listViewSelected(false)"
      :class="{ active: !listView }"
    >
      <img
        class="active"
        src="@/images/globe_active.svg"
        alt="Deselect List View"
      />
      <img
        class="inactive"
        src="@/images/globe_inactive.svg"
        alt="Select List View"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewToggle",
  props: ["listView"],
  methods: {
    listViewSelected(isListView) {
      this.$emit("listViewSelected", isListView);
    },
  },
};
</script>

<style scoped lang="scss">
.view_toggle {
  position: absolute;
  right: 0;
  width: auto;
  height: 100%;
  // background: magenta;
  box-sizing: border-box;
  padding: 28px 40px 28px 0;

  cursor: pointer;

  .icon {
    position: relative;
    display: inline-block;
    margin-left: 12px;
    width: 16px;
    height: 16px;

    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all $uiQuickSpeed;
    }

    img.active {
      opacity: 0;
      pointer-events: none;
    }
    img.inactive {
      opacity: 1;
      pointer-events: all;
    }

    &.active,
    &:hover {
      img.active {
        opacity: 1;
        pointer-events: all;
      }
      img.inactive {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
</style>
