<template>
  <div class="about_view">
    <div class="container">
      <div class="header-tab">
        <span
          @click="setTab('about')"
          :class="[activeTab === 'about' ? 'active' : '']"
          >About</span
        >
        <span
          @click="setTab('methodology')"
          :class="[activeTab === 'methodology' ? 'active' : '']"
          >Methodology</span
        >
      </div>
      <div class="content" v-if="ready">
        <div v-if="activeTab === 'about'">
          <h1><span v-html="asText(pageData.about.title)"></span></h1>
          <div v-html="asHtml(pageData.about.content)"></div>
          <div v-for="(slice, ii) in pageData.about.body" :key="ii">
            <div v-if="slice.slice_type === 'html'">
              <div
                v-if="slice.primary.html_title"
                v-html="asHtml(slice.primary.html_title)"
              ></div>
              <div
                v-if="slice.primary.html_intro"
                v-html="asHtml(slice.primary.html_intro)"
              ></div>
              <div v-for="(item, ii) in slice.items" :key="'item-' + ii">
                <div v-html="asText(item.html)"></div>
              </div>
            </div>
            <div v-if="slice.slice_type === 'rich_text'">
              <div
                v-if="slice.primary.rt_title"
                v-html="asHtml(slice.primary.rt_title)"
              ></div>
              <div
                v-if="slice.primary.rt_intro"
                v-html="asHtml(slice.primary.rt_intro)"
              ></div>
              <div v-for="(item, ii) in slice.items" :key="'item-' + ii">
                <div v-html="asText(item.content)"></div>
              </div>
            </div>
          </div>
          <!--          <div class="mb-16">-->
          <!--            <h2>Joining the VDO</h2>-->
          <!--            <p>-->
          <!--              When a country joins the VDO, it receives access to a range of-->
          <!--              dashboards: a main dashboard, a country-specific private-->
          <!--              dashboard, and UN regional dashboards. Once a country is-->
          <!--              onboarded, its dashboard will begin to incorporate alerts.-->
          <!--            </p>-->
          <!--            <p>-->
          <!--              Countries that sign on for the VDO Dashboard are provided with-->
          <!--              country-specific alerts and technical assistance and training. To-->
          <!--              learn more about joining the VDO, please-->
          <!--              <a href="/contact">contact us</a>.-->
          <!--            </p>-->
          <!--          </div>-->
          <hr class="mt-16" />
          <div class="mt-16 collaboration-section">
            <h4 class="uppercase">A collaboration of</h4>
            <SponsorsComponent class="mt-4 flex flex-col md:flex-row gap-x-4" />
          </div>
        </div>
        <div v-else>
          <h1><span v-html="asText(pageData.methodology.title)"></span></h1>
          <div v-html="asHtml(pageData.methodology.content)"></div>
        </div>
      </div>
    </div>
    <a class="schema" href="https://schemadesign.com" target="_blank">
      Built By<br />
      <img src="@/images/SchemaLogo.svg" alt="Schema Design" />
    </a>
  </div>
</template>

<script>
import SponsorsComponent from "@/components/SponsorsComponent.vue";
import * as prismicH from "@prismicio/helpers";
import linkResolver from "../linkResolver";

export default {
  name: "AboutView",
  inject: ["prismic"],
  components: { SponsorsComponent },
  data() {
    return {
      activeTab: "about",
      pageData: {},
      ready: false,
    };
  },
  methods: {
    setTab(el) {
      this.activeTab = el;
    },
    async loadContent() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);

      await prismicClient.getSingle("vdo_about").then(({ data }) => {
        this.pageData.about = data;
      });
      await prismicClient.getSingle("vdo_methodology").then(({ data }) => {
        this.pageData.methodology = data;
      });

      this.ready = true;

      return true;
    },
    asHtml(ref) {
      return prismicH.asHTML(ref, linkResolver);
    },
    asText(ref) {
      return prismicH.asText(ref);
    },
  },
  created() {
    this.loadContent();
  },
};
</script>

<style lang="scss">
.about_view {
  position: relative;
  top: $topnav_height;
  padding: 3rem 120px;
  font-size: 1rem;
  font-family: "Mark Pro";

  h1 {
    position: relative;
    //font-size: 24px;
    margin-bottom: 2rem;
    background-color: $bg-primary;

    @screen md {
      //font-size: 40px;
      margin-bottom: 2.5rem;
    }

    span {
      background-color: $bg-primary;
      position: relative;
      padding-right: 0.7em;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      border-top: 1px solid $teal;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;

    .header-tab {
      @apply mb-8 font-medium uppercase;
      font-size: 13px;
      span {
        @apply mr-4 cursor-pointer;

        &.active {
          @apply text-jade;
        }
      }
    }

    .content {
      max-width: 632px;
      //margin: auto;

      li {
        @apply list-disc mb-4 ml-4;
        &:before {
          display: none;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 22px;
      line-height: 1.16rem;
      margin: 2rem 0 0.5rem 0;

      @screen md {
        font-size: 28px;
        line-height: 32px;
      }

      span {
        display: block;
        margin-bottom: 1rem;
      }
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin: 8px 0;
      display: inline-block;
    }
  }

  .collaboration-section {
    color: $grey;
  }

  .schema {
    display: block;
    position: fixed;
    right: 40px;
    bottom: 20px;
    width: 57px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    border: none;
    z-index: 10001;
    font-size: 10px;

    opacity: 0.2;
    transition: opacity $uiSpeed;

    &:hover {
      color: $white;
      border: none;
      opacity: 1;
    }

    img {
      width: 100%;
      margin-top: 0.15em;
    }
  }

  .dot {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 16px;

    &--red {
      background-color: $active;
    }

    &--yellow {
      background-color: $passive;
    }

    &--green {
      background-color: $ignore;
    }
  }

  @include breakpoint(tablet) {
    .schema {
      //left: 6px;
      bottom: 4px;
    }
  }

  @include breakpoint(handheld) {
    padding: 3rem 1rem;

    .dot {
      height: 21px;
      width: 21px;
    }

    .schema {
      display: none;
    }
  }
}
</style>
