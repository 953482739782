<template>
  <div class="alert_view" v-if="alertsReady">
    <div class="filter-bar">
      <div class="filter-item">
        <label>
          <input type="radio" value="location" v-model="missingField" />
          Missing Location
        </label>
      </div>
      <div class="filter-item">
        <label>
          <input type="radio" value="vaccine" v-model="missingField" />
          Missing Vaccine Type
        </label>
      </div>
      <div class="filter-item">
        <label>
          <input type="radio" value="topic" v-model="missingField" />
          Missing Topic
        </label>
      </div>
      <div class="filter-item">
        <label>
          <input type="radio" value="alert" v-model="missingField" />
          Missing Alert
        </label>
      </div>
    </div>
    <StackChart
      :totalWeeks="totalWeeks"
      :weeks="weeks"
      :weekWidth="weekWidth"
      :weekIndex="weekIndex"
      :splashView="splashView"
      :alertView="alertView"
      :listView="listView"
    />
    <Choropleth
      :weeks="weeks"
      :weekIndex="weekIndex"
      :splashView="splashView"
      :alertView="alertView"
      :listView="listView"
      :locationsLookup="locationsLookup"
      :location="filterData.location"
      @location-selected="locationSelected"
    />
    <Timeline
      :weeks="weeks"
      :totalWeeks="totalWeeks"
      :weekWidth="weekWidth"
      :weekIndex="weekIndex"
      :alertView="alertView"
      :splashView="splashView"
      :listView="listView"
      @index-change="weekChange"
    />
    <Alert :alert="selectedAlert" :alertView="alertView" :extras="extras" />
    <Cards
      :weeks="weeks"
      :weekIndex="weekIndex"
      :totalWeeks="totalWeeks"
      :splashView="splashView"
      :alertView="alertView"
      @alert-selected="alertSelected"
      @alert-deselected="alertDeselected"
      @index-change="weekChange"
    />
    <SplashScreen :splashView="splashView" @hidden="splashHidden" />
  </div>
</template>

<script>
import { groupBy, forEach, uniq } from "lodash";

import StackChart from "@/components/StackChart.vue";
import Timeline from "@/components/Timeline.vue";
import Cards from "@/components/Cards.vue";
import Alert from "@/components/Alert.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import Choropleth from "@/components/Choropleth.vue";

export default {
  name: "AlertView",
  inject: ["prismic", "dayjs"],
  components: {
    SplashScreen,
    StackChart,
    Timeline,
    Alert,
    Cards,
    Choropleth,
  },
  data() {
    return {
      splashView: false, // is the dashboard splash modal visible?
      alertView: false, // are we looking at an individual alert?
      extras: false, // used to hide features that aren't ready or are disabled
      alertsReady: false,
      apiPageNumber: 1,
      weeks: [],
      weekIndex: -1,
      weekWidth: 60,
      calculatingWeeks: false,
      selectedAlert: null,
      listView: true,
      alerts: [],
      locationsLookup: {},
      missingField: "",
      filterData: {
        location: "",
        alertLevel: "none",
        topic: "",
        virus: "",
        alert: "",
        vaccine: "",
        locations: [],
        topics: [],
        alerts: [
          { display: "Direct Response", key: "active", value: true },
          { display: "Passive Response", key: "passive", value: true },
          { display: "Ignore", key: "ignore", value: true },
        ],
        viruses: [],
      },
    };
  },
  methods: {
    weekChange(index) {
      if (this.weeks.length > 0) {
        // set previous week.active to false
        this.weeks[this.weekIndex].active = false;

        // don't set index to week if there are no alerts to display in it
        // instead set to most recent week with displayed alerts
        if (!this.weeks[index].display) {
          for (let i = this.totalWeeks - 1; i >= 0; i--) {
            if (this.weeks[i].display) {
              index = i;
              break;
            }
          }
        }

        // finally set weekIndex and activate selected week
        this.weekIndex = index;
        this.weeks[this.weekIndex].active = true; // set current week.active to true
      }
    },
    splashHidden() {
      this.splashView = false;
    },
    alertSelected(alert) {
      if (this.selectedAlert) {
        this.selectedAlert.selected = false;
      }
      this.selectedAlert = alert;
      this.selectedAlert.selected = true;
      this.alertView = true;
    },
    alertDeselected() {
      if (this.selectedAlert) {
        this.selectedAlert.selected = false;
      }
      // this.selectedAlert = null;
      this.alertView = false;
    },
    locationSelected(location) {
      this.filterData.location = location;
    },
    alertLevelSelected(alertLevel) {
      this.filterData.alertLevel = alertLevel;
    },
    topicSelected(topic) {
      this.filterData.topic = topic;
    },
    virusSelected(virus) {
      this.filterData.virus = virus;
    },
    listViewSelected(isListView) {
      this.listView = isListView;
    },
    loadAlerts() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);
      prismicClient
        .query(
          this.prismic.Predicates.any("document.type", [
            "misinformation_alert",
          ]),
          {
            pageSize: 100,
            page: this.apiPageNumber,
            fetchLinks: "topic.name, country.name",
          }
        )
        .then((response) => {
          response.results.forEach((alert) => {
            alert.day = this.dayjs(alert.first_publication_date);
            alert.timeAgo = alert.day.fromNow();
            alert.selected = false;
          });

          this.alerts = this.alerts.concat(response.results);

          // sort ascending by date
          this.alerts.sort((a, b) => a.day.diff(b.day));

          // do some cleanup, additons and querying of meta data
          let topics = [];
          let locations = [];
          let vaccines = {};

          forEach(this.alerts, (alert) => {
            alert.display = true;
            let vaccineValues = [];
            let topicValues = [];

            if (alert.data.topics) {
              // console.log('alert topics: ', alert.data.topics);
              alert.data.topics.forEach(({ topic }) => {
                if (topic.uid) {
                  if (
                    topic.tags.length &&
                    topic.tags.includes("vaccine type")
                  ) {
                    vaccines[topic.uid] = topic.data.name;
                    vaccineValues.push(topic.data.name);
                    // topics.push(topic.uid);
                  } else {
                    topics.push(topic.uid);
                    topicValues.push(topic.data.name);
                  }
                }
              });
            }
            if (alert.data.vaccine_types) {
              alert.data.vaccine_types.forEach(({ vaccine }) => {
                if (vaccine && vaccine.uid) {
                  vaccines[vaccine.uid] = vaccine.data.name;
                  vaccineValues.push(vaccine.data.name);
                }
              });
            }
            alert.vaccines = uniq(vaccineValues);
            alert.topics = topicValues;

            let alertMap = {
              "Direct Response": "High",
              "Passive Response": "Medium",
              Ignore: "Low",
            };
            if (alertMap[alert.data.rating]) {
              // console.log(alert.data.rating);
              alert.data.rating = alertMap[alert.data.rating];
            }

            alert.locations = [];

            if (alert.data.locations) {
              alert.data.locations.forEach((location) => {
                if (
                  location.country.slug &&
                  location.country.slug.length !== 2
                ) {
                  locations.push(location.country.slug);
                  this.locationsLookup[location.country.slug] =
                    location.country.uid;
                  alert.locations.push(location.country.slug);
                }
              });
            }
          });

          this.filterData.topics = [];

          let uniqueTopics = uniq(topics);
          uniqueTopics.sort();
          uniqueTopics.forEach((topic) => {
            this.filterData.topics.push({
              key: topic,
              display: topic.replace(/--/g, " & ").replace(/-/g, " "),
              value: true,
            });
          });

          this.filterData.locations = [];

          // console.log('locationsLookup: ', this.locationsLookup);

          let uniqueLocations = uniq(locations);
          uniqueLocations.sort();
          uniqueLocations.forEach((location) => {
            this.filterData.locations.push({
              key: location,
              display: location.replace(/-/g, " "),
              value: true,
            });
          });

          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadAlerts();
          } else {
            this.groupAlertsByWeek();
            this.alertsReady = true;
          }
        });
    },
    groupAlertsByWeek() {
      // let alertsByWeek = [];

      if (this.alerts.length > 0) {
        let now = this.dayjs(); // get today

        let totalWeeksPast = 104;

        for (let i = 0; i < totalWeeksPast; i++) {
          let weeksAlerts = [];

          let weekStart = now.subtract(i * 7, "day");
          let weekEnd = now.subtract((i + 1) * 7, "day");

          forEach(this.alerts, (alert) => {
            if (alert.day.isBetween(weekStart, weekEnd)) {
              weeksAlerts.push(alert);
            }
          });

          // get locations
          let weeksLocations = [];
          forEach(weeksAlerts, (alert) => {
            if (alert.data.locations) {
              alert.data.locations.forEach((location) => {
                let uid = location.country.uid;
                if (uid && uid.length == 2) {
                  weeksLocations.push(uid);
                }
              });
            }
          });
          weeksLocations = uniq(weeksLocations); // remove duplicates, added to alertsByWeekByType below

          // console.log('weeksLocations: ', weeksLocations);

          // group alerts by type e.g. rating: "high", "medium", "low"
          if (weeksAlerts.length > 0) {
            let alertsByWeekByType = groupBy(weeksAlerts, "type");

            forEach(alertsByWeekByType, (array) => {
              array.reverse();
            });

            if (alertsByWeekByType.misinformation_alert) {
              alertsByWeekByType.misinformation_alert = groupBy(
                alertsByWeekByType.misinformation_alert,
                (alert) => {
                  return alert.data.rating;
                }
              );
            } else {
              alertsByWeekByType.misinformation_alert = {};
            }

            alertsByWeekByType.active = false;
            alertsByWeekByType.display = true;

            alertsByWeekByType.dateEnd = weekEnd;
            alertsByWeekByType.dayEnd = weekStart.format("MMM DD YYYY");
            alertsByWeekByType.dayStart = weekEnd.format("MMM DD YYYY");

            alertsByWeekByType.locations = weeksLocations;

            this.weeks.push(alertsByWeekByType);
          }
        }
      }

      this.weeks.reverse();
      this.weekIndex = this.weeks.length - 1;
      this.weeks[this.weekIndex].active = true;

      let lastMonth, thisMonth;
      this.weeks.forEach((week) => {
        thisMonth = week.dateEnd.format("MMM 'YY");
        if (thisMonth != lastMonth) {
          lastMonth = thisMonth;
        } else {
          thisMonth = " ";
        }

        week.month = thisMonth;
        week.day = week.dateEnd.date();
      });

      //console.log("alertView weeks:", this.weeks);
    },
  },
  computed: {
    totalWeeks: function () {
      return this.weeks.length;
    },
  },
  watch: {
    /* eslint-disable no-debugger */
    missingField: {
      deep: true,
      handler() {
        forEach(this.alerts, (alert) => {
          let display = true;

          // console.log(this.filterData);
          if (this.missingField) {
            display = false;
          }

          // forEach(alert.data.topics, (alertTopic) => {
          //   forEach(this.filterData.topics, (topic) => {
          //     if (alertTopic.topic.name == topic.key && !topic.value)
          //       display = false;
          //   });
          // });

          // if (this.filterData.location !== "none" && display) {
          //   if (alert.locations.length == 0) {
          //     display = false;
          //   } else {
          //     let inLocation = false;
          //     forEach(alert.locations, (alertLocation) => {
          //       if (alertLocation == this.filterData.location)
          //         inLocation = true;
          //     });
          //     display = inLocation;
          //   }
          // }

          if (this.missingField === "alert") {
            if (alert.data.rating === "") {
              display = true;
            }
          }

          if (this.missingField === "topic") {
            if (!alert.data.topics.length) {
              display = true;
            }
          }

          if (this.missingField === "vaccine") {
            if (!alert.data.vaccine_types.length) {
              display = true;
            }
            alert.data.topics.forEach(({ topic }) => {
              if (topic.tags && topic.tags.includes("vaccine type")) {
                display = false;
              }
            });
          }

          if (this.missingField === "location") {
            if (
              !alert.data.locations.length ||
              (alert.data.locations.length === 1 &&
                alert.data.locations[0].country.uid === "global")
            ) {
              display = true;
            }
          }

          // if (this.filterData.topic !== "none" && display) {
          //   let hasTopic = false;
          //   forEach(alert.data.topics, (alertTopic) => {
          //     if (alertTopic.topic.slug == this.filterData.topic)
          //       hasTopic = true;
          //   });
          //   if (!hasTopic) display = false;
          // }

          // if (this.filterData.virus !== "none" && display) {
          //   let hasVirus = false;
          //   forEach(alert.data.topics, (alertTopic) => {
          //     if (alertTopic.topic.slug == this.filterData.virus)
          //       hasVirus = true;
          //   });
          //   if (!hasVirus) display = false;
          // }

          alert.display = display;
        });

        // check if filters have hidden all items in a weeks
        // if so hide that week
        this.weeks.forEach((week) => {
          let alerts = week.misinformation_alert;
          let totalDisplayed = 0;

          for (const alertLevel in alerts) {
            alerts[alertLevel].forEach((alert) => {
              if (alert.display) {
                totalDisplayed++;
              }
            });
          }

          if (totalDisplayed > 0) {
            week.display = true;
          } else {
            week.display = false;
          }
        });

        // set the week index back to last item, for scroll considerations
        this.weekChange(this.totalWeeks - 1);
      },
    },
  },
  created() {
    this.loadAlerts();
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  position: absolute;
  top: $topnav_height;
  left: 0;
  width: 100vw;
  height: $filterbar_height;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  margin: 0 -1rem;

  .filter-item {
    font-size: 1rem;
    margin: 0 1rem;
    input {
      margin-right: 0.5rem;
    }
  }

  .filter-item label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
