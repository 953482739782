// import * as Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./Router.js";

import prismic from "@prismicio/client";

import dayjs from "dayjs";

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import "@/scss/_floatingVue.scss";

import "@/scss/tailwind.scss";
dayjs.extend(require("dayjs/plugin/isBetween"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

const app = createApp(App);

app.provide("prismic", prismic);
app.provide("dayjs", dayjs);

app.use(router);
app.mount("#app");

app.use(FloatingVue);
