<template>
  <div class="filterbar" :class="{ faded: alertView || splashView }">
    <div class="flex gap-x-4">
      <div class="group">
        <div class="title">Location</div>
        <Dropdown
          class="dropdown--large"
          :reset="resetTrigger"
          :items="filterData.locations"
          :selectedValue="filterData.location"
          @item-selected="locationSelected"
        />
      </div>

      <div class="group">
        <div class="title">Risk Level</div>
        <Dropdown
          :reset="resetTrigger"
          :items="filterData.alerts"
          :selectedValue="filterData.alertLevel"
          @item-selected="alertLevelSelected"
        />
      </div>

      <div class="group">
        <div class="title">Vaccines</div>
        <Dropdown
          :reset="resetTrigger"
          :items="filterData.viruses"
          :selectedValue="filterData.virus"
          @item-selected="virusSelected"
        />
      </div>

      <div class="group">
        <div class="title">Topics</div>
        <Dropdown
          class="dropdown--large"
          :reset="resetTrigger"
          :items="cleanedTopics"
          :selectedValue="filterData.topic"
          @item-selected="topicSelected"
        />
      </div>
    </div>
    <div
      v-if="
        filterData.alertLevel !== 'none' ||
        filterData.location !== 'none' ||
        filterData.topic !== 'none' ||
        filterData.virus !== 'none'
      "
      class="close-container order-1 md:order-2"
    >
      <div class="relative h-full">
        <span @click="reset" class="close-btn absolute">
          <CloseIcon /><span>Clear All</span>
        </span>
      </div>
    </div>

    <ViewToggle
      :listView="listView"
      @list-view-selected="listViewSelected"
      v-if="enableViewToggle"
    />
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import ViewToggle from "./ViewToggle.vue";
import CloseIcon from "./CloseIcon";

// location: "none",
// alertLevel: "none",
// topic: "none",
// virus: "none",
// import TypeSelect from "./TypeSelect.vue";

// import TopicSelect from "./TopicSelect.vue";

export default {
  name: "FilterBar",
  components: {
    CloseIcon,
    Dropdown,
    ViewToggle,
  },
  props: ["filterData", "listView", "splashView", "alertView"],
  data() {
    return {
      resetTrigger: false,
      enableViewToggle: true,
    };
  },
  computed: {
    cleanedTopics: function () {
      let topics = [];

      this.filterData.topics.forEach((topic) => {
        let isTopicAVirus = false;

        this.filterData.viruses.forEach((virus) => {
          // console.log(virus);
          if (topic.key == virus.key) {
            isTopicAVirus = true;
          }
        });
        if (!isTopicAVirus) {
          topics.push(topic);
        }
      });

      return this.filterData.topics;
    },
  },
  methods: {
    reset() {
      // prop just needs to change value for children to watch, could be anything
      this.resetTrigger = !this.resetTrigger;
    },
    locationSelected(location) {
      this.$emit("locationSelected", location);
    },
    alertLevelSelected(alertLevel) {
      this.$emit("alertLevelSelected", alertLevel);
    },
    topicSelected(topic) {
      this.$emit("topicSelected", topic);
    },
    virusSelected(virus) {
      this.$emit("virusSelected", virus);
    },
    listViewSelected(isListView) {
      this.$emit("listViewSelected", isListView);
    },
  },
};
</script>

<style lang="scss">
.filterbar {
  display: flex;
  align-items: center;
  @apply fixed;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  top: $topnav_height;
  left: 0;
  width: 100vw;
  height: $filterbar_height;

  border-top: 1px solid $darkgrey;
  border-bottom: 1px solid $darkgrey;

  padding: 16px 40px;
  box-sizing: border-box;

  transition: opacity $uiSpeed;

  @screen md {
    position: absolute;
  }

  &.faded {
    opacity: 0.2;
    pointer-events: none;
  }

  .group {
    //margin-right: 24px;

    .title {
      font-weight: bold;
      font-size: 11.11px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .dropdown {
      width: 150px;

      select {
        -moz-appearance: none !important;

        width: 100%;
        position: relative;
        background: $bg-primary;
        color: $white;
        border: 1px solid $midgrey;
        border-radius: 4px;
        outline: none;
        //padding: 0.5em 0.25em;
        box-sizing: border-box;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        // letter-spacing: 0.15em;
        padding: 4px 24px 2px 8px;
        font-family: "Mark Pro";

        background-position: 0 0;

        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;

        background-image: url("~@/images/Single-Angle.svg");
        background-position: calc(100% - 8px) center;
        background-size: 8px auto;
        background-repeat: no-repeat;

        option {
          // text-transform: none;
        }
      }

      &--large {
        //width: 200px;
      }
    }
  }

  .close-container {
    position: relative;
    height: 100%;
    width: 70px;
    @apply mr-4;
    @screen md {
      @apply mr-0 ml-4;
    }

    .close-btn {
      @apply flex items-center;
      flex-direction: row;
      cursor: pointer;
      bottom: 3px;
      span {
        @apply ml-1;
        line-height: 1;
      }
      &:hover {
        color: $teal;
      }

      img {
        margin-right: 4px;
      }
    }

    span:hover {
      color: $teal;

      img {
        filter: invert(50%);
      }
    }
  }

  @include breakpoint(handheld) {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;

    .group {
      margin: 0;

      .title {
        font-size: 8px;
        white-space: nowrap;
      }

      .dropdown {
        select {
          width: 100%;
        }
      }
    }

    .view_toggle {
      display: none;
    }
  }
}
</style>
