<template>
  <div class="sponsors">
    <div class="logo">
      <span class="logo-container">
        <picture class="white-logo">
          <img
            src="~@/images/unicef-white-logo-2.png"
            class="unicef-logo"
            alt="Unicef logo"
          />
        </picture>
      </span>
    </div>
    <div class="logo">
      <span class="logo-container">
        <picture>
          <img
            src="~@/images/pgp-white-logo-2.png"
            class="pgp-logo"
            alt="PGP logo"
          />
        </picture>
      </span>
    </div>
    <div class="logo">
      <span class="logo-container">
        <picture>
          <img
            src="~@/images/yale-white-logo.png"
            class="yale-logo"
            alt="Yale logo"
          />
        </picture>
      </span>
    </div>
  </div>
</template>
<script></script>
<style lang="scss">
.sponsors {
  @apply -my-2;
  > * {
    @apply my-2;
  }
  @screen md {
    @apply -mx-2;
    > * {
      @apply mx-2;
    }
  }

  > * {
    @apply relative flex items-center justify-center;
    max-width: 180px;
    max-height: 180px;
    opacity: 0.6;
    border-bottom: none;
  }

  // .sponsor-logo:hover{
  //   path, rect{
  //   fill: #94DFDE;
  //   }
  // }
  div {
  }

  .logo-container {
    img {
      //@apply absolute inset-0;
    }
  }

  .logo {
    max-width: 50%;
  }
  @screen md {
    .logo {
      max-width: 180px;
    }
    .yale-logo {
      min-width: 180px;
    }
  }
  //.logo-container {
  //  opacity: 0.8;
  //}
  //.logo-container:hover {
  //  .white-logo {
  //    @apply hidden;
  //  }
  //  .green-logo {
  //    @apply block;
  //  }
  //}
}
</style>
