<template>
  <div class="timeline" :class="[{ alert_view: alertView, faded: splashView }]">
    <div v-if="listView" class="axis"></div>

    <div
      class="week_days"
      :style="{
        right:
          'calc(29vw - ' + (totalWeeks - weekIndex - 1) * weekWidth + 'px)',
      }"
    >
      <div
        v-for="(week, index) in weeks"
        class="week_day"
        :class="{ active: week.active, faded: !week.display }"
        :style="{ left: (-totalWeeks + index) * weekWidth + 'px' }"
        :key="'week' + index"
        @click="selectWeekDay(index)"
      >
        <div class="month">
          {{ week.month }}
        </div>
        <div class="day">
          {{ week.day }}
        </div>
      </div>
    </div>

    <div
      class="drag_surface"
      :class="{ hidden: !listView }"
      @mousedown="dragStart"
      @mouseup="dragEnd"
      @mouseleave="dragEnd"
      @mousemove="dragThrottled"
    />

    <div
      class="left_button"
      @click="last(1)"
      :class="{ hidden: weekIndex == 0 }"
    >
      <img src="@/images/angle-double-left.svg" alt="Timeline Left Button" />
    </div>

    <div
      class="right_button"
      @click="next(1)"
      :class="{ hidden: weekIndex == totalWeeks - 1 }"
    >
      <img src="@/images/angle-double-right.svg" alt="Timeline Left Button" />
    </div>
  </div>
</template>

<script>
// import dayjs from "dayjs";
import { throttle } from "lodash";

export default {
  name: "Timeline",
  props: [
    "weeks",
    "totalWeeks",
    "weekWidth",
    "splashView",
    "alertView",
    "weekIndex",
    "listView",
  ],
  data() {
    return {
      // weekWidth: 60,
      offset: 0,
      // index: 0,
      // lastIndex: 0,
      total: 0,
      mouseX: null,
      dragging: false,
      throttleSpeed: 200,
    };
  },
  watch: {
    weekIndex(newVal) {
      // update selected index when weekIndex prop changes
      this.index = newVal;
    },
  },
  created() {
    this.index = this.weekIndex;
    this.lastIndex = this.index;
    this.total = this.totalWeeks - 1;
    this.dragThrottled = throttle(this.drag, this.throttleSpeed);

    window.addEventListener("keydown", this.keyDown);
  },
  emits: ["indexChange"],
  methods: {
    keyDown(e) {
      if (e.key == "ArrowRight") {
        this.next(1);
      } else if (e.key == "ArrowLeft") {
        this.last(1);
      }
    },
    selectWeekDay(index) {
      // this.weekDays[this.index].active = false;
      this.index = index;
      // this.weekDays[this.index].active = true;
      this.$emit("indexChange", this.index);
    },
    next(speed) {
      if (this.index < this.total) {
        this.lastIndex = this.index;
        // this.weekDays[this.index].active = false;
        this.index += speed;
        if (this.index > this.total) {
          this.index = this.total;
        }
        // this.weekDays[this.index].active = true;

        let offsetFactor = Math.abs(this.index - this.lastIndex);
        this.offset -= offsetFactor * this.weekWidth;
        // this.offset = this.index*this.weekWidth;

        this.$emit("indexChange", this.index);
      }
    },
    last(speed) {
      if (this.index > 0) {
        this.lastIndex = this.index;
        // this.weekDays[this.index].active = false;
        this.index -= speed;
        if (this.index < 0) {
          this.index = 0;
        }
        // this.weekDays[this.index].active = true;

        let offsetFactor = Math.abs(this.index - this.lastIndex);
        this.offset += offsetFactor * this.weekWidth;

        this.$emit("indexChange", this.index);
      }
    },
    dragStart() {
      this.dragging = true;
    },
    dragEnd() {
      this.dragging = false;
      this.mouseX = null;
    },
    drag(e) {
      if (this.dragging) {
        if (this.mouseX) {
          let velocity = e.offsetX - this.mouseX;
          let speed = Math.abs(velocity);
          let speedMod = Math.pow(speed, 1.1);
          let indexOffset = Math.round(speedMod / this.weekWidth);

          if (velocity < 0) {
            this.next(indexOffset);
          } else if (velocity > 0) {
            this.last(indexOffset);
          }
        }

        this.mouseX = e.offsetX;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.timeline {
  position: fixed;
  left: 0;
  top: $toparea_height;
  width: 100vw;
  height: calc(100vh - #{$toparea_height});
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  transition: left $viewSpeed, opacity $viewSpeed;
  @screen md {
    position: absolute;
  }

  &.alert_view {
    left: -70vw;
    opacity: 0;
    pointer-events: none;
  }

  &.faded {
    opacity: 0.2;
    pointer-events: none;
  }

  .axis {
    position: absolute;
    right: calc($cards_width + 3.17rem);
    top: 0;
    width: 1px;
    height: 100vh;
    border-left: 1px dashed $offwhite;
    opacity: 0.5 !important;
  }

  .week_days {
    position: absolute;
    right: $cards_width;
    height: $timeline_height;
    width: 0;
    overflow: visible;
    transition: right $uiSpeed;
    pointer-events: all;

    .week_day {
      position: absolute;
      left: 0;
      // top: 10px;
      transform: translateX(-50%);
      display: inline-block;
      width: auto;
      min-width: 40px;
      float: right;
      height: auto;
      padding: 5px;
      padding-top: 10px;
      text-align: center;
      transition: background $uiQuickSpeed ease-out,
        border $uiQuickSpeed ease-in;
      left: $uiSpeed;
      border: 1px solid rgba(255, 255, 255, 0);
      background: #303236;

      cursor: pointer;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      &.active {
        background: $bg-primary;
        border: 1px solid $white;
      }

      &.faded {
        opacity: 0.2;
        pointer-events: none;
      }

      .day,
      .month {
        height: 16px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        white-space: nowrap;
      }
    }
  }

  .drag_surface {
    position: absolute;
    left: 0;
    top: $timeline_height;
    width: 100%;
    height: calc(100% - #{$timeline_height});
    background: rgba(0, 0, 0, 0);
    cursor: ew-resize;

    &.hidden {
      display: none;
    }
  }

  .left_button,
  .right_button {
    position: absolute;
    top: calc(#{$timeline_height} / 2);
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    pointer-events: all;

    cursor: pointer;
    user-select: none;
    background: $bg-primary-semi;
    box-shadow: 0 0 24px 24px $bg-primary-semi;

    transition: transform $uiSpeed, opacity $uiSpeed;
    opacity: 1;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .left_button {
    left: 16px;

    &:hover {
      transform: translateX(-4px) translateY(-50%);
    }
  }

  .right_button {
    right: 16px;

    &:hover {
      transform: translateX(4px) translateY(-50%);
    }

    img {
      right: 0;
    }
  }

  @include breakpoint(handheld) {
    &.alert_view {
      left: 0;
    }

    .left_button {
      left: 8px;
    }

    .right_button {
      right: 8px;
    }
  }
}
</style>
