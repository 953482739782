<template>
  <div
    class="stack_chart"
    :class="{ alert_view: alertView, hidden: !listView }"
    :style="cssVars"
  >
    <div
      class="weeks"
      :style="{
        right:
          'calc(30vw - ' + (totalWeeks - weekIndex - 1) * weekWidth + 'px)',
      }"
    >
      <div
        v-for="(week, index) in weeks.slice().reverse()"
        class="week"
        :class="{ active: index == totalWeeks - weekIndex - 1 }"
        :key="'week_' + index"
        :style="{ left: -index * weekWidth + 'px' }"
      >
        <div class="alerts">
          <div class="dot-stack">
            <div
              v-for="alert in week.misinformation_alert['Low']"
              class="alert alert ignore"
              :class="{ display: alert.display }"
              :key="alert.id"
              v-tooltip="{
                content: dotInfo(alert.data.title),
                placement: 'left',
              }"
              @click="selectAlert(alert, weeks.length - (index + 1))"
            >
              &nbsp;
            </div>
            <div
              v-for="alert in week.misinformation_alert['Medium']"
              class="alert alert passive"
              :class="{ display: alert.display }"
              :key="alert.id"
              v-tooltip="{
                content: dotInfo(alert.data.title),
                placement: 'left',
              }"
              @click="selectAlert(alert, weeks.length - (index + 1))"
            >
              &nbsp;
            </div>
            <div
              v-for="alert in week.misinformation_alert['High']"
              class="alert alert active"
              :class="{ display: alert.display }"
              :key="alert.id"
              v-tooltip="{
                content: dotInfo(alert.data.title),
                placement: 'left',
              }"
              @click="selectAlert(alert, weeks.length - (index + 1))"
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div class="updates">
          <div
            v-for="alert in week.legislation"
            class="alert legislation"
            :class="{ display: alert.display }"
            :key="alert.id"
          >
            &nbsp;
          </div>
          <div
            v-for="alert in week.insights"
            class="alert insight"
            :class="{ display: alert.display }"
            :key="alert.id"
          >
            &nbsp;
          </div>
          <div
            v-for="alert in week.fact_check"
            class="alert fact_check"
            :class="{ display: alert.display }"
            :key="alert.id"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fader"></div> -->
  </div>
</template>

<script>
import * as prismicH from "@prismicio/helpers";

export default {
  name: "StackChart",
  props: [
    "weeks",
    "totalWeeks",
    "weekWidth",
    "weekIndex",
    "alertView",
    "listView",
  ],
  computed: {
    cssVars() {
      return {
        "--week-width": this.weekWidth + "px",
      };
    },
  },
  methods: {
    selectStack(index) {
      this.$emit("indexChange", index);
    },
    dotInfo(alert) {
      return prismicH.asText(alert);
    },
    selectAlert(alert, index) {
      this.$emit("alertSelected", alert);
      setTimeout(() => {
        this.selectStack(index);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.stack_chart {
  position: absolute;
  left: 0;
  top: $toparea_height;
  width: 100vw;
  height: calc(100vh - #{$toparea_height});
  overflow: hidden;
  user-select: none;

  transition: left $viewSpeed, opacity $viewSpeed;

  &.hidden {
    opacity: 0;
  }

  &.alert_view {
    opacity: 0;
    left: calc(-100vw + #{$cards_width});
  }

  .fader {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      $bg-primary 0%,
      $bg-primary-transparent 100%
    );
  }

  .weeks {
    position: absolute;
    right: 40vw;
    top: 0;
    width: 0;
    height: 100vh;
    overflow-x: visible;
    display: flex;
    transition: right $uiSpeed;

    .week {
      position: absolute;
      top: 0;
      height: 100vh;
      transform: translateX(-100%);
      width: var(--week-width);
      box-sizing: border-box;
      display: grid;
      grid-template-rows: 80%;

      opacity: 1;
      transition: opacity $viewSpeed;

      &.active {
        // opacity: 0;
        @apply z-10;
      }

      .alerts,
      .updates {
        // border: 1px solid cyan;
        box-sizing: border-box;
        display: flex;
        flex-direction: column-reverse;

        .alert {
          align-self: center;
          width: 14px;

          background: yellow;
          margin: 0;
          height: 0;
          opacity: 0;

          transition: opacity $uiSpeed, height $uiSpeed $uiSpeed,
            width $uiSpeed $uiSpeed, margin $uiSpeed $uiSpeed;

          // display: none;

          &.display {
            // display: block;
            margin: 2px;
            height: 7px;
            max-height: 0.7vh;
            opacity: 1;
            transition: opacity $uiSpeed $uiSpeed, width $uiSpeed,
              height $uiSpeed, margin $uiSpeed;
          }

          &.alert {
            border-radius: 50%;
          }

          &.ignore {
            background: $ignore;
          }

          &.passive {
            background: $passive;
          }

          &.active {
            background: $active;
          }

          &.fact_check {
            background: $fact_check;
          }

          &.insight {
            background: $insight;
          }

          &.legislation {
            background: $legislation;
          }
        }
      }

      .alerts .alert {
        width: 0;

        &.display {
          width: 10px;
          height: 10px;
          max-height: 10px;
        }
      }

      .dot-stack {
        @apply flex flex-col-reverse cursor-pointer;
        width: 18px;

        &:hover {
          .alert {
            @apply opacity-50;
          }
        }

        .alert {
          transition: opacity 0.2s !important;

          &:hover {
            @apply opacity-100;
          }
        }
      }
    }
  }

  @include breakpoint(handheld) {
    display: none;
    // .weeks .week {
    //   height: calc(100vh - 64px);
    // }
  }
}
</style>
