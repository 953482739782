export default function (doc) {
  if (doc.type === "vdo_link") {
    if (doc.uid === "contact") {
      return "/contact";
    } else if (doc.uid === "alerts") {
      return "/";
    }
    return "";
  } else {
    return "";
  }
}
