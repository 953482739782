<template>
  <div class="resources_view">
    <div class="header-tab">
      <router-link class="" to="/resources">
        <span :class="[$route.path === '/resources' ? 'active' : '']"
          >Articles</span
        >
      </router-link>
      <router-link class="" to="/resources/reports">
        <span :class="[$route.path === '/resources/reports' ? 'active' : '']"
          >UNICEF Social Listening Reports</span
        >
      </router-link>
    </div>
    <div class="container">
      <div v-if="$route.path === '/resources'">
        <h1><span>Articles</span></h1>
        <div class="tab">
          <div>
            <button
              type="button"
              class="filter-button md:hidden border border-white py-1 px-2 mb-6 w-full"
              :class="{ selected: showFilters }"
              @click="showFilters = !showFilters"
            >
              Filters
            </button>
            <div class="filters" :class="showFilters ? 'show' : ''">
              <div class="group">
                <div class="title">Source</div>
                <select
                  v-model="selectedSource"
                  @change="filterSelected('source')"
                >
                  <option :value="'none'" :key="'no_selection'">All</option>
                  <option
                    v-for="source in sources"
                    :value="source"
                    :key="source"
                  >
                    {{ source }}
                  </option>
                </select>
              </div>

              <div class="group">
                <div class="title">Type</div>
                <select v-model="selectedType" @change="filterSelected('type')">
                  <option :value="'none'" :key="'no_selection'">All</option>
                  <option v-for="type in types" :value="type" :key="type">
                    {{ type }}
                  </option>
                </select>
              </div>

              <div class="group">
                <div class="title">Date</div>

                <a
                  class="month-filter"
                  :class="{
                    disabled: !month.display,
                    selected: this.selectedMonth === monthId,
                  }"
                  :href="'#' + month.name.replace(/ /g, '_')"
                  v-for="(month, monthId) in months"
                  :key="month.name"
                  @click.prevent="selectMonth(month.name.replace(/ /g, '_'))"
                >
                  {{ month.name }}
                </a>
              </div>
            </div>
          </div>
          <div class="list" ref="scrollBox" v-if="ready">
            <div class="no_results" :class="{ display: noResults }">
              <div class="title">No resources</div>
              Please select different filters to see results.
            </div>

            <div
              v-for="(month, monthId) in months"
              :key="month.name"
              class="month"
              :class="{ display: month.display }"
              :id="month.name.replace(/ /g, '_')"
            >
              <div :ref="setMonthRef" class="month_name" :data-id="monthId">
                {{ month.name }}
              </div>
              <div
                v-for="resource in month.resources"
                :key="resource.id"
                class="resource"
                :class="{ display: resource.display }"
              >
                <div class="source">
                  <span> {{ resource.data.source }}</span>
                  <span v-if="resource.data.source" class="divider">|</span>
                  <div class="type">{{ resource.data.resource_type.slug }}</div>
                </div>
                <a
                  class="title-link"
                  :href="resource.data.link.url"
                  target="_blank"
                >
                  <h2 class="inline-block items-center">
                    <span>
                      {{ resource.data.title[0].text }}
                    </span>
                    <img
                      src="@/images/external-link-teal.svg"
                      class="link-out"
                      alt="link icon"
                    />
                  </h2>
                </a>
                <p>
                  {{ resource.data.description[0].text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h1><span>UNICEF Reports</span></h1>
        <social-listening-report />
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
import SocialListeningReport from "@/components/SocialListeningReport.vue";

export default {
  name: "ResourcesView",
  inject: ["prismic", "dayjs"],
  components: {
    SocialListeningReport,
  },
  data() {
    return {
      apiPageNumber: 1,
      resources: [],
      initialSources: [],
      ready: false,
      sources: [],
      selectedSource: "none",
      selectedMonth: 0,
      autoScrolling: false,
      observer: {},
      selectedType: "none",
      noResults: false,
      activeTab: "articles",
      showFilters: false,
    };
  },
  methods: {
    selectMonth(id) {
      this.showFilters = false;
      this.autoScrolling = true;
      window.location.hash = id;
      window.setTimeout(() => {
        this.autoScrolling = false;
      }, 2000);
    },
    setMonthRef(monthEl) {
      if (monthEl) {
        this.observer.observe(monthEl);
      }
    },
    resetPinned() {
      document.querySelectorAll(".month_name").forEach((elem) => {
        elem.classList.remove("is-pinned");
      });
    },
    pinElem(elem) {
      this.selectedMonth = parseInt(elem.dataset.id, 10);
      elem.classList.add("is-pinned");
    },
    intersectionCallback(element) {
      if (element.length === 1) {
        const currentElem = element[0];
        if (this.autoScrolling) {
          if (currentElem.isIntersecting) {
            this.resetPinned();
            this.pinElem(currentElem.target);
          }
        } else if (currentElem.intersectionRatio < 1) {
          this.resetPinned();
          this.pinElem(currentElem.target);
        }
      }
      // e.target.classList.toggle("is-pinned", e.intersectionRatio < 1);
    },
    loadResources() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);

      prismicClient
        .query(
          [
            this.prismic.Predicates.any("document.type", ["resource"]),
            this.prismic.Predicates.not("document.tags", ["featured"]),
            this.prismic.Predicates.not("my.resource.system", "VCTR"),
          ],
          {
            pageSize: 100,
            page: this.apiPageNumber,
            orderings: "[my.resource.post_date desc]",
          }
        )
        .then((response) => {
          response.results.forEach((resource) => {
            resource.day = this.dayjs(resource.first_publication_date);
            resource.display = true;
          });

          this.resources = this.resources.concat(response.results);

          // console.log("resources: ", this.resources);

          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadResources();
          } else {
            this.ready = true;
            this.fetchSources();
          }
        });

      return true;
    },
    filterSelected(filter_type) {
      this.showFilters = false;
      if (filter_type === "type" && this.selectedType && this.selectedSource) {
        this.selectedSource = "none";
      }
      if (this.selectedType === "none" && this.selectedSource === "none") {
        this.resources.forEach((resource) => {
          resource.display = true;
        });
      } else {
        this.resources.forEach((resource) => {
          if (
            (resource.data.resource_type.slug === this.selectedType ||
              this.selectedType === "none") &&
            (resource.data.source === this.selectedSource ||
              this.selectedSource === "none")
          ) {
            resource.display = true;
          } else {
            resource.display = false;
          }
        });
      }

      let totalMonths = this.months.length;
      let totalHiddenMonths = 0;

      this.months.forEach((month) => {
        let display = true;

        let totalResources = month.resources.length;
        let totalHidden = 0;

        month.resources.forEach((resource) => {
          if (!resource.display) {
            totalHidden++;
          }
        });

        if (totalHidden === totalResources) {
          display = false;
          totalHiddenMonths++;
        }
        month.display = display;
      });

      if (totalHiddenMonths === totalMonths) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }
    },
    fetchSources() {
      this.sources = [
        ...new Set(this.resources.map((resource) => resource.data.source)),
      ];
      this.sources.sort();
      this.initialSources = this.sources;
    },
    setTab() {
      const url = new URL(window.location);
      let path = url.pathname.split("/").slice(-1)[0];
      if (path === "reports") {
        this.activeTab = path;
      } else {
        this.activeTab = "articles";
      }
    },
  },
  computed: {
    months() {
      let monthsObj = groupBy(this.resources, (resource) => {
        let d = resource.day;
        return d.format("MMMM YYYY");
      });

      let months = [];
      for (const [key, value] of Object.entries(monthsObj)) {
        let month = {
          name: key,
          resources: value,
          display: true,
        };

        months.push(month);
      }

      // months = sortBy(month, months => {

      // })

      return months;
    },
    // sources: function () {
    //   let sourceNames = [
    //     ...new Set(this.resources.map((resource) => resource.data.source)),
    //   ];
    //   sourceNames.sort();
    //   return sourceNames;
    // },
    types() {
      let types = [
        ...new Set(
          this.resources.map((resource) => resource.data.resource_type.slug)
        ),
      ];
      types.sort();

      // console.log("types: ", types);

      return types;
    },
  },
  watch: {
    selectedType: function () {
      //console.log("changed:" + this.selectedType);
      if (this.selectedType !== "none") {
        let filterResources = this.resources.filter((resource) => {
          if (resource.data.resource_type.slug === this.selectedType) {
            return resource.data.source;
          }
        });
        this.sources = [
          ...new Set(filterResources.map((resource) => resource.data.source)),
        ];
      } else {
        this.sources = this.initialSources;
      }
    },
  },
  created() {
    this.loadResources();
    // let options = {
    //   threshold: [1],
    // };
    let options = {
      root: this.$refs.scrollBox,
      rootMargin: "0px",
      threshold: [1],
    };

    this.observer = new IntersectionObserver(
      this.intersectionCallback,
      options
    );
  },
  mounted() {
    // this.setTab();
  },
};
</script>

<style lang="scss" scoped>
.resources_view {
  position: relative;
  top: $topnav_height;
  left: 0;
  width: 100%;
  padding: 1rem 120px;

  @screen md {
    padding: 2rem 120px;
  }

  height: calc(100vh - #{$topnav_height}); //overflow-y: scroll;

  a.title-link {
    @apply no-underline;
    border: none;
  }

  h1 {
    position: relative;
    //font-size: 40px;
    margin-bottom: 2rem;
    background-color: $bg-primary;

    span {
      background-color: $bg-primary;
      position: relative;
      padding-right: 0.7em;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      border-top: 1px solid $teal;
    }
  }

  .filters {
    position: absolute;
    height: 0;
    top: calc(#{$topnav_height} + 6rem);
    overflow: hidden;
    left: 0;
    width: 100%;

    &.show {
      @apply p-4 border-b border-white;
      transition: height 0.2s ease;
      height: 100%;
      z-index: 10;
      background: $bg-primary;
    }

    @screen md {
      @apply p-0;
      height: auto;
      left: auto;
      position: sticky;
      top: calc(#{$topnav_height} + 2rem);
    }

    .group {
      margin-bottom: 24px;
      max-height: 500px;
      overflow-y: auto;

      .month-filter {
        @apply py-1;
        display: block;
        border: none;

        box-sizing: border-box;
        font-size: 14px;
        line-height: 16px;
        &.selected {
          color: $jade;
        }

        &.disabled {
          pointer-events: none;
          color: $grey;
        }
      }
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 11.11px;
      line-height: 14px;
      /* identical to box height */

      letter-spacing: 0.08em;
      text-transform: uppercase;

      margin: 4px 0;
    }

    select {
      position: relative;
      width: 100%;
      background: $bg-primary;
      color: $white;
      border: 1px solid $midgrey;
      border-radius: 4px;
      outline: none;
      padding: 0.5em 0.25em;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      // letter-spacing: 0.15em;
      padding: 4px 8px;
      font-family: "Mark Pro";

      background-position: 0 0;

      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      background-image: url("~@/images/Single-Angle.svg");
      background-position: calc(100% - 8px) center;
      background-size: 8px auto;
      background-repeat: no-repeat;
    }
  }

  .resource-container {
    @apply grid grid-cols-2;
    grid-template-columns: 216px 1fr;

    .filters {
      //width: 216px;
    }
  }

  .container .tab {
    @apply grid grid-cols-1;
    @screen md {
      @apply grid-cols-2;
      grid-template-columns: 216px 1fr;
      grid-column-gap: 72px;
    }

    .list {
      top: 0;
      height: 100%;
      overflow-y: scroll;
      max-height: calc(100vh - 265px);
      box-sizing: border-box;
      //padding-bottom: 80px;

      scroll-behavior: smooth;

      .no_results {
        font-size: 13.33px;
        line-height: 17px;
        letter-spacing: 0.08em;

        color: $grey;
        margin: 0 0 40px 0;
        display: none;

        .title {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 1em;
        }

        &.display {
          display: block;
        }
      }

      .month {
        max-height: 0;
        //overflow: hidden;
        opacity: 0;

        &.display {
          max-height: 10000vh;
          opacity: 1;
          margin: 0 0 40px 0;
        }
      }

      .month_name {
        position: sticky;
        font-weight: bold;
        line-height: 17px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $teal;
        padding: 8px 0;
        margin-bottom: 2rem;
        font-size: 14px;
        border-bottom: 1px solid white;
        top: -1px;
        z-index: 1;
        background: #303236;
      }

      .resource {
        max-width: 800px;

        max-height: 0;
        overflow: hidden;
        opacity: 0;

        transition: opacity $uiSpeed, max-height $uiSpeed;

        &.display {
          max-height: 800px;
          opacity: 1;
          margin-bottom: 24px;
        }

        .source {
          display: flex;
          font-style: normal;
          font-weight: normal;
          font-size: 11.11px;
          line-height: 14px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: #f3f3f3;

          a {
            //border-bottom: 1px solid transparent;

            &:hover {
              border-bottom: 1px solid $teal;
            }

            img {
              width: 12px;
              height: 13px;
              line-height: 13px;
              margin: 0 0.5em;
              position: relative;
              top: 2px;
            }
          }

          .divider {
            padding-left: 4px;
            padding-right: 4px;
          }

          .type {
            font-weight: normal;
            color: #f4f4f4;
            // margin-left: 2em;
          }
        }

        .link-out {
          width: 16px;
          @apply ml-2;
          //margin-top: 2px;
          //margin-left: 4px;
        }

        h2 {
          font-weight: bold;
          font-size: 19.2px;
          line-height: 24px;
          margin: 8px 0;
          border: none;

          > * {
            display: inline;
            border: none;
          }
        }

        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          margin: 8px 0;
          display: inline-block;
        }
      }
    }
  }

  .header-tab {
    @apply mb-8 font-medium uppercase;
    font-size: 13px;
    span {
      @apply mr-4 cursor-pointer;

      &.active {
        @apply text-jade;
      }
    }
  }

  @include breakpoint(tablet) {
    top: $topnav_height;
    //padding-top: 24px;

    h1 {
      margin-bottom: 60px;
    }

    .filters {
      .group {
        .item {
          padding: 0.35em 0.5em;
        }
      }
    }

    .container {
      .list {
        // max-height: calc(100vh - 145px);
      }
    }
  }

  @include breakpoint(handheld) {
    top: $topnav_height;
    padding-left: 16px;
    padding-right: 16px;

    height: calc(100vh - #{$topnav_height});
    overflow-y: scroll;

    h1 {
      font-size: 24px;
      margin-bottom: 32px;
    }

    .container {
      grid-template-columns: 1fr;
      grid-column-gap: 0;

      .filters {
        //display: none;
      }

      .list {
        max-height: calc(100vh - 160px);
        //max-height: initial;

        .resource {
          .source {
            margin-bottom: 0.5em;
          }

          h2 {
            font-size: 18px;
            line-height: 24px;
            margin: 4px 0;
          }

          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .filter-button {
    &.selected {
      background: $jade;
      color: black;
      border-color: $jade;
    }
  }

  a {
    @apply no-underline border-0;
  }
}
</style>
