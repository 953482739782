<template>
  <div class="flex flex-col items-start container mx-auto">
    <button type="button" @click="exportData('topic')">Export Topics</button>
    <button type="button" @click="exportData('country')">
      Export Countries
    </button>
  </div>
</template>

<script>
import csvDownload from "json-to-csv-export";

export default {
  name: "ExportView",
  inject: ["prismic"],
  components: {},
  data() {
    return {
      apiPageNumber: 1,
    };
  },
  methods: {
    exportData(type) {
      this.loadType(type);
    },
    async loadType(type) {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);
      let results = [];

      await prismicClient
        .query(this.prismic.Predicates.at("document.type", type), {
          pageSize: 100,
          page: this.apiPageNumber,
        })
        .then((response) => {
          //console.log(response);

          results = results.concat(
            response.results.map((n) => {
              return {
                uid: n.uid,
                name: n.data.name,
              };
            })
          );
          //console.log(results);

          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadType();
          }
        });
      //console.log(results);
      csvDownload(results, type + ".csv");
    },
  },
  computed: {},
  watch: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 100px;

  button {
    @apply mb-4;
  }
}
</style>
